import { TFunction } from 'i18next';
import validation from '../utils/validation';

export const editValidation = (t: TFunction) => ({
  firstName: validation
    .string()
    .required(t('users.create_user.validation.please_enter_first_name')),
  lastName: validation
    .string()
    .required(t('users.create_user.validation.please_enter_last_name')),
  email: validation
    .string()
    .required(t('users.create_user.validation.please_enter_email'))
    .isEmail(t('users.create_user.validation.please_enter_valid_email')),
  phoneNumber: validation
    .string()
    .max(30, t('users.create_user.validation.please_enter_valid_phone_number')),
  // .isMobilePhone(t('users.create_user.validation.please_enter_valid_phone')),
  role: validation
    .string()
    .required(t('users.create_user.validation.please_select_role')),
});

export const creationValidation = (t: TFunction) => ({
  ...editValidation(t),
});

export const assignUserValidation = (t: TFunction) => ({
  users: validation.array().min(1, t('users.validation.users')),
});

export const bulkEditUsers = (t: TFunction) => ({
  role: validation
    .object()
    .shape({
      value: validation.string().required(),
      label: validation.string().required(),
    })
    .required(t('users.create_user.validation.please_select_role')),
});

export const updateUserProfileValidation = (t: TFunction) => ({
  firstName: validation
    .string()
    .required(t('users.create_user.validation.please_enter_first_name')),
  lastName: validation
    .string()
    .required(t('users.create_user.validation.please_enter_last_name')),
  language: validation
    .string()
    .required(t('users.create_user.validation.please_select_language')),
  phoneNumber: validation
    .string()
    .required(t('users.create_user.validation.please_enter_phone'))
    .isMobilePhone(t('users.create_user.validation.please_enter_valid_phone')),
});

export const updateUserProfileStepsValidation = (t: TFunction) => ({
  firstName: validation
    .string()
    .required(t('users.create_user.validation.please_enter_first_name')),
  lastName: validation
    .string()
    .required(t('users.create_user.validation.please_enter_last_name')),
});
