import React from 'react';
import PlaylistComponent from './playlistComponent';
import PlaylistFormInputs from './playlistFormInputs.interface';
import Playlist from '../../interfaces/playlist.interface';

const PlaylistModal = ({
  onSubmit,
  modalTitle,
  shown,
  hide,
  playlist,
  isLoading,
  isEditMode,
}: {
  onSubmit: (data: PlaylistFormInputs) => void;
  modalTitle: string;
  shown: boolean;
  hide: () => void;
  playlist?: Playlist;
  isLoading: boolean;
  isEditMode?: boolean;
}) => {
  return (
    <>
      {shown && (
        <PlaylistComponent
          isLoading={isLoading}
          playlist={playlist ?? ({} as Playlist)}
          onSubmit={onSubmit}
          shown={shown}
          modalTitle={modalTitle}
          hide={hide}
          isEditMode={isEditMode}
        />
      )}
    </>
  );
};

export default PlaylistModal;
