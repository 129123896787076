import blueMulticolor from '../assets/images/weather/blueMulticolor.png';
import green from '../assets/images/weather/green.png';
import navyBlue from '../assets/images/weather/navyBlue.png';
import orange from '../assets/images/weather/orange.png';
import pinkMulticolor from '../assets/images/weather/pinkMulticolor.png';
import purple from '../assets/images/weather/purple.png';
import purpleMulticolor from '../assets/images/weather/purpleMulticolor.png';
import red from '../assets/images/weather/red.png';
import skyBlue from '../assets/images/weather/skyBlue.png';
import skyblueGradient from '../assets/images/weather/skyblueGradient.png';
import widget from '../assets/images/weather/widget.png';
import WeatherColor from '../enums/weatherColor.enum';

export default {
  [WeatherColor.BlueMulticolor]: blueMulticolor,
  [WeatherColor.Green]: green,
  [WeatherColor.NavyBlue]: navyBlue,
  [WeatherColor.Orange]: orange,
  [WeatherColor.PinkMulticolor]: pinkMulticolor,
  [WeatherColor.Purple]: purple,
  [WeatherColor.PurpleMulticolor]: purpleMulticolor,
  [WeatherColor.Red]: red,
  [WeatherColor.SkyBlue]: skyBlue,
  [WeatherColor.SkyblueGradient]: skyblueGradient,
  [WeatherColor.Widget]: widget,
} as const;
