export const IconsForQrCode = [
  {
    value: 'fa fa-glass',
    label: 'Glass',
  },
  {
    value: 'fa fa-music',
    label: 'Music',
  },
  {
    value: 'fa fa-search',
    label: 'Search',
  },
  {
    value: 'fa fa-envelope-o',
    label: 'Envelope',
  },
  {
    value: 'fa fa-heart',
    label: 'Heart',
  },
  {
    value: 'fa fa-star',
    label: 'Star',
  },
  {
    value: 'fa fa-star-o',
    label: 'Star',
  },
  {
    value: 'fa fa-user',
    label: 'User',
  },
  {
    value: 'fa fa-film',
    label: 'Film',
  },
  {
    value: 'fa fa-th-large',
    label: 'Th',
  },
  {
    value: 'fa fa-th',
    label: 'Th',
  },
  {
    value: 'fa fa-th-list',
    label: 'Th',
  },
  {
    value: 'fa fa-check',
    label: 'Check',
  },
  {
    value: 'fa fa-times',
    label: 'Times',
  },
  {
    value: 'fa fa-search-plus',
    label: 'Search',
  },
  {
    value: 'fa fa-search-minus',
    label: 'Search',
  },
  {
    value: 'fa fa-power-off',
    label: 'Power',
  },
  {
    value: 'fa fa-signal',
    label: 'Signal',
  },
  {
    value: 'fa fa-cog',
    label: 'Cog',
  },
  {
    value: 'fa fa-trash-o',
    label: 'Trash',
  },
  {
    value: 'fa fa-home',
    label: 'Home',
  },
  {
    value: 'fa fa-file-o',
    label: 'File',
  },
  {
    value: 'fa fa-clock-o',
    label: 'Clock',
  },
  {
    value: 'fa fa-road',
    label: 'Road',
  },
  {
    value: 'fa fa-download',
    label: 'Download',
  },
  {
    value: 'fa fa-arrow-circle-o-down',
    label: 'Arrow',
  },
  {
    value: 'fa fa-arrow-circle-o-up',
    label: 'Arrow',
  },
  {
    value: 'fa fa-inbox',
    label: 'Inbox',
  },
  {
    value: 'fa fa-play-circle-o',
    label: 'Play',
  },
  {
    value: 'fa fa-repeat',
    label: 'Repeat',
  },
  {
    value: 'fa fa-refresh',
    label: 'Refresh',
  },
  {
    value: 'fa fa-list-alt',
    label: 'List',
  },
  {
    value: 'fa fa-lock',
    label: 'Lock',
  },
  {
    value: 'fa fa-flag',
    label: 'Flag',
  },
  {
    value: 'fa fa-headphones',
    label: 'Headphones',
  },
  {
    value: 'fa fa-volume-off',
    label: 'Volume',
  },
  {
    value: 'fa fa-volume-down',
    label: 'Volume',
  },
  {
    value: 'fa fa-volume-up',
    label: 'Volume',
  },
  {
    value: 'fa fa-qrcode',
    label: 'Qrcode',
  },
  {
    value: 'fa fa-barcode',
    label: 'Barcode',
  },
  {
    value: 'fa fa-tag',
    label: 'Tag',
  },
  {
    value: 'fa fa-tags',
    label: 'Tags',
  },
  {
    value: 'fa fa-book',
    label: 'Book',
  },
  {
    value: 'fa fa-bookmark',
    label: 'Bookmark',
  },
  {
    value: 'fa fa-print',
    label: 'Print',
  },
  {
    value: 'fa fa-camera',
    label: 'Camera',
  },
  {
    value: 'fa fa-font',
    label: 'Font',
  },
  {
    value: 'fa fa-bold',
    label: 'Bold',
  },
  {
    value: 'fa fa-italic',
    label: 'Italic',
  },
  {
    value: 'fa fa-text-height',
    label: 'Text',
  },
  {
    value: 'fa fa-text-width',
    label: 'Text',
  },
  {
    value: 'fa fa-align-left',
    label: 'Align',
  },
  {
    value: 'fa fa-align-center',
    label: 'Align',
  },
  {
    value: 'fa fa-align-right',
    label: 'Align',
  },
  {
    value: 'fa fa-align-justify',
    label: 'Align',
  },
  {
    value: 'fa fa-list',
    label: 'List',
  },
  {
    value: 'fa fa-outdent',
    label: 'Outdent',
  },
  {
    value: 'fa fa-indent',
    label: 'Indent',
  },
  {
    value: 'fa fa-video-camera',
    label: 'Video',
  },
  {
    value: 'fa fa-picture-o',
    label: 'Picture',
  },
  {
    value: 'fa fa-pencil',
    label: 'Pencil',
  },
  {
    value: 'fa fa-map-marker',
    label: 'Map',
  },
  {
    value: 'fa fa-adjust',
    label: 'Adjust',
  },
  {
    value: 'fa fa-tint',
    label: 'Tint',
  },
  {
    value: 'fa fa-pencil-square-o',
    label: 'Pencil',
  },
  {
    value: 'fa fa-share-square-o',
    label: 'Share',
  },
  {
    value: 'fa fa-check-square-o',
    label: 'Check',
  },
  {
    value: 'fa fa-arrows',
    label: 'Arrows',
  },
  {
    value: 'fa fa-step-backward',
    label: 'Step',
  },
  {
    value: 'fa fa-fast-backward',
    label: 'Fast',
  },
  {
    value: 'fa fa-backward',
    label: 'Backward',
  },
  {
    value: 'fa fa-play',
    label: 'Play',
  },
  {
    value: 'fa fa-pause',
    label: 'Pause',
  },
  {
    value: 'fa fa-stop',
    label: 'Stop',
  },
  {
    value: 'fa fa-forward',
    label: 'Forward',
  },
  {
    value: 'fa fa-fast-forward',
    label: 'Fast',
  },
  {
    value: 'fa fa-step-forward',
    label: 'Step',
  },
  {
    value: 'fa fa-eject',
    label: 'Eject',
  },
  {
    value: 'fa fa-chevron-left',
    label: 'Chevron',
  },
  {
    value: 'fa fa-chevron-right',
    label: 'Chevron',
  },
  {
    value: 'fa fa-plus-circle',
    label: 'Plus',
  },
  {
    value: 'fa fa-minus-circle',
    label: 'Minus',
  },
  {
    value: 'fa fa-times-circle',
    label: 'Times',
  },
  {
    value: 'fa fa-check-circle',
    label: 'Check',
  },
  {
    value: 'fa fa-question-circle',
    label: 'Question',
  },
  {
    value: 'fa fa-info-circle',
    label: 'Info',
  },
  {
    value: 'fa fa-crosshairs',
    label: 'Crosshairs',
  },
  {
    value: 'fa fa-times-circle-o',
    label: 'Times',
  },
  {
    value: 'fa fa-check-circle-o',
    label: 'Check',
  },
  {
    value: 'fa fa-ban',
    label: 'Ban',
  },
  {
    value: 'fa fa-arrow-left',
    label: 'Arrow',
  },
  {
    value: 'fa fa-arrow-right',
    label: 'Arrow',
  },
  {
    value: 'fa fa-arrow-up',
    label: 'Arrow',
  },
  {
    value: 'fa fa-arrow-down',
    label: 'Arrow',
  },
  {
    value: 'fa fa-share',
    label: 'Share',
  },
  {
    value: 'fa fa-expand',
    label: 'Expand',
  },
  {
    value: 'fa fa-compress',
    label: 'Compress',
  },
  {
    value: 'fa fa-plus',
    label: 'Plus',
  },
  {
    value: 'fa fa-minus',
    label: 'Minus',
  },
  {
    value: 'fa fa-asterisk',
    label: 'Asterisk',
  },
  {
    value: 'fa fa-exclamation-circle',
    label: 'Exclamation',
  },
  {
    value: 'fa fa-gift',
    label: 'Gift',
  },
  {
    value: 'fa fa-leaf',
    label: 'Leaf',
  },
  {
    value: 'fa fa-fire',
    label: 'Fire',
  },
  {
    value: 'fa fa-eye',
    label: 'Eye',
  },
  {
    value: 'fa fa-eye-slash',
    label: 'Eye',
  },
  {
    value: 'fa fa-exclamation-triangle',
    label: 'Exclamation',
  },
  {
    value: 'fa fa-plane',
    label: 'Plane',
  },
  {
    value: 'fa fa-calendar',
    label: 'Calendar',
  },
  {
    value: 'fa fa-random',
    label: 'Random',
  },
  {
    value: 'fa fa-comment',
    label: 'Comment',
  },
  {
    value: 'fa fa-magnet',
    label: 'Magnet',
  },
  {
    value: 'fa fa-chevron-up',
    label: 'Chevron',
  },
  {
    value: 'fa fa-chevron-down',
    label: 'Chevron',
  },
  {
    value: 'fa fa-retweet',
    label: 'Retweet',
  },
  {
    value: 'fa fa-shopping-cart',
    label: 'Shopping',
  },
  {
    value: 'fa fa-folder',
    label: 'Folder',
  },
  {
    value: 'fa fa-folder-open',
    label: 'Folder',
  },
  {
    value: 'fa fa-arrows-v',
    label: 'Arrows',
  },
  {
    value: 'fa fa-arrows-h',
    label: 'Arrows',
  },
  {
    value: 'fa fa-bar-chart',
    label: 'Bar',
  },
  {
    value: 'fa fa-twitter-square',
    label: 'Twitter',
  },
  {
    value: 'fa fa-facebook-square',
    label: 'Facebook',
  },
  {
    value: 'fa fa-camera-retro',
    label: 'Camera',
  },
  {
    value: 'fa fa-key',
    label: 'Key',
  },
  {
    value: 'fa fa-cogs',
    label: 'Cogs',
  },
  {
    value: 'fa fa-comments',
    label: 'Comments',
  },
  {
    value: 'fa fa-thumbs-o-up',
    label: 'Thumbs',
  },
  {
    value: 'fa fa-thumbs-o-down',
    label: 'Thumbs',
  },
  {
    value: 'fa fa-star-half',
    label: 'Star',
  },
  {
    value: 'fa fa-heart-o',
    label: 'Heart',
  },
  {
    value: 'fa fa-sign-out',
    label: 'Sign',
  },
  {
    value: 'fa fa-linkedin-square',
    label: 'Linkedin',
  },
  {
    value: 'fa fa-thumb-tack',
    label: 'Thumb',
  },
  {
    value: 'fa fa-external-link',
    label: 'External',
  },
  {
    value: 'fa fa-sign-in',
    label: 'Sign',
  },
  {
    value: 'fa fa-trophy',
    label: 'Trophy',
  },
  {
    value: 'fa fa-github-square',
    label: 'Github',
  },
  {
    value: 'fa fa-upload',
    label: 'Upload',
  },
  {
    value: 'fa fa-lemon-o',
    label: 'Lemon',
  },
  {
    value: 'fa fa-phone',
    label: 'Phone',
  },
  {
    value: 'fa fa-square-o',
    label: 'Square',
  },
  {
    value: 'fa fa-bookmark-o',
    label: 'Bookmark',
  },
  {
    value: 'fa fa-phone-square',
    label: 'Phone',
  },
  {
    value: 'fa fa-twitter',
    label: 'Twitter',
  },
  {
    value: 'fa fa-facebook',
    label: 'Facebook',
  },
  {
    value: 'fa fa-github',
    label: 'Github',
  },
  {
    value: 'fa fa-unlock',
    label: 'Unlock',
  },
  {
    value: 'fa fa-credit-card',
    label: 'Credit',
  },
  {
    value: 'fa fa-rss',
    label: 'Rss',
  },
  {
    value: 'fa fa-hdd-o',
    label: 'Hdd',
  },
  {
    value: 'fa fa-bullhorn',
    label: 'Bullhorn',
  },
  {
    value: 'fa fa-bell',
    label: 'Bell',
  },
  {
    value: 'fa fa-certificate',
    label: 'Certificate',
  },
  {
    value: 'fa fa-hand-o-right',
    label: 'Hand',
  },
  {
    value: 'fa fa-hand-o-left',
    label: 'Hand',
  },
  {
    value: 'fa fa-hand-o-up',
    label: 'Hand',
  },
  {
    value: 'fa fa-hand-o-down',
    label: 'Hand',
  },
  {
    value: 'fa fa-arrow-circle-left',
    label: 'Arrow',
  },
  {
    value: 'fa fa-arrow-circle-right',
    label: 'Arrow',
  },
  {
    value: 'fa fa-arrow-circle-up',
    label: 'Arrow',
  },
  {
    value: 'fa fa-arrow-circle-down',
    label: 'Arrow',
  },
  {
    value: 'fa fa-globe',
    label: 'Globe',
  },
  {
    value: 'fa fa-wrench',
    label: 'Wrench',
  },
  {
    value: 'fa fa-tasks',
    label: 'Tasks',
  },
  {
    value: 'fa fa-filter',
    label: 'Filter',
  },
  {
    value: 'fa fa-briefcase',
    label: 'Briefcase',
  },
  {
    value: 'fa fa-arrows-alt',
    label: 'Arrows',
  },
  {
    value: 'fa fa-users',
    label: 'Users',
  },
  {
    value: 'fa fa-link',
    label: 'Link',
  },
  {
    value: 'fa fa-cloud',
    label: 'Cloud',
  },
  {
    value: 'fa fa-flask',
    label: 'Flask',
  },
  {
    value: 'fa fa-scissors',
    label: 'Scissors',
  },
  {
    value: 'fa fa-files-o',
    label: 'Files',
  },
  {
    value: 'fa fa-paperclip',
    label: 'Paperclip',
  },
  {
    value: 'fa fa-floppy-o',
    label: 'Floppy',
  },
  {
    value: 'fa fa-square',
    label: 'Square',
  },
  {
    value: 'fa fa-bars',
    label: 'Bars',
  },
  {
    value: 'fa fa-list-ul',
    label: 'List',
  },
  {
    value: 'fa fa-list-ol',
    label: 'List',
  },
  {
    value: 'fa fa-strikethrough',
    label: 'Strikethrough',
  },
  {
    value: 'fa fa-underline',
    label: 'Underline',
  },
  {
    value: 'fa fa-table',
    label: 'Table',
  },
  {
    value: 'fa fa-magic',
    label: 'Magic',
  },
  {
    value: 'fa fa-truck',
    label: 'Truck',
  },
  {
    value: 'fa fa-pinterest',
    label: 'Pinterest',
  },
  {
    value: 'fa fa-pinterest-square',
    label: 'Pinterest',
  },
  {
    value: 'fa fa-google-plus-square',
    label: 'Google',
  },
  {
    value: 'fa fa-google-plus',
    label: 'Google',
  },
  {
    value: 'fa fa-money',
    label: 'Money',
  },
  {
    value: 'fa fa-caret-down',
    label: 'Caret',
  },
  {
    value: 'fa fa-caret-up',
    label: 'Caret',
  },
  {
    value: 'fa fa-caret-left',
    label: 'Caret',
  },
  {
    value: 'fa fa-caret-right',
    label: 'Caret',
  },
  {
    value: 'fa fa-columns',
    label: 'Columns',
  },
  {
    value: 'fa fa-sort',
    label: 'Sort',
  },
  {
    value: 'fa fa-sort-desc',
    label: 'Sort',
  },
  {
    value: 'fa fa-sort-asc',
    label: 'Sort',
  },
  {
    value: 'fa fa-envelope',
    label: 'Envelope',
  },
  {
    value: 'fa fa-linkedin',
    label: 'Linkedin',
  },
  {
    value: 'fa fa-undo',
    label: 'Undo',
  },
  {
    value: 'fa fa-gavel',
    label: 'Gavel',
  },
  {
    value: 'fa fa-tachometer',
    label: 'Tachometer',
  },
  {
    value: 'fa fa-comment-o',
    label: 'Comment',
  },
  {
    value: 'fa fa-comments-o',
    label: 'Comments',
  },
  {
    value: 'fa fa-bolt',
    label: 'Bolt',
  },
  {
    value: 'fa fa-sitemap',
    label: 'Sitemap',
  },
  {
    value: 'fa fa-umbrella',
    label: 'Umbrella',
  },
  {
    value: 'fa fa-clipboard',
    label: 'Clipboard',
  },
  {
    value: 'fa fa-lightbulb-o',
    label: 'Lightbulb',
  },
  {
    value: 'fa fa-exchange',
    label: 'Exchange',
  },
  {
    value: 'fa fa-cloud-download',
    label: 'Cloud',
  },
  {
    value: 'fa fa-cloud-upload',
    label: 'Cloud',
  },
  {
    value: 'fa fa-user-md',
    label: 'User',
  },
  {
    value: 'fa fa-stethoscope',
    label: 'Stethoscope',
  },
  {
    value: 'fa fa-suitcase',
    label: 'Suitcase',
  },
  {
    value: 'fa fa-bell-o',
    label: 'Bell',
  },
  {
    value: 'fa fa-coffee',
    label: 'Coffee',
  },
  {
    value: 'fa fa-cutlery',
    label: 'Cutlery',
  },
  {
    value: 'fa fa-file-text-o',
    label: 'File',
  },
  {
    value: 'fa fa-building-o',
    label: 'Building',
  },
  {
    value: 'fa fa-hospital-o',
    label: 'Hospital',
  },
  {
    value: 'fa fa-ambulance',
    label: 'Ambulance',
  },
  {
    value: 'fa fa-medkit',
    label: 'Medkit',
  },
  {
    value: 'fa fa-fighter-jet',
    label: 'Fighter',
  },
  {
    value: 'fa fa-beer',
    label: 'Beer',
  },
  {
    value: 'fa fa-h-square',
    label: 'H',
  },
  {
    value: 'fa fa-plus-square',
    label: 'Plus',
  },
  {
    value: 'fa fa-angle-double-left',
    label: 'Angle',
  },
  {
    value: 'fa fa-angle-double-right',
    label: 'Angle',
  },
  {
    value: 'fa fa-angle-double-up',
    label: 'Angle',
  },
  {
    value: 'fa fa-angle-double-down',
    label: 'Angle',
  },
  {
    value: 'fa fa-angle-left',
    label: 'Angle',
  },
  {
    value: 'fa fa-angle-right',
    label: 'Angle',
  },
  {
    value: 'fa fa-angle-up',
    label: 'Angle',
  },
  {
    value: 'fa fa-angle-down',
    label: 'Angle',
  },
  {
    value: 'fa fa-desktop',
    label: 'Desktop',
  },
  {
    value: 'fa fa-laptop',
    label: 'Laptop',
  },
  {
    value: 'fa fa-tablet',
    label: 'Tablet',
  },
  {
    value: 'fa fa-mobile',
    label: 'Mobile',
  },
  {
    value: 'fa fa-circle-o',
    label: 'Circle',
  },
  {
    value: 'fa fa-quote-left',
    label: 'Quote',
  },
  {
    value: 'fa fa-quote-right',
    label: 'Quote',
  },
  {
    value: 'fa fa-spinner',
    label: 'Spinner',
  },
  {
    value: 'fa fa-circle',
    label: 'Circle',
  },
  {
    value: 'fa fa-reply',
    label: 'Reply',
  },
  {
    value: 'fa fa-github-alt',
    label: 'Github',
  },
  {
    value: 'fa fa-folder-o',
    label: 'Folder',
  },
  {
    value: 'fa fa-folder-open-o',
    label: 'Folder',
  },
  {
    value: 'fa fa-smile-o',
    label: 'Smile',
  },
  {
    value: 'fa fa-frown-o',
    label: 'Frown',
  },
  {
    value: 'fa fa-meh-o',
    label: 'Meh',
  },
  {
    value: 'fa fa-gamepad',
    label: 'Gamepad',
  },
  {
    value: 'fa fa-keyboard-o',
    label: 'Keyboard',
  },
  {
    value: 'fa fa-flag-o',
    label: 'Flag',
  },
  {
    value: 'fa fa-flag-checkered',
    label: 'Flag',
  },
  {
    value: 'fa fa-terminal',
    label: 'Terminal',
  },
  {
    value: 'fa fa-code',
    label: 'Code',
  },
  {
    value: 'fa fa-reply-all',
    label: 'Reply',
  },
  {
    value: 'fa fa-star-half-o',
    label: 'Star',
  },
  {
    value: 'fa fa-location-arrow',
    label: 'Location',
  },
  {
    value: 'fa fa-crop',
    label: 'Crop',
  },
  {
    value: 'fa fa-code-fork',
    label: 'Code',
  },
  {
    value: 'fa fa-chain-broken',
    label: 'Chain',
  },
  {
    value: 'fa fa-question',
    label: 'Question',
  },
  {
    value: 'fa fa-info',
    label: 'Info',
  },
  {
    value: 'fa fa-exclamation',
    label: 'Exclamation',
  },
  {
    value: 'fa fa-superscript',
    label: 'Superscript',
  },
  {
    value: 'fa fa-subscript',
    label: 'Subscript',
  },
  {
    value: 'fa fa-eraser',
    label: 'Eraser',
  },
  {
    value: 'fa fa-puzzle-piece',
    label: 'Puzzle',
  },
  {
    value: 'fa fa-microphone',
    label: 'Microphone',
  },
  {
    value: 'fa fa-microphone-slash',
    label: 'Microphone',
  },
  {
    value: 'fa fa-shield',
    label: 'Shield',
  },
  {
    value: 'fa fa-calendar-o',
    label: 'Calendar',
  },
  {
    value: 'fa fa-fire-extinguisher',
    label: 'Fire',
  },
  {
    value: 'fa fa-rocket',
    label: 'Rocket',
  },
  {
    value: 'fa fa-maxcdn',
    label: 'Maxcdn',
  },
  {
    value: 'fa fa-chevron-circle-left',
    label: 'Chevron',
  },
  {
    value: 'fa fa-chevron-circle-right',
    label: 'Chevron',
  },
  {
    value: 'fa fa-chevron-circle-up',
    label: 'Chevron',
  },
  {
    value: 'fa fa-chevron-circle-down',
    label: 'Chevron',
  },
  {
    value: 'fa fa-html5',
    label: 'Html5',
  },
  {
    value: 'fa fa-css3',
    label: 'Css3',
  },
  {
    value: 'fa fa-anchor',
    label: 'Anchor',
  },
  {
    value: 'fa fa-unlock-alt',
    label: 'Unlock',
  },
  {
    value: 'fa fa-bullseye',
    label: 'Bullseye',
  },
  {
    value: 'fa fa-ellipsis-h',
    label: 'Ellipsis',
  },
  {
    value: 'fa fa-ellipsis-v',
    label: 'Ellipsis',
  },
  {
    value: 'fa fa-rss-square',
    label: 'Rss',
  },
  {
    value: 'fa fa-play-circle',
    label: 'Play',
  },
  {
    value: 'fa fa-ticket',
    label: 'Ticket',
  },
  {
    value: 'fa fa-minus-square',
    label: 'Minus',
  },
  {
    value: 'fa fa-minus-square-o',
    label: 'Minus',
  },
  {
    value: 'fa fa-level-up',
    label: 'Level',
  },
  {
    value: 'fa fa-level-down',
    label: 'Level',
  },
  {
    value: 'fa fa-check-square',
    label: 'Check',
  },
  {
    value: 'fa fa-pencil-square',
    label: 'Pencil',
  },
  {
    value: 'fa fa-external-link-square',
    label: 'External',
  },
  {
    value: 'fa fa-share-square',
    label: 'Share',
  },
  {
    value: 'fa fa-compass',
    label: 'Compass',
  },
  {
    value: 'fa fa-caret-square-o-down',
    label: 'Caret',
  },
  {
    value: 'fa fa-caret-square-o-up',
    label: 'Caret',
  },
  {
    value: 'fa fa-caret-square-o-right',
    label: 'Caret',
  },
  {
    value: 'fa fa-eur',
    label: 'Eur',
  },
  {
    value: 'fa fa-gbp',
    label: 'Gbp',
  },
  {
    value: 'fa fa-usd',
    label: 'Usd',
  },
  {
    value: 'fa fa-inr',
    label: 'Inr',
  },
  {
    value: 'fa fa-jpy',
    label: 'Jpy',
  },
  {
    value: 'fa fa-rub',
    label: 'Rub',
  },
  {
    value: 'fa fa-krw',
    label: 'Krw',
  },
  {
    value: 'fa fa-btc',
    label: 'Btc',
  },
  {
    value: 'fa fa-file',
    label: 'File',
  },
  {
    value: 'fa fa-file-text',
    label: 'File',
  },
  {
    value: 'fa fa-sort-alpha-asc',
    label: 'Sort',
  },
  {
    value: 'fa fa-sort-alpha-desc',
    label: 'Sort',
  },
  {
    value: 'fa fa-sort-amount-asc',
    label: 'Sort',
  },
  {
    value: 'fa fa-sort-amount-desc',
    label: 'Sort',
  },
  {
    value: 'fa fa-sort-numeric-asc',
    label: 'Sort',
  },
  {
    value: 'fa fa-sort-numeric-desc',
    label: 'Sort',
  },
  {
    value: 'fa fa-thumbs-up',
    label: 'Thumbs',
  },
  {
    value: 'fa fa-thumbs-down',
    label: 'Thumbs',
  },
  {
    value: 'fa fa-youtube-square',
    label: 'Youtube',
  },
  {
    value: 'fa fa-youtube',
    label: 'Youtube',
  },
  {
    value: 'fa fa-xing',
    label: 'Xing',
  },
  {
    value: 'fa fa-xing-square',
    label: 'Xing',
  },
  {
    value: 'fa fa-youtube-play',
    label: 'Youtube',
  },
  {
    value: 'fa fa-dropbox',
    label: 'Dropbox',
  },
  {
    value: 'fa fa-stack-overflow',
    label: 'Stack',
  },
  {
    value: 'fa fa-instagram',
    label: 'Instagram',
  },
  {
    value: 'fa fa-flickr',
    label: 'Flickr',
  },
  {
    value: 'fa fa-adn',
    label: 'Adn',
  },
  {
    value: 'fa fa-bitbucket',
    label: 'Bitbucket',
  },
  {
    value: 'fa fa-bitbucket-square',
    label: 'Bitbucket',
  },
  {
    value: 'fa fa-tumblr',
    label: 'Tumblr',
  },
  {
    value: 'fa fa-tumblr-square',
    label: 'Tumblr',
  },
  {
    value: 'fa fa-long-arrow-down',
    label: 'Long',
  },
  {
    value: 'fa fa-long-arrow-up',
    label: 'Long',
  },
  {
    value: 'fa fa-long-arrow-left',
    label: 'Long',
  },
  {
    value: 'fa fa-long-arrow-right',
    label: 'Long',
  },
  {
    value: 'fa fa-apple',
    label: 'Apple',
  },
  {
    value: 'fa fa-windows',
    label: 'Windows',
  },
  {
    value: 'fa fa-android',
    label: 'Android',
  },
  {
    value: 'fa fa-linux',
    label: 'Linux',
  },
  {
    value: 'fa fa-dribbble',
    label: 'Dribbble',
  },
  {
    value: 'fa fa-skype',
    label: 'Skype',
  },
  {
    value: 'fa fa-foursquare',
    label: 'Foursquare',
  },
  {
    value: 'fa fa-trello',
    label: 'Trello',
  },
  {
    value: 'fa fa-female',
    label: 'Female',
  },
  {
    value: 'fa fa-male',
    label: 'Male',
  },
  {
    value: 'fa fa-gratipay',
    label: 'Gratipay',
  },
  {
    value: 'fa fa-sun-o',
    label: 'Sun',
  },
  {
    value: 'fa fa-moon-o',
    label: 'Moon',
  },
  {
    value: 'fa fa-archive',
    label: 'Archive',
  },
  {
    value: 'fa fa-bug',
    label: 'Bug',
  },
  {
    value: 'fa fa-vk',
    label: 'Vk',
  },
  {
    value: 'fa fa-weibo',
    label: 'Weibo',
  },
  {
    value: 'fa fa-renren',
    label: 'Renren',
  },
  {
    value: 'fa fa-pagelines',
    label: 'Pagelines',
  },
  {
    value: 'fa fa-stack-exchange',
    label: 'Stack',
  },
  {
    value: 'fa fa-arrow-circle-o-right',
    label: 'Arrow',
  },
  {
    value: 'fa fa-arrow-circle-o-left',
    label: 'Arrow',
  },
  {
    value: 'fa fa-caret-square-o-left',
    label: 'Caret',
  },
  {
    value: 'fa fa-dot-circle-o',
    label: 'Dot',
  },
  {
    value: 'fa fa-wheelchair',
    label: 'Wheelchair',
  },
  {
    value: 'fa fa-vimeo-square',
    label: 'Vimeo',
  },
  {
    value: 'fa fa-try',
    label: 'Try',
  },
  {
    value: 'fa fa-plus-square-o',
    label: 'Plus',
  },
  {
    value: 'fa fa-space-shuttle',
    label: 'Space',
  },
  {
    value: 'fa fa-slack',
    label: 'Slack',
  },
  {
    value: 'fa fa-envelope-square',
    label: 'Envelope',
  },
  {
    value: 'fa fa-wordpress',
    label: 'Wordpress',
  },
  {
    value: 'fa fa-openid',
    label: 'Openid',
  },
  {
    value: 'fa fa-university',
    label: 'University',
  },
  {
    value: 'fa fa-graduation-cap',
    label: 'Graduation',
  },
  {
    value: 'fa fa-yahoo',
    label: 'Yahoo',
  },
  {
    value: 'fa fa-google',
    label: 'Google',
  },
  {
    value: 'fa fa-reddit',
    label: 'Reddit',
  },
  {
    value: 'fa fa-reddit-square',
    label: 'Reddit',
  },
  {
    value: 'fa fa-stumbleupon-circle',
    label: 'Stumbleupon',
  },
  {
    value: 'fa fa-stumbleupon',
    label: 'Stumbleupon',
  },
  {
    value: 'fa fa-delicious',
    label: 'Delicious',
  },
  {
    value: 'fa fa-digg',
    label: 'Digg',
  },
  {
    value: 'fa fa-pied-piper',
    label: 'Pied',
  },
  {
    value: 'fa fa-pied-piper-alt',
    label: 'Pied',
  },
  {
    value: 'fa fa-drupal',
    label: 'Drupal',
  },
  {
    value: 'fa fa-joomla',
    label: 'Joomla',
  },
  {
    value: 'fa fa-language',
    label: 'Language',
  },
  {
    value: 'fa fa-fax',
    label: 'Fax',
  },
  {
    value: 'fa fa-building',
    label: 'Building',
  },
  {
    value: 'fa fa-child',
    label: 'Child',
  },
  {
    value: 'fa fa-paw',
    label: 'Paw',
  },
  {
    value: 'fa fa-spoon',
    label: 'Spoon',
  },
  {
    value: 'fa fa-cube',
    label: 'Cube',
  },
  {
    value: 'fa fa-cubes',
    label: 'Cubes',
  },
  {
    value: 'fa fa-behance',
    label: 'Behance',
  },
  {
    value: 'fa fa-behance-square',
    label: 'Behance',
  },
  {
    value: 'fa fa-steam',
    label: 'Steam',
  },
  {
    value: 'fa fa-steam-square',
    label: 'Steam',
  },
  {
    value: 'fa fa-recycle',
    label: 'Recycle',
  },
  {
    value: 'fa fa-car',
    label: 'Car',
  },
  {
    value: 'fa fa-taxi',
    label: 'Taxi',
  },
  {
    value: 'fa fa-tree',
    label: 'Tree',
  },
  {
    value: 'fa fa-spotify',
    label: 'Spotify',
  },
  {
    value: 'fa fa-deviantart',
    label: 'Deviantart',
  },
  {
    value: 'fa fa-soundcloud',
    label: 'Soundcloud',
  },
  {
    value: 'fa fa-database',
    label: 'Database',
  },
  {
    value: 'fa fa-file-pdf-o',
    label: 'File',
  },
  {
    value: 'fa fa-file-word-o',
    label: 'File',
  },
  {
    value: 'fa fa-file-excel-o',
    label: 'File',
  },
  {
    value: 'fa fa-file-powerpoint-o',
    label: 'File',
  },
  {
    value: 'fa fa-file-image-o',
    label: 'File',
  },
  {
    value: 'fa fa-file-archive-o',
    label: 'File',
  },
  {
    value: 'fa fa-file-audio-o',
    label: 'File',
  },
  {
    value: 'fa fa-file-video-o',
    label: 'File',
  },
  {
    value: 'fa fa-file-code-o',
    label: 'File',
  },
  {
    value: 'fa fa-vine',
    label: 'Vine',
  },
  {
    value: 'fa fa-codepen',
    label: 'Codepen',
  },
  {
    value: 'fa fa-jsfiddle',
    label: 'Jsfiddle',
  },
  {
    value: 'fa fa-life-ring',
    label: 'Life',
  },
  {
    value: 'fa fa-circle-o-notch',
    label: 'Circle',
  },
  {
    value: 'fa fa-rebel',
    label: 'Rebel',
  },
  {
    value: 'fa fa-empire',
    label: 'Empire',
  },
  {
    value: 'fa fa-git-square',
    label: 'Git',
  },
  {
    value: 'fa fa-git',
    label: 'Git',
  },
  {
    value: 'fa fa-hacker-news',
    label: 'Hacker',
  },
  {
    value: 'fa fa-tencent-weibo',
    label: 'Tencent',
  },
  {
    value: 'fa fa-qq',
    label: 'Qq',
  },
  {
    value: 'fa fa-weixin',
    label: 'Weixin',
  },
  {
    value: 'fa fa-paper-plane',
    label: 'Paper',
  },
  {
    value: 'fa fa-paper-plane-o',
    label: 'Paper',
  },
  {
    value: 'fa fa-history',
    label: 'History',
  },
  {
    value: 'fa fa-circle-thin',
    label: 'Circle',
  },
  {
    value: 'fa fa-header',
    label: 'Header',
  },
  {
    value: 'fa fa-paragraph',
    label: 'Paragraph',
  },
  {
    value: 'fa fa-sliders',
    label: 'Sliders',
  },
  {
    value: 'fa fa-share-alt',
    label: 'Share',
  },
  {
    value: 'fa fa-share-alt-square',
    label: 'Share',
  },
  {
    value: 'fa fa-bomb',
    label: 'Bomb',
  },
  {
    value: 'fa fa-futbol-o',
    label: 'Futbol',
  },
  {
    value: 'fa fa-tty',
    label: 'Tty',
  },
  {
    value: 'fa fa-binoculars',
    label: 'Binoculars',
  },
  {
    value: 'fa fa-plug',
    label: 'Plug',
  },
  {
    value: 'fa fa-slideshare',
    label: 'Slideshare',
  },
  {
    value: 'fa fa-twitch',
    label: 'Twitch',
  },
  {
    value: 'fa fa-yelp',
    label: 'Yelp',
  },
  {
    value: 'fa fa-newspaper-o',
    label: 'Newspaper',
  },
  {
    value: 'fa fa-wifi',
    label: 'Wifi',
  },
  {
    value: 'fa fa-calculator',
    label: 'Calculator',
  },
  {
    value: 'fa fa-paypal',
    label: 'Paypal',
  },
  {
    value: 'fa fa-google-wallet',
    label: 'Google',
  },
  {
    value: 'fa fa-cc-visa',
    label: 'Cc',
  },
  {
    value: 'fa fa-cc-mastercard',
    label: 'Cc',
  },
  {
    value: 'fa fa-cc-discover',
    label: 'Cc',
  },
  {
    value: 'fa fa-cc-amex',
    label: 'Cc',
  },
  {
    value: 'fa fa-cc-paypal',
    label: 'Cc',
  },
  {
    value: 'fa fa-cc-stripe',
    label: 'Cc',
  },
  {
    value: 'fa fa-bell-slash',
    label: 'Bell',
  },
  {
    value: 'fa fa-bell-slash-o',
    label: 'Bell',
  },
  {
    value: 'fa fa-trash',
    label: 'Trash',
  },
  {
    value: 'fa fa-copyright',
    label: 'Copyright',
  },
  {
    value: 'fa fa-at',
    label: 'At',
  },
  {
    value: 'fa fa-eyedropper',
    label: 'Eyedropper',
  },
  {
    value: 'fa fa-paint-brush',
    label: 'Paint',
  },
  {
    value: 'fa fa-birthday-cake',
    label: 'Birthday',
  },
  {
    value: 'fa fa-area-chart',
    label: 'Area',
  },
  {
    value: 'fa fa-pie-chart',
    label: 'Pie',
  },
  {
    value: 'fa fa-line-chart',
    label: 'Line',
  },
  {
    value: 'fa fa-lastfm',
    label: 'Lastfm',
  },
  {
    value: 'fa fa-lastfm-square',
    label: 'Lastfm',
  },
  {
    value: 'fa fa-toggle-off',
    label: 'Toggle',
  },
  {
    value: 'fa fa-toggle-on',
    label: 'Toggle',
  },
  {
    value: 'fa fa-bicycle',
    label: 'Bicycle',
  },
  {
    value: 'fa fa-bus',
    label: 'Bus',
  },
  {
    value: 'fa fa-ioxhost',
    label: 'Ioxhost',
  },
  {
    value: 'fa fa-angellist',
    label: 'Angellist',
  },
  {
    value: 'fa fa-cc',
    label: 'Cc',
  },
  {
    value: 'fa fa-ils',
    label: 'Ils',
  },
  {
    value: 'fa fa-meanpath',
    label: 'Meanpath',
  },
  {
    value: 'fa fa-buysellads',
    label: 'Buysellads',
  },
  {
    value: 'fa fa-connectdevelop',
    label: 'Connectdevelop',
  },
  {
    value: 'fa fa-dashcube',
    label: 'Dashcube',
  },
  {
    value: 'fa fa-forumbee',
    label: 'Forumbee',
  },
  {
    value: 'fa fa-leanpub',
    label: 'Leanpub',
  },
  {
    value: 'fa fa-sellsy',
    label: 'Sellsy',
  },
  {
    value: 'fa fa-shirtsinbulk',
    label: 'Shirtsinbulk',
  },
  {
    value: 'fa fa-simplybuilt',
    label: 'Simplybuilt',
  },
  {
    value: 'fa fa-skyatlas',
    label: 'Skyatlas',
  },
  {
    value: 'fa fa-cart-plus',
    label: 'Cart',
  },
  {
    value: 'fa fa-cart-arrow-down',
    label: 'Cart',
  },
  {
    value: 'fa fa-diamond',
    label: 'Diamond',
  },
  {
    value: 'fa fa-ship',
    label: 'Ship',
  },
  {
    value: 'fa fa-user-secret',
    label: 'User',
  },
  {
    value: 'fa fa-motorcycle',
    label: 'Motorcycle',
  },
  {
    value: 'fa fa-street-view',
    label: 'Street',
  },
  {
    value: 'fa fa-heartbeat',
    label: 'Heartbeat',
  },
  {
    value: 'fa fa-venus',
    label: 'Venus',
  },
  {
    value: 'fa fa-mars',
    label: 'Mars',
  },
  {
    value: 'fa fa-mercury',
    label: 'Mercury',
  },
  {
    value: 'fa fa-transgender',
    label: 'Transgender',
  },
  {
    value: 'fa fa-transgender-alt',
    label: 'Transgender',
  },
  {
    value: 'fa fa-venus-double',
    label: 'Venus',
  },
  {
    value: 'fa fa-mars-double',
    label: 'Mars',
  },
  {
    value: 'fa fa-venus-mars',
    label: 'Venus',
  },
  {
    value: 'fa fa-mars-stroke',
    label: 'Mars',
  },
  {
    value: 'fa fa-mars-stroke-v',
    label: 'Mars',
  },
  {
    value: 'fa fa-mars-stroke-h',
    label: 'Mars',
  },
  {
    value: 'fa fa-neuter',
    label: 'Neuter',
  },
  {
    value: 'fa fa-facebook-official',
    label: 'Facebook',
  },
  {
    value: 'fa fa-pinterest-p',
    label: 'Pinterest',
  },
  {
    value: 'fa fa-whatsapp',
    label: 'Whatsapp',
  },
  {
    value: 'fa fa-server',
    label: 'Server',
  },
  {
    value: 'fa fa-user-plus',
    label: 'User',
  },
  {
    value: 'fa fa-user-times',
    label: 'User',
  },
  {
    value: 'fa fa-bed',
    label: 'Bed',
  },
  {
    value: 'fa fa-viacoin',
    label: 'Viacoin',
  },
  {
    value: 'fa fa-train',
    label: 'Train',
  },
  {
    value: 'fa fa-subway',
    label: 'Subway',
  },
  {
    value: 'fa fa-medium',
    label: 'Medium',
  },
];
