import { map } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PlaylistsSortField from '../../../api/endpoints/playlists/enums/playlistsSortField.enum';
import SortOrder from '../../../enums/sortOrder.enum';
import PlaylistCells from '../../../enums/tableCells/playlistCells.enum';
import Playlist from '../../../interfaces/playlist.interface';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../utils/hooks/useTableMultiSelect';
import { readFromQueryString } from '../../../utils/queryParams';
import Table from '../../Table';
import Columns, { TableHeaderColumn } from '../../Table/Columns';
import WithSpinner from '../../WithSpinner';
import TableRow from './TableRow';
import UpdatePlaylistRequestData from '../../../api/endpoints/playlists/interfaces/updatePlaylistRequestData.interface';

interface TableViewProps {
  playlists: Playlist[];
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight?: number;
  isSelectable?: boolean;
  onCopyPlaylist: () => void;
  multiSelectConfig?: TableMultiSelectConfig;
  unAssignPlaylistFromDeviceGroup?: (playlistId: string, id: string) => void;
  onEditPlaylist?: (id: string, data: UpdatePlaylistRequestData) => void;
}

interface DefaultProps extends TableViewProps {
  multiSelectConfig: Required<TableViewProps>['multiSelectConfig'];
}

export default ({
  playlists,
  isLoading,
  sortQueryField,
  orderQueryField,
  unAssignPlaylistFromDeviceGroup,
  onEditPlaylist,
  preloaderHeight = 300,
  isSelectable = false,
  onCopyPlaylist,
  multiSelectConfig: { selectedItems, setSelectedItem, selectAll },
}: DefaultProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  const columns: TableHeaderColumn<PlaylistsSortField>[] = [
    {
      name: t('playlists.playlist_title'),
      sortBy: PlaylistsSortField.Name,
    },
    {
      name: t('devices.devices'),
      sortBy: PlaylistsSortField.Devices,
    },
    {
      name: t('playlists.priority'),
    },
    {
      name: t('playlists.start_date'),
    },
    {
      name: t('playlists.end_date'),
    },
    {
      name: t('playlists.status'),
    },
    {
      name: t('playlists.last_update'),
      sortBy: PlaylistsSortField.LastUpdate,
    },
    {
      name: t('common.tag'),
    },
    { name: t('common.actions') },
  ];

  const isSelectableValue = playlists.length > 1 ? isSelectable : false;

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns<PlaylistsSortField>
            onChange={selectAll}
            columns={columns}
            checked={isAllSelected(selectedItems, map(playlists, 'id'))}
            config={{
              isSelectable: isSelectableValue,
              sortField,
              setSortField,
              sortQueryField,
              orderQueryField,
            }}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={!isLoading && !playlists.length}
        emptyStateChildren={t('playlists.no_playlists')}
        emptyStateHeight={preloaderHeight}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {playlists.map((playlist) => (
            <TableRow
              key={playlist.id}
              playlist={playlist}
              config={{
                isSelectable: isSelectableValue,
                skipColumns: [PlaylistCells.Owner],
              }}
              isChecked={selectedItems.includes(playlist.id)}
              onCheck={setSelectedItem}
              unAssignPlaylistFromDeviceGroup={unAssignPlaylistFromDeviceGroup}
              onEditPlaylist={onEditPlaylist}
              onCopyPlaylist={onCopyPlaylist}
            />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
