import React from 'react';
import { useTranslation } from 'react-i18next';
import AddUserSteps from '../../../../enums/steps/addUserSteps.enum';
import CreateUserRequestData from '../../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import AssignmentTables from './AssignmentTables';
import StepsTitle from '../StepsTitle';
import Modal from '../../../Modal';
import User from '../../../../interfaces/user/user.interface';

interface AssignProps {
  onSubmit?: (data: Partial<CreateUserRequestData>) => void;
  setMode: (mode: AddUserSteps) => void;
  data: Partial<CreateUserRequestData>;
  setData: (newData: Partial<CreateUserRequestData>) => void;
  currentUser?: User;
  isEdit?: boolean;
}

interface DefaultProps extends AssignProps {
  onSubmit: Required<AssignProps>['onSubmit'];
}

const Info = ({
  onSubmit,
  setMode,
  data,
  setData,
  isEdit,
  currentUser,
}: DefaultProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StepsTitle currentStep={AddUserSteps.Assign} />
      <AssignmentTables
        data={data}
        setData={setData}
        currentUser={currentUser}
      />
      <Modal.Separator withoutDefaultMargins className="mb-7" />
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-white text-primary"
          onClick={() => setMode(AddUserSteps.Info)}
        >
          {t('common.prev_step')}
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => onSubmit(data)}
        >
          {t(isEdit ? 'users.update' : 'users.create')}
        </button>
      </div>
    </>
  );
};

Info.defaultProps = {
  onSubmit: () => null,
};

export default Info;
