export default [
  {
    rssFeedID: 43,
    source: '',
    rssCategoryID: 1,
    value: 'https://https://www.cnbc.com/id/100727362/device/rss/rss.html',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 150,
    active: 1,
    rssCategoryID__1: 1,
    categoryName: 'User Defined',
  },
  {
    rssFeedID: 44,
    source: '',
    rssCategoryID: 1,
    value:
      'https://www.msn.com/en-us/money/stockdetails/fi-a25y77?ocid=ansMSNMoney11&duration=1D',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 235,
    active: 1,
    rssCategoryID__1: 1,
    categoryName: 'User Defined',
  },
  {
    rssFeedID: 45,
    source: '',
    rssCategoryID: 1,
    value:
      'https://www.msn.com/en-us/money/stockdetails/fi-a25y77?ocid=ansMSNMoney11&duration=1D',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 235,
    active: 1,
    rssCategoryID__1: 1,
    categoryName: 'User Defined',
  },
  {
    rssFeedID: 46,
    source: '',
    rssCategoryID: 1,
    value:
      'https://www.msn.com/en-us/money/stockdetails/fi-a25y77?ocid=ansMSNMoney11&duration=1D',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 235,
    active: 1,
    rssCategoryID__1: 1,
    categoryName: 'User Defined',
  },
  {
    rssFeedID: 47,
    source: '',
    rssCategoryID: 1,
    value: 'https://www.marketwatch.com/investing/stock/wms?mod=quote_search',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 235,
    active: 1,
    rssCategoryID__1: 1,
    categoryName: 'User Defined',
  },
  {
    rssFeedID: 48,
    source: '',
    rssCategoryID: 1,
    value:
      'https://freshpet.kminnovations.net/Apps/Dashboards/Dashboard.aspx?DashboardId=96&LocationId=24&Editable=true',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 264,
    active: 1,
    rssCategoryID__1: 1,
    categoryName: 'User Defined',
  },
  {
    rssFeedID: 49,
    source: '',
    rssCategoryID: 1,
    value:
      'https://freshpet.kminnovations.net/Apps/Dashboards/Dashboard.aspx?DashboardId=96&LocationId=24&Editable=true',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 264,
    active: 1,
    rssCategoryID__1: 1,
    categoryName: 'User Defined',
  },
  {
    rssFeedID: 50,
    source: '',
    rssCategoryID: 1,
    value:
      'https://freshpet.kminnovations.net/Apps/Dashboards/Dashboard.aspx?DashboardId=96&LocationId=24&Editable=true',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 264,
    active: 1,
    rssCategoryID__1: 1,
    categoryName: 'User Defined',
  },
  {
    rssFeedID: 56,
    source: '',
    rssCategoryID: 1,
    value: 'https://memebase.cheezburger.com/rss',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 127,
    active: 1,
    rssCategoryID__1: 1,
    categoryName: 'User Defined',
  },
  {
    rssFeedID: 1,
    source: 'ABC News',
    rssCategoryID: 2,
    value: 'https://abcnews.go.com/abcnews/topstories',
    label: 'ABC News: Top Stories',
    imageURL: 'https://s.abcnews.com/images/site/abcnews_google_rss_logo.png',
    refreshRateMin: 30,
    lastUpdated: '03:25.0',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 2,
    categoryName: 'Top Stories',
  },
  {
    rssFeedID: 40,
    source: 'CBS News',
    rssCategoryID: 2,
    value: 'https://www.cbsnews.com/latest/rss/main?40',
    label: 'Home - CBSNews.com',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: '05:05.5',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 2,
    categoryName: 'Top Stories',
  },
  {
    rssFeedID: 41,
    source: 'CNBC',
    rssCategoryID: 2,
    value: 'https://www.cnbc.com/id/100003114/device/rss/rss.html',
    label: 'US Top News and Analysis',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: '00:18.9',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 2,
    categoryName: 'Top Stories',
  },
  {
    rssFeedID: 39,
    source: 'CNN',
    rssCategoryID: 2,
    value: 'http://rss.cnn.com/rss/cnn_topstories.rss',
    label: 'CNN.com - RSS Channel - HP Hero',
    imageURL: 'http://i2.cdn.turner.com/cnn/2015/images/09/24/cnn.digital.png',
    refreshRateMin: 30,
    lastUpdated: '02:44.4',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 2,
    categoryName: 'Top Stories',
  },
  {
    rssFeedID: 42,
    source: 'New York Times',
    rssCategoryID: 2,
    value:
      'https://www.nytimes.com/svc/collections/v1/publish/https://www.nytimes.com/section/world/rss.xml',
    label: 'NYT > World News',
    imageURL: 'https://static01.nyt.com/images/misc/NYT_logo_rss_250x40.png',
    refreshRateMin: 30,
    lastUpdated: '05:15.9',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 2,
    categoryName: 'Top Stories',
  },
  {
    rssFeedID: 2,
    source: 'ABC News',
    rssCategoryID: 3,
    value: 'https://abcnews.go.com/abcnews/internationalheadlines',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 3,
    categoryName: 'International News',
  },
  {
    rssFeedID: 37,
    source: 'BBC',
    rssCategoryID: 3,
    value: 'http://feeds.bbci.co.uk/news/video_and_audio/world/rss.xml#',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 3,
    categoryName: 'International News',
  },
  {
    rssFeedID: 36,
    source: 'CNBC',
    rssCategoryID: 3,
    value: 'https://www.cnbc.com/id/100727362/device/rss/rss.html',
    label: 'International: Top News And Analysis',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: '00:03.6',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 3,
    categoryName: 'International News',
  },
  {
    rssFeedID: 38,
    source: 'Reddit',
    rssCategoryID: 3,
    value: 'https://www.reddit.com/r/worldnews/.rss',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 3,
    categoryName: 'International News',
  },
  {
    rssFeedID: 3,
    source: 'ABC News',
    rssCategoryID: 4,
    value: 'https://abcnews.go.com/abcnews/usheadlines',
    label: 'ABC News: US',
    imageURL: 'https://s.abcnews.com/images/site/abcnews_google_rss_logo.png',
    refreshRateMin: 30,
    lastUpdated: '00:54.6',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 4,
    categoryName: 'U.S. News',
  },
  {
    rssFeedID: 27,
    source: 'CBS News',
    rssCategoryID: 4,
    value: 'https://www.cbsnews.com/latest/rss/main',
    label: 'Home - CBSNews.com',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: '00:56.7',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 4,
    categoryName: 'U.S. News',
  },
  {
    rssFeedID: 26,
    source: 'CNBC',
    rssCategoryID: 4,
    value: 'https://www.cnbc.com/id/15837362/device/rss/rss.html',
    label: 'U.S. News',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: '00:51.8',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 4,
    categoryName: 'U.S. News',
  },
  {
    rssFeedID: 30,
    source: 'CNN',
    rssCategoryID: 4,
    value: 'http://rss.cnn.com/rss/cnn_us.rss',
    label: 'CNN.com - RSS Channel - US',
    imageURL: 'http://i2.cdn.turner.com/cnn/2015/images/09/24/cnn.digital.png',
    refreshRateMin: 30,
    lastUpdated: '00:39.7',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 4,
    categoryName: 'U.S. News',
  },
  {
    rssFeedID: 28,
    source: 'NBC News',
    rssCategoryID: 4,
    value: 'http://feeds.nbcnews.com/nbcnews/public/news',
    label: 'NBC News Top Stories',
    imageURL:
      'https://media1.s-nbcnews.com/i/MSNBC/Components/Media/NBC-promo-graphics/nbc-news.png',
    refreshRateMin: 30,
    lastUpdated: '01:28.9',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 4,
    categoryName: 'U.S. News',
  },
  {
    rssFeedID: 29,
    source: 'New York Times',
    rssCategoryID: 4,
    value: 'https://rss.nytimes.com/services/xml/rss/nyt/US.xml',
    label: 'NYT > U.S. News',
    imageURL: 'https://static01.nyt.com/images/misc/NYT_logo_rss_250x40.png',
    refreshRateMin: 30,
    lastUpdated: '00:57.7',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 4,
    categoryName: 'U.S. News',
  },
  {
    rssFeedID: 32,
    source: 'Reuters',
    rssCategoryID: 4,
    value: 'http://feeds.reuters.com/Reuters/domesticNews',
    label: 'Hvper News',
    imageURL: 'http://www.hvper.com/static/favicon/favicon-96x96.png',
    refreshRateMin: 30,
    lastUpdated: '00:47.4',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 4,
    categoryName: 'U.S. News',
  },
  {
    rssFeedID: 31,
    source: 'USA Today',
    rssCategoryID: 4,
    value: 'http://rssfeeds.usatoday.com/usatodaycomnation-topstories&x=1',
    label: 'GANNETT Syndication Service',
    imageURL:
      'https://www.gannett-cdn.com/sites/usatnetwork/images/RSS_Syndication_Logo-USATN.png',
    refreshRateMin: 30,
    lastUpdated: '00:59.2',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 4,
    categoryName: 'U.S. News',
  },
  {
    rssFeedID: 4,
    source: 'ABC News',
    rssCategoryID: 5,
    value: 'https://abcnews.go.com/abcnews/sportsheadlines',
    label: 'ABC News: Sports',
    imageURL: 'https://s.abcnews.com/images/site/abcnews_google_rss_logo.png',
    refreshRateMin: 30,
    lastUpdated: '09:34.5',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 10,
    source: 'ESPN',
    rssCategoryID: 5,
    value: 'https://www.espn.com/espn/rss/news',
    label: 'www.espn.com - TOP',
    imageURL:
      'https://a.espncdn.com/i/espn/teamlogos/lrg/trans/espn_dotcom_black.gif',
    refreshRateMin: 30,
    lastUpdated: '04:06.2',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 18,
    source: 'ESPN - College Basketball',
    rssCategoryID: 5,
    value: 'https://www.espn.com/espn/rss/ncb/news',
    label: 'www.espn.com - NCB',
    imageURL:
      'https://a.espncdn.com/i/espn/teamlogos/lrg/trans/espn_dotcom_black.gif',
    refreshRateMin: 30,
    lastUpdated: '09:00.1',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 17,
    source: 'ESPN - College Football',
    rssCategoryID: 5,
    value: 'https://www.espn.com/espn/rss/ncf/news',
    label: 'www.espn.com - NCF',
    imageURL:
      'https://a.espncdn.com/i/espn/teamlogos/lrg/trans/espn_dotcom_black.gif',
    refreshRateMin: 30,
    lastUpdated: '08:44.2',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 13,
    source: 'ESPN - MLB',
    rssCategoryID: 5,
    value: 'https://www.espn.com/espn/rss/mlb/news',
    label: 'www.espn.com - MLB',
    imageURL:
      'https://a.espncdn.com/i/espn/teamlogos/lrg/trans/espn_dotcom_black.gif',
    refreshRateMin: 30,
    lastUpdated: '07:32.0',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 12,
    source: 'ESPN - NBA',
    rssCategoryID: 5,
    value: 'https://www.espn.com/espn/rss/nba/news',
    label: 'www.espn.com - NBA',
    imageURL:
      'https://a.espncdn.com/i/espn/teamlogos/lrg/trans/espn_dotcom_black.gif',
    refreshRateMin: 30,
    lastUpdated: '07:10.5',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 11,
    source: 'ESPN - NFL',
    rssCategoryID: 5,
    value: 'https://www.espn.com/espn/rss/nfl/news',
    label: 'www.espn.com - NFL',
    imageURL:
      'https://a.espncdn.com/i/espn/teamlogos/lrg/trans/espn_dotcom_black.gif',
    refreshRateMin: 30,
    lastUpdated: '06:56.3',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 14,
    source: 'ESPN - NHL',
    rssCategoryID: 5,
    value: 'https://www.espn.com/espn/rss/nhl/news',
    label: 'www.espn.com - NHL',
    imageURL:
      'https://a.espncdn.com/i/espn/teamlogos/lrg/trans/espn_dotcom_black.gif',
    refreshRateMin: 30,
    lastUpdated: '07:44.4',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 15,
    source: 'ESPN - Soccer',
    rssCategoryID: 5,
    value: 'https://www.espn.com/espn/rss/soccer/news',
    label: 'www.espn.com - SOCCER',
    imageURL:
      'https://a.espncdn.com/i/espn/teamlogos/lrg/trans/espn_dotcom_black.gif',
    refreshRateMin: 30,
    lastUpdated: '08:02.8',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 16,
    source: 'ESPNU',
    rssCategoryID: 5,
    value: 'https://www.espn.com/espn/rss/espnu/news',
    label: 'www.espn.com - NCAA',
    imageURL:
      'https://a.espncdn.com/i/espn/teamlogos/lrg/trans/espn_dotcom_black.gif',
    refreshRateMin: 30,
    lastUpdated: '08:23.2',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 19,
    source: 'Fox Sports',
    rssCategoryID: 5,
    value: 'https://api.foxsports.com/v1/rss',
    label: 'FOX Sports Digital - Warriors',
    imageURL:
      'http://www.foxsports.com/wp-content/themes/foxsports/assets/images/60x60.png',
    refreshRateMin: 30,
    lastUpdated: '09:47.7',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 23,
    source: 'Fox Sports - College Basketball',
    rssCategoryID: 5,
    value: 'https://api.foxsports.com/v1/rss?tag=cbk',
    label: 'FOX Sports Digital - NCAA',
    imageURL:
      'http://www.foxsports.com/wp-content/themes/foxsports/assets/images/60x60.png',
    refreshRateMin: 30,
    lastUpdated: '10:28.6',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 22,
    source: 'Fox Sports - College Football',
    rssCategoryID: 5,
    value: 'https://api.foxsports.com/v1/rss?tag=cfb',
    label: 'FOX Sports Digital - Arkansas',
    imageURL:
      'http://www.foxsports.com/wp-content/themes/foxsports/assets/images/60x60.png',
    refreshRateMin: 30,
    lastUpdated: '10:18.0',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 20,
    source: 'Fox Sports - MLB',
    rssCategoryID: 5,
    value: 'https://api.foxsports.com/v1/rss?tag=mlb',
    label: 'FOX Sports Digital - Gerrit ',
    imageURL:
      'http://www.foxsports.com/wp-content/themes/foxsports/assets/images/60x60.png',
    refreshRateMin: 30,
    lastUpdated: '09:57.8',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 24,
    source: 'Fox Sports - NBA',
    rssCategoryID: 5,
    value: 'https://api.foxsports.com/v1/rss?tag=nba',
    label: 'FOX Sports Digital - SHAY ',
    imageURL:
      'http://www.foxsports.com/wp-content/themes/foxsports/assets/images/60x60.png',
    refreshRateMin: 30,
    lastUpdated: '10:39.3',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 21,
    source: 'Fox Sports - NFL',
    rssCategoryID: 5,
    value: 'https://api.foxsports.com/v1/rss?tag=nfl',
    label: 'FOX Sports Digital - Tom Brady',
    imageURL:
      'http://www.foxsports.com/wp-content/themes/foxsports/assets/images/60x60.png',
    refreshRateMin: 30,
    lastUpdated: '10:08.2',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 25,
    source: 'Fox Sports - NHL',
    rssCategoryID: 5,
    value: 'https://api.foxsports.com/v1/rss?tag=nhl',
    label: 'FOX Sports Digital - THE HERD',
    imageURL:
      'http://www.foxsports.com/wp-content/themes/foxsports/assets/images/60x60.png',
    refreshRateMin: 30,
    lastUpdated: '10:49.2',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 5,
    categoryName: 'Sports',
  },
  {
    rssFeedID: 5,
    source: 'ABC News',
    rssCategoryID: 6,
    value: 'https://abcnews.go.com/abcnews/entertainmentheadlines',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 6,
    categoryName: 'Entertainment',
  },
  {
    rssFeedID: 35,
    source: 'CNN',
    rssCategoryID: 6,
    value: 'http://rss.cnn.com/rss/cnn_showbiz.rss',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 6,
    categoryName: 'Entertainment',
  },
  {
    rssFeedID: 34,
    source: 'E! Online',
    rssCategoryID: 6,
    value:
      'http://syndication.eonline.com/syndication/feeds/rssfeeds/topstories.xml',
    label: 'E! Online (US) - Top Stories',
    imageURL:
      'https://images.eonline.com/eol_images/Entire_Site/2018223/rs_125x40-180323122205-EGoogleNews.png',
    refreshRateMin: 30,
    lastUpdated: '09:19.4',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 6,
    categoryName: 'Entertainment',
  },
  {
    rssFeedID: 33,
    source: 'Entertainment Tonight',
    rssCategoryID: 6,
    value: 'https://www.etonline.com/rss',
    label: 'NULL',
    imageURL: 'NULL',
    refreshRateMin: 30,
    lastUpdated: 'NULL',
    userID: 'NULL',
    active: 1,
    rssCategoryID__1: 6,
    categoryName: 'Entertainment',
  },
];
