/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Image } from 'antd';
import api from '../../../api';
import Layout from '../../../interfaces/layoutTemplate.interface';
import WithSpinner from '../../../components/WithSpinner';
import styles from './layout.module.scss';

const SelectLayout = ({
  initialValue,
  onChange,
}: {
  initialValue: string;
  onChange: ({ id, preview }: { id: string; preview: string }) => void;
}) => {
  const [layouts, setLayouts] = useState<Layout[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getLayouts = async () => {
    try {
      setIsLoading(true);

      const { items } = await api.layouts.getLayouts({});

      setLayouts(items.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (e) {
      // Do nothing for now
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getLayouts();
  }, []);

  // const previewProps = async () => {
  //   const icons = document.getElementsByClassName(
  //     'ant-image-preview-operations-operation',
  //   );
  //   if (icons) {
  //     console.log('icons', icons[0]);
  //     // Array.prototype.map.call(icons, (icon) => {
  //     //   console.log(icon, 'icon');
  //     //   (icon as HTMLElement).style.display = 'none';
  //     // });

  //     (icons[0] as HTMLElement).style.display = 'none';
  //   }
  // };

  return (
    <WithSpinner isLoading={isLoading} className="my-5" size="md">
      <div className="d-flex">
        {layouts.map(({ name, preview, id }) => (
          <div key={id} className="d-flex flex-column me-3">
            <div role="none" style={{ cursor: 'zoom-in' }}>
              {/* <img
                  src={preview}
                  alt="preview"
                  className={clsx('w-100', styles)}
                /> */}

              <Image
                // onClick={() => {
                //   previewProps();
                // }}
                className={clsx('w-100', styles.preview)}
                src={preview}
              />
            </div>

            <div className="d-flex form-check form-check-inline form-check-solid mt-4">
              <input
                name={name}
                className="form-check-input me-3"
                value={name}
                type="radio"
                checked={id === initialValue}
                onChange={() => onChange({ id, preview })}
              />
              <div style={{ fontSize: '12px' }}>{name}</div>
            </div>
          </div>
        ))}
      </div>
      {/* 
      <ModalLayoutTemplate
        open={open}
        layout={selectedLayout}
        onClose={(val) => setIsOpen(val)}
      /> */}

      {/* <PreviewTemplatesModal url={selectedLayout}>
        <img
          src={selectedLayout}
          alt="preview"
          className={clsx(
            'h-30px min-w-50px',
            // styles.preview,
            // commonStyles.gridBg,
          )}
        /> */}
    </WithSpinner>
  );
};

export default SelectLayout;
