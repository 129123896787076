import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import SelectAsync, {
  SelectedValue,
} from '../../components/Select/SelectAsync';

/**
 * Retrieves the select options asynchronously
 * @param search
 */
const loadOptions = async (search: string): Promise<SelectedValue[]> => {
  try {
    const items = await api.licenses.getLicenses({ search });

    return sortBy(items, ['name']).map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  } catch (e) {
    return [];
  }
};

const SelectLicenseType = ({
  onChange,
  initialValue,
  id,
  className,
  isClearable,
  disabled,
}: {
  onChange?: (selectedValue: SelectedValue) => void;
  initialValue?: string;
  id?: string;
  className?: string;
  isClearable?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<SelectedValue>();

  useEffect(() => {
    if (!initialValue) return;

    setSelectedValue({
      label: `${t('common.loading')}...`,
      value: initialValue,
    });

    setTimeout(async () => {
      try {
        const { name } = await api.licenses.getLicense(initialValue);

        setSelectedValue({ label: name, value: initialValue });
      } catch (e) {
        setSelectedValue({});
      }
    });
  }, []);

  return (
    <SelectAsync
      isDisabled={disabled}
      id={id}
      initialValue={selectedValue}
      onChange={onChange ?? (() => null)}
      loadOptions={loadOptions}
      className={className}
      placeholder={t('common.search')}
      isClearable={isClearable}
    />
  );
};

SelectLicenseType.defaultProps = {
  initialValue: null,
};

export default SelectLicenseType;
