import React from 'react';
import WithConfirm from '../WithConfirm';

export default ({
  children,
  handleConfirm,
}: {
  children: React.ReactNode;
  handleConfirm: () => void;
}) => {
  return (
    <WithConfirm
      handleConfirm={handleConfirm}
      body="Are you sure you want to quit “Login As” mode?"
      quit
      confirmButtonText="Yes, quit!"
      discardButtonText="No, cancel"
    >
      {children}
    </WithConfirm>
  );
};
