import axios from 'axios';
import { API_URL } from '../../../constants/api';
import AppsRoutes from './enums/appsRoutes.enum';
import CreateAppRequestData from './interfaces/createAppRequestData.interface';
import GetAppsListResponseData from './interfaces/getAppsResponseData.interface';
import GetAppsListRequestData from './interfaces/getAppsRequestData.interface';
import ScheduleContentRequestData from '../contents/interfaces/scheduleContentRequestData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import App from '../../../interfaces/app.interface';
import IContent from '../../../interfaces/content.interface';
import AppType from '../../../enums/appType.enum';
import Integration from '../../../interfaces/integration.interface';
import postUserIntegrationRequestData from '../userData/interfaces/postUserIntegrationRequestData.interface';
import UpdateFacebookRequestData from './interfaces/updateFacebookRequestData.interface';
import UpdateInstagramRequestData from './interfaces/updateInstagramRequestData.interface';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';

export default {
  /**
   * Retrieves the list of apps
   * @param appType
   * @param requestData
   */
  getApps: async (
    appType: string,
    requestData: GetAppsListRequestData,
  ): Promise<GetAppsListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${AppsRoutes.Apps}/${appType}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Create app
   * @param appType
   * @param requestData
   */
  createApp: async (
    appType: string,
    requestData: CreateAppRequestData,
  ): Promise<App> => {
    const { data } = await axios.post(
      `${API_URL}/${AppsRoutes.Apps}/${appType}`,
      requestData,
    );

    return data;
  },

  /**
   * Update app
   * @param appId
   * @param requestData
   */
  updateApp: async (
    appId: string,
    requestData: CreateAppRequestData,
  ): Promise<void> => {
    const { data } = await axios.patch(
      `${API_URL}/${AppsRoutes.Apps}/${appId}`,
      requestData,
    );

    return data;
  },

  /**
   * Delete app
   * @param appId
   */
  deleteApp: async (appId: string): Promise<void> => {
    const { data } = await axios.delete(
      `${API_URL}/${AppsRoutes.Apps}/${appId}`,
    );
    return data;
  },

  /**
   * Schedule app
   * @param appType
   * @param appId
   * @param requestData
   */
  scheduleApp: async (
    appType: string,
    appId: string,
    requestData: ScheduleContentRequestData,
  ): Promise<IContent> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${AppsRoutes.Apps}/${appType}/${appId}`,
      data: requestData,
    });

    return data;
  },

  /**
   * Disconnect app
   * @param payload
   */
  disconnectApp: async (
    payload: postUserIntegrationRequestData,
  ): Promise<Integration[]> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${AppType.Canva}/${AppsRoutes.RemoveIntegration}/${payload.appId}/${payload.userId}`,
    });

    return data;
  },

  /**
   * Update facebook Feeds
   * @param payload
   */
  updateFacebookFeeds: async (
    payload: UpdateFacebookRequestData,
  ): Promise<any> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${AppsRoutes.Apps}/updateFeeds/facebook`,
      data: payload,
    });

    return data;
  },

  /**
   * Update Ms teams Feeds
   * @param appId
   * @param requestData
   */
  updateMsTeamsFeeds: async (
    appId: string,
    requestData: any,
  ): Promise<void> => {
    const { data } = await axios.put(
      `${API_URL}/${AppsRoutes.MsTeams}/updateFeeds/${appId}`,
      requestData,
    );

    return data;
  },

  /**
   * Update facebook Feeds
   * @param payload
   */
  updateInstagramFeeds: async (
    payload: UpdateInstagramRequestData,
  ): Promise<any> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${AppsRoutes.Apps}/updateFeeds/instagram`,
      data: payload,
    });

    return data;
  },
  /**
   * get Apps Details Depends On AppType
   * @param appType
   */
  getAppsDetailsDependsOnAppType: async ({
    appType,
    requestData,
  }: {
    appType: string;
    requestData: {
      [PaginationQueryParam.Page]: number;
      [PaginationQueryParam.Limit]: number;
    };
  }): Promise<any> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/dashboard-ap/test-details/${appType}`,
      params: sanitizeQueryParams(requestData),
    });
    return data;
  },
};
