import React from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { prepareHookFromState } from '../../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../../FormErrorMessage';
import validation from '../../../../utils/validation';
import TextInput from '../../../TextInput';
import SelectCountry from '../../../../containers/SelectCountry';
import SelectCounty from '../../../../containers/SelectCounty';
import {
  ColourOption,
  colourOptions,
} from '../../../../containers/SelectColor';
import severeWeatherPic from '../../../../assets/images/severeWeathere/alert.png';
import { SelectedValue } from '../../../Select/SelectAsync';
import App from '../../../../interfaces/app.interface';
import Device from '../../../../interfaces/devices/device.interface';
import DeviceGroup from '../../../../interfaces/deviceGroup.interface';
import SelectDevices from '../../../../containers/Devices/SelectDevices';
import SelectDevicesGroups from '../../../../containers/SelectDevicesGroups';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import { severeWeatherEditValidation } from '../../../../validation/apps/severe-weather';
import County from '../../../../interfaces/county.interface';
import CountryCode from '../../../../enums/countryCode.enum';
import SelectState from '../../../../containers/SelectState';
import WithSpinner from '../../../WithSpinner';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

interface FormInputs {
  name: string;
  countryId: string;
  stateId: string;
  countyIds: SelectedValue[] | undefined;
  textColor: ColourOption;
  config: any;
  devices: SelectedValue[];
  devicesGroups: SelectedValue[];
}

export default ({
  severeWeather,
  onDecline,
  onSubmit,
  isLoading,
}: {
  severeWeather: App;
  onDecline: () => void;
  onSubmit: (data: CreateAppRequestData) => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const counties = severeWeather.dependency?.counties;

  const defaultStateValue = () => {
    if (counties?.length) {
      if (counties[0].state?.id) return counties[0].state?.id;
      return '';
    }
    if (severeWeather.dependency?.state?.id)
      return severeWeather.dependency?.state?.id;
    return '';
  };

  const defaultValues: Partial<FormInputs> = {
    name: severeWeather.name ?? '',
    countyIds:
      counties?.map((item: County) => ({
        label: item.name,
        value: item.id,
      })) ?? [],
    countryId: severeWeather.dependency?.country?.id ?? '',
    stateId: defaultStateValue(),
    devices:
      severeWeather.devices?.map((item: Device) => ({
        label: item.name,
        value: item.id,
      })) ?? [],
    devicesGroups:
      severeWeather.deviceGroups?.map((item: DeviceGroup) => ({
        label: item.name,
        value: item.id,
      })) ?? [],
    textColor:
      colourOptions.find(
        ({ color }) => color === severeWeather?.dependency?.config?.textColor,
      ) ?? colourOptions[0],
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      validation
        .object()
        .shape(severeWeatherEditValidation(t), [['devices', 'devicesGroups']])
        .required(),
    ),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [name, setName] = useFs<FormInputs['name']>('name');
  const [countyIds, setCountyIds] = useFs<FormInputs['countyIds']>('countyIds');
  const [countryId, setCountryId] = useFs<FormInputs['countryId'] | undefined>(
    'countryId',
  );

  const [stateId, setStateId] = useFs<FormInputs['stateId'] | undefined>(
    'stateId',
  );
  const [devices, setDevices] = useFs<FormInputs['devices']>('devices');
  const [deviceGroupIds, setDeviceGroupIds] =
    useFs<FormInputs['devicesGroups']>('devicesGroups');

  /**
   * Submits severe weather data
   */
  const handleSubmitData = () => {
    onSubmit({
      name,
      countryId,
      stateId,
      countyIds: (countyIds?.map((item) => item.value) as string[]) ?? [],
      deviceIds: (devices.map((item) => item.value) as string[]) ?? [],
      deviceGroupIds:
        (deviceGroupIds?.map((item) => item.value) as string[]) ?? [],
    });
  };

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="d-flex flex-column align-items-center">
        <img
          src={severeWeatherPic}
          alt="severe weather preview"
          className="w-100 mb-5"
        />
        <form onSubmit={handleSubmit(handleSubmitData)} className="w-400px">
          <label htmlFor="name" className="required fs-6 fw-bolder mb-3">
            {t('companies.name')}
          </label>
          <TextInput
            name="name"
            id="name"
            placeholder={t('companies.name')}
            value={name}
            onChange={setName}
            useDisabledStyles
          />
          <FormErrorMessage
            name="name"
            errors={errors}
            className={errorMessageClassNames}
          />

          <div className="mt-5">
            <label htmlFor="countryId" className="fs-6 fw-bolder mb-3">
              {t('apps.severeWeather.fields.country')}
            </label>
            <SelectCountry
              id="countryId"
              onChange={({ value }) => {
                setCountryId(value);
                setStateId(undefined);
                if (
                  value &&
                  value !== CountryCode.Us &&
                  value !== CountryCode.Ca
                )
                  setCountyIds(undefined);
              }}
              initialValue={countryId}
              preselect
              isClearable
            />
            <FormErrorMessage
              name="countryId"
              errors={errors}
              className={errorMessageClassNames}
            />
          </div>

          {countryId !== CountryCode.Ca && (
            <div className="mt-5">
              <label htmlFor="stateId" className="fs-6 fw-bolder mb-3">
                {t('apps.severeWeather.fields.state')}
              </label>
              <SelectState
                id="stateId"
                onChange={({ value }) => {
                  if (value) setStateId(value);
                }}
                countryId={countryId}
                initialValue={stateId}
                isClearable
              />
              <FormErrorMessage
                name="stateId"
                errors={errors}
                className={errorMessageClassNames}
              />
            </div>
          )}

          {(countryId === CountryCode.Ca || countryId === CountryCode.Us) && (
            <div className="mt-5">
              <label htmlFor="counties" className="fs-6 fw-bolder mb-3">
                {t('apps.severeWeather.fields.counties')}
              </label>
              <SelectCounty
                id="counties"
                name="counties"
                initialOption={countyIds}
                onChange={
                  setCountyIds as (value: SelectedValue | undefined) => void
                }
                countryCode={countryId}
                stateCode={stateId}
                isSevereWeather
                isClearable
                isMulti
              />
              <FormErrorMessage
                name="counties"
                errors={errors}
                className={errorMessageClassNames}
              />
            </div>
          )}

          <div className="mt-5">
            <label htmlFor="devices" className="form-label fw-boldest">
              {t('devices.devices')}
            </label>
            <SelectDevices
              initialValue={devices.map(({ value }) => value as string)}
              onChange={(selectedValue) => {
                setDevices(selectedValue);
              }}
              hideLicence
            />
            <FormErrorMessage
              name="devices"
              errors={errors}
              className={errorMessageClassNames}
            />
          </div>
          <div className="mt-5">
            <label htmlFor="devicesGroupsIds" className="form-label fw-boldest">
              {t('devices_groups.devices_groups')}
            </label>
            <SelectDevicesGroups
              initialValue={deviceGroupIds}
              onChange={(selectedValue) => {
                setDeviceGroupIds(selectedValue);
              }}
            />
            <FormErrorMessage
              name="devicesGroupsIds"
              errors={errors}
              className={errorMessageClassNames}
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onDecline}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {t('common.edit')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
