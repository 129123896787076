import clsx from 'clsx';
import { map } from 'lodash';
import React, {
  FC,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap-v5';
import DevicesQueryParam from '../../../api/endpoints/devices/enums/devicesQueryParam.enum';
import BulkEditContent from '../../../containers/Contents/BulkEditContent';
import UploadContent from '../../../containers/Aside/UploadContent';
import Orientation from '../../../enums/orientation.enum';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import IContent from '../../../interfaces/content.interface';
import IPlaylist from '../../../interfaces/playlist.interface';
import useTableMultiSelect from '../../../utils/hooks/useTableMultiSelect';
import Card from '../../Card';
import NavTabs from '../../NavTabs';
import Search from '../../Search/QueryParamSearch';
import Pagination from '../../Pagination/QueryParamPagination';
import FiltersDropdown from './FiltersDropdown';
import ContentTable from '../../Contents/ContentTable';
import { getPlaylistInfoRoute } from '../../../utils/playlists';
import PlaylistTabs from '../../../enums/playlistTabs.enum';
import RemoveContent from '../../../containers/Playlist/RemoveContent';
import ConversionJob from '../../../interfaces/conversionJob.interface';
import TimelineDropdown from './TimelineDropdown';
import BulkEditDropdown from './BulkEditDropdown';
import BulkCopyContent from '../../../containers/Playlist/BulkCopyContent';
import NavTab from '../../../interfaces/navTab.interface';
import { FileMimeType } from '../../../store/types/fileManager';
import Device from '../../../interfaces/devices/device.interface';

const options = [
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 1000, label: '1000' },
];

type PlaylistContentProps = {
  playlist: IPlaylist;
  isContentsLoading: boolean;
  contents: IContent[];
  total: number;
  orientation?: string;
  onEdit: () => void;
  devicesWithoutLicence?: Device[];
  conversionJobs?: ConversionJob[];
};

const PlaylistContent: FC<PlaylistContentProps> = ({
  playlist,
  isContentsLoading,
  total,
  contents,
  orientation = Orientation.Landscape,
  conversionJobs,
  onEdit,
  devicesWithoutLicence,
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(50);
  const [hasBothZones, setHasBothZones] = useState<boolean>(false);
  const contentsEndRef = useRef<HTMLSpanElement | null>(null);
  const [selectedItems, setSelectedItem, selectAll, reset] =
    useTableMultiSelect(map(contents, 'id'), []);
  const hasLandscapeConversionJob = conversionJobs?.find(
    (job) => job.orientation !== Orientation.Portrait,
  );
  const hasPortraitConversionJob = conversionJobs?.find(
    (job) => job.orientation === Orientation.Portrait,
  );

  const scrollToBottom: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();

    contentsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const landscapeTab: NavTab<string> = {
    key: 'landscape',
    tab: (
      <div className="d-flex">
        <Link
          role="tab"
          to={getPlaylistInfoRoute(
            playlist.id,
            PlaylistTabs.Contents,
            false,
            playlist,
          )}
          tabIndex={-1}
          className={clsx(
            'nav-link text-active-primary',
            [Orientation.FullScreen, Orientation.Landscape].includes(
              orientation as Orientation,
            ) && 'active',
            hasLandscapeConversionJob ? 'me-2' : 'me-6',
          )}
        >
          {t(
            playlist?.template
              ? 'common.landscape'
              : 'common.orientations.full_screen',
          )}
        </Link>
        <div className="ms-0 mt-5">
          {hasLandscapeConversionJob && (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/click-events-have-key-events
            <i
              role="button"
              tabIndex={-1}
              className="fas fa-sync fa-spin cursor-pointer"
              onClick={scrollToBottom}
            />
          )}
        </div>
      </div>
    ),
  };

  const portraitTab: NavTab<string> = {
    key: 'portrait',
    tab: (
      <div className="d-flex">
        <Link
          role="tab"
          to={getPlaylistInfoRoute(
            playlist.id,
            PlaylistTabs.Contents,
            true,
            playlist,
          )}
          tabIndex={-1}
          className={clsx(
            'nav-link text-active-primary me-6',
            orientation === Orientation.Portrait && 'active',
          )}
        >
          {t('common.portrait')}
        </Link>
        <div className="ms-0 mt-5">
          {hasPortraitConversionJob && (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/click-events-have-key-events
            <i
              role="button"
              tabIndex={-1}
              className="fas fa-sync fa-spin cursor-pointer"
              onClick={scrollToBottom}
            />
          )}
        </div>
      </div>
    ),
  };

  const contentTabs = [landscapeTab];

  const checkTemplate = (): boolean => {
    return !['Default layout 3', 'Default layout 2'].includes(
      playlist?.template?.layout.name ?? '',
    );
  };

  if (playlist?.template && checkTemplate()) {
    contentTabs.push(portraitTab);
  }

  useEffect(() => {
    if (playlist?.template && checkTemplate()) {
      setHasBothZones(true);
    }
  }, [playlist]);

  return (
    <Card>
      <Card.Header>
        <Card.Header.Title>
          <Card.Header.Title.Label>
            {t('common.content')}
          </Card.Header.Title.Label>
        </Card.Header.Title>
        <Card.Header.Toolbar>
          <NavTabs tabs={contentTabs} separatorClassName="mb-7" />
        </Card.Header.Toolbar>
      </Card.Header>

      <Card.Body>
        <div className="row d-flex w-100 justify-content-between">
          <div className="col-lg-3 col-sm-12">
            <Search
              queryField={DevicesQueryParam.Search}
              pageNumberQueryField={PaginationQueryParam.Page}
              placeholder={t('common.search')}
              className="me-2"
            />
          </div>
          <div className="col-lg-9 col-sm-12 d-flex justify-content-end flex-wrap">
            {selectedItems.length > 1 && (
              <>
                <BulkEditDropdown>
                  <Dropdown.Menu>
                    <BulkEditContent
                      onSuccess={() => {
                        reset();
                        onEdit();
                      }}
                      selectedItems={selectedItems}
                      playlist={playlist}
                      hasBothZones={hasBothZones}
                      contentType={{
                        typeVideo: !!contents.find(
                          (content) => content.type === FileMimeType.MP4,
                        ),
                      }}
                    >
                      <Dropdown.Item>Edit</Dropdown.Item>
                    </BulkEditContent>
                    <BulkCopyContent
                      selectedItems={selectedItems}
                      disabled={
                        !!contents.find(
                          (content) =>
                            content?.app?.type === 'power-bi' &&
                            selectedItems.includes(content.id),
                        )
                      }
                      onSuccess={() => {
                        reset();
                        onEdit();
                      }}
                    >
                      <Dropdown.Item
                        disabled={
                          !!contents.find(
                            (content) =>
                              content?.app?.type === 'power-bi' &&
                              selectedItems.includes(content.id),
                          )
                        }
                      >
                        {contents.find(
                          (content) =>
                            content?.app?.type === 'power-bi' &&
                            selectedItems.includes(content.id),
                        ) ? (
                          <>
                            {' '}
                            {t('common.copy')}{' '}
                            <span className="bi bi-exclamation-triangle text-warning" />
                          </>
                        ) : (
                          t('common.copy')
                        )}
                      </Dropdown.Item>
                    </BulkCopyContent>
                    <RemoveContent
                      playlist={playlist}
                      isLocked={
                        !!contents.find(
                          ({ id, isLocked }) =>
                            selectedItems?.includes(id) && isLocked,
                        )
                      }
                      selectedItems={selectedItems}
                      onSuccess={() => {
                        reset();
                        onEdit();
                      }}
                    >
                      <Dropdown.Item>Delete</Dropdown.Item>
                    </RemoveContent>
                  </Dropdown.Menu>
                </BulkEditDropdown>
              </>
            )}
            <TimelineDropdown
              className="me-3 min-w-150px ms-3"
              onValueChange={reset}
            />
            <FiltersDropdown className="me-3" />
            <UploadContent onSuccess={onEdit} singlePlaylistId={playlist.id}>
              <button type="button" className="btn btn-primary">
                {t('contents.add_content')}
              </button>
            </UploadContent>
          </div>
        </div>
        <ContentTable
          contents={contents}
          isLoading={isContentsLoading}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          isSelectable
          conversionJobs={conversionJobs?.filter(
            ({ orientation: jobOrientation }) =>
              (orientation === Orientation.Landscape &&
                jobOrientation !== Orientation.Portrait) ||
              (orientation === Orientation.Portrait &&
                jobOrientation === Orientation.Portrait),
          )}
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          playlist={playlist}
          onEdit={() => {
            reset();
            onEdit();
          }}
          devicesWithoutLicence={devicesWithoutLicence}
        />
        <span ref={contentsEndRef} />

        <div className="d-flex justify-content-between align-items-center">
          <div className="w-80px mb-4">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(+e.target.value);
              }}
              className="form-select form-select-sm form-select-solid"
            >
              {options.map((option) => (
                <option key={`${option.label}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-fill ps-5">
            <Pagination
              total={total}
              pageSize={pageSize}
              pageNumberQueryField={PaginationQueryParam.Page}
              pageSizeQueryField={PaginationQueryParam.Limit}
              className="mt-3 mb-5"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PlaylistContent;
