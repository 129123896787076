import React, { useRef } from 'react';
import { RGBColor } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import DeleteTicker from '../../../../containers/Ticker/DeleteTicker';
import EditTicker from '../../../../containers/Ticker/EditTicker';
import RepublishTicker from '../../../../containers/Ticker/RepublishTicker';
import App from '../../../../interfaces/app.interface';
import { formatDateString } from '../../../../utils/dateTime';
import { readAllFromQueryParams } from '../../../../utils/queryParams';
import CheckBox from '../../../CheckBox';
import Table from '../../../Table';
import TickerText from '../../TickerText';
import styles from './tickerTableRow.module.scss';

const { Row } = Table.Body;
const { Cell } = Row;

const TickersTableRow = ({
  ticker,
  isChecked,
  onCheck,
  onUpdate,
}: {
  ticker: App;
  onUpdate: () => void;
  isChecked: boolean;
  onCheck: (id: string) => void;
}) => {
  const { search } = useLocation();
  const { t } = useTranslation();

  const formatDate = formatDateString({
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const rgbaStyler = (clr: RGBColor) =>
    clr ? `rgba(${clr.r}, ${clr.g}, ${clr.b}, ${clr.a})` : 'none';

  const spanRef = useRef<HTMLDivElement>(null);

  const divRef = useRef<HTMLDivElement>(null);

  const [spanWidthSize, setSpanWidthSize] = React.useState<any>();
  const [divWidthSize, setDivWidthSize] = React.useState<any>();

  const setTickerMessageToShow = () => {
    let tickerMessage;
    if (ticker.dependency.config?.daysSafe) {
      if (ticker.dependency.config?.location) {
        tickerMessage = `${ticker.dependency.config?.location} ${t(
          'tickers.addTicker.hasBeenSafe',
        )} ${ticker.dependency.config?.daysSafe} ${t(
          'tickers.addTicker.days',
        )} - ${ticker.dependency.config?.message}`;
        return tickerMessage;
      }
      tickerMessage = `${t('tickers.addTicker.ourLocationSafe')} ${
        ticker.dependency.config?.daysSafe
      } ${t('tickers.addTicker.days')} - ${ticker.dependency.config?.message} `;
      return tickerMessage;
    }

    tickerMessage = ticker.dependency.config?.message;
    return tickerMessage;
  };

  React.useEffect(() => {
    setSpanWidthSize(spanRef?.current?.offsetWidth);
    setDivWidthSize(spanRef?.current?.offsetWidth);
  }, []);

  return (
    <Row>
      <Cell className="w-fit-content">
        <CheckBox
          name="tickerId"
          id={ticker.id}
          onChange={() => onCheck(ticker.id)}
          checked={isChecked}
          className="mt-1"
        />
      </Cell>
      <Cell isEmpty={!ticker.name}>
        <span className="text-dark fw-bold text-hover-primary mb-1 fs-6 text-end">
          {ticker.name}
        </span>
      </Cell>
      <Cell isEmpty={!ticker.dependency.config?.message}>
        <div ref={divRef} className={styles.message}>
          <TickerText
            duration={
              spanWidthSize && divWidthSize
                ? (spanWidthSize + divWidthSize) / 100
                : 10
            }
          >
            <span
              ref={spanRef}
              className="text-dark fw-bold text-hover-primary mb-1 fs-6 text-end"
            >
              {/* {ticker.dependency.config?.daysSafe ? (
                <>
                  {`${
                    ticker.dependency.config?.location ? (
                      <>
                        {`${ticker.dependency.config?.location} has been safe for ${ticker.dependency.config?.daysSafe} days `}
                      </>
                    ) : (
                      <>
                        {`Our Location has been safe for ${ticker.dependency.config?.daysSafe} days `}{' '}
                      </>
                    )
                  } ${ticker.dependency.config?.message}`}
                </>
              ) : (
                ticker.dependency.config?.message
              )} */}

              {setTickerMessageToShow()}
            </span>
          </TickerText>
        </div>
      </Cell>

      <Cell isEmpty={!ticker.dependency.config?.daysSafe}>
        <div className="d-flex justify-content-center ">
          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
            {ticker.dependency.config?.daysSafe}
          </span>
        </div>
      </Cell>
      <Cell isEmpty={!ticker.dependency.config?.backgroundColor}>
        <div className="d-flex justify-content-center ">
          <div
            className="w-40px h-30px rounded"
            style={{
              backgroundColor: rgbaStyler(
                ticker.dependency.config?.backgroundColor,
              ),
            }}
          />
        </div>
      </Cell>
      <Cell isEmpty={!ticker.dependency.config?.textColor}>
        <div className="d-flex justify-content-center">
          <div
            className="w-40px h-30px rounded "
            style={{
              backgroundColor: rgbaStyler(ticker.dependency.config?.textColor),
            }}
          />
        </div>
      </Cell>

      <Cell isEmpty={!ticker.startDate}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {ticker.startDate && formatDate(ticker.startDate)}
        </span>
      </Cell>

      <Cell isEmpty={!ticker?.endDate}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {ticker.endDate && formatDate(ticker.endDate)}
        </span>
      </Cell>

      <Cell isEmpty={!ticker.devices?.length}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {ticker.devices?.length}
        </span>
      </Cell>
      <Cell isEmpty={!ticker.deviceGroups?.length}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {ticker.deviceGroups?.length}
        </span>
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          {readAllFromQueryParams(search).isCompleted === 'true' && (
            <div className="mx-1">
              <RepublishTicker ticker={ticker} onUpdate={onUpdate} />
            </div>
          )}

          <div className="mx-1">
            <EditTicker ticker={ticker} onUpdate={onUpdate} />
          </div>

          <div className="mx-1">
            <DeleteTicker
              tickerId={ticker.id}
              tickerName={ticker.name}
              onUpdate={onUpdate}
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default TickersTableRow;
