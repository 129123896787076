import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import PowerBiModal from '../../../../components/Apps/PowerBi/CreatePowerBi';
import Modal from '../../../../components/Modal';
import api from '../../../../api';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import App from '../../../../interfaces/app.interface';
import PowerBiUtility from '../../../../interfaces/apps/powerBiUtility.interface';
import Tooltip from '../../../../components/Tooltip';

const EditPowerBi = ({
  children,
  onUpdate,
  powerBi,
}: {
  children?: React.ReactNode;
  onUpdate: () => void;
  powerBi: App;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const handleSubmit = async (data: CreateAppRequestData) => {
    setLoading(true);

    try {
      if (data.config?.powerBiUtilityAppId) {
        const utilityPayload: PowerBiUtility = {
          _id: data.config.powerBiUtilityAppId,
          embedURL: data.config.powerBiFeedUrl,
          refreshRate: data.config.powerBiRefreshRateInSeconds,
          signTitle: data.config.powerBiRecording,
        };
        await api.powerBi.updateApp(utilityPayload);
      }
    } finally {
      await api.apps.updateApp(powerBi.id, data);
      close();
      if (onUpdate) {
        onUpdate();
      }
      toast.success(i18next.t<string>('apps.powerBi.powerBi_edited'));
      setLoading(false);
    }
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.edit')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-edit" />
            </span>
          </Tooltip>
        )}
      </span>
      <Modal
        title={t('apps.powerBi.editPowerBi')}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
      >
        <PowerBiModal
          isLoading={loading}
          onClose={close}
          powerBi={powerBi}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default EditPowerBi;
