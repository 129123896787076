/* eslint-disable consistent-return */
/* eslint-disable func-names */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';
import Bugsnag from '@bugsnag/js';
import { useDispatch } from 'react-redux';
import { auth0, logout, webAuth0 } from '../../utils/auth0';
import WithSpinner from '../../components/WithSpinner';
import styles from './login.module.scss';
import routesConfig from '../../routing/config';
import { getUserData } from '../../store/actions/userData';
// import { readFromQueryString } from '../../utils/queryParams';
// import GlobalState from '../../store/reducers/globalState.interface';

export default () => {
  const dispatch = useDispatch();

  // const isUserDataLoading = useSelector(
  //   (state: GlobalState) => state.userData.isLoading,
  // );

  const canvaId = sessionStorage?.getItem('canvaId');
  const stateCanva = sessionStorage?.getItem('state');
  const userEmail = sessionStorage?.getItem('email');
  const nonce = sessionStorage?.getItem('nonce');

  const [loading, setLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState(routesConfig.home.route);

  useEffect(() => {
    // debugger;
    if (window.location.hash) {
      webAuth0.parseHash(
        { hash: window.location.hash },
        function (err, authResult) {
          // debugger;
          if (err) {
            logout();
            Bugsnag.notify(new Error(err.description));
            return console.log(err);
          }

          if (authResult && authResult.accessToken && authResult.idToken) {
            console.log({
              accessToken: authResult.accessToken,
              idToken: authResult.idToken,
              authResult,
            });

            webAuth0.client.userInfo(
              authResult.accessToken as any,
              function (error, user) {
                if (error) {
                  Bugsnag.notify(new Error(error.description));
                  logout();
                  return console.log(error);
                }
                if (user) {
                  dispatch(getUserData());
                  // debugger;

                  // if (canvaId && stateCanva) {
                  //   console.log('redirecting to canva');
                  //   window.location.href = `${
                  //     process.env.REACT_APP_API_URL
                  //   }/canva/redirect?state=${encodeURIComponent(
                  //     stateCanva as string,
                  //   )}&canvaId=${encodeURIComponent(
                  //     canvaId as string,
                  //   )}&email=${encodeURIComponent(userEmail as string)}`;
                  //   return;
                  // }
                }

                setRedirectTo(routesConfig.home.route);

                setLoading(false);
              },
            );
          }

          // Now you have the user's information
        },
      );
    } else if (window.location.search.includes('code=')) {
      setTimeout(async () => {
        try {
          const {
            appState: { redirectUrl },
          } = await auth0.handleRedirectCallback();

          dispatch(getUserData());

          if (canvaId && stateCanva && userEmail) {
            sessionStorage.clear();
            window.location.href = `${
              process.env.REACT_APP_API_URL
            }/canva/redirect?state=${encodeURIComponent(
              stateCanva as string,
            )}&canvaId=${encodeURIComponent(
              canvaId as string,
            )}&email=${encodeURIComponent(
              userEmail as string,
            )}&nonce=${encodeURIComponent(nonce as string)}`;
            return;
          }

          // debugger;
          setRedirectTo(redirectUrl);
        } catch (e) {
          // do nothing
          console.log({ e });
          // debugger;
        }
        setLoading(false);
      });
    }
  }, []);

  return (
    <WithSpinner
      isLoading={loading}
      size="md"
      className={clsx(
        'position-absolute start-0 top-0 bg-light',
        styles.preloaderBackdrop,
      )}
    >
      <Redirect to={redirectTo} />
    </WithSpinner>
  );
};
