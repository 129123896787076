import axios from 'axios';
import { API_URL } from '../../../constants/api';
import AccountsRoutes from './enums/accountRoutes.enum';
import UpdateUserRequestData from '../users/interfaces/updateUserRequestData.interface';

export default {
  /**
   * Retrieves the current account
   */
  getAccount: async () => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/account`,
    });

    return data;
  },

  /**
   * Send the reset password email for the current account
   */
  resetPassword: async () => {
    await axios({
      method: 'patch',
      url: `${API_URL}/account/reset-password`,
    });
  },

  /**
   * Update the onboarding user's profile
   */
  updateOnboarding: async (requestData: Partial<UpdateUserRequestData>) => {
    await axios({
      method: 'put',
      url: `${API_URL}/account/onboarding-user`,
      data: requestData,
    });
  },

  /**
   * Update the onboarding user's profile
   */
  updateOnboardingDeviceIsPreviewed: async () => {
    await axios({
      method: 'put',
      url: `${API_URL}/account/onboarding-user/device`,
    });
  },

  /**
   * Update the onboarding user's profile
   */
  updateOnboardingWatchedWelcomeVideo: async () => {
    await axios({
      method: 'put',
      url: `${API_URL}/account/onboarding-user/welcome-video`,
    });
  },

  /**
   * Update the onboarding user's profile
   */
  updateOnboardingAppsAccessedFirstTime: async () => {
    await axios({
      method: 'put',
      url: `${API_URL}/account/onboarding-user/apps`,
    });
  },

  /**
   * Send the reset password email for the current account
   */
  adminResetPassword: async (id: string) => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${AccountsRoutes.AdminReset}/${id}/reset-password`,
    });
  },

  /**
   * Send the reset password email for the current account
   */
  adminSendWelcomeEmail: async (id: string) => {
    await axios({
      method: 'post',
      url: `${API_URL}/${AccountsRoutes.AdminReset}/${id}/welcome-email`,
    });
  },

  /**
   * Send the reset email for the current account
   */
  resetEmail: async (email: string) => {
    let emailToReset;
    if (email.includes('+')) {
      emailToReset = email.replace('+', '%2B');
    } else {
      emailToReset = email;
    }

    await axios({
      method: 'patch',
      url: `${API_URL}/account/reset-email?newEmail=${emailToReset}`,
    });
  },

  /**
   * Skip the onboarding steps
   */
  skipSteps: async () => {
    await axios({
      method: 'put',
      url: `${API_URL}/account/onboarding-user/form`,
    });
  },
};
