import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Device from '../../../interfaces/devices/device.interface';
import DeviceInfoModal from '../../../components/Devices/DeviceInfoModal';
import Tooltip from '../../../components/Tooltip';
import Timezone from '../../../interfaces/timezone.interface';

export default ({
  device,
  children,
}: {
  children?: React.ReactNode;
  device: Device;
}) => {
  const { t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);
  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('devices.view_info')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-info" />
            </span>
          </Tooltip>
        )}
      </span>
      <DeviceInfoModal
        isModalVisible={isModalVisible}
        onHide={close}
        license={device.license}
        timezone={device.timezone as Timezone}
      />
    </>
  );
};
