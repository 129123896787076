import axios, { AxiosError, AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import { API_URL } from '../../../constants/api';
import { scheduleHTMLFeedLoadingActionCreator } from '../../actions/apps';

import {
  ACTIONS,
  createScheduleYOUTUBEFeedErrorActionCreator,
  createScheduleYoutubeFeedSuccessActionCreator,
  createYoutubeFeedErrorActionCreator,
  createYoutubeFeedLoadingActionCreator,
  createYoutubeFeedSuccessActionCreator,
  CREATE_SCHEDULE_YOUTUBE_FEED_ACTIONS,
  CREATE_YOUTUBE_FEED_ACTIONS,
  deleteYoutubeFeedErrorActionCreator,
  deleteYoutubeFeedLoadingActionCreator,
  deleteYoutubeFeedSuccessActionCreator,
  DELETE_YOUTUBE_FEED_ACTIONS,
  editYoutubeFeedErrorActionCreator,
  editYoutubeFeedLoadingActionCreator,
  editYoutubeFeedSuccessActionCreator,
  EDIT_YOUTUBE_FEED_ACTIONS,
  getYoutubeFeedsErrorActionCreator,
  getYoutubeFeedsLoadingActionCreator,
  getYoutubeFeedsSuccessActionCreator,
  GET_YOUTUBE_FEEDS_ACTIONS,
  scheduleYoutubeFeedErrorActionCreator,
  scheduleYoutubeFeedLoadingActionCreator,
  scheduleYoutubeFeedSuccessActionCreator,
  SCHEDULE_YOUTUBE_FEED_ACTIONS,
} from '../../actions/apps/youtubeFeed';
import { getUserData } from '../../actions/userData';
import { ErrorResponse } from '../../types/apps/globalTypes';
import {
  CreateYoutubeFeedRequest,
  DeleteYoutubeFeedRequest,
  EditYoutubeFeedRequest,
  GetYoutubeFeedsRequest,
  PaginatedYoutubeFeeds,
  ScheduleYoutubeFeedRequest,
  YoutubeFeedSchema,
} from '../../types/apps/youtubeFeed';

const YOUTUBE_FEED_APP_URL = `${API_URL}/apps/youtube`;

// services

export const getYoutubeFeedsService = async ({
  params,
}: GetYoutubeFeedsRequest): Promise<PaginatedYoutubeFeeds> => {
  const response = await axios({
    method: 'GET',
    url: YOUTUBE_FEED_APP_URL,
    params,
  });

  return response.data;
};

function* getYoutubeFeedsWorker({ payload }: GET_YOUTUBE_FEEDS_ACTIONS) {
  try {
    yield put(getYoutubeFeedsLoadingActionCreator({ loading: true }));

    const response: PaginatedYoutubeFeeds = yield call(
      getYoutubeFeedsService,
      payload as GetYoutubeFeedsRequest,
    );

    yield put(
      getYoutubeFeedsSuccessActionCreator({
        data: response,
        loading: false,
        isGetYoutubeFeedsSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;
    yield put(
      getYoutubeFeedsErrorActionCreator({
        error: response.data,
        loading: false,
        isGetYoutubeFeedsSuccess: false,
      }),
    );
  }
}

export const createYoutubeFeedService = async (
  request: CreateYoutubeFeedRequest,
): Promise<Partial<YoutubeFeedSchema>> => {
  const response = await axios({
    method: 'POST',
    url: YOUTUBE_FEED_APP_URL,
    data: request,
  });

  return response.data;
};

function* createYoutubeFeedWorker({ payload }: CREATE_YOUTUBE_FEED_ACTIONS) {
  try {
    yield put(createYoutubeFeedLoadingActionCreator({ loading: true }));

    const response: Partial<YoutubeFeedSchema> = yield call(
      createYoutubeFeedService,
      payload as CreateYoutubeFeedRequest,
    );

    yield put(
      createYoutubeFeedSuccessActionCreator({
        data: response,
        loading: false,
        isCreateYoutubeFeedSuccess: true,
      }),
    );
    yield put(getUserData());
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      createYoutubeFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isCreateYoutubeFeedSuccess: false,
      }),
    );
  }
}

export const editYoutubeFeedService = async (
  request: EditYoutubeFeedRequest,
): Promise<Partial<YoutubeFeedSchema>> => {
  const response = await axios({
    method: 'PATCH',
    url: `${API_URL}/apps/${request.id}`,
    data: request,
  });

  return response.data;
};

function* editYoutubeFeedWorker({ payload }: EDIT_YOUTUBE_FEED_ACTIONS) {
  try {
    yield put(editYoutubeFeedLoadingActionCreator({ loading: true }));

    const response: Partial<YoutubeFeedSchema> = yield call(
      editYoutubeFeedService,
      payload as EditYoutubeFeedRequest,
    );

    yield put(
      editYoutubeFeedSuccessActionCreator({
        data: response,
        loading: false,
        isEditYoutubeFeedSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      editYoutubeFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isEditYoutubeFeedSuccess: false,
      }),
    );
  }
}

export const scheduleYoutubeFeedService = async (
  request: ScheduleYoutubeFeedRequest,
): Promise<Partial<YoutubeFeedSchema>> => {
  const response = await axios({
    method: 'POST',
    url: `${YOUTUBE_FEED_APP_URL}/${request.appId}`,
    data: request,
  });

  return response.data;
};

function* scheduleYoutubeFeedWorker({
  payload,
}: SCHEDULE_YOUTUBE_FEED_ACTIONS) {
  try {
    yield put(scheduleYoutubeFeedLoadingActionCreator({ loading: true }));

    const response: Partial<YoutubeFeedSchema> = yield call(
      scheduleYoutubeFeedService,
      payload as ScheduleYoutubeFeedRequest,
    );

    yield put(
      scheduleYoutubeFeedSuccessActionCreator({
        data: response,
        loading: false,
        isScheduleYoutubeFeedSuccess: true,
      }),
    );
    yield put(getUserData());
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      scheduleYoutubeFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isScheduleYoutubeFeedSuccess: false,
      }),
    );
  }
}

export const deleteYoutubeFeedService = async (
  request: DeleteYoutubeFeedRequest,
): Promise<void> => {
  const response = await axios({
    method: 'DELETE',
    url: `${API_URL}/apps/${request.id}`,
  });

  return response.data;
};

function* deleteYoutubeFeedWorker({ payload }: DELETE_YOUTUBE_FEED_ACTIONS) {
  try {
    yield put(deleteYoutubeFeedLoadingActionCreator({ loading: true }));

    const response: Partial<void> = yield call(
      deleteYoutubeFeedService,
      payload as DeleteYoutubeFeedRequest,
    );

    yield put(
      deleteYoutubeFeedSuccessActionCreator({
        data: response,
        loading: false,
        isDeleteYoutubeFeedSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      deleteYoutubeFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isDeleteYoutubeFeedSuccess: false,
      }),
    );
  }
}
function* createScheduleYOUTUBEFeedWorker({
  payload,
}: CREATE_SCHEDULE_YOUTUBE_FEED_ACTIONS) {
  try {
    yield put(createYoutubeFeedLoadingActionCreator({ loading: true }));

    const response: Partial<YoutubeFeedSchema> = yield call(
      createYoutubeFeedService,
      payload?.youtubeFeed as CreateYoutubeFeedRequest,
    );

    const scheduleFeed = payload!.scheduleFeed!;
    scheduleFeed.appId = response!.id!;

    yield put(
      createYoutubeFeedSuccessActionCreator({
        data: { name: scheduleFeed.name, ...response },
        loading: false,
        isCreateYoutubeFeedSuccess: true,
      }),
    );

    yield put(scheduleHTMLFeedLoadingActionCreator({ loading: true }));

    yield call(
      scheduleYoutubeFeedService,
      scheduleFeed as ScheduleYoutubeFeedRequest,
    );

    yield put(
      createScheduleYoutubeFeedSuccessActionCreator({
        loading: false,
        isCreateScheduleYoutubeFeedSuccess: true,
        data: response,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      createYoutubeFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isCreateYoutubeFeedSuccess: false,
      }),
    );
    yield put(
      createScheduleYOUTUBEFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isCreateYOUTUBEFeedSuccess: false,
      }),
    );
  }
}

// watchers
export default function* youtubeFeedsWatcher() {
  yield takeEvery<GET_YOUTUBE_FEEDS_ACTIONS>(
    ACTIONS.GET_YOUTUBE_FEEDS,
    getYoutubeFeedsWorker,
  );

  yield takeEvery<CREATE_YOUTUBE_FEED_ACTIONS>(
    ACTIONS.CREATE_YOUTUBE_FEED,
    createYoutubeFeedWorker,
  );
  yield takeEvery<CREATE_SCHEDULE_YOUTUBE_FEED_ACTIONS>(
    ACTIONS.CREATE_SCHEDULE_YOUTUBE_FEED,
    createScheduleYOUTUBEFeedWorker,
  );
  yield takeEvery<SCHEDULE_YOUTUBE_FEED_ACTIONS>(
    ACTIONS.SCHEDULE_YOUTUBE_FEED,
    scheduleYoutubeFeedWorker,
  );

  yield takeEvery<DELETE_YOUTUBE_FEED_ACTIONS>(
    ACTIONS.DELETE_YOUTUBE_FEED,
    deleteYoutubeFeedWorker,
  );

  yield takeEvery<EDIT_YOUTUBE_FEED_ACTIONS>(
    ACTIONS.EDIT_YOUTUBE_FEED,
    editYoutubeFeedWorker,
  );
}
