import { TFunction } from 'i18next';
import validation from '../../utils/validation';

export const weatherCreateValidation = (t: TFunction) => ({
  name: validation
    .string()
    .required(t('contents.validation.please_enter_name')),
  placement: validation
    .string()
    .required(t('apps.weather.createFormFields.validation.placement')),
  countryId: validation
    .string()
    .min(1)
    .required(t('apps.severeWeather.validation.country')),
  zipCode: validation
    .string()
    .when('countryId', {
      is: (countryId: string) => countryId === 'us',
      then: validation
        .string()
        .length(5, t('devices.validation.zipcode'))
        .matches(/^[0-9]{5}/),
    })
    .when('countryId', {
      is: (countryId: string) => countryId === 'ca',
      then: validation
        .string()
        .matches(
          /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) ?(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/,
          t('devices.validation.zipcode'),
        ),
    }),
});
