import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap-v5';
import { useForm } from 'react-hook-form';
import * as React from 'react';
import * as Yup from 'yup';
import Counter from '../../../components/Counter';
import FormErrorMessage from '../../../components/FormErrorMessage';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import { CreateHTMLFeedRequest } from '../../../store/types/apps/htmlFeed';
import PublishModal from '../../../components/PublishModal';
import { createScheduleHTMLFeedActionCreator } from '../../../store/actions/apps';
import GlobalState from '../../../store/reducers/globalState.interface';
import Orientation from '../../../enums/orientation.enum';
import Modal from '../../../components/Modal';
import { disableRightClickIframe } from '../../../utils/common';
import WithSpinner from '../../../components/WithSpinner';

type CreateHTMLFeedRequestFormMutated = Omit<
  CreateHTMLFeedRequest,
  'config' | 'placement'
>;

interface CreateHTMLFeedRequestFormPayload
  extends CreateHTMLFeedRequestFormMutated {
  htmlFeedUrl: string;
  refreshRate: number;
}

export interface CreateFeedModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (createHTMLFeedRequest: CreateHTMLFeedRequest) => void;
  title: string;
  modalHeight?: number;
  onSetIsScheduleandCreateSuccess(bool: boolean): void;
}

const CreateFeedModal = ({
  show,
  onHide,
  title,
  onSubmit,
  onSetIsScheduleandCreateSuccess,
  modalHeight = 400,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const htmlFeedSelector = useSelector((state: GlobalState) => state.htmlFeeds);
  const [previewDocumentIsFetching, setPreviewDocumentIsFetching] =
    React.useState<boolean>(false);

  const initialValues: CreateHTMLFeedRequestFormPayload = {
    name: '',
    htmlFeedUrl: '',
    refreshRate: 0,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    htmlFeedUrl: Yup.string()
      .required(t('common.validation.required'))
      .url('Please enter a valid Website URL')
      .defined(),
    refreshRate: Yup.number()
      .min(0, t('common.validation.refreshRate'))
      .defined(),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<CreateHTMLFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateHTMLFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const handleFeedNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFeedName(value),
    [setFeedName],
  );
  const [submited, setSubmited] = React.useState<boolean>(false);
  const [htmlFeedUrl, setHTMLFeedURL] = useStateWithHookForm<
    CreateHTMLFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'htmlFeedUrl' }, initialValues.htmlFeedUrl);

  const handlePreviewDocumentIsFetching = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (previewDocumentIsFetching: boolean) =>
      setPreviewDocumentIsFetching(previewDocumentIsFetching),
    [],
  );

  const handleHTMLFeedURLChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (value === '') {
        handlePreviewDocumentIsFetching(false);
      } else {
        handlePreviewDocumentIsFetching(true);
      }

      setHTMLFeedURL(value);
    },
    [handlePreviewDocumentIsFetching, setHTMLFeedURL],
  );

  const [refreshRate, setRefreshRate] = useStateWithHookForm<
    CreateHTMLFeedRequestFormPayload,
    number
  >({ setValue, trigger, name: 'refreshRate' }, initialValues.refreshRate);

  const [isScheduleVisible, setIsScheduleVisible] =
    React.useState<boolean>(false);

  const handleRefreshRateChange = React.useCallback(
    (value: number) => setRefreshRate(value),
    [setRefreshRate],
  );

  const handleOnIFrameLoaded = React.useCallback(
    () => handlePreviewDocumentIsFetching(false),
    [handlePreviewDocumentIsFetching],
  );

  const handleOnSubmit = React.useCallback(
    async (data: CreateHTMLFeedRequestFormPayload) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, htmlFeedUrl, refreshRate } = data;

      const createHTMLFeedRequest: CreateHTMLFeedRequest = {
        name,
        config: {
          htmlFeedUrl,
          htmlRefreshRateInSeconds: refreshRate,
        },
        zoneId: Orientation.Landscape,
      };
      setSubmited(true);
      onSubmit(createHTMLFeedRequest);
    },
    [onSubmit],
  );
  const submitAndSchedule = () => {
    onSetIsScheduleandCreateSuccess(true);
    setIsScheduleVisible(true);
  };

  const onSubmitAndSchedule = React.useCallback(
    (data) => {
      const createHTMLData: CreateHTMLFeedRequestFormPayload =
        getValues() as CreateHTMLFeedRequestFormPayload;

      dispatch(
        createScheduleHTMLFeedActionCreator({
          htmlFeed: {
            name: createHTMLData.name,
            config: {
              htmlFeedUrl: createHTMLData.htmlFeedUrl,
              htmlRefreshRateInSeconds: createHTMLData.refreshRate,
            },
            zoneId: data.zoneId,
          },
          scheduleFeed: data,
        }),
      );

      setIsScheduleVisible(false);
      onHide();
    },
    [
      htmlFeedSelector.isScheduleHTMLFeedSuccess,
      htmlFeedSelector.createHTMLFeedLoading,
      htmlFeedSelector.scheduleHTMLFeedLoading,
    ],
  );

  const onCloseSchedule = () => {
    setIsScheduleVisible(false);
    onHide();
  };

  React.useEffect(() => {
    disableRightClickIframe(feedName);
  }, []);

  return (
    <>
      {isScheduleVisible ? (
        <PublishModal
          onClose={onCloseSchedule}
          onSubmit={onSubmitAndSchedule}
          isVisible={isScheduleVisible}
          defaultValues={{
            isOrdered: true,
          }}
          skipOrder
          skipOrientationAuto
        />
      ) : (
        <Modal
          title={title}
          show={show}
          onHide={onHide}
          widthDefaultSize
          withFooter={false}
          floatLeft
        >
          <WithSpinner isLoading={submited} className="min-h-400px" size="md">
            <form
              onSubmit={handleSubmit(handleOnSubmit)}
              id="form"
              className="d-flex flex-column justify-content-between"
            >
              <div className="d-flex flex-row p-4 w-100">
                <div className="d-flex flex-column mx-2 w-100">
                  <label
                    htmlFor="name"
                    className="required text-dark fw-bolder my-1"
                  >
                    {t('apps.html5.Html5Title')}
                  </label>
                  <input
                    name="name"
                    id="name"
                    value={feedName}
                    onChange={handleFeedNameChange}
                    className="form-control form-control-solid"
                    placeholder="Type here"
                    type="text"
                  />
                  <FormErrorMessage
                    name="name"
                    errors={errors}
                    className="my-1 px-2"
                  />
                </div>

                <div className="d-flex flex-column mx-2 w-100">
                  <label
                    htmlFor="htmlFeedUrl"
                    className="required text-dark fw-bolder my-1"
                  >
                    {t('apps.html5.webPageUrl')}
                  </label>
                  <input
                    name="htmlFeedUrl"
                    id="htmlFeedUrl"
                    value={htmlFeedUrl}
                    onChange={handleHTMLFeedURLChange}
                    className="form-control form-control-solid"
                  />
                  <FormErrorMessage
                    name="htmlFeedUrl"
                    errors={errors}
                    className="my-1 px-2"
                  />
                </div>
              </div>
              <div className="d-flex flex-row px-4 w-100">
                <div className="d-flex flex-column mx-2 w-50">
                  <Counter
                    name="refreshRate"
                    title="Refresh Rate (sec)"
                    value={refreshRate}
                    onChange={handleRefreshRateChange}
                    className="form-control form-control-solid"
                    classNameTitle="text-dark fw-bolder my-1"
                  />
                  <FormErrorMessage
                    name="refreshRate"
                    errors={errors}
                    className="my-1 px-2"
                  />
                </div>
              </div>

              <div className="d-flex flex-column   flex-center  p-4 w-100">
                {!errors.htmlFeedUrl && htmlFeedUrl !== '' ? (
                  <div className="d-flex flex-column flex-center w-100 h-100">
                    {previewDocumentIsFetching ? (
                      <Spinner role="status" animation="border" />
                    ) : null}
                    <iframe
                      title={feedName}
                      src={htmlFeedUrl}
                      width="100%"
                      height={`${modalHeight}px`}
                      onLoad={handleOnIFrameLoaded}
                      frameBorder="0"
                      marginHeight={0}
                      marginWidth={0}
                      style={{ padding: '4px' }}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-center w-100">
                    <img
                      className="d-flex flex-column align-self-center w-25"
                      alt="No items found"
                      src="/media/illustrations/sketchy-1/5.png"
                    />
                    <div className="fs-1">{t('apps.html5.noPreview')}</div>
                    <div className="fs-6">{t('apps.html5.webPageUrl')}</div>
                  </div>
                )}
              </div>
            </form>

            <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />

            <div className="d-flex">
              <div className="pe-5 me-auto">
                <button
                  type="button"
                  className="btn btn-light-primary"
                  onClick={onHide}
                >
                  Cancel
                </button>
              </div>
              <button
                id="submit"
                type="button"
                className="btn btn-primary m-1"
                onClick={handleSubmit(handleOnSubmit)}
                disabled={submited}
              >
                Create
              </button>
              <button
                type="button"
                className="btn btn-primary m-1"
                onClick={handleSubmit(submitAndSchedule)}
              >
                Create and schedule
              </button>
            </div>
          </WithSpinner>
        </Modal>
      )}
    </>
  );
};

export default CreateFeedModal;
