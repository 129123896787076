import React, { useEffect } from 'react';
import IContent from '../../../../../../../interfaces/content.interface';
import FacebookThumbnail from '../../../../../../Apps/Facebook/Thumbnail';

export default ({ content }: { content: IContent }) => {
  const [url, setUrl] = React.useState('');

  const FacebookData = content.app;

  useEffect(() => {
    if (!FacebookData) return;

    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?appId=${FacebookData?.id}`,
    );
  }, [FacebookData]);

  return <FacebookThumbnail src={url} />;
};
