import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../../components/Modal';
import { MsTeamsApis } from '../../../../api/endpoints/apps/Microsoft';
import RestoreSubscription from '../../../../components/Apps/MSTeams/RestoreSubscription';

const RestoreSubscriptionMsTeams = ({
  children,
  msTeam,
  onUpdate,
}: {
  children?: React.ReactNode;
  msTeam: App;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const open = () => setModalShown(true);
  const [restoringSubscriptionLoading, setRestoringSubscriptionLoading] =
    useState(true);
  const [restoreSubscriptionIssue, setRestoreSubscriptionIssue] = useState({
    message: null,
  });
  const restoreSubscription = async () => {
    try {
      if (restoreSubscriptionIssue?.message)
        setRestoreSubscriptionIssue({
          message: null,
        });
      await MsTeamsApis.MsTeamsRestoreSubscription(msTeam?.id);
      setRestoringSubscriptionLoading(false);
    } catch (e: any) {
      setRestoringSubscriptionLoading(false);
      if (e?.response?.data?.error?.code === 'ExtensionError') {
        setRestoreSubscriptionIssue({
          message: t('apps.msTeams.restoreSubscriptionExtensionError'),
        });
      } else
        setRestoreSubscriptionIssue({
          message: t('apps.msTeams.restoreSubscriptionIssue'),
        });
      toast.error(`Error During restoring subscription`);
    }
  };
  React.useEffect(() => {
    if (modalShown) {
      restoreSubscription();
    }
  }, [modalShown]);

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('apps.msTeams.restoreSubscriptionTitle')}
        // withHeader={!code}
        show={modalShown}
        onHide={() => {
          setModalShown(false);
          if (onUpdate) {
            onUpdate();
          }
        }}
        withFooter={false}
      >
        <RestoreSubscription
          restoreSubscriptionIssue={restoreSubscriptionIssue}
          restoringSubscriptionLoading={restoringSubscriptionLoading}
        />
      </Modal>
    </>
  );
};

export default RestoreSubscriptionMsTeams;
