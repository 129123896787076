import GetCustomersListSuccessAction from './interfaces/getCustomersListSuccessAction.interface';
import GetCustomersListAction from './interfaces/getCustomersListAction.interface';

import LoadingAction from '../loadingAction.interface';
import CustomersActions from './customersActions.enum';

export const getCustomersList = (
  payload: GetCustomersListAction['payload'],
): GetCustomersListAction => ({
  type: CustomersActions.GET_CUSTOMERS_LIST,
  payload,
});

export const getCustomersListSuccess = (
  payload: GetCustomersListSuccessAction['payload'],
): GetCustomersListSuccessAction => ({
  type: CustomersActions.GET_CUSTOMERS_LIST_SUCCESS,
  payload,
});

export const setCustomersLoading = (
  payload: LoadingAction['payload'],
): LoadingAction => ({
  type: CustomersActions.SET_CUSTOMERS_LOADING,
  payload,
});
