import React from 'react';
import AssignUserGroup from '../../../components/UsersGroups/AssignUserGroup';
import { SelectedValue } from '../../../components/Select/SelectAsync';

export default ({
  children,
  assignUserGroup,
}: {
  children: React.ReactNode;
  assignUserGroup?: (data: SelectedValue[]) => void;
}) => {
  const handleSubmit = async (data: SelectedValue[]) => {
    if (assignUserGroup) {
      assignUserGroup(data);
    }
  };

  return <AssignUserGroup onSubmit={handleSubmit}>{children}</AssignUserGroup>;
};
