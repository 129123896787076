import { useEffect, useRef } from 'react';

export const usePrevValues = <T>(value: T, callback: (value: T) => void) => {
  const prevValues = useRef(value);

  useEffect(() => {
    callback(prevValues.current);

    return () => {
      prevValues.current = value;
    };
  });
};
