import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import Spinner from '../Spinner';
import styles from './withSpinner.module.scss';

const TablePreloaderWrapper = ({
  children,
  isWrapped,
}: {
  children: React.ReactNode;
  isWrapped: boolean;
}) => (
  <>
    {isWrapped ? (
      <tr>
        <td className={clsx('position-relative', styles.tablePreloader)}>
          {children}
        </td>
      </tr>
    ) : (
      children
    )}
  </>
);

export default ({
  children,
  isLoading,
  size,
  className,
  isForTable,
  style,
}: {
  children?: React.ReactNode;
  isLoading: boolean;
  size?: string;
  className?: string;
  isForTable?: boolean;
  style?: CSSProperties;
}) => (
  <>
    {isLoading ? (
      <TablePreloaderWrapper isWrapped={!!isForTable}>
        <div
          className={clsx('w-100 h-100 position-relative', className)}
          style={style}
        >
          <Spinner size={size} />
        </div>
      </TablePreloaderWrapper>
    ) : (
      children
    )}
  </>
);
