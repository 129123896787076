import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UsersGroup from '../../../interfaces/usersGroup.interface';
import UpdateUsersGroupAction from '../../../store/actions/usersGroups/interfaces/updateUsersGroupAction.interface';
import EditUsersGroup from '../../../components/UsersGroups/EditUsersGroup';
import { updateUsersGroup } from '../../../store/actions/usersGroups';
import Tooltip from '../../../components/Tooltip';

export default ({
  group,
  children,
  onEditUserGroup,
}: {
  group: UsersGroup;
  children?: React.ReactNode;
  onEditUserGroup?: (data: UpdateUsersGroupAction['payload']) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (data: UpdateUsersGroupAction['payload']) => {
    if (onEditUserGroup) {
      onEditUserGroup(data);
      return;
    }

    dispatch(updateUsersGroup(data));
  };

  return (
    <EditUsersGroup group={group} onSubmit={handleSubmit}>
      {children ?? (
        <Tooltip text={t('common.edit')}>
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-edit" />
          </span>
        </Tooltip>
      )}
    </EditUsersGroup>
  );
};
