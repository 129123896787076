import React from 'react';
import { disableRightClickIframe } from '../../../utils/common';

export default ({
  title,
  onOpen,
  setRef,
  src,
}: {
  title?: string;
  onOpen?: () => void;
  setRef: (el: HTMLIFrameElement | null) => void;
  src?: string;
}) => {
  React.useEffect(() => {
    if (title) {
      disableRightClickIframe(title);
    }
  }, []);
  return (
    <span
      role="button"
      className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 w-100"
      onKeyPress={onOpen}
      onClick={onOpen}
      tabIndex={-1}
      onContextMenu={(ev) => ev.preventDefault()}
    >
      <div
        className="position-absolute"
        style={{
          zIndex: 100,
          width: 50,
          height: 30,
          backgroundColor: '#fff0',
        }}
      />
      <div className="d-flex flex-row">
        {' '}
        <iframe
          ref={setRef}
          tabIndex={-1}
          title={title}
          src={src}
          width={50}
          height={28}
          style={{ zIndex: 10, marginRight: 5, borderRadius: 5 }}
          marginHeight={0}
          marginWidth={0}
        />
        {title}
      </div>
    </span>
  );
};
