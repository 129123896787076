import React from 'react';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import PublishModal from '../../../../components/PublishModal';
import useModalState from '../../../../utils/hooks/useModalState';
import ScheduleContentRequestData from '../../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import AppType from '../../../../enums/appType.enum';
import api from '../../../../api';
import App from '../../../../interfaces/app.interface';
import IContent from '../../../../interfaces/content.interface';
import PowerBiScheduleUtility from '../../../../interfaces/apps/powerBiUtilitySchedule.interface';
import Tooltip from '../../../../components/Tooltip';

export default ({
  app,
  children,
  onUpdate,
}: {
  app: App;
  children?: React.ReactNode;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [isVisible, handleOpen, handleClose] = useModalState(false);

  const handleSubmit = async (data: ScheduleContentRequestData) => {
    try {
      const result: IContent = await api.apps.scheduleApp(
        AppType.PowerBi,
        app.id,
        data,
      );
      const contentId = result.id;
      const utilityId = result.app?.dependency.config?.powerBiUtilityAppId;
      if (contentId && utilityId) {
        const payload: PowerBiScheduleUtility = {
          _id: utilityId,
          contentId,
        };
        await api.powerBi.addToPlaylist(payload);
      }
      toast.success(i18next.t<string>('common.app_scheduled'));
    } finally {
      handleClose();
      onUpdate();
    }
  };

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={handleOpen}
        onClick={handleOpen}
      >
        {children ?? (
          <Tooltip text={t('common.schedule')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-calendar-day" />
            </span>
          </Tooltip>
        )}
      </span>
      <PublishModal
        onClose={handleClose}
        onSubmit={handleSubmit}
        isVisible={isVisible}
        skipOrientationAuto
        skipOrder
        isPowerBiPublished
      />
    </>
  );
};
