import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';

import { YoutubeFeedSchema } from '../../../store/types/apps/youtubeFeed';

export interface DeleteFeedModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (feed: YoutubeFeedSchema) => void;
  title: string;
  selectedYoutubeFeedSchema: YoutubeFeedSchema;
  isLoading: boolean;
}

const DeleteFeedModal = ({
  show,
  onHide,
  onSubmit,
  title,
  selectedYoutubeFeedSchema,
  isLoading = false,
}: DeleteFeedModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={onHide}
      widthDefaultSize={false}
      title={title}
      withFooter
      footerContent={
        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-danger me-2"
            onClick={() => onSubmit(selectedYoutubeFeedSchema)}
          >
            {isLoading ? (
              <span>
                Deleting{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            ) : (
              <span>{t('common.delete_confirmation.confirm')}</span>
            )}
          </button>
          <button
            type="button"
            className="btn btn-outline btn-outline-danger btn-active-danger"
            onClick={onHide}
          >
            {t('common.cancel')}
          </button>
        </div>
      }
    >
      <p>
        Youtube feed <strong>{selectedYoutubeFeedSchema.name}</strong> is
        selected for delete. Are you sure?
      </p>
    </Modal>
  );
};

export default DeleteFeedModal;
