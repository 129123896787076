import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { pick } from 'lodash';
import * as Yup from 'yup';
import UsersGroup from '../../../../interfaces/usersGroup.interface';
import UpdateUsersGroupRequestData from '../../../../api/endpoints/usersGroups/interfaces/updateUsersGroupRequestData.interface';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import { statusesTranslation } from '../../../../constants/translation/users';
import SelectCompany from '../../../../containers/Companies/SelectCompany';
import SelectLocation from '../../../../containers/SelectLocation';
import { UserStatus } from '../../../../enums/userStatus.enum';
import FormErrorMessage from '../../../FormErrorMessage';
import Select from '../../../Select/RegularSelect';
import TextInput from '../../../TextInput';
import Modal from '../../../Modal';

type FormInputs = Partial<UpdateUsersGroupRequestData>;

interface EditGroupFormProps {
  group: UsersGroup;
  onSubmit?: (data: FormInputs) => void;
}

interface DefaultProps extends EditGroupFormProps {
  onSubmit: Required<EditGroupFormProps>['onSubmit'];
}

const EditGroupForm = ({ group, onSubmit }: DefaultProps) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().min(
          1,
          t('users_groups.edit_group.validation.name_length'),
        ),
      }).required(),
    ),
    defaultValues: pick(group, ['name', 'companyId', 'locationId', 'status']),
  });

  const [name, setName] = useStateWithHookForm<
    FormInputs,
    UpdateUsersGroupRequestData['name']
  >({ setValue, trigger, name: 'name' }, group.name ?? '');
  const [status, setStatus] = useStateWithHookForm<
    FormInputs,
    FormInputs['status']
  >({ setValue, trigger, name: 'status' }, group.status);
  const [companyId, setCompanyId] = useStateWithHookForm<
    FormInputs,
    FormInputs['companyId'] | undefined
  >({ setValue, trigger, name: 'companyId' }, group.company?.id ?? '');
  const [locationId, setLocationId] = useStateWithHookForm<
    FormInputs,
    FormInputs['locationId'] | undefined
  >({ setValue, trigger, name: 'locationId' }, group.location?.id ?? '');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="mb-0">
        <label htmlFor="usersGroupName" className="col-form-label text-lg-end">
          {t('users_groups.common.group_name')}
        </label>
        <TextInput
          name="name"
          id="usersGroupName"
          placeholder={t('users_groups.common.group_name')}
          value={name}
          onChange={setName}
        />
        <FormErrorMessage
          name="name"
          errors={errors}
          className="mt-1 min-h-20px"
        />
      </p>
      <p className="mb-0">
        <label htmlFor="company" className="col-form-label text-lg-end pt-0">
          {t('users_groups.common.company')}
        </label>

        <SelectCompany
          id="company"
          onChange={({ value }) => {
            if (value !== 'loadingValue') setCompanyId(value);
          }}
          initialValue={companyId}
          isClearable
          currentUser={group.company.owner}
        />
        <FormErrorMessage
          name="companyId"
          errors={errors}
          className="mt-1 min-h-20px"
        />
      </p>

      <p className="mb-0">
        <label
          htmlFor="userGroupLocation"
          className="col-form-label text-lg-end pt-0"
        >
          {t('users_groups.common.group_location')}
        </label>
        <SelectLocation
          id="userGroupLocation"
          onChange={({ value }) => {
            if (value !== 'loadingValue') setLocationId(value);
          }}
          initialValue={locationId}
          companyId={companyId}
          showDefaultOptions={false}
          isClearable
        />
        <FormErrorMessage
          name="locationId"
          errors={errors}
          className="mt-1 min-h-20px"
        />
      </p>

      <p className="mb-0">
        <label htmlFor="status" className="form-label fw-bold pt-0">
          {t('common.status')}:
        </label>
        <Select<UserStatus>
          id="status"
          placeholder={t('users_groups.common.select_status')}
          defaultValue={
            status && {
              label: t(statusesTranslation[status]),
              value: status,
            }
          }
          onSelect={(selectedStatus) => setStatus(selectedStatus as UserStatus)}
          options={Object.values(UserStatus).map((userStatusValue) => ({
            label: t(statusesTranslation[userStatusValue]),
            value: userStatusValue,
          }))}
        />
        <FormErrorMessage
          name="status"
          errors={errors}
          className="mt-1 min-h-20px"
        />
      </p>

      <Modal.Separator withoutDefaultMargins className="mt-2 mb-7" />
      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-primary">
          {t('common.save')}
        </button>
      </div>
    </form>
  );
};

EditGroupForm.defaultProps = {
  onSubmit: () => null,
};

export default EditGroupForm;
