import React from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { prepareHookFromState } from '../../../../../../utils/hooks/useStateWithHookForm';
import AddSevereWeatherSteps from '../../../../../../enums/steps/addSevereWeatherSteps.enum';
import validation from '../../../../../../utils/validation';
import SelectDevices from '../../../../../../containers/Devices/SelectDevices';
import SelectDevicesGroups from '../../../../../../containers/SelectDevicesGroups';
import { SelectedValue } from '../../../../../Select/SelectAsync';
import Device from '../../../../../../interfaces/devices/device.interface';
import DeviceGroup from '../../../../../../interfaces/deviceGroup.interface';
import FormErrorMessage from '../../../../../FormErrorMessage';
import Stepper from '../../Stepper';
import severeWeatherPic from '../../../../../../assets/images/severeWeathere/alert.png';
import CreateAppRequestData from '../../../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import { severeWeatherCreationValidation } from '../../../../../../validation/apps/severe-weather';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

interface FormInputs {
  name: string;
  devices: SelectedValue[];
  deviceGroupIds: SelectedValue[];
}

export default ({
  data,
  setMode,
  onSubmit,
}: {
  data: any;
  setMode: (mode: AddSevereWeatherSteps) => void;
  onSubmit: (data: Partial<CreateAppRequestData>) => void;
}) => {
  const { t } = useTranslation();
  const defaultValues: Partial<FormInputs> = {
    devices:
      data.devices?.map((device: Device) => ({
        label: device.name,
        value: device.id,
      })) ?? [],
    deviceGroupIds: data.deviceGroups?.map((item: DeviceGroup) => ({
      label: item.name,
      value: item.id,
    })),
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      validation
        .object()
        .shape(severeWeatherCreationValidation(t), [
          ['devices', 'deviceGroupIds'],
        ])
        .required(),
    ),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [devices, setDevices] = useFs<FormInputs['devices']>('devices');
  const [deviceGroupIds, setDeviceGroupIds] =
    useFs<FormInputs['deviceGroupIds']>('deviceGroupIds');

  /**
   * Submits the step data and switches to the next step
   * @param inputsData
   */
  const handleSubmitData = async (inputsData: Partial<FormInputs>) => {
    onSubmit({
      ...data,
      countryId: data.countryId.countryCode,
      countyIds:
        (data.countyIds?.map(
          (item: { value: any }) => item.value,
        ) as string[]) ?? [],
      deviceIds:
        (inputsData?.devices?.map((item) => item.value) as string[]) ?? [],
      deviceGroupIds:
        (inputsData.deviceGroupIds?.map((item) => item.value) as string[]) ??
        [],
    });

    toast.success(i18next.t<string>('apps.severeWeather.severe_created'));
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitData)}>
      <div className="d-flex justify-content-between">
        <Stepper
          currentStep={AddSevereWeatherSteps.AssignSevereWeatherLocation}
          className="mb-9 col-1 ms-0"
        />
        <div className="d-flex flex-column justify-content-end col">
          <img
            src={severeWeatherPic}
            alt="severe weather preview"
            className="w-100 mb-5"
          />
          <div className="w-md-400px">
            <div className="mb-6">
              <span className="fw-boldest fs-5 mb-3 d-block">
                Name - County
              </span>
              <span>
                {data.name} - {data.countryId?.name}
              </span>
            </div>
            <div className="mb-3">
              <label htmlFor="devices" className="form-label fw-boldest">
                {t('devices.devices')}
              </label>
              <SelectDevices
                initialValue={devices.map(({ value }) => value as string)}
                onChange={(selectedValue) => {
                  setDevices(selectedValue);
                }}
                hideLicence
              />
              <FormErrorMessage
                name="devices"
                errors={errors}
                className={errorMessageClassNames}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="deviceGroupIds" className="form-label fw-boldest">
                {t('devices_groups.devices_groups')}
              </label>
              <SelectDevicesGroups
                initialValue={deviceGroupIds}
                onChange={(selectedValue) => {
                  setDeviceGroupIds(selectedValue);
                }}
              />
              <FormErrorMessage
                name="deviceGroupIds"
                errors={errors}
                className={errorMessageClassNames}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-5">
        <button
          type="button"
          className="btn btn-white text-primary"
          onClick={() =>
            setMode(AddSevereWeatherSteps.CreateSevereWeatherLocation)
          }
        >
          {t('common.prev_step')}
        </button>
        <button type="submit" className="btn btn-primary">
          {t('common.create')}
        </button>
      </div>
    </form>
  );
};
