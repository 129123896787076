import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import Timezone from '../../../enums/timezone.enum';
import DevicesQueryParam from '../../../api/endpoints/devices/enums/devicesQueryParam.enum';
import SelectDeviceGroup from '../../../containers/DevicesGroups/SelectGroup';
import { debouncedUpdateSearch } from '../../../utils/browserHistory';
import SelectTimezoneId from '../../../containers/SelectTimezoneId';
import FiltersDropdown from '../../Dropdown/FiltersDropdown';
import useFilter from '../../../utils/hooks/useFilter';
import SelectLocation from '../../../containers/SelectLocation';
import { readFromQueryString } from '../../../utils/queryParams';

interface FiltersDropdownProps {
  className?: string;
  skipFilters?: DevicesQueryParam[];
  isAdmin?: boolean;
  companyId?: string;
  UserId?: string;
}

interface DefaultProps extends FiltersDropdownProps {
  skipFilters: Required<FiltersDropdownProps>['skipFilters'];
  locationsList?: any;
}

const FiltersDropDown = ({
  className,
  skipFilters,
  UserId,
  isAdmin,
  locationsList,
  companyId,
}: DefaultProps) => {
  const { t } = useTranslation();
  const [location, setLocation] = useFilter<string>(DevicesQueryParam.Location);
  const [timezone, setTimezone] = useFilter<Timezone>(
    DevicesQueryParam.Timezone,
  );
  const [groupValue, setGroupValue] = useFilter<string>(
    DevicesQueryParam.GroupId,
  );

  const handleApply = () =>
    debouncedUpdateSearch({
      [DevicesQueryParam.Timezone]: timezone ?? '',
      [DevicesQueryParam.GroupId]: groupValue ?? '',
      [DevicesQueryParam.Location]: location ?? '',
    });
  const { search } = useLocation();
  const currentUserId =
    UserId || (readFromQueryString(search, 'currentUserId') as string);
  // Reset filters in the query params
  const handleReset = () =>
    debouncedUpdateSearch({
      [DevicesQueryParam.Timezone]: '',
      [DevicesQueryParam.GroupId]: '',
      [DevicesQueryParam.Location]: '',
    });

  return (
    <FiltersDropdown
      className={className}
      onApplyFilters={handleApply}
      onReset={handleReset}
    >
      {!skipFilters.includes(DevicesQueryParam.Location) && (
        <div className="mb-10">
          <label htmlFor="devicelocation" className="form-label fw-bold">
            {t('common.location')}:
          </label>
          <SelectLocation
            id="devicelocation"
            initialValue={location}
            onChange={({ value }) => {
              if (value !== 'loadingValue') setLocation(value);
            }}
            locationsList={locationsList}
            companyId={companyId}
            currentUserId={currentUserId}
          />
        </div>
      )}
      {!skipFilters.includes(DevicesQueryParam.Timezone) && (
        <div className="mb-10">
          <label htmlFor="deviceTimezone" className="form-label fw-bold">
            {t('common.timezone')}:
          </label>
          <SelectTimezoneId
            id="deviceTimezone"
            initialValue={timezone}
            onChange={({ value }) => setTimezone(value as Timezone)}
          />
        </div>
      )}

      {!skipFilters.includes(DevicesQueryParam.GroupId) && (
        <div className="mb-10">
          <label htmlFor="deviceGroup" className="form-label fw-bold">
            {t('devices.device_group')}:
          </label>
          <SelectDeviceGroup
            isAdmin={isAdmin}
            id="deviceGroup"
            initialValue={groupValue}
            onChange={({ value }) => {
              setGroupValue(value);
            }}
            UserId={currentUserId}
          />
        </div>
      )}
    </FiltersDropdown>
  );
};

FiltersDropDown.defaultProps = {
  skipFilters: [],
};

export default FiltersDropDown;
