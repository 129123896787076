import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../Table';
import App from '../../../../../interfaces/app.interface';
import { AppDependency } from '../../../../../store/types/apps/globalTypes';
import EditQrCode from '../../../../../containers/Apps/QrCode/EditQrCode';
import DeleteQrCode from '../../../../../containers/Apps/DeleteApp';
import ScheduleQrCodec from '../../../../../containers/Apps/ScheduleApp';
import { formatDateString } from '../../../../../utils/dateTime';
import PlacementsEnum from '../../../../../enums/placements.enum';
import Yup from '../../../../../utils/validation';
import PreviewFeedModal from '../../PreviewFeedModal';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({
  qrCode,
  onUpdate,
}: {
  qrCode: App;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [showModalFeed, setShowModalFeed] = useState(false);

  return (
    <Row>
      <Cell isEmpty={!qrCode.name}>
        <div onClick={() => setShowModalFeed(true)} aria-hidden>
          <span className="text-dark cursor-pointer fw-bold text-hover-primary d-block mb-1 fs-6 w-100">
            {qrCode.name}
          </span>
        </div>
        <PreviewFeedModal
          show={showModalFeed}
          onHide={() => {
            setShowModalFeed(false);
          }}
          title={t('common.preview')}
          selectedQrCodeFeedSchema={{
            type: 'QrCode',
            createdAt: qrCode!.createdAt,
            updatedAt: qrCode!.updatedAt,
            dependency: qrCode!.dependency as AppDependency,
            id: qrCode!.id,
            name: qrCode!.name,
            placement: qrCode!.placement as PlacementsEnum,
          }}
        />
      </Cell>

      <Cell isEmpty={!qrCode.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {qrCode.ownerApp?.firstName} {qrCode.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!qrCode.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {qrCode.createdAt && formatDate(qrCode.createdAt)}
        </span>
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          <div className="mx-1">
            <EditQrCode qrCode={qrCode} onUpdate={onUpdate} />
          </div>

          {PlacementsEnum.FULL_SCREEN && (
            <div className="mx-1">
              <ScheduleQrCodec
                app={qrCode}
                onUpdate={onUpdate}
                infoValidationSchema={{
                  orientation: Yup.string().required(t('required')),
                  duration: Yup.number().required(t('required')).min(1),
                  startDate: Yup.date(),
                  endDate: Yup.date().when('startDate', {
                    is: (startDate: any) => !!startDate,
                    then: Yup.date().min(
                      Yup.ref('startDate'),
                      t('common.validation.endDate'),
                    ),
                  }),
                  isLocked: Yup.boolean(),
                }}
                skipOrientationAuto
                skipOrder
              />
            </div>
          )}

          <div className="mx-1">
            <DeleteQrCode
              title={t('common.delete_confirmation.qr_code')}
              app={qrCode}
              onUpdate={onUpdate}
              toastMessage="apps.qrCode.qr_code_deleted"
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default TableRow;
