import axios from 'axios';
import { API_URL } from '../../../constants/api';
import TimezonesRoutes from './enums/timezonesRoutes.enum';
import GetTimezonesRequestData from './interfaces/getTimezonesRequestData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import Timezone from '../../../interfaces/timezone.interface';
import County from '../../../interfaces/county.interface';

export default {
  /**
   * Retrieves the list of timezones
   * @param requestData
   */
  getTimezones: async (
    requestData: GetTimezonesRequestData,
  ): Promise<Timezone[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${TimezonesRoutes.Timezones}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the timezone by id
   * @param id
   */
  getTimezone: async (id: string): Promise<County> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${TimezonesRoutes.Timezones}/${id}`,
    });

    return data;
  },
};
