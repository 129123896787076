import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DeviceGroupInfo from '../../components/DevicesGroups/DeviceGroupInfo';
import UpdateDeviceGroupAction from '../../store/actions/devicesGroups/interfaces/updateDeviceGroupAction.interface';
import api from '../../api';
import DeviceGroup from '../../interfaces/deviceGroup.interface';
import PlaylistsTable from '../../containers/DeviceGroupInfo/PlaylistsTable';
import UsersTable from '../../containers/DeviceGroupInfo/UsersTable';
import UserGroups from '../../containers/DeviceGroupInfo/UserGroupsTable';
import DevicesTable from '../../containers/DeviceGroupInfo/DevicesTable';
import NavTabs from '../../components/NavTabs';
import GlobalState from '../../store/reducers/globalState.interface';
import { UserRole } from '../../enums/userRole.enum';
import { readFromQueryString } from '../../utils/queryParams';

export default () => {
  const [deviceGroup, setDeviceGroup] = useState<Partial<DeviceGroup>>({});
  const [isLoadingDeviceGroupInfo, setIsLoadingDeviceGroupInfo] =
    useState<boolean>(true);
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { id, page } = useParams<{
    id: string;
    page: string;
  }>();
  const currentUserId = readFromQueryString(search, 'currentUserId');
  const isSuperAdmin = useSelector((state: GlobalState) =>
    state.userData.user.roles.includes(UserRole.SuperAdmin),
  );

  const navTabs = [
    {
      key: 'all-devices',
      tab: (
        <Link
          to={
            !isSuperAdmin
              ? `/devices-groups/${id}/devices`
              : `/devices-groups/${id}/devices?currentUserId=${currentUserId}`
          }
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'devices' && 'active',
          )}
        >
          {t('devices_groups.device_group_tab.all_devices')}
        </Link>
      ),
    },
    {
      key: 'users-with-access',
      tab: (
        <Link
          to={
            !isSuperAdmin
              ? `/devices-groups/${id}/users`
              : `/devices-groups/${id}/users?currentUserId=${currentUserId}`
          }
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'users' && 'active',
          )}
        >
          {t('devices_groups.device_group_tab.users_with_access')}
        </Link>
      ),
    },
    {
      key: 'user-groups',
      tab: (
        <Link
          to={
            !isSuperAdmin
              ? `/devices-groups/${id}/user-groups`
              : `/devices-groups/${id}/user-groups?currentUserId=${currentUserId}`
          }
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'user-groups' && 'active',
          )}
        >
          {t('devices_groups.device_group_tab.user_groups_with_access')}
        </Link>
      ),
    },
    {
      key: 'playlists',
      tab: (
        <Link
          to={
            !isSuperAdmin
              ? `/devices-groups/${id}/playlists`
              : `/devices-groups/${id}/playlists?currentUserId=${currentUserId}`
          }
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'playlists' && 'active',
          )}
        >
          {t('playlists.playlists')}
        </Link>
      ),
    },
  ];

  const getDeviceGroup = async () => {
    try {
      setIsLoadingDeviceGroupInfo(true);
      const data = await api.devicesGroups.getDeviceGroup({
        deviceGroupId: id,
      });
      setDeviceGroup(data);
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingDeviceGroupInfo(false);
  };

  useEffect(() => {
    setIsLoadingDeviceGroupInfo(true);
    getDeviceGroup();
  }, []);

  const handleEditDeviceGroup = async (
    data: UpdateDeviceGroupAction['payload'],
  ) => {
    try {
      setIsLoadingDeviceGroupInfo(true);

      await api.devicesGroups.updateDeviceGroup(data.data, data.id);
      setDeviceGroup({ ...deviceGroup, ...data.data });
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingDeviceGroupInfo(false);
  };

  const handleRemoveDeviceGroup = async () => {
    try {
      await api.devicesGroups.deleteDeviceGroup(id);
      history.push('/');
    } catch (e) {
      // Do nothing for now
    }
  };
  return (
    <DeviceGroupInfo
      group={deviceGroup}
      isLoading={isLoadingDeviceGroupInfo}
      onEditDeviceGroup={handleEditDeviceGroup}
      onRemoveDeviceGroup={handleRemoveDeviceGroup}
    >
      {page === 'playlists' && (
        <PlaylistsTable
          disableTitle
          toolbarElement={<NavTabs tabs={navTabs} separatorClassName="mb-7" />}
        />
      )}

      {page === 'users' && (
        <UsersTable>
          <NavTabs tabs={navTabs} separatorClassName="mb-7" />
        </UsersTable>
      )}

      {page === 'user-groups' && (
        <UserGroups>
          <NavTabs tabs={navTabs} separatorClassName="mb-7" />
        </UserGroups>
      )}

      {page === 'devices' && (
        <DevicesTable
          onGetDeviceGroup={getDeviceGroup}
          currentUserId={currentUserId}
        >
          <NavTabs tabs={navTabs} separatorClassName="mb-7" />
        </DevicesTable>
      )}
    </DeviceGroupInfo>
  );
};
