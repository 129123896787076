import { map } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import SortOrder from '../../../../enums/sortOrder.enum';
import App from '../../../../interfaces/app.interface';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../../utils/hooks/useTableMultiSelect';
import { readFromQueryString } from '../../../../utils/queryParams';
import Table from '../../../Table';
import Columns from '../../../Table/Columns';
import WithSpinner from '../../../WithSpinner';
import EmptyFiles from '../../../../pages/FileManager/EmptyFiles';
import TableRow from './TableRow';
import styles from './TableRow/tablerow.module.scss';

export default ({
  facebook,
  isLoading,
  sortQueryField,
  orderQueryField,
  onUpdate,
  preloaderHeight = 300,
  isSelectable = false,
  multiSelectConfig: { selectedItems, selectAll },
}: {
  facebook: App[];
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight: number;
  isSelectable?: boolean;
  onUpdate: () => void;
  multiSelectConfig: TableMultiSelectConfig;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  const pageNameList: string[] = [];

  const createPageNameList = async () => {
    facebook.map((fb) => {
      const pageName = fb?.dependency?.config?.pageName;
      if (!pageNameList.includes(pageName)) {
        pageNameList.push(pageName);
      }
      return pageNameList;
    });
  };

  const columns = [
    {
      name: t('apps.facebook.table.title'),
    },
    {
      name: t('apps.facebook.table.page'),
    },
    {
      name: t('apps.facebook.table.type'),
    },

    {
      name: t('apps.common.table.createdBy'),
    },
    {
      name: t('apps.facebook.table.dateCreated'),
    },
    {
      name: t('common.actions'),
      className: clsx('text-center w-1', styles.actions),
    },
  ];

  React.useEffect(() => {
    createPageNameList();
  }, [facebook]);

  return (
    <Table>
      <Table.Head.Row>
        <Columns
          columns={columns}
          checked={isAllSelected(selectedItems, map(facebook, 'id'))}
          onChange={selectAll}
          config={{
            sortField,
            setSortField,
            sortQueryField,
            orderQueryField,
            isSelectable,
          }}
        />
      </Table.Head.Row>

      <Table.Body
        isEmpty={!isLoading && !facebook.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={
          <EmptyFiles descriptionText={t('apps.facebook.noFacebookFound')} />
        }
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {facebook.map((fb) => (
            <TableRow
              pagesNameList={pageNameList}
              key={fb.id}
              facebook={fb}
              onUpdate={onUpdate}
            />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
