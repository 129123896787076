import clsx from 'clsx';
import i18next from 'i18next';
import { compact, omit, uniq } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import api from '../../api';
import ScheduleContentRequestData from '../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import ScheduleContentSteps from '../../enums/steps/scheduleContentSteps.enum';
import WithModesModal from '../WithModesModal';
import DevicesAndGroups from './Steps/DevicesAndGroups';
import Info from './Steps/Info';
import Playlists from './Steps/Playlists';

export default ({
  onSubmit,
  isVisible,
  onClose,
  skipOrder,
  skipOrientationAuto,
  infoValidationSchema,
  scheduleYoutubeApp,
  defaultValues = {},
  hidePortrait,
  eventDate,
  isCountDown,
  isPowerBiPublished,
}: {
  onSubmit: (data: ScheduleContentRequestData) => void;
  onClose?: () => void;
  isVisible: boolean;
  scheduleYoutubeApp?: boolean;
  skipOrder?: boolean;
  skipOrientationAuto?: boolean;
  infoValidationSchema?: { [key: string]: any };
  defaultValues?: Partial<ScheduleContentRequestData>;
  hidePortrait?: boolean;
  eventDate?: Date;
  isCountDown?: boolean;
  isPowerBiPublished?: boolean;
}) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] =
    useState<Partial<ScheduleContentRequestData>>(defaultValues);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let selectedPlaylists: string[] = [];
      if (stepsData.deviceGroupIds) {
        // add deviceGroupIds criteria
        const { items } = await api.devices.getDevicesList({
          deviceGroupIds: stepsData.deviceGroupIds,
          limit: '600',
        });
        selectedPlaylists = [
          ...selectedPlaylists,
          ...compact(items.map((device) => device?.currentPlaylist?.id)),
        ];
      }
      if (stepsData.deviceIds) {
        const { items } = await api.devices.getDevicesList({ limit: '600' });
        selectedPlaylists = [
          ...selectedPlaylists,
          ...compact(
            items.map((device) =>
              stepsData.deviceIds?.includes(device.id)
                ? device?.currentPlaylist?.id
                : null,
            ),
          ),
        ];
      }
      if (!selectedPlaylists || selectedPlaylists.length === 0) {
        toast.error(i18next.t('contents.validation.no_current_playlist'));
        return;
      }
      stepsData.playlistIds = [...uniq(selectedPlaylists)];
      const data = omit(stepsData, ['onlyCurrentSelected']);
      onSubmit(data as ScheduleContentRequestData);
      onClose?.();
      setStepsData({});
    } catch (e: any) {
      toast.error(i18next.t(e?.message ?? ''));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <WithModesModal<any, ScheduleContentSteps>
      submitButtonText="Schedule"
      isVisible={isVisible}
      onClose={onClose}
      modes={{
        [ScheduleContentSteps.Info]: {
          title: t('common.publish'),
          render: ({ setMode, close }) => (
            <Info
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onDecline={close}
              scheduleYoutubeApp={scheduleYoutubeApp}
              validationSchema={infoValidationSchema}
              skipOrder={skipOrder}
              skipOrientationAuto={skipOrientationAuto}
              hidePortrait={hidePortrait}
              eventDate={eventDate}
              isCountDown={isCountDown}
              isPowerBiPublished={isPowerBiPublished}
            />
          ),
        },
        [ScheduleContentSteps.Playlists]: {
          title: t('common.publish'),
          render: ({ setMode, close }) => (
            <Playlists
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onSubmit={() => {
                onSubmit(stepsData as ScheduleContentRequestData);
                close();
                setStepsData({});
              }}
            />
          ),
        },
        [ScheduleContentSteps.Devices]: {
          title: t('common.publish'),
          render: ({ setMode }) => (
            <DevicesAndGroups
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onSubmit={handleSubmit}
              isLoading={isLoading}
              isPowerBiPublished={isPowerBiPublished}
              isOptional={!isPowerBiPublished}
            />
          ),
        },
      }}
      defaultMode={ScheduleContentSteps.Info}
      dialogClassName={clsx('mw-1000px')}
    />
  );
};
