import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routesConfig from '../../../../routing/config';
import GlobalState from '../../../../store/reducers/globalState.interface';
import { UserRole } from '../../../../enums/userRole.enum';

export default ({
  isDrawerOpened,
  onLinkClick,
  children,
  marginTop,
}: {
  isDrawerOpened?: boolean;
  marginTop?: boolean;
  onLinkClick?: () => void;
  children: React.ReactNode | React.ReactNode[];
}) => {
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const handleClick = ({
    target,
  }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((target as Element).closest('a') && onLinkClick) {
      onLinkClick();
    }
  };
  const loginAsUserName = window.localStorage.getItem('loginAsUserName');

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={clsx(
        'aside aside-white aside-hoverable h-100 min-w-250px drawer drawer-start',
        isDrawerOpened && 'drawer-on',
        marginTop && 'mt-40px',
        roles?.includes(UserRole.SuperAdmin) &&
          !loginAsUserName &&
          'aside-pink',
      )}
      role="button"
      tabIndex={-1}
      onClick={handleClick}
    >
      <div className="aside-logo flex-column-auto mt-3 ms-2">
        <Link to={routesConfig.home.route}>
          <img alt="Logo" src="/media/svg/indigo.svg" className="h-45px" />
        </Link>
      </div>

      <div className="aside-menu flex-column-fluid">
        <div className="hover-scroll-overlay-y my-5 my-lg-5">
          <div className="ps-4 pe-4 pt-3 menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
