import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../../components/FormErrorMessage';
import WithSpinner from '../../../components/WithSpinner';
import TextInput from '../../../components/TextInput';
import validation from '../../../utils/validation';
import Modal from '../../../components/Modal';
import api from '../../../api';

interface FormInputs {
  email: string;
}

export default ({ handleSave }: { handleSave: () => void }) => {
  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(
      validation
        .object({
          email: validation
            .string()
            .required(t('users.create_user.validation.please_enter_email'))
            .isEmail(
              t('users.create_user.validation.please_enter_valid_email'),
            ),
        })
        .required(),
    ),
  });

  const [email, setEmail] = useStateWithHookForm<
    FormInputs,
    FormInputs['email']
  >({
    setValue,
    trigger,
    name: 'email',
  });

  const handleSubmitData = async (formInputs: FormInputs) => {
    setIsLoading(true);

    try {
      await api.account.resetEmail(formInputs.email);
      handleSave();
      close();
      toast.success(t('success_messages.reset_email'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-secondary btn-sm px-5 me-3 w-100 mb-3 mt-3"
        onKeyPress={open}
        onClick={open}
      >
        {t('my_account.left_table.reset_email')}
      </button>

      <Modal
        title={t('my_account.left_table.reset_email')}
        bodyClassName="py-5"
        show={modalShown}
        onHide={close}
        withFooter={false}
        widthDefaultSize={false}
      >
        <WithSpinner
          isLoading={isLoading}
          size="md"
          style={{ minHeight: '300px' }}
        >
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <h4 className="fw-boldest mb-2">
              {t('my_account.left_table.email')}
            </h4>
            <TextInput
              name="email"
              id="email"
              placeholder={t('my_account.left_table.email')}
              value={email}
              onChange={setEmail}
            />
            <FormErrorMessage
              name="email"
              errors={errors}
              className="mt-1 min-h-20px mb-2"
            />

            <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />

            <div className="d-flex justify-content-end mt-5">
              <button type="submit" className="btn btn-primary">
                {t('common.submit')}
              </button>
            </div>
          </form>
        </WithSpinner>
      </Modal>
    </>
  );
};
