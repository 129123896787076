import React from 'react';

import RegularSelect, { Option } from '../../components/Select/RegularSelect';

const SelectCustomOrder = ({
  onChange,
  initialValue,
  total,
}: {
  onChange: (selectedValue: any) => void;
  initialValue?: Option;
  total: number;
}) => {
  const customOrder =
    // isEdit ? Array.from({ length: total + 1 }, (v, k) => `${k}`):
    Array.from({ length: total + 1 }, (v, k) => `${k + 1}`);

  return (
    <RegularSelect
      defaultValue={initialValue}
      options={Object.values(customOrder).map((item) => ({
        label: item,
        value: item,
      }))}
      onSelect={(value: string | string[]) =>
        onChange(
          value instanceof Array
            ? value.map((val) => Number.parseInt(val, 10))
            : Number.parseInt(value, 10),
        )
      }
    />
  );
};

SelectCustomOrder.defaultProps = {
  initialValue: [],
};

export default SelectCustomOrder;
