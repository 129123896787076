import ReduxAction from '../../actions/reduxAction.interface';
import CompaniesState from './companiesState.interface';
import CompaniesActions from '../../actions/companies/companies';
import GetCompaniesListSuccessAction from '../../actions/companies/interfaces/getCompaniesListSuccessAction.interface';
import LoadingAction from '../../actions/loadingAction.interface';

const initialState: CompaniesState = {
  loading: true,
  companies: [],
  total: 0,
  adminTotal: 0,
};

export default function companiesReducer(
  state = initialState,
  action: ReduxAction<CompaniesActions>,
): CompaniesState {
  switch (action.type) {
    case CompaniesActions.GET_COMPANIES_LIST_SUCCESS: {
      const {
        payload: { items, meta },
      } = action as GetCompaniesListSuccessAction;

      return {
        ...state,
        companies: items,
        total: meta.totalItems,
      };
    }
    case CompaniesActions.SET_COMPANIES_LOADING:
      return {
        ...state,
        ...(action as LoadingAction<CompaniesActions.SET_COMPANIES_LOADING>)
          .payload,
      };
    default:
      return state;
  }
}
