import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasArrow?: boolean;
  hasBullet?: boolean;
  className?: string;
};

export default ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  className,
}: Props) => (
  <div className={clsx('menu-item me-lg-1 ms-2', className)}>
    <Link className="menu-link py-3" to={to}>
      {hasBullet && (
        <span className="menu-bullet">
          <span className="bullet bullet-dot" />
        </span>
      )}

      {icon && (
        <span className="menu-icon">
          <i className={icon} />
        </span>
      )}

      {fontIcon && (
        <span className="menu-icon">
          <i className={clsx('bi fs-3', fontIcon)} />
        </span>
      )}

      <span>{title}</span>

      {hasArrow && <span className="menu-arrow" />}
    </Link>
  </div>
);
