import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import CreateContentRequestData from '../../../../../api/endpoints/contents/interfaces/createContentRequestData.interface';
import AssignDevicesGroups from '../../../../../containers/DevicesGroups/AssignDevicesGroups';
import AddContentSteps from '../../../../../enums/steps/addContentSteps.enum';
import AssignDevices from '../../../../../containers/Devices/AssignDevices';
import StepsTitle from '../../StepsTitle';
import Modal from '../../../../Modal';
import GlobalState from '../../../../../store/reducers/globalState.interface';
import Tooltip from '../../../../Tooltip';

enum Tab {
  Devices = 'devices',
  DevicesGroups = 'devices-groups',
}

const tabsTranslation = {
  [Tab.Devices]: 'contents.create_content.devices_and_groups_tabs.devices',
  [Tab.DevicesGroups]:
    'contents.create_content.devices_and_groups_tabs.devices_groups',
} as const;

interface AssignDevicesAndGroupsProps {
  data: Partial<CreateContentRequestData>;
  setData: (newData: Partial<CreateContentRequestData>) => void;
  setMode: (mode: AddContentSteps) => void;
  onSubmit: () => void;
  skipLicence?: boolean;
  skipSteps?: AddContentSteps[];
}

interface DefaultProps extends AssignDevicesAndGroupsProps {
  skipSteps: Required<AssignDevicesAndGroupsProps>['skipSteps'];
}

const AssignDevicesAndGroups = ({
  data,
  setData,
  setMode,
  skipSteps,
  skipLicence,
  onSubmit,
}: DefaultProps) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(Tab.Devices);
  const nextStep = !skipSteps.includes(AddContentSteps.Playlists)
    ? AddContentSteps.Playlists
    : undefined;

  let currentTable: React.ReactNode;

  const [currentPlaylistChecked, setCurrentPlaylistChecked] =
    useState<boolean>(true);
  const [currentPlaylistDisabled, setCurrentPlaylistDisabled] =
    useState<boolean>(true);
  const { isTrial } = useSelector((state: GlobalState) => state.userData.user);

  useEffect(() => {
    setData({
      ...data,
      onlyCurrentSelected: true,
    });
  }, []);

  const handleCurrentPlaylistChange = () => {
    setData({ ...data, onlyCurrentSelected: !currentPlaylistChecked });
    setCurrentPlaylistChecked(!currentPlaylistChecked);
  };
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const handleSubmit = () => {
    setConfirmSubmit(true);
    onSubmit();
  };

  switch (currentTab) {
    case Tab.Devices:
      currentTable = (
        <AssignDevices
          skipLicence={skipLicence}
          preselectedItems={data.deviceIds ?? []}
          onSelectionChange={(selectedItems) => {
            setData({
              ...data,
              deviceIds: selectedItems,
            });
            setCurrentPlaylistDisabled(!selectedItems.length);
          }}
        />
      );
      break;
    case Tab.DevicesGroups:
      currentTable = (
        <AssignDevicesGroups
          preselectedItems={data.deviceGroupsIds ?? []}
          onSelectionChange={(selectedItems) => {
            setData({ ...data, deviceGroupsIds: selectedItems });
            setCurrentPlaylistDisabled(!selectedItems.length);
          }}
        />
      );
      break;
    default:
      currentTable = '';
  }

  const handleNextStep = (step: AddContentSteps) => {
    if (currentTab === Tab.Devices && !data.deviceIds?.length) {
      setData({ ...data, onlyCurrentSelected: false });
    }
    setMode(step);
  };

  return (
    <>
      <StepsTitle
        currentStep={AddContentSteps.DevicesAndGroups}
        skipSteps={skipSteps}
      />

      <div className="d-flex justify-content-center mb-5">
        {Object.values(Tab).map((tab, index) =>
          isTrial && tab === Tab.DevicesGroups ? (
            <Tooltip
              text={t('devices_groups.trial_plan')}
              className={clsx(
                'btn w-100 py-4',
                tab === currentTab
                  ? 'btn-bg-light btn-color-primary'
                  : 'btn-bg-secondary btn-color-muted',
                !index && 'me-7',
              )}
            >
              <span key={tab} role="button" tabIndex={-1}>
                {t(tabsTranslation[tab])}
                <i
                  className="bi bi-lock-fill ms-3"
                  style={{ fontSize: '1.4rem' }}
                />
              </span>
            </Tooltip>
          ) : (
            <span
              key={tab}
              role="button"
              tabIndex={-1}
              className={clsx(
                'btn w-100 py-4',
                tab === currentTab
                  ? 'btn-bg-light btn-color-primary'
                  : 'btn-bg-secondary btn-color-muted',
                !index && 'me-7',
              )}
              onClick={() => setCurrentTab(tab)}
              onKeyPress={() => setCurrentTab(tab)}
            >
              {t(tabsTranslation[tab])}
            </span>
          ),
        )}
      </div>

      {currentTable}

      <Modal.Separator withoutDefaultMargins className="mt-6 mb-7" />
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-white text-primary"
          onClick={() => setMode(AddContentSteps.Info)}
        >
          {t('common.prev_step')}
        </button>

        {!currentPlaylistDisabled && (
          <div className="form-check my-2">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              checked={currentPlaylistChecked}
              onChange={handleCurrentPlaylistChange}
              disabled={currentPlaylistDisabled}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              {t('contents.send_current_playlist')}
            </label>
          </div>
        )}

        {nextStep &&
        nextStep === AddContentSteps.Playlists &&
        (!currentPlaylistChecked || currentPlaylistDisabled) ? (
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => handleNextStep(nextStep)}
          >
            {t('common.next_step')}
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-primary"
            disabled={confirmSubmit}
            onClick={handleSubmit}
          >
            {t('common.submit')}
          </button>
        )}
      </div>
    </>
  );
};

AssignDevicesAndGroups.defaultProps = {
  skipSteps: [],
};

export default AssignDevicesAndGroups;
