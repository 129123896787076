import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EditUser from '../../../../containers/Users/EditUser';
import RemoveUser from '../../../../containers/Users/RemoveUser';
import ResetUserPassword from '../../../../containers/Users/ResetUserPassword';
import CreateUserRequestData from '../../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import RestoreUser from '../../../../containers/Users/RestoreUser';
import SuspendUser from '../../../../containers/Users/SuspendUser';
import { UserStatus } from '../../../../enums/userStatus.enum';
import User from '../../../../interfaces/user/user.interface';
import routesConfig from '../../../../routing/config';
import pointerEventsStyles from '../../../../styles/pointerEvents.module.scss';
import CheckBox from '../../../CheckBox';
import Tooltip from '../../../Tooltip';
import Table from '../../../Table';
import {
  rolesTranslation,
  statusesTranslation,
} from '../../../../constants/translation/users';
import GlobalState from '../../../../store/reducers/globalState.interface';
import ShowMoreCell from '../../../ShowMoreCell';
import { UserRole } from '../../../../enums/userRole.enum';

const { Row } = Table.Body;
const { Cell } = Row;

interface TableRowProps {
  user: User;
  config?: {
    skipStatus?: boolean;
    skipGroupAccess?: boolean;
    skipDevicesNumber?: boolean;
    skipActions?: boolean;
    disableLinks?: boolean;
    skipEditSuspendReset?: boolean;
    isSelectable?: boolean;
  };
  isChecked?: boolean;
  onCheck?: (id: string) => void;
}

interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
  isChecked: Required<TableRowProps>['isChecked'];
  onCheck: Required<TableRowProps>['onCheck'];
  onEditUser?: (userId: string, data: Partial<CreateUserRequestData>) => void;
  unAssignUser?: (userId: string, userGroupId: string) => void;
  onRestoreUser?: (userId: string) => void;
  onSuspendUser?: (userId: string) => void;
  onArchiveUser?: (userId: string) => void;
  onUnssingUserFromDeviceGroup?: (userId: string, userGroupId: string) => void;
  userGroupInfo?: boolean;
}

const UsersTableRow = ({
  user,
  config,
  isChecked,
  onCheck,
  onEditUser,
  unAssignUser,
  onRestoreUser,
  userGroupInfo,
  onSuspendUser,
  onArchiveUser,
  onUnssingUserFromDeviceGroup,
}: DefaultProps) => {
  const { t } = useTranslation();
  const currentUser = useSelector((state: GlobalState) => state.userData.user);
  const userRole = user.roles?.[0];
  const selfId = currentUser?.id;
  const currentUserRole = currentUser?.roles?.[0];

  // lets check if they can do actions to the user.
  const canActions =
    currentUserRole === UserRole.AccountOwner ||
    currentUserRole === UserRole.SuperAdmin ||
    (currentUserRole === UserRole.Admin &&
      [UserRole.Manager, UserRole.Employee].includes(userRole)) ||
    (currentUserRole === UserRole.Manager && userRole === UserRole.Employee);

  return (
    <Row>
      {config.isSelectable && (
        <Cell>
          <CheckBox
            name="deviceId"
            id={user.id}
            onChange={() => onCheck(user.id)}
            checked={isChecked}
            className="mt-1"
          />
        </Cell>
      )}

      <Cell isNoWrap className="d-flex align-items-center">
        <div className="symbol symbol-45px overflow-hidden me-3">
          <div className="symbol-label">
            <img src={user.profilePicture} alt="user" className="w-100" />
          </div>
        </div>
        <div className="d-flex flex-column">
          <Link
            to={routesConfig.user.route
              .replace(':id', user.id)
              .replace(':page', 'devices')}
            className={clsx(
              'text-gray-800 text-hover-primary mb-1',
              config.disableLinks && pointerEventsStyles.noPointerEvents,
            )}
          >
            {user.firstName} {user.lastName}
          </Link>
          <span className="text-muted">{user.email}</span>
        </div>
      </Cell>
      <Cell isEmpty={!user?.assignedCompanies?.[0]?.name}>
        {user?.assignedCompanies?.length > 1 ? (
          <ShowMoreCell
            itemNames={user?.assignedCompanies?.map((company) => company?.name)}
            widthDefaultSize
            title={t('companies.companies')}
            bodyClassName="justify-content-center"
          >
            <div className="row w-100">
              {user?.assignedCompanies?.map((company) => (
                <div className="col-lg-3 col-sm-12 mb-5">
                  <span
                    className={clsx(
                      'w-100 text-center bg-light text-primary rounded p-2 d-block',
                    )}
                  >
                    {company && company.name}
                  </span>
                </div>
              ))}
            </div>
          </ShowMoreCell>
        ) : (
          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center hoverable">
            {user?.assignedCompanies?.[0]?.name}
          </span>
        )}
      </Cell>
      <Cell isEmpty={!user.roles?.length}>
        <span
          className={clsx('text-dark fw-bold d-block mb-1 fs-6 text-center')}
        >
          {userRole && t(rolesTranslation[userRole])}
        </span>
      </Cell>
      {!config.skipStatus && (
        <Cell isNoWrap className="text-center">
          <span
            className={clsx(
              'badge fs-7 py-3 px-7 fw-bold',
              user.status === UserStatus.Active
                ? 'badge-light-success'
                : 'badge-light',
            )}
          >
            {t(statusesTranslation[user.status] ?? user.status)}
          </span>
        </Cell>
      )}
      {!config.skipDevicesNumber && (
        <Cell>
          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center hoverable">
            {user.devicesCount}
          </span>
        </Cell>
      )}
      {!config.skipGroupAccess && (
        <Cell isEmpty={!user?.groups?.length} className="text-center">
          {user.groups.map((group, index) => (
            <span
              key={group}
              className={clsx(
                'badge fs-7 py-3 px-7 fw-bold badge-light-primary mb-2',
                index && 'ms-2',
              )}
            >
              {group}
            </span>
          ))}
        </Cell>
      )}

      <Cell isEmpty={!user.assignedLocations?.[0]?.name}>
        {user?.assignedLocations?.length > 1 ? (
          <ShowMoreCell
            itemNames={user?.assignedLocations?.map((loc) => loc?.name)}
            widthDefaultSize
            title={t('companies.companies')}
            bodyClassName="justify-content-center"
          >
            <div className="row w-100">
              {user?.assignedLocations?.map((loc) => (
                <div className="col-lg-3 col-sm-12 mb-5">
                  <span
                    className={clsx(
                      'w-100 text-center bg-light text-primary rounded p-2 d-block',
                    )}
                  >
                    {loc && loc.name}
                  </span>
                </div>
              ))}
            </div>
          </ShowMoreCell>
        ) : (
          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center hoverable">
            {user?.assignedLocations?.[0]?.name}
          </span>
        )}
      </Cell>

      {!config.skipActions && canActions && (
        <Cell isNoWrap isTextEnd>
          <div className="d-flex justify-content-end">
            {!config.skipEditSuspendReset && (
              <div className="mx-1">
                <EditUser user={user} onEditUser={onEditUser} />
              </div>
            )}

            {user.status === UserStatus.Disabled &&
              !config.skipEditSuspendReset && (
                <div className="mx-1">
                  <RemoveUser user={user} unAssignUser={unAssignUser} />
                </div>
              )}

            {user.status !== UserStatus.Disabled &&
              user.id !== selfId &&
              config.skipEditSuspendReset && (
                <div className="mx-1">
                  {userGroupInfo ? (
                    <RemoveUser
                      user={user}
                      onUnssingUserFromDeviceGroup={
                        onUnssingUserFromDeviceGroup
                      }
                      title={t('common.delete_confirmation.unassign_title')}
                      confirmText={t(
                        'common.delete_confirmation.confirm_unassign',
                      )}
                    >
                      <Tooltip text={t('common.unssign')}>
                        <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                          <i className="fa fa-trash" />
                        </span>
                      </Tooltip>
                    </RemoveUser>
                  ) : (
                    <RemoveUser
                      user={user}
                      unAssignUser={unAssignUser}
                      title={t('common.delete_confirmation.unassign_title')}
                      confirmText={t(
                        'common.delete_confirmation.confirm_unassign',
                      )}
                    >
                      <Tooltip text={t('common.unssign')}>
                        <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                          <i className="fa fa-trash" />
                        </span>
                      </Tooltip>
                    </RemoveUser>
                  )}
                </div>
              )}
            {user.status !== UserStatus.Disabled &&
              user.id !== selfId &&
              !config.skipEditSuspendReset && (
                <div className="mx-1">
                  <RemoveUser
                    user={user}
                    unAssignUser={onArchiveUser}
                    title={t('common.delete_confirmation.archive_title')}
                    confirmText={t(
                      'common.delete_confirmation.confirm_archive',
                    )}
                  >
                    <Tooltip text={t('common.archive')}>
                      <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <i className="fa fa-archive" />
                      </span>
                    </Tooltip>
                  </RemoveUser>
                </div>
              )}

            {user.status !== UserStatus.Active && !config.skipEditSuspendReset && (
              <div className="mx-1">
                <RestoreUser user={user} onRestoreUser={onRestoreUser} />
              </div>
            )}

            {user.status === UserStatus.Active &&
              user.id !== selfId &&
              !config.skipEditSuspendReset && (
                <div className="mx-1">
                  <SuspendUser user={user} onSuspendUser={onSuspendUser} />
                </div>
              )}

            {user.status !== UserStatus.Disabled &&
              !config.skipEditSuspendReset && (
                <div className="mx-1">
                  <ResetUserPassword user={user} />
                </div>
              )}
          </div>
        </Cell>
      )}
    </Row>
  );
};

UsersTableRow.defaultProps = {
  config: {
    skipStatus: false,
    skipGroupAccess: false,
    skipDevicesNumber: false,
    skipActions: false,
    disableLinks: false,
    isSelectable: false,
  },
  isChecked: false,
  onCheck: () => null,
};

export default UsersTableRow;
