import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import SortingQueryParam from '../../../../enums/queryParams/sortingQueryParam.enum';
import SortOrder from '../../../../enums/sortOrder.enum';
import DeviceGroup from '../../../../interfaces/deviceGroup.interface';
import Device from '../../../../interfaces/devices/device.interface';
import { readFromQueryString } from '../../../../utils/queryParams';
import useIsAdmin from '../../../../utils/hooks/useIsAdmin';
import Table from '../../../Table';
import Columns from '../../../Table/Columns';
import WithSpinner from '../../../WithSpinner';
import TableRow from './TableRow';

const preloaderHeight = 300;

const loadDeviceGroups = async (
  deviceId: string,
  isAdmin?: boolean,
): Promise<DeviceGroup[]> => {
  const deviceIds = deviceId ? [deviceId] : undefined;

  const { items } = isAdmin
    ? await api.devicesGroups.getAdminDevicesGroups({
        deviceIds,
      })
    : await api.devicesGroups.getDevicesGroups({
        deviceIds,
      });
  return items;
};

export default ({ device }: { device: Device }) => {
  const { t } = useTranslation();

  const [isAdmin] = useIsAdmin();

  const [sortField, setSortField] = useState({
    field: readFromQueryString('', SortingQueryParam.SortBy),
    isInverted:
      readFromQueryString('', SortingQueryParam.SortOrder) ===
      SortOrder.Ascending,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deviceGroups, setDeviceGroups] = useState<DeviceGroup[]>([]);

  useEffect(() => {
    setIsLoading(true);
    loadDeviceGroups(device.id, isAdmin).then((_deviceGroups) => {
      setDeviceGroups(_deviceGroups);
      setIsLoading(false);
    });
  }, [device.id]);

  const columns = [
    {
      name: t('devices_groups.device_group_name'),
    },
    {
      name: t('devices_groups.devices_number'),
    },
    {
      name: t('devices_groups.users_number'),
    },
    {
      name: t('devices_groups.playlists'),
    },
    {
      name: t('devices_groups.creation_date'),
    },
  ];

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns
            columns={columns}
            config={{
              sortField,
              setSortField,
              sortQueryField: SortingQueryParam.SortBy,
              orderQueryField: SortingQueryParam.SortOrder,
              isSelectable: false,
            }}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={false}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={t('devices_groups.no_one_group_found')}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          size="md"
          isForTable
        >
          {deviceGroups.map((deviceGroup: DeviceGroup) => (
            <TableRow key={deviceGroup.id} deviceGroup={deviceGroup} />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
