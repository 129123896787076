/* eslint-disable no-nested-ternary */
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import YouTubeReactPlayer from 'react-player/youtube';
import ReactPlayer from 'react-player';
import { Spinner } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import FormErrorMessage from '../../../components/FormErrorMessage';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import { AppDependencyConfig } from '../../../store/types/apps/globalTypes';
import Orientation from '../../../enums/orientation.enum';
import {
  EditYoutubeFeedRequest,
  YoutubeFeedSchema,
} from '../../../store/types/apps/youtubeFeed';

type EditFeedRequestFormMutated = Omit<
  EditYoutubeFeedRequest,
  'id' | 'config' | 'placement'
>;

interface EditFeedRequestFormPayload extends EditFeedRequestFormMutated {
  youtubeURL: string;
}

export interface EditFeedModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (editFeedRequest: EditYoutubeFeedRequest) => void;
  title: string;
  isLoading: boolean;
  selectedYoutubeFeedSchema: YoutubeFeedSchema;
  widthDefaultSize: boolean;
  previewHeight?: number;
}

const EditFeedModal = ({
  show,
  onHide,
  title,
  onSubmit,
  isLoading = false,
  selectedYoutubeFeedSchema,
  widthDefaultSize = false,
  previewHeight = 400,
}: EditFeedModalProps) => {
  const { t } = useTranslation();
  const [videoName, setVideoName] = React.useState<string>('');
  const [videoDurationInSec, setVideoDurationInSec] = React.useState<
    number | null
  >(null);

  const [videoIsFetching, setVideoIsFetching] = React.useState<boolean>(false);
  const initialValues: EditFeedRequestFormPayload = {
    name: selectedYoutubeFeedSchema?.name,
    youtubeURL: (
      selectedYoutubeFeedSchema?.dependency?.config as AppDependencyConfig
    )?.youtubeFeedUrl as string,
  };

  const validationSchema = Yup.object({
    name: Yup.string().defined(),
    youtubeURL: Yup.string()
      .matches(
        /^(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?$/,
        'Please provide a valid YouTube video URL.',
      )
      .defined(),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<EditFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [feedName, setFeedName] = useStateWithHookForm<
    EditFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const handleFeedNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFeedName(value),
    [setFeedName],
  );

  const [youtubeURL, setYoutubeURL] = useStateWithHookForm<
    EditFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'youtubeURL' }, initialValues.youtubeURL);

  const handleVideoDurationInSec = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (videoDurationInSec: number) => {
      setVideoDurationInSec(videoDurationInSec);
    },
    [],
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleVideoName = React.useCallback((videoName: string) => {
    setVideoName(videoName);
  }, []);

  const handleVideoIsFetching = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (videoIsFetching: boolean) => setVideoIsFetching(videoIsFetching),
    [],
  );
  const handleYoutubeURLChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (value === '') {
        handleVideoIsFetching(false);
      } else {
        handleVideoIsFetching(true);
      }

      setYoutubeURL(value);
    },
    [handleVideoIsFetching, setYoutubeURL],
  );

  const handleOnReadyEvent = React.useCallback(
    async (player: ReactPlayer) => {
      const durationInSec = player.getDuration();

      const uninterceptedAxiosInstance = axios.create();

      const {
        data: { title: videoTitle },
      } = await uninterceptedAxiosInstance({
        method: 'GET',
        url: 'https://noembed.com/embed',
        params: {
          url: youtubeURL,
        },
      });

      handleVideoDurationInSec(durationInSec);
      handleVideoName(videoTitle);

      handleVideoIsFetching(false);
    },
    [
      youtubeURL,
      handleVideoDurationInSec,
      handleVideoName,
      handleVideoIsFetching,
    ],
  );

  const handleOnSubmit = React.useCallback(
    async (data: EditFeedRequestFormPayload) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, youtubeURL } = data;

      const createFileRequest: EditYoutubeFeedRequest = {
        id: selectedYoutubeFeedSchema.id,
        name,
        config: {
          youtubeFeedUrl: youtubeURL,
          youtubeFeedName: videoName,
          youtubeFeedDurationInSeconds: videoDurationInSec as number,
        },
        zoneId: Orientation.Landscape,
      };

      onSubmit(createFileRequest);
    },
    [onSubmit, selectedYoutubeFeedSchema?.id, videoDurationInSec, videoName],
  );

  const videoDuration = React.useMemo(
    () =>
      new Date(1000 * (videoDurationInSec as number))
        .toISOString()
        .slice(11, 19),

    [videoDurationInSec],
  );

  return (
    <Modal
      title={title}
      show={show}
      onHide={onHide}
      widthDefaultSize={widthDefaultSize}
      withFooter
      footerContent={
        <button form="form" type="submit" className="btn btn-primary">
          {isLoading ? (
            <span>
              Editing{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            <span>{t('common.edit')}</span>
          )}
        </button>
      }
      dialogClassName="modal-dialog-centered  h-auto"
    >
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        id="form"
        className="d-flex flex-column  justify-content-between"
      >
        <div className="d-flex flex-row p-4 w-100">
          <div className="d-flex flex-column mx-2 w-100">
            <label htmlFor="name" className="text-dark fw-bolder my-1">
              {t('apps.youtube.labels.title')}
            </label>
            <input
              name="name"
              id="name"
              value={feedName}
              onChange={handleFeedNameChange}
              className="form-control form-control-solid"
              placeholder="Type here"
              type="text"
            />
            <FormErrorMessage
              name="name"
              errors={errors}
              className="my-1 px-2"
            />
          </div>

          <div className="d-flex flex-column mx-2 w-100">
            <label htmlFor="youtubeURL" className="text-dark fw-bolder my-1">
              {t('apps.youtube.labels.enterUrl')}
            </label>
            <input
              name="youtubeURL"
              id="youtubeURL"
              value={youtubeURL}
              onChange={handleYoutubeURLChange}
              className="form-control form-control-solid"
            />
            <FormErrorMessage
              name="youtubeURL"
              errors={errors}
              className="my-1 px-2"
            />
          </div>
        </div>

        <div className="d-flex flex-column p-4 w-100">
          {!errors.youtubeURL && youtubeURL !== '' ? (
            <div className="d-flex flex-column flex-center w-100 h-100">
              {/* simulating a loading state for the player (hacky) and display duration and name fields when it's loaded  */}

              {videoIsFetching ? (
                <div className="d-flex flex-center w-100 h-100 p-4">
                  <Spinner role="status" animation="border" />
                </div>
              ) : null}

              <YouTubeReactPlayer
                url={youtubeURL}
                width="100%"
                height={`${previewHeight}px`}
                controls
                onReady={handleOnReadyEvent}
              />

              {videoIsFetching ? null : (
                <div className="d-flex flex-row my-2 w-100">
                  <div className="d-flex flex-column mx-2 w-100">
                    <span className="text-dark fw-bolder my-1">
                      {t('apps.youtube.labels.videoName')}
                    </span>
                    <span>{videoName}</span>
                  </div>
                  <div className="d-flex flex-column mx-2 w-100">
                    <span className="text-dark fw-bolder my-1">
                      {t('apps.youtube.labels.duration')}
                    </span>
                    <span>{videoDuration}</span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex flex-column flex-center w-100">
              <img
                alt="No items found"
                src="/media/illustrations/sketchy-1/5.png"
                className="w-100"
              />
              <div className="fs-1">{t('apps.youtube.noPreview')}</div>
              <div className="fs-6">
                {t(
                  'apps.youtube.create_feed_validation.please_enter_youtubeUrl',
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default EditFeedModal;
