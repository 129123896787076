import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BulkEditContent from '../../../components/Contents/BulkEditContent';
import Modal from '../../../components/Modal';
import BulkUpdateContentsRequestDataInterface from '../../../api/endpoints/contents/interfaces/bulkUpdateContentRequestData.interface';
import api from '../../../api';
import Playlist from '../../../interfaces/playlist.interface';

export default ({
  children,
  selectedItems,
  onSuccess,
  contentType,
  config,
  playlist,
  hasBothZones = false,
}: {
  children: React.ReactNode;
  selectedItems: string[];
  contentType?: { typeVideo?: boolean };
  onSuccess: () => void;
  config?: { skipDuration?: boolean };
  playlist?: Playlist;
  hasBothZones?: boolean;
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  const handleSubmit = async (data: BulkUpdateContentsRequestDataInterface) => {
    try {
      setIsLoading(true);

      await api.contents.bulkUpdateContent(data);
      onSuccess();
      setIsModalVisible(false);
    } catch (e) {
      // Do nothing for now
    }
    setIsLoading(false);
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('contents.bulk_edit_content')}
        widthDefaultSize={false}
        withFooter={false}
        show={isModalVisible}
        onHide={close}
      >
        <BulkEditContent
          contentIds={selectedItems}
          contentType={contentType}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          config={config}
          playlist={playlist}
          hasBothZones={hasBothZones}
        />
      </Modal>
    </>
  );
};
