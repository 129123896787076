import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Canva from '../../../components/Apps/Canva';
import api from '../../../api';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import App from '../../../interfaces/app.interface';
import AppType from '../../../enums/appType.enum';

export default () => {
  const { search } = useLocation();
  const [apps, setApps] = useState<App[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getAppList = async (recentSearch: string) => {
    try {
      setLoading(true);

      const { items } = await api.apps.getApps(
        AppType.Canva,
        readAllFromQueryParams(recentSearch),
      );

      setApps(items);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAppList(search);
  }, [search]);

  return (
    <Canva
      canva={apps}
      isLoading={isLoading}
      onUpdate={() => getAppList(search)}
    />
  );
};
