import GetCompaniesListSuccessAction from './interfaces/getCompaniesListSuccessAction.interface';
import GetCompaniesListAction from './interfaces/getCompaniesListAction.interface';

import LoadingAction from '../loadingAction.interface';

import CompaniesActions from './companies';

export const getCompaniesList = (
  payload: GetCompaniesListAction['payload'],
): GetCompaniesListAction => ({
  type: CompaniesActions.GET_COMPANIES_LIST,
  payload,
});

export const getCompaniesListSuccess = (
  payload: GetCompaniesListSuccessAction['payload'],
): GetCompaniesListSuccessAction => ({
  type: CompaniesActions.GET_COMPANIES_LIST_SUCCESS,
  payload,
});

export const setCompaniesLoading = (
  payload: LoadingAction['payload'],
): LoadingAction => ({
  type: CompaniesActions.SET_COMPANIES_LOADING,
  payload,
});
