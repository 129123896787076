import React, { useState } from 'react';
import Device from '../../../interfaces/devices/device.interface';
import AssignDeviceToUser from '../../../components/Devices/AssignDeviceToUser';
import api from '../../../api';

export default ({
  device,
  children,
  onAssign,
}: {
  children?: React.ReactNode;
  device: Device;
  onAssign?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  const onSubmit = ({ userId }: { userId: string }) => {
    setIsLoading(true);

    api.devices
      .assignDevicesToOwner([device.id], userId)
      .then(() => {
        close();

        if (onAssign) {
          onAssign();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-user-plus" />
          </span>
        )}
      </span>
      <AssignDeviceToUser
        isLoading={isLoading}
        isModalVisible={isModalVisible}
        initialValue={device.owner?.id}
        onSubmit={onSubmit}
        onHide={close}
        isTrial={device?.trialDevice}
      />
    </>
  );
};
