import React from 'react';
import clsx from 'clsx';

interface WrapperProps {
  children: React.ReactNode;
}

const Card = ({
  children,
  className,
  withoutMargins,
  style,
}: {
  className?: string;
  withoutMargins?: boolean;
  style?: React.CSSProperties;
} & WrapperProps) => (
  <div
    style={style ?? {}}
    className={clsx(
      'card bg-white',
      !withoutMargins && 'mb-5 mb-xl-8',
      className,
    )}
  >
    {children}
  </div>
);

const Header = ({ children }: WrapperProps) => (
  <div className="card-header border-0 pt-5">{children}</div>
);

const Body = ({
  children,
  className,
}: { className?: string } & WrapperProps) => (
  <div className={clsx('card-body py-3 position-relative', className)}>
    {children}
  </div>
);

const HeaderToolbar = ({
  children,
  className,
}: WrapperProps & { className?: string }) => (
  <div
    className={clsx(
      'card-toolbar d-flex flex-wrap flex-stack w-100',
      className,
    )}
  >
    {children}
  </div>
);

const HeaderTitle = ({
  children,
  className,
}: WrapperProps & { className?: string }) => (
  <h3
    className={clsx(
      'card-title align-items-start flex-column w-100',
      className,
    )}
  >
    {children}
  </h3>
);

const HeaderTitleLabel = ({ children }: WrapperProps) => (
  <span className="card-label capital fw-bolder fs-3">{children}</span>
);

Header.Toolbar = HeaderToolbar;
Header.Title = HeaderTitle;
HeaderTitle.Label = HeaderTitleLabel;
Card.Header = Header;
Card.Body = Body;

export default Card;
