import GetDevicesListSuccessAction from '../../actions/devices/interfaces/getDevicesListSuccessAction.interface';
import DevicesActions from '../../actions/devices/devicesActions.enum';
import LoadingAction from '../../actions/loadingAction.interface';
import ReduxAction from '../../actions/reduxAction.interface';
import DevicesState from './devicesState.interface';

const initialState: DevicesState = {
  loading: true,
  devices: [],
  adminDevices: [],
  adminTotal: 0,
  offline: 0,
  online: 0,
  total: 0,
};

export default function devicesReducer(
  state = initialState,
  action: ReduxAction<DevicesActions>,
): DevicesState {
  switch (action.type) {
    case DevicesActions.GET_DEVICES_LIST_SUCCESS: {
      const {
        payload: { items, meta },
      } = action as GetDevicesListSuccessAction;

      return {
        ...state,
        devices: items,
        total: meta.totalItems,
        offline: meta.offline,
        online: meta.online,
      };
    }
    case DevicesActions.GET_ADMIN_DEVICES_LIST_SUCCESS: {
      const {
        payload: { items, meta },
      } = action as GetDevicesListSuccessAction;

      return {
        ...state,
        adminDevices: items,
        adminTotal: meta.totalItems,
      };
    }
    case DevicesActions.GET_ADMIN_DEVICES_LIST_PAGE_SUCCESS: {
      const {
        payload: { items, meta },
      } = action as GetDevicesListSuccessAction;

      return {
        ...state,
        adminDevices: items,
        adminTotal: meta.totalItems,
      };
    }
    case DevicesActions.SET_DEVICES_LOADING:
      return {
        ...state,
        ...(action as LoadingAction<DevicesActions.SET_DEVICES_LOADING>)
          .payload,
      };
    default:
      return state;
  }
}
