import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
import { SelectedValue } from '../../Select/SelectAsync';
import api from '../../../api';
import UsersSortField from '../../../api/endpoints/users/enums/usersSortField.enum';
import SortOrder from '../../../enums/sortOrder.enum';
import dropdownsData from '../../../constants/dropdownsData';

const SelectUsers = ({
  onChange,
  initialValue,
}: {
  onChange: (selectedValue: SelectedValue[]) => void;
  initialValue?: any;
}) => {
  const [options, setOptions] = useState<any>([]);
  const [isAdmin] = useIsAdmin();

  const loadOptions = async () => {
    const { items } = isAdmin
      ? await api.users.getAdminUsersList({
          sortBy: UsersSortField.FirstName,
          sortOrder: SortOrder.Ascending,
        })
      : await api.users.getUsersList({
          sortBy: UsersSortField.FirstName,
          sortOrder: SortOrder.Ascending,
          limit: dropdownsData.usersLimit,
        });
    const getOptions = items.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));

    setOptions(getOptions);
  };

  const handleFilterOptions = (filteredOptions: any, input: string) => {
    return filteredOptions.filter((option: { label: string }) =>
      option.label.toLowerCase().includes(input.toLowerCase()),
    );
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <MultiSelect
      options={options}
      value={initialValue}
      onChange={onChange}
      filterOptions={handleFilterOptions}
      labelledBy="Selects Users"
      isLoading={options.length === 0}
    />
  );
};

SelectUsers.defaultProps = {
  initialValue: [],
};

export default SelectUsers;
