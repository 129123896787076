import React from 'react';
import { useTranslation } from 'react-i18next';
import { debouncedUpdateSearch } from '../../../utils/browserHistory';
import PlaylistsQueryParam from '../../../api/endpoints/playlists/enums/playlistsQueryParam.enum';
import SelectDevices from '../../../containers/Devices/SelectDevices';
import FiltersDropdown from '../../Dropdown/FiltersDropdown';
import useFilter from '../../../utils/hooks/useFilter';

export default ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const [deviceIds, setDeviceIds] = useFilter<string[]>(
    PlaylistsQueryParam.DeviceIds,
    [],
    true,
  );

  const handleApply = () =>
    debouncedUpdateSearch({
      [PlaylistsQueryParam.DeviceIds]: deviceIds ?? [],
    });

  // Reset filters in the query params
  const handleReset = () =>
    debouncedUpdateSearch({
      [PlaylistsQueryParam.DeviceIds]: [],
    });
  return (
    <FiltersDropdown
      className={className}
      onApplyFilters={handleApply}
      onReset={handleReset}
    >
      <div className="mb-11">
        <span className="col-form-label text-lg-end d-inline-flex">
          {t('playlists.devices')}
        </span>
        <SelectDevices
          initialValue={deviceIds}
          onChange={(values) =>
            setDeviceIds(values.map(({ value }) => value as string))
          }
          hasSelectAll={false}
          hideLicence
        />
      </div>
    </FiltersDropdown>
  );
};
