import { isNumber, omitBy } from 'lodash';

/**
 * Removes keys for empty query param values
 * @param queryParams
 */
export const sanitizeQueryParams = (queryParams: { [key: string]: any }) =>
  omitBy(queryParams, (value) => !isNumber(value) && !value);

/**
 * Builds query string based on passed params
 * @param search
 * @param initialValue
 * @param skipExisted
 */
export const buildQueryString = (
  search: { [key: string]: string | number | boolean | string[] },
  initialValue: string = '',
  skipExisted: boolean = false,
): string => {
  const searchParams: URLSearchParams = new URLSearchParams(initialValue);

  Object.keys(search).forEach((paramKey: string): void => {
    if (!(skipExisted && searchParams.has(paramKey))) {
      const rawValue = search[paramKey];
      const value = rawValue?.toString();

      if (!value) {
        searchParams.delete(paramKey);
      } else if (rawValue instanceof Array) {
        searchParams.delete(paramKey);
        rawValue.forEach((val) => searchParams.append(paramKey, val));
      } else {
        searchParams.set(paramKey, value);
      }
    }
  });

  return `?${searchParams.toString()}`;
};

/**
 * Retrieves the query string param value
 * @param queryString
 * @param name
 * @param isArray
 */
export const readFromQueryString = <T extends string | string[] = string>(
  queryString: string,
  name: string,
  isArray: boolean = false,
): T | null => {
  const params = new URLSearchParams(queryString);

  return (isArray ? params.getAll(name) : params.get(name)) as T;
};

/**
 * Retrieves the query string param value
 * @param queryString
 * @param name
 * @param isArray
 */
export const useReadFromQueryString = <T extends string | string[] = string>(
  queryString: string,
  name: string,
  isArray: boolean = false,
): T | null => {
  const params = new URLSearchParams(queryString);

  return (isArray ? params.getAll(name) : params.get(name)) as T;
};

/**
 * Retrieves the query string keys and values in the object shape
 * @param queryString
 */
export const readAllFromQueryParams = (
  queryString: string,
): { [key: string]: string | string[] } => {
  const result: { [key: string]: string | string[] } = {};

  new URLSearchParams(queryString).forEach((value: string, key: string) => {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      const currentValue = result[key];

      result[key] =
        currentValue instanceof Array
          ? [...currentValue, value]
          : [currentValue, value];
    } else {
      result[key] = value;
    }
  });

  return result;
};
