import Bugsnag from '@bugsnag/js';
import * as FullStory from '@fullstory/browser';
import UserDataActions from '../../actions/userData/userDataAction.enum';
import ReduxAction from '../../actions/reduxAction.interface';
import UserDataState from './userDataState.interface';
import Company from '../../../interfaces/company.interface';
import Location from '../../../interfaces/location.interface';
import Integration from '../../../interfaces/integration.interface';
import LoadingAction from '../../actions/loadingAction.interface';

const initialState: UserDataState = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    profilePicture: '',
    numberOfScreens: '',
    roles: [],
    isRoot: false,
    company: {} as Company,
    location: {} as Location,
    email: '',
    phoneNumber: '',
    createdAt: '',
    updatedAt: '',
    users: [],
    language: '',
    loginSessions: [],
    integrations: [],
    isSocial: true,
    isTrial: false,
  },
  loading: true,
  trialExpireDays: undefined,
  isHubSpotLoaded: false,
};

const updateFS = (user: any) => {
  if (process.env.REACT_APP_FULLSTORY_ID && user && FullStory.isInitialized()) {
    try {
      FullStory.identify(user.id, {
        displayName: `${user.firstName} ${user.lastName}`,
        email: user.email,
        phoneNumber: user.phoneNumber,
        companies: user.companies
          .map((company: any) => company.name)
          .join(', '),
        locations: user.assignedLocations
          .map((location: any) => location.name)
          .join(', '),
        numberOfScreens: user.numberOfScreens,
        roles: user.roles.join(', '),
        isRoot: user.isRoot,
        isTrial: user.isTrial,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt,
      });
    } catch (error: any) {
      Bugsnag.notify(error);
    }
  }
};

export default function userDataReducer(
  state = initialState,
  action: ReduxAction<UserDataActions>,
) {
  switch (action.type) {
    case UserDataActions.GET_USER_DATA_SUCCESS:
      updateFS(action.payload);
      return { ...state, user: action.payload, loading: false };
    case UserDataActions.UPDATE_USER_DATA_SUCCESS:
      updateFS(action.payload);
      return { ...state, user: action.payload, loading: false };
    case UserDataActions.SET_USER_LOADING:
      return {
        ...state,
        ...(action as LoadingAction<UserDataActions.SET_USER_LOADING>).payload,
      };

    case UserDataActions.UPDATE_USER_INTEGRATION_SUCCESS: {
      const user = { ...state.user };
      user.integrations = action.payload as Integration[];
      return {
        ...state,
        user,
      };
    }
    case UserDataActions.SET_TRIAL_EXPIRE_DAYS:
      return {
        ...state,
        trialExpireDays: action.payload?.trialExpireDays,
      };

    case UserDataActions.SET_HUBSPOT_LOADED:
      return {
        ...state,
        isHubSpotLoaded: action.payload,
      };

    default:
      return state;
  }
}
