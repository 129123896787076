import React from 'react';
import DeviceGroup from '../../../../../interfaces/deviceGroup.interface';
import { formatDateString } from '../../../../../utils/dateTime';
import Table from '../../../../Table';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
});

export default ({ deviceGroup }: { deviceGroup: DeviceGroup }) => {
  return (
    <Row>
      <Cell isEmpty={!deviceGroup.name}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {deviceGroup.name}
        </span>
      </Cell>
      <Cell isEmpty={!deviceGroup.devicesCount}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {deviceGroup.devicesCount}
        </span>
      </Cell>
      <Cell isEmpty={!deviceGroup.usersCount}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {deviceGroup.usersCount}
        </span>
      </Cell>
      <Cell isEmpty={!deviceGroup.playlistsCount}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {deviceGroup.playlistsCount}
        </span>
      </Cell>
      <Cell isEmpty={!deviceGroup.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {deviceGroup.createdAt && formatDate(deviceGroup.createdAt)}
        </span>
      </Cell>
    </Row>
  );
};
