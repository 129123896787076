import React from 'react';
import Statistic from '../Statistic';
import useDocumentTitle from '../../utils/hooks/useDocumentTitle';

const StatisticComponent = useDocumentTitle({
  ChildComponent: Statistic,
  title: 'Home',
});

export default () => <StatisticComponent />;
