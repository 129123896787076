import axios from 'axios';
import { API_URL } from '../../../constants/api';
import CustomersRoutes from './enums/customersRoutes.enum';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import BulkEditCustomersRequestData from './interfaces/bulkEditCustomersRequestData.interface';
import GetCustomersListResponseData from './interfaces/getCustomersListResponseData.interface';
import GetCustomersRequestData from './interfaces/getCustomersListRequestData.interface';
import CreateCustomerRequestData from './interfaces/createCustomerRequestData.interface';
import Customer from '../../../interfaces/customer.interface';

export default {
  /**
   * Retrieves the list of customers
   * @param requestData
   */
  getCustomersList: async (
    requestData: GetCustomersRequestData,
  ): Promise<GetCustomersListResponseData> => {
    const params = sanitizeQueryParams(requestData);
    params.userType = 'isCustomer';
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CustomersRoutes.Customers}`,
      params,
    });

    return data;
  },

  /**
   * Retrieves the list of customers
   * @param requestData
   */
  getCustomersListV2: async (
    requestData: GetCustomersRequestData,
  ): Promise<GetCustomersListResponseData> => {
    const params = sanitizeQueryParams(requestData);
    params.userType = 'isCustomer';
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CustomersRoutes.Customers}/v2`,
      params,
    });

    return data;
  },

  getCustomer: async (userId: string): Promise<Customer> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CustomersRoutes.Customers}/${userId}`,
    });

    return data;
  },

  createCustomer: async (customer: CreateCustomerRequestData): Promise<any> => {
    return axios({
      method: 'post',
      url: `${API_URL}/${CustomersRoutes.ManagementCustomers}`,
      data: customer,
    });
  },

  editCustomer: async (
    customer: Partial<CreateCustomerRequestData>,
    id: string,
  ): Promise<any> => {
    return axios({
      method: 'put',
      url: `${API_URL}/${CustomersRoutes.Customers}/${id}`,
      data: customer,
    });
  },

  adminBulkEditCustomers: async (
    data: Partial<BulkEditCustomersRequestData>,
  ): Promise<void> => {
    await axios({
      method: 'put',
      url: `${API_URL}/${CustomersRoutes.ManagementCustomers}`,
      data,
    });
  },

  suspendCustomer: async (id: string): Promise<any> => {
    return axios({
      method: 'patch',
      url: `${API_URL}/${CustomersRoutes.ManagementCustomers}/${id}/ban`,
    });
  },

  /**
   * Resets the customer password
   * @param id
   */
  resetCustomerPassword: async (id: string): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${CustomersRoutes.Customers}/${id}/reset-password`,
    });
  },

  /**
   * retrieve the list of customers on csv file
   * @param string[] column names
   * @param string email of the receiver
   * @param string file name
   */
  getCustomersListCsv: async (values: any): Promise<any> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${CustomersRoutes.ManagementCustomers}/csv`,
      data: values,
    }).catch((error) => {
      throw error;
    });
    return data;
  },
};
