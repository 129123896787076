import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import { readFromQueryString } from '../../../utils/queryParams';
import { updateSearch } from '../../../utils/browserHistory';
import useDidUpdate from '../../../utils/hooks/useDidUpdate';
import Search from '../index';

interface SearchProps {
  queryField: string;
  onChange?: (value: string) => void;
  className?: string;
  placeholder?: string;
  pageNumberQueryField?: string;
  wrapperClassName?: string;
}

interface DefaultProps extends SearchProps {
  onChange: Required<SearchProps>['onChange'];
}

const QueryParamSearch = ({
  queryField,
  onChange,
  className,
  placeholder,
  pageNumberQueryField,
  wrapperClassName,
}: DefaultProps) => {
  const { search } = useLocation();
  const [searchValue, setSearchValue] = useState(
    readFromQueryString<string>(search, queryField) ?? '',
  );

  /**
   * Debounced updates the search in the query params
   * and resets the page number if it is required
   * @param value
   * @param currentSearch
   */
  const handleSearch = useCallback(
    debounce((value: string, currentSearch: string) => {
      const newSearchValue = { [queryField]: value };

      // Go to the first page
      if (
        pageNumberQueryField &&
        readFromQueryString(currentSearch, pageNumberQueryField) !== '1'
      ) {
        newSearchValue[pageNumberQueryField] = '1';
      }

      updateSearch(newSearchValue);
      onChange(value);
    }, 520),
    [],
  );

  useDidUpdate(() => {
    handleSearch(searchValue, search);
  }, [searchValue]);

  return (
    <Search
      searchValue={searchValue}
      onChange={setSearchValue}
      className={className}
      placeholder={placeholder}
      wrapperClassName={wrapperClassName}
    />
  );
};

QueryParamSearch.defaultProps = {
  onChange: () => {},
};

export default QueryParamSearch;
