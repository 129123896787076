import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../api';
import SkuSingleContent from '../../../../components/AdminPanel/Library/CreateSku/SingleContent';
import Modal from '../../../../components/Modal';
import SkuInfo from '../../../../interfaces/skuInfo.interface';

const CreateSkuContainerSingle = ({
  children,
  onUpdateContentPosters,
  topicId,
  dynamicOpen,
  isEdit,
  onHideModal,
  topicName,
}: {
  children?: React.ReactNode;
  onUpdateContentPosters: () => void;
  topicId: string;
  dynamicOpen?: boolean;
  isEdit?: boolean;
  topicName?: string;
  onHideModal?: () => void;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(dynamicOpen ?? false);
  // const [loading, setLoading] = useState<boolean>(false);
  // const [customerId, setCustomerId] = useState('');
  const open = () => setModalShown(true);
  const close = () => {
    setModalShown(false);
  };

  const handleSubmit = async (data: SkuInfo) => {
    // console.log(data);

    const skuInfos: SkuInfo[] = [data];
    if (!isEdit)
      await api.libraries
        .createPostersAndContents(skuInfos, topicId, data?.isDownloadable)
        .then(() => {
          close();
          onUpdateContentPosters();
        });

    if (isEdit) {
      await api.libraries
        .ReplaceSignleContents(skuInfos, topicId, data?.isDownloadable)
        .then(() => {
          close();
          onUpdateContentPosters();
        });
    }
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={
          isEdit
            ? 'Update New Free Single Content'
            : t('library.indigo.freeSingleContent')
        }
        show={modalShown}
        onHide={() => {
          if (onHideModal) onHideModal();
          setModalShown(false);
        }}
        withFooter={false}
        widthDefaultSize={false}
      >
        <SkuSingleContent
          topicName={topicName}
          topicId={topicId}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};
export default CreateSkuContainerSingle;
