import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import SelectAsync, {
  SelectedValue,
} from '../../components/Select/SelectAsync';
import dropdownsData from '../../constants/dropdownsData';
import SortOrder from '../../enums/sortOrder.enum';

/**
 * Retrieves the select options asynchronously
 * @param search
 * @param showStateCode
 * @param countryId
 */
const loadOptions = async (
  search: string,
  showStateCode: boolean | undefined,
  countryId?: string,
): Promise<SelectedValue[]> => {
  const { items } = await api.states.getStates({
    search,
    countryId,
    limit: dropdownsData.limit,
    sortOrder: SortOrder.Ascending,
  });

  return sortBy(items, ['name']).map(({ id, name }) => ({
    value: id,
    label: showStateCode ? id : name,
  }));
};

const SelectState = ({
  onChange,
  initialValue,
  id,
  className,
  isClearable,
  showStateCode,
  countryId,
  disabled,
}: {
  onChange: (selectedValue: SelectedValue) => void;
  initialValue?: string;
  id?: string;
  className?: string;
  isClearable?: boolean;
  showStateCode?: boolean;
  countryId?: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<SelectedValue>();

  useEffect(() => {
    if (!initialValue) {
      setSelectedValue(undefined);
      return;
    }

    setSelectedValue({
      label: `${t('common.loading')}...`,
      value: initialValue,
    });

    setTimeout(async () => {
      try {
        const { name, id: stateCode } = await api.states.getState(initialValue);

        setSelectedValue({
          label: showStateCode ? stateCode : name,
          value: initialValue,
        });
      } catch (e) {
        setSelectedValue({});
      }
    });
  }, [countryId]);

  return (
    <SelectAsync
      id={id}
      key={`${countryId}`}
      initialValue={selectedValue}
      onChange={onChange}
      loadOptions={(search) => loadOptions(search, showStateCode, countryId)}
      className={className}
      placeholder={t('common.search')}
      isClearable={isClearable}
      isDisabled={disabled}
    />
  );
};

SelectState.defaultProps = {
  initialValue: null,
};

export default SelectState;
