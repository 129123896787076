import React from 'react';
import clsx from 'clsx';

type Props = {
  title: string;
  icon?: string;
  children: React.ReactNode;
  onKeypress?: () => void;
  onClick?: (event: React.MouseEvent) => void;
  show: boolean;
  className?: string;
};

export default ({
  children,
  title,
  icon,
  onKeypress,
  onClick,
  show = false,
  className = '',
}: Props) => {
  return (
    <div
      role="button"
      tabIndex={-1}
      onKeyPress={onKeypress}
      onClick={onClick}
      className={clsx('menu-item mb-4 menu-accordion', className, 'mb-0', {
        show,
      })}
    >
      <span className="menu-link">
        {icon && (
          <span className="menu-icon">
            <img alt="Logo" src={icon} className="w-90px h-43px" />
          </span>
        )}

        <span className="menu-title" style={{ color: '#3f4254' }}>
          {title}
        </span>
        <span className="menu-arrow" />
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', { show })}>
        {children}
      </div>
    </div>
  );
};
