import { UserRole } from '../enums/userRole.enum';
import config from '../routing/config';

export const roleRedirectionRoute: { [key: string]: string } = {
  [UserRole.SuperAdmin]: config.dashboard.route,
  [UserRole.IndigoAdmin]: config.adminCustomers.route,
  [UserRole.IndigoEmployee]: config.adminCustomers.route,
  [UserRole.Admin]: config.devices.route,
  [UserRole.Employee]: config.devices.route,
  [UserRole.Manager]: config.devices.route,
  [UserRole.Editor]: config.devices.route,
  [UserRole.AccountOwner]: config.devices.route,
};
