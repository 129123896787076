import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import IPlaylist from '../../../interfaces/playlist.interface';
import Tooltip from '../../../components/Tooltip';
import api from '../../../api';
import Modal from '../../../components/Modal';
import { KTSVG } from '../../../lib/metronic/helpers';
import WithSpinner from '../../../components/WithSpinner';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';

const CopyPlaylist = ({
  playlist,
  children,
  onSuccess,
}: {
  playlist: IPlaylist;
  children?: React.ReactNode;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const open = () => {
    setModalShown(true);
  };
  const close = () => {
    setModalShown(false);
  };
  const [isAdmin] = useIsAdmin();

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      if (isAdmin) {
        await api.playlists.cloneAdminPlaylist(playlist.id);
      } else {
        await api.playlists.clonePlaylist(playlist.id);
      }
      close();
      onSuccess?.();
      toast.success(t('playlists.copy_success'));
    } catch (e) {
      // handling error
    }
    setIsLoading(false);
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.copy')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-copy" />
            </span>
          </Tooltip>
        )}
      </span>
      <Modal
        show={modalShown}
        onHide={close}
        withFooter={false}
        withHeader={false}
        widthDefaultSize={false}
        bodyClassName="d-flex flex-center flex-column my-7"
      >
        <WithSpinner isLoading={isLoading}>
          <span className="svg-icon svg-icon-5tx svg-icon-warning mb-10">
            <KTSVG
              path="/media/icons/duotune/general/gen044.svg"
              className="svg-icon-1"
            />
          </span>
          <div className="text-center">
            <h5 className="fw-bolder fs-1 mb-10">
              {t('playlists.confirm_duplicate')}
            </h5>
            <div className="d-flex flex-center flex-wrap">
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary m-2"
              >
                {t('common.confirm')}
              </button>
              <button
                onClick={close}
                type="button"
                className="btn btn-outline btn-outline-primary btn-active-primary m-2"
              >
                {t('common.cancel')}
              </button>
            </div>
          </div>
        </WithSpinner>
      </Modal>
    </>
  );
};

export default CopyPlaylist;
