import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import { RequiredObjectSchema } from 'yup/lib/object';
import { prepareHookFromState } from '../../../../../utils/hooks/useStateWithHookForm';
import DeviceCreationStepsData from '../../../../../interfaces/devices/deviceCreationStepsData.interface';
import AddDeviceSteps from '../../../../../enums/steps/addDeviceSteps.enum';
import { MemoizedSelectLocation } from '../../../../../containers/SelectLocation';
import SelectCountry from '../../../../../containers/SelectCountry';
import CreateLocation from '../../../../../containers/AdminPanel/CompanyLocations/CreateLocation';
import ISelectCompany from '../../../../../interfaces/selectCompany.interface';

import FormErrorMessage from '../../../../FormErrorMessage';
import validation from '../../../../../utils/validation';
import TextInput from '../../../../TextInput';
import StepsTitle from '../../StepsTitle';
import Language from '../../../../../enums/language.enum';
import { MemoizedSelectCompany } from '../../../../../containers/Companies/SelectCompany';
import { MemoizedSelectLanguage } from '../../../../../containers/SelectLanguage';
import { MemoizedSelectTimezoneId } from '../../../../../containers/SelectTimezoneId';
import Device from '../../../../../interfaces/devices/device.interface';
import LicensingForm, { LicensingFormInputs } from '../../../LicensingForm';
import useDidUpdate from '../../../../../utils/hooks/useDidUpdate';
import Select from '../../../../Select/RegularSelect';
import api from '../../../../../api';
import { KTSVG } from '../../../../../lib/metronic/helpers';
import { SelectedValue } from '../../../../Select/SelectAsync';
import useIsAdmin from '../../../../../utils/hooks/useIsAdmin';
import CheckBox from '../../../../CheckBox';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

interface FormInputs extends LicensingFormInputs {
  name: string;
  companyId?: string;
  locationId?: string;
  countryId?: string;
  city?: string;
  timezoneId: string;
  language1: Language;
  language2: Language;
  trialDevice: boolean;
}

export default ({
  data,
  device,
  setData,
  onDecline,
  validationSchema,
  setMode,
}: {
  data?: Partial<DeviceCreationStepsData>;
  device?: Device;
  setData: (newData: Partial<DeviceCreationStepsData>) => void;
  onDecline: () => void;
  validationSchema: RequiredObjectSchema<any, any, any>;
  setMode: (mode: AddDeviceSteps) => void;
}) => {
  const { t } = useTranslation();
  const license = device?.license;
  const defaultValues: Partial<FormInputs> = {
    /* Info */
    name: device?.name ?? data?.name,
    companyId: device?.company?.id ?? data?.companyId,
    locationId: device?.location?.id ?? data?.locationId,
    city: device?.location?.city ?? data?.city,
    countryId: device?.location?.country ?? data?.countryId,
    timezoneId: device?.timezone?.id ?? data?.timezoneId,
    language1: device?.language1 ? device?.language1 : Language.English,
    language2: device?.language2 ?? data?.language2,
    trialDevice: !!(device?.trialDevice ?? data?.trialDevice),

    /* Licensing */
    licenseTypeId: license?.licenseType?.id ?? data?.licenseTypeId,
    isLibrary: !!(license?.isLibrary ?? data?.isLibrary),
    isActive: !!(license?.isActive ?? data?.isActive),
    isTrial: !!(license?.isTrial ?? data?.isTrial),
    billingRate: license?.billingRate ?? data?.billingRate,
    licenseTerm: license?.licenseTerm ?? data?.licenseTerm,
    isLibraryTrial: !!(license?.isLibraryTrial ?? data?.isLibraryTrial),
    startDate: license?.startDate ?? data?.startDate,
    expireDate: license?.expireDate ?? data?.expireDate,
    libraryAccessStartDate:
      license?.libraryAccessStartDate ?? data?.libraryAccessStartDate,
    libraryAccessExpireDate:
      license?.libraryAccessExpireDate ?? data?.libraryAccessExpireDate,
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validationSchema ?? validation.object({}).required()),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [isAdmin] = useIsAdmin();
  const [name, setName] = useFs<FormInputs['name']>('name');
  const [companyId, setCompanyId] = useFs<FormInputs['companyId']>('companyId');
  const [ownerId, setOwnerId] = useState<string>('');
  const [locationId, setLocationId] = useFs<
    FormInputs['locationId'] | undefined
  >('locationId');
  const [countryId, setCountryId] = useFs<FormInputs['countryId'] | undefined>(
    'countryId',
  );
  const [city, setCity] = useFs<FormInputs['city'] | undefined>('city');

  const [timezoneId, setTimezoneId] =
    useFs<FormInputs['timezoneId']>('timezoneId');
  const [language1, setLanguage1] = useFs<FormInputs['language1']>('language1');
  const [language2, setLanguage2] = useFs<FormInputs['language2']>('language2');
  const [zipCode, setZipCode] = useState('');
  const [trialDevice, setTrialDevice] =
    useFs<FormInputs['trialDevice']>('trialDevice');

  // LICENSING SECTION
  const [licenseTypeId, setLicenseType] =
    useFs<LicensingFormInputs['licenseTypeId']>('licenseTypeId');
  const [startDate, setStartDate] =
    useFs<LicensingFormInputs['startDate']>('startDate');
  const [expireDate, setEndDate] =
    useFs<LicensingFormInputs['expireDate']>('expireDate');
  const [libraryAccessStartDate, setLibraryStartDate] = useFs<
    LicensingFormInputs['libraryAccessStartDate']
  >('libraryAccessStartDate');
  const [libraryAccessExpireDate, setLibraryEndDate] = useFs<
    LicensingFormInputs['libraryAccessExpireDate']
  >('libraryAccessExpireDate');
  const [isActive, setIsActive] =
    useFs<LicensingFormInputs['isActive']>('isActive');
  const [isTrial, setIsTrial] =
    useFs<LicensingFormInputs['isTrial']>('isTrial');
  const [billingRate, setBillingRate] =
    useFs<LicensingFormInputs['billingRate']>('billingRate');
  const [licenseTerm, setLicenseTerm] =
    useFs<LicensingFormInputs['licenseTerm']>('licenseTerm');
  const [isLibraryTrial, setIsLibraryTrial] =
    useFs<LicensingFormInputs['isLibraryTrial']>('isLibraryTrial');
  const [isLibrary, setIsLibrary] =
    useFs<LicensingFormInputs['isLibrary']>('isLibrary');

  const [, setCountryName] = useState<string>('');
  const [countyName, setCountyName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [updateFields, setUpdateFields] = useState<boolean>(false);
  const [confirmSubmit, setConfirmSubmit] = useState<boolean | undefined>(
    undefined,
  );

  /**
   * Submits the step data and switches to the next step
   * @param inputsData
   */
  const handleSubmitData = (inputsData: Partial<FormInputs>) => {
    setData({
      ...data,
      ...inputsData,
    });
    if (confirmSubmit) setMode(AddDeviceSteps.Indigo);
  };

  useDidUpdate(() => {
    if (!locationId) {
      setCountryId(undefined);
      setCity(undefined);
      setCountyName('');
      setAddress('');
      setState('');
      setZipCode('');

      return;
    }

    setTimeout(async () => {
      try {
        const {
          country,
          city: locationCity,
          county,
          address1,
          state: locationState,
          zipcode: locationZipCode,
        } = isAdmin
          ? await api.locations.getAdminLocation(locationId ?? '')
          : await api.locations.getLocation(locationId ?? '');

        setCountryId(country);
        setCity(locationCity);
        setCountyName(county.name);
        setAddress(address1);
        setState(locationState?.name ?? '');
        setZipCode(locationZipCode);

        setUpdateFields(false);
      } catch (e) {
        // Do nothing
      }
    });
  }, [locationId, updateFields]);

  React.useEffect(() => {
    setUpdateFields(true);
  }, []);

  useDidUpdate(() => {
    if (!companyId) {
      setIndustry('');

      return;
    }

    setTimeout(async () => {
      try {
        const { industry: companyIndustry } =
          await api.companies.getAdminCompany(companyId);

        setIndustry(companyIndustry?.name ?? '');
        setUpdateFields(false);
      } catch (e) {
        // Do nothing
      }
    });
  }, [companyId, updateFields]);

  useDidUpdate(() => {
    if (!locationId) {
      setTimezoneId('');

      return;
    }

    setTimeout(async () => {
      try {
        const { timezone } = await api.locations.getLocation(locationId);
        if (timezone) setTimezoneId(timezone?.id);
      } catch (e) {
        // Do nothing
      }
    });
  }, [locationId]);

  const handleCompanyChange = (
    selectCompany: SelectedValue | ISelectCompany,
  ) => {
    if (selectCompany?.value !== 'loadingValue') {
      setOwnerId(selectCompany.ownerId);
      setCompanyId(selectCompany.value);
    }
  };

  const handleLocationCreate = () => {
    setConfirmSubmit(true);
    const company = companyId;
    setCompanyId('');
    debounce(() => setCompanyId(company), 500)();
  };

  return (
    <>
      <StepsTitle currentStep={AddDeviceSteps.GeneralInfo} />

      <form onSubmit={handleSubmit(handleSubmitData)} className="row mt-2">
        <div className="row mw-100 min-w-xl-1000px">
          <div className="col-xl-7 rounded border p-5 m-5 pb-0 pt-10">
            <div className="badge badge-light-primary fs-7 w-100 py-6 mb-7">
              {t('common.info')}
            </div>

            <div className="d-flex flex-row flex-column-fluid">
              <div className="d-flex flex-row-fluid flex-column me-5">
                <div>
                  <label htmlFor="deviceName" className="required fw-bold">
                    {t('devices.add_device_modal.device_name')}
                  </label>
                  <TextInput
                    name="name"
                    id="deviceName"
                    placeholder={t('common.type_here')}
                    value={name}
                    onChange={setName}
                  />
                  <FormErrorMessage
                    name="name"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>

                <div>
                  <label htmlFor="company" className="col-form-label pt-1">
                    {t('devices.add_device_modal.company')}
                  </label>
                  <MemoizedSelectCompany
                    id="company"
                    onChange={handleCompanyChange}
                    initialValue={companyId}
                    isClearable
                    trialCompany={trialDevice}
                  />
                  <FormErrorMessage
                    name="companyId"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>

                <div>
                  <label htmlFor="locationId" className="col-form-label pt-1">
                    {t('devices.add_device_modal.location')}
                  </label>
                  <MemoizedSelectLocation
                    disabled={!companyId}
                    companyId={companyId}
                    id="locationId"
                    onChange={({ value }) => {
                      if (value !== 'loadingValue') setLocationId(value);
                    }}
                    initialValue={locationId}
                    isClearable
                  />
                  <FormErrorMessage
                    name="locationId"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>
                {companyId && (
                  <CreateLocation
                    onSuccess={handleLocationCreate}
                    companyId={companyId}
                    ownerId={ownerId}
                  >
                    <p className="text-center">
                      <button type="button" className="btn btn-secondary me-2">
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr075.svg"
                          className="svg-icon-2"
                        />
                        {t('locations.add_location')}
                      </button>
                    </p>
                  </CreateLocation>
                )}

                <div>
                  <label htmlFor="timezoneId" className="col-form-label">
                    {t('common.timezone')}
                  </label>
                  <MemoizedSelectTimezoneId
                    id="timezoneId"
                    initialValue={timezoneId}
                    onChange={({ value }) => setTimezoneId(value as string)}
                    selectedTimeZoneId={timezoneId}
                    observeInitialValue
                    isDisabled={!!locationId}
                  />

                  <FormErrorMessage
                    name="timezoneId"
                    errors={errors}
                    className={errorMessageClassNames}
                  />
                </div>

                <div className="w-100">
                  <label className="fs-6 mb-2">{t('common.industry')}</label>
                  <Select
                    isDisabled
                    observeDefaultValueChange
                    options={[{ value: '1', label: industry }]}
                    defaultValue={{ value: '1', label: industry }}
                    onSelect={() => null}
                  />
                </div>
                <div>
                  <label
                    htmlFor="language1"
                    className="form-label fw-bold pt-1 mt-8"
                  >
                    {t('devices.add_device_modal.language_1')}:
                  </label>
                  <MemoizedSelectLanguage
                    id="language1"
                    placeholder={t('common.select')}
                    language={language1}
                    setLanguage={setLanguage1}
                  />
                  <FormErrorMessage
                    name="language1"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>
              </div>

              <div className="d-flex flex-row-fluid flex-column">
                <div>
                  <label htmlFor="address">{t('companies.address')}</label>
                  <TextInput
                    id="address"
                    name="address"
                    value={address}
                    disabled
                  />
                  <FormErrorMessage
                    name="address"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>

                <div>
                  <label htmlFor="city">{t('common.city')}</label>
                  <TextInput
                    id="city"
                    name="city"
                    onChange={setCity}
                    value={city}
                    disabled
                  />
                  <FormErrorMessage
                    name="city"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>

                <div>
                  <label htmlFor="state">{t('common.state')}</label>
                  <Select
                    isDisabled
                    observeDefaultValueChange
                    options={[{ value: '1', label: state }]}
                    defaultValue={{ value: '1', label: state }}
                    onSelect={() => null}
                  />
                  <FormErrorMessage
                    name="state"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>

                <div>
                  <label htmlFor="zipCode">{t('common.zip_code')}</label>
                  <TextInput
                    disabled
                    id="zipCode"
                    name="zipCode"
                    onChange={setZipCode}
                    placeholder={t('common.zip_code')}
                    value={zipCode}
                  />
                  <FormErrorMessage
                    name="zipCode"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>

                <div>
                  <label htmlFor="countyId">{t('common.county')}</label>
                  <Select
                    isDisabled
                    observeDefaultValueChange
                    options={[{ value: '1', label: countyName }]}
                    defaultValue={{ value: '1', label: countyName }}
                    onSelect={() => null}
                  />
                  <FormErrorMessage
                    name="countyId"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>

                <div className="row">
                  <div className="col-12">
                    <label htmlFor="countryId" className="fs-6 mb-3">
                      {t('apps.severeWeather.fields.country')}
                    </label>
                    <SelectCountry
                      preselect
                      id="countryId"
                      onChange={(selectedCountry) => {
                        setCountryName(selectedCountry.label as string);
                        setCountryId(selectedCountry.value);
                      }}
                      initialValue={countryId}
                      observeInitialValue
                      isClearable
                      disabled
                    />
                    <FormErrorMessage
                      name="countryId"
                      errors={errors}
                      className={errorMessageClassNames}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="language2"
                    className="form-label fw-bold pt-1"
                  >
                    {t('devices.add_device_modal.language_2')}:
                  </label>
                  <MemoizedSelectLanguage
                    id="language2"
                    placeholder={t('common.select')}
                    language={language2}
                    setLanguage={setLanguage2}
                  />
                  <FormErrorMessage
                    name="language2"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>

                <div className="d-flex align-items-center me-6">
                  <label
                    htmlFor="isTrial"
                    className="col-form-label text-lg-end me-3"
                  >
                    {t('devices.is_trial')}
                  </label>
                  <CheckBox
                    name="isTrial"
                    onChange={() => setTrialDevice(!trialDevice)}
                    checked={trialDevice}
                    className="mt-1"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-4 rounded border p-5 m-5 pb-0 pt-10">
            <div className="badge badge-light-primary fs-7 w-100 py-6 mb-7">
              {t('devices.licensing.licensing')}
            </div>

            <LicensingForm
              errors={errors}
              fieldsConfig={{
                isActive,
                setIsActive,
                isTrial,
                setIsTrial,
                licenseTypeId,
                setLicenseType,
                startDate,
                setStartDate,
                expireDate,
                setEndDate,
                billingRate,
                setBillingRate,
                isLibrary,
                setIsLibrary,
                licenseTerm,
                setLicenseTerm,
                isLibraryTrial,
                setIsLibraryTrial,
                libraryAccessStartDate,
                setLibraryStartDate,
                libraryAccessExpireDate,
                setLibraryEndDate,
              }}
            />
          </div>
        </div>

        <div className="position-relative mt-6 mb-13 w-100">
          <div className="separator position-absolute w-100 start-0" />
        </div>

        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-white text-primary"
            onClick={onDecline}
          >
            {t('common.close')}
          </button>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => setConfirmSubmit(true)}
            >
              {t('common.next_step')}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
