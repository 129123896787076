import axios from 'axios';
import { API_URL } from '../../../constants/api';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import UpdateDeviceLicenseRequestData from './interfaces/updateDeviceLicenseRequestData.interface';
import GetLicensesRequestData from './interfaces/getLicensesRequestData.interface';
import License from '../../../interfaces/license.interface';
import LicensesRoutes from './enums/licensesRoutes.enum';

export default {
  /**
   * Retrieves the list of licenses
   * @param requestData
   */
  getLicenses: async (
    requestData: GetLicensesRequestData,
  ): Promise<License[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LicensesRoutes.Licenses}/types`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the license data by id
   * @param id
   */
  getLicense: async (id: string): Promise<License> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LicensesRoutes.Licenses}/types/${id}`,
    });

    return data;
  },

  /**
   * Updates the device's license data
   * @param id
   * @param requestData
   */
  updateDeviceLicense: async (
    id: string,
    requestData: UpdateDeviceLicenseRequestData,
    name?: string,
  ): Promise<License> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${LicensesRoutes.Licenses}/devices/${id}`,
      data: { ...requestData, deviceName: name },
    });

    return data;
  },
};
