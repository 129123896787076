import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LibraryCategory from '../../../interfaces/libraryCategory.interface';
import { LibraryCategoryType } from '../../../enums/library/libraryCategoryType.enum';
import LibrariesQueryParam from '../../../api/endpoints/libraries/enums/librariesQueryParam.enum';
import routesConfig from '../../../routing/config';
import styles from './libraryCategory.module.scss';
import Tooltip from '../../Tooltip';
import { UserRole } from '../../../enums/userRole.enum';
import GlobalState from '../../../store/reducers/globalState.interface';
import CreateLibraryCategory from '../../../containers/AdminPanel/Library';
import DeleteCategory from '../../AdminPanel/Library/DeleteCategory';
import ribbon from '../../../assets/images/workplace/ribbon.png';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import history from '../../../constants/history';

export default ({
  category,
  total,
  onUpdate,
  createSubCategory,
  parentCategoryType,
}: {
  category: LibraryCategory;
  total?: number;
  onUpdate?: () => void;
  parentCategoryType?: LibraryCategoryType;
  createSubCategory?: boolean;
}) => {
  const { t } = useTranslation();
  const { roles } = useSelector((state: GlobalState) => state.userData.user);
  const [windowWidth] = useWindowSize();
  const imageContainerDivRef = React.useRef<HTMLDivElement>(null);

  const librariesRoute = `${routesConfig.indigoLibraries.route.replace(
    ':categoryName',
    category.name,
  )}?${LibrariesQueryParam.CategoryId}=${category.id}`;
  const subcategoriesRoute = `${routesConfig.indigoSubCategoriesLibraries.route
    .replace(':subCategoryName', category?.name as string)
    .replace(':categoryName', category?.parentCategory?.name as string)}?${
    LibrariesQueryParam.CategoryId
  }=${category.id}`;

  useEffect(() => {
    if (imageContainerDivRef?.current) {
      if (
        parentCategoryType === LibraryCategoryType.Free ||
        category.type === LibraryCategoryType.Free
      )
        imageContainerDivRef.current.style.height = `${
          imageContainerDivRef?.current?.clientWidth / 1.72
        }px`;
      else
        imageContainerDivRef.current.style.height = `${
          imageContainerDivRef?.current?.clientWidth / 1.115
        }px`;
    }
  }, [windowWidth, imageContainerDivRef]);

  return (
    <div className=" col-sm-6 col-md-3 mb-5 my-4">
      <div className={clsx(styles.cardWidth)}>
        <div
          className={clsx('card w-100 border', styles.shadow)}
          key={category.id}
        >
          <div className="d-flex">
            {roles.includes(UserRole.SuperAdmin) && (
              <div className="d-flex  justify-content-start">
                <CreateLibraryCategory
                  libraryCategory={category}
                  total={total}
                  isEdit
                  onUpdate={onUpdate}
                  parentCategoryType={parentCategoryType}
                  createSubCategory={createSubCategory}
                >
                  <span
                    className="btn d-flex position-absolute top-0 translate-middle btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow  mx-1"
                    style={{ left: '89%', zIndex: 10 }}
                  >
                    <Tooltip text={t('common.edit')}>
                      <i className="fa fa-edit" />
                    </Tooltip>
                  </span>
                </CreateLibraryCategory>

                <DeleteCategory
                  categoryName={category.name}
                  categoryId={category.id}
                  onUpdate={onUpdate}
                >
                  <span
                    className="btn d-flex position-absolute top-0 start-100 translate-middle btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow  mx-1"
                    style={{ zIndex: 10 }}
                  >
                    <Tooltip text={t('common.delete')}>
                      <i className="fa fa-trash" />
                    </Tooltip>
                  </span>
                </DeleteCategory>
              </div>
            )}
          </div>
          <div
            ref={imageContainerDivRef}
            className="d-flex flex-column justify-content-center align-items-center cursor-pointer"
            role="presentation"
            onClick={() => {
              if (createSubCategory) {
                history.push(subcategoriesRoute);
              } else history.push(librariesRoute);
            }}
            style={{ position: 'relative' }}
          >
            {/* <Link to={librariesRoute}> */}
            <img
              src={category.icon}
              alt="category icon"
              // className={clsx('img-fluid')}
              className={
                category.type === LibraryCategoryType.Free ||
                parentCategoryType === LibraryCategoryType.Free
                  ? clsx('card-img-top w-100')
                  : ''
              }
              style={
                category.type === LibraryCategoryType.Premium ||
                parentCategoryType === LibraryCategoryType.Premium
                  ? { maxHeight: '100%', maxWidth: '100%' }
                  : {
                      maxHeight: '100%',
                      position: 'absolute',
                      maxWidth: '100%',
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      // margin: 'auto',
                      objectFit: 'contain',
                    }
              }
            />
            {/* </Link> */}
          </div>
          <Link to={createSubCategory ? subcategoriesRoute : librariesRoute}>
            {category.type === LibraryCategoryType.Premium && (
              <div className={clsx(styles.overlayImage)}>
                <img src={ribbon} alt="" />
              </div>
            )}
            <div
              className={clsx(
                'd-flex justify-content-center  p-3',
                styles.nameWithBackGroundInFooterOfCard,
              )}
            >
              <p>{category.name}</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
