import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CanvaModal from '../../../../components/Apps/Canva/EditCanva';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import App from '../../../../interfaces/app.interface';
import Tooltip from '../../../../components/Tooltip';
import Modal from '../../../../components/Modal';
import api from '../../../../api';
import { getUserData } from '../../../../store/actions/userData';

const EditCanva = ({
  children,
  onUpdate,
  canva,
  scheduleApp,
}: {
  children?: React.ReactNode;
  onUpdate: () => void;
  scheduleApp: (app: App) => void;
  canva: App;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const handleSubmit = async (data: CreateAppRequestData) => {
    setLoading(true);
    await api.apps.updateApp(canva.id, data);
    close();
    setLoading(false);
    dispatch(getUserData());
    if (data.isScheduled) {
      scheduleApp(canva);
    }
    onUpdate();
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.edit')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-edit" />
            </span>
          </Tooltip>
        )}
      </span>
      <Modal
        title={t('apps.canva.editModalTitle')}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
      >
        <CanvaModal
          onClose={close}
          canva={canva}
          onSubmit={handleSubmit}
          isLoading={loading}
        />
      </Modal>
    </>
  );
};

export default EditCanva;
