import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AddContentSteps from '../../../../enums/steps/addContentSteps.enum';
import SelectNavTab from '../../../NavTabs/SelectNavTab';

interface StepsTitleProps {
  currentStep: AddContentSteps;
  skipSteps?: AddContentSteps[];
}

interface DefaultProps extends StepsTitleProps {
  skipSteps: Required<StepsTitleProps>['skipSteps'];
}

const StepsTitle = ({ currentStep, skipSteps }: DefaultProps) => {
  const { t } = useTranslation();

  const tabs = useMemo(
    () =>
      [
        {
          key: AddContentSteps.Info,
          isActive: currentStep === AddContentSteps.Info,
          text: t('contents.create_content.steps.common_info'),
        },
        {
          key: AddContentSteps.DevicesAndGroups,
          isActive: currentStep === AddContentSteps.DevicesAndGroups,
          text: t('contents.create_content.steps.devices'),
        },
        {
          key: AddContentSteps.Playlists,
          isActive: currentStep === AddContentSteps.Playlists,
          text: t('contents.create_content.steps.playlists'),
        },
      ].filter(({ key }) => !skipSteps.includes(key)),
    [skipSteps],
  );

  if (tabs.length === 1) {
    return null;
  }

  return (
    <SelectNavTab<AddContentSteps>
      isSelectable={false}
      withUnderline={false}
      className="mb-5 justify-content-center"
      tabs={tabs}
    />
  );
};

StepsTitle.defaultProps = {
  skipSteps: [],
};

export default StepsTitle;
