import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../Card';
import UsersTable from './Table';
import CreateUser from '../../Users/CreateUser';
import Search from '../../Search/QueryParamSearch';
import Pagination from '../../Pagination/QueryParamPagination';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import CreateUserRequestData from '../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import UsersQueryParam from '../../../api/endpoints/users/enums/usersQueryParam.enum';
import User from '../../../interfaces/user/user.interface';
import FiltersDropdown from '../../Users/FiltersDropdown';

// const pageSize = 6;
const tableRowHeight = 73.7;

const options = [
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 1000, label: '1000' },
];

export default ({
  users,
  currentUser,
  total,
  isLoading,
  children,
  onEditUser,
  onCreateUser,
  onRestoreUser,
  onSuspendUser,
  onDeleteUser,
}: {
  users: User[];
  currentUser: User;
  total: number;
  isLoading: boolean;
  children?: React.ReactNode;
  onEditUser?: (userId: string, data: Partial<CreateUserRequestData>) => void;
  onCreateUser: (data: CreateUserRequestData) => void;
  onRestoreUser?: (userId: string) => void;
  onSuspendUser?: (userId: string) => void;
  onDeleteUser?: (userId: string) => void;
}) => {
  const { t } = useTranslation();

  const [pageSize, setPageSize] = React.useState(10);

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          {children}

          <Search
            queryField={UsersQueryParam.Search}
            pageNumberQueryField={PaginationQueryParam.Page}
            placeholder={t('users.search_users')}
            className="me-2"
          />
          <div className="d-flex flex-wrap flex-stack">
            <FiltersDropdown className="me-2" />
            <div className="d-flex align-items-center align-content-center">
              <CreateUser
                className="me-2"
                onSubmit={onCreateUser}
                currentUser={currentUser}
              />
            </div>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <UsersTable
          currentUser={currentUser}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
          isLoading={isLoading}
          users={users}
          onEditUser={onEditUser}
          onRestoreUser={onRestoreUser}
          onSuspendUser={onSuspendUser}
          onDeleteUser={onDeleteUser}
        />
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-80px mb-4">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(+e.target.value);
              }}
              className="form-select form-select-sm form-select-solid"
            >
              {options.map((option) => (
                <option key={`${option.label}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-fill ps-5">
            <Pagination
              total={total}
              pageSize={pageSize}
              pageNumberQueryField={PaginationQueryParam.Page}
              pageSizeQueryField={PaginationQueryParam.Limit}
              className="mt-3 mb-5"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
