import clsx from 'clsx';
import React from 'react';
import noPreview from '../../../../../../../assets/images/noPreview/noPreview.png';
import Orientation from '../../../../../../../enums/orientation.enum';
import styles from '../../../tableRow.module.scss';
import playlistStyles from '../../../../../../Playlist/Playlist.module.scss';
import IContent from '../../../../../../../interfaces/content.interface';

export default ({ content }: { content: IContent }) => {
  const media = content.mediaGroup?.medias[0];

  return (
    <img
      src={media?.thumbnailUrl || media?.url || noPreview}
      onError={({ currentTarget }) => {
        // eslint-disable-next-line no-param-reassign
        currentTarget.src = noPreview;
      }}
      alt="preview"
      className={clsx(
        content.zoneId !== Orientation.Portrait ? 'h-30px' : '',
        styles.thumbnail,
        playlistStyles.image_background,
        playlistStyles.imageThumbnail,
      )}
    />
  );
};
