/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import clsx from 'clsx';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { toast } from 'react-toastify';
import { CreateFacebookFeedRequest } from '../../../../store/types/apps/facebookFeed';
import Theme from '../../../../enums/theme.enum';
import FacebookPreview from '../../../../enums/facebookPreview.enum';
import App from '../../../../interfaces/app.interface';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import Orientation from '../../../../enums/orientation.enum';
import FormErrorMessage from '../../../FormErrorMessage';
import Select from '../../../../containers/Apps/Facebook/SelectFeedType';
import { feedTypesTranslation } from '../../../../constants/translation/facebookFeedType';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import styles from './facebook.module.scss';
import facebookLogo from '../../../../assets/images/apps/Facebook.png';
import { Apis } from '../../../../api/endpoints/apps/Facebook';
import { FACEBOOK_APP_ID } from '../../../../constants/dotenvconst';

type CreateFacebookRequestFormMutated = Omit<
  CreateFacebookFeedRequest,
  'config' | 'placement'
>;

interface getFacebookProfile {
  id: string;
  name: string;
}
interface getFacebookLoginData {
  accessToken: string;
  userID: string;
  expiresIn: Number;
  graphDomain: string;
  signedRequest: string;
}
interface CreateFacebookFeedRequestFormPayload
  extends CreateFacebookRequestFormMutated {
  facebookPreview: FacebookPreview;
  theme: Theme;
  facebookUser: string[];
  pageName: string;
  pagesInfos?: string[];
}
export interface CreateFeedModalProps {
  onSubmit: (data: CreateFacebookFeedRequest) => void;
  facebook?: App;
  onClose?: () => void;
  loading?: boolean;
  update?: boolean;
}
export default ({
  onSubmit,
  onClose,
  loading,
  update,
  facebook = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();
  const [facebookLoginData, setFacebookLoginData] =
    useState<getFacebookLoginData>();
  /* const [feeds, setFeeds] = React.useState<any>([]); */
  const [facebookProfileData, setFacebookProfileData] =
    useState<getFacebookProfile>();
  const initialValues: CreateFacebookFeedRequestFormPayload = {
    name: facebook?.name ?? '',
    facebookPreview: facebook?.dependency?.config?.facebookPreview ?? '',
    theme: facebook?.dependency?.config?.Theme ?? '',
    facebookUser: facebook?.dependency?.config?.facebookUser ?? [],
    pageName: facebook?.dependency?.config?.pageName ?? '',
    pagesInfos: facebook?.dependency?.config?.pagesInfos ?? [],
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t('apps.facebook.required'))
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    facebookPreview: Yup.string()
      .required(t('apps.facebook.required'))
      .defined(),
    theme: Yup.string().required(t('apps.facebook.required')).defined(),
    pageName: Yup.string().required(t('apps.facebook.required')).defined(),
  }).defined();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateFacebookFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });
  const [isLoading, setLoading] = useState<boolean>(false);

  const facebookPreviewValue: FacebookPreview =
    initialValues.facebookPreview as FacebookPreview;
  const fbPageName: string = initialValues.pageName as string;

  const feedThemeValue: Theme = initialValues.theme as Theme;

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateFacebookFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const [facebookUser, setFacebookUser] = useStateWithHookForm<
    CreateFacebookFeedRequestFormPayload,
    string[]
  >({ setValue, trigger, name: 'facebookUser' }, initialValues.facebookUser);
  const [pageNames, setPageNames] = useStateWithHookForm<
    CreateFacebookFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'pageName' }, fbPageName);

  const [allPagesInfos, setAllPagesInfos] = React.useState<Object>(
    JSON.parse(JSON.stringify(initialValues.pagesInfos)),
  );

  const handleFeedNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFeedName(value),
    [setFeedName],
  );
  const [isFacebookLoggedIn, setIsFacebookLoggedIn] = useState<boolean>(
    update ?? false,
  );
  const [FacebookType, setFacebookType] = useStateWithHookForm<
    CreateFacebookFeedRequestFormPayload,
    FacebookPreview
  >({ setValue, trigger, name: 'facebookPreview' }, facebookPreviewValue);

  const handleFacebookPreview = (value: FacebookPreview) => {
    setFacebookType(value);
  };
  const handlePageNameChange = (value: string) => {
    setPageNames(value);
  };
  const [FacebookTheme, setFacebookTheme] = useStateWithHookForm<
    CreateFacebookFeedRequestFormPayload,
    Theme
  >({ setValue, trigger, name: 'theme' }, feedThemeValue);

  const handleFacebookTheme = (value: Theme) => {
    setFacebookTheme(value);
  };

  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [pagesLoading, setPagesLoading] = useState<boolean>(false);
  const handleOnSubmit = async (data: CreateFacebookFeedRequestFormPayload) => {
    try {
      setLoading(true);
      if (!update) {
        const getFeeds = await Apis.getFeedsOfPage(
          data?.pageName,
          JSON.parse(JSON.stringify(data?.facebookUser))?.accessToken,
          JSON.parse(JSON.stringify(data?.facebookUser))?.id,
        );
        if (getFeeds.status === 200) {
          const { name } = data;
          const createFacebookFeedRequest: CreateFacebookFeedRequest = {
            name,
            isScheduled,
            config: {
              facebookPreview: FacebookType,
              Theme: FacebookTheme,
              facebookUser,
              AccessToken:
                JSON.parse(JSON.stringify(getFeeds?.data?.access_token)) ?? '',
              pageName: pageNames,
              facebookFeeds: JSON.parse(
                JSON.stringify(getFeeds?.data?.facebookFeeds),
              ),
            },
            zoneId: Orientation.Landscape,
          };
          onSubmit(createFacebookFeedRequest);
        } else {
          toast.error('Something went wrong');
          setLoading(false);
        }
      } else {
        const { name } = data;
        const createFacebookFeedRequest: CreateFacebookFeedRequest = {
          name,
          isScheduled,
          config: {
            facebookPreview: FacebookType,
            Theme: FacebookTheme,
            facebookUser,
            pageName: pageNames,
          },
          zoneId: Orientation.Landscape,
        };
        onSubmit(createFacebookFeedRequest);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const responseFacebookOnLoginSuccess = (response: any) => {
    setFacebookLoginData(response);
  };
  const responseFacebookOnLoginFailure = (response: any) => {
    console.log(response);
    toast.error('Something went wrong please try again later');
  };
  const responseFacebookOnGetProfile = (response: any) => {
    setFacebookProfileData(response);
  };
  const loggedIn = async () => {
    setFacebookUser(
      JSON.parse(
        JSON.stringify({ ...facebookLoginData, ...facebookProfileData }),
      ),
    );
    setIsFacebookLoggedIn(true);
    try {
      setPagesLoading(true);
      const res = await Apis.getPages(
        facebookLoginData?.userID,
        facebookLoginData?.accessToken,
      );
      if (res?.status === 200) {
        setAllPagesInfos(res?.data?.pages);
        setPagesLoading(false);
      }
    } catch (err) {
      console.log(err);
      setPagesLoading(false);
    }
  };
  React.useMemo(() => {
    if (
      facebookLoginData?.accessToken &&
      facebookProfileData?.name &&
      facebookLoginData?.userID
    ) {
      loggedIn();
    }
  }, [facebookLoginData, facebookProfileData]);

  React.useEffect(() => {
    if (loading) setLoading(loading);
    else setLoading(false);
  }, [loading]);

  return (
    <div className="container ps-0 mx-auto py-auto">
      {isFacebookLoggedIn ? (
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id="form"
          className="d-sm-flex flex-column justify-content-center"
        >
          <img
            src={facebookLogo}
            alt="facebook"
            className={clsx('mb-4', styles.facebookImage)}
          />
          <h1 className="text-center text-dark mb-4">
            {t('apps.facebook.title')}
          </h1>
          <button
            type="button"
            className={clsx(styles.facebookLogin, 'mb-5')}
            disabled
          >
            <i className="fab fa-facebook-square" />
            {'Authorized with Facebook as '}
            {JSON.parse(JSON.stringify(facebookUser))?.name}
          </button>
          <div className="d-sm-flex flex-row p-4 w-100">
            <div className="d-sm-flex flex-column mx-2 w-100">
              <label
                htmlFor="name"
                className="required text-dark fw-bolder my-1"
              >
                {`${t('apps.facebook.facebookTitle')}`}
              </label>
              <input
                name="name"
                id="name"
                value={feedName}
                onChange={handleFeedNameChange}
                className="form-control form-control-solid"
                placeholder={t('apps.facebook.type_Here')}
                type="text"
              />
              <FormErrorMessage
                name="name"
                errors={errors}
                className="my-1 px-2"
              />
            </div>
            <div className="d-sm-flex flex-column mx-2 w-100">
              <label
                htmlFor="pageName"
                className="required text-dark fw-bolder my-1"
              >
                {t('apps.facebook.Page')}
              </label>

              <Select
                isDisabled={update || pagesLoading}
                id="pageName"
                placeholder={t('my_account.left_table.lang')}
                defaultValue={{
                  value: pageNames,
                  label: _.capitalize(pageNames),
                }}
                isloading={pagesLoading}
                onSelect={(selectedFacebookPageName) =>
                  handlePageNameChange(selectedFacebookPageName as string)
                }
                options={Object.values(allPagesInfos).map((languageVal) => ({
                  label: _.capitalize(languageVal?.name),
                  value: languageVal?.name,
                }))}
                observeDefaultValueChange
              />
              <FormErrorMessage
                name="pageName"
                errors={errors}
                className="my-1 px-2"
              />
            </div>
            <div className="d-sm-flex flex-column mx-2 w-100">
              <label
                htmlFor="facebookPreview"
                className="required text-dark fw-bolder my-1"
              >
                {t('apps.facebook.facebookAccountType')}
              </label>
              <Select<FacebookPreview>
                id="facebookPreview"
                placeholder={t('my_account.left_table.lang')}
                defaultValue={{
                  value: FacebookType,
                  label: _.capitalize(t(feedTypesTranslation[FacebookType])),
                }}
                onSelect={(selectedFacebookAccountType) =>
                  handleFacebookPreview(
                    selectedFacebookAccountType as FacebookPreview,
                  )
                }
                options={Object.values(FacebookPreview).map((languageVal) => ({
                  label: _.capitalize(t(feedTypesTranslation[languageVal])),
                  value: languageVal,
                }))}
                observeDefaultValueChange
              />
              <FormErrorMessage
                name="facebookPreview"
                errors={errors}
                className="my-1 px-2"
              />
            </div>
            <div className="d-sm-flex flex-column px-4 w-100">
              <label
                htmlFor="theme"
                className="required text-dark fw-bolder my-1"
              >
                {t('apps.facebook.theme')}
              </label>
              <Select<Theme>
                id="theme"
                placeholder={t('my_account.left_table.lang')}
                defaultValue={{
                  value: FacebookTheme,
                  label: _.capitalize(t(feedTypesTranslation[FacebookTheme])),
                }}
                onSelect={(selectedFacebookTheme) =>
                  handleFacebookTheme(selectedFacebookTheme as Theme)
                }
                options={Object.values(Theme).map((languageVal) => ({
                  label: _.capitalize(t(feedTypesTranslation[languageVal])),
                  value: languageVal,
                }))}
                observeDefaultValueChange
              />
              <FormErrorMessage
                name="theme"
                errors={errors}
                className="my-1 px-2"
              />
            </div>
          </div>
          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-sm-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-sm-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
                onClick={() => setIsScheduled(false)}
              >
                <WithSpinner
                  isLoading={isLoading && !isScheduled}
                  className="min-w-100px"
                  size="md"
                >
                  {t('common.save')}
                </WithSpinner>
              </button>
              {!Object.keys(facebook).length && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                >
                  <WithSpinner
                    isLoading={isLoading && isScheduled}
                    className="min-w-100px"
                    size="md"
                  >
                    {t('common.save_schedule')}
                  </WithSpinner>
                </button>
              )}
            </div>
          </div>
        </form>
      ) : (
        <div className="d-sm-flex flex-column justify-content-between">
          <img
            src={facebookLogo}
            alt="facebook"
            className={clsx('mb-4', styles.facebookImage)}
          />
          <h1 className="text-center text-dark mb-4">
            {t('apps.facebook.title')}
          </h1>
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            autoLoad={false}
            scope="public_profile,pages_show_list,pages_read_engagement"
            fields="name"
            onSuccess={responseFacebookOnLoginSuccess}
            onFail={responseFacebookOnLoginFailure}
            onProfileSuccess={responseFacebookOnGetProfile}
            className={clsx(
              styles.facebookLogin,
              'd-sm-flex justify-content-center w-50 mx-auto',
            )}
          />
        </div>
      )}
    </div>
  );
};
