import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { isEqual, map } from 'lodash';
import Counter from '../../Counter';
import TextInput from '../../TextInput';
import DatePicker from '../../DatePicker';
import WithSpinner from '../../WithSpinner';
import FormErrorMessage from '../../FormErrorMessage';
import Orientation from '../../../enums/orientation.enum';
import CreateContentRequestData from '../../../api/endpoints/contents/interfaces/createContentRequestData.interface';
import { prepareHookFromState } from '../../../utils/hooks/useStateWithHookForm';
import validation from '../../../utils/validation';
import SelectDaysOfWeek, {
  getDayOfWeekOption,
} from '../../../containers/SelectDaysOfWeek';
import DayOfWeek from '../../../enums/dayOfWeek.enum';
import SelectTags from '../../../containers/SelectTags';
import { TagsTarget } from '../../../api/endpoints/tags/enums/tagsTarget.enum';
import { SelectedValue } from '../../Select/CreatableAsyncSelect';
import SelectOrientation from '../../../containers/SelectOrientation';
import SwitchCheckbox from '../../SwitchCheckbox';
import Playlist from '../../../interfaces/playlist.interface';
import Payload from '../../../enums/excludePayload.enum';
import { FileMimeType } from '../../../store/types/fileManager';
import Tooltip from '../../Tooltip';
import User from '../../../interfaces/user/user.interface';
import api from '../../../api';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';

interface FormInputs {
  name: string;
  orientation: Orientation;
  files: File[];
  duration: number;
  startDate: Date;
  endDate: Date;
  weekDays: DayOfWeek[];
  tags: string[];
  lockContent: boolean;
}

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

export default ({
  onSubmit,
  initialData,
  contentType,
  validationSchema,
  isLoading = false,
  submitButtonText,
  config = {},
  playlist,

  isEdit,
  currentUserId,
  isApp,
  contentId,
}: {
  onSubmit: (contentData: Partial<CreateContentRequestData>) => void;
  isLoading?: boolean;
  contentType?: string;
  initialData?: Partial<FormInputs>;
  validationSchema: { [key: string]: any };
  submitButtonText?: string;
  config?: {
    skipDuration?: boolean;
  };
  playlist?: Playlist;

  isEdit?: boolean;
  currentUserId?: string;
  isApp?: boolean;
  contentId?: string;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validation.object(validationSchema).required()),
    defaultValues: initialData,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    initialData ?? {},
  );
  const [name, setName] = useFs<FormInputs['name']>('name');
  const [startDate, setStartDate] = useFs<FormInputs['startDate']>('startDate');
  const [endDate, setEndDate] = useFs<FormInputs['endDate']>('endDate');
  const [duration, setDuration] = useFs<FormInputs['duration']>('duration');
  const [orientation, setOrientation] =
    useFs<FormInputs['orientation']>('orientation');
  const [weekDays, setDays] = useFs<FormInputs['weekDays']>('weekDays');
  const [tags, setTags] = useFs<FormInputs['tags']>('tags');
  const [isContentLocked, setIsContentLocked] =
    useFs<FormInputs['lockContent']>('lockContent');
  const [contentOwner, setContentOwner] = useState<User>();
  const [disableLockButton, setDisableLockButton] = useState(false);
  const handleSubmitData = (data: Partial<FormInputs>) => {
    const body: Partial<CreateContentRequestData> = {
      name: data.name,
      zoneId: data.orientation,
      file: data.files && data.files[0],
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      tags: data.tags,
      weekDays: data.weekDays,
      isLocked: data.lockContent,
    };

    if (
      contentType !== Payload.Video &&
      !config.skipDuration &&
      contentType !== FileMimeType.VIDEO
    ) {
      body.duration = String(data.duration);
    }

    onSubmit(body);
  };
  const userConnectRoles = useSelector(
    (state: GlobalState) => state.userData.user.roles,
  );
  const getContentOwnerForContentLock = async (app: boolean) => {
    if (!contentId) return;
    try {
      console.log('appappapp', app);
      const response = await api.playlists.getContentOwner(contentId, app);

      setContentOwner(response);
    } catch (error) {
      console.log(error);
    }
  };

  const disableLockFunction = async () => {
    if (
      (contentOwner && contentOwner?.id === currentUserId) ||
      userConnectRoles.includes(UserRole.SuperAdmin) ||
      userConnectRoles.includes(UserRole.AccountOwner)
    ) {
      setDisableLockButton(false);
      return;
    }

    if (contentOwner && contentOwner?.id !== currentUserId) {
      setDisableLockButton(true);
      return;
    }

    setDisableLockButton(false);
  };

  useEffect(() => {
    if (isEdit && contentOwner) {
      disableLockFunction();
    }
  }, [contentOwner]);

  useEffect(() => {
    if (isApp) {
      getContentOwnerForContentLock(true);
    } else {
      getContentOwnerForContentLock(false);
    }
  }, []);

  useEffect(() => {
    if (!playlist?.template) {
      setOrientation(Orientation.FullScreen);
    }
  }, [playlist]);

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <label htmlFor="contentName" className="required fw-boldest mb-2">
          {t('contents.title')}
        </label>
        <TextInput
          name="name"
          id="contentName"
          placeholder={t('contents.type_here')}
          value={name}
          onChange={setName}
          useDisabledStyles
        />
        <FormErrorMessage
          name="name"
          errors={errors}
          className={errorMessageClassNames}
        />
        {contentType !== FileMimeType.MP4 &&
          !config.skipDuration &&
          contentType !== FileMimeType.VIDEO && (
            <>
              <Counter
                name="duration"
                title={t('common.duration')}
                placeholder={t('contents.type_here')}
                value={duration}
                onChange={setDuration}
                className="w-100"
                classNameTitle="fw-boldest"
              />
              <FormErrorMessage
                name="duration"
                errors={errors}
                className={errorMessageClassNames}
              />
            </>
          )}
        <p>
          <h4>{t('playlists.schedule')}</h4>
          <label className="fw-bold mb-2">{t('contents.start_date')}</label>
          <DatePicker
            name="startDate"
            value={startDate}
            options={{
              enableTime: true,
              defaultHour: 0,
            }}
            onChange={(newDate) => setStartDate(newDate[0])}
          />
        </p>
        <p>
          <label className="fw-bold mb-2">{t('contents.end_date')}</label>
          <DatePicker
            name="endDate"
            value={endDate}
            options={{
              enableTime: true,
              defaultHour: 23,
              defaultMinute: 59,
            }}
            onChange={(newDate) => setEndDate(newDate[0])}
          />
          <FormErrorMessage
            name="endDate"
            errors={errors}
            className={errorMessageClassNames}
          />
        </p>
        <label
          htmlFor="contentOrientation"
          className="required fw-boldest mb-2"
        >
          {t('common.panel')}
        </label>
        {playlist?.template ? (
          <>
            <SelectOrientation
              id="contentOrientation"
              placeholder={t('common.select')}
              orientation={orientation}
              setOrientation={setOrientation}
              playlist={playlist}
            />
            <FormErrorMessage
              name="orientation"
              errors={errors}
              className={errorMessageClassNames}
            />
          </>
        ) : (
          <div className="form-control form-control-solid mb-5">
            <span>{t('common.orientations.full_screen')}</span>
          </div>
        )}

        <p>
          <label className="fw-boldest mb-4">{t('contents.mtwtfss')}</label>
          <SelectDaysOfWeek
            initialValue={weekDays.map((day) => getDayOfWeekOption(day, t))}
            onChange={(selectedDays) => setDays(selectedDays as DayOfWeek[])}
          />
          <FormErrorMessage
            name="weekDays"
            errors={errors}
            className={errorMessageClassNames}
          />
        </p>
        <label htmlFor="contentTag" className="fw-boldest mb-2">
          {t('contents.tag')}
        </label>

        <SelectTags
          id="contentTag"
          target={TagsTarget.Contents}
          initialValue={tags.map((value) => ({ value, label: value }))}
          onChange={(values) => {
            const newValues = map<SelectedValue>(values, 'value');

            if (!isEqual(newValues, tags)) {
              setTags(newValues);
            }
          }}
        />
        <div className="d-inline-flex justify-content-between w-100 mt-5">
          <label htmlFor="lockContent" className="form-label fw-bold pt-1">
            {t('contents.lock_content')}
          </label>
          {disableLockButton ? (
            <Tooltip text={t('contents.lock_button_permission')}>
              <SwitchCheckbox
                disabled={disableLockButton}
                name="lockContent"
                inputClassName="h-20px w-30px"
                checked={!!isContentLocked}
                onChange={({ currentTarget: { checked } }) =>
                  setIsContentLocked(checked)
                }
              />
            </Tooltip>
          ) : (
            <SwitchCheckbox
              name="lockContent"
              inputClassName="h-20px w-30px"
              checked={!!isContentLocked}
              onChange={({ currentTarget: { checked } }) =>
                setIsContentLocked(checked)
              }
            />
          )}
        </div>
        <FormErrorMessage
          name="tag"
          errors={errors}
          className={errorMessageClassNames}
        />
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            {submitButtonText ?? t('common.upload')}
          </button>
        </div>
      </form>
    </WithSpinner>
  );
};
