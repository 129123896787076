import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LibraryCategoryType } from '../../../../enums/library/libraryCategoryType.enum';
import LibraryCategory from '../../../../interfaces/libraryCategory.interface';
import { prepareHookFromState } from '../../../../utils/hooks/useStateWithHookForm';
import WithSpinner from '../../../WithSpinner';
import Modal from '../../../Modal';
import TextInput from '../../../TextInput';
import Radiobutton from '../../../RadioButton';
import FileUploader from '../../../FileUploader';
import styles from '../../../Libraries/LibraryCategory/libraryCategory.module.scss';
import SelectCustomOrder from '../../../../containers/SelectCustomOrder';
import SelectLanguage from '../../../../containers/SelectLanguage';
import Language from '../../../../enums/language.enum';
import UploadImage from '../../../UploadProfilePhoto';
import { createCategoryValidation } from '../../../../validation/category';
import validation from '../../../../utils/validation';
import FormErrorMessage from '../../../FormErrorMessage';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';
interface FormInputs {
  name: string;
  type: LibraryCategoryType;
  icon: string;
  customOrder: number;
  language: any;
  file: File;
}

export default ({
  libraryCategory,
  isLoading,
  onSubmit,
  total,
  createSubCategory,
  parentCategoryType,
}: {
  libraryCategory?: LibraryCategory;
  isLoading: boolean;
  total?: number;
  onSubmit?: (data: any) => void;
  createSubCategory?: boolean;
  parentCategoryType?: LibraryCategoryType;
}) => {
  const { t } = useTranslation();

  const defaultValues: Partial<FormInputs> = {
    name: libraryCategory?.name ?? '',
    type:
      libraryCategory?.type ?? parentCategoryType ?? LibraryCategoryType.Free,
    icon: libraryCategory?.icon ?? '',
    customOrder: libraryCategory?.customOrder ?? 0,
    language: libraryCategory?.language ?? Language.English,
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    // isSubmitting
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      validation.object(createCategoryValidation(t)).required(),
    ),
    defaultValues,
  });

  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [name, setName] = useFs<FormInputs['name']>('name');
  const [icon] = useFs<FormInputs['icon']>('icon');
  const [type, setType] = useFs<FormInputs['type']>('type');
  const [language, setLanguage] = useFs<FormInputs['language']>('language');
  const [customOrder, setCustomOrder] =
    useFs<FormInputs['customOrder']>('customOrder');
  const [file, setFile] = useFs<FormInputs['file']>('file');
  const [loading, setloading] = useState(false);
  const handleSubmitData = () => {
    if (onSubmit) {
      setloading(true);
      onSubmit({
        name,
        type,
        customOrder,
        language,
        file,
      });
      // setloading(false);
    }
  };

  const buttonText = () => {
    if (createSubCategory) {
      if (libraryCategory) {
        return t('library.indigo.editSubCategory');
      }
      return t('library.indigo.createSubCategory');
    }
    if (libraryCategory) {
      return t('library.indigo.editCategory');
    }
    return t('library.indigo.createCategory');
  };

  return (
    <WithSpinner isLoading={isLoading}>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <div className="row  ">
          <div>
            <label className="fs-6 fw-bolder me-2">
              {t('library.indigo.categoryName')}
            </label>
            <TextInput
              name="name"
              id="weatherName"
              placeholder={t('contents.type_here')}
              className="border-secondary mt-2"
              value={name}
              onChange={setName}
            />

            <FormErrorMessage
              name="name"
              errors={errors}
              className={errorMessageClassNames}
            />
          </div>

          <div className="w-100 mt-2">
            <label className="fs-6 fw-bolder me-2">
              {t('library.indigo.categoryIcon')}
            </label>

            {!libraryCategory && (
              <WithSpinner isLoading={false} className="h-100px">
                <FileUploader
                  name="files"
                  mutedText={t('contents.upload_image')}
                  accept=".png, .jpg, .jpeg"
                  onChange={(files) => setFile(files[0])}
                  // defaultValue={file}
                  className="w-100 mt-2"
                  containerClassName="justify-content-center"
                />

                <FormErrorMessage
                  name="file"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </WithSpinner>
            )}

            {libraryCategory && (
              <WithSpinner isLoading={false} className="h-100px">
                <div className="d-flex flex-wrap justify-content-center mb-5">
                  <UploadImage
                    name="currentIcon"
                    setPic={setFile}
                    oldPhoto={file ? URL.createObjectURL(file) : icon}
                    // className=
                    // defaultValue={file}
                    title="Upload New Icon"
                    icon={`${styles.icon}  `}
                  />
                </div>
              </WithSpinner>
            )}
          </div>

          <label
            htmlFor="isActive"
            className="d-flex align-items-center fs-4 fw-bold mb-2 mt-2 "
          >
            <strong>{t('library.indigo.categoryType')}</strong>
          </label>
          <div className="d-flex w-100 mt-2">
            {(!parentCategoryType ||
              parentCategoryType === LibraryCategoryType.Free) && (
              <div
                className="me-10"
                role="presentation"
                onClick={() => {
                  if (!parentCategoryType) setType(LibraryCategoryType.Free);
                }}
              >
                <Radiobutton
                  title={t('common.free')}
                  active={
                    parentCategoryType
                      ? parentCategoryType === LibraryCategoryType.Free
                      : type !== LibraryCategoryType.Premium
                  }
                />
              </div>
            )}
            {(!parentCategoryType ||
              parentCategoryType === LibraryCategoryType.Premium) && (
              <div
                className="me-10"
                role="presentation"
                onClick={() => {
                  if (!parentCategoryType) setType(LibraryCategoryType.Premium);
                }}
              >
                <Radiobutton
                  title={t('common.premium')}
                  active={
                    parentCategoryType
                      ? parentCategoryType === LibraryCategoryType.Premium
                      : type !== LibraryCategoryType.Free
                  }
                />
              </div>
            )}
          </div>

          <div>
            <div>
              <label htmlFor="language" className="form-label fw-bold pt-1">
                {t('common.language')}:
              </label>
              <SelectLanguage
                id="language"
                placeholder={t('users.common.select_language')}
                language={language}
                setLanguage={setLanguage}
                isDisabled={!!libraryCategory}
              />
            </div>
          </div>
          {!createSubCategory && !parentCategoryType && (
            <>
              {' '}
              <label className="fs-6 fw-bolder mt-2">
                {t('library.indigo.categoryOrder')}
              </label>
              <div className="w-100 mt-2">
                <SelectCustomOrder
                  total={total ?? 1}
                  onChange={(selectOrder) => setCustomOrder(selectOrder - 1)}
                  initialValue={{
                    value: String(customOrder),
                    label: String(customOrder + 1),
                  }}
                  // isEdit={!!libraryCategory}
                />
              </div>{' '}
            </>
          )}
        </div>

        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? (
              <>
                {' '}
                <span>
                  Saving
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              </>
            ) : (
              buttonText()
            )}
          </button>
        </div>
      </form>
    </WithSpinner>
  );
};
