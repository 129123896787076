import { combineReducers } from 'redux';
import PlaylistsReducer from './playlists';
import UserDataReducer from './userData';
import SignedAsReducer from './signedAs';
import ContentsReducer from './contents';
import DevicesReducer from './devices';
import DevicesGroupsReducer from './devicesGroups';
import UsersGroupsReducer from './usersGroups';
import UsersReducer from './users';
import CustomersReducer from './customers';
import CompaniesReducer from './companies';
import BreadcrumbsReducer from './breadcrumbs';
import { fileManagerReducer } from './fileManager';
import {
  youtubeFeedsReducer,
  htmlFeedsReducer,
  LiveStreamFeedsReducer,
} from './apps';

export const rootReducer = combineReducers({
  users: UsersReducer,
  customers: CustomersReducer,
  companies: CompaniesReducer,
  devices: DevicesReducer,
  devicesGroups: DevicesGroupsReducer,
  usersGroups: UsersGroupsReducer,
  playlists: PlaylistsReducer,
  userData: UserDataReducer,
  contents: ContentsReducer,
  fileManager: fileManagerReducer,
  signedAs: SignedAsReducer,
  youtubeFeeds: youtubeFeedsReducer,
  htmlFeeds: htmlFeedsReducer,
  breadcrumbs: BreadcrumbsReducer,
  liveStreamFeeds: LiveStreamFeedsReducer,
});
