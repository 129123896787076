import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import api from '../../../api';
import Device from '../../../interfaces/devices/device.interface';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import DevicesTable from '../../../components/Devices';
import BulkUpdateDevicesRequestDataInterface from '../../../api/endpoints/devices/interfaces/bulkUpdateDevicesRequestData.interface';
import UpdateDeviceRequestData from '../../../api/endpoints/devices/interfaces/updateDeviceRequestData.interface';
import DevicesQueryParam from '../../../api/endpoints/devices/enums/devicesQueryParam.enum';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';

export default ({
  onGetDeviceGroup,
  children,
  currentUserId,
}: {
  onGetDeviceGroup: () => void;
  children: React.ReactNode;
  currentUserId?: string | null;
}) => {
  const [devices, setDevices] = useState<Device[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [deviceLoading, setDevicesLoading] = useState<boolean>(true);
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const isSuperAdmin = roles.includes(UserRole.SuperAdmin);
  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();

  const getDeviceGroupDevices = async (recentSearch: string) => {
    try {
      setDevicesLoading(true);
      const { items, meta } = isSuperAdmin
        ? await api.devices.getDeviceGroupDevicesAP({
            deviceGroupId: id,
            ...readAllFromQueryParams(recentSearch),
          })
        : await api.devicesGroups.getDeviceGroupDevices(
            readAllFromQueryParams(recentSearch),
            id,
          );
      setDevices(items);
      setTotal(meta.totalItems);
    } catch (e) {
      // Do nothing for now
    }
    setDevicesLoading(false);
  };

  useEffect(() => {
    setDevicesLoading(true);
    getDeviceGroupDevices(search);
  }, [search]);

  const handleAssignDevicesToDeviceGroup = async (data: SelectedValue[]) => {
    setDevicesLoading(true);

    await Promise.all(
      data.map(async ({ value }) => {
        try {
          setDevicesLoading(true);
          await api.devices.assignDeviceToDeviceGroup(value as string, id);

          getDeviceGroupDevices(search);
          onGetDeviceGroup();
        } catch (e) {
          // Do nothing for now
        }
      }),
    );
    setDevicesLoading(false);
  };

  const handleUnAssignDeviceFromDeviceGroup = async (
    deviceId: string,
    deviceGroupId: string,
  ) => {
    try {
      setDevicesLoading(true);
      await api.devices.unAssignDeviceFromDeviceGroup(deviceId, deviceGroupId);
      setDevices(devices.filter((device) => device.id !== deviceId));
      onGetDeviceGroup();
    } catch (e) {
      // Do nothing for now
    }
    setDevicesLoading(false);
  };

  const handleEditDeviceInDeviceGroup = async (data: {
    id: string;
    data: UpdateDeviceRequestData;
  }) => {
    try {
      setDevicesLoading(true);
      await api.devices.updateDevice(data.data, data.id);
      getDeviceGroupDevices(search);
    } catch (e) {
      // Do nothing for now
    }
    setDevicesLoading(false);
  };

  const handleBulkEditDeviceInDeviceGroup = async (
    data: Partial<BulkUpdateDevicesRequestDataInterface>,
  ) => {
    try {
      setDevicesLoading(true);

      await api.devices.bulkUpdateDevices(data);
      getDeviceGroupDevices(search);
    } catch (e) {
      // Do nothing for now
    }
    setDevicesLoading(false);
  };
  return (
    <DevicesTable
      skipFilters={[DevicesQueryParam.GroupId]}
      devices={devices}
      total={total}
      isLoading={deviceLoading}
      onEditDevice={handleEditDeviceInDeviceGroup}
      onBulkEditDevice={handleBulkEditDeviceInDeviceGroup}
      unAssignDevice={handleUnAssignDeviceFromDeviceGroup}
      assignDevice={handleAssignDevicesToDeviceGroup}
      config={{ skipEditModalDeviceGroup: true }}
      onUpdateLicense={() => getDeviceGroupDevices(search)}
      withDeleteAction
      isFilter
      denyDifferentLicense
      currentUserId={currentUserId as string}
    >
      {children}
    </DevicesTable>
  );
};
