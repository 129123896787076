import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { components, OptionProps } from 'react-select';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import ReactSelect from '../../../Select/RegularSelect';
import Placements from '../../../../enums/placements.enum';
import { prepareHookFromState } from '../../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../../FormErrorMessage';
import TextInput from '../../../TextInput';
import SelectCountry from '../../../../containers/SelectCountry';
import SelectColor, {
  ColourOption,
  colourOptions,
} from '../../../../containers/SelectColor';
import SelectMode, { Mode } from '../../../Select/SelectMode';
import SelectDevices from '../../../../containers/Devices/SelectDevices';
import SelectDevicesGroups from '../../../../containers/SelectDevicesGroups';
import styles from '../../../PlaylistModal/styles.module.scss';
import { SelectedValue } from '../../../Select/SelectAsync';
import validation from '../../../../utils/validation';
import { weatherCreateValidation } from '../../../../validation/apps/weather';
import App from '../../../../interfaces/app.interface';
import Device from '../../../../interfaces/devices/device.interface';
import DeviceGroup from '../../../../interfaces/deviceGroup.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import GlobalState from '../../../../store/reducers/globalState.interface';
import { placementTranslation } from '../../../../constants/translation/placement';
import weatherTemplates from '../../../../constants/weatherTemplates';
import Tooltip from '../../../Tooltip';
import RadioButtons from '../../../RadioButtons';

enum Modes {
  Devices = 'devices',
  DevicesGroup = 'devicesGroup',
}

interface FormInputs {
  name: string;
  zipCode: string;
  countryId: string;
  placement: string;
  celsius: boolean;
  textColor: ColourOption;
  devices: SelectedValue[];
  deviceGroupIds: SelectedValue[];
}

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

export default ({
  onSubmit,
  weather = {} as App,
  onClose,
  isLoading,
}: {
  onSubmit: any;
  weather?: App;
  onClose?: () => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const { loginSessions, isTrial } = useSelector(
    (state: GlobalState) => state.userData.user,
  );

  const lastLoadingSessions = loginSessions
    .pop()
    ?.countryCode.toLocaleLowerCase();

  const defaultValues: Partial<FormInputs> = {
    name: weather?.name ?? '',
    zipCode: weather?.dependency?.weather?.zipCode ?? '',
    countryId: weather.dependency?.country?.id ?? lastLoadingSessions,
    placement: weather.placement ?? '',
    celsius: weather?.dependency?.config?.celsius === true,
    textColor:
      colourOptions.find(
        ({ color }) => color === weather?.dependency?.config?.textColor,
      ) ?? colourOptions[0],
    devices:
      weather.devices?.map((device: Device) => ({
        label: device.name,
        value: device.id,
      })) ?? [],
    deviceGroupIds: weather.deviceGroups?.map((item: DeviceGroup) => ({
      label: item.name,
      value: item.id,
    })),
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      validation.object(weatherCreateValidation(t)).required(),
    ),
    defaultValues,
  });

  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [name, setName] = useFs<FormInputs['name']>('name');
  const [countryId, setCountryId] = useFs<FormInputs['countryId'] | undefined>(
    'countryId',
  );
  const [zipCode, setZipCode] = useFs<FormInputs['zipCode']>('zipCode');
  const [placement, setPlacement] = useFs<FormInputs['placement']>('placement');
  const [textColor, setTextColor] = useFs<FormInputs['textColor']>('textColor');
  const [devices, setDevices] = useFs<FormInputs['devices']>('devices');
  const [celsius, SetCelsius] = useFs<FormInputs['celsius']>('celsius');
  const [deviceGroupIds, setDeviceGroupIds] =
    useFs<FormInputs['deviceGroupIds']>('deviceGroupIds');

  const modes: Mode<Modes>[] = [
    {
      value: Modes.Devices,
      renderView: (active) => (
        <div className={clsx(styles.mode, { [styles.active]: active })}>
          {t('devices.devices')}
        </div>
      ),
    },
    {
      value: Modes.DevicesGroup,
      renderView: (active) =>
        isTrial ? (
          <Tooltip text={t('devices_groups.trial_plan')}>
            <div className={clsx(styles.mode, { [styles.active]: active })}>
              {t('devices_groups.devices_groups')}
              <i
                className="bi bi-lock-fill ms-3"
                style={{ fontSize: '1.4rem' }}
              />
            </div>
          </Tooltip>
        ) : (
          <div className={clsx(styles.mode, { [styles.active]: active })}>
            {t('devices_groups.devices_groups')}
          </div>
        ),
    },
  ];

  const [mode, setMode] = useState<Modes>(modes[0].value);
  const [isScheduled, setIsScheduled] = useState<boolean>(false);

  const setNewMode = (newMode: Modes) => {
    setMode(newMode);
  };

  const handleSubmitData = () => {
    if (onSubmit) {
      onSubmit({
        name,
        isScheduled,
        placement,
        countryId,
        zipCode,
        celsius,
        config: {
          textColor: textColor.color,
          celsius,
        },
        deviceIds: (devices.map((item) => item.value) as string[]) ?? [],
        deviceGroupIds:
          (deviceGroupIds?.map((item) => item.value) as string[]) ?? [],
      });
    }
  };
  const CustomOption = (innerProps: OptionProps<any, false, any>) => {
    const { children, data } = innerProps;
    return (
      <components.Option {...innerProps}>
        {isTrial && data.value === Placements.WIDGET ? (
          <Tooltip text={t('apps.weather.placement.trial_plan_widget')}>
            {children}
            <i
              className="bi bi-lock-fill ms-3"
              style={{ fontSize: '1.4rem' }}
            />
          </Tooltip>
        ) : (
          <>{children}</>
        )}
      </components.Option>
    );
  };

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="mb-4">
                <label className="required fs-6 fw-bolder mb-2">
                  {t('contents.title')}
                </label>
                <TextInput
                  name="name"
                  id="weatherName"
                  placeholder={t('contents.type_here')}
                  className="border-secondary"
                  value={name}
                  onChange={setName}
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
              <div className="mt-3">
                <label
                  htmlFor="countryId"
                  className="col-form-label pt-1 fw-bolder"
                >
                  {t('common.country')}
                </label>
                <SelectCountry
                  id="countryId"
                  onChange={({ value }) => setCountryId(value)}
                  initialValue={countryId}
                  isClearable
                />
                <FormErrorMessage
                  name="countryId"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              <div className="mt-3">
                <label htmlFor="zipCode" className="col-form-label fw-bolder">
                  {t('common.zip_code')}
                </label>
                <TextInput
                  id="zipCode"
                  name="zipCode"
                  onChange={setZipCode}
                  placeholder={t('common.zip_code')}
                  value={zipCode}
                />
                <FormErrorMessage
                  name="zipCode"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              <div className="mt-3">
                <label htmlFor="zipCode" className="col-form-label fw-bolder">
                  {t('apps.weather.degree_type')}
                </label>
                <RadioButtons
                  name="isCelsius"
                  onChange={(value) => SetCelsius(value === 'celsius')}
                  options={[
                    {
                      label: t('apps.weather.fahrenheit'),
                      value: 'fahrenheit',
                      key: 'fahrenheit',
                      checked: !celsius,
                    },
                    {
                      label: t('apps.weather.celsius'),
                      value: 'celsius',
                      key: 'celsius',
                      checked: celsius,
                    },
                  ]}
                />
                <FormErrorMessage
                  name="isSeparate"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>
              {placement === Placements.FULL_SCREEN && (
                <div className="mb-6">
                  <span className="fs-6 fw-bolder mb-2">Color Theme</span>
                  <SelectColor
                    initialValue={textColor}
                    onChange={(value) => setTextColor(value)}
                  />
                </div>
              )}
              {placement === Placements.WIDGET && (
                <>
                  <label
                    htmlFor="devices"
                    className="d-flex align-items-center fs-4 fw-bold mb-3"
                  >
                    <strong>
                      {t('devices_groups.steps.group_devices_description')}
                    </strong>
                  </label>
                  <div className="d-flex w-100 justify-content-center mb-3">
                    <SelectMode
                      modeValue={mode}
                      modes={modes}
                      onSelect={(value) => {
                        if (!isTrial) {
                          setNewMode(value as Modes);
                        } else if (value !== Modes.DevicesGroup)
                          setNewMode(value as Modes);
                      }}
                    />
                  </div>

                  <div className="fv-row mb-2">
                    {mode === Modes.Devices && (
                      <div className="mb-3">
                        <SelectDevices
                          initialValue={devices.map(
                            ({ value }) => value as string,
                          )}
                          onChange={(selectedValue) => {
                            setDevices(selectedValue);
                          }}
                          hideLicence
                        />
                        <div className="fv-plugins-message-container invalid-feedback formik-error-message">
                          <FormErrorMessage name="devices" errors={errors} />
                        </div>
                      </div>
                    )}
                    {mode === Modes.DevicesGroup && (
                      <div className="mb-3">
                        <SelectDevicesGroups
                          initialValue={deviceGroupIds}
                          onChange={(selectedValue) => {
                            setDeviceGroupIds(selectedValue);
                          }}
                        />
                        <div className="fv-plugins-message-container invalid-feedback formik-error-message">
                          <FormErrorMessage
                            name="deviceGroupIds"
                            errors={errors}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="col w-100">
              {placement === Placements.FULL_SCREEN && (
                <div className="col w-100">
                  <img
                    src={weatherTemplates[textColor.value]}
                    alt="template preview"
                    className="w-100"
                  />
                </div>
              )}

              {placement === Placements.WIDGET && (
                <div className="col w-100 text-center">
                  <img
                    src={weatherTemplates.widget}
                    alt="template preview"
                    className="w-50 "
                  />
                </div>
              )}
              <div className="mt-3">
                <label className="required fs-6 fw-bolder">
                  {t('apps.html5.placement')}
                </label>
                <ReactSelect<Placements>
                  id="placements"
                  defaultValue={{
                    value: placement,
                    label: t(placementTranslation[placement]),
                  }}
                  onSelect={(selectedPlacement) =>
                    setPlacement(selectedPlacement as Placements)
                  }
                  options={Object.values(Placements).map((placementValue) => ({
                    label: t(placementTranslation[placementValue]),
                    value: placementValue,
                    isDisabled: isTrial && placementValue === Placements.WIDGET,
                  }))}
                  components={{ Option: CustomOption }}
                />
                <FormErrorMessage
                  name="placement"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
            </div>
          </div>
          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {Object.keys(weather).length
                  ? t('common.save')
                  : t('common.create')}
              </button>
              {!Object.keys(weather).length && placement !== Placements.WIDGET && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                >
                  {t('common.save_schedule')}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
