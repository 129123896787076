/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import api from '../../../api';
import SelectAsync, {
  SelectedValue,
} from '../../../components/Select/SelectAsync';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';

/**
 * Retrieves the select options asynchronously
 * @param isAdmin
 */
const loadOptions =
  (isAdmin?: boolean, UserId?: string) =>
  async (search: string): Promise<SelectedValue[]> => {
    const params = window.location.search.substring(1);
    const currentUserId = UserId || params.split('&currentUserId=')[1];
    const { items } = isAdmin
      ? await api.devicesGroups.getAdminDevicesGroups({ currentUserId })
      : await api.devicesGroups.getDevicesGroups({ search });

    return items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  };
const SelectDeviceGroup = ({
  onChange,
  placeholder,
  initialValue,
  initialOption,
  id,
  isDisabled,
  isMulti,
  isAdmin,
  UserId,
}: {
  onChange: (selectedValue: SelectedValue) => void;
  initialValue?: string;
  initialOption?: SelectedValue[];
  placeholder?: string;
  id?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  isAdmin?: boolean;
  UserId?: string;
}) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<SelectedValue>();
  const isSuperAdmin = useSelector((state: GlobalState) =>
    state.userData.user.roles.includes(UserRole.SuperAdmin),
  );
  useEffect(() => {
    if (!initialValue || initialOption) return;

    setSelectedValue({
      label: `${t('common.loading')}...`,
      value: initialValue,
    });

    setTimeout(async () => {
      const { name } = await api.devicesGroups.getDevicesGroup(initialValue);

      setSelectedValue({ label: name, value: initialValue });
    });
  }, []);

  return (
    <SelectAsync
      placeholder={placeholder}
      id={id}
      initialValue={initialOption || selectedValue}
      onChange={onChange}
      loadOptions={loadOptions(isSuperAdmin, UserId)}
      isDisabled={isDisabled}
      isMulti={isMulti}
    />
  );
};

SelectDeviceGroup.defaultProps = {
  initialValue: null,
};

export default memo(
  SelectDeviceGroup,
  (prev, next) =>
    (['id', 'isDisabled'] as const).every((key) => prev[key] === next[key]) &&
    isEqual(prev.initialValue, next.initialValue),
);
