import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import App from '../../../interfaces/app.interface';
import useTableMultiSelect from '../../../utils/hooks/useTableMultiSelect';
import Card from '../../Card';
import Pagination from '../../Pagination/QueryParamPagination';
import Search from '../../Search/QueryParamSearch';
import PowerBiTable from './Table';
import CreatePowerBi from '../../../containers/Apps/PowerBi/CreatePowerBi';
import { KTSVG } from '../../../lib/metronic/helpers';
import IntegrationButton from './ConnectButton';
import GlobalState from '../../../store/reducers/globalState.interface';
import api from '../../../api';
import styles from './powerBi.module.scss';
import WithSpinner from '../../WithSpinner';

const pageSize = 6;
const tableRowHeight = 63;

const powerBiEmailFromToken = (token: any) => {
  try {
    const idToken = token.id_token;
    const parts = idToken.split('.');
    if (parts.length < 2) {
      return '';
    }
    const decoded = atob(parts[1]);
    const json = JSON.parse(decoded);
    return json.email;
  } catch (error) {
    console.log('Error parsing token', error);
    return '';
  }
};

export default ({
  powerBis,
  total,
  isLoading,
  resetOnSearch,
  onUpdate,
}: {
  powerBis: App[];
  total: number;
  isLoading: boolean;
  resetOnSearch?: boolean;
  onUpdate: () => void;
}) => {
  const user = useSelector((state: GlobalState) => state.userData.user);
  const { t } = useTranslation();
  const { search } = useLocation();
  const [connected, setConnected] = useState<boolean>(false);
  const [powerBiEmail, setPowerBiEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(powerBis, 'id'),
    [],
    resetOnSearch ? search : undefined,
  );

  const checkMicrosoftConnected = async () => {
    try {
      setLoading(true);
      if (user.id !== '') {
        const result = await api.powerBi.checkUserMicrosoftConnected(user.id);
        if (result) {
          setPowerBiEmail(powerBiEmailFromToken(result));
        }
        setConnected(!!result);
      } else {
        setConnected(false);
      }
    } catch (e) {
      console.log('Error checking Microsoft connected', e);
      setConnected(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkMicrosoftConnected();
  }, [user]);

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          <Search
            queryField="search"
            pageNumberQueryField=""
            placeholder={t('common.search')}
            className="me-2"
          />
          <div className="d-flex">
            <div className={clsx('me-3', styles.buttonStyle)}>
              <WithSpinner isLoading={loading}>
                <IntegrationButton
                  powerBiEmail={powerBiEmail}
                  connected={connected}
                />
              </WithSpinner>
            </div>
            <CreatePowerBi onUpdate={onUpdate}>
              {connected && (
                <button type="button" className="mt-2 btn btn-primary">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {t('apps.powerBi.createNewPowerBi')}
                </button>
              )}
            </CreatePowerBi>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <PowerBiTable
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          powerBis={powerBis}
          isLoading={isLoading}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
          onUpdate={onUpdate}
          connected={connected}
          userId={user.id}
        />
        <Pagination
          total={total}
          pageSize={pageSize}
          pageNumberQueryField={PaginationQueryParam.Page}
          pageSizeQueryField={PaginationQueryParam.Limit}
          className="mt-3 mb-5"
        />
      </Card.Body>
    </Card>
  );
};
