import React from 'react';
import IContent from '../../../interfaces/content.interface';
import WithSpinner from '../../WithSpinner';
import noPreview from '../../../assets/images/noPreview/noPreview.png';
import { FileMimeType, FileType } from '../../../store/types/fileManager';
import fileTypes from '../../../constants/docFileTypes';

export default ({
  content,
  isLoading = false,
}: {
  isLoading?: boolean;
  content: IContent;
}) => {
  const { mediaGroup, name } = content;

  const type = mediaGroup?.type;

  if (!type) {
    return <>Unsupported type!</>;
  }

  let preview: React.ReactNode;

  const { docFileTypes, videoFileTypes, pdfFileTypes } = fileTypes;

  switch (true) {
    case videoFileTypes.includes(type as FileMimeType | FileType):
      preview = mediaGroup.medias.map(({ url }) => (
        <video src={url} autoPlay muted controls>
          <track kind="captions" />
        </video>
      ));
      break;
    case pdfFileTypes.includes(type as FileMimeType | FileType):
    case docFileTypes.includes(type as FileMimeType | FileType):
      preview = mediaGroup.medias.map(({ url, id, title, isHide }) => (
        <React.Fragment key={id}>
          {isHide && (
            <div className="mb-5">
              <img src={url} alt={title} width="100%" />
            </div>
          )}
        </React.Fragment>
      ));
      break;
    default:
      preview = mediaGroup?.medias.map(({ url }) => (
        <img
          className="m-1"
          src={url}
          alt={name}
          style={{ objectFit: 'contain', maxHeight: '80vh' }}
          onError={({ currentTarget }) => {
            // eslint-disable-next-line no-param-reassign
            currentTarget.src = noPreview;
          }}
        />
      ));
      break;
  }

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div
        className="card"
        style={{ height: '100%', overflow: 'auto' }}
        onContextMenu={(ev) => ev.preventDefault()}
      >
        {preview}
      </div>
    </WithSpinner>
  );
};
