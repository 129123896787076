import React from 'react';
import { useDispatch } from 'react-redux';
import EditDevice from '../../../components/Devices/EditDevice';
import UpdateDeviceRequestData from '../../../api/endpoints/devices/interfaces/updateDeviceRequestData.interface';
import Device from '../../../interfaces/devices/device.interface';
import { updateDevice } from '../../../store/actions/devices';

export default ({
  device,
  children,
  isReadOnlyInitialState = false,
  isSuperAdmin,
  onEditDevice,
}: {
  device: Device;
  children?: React.ReactNode;
  isReadOnlyInitialState?: boolean;
  isSuperAdmin?: boolean;
  onEditDevice?: (data: { id: string; data: UpdateDeviceRequestData }) => void;
}) => {
  const dispatch = useDispatch();

  const handleSubmit = (data: {
    id: string;
    data: UpdateDeviceRequestData;
  }) => {
    if (onEditDevice) {
      onEditDevice(data);
      return;
    }
    dispatch(updateDevice({ ...data, isAdmin: !!isSuperAdmin }));
  };

  return (
    <EditDevice
      device={device}
      isReadOnlyInitialState={isReadOnlyInitialState}
      onSubmit={handleSubmit}
    >
      {children ?? (
        <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
          <i className="fa fa-edit" />
        </span>
      )}
    </EditDevice>
  );
};
