import { UserStatus } from '../../enums/userStatus.enum';
import { UserRole } from '../../enums/userRole.enum';

export const statusesTranslation: { [key: string]: string } = {
  [UserStatus.Active]: 'users.statuses.active',
  [UserStatus.Disabled]: 'users.statuses.disabled',
  [UserStatus.Banned]: 'users.statuses.banned',
};

export const rolesTranslation: { [key: string]: string } = {
  [UserRole.SuperAdmin]: 'users.roles.super_admin',
  [UserRole.Admin]: 'users.roles.admin',
  [UserRole.Employee]: 'users.roles.employee',
  [UserRole.IndigoAdmin]: 'users.roles.indigo_admin',
  [UserRole.IndigoEmployee]: 'users.roles.indigo_employee',
  [UserRole.Manager]: 'users.roles.manager',
  [UserRole.Editor]: 'users.roles.editor',
  [UserRole.AccountOwner]: 'users.roles.account_owner',
};
