import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { isEqual, map } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { LibraryCategoryType } from '../../../../../enums/library/libraryCategoryType.enum';
import { prepareHookFromState } from '../../../../../utils/hooks/useStateWithHookForm';
import WithSpinner from '../../../../WithSpinner';
import Language from '../../../../../enums/language.enum';
import TextInput from '../../../../TextInput';
import CheckBox from '../../../../CheckBox';
import SelectLanguage from '../../../../../containers/SelectLanguage';
import Modal from '../../../../Modal';
import SelectConciegerMonths from '../SelectMonths';
import LibrariesQueryParam from '../../../../../api/endpoints/libraries/enums/librariesQueryParam.enum';
import { readFromQueryString } from '../../../../../utils/queryParams';
import SelectIndustries from '../../../../../containers/SelectIndustries';
import {
  createTopicValidation,
  createTopicValidationFree,
} from '../../../../../validation/topic';
import validation from '../../../../../utils/validation';
import FormErrorMessage from '../../../../FormErrorMessage';
import Library from '../../../../../interfaces/library.interface';
import SelectTags from '../../../../../containers/SelectTags';
import { TagsTarget } from '../../../../../api/endpoints/tags/enums/tagsTarget.enum';
import { SelectedValue } from '../../../../Select/CreatableAsyncSelect';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

interface FormInputs {
  sku: string;
  // category: LibraryCategory;
  text: string;
  type: LibraryCategoryType;
  description: string;
  isConciergeContent: boolean;
  categoryId: string;
  isQuickClick: boolean;
  language: Language;
  market: string;
  industryIds: string[];
  conciergeMonths: string;
  categoryType: any;
  searchTerms: string[];
}

export default ({
  topic,
  onSubmit,
  update,
  categoryType,
}: {
  topic?: Library;
  onSubmit?: (data: any) => void;
  update?: boolean;
  categoryType?: LibraryCategoryType;
}) => {
  const { t } = useTranslation();

  const defaultValues: Partial<FormInputs> = {
    sku: topic?.sku ?? '',
    text: topic?.text ?? '',
    type: topic?.type ?? categoryType ?? LibraryCategoryType.Free,
    description: topic?.description ?? '',
    isConciergeContent: topic?.isConciergeContent ?? false,
    conciergeMonths: topic?.conciergeMonths ?? '',
    isQuickClick: topic?.isQuickClick ?? false,
    language: topic?.lang ?? Language.English,
    market: topic?.market ?? '',
    industryIds: topic?.industries?.map((item) => item?.id) ?? [],
    categoryType: topic?.category?.type ?? '',
    searchTerms: topic?.searchTerms ?? [],
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      categoryType === LibraryCategoryType.Free ||
        defaultValues?.categoryType === LibraryCategoryType.Free
        ? validation.object(createTopicValidationFree(t)).required()
        : validation.object(createTopicValidation(t)).required(),
    ),
    defaultValues,
  });

  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const { search } = useLocation();
  const [sku, setSku] = useFs<FormInputs['sku']>('sku');
  const [text, setText] = useFs<FormInputs['text']>('text');
  const [type, setType] = useFs<FormInputs['type']>('type');
  const [searchTerms, setSearchTerms] =
    useFs<FormInputs['searchTerms']>('searchTerms');
  const [description, setDescription] =
    useFs<FormInputs['description']>('description');
  const [isConciergeContent, setIsConciergeContent] =
    useFs<FormInputs['isConciergeContent']>('isConciergeContent');
  const [conciergeMonths, setConciergeMonths] =
    useFs<FormInputs['conciergeMonths']>('conciergeMonths');
  const [isQuickClick, setIsQuickClick] =
    useFs<FormInputs['isQuickClick']>('isQuickClick');
  const [language, setLanguage] = useFs<FormInputs['language']>('language');
  const [market, setMarket] = useFs<FormInputs['market']>('market');
  const [industryIds, setIndustryIds] =
    useFs<FormInputs['industryIds']>('industryIds');
  const [submitted, setSubmitted] = useState(false);
  const [categoryId, setCategoryId] =
    useFs<FormInputs['categoryId']>('categoryId');

  const [isLoading, setIsLoading] = useState(false);
  React.useEffect(() => {
    setType(defaultValues.categoryType);
    const category = readFromQueryString<string>(
      search,
      LibrariesQueryParam.CategoryId,
    );

    if (category) setCategoryId(category);
  }, []);

  const handleSubmitData = () => {
    setIsLoading(true);

    if (onSubmit) {
      onSubmit({
        sku,
        text,
        type,
        description,
        isConciergeContent,
        categoryId,
        isQuickClick,
        language,
        market,
        industryIds,
        conciergeMonths,
        searchTerms,
      });
    }
  };
  React.useEffect(() => {
    setConciergeMonths(`${conciergeMonths}`);
  }, [conciergeMonths]);
  const getValueFromMounthsArray = (value: string) => {
    if (value.includes(',')) {
      const arr = value.split(',');
      const newArr = arr.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      return newArr;
    }

    if (value && !value.includes(',')) {
      return { value, label: value };
    }

    return [];
  };

  const getLibraryType = () => {
    if (
      defaultValues.categoryType === LibraryCategoryType.Premium ||
      categoryType === LibraryCategoryType.Premium
    )
      return LibraryCategoryType.Premium;

    if (
      defaultValues.categoryType === LibraryCategoryType.Free ||
      categoryType === LibraryCategoryType.Free
    )
      return LibraryCategoryType.Free;

    return LibraryCategoryType.Free;
  };

  return (
    <WithSpinner isLoading={false}>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <div className="mb-3">
          <label className="fs-6 fw-bolder me-2">
            {t('library.indigo.topicText')}
          </label>
          <TextInput
            name="name"
            id="text"
            placeholder={t('contents.type_here')}
            className="border-secondary mt-2"
            value={text}
            onChange={setText}
          />

          <FormErrorMessage
            name="text"
            errors={errors}
            className={errorMessageClassNames}
          />
        </div>

        <div className="mb-3">
          <div className="row">
            <div className="col-sm">
              <label className="fs-6 fw-bolder me-2">
                {t('library.indigo.topicSku')}
              </label>

              <div className="input-group">
                <TextInput
                  name="name"
                  id="sku"
                  placeholder={t('contents.type_here')}
                  className="border-secondary mt-2"
                  value={sku}
                  onChange={setSku}
                />

                <FormErrorMessage
                  name="sku"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
            </div>
            <div className="col-sm">
              <div className="mb-3">
                <label
                  htmlFor="language"
                  className="form-label fs-6 fw-bolder pt-1"
                >
                  {t('common.language')}:
                </label>
                <SelectLanguage
                  id="language"
                  placeholder={t('users.common.select_language')}
                  language={language}
                  setLanguage={setLanguage}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label className="fs-6 fw-bolder me-2">
            {t('library.indigo.topicDescription')}
          </label>
          <TextInput
            name="name"
            id="description"
            placeholder={t('contents.type_here')}
            className="border-secondary mt-2"
            value={description}
            onChange={setDescription}
          />
        </div>
        {getLibraryType() === LibraryCategoryType.Premium && (
          <div className="mb-3">
            <label className="fs-6 fw-bolder me-2">
              {t('library.indigo.topicMarket')}
            </label>
            <TextInput
              name="name"
              id="market"
              placeholder={t('contents.type_here')}
              className="border-secondary mt-2"
              value={market ?? 'WorkPlace'}
              onChange={setMarket}
            />
            <FormErrorMessage
              name="market"
              errors={errors}
              className={errorMessageClassNames}
            />
          </div>
        )}

        {getLibraryType() === LibraryCategoryType.Premium && (
          <>
            <div className="mb-3">
              <div className="d-flex align-items-center me-2 ms-1">
                <CheckBox
                  name="isConciergeContent"
                  checked={isConciergeContent}
                  onChange={() => setIsConciergeContent(!isConciergeContent)}
                  className="w-sm-auto mb-2"
                />
                <label htmlFor="isConciergeContent" className="w-sm-auto mb-2">
                  {t('library.indigo.isConciergeContent')}
                </label>
              </div>
            </div>
            {isConciergeContent && (
              <>
                <div className="mb-3">
                  <label className="fs-6 fw-bolder me-2">
                    {t('library.indigo.conciergeMonths')}
                  </label>
                  <SelectConciegerMonths
                    initialValue={getValueFromMounthsArray(conciergeMonths)}
                    onChange={(selectedMonths) => {
                      setConciergeMonths(selectedMonths as string);
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}

        {getLibraryType() === LibraryCategoryType.Premium && (
          <div className="d-flex align-items-center me-2 ms-1 mb-3">
            <CheckBox
              name="isQuickClick"
              onChange={() => setIsQuickClick(!isQuickClick)}
              className="w-sm-auto mb-2"
              checked={isQuickClick}
            />
            <label htmlFor="isQuickClick" className="w-sm-auto mb-2">
              {t('library.indigo.isQuickClick')}
            </label>
          </div>
        )}
        {getLibraryType() === LibraryCategoryType.Premium && (
          <div className="w-100 mt-5 mb-3">
            <label className="fs-6 fw-bolder mb-2">
              {t('common.industry')}
            </label>
            <SelectIndustries
              id="industryIds"
              initialValue={industryIds}
              onChange={(values) =>
                setIndustryIds(map(values, 'value') as string[])
              }
            />

            <div hidden={!submitted}>
              <FormErrorMessage
                name="industryIds"
                errors={errors}
                className={errorMessageClassNames}
              />
            </div>
          </div>
        )}

        <div className="mb-3">
          <label className="fs-6 fw-bolder mb-2">
            {t('library.indigo.searchTerms')}
          </label>
          <SelectTags
            id="searchTerms"
            target={TagsTarget.Library}
            initialValue={searchTerms?.map((value) => ({
              value,
              label: value,
            }))}
            onChange={(values) => {
              const newValues = map<SelectedValue>(values, 'value');

              if (!isEqual(newValues, searchTerms)) {
                setSearchTerms(newValues);
              }
            }}
          />
        </div>

        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-flex justify-content-end">
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isLoading}
              onClick={() => setSubmitted(true)}
            >
              {' '}
              {update ? 'Update Topic' : 'Create New Topic'}{' '}
            </button>{' '}
          </div>
        </div>
      </form>
    </WithSpinner>
  );
};
