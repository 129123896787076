import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import App from '../../../interfaces/app.interface';
import DatePicker from '../../DatePicker';
import Modal from '../../Modal';
import validation from '../../../utils/validation';
import { tickerRepublishValidationStepCreate } from '../../../validation/apps/ticker';
import { prepareHookFromState } from '../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../FormErrorMessage';
import WithSpinner from '../../WithSpinner';

interface FormInputs {
  startDate: Date;
  endDate: Date;
}

export default ({
  ticker = {} as App,
  children,
  isLoading,
  onSubmit,
  preloaderHeight = 300,
}: {
  ticker: App;
  preloaderHeight?: number;
  onSubmit: any;
  isLoading: boolean;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState<boolean>(false);

  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const defaultValues: Partial<FormInputs> = {
    startDate: new Date(ticker.startDate ?? ''),
    endDate: new Date(ticker.endDate ?? ''),
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      validation.object(tickerRepublishValidationStepCreate()).required(),
    ),
    defaultValues,
  });

  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [startDate, setStartDate] = useFs<FormInputs['startDate']>('startDate');
  const [endDate, setEndDate] = useFs<FormInputs['endDate']>('endDate');

  const handleSubmitData = () => {
    if (onSubmit) {
      onSubmit({
        ...ticker,
        startDate,
        endDate,
      });
    }
  };
  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('tickers.addTicker.republishTicker')}
        show={modalShown}
        onHide={close}
        widthDefaultSize={false}
        onSubmit={handleSubmit(handleSubmitData)}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          size="md"
        >
          <div className="mb-6">
            <DatePicker
              value={startDate}
              onChange={(newDate) => setStartDate(newDate[0])}
              labelText={t('tickers.addTicker.startDate')}
            />
            <FormErrorMessage
              name="startDate"
              errors={errors}
              className="mt-1 min-h-20px"
            />
          </div>

          <DatePicker
            value={endDate}
            onChange={(newDate) => setEndDate(newDate[0])}
            labelText={t('tickers.addTicker.endDate')}
          />
          <FormErrorMessage
            name="endDate"
            errors={errors}
            className="mt-1 min-h-20px"
          />
        </WithSpinner>
      </Modal>
    </>
  );
};
