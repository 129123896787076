import { compact, omit, uniq } from 'lodash';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import AddContentSteps from '../../../enums/steps/addContentSteps.enum';
import CreateContentRequestData from '../../../api/endpoints/contents/interfaces/createContentRequestData.interface';
import useOnEventEmitterEvent from '../../../utils/hooks/useOnEventEmitterEvent';
import ConversionJobEvents from '../../../enums/events/conversionJobEvents.enum';
import ConversionJobStatus from '../../../enums/conversionJobStatus.enum';
import ConversionJob from '../../../interfaces/conversionJob.interface';
import { conversionJobEvents } from '../../../constants/conversionJob';
import UploadContent from '../../../components/Aside/UploadContent';
import { FileMimeType } from '../../../store/types/fileManager';
import { start } from '../../../webWorkers/conversionJobStatus';
import { mediaFileTypes } from '../../../constants/file';
import { createFormData } from '../../../utils/formData';
import eventEmitter from '../../../utils/eventEmitter';
import api from '../../../api';
import Orientation from '../../../enums/orientation.enum';

interface ConversionJobData {
  file?: File;
  isSeparate?: boolean;
  isOrdered?: boolean;
  orientation?: Orientation;
}

export default ({
  onSuccess,
  children,
  singlePlaylistId,
}: {
  onSuccess?: (playlistIds?: string[]) => void;
  children: React.ReactNode;
  singlePlaylistId?: string;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [playListIds, setPlayListIds] = useState<string[] | null>(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const uploadInProgress = !!playListIds;
  const conversionJobDataRef = useRef<ConversionJobData & { id?: number }>({});

  const handleOpen = () => setIsVisible(true);

  const handleClose = (withoutCancelling?: boolean) => {
    setIsVisible(false);

    if (withoutCancelling) {
      conversionJobDataRef.current = {};

      return;
    }

    const { id } = conversionJobDataRef.current;

    if (id) {
      api.conversionJobs.cancelConversionJob(id);

      conversionJobDataRef.current = {};
    }
  };

  /**
   * File uploader data update handler
   * @param data
   */
  const onFileDataUpdate = async (
    data: ConversionJobData,
  ): Promise<boolean> => {
    conversionJobDataRef.current = { ...conversionJobDataRef.current, ...data };

    const { id, file } = conversionJobDataRef.current;

    try {
      if (
        Object.prototype.hasOwnProperty.call(data, 'file') &&
        !data.file &&
        id
      ) {
        setIsUploadingFile(true);

        await api.conversionJobs.cancelConversionJob(id);

        setIsUploadingFile(false);

        delete conversionJobDataRef.current.id;
      } else if (!mediaFileTypes.includes(file?.type as FileMimeType)) {
        if (id) {
          setIsUploadingFile(true);

          const updatedJob = await api.conversionJobs.updateConversionJob(
            id,
            data,
          );

          setIsUploadingFile(false);

          if (data.file) {
            conversionJobDataRef.current.id = updatedJob.id;
          }
        } else if (file) {
          setIsUploadingFile(true);

          const jobData = await api.conversionJobs.createConversionJob(
            conversionJobDataRef.current,
          );

          setIsUploadingFile(false);

          conversionJobDataRef.current.id = jobData.id;

          await start();
        }
      }
    } catch (e) {
      setIsUploadingFile(false);

      return false;
    }

    return true;
  };

  useOnEventEmitterEvent(
    conversionJobEvents,
    ({ status }: ConversionJob) => {
      if (status === ConversionJobStatus.Failed) {
        toast.error(i18next.t<string>('contents.upload_error'));
      }

      if (!uploadInProgress) return;

      if (status === ConversionJobStatus.Done) {
        toast.success(i18next.t<string>('contents.upload_success'));

        if (onSuccess) {
          onSuccess(playListIds);
        }
      }
    },
    { deps: [uploadInProgress] },
  );

  const handleSubmit = async (
    contentData: Partial<CreateContentRequestData>,
    reset: () => void,
  ) => {
    // let requestData = omit(contentData, ['deviceGroupsIds']);
    let requestData = contentData;

    const conversionJobId = conversionJobDataRef.current.id;

    if (conversionJobId) {
      delete requestData.file;

      requestData.conversionJobId = conversionJobId;
    }

    if (singlePlaylistId) {
      requestData.playlistIds = [singlePlaylistId];
    }

    if (requestData.onlyCurrentSelected) {
      try {
        let selectedPlaylists: string[] = [];
        if (requestData.deviceGroupsIds) {
          // add deviceGroupIds criteria
          const { items } = await api.devices.getDevicesList({
            limit: '400',
            deviceGroupIds: requestData.deviceGroupsIds,
          });

          selectedPlaylists = [
            ...selectedPlaylists,
            ...compact(items.map((device) => device?.currentPlaylist?.id)),
          ];
        }
        if (requestData.deviceIds) {
          const { items } = await api.devices.getDevicesList({ limit: '400' });

          selectedPlaylists = [
            ...selectedPlaylists,
            ...compact(
              items.map((device) =>
                requestData.deviceIds?.includes(device.id)
                  ? device?.currentPlaylist?.id
                  : null,
              ),
            ),
          ];
        } else if (
          !requestData.deviceIds &&
          !requestData.deviceGroupsIds &&
          !singlePlaylistId
        ) {
          selectedPlaylists = compact(requestData?.playlistIds);
        }
        if (!selectedPlaylists || selectedPlaylists.length === 0) {
          toast.error(i18next.t('contents.validation.no_current_playlist'));
          return;
        }

        requestData.playlistIds = [...uniq(selectedPlaylists)];

        requestData = omit(requestData, ['onlyCurrentSelected']);
      } catch (e: any) {
        toast.error(i18next.t(e?.message ?? ''));
        return;
      }
    }

    setLoading(true);

    try {
      await api.contents.createContent(createFormData(requestData, true));

      const currentJob = conversionJobId
        ? await api.conversionJobs.getConversionJob(conversionJobId)
        : undefined;

      if (
        mediaFileTypes.includes(contentData.file?.type as FileMimeType) ||
        currentJob?.status === ConversionJobStatus.Done
      ) {
        if (onSuccess) {
          onSuccess(requestData.playlistIds);
        }

        toast.success(i18next.t<string>('contents.upload_success'));
      } else {
        if (!conversionJobDataRef.current.id) {
          await start();
        }

        setPlayListIds(requestData?.playlistIds || []);

        if (currentJob) {
          eventEmitter.emit(
            `${ConversionJobEvents.ConversionJobPrefix}.${currentJob.status}`,
            currentJob,
          );
        }
      }

      reset();
      handleClose(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={handleOpen}
        onClick={handleOpen}
      >
        {children}
      </span>
      <UploadContent
        isVisible={isVisible}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onClose={handleClose}
        minDuration={1}
        isUploadingFile={isUploadingFile}
        skipSteps={
          singlePlaylistId
            ? [AddContentSteps.DevicesAndGroups, AddContentSteps.Playlists]
            : []
        }
        onFileDataUpdate={onFileDataUpdate}
      />
    </>
  );
};
