import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import { Popover, PopoverContent } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';
import PlaylistsQueryParam from '../../../api/endpoints/playlists/enums/playlistsQueryParam.enum';
import ShowMoreCell from '../../ShowMoreCell';
import IPlaylist from '../../../interfaces/playlist.interface';

const PlaylistPopover = ({
  children,
  playlists = [],
  appName,
}: {
  children: React.ReactNode;
  playlists: Partial<IPlaylist>[];
  appName: string;
}) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <span
        ref={target}
        role="button"
        tabIndex={-1}
        onMouseEnter={() => setShow(true)}
        onKeyPress={() => setShow(!show)}
        onClick={() => setShow(!show)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </span>
      <Overlay target={target.current} show={show} placement="top">
        <Popover id="popover-basic" className="z-index-0">
          <PopoverContent>
            <>
              {playlists.slice(0, 3).map((playlist) => (
                <p
                  className={clsx(
                    'badge fs-7 py-3 px-7 fw-bold badge-light-primary mb-2 ms-2',
                  )}
                >
                  <Link to={`/playlists/${playlist.id}/contents`}>
                    {playlist.name}
                  </Link>
                  <br />
                </p>
              ))}
              {playlists.length > 3 && (
                <ShowMoreCell
                  itemNames={playlists.map((playlist) => playlist?.name ?? '')}
                  onlyCount
                  widthDefaultSize
                  title={appName}
                  bodyClassName="justify-content-center"
                >
                  {playlists?.map(({ id, name }, index) => (
                    <span
                      key={id}
                      className={clsx(
                        'badge fs-7 py-3 px-7 fw-bold badge-light-primary mb-2',
                        index && 'ms-2',
                      )}
                    >
                      <Link
                        to={`/playlists/?${PlaylistsQueryParam.DeviceIds}=${id}`}
                      >
                        {name}
                      </Link>
                    </span>
                  ))}
                </ShowMoreCell>
              )}
            </>
          </PopoverContent>
        </Popover>
      </Overlay>
    </>
  );
};

export default PlaylistPopover;
