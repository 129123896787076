import React from 'react';
import { useTranslation } from 'react-i18next';
import PreviewFeedModal from '../../../../../Apps/CountUpTimer/PreviewFeedModal';
import IContent from '../../../../../../interfaces/content.interface';
import {
  AppType,
  Placement,
  AppDependency,
} from '../../../../../../store/types/apps/globalTypes';

export default ({
  show,
  onClose,
  content,
}: {
  show: boolean;
  onClose: () => void;
  content: IContent;
}) => {
  const { t } = useTranslation();
  const { app } = content;

  return (
    <PreviewFeedModal
      show={show}
      onHide={onClose}
      title={t('common.preview')}
      selectedCountTimerSchema={{
        type: app?.type as AppType,
        createdAt: app!.createdAt,
        updatedAt: app!.updatedAt,
        dependency: app!.dependency as AppDependency,
        id: app!.id,
        name: app!.name,
        placement: app!.placement as Placement,
      }}
    />
  );
};
