import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isEqual, map } from 'lodash';
import SelectDevices from '../../containers/Devices/SelectDevices';
import SelectDevicesGroups from '../../containers/SelectDevicesGroups';
import SelectTags from '../../containers/SelectTags';
import Priorities from '../../enums/priorities.enum';
import DeviceGroup from '../../interfaces/deviceGroup.interface';
import Device from '../../interfaces/devices/device.interface';
import useCreatePlaylistAllowance from '../../utils/hooks/useCreatePlaylistAllowance';
import useStateWithHookForm from '../../utils/hooks/useStateWithHookForm';
import validation from '../../utils/validation';

import {
  bulkUpdatePlaylist,
  editPlaylistValidation,
  playlistValidation,
} from '../../validation/playlists';
import DatePicker from '../DatePicker';
import AdvancedDropdown from '../Dropdown/AdvancedDropdown';
import FormErrorMessage from '../FormErrorMessage';
import Modal from '../Modal';
import IsLocked from './Islocked';
import Radiobutton from '../RadioButton';
import RegularSelect from '../Select/RegularSelect';
import SelectMode, { Mode } from '../Select/SelectMode';
import TextInput from '../TextInput';
import WithSpinner from '../WithSpinner';
import PlaylistFormInputs from './playlistFormInputs.interface';
import { TagsTarget } from '../../api/endpoints/tags/enums/tagsTarget.enum';
import { SelectedValue } from '../Select/CreatableAsyncSelect';
import Playlist from '../../interfaces/playlist.interface';
import styles from './styles.module.scss';
import SelectTemplate from '../../containers/SelectTemplate';
import RestrictedAccess from '../../containers/RestrictedAccess';
import { UserRole } from '../../enums/userRole.enum';
import PlaylistCurrentStatus from '../../api/endpoints/playlists/enums/playlistCurrentStatus.enum';
import Tooltip from '../Tooltip';
import License from '../../interfaces/license.interface';
import CheckBox from '../CheckBox';
import SelectIndustry from '../../containers/SelectIndustry';
import GlobalState from '../../store/reducers/globalState.interface';

enum Modes {
  Devices = 'devices',
  DevicesGroup = 'devicesGroup',
}

const PlaylistComponent = ({
  onSubmit,
  modalTitle,
  shown,
  hide,
  playlist,
  isLoading,
  isEditMode,
  isBulk = false,
}: {
  onSubmit: (data: PlaylistFormInputs) => void;
  modalTitle: string;
  shown: boolean;
  hide: () => void;
  playlist: Playlist;
  isLoading: boolean;
  isEditMode?: boolean;
  isBulk?: boolean;
}) => {
  const { t } = useTranslation();
  const userIsTrial = useSelector(
    (state: GlobalState) => state?.userData?.user?.isTrial,
  );
  let validationObject: { [key: string]: any };

  if (isBulk) {
    validationObject = bulkUpdatePlaylist();
  } else {
    validationObject = isEditMode
      ? editPlaylistValidation(t)
      : playlistValidation(t);
  }

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<PlaylistFormInputs>({
    resolver: yupResolver(
      validation
        .object()
        .shape(validationObject, [['devices', 'deviceGroupIds']])
        .required(),
    ),
    defaultValues: {
      name: playlist.name,
      startDate: playlist.startDate ? new Date(playlist.startDate) : undefined,
      endDate: playlist.endDate ? new Date(playlist.endDate) : undefined,
      devices:
        playlist.devices?.map((device: Device) => ({
          label: device.name,
          value: device.id,
        })) ?? [],
      deviceGroupIds: playlist?.deviceGroups?.map((item: DeviceGroup) => ({
        label: item.name,
        value: item.id,
      })),
      isActive: playlist.isActive ?? true,
      priority: playlist.priority ?? 1,
      locked: playlist.locked ?? false,
      isTrial: playlist?.isTrial ?? false,
      industryId: playlist?.industry?.id ?? '',
      tags: playlist.tags ?? [],
      templateId: playlist.template?.id ?? '',
    },
  });

  const [name, setName] = useStateWithHookForm<PlaylistFormInputs, string>(
    { setValue, trigger, name: 'name' },
    playlist.name ?? '',
  );

  const [templateId, setTemplateId] = useStateWithHookForm<
    PlaylistFormInputs,
    string
  >({ setValue, trigger, name: 'templateId' }, playlist.template?.id ?? '');

  const [startDate, setStartDate] = useStateWithHookForm<
    PlaylistFormInputs,
    Date
  >(
    { setValue, trigger, name: 'startDate' },
    playlist.startDate ? new Date(playlist.startDate) : undefined,
  );

  const [endDate, setEndDate] = useStateWithHookForm<PlaylistFormInputs, Date>(
    { setValue, trigger, name: 'endDate' },
    playlist.endDate ? new Date(playlist.endDate) : undefined,
  );

  const [devices, setDevices] = useStateWithHookForm<
    PlaylistFormInputs,
    { label?: string; value?: string; license?: License }[]
  >(
    { setValue, trigger, name: 'devices' },
    playlist.devices?.map((device: Device) => ({
      label: device.name,
      value: device.id,
      license: device?.license,
    })) ?? [],
  );

  const [deviceGroupIds, setDeviceGroupIds] = useStateWithHookForm<
    PlaylistFormInputs,
    { label?: string; value?: string }[]
  >(
    { setValue, trigger, name: 'deviceGroupIds' },
    playlist.deviceGroups?.map((group: DeviceGroup) => ({
      label: group.name,
      value: group.id,
    })) ?? [],
  );

  const [tags, setTags] = useStateWithHookForm<
    PlaylistFormInputs,
    PlaylistFormInputs['tags']
  >({ setValue, trigger, name: 'tags' }, playlist.tags ?? []);

  const [isActive, setIsActive] = useStateWithHookForm<
    PlaylistFormInputs,
    PlaylistCurrentStatus
  >(
    { setValue, trigger, name: 'isActive' },
    playlist.isActive ?? PlaylistCurrentStatus.ACTIVE,
  );

  const [locked, setLocked] = useStateWithHookForm<PlaylistFormInputs, boolean>(
    { setValue, trigger, name: 'locked' },
    playlist.locked ?? false,
  );

  const loginAsUserName = window.localStorage.getItem('loginAsUserName');

  const [industryId, setIndustryId] = useStateWithHookForm<
    PlaylistFormInputs,
    string | undefined
  >({ setValue, trigger, name: 'industryId' }, playlist?.industry?.id ?? '');

  const [isTrial, setIsTrial] = useStateWithHookForm<
    PlaylistFormInputs,
    boolean
  >({ setValue, trigger, name: 'isTrial' }, playlist.isTrial ?? false);

  const [priority, setPriority] = useStateWithHookForm<
    PlaylistFormInputs,
    number | undefined
  >({ setValue, trigger, name: 'priority' }, playlist.priority ?? 1);

  const hasNoLicense = (): boolean => {
    if (devices.length > 0) {
      devices.forEach((device) => {
        if (!device?.license || !device?.license?.isActive) {
          return true;
        }
        return false;
      });
    }
    return false;
  };

  const modes: Mode<Modes>[] = [
    {
      value: Modes.Devices,
      renderView: (active) => (
        <div className={clsx(styles.mode, { [styles.active]: active })}>
          {t('devices.devices')}
        </div>
      ),
    },
    {
      value: Modes.DevicesGroup,
      renderView: (active) =>
        userIsTrial ? (
          <Tooltip text={t('devices_groups.trial_plan')}>
            <div className={clsx(styles.mode, { [styles.active]: active })}>
              {t('devices_groups.devices_groups')}
              <i
                className="bi bi-lock-fill ms-3"
                style={{ fontSize: '1.4rem' }}
              />
            </div>
          </Tooltip>
        ) : (
          <div className={clsx(styles.mode, { [styles.active]: active })}>
            {t('devices_groups.devices_groups')}
          </div>
        ),
    },
  ];

  const [mode, setMode] = useState<Modes>(
    !playlist.deviceGroups?.length ? Modes.Devices : Modes.DevicesGroup,
  );

  const devicesIds = devices.map(({ value }) => value as string);

  const allowance = useCreatePlaylistAllowance({
    devices,
    deviceGroupIds,
    priority,
    startDate,
    endDate,
    playlistId: playlist.id,
  });

  const hideModal = () => {
    hide();
  };

  useEffect(() => {
    if (playlist.id) {
      setName(playlist.name);

      if (playlist.startDate) {
        setStartDate(new Date(playlist.startDate));
      }

      if (playlist.endDate) {
        setEndDate(new Date(playlist.endDate));
      }
      setDevices(
        playlist.devices?.map((device: Device) => ({
          label: device.name,
          value: device.id,
          license: device.license,
        })) ?? [],
      );
      setIsActive(playlist.isActive);
      setPriority(playlist.priority);
    }
  }, [playlist]);

  const handleSubmitData = (data: any) => {
    onSubmit(data);
  };

  return (
    <Modal
      widthDefaultSize={false}
      dialogClassName="mw-700px"
      title={modalTitle}
      show={shown}
      onHide={hideModal}
      withFooter={false}
    >
      <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
        <div className="w-100">
          <div className="row">
            <div className="col">
              <div className="fv-row mb-10">
                {!isBulk && (
                  <>
                    <label
                      htmlFor="name"
                      className="d-flex align-items-center fs-4 fw-bold mb-2"
                    >
                      <strong>{t('playlists.playlist_title')}</strong>
                    </label>
                    <TextInput
                      id="name"
                      className="form-control form-control-lg form-control-solid mb-5"
                      name="name"
                      placeholder={t('playlists.placeholder_title_name')}
                      value={name}
                      onChange={setName}
                    />
                    <FormErrorMessage name="name" errors={errors} />
                  </>
                )}

                {!isBulk && (
                  <>
                    <label
                      htmlFor="dateRange"
                      className="d-flex align-items-center fs-4 fw-bold mb-2"
                    >
                      <strong>{t('playlists.schedule')}</strong>
                    </label>

                    <div className="fv-row mb-5">
                      <label
                        htmlFor="startDate"
                        className="d-flex align-items-center fs-6"
                      >
                        <span>{t('playlists.start_date')}</span>
                      </label>
                      <DatePicker
                        name="startDate"
                        value={startDate}
                        placeholder="Select start date - time"
                        onChange={(newDate) => setStartDate(newDate[0])}
                        options={{
                          enableTime: true,
                          defaultHour: 0,
                        }}
                      />
                      <FormErrorMessage name="startDate" errors={errors} />
                    </div>

                    <div className="fv-row mb-10">
                      <label
                        htmlFor="endDate"
                        className="d-flex align-items-center fs-6"
                      >
                        <span>{t('playlists.end_date')}</span>
                      </label>
                      <DatePicker
                        name="endDate"
                        value={endDate}
                        placeholder="Select end date - time"
                        onChange={(newDate) => setEndDate(newDate[0])}
                        options={{
                          enableTime: true,
                          defaultHour: 23,
                          defaultMinute: 59,
                        }}
                      />
                      <FormErrorMessage name="endDate" errors={errors} />
                    </div>
                  </>
                )}

                <label
                  htmlFor="isActive"
                  className="d-flex align-items-center fs-4 fw-bold mb-2"
                >
                  <strong>{t('playlists.status')}</strong>
                </label>
                <div className="d-flex">
                  <div
                    className="me-10"
                    role="presentation"
                    onClick={() => setIsActive(PlaylistCurrentStatus.ACTIVE)}
                  >
                    <Radiobutton
                      active={isActive !== PlaylistCurrentStatus.DRAFT}
                      title={t('playlists.statuses.active')}
                    />
                  </div>
                  <div
                    role="presentation"
                    onClick={() => setIsActive(PlaylistCurrentStatus.DRAFT)}
                  >
                    <Radiobutton
                      active={isActive === PlaylistCurrentStatus.DRAFT}
                      title={t('playlists.statuses.inactive')}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <label
                htmlFor="devices"
                className="d-flex align-items-center fs-4 fw-bold mb-2"
              >
                <strong>{t('playlists.devices')}</strong>
              </label>
              <div className="d-flex w-100 justify-content-center mb-3">
                <SelectMode
                  modeValue={mode}
                  modes={modes}
                  onSelect={(value) => {
                    if (!userIsTrial) {
                      setMode(value as Modes);
                    } else if (value !== Modes.DevicesGroup)
                      setMode(value as Modes);
                  }}
                />
              </div>
              <div className="fv-row mb-2">
                {mode === Modes.Devices && (
                  <>
                    <div className="row">
                      <div className="col mw-300px mb-3">
                        <SelectDevices
                          initialValue={devicesIds}
                          onChange={(selectedValue) =>
                            setDevices(selectedValue)
                          }
                          deviceIds={
                            playlist.devices?.map((item) => item.id) as string[]
                          }
                          hideLicence
                        />
                        <div className="fv-plugins-message-container invalid-feedback formik-error-message">
                          <FormErrorMessage name="devices" errors={errors} />
                        </div>
                      </div>
                      <div className="col mw-45px px-0 ">
                        <Tooltip text={t('devices.multipleInfo')}>
                          <i
                            className={clsx(
                              'fas fa-info-circle pt-3 fs-2',
                              styles.tooltipDeviceGroup,
                            )}
                            data-bs-toggle="tooltip"
                          />
                        </Tooltip>
                      </div>
                    </div>
                    {hasNoLicense() && (
                      <span>
                        <i className="fas fa-exclamation-circle text-danger pe-2 pt-2" />
                        {t('devices.warningNoLicense')}
                      </span>
                    )}
                  </>
                )}
                {mode === Modes.DevicesGroup && (
                  <div className="mb-3 mw-350px">
                    <SelectDevicesGroups
                      initialValue={deviceGroupIds}
                      deviceIds={devicesIds}
                      onChange={(selectedValue) => {
                        setDeviceGroupIds(selectedValue);
                      }}
                    />
                    <div className="fv-plugins-message-container invalid-feedback formik-error-message">
                      <FormErrorMessage name="deviceGroupIds" errors={errors} />
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div className="row">
                  <label
                    htmlFor="template"
                    className="d-flex align-items-center fs-4 fw-bold mb-2"
                  >
                    <strong>{t('playlists.select_template')}</strong>
                    {!templateId && (
                      <span className="badge badge-white">
                        {t('playlists.fullscreen_by_default')}
                      </span>
                    )}
                  </label>
                </div>
                <SelectTemplate
                  id="template"
                  initialValue={templateId}
                  onChange={({ value }) => setTemplateId(value as string)}
                  isClearable
                />
                {!allowance && (
                  <div className={clsx(styles.errorMessage)}>
                    {t('playlists.create.error_message')}
                  </div>
                )}
              </div>
              {loginAsUserName === 'Super Admin' && (
                <div>
                  <div className="w-100 mt-5">
                    <label className="fs-6 fw-bolder mb-2">
                      {t('common.industry')}
                    </label>
                    <SelectIndustry
                      initialValue={industryId}
                      onChange={({ value }) => setIndustryId(value as string)}
                    />
                  </div>
                  <div className="d-flex align-items-center me-6 ms-2 mt-5">
                    <CheckBox
                      name="isActive"
                      onChange={() => setIsTrial(!isTrial)}
                      id="isTrail"
                      checked={isTrial}
                      className="mt-1"
                    />
                    <label
                      htmlFor="trialCompany"
                      className="col-form-label text-lg-end me-3"
                    >
                      {t('playlists.isTrial')}
                    </label>
                  </div>
                </div>
              )}

              {isBulk && <IsLocked locked={locked} setLocked={setLocked} />}
            </div>
          </div>
        </div>

        {!isBulk && (
          <AdvancedDropdown>
            <div className="row">
              <div className="col">
                <div className="fv-row mb-10">
                  <label
                    htmlFor="priority"
                    className="d-flex align-items-center fs-4 fw-bold mb-2"
                  >
                    <strong>{t('playlists.priority')}</strong>
                  </label>
                  <RegularSelect
                    options={Object.values(Priorities).map((item) => ({
                      label: item,
                      value: item,
                    }))}
                    defaultValue={{
                      label: priority?.toString() ?? '',
                      value: priority?.toString() ?? '',
                    }}
                    onSelect={(selectedValue) => {
                      setPriority(Number(selectedValue));
                    }}
                  />
                  <span className="d-flex align-items-center fs-6 mt-2">
                    {t('playlists.priority_description')}
                  </span>
                </div>
              </div>
              <div className="col">
                <label
                  htmlFor="tags"
                  className="d-flex align-items-center fs-4 fw-bold mb-2"
                >
                  <strong>{t('playlists.add_tags')}</strong>
                </label>
                <SelectTags
                  target={TagsTarget.Playlists}
                  initialValue={tags.map((value) => ({ value, label: value }))}
                  onChange={(values) => {
                    const newValues = map<SelectedValue>(values, 'value');

                    if (!isEqual(newValues, tags)) {
                      setTags(newValues);
                    }
                  }}
                />
                <RestrictedAccess
                  allowedRoles={[
                    UserRole.SuperAdmin,
                    UserRole.Admin,
                    UserRole.AccountOwner,
                    UserRole.Manager,
                    UserRole.IndigoAdmin,
                    UserRole.Editor,
                  ]}
                >
                  <IsLocked locked={locked} setLocked={setLocked} />
                </RestrictedAccess>
              </div>
            </div>
          </AdvancedDropdown>
        )}
        <div className="modal-footer pb-0 d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-light me-3"
            onClick={hideModal}
          >
            {t('common.cancel')}
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(handleSubmitData)}
            disabled={!isValid}
          >
            {t('common.save')}
          </button>
        </div>
      </WithSpinner>
    </Modal>
  );
};

export default PlaylistComponent;
