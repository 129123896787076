/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import clsx from 'clsx';
import Select, { components, OptionProps } from 'react-select';
import FormErrorMessage from '../../../FormErrorMessage';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import Orientation from '../../../../enums/orientation.enum';
import styles from './QrCode.module.scss';
import { CreateQrCodeFeedRequest } from '../../../../store/types/apps/qrCodeFeed';
import TextInput from '../../../TextInput';
import ColorPicker from '../../../ColorPicker';
import { getRgbaValuesFromString } from '../../../../utils/colorFunctions';
import { IconsForQrCode } from '../../../../constants/IconsQr';
import FileUploader from '../../../FileUploader';
import Tooltip from '../../../Tooltip';

type CreateQrCodeRequestFormMutated = Omit<
  CreateQrCodeFeedRequest,
  'config' | 'placement'
>;

interface CreateQrCodeFeedRequestFormPayload
  extends CreateQrCodeRequestFormMutated {
  qrCodeTitle: string;
  qrCodeBgColor: string;
  qrCodeTxtColor: string;
  icon?: string;
  iconColor?: string;
  iconSize?: string;
  qrCodeImage?: string;
  qrCodeSubTitle?: string;
  base64FileType?: string;
  uploadedQrCode?: boolean;
}

export interface CreateFeedModalProps {
  onSubmit: (data: CreateQrCodeFeedRequest) => void;
  qrCode?: App;
  onClose?: () => void;
  isLoading: boolean;
}

export default ({
  onSubmit,
  onClose,
  isLoading,
  qrCode = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();

  const initialValues: CreateQrCodeFeedRequestFormPayload = {
    name: qrCode?.name ?? '',
    qrCodeTitle: qrCode?.dependency?.config?.qrCodeTitle ?? '',
    qrCodeBgColor:
      qrCode?.dependency?.config?.qrCodeBgColor ?? 'rgb(255, 255, 255)',
    qrCodeTxtColor:
      qrCode?.dependency?.config?.qrCodeTxtColor ?? 'rgba(0,0,0,1)',
    qrCodeImage: qrCode?.dependency?.config?.qrCodeImage ?? '',
    qrCodeSubTitle: qrCode?.dependency?.config?.qrCodeSubTitle ?? '',
    icon: qrCode?.dependency?.config?.icon ?? '',
    iconColor: qrCode?.dependency?.config?.iconColor ?? '',
    iconSize: qrCode?.dependency?.config?.iconSize ?? 50,
    uploadedQrCode: qrCode?.dependency?.config?.uploadedQrCode ?? true,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('apps.qrCode.validationName')),
    qrCodeImage: Yup.string().required(t('apps.qrCode.valdiationQrcodeImage')),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateQrCodeFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const [qrCodeTitle, setQrCodeTitle] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'qrCodeTitle' }, initialValues.qrCodeTitle);

  const [subTitle, setSubtitle] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'qrCodeSubTitle' },
    initialValues.qrCodeSubTitle,
  );

  const [qrCodeImage, setQrCodeImage] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'qrCodeImage' }, initialValues.qrCodeImage);

  const [qrCodeBgColor, setQrCodeBgColor] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'qrCodeBgColor' }, initialValues.qrCodeBgColor);

  const [qrCodeTxtColor, setQrCodeTxtColor] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'qrCodeTxtColor' },
    initialValues.qrCodeTxtColor,
  );

  const [icon, setIcon] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'icon' }, initialValues.icon);

  const [iconColor, setIconColor] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'iconColor' }, initialValues.iconColor);

  const [iconSize, setIconSize] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'iconSize' }, initialValues.iconSize);

  const [isScheduled, setIsScheduled] = useState<boolean>(false);

  const handleOnSubmit = async (data: CreateQrCodeFeedRequestFormPayload) => {
    const { name, qrCodeBgColor: bg, qrCodeTxtColor: fg } = data;

    const createQrCodeFeedRequest: CreateQrCodeFeedRequest = {
      name,
      isScheduled,
      config: {
        qrCodeTitle,

        qrCodeSubTitle: subTitle,
        qrCodeBgColor: bg,
        qrCodeTxtColor: fg,

        qrCodeImage,
        base64FileType: 'image/png',
        icon,
        iconColor,
        iconSize,
        uploadedQrCode: true,
      },
      zoneId: Orientation.Landscape,
    };

    onSubmit(createQrCodeFeedRequest);
  };

  const handleUpload = async (selectedFiles: File[]) => {
    if (!selectedFiles.length) {
      setQrCodeImage('');
      return;
    }

    const img = new Image();
    const reader = new FileReader();
    const objectUrl = URL.createObjectURL(selectedFiles[0]);
    img.onload = function handleLoadImage() {
      URL.revokeObjectURL(objectUrl);
    };

    img.src = objectUrl;
    reader.readAsDataURL(selectedFiles[0]);
    reader.onloadend = async () => {
      const base64data = reader.result;
      setQrCodeImage(base64data as string);
    };
  };

  const CustomOption = (innerProps: OptionProps<any, false, any>) => {
    const { data } = innerProps;
    return (
      <components.Option {...innerProps}>
        <i className={`${data.value}`} /> {data.label}
      </components.Option>
    );
  };

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id="form"
          className="d-flex flex-column justify-content-between"
        >
          <div style={{ marginLeft: '20%', marginRight: '20%' }}>
            <FileUploader
              name={`QrCodeUploadFile `}
              mutedText={t('contents.upload_image')}
              accept=".png, .jpg, .jpeg"
              onChange={(image) => {
                handleUpload(image);
              }}
              className="w-100 mt-2"
              containerClassName="justify-content-center"
              maxFiles={1}
            />

            <FormErrorMessage
              name="qrCodeImage"
              errors={errors}
              className="my-1 px-2"
            />

            <Modal.Separator withoutDefaultMargins className="mt-1 mb-7 p-4" />
          </div>
          <div className="d-flex flex-row p-4 w-100">
            <div className="d-flex flex-column mx-2 w-100">
              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" required col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('apps.qrCode.name')}
                </label>
                <TextInput
                  name="name"
                  id="name"
                  value={feedName}
                  onChange={setFeedName}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className="my-1 px-2"
                />
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className="   d-inline-flex align-items-center col-form-label text-lg-end fw-bolder  fs-6"
                >
                  {t('apps.qrCode.qrCodeTitle')}
                  <Tooltip text={t('apps.qrCode.qrCodeTitleInformation')}>
                    <i className="fas fa-info-circle fs-6 " />
                  </Tooltip>
                </label>
                <TextInput
                  name="name"
                  id="name"
                  value={qrCodeTitle}
                  onChange={setQrCodeTitle}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
              </p>
              <p className="mt-2 mb-0">
                <label
                  htmlFor="QrCodeSubtitle"
                  className="  d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.qrCode.qrCodeSubTitle')}
                  <Tooltip text={t('apps.qrCode.qrCodeSubTitleInformation')}>
                    <i className="fas fa-info-circle fs-6 " />
                  </Tooltip>
                </label>
                <div className="input-group">
                  <TextInput
                    name="QrCodeSubtitle"
                    id="QrCodeSubtitle"
                    value={subTitle}
                    onChange={setSubtitle}
                    className="form-control form-control-solid"
                    placeholder="Type here"
                  />
                </div>
              </p>
            </div>

            <div className="d-flex flex-column mx-2 w-100">
              <p className="mt-2 mb-0">
                <label htmlFor="name" className=" text-dark fw-bolder my-1">
                  {t('apps.qrCode.qrCodeBgColor')}
                </label>
                <ColorPicker
                  id="BgColor"
                  name="BgColor"
                  onSelect={(color) => {
                    setQrCodeBgColor(
                      `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                        color?.b ?? 0
                      }, ${color?.a ?? 1})`,
                    );
                  }}
                  value={getRgbaValuesFromString(qrCodeBgColor)}
                />
              </p>

              <p className="mt-2 mb-0">
                <label htmlFor="name" className=" text-dark fw-bolder my-1">
                  {t('apps.qrCode.qrCodeTxtColor')}
                </label>
                <ColorPicker
                  id="BgColor"
                  name="BgColor"
                  onSelect={(color) => {
                    setQrCodeTxtColor(
                      `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                        color?.b ?? 0
                      }, ${color?.a ?? 1})`,
                    );
                  }}
                  value={getRgbaValuesFromString(qrCodeTxtColor)}
                />
              </p>

              <p className="mt-2 mb-0">
                <label htmlFor="name" className=" text-dark fw-bolder my-1">
                  {t('apps.qrCode.qrCodeIcon')}
                </label>
                <Select
                  isClearable
                  options={IconsForQrCode}
                  components={{
                    Option: CustomOption,
                  }}
                  onChange={(val) => {
                    setIcon(val?.value);
                  }}
                />
              </p>

              {icon && (
                <p className="mt-2 mb-0">
                  <label htmlFor="name" className=" text-dark fw-bolder my-1">
                    {t('apps.qrCode.qrCodeIconConfiguration')}
                  </label>
                  <div
                    className="row mt-2"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div className="col col-lg-9">
                      <TextInput
                        name="QrCodeSubtitle"
                        id="QrCodeSubtitle"
                        value={iconSize}
                        onChange={setIconSize}
                        className="form-control form-control-solid"
                        placeholder="Type here"
                        type="number"
                      />
                    </div>

                    <div className="col col-lg-3">
                      {' '}
                      <ColorPicker
                        id="BgColor"
                        name="BgColor"
                        onSelect={(color) => {
                          setIconColor(
                            `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                              color?.b ?? 0
                            }, ${color?.a ?? 1})`,
                          );
                        }}
                        value={getRgbaValuesFromString(iconColor)}
                      />
                    </div>
                  </div>
                </p>
              )}
            </div>
          </div>

          {qrCodeImage && (
            <div
              className=" d-flex flex-column   flex-center  p-4 w-100"
              style={{ backgroundColor: qrCodeBgColor }}
            >
              {icon && (
                <label htmlFor="name" className=" fw-bolder my-1">
                  <i
                    className={`${icon}`}
                    style={{ color: `${iconColor}`, fontSize: `${iconSize}px` }}
                  />
                </label>
              )}

              <label
                htmlFor="name"
                className="  my-1"
                style={{
                  color: qrCodeTxtColor,
                  fontSize: '150%',
                  fontWeight: 'bold',
                  paddingBottom: '12px',
                }}
              >
                {qrCodeTitle && <> {qrCodeTitle}</>}
              </label>
              {qrCodeImage && (
                <img
                  src={qrCodeImage}
                  style={{ maxHeight: '300px', maxWidth: '300px' }}
                />
              )}

              <label
                htmlFor="name"
                className=" fw-bolder my-1"
                style={{ color: qrCodeTxtColor, opacity: '0.6' }}
              >
                {subTitle && <> {subTitle}</>}
              </label>
            </div>
          )}
          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {t('common.save')}
              </button>
              {!Object.keys(qrCode).length && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                >
                  {t('common.save_schedule')}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
