import React from 'react';
import { Dropdown } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';

export default ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        {t('common.bulk_edit')}
      </Dropdown.Toggle>
      {children}
    </Dropdown>
  );
};
