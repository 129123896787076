import React from 'react';
import { KTSVG } from '../../../lib/metronic/helpers';
import Modal from '../../Modal';
import WithSpinner from '../../WithSpinner';

export default ({
  body,
  isVisible,
  isLoading,
  handleConfirm,
  handleDecline,
  confirmButtonText,
  discardButtonText,
}: {
  body: string;
  isLoading: boolean;
  isVisible: boolean;
  handleConfirm?: () => void;
  handleDecline?: () => void;
  confirmButtonText?: string;
  discardButtonText?: string;
}) => (
  <Modal
    show={isVisible}
    onHide={handleDecline}
    withFooter={false}
    withHeader={false}
    widthDefaultSize={false}
    bodyClassName="d-flex flex-center flex-column my-7"
  >
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <span className="svg-icon svg-icon-5tx svg-icon-danger mb-10">
        <KTSVG
          path="/media/icons/duotune/general/gen044.svg"
          className="svg-icon-1"
        />
      </span>
      <div className="text-center">
        <h5 className="fw-bolder fs-1 mb-10">{body}</h5>
        <div className="d-flex flex-center flex-wrap">
          {confirmButtonText && (
            <button
              onClick={handleConfirm}
              type="button"
              className="btn btn-danger m-2"
            >
              {confirmButtonText}
            </button>
          )}
          {discardButtonText && (
            <button
              onClick={handleDecline}
              type="button"
              className="btn btn-outline btn-outline-danger btn-active-danger m-2"
            >
              {discardButtonText}
            </button>
          )}
        </div>
      </div>
    </WithSpinner>
  </Modal>
);
