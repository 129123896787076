import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AppDependencyConfig } from '../../../store/types/apps/globalTypes';
import { CanvaFeedSchema } from '../../../store/types/apps/canvaFeed';
import Modal from '../../Modal';
import noPreview from '../../../assets/images/noPreview/noPreview.png';
import canvaPreviewStyles from './canvaPreview.module.scss';

export interface PreviewFeedModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  selectedCanvaFeedSchema: CanvaFeedSchema;
}

const PreviewFeedModal = ({
  show,
  onHide,
  title,
  selectedCanvaFeedSchema,
}: PreviewFeedModalProps) => {
  const { t } = useTranslation();
  const { name: feedTitle, dependency } = selectedCanvaFeedSchema;

  const handleHide = () => {
    onHide();
  };

  return (
    <div onContextMenu={(ev) => ev.preventDefault()}>
      <Modal
        show={show}
        onHide={handleHide}
        widthDefaultSize
        title={title}
        withFooter
        footerContent={
          <button
            type="button"
            className="btn btn-light-primary me-2"
            onClick={handleHide}
          >
            {t('common.close')}
          </button>
        }
      >
        <div>
          <h3 className="text-center mt-3 mb-7">
            {t('common.title')}
            {': '}
            <small className="text-muted">{feedTitle}</small>
          </h3>
          <p className="text-center">
            <img
              className={clsx(canvaPreviewStyles.canvaImage)}
              alt={feedTitle}
              onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = noPreview;
              }}
              src={(dependency.config as AppDependencyConfig).canvaAssetUrl}
            />
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default PreviewFeedModal;
