import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import moment from 'moment';
import { DateTime } from 'luxon';
import FormErrorMessage from '../../../FormErrorMessage';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';

import Orientation from '../../../../enums/orientation.enum';
import styles from './DaysSafe.module.scss';
import { CreateDaysSafeFeedRequest } from '../../../../store/types/apps/daysSafeFeed';
import TextInput from '../../../TextInput';
import ColorPicker from '../../../ColorPicker';
import { getRgbaValuesFromString } from '../../../../utils/colorFunctions';

import Yup from '../../../../utils/validation';
// datsSafe11Preview

import datsSafe11Preview from '../../../../assets/images/countTimer/daysSafe1.png';

type CreateDaysSafeRequestFormMutated = Omit<
  CreateDaysSafeFeedRequest,
  'config' | 'placement'
>;

interface CreateDaysSafeFeedRequestFormPayload
  extends CreateDaysSafeRequestFormMutated {
  startTimer?: Date;
  backgroundColor?: string;
  text?: string;
  textColor?: string;
  bottomText?: string;
  beginningNumber?: string;
}

export interface CreateFeedModalProps {
  onSubmit: (data: CreateDaysSafeFeedRequest) => void;
  daysSafeApp?: App;
  onClose?: () => void;
  isLoading: boolean;
}

export default ({
  onSubmit,
  onClose,
  isLoading,
  daysSafeApp = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();

  const initialValues: CreateDaysSafeFeedRequestFormPayload = {
    name: daysSafeApp?.name ?? '',

    backgroundColor:
      daysSafeApp?.dependency?.config?.backgroundColor ?? 'rgba(0, 191, 99, 1)',
    textColor:
      daysSafeApp?.dependency?.config?.textColor ?? 'rgba(0, 0, 0, 100)',
    text:
      daysSafeApp?.dependency?.config?.text ??
      t('apps.daysSafe.defaultMessage'),
    startTimer: daysSafeApp?.dependency?.config?.startTimer ?? new Date(),
    bottomText: daysSafeApp?.dependency?.config?.bottomText ?? '',
    // t('apps.daysSafe.defaultBottomText'),
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    text: Yup.string().required(t('apps.daysSafe.required')),
    // startTimer: Yup.date().required(t('apps.daysSafe.required')),
    // bottomText: Yup.string().required(t('apps.daysSafe.required')),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateDaysSafeFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  //   const modalHeight = 400;

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateDaysSafeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const [backgroundColor, setBackgroundColor] = useStateWithHookForm<
    CreateDaysSafeFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'backgroundColor' },
    initialValues.backgroundColor,
  );

  const [textColor, setTextColor] = useStateWithHookForm<
    CreateDaysSafeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'textColor' }, initialValues.textColor);

  const [text, setText] = useStateWithHookForm<
    CreateDaysSafeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'text' }, initialValues.text);

  const [bottomText, setBottomText] = useStateWithHookForm<
    CreateDaysSafeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'bottomText' }, initialValues.bottomText);

  const [startTimer] = useStateWithHookForm<
    CreateDaysSafeFeedRequestFormPayload,
    Date
  >({ setValue, trigger, name: 'startTimer' }, initialValues.startTimer);

  const [beginningNumber, setBeginningNumber] = useStateWithHookForm<
    CreateDaysSafeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'beginningNumber' }, '0');

  const [isScheduled, setIsScheduled] = useState<boolean>(false);

  const getDateFromNumberLuxon = (safeDays: number) => {
    // Get the current date and time in UTC
    const now = DateTime.utc();

    // Calculate the date of the last incident
    const lastIncidentDate = now.minus({ days: safeDays });

    // Convert this to the date in UTC-12
    const lastIncidentDateInUtcMinus12 = lastIncidentDate.setZone('Etc/GMT+12');
    return lastIncidentDateInUtcMinus12;
  };

  // const getDateFromNumber = (days: number) => {
  //   getDateFromNumberLuxon(days);
  //   const currentDateMinusDays = moment()
  //     .subtract(days, 'days')
  //     .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  //     .toDate();
  //   // console.log(currentDateMinusDays);
  //   return currentDateMinusDays;
  // };

  const getNumberOfDaysSafe = () => {
    const daysNumber = moment().diff(
      daysSafeApp?.dependency?.config?.startTimer ?? startTimer,
      'days',
    );

    return daysNumber;
  };

  const handleOnSubmit = async (data: CreateDaysSafeFeedRequestFormPayload) => {
    const { name } = data;

    const CreateDaysSafeFeedRequestAdd: CreateDaysSafeFeedRequest = {
      name,
      isScheduled,
      config: {
        backgroundColor,
        startTimer:
          daysSafeApp?.dependency?.config?.startTimer ??
          getDateFromNumberLuxon(Number(beginningNumber)),
        textColor,
        text,
        bottomText,
      },
      zoneId: Orientation.Landscape,
    };

    onSubmit(CreateDaysSafeFeedRequestAdd);
  };

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id="form"
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex flex-row p-4 w-100">
            <div className="d-flex flex-column mx-2 w-100">
              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" required col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('apps.daysSafe.name')}
                </label>
                <TextInput
                  name="name"
                  id="name"
                  value={feedName}
                  onChange={setFeedName}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className="my-1 px-2"
                />
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" required col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('apps.daysSafe.text')}
                </label>
                <TextInput
                  name="text"
                  id="text"
                  value={text}
                  onChange={setText}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
                <FormErrorMessage
                  name="text"
                  errors={errors}
                  className="my-1 px-2"
                />
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="beginningNumber"
                  className="col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.daysSafe.beginningNumber')}
                </label>
                <TextInput
                  name="text"
                  id="text"
                  value={beginningNumber ?? getNumberOfDaysSafe()}
                  onChange={setBeginningNumber}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="number"
                  disabled={!!daysSafeApp?.dependency?.config?.startTimer}
                />
                <FormErrorMessage
                  name="startTimer"
                  errors={errors}
                  className="my-1 px-2"
                />
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className="col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.daysSafe.bottomText')}
                </label>
                <TextInput
                  name="bottomText"
                  id="bottomText"
                  value={bottomText}
                  onChange={setBottomText}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
              </p>
            </div>

            <div className="d-flex flex-column mx-2 w-100">
              <label
                htmlFor="name"
                className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
              >
                {t('apps.daysSafe.previewExample')}
              </label>
              <img
                src={datsSafe11Preview}
                alt="count down timer preview"
                className="mb-5"
                style={{ width: '100%' }}
              />
              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.daysSafe.backgroundColor')}
                </label>
                <ColorPicker
                  id="BgColor"
                  name="BgColor"
                  onSelect={(color) => {
                    setBackgroundColor(
                      `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                        color?.b ?? 0
                      }, ${color?.a ?? 1})`,
                    );
                  }}
                  value={getRgbaValuesFromString(backgroundColor)}
                />
              </p>

              <p className="mt-2 mb-0" style={{ paddingTop: '1.6rem ' }}>
                <label
                  htmlFor="name"
                  className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.daysSafe.textColor')}
                </label>
                <ColorPicker
                  id="textColor"
                  name="textColor"
                  onSelect={(color) => {
                    setTextColor(
                      `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                        color?.b ?? 0
                      }, ${color?.a ?? 1})`,
                    );
                  }}
                  value={getRgbaValuesFromString(textColor)}
                />
              </p>
            </div>
          </div>
          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {t('common.save')}
              </button>
              {!Object.keys(daysSafeApp).length && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                >
                  {t('common.save_schedule')}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
