import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import App from '../../../../../interfaces/app.interface';
import Table from '../../../../Table';
import DeleteCanva from '../../../../../containers/Apps/DeleteApp';
import ScheduleCanva from '../../../../../containers/Apps/ScheduleApp';
import { AppDependency } from '../../../../../store/types/apps/globalTypes';
import { formatDateString } from '../../../../../utils/dateTime';
import PlacementsEnum from '../../../../../enums/placements.enum';
import Yup from '../../../../../utils/validation';
import PreviewFeedModal from '../../PreviewFeedModal';
import AppType from '../../../../../enums/appType.enum';
import CanvaThumbnail from '../../CanvaThumbnail';
import EditCanva from '../../../../../containers/Apps/Canva/EditCanva';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({
  canva,
  onUpdate,
  scheduleApp,
}: {
  canva: App;
  onUpdate: () => void;
  scheduleApp: (app: App) => void;
}) => {
  const { t } = useTranslation();
  const [showModalFeed, setShowModalFeed] = useState(false);

  return (
    <Row>
      <Cell isEmpty={!canva.name}>
        <div onClick={() => setShowModalFeed(true)} aria-hidden>
          <CanvaThumbnail app={canva}>
            <span className="text-dark cursor-pointer pt-2 px-2 fw-bold text-hover-primary fs-6">
              {canva.name}
            </span>
          </CanvaThumbnail>
        </div>
        <PreviewFeedModal
          show={showModalFeed}
          onHide={() => {
            setShowModalFeed(false);
          }}
          title={t('common.preview')}
          selectedCanvaFeedSchema={{
            type: AppType.Canva,
            createdAt: canva!.createdAt,
            updatedAt: canva!.updatedAt,
            dependency: canva!.dependency as AppDependency,
            id: canva!.id,
            name: canva!.name,
            placement: canva!.placement as PlacementsEnum,
          }}
        />
      </Cell>

      <Cell isEmpty={!canva.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {canva?.ownerApp?.firstName} {canva?.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!canva.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {canva.createdAt && formatDate(canva.createdAt)}
        </span>
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          <div className="mx-1">
            <EditCanva
              canva={canva}
              onUpdate={onUpdate}
              scheduleApp={scheduleApp}
            />
          </div>
          {canva.placement === PlacementsEnum.FULL_SCREEN && (
            <div className="mx-1">
              <ScheduleCanva
                app={canva}
                onUpdate={onUpdate}
                infoValidationSchema={{
                  orientation: Yup.string().required(t('required')),
                  duration: Yup.number().required(t('required')).min(1),
                  startDate: Yup.date(),
                  endDate: Yup.date().when('startDate', {
                    is: (startDate: any) => !!startDate,
                    then: Yup.date().min(
                      Yup.ref('startDate'),
                      t('common.validation.endDate'),
                    ),
                  }),
                  isLocked: Yup.boolean(),
                }}
                skipOrientationAuto
                skipOrder
              />
            </div>
          )}

          <div className="mx-1">
            <DeleteCanva
              app={canva}
              onUpdate={onUpdate}
              title={t('common.delete_confirmation.canva')}
              toastMessage="apps.canva.canva_deleted"
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default TableRow;
