import React from 'react';
import { Mode } from '../components/Select/SelectMode';
import Orientation from '../enums/orientation.enum';
import Icon from '../components/Icon';

export default ({
  landscapeText,
  portraitText,
  autoText,
  fullScreenText,
}: {
  landscapeText: string;
  portraitText?: string;
  autoText?: string;
  fullScreenText?: string;
}): Mode<Orientation>[] =>
  (
    [
      autoText && {
        value: Orientation.Auto,
        renderView: (isActive) => (
          <Icon
            className="rounded-3 mb-3"
            text={autoText}
            isActive={isActive}
            path="/media/icons/duotune/layouts/lay012.svg"
          />
        ),
      },
      fullScreenText && {
        value: Orientation.FullScreen,
        renderView: (isActive) => (
          <Icon
            className="rounded-3 mb-3"
            text={fullScreenText}
            isActive={isActive}
            path="/media/icons/duotune/layouts/lay012.svg"
          />
        ),
      },
      landscapeText && {
        value: Orientation.Landscape,
        renderView: (isActive) => (
          <Icon
            className="rounded-3 mb-3"
            text={landscapeText}
            isActive={isActive}
            path="/media/icons/duotune/layouts/lay011.svg"
          />
        ),
      },
      portraitText && {
        value: Orientation.Portrait,
        renderView: (isActive) => (
          <Icon
            className="rounded-3 mb-3"
            text={portraitText}
            isActive={isActive}
            path="/media/icons/duotune/layouts/lay012.svg"
          />
        ),
      },
    ] as (Mode<Orientation> | '')[]
  ).filter(Boolean) as Mode<Orientation>[];
