import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import SelectAsync, {
  SelectedValue,
} from '../../components/Select/SelectAsync';

interface SelectIndustriesProps {
  onChange?: (selectedValues: SelectedValue[]) => void;
  initialValue?: string[];
  id?: string;
  className?: string;
  preselect?: boolean;
}

interface DefaultProps extends SelectIndustriesProps {
  onChange: Required<SelectIndustriesProps>['onChange'];
  initialValue: Required<SelectIndustriesProps>['initialValue'];
}

const SelectIndustries = ({
  onChange,
  initialValue,
  id,
  className,
  preselect,
}: DefaultProps) => {
  const { t } = useTranslation();
  const [setSelectedValues, setSelectedValue] = useState<SelectedValue[]>([]);

  useEffect(() => {
    if (!initialValue) return;

    setSelectedValue([
      {
        label: `${t('common.loading')}...`,
      },
    ]);

    setTimeout(async () => {
      const values = initialValue?.length
        ? await api.industries.getIndustriesByIds(initialValue)
        : [];

      setSelectedValue(
        values.map(({ name, id: value }) => ({ label: name, value })),
      );
    });
  }, []);

  return (
    <SelectAsync
      id={id}
      initialValue={setSelectedValues}
      onChange={(selectedValues: SelectedValue[]) => onChange(selectedValues)}
      loadOptions={async (search: string): Promise<SelectedValue[]> => {
        const {
          items,
          meta: { totalItems },
        } = await api.industries.getIndustries({ search });

        if (preselect && totalItems === 1 && !search) {
          const [item] = items;

          setSelectedValue([{ label: item.name, value: item.id }]);
        }

        return sortBy(items, ['name']).map(({ id: value, name }) => ({
          value,
          label: name,
        }));
      }}
      className={className}
      placeholder={t('industries.select_industries')}
      isClearable
      isMulti
    />
  );
};

SelectIndustries.defaultProps = {
  initialValue: [],
  onChange: () => null,
};

export default SelectIndustries;
