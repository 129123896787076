import clsx from 'clsx';
import React, { memo } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap-v5';
import { KTSVG } from '../../lib/metronic/helpers';
import styles from './modal.module.scss';

interface ModalProps {
  show: boolean;
  title?: string;
  fullscreen?:
    | true
    | 'sm-down'
    | 'md-down'
    | 'lg-down'
    | 'xl-down'
    | 'xxl-down'
    | undefined;
  onHide?: () => void;
  handleShow?: () => void;
  onSubmit?: () => void;
  children: React.ReactNode;
  footerContent?: React.ReactNode;
  widthDefaultSize?: boolean;
  withFooter?: boolean;
  withHeader?: boolean;
  bodyClassName?: string;
  dialogClassName?: string;
  copy?: () => void;
  hideButton?: boolean;
  submitButtonText?: string;
  floatLeft?: boolean;
  fullScreenPreview?: boolean;
  isTrial?: boolean;
}

const Modal = ({
  show,
  fullscreen,
  fullScreenPreview,
  handleShow,
  onHide,
  onSubmit,
  title,
  children,
  copy,
  footerContent,
  withFooter,
  withHeader,
  widthDefaultSize,
  bodyClassName,
  dialogClassName,
  hideButton,
  submitButtonText,
  floatLeft,
  isTrial,
}: ModalProps) => (
  <>
    <BootstrapModal
      enforceFocus={false}
      show={show}
      onHide={onHide}
      dialogClassName={clsx(
        'modal-dialog-centered',
        widthDefaultSize && !fullscreen && 'mw-900px',
        dialogClassName,
      )}
      key={title}
    >
      {withHeader && (
        <div
          className="modal-header"
          style={{
            maxHeight: '60px',
          }}
        >
          <div className="d-flex">
            <h2>{title}</h2>
          </div>
          <div
            className={clsx(
              'd-flex justify-content-end',
              styles.previewpopupicon,
            )}
          >
            {copy && handleShow && isTrial && (
              <>
                <div
                  className="btn btn-sm btn-icon btn-active-color-primary"
                  onClick={copy}
                  tabIndex={-1}
                  onKeyPress={copy}
                  role="button"
                  data-bs-dismiss="modal"
                >
                  <KTSVG
                    path="/media/icons/link.svg"
                    className={clsx(styles.svgLinkIcon)}
                  />
                </div>
                <div
                  onClick={handleShow}
                  tabIndex={-1}
                  role="button"
                  onKeyPress={handleShow}
                  className="btn btn-sm btn-icon btn-active-color-primary"
                >
                  <KTSVG
                    path="/media/icons/fullscreen.svg"
                    className={clsx(styles.svgZoomIcon)}
                  />
                </div>
              </>
            )}
            {fullScreenPreview && !copy && handleShow && !isTrial && (
              <>
                <div
                  onClick={handleShow}
                  tabIndex={-1}
                  role="button"
                  onKeyPress={handleShow}
                  className="btn btn-sm btn-icon btn-active-color-primary"
                >
                  <KTSVG
                    path="/media/icons/fullscreen.svg"
                    className={clsx(styles.svgZoomIcon)}
                  />
                </div>
              </>
            )}

            <div
              role="button"
              tabIndex={-1}
              onKeyPress={onHide}
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={onHide}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon-1"
              />
            </div>
          </div>
        </div>
      )}
      <div className={clsx('modal-body', bodyClassName)}>{children}</div>
      {withFooter && (
        <div
          className="modal-footer"
          style={{
            maxHeight: '50px !important',
          }}
        >
          {footerContent || (
            <>
              {hideButton && (
                <div className={floatLeft ? 'pe-5 me-auto' : ''}>
                  <button
                    type="button"
                    className="btn btn-light-primary"
                    onClick={onHide}
                  >
                    Cancel
                  </button>
                </div>
              )}
              <button
                id="submit"
                type="button"
                className="btn btn-primary"
                onClick={onSubmit}
              >
                {submitButtonText}
              </button>
            </>
          )}
        </div>
      )}
    </BootstrapModal>
  </>
);

Modal.defaultProps = {
  hideButton: true,
  widthDefaultSize: true,
  withFooter: true,
  withHeader: true,
  title: '',
  submitButtonText: 'Apply',
};

Modal.Separator = memo(
  ({
    className,
    withoutDefaultMargins,
  }: {
    className?: string;
    withoutDefaultMargins?: boolean;
  }) => (
    <div
      className={clsx(
        'd-flex position-relative',
        !withoutDefaultMargins && 'mt-8 mb-7',
        className,
      )}
    >
      <div className={clsx('separator position-absolute', styles.separator)} />
    </div>
  ),
);

export default Modal;
