import React from 'react';
import { useTranslation } from 'react-i18next';
import StepsTitle from '../../../WithModesModal/StepsTitle';
import AddUserSteps from '../../../../enums/steps/addUserSteps.enum';

export default ({ currentStep }: { currentStep: AddUserSteps }) => {
  const { t } = useTranslation();

  const steps = {
    [AddUserSteps.Info]: t('users.create_user.steps.personal_info'),
    [AddUserSteps.Assign]: t('users.create_user.steps.device_access'),
  } as const;

  return <StepsTitle currentStep={currentStep} steps={steps} />;
};
