import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import authImage from '../../../assets/images/auth/bg10.jpeg';
import appHero from '../../../assets/images/auth/appHero.png';
import logo from '../../../assets/images/logo/indigo_vizual_logo.png';
import '../Profile/signup.css';
import styles from './styles.module.scss';
import WithSpinner from '../../../components/WithSpinner';
import fritolay from '../../../assets/images/logo/brands/fritolay.png';
import napa from '../../../assets/images/logo/brands/napa.png';
import kelloggs from '../../../assets/images/logo/brands/kelloggs.png';
import michaels from '../../../assets/images/logo/brands/michaels.png';
import Ads from '../../../assets/images/logo/brands/ads.png';
import abx from '../../../assets/images/logo/brands/abx.png';
import gems from '../../../assets/images/logo/brands/gems.png';
import hb from '../../../assets/images/logo/brands/hb.png';
import kinger from '../../../assets/images/logo/brands/kinder.png';
import hemlock from '../../../assets/images/logo/brands/hemlock.png';
import madison from '../../../assets/images/logo/brands/madison.png';
import PMS from '../../../assets/images/logo/brands/PMS.png';
import qpp from '../../../assets/images/logo/brands/qpp.png';
import ron from '../../../assets/images/logo/brands/ron.png';
import tempstaff from '../../../assets/images/logo/brands/tempstaff.png';
import sally from '../../../assets/images/logo/brands/sallybeaty.png';

export default ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();

  const urls = [fritolay, napa, kelloggs, michaels, Ads, abx, gems, hb];
  const urls2 = [kinger, hemlock, madison, PMS, qpp, ron, tempstaff, sally];

  const [imageLoading, setImageLoading] = React.useState(false);

  React.useEffect(() => {
    const image = new Image();
    image.src = appHero;
    setImageLoading(true);
    image.onload = () => {
      setImageLoading(false);
    };
  }, []);
  return (
    <div
      id="kt_body"
      className={clsx(
        'd-flex justify-content-center align-items-center app-blank  bgi-size-cover bgi-position-center bgi-no-repeat ',
        styles.background,
      )}
      style={{
        backgroundImage: `url(${!imageLoading ? authImage : ''})`,
      }}
    >
      {!imageLoading ? (
        <div className="d-flex flex-column flex-root" id="kt_app_root">
          <div className="d-md-flex flex-column flex-lg-row flex-column-fluid justify-content-center align-items-center m-auto">
            <div className={clsx(styles.leftpart, 'd-flex flex-lg-row-fluid')}>
              <div
                className={clsx(
                  'leftColumn flex-column flex-center pb-0 pb-lg-10 p-10 w-100',
                  styles.image,
                )}
              >
                <div className="mb-2 mw-400px ">
                  <img alt="Logo" src={logo} className="img-fluid mw-50 " />
                </div>
                <h6
                  className={clsx(
                    'text-primary fs-2qx fw-bolder text-center mb-5 mw-550px',
                    styles.title,
                  )}
                >
                  {t('auth_pages.heading')}
                </h6>
                <img
                  className={clsx('img-fluid pt-2', styles.imageHome)}
                  src={appHero}
                  id="image"
                  alt=""
                />
                <div
                  className={clsx(
                    'd-flex flex-column flex-center pt-5',
                    styles.brands,
                  )}
                >
                  <div
                    className={clsx(
                      styles.warraper,
                      'd-sm-flex flex-row  w-100',
                    )}
                  >
                    {urls.map((url) => (
                      <div className="d-sm-flex flex-column justify-content-center  w-100 px-2">
                        <img
                          key={url}
                          src={url}
                          alt="logo"
                          className="img-fluid"
                        />
                      </div>
                    ))}
                  </div>
                  <div
                    className={clsx(
                      styles.warraper,
                      'd-sm-flex justify-content-center align-items-center flex-row w-100 px-2',
                    )}
                  >
                    {urls2.map((url) => (
                      <div className="d-sm-flex flex-column justify-content-center   w-100 px-2">
                        <img
                          src={url}
                          key={url}
                          alt="logo"
                          className="img-fluid"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center flex-column-fluid flex-lg-row-auto  justify-content-lg-center p-12">
              <div
                className={clsx(
                  'shadow bg-body d-flex right-form-indigo rounded w-md-500px p-10',
                  styles.form,
                )}
                style={{ justifyContent: 'center' }}
              >
                <div className="w-md-400px">{children}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <WithSpinner isLoading={imageLoading} />
      )}
    </div>
  );
};
