import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap-v5';
import clsx from 'clsx';
import GlobalState from '../../../../store/reducers/globalState.interface';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import Yup from '../../../../utils/validation';
import Counter from '../../../Counter';
import FormErrorMessage from '../../../FormErrorMessage';
import Modal from '../../../Modal';
import TextInput from '../../../TextInput';
import { CreatePowerBiFeedRequest } from '../../../../store/types/apps/powerBiFeed';
import styles from './PowerBi.module.scss';
import App from '../../../../interfaces/app.interface';
import Orientation from '../../../../enums/orientation.enum';
import WithSpinner from '../../../WithSpinner';
import { disableRightClickIframe } from '../../../../utils/common';

declare global {
  interface Window {
    userId: string;
    powerBiServiceURL?: string;
    parentStore: string | null;
    rid: string;
    reportState: string;
  }
}

interface PowerBiForm {
  name: string;
  refreshRate: number;
  powerBiFeedUrl: string;
}

type CreatePowerBiRequestFormMutated = Omit<
  CreatePowerBiFeedRequest,
  'config' | 'placement'
>;

interface CreatePowerBiFeedRequestFormPayload
  extends CreatePowerBiRequestFormMutated {
  powerBiFeedUrl: string;
  refreshRate: number;
}
export interface CreateFeedModalProps {
  onSubmit: (data: CreatePowerBiFeedRequest) => void;
  powerBi?: App;
  onClose?: () => void;
  isLoading: boolean;
}

export default ({
  onSubmit,
  onClose,
  isLoading,
  powerBi = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();
  const modalHeight = 400;
  const user = useSelector((state: GlobalState) => state.userData.user);

  const initialValues: CreatePowerBiFeedRequestFormPayload = {
    name: powerBi?.name ?? '',
    powerBiFeedUrl: powerBi?.dependency?.config?.powerBiFeedUrl ?? '',
    refreshRate: powerBi?.dependency?.config?.powerBiRefreshRateInSeconds ?? 30,
  };

  useEffect(() => {
    window.userId = user.id;
    window.powerBiServiceURL = process.env.REACT_APP_POWER_API_URL;
    window.parentStore = null;
    window.rid = user.id;
    window.reportState = '';
  }, []);

  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [showSaved, setShowSaved] = useState<boolean>(false);

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<PowerBiForm>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().required(
          t('apps.powerBi.create_powerbi_validation.please_enter_title'),
        ),
        refreshRate: Yup.number()
          .required(
            t(
              'apps.powerBi.create_powerbi_validation.please_enter_refreshRate',
            ),
          )
          .min(16, t('apps.powerBi.create_powerbi_validation.min_refreshRate'))
          .defined(),
        powerBiFeedUrl: Yup.string()
          .matches(/app.powerbi.com/, t('apps.powerBi.invalidLink'))
          .url(t('common.validation.validUrl'))
          .defined(),
      }).required(),
    ),
    defaultValues: initialValues,
  });

  const [name, setTitle] = useStateWithHookForm<PowerBiForm, string>(
    { setValue, trigger, name: 'name' },
    initialValues.name,
  );

  const [refreshRate, setRefreshRate] = useStateWithHookForm<
    PowerBiForm,
    number
  >({ setValue, trigger, name: 'refreshRate' }, initialValues.refreshRate);

  const [powerBiFeedUrl, setPowerBiFeedUrl] = useStateWithHookForm<
    PowerBiForm,
    string
  >(
    { setValue, trigger, name: 'powerBiFeedUrl' },
    initialValues.powerBiFeedUrl,
  );

  const getReportPreviewUrl = (powerBiUrl: string) =>
    powerBiUrl === ''
      ? ''
      : `${process.env.REACT_APP_POWER_API_URL}/powerbi/record?rid=${user.id}&userId=${user.id}&embedURL=${powerBiUrl}&record=true`;

  const [previewURL, setPreviewURL] = useState<string>(
    getReportPreviewUrl(powerBiFeedUrl),
  );

  const [powerBiRecording, setPowerbiRecording] = useState<string>('');

  const [previewDocumentIsFetching, setPreviewDocumentIsFetching] =
    React.useState<boolean>(false);

  const handlePreviewDocumentIsFetching = (
    previewDocumentIsFetchingData: boolean,
  ) => setPreviewDocumentIsFetching(previewDocumentIsFetchingData);

  const handleOnIFrameLoaded = () => handlePreviewDocumentIsFetching(false);

  const handleRefreshRateChange = (value: number) => setRefreshRate(value);

  const loadReport = () => {
    if (!errors.powerBiFeedUrl && powerBiFeedUrl.length !== 0) {
      setPreviewDocumentIsFetching(true);
      setTimeout(() => {
        setPreviewDocumentIsFetching(false);
        setPreviewURL(getReportPreviewUrl(btoa(powerBiFeedUrl)));
      }, 500);
    }
  };

  const saveReport = () => {
    if (!errors.powerBiFeedUrl && powerBiFeedUrl.length !== 0) {
      fetch(`${window.powerBiServiceURL}/recording/${window.rid}`).then(
        async (response) => {
          window.reportState = await response.text();
          setPowerbiRecording(encodeURIComponent(window.reportState));
          setShowSaved(true);
          setTimeout(() => {
            setShowSaved(false);
          }, 2000);
        },
      );
    }
  };

  const handleOnSubmit = async (data: CreatePowerBiFeedRequestFormPayload) => {
    const {
      name: powerBiName,
      powerBiFeedUrl: powerBiFeedUrlData,
      refreshRate: refreshRateData,
    } = data;
    const createPowerBiFeedRequest: CreatePowerBiFeedRequest = {
      name: powerBiName,
      isScheduled,
      config: {
        powerBiFeedUrl: powerBiFeedUrlData,
        powerBiRefreshRateInSeconds: refreshRateData,
        powerBiUtilityAppId: powerBi?.dependency?.config?.powerBiUtilityAppId,
        powerBiRecording,
      },
      zoneId: Orientation.Landscape,
    };

    onSubmit(createPowerBiFeedRequest);
  };

  React.useEffect(() => {
    disableRightClickIframe(name);
  }, []);

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <form onSubmit={handleSubmit(handleOnSubmit)} id="form">
        <div className="container ps-0">
          <div className="row">
            <div className="d-flex flex-row p-4 w-100">
              <div className="d-flex flex-column mx-2 w-100">
                <label
                  htmlFor="name"
                  className="required text-dark fw-bolder my-1"
                >
                  {t('apps.powerBi.createNewPowerBi')}
                </label>
                <TextInput
                  value={name}
                  onChange={setTitle}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className="my-1 px-2"
                />
              </div>
              <div className="d-flex flex-column mx-2 w-50">
                <Counter
                  name="refreshRate"
                  title="Refresh Rate (sec)"
                  value={refreshRate}
                  onChange={handleRefreshRateChange}
                  className="form-control form-control-solid"
                  classNameTitle="text-dark fw-bolder my-1"
                />
                <FormErrorMessage
                  name="refreshRate"
                  errors={errors}
                  className="my-1 px-2"
                />
              </div>
            </div>
            <div className="d-flex flex-row px-4 w-100">
              <div className="d-flex flex-column mx-2 w-100">
                <label
                  htmlFor="googleDocFeedUrl"
                  className="required text-dark fw-bolder my-1"
                >
                  {t('apps.powerBi.webPageUrl')}
                </label>
                <TextInput
                  placeholder=""
                  value={powerBiFeedUrl}
                  onChange={setPowerBiFeedUrl}
                  className="form-control form-control-solid"
                />
                <FormErrorMessage
                  name="powerBiFeedUrl"
                  errors={errors}
                  className="my-1 px-2"
                />
                <div>
                  <button
                    type="button"
                    onClick={loadReport}
                    className={`btn btn-secondary ${styles.loadBtns}`}
                  >
                    {t('common.load')}
                  </button>
                  <button
                    type="button"
                    onClick={saveReport}
                    className={`btn btn-secondary ${styles.loadBtns}`}
                  >
                    {t('common.save_filters')}
                    {showSaved && (
                      <i
                        className={`fas fa-check-circle ${styles.successIcon}`}
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column   flex-center  p-4 w-100">
              {!errors.powerBiFeedUrl && previewURL !== '' ? (
                <div className="d-flex flex-column flex-center w-100 h-100">
                  {previewDocumentIsFetching ? (
                    <Spinner role="status" animation="border" />
                  ) : (
                    <iframe
                      title={name}
                      src={previewURL}
                      width="100%"
                      height={`${modalHeight}px`}
                      onLoad={handleOnIFrameLoaded}
                      frameBorder="0"
                      marginHeight={0}
                      marginWidth={0}
                      className={styles.htmlIframe}
                    />
                  )}
                </div>
              ) : (
                <div className="d-flex flex-column flex-center w-100">
                  <img
                    className="d-flex flex-column align-self-center w-25"
                    alt="No items found"
                    src="/media/illustrations/sketchy-1/5.png"
                  />
                  <div className="fs-1">{t('apps.googleDocs.noPreview')}</div>
                  <div className="fs-6">{t('apps.powerBi.webPageUrl')}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-white text-primary"
            onClick={onClose}
          >
            {t('common.cancel')}
          </button>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {t('common.save')}
            </button>
            {!Object.keys(powerBi).length && (
              <button
                onClick={() => setIsScheduled(true)}
                type="submit"
                className={clsx('btn btn-primary', styles.scheduleBtn)}
              >
                {t('common.save_schedule')}
              </button>
            )}
          </div>
        </div>
      </form>
    </WithSpinner>
  );
};
