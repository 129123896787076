import {
  HTMLFeedsReduxAction,
  HTML_FEED_ACTIONS,
} from '../../actions/apps/htmlFeed';

import { ErrorResponse } from '../../types/apps/globalTypes';
import { PaginatedHTMLFeeds } from '../../types/apps/htmlFeed';

export interface HTMLFeedsState {
  error: ErrorResponse | null;

  data: PaginatedHTMLFeeds;
  loading: boolean;
  isGetHTMLFeedsSuccess: boolean;

  createHTMLFeedLoading: boolean;
  isCreateHTMLFeedSuccess: boolean;

  scheduleHTMLFeedLoading: boolean;
  isScheduleHTMLFeedSuccess: boolean;

  deleteHTMLFeedLoading: boolean;
  isDeleteHTMLFeedSuccess: boolean;

  editHTMLFeedLoading: boolean;
  isEditHTMLFeedSuccess: boolean;
  isCreateScheduleHTMLFeedSuccess: boolean;
}

const initialState: HTMLFeedsState = {
  error: null,

  data: {
    items: [],
    // links: { first: '', previous: '', next: '', last: '' },
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 10,
      // totalPages: 0,
      currentPage: 1,
    },
  },
  loading: false,
  isGetHTMLFeedsSuccess: false,

  createHTMLFeedLoading: false,
  isCreateHTMLFeedSuccess: false,

  scheduleHTMLFeedLoading: false,
  isScheduleHTMLFeedSuccess: false,

  deleteHTMLFeedLoading: false,
  isDeleteHTMLFeedSuccess: false,

  editHTMLFeedLoading: false,
  isEditHTMLFeedSuccess: false,
  isCreateScheduleHTMLFeedSuccess: false,
};

export default function HTMLFeedsReducer(
  state = initialState,
  action: HTMLFeedsReduxAction<HTML_FEED_ACTIONS>,
): HTMLFeedsState {
  const { payload } = action;

  switch (action.type) {
    // GET
    case HTML_FEED_ACTIONS.GET_HTML_FEEDS_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };

    case HTML_FEED_ACTIONS.GET_HTML_FEEDS_SUCCESS:
      return {
        ...state,
        loading: payload.loading,
        data: payload.data,
        isGetHTMLFeedsSuccess: payload.isGetHTMLFeedsSuccess,
      };

    case HTML_FEED_ACTIONS.GET_HTML_FEEDS_ERROR:
      return {
        ...state,
        loading: payload.loading,
        error: payload.error,
        isGetHTMLFeedsSuccess: payload.isGetHTMLFeedsSuccess,
      };

    case HTML_FEED_ACTIONS.GET_HTML_FEEDS_RESET:
      return {
        ...state,
        data: payload.data,
        loading: payload.loading,
        error: payload.error,
        isGetHTMLFeedsSuccess: payload.isGetHTMLFeedsSuccess,
      };

    // CREATE HTML FEED
    case HTML_FEED_ACTIONS.CREATE_HTML_FEED_LOADING:
      return {
        ...state,
        createHTMLFeedLoading: payload.loading,
      };

    case HTML_FEED_ACTIONS.CREATE_HTML_FEED_SUCCESS:
      return {
        ...state,
        createHTMLFeedLoading: payload.loading,
        isCreateHTMLFeedSuccess: payload.isCreateHTMLFeedSuccess,
      };

    case HTML_FEED_ACTIONS.CREATE_HTML_FEED_ERROR:
      return {
        ...state,
        createHTMLFeedLoading: payload.loading,
        error: payload.error,
        isCreateHTMLFeedSuccess: payload.isCreateHTMLFeedSuccess,
      };

    case HTML_FEED_ACTIONS.CREATE_HTML_FEED_RESET:
      return {
        ...state,
        createHTMLFeedLoading: payload.loading,
        error: payload.error,
        isCreateHTMLFeedSuccess: payload.isCreateHTMLFeedSuccess,
      };

    // SCHEDULE HTML FEED
    case HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_LOADING:
      return {
        ...state,
        scheduleHTMLFeedLoading: payload.loading,
      };

    case HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_SUCCESS:
      return {
        ...state,
        scheduleHTMLFeedLoading: payload.loading,
        isScheduleHTMLFeedSuccess: payload.isScheduleHTMLFeedSuccess,
      };

    case HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_ERROR:
      return {
        ...state,
        scheduleHTMLFeedLoading: payload.loading,
        error: payload.error,
        isScheduleHTMLFeedSuccess: payload.isScheduleHTMLFeedSuccess,
      };

    case HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_RESET:
      return {
        ...state,
        scheduleHTMLFeedLoading: payload.loading,
        error: payload.error,
        isScheduleHTMLFeedSuccess: payload.isScheduleHTMLFeedSuccess,
      };

    // DELETE HTML FEED
    case HTML_FEED_ACTIONS.DELETE_HTML_FEED_LOADING:
      return {
        ...state,
        deleteHTMLFeedLoading: payload.loading,
      };

    case HTML_FEED_ACTIONS.DELETE_HTML_FEED_SUCCESS:
      return {
        ...state,
        deleteHTMLFeedLoading: payload.loading,
        isDeleteHTMLFeedSuccess: payload.isDeleteHTMLFeedSuccess,
      };

    case HTML_FEED_ACTIONS.DELETE_HTML_FEED_ERROR:
      return {
        ...state,
        deleteHTMLFeedLoading: payload.loading,
        error: payload.error,
        isDeleteHTMLFeedSuccess: payload.isDeleteHTMLFeedSuccess,
      };

    case HTML_FEED_ACTIONS.DELETE_HTML_FEED_RESET:
      return {
        ...state,
        deleteHTMLFeedLoading: payload.loading,
        error: payload.error,
        isDeleteHTMLFeedSuccess: payload.isDeleteHTMLFeedSuccess,
      };

    // EDIT HTML FEED
    case HTML_FEED_ACTIONS.EDIT_HTML_FEED_LOADING:
      return {
        ...state,
        editHTMLFeedLoading: payload.loading,
      };

    case HTML_FEED_ACTIONS.EDIT_HTML_FEED_SUCCESS:
      return {
        ...state,
        editHTMLFeedLoading: payload.loading,
        isEditHTMLFeedSuccess: payload.isEditHTMLFeedSuccess,
      };

    case HTML_FEED_ACTIONS.EDIT_HTML_FEED_ERROR:
      return {
        ...state,
        editHTMLFeedLoading: payload.loading,
        error: payload.error,
        isEditHTMLFeedSuccess: payload.isEditHTMLFeedSuccess,
      };

    case HTML_FEED_ACTIONS.EDIT_HTML_FEED_RESET:
      return {
        ...state,
        editHTMLFeedLoading: payload.loading,
        error: payload.error,
        isEditHTMLFeedSuccess: payload.isEditHTMLFeedSuccess,
      };

    default:
      return state;
  }
}
