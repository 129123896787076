/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';
import { toast } from 'react-toastify';
import App from '../../../../interfaces/app.interface';
import { CreateMsTeamsFeedRequest } from '../../../../store/types/apps/msTeamsFeed';
import microsoft from '../../../../assets/images/auth/microsoft-96.svg';
import msTeamsLogo from '../../../../assets/images/apps/msTeams.png';
import styles from './MSTeams.module.scss';
import WithSpinner from '../../../WithSpinner';
import { MsTeamsApis } from '../../../../api/endpoints/apps/Microsoft';

const scopes = [
  'Channel.ReadBasic.All',
  'ChannelMessage.Read.All',
  'email',
  'Files.Read.All',
  'openid',
  'profile',
  'Team.ReadBasic.All',
  'User.Read',
  'offline_access',
];
interface AuthenticatedUser {
  id: string;
  name: string;
  mail: string;
  accessToken: string;
  refreshToken: string;
  expiresIn: number;
}

export default ({
  authenticatedUser,
  msTeam = {} as App,
  isloadingLogin,
  code,
  wrongAccountAlert,
  updatingAppsLoading,
}: {
  msTeam?: App;
  authenticatedUser?: AuthenticatedUser;
  isloadingLogin: boolean;
  code?: string;
  wrongAccountAlert?: boolean;
  updatingAppsLoading: boolean;
}) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isScheduled, setIsScheduled] = useState<boolean>(false);

  const handleMsteamAuth = async () => {
    try {
      sessionStorage.setItem('relogin', 'true');
      window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
        process.env.REACT_APP_MS_TEAMS_CLIENT_ID
      }&response_type=code&redirect_uri=${
        process.env.REACT_APP_MS_TEAMS_REDIRECT_URI
      }&response_mode=query&scope=${scopes.join('%20')}&prompt=login`;
    } catch (e) {
      console.log(e);
    }
  };
  //   React.useMemo(() => {
  //     if (code) {
  //       getTokenAndProfileData();
  //     }
  //   }, []);
  return (
    <>
      <form id="form" className="d-sm-flex flex-column justify-content-center">
        <img
          src={msTeamsLogo}
          alt="teamss"
          className={clsx('mb-4', styles.msTeamsImage)}
        />
        {!code && (
          <h1 className="text-center text-dark mb-4">
            {t('apps.msTeams.title')}
          </h1>
        )}
        {wrongAccountAlert && (
          <span
            className="text-left text-dark mb-4 fs-3"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('apps.msTeams.wrongAccountAlertMessage', {
                msAppAccount: msTeam?.dependency?.config?.mail,
                loggedInAccount: authenticatedUser?.mail,
              }),
            }}
          />
        )}

        <WithSpinner
          isLoading={isloadingLogin}
          style={{ minHeight: `300px` }}
          // isForTable
          size="md"
        >
          {authenticatedUser?.name ? (
            <>
              <div className="d-sm-flex flex-row justify-content-center ">
                <div
                  className={clsx(styles.wpo365mssigninbutton)}
                  style={{
                    cursor: 'default',
                  }}
                >
                  <div className={clsx(styles.wpo365mssigninlogo)}>
                    <img
                      src={microsoft}
                      alt="Micrososft accout"
                      width="21"
                      height="21"
                    />
                  </div>
                  <div className={clsx(styles.wpo365mssigninlabel)}>
                    {`${t('apps.msTeams.msTeamsAuthenticated')} ${
                      authenticatedUser?.name
                    } `}
                  </div>
                </div>
              </div>
              {updatingAppsLoading && !wrongAccountAlert && (
                <div className="d-sm-flex flex-row justify-content-center mt-4">
                  <span className="text-left text-dark mb-4 fs-3">
                    {t('apps.msTeams.updatingRelatedApps')}
                  </span>
                </div>
              )}
              {!wrongAccountAlert && (
                <WithSpinner
                  isLoading={updatingAppsLoading}
                  style={{ minHeight: `80px` }}
                  // isForTable
                  size="md"
                >
                  <div className="d-sm-flex flex-row justify-content-center mt-4">
                    <span className="text-left text-dark mb-4 fs-3">
                      {t('apps.msTeams.updatedRelatedApps')}
                    </span>
                  </div>
                </WithSpinner>
              )}
            </>
          ) : (
            <div className="d-sm-flex flex-row justify-content-center ">
              <div
                role="none"
                className={clsx(styles.wpo365mssigninbutton)}
                onClick={() => {
                  if (!authenticatedUser?.name) handleMsteamAuth();
                }}
                style={{
                  cursor: authenticatedUser?.name ? 'default' : 'pointer',
                }}
              >
                <div className={clsx(styles.wpo365mssigninlogo)}>
                  <img
                    src={microsoft}
                    alt="Micrososft accout"
                    width="21"
                    height="21"
                  />
                </div>
                <div className={clsx(styles.wpo365mssigninlabel)}>
                  {authenticatedUser?.name
                    ? `${t('apps.msTeams.msTeamsauthorized')} ${
                        authenticatedUser?.name
                      } `
                    : `${t('apps.msTeams.loginWith')}`}
                </div>
              </div>
            </div>
          )}
        </WithSpinner>
      </form>
    </>
  );
};
