import { TFunction } from 'i18next';
import validation from '../utils/validation';

export const createTopicValidation = (t: TFunction) => ({
  text: validation.string().required(t('library.indigo.validation.text')),
  sku: validation.string().required(t('library.indigo.validation.sku')),
  market: validation.string().required(t('library.indigo.validation.market')),
  industryIds: validation
    .array()
    .min(1, t('library.indigo.validation.industries'))
    .required(t('library.indigo.validation.industries')),
});

export const createTopicValidationFree = (t: TFunction) => ({
  text: validation.string().required(t('library.indigo.validation.text')),
  sku: validation.string().required(t('library.indigo.validation.sku')),
});
