/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTable } from 'react-table';

import { FileSchema, PaginatedFiles } from '../../store/types/fileManager';

import Table from '../../components/Table';
import WithSpinner from '../../components/WithSpinner';

import useColumns from './useColumns';
import useData from './useData';
import EmptyFiles from './EmptyFiles';
import ConversionJob from '../../interfaces/conversionJob.interface';
import LibraryConversionJobRow from '../../components/FileManager/LibraryConversionJobRow';

export interface TableViewProps {
  isLoading: boolean;
  paginatedFiles: PaginatedFiles;
  clickOnFile: (file: FileSchema) => void;
  openRenameFileModal: (file: FileSchema) => void;
  openMoveToFolderModal: (file: FileSchema) => void;
  openDeleteFileModal: (file: FileSchema) => void;
  openActionsDropdown: (file: FileSchema) => void;
  openScheduleFileModal: (file: FileSchema) => void;
  selectedFile: FileSchema | null;
  conversionJobs?: ConversionJob[];
}

const TableView = ({
  isLoading = false,
  paginatedFiles,
  clickOnFile,
  openRenameFileModal,
  openMoveToFolderModal,
  openDeleteFileModal,
  openActionsDropdown,
  openScheduleFileModal,
  selectedFile,
  conversionJobs,
}: TableViewProps) => {
  const data = useData(paginatedFiles.items);
  const columns = useColumns({
    clickOnFile,
    openRenameFileModal,
    openDeleteFileModal,
    openMoveToFolderModal,
    openActionsDropdown,
    openScheduleFileModal,
    selectedFile,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <Table {...getTableProps()}>
      <Table.Head>
        {headerGroups.map((headerGroup) => (
          <Table.Head.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <Table.Head.Row.Cell
                isTextEnd={index === headerGroup.headers.length - 1}
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </Table.Head.Row.Cell>
            ))}
          </Table.Head.Row>
        ))}
      </Table.Head>
      <Table.Body
        {...getTableBodyProps()}
        isEmpty={!isLoading && paginatedFiles.items.length === 0}
        emptyStateHeight={500}
        emptyStateChildren={<EmptyFiles />}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: '500px' }}
          isForTable
        >
          {rows.map((row) => {
            prepareRow(row);

            return (
              <Table.Body.Row {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <Table.Body.Row.Cell
                      isTextEnd={index === row.cells.length - 1}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </Table.Body.Row.Cell>
                  );
                })}
              </Table.Body.Row>
            );
          })}
          {conversionJobs?.length
            ? conversionJobs.map((conversionJob) => (
                <LibraryConversionJobRow
                  conversionJob={conversionJob}
                  config={{ isSelectable: false }}
                />
              ))
            : null}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};

export default TableView;
