import axios from 'axios';
import { API_URL } from '../../../../constants/api';

export default {
  /**
   * Validate Nonce
   */
  validateNonce: async (nonce: string | null) => {
    if (!nonce) {
      throw new Error('Nonce is required to validate');
    }
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/canva/validate-nonce/?nonce=${nonce}`,
      withCredentials: true,
    });
    return data;
  },
};
