/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../components/Tooltip';
import App from '../../../interfaces/app.interface';
import PreviewFeedModalFacebook from '../../../components/Apps/Facebook/PreviewFeedModal';
// eslint-disable-next-line import/no-duplicates
import PreviewFeedModalInstagram from '../../../components/Apps/InstagramApp/PreviewFeedModal';
// eslint-disable-next-line import/no-duplicates
import PreviewFeedModalTwitter from '../../../components/Apps/TwitterApp/PreviewFeedModal';
import PreviewFeedModalCountUp from '../../../components/Apps/CountUpTimer/PreviewFeedModal';
import PreviewFeedModalCountDown from '../../../components/Apps/CountDownTimer/PreviewFeedModal';
import PreviewFeedModalDaysSafe from '../../../components/Apps/DaysSafe/PreviewFeedModal';
import PreviewFeedModalMsTeams from '../../../components/Apps/MSTeams/PreviewFeedModal';
import { AppDependency } from '../../../store/types/apps/globalTypes';
import PlacementsEnum from '../../../enums/placements.enum';

const Preview = ({
  children,
  id,
  facebook,
  instagram,
  twitter,
  msTeams,
  countDownApp,
  countUpApp,
  daysSafeApp,
}: {
  children?: React.ReactNode;
  id?: string;
  facebook?: App;
  instagram?: App;
  twitter?: App;
  msTeams?: App;
  countUpApp?: App;
  countDownApp?: App;
  daysSafeApp?: App;
}) => {
  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const open = () => setModalShown(true);
  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.fullscreen')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-eye" />
            </span>
          </Tooltip>
        )}
      </span>

      {facebook && (
        <PreviewFeedModalFacebook
          show={modalShown}
          onHide={() => {
            setModalShown(false);
          }}
          title={t('common.preview')}
          selectedFacebookFeedSchema={{
            type: 'Facebook',
            createdAt: facebook!.createdAt,
            updatedAt: facebook!.updatedAt,
            dependency: facebook!.dependency as AppDependency,
            id: facebook!.id,
            name: facebook!.name,
            placement: facebook!.placement as PlacementsEnum,
          }}
        />
      )}

      {instagram && (
        <PreviewFeedModalInstagram
          show={modalShown}
          onHide={() => {
            setModalShown(false);
          }}
          title={t('common.preview')}
          selectedInstagramFeedSchema={{
            type: 'Instagram',
            createdAt: instagram!.createdAt,
            updatedAt: instagram!.updatedAt,
            dependency: instagram!.dependency as AppDependency,
            id: instagram!.id,
            name: instagram!.name,
            placement: instagram!.placement as PlacementsEnum,
          }}
        />
      )}

      {twitter && (
        <PreviewFeedModalTwitter
          show={modalShown}
          onHide={() => {
            setModalShown(false);
          }}
          title={t('common.preview')}
          selectedTwitterFeedSchema={{
            type: 'Twitter',
            createdAt: twitter!.createdAt,
            updatedAt: twitter!.updatedAt,
            dependency: twitter!.dependency as AppDependency,
            id: twitter!.id,
            name: twitter!.name,
            placement: twitter!.placement as PlacementsEnum,
          }}
        />
      )}

      {countUpApp && (
        <PreviewFeedModalCountUp
          show={modalShown}
          onHide={() => {
            setModalShown(false);
          }}
          title={t('common.preview')}
          selectedCountTimerSchema={{
            type: 'count-up',
            createdAt: countUpApp!.createdAt,
            updatedAt: countUpApp!.updatedAt,
            dependency: countUpApp!.dependency as AppDependency,
            id: countUpApp!.id,
            name: countUpApp!.name,
            placement: countUpApp!.placement as PlacementsEnum,
          }}
        />
      )}

      {countDownApp && (
        <PreviewFeedModalCountDown
          show={modalShown}
          onHide={() => {
            setModalShown(false);
          }}
          title={t('common.preview')}
          selectedCountTimerSchema={{
            type: 'count-down',
            createdAt: countDownApp!.createdAt,
            updatedAt: countDownApp!.updatedAt,
            dependency: countDownApp!.dependency as AppDependency,
            id: countDownApp!.id,
            name: countDownApp!.name,
            placement: countDownApp!.placement as PlacementsEnum,
          }}
        />
      )}

      {daysSafeApp && (
        <PreviewFeedModalDaysSafe
          show={modalShown}
          onHide={() => {
            setModalShown(false);
          }}
          title={t('common.preview')}
          selectedDaysSafeSchema={{
            type: 'days-safe',
            createdAt: daysSafeApp!.createdAt,
            updatedAt: daysSafeApp!.updatedAt,
            dependency: daysSafeApp!.dependency as AppDependency,
            id: daysSafeApp!.id,
            name: daysSafeApp!.name,
            placement: daysSafeApp!.placement as PlacementsEnum,
          }}
        />
      )}

      {msTeams && (
        <PreviewFeedModalMsTeams
          show={modalShown}
          onHide={() => {
            setModalShown(false);
          }}
          title={t('common.preview')}
          selectedMsteamsFeedSchema={{
            type: 'msTeams',
            createdAt: msTeams!.createdAt,
            updatedAt: msTeams!.updatedAt,
            dependency: msTeams!.dependency as AppDependency,
            id: msTeams!.id,
            name: msTeams!.name,
            placement: msTeams!.placement as PlacementsEnum,
          }}
        />
      )}
    </>
  );
};
export default Preview;
