import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SortOrder from '../../../../enums/sortOrder.enum';
import UsersSortField from '../../../../api/endpoints/users/enums/usersSortField.enum';
import CreateUserRequestData from '../../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import { readFromQueryString } from '../../../../utils/queryParams';
import Columns, { TableHeaderColumn } from '../../../Table/Columns';
import User from '../../../../interfaces/user/user.interface';
import WithSpinner from '../../../WithSpinner';
import Table from '../../../Table';
import TableRow from './TableRow';

interface TableViewProps {
  users: User[];
  currentUser: User;
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight?: number;
  onEditUser?: (userId: string, data: Partial<CreateUserRequestData>) => void;
  onRestoreUser?: (userId: string) => void;
  onSuspendUser?: (userId: string) => void;
  onDeleteUser?: (userId: string) => void;
}

export default ({
  users,
  isLoading,
  sortQueryField,
  orderQueryField,
  preloaderHeight = 300,
  onEditUser,
  onRestoreUser,
  onSuspendUser,
  onDeleteUser,
  currentUser,
}: TableViewProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  const columns: TableHeaderColumn<UsersSortField>[] = [
    {
      name: t('users.table.username'),
      sortBy: UsersSortField.FirstName,
    },
    { name: t('users.common.company') },
    { name: t('users.common.role') },
    {
      name: t('users.table.status'),
      sortBy: UsersSortField.Status,
    },
    { name: t('users.table.devices_number') },
    { name: t('users.table.group_access') },
    { name: t('users.table.location') },
    { name: t('common.actions') },
  ];

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns<UsersSortField>
            columns={columns}
            config={{
              isSelectable: false,
              sortField,
              setSortField,
              sortQueryField,
              orderQueryField,
            }}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={!isLoading && !users.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={t('users.table.no_one_user_found')}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {users.map((user) => (
            <TableRow
              currentUser={currentUser}
              key={user.id}
              user={user}
              onEditUser={onEditUser}
              onRestoreUser={onRestoreUser}
              onSuspendUser={onSuspendUser}
              onDeleteUser={onDeleteUser}
            />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
