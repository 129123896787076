import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import * as FullStory from '@fullstory/browser';
import history from '../constants/history';
import Routes from '../routing/Routes';

const HubspotJS = () => {
  //  HubSpot Embed Code
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.src = '//js.hs-scripts.com/21304428.js';
  scriptTag.defer = true;
  scriptTag.async = true;
  scriptTag.id = 'hs-script-loader';
  document.body.appendChild(scriptTag);
};

const tagManager = () => {
  //  Google Tag Manager Embed Code
  if (process.env.REACT_APP_GTM_ID) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };
    TagManager.initialize(tagManagerArgs);
  }
};

const fullStoryInit = () => {
  //  FullStory Embed Code
  if (process.env.REACT_APP_FULLSTORY_ID) {
    FullStory.init({
      orgId: process.env.REACT_APP_FULLSTORY_ID,
    });
  }
};

const attachSomeAnalyticsJS = () => {
  // Prod only
  if (process.env.REACT_APP_ENV === 'prod') {
    HubspotJS();
    tagManager();
    fullStoryInit();
  }
};

export default () => {
  useEffect(() => {
    attachSomeAnalyticsJS();
  }, []);

  return (
    <>
      <Router history={history}>
        <Routes />
      </Router>
    </>
  );
};
