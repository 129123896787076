import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

export default eventEmitter;

/**
 * Adds multiple event listeners to the EventEmitter
 * @param events
 * @param handler
 * @param context
 * @param once
 */
export const onEvents = <T extends EventEmitter.EventNames<string | symbol>>(
  events: T[],
  handler: EventEmitter.EventListener<string | symbol, T>,
  context?: any,
  once?: boolean,
) => {
  events.forEach((event) =>
    eventEmitter[once ? 'once' : 'on'](event, handler, context),
  );
};

/**
 * Removes multiple EventEmitter's event listeners
 * @param events
 * @param handler
 */
export const offEvents = <T extends EventEmitter.EventNames<string | symbol>>(
  events: T[],
  handler: EventEmitter.EventListener<string | symbol, T>,
) => {
  events.forEach((event) => eventEmitter.off(event, handler));
};
