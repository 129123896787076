import React, { useState } from 'react';
import Device from '../../../interfaces/devices/device.interface';
import UpdateDeviceLicenseRequestData from '../../../api/endpoints/licenses/interfaces/updateDeviceLicenseRequestData.interface';
import LicensingModal from '../../../components/Devices/LicensingModal';
import api from '../../../api';

export default ({
  device,
  children,
  onSuccess,
}: {
  children?: React.ReactNode;
  device: Device;
  onSuccess?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  const onSubmitData = async (data: UpdateDeviceLicenseRequestData) => {
    setIsLoading(true);

    try {
      await api.licenses.updateDeviceLicense(device.id, data, device?.name);

      close();

      if (onSuccess) onSuccess();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-user-plus" />
          </span>
        )}
      </span>
      <LicensingModal
        device={device}
        isLoading={isLoading}
        isModalVisible={isModalVisible}
        onSubmit={onSubmitData}
        onHide={close}
        license={device.license}
      />
    </>
  );
};
