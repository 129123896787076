/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Option } from 'react-multi-select-component';
import { v4 as uuidv4 } from 'uuid';
import App from '../../../../interfaces/app.interface';
import { CreateMsTeamsFeedRequest } from '../../../../store/types/apps/msTeamsFeed';
import microsoft from '../../../../assets/images/auth/microsoft-96.svg';
import msTeamsLogo from '../../../../assets/images/apps/msTeams.png';
import styles from './MSTeams.module.scss';
import Theme from '../../../../enums/theme.enum';
import { feedTypesTranslation } from '../../../../constants/translation/facebookFeedType';
import Select from '../../../../containers/Apps/MsTeams/SelectFeedType';
import { MsTeamsApis } from '../../../../api/endpoints/apps/Microsoft';
import Modal from '../../../Modal';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import Yup from '../../../../utils/validation';
import Orientation from '../../../../enums/orientation.enum';
import WithSpinner from '../../../WithSpinner';
import FormErrorMessage from '../../../FormErrorMessage';
import MsTeamsPreview from '../../../../enums/msTeams.enum';
import SwitchCheckbox from '../../../SwitchCheckbox';

const scopes = [
  'Channel.ReadBasic.All',
  'ChannelMessage.Read.All',
  'email',
  'Files.Read.All',
  'openid',
  'profile',
  'Team.ReadBasic.All',
  'User.Read',
  'offline_access',
];

const expiredValues: Option[] = [
  {
    value: 0,
    label: 'No Limit',
  },
  {
    value: 1,
    label: '1 Day',
  },
  {
    value: 7,
    label: '1 Week',
  },
  { value: 30, label: '1 Month' },
  { value: 180, label: '6 Months' },
  { value: 365, label: '1 Year' },
];

type CreateMicrosoftTeamsRequestFormMutated = Omit<
  CreateMsTeamsFeedRequest,
  'config' | 'placement'
>;

interface CreateMicrosoftTeamsRequestFormPayload
  extends CreateMicrosoftTeamsRequestFormMutated {
  theme: Theme;
  Accountname: string;
  channelName: string;
  teamName: string;
  msTeamsPreview: string;
  displayNumber: number;
  removeOldPosts: number;
  displayTimePerPost: number;
  showAuthor: boolean;
  displayPostDate: boolean;
  corpImagetoFit: boolean;
  muteVideo: boolean;
  appGuiid: string;
  // muteVideo: boolean;
  // playFulllength: boolean;
}

interface TeamsInterface {
  id: string;
  name: string;
}

interface ChannelInterface {
  id: string;
  name: string;
}

export interface CreateFeedModalProps {
  onSubmit: (data: CreateMsTeamsFeedRequest) => void;
  msTeam?: App;
  onClose?: () => void;
  update?: boolean;
  loading?: boolean;
  code?: string;
}

export default ({
  onSubmit,
  onClose,
  update,
  loading,
  code,
  msTeam = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();

  const initialValues: CreateMicrosoftTeamsRequestFormPayload = {
    name: msTeam?.name ?? '',
    msTeamsPreview:
      msTeam?.dependency?.config?.msTeamsPreview ?? `${MsTeamsPreview.Single}`,
    theme: msTeam?.dependency?.config?.Theme ?? '',
    Accountname: msTeam?.dependency?.config?.Accountname ?? '',
    channelName: msTeam?.dependency?.config?.channelName ?? '',
    teamName: msTeam?.dependency?.config?.teamName ?? '',
    displayNumber: msTeam?.dependency?.config?.displayNumber ?? 30,
    corpImagetoFit: msTeam?.dependency?.config?.corpImagetoFit ?? false,
    displayPostDate: msTeam?.dependency?.config?.displayPostDate ?? true,
    removeOldPosts: msTeam?.dependency?.config?.removeOldPosts ?? 0,
    displayTimePerPost: msTeam?.dependency?.config?.displayTimePerPost ?? 5,
    showAuthor: msTeam?.dependency?.config?.showAuthor ?? true,
    muteVideo: msTeam?.dependency?.config?.muteVideo ?? false,
    appGuiid: msTeam?.dependency?.config?.appGuiid ?? uuidv4(),
  };

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isloadingLogin, setIsloadingLogin] = useState<boolean>(false);
  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [authenticatedUser, setAuthenticatedUser] = useState<any>();
  const [teams, setTeams] = useState<Array<TeamsInterface>>([]);
  const msTeamsPreviewValue: MsTeamsPreview =
    initialValues.msTeamsPreview as MsTeamsPreview;
  const [teamsLoading, setTeamsLoading] = useState<boolean>(true);
  const [ChannelsLoading, setChannelsLoading] = useState<boolean>(false);
  const [teamsDisabled, setTeamsDisabled] = useState<boolean>(true);
  const [channelsDisabled, setChannelsDisabled] = useState<boolean>(true);
  const [channels, setChannels] = useState<Array<ChannelInterface>>([]);

  const { appGuiid } = initialValues;

  const feedThemeValue: Theme = initialValues.theme as Theme;
  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t('apps.msTeams.required'))
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    theme: Yup.string().required(t('apps.msTeams.required')).defined(),
    channelName: Yup.string().required(t('apps.msTeams.required')).defined(),
    teamName: Yup.string().required(t('apps.msTeams.required')).defined(),
    displayNumber: Yup.string()
      .max(30, t('apps.msTeams.maximumPosts'))
      .defined(),
    msTeamsPreview: Yup.string().required(t('apps.msTeams.required')).defined(),
  }).defined();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateMicrosoftTeamsRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const [displayNumber, setDisplayNumber] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    number
  >({ setValue, trigger, name: 'displayNumber' }, initialValues.displayNumber);

  const [Accountname, setAccountname] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    string
  >({ setValue, trigger, name: 'Accountname' }, initialValues.Accountname);

  const [msTeamsTheme, setMsTeamsTeams] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    Theme
  >({ setValue, trigger, name: 'theme' }, feedThemeValue);

  const [selectedTeam, setSelectedTeam] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    string
  >({ setValue, trigger, name: 'teamName' }, initialValues.teamName);
  const [selectedChannel, setSelectedChannel] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    string
  >({ setValue, trigger, name: 'channelName' }, initialValues.channelName);

  const [msTeamsType, setMsTeamsType] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    MsTeamsPreview
  >({ setValue, trigger, name: 'msTeamsPreview' }, msTeamsPreviewValue);

  const [corpImagetoFit, SetCorpImagetoFit] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    boolean
  >(
    { setValue, trigger, name: 'corpImagetoFit' },
    initialValues.corpImagetoFit,
  );

  const [displayPostDate, setDisplayPostDate] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    boolean
  >(
    { setValue, trigger, name: 'displayPostDate' },
    initialValues.displayPostDate,
  );

  const [removeOldPosts, setRemoveOldPosts] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    number
  >(
    { setValue, trigger, name: 'removeOldPosts' },
    initialValues.removeOldPosts,
  );

  const [displayTimePerPost, setDisplayTimePerPost] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    number
  >(
    { setValue, trigger, name: 'displayTimePerPost' },
    initialValues.displayTimePerPost,
  );

  const [showAuthor, setShowAuthor] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    boolean
  >({ setValue, trigger, name: 'showAuthor' }, initialValues.showAuthor);

  const [muteVideo, setMuteVideo] = useStateWithHookForm<
    CreateMicrosoftTeamsRequestFormPayload,
    boolean
  >({ setValue, trigger, name: 'muteVideo' }, initialValues.muteVideo);

  const handleMsTeamsTeams = (value: Theme) => {
    setMsTeamsTeams(value);
  };

  const handleDispayPerPost = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setDisplayTimePerPost(parseFloat(value)),
    [displayTimePerPost],
  );

  const handleFeedNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFeedName(value),
    [setFeedName],
  );

  const handleDisplayNumberChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setDisplayNumber(parseFloat(value)),
    [displayNumber],
  );

  const handleMsteamsPreview = (value: MsTeamsPreview) => {
    setMsTeamsType(value);
  };

  const getTokenAndProfileData = async () => {
    try {
      const res = await MsTeamsApis.MsTeamsGetToken(code);
      const userProfile = await MsTeamsApis.MsTeamsGetProfile(
        res?.data?.access_token,
      );

      setAuthenticatedUser({
        id: userProfile?.data?.id,
        name: userProfile?.data?.displayName,
        mail: userProfile?.data?.mail,
        accessToken: res?.data?.access_token,
        refreshToken: res?.data?.refresh_token,
        expiresIn: res?.data.expires_in,
      });
      setAccountname(userProfile?.data?.displayName);
      setIsloadingLogin(false);
    } catch (e) {
      setIsloadingLogin(false);
      console.log(e);

      toast.error(`Error During login please try it later`);
    }
  };

  const handleMsteamAuth = async () => {
    try {
      sessionStorage.setItem('relogin', 'false');
      window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
        process.env.REACT_APP_MS_TEAMS_CLIENT_ID
      }&response_type=code&redirect_uri=${
        process.env.REACT_APP_MS_TEAMS_REDIRECT_URI
      }&response_mode=query&scope=${scopes.join('%20')}`;
    } catch (e) {
      console.log(e);
    }
  };

  const apiGetTeams = async () => {
    if (authenticatedUser?.accessToken) {
      try {
        const getTeams = await MsTeamsApis.getTeams(
          authenticatedUser.accessToken,
        );
        if (getTeams?.status === 200) {
          if (getTeams?.data?.teams?.value) {
            getTeams?.data?.teams?.value.map(
              (item: { id: any; displayName: any }) => {
                return setTeams((prevTeams) => [
                  ...prevTeams,
                  {
                    id: item?.id,
                    name: item?.displayName,
                  },
                ]);
              },
            );
            setTeamsLoading(false);
            setTeamsDisabled(false);
          }
        }
      } catch (err) {
        console.log(err);
        setTeamsLoading(false);
      }
    }
  };

  const apiGetChannels = async () => {
    if (authenticatedUser?.accessToken && selectedTeam) {
      try {
        setChannels([]);
        // setPagesLoading(true);
        const getChannels = await MsTeamsApis.getChannels(
          authenticatedUser.accessToken,
          teams.length === 1 ? teams[0].id : selectedTeam,
        );

        if (getChannels?.status === 200) {
          if (getChannels?.data?.channel?.value) {
            getChannels?.data?.channel?.value.map(
              (item: { id: any; displayName: any }) => {
                return setChannels((prev) => [
                  ...prev,
                  {
                    id: item?.id,
                    name: item?.displayName,
                  },
                ]);
              },
            );

            setChannelsLoading(false);
            setChannelsDisabled(false);
          }
        }
      } catch (err) {
        console.log(err);
        setChannelsLoading(false);
      }
    }
  };

  const handleOnSubmit = async (
    data: CreateMicrosoftTeamsRequestFormPayload,
  ) => {
    try {
      setLoading(true);
      if (!update) {
        const getFeeds = await MsTeamsApis.getMessages(
          authenticatedUser?.accessToken,
          selectedTeam,
          selectedChannel,
          displayNumber,
          removeOldPosts,
          appGuiid,
        );
        if (getFeeds.status === 200) {
          const { name } = data;
          const createNewMsTeamsFeedRequest: CreateMsTeamsFeedRequest = {
            name,
            isScheduled,
            config: {
              msTeamsPreview: msTeamsType,
              Theme: msTeamsTheme,
              Accountname,
              AccessToken: authenticatedUser?.accessToken ?? '',
              RefreshToken: authenticatedUser?.refreshToken,
              channelName: channels.filter(
                (item) => item.id === selectedChannel,
              )?.[0]?.name,
              teamName: teams.filter((item) => item.id === selectedTeam)?.[0]
                ?.name,
              teamId: selectedTeam,
              channelId: selectedChannel,
              msTeamsFeeds: getFeeds?.data?.messages?.value,
              displayNumber,
              msUserId: authenticatedUser?.id,
              expiresIn: authenticatedUser?.expiresIn,
              mail: authenticatedUser?.mail,
              corpImagetoFit,
              displayPostDate,
              removeOldPosts,
              displayTimePerPost,
              showAuthor,
              appGuiid,
              muteVideo,
            },
            zoneId: Orientation.Landscape,
          };
          onSubmit(createNewMsTeamsFeedRequest);
        } else {
          toast.error('Something went wrong');
          setLoading(false);
        }
      } else {
        const { name } = data;

        const createNewMsTeamsFeedRequest: CreateMsTeamsFeedRequest = {
          name,
          isScheduled,
          config: {
            msTeamsPreview: msTeamsType,
            Theme: msTeamsTheme,
            Accountname,
            AccessToken: msTeam?.dependency?.config?.AccessToken,
            RefreshToken: msTeam?.dependency?.config?.RefreshToken,
            channelName: msTeam?.dependency?.config?.channelName,
            teamName: msTeam?.dependency?.config?.teamName,
            msTeamsFeeds: msTeam?.dependency?.config?.msTeamsFeeds,
            displayNumber,
            teamId: msTeam?.dependency?.config?.teamId,
            channelId: msTeam?.dependency?.config?.channelId,
            msUserId: msTeam?.dependency?.config?.msUserId,
            expiresIn: msTeam?.dependency?.config?.expiresIn,
            mail: msTeam?.dependency?.config?.mail,
            corpImagetoFit,
            displayPostDate,
            removeOldPosts,
            displayTimePerPost,
            showAuthor,
            muteVideo,
            appGuiid,
          },
          zoneId: Orientation.Landscape,
        };
        onSubmit(createNewMsTeamsFeedRequest);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      toast.error('Something went wrong');
    }
  };

  React.useEffect(() => {
    apiGetTeams();
  }, [authenticatedUser]);

  React.useEffect(() => {
    apiGetChannels();
  }, [selectedTeam]);

  React.useEffect(() => {
    if (teams.length === 1) apiGetChannels();
  }, [teams]);

  React.useEffect(() => {
    if (loading) setLoading(loading);
    else setLoading(false);
  }, [loading]);

  React.useEffect(() => {
    if (msTeam) {
      setChannelsLoading(false);
      setTeamsLoading(false);
    }
    if (code) {
      getTokenAndProfileData();
      setIsloadingLogin(true);
    }
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        id="form"
        className="d-sm-flex flex-column justify-content-center"
      >
        <img
          src={msTeamsLogo}
          alt="teamss"
          className={clsx('mb-4', styles.msTeamsImage)}
        />
        {!msTeam?.dependency?.config?.Accountname && (
          <h1 className="text-center text-dark mb-4">
            {t('apps.msTeams.title')}
          </h1>
        )}
        <div className="d-sm-flex flex-row justify-content-center ">
          <WithSpinner
            isLoading={isloadingLogin}
            style={{ minHeight: `300px` }}
            // isForTable
            size="md"
          >
            {msTeam?.dependency?.config?.Accountname ? (
              <div
                className={clsx(styles.wpo365mssigninbutton)}
                style={{
                  cursor: 'default',
                }}
              >
                <div className={clsx(styles.wpo365mssigninlogo)}>
                  <img
                    src={microsoft}
                    alt="Micrososft accout"
                    width="21"
                    height="21"
                  />
                </div>
                <div className={clsx(styles.wpo365mssigninlabel)}>
                  {`${t('apps.msTeams.msTeamsauthorized')} ${
                    msTeam?.dependency?.config?.Accountname
                  } `}
                </div>
              </div>
            ) : (
              <div
                role="none"
                className={clsx(styles.wpo365mssigninbutton)}
                onClick={() => {
                  if (!authenticatedUser?.name) handleMsteamAuth();
                }}
                style={{
                  cursor: authenticatedUser?.name ? 'default' : 'pointer',
                }}
              >
                <div className={clsx(styles.wpo365mssigninlogo)}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                  >
                    <rect x="1" y="1" width="9" height="9" fill="#f25022" />
                    <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
                    <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
                    <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
                  </svg> */}

                  <img
                    src={microsoft}
                    alt="Micrososft accout"
                    width="21"
                    height="21"
                  />
                </div>
                <div className={clsx(styles.wpo365mssigninlabel)}>
                  {authenticatedUser?.name
                    ? `${t('apps.msTeams.msTeamsauthorized')} ${
                        authenticatedUser?.name
                      } `
                    : `${t('apps.msTeams.loginWith')}`}
                </div>
              </div>
            )}
          </WithSpinner>
        </div>
        {(authenticatedUser || msTeam?.dependency?.config?.Accountname) && (
          <>
            <div className="d-flex flex-row p-4 w-100">
              <div className="d-flex flex-column mx-7 w-100">
                <p className="mt-2 mb-0">
                  <label
                    htmlFor="name"
                    className="required text-dark fw-bolder my-1"
                  >
                    {`${t('apps.msTeams.table.title')}`}
                  </label>
                  <input
                    name="name"
                    id="name"
                    value={feedName}
                    onChange={handleFeedNameChange}
                    className="form-control form-control-solid"
                    placeholder={t('apps.facebook.type_Here')}
                    type="text"
                    style={{ lineHeight: '1.25' }}
                  />
                  <FormErrorMessage
                    name="name"
                    errors={errors}
                    className="my-1 px-2"
                  />
                </p>

                <p className="mt-2 mb-0">
                  <label
                    htmlFor="msTeams"
                    className="required text-dark fw-bolder my-1"
                  >
                    {t('apps.msTeams.teams')}{' '}
                  </label>

                  <Select
                    isloading={teamsLoading}
                    defaultValue={{
                      value: selectedTeam,
                      label: selectedTeam,
                    }}
                    isDisabled={update || teamsDisabled}
                    id="teams"
                    onSelect={(selectTeam) => {
                      setSelectedTeam(selectTeam as string);
                      setChannelsLoading(true);
                      setChannels([]);
                    }}
                    options={Object.values(teams).map((team) => ({
                      label: _.capitalize(team?.name),
                      value: team?.id,
                    }))}
                  />

                  <FormErrorMessage
                    name="teamName"
                    errors={errors}
                    className="my-1 px-2"
                  />
                </p>

                <p className="mt-2 mb-0">
                  {' '}
                  <label
                    htmlFor="channels"
                    className="required text-dark fw-bolder my-1"
                  >
                    {t('apps.msTeams.selectChannel')}
                  </label>
                  <Select
                    id="channels"
                    defaultValue={{
                      value: selectedChannel,
                      label: selectedChannel,
                    }}
                    isloading={ChannelsLoading}
                    isDisabled={update || channelsDisabled}
                    onSelect={(channel) =>
                      setSelectedChannel(channel as string)
                    }
                    options={Object.values(channels).map((channel) => ({
                      label: _.capitalize(channel.name),
                      value: channel.id,
                    }))}
                    // placeholder={msTeam ? selectedChannel : ''}
                    // observeDefaultValueChange
                  />
                  <FormErrorMessage
                    name="channelName"
                    errors={errors}
                    className="my-1 px-2"
                  />{' '}
                </p>
              </div>

              <div className="d-flex flex-column mx-2 w-100">
                {/* <p className="mt-2 mb-0">
                  {' '}
                  <label
                    htmlFor="MsTeamsPreview"
                    className="required text-dark fw-bolder my-1"
                  >
                    {t('apps.msTeams.viewMode')}
                  </label>
                  <Select<MsTeamsPreview>
                    id="teamsPreview"
                    placeholder={t('my_account.left_table.lang')}
                    isDisabled
                    defaultValue={{
                      value: msTeamsType,
                      label: _.capitalize(t(feedTypesTranslation[msTeamsType])),
                    }}
                    onSelect={(selectedMsTypePreviewType) =>
                      handleMsteamsPreview(
                        selectedMsTypePreviewType as MsTeamsPreview,
                      )
                    }
                    options={Object.values(MsTeamsPreview).map((item) => ({
                      label: _.capitalize(t(feedTypesTranslation[item])),
                      value: item,
                    }))}
                    observeDefaultValueChange
                  />
                  <FormErrorMessage
                    name="msTeamsPreview"
                    errors={errors}
                    className="my-1 px-2"
                  />
                </p> */}

                <p className="mt-2 mb-0">
                  {' '}
                  <label
                    htmlFor="theme"
                    className="required text-dark fw-bolder my-1"
                  >
                    {t('apps.facebook.theme')}
                  </label>
                  <Select<Theme>
                    id="theme"
                    defaultValue={{
                      value: msTeamsTheme,
                      label: _.capitalize(
                        t(feedTypesTranslation[msTeamsTheme]),
                      ),
                    }}
                    onSelect={(selectedMsTeamsTheme) =>
                      handleMsTeamsTeams(selectedMsTeamsTheme as Theme)
                    }
                    options={Object.values(Theme).map((languageVal) => ({
                      label: _.capitalize(t(feedTypesTranslation[languageVal])),
                      value: languageVal,
                    }))}
                    observeDefaultValueChange
                  />
                  <FormErrorMessage
                    name="theme"
                    errors={errors}
                    className="my-1 px-2"
                  />
                </p>
                <p className="mt-2 mb-0">
                  {' '}
                  <label
                    htmlFor="MsTeamsPreview"
                    className=" text-dark fw-bolder my-1"
                  >
                    {t('apps.msTeams.removeOldPosts')}
                  </label>
                  <Select
                    id="expiredValues"
                    placeholder={t('my_account.left_table.lang')}
                    defaultValue={{
                      value: String(removeOldPosts),
                      label: expiredValues.filter(
                        (item) => item.value === removeOldPosts,
                      )[0].label,
                    }}
                    onSelect={(selectExpired) => {
                      setRemoveOldPosts(Number(selectExpired));
                    }}
                    options={Object.values(expiredValues).map((item) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                    observeDefaultValueChange
                  />
                </p>
                <div className="row">
                  <div className="col col-sm-6">
                    <p className="mt-2 mb-0">
                      {' '}
                      <label
                        htmlFor="name"
                        className=" text-dark fw-bolder my-1"
                      >
                        {`${t('apps.msTeams.numberOfdisplay')}`}
                      </label>
                      <input
                        name="number"
                        id="number"
                        value={displayNumber}
                        onChange={handleDisplayNumberChange}
                        className="form-control form-control-solid"
                        placeholder={t('apps.facebook.type_Here')}
                        type="number"
                        max={30}
                        min={1}
                        style={{ lineHeight: '1.25' }}
                      />
                      <FormErrorMessage
                        name="displayNumber"
                        errors={errors}
                        className="my-1 px-2"
                      />
                    </p>
                  </div>

                  <div className="col col-sm-6">
                    <p className="mt-2 mb-0">
                      {' '}
                      <label
                        htmlFor="name"
                        className="text-dark fw-bolder my-1"
                      >
                        {`${t('apps.msTeams.timePerPost')}`}
                      </label>
                      <input
                        name="number"
                        id="number"
                        value={displayTimePerPost}
                        onChange={handleDispayPerPost}
                        className="form-control form-control-solid"
                        placeholder={t('apps.facebook.type_Here')}
                        type="number"
                        min={5}
                        style={{ lineHeight: '1.25' }}
                      />
                      <FormErrorMessage
                        name="displayNumber"
                        errors={errors}
                        className="my-1 px-2"
                      />
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-sm-6 ">
                    {' '}
                    <p className="mt-2 mb-0 d-inline-flex justify-content-between w-100">
                      <label
                        htmlFor="showAuthor"
                        className=" text-dark fw-bolder my-1"
                      >
                        {t('apps.msTeams.showAuthor')}
                      </label>
                      <SwitchCheckbox
                        name="showAuthor"
                        inputClassName="h-20px w-30px"
                        checked={!!showAuthor}
                        onChange={({ currentTarget: { checked } }) =>
                          setShowAuthor(checked)
                        }
                      />
                    </p>{' '}
                  </div>
                  <div className="col col-sm-6">
                    {' '}
                    <p className="mt-2 mb-0 d-inline-flex justify-content-between w-100">
                      <label
                        htmlFor="displayDate"
                        className=" text-dark fw-bolder my-1"
                      >
                        {t('apps.msTeams.displayPostDate')}
                      </label>
                      <SwitchCheckbox
                        name="displayDate"
                        inputClassName="h-20px w-30px"
                        checked={!!displayPostDate}
                        onChange={({ currentTarget: { checked } }) =>
                          setDisplayPostDate(checked)
                        }
                      />
                    </p>{' '}
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col col-sm-6 ">
                    {' '}
                    <p className="mt-2 mb-0 d-inline-flex justify-content-between w-100">
                      <label
                        htmlFor="imageFit"
                        className="text-dark fw-bolder my-1"
                      >
                        {t('apps.msTeams.cropImagesToFit')}
                      </label>
                      <SwitchCheckbox
                        name="imageFit"
                        inputClassName="h-20px w-30px"
                        checked={!!corpImagetoFit}
                        onChange={({ currentTarget: { checked } }) =>
                          SetCorpImagetoFit(checked)
                        }
                      />
                    </p>{' '}
                  </div> */}
                  {/* 
                  <div className="col col-sm-6 ">
                    {' '}
                    <p className="mt-2 mb-0 d-inline-flex justify-content-between w-100">
                      <label
                        htmlFor="imageFit"
                        className="text-dark fw-bolder my-1"
                      >
                        {t('apps.msTeams.muteVideos')}
                      </label>
                      <SwitchCheckbox
                        name="muteVideo"
                        inputClassName="h-20px w-30px"
                        checked={!!muteVideo}
                        onChange={({ currentTarget: { checked } }) =>
                          setMuteVideo(checked)
                        }
                      />
                    </p>{' '}
                  </div> */}
                </div>
              </div>
            </div>

            <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
            <div className="d-sm-flex justify-content-between">
              <button
                type="button"
                className="btn btn-white text-primary"
                onClick={onClose}
              >
                {t('common.cancel')}
              </button>
              <div className="d-sm-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => setIsScheduled(false)}
                  disabled={isLoading}
                >
                  <WithSpinner
                    isLoading={isLoading && !isScheduled}
                    className="min-w-100px"
                    size="md"
                  >
                    {t('common.save')}
                  </WithSpinner>
                </button>
                {!Object.keys(msTeam).length && (
                  <button
                    onClick={() => setIsScheduled(true)}
                    type="submit"
                    className={clsx('btn btn-primary', styles.scheduleBtn)}
                    disabled={isLoading}
                  >
                    <WithSpinner
                      isLoading={isLoading && isScheduled}
                      className="min-w-100px"
                      size="md"
                    >
                      {t('common.save_schedule')}
                    </WithSpinner>
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
};
