import React from 'react';

export default ({
  options,
  name,
  onChange,
  className,
}: {
  options: { label: string; value: string; key: string; checked?: boolean }[];
  name: string;
  onChange: (value: string) => void;
  className?: string;
}) => (
  <div className={className}>
    {options.map(({ label, value, key, checked }) => (
      <div key={key} className="form-check form-check-inline form-check-solid">
        <input
          name={name}
          className="form-check-input"
          value={value}
          type="radio"
          defaultChecked={checked}
          onClick={() => onChange(value)}
        />
        <span className="form-check-label">{label}</span>
      </div>
    ))}
  </div>
);
