import React from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import clsx from 'clsx';
import SelectLanguage from '../../../containers/SelectLanguage';
import LibrariesQueryParam from '../../../api/endpoints/libraries/enums/librariesQueryParam.enum';
import { debouncedUpdateSearch } from '../../../utils/browserHistory';
import SelectIndustries from '../../../containers/SelectIndustries';
import FiltersDropdown from '../../Dropdown/FiltersDropdown';
import useFilter from '../../../utils/hooks/useFilter';
import Language from '../../../enums/language.enum';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import SortOrder from '../../../enums/sortOrder.enum';
import useSyncWithSearch from '../../../utils/hooks/useSyncWithSearch';
import LibrariesSortField from '../../../api/endpoints/libraries/enums/librariesSortField.enum';

export default ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const [industries, setIndustries] = useFilter<string[]>(
    LibrariesQueryParam.Industries,
    [],
    true,
  );

  const [sortOrder, setSortOrder] = useSyncWithSearch<string>(
    SortingQueryParam.SortOrder,
    SortOrder.Ascending,
  );

  const [sortBy, setSortBy] = useSyncWithSearch<string>(
    SortingQueryParam.SortBy,
    LibrariesSortField.Title,
  );

  const isAscendingOrder = sortOrder === SortOrder.Ascending;
  const isSortedByDate = sortBy === LibrariesSortField.Date;
  const isSortedByTitle = sortBy === LibrariesSortField.Title;
  const [language, setLanguage] = useFilter<Language>(
    LibrariesQueryParam.Languages,
  );

  const handleApply = () =>
    debouncedUpdateSearch({
      [LibrariesQueryParam.Industries]: industries ?? [],
      [LibrariesQueryParam.Languages]: language ?? '',
    });

  // Reset filters in the query params
  const handleReset = () => {
    debouncedUpdateSearch({
      [LibrariesQueryParam.Industries]: [],
      [LibrariesQueryParam.Languages]: '',
    });
  };

  const switchSortOrder = () =>
    setSortOrder(isAscendingOrder ? SortOrder.Descending : SortOrder.Ascending);

  return (
    <FiltersDropdown
      className={className}
      onApplyFilters={handleApply}
      onReset={handleReset}
      topicFilter
    >
      <div className="mb-10">
        <label htmlFor="industry" className="form-label fw-bold">
          {t('library.indigo.industry')}:
        </label>
        <SelectIndustries
          initialValue={industries}
          onChange={(values) => setIndustries(map(values, 'value') as string[])}
        />
      </div>
      <div className="mb-10">
        <label htmlFor="language" className="form-label fw-bold">
          {t('library.indigo.language')}:
        </label>
        <SelectLanguage
          id="language"
          placeholder={t('users.common.select_language')}
          language={language}
          setLanguage={setLanguage}
        />
      </div>

      <div>
        {/* <div className="mb-10">
          <button
            type="button"
            className={clsx(
              'btn btn-light-primary me-4 bi bi-arrow-down-up',
              isSortedByTitle && 'active',
            )}
            onClick={() => {
              if (isSortedByTitle) {
                switchSortOrder();
              } else {
                setSortBy(LibrariesSortField.Title);
              }
            }}
          >
            {' '}
            {t(
              isSortedByTitle && isAscendingOrder
                ? 'library.indigo.sort_order.a_z'
                : 'library.indigo.sort_order.z_a',
            )}
          </button>
        </div> */}

        <div className="mb-10">
          <span
            tabIndex={-1}
            className={clsx(
              ' me-4 bi bi-arrow-down-up',
              isSortedByDate && 'active',
            )}
            role="button"
            onClick={() => {
              if (isSortedByTitle) {
                switchSortOrder();
              } else {
                setSortBy(LibrariesSortField.Title);
              }
            }}
            onKeyPress={() => {
              if (isSortedByTitle) {
                switchSortOrder();
              } else {
                setSortBy(LibrariesSortField.Title);
              }
            }}
          >
            {t(
              isSortedByTitle && isAscendingOrder
                ? 'library.indigo.sort_order.a_z'
                : 'library.indigo.sort_order.z_a',
            )}
          </span>{' '}
        </div>

        <div className="mb-10">
          <span
            tabIndex={-1}
            className={clsx(
              ' me-4 bi bi-arrow-down-up',
              isSortedByDate && 'active',
            )}
            role="button"
            onClick={() => {
              if (isSortedByDate) {
                switchSortOrder();
              } else {
                setSortBy(LibrariesSortField.Date);
              }
            }}
            onKeyPress={() => {
              if (isSortedByDate) {
                switchSortOrder();
              } else {
                setSortBy(LibrariesSortField.Date);
              }
            }}
          >
            {t(
              isSortedByDate && isAscendingOrder
                ? 'library.indigo.sort_order.oldest_to_newest'
                : 'library.indigo.sort_order.newest_to_oldest',
            )}
          </span>{' '}
        </div>
      </div>
    </FiltersDropdown>
  );
};
