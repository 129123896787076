import { all } from 'redux-saga/effects';
import userDataWatcher from './userData';
import playlistsWatcher from './playlists';
import contentsWatcher from './contents';
import devicesWatcher from './devices';
import devicesGroupsWatcher from './devicesGroups';
import usersGroupsWatcher from './usersGroups';
import usersWatcher from './users';
import customersWatcher from './customers';
import companiesWatcher from './companies';
import fileManagerWatcher from './fileManager';
import {
  youtubeFeedsWatcher,
  HTMLFeedsWatcher,
  LiveStreamFeedsWatcher,
} from './apps';

export default function* root() {
  yield all([
    usersWatcher(),
    customersWatcher(),
    companiesWatcher(),
    devicesWatcher(),
    devicesGroupsWatcher(),
    usersGroupsWatcher(),
    playlistsWatcher(),
    userDataWatcher(),
    contentsWatcher(),
    fileManagerWatcher(),
    youtubeFeedsWatcher(),
    HTMLFeedsWatcher(),
    LiveStreamFeedsWatcher(),
  ]);
}
