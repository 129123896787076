import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import i18next from 'i18next';
import api from '../../../api';
import { createFormData } from '../../../utils/formData';
import WithSpinner from '../../../components/WithSpinner';
import User from '../../../interfaces/user/user.interface';
import { editValidation } from '../../../validation/users';
import { getUsersList } from '../../../store/actions/users';
import Info from '../../../components/Users/CreateUser/Info';
import UserWithRelationsIdsInterface from '../../../interfaces/user/userWithRelationsIds.interface';
import CreateUserRequestData from '../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import AddUserSteps from '../../../enums/steps/addUserSteps.enum';
import Assign from '../../../components/Users/CreateUser/Assign';
import WithModesModal from '../../../components/WithModesModal';
import useModalState from '../../../utils/hooks/useModalState';
import sizingStyles from '../../../styles/sizing.module.scss';
import Tooltip from '../../../components/Tooltip';

interface StepsData extends Partial<CreateUserRequestData> {
  deviceIds?: string[];
}

const prepareStepsData = (user: UserWithRelationsIdsInterface): StepsData => ({
  ...pick(user, [
    'email',
    'phoneNumber',
    'firstName',
    'lastName',
    'status',
    'language',
    'isTrial',
    'twoFactorEnabled',
    'deviceGroupsIds',
    'userGroupIds',
    'deviceGroupsIds',
    'assignedCompanyIds',
    'assignedLocationIds',
    'userAccessBasedOnCompanyIds',
    'userAccessBasedOnLocationIds',
  ]),
  role: user.roles[0],
  companyId: user.company?.id,
  locationId: user.location?.id,
  assignedDevices: user.assignedDevices?.length
    ? user.assignedDevices
    : user.deviceIds,
});

export default ({
  user,
  children,
  onEditUser,
  currentUser,
  triggerButtonClass,
}: {
  user: User;
  currentUser?: User;
  children?: React.ReactNode;
  onEditUser?: (userId: string, data: Partial<CreateUserRequestData>) => void;
  triggerButtonClass?: string;
}) => {
  const { id, profilePicture, roles } = user;
  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [stepsData, setStepsData] = useState<StepsData>({});

  const [isVisible, handleOpen, handleClose] = useModalState(false);
  const onOpen = () => {
    setIsLoading(true);
    handleOpen();
    api.users
      .getUser(id)
      .then((fetchedUser) => {
        setStepsData(prepareStepsData(fetchedUser));
      })
      .finally(() => setIsLoading(false));
  };

  const onSubmit =
    (close: () => void) => (data: Partial<CreateUserRequestData>) => {
      setIsLoading(true);
      if (onEditUser) {
        onEditUser(id, data);
        close();
        return;
      }

      api.users
        .updateUserData(id, createFormData(data, true))
        .then(() => {
          close();
          dispatch(getUsersList(readAllFromQueryParams(search)));
          toast.success(i18next.t<string>('users.edited_message'));
        })
        .finally(() => setIsLoading(false));
    };
  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={onOpen}
        onClick={onOpen}
        className={triggerButtonClass}
      >
        {children ?? (
          <Tooltip text={t('common.edit')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-edit" />
            </span>
          </Tooltip>
        )}
      </span>
      <WithModesModal<any, AddUserSteps>
        isVisible={isVisible}
        onClose={handleClose}
        modes={{
          [AddUserSteps.Info]: {
            title: t('users.edit_user'),
            render: ({ setMode, close }) => (
              <WithSpinner isLoading={isLoading} className="w-700px h-500px">
                <Info
                  setMode={setMode}
                  data={stepsData}
                  editData={{ profilePicture, roles }}
                  setData={setStepsData}
                  currentUser={currentUser ?? user}
                  validationSchema={editValidation(t)}
                  onSubmit={onSubmit(close)}
                />
              </WithSpinner>
            ),
          },
          [AddUserSteps.Assign]: {
            title: t('users.edit_user'),
            render: ({ setMode, close }) => (
              <WithSpinner isLoading={isLoading} className="w-700px h-500px">
                <Assign
                  isEdit
                  setMode={setMode}
                  onSubmit={onSubmit(close)}
                  currentUser={currentUser ?? user}
                  setData={setStepsData}
                  data={stepsData}
                />
              </WithSpinner>
            ),
          },
        }}
        defaultMode={AddUserSteps.Info}
        dialogClassName={clsx('mw-1000px', sizingStyles.fitContentOld)}
      />
    </>
  );
};
