import React from 'react';
import { useTranslation } from 'react-i18next';
import ScheduleContentSteps from '../../../enums/steps/scheduleContentSteps.enum';
import SelectNavTab from '../../NavTabs/SelectNavTab';

export default ({ currentStep }: { currentStep: ScheduleContentSteps }) => {
  const { t } = useTranslation();

  return (
    <SelectNavTab<ScheduleContentSteps>
      isSelectable={false}
      withUnderline={false}
      className="mb-5 justify-content-center"
      tabs={[
        {
          key: ScheduleContentSteps.Info,
          isActive: currentStep === ScheduleContentSteps.Info,
          text: t('contents.create_content.steps.common_info'),
        },
        {
          key: ScheduleContentSteps.Devices,
          isActive: currentStep === ScheduleContentSteps.Devices,
          text: t('contents.create_content.steps.devices'),
        },
        {
          key: ScheduleContentSteps.Playlists,
          isActive: currentStep === ScheduleContentSteps.Playlists,
          text: t('contents.create_content.steps.playlists'),
        },
      ]}
    />
  );
};
