import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import SkuOrientation from '../../../../enums/skuOrientation';
import SkuInfo from '../../../../interfaces/skuInfo.interface';
import Modal from '../../../Modal';
import UploadSku from './UploadSku';

export default ({
  onSubmit,
  incrementIndexForPosters,
  incrementIndexForContent,
  incrementIndexForVideos,
  incrementIndexForFullScreen,

  update,
}: {
  onSubmit?: (data: any) => void;
  incrementIndexForPosters?: number;
  incrementIndexForContent?: number;
  incrementIndexForVideos?: number;
  incrementIndexForFullScreen?: number;

  update?: boolean;
}) => {
  const { t } = useTranslation();

  const [postersData, setPostersData] = useState<SkuInfo[]>();

  const [contentData, setContentData] = useState<SkuInfo[]>();
  const [videosData, setVideosData] = useState<SkuInfo[]>();
  const [fullScreenData, setFullScreenData] = useState<SkuInfo[]>();

  const [loading, setLoading] = useState(false);

  // const [sku, setSku] = useState<SkuInfo[]>();

  const handleContent = (data: any) => {
    setContentData(data);
  };

  const handleVideos = (data: any) => {
    setVideosData(data);
  };
  const handlePosters = (data: any) => {
    setPostersData(data);
  };
  const handleFullScreen = (data: any) => {
    setFullScreenData(data);
  };

  const onSubmitData = () => {
    setLoading(true);
    const Sku = [
      ...((postersData as SkuInfo[]) ?? []),
      ...((contentData as SkuInfo[]) ?? []),
      ...((videosData as SkuInfo[]) ?? []),
      ...((fullScreenData as SkuInfo[]) ?? []),
    ];

    if (onSubmit) onSubmit(Sku);
  };

  return (
    <>
      <Row>
        <Col>
          <h4 className="fw-boldest mb-2">
            {t('library.indigo.upload_fullscreen')}
          </h4>

          <UploadSku
            key={0}
            onSubmit={handleFullScreen}
            orientation={SkuOrientation.FullScreen}
            incrementIndex={incrementIndexForFullScreen}
          />
        </Col>
        <Col>
          <h4 className="fw-boldest mb-2">
            {t('library.indigo.upload_posters')}
          </h4>

          <UploadSku
            key={1}
            onSubmit={handlePosters}
            orientation={SkuOrientation.Poster}
            incrementIndex={incrementIndexForPosters}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col>
          <h4 className="fw-boldest mb-2">
            {t('library.indigo.upload_contents')}
          </h4>
          <UploadSku
            key={2}
            onSubmit={handleContent}
            orientation={SkuOrientation.Content}
            incrementIndex={incrementIndexForContent}
          />
        </Col>

        <Col>
          <h4 className="fw-boldest mb-2">
            {t('library.indigo.upload_videos')}
          </h4>
          <UploadSku
            key={3}
            onSubmit={handleVideos}
            orientation={SkuOrientation.Video}
            incrementIndex={incrementIndexForVideos}
          />
        </Col>
      </Row>

      <Modal.Separator withoutDefaultMargins className="mt-1 mb-7 mb-2" />

      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={onSubmitData}
          disabled={loading}
        >
          {loading ? (
            <>
              {' '}
              <span>
                Saving
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </>
          ) : (
            <>{update ? ' Update Media' : 'Upload Media'} </>
          )}
        </button>
      </div>
    </>
  );
};
