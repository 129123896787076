import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KTSVG } from '../../../lib/metronic/helpers';
import styles from './styles.module.scss';

const AdvancedDropdown = ({
  children,
  expanded,
}: {
  children: React.ReactNode;
  expanded?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded ?? false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const { t } = useTranslation();
  return (
    <>
      <div className="w-100 d-flex justify-content-center align-items-center py-2">
        {isExpanded ? (
          <KTSVG path="/media/icons/minimize.svg" className="svg-icon" />
        ) : (
          <KTSVG path="/media/icons/maximize.svg" className="svg-icon" />
        )}
        <span
          tabIndex={-1}
          onKeyPress={toggleExpanded}
          className={styles.label}
          role="button"
          onClick={toggleExpanded}
        >
          {t('common.advanced')}
        </span>
      </div>
      {isExpanded && children}
    </>
  );
};

export default AdvancedDropdown;
