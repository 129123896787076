import { FileMimeType, FileType } from '../store/types/fileManager';

export default {
  docFileTypes: [
    FileMimeType.DOC,
    FileMimeType.DOCX,
    FileMimeType.XLSX,
    FileMimeType.XLS,
    FileMimeType.PPT,
    FileMimeType.PPTX,
    FileType.XLS,
    FileType.XLSX,
    FileType.DOCX,
    FileType.DOC,
    FileType.PPT,
    FileType.PPTX,
    FileType.DOCUMENT,
    FileType.SHEET,
    FileType.PRESENTATION,
  ],
  imageFileTypes: [
    FileMimeType.JPEG,
    FileMimeType.JPG,
    FileMimeType.PNG,
    FileMimeType.GIF,
    FileType.JPEG,
    FileType.JPG,
    FileType.PNG,
  ],
  videoFileTypes: [FileMimeType.MP4, FileMimeType.VIDEO, FileType.MP4],
  pdfFileTypes: [FileMimeType.PDF, FileType.PDF],
};
