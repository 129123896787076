import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateTopicRequestData from '../../../api/endpoints/libraries/interfaces/createTopicRequestData.interface';

import api from '../../../api';

import CreateTopic from '../../../components/AdminPanel/Library/Topic/CreateTopic';
import Modal from '../../../components/Modal';
import { LibraryCategoryType } from '../../../enums/library/libraryCategoryType.enum';

const CreateCategoryTopic = ({
  onUpdate,
  categoryType,
  children,
}: {
  children?: React.ReactNode;
  onUpdate?: (topicId: string) => void;
  categoryType?: LibraryCategoryType;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(false);

  // const [customerId, setCustomerId] = useState('');
  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const handleSubmit = (data: CreateTopicRequestData) => {
    api.libraries.createTopic(data).then((res) => {
      close();
      if (onUpdate) {
        onUpdate(res.id);
      }
    });
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('library.indigo.createTopic')}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
        fullscreen
      >
        <CreateTopic categoryType={categoryType} onSubmit={handleSubmit} />
      </Modal>
    </>
  );
};
export default CreateCategoryTopic;
