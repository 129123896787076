import React from 'react';
import { useTranslation } from 'react-i18next';
import AppTypes from '../../../../../enums/appType.enum';
import IContent from '../../../../../interfaces/content.interface';
import GoogleCalendarPreview from './GoogleCalendarPreview';
import GoogleSlidesPreview from './GoogleSlidesPreview';
import GoogleSheetsPreview from './GoogleSheetsPreview';
import GoogleDocsPreview from './GoogleDocsPreview';
import ViewContent from '../../../ViewContent';
import YoutubePreview from './YoutubePreview';
import WeatherPreview from './WeatherPreview';
import HTMLPreview from './HTMLPreview';
import LiveStreamPreview from './liveStreamPreview';
import RssPreview from './RssPreview';
import Modal from '../../../../Modal';
import PowerBiPreview from './PowerBiPreview';
import CanvaPreview from './CanvaPreview';
import TwitterPreview from './TwitterPreview';
import FacebookPreview from './FacebookPreview';
import InstagramPreview from './InstagramPreview';
import QrCodePreview from './QrCodePreview';
import MsTeamsPreview from './MsTeamsPreview';
import CountUpPreview from './CountUpPreview';
import CountDownPreview from './CountDownPreview';
import DaysSafePreview from './daysSafePreview';

export default ({
  content,
  show,
  onClose,
  isLoading,
}: {
  content: IContent;
  show: boolean;
  onClose: () => void;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  switch (content?.app?.type) {
    case AppTypes.LiveStream:
      return (
        <LiveStreamPreview show={show} onClose={onClose} content={content} />
      );
    case AppTypes.Html:
      return <HTMLPreview show={show} onClose={onClose} content={content} />;
    case AppTypes.GoogleSheet:
      return (
        <GoogleSheetsPreview show={show} onClose={onClose} content={content} />
      );
    case AppTypes.PowerBi:
      return <PowerBiPreview show={show} onClose={onClose} content={content} />;
    case AppTypes.GoogleDocs:
      return (
        <GoogleDocsPreview show={show} onClose={onClose} content={content} />
      );
    case AppTypes.GoogleSlides:
      return (
        <GoogleSlidesPreview show={show} onClose={onClose} content={content} />
      );
    case AppTypes.Weather:
      return <WeatherPreview show={show} onClose={onClose} content={content} />;
    case AppTypes.GoogleCalendar:
      return (
        <GoogleCalendarPreview
          show={show}
          onClose={onClose}
          content={content}
        />
      );
    case AppTypes.YouTube:
      return <YoutubePreview show={show} onClose={onClose} content={content} />;
    case AppTypes.Rss:
      return <RssPreview show={show} onClose={onClose} content={content} />;
    case AppTypes.Canva:
      return <CanvaPreview show={show} onClose={onClose} content={content} />;
    case AppTypes.Twitter:
      return <TwitterPreview show={show} onClose={onClose} content={content} />;
    case AppTypes.Facebook:
      return (
        <FacebookPreview show={show} onClose={onClose} content={content} />
      );
    case AppTypes.Instagram:
      return (
        <InstagramPreview show={show} onClose={onClose} content={content} />
      );

    case AppTypes.MsTeams:
      return <MsTeamsPreview show={show} onClose={onClose} content={content} />;

    case AppTypes.QrCode:
      return <QrCodePreview show={show} onClose={onClose} content={content} />;

    case AppTypes.CountUpTimer:
      return <CountUpPreview show={show} onClose={onClose} content={content} />;
    case AppTypes.DaysSafe:
      return (
        <DaysSafePreview show={show} onClose={onClose} content={content} />
      );
    case AppTypes.CountDownTimer:
      return (
        <CountDownPreview show={show} onClose={onClose} content={content} />
      );

    default:
      return (
        <Modal
          title={`${content.name ?? ''} ${t('contents.view_content')}`}
          widthDefaultSize={false}
          withFooter={false}
          show={show}
          onHide={onClose}
          dialogClassName="mw-900px"
        >
          <ViewContent isLoading={isLoading} content={content} />
        </Modal>
      );
  }
};
