import React, { CSSProperties, useState } from 'react';
import clsx from 'clsx';
import CardDropdown from '../CardDropdown';
import { KTSVG } from '../../../lib/metronic/helpers';
import styles from './buttonDropdown.module.scss';

interface Item {
  key: string;
  content: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
}

export default ({
  items,
  text,
  actionButtonStyles,
  cardDropdownClassName,
  disable = false,
}: {
  items: (Item | null)[];
  text: string;
  actionButtonStyles?: CSSProperties;
  cardDropdownClassName?: string;
  disable?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>();

  return (
    <CardDropdown
      show={disable ? !disable : isOpen}
      onToggle={setIsOpen}
      className={cardDropdownClassName}
      menuClassName="menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7"
      toggle={
        <button
          disabled={disable}
          type="button"
          className="btn btn-light btn-active-light-primary btn-sm"
          style={actionButtonStyles}
        >
          {text}
          <KTSVG
            path="/media/icons/duotune/arrows/arr072.svg"
            className="svg-icon-5 m-0"
          />
        </button>
      }
    >
      {(items.filter(Boolean) as Item[]).map(
        ({ key, content, disabled, hidden }) => (
          <div
            key={key}
            className={clsx(
              'menu-item px-3',
              hidden && styles.hiddenAction,
              disabled && styles.disabledAction,
            )}
          >
            <span className="menu-link px-3 position-relative">{content}</span>
          </div>
        ),
      )}
    </CardDropdown>
  );
};
