import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import User from '../../../interfaces/user/user.interface';
import Tooltip from '../../../components/Tooltip';
import api from '../../../api';

export default ({
  user: { id },
  children,
}: {
  user: User;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  const reset = () => {
    api.users
      .resetUserPassword(id)
      .then(() => toast.success(t('success_messages.reset_password')));
  };

  return (
    <span role="button" tabIndex={-1} onKeyPress={reset} onClick={reset}>
      {children ?? (
        <Tooltip text={t('common.reset_password')}>
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-key" />
          </span>
        </Tooltip>
      )}
    </span>
  );
};
