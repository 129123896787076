import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import CreateContentRequestData from '../../../../../api/endpoints/contents/interfaces/createContentRequestData.interface';
import AssignPlaylists from '../../../../../containers/Playlists/AssignPlaylists';
import AddContentSteps from '../../../../../enums/steps/addContentSteps.enum';
import StepsTitle from '../../StepsTitle';
import Modal from '../../../../Modal';

interface AssignPlaylistsProps {
  data: Partial<CreateContentRequestData>;
  setData: (newData: Partial<CreateContentRequestData>) => void;
  setMode: (mode: AddContentSteps) => void;
  onSubmit: () => void;
  skipSteps?: AddContentSteps[];
}

interface DefaultProps extends AssignPlaylistsProps {
  skipSteps: Required<AssignPlaylistsProps>['skipSteps'];
}

const AssignPlaylistsComponent = ({
  data,
  setData,
  setMode,
  onSubmit,
  skipSteps,
}: DefaultProps) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState(false);

  const handleSubmit = () => {
    setDisabled(true);
    onSubmit();
  };

  return (
    <>
      <StepsTitle
        currentStep={AddContentSteps.Playlists}
        skipSteps={skipSteps}
      />

      <AssignPlaylists
        requestData={{
          devicesIds: data.deviceIds,
          deviceGroupsIds: data.deviceGroupsIds,
        }}
        preselectedItems={data.playlistIds ?? []}
        onSelectionChange={(selectedItems) =>
          setData({ ...data, playlistIds: selectedItems })
        }
        skipQuickClick
      />

      <Modal.Separator withoutDefaultMargins className="mt-6 mb-7" />
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-white text-primary"
          onClick={() => setMode(AddContentSteps.DevicesAndGroups)}
        >
          {t('common.prev_step')}
        </button>
        <button
          type="submit"
          className={clsx(
            'btn btn-primary',
            !data.playlistIds?.length && 'disabled',
          )}
          onClick={handleSubmit}
          disabled={disabled}
        >
          {t('common.submit')}
        </button>
      </div>
    </>
  );
};

AssignPlaylistsComponent.defaultProps = {
  skipSteps: [],
};

export default AssignPlaylistsComponent;
