/* eslint-disable react/react-in-jsx-scope */
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar } from '@ant-design/plots';

ChartJS.register(ArcElement, Tooltip, Legend);

export default ({ data }: { data: { type: string; value: number }[] }) => {
  const config = {
    data,
    xField: 'value',
    yField: 'type',
    seriesField: 'type',
    legend: { position: 'top' as const },
  };
  return <Bar {...config} />;
};
