import axios from 'axios';
import { API_URL } from '../../../constants/api';
import DevicesGroupsRoutes from './enums/devicesGroupsRoutes.enum';
import GetDevicesGroupsRequestData from './interfaces/getDevicesGroupsRequestData.interface';
import GetDevicesGroupsResponseData from './interfaces/getDevicesGroupsResponseData.interface';
import UpdateDeviceGroupRequestData from './interfaces/updateDeviceGroupRequestData.interface';
import DeviceGroup from '../../../interfaces/deviceGroup.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import CreateDeviceGroupRequestData from './interfaces/createDeviceGroupRequestData.interface';
import CreateDeviceGroupResponseData from './interfaces/createDeviceGroupResponseData.interface';
import GetDevicesListResponseData from '../devices/interfaces/getDevicesListResponseData.interface';
import GetDeviceGroupResponseData from './interfaces/getDeviceGroupResponseData';
import GetDevicesListRequestData from '../devices/interfaces/getDevicesListRequestData.interface';
import GetDevicesGroupUsersRequestData from './interfaces/getDevicesGroupUsersRequestData.interface';
import GetDevicesGroupPlaylistsResponseData from './interfaces/getDevicesGroupPlaylistsResponseData.interface';
import GetDevicesGroupUsersResponseData from './interfaces/getDevicesGroupUsersResponseData.interface';
import GetPlaylistsRequestData from '../playlists/interfaces/getPlaylistsRequestData.interface';
import GetUsersGroupsRequestData from '../usersGroups/interfaces/getUsersGroupsRequestData.interface';
import IPlaylist from '../../../interfaces/playlist.interface';
import GetUsersRequestData from '../users/interfaces/getUsersRequestData.interface';
import GetUsersGroupsResponseData from '../usersGroups/interfaces/getUsersGroupsResponseData.interface';
import UsersGroupsRoutes from '../usersGroups/enums/usersGroupsRoutes.enum';
import GetUsersListResponseData from '../users/interfaces/getUsersListResponseData.interface';

export default {
  /**
   * Retrieves the list of devices groups for add content
   * @param requestData
   */
  getDevicesGroupsForAddContent: async (
    requestData: GetDevicesGroupsRequestData,
  ): Promise<GetDevicesGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/optimizedList/addContent/deviceGroups`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Retrieves the list of devices groups
   * @param requestData
   */
  getDevicesGroups: async (
    requestData: GetDevicesGroupsRequestData,
  ): Promise<GetDevicesGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  assignDeviceGroupsToOwner: async (
    ownerType: string,
    ownerId: string,
    deviceGroupIds: string[],
  ): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/assign`,
      data: {
        ownerType,
        ownerId,
        deviceGroupIds,
      },
    });
  },

  addDeviceGroupsAndAssignToOwner: async (
    name: string,
    userId: string,
    userIds: string[],
    deviceIds: string[],
  ): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${DevicesGroupsRoutes.AdminGroups}`,
      data: {
        name,
        userId,
        userIds,
        deviceIds,
      },
    });
  },

  /**
   * Retrieves the list of users by current user
   * @param requestData
   */
  getUsersListByCurrentUser: async (
    requestData: GetUsersRequestData,
    deviceGroupeId?: string,
  ): Promise<GetUsersListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.AdminGroups}/${deviceGroupeId}/users`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Retrieves the list of devices groups in user group info
   * @param id
   * @param requestData
   */
  getDevicesGroupsInUserGroupInfo: async (
    requestData: GetDevicesGroupsRequestData,
    id?: string,
  ): Promise<GetDevicesGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}/${id}/device-groups`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Retrieves the list of devices groups in user group info
   * @param requestData
   */
  getAdminDevicesGroupList: async (
    requestData: GetDevicesGroupsRequestData,
  ): Promise<GetUsersGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.DeviceGroups}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of devices groups
   * @param requestData
   */
  getAdminDevicesGroups: async (
    requestData: GetDevicesGroupsRequestData,
  ): Promise<GetDevicesGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.AdminGroups}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the devices groups by id
   * @param id
   */
  getDevicesGroup: async (id: string): Promise<DeviceGroup> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${id}`,
    });

    return data;
  },

  /**
   * Retrieves the list device group devices
   * @param requestData
   * @param deviceGroupId
   */
  getDeviceGroupDevices: async (
    requestData: GetDevicesListRequestData,
    deviceGroupId: string,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/devices`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieve device group
   * @param deviceGroupId
   */
  getDeviceGroup: async ({
    deviceGroupId,
  }: {
    deviceGroupId: string;
  }): Promise<GetDeviceGroupResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}`,
    });

    return data;
  },

  /**
   * Retrieves a list of users of device group
   * @param requestData
   * @param groupId
   */
  getDeviceGroupUsersList: async (
    requestData: GetUsersRequestData,
    groupId: GetDevicesGroupUsersRequestData,
  ): Promise<GetDevicesGroupUsersResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${groupId}/users`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves a list of user groups of device group
   * @param requestData
   * @param groupId
   */
  getDeviceGroupUserGroupsList: async (
    requestData: GetUsersGroupsRequestData,
    groupId: string,
  ): Promise<GetUsersGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${groupId}/usergroups`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Creates a new devices group
   * @param requestData
   */
  createDeviceGroup: async (
    requestData: CreateDeviceGroupRequestData,
  ): Promise<CreateDeviceGroupResponseData> => {
    const { data } = await axios.post(
      `${API_URL}/${DevicesGroupsRoutes.Groups}`,
      requestData,
    );

    return data;
  },

  /**
   * Updates the device group by id
   * @param requestData
   * @param deviceGroupId
   */
  updateDeviceGroup: async (
    requestData: UpdateDeviceGroupRequestData,
    deviceGroupId: string,
  ): Promise<DeviceGroup> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}`,
      data: requestData,
    });

    return data;
  },

  /**
   * Adds users to device group
   * @param requestData
   * @param deviceGroupId
   */
  assignUsersToGroup: async (
    requestData: UpdateDeviceGroupRequestData,
    deviceGroupId: string,
  ): Promise<DeviceGroup> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/users`,
      params: {
        userIds: requestData.userIds,
      },
    });

    return data;
  },

  /**
   * Delete users from device group
   * @param deviceGroupId
   * @param userId
   */
  unAssignUsersFromGroup: async (
    userId: string,
    deviceGroupId: string,
  ): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/users`,
      params: {
        userIds: [userId],
      },
    });
  },

  /**
   * Adds user groups to device group
   * @param deviceGroupId
   * @param userGroupId
   */
  assignUserGroupToGroup: async (
    deviceGroupId: string,
    userGroupId: string,
  ): Promise<void> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/usergroups/${userGroupId}`,
    });

    return data;
  },

  /**
   * Delete user groups from device group
   * @param deviceGroupId
   * @param userGroupId
   */
  unAssignUserGroupsFromGroup: async (
    deviceGroupId: string,
    userGroupId: string,
  ): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/usergroups/${userGroupId}`,
    });
  },

  /**
   * Adds playlists to device group
   * @param playlistIds
   * @param deviceGroupId
   */
  assignPlaylistsToGroup: async (
    playlistIds: string[],
    deviceGroupId: string,
  ): Promise<DeviceGroup> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/playlists`,
      params: {
        playlistIds,
      },
    });

    return data;
  },

  /**
   * Unassign playlists from device group
   * @param playlistIds
   * @param deviceGroupId
   */
  unAssignPlaylistsFromToGroup: async (
    playlistIds: string,
    deviceGroupId: string,
  ): Promise<DeviceGroup> => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/playlists`,
      params: {
        playlistIds: [playlistIds],
      },
    });

    return data;
  },

  /**
   * Removes users to device group
   * @param requestData
   * @param deviceGroupId
   */
  removeUsersFromGroup: async (
    requestData: UpdateDeviceGroupRequestData,
    deviceGroupId: string,
  ): Promise<DeviceGroup> => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/users`,
      params: {
        userIds: requestData.userIds,
      },
    });

    return data;
  },

  /**
   * Removes the devices group by id
   * @param id
   */
  deleteDeviceGroup: async (id: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${id}`,
    });
  },

  /**
   * Removes the devices group by ids
   * @param ids
   */
  deleteDeviceGroups: async (ids: string[]): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}`,
      data: ids,
    });
  },

  /**
   * Get device group playlists
   * @param requestData
   * @param deviceGroupId
   */
  getDeviceGroupPlaylists: async (
    requestData: GetPlaylistsRequestData,
    deviceGroupId: string,
  ): Promise<GetDevicesGroupPlaylistsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/playlists`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Get device group playlists by current user id
   * @param requestData
   * @param deviceGroupId
   */
  getDeviceGroupPlaylistsByCurrentUser: async (
    requestData: GetPlaylistsRequestData,
    deviceGroupId: string,
  ): Promise<GetDevicesGroupPlaylistsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.AdminGroups}/${deviceGroupId}/playlists`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Receives playlists of group by given params
   * @param deviceGroupId
   * @param priority
   * @param endDate
   * @param startDate
   */
  getPlaylists: async ({
    deviceGroupId,
    priority,
    endDate,
    startDate,
  }: {
    deviceGroupId?: string;
    priority: number;
    endDate: Date;
    startDate: Date;
  }): Promise<IPlaylist[]> => {
    const { data } = await axios.get(
      `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/playlists`,
      {
        params: sanitizeQueryParams({ priority, startDate, endDate }),
      },
    );

    return data;
  },

  /**
   * Receives customer device groups
   * @param id
   * @param requestData
   */
  getCustomerDeviceGroupsList: async (
    id: string,
    requestData: GetDevicesGroupsRequestData,
  ): Promise<GetDevicesGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesGroupsRoutes.AdminGroups}/owner/${id}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Assign device group from user group
   * @param userGroupId
   * @param deviceGroupuserId
   */
  assignDeviceGroupToUserGroup: async (
    userGroupId: string,
    deviceGroupId: string | undefined,
  ): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${DevicesGroupsRoutes.Groups}/${deviceGroupId}/usergroups/${userGroupId}`,
    });
  },
};
