import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import validation from '../../../utils/validation';
import FiltersLocationDropdown from '../FiltersLocationDropdown';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import SelectUser from '../../../containers/Users/SelectUser';
import { UserType } from '../../../enums/userType.enum';
import WithSpinner from '../../WithSpinner';
import Modal from '../../Modal';
import SelectCompany from '../../../containers/Companies/SelectCompany';

interface FormInputs {
  userId: string;
  companyId: string;
}

export default ({
  onSubmit,
  onHide,
  initialValue,
  isModalVisible,
  isLoading,
  isTrial,
}: {
  isModalVisible: boolean;
  onSubmit: (data: FormInputs) => void;
  onHide?: () => void;
  initialValue?: string;
  isLoading: boolean;
  isTrial?: boolean;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(
      validation
        .object({
          userId: validation.string().required(),
        })
        .required(),
    ),
    defaultValues: {
      userId: initialValue,
    },
  });

  const [userId, setUserId] = useStateWithHookForm<
    FormInputs,
    FormInputs['userId']
  >({ setValue, trigger, name: 'userId' }, initialValue);

  const [companyId, setCompanyId] = useStateWithHookForm<
    FormInputs,
    FormInputs['companyId'] | undefined
  >({ setValue, trigger, name: 'companyId' }, initialValue);

  return (
    <Modal
      title={t('devices.table.assign_device')}
      widthDefaultSize={false}
      show={isModalVisible}
      onHide={onHide}
      bodyClassName="py-5"
      withFooter={false}
    >
      <WithSpinner isLoading={isLoading} className="min-h-200px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex justify-content-end mb-5">
            <FiltersLocationDropdown className="me-2" />
          </div>
          <div>
            <label htmlFor="companyId" className="col-form-label text-lg-end">
              {t('users_groups.common.company')}
            </label>
            <SelectCompany
              id="company"
              placeholder={t('users.common.select_company')}
              trialCompany={isTrial}
              onChange={({ value }) => {
                if (value !== 'loadingValue') setCompanyId(value);
              }}
              initialValue={companyId}
              isClearable
            />
          </div>
          <div>
            <label htmlFor="userId" className="col-form-label text-lg-end">
              {t('users.user')}
            </label>
            <SelectUser
              id="userId"
              companyId={companyId}
              initialValue={userId}
              filters={{ userType: UserType.Customer }}
              onChange={setUserId}
              isAdmin
              isTrial={isTrial}
              aria-invalid={errors.userId ? 'true' : 'false'}
            />
            {errors.userId && (
              <div style={{ color: 'red' }}>{`User ${t(
                'input_errors.required_field',
              )}`}</div>
            )}
          </div>

          <Modal.Separator withoutDefaultMargins className="mt-6 mb-7" />
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {t('common.assign')}
            </button>
          </div>
        </form>
      </WithSpinner>
    </Modal>
  );
};
