import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import clsx from 'clsx';
import FormErrorMessage from '../../../FormErrorMessage';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import { CreateCanvaFeedRequest } from '../../../../store/types/apps/canvaFeed';
import styles from './Canva.module.scss';
import canvaPreviewStyles from '../canvaPreview.module.scss';
import noPreview from '../../../../assets/images/noPreview/noPreview.png';
import { AppDependencyConfig } from '../../../../store/types/apps/globalTypes';

type CreateCanvaRequestFormMutated = Omit<
  CreateCanvaFeedRequest,
  'config' | 'placement'
>;

interface CreateCanvaFeedRequestFormPayload
  extends CreateCanvaRequestFormMutated {}

export interface CreateFeedModalProps {
  onSubmit: (data: CreateCanvaFeedRequest) => void;
  canva?: App;
  onClose?: () => void;
  isLoading: boolean;
}

export default ({
  onSubmit,
  onClose,
  isLoading,
  canva = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();

  const initialValues: CreateCanvaFeedRequestFormPayload = {
    name: canva?.name ?? '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateCanvaFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateCanvaFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const handleFeedNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFeedName(value),
    [setFeedName],
  );

  const [isScheduled, setIsScheduled] = useState<boolean>(false);

  const handleOnSubmit = async (data: CreateCanvaFeedRequestFormPayload) => {
    const { name } = data;
    const createGoogleSheetFeedRequest: CreateCanvaFeedRequest = {
      name,
      isScheduled,
      config: canva.dependency.config,
    };

    onSubmit(createGoogleSheetFeedRequest);
  };

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id="form"
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex flex-row p-4 w-100">
            <div className="d-flex flex-column mx-2 w-100">
              <label
                htmlFor="name"
                className="required text-dark fw-bolder my-1"
              >
                {t('apps.googleSheets.googleSheetTitle')}
              </label>
              <input
                name="name"
                id="name"
                value={feedName}
                onChange={handleFeedNameChange}
                className="form-control form-control-solid"
                placeholder="Type here"
                type="text"
              />
              <FormErrorMessage
                name="name"
                errors={errors}
                className="my-1 px-2"
              />
            </div>
          </div>
          <div>
            <p className="text-center">
              <img
                className={clsx(canvaPreviewStyles.canvaImage)}
                alt={canva.name}
                onError={({ currentTarget }) => {
                  // eslint-disable-next-line no-param-reassign
                  currentTarget.src = noPreview;
                }}
                src={
                  (canva?.dependency?.config as AppDependencyConfig)
                    ?.canvaAssetUrl
                }
              />
            </p>
          </div>

          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {t('common.save')}
              </button>

              <button
                onClick={() => setIsScheduled(true)}
                type="submit"
                className={clsx('btn btn-primary', styles.scheduleBtn)}
              >
                {t('common.update_schedule')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
