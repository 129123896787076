import React, { FC, HTMLInputTypeAttribute, memo } from 'react';
import clsx from 'clsx';
import styles from './TextInput.module.scss';

type Props = {
  id?: string;
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  useDisabledStyles?: boolean;
  noAutocomplete?: boolean;
  name?: string;
  type?: HTMLInputTypeAttribute;
  maxLength?: number;
  min?: number;
  max?: number;
};

const TextInput: FC<Props> = ({
  id,
  value,
  disabled = false,
  onChange,
  placeholder = '',
  className,
  useDisabledStyles = false,
  name,
  type = 'text',
  noAutocomplete = true,
  maxLength,
  min,
  max,
}) => {
  return (
    <input
      className={clsx(
        !disabled && !useDisabledStyles && styles.enabled,
        'form-control form-control-solid',
        className,
      )}
      id={id}
      name={name}
      disabled={disabled}
      type={type}
      value={value}
      onChange={({ currentTarget: { value: targetValue } }) => {
        if (onChange) onChange(targetValue);
      }}
      placeholder={placeholder}
      autoComplete={noAutocomplete ? 'off' : undefined}
      maxLength={maxLength}
      min={min}
      max={max}
    />
  );
};

export default memo(TextInput);
