import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import clsx from 'clsx';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Card from '../../Card';
import App from '../../../interfaces/app.interface';
import useTableMultiSelect from '../../../utils/hooks/useTableMultiSelect';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import SheetsTable from './Table';
import Search from '../../Search/QueryParamSearch';
import CanvaConnection from './CanvaConnection';
import PublishModal from '../../PublishModal';
import ScheduleContentRequestData from '../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import useModalState from '../../../utils/hooks/useModalState';
import Yup from '../../../utils/validation';
import api from '../../../api';
import config from '../../../routing/config';
import styles from './canvaPreview.module.scss';
import { KTSVG } from '../../../lib/metronic/helpers';

const pageSize = 6;
const tableRowHeight = 63;

export default ({
  canva,
  isLoading,
  resetOnSearch,
  onUpdate,
}: {
  canva: App[];
  isLoading: boolean;
  resetOnSearch?: boolean;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [isVisible, handleOpen, handleClose] = useModalState(false);
  const { id } = useParams<{ id: string }>();

  const { search } = useLocation();
  const history = useHistory();

  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(canva, 'id'),
    [],
    resetOnSearch ? search : undefined,
  );

  const [scheduleApp, setScheduleApp] = useState<App>({} as App);
  const [incrementationCanva, setIncrementationCanva] = useState(0);

  useEffect(() => {
    if (id && incrementationCanva === 0 && canva.length) {
      setIncrementationCanva(1);
      handleOpen();
      const canvaApp = canva.find((app) => app.id === id);
      if (canvaApp) {
        setScheduleApp(canvaApp);
      }
    }
  }, [canva]);

  const onScheduleApp = (app: App) => {
    setScheduleApp(app);
    handleOpen();
  };

  const handleScheduleSubmit = async (data: ScheduleContentRequestData) => {
    try {
      await api.apps.scheduleApp(scheduleApp.type, scheduleApp.id, data);
      toast.success(i18next.t<string>('common.app_scheduled'));
      handleClose();
      onUpdate();
      history.push(config.canva.route);
    } catch (e: any) {
      toast.error(e?.message ?? '');
    }
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Header.Toolbar>
            <Search
              queryField="search"
              pageNumberQueryField=""
              placeholder={t('common.search')}
              className="me-2"
            />
            <div className="d-flex">
              <div className={clsx('me-3', styles.buttonStyle)}>
                <CanvaConnection />
              </div>
              <a
                href="https://canva.com"
                rel="noreferrer"
                target="_blank"
                className="mt-5 btn btn-primary"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                {t('apps.canva.createCanva')}
              </a>
            </div>
          </Card.Header.Toolbar>
        </Card.Header>
        <Card.Body>
          <SheetsTable
            multiSelectConfig={{
              selectedItems,
              setSelectedItem,
              selectAll,
            }}
            onUpdate={onUpdate}
            canva={canva}
            isLoading={isLoading}
            sortQueryField={SortingQueryParam.SortBy}
            orderQueryField={SortingQueryParam.SortOrder}
            preloaderHeight={pageSize * tableRowHeight}
            scheduleApp={onScheduleApp}
          />
        </Card.Body>
      </Card>
      <PublishModal
        onClose={handleClose}
        onSubmit={handleScheduleSubmit}
        isVisible={isVisible}
        skipOrder
        skipOrientationAuto
        infoValidationSchema={{
          orientation: Yup.string().required(t('required')),
          duration: Yup.number().required(t('required')).min(1),
          startDate: Yup.date(),
          endDate: Yup.date().when('startDate', {
            is: (startDate: any) => !!startDate,
            then: Yup.date().min(
              Yup.ref('startDate'),
              t('common.validation.endDate'),
            ),
          }),
          isLocked: Yup.boolean(),
        }}
      />
    </>
  );
};
