enum EventType {
  Create = 'Create',
  Read = 'Read',
  Update = 'Update',
  Delete = 'Delete',
  Copy = 'Copy',
  Schedule = 'Schedule',
  BulkEdit = 'BulkEdit',
  Lock = 'Lock',
  UnLock = 'UnLock',
}

export default EventType;
