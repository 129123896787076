import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Spinner } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import Card from '../../Card';
import styles from '../devicesGroups.module.scss';
import EditGroup from '../EditGroup';
import RemoveGroup from '../../../containers/DevicesGroups/RemoveGroup';
import { KTSVG } from '../../../lib/metronic/helpers';
import UpdateDeviceGroupAction from '../../../store/actions/devicesGroups/interfaces/updateDeviceGroupAction.interface';

export default ({
  isLoading,
  group,
  onEditDeviceGroup,
  onRemoveDeviceGroup,
  children,
}: {
  group: any;
  isLoading: boolean;
  onRemoveDeviceGroup: () => void;
  onEditDeviceGroup: (data: UpdateDeviceGroupAction['payload']) => void;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between">
      {!isLoading ? (
        <Card className={clsx('ml-5 ', styles.deviceBlockInfo)}>
          <Card.Header>
            <span className={clsx(styles.deviceGroupName)}>{group.name}</span>
          </Card.Header>
          <Card.Body
            className={clsx(
              'd-flex flex-column',
              styles.deviceGroupCreatedDate,
            )}
          >
            <div className="d-flex flex-column mb-4">
              Date Created
              <span>{moment(group.createdAt).format('l')}</span>
            </div>
            <div
              className={clsx(
                'd-flex justify-content-between flex-wrap',
                styles.footerButtons,
              )}
            >
              <EditGroup isName group={group} onSubmit={onEditDeviceGroup}>
                <span
                  className={clsx(
                    'btn btn-bg-light btn-active-color-primary',
                    styles.editButton,
                  )}
                >
                  {t('devices_groups.edit_device_group.edit_group')}
                </span>
              </EditGroup>
              <RemoveGroup
                group={group}
                onRemoveDeviceGroup={onRemoveDeviceGroup}
              >
                <span
                  className={clsx(
                    'btn btn-icon btn-bg-light btn-color-danger btn-sm me-2',
                    styles.removeButton,
                  )}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen027.svg"
                    className="svg-icon-3"
                  />
                </span>
              </RemoveGroup>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <Spinner
          hidden={!isLoading}
          className={styles.nextButton}
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}

      <div className="flex-fill col-1 min-w-500px">{children}</div>
    </div>
  );
};
