import clsx from 'clsx';
import React from 'react';
import Table from '../index';
import { handleSort, SetSortField, SortField } from '../../../utils/sorting';
import CheckBox from '../../CheckBox';

export interface TableHeaderColumn<S extends string> {
  name: string;
  sortBy?: S;
  className?: string;
}

export default <S extends string>({
  columns,
  checked,
  checkBoxClassName,
  onChange,
  onSort,
  config: {
    sortField,
    setSortField,
    sortQueryField,
    orderQueryField,
    isSelectable,
    disabled,
  },
}: {
  columns: TableHeaderColumn<S>[];
  checked?: boolean;
  onChange?: any;
  onSort?: (sortField: string, isInverted: boolean) => void;
  checkBoxClassName?: string;
  config: {
    sortField: SortField;
    setSortField: SetSortField;
    sortQueryField?: string;
    orderQueryField?: string;
    isSelectable?: boolean;
    disabled?: boolean;
  };
}) => (
  <>
    {isSelectable && (
      <Table.Head.Row.Cell>
        <CheckBox
          className={checkBoxClassName}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </Table.Head.Row.Cell>
    )}

    {columns.map(({ name, sortBy, className }, index) => {
      const isSorted = sortField.field === sortBy;
      const isLastItem = index === columns.length - 1;

      return (
        <Table.Head.Row.Cell
          key={name}
          className={clsx(
            'text-uppercase',
            index !== 0 && (className || 'text-center'),
          )}
          isSortable={!!sortBy}
          isTextEnd={isLastItem}
          onToggle={
            onSort && sortBy
              ? (isInverted) => onSort(sortBy, isInverted)
              : handleSort<S>(sortBy, {
                  setSortField,
                  sortQueryField,
                  orderQueryField,
                })
          }
          isInverted={isSorted && sortField.isInverted}
          isNoWrap
        >
          {name}
        </Table.Head.Row.Cell>
      );
    })}
  </>
);
