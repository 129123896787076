import GetUsersGroupsSuccessAction from '../../actions/usersGroups/interfaces/getUsersGroupsSuccessAction.interface';
import UsersGroupsActions from '../../actions/usersGroups/usersGroupsActions.enum';
import LoadingAction from '../../actions/loadingAction.interface';
import ReduxAction from '../../actions/reduxAction.interface';
import UsersGroupsState from './usersGroupsState.interface';

const initialState: UsersGroupsState = {
  loading: true,
  groups: [],
  total: 0,
};

export default function usersGroupsReducer(
  state = initialState,
  action: ReduxAction<UsersGroupsActions>,
): UsersGroupsState {
  switch (action.type) {
    case UsersGroupsActions.GET_USERS_GROUPS_SUCCESS: {
      const {
        payload: { items, meta },
      } = action as GetUsersGroupsSuccessAction;

      return {
        ...state,
        groups: items,
        total: meta.totalItems,
      };
    }
    case UsersGroupsActions.SET_USERS_GROUPS_LOADING:
      return {
        ...state,
        ...(
          action as LoadingAction<UsersGroupsActions.SET_USERS_GROUPS_LOADING>
        ).payload,
      };
    default:
      return state;
  }
}
