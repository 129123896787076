import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Info from './Info';
import Assign from './Assign';
import WithModesModal from '../../WithModesModal';
import { KTSVG } from '../../../lib/metronic/helpers';
import User from '../../../interfaces/user/user.interface';
import { creationValidation } from '../../../validation/users';
import AddUserSteps from '../../../enums/steps/addUserSteps.enum';
import CreateUserRequestData from '../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import useModalState from '../../../utils/hooks/useModalState';
import sizingStyles from '../../../styles/sizing.module.scss';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';
import TrialAccountPopup from '../../TrialAccountPopup';
import Tooltip from '../../Tooltip';

export default ({
  className,
  onSubmit,
  currentUser,
}: {
  className?: string;
  onSubmit: (data: CreateUserRequestData) => void;
  currentUser?: User;
}) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] = useState<Partial<CreateUserRequestData>>(
    {},
  );
  const [open, setOpen] = useState<boolean>(false);
  const [isVisible, handleOpen, handleClose] = useModalState(false);
  const user = useSelector((state: GlobalState) => state.userData.user);

  const handleClick = () => {
    const { isTrial } = user;
    const isAccountOwner = user.roles.includes(UserRole.AccountOwner);
    if (isAccountOwner && isTrial) {
      setOpen(true);
    } else {
      handleOpen();
    }
  };
  return (
    <>
      <Tooltip
        text="This feature is not available for trial users. "
        hide={!user.isTrial}
      >
        <button
          type="button"
          onClick={handleClick}
          className={clsx('btn btn-primary', className)}
          disabled={user.isTrial}
        >
          {user.isTrial ? (
            <i className="bi bi-lock-fill me-2" />
          ) : (
            <KTSVG
              path="/media/icons/duotune/arrows/arr075.svg"
              className="svg-icon-2"
            />
          )}{' '}
          {t('users.add_user')}
        </button>
      </Tooltip>
      <WithModesModal<any, AddUserSteps>
        isVisible={isVisible}
        onClose={handleClose}
        modes={{
          [AddUserSteps.Info]: {
            title: t('users.add_user'),
            render: ({ setMode }) => (
              <Info
                setMode={setMode}
                data={stepsData}
                setData={setStepsData}
                validationSchema={creationValidation(t)}
                currentUser={currentUser}
              />
            ),
          },
          [AddUserSteps.Assign]: {
            title: t('users.add_user'),
            render: ({ setMode, close }) => (
              <Assign
                setMode={setMode}
                onSubmit={(data) => {
                  close();
                  setStepsData({});
                  onSubmit(data as CreateUserRequestData);
                }}
                setData={setStepsData}
                data={stepsData}
                currentUser={currentUser}
              />
            ),
          },
        }}
        defaultMode={AddUserSteps.Info}
        dialogClassName={clsx('mw-1000px', sizingStyles.fitContentOld)}
      />
      <TrialAccountPopup isModalVisible={open} setIsModalVisible={setOpen} />
    </>
  );
};
