import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../../Modal';
import { SelectedValue } from '../../Select/SelectAsync';
import validation from '../../../utils/validation';
import { assignUserValidation } from '../../../validation/users';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../FormErrorMessage';
import SelectDevicesGroups from '../../../containers/SelectDevicesGroups';

interface FormInputs {
  devicesgroups: SelectedValue[];
}

export default ({
  children,
  assignDevicesGroupsToUserGroup,
  userGroupId,
}: {
  children?: React.ReactNode;
  assignDevicesGroupsToUserGroup?: (data: SelectedValue[]) => void;
  userGroupId?: string;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    clearErrors,
  } = useForm<FormInputs>({
    resolver: yupResolver(
      validation.object(assignUserValidation(t)).required(),
    ),
    defaultValues: {
      devicesgroups: [],
    },
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [devicesGroups, setDevicesGroups] = useStateWithHookForm<
    FormInputs,
    SelectedValue[]
  >({ setValue, trigger, name: 'devicesgroups' }, []);

  const handleClose = () => setIsModalVisible(false);

  const handleSubmitData = () => {
    if (assignDevicesGroupsToUserGroup) {
      assignDevicesGroupsToUserGroup(devicesGroups);
    }

    handleClose();
  };

  const open = () => {
    clearErrors();
    setIsModalVisible(true);
  };

  // empty the select value when the component unmounts(on modal close)
  React.useEffect(() => {
    if (isModalVisible) {
      return () => setDevicesGroups([]);
    }
    // sould return something or eslint would throw an issue
    return undefined;
  }, [isModalVisible]);

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('devices_groups.add_group')}
        widthDefaultSize={false}
        show={isModalVisible}
        onHide={handleClose}
        bodyClassName="py-5"
        footerContent={
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(handleSubmitData)}
          >
            {t('devices_groups.add_group')}
          </button>
        }
      >
        <>
          <div>
            <span className="col-form-label text-lg-end">
              {t('devices_groups.device_groups')}
            </span>
            <SelectDevicesGroups
              userGroupId={userGroupId}
              initialValue={devicesGroups}
              onChange={setDevicesGroups}
            />
            <FormErrorMessage name="users" errors={errors} />
          </div>
        </>
      </Modal>
    </>
  );
};
