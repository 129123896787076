import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import App from '../../../../interfaces/app.interface';
import api from '../../../../api';
import Modal from '../../../../components/Modal';
import UpdateInstagramModal from '../../../../components/Apps/InstagramApp/UpdateInstagram';
import UpdateInstagramRequestData from '../../../../api/endpoints/apps/interfaces/updateInstagramRequestData.interface';

const UpdateInstagram = ({
  children,
  onUpdate,
  openWindow,
  app,
  code,
}: {
  children?: React.ReactNode;
  onUpdate: () => void;
  openWindow?: boolean;
  code?: string;
  app?: App;
}) => {
  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState<boolean>(!!openWindow);
  const [loading, setLoading] = useState<boolean>(false);
  //   const [app, setApp] = useState<App>({} as App);
  const open = () => setModalShown(true);
  const close = () => setModalShown(false);
  const handleSubmit = (data: UpdateInstagramRequestData) => {
    setLoading(true);
    api.apps
      .updateInstagramFeeds(data)
      .then((result: App) => {
        // setApp(result);
        console.log(result);
        close();
        if (onUpdate) {
          onUpdate();
        }

        sessionStorage.removeItem('currentInstagramUserName');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={
          app?.dependency?.config?.instagramUser
            ? `${t('apps.instagram.refreshInstagramModalTitle')} ${
                app?.dependency?.config?.instagramUser
              }`
            : `${t('apps.instagram.refreshInstagramModalTitleInstagram')}`
        }
        show={!!modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
      >
        <UpdateInstagramModal
          onSuccess={handleSubmit}
          loading={loading}
          code={code}
          app={app}
        />
      </Modal>
    </>
  );
};

export default UpdateInstagram;
