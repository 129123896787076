import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import HelpIcon from '../../../../assets/images/icons/helpCircle.svg';
import GlobalState from '../../../../store/reducers/globalState.interface';
import CardDropdown from '../../../../components/Dropdown/CardDropdown';
import DropdownContent from '../DropdownContent';
import Device from '../../../../interfaces/devices/device.interface';
import { setTrialExpireDays } from '../../../../store/actions/usersGroups';
import TalkToSales from '../../../../components/Trial/TalkToSales';
import { getDevicesList } from '../../../../store/actions/devices';
import styles from './styles.module.scss';
import Tooltip from '../../../../components/Tooltip';
import WithSpinner from '../../../../components/WithSpinner';
// import dummyImage from '../../../../assets/images/noPreview/dummy.jpg';

const sortDevicesByExiprationDate = (devices: Device[]) =>
  devices.sort((a, b) => {
    let aDate;
    let bDate;
    try {
      aDate = new Date(a.license?.expireDate);
      bDate = new Date(b.license?.expireDate);
      return aDate < bDate ? -1 : 1;
    } catch (error) {
      return -1;
    }
  });

const trialRemainingDays = (device: Device) => {
  if (!device) return undefined;

  let days = Math.round(
    moment.duration(moment(device.license?.expireDate).diff(moment())).asDays(),
  );

  if (days) {
    if (days > 30) days = 30;
    else if (days < 0) days = 0;
  }
  return days;
};

export default ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  const [isUserMenuShown, setIsUserMenuShown] = useState(false);
  const { firstName, lastName, profilePicture, isTrial } = useSelector(
    (state: GlobalState) => state.userData.user,
  );
  const trialExpireDays = useSelector(
    (state: GlobalState) => state.userData.trialExpireDays,
  );
  const dispatch = useDispatch();

  const devices = useSelector((state: GlobalState) => state.devices.devices);

  useEffect(() => {
    if (isTrial && !trialExpireDays) {
      if (!devices.length) {
        dispatch(getDevicesList({}));
        return;
      }
      const sortedDevices = sortDevicesByExiprationDate(devices);
      const daysRemainingInTrial = trialRemainingDays(sortedDevices[0]);

      if (typeof daysRemainingInTrial === 'number') {
        dispatch(setTrialExpireDays({ trialExpireDays: daysRemainingInTrial }));
      }
    }
  }, [isTrial, devices, dispatch]);

  const picture = profilePicture;

  const IsTrialPartial = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const handleClose = () => {
      setIsModalVisible(false);
    };
    return (
      <div
        className={clsx(
          'd-flex align-items-center ms-1 ms-lg-3',
          styles.responsiveUpgradeButton,
        )}
      >
        <span className="text-muted">
          {' '}
          <b>{trialExpireDays}</b> Days Remaining
        </span>
        <TalkToSales handleClose={handleClose} isModalVisible={isModalVisible}>
          <Tooltip text="Upgrade to Premium">
            <button
              type="button"
              className="btn btn-indigo-pink ms-2"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              {' '}
              <i className="bi bi-arrow-up-circle-fill" />
              <span>Upgrade now </span>
            </button>
          </Tooltip>
        </TalkToSales>
      </div>
    );
  };

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div className="d-flex align-items-stretch flex-shrink-0">
        {isTrial && <IsTrialPartial />}
        <div className="d-flex align-items-center ms-1 ms-lg-3">
          <a
            href="https://learn.indigovizual.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={HelpIcon} alt="logo" className="position-relative" />
          </a>
          <CardDropdown
            show={isUserMenuShown}
            onToggle={setIsUserMenuShown}
            containerClassName="me-10"
            toggle={
              <div className="cursor-pointer symbol show me-3 ms-2 d-flex align-items-center symbol-30px symbol-md-40px">
                <WithSpinner
                  isLoading={Boolean(!firstName)}
                  size="md"
                  className="min-w-50px"
                >
                  <div
                    onContextMenu={(ev) => ev.preventDefault()}
                    className="image-input image-input"
                    style={{
                      backgroundImage: `url(${picture})`,
                    }}
                  >
                    {profilePicture ? (
                      <div
                        className="image-input-wrapper w-40px h-40px"
                        style={{
                          backgroundImage: `url(${profilePicture})`,
                        }}
                      />
                    ) : (
                      <div
                        className={clsx(
                          'symbol-label fs-3',
                          `bg-light-${lastName}`,
                          `text-${firstName}`,
                        )}
                      >
                        {firstName?.charAt(0) + lastName?.charAt(0)}
                      </div>
                    )}
                  </div>
                  <h6 className="fw-normal m-2">
                    {firstName} {lastName}
                  </h6>
                </WithSpinner>
              </div>
            }
          >
            <DropdownContent>{children}</DropdownContent>
          </CardDropdown>
        </div>
      </div>
    </div>
  );
};
