import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WeatherApp } from '../../../../../interfaces/app.interface';
import CheckBox from '../../../../CheckBox';
import Table from '../../../../Table';
import { formatDateString } from '../../../../../utils/dateTime';
import EditWeather from '../../../../../containers/Apps/Weather/EditWeather';
import DeleteWeather from '../../../../../containers/Apps/DeleteApp';
import ScheduleWeather from '../../../../../containers/Apps/ScheduleApp';
import PlacementsEnum from '../../../../../enums/placements.enum';
import { placementTranslation } from '../../../../../constants/translation/placement';
import WeatherThumbnail from '../../../../Weather/WeatherThumbnail';
import Yup from '../../../../../utils/validation';
import PreviewWeatherModal from '../../Preview';
import PlaylistPopover from '../../../../PopOver/PlaylistPopover';
import IPlaylist from '../../../../../interfaces/playlist.interface';
import api from '../../../../../api';
import { useAccessByRole } from '../../../../../utils/hooks/useAccessRole';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({
  weather,
  isChecked,
  config,
  onCheck,
  onUpdate,
}: {
  weather: WeatherApp;
  config: {
    isSelectable?: boolean;
  };
  isChecked: boolean;
  onCheck: (id: string) => void;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();

  const [showPreview, setShowPreview] = useState(false);
  const [iframeRef, setIframeRef] = React.useState<HTMLIFrameElement>();
  const [url, setUrl] = React.useState('');

  const [playlists, setPlaylists] = useState<Partial<IPlaylist>[]>([]);
  const weatherData = weather.dependency.weather;
  const weatherContent = JSON.stringify(weatherData);

  const displayPreview = () => setShowPreview(true);

  const setContents = () => {
    const playlistOptions: Partial<IPlaylist>[] = [];
    if (weather?.contents && weather?.contents?.length > 0) {
      const contentIds: string[] = weather.contents.map(
        (content) => content.id,
      );
      const { length } = contentIds;
      let count = 0;
      contentIds.forEach(async (contentId) => {
        const content = await api.contents.getContent(contentId);
        content.playlists?.forEach((contentPlaylist: any) => {
          playlistOptions.push({
            id: contentPlaylist?.playlist?.id ?? '',
            name: contentPlaylist?.playlist?.name ?? '',
          });
        });
        count += 1;
        if (count === length) {
          const map = new Map();
          playlistOptions.forEach((option) => {
            map.set(option.id, option.name);
          });
          const filteredPlaylist: Partial<IPlaylist>[] = [];
          Array.from(map.entries()).forEach(([id, name]) => {
            filteredPlaylist.push({ id, name });
          });
          setPlaylists(filteredPlaylist);
        }
      });
    }
  };
  const currentUserHasAccessRole = useAccessByRole(weather.ownerApp);

  React.useEffect(() => {
    if (iframeRef?.contentWindow) {
      if (weather.placement === PlacementsEnum.FULL_SCREEN) {
        setUrl(
          `${
            process.env.REACT_APP_PLAYER_PREVIEW_URL
          }/apps/weather/weather.html?weather=${weatherContent}
        &config=${JSON.stringify(weather.dependency.config)
          .replace('#', '__ht__')
          .toLowerCase()
          .replace('color', 'Color')}&ratio=${50 / 1920}`,
        );
      } else {
        setUrl(
          `${
            process.env.REACT_APP_PLAYER_PREVIEW_URL
          }/apps/weather/weather-widget-thumbnail.html?weather=${weatherContent}
        &config=${JSON.stringify(weather.dependency.config)
          .replace('#', '__ht__')
          .toLowerCase()
          .replace('color', 'Color')}&ratio=${50 / 1920}`,
        );
      }

      iframeRef.contentWindow.document.body.style.transformOrigin = 'top left';
      iframeRef.style.display = 'block';
    }
    setContents();
  }, [iframeRef]);

  return (
    <Row>
      {config.isSelectable && (
        <Cell>
          <CheckBox
            name="weather"
            id={weather.id}
            onChange={() => onCheck(weather.id)}
            checked={isChecked}
            className="mt-1"
          />
        </Cell>
      )}

      <Cell isEmpty={!weather.name}>
        <PreviewWeatherModal
          selectedWeatherContent={weatherContent}
          selectedWeatherConfig={weather.dependency.config}
          key="preview"
          show={showPreview}
          onHide={() => setShowPreview(false)}
          title={weather.name}
          placement={weather.placement}
        >
          <WeatherThumbnail
            onOpen={displayPreview}
            src={url}
            title={weather.name}
            setRef={(e) => setIframeRef(e || undefined)}
          />
        </PreviewWeatherModal>
      </Cell>
      <Cell isEmpty={!Object.keys(weather.dependency.country).length}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {weather.dependency.country.name}
        </span>
      </Cell>
      <Cell isEmpty={!weather.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {weather.ownerApp?.firstName} {weather.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!weather.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {weather.createdAt && formatDate(weather.createdAt)}
        </span>
      </Cell>
      <Cell isEmpty={!weather.placement}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {t(placementTranslation[weather.placement])}
        </span>
      </Cell>
      <Cell>
        {playlists.length ? (
          <PlaylistPopover playlists={playlists} appName={weather.name}>
            <span className="text-dark fw-bold cursor-pointer text-hover-primary d-block mb-1 fs-6 text-center">
              {playlists.length}
            </span>
          </PlaylistPopover>
        ) : (
          <span>-</span>
        )}
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          {currentUserHasAccessRole && (
            <>
              <div className="mx-1">
                <EditWeather weather={weather} onUpdate={onUpdate} />
              </div>

              {weather.placement === PlacementsEnum.FULL_SCREEN && (
                <div className="mx-1">
                  <ScheduleWeather
                    app={weather}
                    onUpdate={onUpdate}
                    infoValidationSchema={{
                      orientation: Yup.string().required(t('required')),
                      duration: Yup.number().required(t('required')).min(1),
                      startDate: Yup.date(),
                      endDate: Yup.date().when('startDate', {
                        is: (startDate: any) => !!startDate,
                        then: Yup.date().min(
                          Yup.ref('startDate'),
                          t('common.validation.endDate'),
                        ),
                      }),
                      isLocked: Yup.boolean(),
                    }}
                    skipOrientationAuto
                    skipOrder
                  />
                </div>
              )}

              <div className="mx-1">
                <DeleteWeather
                  app={weather}
                  onUpdate={onUpdate}
                  toastMessage="apps.weather.weather_deleted"
                />
              </div>
            </>
          )}
        </div>
      </Cell>
    </Row>
  );
};

export default TableRow;
