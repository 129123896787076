/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';

import { KTSVG } from '../../../../../lib/metronic/helpers';
import Modal from '../../../../Modal';

export default ({
  children,
  src,
  library,
  onUpdate,
}: {
  children?: React.ReactNode;
  src?: string;
  onUpdate?: () => void;
  library?: any;
}) => {
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(false);

  const [isModalVisible, setsIsModalVisible] = useState(false);

  const open = () => {
    setsIsModalVisible(true);
  };

  const close = () => {
    setsIsModalVisible(false);
  };

  const onOk = async () => {
    let id = '';
    setIsDisabled(true);
    [
      ...library?.posters,
      ...library?.content,
      ...library?.videos,
      ...library?.fullscreen,
    ].map(function (item: any) {
      if (item?.media?.thumbnailUrl === src) {
        id = item.id;
      }
    });
    api.libraries.deleteImage(id).then(() => {
      close();
      if (onUpdate) {
        onUpdate();
      }
    });
  };

  return (
    <div className="mb-5 d-flex justify-content-end">
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </span>
        )}
      </span>
      <Modal
        show={isModalVisible}
        onHide={close}
        withFooter={false}
        withHeader={false}
        widthDefaultSize={false}
        bodyClassName="d-flex flex-center flex-column my-7"
      >
        <span className="svg-icon svg-icon-5tx svg-icon-danger mb-10">
          <KTSVG
            path="/media/icons/duotune/general/gen044.svg"
            className="svg-icon-1"
          />
        </span>
        <div className="text-center">
          <h5 className="fw-bolder fs-1 mb-10">
            {' '}
            {`${t('common.delete_confirmation.title')} ${t(
              'common.delete_confirmation.Image',
            )} `}
          </h5>
          <div className="d-flex flex-center flex-wrap">
            <button
              onClick={onOk}
              type="button"
              className="btn btn-danger m-2"
              disabled={isDisabled}
            >
              {isDisabled ? (
                <>
                  {' '}
                  <span>
                    {t('common.deleting')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                </>
              ) : (
                t('common.delete_confirmation.confirm')
              )}
            </button>
            <button
              onClick={close}
              type="button"
              className="btn btn-outline btn-outline-danger btn-active-danger m-2"
              disabled={isDisabled}
            >
              {t('common.delete_confirmation.decline')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
