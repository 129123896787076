import { ErrorResponse } from '../../types/apps/globalTypes';
import {
  CreateLiveStreamFeedRequest,
  CreateScheduleLiveStreamFeedRequest,
  DeleteLiveStreamFeedRequest,
  EditLiveStreamFeedRequest,
  GetLiveStreamFeedsRequest,
  LiveStreamFeedSchema,
  PaginatedLiveStreamFeeds,
  ScheduleLiveStreamFeedRequest,
} from '../../types/apps/liveStreamFeed';

export interface LiveStreamFeedsReduxAction<
  TYPE = LIVE_STREAM_FEED_ACTIONS,
  PAYLOAD = any,
> {
  type: TYPE;
  payload?: PAYLOAD;
}

export enum LIVE_STREAM_FEED_ACTIONS {
  GET_LIVE_STREAM_FEEDS = 'GET_LIVE_STREAM_FEEDS',
  GET_LIVE_STREAM_FEEDS_LOADING = 'GET_LIVE_STREAM_FEEDS_LOADING',
  GET_LIVE_STREAM_FEEDS_SUCCESS = 'GET_LIVE_STREAM_FEEDS_SUCCESS',
  GET_LIVE_STREAM_FEEDS_ERROR = 'GET_LIVE_STREAM_FEEDS_ERROR',
  GET_LIVE_STREAM_FEEDS_RESET = 'GET_LIVE_STREAM_FEEDS_RESET',

  CREATE_LIVE_STREAM_FEED = 'CREATE_LIVE_STREAM_FEED',
  CREATE_LIVE_STREAM_FEED_LOADING = 'CREATE_LIVE_STREAM_FEED_LOADING',
  CREATE_LIVE_STREAM_FEED_SUCCESS = 'CREATE_LIVE_STREAM_FEED_SUCCESS',
  CREATE_LIVE_STREAM_FEED_ERROR = 'CREATE_LIVE_STREAM_FEED_ERROR',
  CREATE_LIVE_STREAM_FEED_RESET = 'CREATE_LIVE_STREAM_FEED_RESET',

  SCHEDULE_LIVE_STREAM_FEED = 'SCHEDULE_LIVE_STREAM_FEED',
  SCHEDULE_LIVE_STREAM_FEED_LOADING = 'SCHEDULE_LIVE_STREAM_FEED_LOADING',
  SCHEDULE_LIVE_STREAM_FEED_SUCCESS = 'SCHEDULE_LIVE_STREAM_FEED_SUCCESS',
  SCHEDULE_LIVE_STREAM_FEED_ERROR = 'SCHEDULE_LIVE_STREAM_FEED_ERROR',
  SCHEDULE_LIVE_STREAM_FEED_RESET = 'SCHEDULE_LIVE_STREAM_FEED_RESET',

  DELETE_LIVE_STREAM_FEED = 'DELETE_LIVE_STREAM_FEED',
  DELETE_LIVE_STREAM_FEED_LOADING = 'DELETE_LIVE_STREAM_FEED_LOADING',
  DELETE_LIVE_STREAM_FEED_SUCCESS = 'DELETE_LIVE_STREAM_FEED_SUCCESS',
  DELETE_LIVE_STREAM_FEED_ERROR = 'DELETE_LIVE_STREAM_FEED_ERROR',
  DELETE_LIVE_STREAM_FEED_RESET = 'DELETE_LIVE_STREAM_FEED_RESET',

  EDIT_LIVE_STREAM_FEED = 'EDIT_LIVE_STREAM_FEED',
  EDIT_LIVE_STREAM_FEED_LOADING = 'EDIT_LIVE_STREAM_FEED_LOADING',
  EDIT_LIVE_STREAM_FEED_SUCCESS = 'EDIT_LIVE_STREAM_FEED_SUCCESS',
  EDIT_LIVE_STREAM_FEED_ERROR = 'EDIT_LIVE_STREAM_FEED_ERROR',
  EDIT_LIVE_STREAM_FEED_RESET = 'EDIT_LIVE_STREAM_FEED_RESET',

  CREATE_SCHEDULE_LIVE_STREAM_FEED = 'CREATE_SCHEDULE_LIVE_STREAM_FEED',
  CREATE_SCHEDULE_LIVE_STREAM_FEED_LOADING = 'CREATE_SCHEDULE_LIVE_STREAM_FEED_LOADING',
  CREATE_SCHEDULE_LIVE_STREAM_FEED_SUCCESS = 'CREATE_SCHEDULE_LIVE_STREAM_FEED_SUCCESS',
  CREATE_SCHEDULE_LIVE_STREAM_FEED_ERROR = 'CREATE_SCHEDULE_LIVE_STREAM_FEED_ERROR',
  CREATE_SCHEDULE_LIVE_STREAM_FEED_RESET = 'CREATE_SCHEDULE_LIVE_STREAM_FEED_RESET',
}

export interface GET_LIVE_STREAM_FEEDS_ACTIONS
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS,
    GetLiveStreamFeedsRequest
  > {}

export const getLiveStreamFeedsActionCreator = (
  payload: GET_LIVE_STREAM_FEEDS_ACTIONS['payload'],
): GET_LIVE_STREAM_FEEDS_ACTIONS => ({
  type: LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS,
  payload,
});

export interface CREATE_LIVE_STREAM_FEED_ACTIONS
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED,
    CreateLiveStreamFeedRequest
  > {}

export const createLiveStreamFeedActionCreator = (
  payload: CREATE_LIVE_STREAM_FEED_ACTIONS['payload'],
): CREATE_LIVE_STREAM_FEED_ACTIONS => ({
  type: LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED,
  payload,
});

export interface CREATE_SCHEDULE_LIVE_STREAM_FEED_ACTIONS
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.CREATE_SCHEDULE_LIVE_STREAM_FEED,
    CreateScheduleLiveStreamFeedRequest
  > {}

export const createScheduleLiveStreamFeedActionCreator = (
  payload: CREATE_SCHEDULE_LIVE_STREAM_FEED_ACTIONS['payload'],
): CREATE_SCHEDULE_LIVE_STREAM_FEED_ACTIONS => ({
  type: LIVE_STREAM_FEED_ACTIONS.CREATE_SCHEDULE_LIVE_STREAM_FEED,
  payload,
});

export interface EDIT_LIVE_STREAM_FEED_ACTIONS
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED,
    EditLiveStreamFeedRequest
  > {}

export const editLiveStreamFeedActionCreator = (
  payload: EDIT_LIVE_STREAM_FEED_ACTIONS['payload'],
): EDIT_LIVE_STREAM_FEED_ACTIONS => ({
  type: LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED,
  payload,
});

export interface SCHEDULE_LIVE_STREAM_FEED_ACTIONS
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED,
    ScheduleLiveStreamFeedRequest
  > {}

export const scheduleLiveStreamFeedActionCreator = (
  payload: SCHEDULE_LIVE_STREAM_FEED_ACTIONS['payload'],
): SCHEDULE_LIVE_STREAM_FEED_ACTIONS => ({
  type: LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED,
  payload,
});

export interface DELETE_LIVE_STREAM_FEED_ACTIONS
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED,
    DeleteLiveStreamFeedRequest
  > {}

export const deleteLiveStreamFeedActionCreator = (
  payload: DELETE_LIVE_STREAM_FEED_ACTIONS['payload'],
): DELETE_LIVE_STREAM_FEED_ACTIONS => ({
  type: LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED,
  payload,
});

// Success action and success action creator
export interface CREATE_LIVE_STREAM_FEED_SUCCESS_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_SUCCESS,
    {
      data: Partial<LiveStreamFeedSchema>;
      loading: boolean;
      isCreateLiveStreamFeedSuccess: boolean;
    }
  > {}

export const createLiveStreamFeedSuccessActionCreator = (
  payload: CREATE_LIVE_STREAM_FEED_SUCCESS_ACTION['payload'],
): CREATE_LIVE_STREAM_FEED_SUCCESS_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_SUCCESS,
  payload,
});

export interface EDIT_LIVE_STREAM_FEED_SUCCESS_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_SUCCESS,
    {
      data: Partial<LiveStreamFeedSchema>;
      loading: boolean;
      isEditLiveStreamFeedSuccess: boolean;
    }
  > {}

export const editLiveStreamFeedSuccessActionCreator = (
  payload: EDIT_LIVE_STREAM_FEED_SUCCESS_ACTION['payload'],
): EDIT_LIVE_STREAM_FEED_SUCCESS_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_SUCCESS,
  payload,
});

export interface GET_LIVE_STREAM_FEEDS_SUCCESS_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_SUCCESS,
    {
      data: PaginatedLiveStreamFeeds;
      loading: boolean;
      isGetLiveStreamFeedsSuccess: boolean;
    }
  > {}

export const getLiveStreamFeedsSuccessActionCreator = (
  payload: GET_LIVE_STREAM_FEEDS_SUCCESS_ACTION['payload'],
): GET_LIVE_STREAM_FEEDS_SUCCESS_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_SUCCESS,
  payload,
});

export interface SCHEDULE_LIVE_STREAM_FEED_SUCCESS_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_SUCCESS,
    {
      data: Partial<LiveStreamFeedSchema>;
      loading: boolean;
      isScheduleLiveStreamFeedSuccess: boolean;
    }
  > {}

export const scheduleLiveStreamFeedSuccessActionCreator = (
  payload: SCHEDULE_LIVE_STREAM_FEED_SUCCESS_ACTION['payload'],
): SCHEDULE_LIVE_STREAM_FEED_SUCCESS_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_SUCCESS,
  payload,
});

export interface DELETE_LIVE_STREAM_FEED_SUCCESS_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_SUCCESS,
    { data: void; loading: boolean; isDeleteLiveStreamFeedSuccess: boolean }
  > {}

export const deleteLiveStreamFeedSuccessActionCreator = (
  payload: DELETE_LIVE_STREAM_FEED_SUCCESS_ACTION['payload'],
): DELETE_LIVE_STREAM_FEED_SUCCESS_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_SUCCESS,
  payload,
});

// Loading action and loading action creator
export interface GET_LIVE_STREAM_FEEDS_LOADING_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_LOADING,
    { loading: boolean }
  > {}

export const getLiveStreamFeedsLoadingActionCreator = (
  payload: GET_LIVE_STREAM_FEEDS_LOADING_ACTION['payload'],
): GET_LIVE_STREAM_FEEDS_LOADING_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_LOADING,
  payload,
});

export interface CREATE_LIVE_STREAM_FEED_LOADING_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_LOADING,
    { loading: boolean }
  > {}

export const createLiveStreamFeedLoadingActionCreator = (
  payload: CREATE_LIVE_STREAM_FEED_LOADING_ACTION['payload'],
): CREATE_LIVE_STREAM_FEED_LOADING_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_LOADING,
  payload,
});

export interface EDIT_LIVE_STREAM_FEED_LOADING_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_LOADING,
    { loading: boolean }
  > {}

export const editLiveStreamFeedLoadingActionCreator = (
  payload: EDIT_LIVE_STREAM_FEED_LOADING_ACTION['payload'],
): EDIT_LIVE_STREAM_FEED_LOADING_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_LOADING,
  payload,
});

export interface SCHEDULE_LIVE_STREAM_FEED_LOADING_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_LOADING,
    { loading: boolean }
  > {}

export const scheduleLiveStreamFeedLoadingActionCreator = (
  payload: SCHEDULE_LIVE_STREAM_FEED_LOADING_ACTION['payload'],
): SCHEDULE_LIVE_STREAM_FEED_LOADING_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_LOADING,
  payload,
});

export interface DELETE_LIVE_STREAM_FEED_LOADING_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_LOADING,
    { loading: boolean }
  > {}

export const deleteLiveStreamFeedLoadingActionCreator = (
  payload: DELETE_LIVE_STREAM_FEED_LOADING_ACTION['payload'],
): DELETE_LIVE_STREAM_FEED_LOADING_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_LOADING,
  payload,
});

// Error action and error action creator
export interface GET_LIVE_STREAM_FEEDS_ERROR_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isGetLiveStreamFeedsSuccess: boolean;
    }
  > {}

export const getLiveStreamFeedsErrorActionCreator = (
  payload: GET_LIVE_STREAM_FEEDS_ERROR_ACTION['payload'],
): GET_LIVE_STREAM_FEEDS_ERROR_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_ERROR,
  payload,
});

export interface CREATE_LIVE_STREAM_FEED_ERROR_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isCreateLiveStreamFeedSuccess: boolean;
    }
  > {}

export const createLiveStreamFeedErrorActionCreator = (
  payload: CREATE_LIVE_STREAM_FEED_ERROR_ACTION['payload'],
): CREATE_LIVE_STREAM_FEED_ERROR_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_ERROR,
  payload,
});

export interface CREATE_SHEDULE_LIVE_STREAM_FEED_ERROR_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.CREATE_SCHEDULE_LIVE_STREAM_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isCreateLiveStreamFeedSuccess: boolean;
    }
  > {}

export const createScheduleLiveStreamFeedErrorActionCreator = (
  payload: CREATE_SHEDULE_LIVE_STREAM_FEED_ERROR_ACTION['payload'],
): CREATE_SHEDULE_LIVE_STREAM_FEED_ERROR_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.CREATE_SCHEDULE_LIVE_STREAM_FEED_ERROR,
  payload,
});

export interface EDIT_LIVE_STREAM_FEED_ERROR_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isEditLiveStreamFeedSuccess: boolean;
    }
  > {}

export const editLiveStreamFeedErrorActionCreator = (
  payload: EDIT_LIVE_STREAM_FEED_ERROR_ACTION['payload'],
): EDIT_LIVE_STREAM_FEED_ERROR_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_ERROR,
  payload,
});

export interface SCHEDULE_LIVE_STREAM_FEED_ERROR_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isScheduleLiveStreamFeedSuccess: boolean;
    }
  > {}

export const scheduleLiveStreamFeedErrorActionCreator = (
  payload: SCHEDULE_LIVE_STREAM_FEED_ERROR_ACTION['payload'],
): SCHEDULE_LIVE_STREAM_FEED_ERROR_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_ERROR,
  payload,
});

export interface DELETE_LIVE_STREAM_FEED_ERROR_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isDeleteLiveStreamFeedSuccess: boolean;
    }
  > {}

export const deleteLiveStreamFeedErrorActionCreator = (
  payload: DELETE_LIVE_STREAM_FEED_ERROR_ACTION['payload'],
): DELETE_LIVE_STREAM_FEED_ERROR_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_ERROR,
  payload,
});

// Reset action and reset action creator
export interface GET_LIVE_STREAM_FEEDS_RESET_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_RESET,
    {
      data: PaginatedLiveStreamFeeds;
      loading: boolean;
      isGetLiveStreamFeedsSuccess: boolean;
      error: null;
    }
  > {}

export const getLiveStreamFeedsResetActionCreator = (
  payload: GET_LIVE_STREAM_FEEDS_RESET_ACTION['payload'],
): GET_LIVE_STREAM_FEEDS_RESET_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_RESET,
  payload,
});

export interface CREATE_LIVE_STREAM_FEED_RESET_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_RESET,
    { error: null; loading: boolean; isCreateLiveStreamFeedSuccess: boolean }
  > {}

export const createLiveStreamFeedResetActionCreator = (
  payload: CREATE_LIVE_STREAM_FEED_RESET_ACTION['payload'],
): CREATE_LIVE_STREAM_FEED_RESET_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_RESET,
  payload,
});

export interface CREATE_SCHEDULE_LIVE_STREAM_FEED_RESET_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.CREATE_SCHEDULE_LIVE_STREAM_FEED_RESET,
    {
      error: null;
      loading: boolean;
      isCreateScheduleLiveStreamFeedSuccess: boolean;
    }
  > {}

export const createScheduleLiveStreamFeedResetActionCreator = (
  payload: CREATE_SCHEDULE_LIVE_STREAM_FEED_RESET_ACTION['payload'],
): CREATE_SCHEDULE_LIVE_STREAM_FEED_RESET_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.CREATE_SCHEDULE_LIVE_STREAM_FEED_RESET,
  payload,
});

export interface EDIT_LIVE_STREAM_FEED_RESET_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_RESET,
    { error: null; loading: boolean; isEditLiveStreamFeedSuccess: boolean }
  > {}

export const editLiveStreamFeedResetActionCreator = (
  payload: EDIT_LIVE_STREAM_FEED_RESET_ACTION['payload'],
): EDIT_LIVE_STREAM_FEED_RESET_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_RESET,
  payload,
});

export interface SCHEDULE_LIVE_STREAM_FEED_RESET_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_RESET,
    { error: null; loading: boolean; isScheduleLiveStreamFeedSuccess: boolean }
  > {}

export const scheduleLiveStreamFeedResetActionCreator = (
  payload: SCHEDULE_LIVE_STREAM_FEED_RESET_ACTION['payload'],
): SCHEDULE_LIVE_STREAM_FEED_RESET_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_RESET,
  payload,
});

export interface DELETE_LIVE_STREAM_FEED_RESET_ACTION
  extends LiveStreamFeedsReduxAction<
    LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_RESET,
    { error: null; loading: boolean; isDeleteLiveStreamFeedSuccess: boolean }
  > {}

export const deleteLiveStreamFeedResetActionCreator = (
  payload: DELETE_LIVE_STREAM_FEED_RESET_ACTION['payload'],
): DELETE_LIVE_STREAM_FEED_RESET_ACTION => ({
  type: LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_RESET,
  payload,
});
