/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../Table';
import App from '../../../../../interfaces/app.interface';
import { AppDependency } from '../../../../../store/types/apps/globalTypes';
import DeleteMsTeams from '../../../../../containers/Apps/DeleteApp';
import ScheduleMsTeams from '../../../../../containers/Apps/ScheduleApp';
import { formatDateString } from '../../../../../utils/dateTime';
import PlacementsEnum from '../../../../../enums/placements.enum';
import Yup from '../../../../../utils/validation';
// import EditMsTeams from '../../../../../containers/Apps/Facebook/EditFacebook';
import Preview from '../../../../../containers/Apps/Preview';
import PreviewFeedModal from '../../PreviewFeedModal';
import Tooltip from '../../../../Tooltip';
// import UpdateFacebook from '../../../../../containers/Apps/Facebook/UpdateFacebook';
import EditMicrosoft from '../../../../../containers/Apps/MsTeams/EditMsTeams';
import { convertLocalDateToUTC } from '../../../../../utils/convertToUTC';
import ReLoginMsTeams from '../../../../../containers/Apps/MsTeams/ReLoginMsTeams';
import RestoreSubscriptionMsTeams from '../../../../../containers/Apps/MsTeams/RestoreSubscriptionMsTeams';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({ app, onUpdate }: { app: App; onUpdate: () => void }) => {
  const { t } = useTranslation();
  const [showModalFeed, setShowModalFeed] = useState(false);
  const [brokenApp, setBrokenApp] = useState<{
    appLostConnection: boolean;
    appSubscriptionExpired: boolean;
  }>();
  const [appSubscriptionExpired, setAppSubscriptionExpired] = useState(false);
  React.useMemo(() => {
    const stateValue = {
      appLostConnection: false,
      appSubscriptionExpired: false,
    };

    console.log(
      new Date(),
      'convertLocalDateToUTC now',
      convertLocalDateToUTC(new Date()),
    );
    console.log(
      app?.dependency?.msTokenCache?.expireDate,
      'convertDBDateToUTC ',
      convertLocalDateToUTC(
        new Date(app?.dependency?.msTokenCache?.expireDate as Date),
      ),
    );

    if (
      !app?.dependency?.msTokenCache?.expireDate ||
      convertLocalDateToUTC(new Date()) >
        convertLocalDateToUTC(
          new Date(app?.dependency?.msTokenCache?.expireDate),
        )
    ) {
      stateValue.appLostConnection = true;
    }
    if (
      !app?.msTeamsSubscription?.expiresAt ||
      convertLocalDateToUTC(new Date()) >
        convertLocalDateToUTC(new Date(app?.msTeamsSubscription?.expiresAt))
    ) {
      stateValue.appSubscriptionExpired = true;
    }
    setBrokenApp(stateValue);
  }, [app]);
  return (
    <Row>
      <Cell isEmpty={!app?.name}>
        <div onClick={() => setShowModalFeed(true)} aria-hidden>
          <span className="text-dark cursor-pointer fw-bold text-hover-primary d-block mb-1 fs-6 w-100">
            {app?.name}
          </span>
        </div>
        <PreviewFeedModal
          show={showModalFeed}
          onHide={() => {
            setShowModalFeed(false);
          }}
          title={t('common.preview')}
          selectedMsteamsFeedSchema={{
            type: 'msTeams',
            createdAt: app!.createdAt,
            updatedAt: app!.updatedAt,
            dependency: app!.dependency as AppDependency,
            id: app!.id,
            name: app!.name,
            placement: app!.placement as PlacementsEnum,
          }}
        />
      </Cell>
      <Cell isEmpty={!app?.dependency?.config?.teamName}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {app?.dependency?.config?.teamName}
        </span>
      </Cell>

      <Cell isEmpty={!app?.dependency?.config?.channelName}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {app?.dependency?.config?.channelName}
        </span>
      </Cell>

      <Cell isEmpty={!app?.dependency?.config?.Accountname}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {app?.dependency?.config?.Accountname}
        </span>
      </Cell>

      <Cell>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {app?.dependency?.config?.msTeamsFeeds?.length ?? 0}
        </span>
      </Cell>

      {/* <Cell isEmpty={!app?.dependency?.config?.facebookPreview}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {app?.dependency?.config?.facebookPreview === 'single_post'
            ? 'Single'
            : 'Wall'}{' '}
          View
        </span>
      </Cell> */}
      <Cell isEmpty={!app?.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {app?.ownerApp?.firstName} {app?.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!app?.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {app?.createdAt && formatDate(app?.createdAt)}
        </span>
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          {!brokenApp?.appLostConnection &&
          !brokenApp?.appSubscriptionExpired ? (
            <>
              <div className="mx-1">
                <Preview msTeams={app} />
              </div>
              <div className="mx-1">
                <EditMicrosoft msTeam={app} onUpdate={onUpdate} />
              </div>

              {PlacementsEnum.FULL_SCREEN && (
                <div className="mx-1">
                  <ScheduleMsTeams
                    app={app}
                    onUpdate={onUpdate}
                    infoValidationSchema={{
                      orientation: Yup.string().required(t('required')),
                      duration: Yup.number().required(t('required')).min(1),
                      startDate: Yup.date(),
                      endDate: Yup.date().when('startDate', {
                        is: (startDate: any) => !!startDate,
                        then: Yup.date().min(
                          Yup.ref('startDate'),
                          t('common.validation.endDate'),
                        ),
                      }),
                      isLocked: Yup.boolean(),
                    }}
                    skipOrientationAuto
                    skipOrder
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="mx-1">
                {brokenApp?.appLostConnection ? (
                  <ReLoginMsTeams msTeam={app}>
                    <Tooltip text={t('common.msTeams_lost_connection')}>
                      <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <i className="fa fa-exclamation-triangle text-warning" />{' '}
                      </span>
                    </Tooltip>
                  </ReLoginMsTeams>
                ) : (
                  <RestoreSubscriptionMsTeams msTeam={app} onUpdate={onUpdate}>
                    <Tooltip text={t('common.msTeams_subscription_expired')}>
                      <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                        <i className="fa fa-exclamation-triangle text-warning" />{' '}
                      </span>
                    </Tooltip>
                  </RestoreSubscriptionMsTeams>
                )}
              </div>
            </>
          )}
          <div className="mx-1">
            <DeleteMsTeams
              title={t('common.delete_confirmation.msTeams')}
              app={app}
              onUpdate={onUpdate}
              toastMessage={t('apps.msTeams.msTeamsDelete')}
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};
export default TableRow;
