import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KTSVG } from '../../../lib/metronic/helpers';
import CardDropdown from '../CardDropdown';

interface FiltersDropdownProps {
  onApplyFilters?: () => void;
  onReset?: () => void;
  className?: string;
  children?: React.ReactNode;
}

interface DefaultProps extends FiltersDropdownProps {
  onApplyFilters: Required<FiltersDropdownProps>['onApplyFilters'];
  onReset: Required<FiltersDropdownProps>['onReset'];
  topicFilter?: boolean;
}

const FiltersDropdown = ({
  onApplyFilters,
  onReset,
  className,
  children,
  topicFilter,
}: DefaultProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>();

  const handleApply = () => {
    setIsOpen(false);

    onApplyFilters();
  };

  const handleReset = () => {
    setIsOpen(false);

    onReset();
  };

  return (
    <CardDropdown
      className={className}
      menuClassName="w-250px w-md-300px"
      show={isOpen}
      onToggle={setIsOpen}
      closeOnSelect={false}
      renderClosed={false}
      toggle={
        <button type="button" className="btn btn-light-primary">
          <KTSVG
            path="/media/icons/duotune/general/gen031.svg"
            className="svg-icon-2"
          />
          {t('common.filter.filter')}
        </button>
      }
    >
      <div className="px-7 py-5">
        <div className="fs-5 text-dark fw-bolder">
          {topicFilter
            ? t('common.filter.topic_filter_options')
            : t('common.filter.filter_options')}
        </div>
      </div>
      <div className="separator border-gray-200" />
      <div className="px-7 py-5">
        {children}
        <div className="d-flex justify-content-end">
          <button
            onClick={handleReset}
            className="btn btn-sm btn-white btn-active-light-primary me-2"
            type="button"
          >
            {t('common.reset')}
          </button>
          <button
            onClick={handleApply}
            className="btn btn-sm btn-primary"
            type="submit"
          >
            {t('common.apply')}
          </button>
        </div>
      </div>
    </CardDropdown>
  );
};

FiltersDropdown.defaultProps = {
  onApplyFilters: () => {},
  onReset: () => {},
};

export default FiltersDropdown;
