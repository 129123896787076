import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DeviceGroup from '../../../../interfaces/deviceGroup.interface';
import RemoveDeviceGroup from '../../../../containers/DevicesGroups/RemoveGroup';
import { formatDateString } from '../../../../utils/dateTime';
import ButtonDropdown from '../../../Dropdown/ButtonDropdown';
import WithClickBackdrop from '../../../WithClickBackdrop';
import CheckBox from '../../../CheckBox';
// import EditGroup from '../../EditGroup';
import Table from '../../../Table';
import UpdateDeviceGroupAction from '../../../../store/actions/devicesGroups/interfaces/updateDeviceGroupAction.interface';
import styles from '../../devicesGroups.module.scss';
import routeConfig from '../../../../routing/config';
import ShowMoreCell from '../../../ShowMoreCell';
import DevicePlaylistsLink from '../../../Devices/DevicePlaylistsLink';
import { getPlaylistInfoRoute } from '../../../../utils/playlists';
import Tooltip from '../../../Tooltip';
import GlobalState from '../../../../store/reducers/globalState.interface';
import { UserRole } from '../../../../enums/userRole.enum';
import { readFromQueryString } from '../../../../utils/queryParams';

const createDeviceComponent: any = {};

export function registerComponent(name: string, Component: any) {
  createDeviceComponent[name] = Component;
}

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

interface TableRowProps {
  group: DeviceGroup;
  config?: {
    skipActions?: boolean;
    isSelectable?: boolean;
    drownDown?: boolean;
  };
  isChecked?: boolean;
  onCheck?: (id: string) => void;
}

interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
  isChecked: Required<TableRowProps>['isChecked'];
  onCheck: Required<TableRowProps>['onCheck'];
  onEditDeviceGroup?: (data: UpdateDeviceGroupAction['payload']) => void;
  unAssignDeviceGroup?: (deviceGroupId: string, userGroupId: string) => void;
  removeDeviceGroup?: (idDeviceGroup: string) => void;
  showIcon?: boolean;
  drownDown?: boolean;
  userProfileId?: string;
  isPowerBiPublished?: boolean;
}

const DevicesGroupsTableRow = ({
  group,
  config,
  isChecked,
  userProfileId,
  removeDeviceGroup,
  onCheck,
  onEditDeviceGroup,
  unAssignDeviceGroup,
  showIcon = false,
  isPowerBiPublished,
}: DefaultProps) => {
  const { t } = useTranslation();
  const groupRoute = `${routeConfig.devicesGroups.route}/${group?.id}`;
  const groupDevicesRoute = `${groupRoute}/devices`;
  const { roles } = useSelector((state: GlobalState) => state.userData.user);
  const CreateComponentDevicesGroup = createDeviceComponent.CreateDeviceGroup;
  const { search } = useLocation();
  const currentUserId = readFromQueryString(search, 'currentUserId') || '';

  return (
    <Row>
      {config.isSelectable && (
        <Cell className={styles.checkbox}>
          <CheckBox
            name="deviceId"
            id={group.id}
            onChange={() => onCheck(group.id)}
            checked={isChecked}
            className="mt-1"
            disabled={
              !!(
                isPowerBiPublished &&
                group.licenses.some((license) => license.name !== 'Enterprise')
              )
            }
          />
        </Cell>
      )}

      <Cell isNoWrap>
        {userProfileId && roles.includes(UserRole.SuperAdmin) && (
          <Link
            to={`${groupDevicesRoute}?currentUserId=${userProfileId}`}
            className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
          >
            {group.name}
          </Link>
        )}
        {!userProfileId && !roles.includes(UserRole.SuperAdmin) && (
          <Link
            to={`${groupDevicesRoute}`}
            className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
          >
            {group.name}
          </Link>
        )}

        {!userProfileId && roles.includes(UserRole.SuperAdmin) && (
          <div className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
            {group.name}
          </div>
        )}
        {userProfileId && !roles.includes(UserRole.SuperAdmin) && (
          <Link
            to={`${groupDevicesRoute}?currentUserId=${userProfileId}`}
            className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
          >
            {group.name}
          </Link>
        )}
      </Cell>
      <Cell isEmpty={!group?.devices?.[0]?.name}>
        {group?.devices?.length > 1 ? (
          <ShowMoreCell
            itemNames={group?.devices?.map((device) => device?.name)}
            widthDefaultSize
            title={t('Assigned Devices')}
            bodyClassName="justify-content-center"
          >
            <div className="row w-100">
              {group?.devices?.map((device) => (
                <div className="col-lg-3 col-sm-12 mb-5">
                  <span
                    className={clsx(
                      'w-100 text-center bg-light text-primary rounded p-2 d-block',
                    )}
                  >
                    <DevicePlaylistsLink
                      device={device}
                      isAdmin={roles.includes(UserRole.SuperAdmin)}
                      currentUserId={currentUserId}
                    >
                      {device.name}
                    </DevicePlaylistsLink>
                    {/* {`${device.name}`} */}
                  </span>
                </div>
              ))}
            </div>
          </ShowMoreCell>
        ) : (
          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center">
            {/* {`${group?.devices?.[0]?.name}`} */}

            <DevicePlaylistsLink
              device={group?.devices?.[0]}
              isAdmin={roles.includes(UserRole.SuperAdmin)}
              currentUserId={currentUserId}
            >
              {group?.devices?.[0]?.name}
            </DevicePlaylistsLink>
          </span>
        )}
      </Cell>
      <Cell isEmpty={!group?.users?.[0]?.firstName}>
        {group?.users?.length > 1 ? (
          <ShowMoreCell
            itemNames={group?.users?.map((user) => user?.firstName)}
            widthDefaultSize
            title={t('Users with access')}
            bodyClassName="justify-content-center"
          >
            <div className="row w-100">
              {group?.users?.map((user) => (
                <div className="col-lg-3 col-sm-12 mb-5">
                  <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center">
                    <Link
                      to={routeConfig.user.route
                        .replace(':id', user?.id)
                        .replace(':page', 'devices')}
                      className={clsx(
                        'w-100 text-center bg-light text-primary rounded p-2 d-block',
                      )}
                    >
                      {`${user.firstName} ${user.lastName}`}
                    </Link>
                  </span>
                </div>
              ))}
            </div>
          </ShowMoreCell>
        ) : (
          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center">
            <Link
              to={routeConfig.user.route
                .replace(':id', group?.users?.[0]?.id)
                .replace(':page', `devices`)}
              className={clsx('text-gray-800 text-hover-primary mb-1')}
            >
              {`${group?.users?.[0]?.firstName} ${group?.users?.[0]?.lastName}`}
            </Link>
          </span>
        )}
      </Cell>
      <Cell isEmpty={!group?.playlists?.[0]?.name}>
        {group?.playlists?.length > 1 ? (
          <ShowMoreCell
            itemNames={group?.playlists?.map((playlist) => playlist?.name)}
            widthDefaultSize
            title={t('Playlists')}
            bodyClassName="justify-content-center"
          >
            <div className="row w-100">
              {group?.playlists?.map((playlist) => (
                <div className="col-lg-3 col-sm-12 mb-5">
                  <span
                    className={clsx(
                      'w-100 text-center bg-light text-primary rounded p-2 d-block',
                    )}
                  >
                    <Link
                      to={getPlaylistInfoRoute(
                        playlist.id,
                        undefined,
                        false,
                        playlist,
                      )}
                      className="text-reset"
                    >
                      {`${playlist.name}`}
                    </Link>
                  </span>
                </div>
              ))}
            </div>
          </ShowMoreCell>
        ) : (
          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center">
            <Link
              to={getPlaylistInfoRoute(
                group?.playlists?.[0]?.id,
                undefined,
                false,
                group?.playlists?.[0],
              )}
              className="text-reset"
            >
              {`${group?.playlists?.[0]?.name}`}
            </Link>
          </span>
        )}
      </Cell>
      {showIcon ? (
        <Cell isNoWrap>
          <Link className="text-reset" target="_blank" to={groupDevicesRoute}>
            <div
              role="presentation"
              className="text-dark fw-bold d-block mb-1 fs-6 hoverable text-center"
            >
              <i className="fas fa-eye" />
            </div>
          </Link>
        </Cell>
      ) : (
        <Cell isEmpty={!group.createdAt} isNoWrap>
          <div className="text-dark fw-bold d-block mb-1 fs-6 text-center">
            {group.createdAt && formatDate(group.createdAt)}
          </div>
        </Cell>
      )}
      {!config.skipActions && (
        <Cell isNoWrap isTextEnd>
          <div className="d-flex justify-content-end">
            {!config.drownDown ? (
              <ButtonDropdown
                text={t('common.actions')}
                items={[
                  {
                    key: 'edit',
                    content: (
                      <CreateComponentDevicesGroup
                        group={group}
                        isEdit
                        onSubmitUpdate={onEditDeviceGroup}
                      />
                    ),
                  },
                  {
                    key: 'delete',
                    content: (
                      <RemoveDeviceGroup
                        group={group}
                        unAssignDeviceGroup={unAssignDeviceGroup}
                        onRemoveDeviceGroup={removeDeviceGroup}
                      >
                        <WithClickBackdrop>
                          {t('common.delete')}
                        </WithClickBackdrop>
                      </RemoveDeviceGroup>
                    ),
                  },
                ]}
              />
            ) : (
              <>
                <RemoveDeviceGroup
                  group={group}
                  unAssignDeviceGroup={unAssignDeviceGroup}
                >
                  <Tooltip text={t('common.unssign')}>
                    <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                      <i className="fa fa-trash" />
                    </span>
                  </Tooltip>
                </RemoveDeviceGroup>
              </>
            )}
          </div>
        </Cell>
      )}
    </Row>
  );
};
DevicesGroupsTableRow.defaultProps = {
  config: {
    skipActions: false,
    isSelectable: false,
  },
  isChecked: false,
  onCheck: () => null,
};

export default DevicesGroupsTableRow;
