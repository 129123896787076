import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { useParams } from 'react-router';
import api from '../../api';
import SelectAsync, {
  SelectedValue,
} from '../../components/Select/SelectAsync';
import useIsAdmin from '../../utils/hooks/useIsAdmin';
import dropdowns from '../../constants/dropdownsData';

interface SelectLocationsProps {
  onChange?: (selectedValues: SelectedValue[]) => void;
  initialValue?: string[];
  id?: string;
  className?: string;
  preselect?: boolean;
  isLoadingLocations?: boolean;
  companyIds?: string[];
  showDefaultOptions?: boolean;
}

interface DefaultProps extends SelectLocationsProps {
  onChange: Required<SelectLocationsProps>['onChange'];
  initialValue: Required<SelectLocationsProps>['initialValue'];
}

const SelectLocations = ({
  onChange,
  initialValue,
  id,
  className,
  preselect,
  isLoadingLocations,
  companyIds,
  showDefaultOptions = true,
}: DefaultProps) => {
  const { limit } = dropdowns;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [setSelectedValues, setSelectedValue] = useState<SelectedValue[]>([]);
  const [selectedValuesop, setSelectedValue] = useState<SelectedValue[]>([
    {
      value: 'loadingValue',
      label: `${t('common.loading')}...`,
    },
  ]);
  const [loadedLocations, setLoadedLocations] = useState<
    SelectedValue[] | undefined
  >(undefined);
  const [defaultLocations, setDefaultLocations] = useState<SelectedValue[]>([]);
  const [isAdmin] = useIsAdmin();
  const { id: currentUserId } = useParams<{ id: string }>();

  // useEffect(() => {
  //   if (!selectedValuesop)
  //     setSelectedValue([
  //       {
  //         label: `${t('common.loading')}...`,
  //       },
  //     ]);
  // }, [isLoadingLocations, initialValue]);
  useEffect(() => {
    if (loadedLocations)
      setSelectedValue(
        loadedLocations?.filter((location) =>
          initialValue?.includes(location.value as string),
        ) as SelectedValue[],
      );
  }, [isLoadingLocations, loadedLocations]);

  const handleLoad = async (search?: string): Promise<SelectedValue[]> => {
    if (!search) {
      setIsLoading(true);
    }
    let locations: SelectedValue[] = [];
    if (companyIds?.length || showDefaultOptions) {
      let adminLocationsResult;
      if (isAdmin && currentUserId) {
        adminLocationsResult = await api.locations.getAdminLocations({
          search,
          limit,
          companyIds,
          currentUserId,
        });
      } else {
        adminLocationsResult = await api.locations.getLocations({
          search,
          limit,
          companyIds,
        });
      }
      const {
        items,
        meta: { totalItems },
      } = adminLocationsResult;

      locations = sortBy(items, ['name']).map(({ id: value, name }) => ({
        value,
        label: name,
      }));
      if (search) {
        return locations;
      }
      if (preselect && totalItems === 1 && !search) {
        const [item] = items;

        setSelectedValue([{ label: item.name, value: item.id }]);
      }
      if (!search) {
        setDefaultLocations(locations);
      }
    } else {
      setDefaultLocations(locations);
    }
    if (!search) {
      setIsLoading(false);
    }

    setLoadedLocations(locations);

    return locations;
  };

  useEffect(() => {
    handleLoad();
  }, [companyIds]);

  // usePrevValues(companyIds as string[], (prevCompanyIds: string[]) => {
  //   if (
  //     companyIds &&
  //     !containsUndefined(prevCompanyIds) &&
  //     !containsUndefined(companyIds) &&
  //     !isEqual(prevCompanyIds, companyIds)
  //   ) {
  //     setSelectedValue([]);
  //   }
  // });

  return (
    <SelectAsync
      id={id}
      initialValue={selectedValuesop}
      isLoading={isLoading}
      onChange={(selectedValues: SelectedValue[]) => onChange(selectedValues)}
      loadOptions={handleLoad}
      className={className}
      placeholder={t('locations.select_locations')}
      isClearable
      isMulti
      defaultOptions={defaultLocations}
    />
  );
};

SelectLocations.defaultProps = {
  initialValue: [],
  onChange: () => null,
};

export default SelectLocations;
