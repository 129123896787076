import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../../../constants/api';
import LocationsRoutes from './enums/locationsRoutes.enum';
import GetLocationsRequestData from './interfaces/getLocationsRequestData.interface';
import GetLocationsResponseData from './interfaces/getLocationsResponseData.interface';
import CreateAdminLocationRequestData from './interfaces/createAdminLocationRequestData.interface';
import EditAdminLocationRequestData from './interfaces/editAdminLocationRequestData.interface';
import LocationsByIdsQueryParam from './enums/locationsByIdsQueryParam.enum';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import Location from '../../../interfaces/location.interface';

export default {
  /**
   * Retrieves the list of locations
   * @param requestData
   */
  getLocations: async (
    requestData: GetLocationsRequestData,
  ): Promise<GetLocationsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LocationsRoutes.Locations}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Retrieves the list of locations
   * @param requestData
   */
  getLocationsUnderCompany: async (
    requestData: {
      search?: string;
      page?: string;
      limit?: string;
      sortBy?: string;
      sortOrder?: string;
    },
    companyId?: string,
  ): Promise<GetLocationsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LocationsRoutes.Locations}/${companyId}/locations`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of locations by ids
   * @param ids
   * @param currentUserId
   */
  getLocationsByIds: async (
    ids: string[],
    currentUserId?: string,
  ): Promise<Location[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LocationsRoutes.Locations}`,
      params: { [LocationsByIdsQueryParam.LocationIds]: ids, currentUserId },
    });

    return data.items;
  },

  /**
   * Retrieves the list of locations by ids (admon)
   * @param ids
   * @param currentUserId
   */
  getAdminLocationsByIds: async (
    ids: string[],
    currentUserId?: string,
  ): Promise<Location[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LocationsRoutes.AdminLocations}`,
      params: { [LocationsByIdsQueryParam.LocationIds]: ids, currentUserId },
    });

    return data.items;
  },

  /**
   * Retrieves the list of admin locations
   * @param requestData
   */
  getAdminLocations: async (
    requestData: GetLocationsRequestData,
  ): Promise<GetLocationsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LocationsRoutes.AdminLocations}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the location by id
   * @param id
   */
  getLocation: async (id: string): Promise<Location> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LocationsRoutes.Locations}/${id}`,
    });

    return data;
  },

  /**
   * Retrieves the location by id (for admin)
   * @param id
   * @param currentUserId
   */
  getAdminLocation: async (
    id: string,
    currentUserId?: string,
  ): Promise<Location> => {
    const { data } = await axios({
      method: 'get',
      // TODO: Replace the LocationsRoutes.Locations on LocationsRoutes.AdminLocations
      url: `${API_URL}/${LocationsRoutes.AdminLocations}/${id}`,
      params: sanitizeQueryParams({ currentUserId }),
    });

    return data;
  },

  /**
   * Create location
   * @param requestData
   */
  createAdminLocation: async (
    requestData: CreateAdminLocationRequestData,
  ): Promise<Location> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${LocationsRoutes.AdminLocations}`,
      data: requestData,
    });

    return data;
  },

  /**
   * Create location
   * @param requestData
   */
  createLocation: async (
    requestData: CreateAdminLocationRequestData,
  ): Promise<Location> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${LocationsRoutes.Locations}`,
      data: requestData,
    });

    return data;
  },

  /**
   * Edit location
   * @param locationId
   * @param requestData
   */
  editAdminLocation: async (
    locationId: string,
    requestData: EditAdminLocationRequestData,
  ): Promise<Location> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${LocationsRoutes.AdminLocations}/${locationId}`,
      data: requestData,
    });

    return data;
  },

  /**
   * Edit location
   * @param locationId
   * @param requestData
   */
  editLocation: async (
    locationId: string,
    requestData: EditAdminLocationRequestData,
  ): Promise<Location> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${LocationsRoutes.Locations}/${locationId}`,
      data: requestData,
    });

    return data;
  },

  /**
   * Delete location
   * @param locationId
   */
  deleteAdminLocation: async (locationId: string): Promise<Location> => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${LocationsRoutes.AdminLocations}/${locationId}`,
      throwError: true,
    } as AxiosRequestConfig);

    return data;
  },

  /**
   * Delete location
   * @param locationId
   */
  deleteLocation: async (locationId: string): Promise<Location> => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${LocationsRoutes.Locations}/${locationId}`,
      throwError: true,
    } as AxiosRequestConfig);

    return data;
  },
};
