import { map } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import clsx from 'clsx';
import SortOrder from '../../../../enums/sortOrder.enum';
import App from '../../../../interfaces/app.interface';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../../utils/hooks/useTableMultiSelect';
import { readFromQueryString } from '../../../../utils/queryParams';
import Table from '../../../Table';
import Columns from '../../../Table/Columns';
import WithSpinner from '../../../WithSpinner';
import EmptyFiles from '../../../../pages/FileManager/EmptyFiles';
import TableRow from './TableRow';
import styles from './TableRow/tablerow.module.scss';

export default ({
  teamsApps,
  isLoading,
  sortQueryField,
  orderQueryField,
  onUpdate,
  preloaderHeight = 300,
  isSelectable = false,
  multiSelectConfig: { selectedItems, selectAll },
}: {
  teamsApps: App[];
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight: number;
  isSelectable?: boolean;
  onUpdate: () => void;
  multiSelectConfig: TableMultiSelectConfig;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  const columns = [
    {
      name: t('apps.msTeams.table.title'),
    },
    {
      name: t('apps.msTeams.table.team'),
    },
    {
      name: t('apps.msTeams.table.channel'),
    },
    {
      name: t('apps.msTeams.table.account'),
    },
    {
      name: t('apps.msTeams.table.numberOfFeeds'),
    },
    {
      name: t('apps.common.table.createdBy'),
    },
    {
      name: t('apps.msTeams.table.dateCreated'),
    },
    {
      name: t('common.actions'),
      className: clsx('text-center w-1', styles.actions),
    },
  ];

  return (
    <Table>
      <Table.Head.Row>
        <Columns
          columns={columns}
          checked={isAllSelected(selectedItems, map(teamsApps, 'id'))}
          onChange={selectAll}
          config={{
            sortField,
            setSortField,
            sortQueryField,
            orderQueryField,
            isSelectable,
          }}
        />
      </Table.Head.Row>

      <Table.Body
        isEmpty={!isLoading && !teamsApps.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={
          <EmptyFiles descriptionText={t('apps.msTeams.noMsTeamsFound')} />
        }
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {teamsApps.map((team) => (
            <TableRow key={team.id} app={team} onUpdate={onUpdate} />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
