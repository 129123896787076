import axios, { AxiosError, AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import { API_URL } from '../../../constants/api';

import {
  getLiveStreamFeedsLoadingActionCreator,
  getLiveStreamFeedsSuccessActionCreator,
  getLiveStreamFeedsErrorActionCreator,
  createLiveStreamFeedLoadingActionCreator,
  createLiveStreamFeedSuccessActionCreator,
  createLiveStreamFeedErrorActionCreator,
  editLiveStreamFeedLoadingActionCreator,
  editLiveStreamFeedSuccessActionCreator,
  editLiveStreamFeedErrorActionCreator,
  scheduleLiveStreamFeedLoadingActionCreator,
  scheduleLiveStreamFeedSuccessActionCreator,
  scheduleLiveStreamFeedErrorActionCreator,
  deleteLiveStreamFeedLoadingActionCreator,
  deleteLiveStreamFeedSuccessActionCreator,
  deleteLiveStreamFeedErrorActionCreator,
  LIVE_STREAM_FEED_ACTIONS,
  CREATE_LIVE_STREAM_FEED_ACTIONS,
  DELETE_LIVE_STREAM_FEED_ACTIONS,
  EDIT_LIVE_STREAM_FEED_ACTIONS,
  GET_LIVE_STREAM_FEEDS_ACTIONS,
  SCHEDULE_LIVE_STREAM_FEED_ACTIONS,
  CREATE_SCHEDULE_LIVE_STREAM_FEED_ACTIONS,
  createScheduleLiveStreamFeedErrorActionCreator,
} from '../../actions/apps';
import { getUserData } from '../../actions/userData';

import { ErrorResponse } from '../../types/apps/globalTypes';
import {
  GetLiveStreamFeedsRequest,
  PaginatedLiveStreamFeeds,
  LiveStreamFeedSchema,
  CreateLiveStreamFeedRequest,
  DeleteLiveStreamFeedRequest,
  EditLiveStreamFeedRequest,
  ScheduleLiveStreamFeedRequest,
} from '../../types/apps/liveStreamFeed';

const LIVE_STREAM_FEED_APP_URL = `${API_URL}/apps/live-stream`;

// services

export const getLiveStreamFeedsService = async ({
  params,
}: GetLiveStreamFeedsRequest): Promise<PaginatedLiveStreamFeeds> => {
  const response = await axios({
    method: 'GET',
    url: LIVE_STREAM_FEED_APP_URL,
    params,
  });

  return response.data;
};

function* getLiveStreamFeedsWorker({ payload }: GET_LIVE_STREAM_FEEDS_ACTIONS) {
  try {
    yield put(getLiveStreamFeedsLoadingActionCreator({ loading: true }));

    const response: PaginatedLiveStreamFeeds = yield call(
      getLiveStreamFeedsService,
      payload as GetLiveStreamFeedsRequest,
    );

    yield put(
      getLiveStreamFeedsSuccessActionCreator({
        data: response,
        loading: false,
        isGetLiveStreamFeedsSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      getLiveStreamFeedsErrorActionCreator({
        error: response.data,
        loading: false,
        isGetLiveStreamFeedsSuccess: false,
      }),
    );
  }
}

export const createLiveStreamFeedService = async (
  request: CreateLiveStreamFeedRequest,
): Promise<Partial<LiveStreamFeedSchema>> => {
  const response = await axios({
    method: 'POST',
    url: LIVE_STREAM_FEED_APP_URL,
    data: request,
  });

  return response.data;
};

function* createLiveStreamFeedWorker({
  payload,
}: CREATE_LIVE_STREAM_FEED_ACTIONS) {
  try {
    yield put(createLiveStreamFeedLoadingActionCreator({ loading: true }));

    const response: Partial<LiveStreamFeedSchema> = yield call(
      createLiveStreamFeedService,
      payload as CreateLiveStreamFeedRequest,
    );

    yield put(
      createLiveStreamFeedSuccessActionCreator({
        data: response,
        loading: false,
        isCreateLiveStreamFeedSuccess: true,
      }),
    );

    yield put(getUserData());
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      createLiveStreamFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isCreateLiveStreamFeedSuccess: false,
      }),
    );
  }
}

export const editLiveStreamFeedService = async (
  request: EditLiveStreamFeedRequest,
): Promise<Partial<LiveStreamFeedSchema>> => {
  const response = await axios({
    method: 'PATCH',
    url: `${API_URL}/apps/${request.id}`,
    data: request,
  });

  return response.data;
};

function* editLiveStreamFeedWorker({ payload }: EDIT_LIVE_STREAM_FEED_ACTIONS) {
  try {
    yield put(editLiveStreamFeedLoadingActionCreator({ loading: true }));

    const response: Partial<LiveStreamFeedSchema> = yield call(
      editLiveStreamFeedService,
      payload as EditLiveStreamFeedRequest,
    );

    yield put(
      editLiveStreamFeedSuccessActionCreator({
        data: response,
        loading: false,
        isEditLiveStreamFeedSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      editLiveStreamFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isEditLiveStreamFeedSuccess: false,
      }),
    );
  }
}

export const scheduleLiveStreamFeedService = async (
  request: ScheduleLiveStreamFeedRequest,
): Promise<Partial<LiveStreamFeedSchema>> => {
  const response = await axios({
    method: 'POST',
    url: `${LIVE_STREAM_FEED_APP_URL}/${request.appId}`,
    data: request,
  });

  return response.data;
};

function* scheduleLiveStreamFeedWorker({
  payload,
}: SCHEDULE_LIVE_STREAM_FEED_ACTIONS) {
  try {
    yield put(scheduleLiveStreamFeedLoadingActionCreator({ loading: true }));

    const response: Partial<LiveStreamFeedSchema> = yield call(
      scheduleLiveStreamFeedService,
      payload as ScheduleLiveStreamFeedRequest,
    );

    yield put(
      scheduleLiveStreamFeedSuccessActionCreator({
        data: response,
        loading: false,
        isScheduleLiveStreamFeedSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      scheduleLiveStreamFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isScheduleLiveStreamFeedSuccess: false,
      }),
    );
  }
}

export const deleteLiveStreamFeedService = async (
  request: DeleteLiveStreamFeedRequest,
): Promise<void> => {
  const response = await axios({
    method: 'DELETE',
    url: `${API_URL}/apps/${request.id}`,
  });

  return response.data;
};

function* deleteLiveStreamFeedWorker({
  payload,
}: DELETE_LIVE_STREAM_FEED_ACTIONS) {
  try {
    yield put(deleteLiveStreamFeedLoadingActionCreator({ loading: true }));

    const response: Partial<void> = yield call(
      deleteLiveStreamFeedService,
      payload as DeleteLiveStreamFeedRequest,
    );

    yield put(
      deleteLiveStreamFeedSuccessActionCreator({
        data: response,
        loading: false,
        isDeleteLiveStreamFeedSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      deleteLiveStreamFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isDeleteLiveStreamFeedSuccess: false,
      }),
    );
  }
}

function* createScheduleLiveStreamFeedWorker({
  payload,
}: CREATE_SCHEDULE_LIVE_STREAM_FEED_ACTIONS) {
  try {
    yield put(createLiveStreamFeedLoadingActionCreator({ loading: true }));

    const response: Partial<LiveStreamFeedSchema> = yield call(
      createLiveStreamFeedService,
      payload?.LiveStreamFeed as CreateLiveStreamFeedRequest,
    );

    const scheduleFeed = payload!.scheduleFeed!;
    scheduleFeed.appId = response!.id!;

    yield put(
      createLiveStreamFeedSuccessActionCreator({
        data: { name: payload?.LiveStreamFeed?.name, ...response },
        loading: false,
        isCreateLiveStreamFeedSuccess: true,
      }),
    );

    yield put(scheduleLiveStreamFeedLoadingActionCreator({ loading: true }));

    const response1: Partial<LiveStreamFeedSchema> = yield call(
      scheduleLiveStreamFeedService,
      scheduleFeed as ScheduleLiveStreamFeedRequest,
    );

    yield put(
      scheduleLiveStreamFeedSuccessActionCreator({
        data: response1,
        loading: false,
        isScheduleLiveStreamFeedSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      createLiveStreamFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isCreateLiveStreamFeedSuccess: false,
      }),
    );
    yield put(
      createScheduleLiveStreamFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isCreateLiveStreamFeedSuccess: false,
      }),
    );
  }
}

// watchers
export default function* LiveStreamFeedsWatcher() {
  yield takeEvery<GET_LIVE_STREAM_FEEDS_ACTIONS>(
    LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS,
    getLiveStreamFeedsWorker,
  );

  yield takeEvery<CREATE_LIVE_STREAM_FEED_ACTIONS>(
    LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED,
    createLiveStreamFeedWorker,
  );
  yield takeEvery<CREATE_SCHEDULE_LIVE_STREAM_FEED_ACTIONS>(
    LIVE_STREAM_FEED_ACTIONS.CREATE_SCHEDULE_LIVE_STREAM_FEED,
    createScheduleLiveStreamFeedWorker,
  );

  yield takeEvery<SCHEDULE_LIVE_STREAM_FEED_ACTIONS>(
    LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED,
    scheduleLiveStreamFeedWorker,
  );

  yield takeEvery<DELETE_LIVE_STREAM_FEED_ACTIONS>(
    LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED,
    deleteLiveStreamFeedWorker,
  );

  yield takeEvery<EDIT_LIVE_STREAM_FEED_ACTIONS>(
    LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED,
    editLiveStreamFeedWorker,
  );
}
