import SetQueryParamsAction from './interfaces/setQueryParamsAction.interface';
import BreadcrumbsActions from './breadcrumbsActions.enum';
import SetPlaceHolderAction from './interfaces/setPlaceHolderAction.interface';
import ClearPlaceHolderAction from './interfaces/clearPlaceHolderAction.interface';
import ClearQueryParamsAction from './interfaces/clearQueryParamsAction.interface';

export const setQueryParams = (
  payload: SetQueryParamsAction['payload'],
): SetQueryParamsAction => ({
  type: BreadcrumbsActions.SET_BREADCRUMBS_QP,
  payload,
});

export const setPlaceHolder = (
  payload: SetPlaceHolderAction['payload'],
): SetPlaceHolderAction => ({
  type: BreadcrumbsActions.SET_BREADCRUMBS_PLACEHOLDER,
  payload,
});
export const clearPlaceHolder = (): ClearPlaceHolderAction => ({
  type: BreadcrumbsActions.CLEAR_BREADCRUMBS_PLACEHOLDER,
});
export const clearQueryParams = (): ClearQueryParamsAction => ({
  type: BreadcrumbsActions.CLEAR_BREADCRUMBS_QP,
});
