enum UsersQueryParam {
  Search = 'search',
  Status = 'status',
  Role = 'role',
  CompanyId = 'companyId',
  LocationId = 'locationId',
  ManagerId = 'managerId',
}

export default UsersQueryParam;
