import React from 'react';
import { useTranslation } from 'react-i18next';
import AssignPlaylists from '../../../../../containers/Playlists/AssignPlaylists';
import CopyContentSteps from '../../../../../enums/steps/copyContentSteps.enum';
import CopyContentStepsData from '../../copyContentStepsData.interface';
import WithSpinner from '../../../../WithSpinner';
import StepsTitle from '../../StepsTitle';
import Modal from '../../../../Modal';

export default ({
  data,
  setData,
  setMode,
  onSubmit,
  isLoading = false,
}: {
  data: Partial<CopyContentStepsData>;
  setData: (newData: Partial<CopyContentStepsData>) => void;
  setMode: (mode: CopyContentSteps) => void;
  onSubmit: () => void;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <WithSpinner
      isLoading={!!isLoading}
      className="min-h-400px min-w-400px"
      size="md"
    >
      <StepsTitle currentStep={CopyContentSteps.Playlists} />

      <AssignPlaylists
        requestData={{
          devicesIds: data.deviceIds,
          deviceGroupsIds: data.deviceGroupsIds,
        }}
        preselectedItems={data.playlistIds ?? []}
        onSelectionChange={(selectedItems) =>
          setData({ ...data, playlistIds: selectedItems })
        }
      />

      <Modal.Separator withoutDefaultMargins className="mt-6 mb-7" />
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-white text-primary"
          onClick={() => setMode(CopyContentSteps.DevicesAndGroups)}
        >
          {t('common.prev_step')}
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!data.playlistIds?.length}
          onClick={onSubmit}
        >
          {t('common.submit')}
        </button>
      </div>
    </WithSpinner>
  );
};
