/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import Tabs from 'antd/lib/tabs';
import Card from '../../components/Card';
import Step from './Step';
import StepContent from './StepContent';
import GlobalState from '../../store/reducers/globalState.interface';
// import api from '../../api';
// import { getUserData } from '../../store/actions/userData';
// import WithSpinner from '../../components/WithSpinner';
import './onboarding.css';
import config from '../../routing/config';

export default () => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();

  const onboarding = useSelector((state: GlobalState) => {
    return state.userData.user.onboarding;
  });
  const deviceId = useSelector((state: GlobalState) => {
    const deviceList = state?.devices?.devices;
    return deviceList && deviceList.length > 0 ? deviceList[0].id : '';
  });
  const [more, showMore] = useState<boolean>(true);
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [completePercent, setCompletePercent] = useState<number>(0);

  useEffect(() => {
    if (onboarding) {
      let total = 0;
      if (onboarding?.watchedWelcomeVideo) total += 1;
      if (onboarding?.playlistCreated) total += 1;
      if (onboarding?.contentAdded) total += 1;
      if (onboarding?.viewAppsPage) total += 1;
      if (onboarding?.appsIntegration) total += 1;
      if (onboarding?.devicePreview) total += 1;

      if (total === 6) {
        setCompletePercent(100);
      } else {
        setCompletePercent(total * 16);
      }
    }
  }, [onboarding]);

  // const handleClick = async () => {
  //   setIsLoading(true);
  //   try {
  //     await api.account.skipSteps();
  //   } finally {
  //     setIsLoading(false);
  //   }
  //   dispatch(getUserData());
  // };

  return (
    <Card withoutMargins style={{ height: '100%' }}>
      <Card.Header>
        <Card.Header.Title>
          <div className="d-flex justify-content-between w-100">
            <Card.Header.Title.Label>
              Welcome! Let's get started:
            </Card.Header.Title.Label>
            <button
              type="button"
              onClick={() => showMore(!more)}
              className="bg-transparent"
              style={{ textTransform: 'capitalize', fontSize: '12px' }}
            >
              {!more ? (
                <span>
                  show <i className="fas fa-chevron-down" />
                </span>
              ) : (
                <span>
                  hide <i className="fas fa-chevron-up" />
                </span>
              )}
            </button>
          </div>
        </Card.Header.Title>
      </Card.Header>
      <Card.Body>
        <div className="pb-5">
          {/* <WithSpinner isLoading={isLoading}> */}
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${completePercent}%` }}
              aria-valuenow={completePercent}
              aria-valuemin={0}
              aria-valuemax={100}
            >
              {completePercent}%
            </div>
          </div>
          {more && (
            <div className="d-flex flex-md-row px-3 mt-4 flex-column-reverse">
              <div className="col-md-12">
                <div className="card1">
                  <div className="d-flex flex-column flex-md-row">
                    <ul
                      id="progressbar"
                      className="nav nav-tabs border-0 flex-column me-5 mb-md-0 fs-6"
                      role="tablist"
                    >
                      <Step
                        isActive={onboarding?.watchedWelcomeVideo ?? false}
                        isDefaultSelected
                        targetTab="#tab1"
                        // redirectLink={config.company.route}
                        title={t('onboarding_steps.watch_welcome_video')}
                      />
                      <Step
                        isActive={onboarding?.playlistCreated ?? false}
                        targetTab="#tab2"
                        redirectLink={config.playlists.route}
                        title={t('onboarding_steps.create_playlist')}
                      />

                      <Step
                        isActive={onboarding?.contentAdded ?? false}
                        targetTab="#tab3"
                        openUploadContentModal
                        title={t('onboarding_steps.add_content')}
                      />

                      <Step
                        isActive={onboarding?.viewAppsPage ?? false}
                        targetTab="#tab4"
                        redirectLink={config.apps.route}
                        title={t('onboarding_steps.visit_app')}
                      />

                      <Step
                        isActive={onboarding?.appsIntegration ?? false}
                        targetTab="#tab5"
                        redirectLink={config.apps.route}
                        title={t('onboarding_steps.integration_added')}
                      />

                      <Step
                        isActive={onboarding?.devicePreview ?? false}
                        targetTab="#tab6"
                        openDevicePreviewModal
                        deviceId={deviceId}
                        title={t('onboarding_steps.preview_device')}
                      />
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <StepContent tabId="tab1" />
                      <StepContent tabId="tab2" />
                      <StepContent tabId="tab3" />
                      <StepContent tabId="tab4" />
                      <StepContent tabId="tab5" />
                      <StepContent tabId="tab6" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="d-flex justify-content-end mt-2">
              <button
                type="button"
                onClick={handleClick}
                className="btn btn-active-secondary"
              >
                {t('common.skip')}
              </button>
            </div> */}
          {/* </WithSpinner> */}
        </div>
      </Card.Body>
    </Card>
  );
};
