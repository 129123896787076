import * as React from 'react';
import YouTubeReactPlayer from 'react-player/youtube';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Modal from '../../../components/Modal';
import { YoutubeFeedSchema } from '../../../store/types/apps/youtubeFeed';
import { AppDependencyConfig } from '../../../store/types/apps/globalTypes';
import Spinner from '../../../components/Spinner';
import styles from './youtube.module.scss';

export interface PreviewFeedModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  widthDefaultSize?: boolean;
  selectedYoutubeFeedSchema: YoutubeFeedSchema;
  previewHeight?: number;
}

const PreviewFeedModal = ({
  show,
  onHide,
  title,
  widthDefaultSize = false,
  previewHeight = 400,
  selectedYoutubeFeedSchema,
}: PreviewFeedModalProps) => {
  const { t } = useTranslation();
  const [videoIsFetching, setVideoIsFetching] = React.useState<boolean>(true);

  const handlePlayerReady = () => setVideoIsFetching(false);

  const formattedDuration = React.useMemo(() => {
    const videoDurationInSec = (
      selectedYoutubeFeedSchema?.dependency?.config as AppDependencyConfig
    )?.youtubeFeedDurationInSeconds;

    return new Date(1000 * (videoDurationInSec ?? 0))
      .toISOString()
      .slice(11, 19);
  }, [selectedYoutubeFeedSchema?.dependency?.config]);

  const handleHide = () => {
    onHide();
    setVideoIsFetching(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      widthDefaultSize={widthDefaultSize}
      title={title}
      withFooter
      footerContent={
        <button
          type="button"
          className="btn btn-light-primary me-2"
          onClick={handleHide}
        >
          {t('apps.youtube.labels.close')}
        </button>
      }
      dialogClassName="modal-dialog-centered h-auto"
    >
      <div className="d-flex flex-center w-100 h-100 flex-wrap">
        <div className="d-flex flex-column w-100">
          <div className="d-flex flex-column my-2 w-100">
            <span className="text-dark fw-bolder my-1">Video Name</span>
            <span>
              {
                (
                  selectedYoutubeFeedSchema?.dependency
                    ?.config as AppDependencyConfig
                )?.youtubeFeedName
              }
            </span>
          </div>
          <div className="d-flex flex-column my-2 w-100">
            <span className="text-dark fw-bolder my-1">Duration</span>
            <span>{formattedDuration}</span>
          </div>
        </div>

        {/* simulating a loading state for the player (hacky) and display duration and name fields when it's loaded  */}

        <div className="w-100">
          {videoIsFetching ? (
            <Spinner size="md" backdropClassName="bg-white" />
          ) : null}

          <div className="w-100 d-flex justify-content-center">
            <YouTubeReactPlayer
              url={
                (
                  selectedYoutubeFeedSchema?.dependency
                    ?.config as AppDependencyConfig
                )?.youtubeFeedUrl
              }
              width="100%"
              height={`${previewHeight}px`}
              controls
              onReady={handlePlayerReady}
              className={clsx(styles.youtubePlayerPopup)}
              style={{ pointerEvents: 'auto' }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewFeedModal;
