import {
  ACTIONS,
  YoutubeFeedsReduxAction,
} from '../../actions/apps/youtubeFeed';

import { ErrorResponse } from '../../types/apps/globalTypes';
import { PaginatedYoutubeFeeds } from '../../types/apps/youtubeFeed';

export interface YoutubeFeedsState {
  error: ErrorResponse | null;

  data: PaginatedYoutubeFeeds;
  loading: boolean;
  isGetYoutubeFeedsSuccess: boolean;

  createYoutubeFeedLoading: boolean;
  isCreateYoutubeFeedSuccess: boolean;

  scheduleYoutubeFeedLoading: boolean;
  isScheduleYoutubeFeedSuccess: boolean;

  deleteYoutubeFeedLoading: boolean;
  isDeleteYoutubeFeedSuccess: boolean;

  editYoutubeFeedLoading: boolean;
  isEditYoutubeFeedSuccess: boolean;
  isCreateScheduleYOUTUBEFeedSuccess: boolean;
}

const initialState: YoutubeFeedsState = {
  error: null,

  data: {
    items: [],
    // links: { first: '', previous: '', next: '', last: '' },
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 10,
      // totalPages: 0,
      currentPage: 1,
    },
  },
  loading: false,
  isGetYoutubeFeedsSuccess: false,

  createYoutubeFeedLoading: false,
  isCreateYoutubeFeedSuccess: false,

  scheduleYoutubeFeedLoading: false,
  isScheduleYoutubeFeedSuccess: false,

  deleteYoutubeFeedLoading: false,
  isDeleteYoutubeFeedSuccess: false,

  editYoutubeFeedLoading: false,
  isEditYoutubeFeedSuccess: false,
  isCreateScheduleYOUTUBEFeedSuccess: false,
};

export default function youtubeFeedsReducer(
  state = initialState,
  action: YoutubeFeedsReduxAction<ACTIONS>,
): YoutubeFeedsState {
  const { payload } = action;

  switch (action.type) {
    // GET
    case ACTIONS.GET_YOUTUBE_FEEDS_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };

    case ACTIONS.GET_YOUTUBE_FEEDS_SUCCESS:
      return {
        ...state,
        loading: payload.loading,
        data: payload.data,
        isGetYoutubeFeedsSuccess: payload.isGetYoutubeFeedsSuccess,
      };

    case ACTIONS.GET_YOUTUBE_FEEDS_ERROR:
      return {
        ...state,
        loading: payload.loading,
        error: payload.error,
        isGetYoutubeFeedsSuccess: payload.isGetYoutubeFeedsSuccess,
      };

    case ACTIONS.GET_YOUTUBE_FEEDS_RESET:
      return {
        ...state,
        data: payload.data,
        loading: payload.loading,
        error: payload.error,
        isGetYoutubeFeedsSuccess: payload.isGetYoutubeFeedsSuccess,
      };

    // CREATE YOUTUBE FEED
    case ACTIONS.CREATE_YOUTUBE_FEED_LOADING:
      return {
        ...state,
        createYoutubeFeedLoading: payload.loading,
      };

    case ACTIONS.CREATE_YOUTUBE_FEED_SUCCESS:
      return {
        ...state,
        createYoutubeFeedLoading: payload.loading,
        isCreateYoutubeFeedSuccess: payload.isCreateYoutubeFeedSuccess,
      };

    case ACTIONS.CREATE_YOUTUBE_FEED_ERROR:
      return {
        ...state,
        createYoutubeFeedLoading: payload.loading,
        error: payload.error,
        isCreateYoutubeFeedSuccess: payload.isCreateYoutubeFeedSuccess,
      };

    case ACTIONS.CREATE_YOUTUBE_FEED_RESET:
      return {
        ...state,
        createYoutubeFeedLoading: payload.loading,
        error: payload.error,
        isCreateYoutubeFeedSuccess: payload.isCreateYoutubeFeedSuccess,
      };

    // SCHEDULE YOUTUBE FEED
    case ACTIONS.SCHEDULE_YOUTUBE_FEED_LOADING:
      return {
        ...state,
        scheduleYoutubeFeedLoading: payload.loading,
      };

    case ACTIONS.SCHEDULE_YOUTUBE_FEED_SUCCESS:
      return {
        ...state,
        scheduleYoutubeFeedLoading: payload.loading,
        isScheduleYoutubeFeedSuccess: payload.isScheduleYoutubeFeedSuccess,
      };

    case ACTIONS.SCHEDULE_YOUTUBE_FEED_ERROR:
      return {
        ...state,
        scheduleYoutubeFeedLoading: payload.loading,
        error: payload.error,
        isScheduleYoutubeFeedSuccess: payload.isScheduleYoutubeFeedSuccess,
      };

    case ACTIONS.SCHEDULE_YOUTUBE_FEED_RESET:
      return {
        ...state,
        scheduleYoutubeFeedLoading: payload.loading,
        error: payload.error,
        isScheduleYoutubeFeedSuccess: payload.isScheduleYoutubeFeedSuccess,
      };

    // DELETE YOUTUBE FEED
    case ACTIONS.DELETE_YOUTUBE_FEED_LOADING:
      return {
        ...state,
        deleteYoutubeFeedLoading: payload.loading,
      };

    case ACTIONS.DELETE_YOUTUBE_FEED_SUCCESS:
      return {
        ...state,
        deleteYoutubeFeedLoading: payload.loading,
        isDeleteYoutubeFeedSuccess: payload.isDeleteYoutubeFeedSuccess,
      };

    case ACTIONS.DELETE_YOUTUBE_FEED_ERROR:
      return {
        ...state,
        deleteYoutubeFeedLoading: payload.loading,
        error: payload.error,
        isDeleteYoutubeFeedSuccess: payload.isDeleteYoutubeFeedSuccess,
      };

    case ACTIONS.DELETE_YOUTUBE_FEED_RESET:
      return {
        ...state,
        deleteYoutubeFeedLoading: payload.loading,
        error: payload.error,
        isDeleteYoutubeFeedSuccess: payload.isDeleteYoutubeFeedSuccess,
      };

    // EDIT YOUTUBE FEED
    case ACTIONS.EDIT_YOUTUBE_FEED_LOADING:
      return {
        ...state,
        editYoutubeFeedLoading: payload.loading,
      };

    case ACTIONS.EDIT_YOUTUBE_FEED_SUCCESS:
      return {
        ...state,
        editYoutubeFeedLoading: payload.loading,
        isEditYoutubeFeedSuccess: payload.isEditYoutubeFeedSuccess,
      };

    case ACTIONS.EDIT_YOUTUBE_FEED_ERROR:
      return {
        ...state,
        editYoutubeFeedLoading: payload.loading,
        error: payload.error,
        isEditYoutubeFeedSuccess: payload.isEditYoutubeFeedSuccess,
      };

    case ACTIONS.EDIT_YOUTUBE_FEED_RESET:
      return {
        ...state,
        editYoutubeFeedLoading: payload.loading,
        error: payload.error,
        isEditYoutubeFeedSuccess: payload.isEditYoutubeFeedSuccess,
      };

    default:
      return state;
  }
}
