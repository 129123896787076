import GetUsersListSuccessAction from '../../actions/users/interfaces/getUsersListSuccessAction.interface';
import LoadingAction from '../../actions/loadingAction.interface';
import UsersActions from '../../actions/users/usersActions.enum';
import ReduxAction from '../../actions/reduxAction.interface';
import UsersState from './usersState.interface';

const initialState: UsersState = {
  loading: true,
  users: [],
  adminUsers: [],
  total: 0,
  adminTotal: 0,
};

export default function usersReducer(
  state = initialState,
  action: ReduxAction<UsersActions>,
): UsersState {
  switch (action.type) {
    case UsersActions.GET_USERS_LIST_SUCCESS: {
      const {
        payload: { items, meta },
      } = action as GetUsersListSuccessAction;

      return {
        ...state,
        users: items,
        total: meta.totalItems,
        loading: false,
      };
    }
    case UsersActions.GET_ADMIN_USERS_LIST_SUCCESS: {
      const {
        payload: { items, meta },
      } = action as GetUsersListSuccessAction;

      return {
        ...state,
        adminUsers: items,
        adminTotal: meta.totalItems,
        loading: false,
      };
    }
    case UsersActions.SET_USERS_LOADING:
      return {
        ...state,
        ...(action as LoadingAction<UsersActions.SET_USERS_LOADING>).payload,
      };
    default:
      return state;
  }
}
