import clsx from 'clsx';
import React, { useState } from 'react';
import { RGBColor, SketchPicker } from 'react-color';
import styles from './color_picker.module.scss';

export default ({
  label,
  id,
  name,
  value,
  onSelect,
  disableAlpha = false,
}: {
  disableAlpha?: boolean;
  label?: string;
  name: string;
  id: string;
  onSelect: (color: RGBColor) => void;
  value: RGBColor | undefined;
}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

  const handleClick = () => setDisplayColorPicker((preD) => !preD);

  const handleClose = () => setDisplayColorPicker(false);

  const handleChange = ({ rgb }: { rgb: RGBColor }) => onSelect(rgb);

  return (
    <div className="d-flex flex-column" aria-label={`color picker for ${name}`}>
      {label && (
        <label htmlFor={name} className="mb-2 mt-1">
          {label}
        </label>
      )}
      <div>
        <button
          type="button"
          className={clsx(styles.swatch, 'border-0')}
          onClick={handleClick}
        >
          <div
            className={styles.color}
            style={{
              background: `rgba(${value?.r ?? 0}, ${value?.g ?? 0}, ${
                value?.b ?? 0
              }, ${value?.a ?? 1})`,
            }}
          />
        </button>
        {displayColorPicker && (
          <div className={styles.popover}>
            <button
              type="button"
              className={clsx(styles.cover, 'border-0 w-100 bg-transparent')}
              onClick={handleClose}
              aria-label="Close React Color"
            />
            <SketchPicker
              disableAlpha={disableAlpha}
              key={id}
              color={value}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
    </div>
  );
};
