/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '../../../../../FormErrorMessage';
import validation from '../../../../../../utils/validation';
import TextInput from '../../../../../TextInput';
import SelectCounty from '../../../../../../containers/SelectCounty';
import SelectCountry from '../../../../../../containers/SelectCountry';
import severeWeatherPic from '../../../../../../assets/images/severeWeathere/alert.png';
import { prepareHookFromState } from '../../../../../../utils/hooks/useStateWithHookForm';
import CreateAppRequestData from '../../../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import AddSevereWeatherSteps from '../../../../../../enums/steps/addSevereWeatherSteps.enum';
import { SelectedValue } from '../../../../../Select/SelectAsync';
import SelectState from '../../../../../../containers/SelectState';
import Stepper from '../../Stepper';
import {
  ColourOption,
  colourOptions,
} from '../../../../../../containers/SelectColor';
import CountryCode from '../../../../../../enums/countryCode.enum';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

interface FormInputs {
  name: string;
  countryId: string;
  countryName: string;
  stateId: string;
  countyIds: SelectedValue[];
  textColor: ColourOption;
  config: any;
}

export default ({
  data,
  setData,
  onDecline,
  validationSchema,
  setMode,
}: {
  data: Partial<CreateAppRequestData>;
  setData: any;
  onDecline: () => void;
  validationSchema: { [key: string]: any };
  setMode: (mode: AddSevereWeatherSteps) => void;
}) => {
  const { t } = useTranslation();
  const defaultValues: Partial<FormInputs> = {
    name: data?.name ? data?.name : 'Severe Weather Alert',
    countyIds:
      data.countyIds?.map((county: any) => ({
        label: county.label,
        value: county.value,
      })) ?? [],
    textColor:
      colourOptions.find(({ color }) => color === data?.config?.textColor) ??
      colourOptions[0],
    stateId: data?.stateId,
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validation.object(validationSchema).required()),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [name, setName] = useFs<FormInputs['name']>('name');
  const [countyIds, setCountyIds] = useFs<FormInputs['countyIds']>('countyIds');
  const [countryId, setCountryId] = useFs<FormInputs['countryId'] | undefined>(
    'countryId',
  );
  const [countryName, setCountryName] = useState<string>('');
  const [stateId, setStateId] = useFs<FormInputs['stateId'] | undefined>(
    'stateId',
  );
  const [stateName, setStateName] = useState<string>('');

  /**
   * Submits the step data and switches to the next step
   * @param inputsData
   */
  const handleSubmitData = (inputsData: Partial<FormInputs>) => {
    setData({
      ...data,
      name: inputsData.name,
      countyIds: inputsData.countyIds,
      countryName,
      countryId: {
        countryCode: inputsData.countryId,
        name: countryName,
      },
      stateId: inputsData.stateId ? inputsData.stateId : stateName,
      config: {
        textColor: inputsData?.textColor?.color,
      },
    });

    setMode(AddSevereWeatherSteps.AssignSevereWeatherLocation);
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitData)}>
      <div className="row d-flex justify-content-between">
        <Stepper
          currentStep={AddSevereWeatherSteps.CreateSevereWeatherLocation}
          className="mb-9 col-12 col-md-6 ms-0"
        />

        <div className="d-flex flex-column justify-content-end col-12 col-md-6">
          <img
            src={severeWeatherPic}
            alt="severe weather preview"
            className="w-100 mb-5"
          />

          <div className="w-md-400px">
            <label htmlFor="name" className="required fs-6 fw-bolder mb-3">
              {t('companies.name')}
            </label>
            <TextInput
              name="name"
              id="name"
              placeholder={t('companies.name')}
              value={name}
              onChange={setName}
              useDisabledStyles
            />
            <FormErrorMessage
              name="name"
              errors={errors}
              className={errorMessageClassNames}
            />

            <div className="mt-5 row">
              <div
                className={countryId === CountryCode.Us ? 'col-6' : 'col-12'}
              >
                <label
                  htmlFor="countryId"
                  className="fs-6 fw-bolder mb-3 required"
                >
                  {t('apps.severeWeather.fields.country')}
                </label>
                <SelectCountry
                  id="countryId"
                  onChange={(selectedCountry) => {
                    setCountryName(selectedCountry.label as string);
                    setCountryId(selectedCountry.value);
                    setStateId(undefined);
                  }}
                  initialValue={
                    !data.countryId
                      ? countryId
                      : JSON.parse(JSON.stringify(data.countryId))?.countryCode
                  }
                  isClearable
                />
                <FormErrorMessage
                  name="countryId"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              {countryId !== CountryCode.Ca && (
                <div className="col-6">
                  <label
                    htmlFor="stateId"
                    className="fs-6 fw-bolder mb-3 required"
                  >
                    {t('apps.severeWeather.fields.state')}
                  </label>
                  <SelectState
                    id="stateId"
                    onChange={(selectedState) => {
                      setStateName(selectedState.label as string);
                      setStateId(selectedState.value);
                    }}
                    showStateCode
                    countryId={countryId}
                    initialValue={stateId}
                    isClearable
                  />
                  <FormErrorMessage
                    name="stateId"
                    errors={errors}
                    className={errorMessageClassNames}
                  />
                </div>
              )}
            </div>

            {(countryId === CountryCode.Ca || countryId === CountryCode.Us) && (
              <div className="mt-5">
                <label
                  htmlFor="counties"
                  className="fs-6 fw-bolder mb-3 required"
                >
                  {countryId === CountryCode.Us
                    ? t('apps.severeWeather.fields.counties')
                    : t('apps.severeWeather.fields.state')}
                </label>
                <SelectCounty
                  isSevereWeather
                  id="counties"
                  name="counties"
                  initialOption={countyIds}
                  onChange={
                    setCountyIds as (value: SelectedValue | undefined) => void
                  }
                  countryCode={
                    !data.countryId
                      ? countryId
                      : JSON.parse(JSON.stringify(data.countryId))?.countryCode
                  }
                  stateCode={stateId}
                  isMulti
                  isClearable
                />
                <FormErrorMessage
                  name="countyIds"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between mt-5">
          <button
            type="button"
            className="btn btn-white text-primary"
            onClick={onDecline}
          >
            {t('common.cancel')}
          </button>

          <div className="d-flex justify-content-end ">
            <button type="submit" className="btn btn-primary">
              {t('common.continue')}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
