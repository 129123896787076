import React, { useEffect } from 'react';
import IContent from '../../../../../../../interfaces/content.interface';
import InstagramThumbnail from '../../../../../../Apps/InstagramApp/Thumbnail';

export default ({ content }: { content: IContent }) => {
  const [url, setUrl] = React.useState('');

  const InstagramData = content.app;

  useEffect(() => {
    if (!InstagramData) return;

    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?appId=${InstagramData?.id}`,
    );
  }, [InstagramData]);

  return <InstagramThumbnail src={url} />;
};
