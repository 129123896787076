import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { debouncedUpdateSearch } from '../browserHistory';
import { readFromQueryString } from '../queryParams';

/**
 * Creates the state synchronized with query params
 * @param queryParamName
 * @param defaultValue
 */
export default <T extends string = string>(
  queryParamName: string,
  defaultValue?: T,
): [T, (value: T) => void] => {
  const { search } = useLocation();
  const [observableVariable, handleSearchUpdate] = useState<T>(
    readFromQueryString<T>(search, queryParamName) || ('' as T),
  );

  const syncWithQueryParam = (value: T) =>
    debouncedUpdateSearch({
      [queryParamName]: value,
    });

  // Observe search update and sync state with query params
  useEffect(() => {
    const searchValue = readFromQueryString<T>(search, queryParamName);

    if (!searchValue && defaultValue) {
      syncWithQueryParam(defaultValue);
    }

    if (searchValue !== observableVariable) {
      handleSearchUpdate(searchValue ?? ('' as T));
    }
  }, [search]);

  return [observableVariable, syncWithQueryParam];
};
