import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { pick } from 'lodash';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from '../../../FormErrorMessage';
import Modal from '../../../Modal';
import TextInput from '../../../TextInput';
import AddDevicesGroupSteps from '../../../../enums/steps/addDevicesGroupSteps.enum';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import CreateDeviceGroupRequestData from '../../../../api/endpoints/devicesGroups/interfaces/createDeviceGroupRequestData.interface';
import StepsTitle from '../StepsTitle';
import Yup from '../../../../utils/validation';
import DeviceGroup from '../../../../interfaces/deviceGroup.interface';

type FieldsData = Partial<CreateDeviceGroupRequestData>;

export default ({
  setMode,
  data,
  setData,
  group,
}: {
  setMode: (mode: AddDevicesGroupSteps) => void;
  data: FieldsData;
  setData: (newData: FieldsData) => void;
  group?: DeviceGroup;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FieldsData>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().required(
          t('users_groups.create_group.validation.please_enter_name'),
        ),
      }).required(),
    ),
    defaultValues: pick(group || data, ['name']),
  });

  const [name, setName] = useStateWithHookForm<FieldsData, FieldsData['name']>(
    { setValue, trigger, name: 'name' },
    group?.name ?? data?.name ?? '',
  );

  /**
   * Submits the step data and switches to the next step
   * @param inputsData
   */
  const handleSubmitData = (inputsData: FieldsData) => {
    setData({ ...data, ...inputsData });
    setMode(AddDevicesGroupSteps.Assign);
  };

  return (
    <div className="w-100">
      <StepsTitle currentStep={AddDevicesGroupSteps.Info} />
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <p className="mt-2 mb-0">
          <label
            htmlFor="name"
            className="col-form-label text-lg-end fw-bolder required fs-6"
          >
            {t('devices_groups.steps.group_name_lbl')}
          </label>
          <TextInput
            name="name"
            id="name"
            placeholder={t('contents.type_here')}
            value={name}
            onChange={setName}
            className="border-gray-400"
          />
          <FormErrorMessage
            name="name"
            errors={errors}
            className="mt-1 min-h-20px"
          />
        </p>

        <Modal.Separator />
        <div className="d-sm-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            {t('common.next_step')}
          </button>
        </div>
      </form>
    </div>
  );
};
