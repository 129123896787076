import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UsersQueryParam from '../../../api/endpoints/users/enums/usersQueryParam.enum';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import Card from '../../Card';
import Search from '../../Search/QueryParamSearch';
import CompaniesTable from './CompaniesTable';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import Pagination from '../../Pagination/QueryParamPagination';
import useTableMultiSelect from '../../../utils/hooks/useTableMultiSelect';
import Company from '../../../interfaces/company.interface';
import CreateUserCompany from '../../../containers/Companies/CreateUserCompany';
import { KTSVG } from '../../../lib/metronic/helpers';
import TalkToSales from '../../Trial/TalkToSales';

const pageSize = 6;
const tableRowHeight = 73.7;

export default ({
  companies,
  total,
  isLoading,
  resetOnSearch,
  onUpdate,
}: {
  companies: Company[];
  total: number;
  isLoading: boolean;
  resetOnSearch?: boolean;
  onUpdate?: () => void;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const isTrial = useSelector((state: any) => state.userData?.user?.isTrial);

  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(companies, 'id'),
    [],
    resetOnSearch ? search : undefined,
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          <Search
            queryField={UsersQueryParam.Search}
            pageNumberQueryField={PaginationQueryParam.Page}
            placeholder={t('companies.search')}
            className="me-2"
          />
          <div className="d-flex align-items-center align-content-center">
            {isTrial ? (
              <TalkToSales
                handleClose={handleClose}
                isModalVisible={isModalVisible}
              >
                <button
                  type="button"
                  className="btn btn-secondary me-2"
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {t('companies.add_company')}
                  <i
                    className="bi bi-lock-fill ms-3"
                    style={{ fontSize: '1.4rem' }}
                  />
                </button>
              </TalkToSales>
            ) : (
              <CreateUserCompany onUpdate={onUpdate}>
                <button type="button" className="btn btn-primary me-2">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  {t('companies.add_company')}
                </button>
              </CreateUserCompany>
            )}
          </div>
        </Card.Header.Toolbar>
      </Card.Header>

      <Card.Body>
        <CompaniesTable
          companies={companies}
          isLoading={isLoading}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          onUpdate={onUpdate}
        />
        <Pagination
          total={total}
          pageSize={pageSize}
          pageNumberQueryField={PaginationQueryParam.Page}
          pageSizeQueryField={PaginationQueryParam.Limit}
          className="mt-3 mb-5"
        />
      </Card.Body>
    </Card>
  );
};
