import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import PlaylistModal from '../../../components/PlaylistModal';
import PlaylistFormInputs from '../../../components/PlaylistModal/playlistFormInputs.interface';
import api from '../../../api';
import { getUserData } from '../../../store/actions/userData';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';
import { readFromQueryString } from '../../../utils/queryParams';

const CreatePlaylist = ({ onSuccess }: { onSuccess: () => void }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { roles, id } = useSelector(
    (state: GlobalState) => state.userData.user,
  );

  const open = () => {
    setModalShown(true);
  };

  const handleSubmit = async (data: PlaylistFormInputs) => {
    setIsLoading(true);
    if (!roles.includes(UserRole.SuperAdmin)) {
      try {
        await api.playlists.createPlaylist({
          name: data.name,
          startDate: data.startDate && data.startDate.toISOString(),
          endDate: data.endDate && data.endDate.toISOString(),
          priority: data.priority,
          locked: data.locked,
          isActive: data.isActive,
          deviceIds: data.devices?.length
            ? (data.devices?.map((item) => item.value) as string[])
            : [''],
          deviceGroupIds: data.deviceGroupIds?.length
            ? (data.deviceGroupIds?.map((item) => item.value) as string[])
            : [''],
          tags: data.tags,
          templateId: data.templateId,
          industryId: data?.industryId?.length ? data?.industryId : undefined,
          isTrial: data?.isTrial ? data?.isTrial : undefined,
        });

        setModalShown(false);
        dispatch(getUserData());

        if (onSuccess) {
          onSuccess();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    } else {
      const devicesIds = readFromQueryString(search, 'devicesIds');
      if (devicesIds) {
        try {
          const userId = (await api.devices.getDeviceById(devicesIds))?.owner
            ?.id;
          await api.playlists.admincreatePlaylist({
            name: data.name,
            startDate: data.startDate && data.startDate.toISOString(),
            endDate: data.endDate && data.endDate.toISOString(),
            priority: data.priority,
            locked: data.locked,
            isActive: data.isActive,
            deviceIds: data.devices?.length
              ? (data.devices?.map((item) => item.value) as string[])
              : [''],
            deviceGroupIds: data.deviceGroupIds?.length
              ? (data.deviceGroupIds?.map((item) => item.value) as string[])
              : [''],
            tags: data.tags,
            templateId: data.templateId,
            industryId: data?.industryId?.length ? data?.industryId : undefined,
            isTrial: data?.isTrial ? data?.isTrial : undefined,
            userId: userId ?? id,
          });

          setModalShown(false);
          dispatch(getUserData());

          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };
  return (
    <>
      <button type="button" className="btn btn-primary" onClick={open}>
        {t('playlists.create_playlist')}
      </button>
      <PlaylistModal
        onSubmit={handleSubmit}
        shown={modalShown}
        modalTitle={t('playlists.create_playlist')}
        hide={() => setModalShown(false)}
        isLoading={isLoading}
      />
    </>
  );
};

export default CreatePlaylist;
