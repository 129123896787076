import axios from 'axios';
import { API_URL } from '../../../constants/api';
import GetLayoutsRequestData from './interfaces/getLayoutsRequestData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import LayoutsRoutes from './enums/layoutsRoutes.enum';
import GetLayoutsResponseData from './interfaces/getLayoutsResponseData';

export default {
  /**
   * Retrieves the list of layouts
   * @param requestData
   */
  getLayouts: async (
    requestData: GetLayoutsRequestData,
  ): Promise<GetLayoutsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LayoutsRoutes.Layouts}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
};
