import React from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { prepareHookFromState } from '../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../FormErrorMessage';
import validation from '../../../utils/validation';
import Modal from '../../Modal';
import CreateTemplateRequestData from '../../../api/endpoints/templates/interfaces/createTemplateRequestData.interface';
import Template from '../../../interfaces/template.interface';
import TextInput from '../../TextInput';
import { editTemplate } from '../../../validation/template';
import WithSpinner from '../../WithSpinner';
import TemplateOrientation from '../../../enums/templateOrientation.enum';
import UploadImage from '../../UploadProfilePhoto';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

interface FormInputs {
  name: string;
  orientation: any;
  background: File;
  logo: File;
}

export default ({
  template,
  isLoading,
  onSubmit,
}: {
  template: Template;
  isLoading: boolean;
  onSubmit: (data: Partial<CreateTemplateRequestData>) => void;
}) => {
  const { t } = useTranslation();
  const defaultValues: Partial<FormInputs> = {
    name: template.name ?? '',
    orientation: template.orientation ?? TemplateOrientation.HORIZONTAL,
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validation.object(editTemplate(t)).required()),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [name, setName] = useFs<FormInputs['name']>('name');
  const [background, setBackground] =
    useFs<FormInputs['background']>('background');
  const [logo, setLogo] = useFs<FormInputs['logo']>('logo');

  const handleSubmitData = (inputsData: Partial<FormInputs>) => {
    onSubmit({
      name,
      background: inputsData.background,
      logo: inputsData.logo,
    });
  };

  return (
    <WithSpinner isLoading={isLoading} size="md" style={{ minHeight: '300px' }}>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <h4 className="fw-boldest mb-2">
          {t('templates.create_template.fields.title')}
        </h4>
        <TextInput
          name="name"
          id="contentName"
          placeholder={t('contents.type_here')}
          value={name}
          onChange={setName}
          useDisabledStyles
        />
        <FormErrorMessage
          name="name"
          errors={errors}
          className={errorMessageClassNames}
        />
        <div className="d-flex flex-wrap justify-content-center mb-5">
          <div className="d-flex flex-column me-5 w-400px">
            <div className="mt-5">
              <h4 className="fw-boldest mb-2">
                {t('templates.create_template.fields.background')}
              </h4>
              <UploadImage
                name="background"
                setPic={setBackground}
                oldPhoto={
                  background
                    ? URL.createObjectURL(background)
                    : template.background
                }
                title="Upload New Background"
              />
            </div>
          </div>
          <div className="d-flex flex-column w-400px">
            <div className="mt-5">
              <h4 className="fw-boldest mb-2">
                {t('templates.create_template.fields.logo')}
              </h4>
              <UploadImage
                name="logo"
                setPic={setLogo}
                oldPhoto={logo ? URL.createObjectURL(logo) : template.logo}
                title="Upload New Logo"
              />
            </div>
          </div>
        </div>
        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-flex justify-content-end mt-5">
          <button type="submit" className="btn btn-primary">
            {t('common.save_changes')}
          </button>
        </div>
      </form>
    </WithSpinner>
  );
};
