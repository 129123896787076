import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import Device from '../../../interfaces/devices/device.interface';
import useDidUpdate from '../../../utils/hooks/useDidUpdate';
import License from '../../../interfaces/license.interface';
import api from '../../../api';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
import dropdownsData from '../../../constants/dropdownsData';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';
import { readFromQueryString } from '../../../utils/queryParams';

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

type DeviceOptions = (Option & Device)[];
type OptionsWithLicense = (Option & { license: License })[];

const mapDevicesToSelects = (devices: Device[]) =>
  devices.map(({ id, name, license, trialDevice, location }) => {
    const licenseTypeName = license?.licenseType?.name;
    const locationName = location?.name;
    // Do not nest ternary expressions
    if (trialDevice) {
      return {
        value: id,
        label: `${name}${trialDevice ? ' (Trial)' : ''}`,
        license,
      };
    }
    return {
      value: id,
      label: ` ${locationName ? `${locationName} | ` : ''} ${name}${
        licenseTypeName ? ` (${licenseTypeName})` : ''
      } `,
      license,
    };
  });

const mapDevicesToSelectsWithoutLicense = (devices: Device[]) =>
  devices.map(({ id, name, license, location }) => {
    return {
      value: id,
      label: `  ${location.name ? `${location.name} | ` : ''} ${name}`,
      license,
    };
  });
const mapOptionsWithLicensing = (
  options: DeviceOptions,
  selectedValue: OptionsWithLicense,
) =>
  options.map((option) =>
    selectedValue.find(
      ({ license, value }) =>
        value !== option.value &&
        license?.licenseType?.id !== option?.license?.licenseType?.id,
    )
      ? { ...option, disabled: true }
      : option,
  );

const findOptionWithSameLicense = (options: OptionsWithLicense) =>
  options.find(({ license }) =>
    options.find(
      (option) => option.license?.licenseType?.id === license?.licenseType?.id,
    ),
  );

const SelectDevices = ({
  onChange,
  initialValue,
  hasSelectAll = true,
  deviceIds,
  hideLicence,
}: {
  onChange: (selectedValue: SelectedValue[]) => void;
  initialValue?: string[];
  hasSelectAll?: boolean;
  deviceIds?: string[];
  hideLicence?: boolean;
}) => {
  const [options, setOptions] = useState<OptionsWithLicense>([]);
  const [selectedValue, setSelectedValue] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isAdmin] = useIsAdmin();
  const location = useLocation();
  const currentUserId = readFromQueryString(location.search, 'currentUserId');
  const roles = useSelector((state: GlobalState) => {
    return state.userData.user.roles;
  });
  const isSuperAdmin = roles.includes(UserRole.SuperAdmin);
  const loadOptions = async (search?: string) => {
    let devices;
    let deviceParam;

    if (isSuperAdmin) {
      let param = new URLSearchParams(location.search).get('devicesIds');
      if (currentUserId) {
        devices = await api.devices.getAdminDevicesList({
          limit: dropdownsData.devicesLimit,
          currentUserId,
        });
      }

      if (!param && deviceIds) {
        param = deviceIds[0] as string;
      }
      if (!param && initialValue) {
        param = initialValue[0] as string;
      }
      if (param) {
        deviceParam = await api.devices.getDeviceById(param as string);
      }

      if (deviceParam?.company) {
        devices = await api.devices.getAdminDevicesList({
          limit: dropdownsData.devicesLimit,
          companyIds: [deviceParam?.company?.id],
        });
        setOptions(
          !hideLicence
            ? mapDevicesToSelects(devices.items)
            : mapDevicesToSelectsWithoutLicense(devices.items),
        );
      } else if (param) {
        deviceParam = await api.devices.getDeviceById(param as string);
        setOptions(
          !hideLicence
            ? mapDevicesToSelects([deviceParam])
            : mapDevicesToSelectsWithoutLicense([deviceParam]),
        );
      } else if (devices) {
        setOptions(
          !hideLicence
            ? mapDevicesToSelects(devices.items)
            : mapDevicesToSelectsWithoutLicense(devices.items),
        );
      }
    } else {
      devices = await api.devices.getDevicesList({
        search,
        limit: dropdownsData.devicesLimit,
      });
      setOptions(
        !hideLicence
          ? mapDevicesToSelects(devices.items)
          : mapDevicesToSelectsWithoutLicense(devices.items),
      );
    }

    // const { items } = devices;
    // setOptions(mapDevicesToSelects(items));
  };

  const loadSelectedOption = async () => {
    if (!initialValue?.length) return;

    let devices;
    if (isAdmin) {
      devices = await api.devices.getAdminDevicesList({
        limit: dropdownsData.devicesLimit,
        ids: initialValue,
      });
    } else {
      devices = await api.devices.getDevicesList({
        limit: dropdownsData.devicesLimit,
        ids: initialValue,
      });
    }
    const { items } = devices;

    setSelectedValue(
      !hideLicence
        ? mapDevicesToSelects(items)
        : mapDevicesToSelectsWithoutLicense(items),
    );
  };

  const filterOptions = (
    filteredOptions: Option[],
    input: string,
  ): Option[] => {
    setSearchValue(input);

    return filteredOptions;
  };

  useEffect(() => {
    setIsLoading(true);

    Promise.all([loadOptions(), loadSelectedOption()]).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useDidUpdate(() => {
    setIsLoading(true);

    loadOptions(searchValue).finally(() => {
      setIsLoading(false);
    });
  }, [searchValue]);
  return (
    <MultiSelect
      options={mapOptionsWithLicensing(
        options as DeviceOptions,
        selectedValue as OptionsWithLicense,
      )}
      value={selectedValue}
      onChange={(selectedValues: Option[]) => {
        setSelectedValue(selectedValues);
        onChange(selectedValues);
      }}
      filterOptions={filterOptions}
      labelledBy="Selects Devices"
      isLoading={isLoading}
      hasSelectAll={!findOptionWithSameLicense(options) && hasSelectAll}
    />
  );
};

SelectDevices.defaultProps = {
  initialValue: [],
};

export default SelectDevices;
