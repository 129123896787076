import axios from 'axios';
import { API_URL } from '../../../constants/api';
import CountiesRoutes from './enums/countiesRoutes.enum';
import GetCountiesRequestData from './interfaces/getCountiesRequestData.interface';
import GetCountiesResponseData from './interfaces/getCountiesResponseData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import County from '../../../interfaces/county.interface';

export default {
  /**
   * Retrieves the list of counties
   * @param requestData
   * @param isSevereWeather
   */
  getCounties: async (
    requestData: GetCountiesRequestData,
    isSevereWeather?: boolean,
  ): Promise<GetCountiesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${
        isSevereWeather
          ? CountiesRoutes.SevereWeatherCounties
          : CountiesRoutes.Counties
      }`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the county by id
   * @param id
   * @param isSevereWeather
   */
  getCounty: async (id: string, isSevereWeather?: boolean): Promise<County> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${
        isSevereWeather
          ? CountiesRoutes.SevereWeatherCounties
          : CountiesRoutes.Counties
      }/${id}`,
    });

    return data;
  },
};
