import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../../../constants/api';
import StatesRoutes from './enums/statesRoutes.enum';
import GetStatesRequestData from './interfaces/getStatesRequestData.interface';
import GetStatesResponseData from './interfaces/getStatesResponseData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import State from '../../../interfaces/state.interface';

export default {
  /**
   * Retrieves the list of states
   * @param requestData
   */
  getStates: async (
    requestData: GetStatesRequestData,
  ): Promise<GetStatesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${StatesRoutes.States}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the state by id
   * @param id
   */
  getState: async (id: string): Promise<State> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${StatesRoutes.States}/${id}`,
      disableToast: true,
    } as AxiosRequestConfig);

    return data;
  },
};
