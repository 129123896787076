import axios from 'axios';
import { API_URL } from '../../../constants/api';
import IndustriesRoutes from './enums/industriesRoutes.enum';
import GetIndustriesRequestData from './interfaces/getIndustriesRequestData.interface';
import GetIndustriesResponseData from './interfaces/getIndustriesResponseData.interface';
import IndustriesByIdsQueryParam from './enums/industriesByIdsQueryParam.enum';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import Industry from '../../../interfaces/industry.interface';

export default {
  /**
   * Retrieves the list of industries
   * @param requestData
   */
  getIndustries: async (
    requestData: GetIndustriesRequestData,
  ): Promise<GetIndustriesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${IndustriesRoutes.Industries}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of industries by ids
   * @param ids
   */
  getIndustriesByIds: async (ids: string[]): Promise<Industry[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${IndustriesRoutes.Industries}`,
      params: { [IndustriesByIdsQueryParam.IndustryIds]: ids },
    });

    return data.items;
  },

  /**
   * Retrieves the industry by id
   * @param id
   */
  getIndustry: async (id: string): Promise<Industry> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${IndustriesRoutes.Industries}/${id}`,
    });

    return data;
  },
};
