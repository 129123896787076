import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import WithDeleteConfirm from '../../../components/WithDeleteConfirm';
import Tooltip from '../../../components/Tooltip';
import api from '../../../api';

export default ({
  tickerId,
  tickerName,
  children,
  onUpdate,
}: {
  tickerId: string;
  onUpdate: () => void;
  tickerName: string;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    if (tickerId) {
      api.apps.deleteApp(tickerId).then(() => {
        if (onUpdate) {
          onUpdate();
        }

        toast.success(i18next.t<string>(`tickers.ticker_deleted`));
      });
    }
  };

  return (
    <WithDeleteConfirm
      body={`${t('common.delete_confirmation.title')} ${t(
        'common.delete_confirmation.ticker',
      )} "${tickerName}"?`}
      handleConfirm={handleConfirm}
    >
      {children ?? (
        <Tooltip text={t('common.delete')}>
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-trash" />
          </span>
        </Tooltip>
      )}
    </WithDeleteConfirm>
  );
};
