import { floor } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RSSFeedSchema } from '../../../../store/types/apps/rssFeed';
import { disableRightClickIframe } from '../../../../utils/common';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import Modal from '../../../Modal';
import Spinner from '../../../Spinner';
import styles from './severeWeather.module.scss';

export interface PreviewFeedModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  selectedRssFeedSchema: RSSFeedSchema;
  children?: React.ReactNode;
}

const PreviewFeedModal = ({
  show,
  onHide,
  title,
  selectedRssFeedSchema,
  children,
}: PreviewFeedModalProps) => {
  const { t } = useTranslation();
  const heightBaseValue = 4;
  const widthBaseValue = 2.248;

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { name: feedTitle, id } = React.useMemo(
    () => selectedRssFeedSchema,
    [selectedRssFeedSchema],
  );
  const [url, setUrl] = React.useState('');

  const [windowWidth] = useWindowSize();
  const iframeHeight = floor(windowWidth / heightBaseValue);
  const iframeWidth = windowWidth / widthBaseValue;

  const handleIframeLoad = () => setIsLoading(false);

  const handleHide = () => {
    setIsLoading(true);
    onHide();
  };

  React.useEffect(() => {
    if (!id) return;
    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?appId=${id}&ratio=${windowWidth}`,
    );
  }, [windowWidth, id]);

  React.useEffect(() => {
    disableRightClickIframe(feedTitle);
  }, []);

  return (
    <>
      {children}
      <Modal
        show={show}
        onHide={handleHide}
        widthDefaultSize
        title={title}
        withFooter
        dialogClassName={styles.dialog}
        footerContent={
          <button
            type="button"
            className="btn btn-light-primary me-2"
            onClick={handleHide}
          >
            {t('common.close')}
          </button>
        }
      >
        {isLoading ? <Spinner size="md" backdropClassName="bg-white" /> : null}

        <div
          className="d-flex flex-column-reverse flex-center w-100 h-100"
          onContextMenu={(ev) => ev.preventDefault()}
        >
          <iframe
            title={feedTitle}
            src={url}
            width={iframeWidth}
            height={iframeHeight}
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            onLoad={handleIframeLoad}
          />
        </div>
      </Modal>
    </>
  );
};

export default PreviewFeedModal;
