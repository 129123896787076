import React from 'react';
import { useTranslation } from 'react-i18next';
// import google from '../../assets/images/auth/google-icon.svg';
import microsoft from '../../assets/images/auth/microsoft-96.svg';
import { webAuth0 } from '../../utils/auth0';
import routesConfig from '../../routing/config';

export default ({ isGPCLocked }: { isGPCLocked?: boolean }) => {
  const { t } = useTranslation();

  return (
    <>
      {isGPCLocked ? null : (
        <div className="separator separator-content my-10">
          <span className="w-20px text-gray-500 fw-semibold fs-7">
            {t('auth_pages.or_with_email')}
          </span>
        </div>
      )}
      <div className="row g-3 mb-9">
        {/* <div className="col-md-6">
          <button
            disabled
            type="button"
            className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
          >
            <img alt="Logo" src={google} className="h-15px me-3" />
            {t('auth_pages.signup_google')}
          </button>
        </div> */}
        <div className="col-md-12">
          <button
            type="button"
            className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
            onClick={() => {
              webAuth0.authorize({
                connection:
                  process.env.REACT_APP_AUTH0_AD_CONNECT_NAME ??
                  'IndigoVizualAD',
                domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
                redirectUri: `${window.location.origin}${routesConfig.login.route}`,
                responseType: 'code',
              });
            }}
          >
            <img
              alt="Logo"
              src={microsoft}
              className="theme-dark-show h-15px me-1"
            />
            {isGPCLocked
              ? t('auth_pages.signin_microsoft')
              : t('auth_pages.signup_microsoft')}
          </button>
        </div>
      </div>
    </>
  );
};
