import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getComparatorBy } from '../../utils/common';
import api from '../../api';
import SelectAsync, {
  SelectedValue,
} from '../../components/Select/SelectAsync';
import { usePrevValues } from '../../utils/hooks/usePrevValues';
import dropdownsData from '../../constants/dropdownsData';
import GlobalState from '../../store/reducers/globalState.interface';
import { UserRole } from '../../enums/userRole.enum';

/**
 * Retrieves the select options asynchronously
 * @param search
 * @param companyId
 * @param isAdmin
 * @param limit
 * @param currentUserId
 */
const loadOptions = async (
  search?: string,
  companyId?: string,
  isAdmin?: boolean,
  limit?: string,
  currentUserId?: string,
): Promise<SelectedValue[]> => {
  const { items: locations } = isAdmin
    ? await api.locations.getAdminLocations({
        search,
        companyId,
        currentUserId,
        limit,
      })
    : await api.locations.getLocationsUnderCompany(
        {
          search,
          limit,
        },
        companyId,
      );
  return sortBy(locations, ['name']).map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

const SelectLocation = ({
  onChange,
  initialValue,
  id,
  className,
  isClearable,
  currentUserId,
  companyId,
  disabled,
  placeholder,
  showDefaultOptions = true,
  locationsList,
}: {
  onChange: (selectedValue: SelectedValue) => void;
  initialValue?: string;
  id?: string;
  className?: string;
  isClearable?: boolean;
  disabled?: boolean;
  companyId?: string;
  placeholder?: string;
  currentUserId?: string;
  showDefaultOptions?: boolean;
  locationsList?: any;
}) => {
  const { limit } = dropdownsData;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [defaultLocations, setDefaultLocations] = useState<SelectedValue[]>([]);
  const [selectedValuesop, setSelectedValue] = useState<SelectedValue>({
    value: 'loadingValue',
    label: `${t('common.loading')}...`,
  });
  const [loadedLocations, setLoadedLocations] = useState<
    SelectedValue[] | undefined
  >(undefined);
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const isAdmin = roles.includes(UserRole.SuperAdmin);
  useEffect(() => {
    if (loadedLocations) {
      setSelectedValue(
        loadedLocations?.find(
          (location) => initialValue === location.value,
        ) as SelectedValue,
      );
    }
  }, [loadedLocations]);
  const handleLoad = async (search?: string) => {
    if (!search) {
      setIsLoading(true);
    }
    let locations: SelectedValue[] = [];

    if (companyId || showDefaultOptions) {
      if (search)
        return loadOptions(search, companyId, isAdmin, limit, currentUserId);
      locations = await loadOptions(
        search,
        companyId,
        isAdmin,
        limit,
        currentUserId,
      );

      if (locationsList && !companyId && !currentUserId) {
        locations = locationsList.map((a: { id: any; name: any }) => ({
          value: a.id,
          label: a.name,
        }));
      }
      // DISABLE PRESELECTED location
      // if (locations.length === 1) {
      //   const defaultSelect = locations[0].value;
      //   if (!defaultSelect) return locations;

      //   const { name } = isAdmin
      //     ? await api.locations.getAdminLocation(defaultSelect)
      //     : await api.locations.getLocation(defaultSelect);

      //   setSelectedValue({ label: name, value: defaultSelect });
      // }
      if (!search) {
        setDefaultLocations(locations);
      }
      setIsLoading(false);
    } else {
      setDefaultLocations(locations);
    }
    if (!search) {
      setIsLoading(false);
    }
    setLoadedLocations(locations);

    return locations;
  };

  useEffect(() => {
    handleLoad();
    setIsLoading(true);
  }, [companyId, currentUserId]);

  usePrevValues(companyId, (prevCompanyId: string | undefined) => {
    if (prevCompanyId !== companyId) {
      setSelectedValue([]);
    }
  });

  return (
    <SelectAsync
      id={id}
      isLoading={isLoading}
      initialValue={selectedValuesop}
      onChange={onChange}
      loadOptions={handleLoad}
      className={className}
      placeholder={placeholder ?? t('common.search')}
      isClearable={isClearable}
      isDisabled={disabled}
      defaultOptions={defaultLocations}
    />
  );
};

SelectLocation.defaultProps = {
  initialValue: null,
};

export const MemoizedSelectLocation = memo(
  SelectLocation,
  getComparatorBy(['id', 'className', 'isClearable', 'disabled', 'companyId']),
);

export default SelectLocation;
