import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeletePowerBi from '../../../../../containers/Apps/PowerBi/DeletePowerBi';
import App from '../../../../../interfaces/app.interface';
import Table from '../../../../Table';
import SchedulePowerBi from '../../../../../containers/Apps/PowerBi/Schedule';
import EditPowerBi from '../../../../../containers/Apps/PowerBi/EditPowerBi';
import PreviewFeedModal from '../../PreviewFeedModal';
import { AppDependency } from '../../../../../store/types/apps/globalTypes';
import PlacementsEnum from '../../../../../enums/placements.enum';
import { formatDateString } from '../../../../../utils/dateTime';
import Tooltip from '../../../../Tooltip';

const { Row } = Table.Body;
const { Cell } = Row;

const TableRow = ({
  powerBi,
  onUpdate,
  connected,
  userId,
}: {
  powerBi: App;
  onUpdate: () => void;
  connected: boolean;
  userId?: string;
}) => {
  const { t } = useTranslation();

  const [showModalFeed, setShowModalFeed] = useState(false);
  const [brokenApp, setBrokenApp] = useState<boolean>();

  const formatDate = formatDateString({
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    minute: '2-digit',
    hour: 'numeric',
    hour12: true,
  });

  React.useMemo(() => {
    if (!connected && powerBi?.ownerApp?.id === userId) {
      setBrokenApp(true);
    } else {
      setBrokenApp(false);
    }
  }, [connected, powerBi]);

  return (
    <Row>
      <Cell isEmpty={!powerBi.name}>
        <div
          className="d-flex align-items-center cursor-pointer"
          onClick={() => setShowModalFeed(true)}
          aria-hidden
        >
          <p className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 w-100">
            {powerBi.name}
          </p>
        </div>
        <PreviewFeedModal
          show={showModalFeed}
          onHide={() => {
            setShowModalFeed(false);
          }}
          title={t('common.preview')}
          selectedPowerBiFeedSchema={{
            type: 'googleSlides',
            createdAt: powerBi?.createdAt,
            updatedAt: powerBi?.updatedAt,
            dependency: powerBi?.dependency as AppDependency,
            id: powerBi?.id,
            name: powerBi?.name,
            placement: powerBi?.placement as PlacementsEnum,
          }}
        />
      </Cell>
      <Cell isEmpty={!powerBi.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {powerBi.ownerApp?.firstName} {powerBi.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!powerBi.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {powerBi.createdAt && formatDate(powerBi.createdAt)}
        </span>
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          {!brokenApp ? (
            <>
              {' '}
              <div className="mx-1">
                <SchedulePowerBi app={powerBi} onUpdate={onUpdate} />
              </div>
              <div className="mx-1">
                <EditPowerBi powerBi={powerBi} onUpdate={onUpdate} />
              </div>{' '}
            </>
          ) : (
            <Tooltip text={t('apps.powerBi.microsoftErrors.powerbi_expired')}>
              <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                <i className="fa fa-exclamation-triangle text-warning" />{' '}
              </span>
            </Tooltip>
          )}

          <div className="mx-1">
            <DeletePowerBi app={powerBi} onUpdate={onUpdate} />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default TableRow;
