import axios, { AxiosError, AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { API_URL } from '../../constants/api';
import {
  GET_FILES_ACTIONS,
  getFilesLoadingActionCreator,
  getFilesSuccessActionCreator,
  getFilesErrorActionCreator,
  GET_ONLY_DIRECTORIES_ACTIONS,
  getOnlyDirectoriesLoadingActionCreator,
  getOnlyDirectoriesSuccessActionCreator,
  getOnlyDirectoriesErrorActionCreator,
  DELETE_FILES_ACTIONS,
  deleteFilesLoadingActionCreator,
  deleteFilesSuccessActionCreator,
  deleteFilesErrorActionCreator,
  RENAME_FILE_ACTIONS,
  renameFileLoadingActionCreator,
  renameFileSuccessActionCreator,
  renameFileErrorActionCreator,
  CREATE_FILE_ACTIONS,
  createFileLoadingActionCreator,
  createFileSuccessActionCreator,
  MOVE_FILE_ACTIONS,
  moveFileLoadingActionCreator,
  moveFileSuccessActionCreator,
  moveFileErrorActionCreator,
  SCHEDULE_FILE_ACTIONS,
  scheduleFileLoadingActionCreator,
  scheduleFileSuccessActionCreator,
  scheduleFileErrorActionCreator,
  ACTIONS,
} from '../actions/fileManager';
import {
  CreateFileRequest,
  FileSchema,
  ScheduleFileRequest,
  GetFilesRequest,
  PaginatedFiles,
  GetFilesRequestQueryParams,
  DeleteFilesRequest,
  RenameFileRequest,
  MoveFileRequest,
  ErrorResponse,
} from '../types/fileManager';

const FILES_URL = `${API_URL}/files`;

// services

export const createFileService = async (
  request: CreateFileRequest,
): Promise<Partial<FileSchema>> => {
  const {
    name,
    isDirectory,
    parentDirectoryId,
    file,
    width,
    height,
    isPublic,
    conversionJobId,
  } = request;

  const fd = new FormData();

  fd.append('name', name);
  fd.append('isDirectory', isDirectory.toString());
  fd.append('isPublic', isPublic.toString());
  fd.append(
    'conversionJobId',
    conversionJobId ? conversionJobId.toString() : '',
  );

  if (parentDirectoryId) {
    fd.append('parentDirectoryId', parentDirectoryId);
  }

  if (file) {
    fd.append('file', file);
  }

  if (width && height) {
    fd.append('width', width);
    fd.append('height', height);
  }

  const response = await axios({
    method: 'POST',
    url: FILES_URL,
    data: fd,
  });

  return response.data;
};

export const scheduleFileService = async (
  requestData: ScheduleFileRequest,
): Promise<void> => {
  const { data } = await axios.post(
    `${FILES_URL}/${requestData.fileId}`,
    requestData,
  );

  return data;
};

export const getFilesService = async ({
  params,
}: GetFilesRequest): Promise<PaginatedFiles> => {
  const {
    search,
    limit,
    page,
    type: fileType,
    directoryId,
    onlyDir,
    sortBy,
    isPublic,
  } = params;

  const queryParams: Partial<GetFilesRequestQueryParams> = {
    search,
    limit,
    page,
    sortBy,
    isPublic,
  };

  if (fileType) {
    queryParams.type = fileType;
  }

  if (directoryId) {
    queryParams.directoryId = directoryId;
  }

  if (onlyDir) {
    queryParams.onlyDir = onlyDir;
  }

  const response = await axios({
    method: 'GET',
    url: FILES_URL,
    params: queryParams,
  });

  return response.data;
};

export const deleteFilesService = async ({
  params,
}: DeleteFilesRequest): Promise<void> => {
  const response = await axios({
    method: 'DELETE',
    url: FILES_URL,
    params,
  });

  return response.data;
};

export const renameFileService = async ({
  fileId,
  name,
}: RenameFileRequest): Promise<Partial<FileSchema>> => {
  const response = await axios({
    method: 'PATCH',
    url: `${FILES_URL}/${fileId}`,
    data: { name },
  });

  return response.data;
};

export const moveFileService = async (
  moveFileRequest: MoveFileRequest,
): Promise<void> => {
  const response = await axios({
    method: 'PUT',
    url: FILES_URL,
    data: moveFileRequest,
  });

  return response.data;
};

function* getFilesWorker({ payload }: GET_FILES_ACTIONS) {
  try {
    yield put(getFilesLoadingActionCreator({ loading: true }));

    const response: PaginatedFiles = yield call(
      getFilesService,
      payload as GetFilesRequest,
    );

    yield put(
      getFilesSuccessActionCreator({
        data: response,
        loading: false,
        isGetFilesSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      getFilesErrorActionCreator({
        error: response.data,
        loading: false,
        isGetFilesSuccess: false,
      }),
    );
  }
}

function* getOnlyDirectoriesWorker({ payload }: GET_ONLY_DIRECTORIES_ACTIONS) {
  try {
    yield put(getOnlyDirectoriesLoadingActionCreator({ loading: true }));

    const response: PaginatedFiles = yield call(
      getFilesService,
      payload as GetFilesRequest,
    );

    yield put(
      getOnlyDirectoriesSuccessActionCreator({
        data: response,
        loading: false,
        isGetOnlyDirectoriesSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      getOnlyDirectoriesErrorActionCreator({
        error: response.data,
        loading: false,
        isGetOnlyDirectoriesSuccess: false,
      }),
    );
  }
}

function* deleteFilesWorker({ payload }: DELETE_FILES_ACTIONS) {
  try {
    yield put(deleteFilesLoadingActionCreator({ loading: true }));

    const response: void = yield call(
      deleteFilesService,
      payload as DeleteFilesRequest,
    );

    yield put(
      deleteFilesSuccessActionCreator({
        data: response,
        loading: false,
        isDeleteFilesSuccess: true,
      }),
    );
    toast.success(
      payload?.isFolder
        ? i18next.t<string>('file_uploader.folder_deleted')
        : i18next.t<string>('file_uploader.file_deleted'),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      deleteFilesErrorActionCreator({
        error: response.data,
        loading: false,
        isDeleteFilesSuccess: false,
      }),
    );
  }
}

function* renameFileWorker({ payload }: RENAME_FILE_ACTIONS) {
  try {
    yield put(renameFileLoadingActionCreator({ loading: true }));

    const response: Partial<FileSchema> = yield call(
      renameFileService,
      payload as RenameFileRequest,
    );

    yield put(
      renameFileSuccessActionCreator({
        data: response,
        loading: false,
        isRenameFileSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      renameFileErrorActionCreator({
        error: response.data,
        loading: false,
        isRenameFileSuccess: false,
      }),
    );
  }
}

function* createFileWorker({ payload }: CREATE_FILE_ACTIONS) {
  try {
    yield put(createFileLoadingActionCreator({ loading: true }));

    const response: Partial<FileSchema> = yield call(
      createFileService,
      payload as CreateFileRequest,
    );

    yield put(
      createFileSuccessActionCreator({
        data: response,
        loading: false,
        isCreateFileSuccess: true,
      }),
    );
  } catch (error) {
    yield put(createFileLoadingActionCreator({ loading: false }));
  }
}

function* moveFileWorker({ payload }: MOVE_FILE_ACTIONS) {
  try {
    yield put(moveFileLoadingActionCreator({ loading: true }));

    const response: void = yield call(
      moveFileService,
      payload as MoveFileRequest,
    );

    yield put(
      moveFileSuccessActionCreator({
        data: response,
        loading: false,
        isMoveFileSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      moveFileErrorActionCreator({
        error: response.data,
        loading: false,
        isMoveFileSuccess: false,
      }),
    );
  }
}

function* scheduleFileWorker({ payload }: SCHEDULE_FILE_ACTIONS) {
  try {
    yield put(scheduleFileLoadingActionCreator({ loading: true }));
    const response: void = yield call(
      scheduleFileService,
      payload as ScheduleFileRequest,
    );
    yield put(
      scheduleFileSuccessActionCreator({
        data: response,
        loading: false,
        isScheduleFileSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;
    yield put(
      scheduleFileErrorActionCreator({
        error: response.data,
        loading: false,
        isScheduleFileSuccess: false,
      }),
    );
  }
}

// watchers

export default function* fileManagerWatcher() {
  yield takeEvery<GET_FILES_ACTIONS>(ACTIONS.GET_FILES, getFilesWorker);

  yield takeEvery<GET_ONLY_DIRECTORIES_ACTIONS>(
    ACTIONS.GET_ONLY_DIRECTORIES,
    getOnlyDirectoriesWorker,
  );

  yield takeEvery<DELETE_FILES_ACTIONS>(
    ACTIONS.DELETE_FILES,
    deleteFilesWorker,
  );

  yield takeEvery<RENAME_FILE_ACTIONS>(ACTIONS.RENAME_FILE, renameFileWorker);

  yield takeEvery<CREATE_FILE_ACTIONS>(ACTIONS.CREATE_FILE, createFileWorker);

  yield takeEvery<MOVE_FILE_ACTIONS>(ACTIONS.MOVE_FILE, moveFileWorker);

  yield takeEvery<SCHEDULE_FILE_ACTIONS>(
    ACTIONS.SCHEDULE_FILE,
    scheduleFileWorker,
  );
}
