import { call, takeEvery, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import getUserDataResponseData from '../../api/endpoints/userData/interfaces/getUserDataResponseData.interface';
import GetUserDataAction from '../actions/userData/interfaces/getUserDataAction.interface';
import UserDataActions from '../actions/userData/userDataAction.enum';
import { logout } from '../../utils/auth0';

import {
  getUserData,
  saveUserData,
  saveUserIntegrationData,
  setUserLoading,
} from '../actions/userData';
import api from '../../api';
import Integration from '../../interfaces/integration.interface';
import postUserIntegrationRequestData from '../../api/endpoints/userData/interfaces/postUserIntegrationRequestData.interface';

function* getUserDataRequestWorker() {
  try {
    const responseData: getUserDataResponseData = yield call(
      api.userData.getUserData,
    );
    yield put(saveUserData(responseData));
  } catch (error: any) {
    // eslint-disable-next-line no-debugger
    // TODO: Handle error
    if (error.response?.status === 404) {
      // means that you logged in on Auth0 but user is not in our DB
      // sdcfgvwsdfg
      yield call(logout);
    }
    if (error.message?.includes('undefined')) {
      yield call(logout);
    }
  }
}

function* updateUserDataRequestWorker({ payload }: any) {
  yield put(setUserLoading({ loading: true }));
  const formData = new FormData();
  const {
    picture,
    firstNameLoc,
    lastNameLoc,
    phoneLoc,
    languageLoc,
    numberOfScreens,
  } = payload;

  const pictureName = picture?.name.split(' ').join('-');

  if (pictureName) {
    formData.append('profilePicture', picture, pictureName);
  }
  formData.append('firstName', firstNameLoc);
  formData.append('lastName', lastNameLoc);
  formData.append('numberOfScreens', numberOfScreens);
  formData.append('phoneNumber', phoneLoc);
  formData.append('language', languageLoc.toUpperCase());

  try {
    const reqData: getUserDataResponseData = yield call(
      api.userData.updateUserData,
      formData,
    );

    yield put(saveUserData(reqData));
    yield put(getUserData());
    yield put(setUserLoading({ loading: false }));
  } catch (error) {
    // TODO: Handle error
    toast.error('Failed to update user.');
  }
}

function* updateUserIntegrationRequestWorker({
  payload,
}: {
  payload: postUserIntegrationRequestData;
}) {
  try {
    const result: Integration[] = yield call(api.apps.disconnectApp, payload);
    yield put(saveUserIntegrationData(result));
    yield put(getUserData());
    toast.success(i18next.t<string>('apps.integrations.removed'));
  } catch (error) {
    // TODO: Handle error
  }
}

export default function* userDataWatcher() {
  yield takeEvery<GetUserDataAction>(
    UserDataActions.GET_USER_DATA,
    getUserDataRequestWorker,
  );
  yield takeEvery<any>(
    UserDataActions.UPDATE_USER_DATA_REQUEST,
    updateUserDataRequestWorker,
  );
  yield takeEvery<any>(
    UserDataActions.UPDATE_USER_INTEGRATION_REQUEST,
    updateUserIntegrationRequestWorker,
  );
}
