import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { times } from 'lodash';
import clsx from 'clsx';

const disabledArrowClassNames = 'pe-none opacity-50';
const limitBetweenThreeDots = 2;

const ThreeDots = ({ key }: { key: number | string }) => (
  <li key={key} className="page-item">
    <span className="page-link">...</span>
  </li>
);

export default ({
  pageSize,
  pageNumber,
  total,
  onPageNumberUpdate,
  className,
  hideText,
  limit = 3,
}: {
  pageSize: number;
  pageNumber: number;
  onPageNumberUpdate: (pageNumber: number) => void;
  total: number;
  className?: string;
  limit?: number;
  hideText?: boolean;
}) => {
  const { t } = useTranslation();

  // The pages number value calculation
  const pagesNumber = useMemo(
    () => (total <= pageSize ? 1 : Math.ceil(total / pageSize)),
    [total, pageSize],
  );
  // The current page first item number calculation
  const startNumber = useMemo(
    () => pageSize * (pageNumber - 1) + 1,
    [pageSize, pageNumber],
  );
  // The current page last item number calculation
  const endNumber = useMemo(() => {
    const endNumberValue = pageSize * pageNumber;

    return endNumberValue > total ? total : endNumberValue;
  }, [pageSize, pageNumber, total]);

  const goNext = () => {
    if (pageNumber < pagesNumber) onPageNumberUpdate(pageNumber + 1);
  };
  const goPrev = () => {
    if (pageNumber > 1) onPageNumberUpdate(pageNumber - 1);
  };

  return (
    <div className={clsx('d-flex flex-stack flex-wrap', className)}>
      {!hideText && (
        <div className="fs-6 fw-bold text-gray-700">
          {t('common.pagination.showing')} {total ? startNumber : 0}{' '}
          {t('common.pagination.to')} {endNumber} {t('common.pagination.of')}{' '}
          {total} {t('common.pagination.entries')}
        </div>
      )}
      <ul className="pagination">
        {times(pagesNumber + 2, (index) => {
          // "Go back" arrow render
          if (index === 0) {
            return (
              <li key={index} className="page-item previous">
                <span
                  role="link"
                  tabIndex={-1}
                  className={clsx(
                    'page-link hoverable',
                    pageNumber === 1 && disabledArrowClassNames,
                  )}
                  onKeyPress={goPrev}
                  onClick={goPrev}
                >
                  <i className="previous" />
                </span>
              </li>
            );
          }

          // "Go next" arrow render
          if (index === pagesNumber + 1) {
            return (
              <li key={index} className="page-item next">
                <span
                  role="link"
                  tabIndex={-1}
                  className={clsx(
                    'page-link hoverable',
                    pageNumber === pagesNumber && disabledArrowClassNames,
                  )}
                  onKeyPress={goNext}
                  onClick={goNext}
                >
                  <i className="next" />
                </span>
              </li>
            );
          }

          const isExceedLeftOffset =
            pageNumber - index > limit - limitBetweenThreeDots;
          const isExceedRightOffset =
            index - pageNumber > limit - limitBetweenThreeDots;
          const isInTheMiddle =
            pageNumber > limit && pageNumber - 1 < pagesNumber - limit;

          // Hide start and end numbers
          if (
            isInTheMiddle &&
            (isExceedLeftOffset || isExceedRightOffset) &&
            index !== 1 &&
            index !== pagesNumber
          ) {
            return index === pagesNumber - 1 || index === 2 ? (
              <ThreeDots key={index} />
            ) : undefined;
          }

          // Hide middle numbers
          if (
            !isInTheMiddle &&
            index > limit &&
            pagesNumber - index > limit - 1
          ) {
            return index === limit + 1 ? <ThreeDots key={index} /> : undefined;
          }

          // "Page number" item render
          return (
            <li
              key={index}
              className={clsx(
                'page-item',
                index === pageNumber && 'active pe-none',
              )}
            >
              <span
                role="link"
                tabIndex={-1}
                className="page-link hoverable"
                onKeyPress={() => onPageNumberUpdate(index)}
                onClick={() => onPageNumberUpdate(index)}
              >
                {index}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
