import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { KTSVG } from '../../../../../lib/metronic/helpers';
import IContent from '../../../../../interfaces/content.interface';
import pointerEventsStyles from '../../../../../styles/pointerEvents.module.scss';
import VideoThumbnail from './Thumbnails/VideoThumbnail';
import ImageThumbnail from './Thumbnails/ImageThumbnail';
import ContentIcon from './ContentIcon';
import Thumbnails from './Thumbnails';
import Orientation from '../../../../../enums/orientation.enum';
import Playlist from '../../../../../interfaces/playlist.interface';
import { FileType } from '../../../../../store/types/fileManager';
import ThumbnailIcon from './Thumbnails/ThumbnailIcon';
import sheets from '../../../../../assets/images/apps/sheets.svg';
import googleDocs from '../../../../../assets/images/apps/google_docs.svg';
import googleSlides from '../../../../../assets/images/apps/google_slides.svg';
import pdfSvg from '../../../../../assets/images/apps/pdf_svg.svg';
import Device from '../../../../../interfaces/devices/device.interface';

export default ({
  content,
  disableLinks,
  children,
  playlist,
  devicesWithoutLicence,
}: // noLicense = false,
{
  content: IContent;
  disableLinks?: boolean;
  children?: React.ReactNode;
  playlist?: Playlist;
  devicesWithoutLicence?: Device[];
  // noLicense?: boolean;
}) => {
  const { t } = useTranslation();
  const { mediaGroup, app } = content;
  const type = mediaGroup?.type;
  const appType = app?.type;

  let preview: React.ReactNode;

  if (type) {
    let fileType = t('common.unknown_file_type');

    if (mediaGroup?.medias.length) {
      const filePath = mediaGroup?.medias[0].url ?? '';
      const subStrings = filePath.split('.');
      fileType = subStrings[subStrings.length - 1];
    }

    switch (fileType) {
      case FileType.MP4:
        preview = <VideoThumbnail content={content} />;
        break;
      case FileType.DOC:
      case FileType.DOCX:
        preview = <ThumbnailIcon src={googleDocs} />;
        break;
      case FileType.XLSX:
      case FileType.XLS:
        preview = <ThumbnailIcon src={sheets} />;
        break;
      case FileType.PPT:
      case FileType.PPTX:
        preview = <ThumbnailIcon src={googleSlides} />;
        break;
      case FileType.PDF:
        preview = <ThumbnailIcon src={pdfSvg} />;
        break;
      default:
        preview = <ImageThumbnail content={content} />;
        break;
    }
  }

  if (type) {
    return (
      <div className="d-flex align-items-center">
        <div
          className="symbol symbol-50px me-5 d-flex"
          onContextMenu={(ev) => ev.preventDefault()}
        >
          {preview}
        </div>
        <span
          className={clsx(
            'text-dark fw-bold text-hover-primary d-block mb-1 fs-6',
            disableLinks && pointerEventsStyles.noPointerEvents,
          )}
        >
          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
            {children}
            {`${content.title} ${
              playlist?.template && content.zoneId === Orientation.FullScreen
                ? `(${t('common.orientations.full_screen')})`
                : ''
            }`}

            {content.isLocked && (
              <KTSVG path="/media/icons/locked.svg" className="svg-icon-2x" />
            )}

            <ContentIcon typeApp={type ?? appType} />
            {/** should remove the icons if the content is from a free library , topic type = 0 || FREE */}
            {content?.topic?.type === 1
              ? devicesWithoutLicence &&
                devicesWithoutLicence.length > 0 &&
                content.topic && (
                  <>
                    <i
                      title={
                        devicesWithoutLicence.length > 1
                          ? `${t(
                              'devices.devices',
                            )} (${devicesWithoutLicence.map(
                              (device) => ` ${device.name}`,
                            )}) ${t('devices.warning_content_devices')}`
                          : `${t('devices.devices')} ( ${
                              devicesWithoutLicence?.[0]?.name
                            }) ${t('devices.warning_content_devices')}`
                      }
                      className="fas fa-exclamation-triangle text-warning"
                    />
                  </>
                )
              : null}
          </span>
        </span>
      </div>
    );
  }

  return (
    <>
      <div
        className="d-flex align-items-center cursor-pointer"
        aria-hidden="true"
      >
        <div
          className="symbol symbol-50px me-5 d-flex"
          onContextMenu={(ev) => ev.preventDefault()}
        >
          <Thumbnails content={content} />
        </div>

        <span
          className={clsx(
            'text-dark fw-bold text-hover-primary d-block mb-1 fs-6',
            disableLinks && pointerEventsStyles.noPointerEvents,
          )}
        >
          <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6">
            {children}
            {`${content.title} ${
              playlist?.template && content.zoneId === Orientation.FullScreen
                ? `(${t('common.orientations.full_screen')})`
                : ''
            }`}
            {content.isLocked && (
              <KTSVG path="/media/icons/locked.svg" className="svg-icon-2x" />
            )}

            <ContentIcon typeApp={type ?? appType} />
          </span>
        </span>
      </div>
    </>
  );
};
