import axios from 'axios';
import { API_URL } from '../../../constants/api';
import CustomFeedsRoutes from './enums/customFeeds.enum';
import CreateCustomFeedRequest from './interfaces/creatCustomFeedData.interface';
import GetCustomFeedResponseData from './interfaces/getCustomFeeds.interface';

export default {
  /**
   * Retrieves the list of custom Feeds
   */
  getCustomFeeds: async (): Promise<GetCustomFeedResponseData[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CustomFeedsRoutes.CustomFeeds}`,
    });
    return data;
  },

  /**
   * Create CustomFeed
   * @param requestData
   */
  createCustomFeed: async (
    requestData: CreateCustomFeedRequest,
  ): Promise<void> => {
    const { data } = await axios.post(
      `${API_URL}/${CustomFeedsRoutes.CustomFeeds}`,
      requestData,
    );

    return data;
  },

  /**
   * Delete app
   * @param feedId
   */
  deleteFeed: async (feedId: string): Promise<void> => {
    const { data } = await axios.delete(
      `${API_URL}/${CustomFeedsRoutes.CustomFeeds}/${feedId}`,
    );
    return data;
  },
};
