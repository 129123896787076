import clsx from 'clsx';
import React from 'react';
import LoginAsHeader from '../../components/LoginAsHeader';
import styles from './layout.module.scss';

export default ({ children }: { children: React.ReactNode }) => {
  const loginAsUserName = window.localStorage.getItem('loginAsUserName');

  return (
    <>
      {loginAsUserName && <LoginAsHeader />}
      <div className={clsx('h-100', styles.page, loginAsUserName && 'mt-40px')}>
        {children}
      </div>
    </>
  );
};
