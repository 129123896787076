import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BulkUpdateDevicesRequestDataInterface from '../../../api/endpoints/devices/interfaces/bulkUpdateDevicesRequestData.interface';
import BulkEditLicense from '../../../components/Devices/BulkEditLicence';
import { LicensingFormInputs } from '../../../components/Devices/LicensingForm';
import { UserRole } from '../../../enums/userRole.enum';
import {
  updateMultipleAdminDevices,
  updateMultipleDevices,
} from '../../../store/actions/devices';
import GlobalState from '../../../store/reducers/globalState.interface';
import UpdateMultipleDevicesAction from '../../../store/actions/devices/interfaces/updateMultipleDevicesAction.interface';

export default ({
  children,
  selectedItems,
  onBulkEditLicenceDevice,
  isSuperAdmin,
  isLoading,
}: {
  children: React.ReactNode;
  selectedItems: string[];
  isSuperAdmin?: boolean;
  isLoading?: boolean;
  onBulkEditLicenceDevice?: (
    data: Partial<BulkUpdateDevicesRequestDataInterface>,
  ) => void;
}) => {
  const dispatch = useDispatch();
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);

  const handleSubmit = (data: Partial<LicensingFormInputs>) => {
    if (onBulkEditLicenceDevice) {
      onBulkEditLicenceDevice({ ...data, deviceIds: selectedItems });
      return;
    }

    if (roles.includes(UserRole.SuperAdmin)) {
      dispatch(
        updateMultipleAdminDevices({
          data: { ...data, deviceIds: selectedItems },
        } as UpdateMultipleDevicesAction['payload']),
      );
    } else {
      dispatch(
        updateMultipleDevices({
          data: { ...data, deviceIds: selectedItems },
        } as UpdateMultipleDevicesAction['payload']),
      );
    }
  };
  return selectedItems.length > 1 ? (
    <BulkEditLicense
      onSubmit={handleSubmit}
      isAdmin={isSuperAdmin}
      isLoading={isLoading}
    >
      {children}
    </BulkEditLicense>
  ) : (
    <></>
  );
};
