import React from 'react';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';
import ErrorMessage from '../ErrorMessage';

export default ({
  name,
  errors,
  className,
}: {
  name: string;
  errors: any;
  className?: string;
}) => (
  <ErrorMessage className={className}>
    <HookFormErrorMessage errors={errors} name={name} />
  </ErrorMessage>
);
