import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../onboarding.css';
import UploadContent from '../../Aside/UploadContent';
import { start } from '../../../webWorkers/conversionJobStatus';
import eventEmitter from '../../../utils/eventEmitter';
import { PlaylistEvents } from '../../../enums/events/playlistEvents.enum';
import { getUserData } from '../../../store/actions/userData';
import WithDevicePreview from '../../../components/Devices/WithDevicePreview';
import noPreview from '../../../assets/images/noPreview/noPreview.png';

export default ({
  isActive,
  title,
  targetTab,
  redirectLink,
  isDefaultSelected,
  openUploadContentModal,
  openDevicePreviewModal,
  deviceId,
}: {
  isActive: boolean;
  title: string;
  targetTab: string;
  redirectLink?: string;
  isDefaultSelected?: boolean;
  openUploadContentModal?: boolean;
  openDevicePreviewModal?: boolean;
  deviceId?: string;
}) => {
  const ahref = React.useRef<HTMLAnchorElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDefaultSelected && ahref.current) {
      ahref.current.click();
    }
  }, [isDefaultSelected]);

  const handleClick = () => {
    history.push(redirectLink ?? '');
  };

  function LinkTag() {
    return (
      <i
        role="button"
        tabIndex={-1}
        aria-label="a"
        onKeyPress={handleClick}
        onClick={handleClick}
        className="fas fa-external-link-alt text-dark ms-2"
      />
    );
  }

  const handleSuccess = (playlistIds?: string[]) => {
    start();
    dispatch(getUserData());
    eventEmitter.emit(PlaylistEvents.Refresh, playlistIds);
  };
  const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    // Get all video elements
    const videos = document.getElementsByTagName('video');

    // Add event listeners to each video
    Array.from(videos).forEach((video: HTMLVideoElement) => {
      video.addEventListener('play', () => {
        // Pause all other videos
        Array.from(videos).forEach((v: HTMLVideoElement) => {
          if (v !== video) {
            v.pause();
          }
        });
      });
    });
  };
  return (
    <li
      className={clsx('step0 nav-item', isActive && 'active')}
      role="presentation"
    >
      <a
        className="nav-link text-dark"
        data-bs-toggle="tab"
        href={targetTab}
        aria-selected="true"
        role="tab"
        ref={ahref}
        onClick={handleAnchorClick}
      >
        {title}
        {redirectLink && <LinkTag />}

        {openUploadContentModal && !redirectLink && (
          <UploadContent onSuccess={handleSuccess}>
            <i className="fas fa-external-link-alt text-dark ms-2" />
          </UploadContent>
        )}
        {openDevicePreviewModal && !redirectLink && (
          <WithDevicePreview
            id={deviceId}
            previewUrl={
              `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?deviceId=${deviceId}` ||
              noPreview
            }
          >
            <i className="fas fa-external-link-alt text-dark ms-2" />
          </WithDevicePreview>
        )}
      </a>
    </li>
  );
};
