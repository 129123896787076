import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import DevicesGroupsTable from '../../../components/AssignmentTables/DevicesGroupsTable';
import { defaultDebounceValue } from '../../../constants/api/debounceSettings';
import DeviceGroup from '../../../interfaces/deviceGroup.interface';
import User from '../../../interfaces/user/user.interface';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
import api from '../../../api';

export default ({
  showIcon = false,
  onSelectionChange,
  preselectedItems,
  currentUser,
  isPowerBiPublished,
}: {
  showIcon?: boolean;
  onSelectionChange: (selectedItems: string[]) => void;
  preselectedItems: string[];
  currentUser?: User;
  isPowerBiPublished?: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [devicesGroups, setDevicesGroups] = useState<DeviceGroup[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isAdmin] = useIsAdmin();

  const fetchDevicesGroupsList = useCallback(
    debounce(async (searchData: { [key: string]: string }) => {
      try {
        const { items, meta } = isAdmin
          ? await api.devicesGroups.getAdminDevicesGroups({
              ...searchData,
              currentUserId: currentUser?.id,
            })
          : await api.devicesGroups.getDevicesGroupsForAddContent(searchData);

        setDevicesGroups(items);
        setTotal(meta.totalItems);
      } catch (e) {
        // Do nothing
      }

      setLoading(false);
    }, defaultDebounceValue),
    [],
  );

  useEffect(() => {
    setLoading(true);
    fetchDevicesGroupsList(filters);
  }, [filters]);

  return (
    <DevicesGroupsTable
      isLoading={loading}
      devicesGroups={devicesGroups}
      total={total}
      onSearchDataChange={setFilters}
      onSelectionChange={onSelectionChange}
      preselectedItems={preselectedItems}
      showIcon={showIcon}
      isPowerBiPublished={isPowerBiPublished}
    />
  );
};
