import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import api from '../../../api';
import Ticker from '../../../components/Ticker';
import App from '../../../interfaces/app.interface';
import { readAllFromQueryParams } from '../../../utils/queryParams';

export default () => {
  const { search } = useLocation();
  const [apps, setApps] = useState<App[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const getAppList = async (recentSearch: string) => {
    try {
      setLoading(true);
      const { items, meta } = await api.apps.getApps(
        'ticker',
        readAllFromQueryParams(recentSearch),
      );
      setApps(items);
      setTotal(meta.totalItems);
    } catch (e) {
      // just be patient for now ;)
    }
    setLoading(false);
  };

  useEffect(() => {
    getAppList(search);
  }, [search]);

  return (
    <Ticker
      onUpdate={() => getAppList(search)}
      tickers={apps}
      total={total}
      isLoading={loading}
    />
  );
};
