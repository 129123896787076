/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Device from '../../../interfaces/devices/device.interface';
import PlaylistsQueryParam from '../../../api/endpoints/playlists/enums/playlistsQueryParam.enum';
import { buildQueryString } from '../../../utils/queryParams';
import config from '../../../routing/config';

export default ({
  device: { id, name },
  children,
  isAdmin,
  currentUserId,
}: {
  device: Device;
  children?: React.ReactNode;
  isAdmin: boolean;
  currentUserId?: string;
}) => {
  const playlistLink = () => {
    if (currentUserId) {
      return `${config.playlists.route}${buildQueryString({
        [PlaylistsQueryParam.DeviceIds]: [id],
      })}?currentUserId=${currentUserId}`;
    }
    return `${config.playlists.route}${buildQueryString({
      [PlaylistsQueryParam.DeviceIds]: [id],
    })}&deviceName=${name}`;
  };
  return (
    <Link className="text-reset" to={playlistLink}>
      {children ?? (
        <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
          <i className="fa fa-book" />
        </span>
      )}
    </Link>
  );
};
