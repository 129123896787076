import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import TableRow from '../../Playlists/PlaylistsTable/TableRow';
import Columns, { TableHeaderColumn } from '../../Table/Columns';
import PlaylistsSortField from '../../../api/endpoints/playlists/enums/playlistsSortField.enum';
import PlaylistsQueryParam from '../../../api/endpoints/playlists/enums/playlistsQueryParam.enum';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import Playlist from '../../../interfaces/playlist.interface';
import useDidUpdate from '../../../utils/hooks/useDidUpdate';
import SortOrder from '../../../enums/sortOrder.enum';
import { SortField } from '../../../utils/sorting';
import WithSpinner from '../../WithSpinner';
import Pagination from '../../Pagination';
import Search from '../../Search';
import Table from '../../Table';
import useTableMultiSelect, {
  isAllSelected,
} from '../../../utils/hooks/useTableMultiSelect';
import PlaylistCells from '../../../enums/tableCells/playlistCells.enum';

const preloaderHeight = 313.5;
const pageSize = 150;

export default ({
  playlists,
  isLoading,
  total,
  onSearchDataChange,
  onSelectionChange,
  preselectedItems,
  lockingPlaylist,
}: {
  playlists: Playlist[];
  isLoading: boolean;
  total: number;
  onSearchDataChange: (searchData: { [key: string]: string }) => void;
  onSelectionChange: (selectedItems: string[]) => void;
  preselectedItems: string[];
  lockingPlaylist?: boolean;
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  // const [filteredPlaylist, setFilteredPlaylist] = useState<Playlist[]>([]);

  const filteredPlaylist = lockingPlaylist
    ? playlists.filter(
        (playlist) =>
          playlist.locked === false || playlist.isLockedContent === false,
      )
    : playlists;
  const [sortField, setSortField] = useState<SortField>({
    field: null,
    isInverted: false,
  });
  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(filteredPlaylist, 'id'),
    preselectedItems,
  );
  const isSearchValueChanged = useRef(false);

  useEffect(() => {
    onSearchDataChange({
      [PlaylistsQueryParam.Search]: searchValue,
      [PaginationQueryParam.Limit]: String(pageSize),
      [PaginationQueryParam.Page]: String(pageNumber),
      [SortingQueryParam.SortBy]: sortField.field ?? '',
      [SortingQueryParam.SortOrder]: sortField.isInverted
        ? SortOrder.Ascending
        : SortOrder.Descending,
    });
  }, [searchValue, pageNumber, sortField]);

  useEffect(() => onSelectionChange(selectedItems), [selectedItems]);

  useEffect(() => {
    if (!isSearchValueChanged.current && total === 1) {
      selectAll();
    }
  }, [total]);

  useDidUpdate(() => {
    isSearchValueChanged.current = true;
  }, [searchValue]);

  const columns: TableHeaderColumn<PlaylistsSortField>[] = [
    {
      name: t('playlists.playlist_title'),
      sortBy: PlaylistsSortField.Name,
    },
    {
      name: t('playlists.priority'),
    },
    {
      name: t('common.start_date'),
    },
    {
      name: t('playlists.end_date'),
      sortBy: PlaylistsSortField.EndDate,
    },
    {
      name: t('playlists.status'),
    },
    {
      name: t('playlists.created_by'),
    },
  ];

  return (
    <>
      <Search
        searchValue={searchValue}
        onChange={setSearchValue}
        placeholder={t('playlists.search_playlists')}
        wrapperClassName="mb-3"
      />
      <Table>
        <Table.Head>
          <Table.Head.Row>
            <Columns<PlaylistsSortField>
              columns={columns}
              config={{ sortField, setSortField, isSelectable: true }}
              onChange={selectAll}
              checked={isAllSelected(
                selectedItems,
                map(filteredPlaylist, 'id'),
              )}
            />
          </Table.Head.Row>
        </Table.Head>
        <Table.Body
          isEmpty={!isLoading && !playlists.length}
          emptyStateHeight={preloaderHeight}
          emptyStateChildren={t('playlists.no_playlists')}
        >
          <WithSpinner
            isLoading={isLoading}
            style={{ minHeight: `${preloaderHeight}px` }}
            isForTable
            size="md"
          >
            {playlists.map((playlist) => (
              <TableRow
                key={playlist.id}
                playlist={playlist}
                isChecked={selectedItems.includes(playlist.id)}
                onCheck={setSelectedItem}
                config={{
                  skipActions: true,
                  disableLinks: true,
                  isSelectable: true,
                  skipColumns: [
                    PlaylistCells.DevicesCount,
                    PlaylistCells.Tags,
                    PlaylistCells.LastUpdate,
                    PlaylistCells.LayoutTemplate,
                    PlaylistCells.Duration,
                  ],
                }}
                lockingPlaylist={lockingPlaylist}
              />
            ))}
          </WithSpinner>
        </Table.Body>
      </Table>
      <Pagination
        total={total}
        pageSize={pageSize}
        pageNumber={pageNumber}
        onPageNumberUpdate={setPageNumber}
        className="mt-3"
      />
    </>
  );
};
