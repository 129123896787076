import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import clsx from 'clsx';
import ReactPlayer from 'react-player';
import {
  FileSchema,
  FileMimeType,
  FileType,
} from '../../store/types/fileManager';
import Pagination from '../../components/Pagination';
import Modal from '../../components/Modal';
import Spinner from '../../components/Spinner';
import fileTypes from '../../constants/docFileTypes';

export interface PreviewFileModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  selectedFileSchema: FileSchema;
}

const PreviewFileModal = ({
  show,
  onHide,
  title,
  selectedFileSchema,
}: PreviewFileModalProps) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages: value }: { numPages: number }) =>
    setNumPages(value);

  let preview: React.ReactNode;

  const { docFileTypes, videoFileTypes, pdfFileTypes, imageFileTypes } =
    fileTypes;

  switch (true) {
    case imageFileTypes.includes(selectedFileSchema?.type):
      preview = (
        <img
          style={{ maxHeight: '80vh', objectFit: 'contain' }}
          alt={selectedFileSchema.title}
          src={selectedFileSchema.url}
          className="w-100"
        />
      );
      break;
    case docFileTypes.includes(selectedFileSchema?.type):
    case pdfFileTypes.includes(selectedFileSchema?.type):
    case pdfFileTypes.includes(
      selectedFileSchema?.mediaGroup?.type as FileMimeType | FileType,
    ):
    case docFileTypes.includes(
      selectedFileSchema?.mediaGroup?.type as FileMimeType | FileType,
    ):
      preview = selectedFileSchema?.mediaGroup ? (
        selectedFileSchema?.mediaGroup.medias.map(
          ({ url, id, title: fileTitle }) => (
            <div key={id} className="mb-5">
              <img src={url} alt={fileTitle} width="100%" />
            </div>
          ),
        )
      ) : (
        <div>
          <Document
            file={selectedFileSchema.url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Spinner size="md" backdropClassName="bg-white" />}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div className="d-flex justify-content-center mt-5">
            <Pagination
              total={numPages}
              pageSize={1}
              pageNumber={pageNumber}
              onPageNumberUpdate={setPageNumber}
              className="mt-3 mb-5"
              hideText
            />
          </div>
        </div>
      );
      break;
    case videoFileTypes.includes(selectedFileSchema?.type):
      preview = (
        <ReactPlayer
          url={selectedFileSchema.url}
          controls
          width="100%"
          height="100%"
        />
      );
      break;
    default:
      preview = (
        <p className="fw-bolder text-dark p-0 m-0">
          Sadly, currently we only display images.
        </p>
      );
  }

  const isPdf = selectedFileSchema?.type === FileMimeType.PDF;

  return (
    <Modal
      show={show}
      onHide={onHide}
      widthDefaultSize={false}
      title={title}
      withFooter={false}
      dialogClassName={isPdf ? 'mw-650px' : 'mw-1000px'}
    >
      <div
        className={clsx(
          'd-flex flex-column flex-center',
          !isPdf && 'overflow-auto',
        )}
        onContextMenu={(ev) => ev.preventDefault()}
      >
        {preview}
      </div>
    </Modal>
  );
};

export default PreviewFileModal;
