import { map } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import App from '../../../interfaces/app.interface';
import useTableMultiSelect from '../../../utils/hooks/useTableMultiSelect';
import Card from '../../Card';
import Pagination from '../../Pagination/QueryParamPagination';
import Search from '../../Search/QueryParamSearch';
import RssTable from './Table';
import CreateRss from '../../../containers/Apps/Rss/CreateRss';
import { KTSVG } from '../../../lib/metronic/helpers';

const pageSize = 6;
const tableRowHeight = 63;

export default ({
  rss,
  total,
  isLoading,
  resetOnSearch,
  onUpdate,
}: {
  rss: App[];
  total: number;
  isLoading: boolean;
  resetOnSearch?: boolean;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();

  const { search } = useLocation();

  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(rss, 'id'),
    [],
    resetOnSearch ? search : undefined,
  );

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          <Search
            queryField="search"
            pageNumberQueryField=""
            placeholder={t('common.search')}
            className="me-2"
          />
          <div className="d-flex">
            <CreateRss onUpdate={onUpdate}>
              <button type="button" className="mt-2 btn btn-primary">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                {t('apps.rss.create_new_rss')}
              </button>
            </CreateRss>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <RssTable
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          onUpdate={onUpdate}
          rss={rss}
          isLoading={isLoading}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
        />

        <Pagination
          total={total}
          pageSize={pageSize}
          pageNumberQueryField={PaginationQueryParam.Page}
          pageSizeQueryField={PaginationQueryParam.Limit}
          className="mt-3 mb-5"
        />
      </Card.Body>
    </Card>
  );
};
