import axios from 'axios';
import { TWITTER_MICROSERVICE } from '../../../../constants/api';

export const api = axios.create({
  baseURL: TWITTER_MICROSERVICE,
  responseType: 'json',
});

export const Apis = {
  getPages: (userID?: string, AccessToken?: string) =>
    api.post(`facebook/getPages`, { AccessToken, userID }),
  getFeedsOfPage: (pageName?: string, AccessToken?: string, userID?: string) =>
    api.post(`facebook/getFeeds`, { AccessToken, userID, name: pageName }),
};
