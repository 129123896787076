import i18n, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './languages/en/translation.json';
import frTranslation from './languages/fr/translation.json';
import esTranslation from './languages/es/translation.json';
import Language from '../enums/language.enum';

i18n
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init i18next
  .init({
    resources: {
      [Language.English]: { translation: enTranslation },
      [Language.French]: { translation: frTranslation },
      [Language.Spanish]: { translation: esTranslation },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    fallbackLng: [Language.English],
  });

/**
 * Changes the language
 * @param language
 */
export const changeLanguage = (language: Language): Promise<TFunction> =>
  i18n.changeLanguage(language);
