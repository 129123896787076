/* eslint-disable jsx-a11y/html-has-lang */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import TalkToSales from '../TalkToSales';

export default ({
  isModalVisible,
  handleClose,
  children,
}: {
  isModalVisible: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <>
      <TalkToSales handleClose={handleClose} isModalVisible={isModalVisible}>
        {' '}
        {children}
      </TalkToSales>
    </>
  );
};
