import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import AssignDevices from '../../../../../containers/Devices/AssignDevices';
import AssignDevicesGroups from '../../../../../containers/DevicesGroups/AssignDevicesGroups';
import CreateUserRequestData from '../../../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import SelectCompanies from '../../../../../containers/Companies/SelectCompanies';
import SelectLocations from '../../../../../containers/SelectLocations';
import useDidUpdate from '../../../../../utils/hooks/useDidUpdate';
import RadioButtons from '../../../../RadioButtons';
import Card from '../../../../Card';
import User from '../../../../../interfaces/user/user.interface';

enum Tab {
  Devices = 'devices',
  DevicesGroups = 'devices-groups',
  Locations = 'locations',
  Companies = 'companies',
}

// const getInitialTab = ({
//   deviceGroupsIds,
//   assignedCompanyIds,
//   assignedLocationIds,
// }: Partial<CreateUserRequestData>): Tab => {
//   if (deviceGroupsIds?.length) return Tab.DevicesGroups;
//   if (assignedLocationIds?.length) return Tab.Locations;
//   if (assignedCompanyIds?.length) return Tab.Companies;

//   return Tab.Devices;
// };

export default ({
  data,
  setData,
  currentUser,
}: {
  data: Partial<CreateUserRequestData>;
  setData: (newData: Partial<CreateUserRequestData>) => void;
  currentUser?: User;
}) => {
  const { t } = useTranslation();
  // const [currentTab, setCurrentTab] = useState<Tab>(getInitialTab(data));
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.Devices);

  let currentTable: React.ReactNode;
  useDidUpdate(
    () =>
      setData({
        ...data,
        assignedDevices: [],
        deviceGroupsIds: [],
      }),
    [currentTab],
  );

  switch (currentTab) {
    case Tab.Devices:
      currentTable = (
        <AssignDevices
          preselectedItems={data.assignedDevices ?? []}
          selectedCompaniesIds={data.assignedCompanyIds}
          currentUser={currentUser}
          onSelectionChange={(selectedItems) =>
            setData({
              ...data,
              assignedDevices: selectedItems,
              userAccessBasedOnLocationIds: [],
              userAccessBasedOnCompanyIds: [],
              deviceGroupsIds: [],
            })
          }
        />
      );
      break;
    case Tab.DevicesGroups:
      currentTable = (
        <AssignDevicesGroups
          preselectedItems={data.deviceGroupsIds ?? []}
          currentUser={currentUser}
          onSelectionChange={(selectedItems) =>
            setData({
              ...data,
              deviceGroupsIds: selectedItems,
              userAccessBasedOnLocationIds: [],
              userAccessBasedOnCompanyIds: [],
              assignedDevices: [],
            })
          }
          showIcon
        />
      );
      break;
    case Tab.Locations:
      currentTable = (
        <SelectLocations
          onChange={(values) => {
            if (!values.find(({ value }) => value === 'loadingValue'))
              setData({
                ...data,
                userAccessBasedOnLocationIds: map(values, 'value') as string[],
                deviceGroupsIds: [],
                userAccessBasedOnCompanyIds: [],
                assignedDevices: [],
              });
          }}
          initialValue={data.userAccessBasedOnLocationIds}
        />
      );
      break;
    case Tab.Companies:
      currentTable = (
        <SelectCompanies
          id="company"
          onChange={(values) => {
            if (!values.find(({ value }) => value === 'loadingValue'))
              setData({
                ...data,
                userAccessBasedOnCompanyIds: map(values, 'value') as string[],
                deviceGroupsIds: [],
                userAccessBasedOnLocationIds: [],
                assignedDevices: [],
              });
          }}
          initialValue={data.userAccessBasedOnCompanyIds}
          currentUser={currentUser}
        />
      );
      break;
    default:
      currentTable = '';
  }

  return (
    <Card className="mb-5" withoutMargins>
      <Card.Header>
        <Card.Header.Toolbar className="justify-content-center">
          <RadioButtons
            name="isOrdered"
            onChange={(value) => setCurrentTab(value as Tab)}
            options={[
              {
                label: t('devices.devices'),
                value: Tab.Devices,
                key: Tab.Devices,
                checked: currentTab === Tab.Devices,
              },
              {
                label: t('devices_groups.device_groups'),
                value: Tab.DevicesGroups,
                key: Tab.DevicesGroups,
                checked: currentTab === Tab.DevicesGroups,
              },
              {
                label: t('locations.locations'),
                value: Tab.Locations,
                key: Tab.Locations,
                checked: currentTab === Tab.Locations,
              },
              {
                label: t('companies.companies'),
                value: Tab.Companies,
                key: Tab.Companies,
                checked: currentTab === Tab.Companies,
              },
            ]}
          />
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>{currentTable}</Card.Body>
    </Card>
  );
};
