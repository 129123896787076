import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { prepareHookFromState } from '../../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../../FormErrorMessage';
import TextInput from '../../../TextInput';
import validation from '../../../../utils/validation';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import SelectCountry from '../../../../containers/SelectCountry';
import { createCompanyLocationValidation } from '../../../../validation/locations';
import SelectTimezoneId from '../../../../containers/SelectTimezoneId';
import CountryCode from '../../../../enums/countryCode.enum';
import SelectCounty from '../../../../containers/SelectCounty';
import SelectState from '../../../../containers/SelectState';
import SelectCompany from '../../../../containers/Companies/SelectCompany';
import User from '../../../../interfaces/user/user.interface';

interface FormInputs {
  name: string;
  countryId: string;
  countyId?: string;
  stateId?: string;
  city: string;
  timezone?: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  contact: string;
  zipcode: string;
  company: string;
  manageCompany: boolean;
}

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

export default ({
  onSubmit,
  isLoading,
  initialState,
  isEdit,
  user,
  hasCompany = false,
}: {
  onSubmit: any;
  user?: User;
  hasCompany?: boolean;
  isLoading: boolean;
  initialState?: Partial<FormInputs>;
  isEdit?: boolean;
}) => {
  const { t } = useTranslation();

  const defaultValues: Partial<FormInputs> = {
    name: initialState?.name ?? '',
    countryId: initialState?.countryId ?? CountryCode.Us,
    countyId: initialState?.countyId ?? '',
    stateId: initialState?.stateId ?? '',
    contact: initialState?.contact ?? '',
    city: initialState?.city ?? '',
    timezone: initialState?.timezone ?? '',
    email: initialState?.email ?? '',
    phone: initialState?.phone ?? '',
    address1: initialState?.address1 ?? '',
    address2: initialState?.address2 ?? '',
    zipcode: initialState?.zipcode ?? '',
    company: initialState?.company ?? '',
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    // reset,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      validation.object(createCompanyLocationValidation(t)).required(),
    ),
    defaultValues,
  });

  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [name, setName] = useFs<FormInputs['name']>('name');
  const [city, setCity] = useFs<FormInputs['city']>('city');
  const [countryId, setCountryId] = useFs<FormInputs['countryId'] | undefined>(
    'countryId',
  );
  const [email, setEmail] = useFs<FormInputs['email']>('email');
  const [phone, setPhone] = useFs<FormInputs['phone']>('phone');
  const [contact, setContact] = useFs<FormInputs['contact']>('contact');
  const [company, setCompany] = useFs<FormInputs['company']>('company');
  const [manageCompany, setManageCompany] =
    useFs<FormInputs['manageCompany']>('manageCompany');
  const [countyId, setCountyId] = useFs<FormInputs['countyId']>('countyId');
  const [address1, setAddress1] = useFs<FormInputs['address1']>('address1');
  const [address2, setAddress2] = useFs<FormInputs['address2']>('address2');
  const [timezone, setTimezone] = useFs<FormInputs['timezone']>('timezone');
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);

  const [stateId, setStateId] = useFs<FormInputs['stateId'] | undefined>(
    'stateId',
  );
  const [zipcode, setZip] = useFs<FormInputs['zipcode']>('zipcode');

  const handleSubmitData = () => {
    if (onSubmit) {
      onSubmit({
        name,
        address1,
        address2,
        city,
        countyId,
        country: countryId,
        timezone,
        email,
        phone,
        zipcode,
        contact,
        company,
        state: stateId,
      });
    }
  };
  useEffect(() => {
    setManageCompany(hasCompany);
  }, []);
  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form onSubmit={handleSubmit(handleSubmitData)} id="my-form">
          <div className="d-flex justify-content-between row">
            <div className="col-12 col-lg-6">
              <div className="mb-4">
                <label className="fs-6 fw-bolder mb-2">
                  {t('locations.name')}
                </label>
                <TextInput
                  name="name"
                  id="locationName"
                  placeholder={t('contents.type_here')}
                  className="border-secondary"
                  value={name}
                  onChange={setName}
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
              <div className="mb-4">
                <label className="fs-6 fw-bolder mb-2">
                  {t('locations.address1')}
                </label>
                <TextInput
                  name="address1"
                  id="address1"
                  placeholder={t('contents.type_here')}
                  className="border-secondary"
                  value={address1}
                  onChange={setAddress1}
                />
                <FormErrorMessage
                  name="address1"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
              <div className="mb-4">
                <label className="fs-6 fw-bolder mb-2">
                  {t('locations.address2')}
                </label>
                <TextInput
                  name="address2"
                  id="address2"
                  placeholder={t('contents.type_here')}
                  className="border-secondary"
                  value={address2}
                  onChange={setAddress2}
                />
                <FormErrorMessage
                  name="address2"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
              <div className="mb-4">
                <label className="fs-6 fw-bolder mb-2">
                  {t('locations.city')}
                </label>
                <TextInput
                  name="city"
                  id="city"
                  placeholder={t('contents.type_here')}
                  className="border-secondary"
                  value={city}
                  onChange={setCity}
                />
                <FormErrorMessage
                  name="city"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              {countryId !== CountryCode.Ca && (
                <div className="mb-4 row">
                  <div className="col-6">
                    <label htmlFor="stateId" className="fs-6 fw-bolder mb-3">
                      {t('apps.severeWeather.fields.state')}
                    </label>
                    <SelectState
                      id="stateId"
                      onChange={(selectedState) => {
                        setStateId(selectedState.value);
                      }}
                      showStateCode
                      countryId={countryId}
                      initialValue={stateId}
                      isClearable
                    />
                    <FormErrorMessage
                      name="stateId"
                      errors={errors}
                      className={errorMessageClassNames}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="zipcode" className="fs-6 mb-3">
                      {t('locations.zipcode')}
                    </label>
                    <TextInput
                      name="zipcode"
                      id="zipcode"
                      placeholder={t('contents.type_here')}
                      className="border-secondary"
                      value={zipcode}
                      onChange={setZip}
                    />
                    <FormErrorMessage
                      name="zipcode"
                      errors={errors}
                      className={errorMessageClassNames}
                    />
                  </div>
                </div>
              )}
              <div className="mb-4">
                <label htmlFor="countryId" className="fs-6 fw-bolder mb-3">
                  {t('locations.country')}
                </label>
                <SelectCountry
                  preselect
                  id="countryId"
                  onChange={({ value }) => {
                    if (value) setCountryId(value);
                    setStateId(undefined);
                    // reset({ countyId: '' });
                    setCountyId('');
                  }}
                  initialValue={countryId}
                  isClearable
                />
                <FormErrorMessage
                  name="countryId"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="">
                <div className="mb-4">
                  <label className="fs-6 fw-bolder mb-2">
                    {t('locations.contact')}
                  </label>
                  <TextInput
                    name="contact"
                    id="contact"
                    placeholder={t('contents.type_here')}
                    className="border-secondary"
                    value={contact}
                    onChange={setContact}
                  />
                  <FormErrorMessage
                    name="contact"
                    errors={errors}
                    className={errorMessageClassNames}
                  />
                </div>
                <div className="mb-4">
                  <label className="fs-6 fw-bolder mb-2">
                    {t('locations.email')}
                  </label>
                  <TextInput
                    name="email"
                    id="email"
                    placeholder={t('contents.type_here')}
                    className="border-secondary"
                    value={email}
                    onChange={setEmail}
                  />
                  <FormErrorMessage
                    name="email"
                    errors={errors}
                    className={errorMessageClassNames}
                  />
                </div>

                <div className="mb-4">
                  <label className="fs-6 fw-bolder mb-2">
                    {t('locations.phone')}
                  </label>
                  <TextInput
                    name="phone"
                    id="phone"
                    placeholder={t('contents.type_here')}
                    className="border-secondary"
                    value={phone}
                    onChange={setPhone}
                  />
                  <FormErrorMessage
                    name="phone"
                    errors={errors}
                    className={errorMessageClassNames}
                  />
                </div>
                {(countryId === CountryCode.Ca ||
                  countryId === CountryCode.Us) && (
                  <div className="mb-4">
                    <label htmlFor="countyId" className="fs-6 mb-3">
                      {t('common.county')}
                    </label>
                    <SelectCounty
                      id="countyId"
                      name="countyId"
                      onChange={({ value }) => setCountyId(value)}
                      initialValue={countyId}
                      countryCode={countryId}
                      isClearable
                      isSevereWeather
                      stateCode={stateId}
                      disabled={
                        !(
                          countryId?.includes('us') || countryId?.includes('ca')
                        )
                      }
                    />
                    {showErrorMessage && (
                      <FormErrorMessage
                        name="countyId"
                        errors={errors}
                        className={errorMessageClassNames}
                      />
                    )}
                  </div>
                )}

                <div className="mb-4">
                  <label
                    htmlFor="timezone"
                    className="col-form-label text-lg-end"
                  >
                    {t('common.timezone')}
                  </label>
                  <SelectTimezoneId
                    id="timezone"
                    initialValue={timezone}
                    onChange={({ value }) => {
                      if (value) setTimezone(value);
                    }}
                  />
                  <FormErrorMessage
                    name="timezone"
                    errors={errors}
                    className={clsx(errorMessageClassNames, 'mw-350px')}
                  />
                </div>
                {manageCompany && (
                  <div className="mb-4">
                    <label htmlFor="company" className="fs-6 fw-bolder mb-4">
                      {t('common.company')}
                    </label>
                    <SelectCompany
                      id="company"
                      currentUser={user}
                      initialValue={company}
                      className="border-secondary"
                      onChange={({ value }) => {
                        if (value && value !== 'loadingValue')
                          setCompany(value);
                      }}
                    />
                    <FormErrorMessage
                      name="company"
                      errors={errors}
                      className={clsx(errorMessageClassNames, 'mw-350px')}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-end">
            <button
              onClick={() => setShowErrorMessage(true)}
              type="submit"
              className="btn btn-primary"
              form="my-form"
            >
              {t(isEdit ? 'locations.edit' : 'locations.create_location')}
            </button>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
