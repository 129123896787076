import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import WithDeleteConfirm from '../../WithDeleteConfirm';
import { KTSVG } from '../../../lib/metronic/helpers';
import Company from '../../../interfaces/company.interface';
import { handleError } from '../../../api/interceptors/errors.interceptor';
import api from '../../../api';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';

export default ({
  company: { id, name },
  children,
  onUpdate,
}: {
  company: Company;
  children?: React.ReactNode;
  onUpdate?: () => void;
}) => {
  const { t } = useTranslation();
  const [isAdmin] = useIsAdmin();

  const handleConfirm = async () => {
    try {
      if (isAdmin) {
        await api.companies.deleteCompany(id);
      } else {
        await api.companies.deleteUserCompany(id);
      }
      if (onUpdate) {
        onUpdate();
      }
      toast.success(i18next.t<string>('companies.removed_message'));
    } catch (error: any) {
      if (error.response.status === 409) {
        toast.error(
          i18next.t<string>('companies.removal_error', {
            count: error.response.data.count,
            entities: i18next.t<string>(
              `companies.removal_error_entities.${error.response.data?.entity}`,
            ),
          }),
        );
      } else {
        handleError(error);
      }
    }
  };

  return (
    <WithDeleteConfirm
      body={`${t('common.delete_confirmation.title')} ${t(
        'common.delete_confirmation.company',
      )} "${name}"?`}
      handleConfirm={handleConfirm}
    >
      {children ?? (
        <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
          <KTSVG
            path="/media/icons/duotune/general/gen027.svg"
            className="svg-icon-3"
          />
        </span>
      )}
    </WithDeleteConfirm>
  );
};
