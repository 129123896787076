import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AsideWrapper from './AsideWrapper';
import RestrictedAccess from '../../../containers/RestrictedAccess';
import UploadContent from '../../../containers/Aside/UploadContent';
import quickclick from '../../../assets/images/apps/quickclick.svg';
import { start } from '../../../webWorkers/conversionJobStatus';
import { UserRole } from '../../../enums/userRole.enum';
import NestedMenuItem from './NestedMenuItem';
import config from '../../../routing/config';
import MenuItem from './MenuItem';
import eventEmitter from '../../../utils/eventEmitter';
import { PlaylistEvents } from '../../../enums/events/playlistEvents.enum';
import GlobalState from '../../../store/reducers/globalState.interface';
import { getUserData } from '../../../store/actions/userData';

export default ({
  isDrawerOpened,
  onLinkClick,
  marginTop,
}: {
  isDrawerOpened?: boolean;
  marginTop?: boolean;
  onLinkClick?: () => void;
}) => {
  const dispatch = useDispatch();
  const contentTree = useRef<boolean>(true);
  const isTrialUser = useSelector(
    (state: any) => state.userData?.user?.isTrial,
  );
  const [showParentContentMenu, setShowParentContentMenu] =
    React.useState<boolean>(true);

  const [showContentMenu, setShowContentMenu] = React.useState<boolean>(false);

  const toggleParentContentMenu = React.useCallback(
    (event) => {
      event.stopPropagation();

      setShowContentMenu(true);

      setShowParentContentMenu(!showParentContentMenu);

      if (contentTree && !showParentContentMenu) {
        // history.push(config.contents.route);
        contentTree.current = false;
      }
    },
    [showContentMenu, showParentContentMenu, contentTree],
  );

  const toggleContentMenu = React.useCallback(
    (event) => {
      event.stopPropagation();
      setShowContentMenu(!showContentMenu);
    },
    [showContentMenu],
  );

  const handleSuccess = (playlistIds?: string[]) => {
    start();
    dispatch(getUserData());
    eventEmitter.emit(PlaylistEvents.Refresh, playlistIds);
  };
  const user = useSelector((state: GlobalState) => {
    return state.userData.user;
  });
  return (
    <AsideWrapper
      marginTop={marginTop}
      isDrawerOpened={isDrawerOpened}
      onLinkClick={onLinkClick}
    >
      <RestrictedAccess>
        <MenuItem
          icon="/media/icons/duotune/files/dashboard.svg"
          to={config.statistic.route}
          title="Dashboard"
        />
      </RestrictedAccess>

      <RestrictedAccess
        allowedRoles={[UserRole.SuperAdmin]}
        fallback={
          <UploadContent onSuccess={handleSuccess}>
            <MenuItem
              icon="/media/icons/duotune/files/export.svg"
              title="Upload"
            />
          </UploadContent>
        }
      >
        <MenuItem
          icon="/media/icons/duotune/files/dashboard.svg"
          to={config.dashboard.route}
          title="Dashboard"
        />

        <MenuItem
          icon="/media/icons/duotune/files/sales.svg"
          to={config.adminSales.route}
          title="Sales"
        />

        <MenuItem
          icon="/favicon.svg"
          to={config.indigoLibraryCategories.route}
          title="Library"
        />
      </RestrictedAccess>
      <RestrictedAccess
        allowedRoles={[UserRole.SuperAdmin]}
        fallback={
          <MenuItem
            to={
              user.isTrial
                ? config.indigoLibraryWorkplace.route
                : config.indigoLibraryCategories.route
            }
            image={quickclick}
            title="Quick Click"
            isLocked={isTrialUser}
          />
        }
      >
        <NestedMenuItem
          title="Devices"
          icon="/media/icons/duotune/files/devices.svg"
          onClick={toggleContentMenu}
          show={showContentMenu}
        >
          <MenuItem
            to={`${config.adminDevices.route}?limit=50&page=1&sortBy=name&sortOrder=asc&isTrial=false`}
            title="Devices"
            className="pe-1 mb-2"
          />
          <MenuItem
            title="Devices - Trial"
            to={`${config.adminCustomerTrialDevices.route}?limit=50&page=1&sortBy=name&sortOrder=asc&isTrial=true`}
            className="pe-1 mb-2"
          />

          <MenuItem
            title="Analytics"
            to={`${config.devicesCharts.route}?limit=50&page=1&sortBy=lastOnline&sortOrder=desc&isTrial=false`}
            className="pe-1 mb-2"
          />
        </NestedMenuItem>
      </RestrictedAccess>
      <RestrictedAccess
        allowedRoles={[UserRole.SuperAdmin]}
        fallback={
          <MenuItem
            to={config.playlists.route}
            icon="/media/icons/duotune/files/playlists.svg"
            title="Playlist"
          />
        }
      >
        <MenuItem
          icon="/media/icons/duotune/files/billing.svg"
          to={config.adminBilling.route}
          title="Billing"
        />

        <MenuItem
          icon="/media/icons/duotune/files/fil001.svg"
          to={config.logs.route}
          title="Activity Logs"
        />
      </RestrictedAccess>
      <RestrictedAccess
        allowedRoles={[UserRole.SuperAdmin]}
        fallback={
          <MenuItem
            to={config.apps.route}
            icon="/media/icons/duotune/files/apps.svg"
            title="Apps"
          />
        }
      />

      <RestrictedAccess
        allowedRoles={[UserRole.SuperAdmin]}
        fallback={
          <NestedMenuItem
            title="Content"
            icon="/media/icons/duotune/files/content.svg"
            onClick={toggleParentContentMenu}
            show={showParentContentMenu}
          >
            <MenuItem
              onClick={(event) => event.stopPropagation()}
              to={config.indigoLibraryCategories.route}
              title="Indigo Library"
              icon="/favicon.svg"
              // isLocked={isTrialUser}
            />
            <NestedMenuItem
              onClick={toggleContentMenu}
              show={showContentMenu}
              icon="/media/icons/content.svg"
              title="My Content"
            >
              <MenuItem
                lowerMargin={false}
                to={config.contents.route}
                title="My Folders"
                onClick={(event) => event.stopPropagation()}
                icon="/media/icons/duotune/files/fil012.svg"
              />
              <MenuItem
                to={config.sharedContents.route}
                title="Shared Folders"
                onClick={(event) => event.stopPropagation()}
                icon="/media/icons/people.svg"
              />
            </NestedMenuItem>
            <MenuItem
              onClick={(event) => event.stopPropagation()}
              to="/crafted/widgets/charts"
              title="Push Notifications"
              isHide
            />
          </NestedMenuItem>
        }
      />
      {isDrawerOpened && (
        <>
          <RestrictedAccess
            allowedRoles={[UserRole.SuperAdmin]}
            fallback={<MenuItem title="Devices" to={config.devices.route} />}
          />
          <RestrictedAccess allowedRoles={[UserRole.SuperAdmin]}>
            <MenuItem title="Indigo Users" to={config.adminUsers.route} />
          </RestrictedAccess>
          <RestrictedAccess allowedRoles={[UserRole.SuperAdmin]}>
            <NestedMenuItem
              title="Companies"
              onClick={toggleContentMenu}
              show={showContentMenu}
            >
              <MenuItem
                title="Users"
                to={`${config.adminCustomers.route}?limit=50&page=1&sortBy=firstName&sortOrder=asc&isTrial=false&isTest=false`}
                className="pe-1 mt-2 mb-1"
              />
              <MenuItem
                title="Users - Trial"
                to={`${config.adminTrialCustomers.route}?limit=50&page=1&sortBy=firstName&sortOrder=asc&isTrial=true&isTest=false`}
                className="pe-1 mb-1"
              />
              <MenuItem
                title="Companies"
                to={`${config.adminCustomerCompanies.route}?limit=50&page=1&isTest=false&sortBy=name&sortOrder=asc&trialCompany=false`}
                className="pe-1 mb-2"
              />
              <MenuItem
                title="Companies - Trial"
                to={`${config.adminCustomerTrialCompanies.route}?limit=50&page=1&isTest=false&sortBy=name&sortOrder=asc&trialCompany=true`}
                className="pe-1 mb-2"
              />
            </NestedMenuItem>
          </RestrictedAccess>
          <RestrictedAccess
            isHide
            allowedRoles={[UserRole.SuperAdmin]}
            fallback={<MenuItem title="Report" to={config.report.route} />}
          >
            <MenuItem title="Packages" to={config.adminPackages.route} />
          </RestrictedAccess>
        </>
      )}
    </AsideWrapper>
  );
};
