/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { unset } from 'lodash';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import './signup.css';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import validation from '../../../utils/validation';
import { webAuth0 } from '../../../utils/auth0';
import logo from '../../../assets/images/logo/indigo_vizual_logo.png';
import FormErrorMessage from '../../../components/FormErrorMessage';

import CreateAccountPayload from '../../../api/endpoints/auth/interfaces/CreateAccountPayload.interface';
import config from '../../../routing/config';
import useQuery from '../../../utils/hooks/useQuery';

// import SocialLogin from '../../../components/SocialLogin';

interface FormInputs {
  email: string;
  password: string;
  repeatPassword: string;
}

export default () => {
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const query = useQuery();
  const queryEmail = query.get('email');
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const validationSchema = validation
    .object({
      email: validation
        .string()
        .required(t('signup.email'))
        .email(t('signup.emailValid'))
        .defined(),
      password: validation
        .string()
        .required(t('signup.password'))
        .min(8, t('signup.passwordPattern'))
        .matches(
          /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!+&@$#%]).*$/,
          t('signup.passwordPattern'),
        )
        .defined(),
      repeatPassword: validation
        .string()
        .required(t('signup.repeatPassword'))
        .oneOf([validation.ref('password')], t('signup.passwordMatch'))
        .defined(),
    })
    .defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const [terms, setTerms] = useState<boolean>(false);
  const [showTermsMessasge, setTermsMessage] = useState<boolean>(false);

  const [email, setEmail] = useStateWithHookForm<Partial<FormInputs>, string>(
    { setValue, trigger, name: 'email' },
    '',
  );

  const handleEmailChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(value);
  };

  const [password, setPassword] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'password' }, '');

  const handlePasswordChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(value);
  };

  const [repeatPassword, setRepeatPassword] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'repeatPassword' }, '');

  const handleRepeatPasswordChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(value);
  };

  const handleTermsChange = () => {
    if (!terms) {
      setTermsMessage(false);
    }
    setTerms(!terms);
  };

  const handleSubmitData = async (inputsData: Partial<FormInputs>) => {
    if (!terms) {
      setTermsMessage(true);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    unset(inputsData, 'repeatPassword');
    const payload: CreateAccountPayload = {
      email: inputsData?.email ?? '',
      password: inputsData?.password ?? '',
    };
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/register/sign-up`,
        payload,
      );
    } catch (error) {
      setIsLoading(false);
      return;
    }

    const url = `${process.env.REACT_APP_URL}${config.login.route}`;
    try {
      webAuth0.login(
        {
          realm: 'Username-Password-Authentication',
          username: inputsData.email ?? '',
          password: inputsData.password ?? '',
          responseType: 'token id_token',
          redirectUri: url,
          scope: 'roles openid profile email',
        },
        (error: any) => {
          if (error) {
            Bugsnag.notify(error.description as string);
            toast.error(error.description);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        },
      );
    } catch (error: any) {
      setIsLoading(false);
      toast.error(error.description);
    }
  };

  useEffect(() => {
    if (queryEmail) {
      // function to validate email
      const isValidEmail = (_email: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(_email);
      };

      if (!isValidEmail(queryEmail)) {
        history.push(`${config.signupEmail.route}`);
        return;
      }

      setEmail(queryEmail);
      if (passwordInputRef.current !== null) {
        if (passwordInputRef.current.focus) {
          passwordInputRef.current.focus();
        }
      }
      // window.location.href = window.location.origin + window.location.pathname;
    } else if (process.env.REACT_APP_ENV === 'dev') {
      // do nothing
    } else {
      history.push(config.signupEmail.route);
    }
  }, [queryEmail]);

  useEffect(() => {
    const canvaMail = sessionStorage.getItem('email');
    if (canvaMail) {
      sessionStorage.removeItem('email');
    }
  }, []);

  return (
    <form className="form w-100" id="kt_sign_up_form">
      <div className="formLogo mb-11 text-center">
        <img alt="Logo" src={logo} width="170" />
      </div>
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">
          {t('auth_pages.createYourFreeAccount')}
        </h1>
      </div>
      <div
        className="fv-row mb-8"
        style={{
          display: process.env.REACT_APP_ENV === 'dev' ? 'block' : 'none',
        }}
      >
        <input
          type="text"
          name="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          autoComplete="none"
          className="form-control bg-transparent d-block"
          disabled={process.env.REACT_APP_ENV !== 'dev'}
        />
        <FormErrorMessage name="email" errors={errors} className="my-1 px-2" />
      </div>
      <div className="fv-row mb-8" data-kt-password-meter="true">
        <div className="mb-1">
          <div className="position-relative mb-3">
            <input
              className="form-control bg-transparent"
              type={viewPassword ? 'text' : 'password'}
              placeholder="Password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="none"
              ref={passwordInputRef}
            />
            <span
              tabIndex={-1}
              role="button"
              onKeyDown={() => setViewPassword(!viewPassword)}
              onClick={() => setViewPassword(!viewPassword)}
              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
              data-kt-password-meter-control="visibility"
            >
              <i
                className={clsx(
                  'bi bi-eye-slash fs-2',
                  !viewPassword ? 'd-none' : '',
                )}
              />
              <i
                className={clsx('bi bi-eye fs-2', viewPassword ? 'd-none' : '')}
              />
            </span>
          </div>
          <FormErrorMessage
            name="password"
            errors={errors}
            className="my-1 px-2"
          />
        </div>
      </div>
      <div className="fv-row mb-8">
        <input
          placeholder="Repeat Password"
          name="repeatPassword"
          type="password"
          value={repeatPassword}
          onChange={handleRepeatPasswordChange}
          autoComplete="none"
          className="form-control bg-transparent d-block"
        />
        <FormErrorMessage
          name="repeatPassword"
          errors={errors}
          className="my-1 px-2"
        />
      </div>
      <div className="fv-row mb-8">
        <label className="form-check form-check-inline d-block">
          <input
            className="form-check-input"
            type="checkbox"
            name="terms"
            value="1"
            checked={terms}
            onChange={handleTermsChange}
          />
          <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
            {t('auth_pages.accept')}
          </span>
          <a
            className="ms-1 link-primary text-primary"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.open(
                'https://www.indigovizual.com/terms-of-service',
                undefined,
                'width=800,height=900',
              );
            }}
          >
            {t('auth_pages.termsOfService')}
          </a>
        </label>
        {showTermsMessasge && (
          <p className="text-danger pt-3">{t('signup.terms')}</p>
        )}
      </div>
      <div className="d-grid mb-10">
        <button
          disabled={isLoading}
          onClick={handleSubmit(handleSubmitData)}
          type="button"
          id="kt_sign_up_submit"
          className="btn btn-purple"
        >
          {!isLoading ? (
            <span className="indicator-label">{t('auth_pages.signup')}</span>
          ) : (
            <span className="d-block indicator-progress">
              {t('auth_pages.wait')}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
        </button>
      </div>
      {/* <SocialLogin /> */}

      <div className="text-gray-500 text-center fw-semibold fs-6">
        {t('auth_pages.already_account')} &nbsp;
        <Link to={config.signin.route} className="link-primary fw-semibold">
          {t('auth_pages.signin')}
        </Link>
      </div>
    </form>
  );
};
