import clsx from 'clsx';
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from 'react-i18next';
import noPreview from '../../../../assets/images/noPreview/noPreviewVertical.png';
import useIsAdmin from '../../../../utils/hooks/useIsAdmin';
import DeleteTopicImg from '../../../AdminPanel/Library/Topic/DeleteTopicImg';
import Tooltip from '../../../Tooltip';
import styles from './library-carousel.module.scss';

export default ({
  images,
  library,
  onUpdate,
}: {
  images: string[];
  library: any;
  onUpdate?: () => void;
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const { t } = useTranslation();
  const handleSelect = (selectedIndex: number) => {
    setCarouselIndex(selectedIndex);
  };
  const [isAdmin] = useIsAdmin();

  return (
    <>
      <Carousel
        className={styles.carousel}
        activeIndex={carouselIndex}
        onSelect={handleSelect}
        nextIcon={
          <span className="carousel-control-next-icon bg-primary rounded-circle border border-primary border-5" />
        }
        prevIcon={
          <span className="carousel-control-prev-icon bg-primary rounded-circle border border-primary border-5" />
        }
      >
        {library
          ? [
              ...[
                ...library?.posters,
                ...library?.content,
                ...library?.fullscreen,
              ]?.map((content, index) => (
                <Carousel.Item>
                  {isAdmin && (
                    <DeleteTopicImg
                      src={content.media.thumbnailUrl}
                      library={library}
                      onUpdate={onUpdate}
                    >
                      <label
                        className="btn btn-danger d-flex position-absolute z-index-3 bg-danger  start-50 mt-5  left-0 translate-middle btn-icon btn-circle btn-active-color-white text-light w-25px h-25px  shadow  mx-1"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title=""
                      >
                        {/* <DeleteTopic topic={library} onUpdate={onUpdate}> */}

                        <Tooltip text={t('common.delete')}>
                          <i className="bi bi-trash fs-7" />

                          <input type="hidden" name="avatar_remove" />
                        </Tooltip>
                        {/*  </DeleteTopic> */}
                      </label>
                    </DeleteTopicImg>
                  )}
                  <img
                    onContextMenu={(ev) => ev.preventDefault()}
                    className="d-block w-100 min-h-400px mh-400px"
                    src={content.media.thumbnailUrl}
                    alt={`Slide ${index}`}
                    style={{ objectFit: 'contain' }}
                    onError={(e) => {
                      e.currentTarget.src = noPreview;
                    }}
                  />
                </Carousel.Item>
              )),
              ...library?.videos?.map((content: any) => (
                <Carousel.Item>
                  {isAdmin && (
                    <DeleteTopicImg
                      src={content.media.thumbnailUrl}
                      library={library}
                      onUpdate={onUpdate}
                    >
                      <label
                        className="btn btn-danger d-flex position-absolute z-index-3 bg-danger  start-50 mt-5  left-0 translate-middle btn-icon btn-circle btn-active-color-white text-light w-25px h-25px  shadow  mx-1"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title=""
                      >
                        {/* <DeleteTopic topic={library} onUpdate={onUpdate}> */}

                        <Tooltip text={t('common.delete')}>
                          <i className="bi bi-trash fs-7" />

                          <input type="hidden" name="avatar_remove" />
                        </Tooltip>
                        {/*  </DeleteTopic> */}
                      </label>
                    </DeleteTopicImg>
                  )}
                  <video
                    onContextMenu={(ev) => ev.preventDefault()}
                    className="d-block w-100 min-h-400px mh-400px"
                    style={{ objectFit: 'contain' }}
                    onError={(e) => {
                      e.currentTarget.src = noPreview;
                    }}
                    autoPlay
                    muted
                  >
                    <source src={content.media.url} />
                  </video>
                </Carousel.Item>
              )),
            ]
          : images.map((src, index) => (
              <Carousel.Item>
                {isAdmin && (
                  <DeleteTopicImg
                    src={src}
                    library={library}
                    onUpdate={onUpdate}
                  >
                    <label
                      className="btn btn-danger d-flex position-absolute z-index-3 bg-danger  start-50 mt-5  left-0 translate-middle btn-icon btn-circle btn-active-color-white text-light w-25px h-25px  shadow  mx-1"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title=""
                    >
                      {/* <DeleteTopic topic={library} onUpdate={onUpdate}> */}

                      <Tooltip text={t('common.delete')}>
                        <i className="bi bi-trash fs-7" />

                        <input type="hidden" name="avatar_remove" />
                      </Tooltip>
                      {/*  </DeleteTopic> */}
                    </label>
                  </DeleteTopicImg>
                )}
                <img
                  onContextMenu={(ev) => ev.preventDefault()}
                  className="d-block w-100 min-h-400px mh-400px"
                  src={src}
                  alt={`Slide ${index}`}
                  style={{ objectFit: 'contain' }}
                  onError={(e) => {
                    e.currentTarget.src = noPreview;
                  }}
                />
              </Carousel.Item>
            ))}
      </Carousel>
      <div
        className="d-flex mh-100px g-4 justify-content-center"
        onContextMenu={(ev) => ev.preventDefault()}
      >
        {library
          ? [
              ...[
                ...library?.posters,
                ...library?.content,
                ...library?.fullscreen,
              ]?.map((content, index) => (
                <>
                  <div
                    role="button"
                    tabIndex={index}
                    className={clsx(
                      'd-block w-65px h-100 m-2 my-3 hoverable',
                      carouselIndex === index && 'mt-1',
                    )}
                    onClick={() => handleSelect(index)}
                    onKeyDown={() => handleSelect(index)}
                  >
                    <img
                      style={{ width: '100%' }}
                      src={content?.media?.thumbnailUrl}
                      alt={`Slide ${index}`}
                      className="mt-5"
                      onError={(e) => {
                        e.currentTarget.src = noPreview;
                      }}
                    />
                  </div>
                </>
              )),
              ...library?.videos?.map((content: any, index: number) => {
                let newIdx = index;
                if ([...library?.posters, ...library?.content]?.length) {
                  newIdx =
                    index + [...library?.posters, ...library?.content].length;
                }
                return (
                  <>
                    <div
                      role="button"
                      tabIndex={newIdx}
                      className={clsx(
                        'd-block w-65px h-100 m-2 my-3 hoverable',
                        carouselIndex === newIdx && 'mt-1',
                      )}
                      onClick={() => handleSelect(newIdx)}
                      onKeyDown={() => handleSelect(newIdx)}
                    >
                      <video
                        style={{ width: '100%' }}
                        className="mt-5"
                        onError={(e) => {
                          e.currentTarget.src = noPreview;
                        }}
                        autoPlay={false}
                        muted
                      >
                        <source src={content?.media?.url} />
                      </video>
                    </div>
                  </>
                );
              }),
            ]
          : images.map((src, index) => (
              <>
                <div
                  role="button"
                  tabIndex={index}
                  className={clsx(
                    'd-block w-65px h-100 m-2 my-3 hoverable',
                    carouselIndex === index && 'mt-1',
                  )}
                  onClick={() => handleSelect(index)}
                  onKeyDown={() => handleSelect(index)}
                >
                  <img
                    style={{ width: '100%' }}
                    src={src}
                    alt={`Slide ${index}`}
                    className="mt-5"
                    onError={(e) => {
                      e.currentTarget.src = noPreview;
                    }}
                  />
                </div>
              </>
            ))}
      </div>
    </>
  );
};
