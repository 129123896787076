/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import clsx from 'clsx';
import App from '../../../../interfaces/app.interface';
import { readFromQueryString } from '../../../../utils/queryParams';
import SortOrder from '../../../../enums/sortOrder.enum';
import Table from '../../../Table';
import Columns from '../../../Table/Columns';
import WithSpinner from '../../../WithSpinner';
import TableRow from './TableRow';

import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../../utils/hooks/useTableMultiSelect';
import EmptyFiles from '../../../../pages/FileManager/EmptyFiles';
import styles from './TableRow/yablerow.module.scss';

export default ({
  instagram,
  isLoading,
  sortQueryField,
  orderQueryField,
  onUpdate,
  preloaderHeight = 300,
  isSelectable = false,
  multiSelectConfig: { selectedItems, selectAll },
}: {
  instagram: App[];
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight: number;
  isSelectable?: boolean;
  onUpdate: () => void;
  multiSelectConfig: TableMultiSelectConfig;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  const columns = [
    {
      name: t('apps.instagram.table.name'),
    },
    {
      name: t('apps.instagram.table.viewType'),
    },
    {
      name: t('apps.common.table.createdBy'),
    },
    {
      name: t('apps.instagram.table.dateCreated'),
    },
    {
      name: t('common.actions'),
      className: clsx('text-center', styles.actions),
    },
  ];

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns
            columns={columns}
            checked={isAllSelected(selectedItems, map(instagram, 'id'))}
            onChange={selectAll}
            config={{
              sortField,
              setSortField,
              sortQueryField,
              orderQueryField,
              isSelectable,
            }}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={!isLoading && !instagram.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={
          <EmptyFiles descriptionText={t('apps.instagram.noInstagramFound')} />
        }
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {instagram.map((insta) => (
            <TableRow key={insta.id} instagram={insta} onUpdate={onUpdate} />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
