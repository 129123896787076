import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Modal from '../Modal';
import WithSpinner from '../WithSpinner';

interface WithModesModalProps<T, M> {
  payload?: T;
  modes: {
    [key: string]: {
      title: string;
      description?: string;
      render: (value: {
        payload: T;
        close: () => void;
        setMode: (mode: M) => void;
      }) => React.ReactNode;
    };
  };
  defaultMode: M;
  isVisible: boolean;
  transitionDuration?: number;
  dialogClassName?: string;
  bodyClassName?: string;
  onClose?: () => void;
  isLoading?: boolean;
  submitButtonText?: string;
  forceRender?: React.ReactNode;
  withHeader?: boolean;
}

interface DefaultProps<T, M> extends WithModesModalProps<T, M> {
  payload: Required<WithModesModalProps<T, M>>['payload'];
  onClose: Required<WithModesModalProps<T, M>>['onClose'];
  transitionDuration: Required<WithModesModalProps<T, M>>['transitionDuration'];
  isLoading: Required<WithModesModalProps<T, M>>['isLoading'];
}

const WithModesModal = <T extends any, M extends string>({
  payload,
  modes,
  defaultMode,
  transitionDuration,
  dialogClassName,
  bodyClassName,
  isVisible,
  onClose,
  isLoading,
  submitButtonText,
  forceRender,
  withHeader,
}: DefaultProps<T, M>) => {
  const [isModalVisible, setIsModalVisible] = useState(isVisible);
  const [mode, setMode] = useState<M>(defaultMode);
  const currentModeConfig = modes[mode];

  if (!currentModeConfig) {
    console.error(`Mode "${mode}" doesn't exist, please check the config!`);
  }

  useEffect(() => {
    if (isModalVisible === isVisible) {
      return;
    }

    if (!isVisible) {
      setTimeout(() => {
        setMode(defaultMode);
        setIsModalVisible(false);
      }, transitionDuration);
    } else {
      setIsModalVisible(true);
    }
  }, [isVisible]);

  return isModalVisible ? (
    <Modal
      onHide={onClose}
      show={isVisible}
      title={currentModeConfig.title}
      widthDefaultSize={false}
      withFooter={false}
      bodyClassName={clsx('mt-0', bodyClassName)}
      dialogClassName={dialogClassName}
      submitButtonText={submitButtonText}
      withHeader={withHeader}
    >
      <WithSpinner
        isLoading={isLoading}
        className="min-h-300px min-w-300px"
        size="md"
      >
        {forceRender ||
          currentModeConfig.render({ payload, close: onClose, setMode })}
      </WithSpinner>
    </Modal>
  ) : null;
};

WithModesModal.defaultProps = {
  payload: {},
  onClose: () => null,
  transitionDuration: 300,
  isLoading: false,
};

export default WithModesModal;
