import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import Device from '../../../interfaces/devices/device.interface';
import FormErrorMessage from '../../FormErrorMessage';
import validation from '../../../utils/validation';
import Modal from '../../Modal';
import TextInput from '../../TextInput';

interface FormInputs {
  pin: string;
}

export default ({
  children,
  onSubmit,
  device,
}: {
  children: React.ReactNode;
  onSubmit: (pin: string) => void;
  device?: Device;
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(
      validation
        .object({
          pin: validation
            .string()
            .length(6)
            .matches(/^[0-9]{6}/)
            .required(),
        })
        .required(),
    ),
  });

  const [pin, setPin] = useStateWithHookForm<FormInputs, FormInputs['pin']>({
    setValue,
    trigger,
    name: 'pin',
  });

  const open = () => setIsModalVisible(true);
  const handleClose = () => setIsModalVisible(false);

  const handleSubmitData = (inputsData: FormInputs) => {
    onSubmit(inputsData.pin);

    handleClose();
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('common.link')}
        widthDefaultSize={false}
        show={isModalVisible}
        onHide={handleClose}
        bodyClassName="py-5"
        footerContent={
          <div className="w-100 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-light me-3"
              onClick={handleClose}
            >
              {t('common.cancel')}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit(handleSubmitData)}
            >
              {t('common.link')}
            </button>
          </div>
        }
      >
        <form onSubmit={handleSubmit(handleSubmitData)}>
          {device && (
            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 justify-content-center mt-3">
              <div className="d-flex align-items-center me-5 text-gray-700 fw-bold">
                {t('devices.device_name')}: {device?.name}
              </div>
              <div className="d-flex align-items-center me-5 text-gray-700 fw-bold">
                {t('common.location')}: {device?.location?.name}
              </div>
              <div className="d-flex align-items-center text-gray-700 fw-bold">
                {t('common.company')}: {device?.company?.name}
              </div>
            </div>
          )}

          <label htmlFor="pin" className="required fw-bold mb-2 mt-5">
            {t('devices.link_device.enter_pin')}
          </label>
          <TextInput
            name="pin"
            id="pin"
            placeholder={t('contents.type_here')}
            value={pin}
            onChange={setPin}
          />
          <FormErrorMessage
            name="pin"
            errors={errors}
            className="mt-1 min-h-20px"
          />
        </form>
      </Modal>
    </>
  );
};
