import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Info from './Steps/Info';
import WithModesModal from '../../WithModesModal';
import AssignPlaylists from './Steps/AssignPlaylists';
import CreateContentRequestData from '../../../api/endpoints/contents/interfaces/createContentRequestData.interface';
import { playlistsContentUploadValidation } from '../../../validation/contents';
import AddContentSteps from '../../../enums/steps/addContentSteps.enum';
import AssignDevicesAndGroups from './Steps/AssignDevicesAndGroups';
import sizingStyles from '../../../styles/sizing.module.scss';
import contentStyles from './contentUpload.module.scss';

interface UploadContentProps {
  onSubmit: (data: CreateContentRequestData, reset: () => void) => void;
  onClose?: () => void;
  isVisible: boolean;
  isLoading?: boolean;
  includeFileUpload?: boolean;
  defaultStepsValues?: Partial<CreateContentRequestData>;
  skipSteps?: AddContentSteps[];
  minDuration?: number;
  isUploadingFile?: boolean;
  onFileDataUpdate?: (data: {
    file?: File;
    isSeparate?: boolean;
    isOrdered?: boolean;
  }) => Promise<boolean>;
}

interface DefaultProps extends UploadContentProps {
  skipSteps: Required<UploadContentProps>['skipSteps'];
  defaultStepsValues: Required<UploadContentProps>['defaultStepsValues'];
  includeFileUpload: Required<UploadContentProps>['includeFileUpload'];
}

const UploadContent = ({
  onSubmit,
  isVisible,
  onClose,
  isLoading,
  includeFileUpload,
  defaultStepsValues,
  skipSteps,
  minDuration,
  onFileDataUpdate,
  isUploadingFile,
}: DefaultProps) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] =
    useState<Partial<CreateContentRequestData>>(defaultStepsValues);

  const reset = () => setStepsData(defaultStepsValues);

  const handleSubmit = (data?: Partial<CreateContentRequestData>) => {
    onSubmit((data ?? stepsData) as CreateContentRequestData, reset);
  };

  const handleClose = () => {
    reset();

    if (onClose) {
      onClose();
    }
  };

  return (
    <WithModesModal<Partial<CreateContentRequestData>, AddContentSteps>
      isVisible={isVisible}
      isLoading={isLoading}
      onClose={handleClose}
      withHeader
      modes={{
        [AddContentSteps.Info]: {
          title: t('contents.add_content'),
          render: ({ setMode, close }) => (
            <Info
              skipSteps={skipSteps}
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onDecline={close}
              validationSchema={playlistsContentUploadValidation(
                t,
                includeFileUpload,
              )}
              includeFileUpload={includeFileUpload}
              onSubmit={handleSubmit}
              minDuration={minDuration}
              onFileDataUpdate={onFileDataUpdate}
              isUploadingFile={isUploadingFile}
            />
          ),
        },
        [AddContentSteps.DevicesAndGroups]: {
          title: t('contents.add_content'),
          render: ({ setMode }) => (
            <AssignDevicesAndGroups
              skipLicence
              skipSteps={skipSteps}
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onSubmit={handleSubmit}
            />
          ),
        },
        [AddContentSteps.Playlists]: {
          title: t('contents.add_content'),
          render: ({ setMode }) => (
            <AssignPlaylists
              skipSteps={skipSteps}
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onSubmit={handleSubmit}
            />
          ),
        },
      }}
      defaultMode={AddContentSteps.Info}
      dialogClassName={clsx(contentStyles.dialogWidth, sizingStyles.fitContent)}
    />
  );
};

UploadContent.defaultProps = {
  skipSteps: [],
  defaultStepsValues: {},
  includeFileUpload: true,
};

export default UploadContent;
