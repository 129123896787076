import { RGBColor } from 'react-color';

export const hexToRgba = (hex: string, opacity: number) => {
  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity must be between 0 and 1');
  }

  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const componentToHex = (hex: string) => (hex.length === 1 ? `0${hex}` : hex);

export const rgbaToHex = (rgba: string) => {
  const [r, g, b] = rgba
    .slice(5)
    .replace(')', '')
    .split(',')
    .map((str) => parseInt(str, 10).toString(16));
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

export const getRgbaValuesFromString = (color: string) => {
  const colorsOnly = color
    .substring(color.indexOf('(') + 1, color.lastIndexOf(')'))
    .split(/,\s*/);

  const returnedRgbaColor: RGBColor = {
    r: Number(colorsOnly[0]),
    g: Number(colorsOnly[1]),
    b: Number(colorsOnly[2]),
    a: Number(colorsOnly[3]),
  };
  return returnedRgbaColor;
};
