// import { floor } from 'lodash';
import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import WithSpinner from '../../WithSpinner';
import styles from '../TableView/TableRow/tableRow.module.scss';

export interface PreviewTemplatesModalProps {
  url: string;
  children: React.ReactNode;
  templateName?: string;
}

const PreviewTemplatesModal = ({
  url,
  children,
  templateName,
}: PreviewTemplatesModalProps) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const { t } = useTranslation();

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  return (
    <>
      <div role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </div>

      <WithSpinner isLoading={false} className="min-h-400px" size="md">
        <Modal
          title={`${templateName ?? t('common.preview')}`}
          show={isModalVisible}
          withHeader
          onHide={close}
          withFooter={false}
        >
          <div className={clsx('card', styles.templateLogoPreview)}>
            <img src={url} alt="preview" />
          </div>
        </Modal>
      </WithSpinner>
    </>
  );
};

export default PreviewTemplatesModal;
