import React from 'react';
import MenuItem from './MenuItem';
import { UserRole } from '../../../enums/userRole.enum';
import RestrictedAccess from '../../../containers/RestrictedAccess';
import NestedMenuItem from './NestedMenuItem';
import config from '../../../routing/config';

export default () => {
  const [showContentMenu, setShowContentMenu] = React.useState<boolean>(false);
  const [showContentMenuForCompanies, setShowContentMenuForCompanies] =
    React.useState<boolean>(false);
  return (
    <div className="d-flex align-items-stretch">
      <div className="header-menu align-items-stretch">
        <div className="ms-3 menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
          <RestrictedAccess
            allowedRoles={[UserRole.SuperAdmin]}
            fallback={<MenuItem title="Devices" to={config.devices.route} />}
          />
          <RestrictedAccess allowedRoles={[UserRole.SuperAdmin]}>
            <MenuItem title="Indigo Users" to={config.adminUsers.route} />
          </RestrictedAccess>
          <RestrictedAccess
            allowedRoles={[UserRole.SuperAdmin]}
            // fallback={<MenuItem title="Audience" to={config.audience.route} />}
          >
            <NestedMenuItem
              title="Customers"
              onToggle={setShowContentMenu}
              isOpened={showContentMenu}
            >
              <MenuItem
                title="Customers"
                to={`${config.adminCustomers.route}?limit=50&page=1&sortBy=firstName&sortOrder=asc&isTrial=false&isTest=false`}
                className="pe-1 mt-2 mb-1"
                icon="bi bi-people"
              />
              <MenuItem
                title="Customers - Trial"
                to={`${config.adminTrialCustomers.route}?limit=50&page=1&sortBy=firstName&sortOrder=asc&isTrial=true&isTest=false`}
                className="pe-1 mb-1"
                icon="bi bi-people-fill"
              />
            </NestedMenuItem>
            <NestedMenuItem
              title="Companies"
              onToggle={setShowContentMenuForCompanies}
              isOpened={showContentMenuForCompanies}
            >
              <MenuItem
                title="Companies"
                to={`${config.adminCustomerCompanies.route}?limit=50&page=1&isTest=false&sortBy=name&sortOrder=asc&trialCompany=false`}
                className="pe-1 mb-2"
                icon="bi bi-building"
              />
              <MenuItem
                title="Companies - Trial"
                to={`${config.adminCustomerTrialCompanies.route}?limit=50&page=1&isTest=false&sortBy=name&sortOrder=asc&trialCompany=true`}
                className="pe-1 mb-2"
                icon="bi bi-building"
              />
            </NestedMenuItem>
          </RestrictedAccess>
          <RestrictedAccess allowedRoles={[UserRole.SuperAdmin]}>
            <MenuItem
              title="App Analytics"
              to={config.adminAppAnalytics.route}
            />
          </RestrictedAccess>
        </div>
      </div>
    </div>
  );
};
