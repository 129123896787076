import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../../components/Modal';
import FacebookReloginModal from '../../../../components/Apps/Facebook/UpdateFacebook';
import api from '../../../../api';
import UpdateFacebookRequestData from '../../../../api/endpoints/apps/interfaces/updateFacebookRequestData.interface';
import GlobalState from '../../../../store/reducers/globalState.interface';

const UpdateFacebook = ({
  children,
  onUpdate,
  facebook,
  pagesNameList,
}: {
  children: React.ReactNode;
  onUpdate?: () => void;
  facebook: App;
  pagesNameList?: string[];
}) => {
  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const open = () => setModalShown(true);
  const close = () => setModalShown(false);
  const { id: indigoUserId } = useSelector(
    (state: GlobalState) => state.userData.user,
  );

  const handleSendReLogin = (accessToken: string, userID: string) => {
    console.log(pagesNameList);
    if (!indigoUserId) {
      toast.error('Missing the loggedIn indigoUserId');
    }
    const updateFacebookReques: UpdateFacebookRequestData = {
      pageName: facebook?.dependency?.config?.pageName,
      token: accessToken,
      userId: userID,
      indigoUserId,
    };

    setLoading(true);

    if (userID === facebook?.dependency?.config?.facebookUser?.userID) {
      api.apps
        .updateFacebookFeeds(updateFacebookReques)
        .then(() => {
          close();
          if (onUpdate) {
            onUpdate();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      toast.error('Sign in to your existing Facebook account');
    }
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={`${t('apps.facebook.refreshFacebookModalTitle')} ${
          facebook?.dependency?.config?.facebookUser?.name
        }`}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
      >
        <FacebookReloginModal loading={loading} onSuccess={handleSendReLogin} />
      </Modal>
    </>
  );
};
export default UpdateFacebook;
