import React, { useState } from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import useTableMultiSelect from '../../../../utils/hooks/useTableMultiSelect';
import SortingQueryParam from '../../../../enums/queryParams/sortingQueryParam.enum';
import CreateLocation from '../../../../containers/AdminPanel/CompanyLocations/CreateLocation';
import GetLocationsRequestData from '../../../../api/endpoints/locations/interfaces/getLocationsRequestData.interface';
import LocationsQueryParam from '../../../../api/endpoints/locations/enums/locationsQueryParam.enum';
import PaginationQueryParam from '../../../../enums/queryParams/paginationQueryParam.enum';
import Location from '../../../../interfaces/location.interface';
import SortOrder from '../../../../enums/sortOrder.enum';
import { KTSVG } from '../../../../lib/metronic/helpers';
import Pagination from '../../../Pagination';
import TableView from '../Table/TableView';
import Search from '../../../Search';
import Card from '../../../Card';
import Tooltip from '../../../Tooltip';

const tableRowHeight = 90;

const options = [
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 1000, label: '1000' },
];

export default ({
  locations,
  total,
  isLoading,
  children,
  resetOnSearch,
  onUpdate,
  setRequestData,
  requestData,
  isSelectable,
  showModal = false,
  isAccountOwner = false,
  loggedInAsAccountOwner = false,
}: {
  locations?: Location[];
  total: number;
  children?: React.ReactNode;
  isLoading: boolean;
  isSelectable?: boolean;
  resetOnSearch?: boolean;
  onUpdate?: () => void;
  setRequestData?: (value: Partial<GetLocationsRequestData>) => void;
  requestData?: Partial<GetLocationsRequestData>;
  showModal?: boolean;
  isAccountOwner?: boolean;
  loggedInAsAccountOwner?: boolean;
}) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(locations, 'id'),
    [],
    resetOnSearch ? requestData?.search : undefined,
  );
  const [sortFieldValue, setSortFieldValue] = useState<{
    field: string;
    isInverted: boolean;
  }>();

  const [pageSize, setPageSize] = useState(10);

  const handleSearch = (searchValue: string) => {
    if (setRequestData) {
      setRequestData({
        ...requestData,
        [LocationsQueryParam.Search]: searchValue,
        [PaginationQueryParam.Page]: '1',
      });
    }
  };

  const handlePageNumberChange = (pageNumber: number) => {
    if (setRequestData) {
      setRequestData({
        ...requestData,
        [PaginationQueryParam.Page]: String(pageNumber),
      });
    }
  };

  const handleSort = (sortField: string, isInverted: boolean) => {
    setSortFieldValue({ field: sortField, isInverted });

    if (setRequestData) {
      setRequestData({
        ...requestData,
        [SortingQueryParam.SortBy]: sortField,
        [SortingQueryParam.SortOrder]: isInverted
          ? SortOrder.Ascending
          : SortOrder.Descending,
      });
    }
  };

  React.useEffect(() => {
    if (setRequestData) {
      setRequestData({
        ...requestData,
        [PaginationQueryParam.Limit]: String(pageSize),
      });
    }
  }, [pageSize]);

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          {children}
          <Search
            searchValue={requestData?.search ?? ''}
            onChange={handleSearch}
            placeholder={t('companies.search')}
            className="me-2"
          />
          <div className="d-flex align-items-center align-content-center">
            <CreateLocation
              onSuccess={onUpdate}
              hasCompany
              disabled={
                !showModal || !isAccountOwner || !loggedInAsAccountOwner
              }
            >
              {
                // Do not nest ternary expressions
                (!isAccountOwner || !loggedInAsAccountOwner) && (
                  <Tooltip
                    text={t('locations.add_location_lock_not_accountowner')}
                  >
                    <button type="button" className="btn btn-secondary me-2">
                      {t('locations.add_location')}
                      <i
                        className="bi bi-lock-fill ms-3"
                        style={{ fontSize: '1.4rem' }}
                      />
                    </button>
                  </Tooltip>
                )
              }
              {isAccountOwner &&
                loggedInAsAccountOwner &&
                (showModal ? (
                  <button type="button" className="btn btn-secondary me-2">
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-2"
                    />
                    {t('locations.add_location')}
                  </button>
                ) : (
                  <Tooltip
                    text={t('locations.add_location_lock_empty_companies')}
                  >
                    <button type="button" className="btn btn-secondary me-2">
                      {t('locations.add_location')}
                      <i
                        className="bi bi-lock-fill ms-3"
                        style={{ fontSize: '1.4rem' }}
                      />
                    </button>
                  </Tooltip>
                ))}
            </CreateLocation>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <TableView
          loggedInAsAccountOwner={loggedInAsAccountOwner}
          isAccountOwner={isAccountOwner}
          onUpdate={onUpdate}
          locations={locations}
          isLoading={isLoading}
          onSort={handleSort}
          sortFieldValue={sortFieldValue}
          preloaderHeight={pageSize * tableRowHeight}
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          isSelectable={isSelectable}
        />

        <div className="d-flex justify-content-between align-items-center">
          <div className="w-80px mb-4">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(+e.target.value);
              }}
              className="form-select form-select-sm form-select-solid"
            >
              {options.map((option) => (
                <option key={`${option.label}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-fill ps-5">
            <Pagination
              total={total}
              pageSize={pageSize}
              pageNumber={Number(requestData?.page ?? 0)}
              onPageNumberUpdate={handlePageNumberChange}
              className="mt-3 mb-5"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
