import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import PlaylistsTable from '../../../components/AssignmentTables/PlaylistsTable';
import { defaultDebounceValue } from '../../../constants/api/debounceSettings';
import GetPlaylistsRequestData from '../../../api/endpoints/playlists/interfaces/getPlaylistsRequestData.interface';
import Playlist from '../../../interfaces/playlist.interface';
import api from '../../../api';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';

export default ({
  onSelectionChange,
  preselectedItems,
  requestData,
  skipQuickClick,
}: {
  onSelectionChange: (selectedItems: string[]) => void;
  preselectedItems: string[];
  requestData: GetPlaylistsRequestData;
  skipQuickClick?: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isAdmin] = useIsAdmin();

  const fetchPlaylistsList = useCallback(
    debounce(async (searchData: GetPlaylistsRequestData) => {
      try {
        const { items, meta } = isAdmin
          ? await api.playlists.adminGetPlaylists(searchData)
          : await api.playlists.getPlaylists(searchData);

        setPlaylists(items);
        setTotal(meta.totalItems);
      } catch (e) {
        // Do nothing
      }

      setLoading(false);
    }, defaultDebounceValue),
    [],
  );

  useEffect(() => {
    setLoading(true);
    fetchPlaylistsList({ ...filters, ...requestData, skipQuickClick });
  }, [filters]);

  return (
    <PlaylistsTable
      isLoading={loading}
      playlists={playlists}
      total={total}
      onSearchDataChange={setFilters}
      onSelectionChange={onSelectionChange}
      preselectedItems={preselectedItems}
    />
  );
};
