/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { MICROSOFT_API, API_URL } from '../../../../constants/api';

export interface ReEstablishMsTeamsConnectionRequestBody {
  expiresIn?: number;
  AccessToken?: string;
  RefreshToken?: string;
  msUserId?: string;
  mail?: string;
  Accountname?: string;
}
export const api = axios.create({
  baseURL: MICROSOFT_API,
  responseType: 'json',
});
const indigoApi = axios.create({
  baseURL: API_URL,
});
export const MsTeamsApis = {
  MsTeamsGetToken: (code?: string) => api.post('/getToken', { code }),
  MsTeamsRestoreSubscription: (appId: string) =>
    indigoApi.post(`msTeams/subscription/${appId}`),
  MsTeamsReEstablishConnection: (
    appId: string,
    requestBody: ReEstablishMsTeamsConnectionRequestBody,
  ) => indigoApi.post(`msTeams/reconnect/${appId}`, requestBody),
  MsTeamsGetProfile: (accessToken?: string) =>
    api.post('/getProfile', { accessToken }),
  getTeams: (AccessToken?: string) =>
    api.post(`/getTeams`, { accessToken: AccessToken }),
  getChannels: (AccessToken: string, teamsId: string) =>
    api.post(`/getChannels`, { accessToken: AccessToken, teamsId }),

  getMessages: (
    AccessToken: string,
    teamsId?: string,
    channelId?: string,
    numberToDisplay?: number,
    removeOldPosts?: number,
    appGuiid?: string,
  ) =>
    api.post(`/getMessages`, {
      accessToken: AccessToken,
      teamsId,
      channelId,
      numberToDisplay,
      removeOldPosts,
      appGuiid,
    }),
};
