import React from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { prepareHookFromState } from '../../../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../../../FormErrorMessage';
import validation from '../../../../../utils/validation';
import TextInput from '../../../../TextInput';
import StepsTitle from '../../StepsTitle';
import Modal from '../../../../Modal';
import CreateTemplateRequestData from '../../../../../api/endpoints/templates/interfaces/createTemplateRequestData.interface';
import AddTemplateSteps from '../../../../../enums/steps/addTemplateSteps.enum';
import SelectLayout from '../../../../../containers/Templates/SelectLayout';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

type FieldsData = Partial<CreateTemplateRequestData>;

interface FormInputs {
  name: string;
  layout: {
    id: string;
    preview: string;
  };
}

export default ({
  data,
  setData,
  validationSchema,
  setMode,
}: {
  data: any;
  setData: (newData: FieldsData) => void;
  validationSchema: { [key: string]: any };
  setMode: (mode: AddTemplateSteps) => void;
}) => {
  const { t } = useTranslation();
  const defaultValues: Partial<FormInputs> = {
    name: data.name ?? '',
    layout: { id: data.layout?.id, preview: data.layout?.preview } ?? {},
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validation.object(validationSchema).required()),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [name, setName] = useFs<FormInputs['name']>('name');
  const [layout, setLayout] = useFs<FormInputs['layout']>('layout');

  const handleSubmitData = (inputsData: Partial<FormInputs>) => {
    setData({
      ...data,
      name: inputsData.name,
      layout: inputsData.layout,
    });

    setMode(AddTemplateSteps.Details);
  };

  return (
    <>
      <StepsTitle currentStep={AddTemplateSteps.Layout} />
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <div className="d-flex flex-column flex-column-fluid mb-2">
          <h4 className="fw-boldest mb-2">
            {t('templates.create_template.fields.title')}
          </h4>
          <TextInput
            name="name"
            id="contentName"
            placeholder={t('contents.type_here')}
            value={name}
            onChange={setName}
            useDisabledStyles
          />
          <FormErrorMessage
            name="name"
            errors={errors}
            className={errorMessageClassNames}
          />
          <h4 className="fw-boldest mb-2">
            {t('templates.create_template.fields.layout')}
          </h4>
          <SelectLayout
            initialValue={layout?.id}
            onChange={({ id, preview }) => setLayout({ id, preview })}
          />
          <FormErrorMessage
            name="layout.id"
            errors={errors}
            className={errorMessageClassNames}
          />
        </div>

        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            {t('common.next_step')}
          </button>
        </div>
      </form>
    </>
  );
};
