import axios from 'axios';
import { toast } from 'react-toastify';
import errorsCodesMessages from '../../constants/api/errorsCodesMessages';

const ignoredStatusCodes = [401];
const networkErrorMessage = 'Network Error';

export const handleError = async (error: any) => {
  const { response } = error;

  // skip this status for now
  if (response.status === 401 || response.data.statusCode === 401) {
    return {};
  }

  if (response) {
    if (response.config.disableToast) {
      return {};
    }

    if (response.config.throwError) {
      return Promise.reject(error);
    }

    const {
      data: { message, statusCode },
    }: { data: { message: string; statusCode: number } } = response;
    if (message && (!statusCode || !ignoredStatusCodes.includes(statusCode))) {
      const errorCodeMessage = (
        errorsCodesMessages as { [key: string]: string }
      )[statusCode];
      if (message !== 'corrupted')
        toast.error(
          `${errorCodeMessage ? `${errorCodeMessage}: ` : ''}${message}`,
        );
    }
  } else if (error.message === networkErrorMessage) {
    toast.error(networkErrorMessage);
  }

  return Promise.reject(error);
};

/**
 * Intercepts server error responses and shows error messages
 */
export default () => {
  axios.interceptors.response.use(undefined, handleError);
};
