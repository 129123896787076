import { call, put, takeEvery } from 'redux-saga/effects';
import UsersGroupsActions from '../actions/usersGroups/usersGroupsActions.enum';
import GetUsersGroupsResponseData from '../../api/endpoints/usersGroups/interfaces/getUsersGroupsResponseData.interface';
import DeleteUsersGroupAction from '../actions/usersGroups/interfaces/deleteUsersGroupAction.interface';
import CreateUsersGroupAction from '../actions/usersGroups/interfaces/createUsersGroupAction.interface';
import UpdateUsersGroupAction from '../actions/usersGroups/interfaces/updateUsersGroupAction.interface';
import GetUsersGroupsAction from '../actions/usersGroups/interfaces/getUsersGroupsAction.interface';
import { readAllFromQueryParams } from '../../utils/queryParams';
import history from '../../constants/history';
import api from '../../api';
import {
  getUsersGroups,
  getUsersGroupsSuccess,
  setUsersGroupsLoading,
} from '../actions/usersGroups';

/**
 * The users groups retrieval worker
 * @param payload
 */
function* getUsersGroupsRequestWorker({ payload }: GetUsersGroupsAction) {
  yield put(setUsersGroupsLoading({ loading: true }));

  try {
    const responseData: GetUsersGroupsResponseData = yield call(
      api.usersGroups.getUsersGroups,
      payload,
    );

    yield put(getUsersGroupsSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setUsersGroupsLoading({ loading: false }));
}

/**
 * The users group creation worker
 * @param payload
 */
function* createUsersGroupRequestWorker({ payload }: CreateUsersGroupAction) {
  yield put(setUsersGroupsLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.usersGroups.createUsersGroup, payload);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(getUsersGroups(readAllFromQueryParams(history.location.search)));
  } else {
    yield put(setUsersGroupsLoading({ loading: false }));
  }
}

/**
 * The users group removal worker
 * @param payload
 */
function* removeUsersGroupRequestWorker({ payload }: DeleteUsersGroupAction) {
  yield put(setUsersGroupsLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(
      payload.isAdmin
        ? api.usersGroups.AdminDeleteUsersGroup
        : api.usersGroups.deleteUsersGroup,
      payload.id,
    );
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(getUsersGroups(readAllFromQueryParams(history.location.search)));
  } else {
    yield put(setUsersGroupsLoading({ loading: false }));
  }
}

/**
 * The users group update worker
 * @param payload
 */
function* updateUsersGroupRequestWorker({ payload }: UpdateUsersGroupAction) {
  yield put(setUsersGroupsLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.usersGroups.updateUsersGroup, payload.data, payload.id);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(getUsersGroups(readAllFromQueryParams(history.location.search)));
  } else {
    yield put(setUsersGroupsLoading({ loading: false }));
  }
}

export default function* usersGroupsWatcher() {
  yield takeEvery<GetUsersGroupsAction>(
    UsersGroupsActions.GET_USERS_GROUPS,
    getUsersGroupsRequestWorker,
  );

  yield takeEvery<CreateUsersGroupAction>(
    UsersGroupsActions.CREATE_USERS_GROUP,
    createUsersGroupRequestWorker,
  );

  yield takeEvery<DeleteUsersGroupAction>(
    UsersGroupsActions.DELETE_USERS_GROUP,
    removeUsersGroupRequestWorker,
  );

  yield takeEvery<UpdateUsersGroupAction>(
    UsersGroupsActions.UPDATE_USERS_GROUP,
    updateUsersGroupRequestWorker,
  );
}
