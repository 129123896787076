import GetDevicesListSuccessAction from './interfaces/getDevicesListSuccessAction.interface';
import GetDevicesListAction from './interfaces/getDevicesListAction.interface';
import UpdateDeviceAction from './interfaces/updateDeviceAction.interface';
import UpdateMultipleDevicesActionInterface from './interfaces/updateMultipleDevicesAction.interface';
import LoadingAction from '../loadingAction.interface';
import DevicesActions from './devicesActions.enum';
import CreateDeviceAction from './interfaces/createDeviceAction.interface';
import LinkDeviceAction from './interfaces/linkDeviceAction.interface';

export const getDevicesList = (
  payload: GetDevicesListAction['payload'],
): GetDevicesListAction => ({
  type: DevicesActions.GET_DEVICES_LIST,
  payload,
});

export const getDevicesListSuccess = (
  payload: GetDevicesListSuccessAction['payload'],
): GetDevicesListSuccessAction => ({
  type: DevicesActions.GET_DEVICES_LIST_SUCCESS,
  payload,
});

export const getAllAdminDevicesListPage = (
  payload: GetDevicesListAction['payload'],
): any => ({
  type: DevicesActions.GET_ADMIN_DEVICES_LIST_PAGE,
  payload,
});

export const getAllAdminDevicesListPageSuccess = (
  payload: GetDevicesListSuccessAction['payload'],
): any => ({
  type: DevicesActions.GET_ADMIN_DEVICES_LIST_PAGE_SUCCESS,
  payload,
});

export const getAdminDevicesList = (
  payload: GetDevicesListAction['payload'],
): any => ({
  type: DevicesActions.GET_ADMIN_DEVICES_LIST,
  payload,
});

export const getAdminDevicesListSuccess = (
  payload: GetDevicesListSuccessAction['payload'],
): any => ({
  type: DevicesActions.GET_ADMIN_DEVICES_LIST_SUCCESS,
  payload,
});

export const updateDevice = (
  payload: UpdateDeviceAction['payload'],
): UpdateDeviceAction => ({
  type: DevicesActions.UPDATE_DEVICE,
  payload,
});

export const linkDevice = (
  payload: LinkDeviceAction['payload'],
): LinkDeviceAction => ({
  type: DevicesActions.LINK_DEVICE,
  payload,
});

export const updateMultipleDevices = (
  payload: UpdateMultipleDevicesActionInterface['payload'],
): UpdateMultipleDevicesActionInterface => ({
  type: DevicesActions.UPDATE_MULTIPLE_DEVICES,
  payload,
});

export const updateMultipleAdminDevices = (
  payload: UpdateMultipleDevicesActionInterface['payload'],
): any => ({
  type: DevicesActions.UPDATE_MULTIPLE_ADMIN_DEVICES,
  payload,
});

export const setDevicesLoading = (
  payload: LoadingAction['payload'],
): LoadingAction => ({
  type: DevicesActions.SET_DEVICES_LOADING,
  payload,
});

export const createDevice = (
  payload: CreateDeviceAction['payload'],
): CreateDeviceAction => ({
  type: DevicesActions.CREATE_DEVICE,
  payload,
});
