enum LeadSourceEnum {
  facebook = 'Facebook',
  instagram = 'Instagram',
  linkedin = 'LinkedIn Ads',
  google = 'Google Organic',
  youTube = 'YouTube',
  manufacturingMarvels = 'Manufacturing Marvels',
  email = 'Email or Newsletter',
  Radio = 'Radio',
  blog = 'Blog or Publication',
  other = 'Other',
}

export default LeadSourceEnum;
