import React from 'react';
import { isEqual, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { debouncedUpdateSearch } from '../../../../utils/browserHistory';
import ContentsQueryParam from '../../../../api/endpoints/contents/enums/contentsQueryParam.enum';
import { TagsTarget } from '../../../../api/endpoints/tags/enums/tagsTarget.enum';
import { SelectedValue } from '../../../Select/CreatableAsyncSelect';
import FiltersDropdown from '../../../Dropdown/FiltersDropdown';
import SelectTags from '../../../../containers/SelectTags';
import useFilter from '../../../../utils/hooks/useFilter';

export default ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const [tags, setTags] = useFilter<string[]>(
    ContentsQueryParam.Tags,
    [],
    true,
  );

  const handleApply = () =>
    debouncedUpdateSearch({
      [ContentsQueryParam.Tags]: tags ?? [],
    });

  // Reset filters in the query params
  const handleReset = () =>
    debouncedUpdateSearch({
      [ContentsQueryParam.Tags]: [],
    });

  return (
    <FiltersDropdown
      className={className}
      onApplyFilters={handleApply}
      onReset={handleReset}
    >
      <p className="mb-5">
        <label htmlFor="contentTag" className="fw-bold mb-2">
          {t('contents.tag')}
        </label>
        <SelectTags
          id="contentTag"
          target={TagsTarget.Contents}
          initialValue={tags?.map((value) => ({ value, label: value }))}
          onChange={(values) => {
            const newValues = map<SelectedValue>(values, 'value');

            if (!isEqual(newValues, tags)) {
              setTags(newValues);
            }
          }}
        />
      </p>
    </FiltersDropdown>
  );
};
