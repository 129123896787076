import clsx from 'clsx';
import React, { memo } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap-v5';
import { KTSVG } from '../../../../lib/metronic/helpers';
import styles from './modal.module.scss';
import { logout } from '../../../../utils/auth0';

interface ModalProps {
  show: boolean;
  children: React.ReactNode;
  bodyClassName?: string;
  dialogClassName?: string;
  onHide?: () => void;
  background?: any;
  closeModal?: boolean;
}

const Modal = ({
  show,
  children,
  onHide,
  bodyClassName,
  dialogClassName,
  closeModal,
  background,
}: ModalProps) => (
  <>
    <BootstrapModal
      enforceFocus={false}
      show={show}
      onHide={onHide}
      dialogClassName={clsx(
        'modal-dialog-centered',
        'mw-1400px',

        dialogClassName,
      )}
      contentClassName="rounded-pill modal-content"
    >
      {/* <div className="modal-header" /> */}

      {background ? (
        <div
          className={clsx('modal-body', bodyClassName)}
          style={{
            backgroundImage: `url(${background})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            overflow: 'hidden',
            borderRadius: '2.475rem',
          }}
        >
          <div
            role="button"
            tabIndex={-1}
            onKeyPress={closeModal ? onHide : logout}
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={closeModal ? onHide : logout}
            style={{ float: 'right' }}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon-1"
            />
          </div>
          {children}
        </div>
      ) : (
        <div className={clsx('modal-body', bodyClassName)}>{children}</div>
      )}
      {/* <div className="modal-footer" /> */}
    </BootstrapModal>
  </>
);

Modal.Separator = memo(
  ({
    className,
    withoutDefaultMargins,
  }: {
    className?: string;
    withoutDefaultMargins?: boolean;
  }) => (
    <div
      className={clsx(
        'd-flex position-relative',
        !withoutDefaultMargins && 'mt-8 mb-7',
        className,
      )}
    >
      <div className={clsx('separator position-absolute', styles.separator)} />
    </div>
  ),
);

export default Modal;
