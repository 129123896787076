import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
// import WithDeleteConfirm from '../../../components/WithDeleteConfirm/Controlled';
import { useSelector } from 'react-redux';
import WithConfirm from '../../../components/WithConfirm/Controlled';
import IContent from '../../../interfaces/content.interface';
import Playlist from '../../../interfaces/playlist.interface';
import Tooltip from '../../../components/Tooltip';
import api from '../../../api';
import RemoveContentPlaylists from './RemoveContentPlaylists';
import GetPlaylistsRequestData from '../../../api/endpoints/playlists/interfaces/getPlaylistsRequestData.interface';
import GlobalState from '../../../store/reducers/globalState.interface';

export default ({
  content,
  children,
  playlist,
  selectedItems,
  isLocked,
  onSuccess,
}: {
  content?: IContent;
  children?: React.ReactNode;
  playlist: Playlist;
  isLocked?: boolean;
  selectedItems?: string[];
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const { id } = useSelector((state: GlobalState) => state.userData.user);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [contentPlaylists, setContentPlaylists] = useState<Playlist[]>([]);

  const [playlistsIds, setPlaylistsIds] = useState<string[]>([]);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);
  const [loadingModals, isLoadingModals] = useState(false);
  const [filters, setFilters] = useState<GetPlaylistsRequestData>();
  const [multiplePlaylists, setMultiplePlaylists] = useState<boolean>(false);

  const getPlaylistsBasedOnContentId = async () => {
    if (content) {
      try {
        isLoadingModals(true);
        setIsLoading(true);

        await api.playlists
          .getPlaylistsByContentId(
            filters ?? { limit: '150' },
            content?.mediaGroup?.medias[0]?.id,
            content?.app?.id,
            content?.contentUuid,
          )
          .then((res) => {
            setTotal(res.meta.totalItems);
            if (res.meta.totalItems > 1) setMultiplePlaylists(true);
            setContentPlaylists(res.items);
          });
      } catch (error: any) {
        if (error?.response?.data?.message === 'corrupted') {
          toast.warn(t('playlists.corruptedContent'));
        } else toast.error(error);
      } finally {
        isLoadingModals(false);
        setIsLoading(false);
      }
    }
  };

  const checkIfContentIsLockedForUsersExceptOwner = () => {
    if (!content) {
      if (!isLocked) return false;
      return true;
    }

    if (!content.isLocked) return false;

    if (content.contentOwner && content.contentOwner.id === id) return false;

    if (!content.contentOwner) {
      if (
        content.app?.ownerApp?.id === id ||
        content.mediaGroup?.medias[0]?.ownerMedia?.id === id
      ) {
        return false;
      }
    }

    return true;
  };

  const handleConfirm = () => {
    setIsLoading(true);

    if (content) {
      api.playlists
        .deleteContentFromPlaylist({
          contentId: content.id,
          playlistId: playlist.id,
        })
        .then(onSuccess)
        .finally(() => setIsLoading(false));
    }

    if (selectedItems) {
      api.playlists
        .deleteMultipleContentsFromPlaylist(playlist.id, selectedItems, [
          playlist.id,
        ])
        .then(onSuccess)
        .finally(() => setIsLoading(false));
    }

    close();
    toast.success(i18next.t<string>('playlists.content_removed_message'));
  };

  const handleConfirmMultiplePlaylists = () => {
    if (content) {
      try {
        api.playlists
          .deleteContentFromMultiplePlaylists(
            playlistsIds,
            content?.mediaGroup?.medias[0]?.id,
            content?.app?.id,
          )
          .then(onSuccess)
          .finally(() => setIsLoading(false));
      } catch (error: any) {
        toast.error(error);
      }
    }
  };

  React.useEffect(() => {
    if (isModalVisible) getPlaylistsBasedOnContentId();
  }, [isModalVisible]);

  React.useEffect(() => {
    if (isModalVisible) getPlaylistsBasedOnContentId();
  }, [filters]);

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.remove')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-trash" />
            </span>
          </Tooltip>
        )}
      </span>
      <>
        {checkIfContentIsLockedForUsersExceptOwner() === false ? (
          <RemoveContentPlaylists
            playlists={contentPlaylists}
            body={`${
              selectedItems && selectedItems.length > 1
                ? t('common.delete_confirmation.multiple_delete_title')
                : `${t('common.delete_confirmation.title')} ${t(
                    'common.delete_confirmation.content',
                  )} ${content?.title}`
            }
      ?`}
            handleConfirm={handleConfirm}
            handleDecline={close}
            isVisible={isModalVisible}
            isLoading={isLoading}
            onSelectionChange={(selectedItemsPlaylists) => {
              setPlaylistsIds(selectedItemsPlaylists);
            }}
            handleConfirmMultipleDelete={handleConfirmMultiplePlaylists}
            multipleContents={!!selectedItems}
            total={total}
            preselectedItems={playlist.id}
            setFiltersSearch={setFilters}
            multiplePlaylists={multiplePlaylists}
            loadingModals={loadingModals}
            contentTitle={content?.title}
          />
        ) : (
          <WithConfirm
            isVisible={isModalVisible}
            isLoading={isLoading}
            handleDecline={close}
            body={t('playlists.delete_locked_alert')}
            discardButtonText={t('common.ok')}
          />
        )}
      </>
    </>
  );
};
