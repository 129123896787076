import React from 'react';
import WithSpinner from '../../../components/WithSpinner';
import IPlaylist from '../../../interfaces/playlist.interface';
import { disableRightClickIframe } from '../../../utils/common';

export default ({
  playlist,
  isLoading = false,
  height,
  src,
  fullscreen,
}: {
  isLoading?: boolean;
  height?: number;
  src?: string;
  playlist: IPlaylist;
  fullscreen?:
    | true
    | 'sm-down'
    | 'md-down'
    | 'lg-down'
    | 'xl-down'
    | 'xxl-down'
    | undefined;
}) => {
  React.useEffect(() => {
    disableRightClickIframe(`${playlist.id}_iframe`);
  }, []);

  const contentPreivewMediaType: React.ReactNode = (
    <div id={playlist.id}>
      <iframe
        id={`${playlist.id}_iframe`}
        src={src?.replace('thumbnail', 'preview')}
        title={playlist.name}
        width="100%"
        height={height}
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
        style={{
          height: `${fullscreen ? '100vh' : '480px'}`,
        }}
      />
    </div>
  );

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="card">{contentPreivewMediaType}</div>
    </WithSpinner>
  );
};
