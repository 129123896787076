import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import SelectAsync, {
  SelectedValue,
} from '../../components/Select/SelectAsync';
import SortOrder from '../../enums/sortOrder.enum';
import { usePrevValues } from '../../utils/hooks/usePrevValues';

const removeCounty = (item: string) =>
  item.includes('County') ? item.replace('County', '') : item;

/**
 * Retrieves the select options asynchronously
 * @param search
 * @param countryCode
 * @param stateCode
 * @param isSevereWeather
 */
const loadOptions = async (
  search: string,
  countryCode?: string,
  stateCode?: string,
  isSevereWeather?: boolean,
): Promise<SelectedValue[]> => {
  const { items } = await api.counties.getCounties(
    {
      search,
      countryCode,
      stateCode,
      sortOrder: SortOrder.Ascending,
      limit: 300,
    },
    isSevereWeather,
  );

  return sortBy(items, ['name']).map(({ id, name }) => ({
    value: id,
    label: removeCounty(name),
  }));
};

const SelectCounty = ({
  onChange,
  initialValue,
  initialOption,
  id,
  name,
  className,
  isClearable,
  isMulti,
  countryCode,
  stateCode,
  isSevereWeather,
  disabled,
}: {
  onChange: (selectedValue: SelectedValue) => void;
  initialValue?: string;
  initialOption?: SelectedValue[];
  id?: string;
  name?: string;
  className?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  isSevereWeather?: boolean;
  countryCode?: string;
  stateCode?: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<SelectedValue>();
  const [initialOptionValue, setInitialOptionValue] = useState<
    SelectedValue[] | undefined
  >(initialOption);

  useEffect(() => {
    if (!initialValue || initialOption) return;

    setSelectedValue({
      label: `${t('common.loading')}...`,
      value: initialValue,
    });

    setTimeout(async () => {
      const { name: countyName } = await api.counties.getCounty(
        initialValue,
        isSevereWeather,
      );

      setSelectedValue({ label: countyName, value: initialValue });
    });
  }, []);

  usePrevValues(countryCode, (prevCountryCode: string | undefined) => {
    if (
      prevCountryCode !== undefined &&
      countryCode !== undefined &&
      prevCountryCode !== countryCode
    ) {
      setInitialOptionValue([]);
    }
  });

  usePrevValues(stateCode, (prevStateCode: string | undefined) => {
    if (
      prevStateCode !== undefined &&
      stateCode !== undefined &&
      prevStateCode !== stateCode
    ) {
      setInitialOptionValue([]);
    }
  });

  return (
    <SelectAsync
      id={id}
      name={name}
      key={`${countryCode}-${stateCode}`}
      initialValue={initialOptionValue || selectedValue}
      onChange={onChange}
      loadOptions={(search) =>
        loadOptions(search, countryCode, stateCode, isSevereWeather)
      }
      className={className}
      placeholder={t('common.search')}
      isClearable={isClearable}
      isMulti={isMulti}
      isDisabled={disabled}
    />
  );
};

SelectCounty.defaultProps = {
  initialValue: null,
};

export default SelectCounty;
