import clsx from 'clsx';
import React from 'react';
import noPreview from '../../../assets/images/noPreview/noPreview.png';
import styles from '../../Contents/ContentTable/TableRow/tableRow.module.scss';
import playlistStyles from '../../Playlist/Playlist.module.scss';
import App from '../../../interfaces/app.interface';
import IContentApp from '../../../interfaces/contentApp.interface';

export default ({
  app,
  children,
}: {
  app?: App | IContentApp;
  children?: React.ReactNode;
}) => (
  <span onContextMenu={(ev) => ev.preventDefault()}>
    <img
      src={app?.dependency?.config.canvaAssetUrl || noPreview}
      onError={({ currentTarget }) => {
        // eslint-disable-next-line no-param-reassign
        currentTarget.src = noPreview;
      }}
      alt="preview"
      className={clsx(
        styles.thumbnail,
        playlistStyles.image_background,
        playlistStyles.imageThumbnail,
      )}
    />
    {children}
  </span>
);
