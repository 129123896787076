import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { components, MenuProps } from 'react-select';
import { useSelector } from 'react-redux';
import api from '../../api';
import SelectAsync, {
  SelectedValue,
} from '../../components/Select/SelectAsync';
import GlobalState from '../../store/reducers/globalState.interface';
import { UserRole } from '../../enums/userRole.enum';
import { readFromQueryString } from '../../utils/queryParams';

/**
 * Retrieves the select options asynchronously
 */
const loadOptions = async (userId?: string): Promise<SelectedValue[]> => {
  let items;

  if (userId) {
    items = (await api.templates.getTemplatesAdmin({ currentUserId: userId }))
      .items;

    return items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }
  items = (await api.templates.getTemplates({})).items;

  return items.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

const NoOptionsMessage = ({ noOption }: { noOption?: boolean }) => {
  const { roles } = useSelector((state: GlobalState) => state.userData.user);
  if (roles.includes(UserRole.SuperAdmin)) return <></>;
  return (
    <div className="p-2">
      {noOption && 'No templates found. '}If you want create a new template go
      to <Link to="/templates">Templates</Link>
    </div>
  );
};
const IsTrialMessage = () => {
  return (
    <div className="p-2">
      <i className="bi bi-lock-fill ms-3" style={{ fontSize: '1.4rem' }} />{' '}
      Change templates is available in a paid plan.
    </div>
  );
};

const Menu = (props: MenuProps<any, false, any>) => {
  const { children, options } = props;
  const isTrial = useSelector((state: GlobalState) => {
    return state.userData?.user?.isTrial;
  });

  return (
    <components.Menu<any, false, any> {...props}>
      <>
        {children}
        {isTrial ? (
          <IsTrialMessage />
        ) : (
          <NoOptionsMessage noOption={!options?.length} />
        )}
      </>
    </components.Menu>
  );
};

const SelectTemplate = ({
  onChange,
  initialValue,
  id,
  className,
  isClearable,
}: {
  onChange: (selectedValue: SelectedValue) => void;
  initialValue?: string;
  id?: string;
  className?: string;
  isClearable?: boolean;
}) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<SelectedValue>();
  const { search } = useLocation();
  const { roles } = useSelector((state: GlobalState) => state.userData.user);

  useEffect(() => {
    if (!initialValue) return;

    setSelectedValue({
      label: `${t('common.loading')}...`,
      value: initialValue,
    });

    setTimeout(async () => {
      const { name } = await api.templates.getTemplate(initialValue);

      setSelectedValue({ label: name, value: initialValue });
    });
  }, []);

  const handleLoad = async () => {
    let options: SelectedValue[] = [];
    if (roles.includes(UserRole.SuperAdmin)) {
      const devicesIds = readFromQueryString(search, 'devicesIds');
      if (devicesIds) {
        const currentUserId = (await api.devices.getDeviceById(devicesIds))
          ?.owner?.id;
        options = await loadOptions(currentUserId);
        return options;
      }
    }
    options = await loadOptions();
    return options;
  };

  return (
    <SelectAsync
      id={id}
      initialValue={selectedValue}
      onChange={onChange}
      loadOptions={handleLoad}
      className={className}
      placeholder={t('playlists.select_template')}
      isClearable={isClearable}
      components={{ Menu, NoOptionsMessage: () => null }}
    />
  );
};

export default SelectTemplate;
