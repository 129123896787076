import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { isEqual, map } from 'lodash';
import SelectDaysOfWeek, {
  getDayOfWeekOption,
} from '../../../containers/SelectDaysOfWeek';
import SelectTags from '../../../containers/SelectTags';
import DayOfWeek from '../../../enums/dayOfWeek.enum';
import Orientation from '../../../enums/orientation.enum';
import { TagsTarget } from '../../../api/endpoints/tags/enums/tagsTarget.enum';
import BulkUpdateContentsRequestDataInterface from '../../../api/endpoints/contents/interfaces/bulkUpdateContentRequestData.interface';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import { bulkEditContentsValidation } from '../../../validation/contents';
import SelectOrientation from '../../../containers/SelectOrientation';
import validation from '../../../utils/validation';
import Counter from '../../Counter';
import DatePicker from '../../DatePicker';
import FormErrorMessage from '../../FormErrorMessage';
import { SelectedValue } from '../../Select/SelectAsync';
import WithSpinner from '../../WithSpinner';
import Playlist from '../../../interfaces/playlist.interface';
import Tooltip from '../../Tooltip';

interface FormInputs {
  orientation: Orientation;
  duration: number;
  startDate: Date;
  endDate: Date;
  tags: string[];
  weekDays: DayOfWeek[];
}

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

export default ({
  contentIds,
  onSubmit,
  isLoading = false,
  config,
  contentType,
  playlist,
  hasBothZones = false,
}: {
  contentIds: string[];
  onSubmit: (contentData: BulkUpdateContentsRequestDataInterface) => void;
  isLoading?: boolean;
  config?: { skipDuration?: boolean };
  playlist?: Playlist;
  contentType?: { typeVideo?: boolean };
  hasBothZones?: boolean;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(
      validation.object(bulkEditContentsValidation(t)).required(),
    ),
  });

  const [duration, setDuration] = useStateWithHookForm<FormInputs, number>(
    {
      setValue,
      trigger,
      name: 'duration',
    },
    15,
  );
  const [tags, setTags] = useStateWithHookForm<FormInputs, FormInputs['tags']>(
    { setValue, trigger, name: 'tags' },
    [],
  );
  const [weekDays, setDays] = useStateWithHookForm<FormInputs, DayOfWeek[]>(
    { setValue, trigger, name: 'weekDays' },
    [],
  );
  const [startDate, setStartDate] = useStateWithHookForm<FormInputs, Date>({
    setValue,
    trigger,
    name: 'startDate',
  });
  const [endDate, setEndDate] = useStateWithHookForm<FormInputs, Date>({
    setValue,
    trigger,
    name: 'endDate',
  });
  const [orientation, setOrientation] = useStateWithHookForm<
    FormInputs,
    Orientation
  >({ setValue, trigger, name: 'orientation' }, '' as Orientation);

  const handleSubmitData = () => {
    const body: BulkUpdateContentsRequestDataInterface = {
      contentIds,
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      zoneId: orientation,
      tags,
      weekDays,
      playlistId: playlist?.id,
    };
    if (!config?.skipDuration && !contentType?.typeVideo) {
      body.duration = duration?.toString();
    }
    onSubmit(body);
  };

  useEffect(() => {
    if (!playlist?.template) {
      setOrientation(Orientation.FullScreen);
    }
  }, [playlist]);

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <form onSubmit={handleSubmit(handleSubmitData)}>
        {!config?.skipDuration && !contentType?.typeVideo && (
          <Tooltip text={t('contents.video_constaint_duration')}>
            <p>
              <Counter
                name="duration"
                title={t('common.duration')}
                placeholder={t('contents.type_here')}
                value={duration}
                onChange={setDuration}
                className="w-100"
                classNameTitle="fw-boldest"
              />
              <FormErrorMessage
                name="duration"
                errors={errors}
                className={errorMessageClassNames}
              />
            </p>
          </Tooltip>
        )}

        <p>
          <h4>{t('playlists.schedule')}</h4>
          <label className="fw-bold mb-2">{t('contents.start_date')}</label>
          <DatePicker
            name="startDate"
            value={startDate}
            options={{
              enableTime: true,
              defaultHour: 0,
            }}
            onChange={(newDate) => setStartDate(newDate[0])}
          />
          <FormErrorMessage
            name="startDate"
            errors={errors}
            className={errorMessageClassNames}
          />
        </p>
        <p>
          <label className="fw-bold mb-2">{t('contents.end_date')}</label>
          <DatePicker
            name="endDate"
            value={endDate}
            options={{
              enableTime: true,
              defaultHour: 23,
              defaultMinute: 59,
            }}
            onChange={(newDate) => setEndDate(newDate[0])}
          />
          <FormErrorMessage
            name="endDate"
            errors={errors}
            className={errorMessageClassNames}
          />
        </p>
        <label
          htmlFor="contentOrientation"
          className="required fw-boldest mb-2"
        >
          {t('common.panel')}
        </label>
        {playlist?.template ? (
          <>
            <SelectOrientation
              id="contentOrientation"
              placeholder={t('common.select')}
              orientation={orientation}
              setOrientation={setOrientation}
              playlist={playlist}
            />
            {orientation === Orientation.Auto && !hasBothZones && (
              <p className="my-2 text-warning">
                {t('playlists.orientation_warning')}
              </p>
            )}
            <FormErrorMessage
              name="orientation"
              errors={errors}
              className={errorMessageClassNames}
            />
          </>
        ) : (
          <div className="form-control form-control-solid mb-5">
            <span>{t('common.orientations.full_screen')}</span>
          </div>
        )}

        <p>
          <label className="fw-boldest mb-4">{t('contents.mtwtfss')}</label>
          <SelectDaysOfWeek
            initialValue={weekDays.map((day) => getDayOfWeekOption(day, t))}
            onChange={(selectedDays) => setDays(selectedDays as DayOfWeek[])}
          />
          <FormErrorMessage
            name="weekDays"
            errors={errors}
            className={errorMessageClassNames}
          />
        </p>
        <p>
          <label htmlFor="contentTag" className="fw-boldest mb-2">
            {t('contents.tag')}
          </label>
          <SelectTags
            target={TagsTarget.Contents}
            initialValue={tags.map((value) => ({ value, label: value }))}
            onChange={(values) => {
              const newValues = map<SelectedValue>(values, 'value');
              if (!isEqual(newValues, tags)) {
                setTags(newValues);
              }
            }}
          />
          <FormErrorMessage
            name="tags"
            errors={errors}
            className={errorMessageClassNames}
          />
        </p>
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            {t('common.edit')}
          </button>
        </div>
      </form>
    </WithSpinner>
  );
};
