/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, MutableRefObject, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { KTSVG } from '../../../lib/metronic/helpers';
import Library from '../../../interfaces/library.interface';
import { ReactComponent as Logo } from '../../../assets/images/logo/miniLogo.svg';
import { languagesTranslation } from '../../../constants/translation/languages';
import noPreview from '../../../assets/images/noPreview/noPreviewVertical.png';
import QuickClick from '../../../containers/Libraries/QuickClick';
import { getUrlsFromContent } from '../../../utils/library';
import Carousel from '../LibraryInfo/Carousel';
import WithPreview from '../../WithPreview';
import styles from './libraryCard.module.scss';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';
import routesConfig from '../../../routing/config';

import DeleteTopic from '../../AdminPanel/Library/Topic/DeleteTopic';
import UpdateCategoryTopic from '../../../containers/AdminPanel/Topic/EditTopic';
import PublishModal from '../LibraryInfo/PublishModal';
import ScheduleLibraryRequestData from '../../../api/endpoints/libraries/interfaces/scheduleLibraryRequestData.interface';
import api from '../../../api';
import UpdateTopicImg from '../../AdminPanel/Library/Topic/UpdateTopicImg';
import ReOrderSkus from '../../AdminPanel/Library/Topic/ReOrderSkus';
import { LibraryCategoryType } from '../../../enums/library/libraryCategoryType.enum';
import CreateSkuContainerSingle from '../../../containers/AdminPanel/Sku/SingleContent';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import history from '../../../constants/history';

export default ({
  library,
  onUpdate,
}: {
  library: Library;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const { text, posters, content, videos, lang, id, sku, fullscreen } = library;

  // const [divHeight, setDivHeight] = useState<number>();
  const { roles } = useSelector((state: GlobalState) => state.userData.user);
  const [windowWidth] = useWindowSize();

  const ref = useRef<HTMLImageElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>();
  const cardDivRef = useRef<HTMLDivElement>(null);
  const hTagTitleRef = useRef<HTMLHeadingElement>(null);
  const hTagSkuRef = useRef<HTMLHeadingElement>(null);
  const pLangRef = useRef<HTMLParagraphElement>(null);
  const playIcon = useRef<HTMLDivElement>(null);
  const pauseIcon = useRef<HTMLDivElement>(null);
  const pauseIconHover = useRef<HTMLDivElement>(null);
  const pAddToDeviceRef = useRef<HTMLParagraphElement>(null);
  const [publishModal, setPublishModal] = useState<boolean>(false);
  const handleCloseSchedule = () => setPublishModal(false);
  const handleOpenSchedule = () => setPublishModal(true);
  const allTopicSku = [
    ...library?.posters,
    ...library?.content,
    ...library?.videos,
    ...library?.fullscreen,
  ];
  const videoDiv = useRef<HTMLDivElement>(null);
  const [picturePreview, setPicturePreview] = useState('');
  // const actions = [
  //   {
  //     key: 'Edit',
  //     content: <span className="w-100 d-block">Edit Topic</span>,
  //   },

  //   {
  //     key: 'Delete',
  //     content: (
  //       <DeleteTopic>
  //         <i className="bi bi-trash fs-7" />
  //         <span className="w-100 d-block">{t('common.edit')}</span>
  //       </DeleteTopic>
  //     ),
  //   },
  // ];

  const handleScheduleLibrary = async (data: ScheduleLibraryRequestData) => {
    try {
      await api.libraries.scheduleLibrary(library.id, {
        ...data,
        skuIds: [allTopicSku?.[0]?.id],
      });
      toast.success(i18next.t<string>('library.successfully_scheduled'));
    } catch (e) {
      toast.error('error in scheduling library ');
    }
  };
  useEffect(() => {
    if (cardDivRef?.current && hTagTitleRef?.current) {
      hTagTitleRef.current.style.fontSize = `${
        cardDivRef.current.clientWidth * 0.055
      }px`;
    }
  }, [cardDivRef, hTagTitleRef, windowWidth]);

  useEffect(() => {
    if (cardDivRef?.current && hTagSkuRef?.current) {
      hTagSkuRef.current.style.fontSize = `${
        cardDivRef.current.clientWidth * 0.055
      }px`;
    }
  }, [cardDivRef, hTagSkuRef, windowWidth]);

  useEffect(() => {
    if (cardDivRef?.current && pLangRef?.current) {
      pLangRef.current.style.fontSize = `${
        cardDivRef.current.clientWidth * 0.042
      }px`;
    }
  }, [cardDivRef, pLangRef, windowWidth]);

  useEffect(() => {
    if (cardDivRef?.current && pAddToDeviceRef?.current) {
      pAddToDeviceRef.current.style.fontSize = `${
        cardDivRef.current.clientWidth * 0.05
      }px`;
    }
  }, [cardDivRef, pAddToDeviceRef, windowWidth]);

  useEffect(() => {
    if (divRef?.current) {
      if (ref?.current) {
        if (library?.category?.type === LibraryCategoryType.Free) {
          divRef.current.style.height = `${
            (9 / 16) * divRef?.current?.clientWidth
          }px`;
        } else {
          divRef.current.style.height = `${
            (4 / 3) * divRef?.current?.clientWidth
          }px`;
        }
      }
      if (videoRef?.current) {
        if (library?.category?.type === LibraryCategoryType.Free) {
          divRef.current.style.height = `${
            (9 / 16) * divRef?.current?.clientWidth
          }px`;
        } else {
          divRef.current.style.height = `${
            (4 / 3) * divRef?.current?.clientWidth
          }px`;
        }
      }
    }
  }, [ref, divRef, videoRef, windowWidth]);

  useEffect(() => {
    if (allTopicSku) {
      const skuWithcover = allTopicSku?.filter(
        (skuu) => skuu?.isCover === true,
      );

      if (skuWithcover.length > 0) {
        setPicturePreview(skuWithcover?.[0]?.media?.thumbnailUrl);
      } else setPicturePreview(allTopicSku[0]?.media?.thumbnailUrl);
    }
  }, []);
  const [firstPlay, setFirstPlay] = useState<boolean>(true);
  const playVideo = () => {
    if (videoRef?.current && videoRef?.current?.paused) {
      videoRef.current.play();
      setFirstPlay(false);
      if (videoDiv?.current) videoDiv.current.style.display = 'none';
    } else if (videoRef?.current && !videoRef?.current?.paused) {
      videoRef.current.pause();
      setFirstPlay(false);
      if (videoDiv?.current) videoDiv.current.style.display = 'block';
      if (playIcon?.current) playIcon.current.style.display = 'block';
      if (pauseIcon?.current) pauseIcon.current.style.display = 'none';
      if (pauseIconHover?.current)
        pauseIconHover.current.style.display = 'none';
    }
  };

  const hoverOn = () => {
    if (videoRef?.current && !firstPlay && !videoRef?.current?.paused) {
      if (videoDiv?.current) videoDiv.current.style.display = 'block';
      if (pauseIconHover?.current && pauseIcon?.current && playIcon?.current) {
        playIcon.current.style.display = 'none';
        pauseIcon.current.style.display = 'none';
        pauseIconHover.current.style.display = 'block';
      }
    }
  };
  const hoverOff = () => {
    if (videoRef?.current && !firstPlay && !videoRef?.current?.paused) {
      if (videoDiv?.current) videoDiv.current.style.display = 'none';
      if (pauseIcon?.current && pauseIconHover?.current && playIcon?.current) {
        playIcon.current.style.display = 'none';
        pauseIcon.current.style.display = 'block';
        pauseIconHover.current.style.display = 'none';
      }
    }
  };
  const singleContentTopic = (
    imgRef: MutableRefObject<HTMLImageElement>,
    divContainerRef: MutableRefObject<HTMLDivElement>,
    vidRef: MutableRefObject<HTMLVideoElement>,
  ) => {
    if (videos?.length)
      return (
        <div
          style={{ position: 'relative' }}
          className={clsx(styles.videoContaint)}
          ref={divContainerRef}
          onMouseEnter={() => hoverOn()}
          onMouseLeave={() => hoverOff()}
        >
          <div
            className={clsx(
              styles.backgroundBluredForTheVideo,
              'cursor-pointer',
            )}
            ref={videoDiv}
            onClick={() => playVideo()}
          >
            <i
              ref={playIcon}
              className={clsx(styles.videoIconPlay, 'bi bi-play-circle-fill')}
            />
            <i
              ref={pauseIcon}
              style={{ display: 'none' }}
              className={clsx(
                styles.videoIconPlay,
                styles.videoIconPause,
                'bi bi-play-circle-fill',
              )}
            />
            <i
              ref={pauseIconHover}
              style={{ display: 'none' }}
              className={clsx(
                styles.videoIconPlay,
                styles.videoIconPause,
                'bi bi-pause-circle-fill',
              )}
            />
          </div>
          <video
            ref={vidRef}
            className={clsx(styles.videoDiv)}
            onContextMenu={(ev) => ev.preventDefault()}
            // style={{ objectFit: 'contain' }}
            onError={(e) => {
              e.currentTarget.src = noPreview;
            }}
            autoPlay={false}
            onClick={() => playVideo()}
            muted
          >
            <source src={videos?.[0]?.media?.url} />
          </video>
        </div>
      );

    return (
      <div style={{ position: 'relative' }} ref={divContainerRef}>
        <img
          style={{
            maxHeight: '100%',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            objectFit: 'contain',
          }}
          ref={imgRef}
          className={clsx('card-img-top w-100')}
          src={
            posters.length > 0 || content.length || fullscreen.length > 0
              ? posters?.[0]?.media?.thumbnailUrl ||
                content?.[0]?.media?.thumbnailUrl ||
                fullscreen?.[0]?.media?.thumbnailUrl
              : noPreview
          }
          alt="library preview"
          onError={(e) => {
            e.currentTarget.src = noPreview;
          }}
        />
      </div>
    );
  };
  return (
    <div
      className={clsx(styles.cardWidth)}
      style={{ position: 'relative' }}
      key={id}
    >
      <div
        ref={cardDivRef}
        className={clsx('card border my-4 mx-auto', styles.shadow)}
      >
        {roles.includes(UserRole.SuperAdmin) && (
          <>
            <div className="dropdown">
              <button
                className="btn d-flex position-absolute top-0 start-100 translate-middle btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow  mx-1 dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                aria-label="DropDownButton"
              />

              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <UpdateCategoryTopic
                    topic={library}
                    onUpdate={onUpdate}
                    update
                  >
                    <div className="dropdown-item">Edit Topic</div>
                  </UpdateCategoryTopic>
                </li>

                {library.category.type === LibraryCategoryType.Premium && (
                  <li>
                    <ReOrderSkus onUpdate={onUpdate} topic={library}>
                      <div className="dropdown-item"> Re-Order Contents </div>
                    </ReOrderSkus>
                  </li>
                )}

                {library.category.type === LibraryCategoryType.Free && (
                  <li>
                    <CreateSkuContainerSingle
                      isEdit
                      // onUpdateContentPosters={onUpdate}
                      topicId={library.id}
                      topicName={library.text}
                      onUpdateContentPosters={onUpdate}
                    >
                      <div className="dropdown-item">
                        {' '}
                        Update Single Content{' '}
                      </div>
                    </CreateSkuContainerSingle>
                  </li>
                )}
                {library?.category?.type === LibraryCategoryType.Premium && (
                  <li>
                    <UpdateTopicImg library={library} onUpdate={onUpdate}>
                      <div className="dropdown-item"> Upload Content </div>
                    </UpdateTopicImg>
                  </li>
                )}
                <li>
                  <DeleteTopic topic={library} onUpdate={onUpdate}>
                    <div className="dropdown-item"> Delete Topic </div>
                  </DeleteTopic>
                </li>
              </ul>
            </div>
          </>
        )}

        <span
          className={
            library?.category?.type === LibraryCategoryType.Free
              ? 'text-center'
              : 'text-center p-7'
          }
        >
          {allTopicSku?.length === 1 ? (
            singleContentTopic(
              ref as MutableRefObject<HTMLImageElement>,
              divRef as MutableRefObject<HTMLDivElement>,
              videoRef as MutableRefObject<HTMLVideoElement>,
            )
          ) : (
            <WithPreview
              title={t('common.preview')}
              modalContent={
                <Carousel
                  onUpdate={onUpdate}
                  library={library}
                  images={[
                    ...getUrlsFromContent(library?.posters),
                    ...getUrlsFromContent(library?.content),
                    ...getUrlsFromContent(library?.videos),
                    ...getUrlsFromContent(library?.fullscreen),
                  ]}
                />
              }
            >
              <div style={{ position: 'relative' }} ref={divRef}>
                <img
                  style={{
                    maxHeight: '100%',
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    margin: 'auto',
                    objectFit: 'contain',
                  }}
                  ref={ref}
                  className={clsx('card-img-top w-100')}
                  src={allTopicSku.length > 0 ? picturePreview : noPreview}
                  alt="library preview"
                  onError={(e) => {
                    e.currentTarget.src = noPreview;
                  }}
                />
              </div>
            </WithPreview>
          )}
        </span>

        <div
          className={
            library.category.type === LibraryCategoryType.Free
              ? 'card-body px-4 pt-7 pb-6 h-200'
              : 'card-body px-4 pt-2 pb-6 h-200'
          }
        >
          <div
            className={
              library.category.type === LibraryCategoryType.Free
                ? ''
                : styles.textHeight
            }
          >
            {text ? (
              <h5
                ref={hTagTitleRef}
                className={
                  library.category.type === LibraryCategoryType.Free
                    ? clsx('card-title text-center', styles.freeSkuText)
                    : clsx('card-title text-center', styles.skuText)
                }
              >
                {text}
              </h5>
            ) : (
              <div
                style={
                  library.category.type === LibraryCategoryType.Free
                    ? { height: '37.75px', marginBottom: '0.5rem' }
                    : { height: '15.73px', marginBottom: '0.5rem' }
                }
              />
            )}

            {roles.includes(UserRole.SuperAdmin) &&
              (sku ? (
                <h5
                  ref={hTagSkuRef}
                  className={
                    // library.category.type === LibraryCategoryType.Free
                    //   ? clsx('card-title text-center', styles.freeSkuText)
                    //   :
                    clsx('card-title text-center', styles.skuText)
                  }
                >
                  {sku}
                </h5>
              ) : (
                <div style={{ height: '15.73px', marginBottom: '0.5rem' }} />
              ))}
            <p
              ref={pLangRef}
              className={
                library.category.type === LibraryCategoryType.Free
                  ? 'card-text text-center text-capitalize'
                  : 'card-text text-center mb-10 text-capitalize'
              }
            >
              {t(languagesTranslation[lang])}
            </p>
          </div>
          {!roles.includes(UserRole.SuperAdmin) &&
            library.category.type === LibraryCategoryType.Premium && (
              <div className="d-flex justify-content-center mb-4">
                <Logo className={styles.img} />
                <QuickClick library={library}>
                  <span className={clsx('ms-3', styles.link)}>
                    {t('library.indigo.card.quick_click')}
                  </span>
                </QuickClick>
              </div>
            )}

          {library?.isDownloadable && library?.attachedTemplate ? (
            <>
              <a
                href={library.attachedTemplate}
                // className="btn btn-primary d-flex align-items-center w-100 justify-content-center"
                className={clsx(
                  'btn btn-outline-primary d-flex align-items-center w-100 justify-content-center',
                  styles.reverseButtonDesign,
                )}
              >
                <i className={clsx('fa fa-download')} />
                <p className="mb-0 fw-boldest">
                  {t('library.indigo.card.download')}
                </p>
              </a>
            </>
          ) : (
            <>
              {allTopicSku?.length === 1 ? (
                <>
                  <span
                    tabIndex={-1}
                    onKeyPress={() => {}}
                    role="button"
                    className="btn btn-primary d-flex align-items-center w-100 justify-content-center"
                    onClick={handleOpenSchedule}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-3"
                    />
                    <p
                      ref={pAddToDeviceRef}
                      className={clsx('mb-0 font-weight-bolder')}
                    >
                      {t('library.indigo.card.add_to_device')}
                    </p>
                  </span>
                  <PublishModal
                    fullInfoSelectedItems={allTopicSku}
                    isVisible={publishModal}
                    onSubmit={handleScheduleLibrary}
                    onClose={handleCloseSchedule}
                    defaultValues={{ duration: '15' }}
                    withOrientation
                  />
                </>
              ) : (
                <>
                  <span
                    tabIndex={-1}
                    onKeyPress={() => {}}
                    role="button"
                    className="btn btn-primary d-flex align-items-center w-100 justify-content-center"
                    onClick={() => {
                      history.push(
                        library?.category?.parentCategory
                          ? routesConfig.indigoLibraryUnderSubCategory.route
                              .replace(':id', id)
                              .replace(
                                ':subCategoryName',
                                library?.category?.parentCategory?.name,
                              )
                              .replace(':categoryName', library?.category?.name)
                          : routesConfig.indigoLibrary.route
                              .replace(':id', id)
                              .replace(
                                ':categoryName',
                                library?.category?.name,
                              ),
                      );
                      // to={routesConfig.indigoLibrary.route.replace(':id', id)}
                    }}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-3"
                    />
                    <p
                      ref={pAddToDeviceRef}
                      className={clsx('mb-0 font-weight-bolder')}
                    >
                      {t('library.indigo.card.add_to_device')}
                    </p>
                  </span>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
