import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogSessionInterface } from '../../../store/reducers/userData/userDataState.interface';
import { formatDateString } from '../../../utils/dateTime';
import styles from '../myAccount.module.scss';

export default ({
  children,
  fullWidth = false,
  withoutTitle = false,
  isLoading = false,
  loginsessions,
}: {
  children?: React.ReactNode;
  fullWidth?: boolean;
  withoutTitle?: boolean;
  isLoading?: boolean;
  loginsessions: Array<LogSessionInterface>;
}) => {
  const { t } = useTranslation();

  const formatDate = formatDateString({
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    // minute: '2-digit',
    // hour: 'numeric',
    hour12: true,
  });
  return (
    <div className={`${!fullWidth && 'd-md-w-100 col-lg-6'} flex-grow-1`}>
      {children}
      <div
        className={`${styles.tableWrapper} ${styles.tableRight} table-responsive col-12 bg-white`}
      >
        <div className="table-responsive">
          {!withoutTitle && (
            <h2 className="fw-normal mx-5">
              {t('my_account.right_table.title')}
            </h2>
          )}
          <table className="table table-row-gray-250 gs-0 gy-5 gx-15">
            <thead className="bg-light">
              <tr className="fw-bolder text-muted text-left">
                <th className="px-5">{t('my_account.right_table.location')}</th>
                <th className="px-5">{t('my_account.right_table.dates')}</th>
                <th className="px-5">{t('my_account.right_table.time')}</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                loginsessions?.map((session) => (
                  <tr
                    key={`${session.ipAddress}${session.createdAt}`}
                    className="fw-bolder text-muted text-left"
                  >
                    <th className="px-5">{session.timeZone}</th>
                    <th className="px-5">{formatDate(session.createdAt)}</th>
                    <th className="px-5">{session.loginsCount}</th>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
