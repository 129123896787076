import DayOfWeek from '../../../enums/dayOfWeek.enum';
import FacebookPreview from '../../../enums/facebookPreview.enum';
import InstagramFeedType from '../../../enums/instagramFeedType.enum';
import Orientation from '../../../enums/orientation.enum';
import PlacementsEnum from '../../../enums/placements.enum';

export type AppType =
  | 'youtube'
  | 'html'
  | 'googleSheet'
  | 'rss'
  | 'googleDocs'
  | 'googleSlides'
  | 'googleCalendar'
  | 'powerBi'
  | 'Twitter'
  | 'Instagram'
  | 'Facebook'
  | 'canva'
  | 'QrCode'
  | 'count-up'
  | 'count-down'
  | 'days-safe'
  | 'msTeams'
  | 'live-stream';

export interface AppFeedSchema {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  type: AppType;
  placement: Placement | PlacementsEnum;
  dependency: AppDependency;
}

export interface AppDependencyConfig {
  youtubeFeedUrl?: string;
  youtubeFeedName?: string;
  youtubeFeedDurationInSeconds?: number;
  googleSheetRefreshRateInSeconds?: number;
  googleDocRefreshRateInSeconds?: number;
  googleCalendarRefreshRateInSeconds?: number;
  googleSlidesRefreshRateInSeconds?: number;
  htmlRefreshRateInSeconds?: number;
  powerBiRefreshRateInSeconds?: number;
  powerBiUtilityAppId?: string;
  powerBiRecording?: string;
  htmlFeedUrl?: string;
  rssFeedUrl?: string;
  feedsData?: string[];
  googleSheetFeedUrl?: string;
  googleDocFeedUrl?: string;
  googleCalendarFeedUrl?: string;
  googleSlidesFeedUrl?: string;
  powerBiFeedUrl?: string;
  canvaAssetUrl?: string;
  TwitterFeedUrl?: string;
  TwitterFeedType?: string;
  TwitterUserName?: string;
  twitterRefreshRateInSeconds?: number;
  twitterFeedsCount?: number;
  TwitterAccount?: string;
  InstagramFeedUrl?: string;
  InstagramFeedType?: string;
  InstagramUserName?: string;
  InstagramRefreshRateInSeconds?: number;
  InstagramFeedsCount?: number;
  instagramUser?: string[];
  instagramPreview?: InstagramFeedType;
  facebookPreview?: FacebookPreview;
  Accountname?: string;
  TwitterPreview?: string;
  Theme?: string;
  facebookUser?: string[];
  pageName?: string;
  pagesInfos?: string[];
  facebookFeeds?: string[];
  feeds?: string[];
  AccessToken?: string;
  expiresIn?: string;
  qrCodeMessage?: string;
  qrCodeBgColor?: string;
  qrCodeTxtColor?: string;
  qrCodeImage?: string;
  base64FileType?: string;
  dotsStyle?: string;
  dotsColor?: string;
  cornersSquareStyle?: string;
  cornersSquareColor?: string;
  cornersDotStyle?: string;
  cornersDotColor?: string;
  qrCodeSubTitle?: string;
  icon?: string;
  iconColor?: string;
  iconSize?: string;
  qrCodeTitle?: string;
  uploadedQrCode?: boolean;
  qrCodeIconImage?: string;
  microsoftUser?: string;
  channelName?: string;
  teamName?: string;
  channelId?: string;
  teamId?: string;
  msTeamsFeeds?: any;
  displayNumber?: number;
  msTeamsPreview?: string;
  RefreshToken?: string;
  msUserId?: string;
  mail?: string;
  removeOldPosts?: number;
  displayTimePerPost?: number;
  showAuthor?: boolean;
  displayPostDate?: boolean;
  corpImagetoFit?: boolean;
  muteVideo?: boolean;
  appGuiid?: string;
  startTime?: string;
  startTimer?: Date;
  style?: string;
  backgroundColor?: string;
  textColor?: string;
  text?: string;
  countTime?: Date;
  completionMessage?: string;
  completionIcon?: string;
  bottomText?: string;
  beginningNumber?: string;
  showDays?: boolean;
  liveStreamFeedUrl?: string;
}

export interface AlertDesign {}

export interface Country {}

export interface AppDependency {
  id: string;
  updatedAt: string;
  createdAt: string;
  htmlContent: string | null;
  type: AppType;
  config?: AppDependencyConfig;
  url?: string;
  alertDesign?: AlertDesign;
  country?: Country;
  previewUrl?: string | null;
}

export enum SortBy {
  ID = 'id',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

// export interface PaginatedFeedLink {
//   first: string;
//   previous: string;
//   next: string;
//   last: string;
// }

export interface PaginatedFeedMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  // totalPages: number;
  currentPage: number;
}

export interface PaginatedFeeds<T> {
  items: T[];
  // links: PaginatedFeedLink;
  meta: PaginatedFeedMeta;
}

export interface ErrorResponse {
  message: string;
  error: string;
  statusCode: number;
}

export interface GetFeedsRequestQueryParams {
  search: string;
  limit: number;
  page: number;
  appType: AppType;
  sortOrder: SortOrder;
  sortBy: SortBy;
}

export type Placement = 'LANDSCAPE' | 'PORTRAIT' | 'fullScreen';

// Payload structure with required and optional field is based on `POST /apps/{appType}/{appId}` (Swagger) docs
// see: `CreateContentModel` (server-side)
export interface ScheduleFeedRequest {
  appId: string;
  appType: AppType;
  name: string;
  startDate: string;
  endDate: string;
  duration: string;
  isLocked?: boolean;
  isOrdered?: boolean;
  isSeparated?: boolean;
  playlistIds?: string[];
  zoneId?: Orientation;
  tags?: string[];
  weekDays?: DayOfWeek[];
}

export interface CreateFeedRequest {
  name: string;
  config: AppDependencyConfig | null;
  zoneId?: Orientation;
  isScheduled?: boolean;
}

export interface EditFeedRequest extends CreateFeedRequest {
  id: string;
}

export interface DeleteFeedRequest {
  id: string;
}
