import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap-v5';

export enum Placement {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

export default ({
  children,
  text,
  placement,
  className,
  hide,
}: {
  children: React.ReactNode;
  text: string;
  placement?: Placement;
  className?: string;
  hide?: boolean;
}) => (
  <OverlayTrigger
    placement={placement}
    overlay={
      <Tooltip id={`tooltip-${placement}`} className="lh-1" hidden={hide}>
        {text}
      </Tooltip>
    }
  >
    <div className={className || ''}>{children}</div>
  </OverlayTrigger>
);
