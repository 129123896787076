import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import api from '../../../api';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import { UserRole } from '../../../enums/userRole.enum';
import GlobalState from '../../../store/reducers/globalState.interface';
import { readAllFromQueryParams } from '../../../utils/queryParams';

const SelectUserGroups = ({
  onChange,
  initialValue,
}: {
  onChange: (selectedValue: SelectedValue[]) => void;
  initialValue?: any;
}) => {
  const [options, setOptions] = useState<any>([]);
  const { id: deviceGroupId } = useParams<{ id: string }>();
  const { search } = useLocation();
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const isSuperAdmin = roles.includes(UserRole.SuperAdmin);
  const loadOptions = async () => {
    const { items } = isSuperAdmin
      ? await api.usersGroups.getDeviceGroupUserGroupsListAdmin({
          exclude: true,
          deviceGroupId,
          ...readAllFromQueryParams(search),
        })
      : await api.devicesGroups.getDeviceGroupUserGroupsList(
          { exclude: true, ...readAllFromQueryParams(search) },
          deviceGroupId,
        );

    const getOptions = items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    setOptions(getOptions);
  };

  const handleFilterOptions = (filteredOptions: any, input: string) => {
    return filteredOptions.filter((option: { label: string }) =>
      option.label.toLowerCase().includes(input.toLowerCase()),
    );
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <MultiSelect
      options={options}
      value={initialValue}
      onChange={onChange}
      filterOptions={handleFilterOptions}
      labelledBy="Selects Devices"
    />
  );
};

SelectUserGroups.defaultProps = {
  initialValue: [],
};

export default SelectUserGroups;
