import { useSelector } from 'react-redux';
import { UserRole } from '../../enums/userRole.enum';
import GlobalState from '../../store/reducers/globalState.interface';
import User from '../../interfaces/user/user.interface';

export const useAccessByRole = (user?: User) => {
  const ownerRoles = user?.roles;

  const userConnectRoles = useSelector(
    (state: GlobalState) => state.userData.user.roles,
  );
  const sortedUserConnectRoles = Object.values(userConnectRoles);
  if (sortedUserConnectRoles?.includes(UserRole.SuperAdmin)) return true;
  if (sortedUserConnectRoles?.includes(UserRole.AccountOwner)) return true;

  if (!user) return false;

  const sortedUserRoles = Object.values(UserRole);

  const getTopUserConnectRole = () => {
    return sortedUserConnectRoles.sort((a, b) => {
      return sortedUserRoles.indexOf(a) - sortedUserRoles.indexOf(b);
    })?.[0];
  };
  if (!getTopUserConnectRole) {
    return false;
  }
  const getTopOwnerRole = () => {
    return ownerRoles?.sort((a, b) => {
      return sortedUserRoles.indexOf(a) - sortedUserRoles.indexOf(b);
    })?.[0];
  };
  if (!getTopOwnerRole) {
    return false;
  }

  const compareUserConnectToOwner = () => {
    return (
      Object.values(UserRole).indexOf(getTopUserConnectRole()) <=
      Object.values(UserRole).indexOf(getTopOwnerRole() as UserRole)
    );
  };
  return compareUserConnectToOwner();
  //   if (!user) return false;
};
