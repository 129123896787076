import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ title }: { title: string }) => {
  return (
    <Helmet titleTemplate="Indigo Vizual - %s" defaultTitle="Indigo Vizual">
      <title>{title}</title>
    </Helmet>
  );
};
