import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import YouTubeReactPlayer from 'react-player/youtube';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap-v5';
import { useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';
import * as React from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import Modal from '../../../components/Modal';
import FormErrorMessage from '../../../components/FormErrorMessage';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import { CreateYoutubeFeedRequest } from '../../../store/types/apps/youtubeFeed';
import PublishModal from '../../../components/PublishModal';
import { createScheduleYoutubeFeedActionCreator } from '../../../store/actions/apps';
import GlobalState from '../../../store/reducers/globalState.interface';
import Orientation from '../../../enums/orientation.enum';
import WithSpinner from '../../../components/WithSpinner';

type CreateFeedRequestFormMutated = Omit<
  CreateYoutubeFeedRequest,
  'config' | 'placement'
>;

interface CreateFeedRequestFormPayload extends CreateFeedRequestFormMutated {
  youtubeURL: string;
}

export interface CreateFeedModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (createFeedRequest: CreateYoutubeFeedRequest) => void;
  title: string;
  onSetIsScheduleandCreateSuccess(bool: boolean): void;
  widthDefaultSize: boolean;
  previewHeight?: number;
}

const CreateFeedModal = ({
  show,
  onHide,
  title,
  onSubmit,
  onSetIsScheduleandCreateSuccess,
  widthDefaultSize,
  previewHeight = 400,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();
  const [videoName, setVideoName] = React.useState<string>('');
  const [videoDurationInSec, setVideoDurationInSec] = React.useState<any>(0);

  const [videoIsFetching, setVideoIsFetching] = React.useState<boolean>(false);
  const [isSubmited, setIsSubmited] = React.useState<boolean>(false);
  const [isVisibleSchedule, setisVisibleSchedule] =
    React.useState<boolean>(false);
  const initialValues: CreateFeedRequestFormPayload = {
    name: videoName,
    youtubeURL: '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().defined(),
    youtubeURL: Yup.string()
      .matches(
        /^(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?$/,
        'Please provide a valid YouTube video URL.',
      )
      .defined(),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    getValues,
  } = useForm<CreateFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });
  const youtubeFeedSelector = useSelector(
    (state: GlobalState) => state.youtubeFeeds,
  );
  const dispatch = useDispatch();

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const handleFeedNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFeedName(value),
    [setFeedName],
  );

  const [youtubeURL, setYoutubeURL] = useStateWithHookForm<
    CreateFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'youtubeURL' }, initialValues.youtubeURL);

  const handleVideoDurationInSec = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (videoDurationInSec: number) => {
      setVideoDurationInSec(videoDurationInSec);
    },
    [],
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleVideoName = React.useCallback((videoName: string) => {
    setVideoName(videoName);
  }, []);

  const handleVideoIsFetching = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (videoIsFetching: boolean) => setVideoIsFetching(videoIsFetching),
    [],
  );
  const handleYoutubeURLChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (value === '') {
        handleVideoIsFetching(false);
      } else {
        handleVideoIsFetching(true);
      }

      setYoutubeURL(value);
    },
    [handleVideoIsFetching, setYoutubeURL],
  );

  const handleOnReadyEvent = React.useCallback(
    async (player: ReactPlayer) => {
      const durationInSec = player.getDuration();

      const uninterceptedAxiosInstance = axios.create();

      const {
        data: { title: videoTitle },
      } = await uninterceptedAxiosInstance({
        method: 'GET',
        url: 'https://noembed.com/embed',
        params: {
          url: youtubeURL,
        },
      });

      handleVideoDurationInSec(durationInSec);
      if (videoName === '') handleVideoName(videoTitle);
      if (feedName === '') setFeedName(videoTitle);
      handleVideoIsFetching(false);
    },
    [
      youtubeURL,
      handleVideoDurationInSec,
      handleVideoName,
      handleVideoIsFetching,
    ],
  );

  const handleOnSubmit = async (data: CreateFeedRequestFormPayload) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { name, youtubeURL } = data;

    const createFileRequest: CreateYoutubeFeedRequest = {
      name: name || videoName,
      config: {
        youtubeFeedUrl: youtubeURL,
        youtubeFeedName: videoName,
        youtubeFeedDurationInSeconds: videoDurationInSec,
      },
      zoneId: Orientation.Landscape,
    };
    setIsSubmited(true);
    onSubmit(createFileRequest);
    setVideoDurationInSec(
      createFileRequest.config!.youtubeFeedDurationInSeconds,
    );
  };

  const submitAndSchedule = () => {
    onSetIsScheduleandCreateSuccess(true);
    setisVisibleSchedule(true);
  };

  const videoDuration = React.useMemo(
    () => new Date(1000 * videoDurationInSec).toISOString().slice(11, 19),

    [videoDurationInSec],
  );
  const onCloseSchedule = () => {
    setisVisibleSchedule(false);
    onHide();
  };

  const onSubmitAndSchedule = React.useCallback(
    (data) => {
      const createFeedData: CreateFeedRequestFormPayload = getValues();
      dispatch(
        createScheduleYoutubeFeedActionCreator({
          youtubeFeed: {
            name: createFeedData.name || videoName,
            config: {
              youtubeFeedUrl: createFeedData.youtubeURL,
              youtubeFeedName: createFeedData.name,
              youtubeFeedDurationInSeconds: data.duration,
            },
            zoneId: data.zoneId,
          },
          scheduleFeed: data,
        }),
      );

      setisVisibleSchedule(false);
      onHide();
    },
    [
      youtubeFeedSelector.isScheduleYoutubeFeedSuccess,
      youtubeFeedSelector.createYoutubeFeedLoading,
      youtubeFeedSelector.scheduleYoutubeFeedLoading,
    ],
  );
  React.useEffect(() => {
    initialValues.name = videoName;
  }, [videoName]);
  return (
    <>
      {isVisibleSchedule ? (
        <PublishModal
          onClose={onCloseSchedule}
          onSubmit={onSubmitAndSchedule}
          isVisible={isVisibleSchedule}
          scheduleYoutubeApp
          defaultValues={{
            isOrdered: true,
            duration: videoDurationInSec.toString(),
          }}
          skipOrder
          skipOrientationAuto
        />
      ) : (
        <Modal
          title={title}
          show={show}
          onHide={onHide}
          widthDefaultSize={widthDefaultSize}
          withFooter={false}
          floatLeft
          dialogClassName="modal-dialog-centered h-auto"
        >
          <WithSpinner isLoading={isSubmited} className="min-h-400px" size="md">
            <form
              onSubmit={handleSubmit(handleOnSubmit)}
              id="form"
              className="d-flex flex-column justify-content-between"
            >
              <div className="d-flex flex-row p-4 w-100">
                <div className="d-flex flex-column mx-2 w-100">
                  <label htmlFor="name" className="text-dark fw-bolder my-1">
                    {t('apps.youtube.labels.title')}
                  </label>
                  <input
                    name="name"
                    id="name"
                    defaultValue={videoName}
                    onChange={handleFeedNameChange}
                    className="form-control form-control-solid"
                    placeholder="Type here"
                    type="text"
                  />
                  <FormErrorMessage
                    name="name"
                    errors={errors}
                    className="my-1 px-2"
                  />
                </div>

                <div className="d-flex flex-column mx-2 w-100">
                  <label
                    htmlFor="youtubeURL"
                    className="text-dark fw-bolder my-1 required"
                  >
                    {t('apps.youtube.labels.enterUrl')}
                  </label>
                  <input
                    name="youtubeURL"
                    id="youtubeURL"
                    value={youtubeURL}
                    onChange={handleYoutubeURLChange}
                    className="form-control form-control-solid"
                  />
                  <FormErrorMessage
                    name="youtubeURL"
                    errors={errors}
                    className="my-1 px-2"
                  />
                </div>
              </div>

              <div className="d-flex flex-column   flex-center  p-4 w-100">
                {!errors.youtubeURL && youtubeURL !== '' ? (
                  <div className="d-flex flex-column align-self-center w-100 h-100">
                    {/* simulating a loading state for the player (hacky) and display duration and name fields when it's loaded  */}

                    {videoIsFetching ? (
                      <div className="d-flex flex-center w-100 h-100 p-4">
                        <Spinner role="status" animation="border" />
                      </div>
                    ) : null}

                    <YouTubeReactPlayer
                      url={youtubeURL}
                      width="100%"
                      height={`${previewHeight}px`}
                      controls
                      onReady={handleOnReadyEvent}
                    />

                    {videoIsFetching ? null : (
                      <div className="d-flex flex-row my-2 w-100">
                        <div className="d-flex flex-column mx-2 w-100">
                          <span className="text-dark fw-bolder my-1">
                            {t('apps.youtube.labels.videoName')}
                          </span>
                          <span>{videoName}</span>
                        </div>
                        <div className="d-flex flex-column mx-2 w-100">
                          <span className="text-dark fw-bolder my-1">
                            {t('apps.youtube.labels.duration')}
                          </span>
                          <span>{videoDuration}</span>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-center w-100">
                    <img
                      alt="No items found"
                      src="/media/illustrations/sketchy-1/5.png"
                      className="d-flex flex-column align-self-center w-25"
                    />
                    <div className="fs-1">
                      {t('apps.youtube.labels.noPreview')}.
                    </div>
                    <div className="fs-6">
                      {t('apps.youtube.labels.youtubeURL')}
                    </div>
                  </div>
                )}
              </div>
            </form>

            <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />

            <div className="d-flex">
              <div className="pe-5 me-auto">
                <button
                  type="button"
                  className="btn btn-light-primary"
                  onClick={onHide}
                >
                  Cancel
                </button>
              </div>
              <button
                id="submit"
                type="button"
                className="btn btn-primary m-1"
                disabled={isSubmited}
                onClick={handleSubmit(handleOnSubmit)}
              >
                Create
              </button>
              <button
                type="button"
                className="btn btn-primary m-1"
                onClick={handleSubmit(submitAndSchedule)}
              >
                Create and schedule
              </button>
            </div>
          </WithSpinner>
        </Modal>
      )}
    </>
  );
};

export default CreateFeedModal;
