import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import GlobalState from '../../../../store/reducers/globalState.interface';
import App from '../../../../interfaces/app.interface';
import PowerBiUtility from '../../../../interfaces/apps/powerBiUtility.interface';
import Modal from '../../../../components/Modal';
import Yup from '../../../../utils/validation';
import api from '../../../../api';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import AppType from '../../../../enums/appType.enum';
import PowerBiModal from '../../../../components/Apps/PowerBi/CreatePowerBi';
import useModalState from '../../../../utils/hooks/useModalState';
import PublishModal from '../../../../components/PublishModal';
import ScheduleContentRequestData from '../../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import IContent from '../../../../interfaces/content.interface';
import PowerBiScheduleUtility from '../../../../interfaces/apps/powerBiUtilitySchedule.interface';
import { getUserData } from '../../../../store/actions/userData';

const CreatePowerBi = ({
  children,
  onUpdate,
}: {
  children: React.ReactNode;
  onUpdate: () => void;
}) => {
  const user = useSelector((state: GlobalState) => state.userData.user);
  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [app, setApp] = useState<App>({} as App);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const open = () => setModalShown(true);
  const close = () => setModalShown(false);
  const [isVisible, handleOpen, handleClose] = useModalState(false);

  const handleScheduleSubmit = async (data: ScheduleContentRequestData) => {
    try {
      const result: IContent = await api.apps.scheduleApp(
        app.type,
        app.id,
        data,
      );
      const contentId = result.id;
      const utilityId = result.app?.dependency.config?.powerBiUtilityAppId;
      if (contentId && utilityId) {
        const payload: PowerBiScheduleUtility = {
          _id: utilityId,
          contentId,
        };
        await api.powerBi.addToPlaylist(payload);
      }
      toast.success(i18next.t<string>('common.app_scheduled'));
    } finally {
      handleClose();
      onUpdate();
    }
  };

  const handleSubmit = async (data: CreateAppRequestData) => {
    let id;
    setLoading(true);
    try {
      const utilityPayload: PowerBiUtility = {
        embedURL: data.config.powerBiFeedUrl,
        userId: user.id,
        refreshRate: data.config.powerBiRefreshRateInSeconds,
        signTitle: data.name,
        powerbiRecording: data.config.powerBiRecording,
      };
      const doc: PowerBiUtility = await api.powerBi.createApp(utilityPayload);
      // eslint-disable-next-line no-underscore-dangle
      id = doc._id;
    } finally {
      const payload = { ...data };
      payload.config.powerBiUtilityAppId = id;
      const result: App = await api.apps.createApp(AppType.PowerBi, payload);
      setApp(result);
      dispatch(getUserData());
      close();
      if (onUpdate) {
        onUpdate();
      }
      if (data.isScheduled) {
        handleOpen();
      }
      toast.success(i18next.t<string>('apps.powerBi.powerBi_added'));
      setLoading(false);
    }
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('apps.powerBi.createNewPowerBi')}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
      >
        <PowerBiModal
          onClose={close}
          isLoading={loading}
          onSubmit={handleSubmit}
        />
      </Modal>
      <PublishModal
        onClose={handleClose}
        onSubmit={handleScheduleSubmit}
        isVisible={isVisible}
        skipOrder
        isPowerBiPublished
        skipOrientationAuto
        infoValidationSchema={{
          orientation: Yup.string().required(t('required')),
          duration: Yup.number().required(t('required')).min(1),
          startDate: Yup.date(),
          endDate: Yup.date().when('startDate', {
            is: (startDate: any) => !!startDate,
            then: Yup.date().min(
              Yup.ref('startDate'),
              t('common.validation.endDate'),
            ),
          }),
          isLocked: Yup.boolean(),
        }}
      />
    </>
  );
};

export default CreatePowerBi;
