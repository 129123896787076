export default [
  {
    value: 1,
    label: 'User Defined',
  },
  {
    value: 2,
    label: 'Top Stories',
  },
  {
    value: 3,
    label: 'International News',
  },
  {
    value: 4,
    label: 'U.S. News',
  },
  {
    value: 5,
    label: 'Sports',
  },
  {
    value: 6,
    label: 'Entertainment',
  },
  {
    value: 7,
    label: 'Politics',
  },
  {
    value: 8,
    label: 'Technology',
  },
  {
    value: 9,
    label: 'Health',
  },
  {
    value: 10,
    label: 'Finance',
  },
];
