import clsx from 'clsx';
import * as React from 'react';
import { RSSFeedSchema } from '../../../../store/types/apps/rssFeed';
import { disableRightClickIframe } from '../../../../utils/common';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import Modal from '../../../Modal';
import Spinner from '../../../Spinner';
import styles from './rssPreview.module.scss';

export interface PreviewFeedModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  selectedRssFeedSchema: RSSFeedSchema;
  children?: React.ReactNode;
}

const PreviewFeedModal = ({
  show,
  onHide,
  title,
  selectedRssFeedSchema,
  children,
}: PreviewFeedModalProps) => {
  const [fullscreen, setFullscreen] = React.useState<
    | true
    | 'sm-down'
    | 'md-down'
    | 'lg-down'
    | 'xl-down'
    | 'xxl-down'
    | undefined
  >();
  const [windowWidth] = useWindowSize();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { name: feedTitle, id } = React.useMemo(
    () => selectedRssFeedSchema,
    [selectedRssFeedSchema],
  );
  const [url, setUrl] = React.useState('');

  const handleIframeLoad = () => setIsLoading(false);

  const handleHide = () => {
    setIsLoading(true);
    setFullscreen(undefined);
    onHide();
    setFullscreen(undefined);
  };
  const handleShow = () => {
    setFullscreen(true);
    if (id) {
      const targetEl = document.getElementsByClassName(id.toString());
      const setFull = targetEl.length && targetEl[0];
      if (setFull && setFull.requestFullscreen) {
        setFull.requestFullscreen();
      }
    }
  };
  React.useEffect(() => {
    if (!id) return;
    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?appId=${id}&ratio=${windowWidth}&fullsceen=${fullscreen}`,
    );
  }, [windowWidth, id, fullscreen]);

  React.useEffect(() => {
    const exitHandler = () => {
      if (
        document &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setFullscreen(undefined);
      }
    };

    if (document.addEventListener) {
      console.log('add event listener');
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }

    return () => {
      if (document.removeEventListener) {
        console.log('remove event listener');
        document.removeEventListener('fullscreenchange', exitHandler, false);
        document.removeEventListener('mozfullscreenchange', exitHandler, false);
        document.removeEventListener('MSFullscreenChange', exitHandler, false);
        document.removeEventListener(
          'webkitfullscreenchange',
          exitHandler,
          false,
        );
      }
    };
  }, []);

  React.useEffect(() => {
    disableRightClickIframe(feedTitle);
  }, []);

  return (
    <>
      {children}
      <Modal
        show={show}
        onHide={handleHide}
        handleShow={handleShow}
        withFooter={false}
        widthDefaultSize={false}
        fullscreen={fullscreen}
        fullScreenPreview
        title={title}
        bodyClassName={id}
        dialogClassName={styles.dialog}
      >
        {isLoading ? <Spinner size="md" backdropClassName="bg-white" /> : null}

        <div id={id}>
          <iframe
            title={feedTitle}
            src={url}
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            style={{ height: `${fullscreen ? '100vh' : '500px'}` }}
            className={clsx('w-100  rounded', styles.preview)}
            onLoad={handleIframeLoad}
          />
        </div>
      </Modal>
    </>
  );
};

export default PreviewFeedModal;
