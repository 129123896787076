import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DateTime } from 'luxon';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';

import { CreateDaysSafeFeedRequest } from '../../../../store/types/apps/daysSafeFeed';
import TextInput from '../../../TextInput';

// import Yup from '../../../../utils/validation';
// datsSafe11Preview

export interface CreateFeedModalProps {
  onSubmit: (data: CreateDaysSafeFeedRequest) => void;
  daysSafeApp?: App;
  onClose?: () => void;
  isLoading: boolean;
}

export default ({
  onSubmit,
  onClose,
  isLoading,
  daysSafeApp = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();

  // const validationSchema = Yup.object({
  //   // name: Yup.string()
  //   //   .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
  //   //   .defined(),
  //   // text: Yup.string().required(t('apps.daysSafe.required')),
  //   // bottomText: Yup.string().required(t('apps.daysSafe.required')),
  // }).defined();

  const [beginningNumber, setBeginningNumber] = useState('0');

  // const getDateFromNumber = (days: number) => {
  //   const changedDays = days > 9999 ? 9999 : days;
  //   const currentDateMinusDays = moment()
  //     .subtract(changedDays, 'days')
  //     .toDate();

  //   return currentDateMinusDays;
  // };

  const getDateFromNumberLuxon = (safeDays: number) => {
    // Get the current date and time in UTC
    const now = DateTime.utc();

    // Calculate the date of the last incident
    const lastIncidentDate = now.minus({ days: safeDays });

    // Convert this to the date in UTC-12
    const lastIncidentDateInUtcMinus12 = lastIncidentDate.setZone('Etc/GMT+12');
    return lastIncidentDateInUtcMinus12;
  };

  const getNumberOfDaysSafe = () => {
    const daysNumber = moment().diff(
      daysSafeApp?.dependency?.config?.startTimer,
      'days',
    );

    return daysNumber;
  };

  const handleOnSubmit = async () => {
    const dateStartTimer = getDateFromNumberLuxon(Number(beginningNumber));

    const CreateDaysSafeFeedRequestAdd: CreateDaysSafeFeedRequest = {
      ...daysSafeApp,
      config: {
        ...daysSafeApp?.dependency?.config,
        startTimer: dateStartTimer,
      },
    };

    onSubmit(CreateDaysSafeFeedRequestAdd);
  };

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <form
        onSubmit={handleOnSubmit}
        id="form"
        className="d-flex flex-column justify-content-between"
      >
        <div className="d-flex flex-row p-4 w-100">
          <div className="d-flex flex-column mx-2 w-100">
            <p className="mt-2 mb-0">
              <label
                htmlFor="beginningNumber"
                className="col-form-label text-lg-end fw-bolder fs-6"
              >
                {t('apps.daysSafe.newNumber')}
              </label>
              <TextInput
                name="text"
                id="text"
                value={beginningNumber ?? getNumberOfDaysSafe()}
                onChange={setBeginningNumber}
                className="form-control form-control-solid"
                placeholder="Type here"
                type="number"
                min={0}
              />
            </p>
          </div>
        </div>
        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-white text-primary"
            onClick={onClose}
          >
            {t('common.cancel')}
          </button>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {t('common.save')}
            </button>
          </div>
        </div>
      </form>
    </WithSpinner>
  );
};
