import { Col, Divider, Row } from 'antd';
import React, { SetStateAction } from 'react';
import BarChart from '../../../components/Analytics/BarChart/index';
// import MostUsedApps from '../../../components/AdminPanel/Dashboard/Cards/MostUsedApps';
import api from '../../../api';
import WithSpinner from '../../../components/WithSpinner';
import MostUsedAppsTable from '../../../components/Analytics/MostUsedAppsTable';

export default () => {
  const [mostUsedApps, setMostUsedApps] = React.useState([]);
  const [AppsLoading, setAppsLoading] = React.useState<boolean>(true);
  /**
   *  isLoading,
  sortQueryField,
  orderQueryField,
   */

  const appsLabel = [
    {
      id: '0',
      label: 'Severe Weather',
    },
    {
      id: '1',
      label: 'Emergency Alert',
    },
    {
      id: '2',
      label: 'Ticker',
    },
    {
      id: '3',
      label: 'Youtube',
    },
    {
      id: '4',
      label: 'HTML5',
    },
    {
      id: '5',
      label: 'Weather',
    },
    {
      id: '6',
      label: 'PowerBi',
    },
    {
      id: '7',
      label: 'RSS',
    },

    {
      id: '8',
      label: 'Google Sheets',
    },
    {
      id: '9',
      label: 'Google Docs',
    },
    {
      id: '10',
      label: 'Google Slides',
    },
    {
      id: '11',
      label: 'Google calendar',
    },
    {
      id: '12',
      label: 'Canva',
    },

    {
      id: '13',
      label: 'Twitter',
    },
    {
      id: '14',
      label: 'Instagram',
    },
    {
      id: '15',
      label: 'Facebook',
    },

    {
      id: '16',
      label: 'QR Code',
    },
    {
      id: '17',
      label: 'MS Teams',
    },
    {
      id: '18',
      label: 'Count UP Timer',
    },
    {
      id: '19',
      label: 'Count Down Timer',
    },
    {
      id: '20',
      label: 'Count Down Timer',
    },

    {
      id: '20',
      label: 'Injury Free Counter',
    },

    {
      id: '21',
      label: 'Live Stream',
    },
  ];
  const getMostUsedApps = async () => {
    try {
      setAppsLoading(true);

      const apps = await api.dashboard.getMostUsedApps();
      const mapper = apps.map(
        // eslint-disable-next-line camelcase
        (_elem: { type: number; total_contents_count: string }) => {
          return {
            type: appsLabel[_elem.type].label,
            value: parseInt(_elem?.total_contents_count, 10),
          };
        },
      );
      setMostUsedApps(mapper as SetStateAction<never[]>);

      setAppsLoading(false);
    } catch (e) {
      setAppsLoading(false);
    }
  };

  React.useEffect(() => {
    getMostUsedApps();
  }, []);
  return (
    <>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        className=" p-auto m-auto"
        style={{ backgroundColor: 'white' }}
      >
        <Col className="gutter-row" span={24} style={{ padding: '20px' }}>
          <WithSpinner isLoading={AppsLoading}>
            <BarChart data={mostUsedApps} />
          </WithSpinner>
        </Col>
      </Row>
      <Divider />
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        className=" p-auto m-auto"
        style={{ backgroundColor: 'white' }}
      >
        <Col className="gutter-row" span={24}>
          <MostUsedAppsTable orderQueryField="App Name" sortQueryField="DESC" />
        </Col>
      </Row>
      <Divider />
    </>
  );
};
