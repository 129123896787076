import React from 'react';
import { useTranslation } from 'react-i18next';
import WithDeleteConfirm from '../../../components/WithDeleteConfirm';
import Template from '../../../interfaces/template.interface';
import Tooltip from '../../../components/Tooltip';
import api from '../../../api';

export default ({
  template: { id, name },
  children,
  onSuccess,
}: {
  template: Template;
  children?: React.ReactNode;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    api.templates.deleteTemplate(id).then(onSuccess);
  };

  return (
    <WithDeleteConfirm
      body={`${t('common.delete_confirmation.title')} ${t(
        'common.delete_confirmation.template',
      )} "${name}"?`}
      handleConfirm={handleConfirm}
    >
      {children ?? (
        <Tooltip text={t('common.remove')}>
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-trash" />
          </span>
        </Tooltip>
      )}
    </WithDeleteConfirm>
  );
};
