import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import api from '../../../../api';
import CreateLocations from '../../../../components/AdminPanel/CompanyLocations/LocationForm';
import CreateLocationRequestData from '../../../../api/endpoints/locations/interfaces/createLocationRequestData.interface';
import Modal from '../../../../components/Modal';
import Company from '../../../../interfaces/company.interface';
import User from '../../../../interfaces/user/user.interface';
import useIsAdmin from '../../../../utils/hooks/useIsAdmin';

const CreateLocation = ({
  company,
  children,
  onSuccess,
  companyId,
  ownerId,
  hasCompany,
  disabled = false,
}: {
  company?: Company;
  children: React.ReactNode;
  onSuccess?: () => void;
  companyId?: string;
  ownerId?: string;
  hasCompany?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User>();
  const [isAdmin] = useIsAdmin();

  const open = () => {
    if (!disabled) setModalShown(true);
  };

  const close = () => {
    setModalShown(false);
  };

  const handleSubmit = async (data: CreateLocationRequestData) => {
    setIsLoading(true);
    let payloadCompanyId = '';
    let payloadOwnerId;

    if (company) {
      payloadCompanyId = company.id;
      payloadOwnerId = company.owner.id;
    }

    if (companyId && ownerId) {
      payloadCompanyId = companyId;
      payloadOwnerId = ownerId;
    }

    if (data?.company) {
      payloadCompanyId = data?.company;
    }

    try {
      if (isAdmin) {
        await api.locations.createAdminLocation({
          ...data,
          companyId: payloadCompanyId,
          userId: payloadOwnerId ?? id,
        });
      } else {
        await api.locations.createLocation({
          ...data,
          companyId: payloadCompanyId,
          userId: payloadOwnerId ?? id,
        });
      }
      if (onSuccess) {
        onSuccess();
      }
    } finally {
      close();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!id) return;

    const getCurrentUser = async () => {
      const getUser = await api.users.getUser(id);
      setUser(getUser);
    };
    getCurrentUser();
  }, [id]);

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('locations.add_location')}
        show={modalShown}
        onHide={close}
        bodyClassName="py-5"
        dialogClassName="w-550px"
        withFooter={false}
      >
        <CreateLocations
          hasCompany={hasCompany}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          user={user}
        />
      </Modal>
    </>
  );
};

export default CreateLocation;
