import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import App from '../../../../../interfaces/app.interface';
import CheckBox from '../../../../CheckBox';
import Table from '../../../../Table';
import styles from './tablerow.module.scss';
import { formatDateString } from '../../../../../utils/dateTime';
import EditSevereWeather from '../../../../../containers/Apps/SevereWeather/EditSevereWeather';
import DeleteSevereWeather from '../../../../../containers/Apps/DeleteApp';
import severeWeatherPic from '../../../../../assets/images/severeWeathere/alert.png';
import PreviewFeedModal from '../../Preview/PreviewFeedModal';
import { AppDependency } from '../../../../../store/types/apps/globalTypes';
import PlacementsEnum from '../../../../../enums/placements.enum';
import PopOver from '../../../../PopOver';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({
  severeWeather,
  isChecked,
  onCheck,
  onUpdate,
}: {
  severeWeather: App;
  isChecked: boolean;
  onCheck: (id: string) => void;
  onUpdate: () => void;
}) => {
  const [showModalFeed, setShowModalFeed] = useState(false);
  const { t } = useTranslation();
  const displayPreview = () => setShowModalFeed(true);
  return (
    <Row>
      <Cell className="w-fit-content">
        <CheckBox
          name="weather"
          id={severeWeather.id}
          onChange={() => onCheck(severeWeather.id)}
          checked={isChecked}
          className="mt-1"
        />
      </Cell>
      <Cell isEmpty={!severeWeather.name}>
        <PreviewFeedModal
          show={showModalFeed}
          onHide={() => {
            setShowModalFeed(false);
          }}
          title={t('common.preview')}
          selectedRssFeedSchema={{
            type: 'rss',
            createdAt: severeWeather!.createdAt,
            updatedAt: severeWeather!.updatedAt,
            dependency: severeWeather!.dependency as AppDependency,
            id: severeWeather!.id,
            name: severeWeather!.name,
            placement: severeWeather!.placement as PlacementsEnum,
          }}
        >
          <div
            onContextMenu={(ev) => ev.preventDefault()}
            onClick={displayPreview}
            onKeyDown={displayPreview}
            role="button"
            tabIndex={-1}
            className="d-flex align-items-center cursor-pointer"
          >
            <div
              className={clsx('h-30px w-40px me-4 rounded', styles.image)}
              style={{ backgroundImage: `url(${severeWeatherPic})` }}
            />

            <p className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 w-100">
              {severeWeather.name}
            </p>
          </div>
        </PreviewFeedModal>
      </Cell>
      <Cell isEmpty={!Object.keys(severeWeather.dependency.country).length}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {severeWeather.dependency?.country?.name},{' '}
          {severeWeather.dependency?.counties?.length
            ? severeWeather.dependency?.counties
                ?.map((county) => county.name)
                .join(', ')
            : severeWeather.dependency?.state?.name}
        </span>
      </Cell>
      <Cell isEmpty={!severeWeather.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {severeWeather.ownerApp?.firstName} {severeWeather.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!severeWeather.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {severeWeather.createdAt && formatDate(severeWeather.createdAt)}
        </span>
      </Cell>
      <Cell isEmpty={!severeWeather.devices?.length}>
        {severeWeather.devices?.length ? (
          <PopOver devices={severeWeather.devices} appName={severeWeather.name}>
            <span className="text-dark fw-bold cursor-pointer text-hover-primary d-block mb-1 fs-6 text-center ">
              {severeWeather.devices?.length}
            </span>
          </PopOver>
        ) : (
          <span>-</span>
        )}
      </Cell>
      <Cell isEmpty={!severeWeather.deviceGroups} className="text-center">
        {severeWeather.deviceGroups?.map(({ id, name }, index) => (
          <span
            key={id}
            className={clsx(
              'badge fs-7 py-3 px-7 fw-bold badge-light-primary mb-2',
              index && 'ms-2',
            )}
          >
            {name}
          </span>
        ))}
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          <div className="mx-1">
            <EditSevereWeather
              severeWeather={severeWeather}
              onUpdate={onUpdate}
            />
          </div>

          <div className="mx-1">
            <DeleteSevereWeather
              title={t('common.delete_confirmation.severe_weather')}
              app={severeWeather}
              onUpdate={onUpdate}
              toastMessage="apps.severeWeather.severe_deleted"
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default TableRow;
