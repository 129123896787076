import React from 'react';
import Select, { StylesConfig } from 'react-select';
import WeatherColor from '../../enums/weatherColor.enum';

export interface ColourOption {
  value: WeatherColor;
  label: string;
  color: string;
}

export const colourOptions: ColourOption[] = [
  {
    value: WeatherColor.BlueMulticolor,
    label: 'Blue Multicolor',
    color: '#6d6afb',
  },
  { value: WeatherColor.Green, label: 'Green', color: '#3ea072' },
  { value: WeatherColor.NavyBlue, label: 'Navy Blue', color: '#024366' },
  { value: WeatherColor.Orange, label: 'Orange', color: '#fc7500' },
  {
    value: WeatherColor.PinkMulticolor,
    label: 'Pink Multicolor',
    color: '#b85ae3',
  },
  { value: WeatherColor.Purple, label: 'Purple', color: '#885de2' },
  {
    value: WeatherColor.PurpleMulticolor,
    label: 'Purple Multicolor',
    color: '#fb7ead',
  },
  { value: WeatherColor.Red, label: 'Red', color: '#b92722' },
  { value: WeatherColor.SkyBlue, label: 'Sky Blue', color: '#04a3f9' },
  {
    value: WeatherColor.SkyblueGradient,
    label: 'Sky Blue Gradient',
    color: '#10b2f4',
  },
];

const block = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 4,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 15,
    width: 36,
  },
});

const colourStyles: StylesConfig<ColourOption, false> = {
  option: (styles, { data }) => ({
    ...styles,
    ...block(data.color),
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...block(data.color),
  }),
};

export default ({
  onChange,
  initialValue,
}: {
  onChange: (selectedValue: ColourOption) => void;
  initialValue: ColourOption;
}) => (
  <Select
    defaultValue={initialValue}
    options={colourOptions}
    styles={colourStyles}
    onChange={(value) => onChange(value as ColourOption)}
  />
);
