import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import CreateCompany from '../../../components/Companies/CreateUserCompany';
import Company from '../../../interfaces/company.interface';
import api from '../../../api';
import EditAdminCompanyRequestData from '../../../api/endpoints/companies/interfaces/editAdminCompanyRequestData.interfaces';

const CreateCustomerCompany = ({
  companyId,
  children,
  onUpdate,
  isEdit,
}: {
  companyId?: string;
  children?: React.ReactNode;
  onUpdate?: () => void;
  isEdit?: boolean;
}) => {
  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<Company>();
  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const getCompanyById = async () => {
    try {
      if (!companyId) return;
      const responseCompanyById = await api.companies.getCompany(companyId);
      setCompany(responseCompanyById);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = (data: EditAdminCompanyRequestData) => {
    setLoading(true);
    if (isEdit && company) {
      api.companies.editCompany(company.id, data).then(() => {
        close();
        onUpdate?.();
      });
    } else {
      api.companies.addCompany(data).then(() => {
        close();
        onUpdate?.();
      });
    }
  };

  useEffect(() => {
    if (companyId) {
      getCompanyById();
    }
  }, []);
  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={isEdit ? t('companies.edit_modal') : t('companies.create_modal')}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
        widthDefaultSize={false}
      >
        <CreateCompany
          company={company}
          onSubmit={handleSubmit}
          isLoading={loading}
        />
      </Modal>
    </>
  );
};

export default CreateCustomerCompany;
