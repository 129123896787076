enum PlaylistCells {
  Name = 'name',
  DevicesCount = 'devices_count',
  Priority = 'priority',
  StartDate = 'start_date',
  EndDate = 'end_date',
  Status = 'status',
  LayoutTemplate = 'layout_template',
  LastUpdate = 'last_update',
  Duration = 'duration',
  Tags = 'tags',
  Owner = 'owner',
}

export default PlaylistCells;
