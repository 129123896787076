import React from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import SelectLanguage from '../../../containers/SelectLanguage';
import LibrariesQueryParam from '../../../api/endpoints/libraries/enums/librariesQueryParam.enum';
import { debouncedUpdateSearch } from '../../../utils/browserHistory';
import SelectIndustries from '../../../containers/SelectIndustries';
import FiltersDropdown from '../../Dropdown/FiltersDropdown';
import useFilter from '../../../utils/hooks/useFilter';
import Language from '../../../enums/language.enum';

export default ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const [industries, setIndustries] = useFilter<string[]>(
    LibrariesQueryParam.Industries,
    [],
    true,
  );
  const [language, setLanguage] = useFilter<Language>(
    LibrariesQueryParam.Languages,
  );

  const handleApply = () =>
    debouncedUpdateSearch({
      [LibrariesQueryParam.Industries]: industries ?? [],
      [LibrariesQueryParam.Languages]: language ?? '',
    });

  // Reset filters in the query params
  const handleReset = () => {
    debouncedUpdateSearch({
      [LibrariesQueryParam.Industries]: [],
      [LibrariesQueryParam.Languages]: '',
    });
  };

  return (
    <FiltersDropdown
      className={className}
      onApplyFilters={handleApply}
      onReset={handleReset}
      topicFilter
    >
      <div className="mb-10">
        <label htmlFor="industry" className="form-label fw-bold">
          {t('library.indigo.industry')}:
        </label>
        <SelectIndustries
          initialValue={industries}
          onChange={(values) => setIndustries(map(values, 'value') as string[])}
        />
      </div>
      <div className="mb-10">
        <label htmlFor="language" className="form-label fw-bold">
          {t('library.indigo.language')}:
        </label>
        <SelectLanguage
          id="language"
          placeholder={t('users.common.select_language')}
          language={language}
          setLanguage={setLanguage}
        />
      </div>
    </FiltersDropdown>
  );
};
