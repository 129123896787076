/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import UpdateContentRequestData from '../../../api/endpoints/contents/interfaces/updateContentRequestData.interface';
import { playlistContentEditValidation } from '../../../validation/contents';
import UploadContent from '../../../components/Contents/UploadContent';
import IContent from '../../../interfaces/content.interface';
import Tooltip from '../../../components/Tooltip';
import AppType from '../../../enums/appType.enum';
import Modal from '../../../components/Modal';
import api from '../../../api';
import Playlist from '../../../interfaces/playlist.interface';
import Orientation from '../../../enums/orientation.enum';
import GetPlaylistsRequestData from '../../../api/endpoints/playlists/interfaces/getPlaylistsRequestData.interface';
import WithSpinner from '../../../components/WithSpinner';
import EditContentTable from '../../Playlist/RemoveContent/RemoveContentPlaylists/RemoveContentTable';
import DayOfWeek from '../../../enums/dayOfWeek.enum';
import GlobalState from '../../../store/reducers/globalState.interface';

export default ({
  children,
  content,
  onSuccess,
  playlist,
  userAccessByRole,
}: {
  children?: React.ReactNode;
  content: IContent;
  onSuccess: () => void;
  playlist?: Playlist;
  userAccessByRole?: boolean;
}) => {
  const { t } = useTranslation();
  const [contentPlaylists, setContentPlaylists] = useState<Playlist[]>([]);
  const { id } = useSelector((state: GlobalState) => state.userData.user);
  const [multiplePlaylistsModal, setMultiplePlaylistsModal] =
    useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingModals, isLoadingModals] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [filters, setFilters] = useState<GetPlaylistsRequestData>();
  const [data, setData] = useState<UpdateContentRequestData>();
  const [isModalVisiblePlaylists, setIsModalVisiblePlaylists] = useState(false);
  const [fromPreviousStep, setFromPreviousStep] = useState<boolean>(false);
  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);
  const [playlistsIds, setPlaylistsIds] = useState<string[]>([]);
  const openPlaylistsModal = () => setIsModalVisiblePlaylists(true);
  const closePlaylistsModal = () => setIsModalVisiblePlaylists(false);
  const handleSubmit = (contentData: UpdateContentRequestData) => {
    setIsLoading(true);

    if (multiplePlaylistsModal && !content.isLocked) {
      setData(contentData);
      close();
      setIsLoading(false);
      openPlaylistsModal();
      return;
    }

    api.contents
      .updateContent(contentData, content.id)
      .then(() => {
        onSuccess();

        toast.success(i18next.t<string>('playlists.content_edited_message'));
      })
      .finally(() => {});
  };

  const handleSubmitWithSelectedPlaylists = () => {
    if (data) {
      const dataContent = data;
      dataContent.playlistIds = playlistsIds;
      setIsLoading(true);
      api.contents
        .UpdateContentFromMultiplePlaylists(
          dataContent,
          content?.mediaGroup?.medias[0]?.id,
          content?.app?.id,
        )
        .then(() => {
          onSuccess();

          toast.success(i18next.t<string>('playlists.content_edited_message'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const defaultvaluesForContent = () => {
    if (data) {
      return {
        startDate: data?.startDate ? new Date(data?.startDate) : undefined,
        endDate: data?.endDate ? new Date(data?.endDate) : undefined,
        duration: Number(data?.duration),
        name: data?.name,
        orientation: playlist?.template ? data?.zoneId : Orientation.FullScreen,
        files: [],
        weekDays: data?.weekDays ?? [],
        tags: data.tags ?? [],
        lockContent: data?.isLocked,
      };
    }

    return {
      startDate: content.startDate ? new Date(content.startDate) : undefined,
      endDate: content.endDate ? new Date(content.endDate) : undefined,
      duration: content.duration,
      name: content.title,
      orientation: playlist?.template ? content.zoneId : Orientation.FullScreen,
      files: [],
      weekDays: content.weekDays ?? [],
      tags: content.tags ?? [],
      lockContent: content.isLocked,
    };
  };

  const getPlaylistsBasedOnContentId = async () => {
    if (content) {
      try {
        isLoadingModals(true);
        await api.playlists
          .getPlaylistsByContentId(
            filters ?? { limit: '150' },
            content?.mediaGroup?.medias[0]?.id,
            content?.app?.id,
            content?.contentUuid,
          )
          .then((res) => {
            setTotal(res.meta.totalItems);
            if (res.meta.totalItems > 1) setMultiplePlaylistsModal(true);
            setContentPlaylists(res.items);
          });
      } catch (error: any) {
        if (error?.response?.data?.message === 'corrupted') {
          toast.warn(t('playlists.corruptedContent'));
        } else toast.error(error);
      } finally {
        isLoadingModals(false);
      }
    }
  };

  // const disableLockFuntion = () => {
  //   if (content && content?.contentOwner?.id === id) {
  //     return false;
  //   }

  //   if (content && content?.contentOwner?.id !== id && !userAccessByRole) {
  //     return true;
  //   }

  //   return false;
  // };

  React.useEffect(() => {
    if (isModalVisible && !fromPreviousStep) getPlaylistsBasedOnContentId();
    setFromPreviousStep(false);
  }, [isModalVisible]);

  React.useEffect(() => {
    if (isModalVisiblePlaylists) {
      getPlaylistsBasedOnContentId();
    }
  }, [filters]);
  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.edit')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-edit" />
            </span>
          </Tooltip>
        )}
      </span>
      <>
        <Modal
          title={t('contents.edit_content')}
          widthDefaultSize={false}
          withFooter={false}
          show={isModalVisible}
          onHide={close}
        >
          <WithSpinner
            isLoading={loadingModals}
            className="min-h-400px"
            size="md"
          >
            {!content?.isLocked ||
            content?.contentOwner?.id === id ||
            userAccessByRole ? (
              <UploadContent
                submitButtonText={
                  contentPlaylists.length > 1 && !content.isLocked
                    ? t('common.next_step')
                    : t('common.submit')
                }
                isLoading={isLoading}
                onSubmit={handleSubmit}
                validationSchema={playlistContentEditValidation(t)}
                playlist={playlist}
                contentType={content.type}
                initialData={{
                  ...defaultvaluesForContent(),
                }}
                config={{
                  skipDuration:
                    content.app?.dependency?.type === AppType.YouTube,
                }}
                isEdit
                currentUserId={id}
                isApp={!!content?.app?.id}
                contentId={content.id}
              />
            ) : (
              <div className="text-center fs-4">
                {t('contents.locked_content_permission')}
              </div>
            )}
          </WithSpinner>
        </Modal>

        <Modal
          title={`${t('contents.edit_content_multiple')} ${content.title}`}
          // widthDefaultSize={false}
          show={isModalVisiblePlaylists}
          onHide={closePlaylistsModal}
          bodyClassName="py-5"
          footerContent={
            <>
              <div className="pe-5 me-auto">
                <button
                  type="button"
                  className="btn btn-white text-primary"
                  onClick={() => {
                    setFromPreviousStep(true);
                    closePlaylistsModal();
                    open();
                  }}
                >
                  {t('common.prev_step')}
                </button>
              </div>

              <button
                type="button"
                className="btn btn-primary m-2"
                onClick={() => {
                  handleSubmitWithSelectedPlaylists();
                }}
                disabled={isLoading}
              >
                {t('common.submit')}
              </button>
            </>
          }
        >
          <EditContentTable
            onSelectionChange={(selectedItems) => {
              setPlaylistsIds(selectedItems);
            }}
            preselectedItems={playlist?.id ? [playlist?.id] : []}
            total={total}
            requestData={{}}
            playlists={contentPlaylists}
            setFiltersSearch={setFilters}
            loadingModals={loadingModals}
          />
        </Modal>
      </>
    </>
  );
};
