import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PlaylistContent from '../../../components/Playlist/PlaylistContent';
import useOnEventEmitterEvent from '../../../utils/hooks/useOnEventEmitterEvent';
import { PlaylistEvents } from '../../../enums/events/playlistEvents.enum';
import ConversionJob from '../../../interfaces/conversionJob.interface';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import IPlaylist from '../../../interfaces/playlist.interface';
import Orientation from '../../../enums/orientation.enum';
import api from '../../../api';
import {
  completedStatuses,
  conversionJobEvents,
} from '../../../constants/conversionJob';
import IContent from '../../../interfaces/content.interface';
import Device from '../../../interfaces/devices/device.interface';

export default ({
  playlist,
  fetchContents,
  contents,
  isContentsLoading,
  contentsMeta,
  devicesWithoutLicence,
}: {
  playlist: IPlaylist;
  fetchContents: (recentSearch: string) => void;
  contents: IContent[];
  isContentsLoading: boolean;
  contentsMeta: any;
  devicesWithoutLicence?: Device[];
}) => {
  const { search } = useLocation();
  const [orientation, setOrientation] = useState<Orientation | Orientation[]>(
    Orientation.Landscape,
  );
  const [conversionJobs, setConversionJobs] = useState<ConversionJob[]>([]);
  const userId = useSelector((state: any) => state.userData?.user?.id);

  const refreshConversionJobs = async (_userId: string) => {
    try {
      const jobs = await api.conversionJobs.getPlaylistConversionJobs(
        playlist.id,
        5,
        true,
        _userId,
      );

      if (!Array.isArray(jobs)) {
        if (conversionJobs.length) {
          setConversionJobs([]);
          return;
        }
      }
      if (jobs.length !== conversionJobs.length) {
        setConversionJobs(jobs);
        return;
      }

      jobs.forEach((job) => {
        const index = conversionJobs.findIndex((j) => j.id === job.id);
        if (index !== -1) {
          if (conversionJobs[index].status !== job.status) {
            setConversionJobs(jobs);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useOnEventEmitterEvent(
    PlaylistEvents.Refresh,
    (playlistIds: string[]) => {
      if (!playlist) return;

      if (playlistIds?.includes(playlist.id)) {
        fetchContents(search);
        refreshConversionJobs(userId);
      }
    },
    { deps: [search, playlist] },
  );

  useOnEventEmitterEvent(
    conversionJobEvents,
    (job: ConversionJob) => {
      if (!playlist) return;

      const { status, playlistIds } = job;

      if (playlistIds?.includes(playlist.id)) {
        if (completedStatuses.includes(status)) {
          setConversionJobs(conversionJobs.filter(({ id }) => id !== job.id));
          fetchContents(search);
        } else if (
          conversionJobs.findIndex(
            (conversionJob) => conversionJob.id === job.id,
          ) === -1
        ) {
          setConversionJobs([...conversionJobs, job]);
        } else {
          setConversionJobs(
            conversionJobs.map((conversionJob) =>
              conversionJob.id === job.id ? job : conversionJob,
            ),
          );
        }
      }
    },
    { deps: [conversionJobs, search] },
  );

  useEffect(() => {
    const updatePlaylistConversionJob = setInterval(async () => {
      await refreshConversionJobs(userId);
    }, 3000);

    const windowOnFocus = () => {
      (window as any).focused = true;
    };
    const windowOnBlur = () => {
      (window as any).focused = false;
    };

    window.addEventListener('focus', windowOnFocus);
    window.addEventListener('blur', windowOnBlur);

    return () => {
      clearInterval(updatePlaylistConversionJob);
      window.removeEventListener('focus', windowOnFocus);
      window.removeEventListener('blur', windowOnBlur);
    };
  }, [userId]);

  useEffect(() => {
    const { orientation: newOrientation } = readAllFromQueryParams(search);
    if (orientation !== newOrientation)
      setOrientation(newOrientation as Orientation);

    fetchContents(search);
  }, [search]);

  return (
    <PlaylistContent
      playlist={playlist}
      onEdit={() => fetchContents(search)}
      contents={contents.map((item: any) => ({
        ...item.content,
        contentUuid: item.contentUuid,
        itemId: item.id,
        zoneId: item.zoneId,
        order: item.order,
        contentOwner: item.contentOwner,
      }))}
      isContentsLoading={isContentsLoading}
      total={contentsMeta.totalItems}
      conversionJobs={conversionJobs}
      orientation={orientation instanceof Array ? orientation[0] : orientation}
      devicesWithoutLicence={devicesWithoutLicence}
    />
  );
};
