import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import WithModesModal from '../../WithModesModal';
import Assign from './Assign';
import { KTSVG } from '../../../lib/metronic/helpers';
import CreateDeviceGroupRequestData from '../../../api/endpoints/devicesGroups/interfaces/createDeviceGroupRequestData.interface';
import useModalState from '../../../utils/hooks/useModalState';
import sizingStyles from '../../../styles/sizing.module.scss';
import CreateDeviceGroupInfoStep from './createDeviceGroupInfoStep';
import AddDevicesGroupSteps from '../../../enums/steps/addDevicesGroupSteps.enum';
import DeviceGroup from '../../../interfaces/deviceGroup.interface';
import UpdateDeviceGroupAction from '../../../store/actions/devicesGroups/interfaces/updateDeviceGroupAction.interface';
import WithClickBackdrop from '../../WithClickBackdrop';
import { registerComponent } from '../GroupsTable/TableRow';

const CreateDeviceGroup = ({
  className,
  onSubmit,
  isEdit,
  group,
  onSubmitUpdate,
}: {
  className?: string;
  isEdit?: boolean;
  group?: DeviceGroup;
  onSubmit?: (data: CreateDeviceGroupRequestData) => void;
  onSubmitUpdate?: (data: UpdateDeviceGroupAction['payload']) => void;
}) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] = useState<
    Partial<CreateDeviceGroupRequestData>
  >({});
  const [isVisible, handleOpen, handleClose] = useModalState(false);

  const [addDeviceMode, setAddDeviceMode] = useState<AddDevicesGroupSteps>(
    AddDevicesGroupSteps.Info,
  );

  return (
    <>
      {!isEdit && (
        <button
          type="button"
          onClick={handleOpen}
          className={clsx('btn btn-primary', className)}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr075.svg"
            className="svg-icon-2"
          />{' '}
          {t('devices_groups.add_group')}
        </button>
      )}

      {isEdit && (
        <span aria-hidden="true" onClick={handleOpen}>
          <WithClickBackdrop>{t('common.edit')}</WithClickBackdrop>{' '}
        </span>
      )}

      <WithModesModal<any, AddDevicesGroupSteps>
        isVisible={isVisible}
        onClose={handleClose}
        modes={{
          [AddDevicesGroupSteps.Info]: {
            title: isEdit
              ? t('devices_groups.edit_device_group.edit_group')
              : t('devices_groups.add_group'),
            render: ({ setMode }) => (
              <CreateDeviceGroupInfoStep
                setMode={(mode) => {
                  setMode(mode);
                  setAddDeviceMode(mode);
                }}
                data={stepsData}
                setData={setStepsData}
                group={group}
              />
            ),
          },
          [AddDevicesGroupSteps.Assign]: {
            title: isEdit
              ? t('devices_groups.edit_device_group.edit_group')
              : t('devices_groups.add_group'),
            render: ({ setMode, close }) => (
              <Assign
                isEdit={isEdit}
                setMode={(mode) => {
                  setMode(mode);
                  setAddDeviceMode(mode);
                }}
                onSubmit={(data) => {
                  close();
                  setStepsData({});
                  if (onSubmit) onSubmit(data as CreateDeviceGroupRequestData);
                  if (onSubmitUpdate)
                    onSubmitUpdate({ id: group?.id as string, data });
                }}
                group={group}
                setData={setStepsData}
                data={stepsData}
              />
            ),
          },
        }}
        defaultMode={AddDevicesGroupSteps.Info}
        dialogClassName={
          addDeviceMode === AddDevicesGroupSteps.Info
            ? clsx(sizingStyles.fitContentOld)
            : clsx(sizingStyles.fitContent)
        }
      />
    </>
  );
};

export default CreateDeviceGroup;
registerComponent('CreateDeviceGroup', CreateDeviceGroup);
