import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { compact, omit } from 'lodash';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import ScheduleContentSteps from '../../../../enums/steps/scheduleContentSteps.enum';
import ScheduleLibraryRequestData from '../../../../api/endpoints/libraries/interfaces/scheduleLibraryRequestData.interface';
import DevicesAndGroups from '../../../PublishModal/Steps/DevicesAndGroups';
import sizingStyles from '../../../../styles/sizing.module.scss';
import Playlists from '../../../PublishModal/Steps/Playlists';
import WithModesModal from '../../../WithModesModal';
import Info from './Steps/Info';
import api from '../../../../api';
import { Contents } from '../../../../interfaces/library.interface';
import GlobalState from '../../../../store/reducers/globalState.interface';
import { UserRole } from '../../../../enums/userRole.enum';

export default ({
  onSubmit,
  isVisible,
  onClose,
  infoValidationSchema,
  defaultValues = {},
  fullInfoSelectedItems,
  withOrientation,
}: {
  onSubmit: (data: ScheduleLibraryRequestData) => void;
  onClose?: () => void;
  isVisible: boolean;
  infoValidationSchema?: { [key: string]: any };
  defaultValues?: Partial<ScheduleLibraryRequestData>;
  fullInfoSelectedItems?: Contents;
  withOrientation?: boolean;
}) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] =
    useState<Partial<ScheduleLibraryRequestData>>(defaultValues);
  const isAdmin = useSelector((state: GlobalState) =>
    state.userData.user.roles.includes(UserRole.SuperAdmin),
  );
  const handleSubmit = async () => {
    try {
      let selectedPlaylists: string[] = [];
      if (stepsData.deviceGroupIds) {
        // add deviceGroupIds criteria
        const { items } = isAdmin
          ? await api.devices.getAdminDevicesList({
              limit: '400',
              deviceGroupIds: stepsData.deviceGroupIds,
            })
          : await api.devices.getDevicesList({
              limit: '400',
              deviceGroupIds: stepsData.deviceGroupIds,
            });

        selectedPlaylists = [
          ...selectedPlaylists,
          ...compact(items.map((device) => device?.currentPlaylist?.id)),
        ];
      }

      if (stepsData.deviceIds) {
        const { items } = isAdmin
          ? await api.devices.getAdminDevicesList({ ids: stepsData.deviceIds })
          : await api.devices.getDevicesList({ ids: stepsData.deviceIds });

        selectedPlaylists = [
          ...selectedPlaylists,
          ...compact(
            items.map((device) =>
              stepsData.deviceIds?.includes(device.id)
                ? device?.currentPlaylist?.id
                : null,
            ),
          ),
        ];
      } else if (
        !stepsData.deviceIds &&
        !stepsData.deviceGroupIds &&
        stepsData
      ) {
        selectedPlaylists = compact(stepsData.playlistIds);
      }
      if (!selectedPlaylists || selectedPlaylists.length === 0) {
        toast.error(i18next.t('contents.validation.no_current_playlist'));
        return;
      }

      // const { items } =
      //   ? await api.devices.getAdminDevicesList({ ids: stepsData.deviceIds })
      //   : await api.devices.getDevicesList({ ids: stepsData.deviceIds });
      // let check = false;

      // stepsData.deviceIds?.forEach((id) => {
      //   const device = items.find((item) => item.id === id);

      //   if (device?.currentPlaylist?.id === null) {
      //     check = false;
      //   } else {
      //     check = true;
      //   }
      // });
      // const selectedPlaylists = uniq(
      //   compact(
      //     items.map((device) => (check ? device?.currentPlaylist?.id : null)),
      //   ),
      // );
      // if (!selectedPlaylists || selectedPlaylists.length === 0) {
      //   toast.error(i18next.t('contents.validation.no_current_playlist'));
      //   return;
      // }
      stepsData.playlistIds = [...selectedPlaylists];
      const data = omit(stepsData, ['onlyCurrentSelected']);
      onSubmit(data as ScheduleLibraryRequestData);

      onClose?.();
      setStepsData({});
    } catch (e: any) {
      toast.error(i18next.t(e?.message ?? ''));
    }
  };

  return (
    <WithModesModal<any, ScheduleContentSteps>
      submitButtonText="Schedule"
      isVisible={isVisible}
      onClose={onClose}
      modes={{
        [ScheduleContentSteps.Info]: {
          title: t('library.indigo.publishModal.title'),
          render: ({ setMode, close }) => (
            <Info
              fullInfoSelectedItems={fullInfoSelectedItems}
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onDecline={close}
              validationSchema={infoValidationSchema}
              withOrientation={withOrientation}
            />
          ),
        },
        [ScheduleContentSteps.Devices]: {
          title: t('library.indigo.publishModal.title'),
          render: ({ setMode }) => (
            <DevicesAndGroups
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              isOptional
              onSubmit={handleSubmit}
              libraryPublish
            />
          ),
        },
        [ScheduleContentSteps.Playlists]: {
          title: t('library.indigo.publishModal.title'),
          render: ({ setMode, close }) => (
            <Playlists
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onSubmit={() => {
                onSubmit(stepsData as ScheduleLibraryRequestData);
                close();
                setStepsData({});
              }}
            />
          ),
        },
      }}
      defaultMode={ScheduleContentSteps.Info}
      dialogClassName={clsx(
        sizingStyles.fitContentOld,
        sizingStyles.maxWidthNone,
      )}
    />
  );
};
