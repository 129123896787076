import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import TableRow from '../../Users/UsersTable/TableRow';
import Columns, { TableHeaderColumn } from '../../Table/Columns';
import UsersSortField from '../../../api/endpoints/users/enums/usersSortField.enum';
import UsersQueryParam from '../../../api/endpoints/users/enums/usersQueryParam.enum';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import User from '../../../interfaces/user/user.interface';
import SortOrder from '../../../enums/sortOrder.enum';
import { SortField } from '../../../utils/sorting';
import WithSpinner from '../../WithSpinner';
import Pagination from '../../Pagination';
import Search from '../../Search';
import Table from '../../Table';
import useTableMultiSelect, {
  isAllSelected,
} from '../../../utils/hooks/useTableMultiSelect';

const preloaderHeight = 283.5;
const pageSize = 4;

export default ({
  users,
  isLoading,
  total,
  onSearchDataChange,
  onSelectionChange,
  preselectedItems,
}: {
  users: User[];
  isLoading: boolean;
  total: number;
  onSearchDataChange: (searchData: { [key: string]: string }) => void;
  onSelectionChange: (selectedItems: string[]) => void;
  preselectedItems: string[];
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [filters] = useState({});
  const [sortField, setSortField] = useState<SortField>({
    field: null,
    isInverted: false,
  });
  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(users, 'id'),
    preselectedItems,
  );

  useEffect(() => {
    onSearchDataChange({
      [UsersQueryParam.Search]: searchValue,
      [PaginationQueryParam.Limit]: String(pageSize),
      [PaginationQueryParam.Page]: String(pageNumber),
      [SortingQueryParam.SortBy]: sortField.field ?? '',
      [SortingQueryParam.SortOrder]: sortField.isInverted
        ? SortOrder.Ascending
        : SortOrder.Descending,
      ...filters,
    });
  }, [searchValue, pageNumber, sortField, filters]);

  useEffect(() => onSelectionChange(selectedItems), [selectedItems]);

  const columns: TableHeaderColumn<UsersSortField>[] = [
    {
      name: t('users.table.username'),
      sortBy: UsersSortField.FirstName,
    },
    { name: t('users.common.company') },
    { name: t('users.common.role') },
    { name: t('users.table.location') },
  ];

  return (
    <>
      <div className="d-flex flex-wrap flex-stack">
        <Search
          searchValue={searchValue}
          onChange={setSearchValue}
          placeholder={t('users.search_users')}
          wrapperClassName="mb-3"
        />
      </div>
      <Table className="min-w-600px">
        <Table.Head>
          <Table.Head.Row>
            <Columns<UsersSortField>
              columns={columns}
              config={{ sortField, setSortField, isSelectable: true }}
              onChange={selectAll}
              checked={isAllSelected(selectedItems, map(users, 'id'))}
            />
          </Table.Head.Row>
        </Table.Head>
        <Table.Body
          isEmpty={!isLoading && !users.length}
          emptyStateHeight={preloaderHeight}
          emptyStateChildren={t('users.table.no_one_user_found')}
        >
          <WithSpinner
            isLoading={isLoading}
            style={{ minHeight: `${preloaderHeight}px` }}
            isForTable
            size="md"
          >
            {users.map((user) => (
              <TableRow
                key={user.id}
                user={user}
                isChecked={selectedItems.includes(user.id)}
                onCheck={setSelectedItem}
                config={{
                  skipStatus: true,
                  skipDevicesNumber: true,
                  skipGroupAccess: true,
                  skipActions: true,
                  disableLinks: true,
                  isSelectable: true,
                }}
              />
            ))}
          </WithSpinner>
        </Table.Body>
      </Table>
      <Pagination
        total={total}
        pageSize={pageSize}
        pageNumber={pageNumber}
        onPageNumberUpdate={setPageNumber}
        className="mt-3"
      />
    </>
  );
};
