import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import api from '../../../api';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import UserAssignmentTargetEnum from '../../../enums/userAssignmentTarget.enum';
import { UserRole } from '../../../enums/userRole.enum';
import User from '../../../interfaces/user/user.interface';
import UserAssignmentTarget from '../../../interfaces/user/userAssignmentTarget.interface';
import GlobalState from '../../../store/reducers/globalState.interface';
import { readAllFromQueryParams } from '../../../utils/queryParams';

const SelectUserGroups = ({
  onChange,
  initialValue,
  userAssignmentTarget,
}: {
  onChange: (selectedValue: SelectedValue[]) => void;
  initialValue?: any;
  userAssignmentTarget?: UserAssignmentTarget;
}) => {
  const [options, setOptions] = useState<any>([]);
  const { id: deviceGroupId } = useParams<{ id: string }>();
  const roles = useSelector((state: GlobalState) => state.userData.user);
  const isSupperAdmin = roles.roles.includes(UserRole.SuperAdmin);
  const { search } = useLocation();
  const { currentUserId } = readAllFromQueryParams(search);

  const loadOptions = async () => {
    let items: User[];

    switch (userAssignmentTarget?.type) {
      case UserAssignmentTargetEnum.UserGroup:
        ({ items } =
          isSupperAdmin && currentUserId
            ? await api.usersGroups.getAdminUsersAssignedToUserGroup(
                { exclude: true, currentUserId: String(currentUserId) || '' },
                userAssignmentTarget?.id,
              )
            : await api.usersGroups.getUsersAssignedToUserGroup(
                { exclude: true },
                userAssignmentTarget?.id,
              ));
        break;
      default:
        ({ items } =
          isSupperAdmin &&
          currentUserId &&
          currentUserId !== 'undefined' &&
          deviceGroupId
            ? await api.devicesGroups.getUsersListByCurrentUser(
                { exclude: true, currentUserId: String(currentUserId) || '' },
                deviceGroupId,
              )
            : await api.devicesGroups.getDeviceGroupUsersList(
                { exclude: true },
                deviceGroupId,
              ));
    }
    const getOptions = items.map(({ id, firstName, lastName }) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));

    setOptions(getOptions);
  };

  const handleFilterOptions = (filteredOptions: any, input: string) => {
    return filteredOptions.filter((option: { label: string }) =>
      option.label.toLowerCase().includes(input.toLowerCase()),
    );
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <MultiSelect
      options={options}
      value={initialValue}
      onChange={onChange}
      filterOptions={handleFilterOptions}
      labelledBy="Select user groups"
    />
  );
};

SelectUserGroups.defaultProps = {
  initialValue: [],
};

export default SelectUserGroups;
