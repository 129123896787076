import clsx from 'clsx';
import React from 'react';
import { disableRightClickIframe } from '../../../../utils/common';
import styles from './thumbnail.module.scss';

export default ({
  title,
  onOpen,
  setRef,
  src,
}: {
  title?: string;
  onOpen?: () => void;
  setRef?: (el: HTMLIFrameElement | null) => void;
  src?: string;
}) => {
  React.useEffect(() => {
    if (title) {
      disableRightClickIframe(title);
    }
  }, []);
  return (
    <span
      onContextMenu={(ev) => ev.preventDefault()}
      role="button"
      className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 w-100"
      onKeyPress={onOpen}
      onClick={onOpen}
      tabIndex={-1}
    >
      <div className={clsx('position-absolute', styles.facebookThumbnail)} />
      <div className="d-flex flex-row">
        {' '}
        <iframe
          ref={setRef}
          tabIndex={-1}
          title={title}
          src={src}
          width={50}
          height={28}
          marginHeight={0}
          marginWidth={0}
          className={styles.facebookIframe}
        />
        {title}
      </div>
    </span>
  );
};
