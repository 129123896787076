import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { Select } from 'antd';
import SortOrder from '../../../enums/sortOrder.enum';
import { readFromQueryString } from '../../../utils/queryParams';
import Table from '../../Table';
import Columns from '../../Table/Columns';
import EmptyFeeds from '../../Apps/EmptyFeeds/EmptyFeeds';
import Pagination from '../../Pagination';
import Card from '../../Card';
import TableRow from './TableRow';
import html5Logo from '../../../assets/images/apps/html5.png';
import tickerLogo from '../../../assets/images/apps/Bullhorn.png';
import powerBiLogo from '../../../assets/images/apps/powerBi.svg';
import youtubeLogo from '../../../assets/images/apps/youtube.svg';
import weatherLogo from '../../../assets/images/apps/PartlyCloud.png';
import twitterLogo from '../../../assets/images/apps/twitter.svg';
import instagramLogo from '../../../assets/images/apps/instagram.svg';
import facebookLogo from '../../../assets/images/apps/facebook.svg';
import severeWeatherLogo from '../../../assets/images/apps/Storm.png';
import qrCodeLogo from '../../../assets/images/apps/qrCode.svg';
import msTeams from '../../../assets/images/apps/microsoft-teams.svg';
import rss from '../../../assets/images/apps/rss.svg';
import countUpTimer from '../../../assets/images/apps/countUpTimer.svg';
import countDownTimer from '../../../assets/images/apps/countDownTimer.svg';
import daysSafe from '../../../assets/images/apps/daysSafe.svg';
import liveStream from '../../../assets/images/apps/liveStream.svg';

import canva from '../../../assets/images/apps/canva.svg';
import googleDocs from '../../../assets/images/apps/google_docs.svg';
import googleCalendar from '../../../assets/images/apps/google_calendar.svg';
import googleSlides from '../../../assets/images/apps/google_slides.svg';
import sheets from '../../../assets/images/apps/sheets.svg';
import WithSpinner from '../../WithSpinner';
import api from '../../../api';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import AppDetailsType from '../../../interfaces/appDetails.interface';

type MetaType = {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  currentPage: number;
};
const appsLabel = [
  {
    id: '0',
    label: 'Severe Weather',
    iconPath: severeWeatherLogo,
    styles: {
      marginRight: '10px',
      width: '30px',
      height: '25px',
    },
  },
  // {
  //   id: '1',
  //   label: 'Emergency Alert',
  //   iconPath: severeWeatherLogo,
  //   width: '35px',
  //   height: '35px',
  // },
  {
    id: '2',
    label: 'Ticker',
    iconPath: tickerLogo,
    styles: {
      marginRight: '10px',
      width: '30px',
      height: '25px',
    },
  },
  {
    id: '3',
    label: 'Youtube',
    iconPath: youtubeLogo,
    styles: {
      marginRight: '8px',
      width: '32px',
      height: '22px',
    },
  },
  {
    id: '4',
    label: 'HTML5',
    iconPath: html5Logo,
    styles: {
      marginRight: '12px',
      width: '25px',
      height: '25px',
    },
  },
  {
    id: '5',
    label: 'Weather',
    iconPath: weatherLogo,
    styles: {
      marginRight: '10px',
      width: '30px',
      height: '30px',
    },
  },
  {
    id: '6',
    label: 'PowerBi',
    iconPath: powerBiLogo,
  },
  {
    id: '7',
    label: 'RSS',
    iconPath: rss,
  },

  {
    id: '8',
    label: 'Google Sheets',
    iconPath: sheets,
  },
  {
    id: '9',
    label: 'Google Docs',
    iconPath: googleDocs,
  },
  {
    id: '10',
    label: 'Google Slides',
    iconPath: googleSlides,
  },
  {
    id: '11',
    label: 'Google calendar',
    iconPath: googleCalendar,
  },
  {
    id: '12',
    label: 'Canva',
    iconPath: canva,
  },

  {
    id: '13',
    label: 'Twitter',
    iconPath: twitterLogo,
  },
  {
    id: '14',
    label: 'Instagram',
    iconPath: instagramLogo,
    width: '35px',
    height: '35px',
  },
  {
    id: '15',
    label: 'Facebook',
    iconPath: facebookLogo,
  },

  {
    id: '16',
    label: 'QR Code',
    iconPath: qrCodeLogo,
  },
  {
    id: '17',
    label: 'MS Teams',
    iconPath: msTeams,
  },
  {
    id: '18',
    label: 'Count UP Timer',
    iconPath: countUpTimer,
  },
  {
    id: '19',
    label: 'Count Down Timer',
    iconPath: countDownTimer,
  },

  {
    id: '20',
    label: 'Injury Free Counter',
    iconPath: daysSafe,
  },

  {
    id: '21',
    label: 'Live Stream',
    iconPath: liveStream,
  },
];

const { Option } = Select;

export default ({
  sortQueryField,
  orderQueryField,
  isSelectable = false,
}: {
  sortQueryField: string;
  orderQueryField: string;
  isSelectable?: boolean;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });
  const queryParams = new URLSearchParams(search);
  const tip =
    queryParams.get('type') &&
    (queryParams.get('type') as unknown as number) < appsLabel.length
      ? queryParams.get('type')
      : '0';
  const [appType, setAppType] = useState(tip || '0');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<AppDetailsType[]>([]);
  const [meta, setMeta] = useState<MetaType | undefined>();
  const [requestData, setRequestData] = useState({
    [PaginationQueryParam.Page]: 1,
    [PaginationQueryParam.Limit]: 6,
  });
  const columns = [
    {
      name: t('App'),
    },
    {
      name: t('Company'),
    },
    {
      name: t('Location'),
    },
    {
      name: t('User'),
    },
    {
      name: t('Playlist'),
    },
    {
      name: t('Device'),
    },
  ];
  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await api.apps.getAppsDetailsDependsOnAppType({
        appType,
        requestData,
      });
      setData(res?.items);
      setMeta(res?.meta);
    } catch (error) {
      toast.error('Something went wrong !');
    } finally {
      setIsLoading(false);
    }
  }, [appType, requestData]);
  const handleChange = (value: string) => {
    setAppType(value);
  };
  const handlePageNumberChange = (pageNumber: number) => {
    setRequestData({
      ...requestData,
      [PaginationQueryParam.Page]: pageNumber ?? 1,
    });
  };
  useEffect(() => {
    getData();
  }, [appType, requestData]);
  return (
    <Card>
      <div style={{ width: '300px', margin: '20px', float: 'right' }}>
        <Select
          style={{ width: '100%' }}
          placeholder="select app type"
          onChange={handleChange}
          size="large"
          defaultValue={
            queryParams.get('type') &&
            (queryParams.get('type') as unknown as number) < appsLabel.length
              ? queryParams.get('type')
              : '0'
          }
        >
          {appsLabel.map(
            (elem: {
              id: string;
              label: string;
              iconPath: string;
              width?: string;
              height?: string;
              styles?: React.CSSProperties;
            }) => (
              <Option value={elem?.id} label="youtube">
                <span className="symbol-label">
                  <img
                    src={elem.iconPath}
                    alt={`${elem?.label}.png`}
                    style={
                      elem?.styles ?? {
                        marginRight: '10px',
                        width: '30px',
                        height: '30px',
                      }
                    }
                  />
                </span>
                <span>{elem?.label}</span>
              </Option>
            ),
          )}
        </Select>
      </div>
      <Table>
        <Table.Head>
          <Table.Head.Row>
            <Columns
              columns={columns}
              config={{
                sortField,
                setSortField,
                sortQueryField,
                orderQueryField,
                isSelectable,
              }}
            />
          </Table.Head.Row>
        </Table.Head>

        <Table.Body
          isEmpty={false}
          emptyStateHeight={500}
          emptyStateChildren={
            <EmptyFeeds descriptionText="No data is available" />
          }
        >
          <WithSpinner isLoading={isLoading} isForTable size="md">
            {data.map((_elem) => (
              <TableRow row={_elem} key={_elem?.id} />
            ))}
          </WithSpinner>
        </Table.Body>
      </Table>
      <Pagination
        total={meta?.totalItems ?? 0}
        pageSize={meta?.itemsPerPage ?? 5}
        pageNumber={meta?.currentPage ?? 1}
        onPageNumberUpdate={handlePageNumberChange}
        className="mt-3 mb-5"
      />
    </Card>
  );
};
