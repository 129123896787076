import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Alert } from 'react-bootstrap-v5';
import ScheduleContentSteps from '../../../../enums/steps/scheduleContentSteps.enum';
import ScheduleContentRequestData from '../../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import AssignDevicesGroups from '../../../../containers/DevicesGroups/AssignDevicesGroups';
import AssignDevices from '../../../../containers/Devices/AssignDevices';
import StepsTitle from '../../StepsTitle';
import Modal from '../../../Modal';
import GlobalState from '../../../../store/reducers/globalState.interface';
import Tooltip from '../../../Tooltip';
import { UserRole } from '../../../../enums/userRole.enum';

enum Tab {
  Devices = 'devices',
  DevicesGroups = 'devices-groups',
}

const tabsTranslation = {
  [Tab.Devices]: 'contents.create_content.devices_and_groups_tabs.devices',
  [Tab.DevicesGroups]:
    'contents.create_content.devices_and_groups_tabs.devices_groups',
} as const;

export default ({
  data,
  setData,
  setMode,
  isOptional,
  onSubmit,
  isLoading = false,
  libraryPublish,
  isPowerBiPublished,
}: {
  data: Partial<ScheduleContentRequestData>;
  setData: (newData: Partial<ScheduleContentRequestData>) => void;
  setMode: (mode: ScheduleContentSteps) => void;
  isOptional?: boolean;
  onSubmit?: () => void;
  isLoading?: boolean;
  libraryPublish?: boolean;
  isPowerBiPublished?: boolean;
}) => {
  const { t } = useTranslation();
  const { isTrial, roles } = useSelector(
    (state: GlobalState) => state.userData.user,
  );

  const [currentTab, setCurrentTab] = useState(Tab.Devices);
  const [currentPlaylistChecked, setCurrentPlaylistChecked] =
    useState<boolean>(true);
  const [currentPlaylistDisabled, setCurrentPlaylistDisabled] =
    useState<boolean>(true);
  let currentTable: React.ReactNode;
  const EnabledRoles = [
    roles?.includes(UserRole.SuperAdmin),
    roles?.includes(UserRole.IndigoAdmin),
    roles?.includes(UserRole.IndigoEmployee),
  ];
  const handleCurrentPlaylistChange = () => {
    setData({ ...data, onlyCurrentSelected: !currentPlaylistChecked });
    setCurrentPlaylistChecked(!currentPlaylistChecked);
  };

  const handleNextStep = (step: ScheduleContentSteps) => {
    if (currentTab === Tab.Devices && !data.deviceIds?.length) {
      setData({ ...data, onlyCurrentSelected: false });
    }
    setMode(step);
  };

  useEffect(
    () =>
      setData({
        ...data,
        onlyCurrentSelected: true,
      }),
    [],
  );
  switch (currentTab) {
    case Tab.Devices:
      currentTable = (
        <AssignDevices
          preselectedItems={data?.deviceIds ?? []}
          onSelectionChange={(selectedItems) => {
            setData({ ...data, deviceIds: selectedItems });
            setCurrentPlaylistDisabled(!selectedItems.length);
          }}
          isForPublish
          libraryPublish={libraryPublish}
          isPowerBiPublished={isPowerBiPublished}
        />
      );
      break;
    case Tab.DevicesGroups:
      currentTable = (
        <AssignDevicesGroups
          preselectedItems={data?.deviceGroupIds ?? []}
          onSelectionChange={(selectedItems) => {
            setData({ ...data, deviceGroupIds: selectedItems });
            setCurrentPlaylistDisabled(!selectedItems.length);
          }}
          isPowerBiPublished={isPowerBiPublished}
        />
      );
      break;
    default:
      currentTable = '';
  }

  return (
    <>
      {isPowerBiPublished && (
        <Alert
          key="warning"
          variant="warning"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <i
            className="bi bi-exclamation-triangle-fill"
            style={{ fontWeight: 'bolder', fontSize: '20px' }}
          />
          <span
            style={{
              fontWeight: 'bolder',
              fontSize: '1em',

              marginLeft: '10px',
            }}
          >
            Power BI is only available to enterprise devices and device groups.
          </span>
        </Alert>
      )}
      <StepsTitle currentStep={ScheduleContentSteps.Devices} />

      <div className="d-flex justify-content-center mb-5">
        {Object.values(Tab).map((tab, index) =>
          isTrial && tab === Tab.DevicesGroups ? (
            <Tooltip
              text={t('devices_groups.trial_plan')}
              className={clsx(
                'btn w-100 py-4',
                tab === currentTab
                  ? 'btn-bg-light btn-color-primary'
                  : 'btn-bg-secondary btn-color-muted',
                !index && 'me-7',
              )}
            >
              <span key={tab} role="button" tabIndex={-1}>
                {t(tabsTranslation[tab])}
                <i
                  className="bi bi-lock-fill ms-3"
                  style={{ fontSize: '1.4rem' }}
                />
              </span>
            </Tooltip>
          ) : (
            <span
              key={tab}
              role="button"
              tabIndex={-1}
              className={clsx(
                'btn w-100 py-4',
                tab === currentTab
                  ? 'btn-bg-light btn-color-primary'
                  : 'btn-bg-secondary btn-color-muted',
                !index && 'me-7',
              )}
              onClick={() => setCurrentTab(tab)}
              onKeyPress={() => setCurrentTab(tab)}
            >
              {t(tabsTranslation[tab])}
            </span>
          ),
        )}
      </div>

      {currentTable}

      <Modal.Separator withoutDefaultMargins className="mt-6 mb-7" />
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-white text-primary"
          onClick={() => setMode(ScheduleContentSteps.Info)}
        >
          {t('common.prev_step')}
        </button>
        {/* {currentTab === Tab.Devices && ( */}
        {!currentPlaylistDisabled && (
          <div className="form-check my-2">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              checked={currentPlaylistChecked}
              onChange={handleCurrentPlaylistChange}
              disabled={currentPlaylistDisabled}
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              {t('contents.send_current_playlist')}
            </label>
          </div>
        )}

        {!currentPlaylistChecked || currentPlaylistDisabled ? (
          <button
            disabled={
              !isOptional &&
              !data.deviceIds?.length &&
              !data.deviceGroupIds?.length &&
              (!EnabledRoles || isPowerBiPublished)
            }
            type="submit"
            className="btn btn-primary"
            onClick={() => handleNextStep(ScheduleContentSteps.Playlists)}
          >
            {t('common.next_step')}
          </button>
        ) : (
          <button
            type="button"
            disabled={isLoading}
            className="btn btn-primary"
            onClick={onSubmit}
          >
            {t('common.submit')}
          </button>
        )}
      </div>
    </>
  );
};
