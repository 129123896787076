import clsx from 'clsx';
import React from 'react';
import AddSevereWeatherSteps from '../../../../../enums/steps/addSevereWeatherSteps.enum';

export default <T extends string>({
  currentStep,
  className,
}: {
  currentStep: T;
  className?: string;
}) => {
  const addSevereWeatherSteps = {
    [AddSevereWeatherSteps.CreateSevereWeatherLocation]:
      'Choose Name, Select Country and Counties',
    [AddSevereWeatherSteps.AssignSevereWeatherLocation]: 'Assign an Alert',
  };

  return (
    <div
      className={clsx(
        'flex-row-fluid stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid',
        className,
      )}
    >
      <div className="d-flex justify-content-xl-start flex-row-auto w-100 w-xl-300px">
        <div className="stepper-nav ps-lg-10">
          {Object.entries(addSevereWeatherSteps).map(
            ([step, description], index) => (
              <div
                key={step}
                className={clsx(
                  'stepper-item',
                  step === currentStep && 'current',
                )}
              >
                <div className="stepper-line w-30px" />

                <div className="stepper-icon w-40px h-40px">
                  <i className="stepper-check fas fa-check" />
                  <span className="stepper-number">{index + 1}</span>
                </div>

                <div className="stepper-label">
                  <h3 className="stepper-title">{step}</h3>
                  <div className="stepper-desc">{description}</div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};
