/* eslint-disable object-shorthand */
import axios from 'axios';
import { API_URL } from '../../../constants/api';
import Device from '../../../interfaces/devices/device.interface';
import IPlaylist from '../../../interfaces/playlist.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import DevicesRoutes from './enums/devicesRoutes.enum';
import BulkUpdateDevicesRequestDataInterface from './interfaces/bulkUpdateDevicesRequestData.interface';
import GetDevicesCount from './interfaces/devicesCounts.interface';
import GetDevicesCountRequestData from './interfaces/getDevicesCountRequestData.interface';
import GetDevicesListRequestData from './interfaces/getDevicesListRequestData.interface';
import GetDevicesListResponseData from './interfaces/getDevicesListResponseData.interface';
import getDeviceUptimes from './interfaces/getDeviceUptimes.interface';
import UpdateDeviceRequestData from './interfaces/updateDeviceRequestData.interface';

export default {
  /**
   * Retrieves the devices list
   * @param requestData
   */
  getDevicesList: async (
    requestData: GetDevicesListRequestData,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.Devices}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  getCurrentPlaylistIdsByDevicesGroups: async (
    deviceGroupsIds: string[],
  ): Promise<any> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.Devices}/currentPlaylists/deviceGroups`,
      params: sanitizeQueryParams({ deviceGroupsIds }),
    });

    return data;
  },

  /**
   * Bulk edit license of devices
   * @param requestData
   */

  bulkEditLicenseDevices: async (
    requestData: GetDevicesListRequestData,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}/license`,
      data: requestData,
    });
    return data;
  },
  /**
   * Retrieves the list device group devices by current user id
   * @param requestData
   *
   */

  getDeviceGroupDevicesAP: async (
    requestData: GetDevicesListRequestData,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  getDeviceGroupeDevicesByCurrentUserId: async (
    requestData: GetDevicesListRequestData,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Retrieves device by Id
   * @param id
   */
  getDeviceById: async (id: string): Promise<Device> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.Devices}/${id}`,
    });

    return data;
  },

  /**
   * Retrieves device by Id for devices charts
   * @param id
   */
  getDeviceByIdForLastOffline: async (id: string): Promise<Device> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.Devices}/analytics/${id}`,
    });

    return data;
  },

  /**
   * Retrieves the devices list
   *  @param requestData
   */
  getAdminDevicesList: async (
    requestData: GetDevicesListRequestData,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Retrieves the devices list
   *  @param requestData
   */
  getAllAdminDevicesListPage: async (
    requestData: GetDevicesListRequestData,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.PageAdmingAllDevicesList}`,
      params: sanitizeQueryParams(requestData),
    });
    return data;
  },
  /**
   * Retrieves recently offline/online devices
   *  @param requestData
   */
  getAdminRecentlyDevicesList: async (
    requestData: GetDevicesListRequestData,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.Analytics}/devices`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Updates the device by id
   * @param requestData
   * @param deviceId
   */
  updateDevice: async (
    requestData: UpdateDeviceRequestData,
    deviceId: string,
  ): Promise<Device> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${DevicesRoutes.Devices}/${deviceId}`,
      data: requestData,
    });

    return data;
  },

  /**
   *  Updates the device by id (admin)
   * @param requestData
   * @param deviceId
   */
  adminUpdateDevice: async (
    requestData: UpdateDeviceRequestData,
    deviceId: string,
  ): Promise<Device> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}/${deviceId}`,
      data: requestData,
    });

    return data;
  },

  /**
   * Bulk updating devices
   * @param requestData
   */
  bulkUpdateDevices: async (
    requestData: Partial<BulkUpdateDevicesRequestDataInterface>,
  ): Promise<Device> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${DevicesRoutes.Devices}/bulk`,
      data: requestData,
    });

    return data;
  },

  /**
   * Bulk updating admin devices
   * @param requestData
   */
  bulkUpdateAdminDevices: async (
    requestData: BulkUpdateDevicesRequestDataInterface,
  ): Promise<Device> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}`,
      data: requestData,
    });

    return data;
  },

  /**
   * Assign device to device group
   * @param deviceId
   * @param deviceGroupId
   */
  assignDeviceToDeviceGroup: async (
    deviceId: string,
    deviceGroupId: string,
  ) => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${DevicesRoutes.Devices}/${deviceId}/groups/${deviceGroupId}`,
    });

    return data;
  },

  /**
   * Assign device to user group
   * @param deviceId
   * @param userGroupId
   */
  assignDeviceToUserGroup: async (
    deviceId: string | undefined,
    userGroupId: string,
  ) => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${DevicesRoutes.Devices}/${deviceId}/usergroups/${userGroupId}`,
    });

    return data;
  },

  /**
   * Unassign device from user group
   * @param deviceId
   * @param userGroupId
   */
  unAssignDeviceFromUserGroup: async (
    deviceId: string,
    userGroupId: string,
  ): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesRoutes.Devices}/${deviceId}/usergroups/${userGroupId}`,
    });
  },

  /**
   * Unassign device from device group
   * @param deviceId
   * @param userGroupId
   */
  unAssignDeviceFromDeviceGroup: async (
    deviceId: string,
    userGroupId: string,
  ): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesRoutes.Devices}/${deviceId}/groups/${userGroupId}`,
    });
  },

  /**
   * Unassign device from playlist
   * @param deviceId
   * @param playlistId
   */
  unAssignDeviceFromPlaylist: async (
    deviceId: string,
    playlistId: string,
  ): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesRoutes.Devices}/${deviceId}/playlists/${playlistId}`,
    });
  },

  /**
   * Removes the device by id
   * @param id
   */
  deleteDevice: async (id: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}/${id}`,
    });
  },

  /**
   * Creates a new device
   * @param requestData
   */
  createDevice: async (requestData: FormData): Promise<Device> => {
    const { data } = await axios.post(
      `${API_URL}/${DevicesRoutes.AdminDevices}`,
      requestData,
    );

    return data;
  },

  getPlaylists: async ({
    deviceId,
    priority,
    endDate,
    startDate,
  }: {
    deviceId?: string;
    priority: number;
    endDate: Date;
    startDate: Date;
  }): Promise<IPlaylist[]> => {
    const { data } = await axios.get(
      `${API_URL}/${DevicesRoutes.Devices}/${deviceId}/playlists`,
      {
        params: sanitizeQueryParams({ priority, startDate, endDate }),
      },
    );

    return data;
  },

  getCustomerDevicesList: async (
    id: string,
    requestData: GetDevicesListRequestData,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}/owner/${id}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  // --------------------------- User Profile

  /**
   * Assign device to user
   * @param deviceIds
   * @param userId
   */
  assignDevicesToOwner: async (deviceIds: string[], userId: string) => {
    const { data } = await axios({
      method: 'put',
      params: {
        deviceIds,
      },
      url: `${API_URL}/${DevicesRoutes.AdminDevices}/{deviceId}/owner/${userId}`,
    });

    return data;
  },
  /**
   * Unassign device from user
   * @param deviceId
   * @param userId
   */
  unAssignDeviceFromUser: async (
    deviceId: string,
    userId: string,
  ): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}/${deviceId}/owner/${userId}`,
    });
  },
  /**
   * Unassign device from user in user panel
   * @param deviceId
   * @param userId
   */
  unAssignDeviceFromUserUP: async (
    deviceId: string,
    userId: string,
  ): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${DevicesRoutes.Devices}/${deviceId}/users/${userId}`,
    });
  },

  /**
   *
   * devices Count
   */
  getDevicesCount: async (
    requestData: GetDevicesCountRequestData,
  ): Promise<GetDevicesCount> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}/uptime/count`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * get Devices Uptime
   * @param deviceId
   */
  getDevicesUptime: async (
    deviceId: string,
    limit: number,
  ): Promise<getDeviceUptimes> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DevicesRoutes.AdminDevices}/uptime/${deviceId}`,
      params: { limit: limit },
    });

    return data;
  },
};
