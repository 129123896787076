import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import UserGroupInfo from '../../components/UsersGroups/UserGroupInfo';
import api from '../../api';
import {
  readAllFromQueryParams,
  readFromQueryString,
} from '../../utils/queryParams';
import Device from '../../interfaces/devices/device.interface';
import UpdateUsersGroupRequestData from '../../api/endpoints/usersGroups/interfaces/updateUsersGroupRequestData.interface';
import CreateUserRequestData from '../../api/endpoints/users/interfaces/createUserRequestData.interface';
import { createFormData } from '../../utils/formData';
import DeviceGroup from '../../interfaces/deviceGroup.interface';
import User from '../../interfaces/user/user.interface';
import UpdateDeviceGroupAction from '../../store/actions/devicesGroups/interfaces/updateDeviceGroupAction.interface';
import UpdateDeviceRequestData from '../../api/endpoints/devices/interfaces/updateDeviceRequestData.interface';
import { SelectedValue } from '../../components/Select/SelectAsync';
import GlobalState from '../../store/reducers/globalState.interface';
import BulkUpdateUsersRequestDataInterface from '../../api/endpoints/users/interfaces/bulkUpdateUsersRequestData.interface';
import { UserRole } from '../../enums/userRole.enum';
import { debouncedUpdateSearch } from '../../utils/browserHistory';

type FormInputs = Partial<UpdateUsersGroupRequestData>;

export default () => {
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const [userGroupInfo, setUserGroupInfo] = useState({});
  const [isLoadingUserGroupInfo, setIsLoadingUserGroupInfo] =
    useState<boolean>(true);

  const [users, setUsers] = useState<any>([]);
  const [userProfileId, setUserProfileId] = useState<string>();

  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);

  const [devices, setDevices] = useState<Device[]>([]);
  const [isLoadingDevices, setIsLoadingDevices] = useState<boolean>(true);

  const [deviceGroups, setDeviceGroups] = useState<DeviceGroup[]>([]);
  const [isLoadingDeviceGroup, setIsLoadingDeviceGroup] =
    useState<boolean>(true);

  const { search } = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isAdmin = roles.includes(UserRole.SuperAdmin);
  const currentUserId =
    readFromQueryString(search, 'currentUserId') || undefined;
  const getUserGroupInfo = async () => {
    try {
      setIsLoadingUserGroupInfo(true);
      const data = await api.usersGroups.getUsersGroup(id);
      setUserGroupInfo(data);
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingUserGroupInfo(false);
  };

  const getAssignedUsersToUserGroup = async (recentSearch: string) => {
    try {
      if (recentSearch) {
        setIsLoadingUsers(true);
        const { items } = isAdmin
          ? await api.usersGroups.getAdminUsersAssignedToUserGroup(
              { currentUserId, ...readAllFromQueryParams(recentSearch) },
              id,
            )
          : await api.usersGroups.getUsersAssignedToUserGroup(
              readAllFromQueryParams(recentSearch),
              id,
            );
        setUsers(items);
      }
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingUsers(false);
  };

  const getAssignedDevicesToUserGroup = async (recentSearch: string) => {
    try {
      if (recentSearch) {
        setIsLoadingDevices(true);

        const { items } = isAdmin
          ? await api.devices.getAdminDevicesList({
              ...readAllFromQueryParams(recentSearch),
              userGroupId: id,
            })
          : await api.usersGroups.getDevicesAssignedToUserGroup(
              readAllFromQueryParams(recentSearch),
              id,
            );
        setDevices(items);
      }
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingDevices(false);
  };

  const getAssignedDeviceGroupsToUserGroup = async (recentSearch: string) => {
    try {
      setIsLoadingDeviceGroup(true);

      const { items } = isAdmin
        ? await api.devicesGroups.getAdminDevicesGroups({
            ...readAllFromQueryParams(recentSearch),
            userGroupId: id,
          })
        : await api.usersGroups.getDeviceGroupsAssignedToUserGroup(
            readAllFromQueryParams(recentSearch),
            id,
          );
      setDeviceGroups(items);
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingDeviceGroup(false);
  };

  useEffect(() => {
    setIsLoadingUserGroupInfo(true);
    getUserGroupInfo();
  }, []);

  useEffect(() => {
    setIsLoadingUsers(true);
    getAssignedUsersToUserGroup(search);
  }, [search]);

  useEffect(() => {
    setIsLoadingDevices(true);
    getAssignedDevicesToUserGroup(search);
  }, [search, isAdmin]);

  useEffect(() => {
    setIsLoadingDeviceGroup(true);
    getAssignedDeviceGroupsToUserGroup(search);
  }, [search, isAdmin]);

  const handleEditUserGroup = async (data: FormInputs, userGroupId: string) => {
    try {
      setIsLoadingUserGroupInfo(true);
      await api.usersGroups.updateUsersGroup(data, userGroupId, true);
      await getUserGroupInfo();
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingUserGroupInfo(false);
  };

  const handleRemoveUserGroup = async (userGroupId: string) => {
    try {
      if (isAdmin) {
        await api.usersGroups.AdminDeleteUsersGroup(userGroupId);
        history.goBack();
      } else {
        await api.usersGroups.deleteUsersGroup(userGroupId);
        history.push('/users-groups');
      }
    } catch (e) {
      // Do nothing for now
    }
  };

  const handleEditDeviceInUserGroup = async (data: {
    id: string;
    data: UpdateDeviceRequestData;
  }) => {
    try {
      setIsLoadingDevices(true);

      await api.devices.updateDevice(data.data, data.id);
      setDevices(
        devices.map((device) =>
          device.id === data.id ? { ...device, ...data.data } : device,
        ),
      );
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingDevices(false);
  };

  const handleEditUserInUserGroup = async (
    userId: string,
    data: Partial<CreateUserRequestData>,
  ) => {
    try {
      setIsLoadingUsers(true);
      await api.users.updateUserData(userId, createFormData(data, true));
      setUsers(
        users.map((user: User) =>
          user.id === userId ? { ...user, ...data } : user,
        ),
      );
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingUsers(false);
  };

  const handleEditDeviceGroup = async (
    data: UpdateDeviceGroupAction['payload'],
  ) => {
    try {
      setIsLoadingDeviceGroup(true);

      await api.devicesGroups.updateDeviceGroup(data.data, data.id);
      setDeviceGroups(
        deviceGroups.map((deviceGroup) =>
          deviceGroup.id === data.id
            ? { ...deviceGroup, ...data.data }
            : deviceGroup,
        ),
      );
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingDeviceGroup(false);
  };

  const handleUnAssignDeviceFromUserGroup = async (
    deviceId: string,
    userGroupId: string,
  ) => {
    try {
      setIsLoadingDevices(true);

      await api.devices.unAssignDeviceFromUserGroup(deviceId, userGroupId);
      setDevices(devices.filter((device) => device.id !== deviceId));
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingDevices(false);
  };

  const handleUnAssignUserFromUserGroup = async (
    userId: string,
    userGroupId: string,
  ) => {
    try {
      setIsLoadingUsers(true);
      if (isAdmin) {
        await api.usersGroups.AdminUnAssignUserGroupFromUser(
          userGroupId,
          userId,
        );
      } else {
        await api.usersGroups.unAssignUserFromUserGroup(userGroupId, userId);
      }
      setUsers(users.filter((user: User) => user.id !== userId));
    } catch (e) {
      // Do nothing for now
    }
    setIsLoadingUsers(false);
  };

  const handleAssignUsersToUserGroup = (data: SelectedValue[]) => {
    data.map(async ({ value }) => {
      try {
        setIsLoadingUsers(true);
        await api.usersGroups.assignUserFromUserGroup(id, value);

        toast.success(i18next.t<string>('users.assigned_message'));

        await getAssignedUsersToUserGroup(search);
      } catch (e) {
        setIsLoadingUsers(false);
      }
    });
  };

  const handleAssignDevicesToUserGroup = (data: SelectedValue[]) => {
    data.map(async ({ value }) => {
      try {
        setIsLoadingDevices(true);
        await api.devices.assignDeviceToUserGroup(value, id);

        // Refactor
        const { items } = isAdmin
          ? await api.devices.getAdminDevicesList({
              ...readAllFromQueryParams(search),
              userGroupId: id,
            })
          : await api.devices.getDevicesList({});
        const getUsers = items.filter((item) =>
          data.find((user) => user.value === item.id),
        );
        setDevices([...devices, ...getUsers]);
      } catch (e) {
        // Do nothing for now
      }
      setIsLoadingDevices(false);
    });
  };
  const handleAssignDevicesGroupsToUserGroup = (data: SelectedValue[]) => {
    data.map(async ({ value }) => {
      try {
        setIsLoadingDeviceGroup(true);
        // new assignDeviceToUserGroup api
        await api.devicesGroups.assignDeviceGroupToUserGroup(id, value);
        // Refactor123
        await getAssignedDeviceGroupsToUserGroup(search);
      } catch (e) {
        // Do nothing for now
      }
      setIsLoadingDeviceGroup(false);
    });
  };

  const handleSuspendUser = (userId: string) => {
    setIsLoadingUsers(true);

    api.users
      .suspendUser(userId)
      .then(() => {
        toast.success(i18next.t<string>('users.suspended_message'));
        getAssignedUsersToUserGroup(search);
      })
      .catch(() => {
        setIsLoadingUsers(false);
      });
  };

  const handleRestoreUser = (userId: string) => {
    setIsLoadingUsers(true);

    api.users
      .restoreUser(userId)
      .then(() => {
        toast.success(i18next.t<string>('users.suspended_message'));
        getAssignedUsersToUserGroup(search);
      })
      .catch(() => {
        setIsLoadingUsers(false);
      });
  };

  const handleBulkEditUser = async (
    data: BulkUpdateUsersRequestDataInterface,
  ) => {
    setIsLoadingUsers(true);

    try {
      if (roles.includes(UserRole.SuperAdmin)) {
        await api.users.bulkEditAdminUsers(data);
      } else {
        await api.users.bulkEditUsers(data);
      }

      getAssignedUsersToUserGroup(search);
    } catch (e) {
      setIsLoadingUsers(false);
    }
  };

  const onUnAssignUser = async (userId: string, userGroupId: string) => {
    setIsLoadingUsers(true);

    try {
      await api.usersGroups.unAssignUserFromUserGroup(userGroupId, userId);

      getAssignedUsersToUserGroup(search);
    } catch (e) {
      setIsLoadingUsers(false);
    }
  };

  // const handleAddDeviceGroup = async (data: CreateDeviceGroupRequestData) => {
  //   setIsLoadingDeviceGroup(true);

  //   try {
  //     await api.devicesGroups.createDeviceGroup({
  //       ...data,
  //       userGroupIds: [id],
  //     });

  //     await getAssignedDeviceGroupsToUserGroup(search);
  //   } catch (e) {
  //     setIsLoadingDeviceGroup(false);
  //   }
  // };

  const handleUnAssignDeviceGroupFromUserGroup = async (
    deviceGroupId: string,
    userGroupId: string,
  ) => {
    setIsLoadingDeviceGroup(true);

    try {
      await api.devicesGroups.unAssignUserGroupsFromGroup(
        deviceGroupId,
        userGroupId,
      );

      await getAssignedDeviceGroupsToUserGroup(search);
    } catch (e) {
      setIsLoadingDeviceGroup(false);
    }
  };
  useEffect(() => {
    if (isAdmin) {
      if (readFromQueryString(search, 'currentUserId')) {
        setUserProfileId(String(readFromQueryString(search, 'currentUserId')));
      } else if (userProfileId) {
        debouncedUpdateSearch({
          currentUserId: userProfileId,
        });
      }
    }
  }, [search]);

  return (
    <UserGroupInfo
      id={id}
      users={users}
      currentUserId={currentUserId || userProfileId}
      devices={devices}
      deviceGroups={deviceGroups}
      group={userGroupInfo}
      isLoadingUsers={isLoadingUsers}
      isLoadingUserGroup={isLoadingUserGroupInfo}
      isLoadingDevices={isLoadingDevices}
      isLoadingDeviceGroups={isLoadingDeviceGroup}
      onEditUserGroup={handleEditUserGroup}
      onDeleteUserGroup={handleRemoveUserGroup}
      onEditDevicesInUserGroup={handleEditDeviceInUserGroup}
      onEditUsersInUserGroup={handleEditUserInUserGroup}
      onEditDeviceGroupInUserGroup={handleEditDeviceGroup}
      unAssignDeviceGroupFromUserGroup={handleUnAssignDeviceGroupFromUserGroup}
      unAssignDeviceFromUserGroup={handleUnAssignDeviceFromUserGroup}
      unAssignUserFromUserGroup={handleUnAssignUserFromUserGroup}
      assignUserToUserGroup={handleAssignUsersToUserGroup}
      assignDeviceToUserGroup={handleAssignDevicesToUserGroup}
      assignDevicesGroupsToUserGroup={handleAssignDevicesGroupsToUserGroup}
      onSuspendUser={handleSuspendUser}
      onRestoreUser={handleRestoreUser}
      onBulkEditUser={handleBulkEditUser}
      onUnAssignUser={onUnAssignUser}
      // onAddDeviceGroup={handleAddDeviceGroup}
    />
  );
};
