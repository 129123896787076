import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { omit } from 'lodash';
import { toast } from 'react-toastify';
import api from '../../api';
import { SelectedValue } from '../../components/Select/SelectAsync';
import UserProfileComponent from '../../components/UserProfileComponent';
import User from '../../interfaces/user/user.interface';
import UsersGroup from '../../interfaces/usersGroup.interface';
import Device from '../../interfaces/devices/device.interface';
import CreateUserRequestData from '../../api/endpoints/users/interfaces/createUserRequestData.interface';
import UpdateUsersGroupAction from '../../store/actions/usersGroups/interfaces/updateUsersGroupAction.interface';
import BulkUpdateDevicesRequestDataInterface from '../../api/endpoints/devices/interfaces/bulkUpdateDevicesRequestData.interface';
import UpdateDeviceRequestData from '../../api/endpoints/devices/interfaces/updateDeviceRequestData.interface';
import { LogSessionInterface } from '../../store/reducers/userData/userDataState.interface';
import { readAllFromQueryParams } from '../../utils/queryParams';
import { createFormData } from '../../utils/formData';
import useIsAdmin from '../../utils/hooks/useIsAdmin';
import Company from '../../interfaces/company.interface';
import Location from '../../interfaces/location.interface';

import UpdateDeviceGroupRequestData from '../../api/endpoints/devicesGroups/interfaces/updateDeviceGroupRequestData.interface';
import UserLogs from '../../interfaces/userLogs.interface';
// import { checkAccountOwner } from '../../utils/common';

export default ({ isCustomer }: { isCustomer?: boolean }) => {
  // to avoid calling more than APIs together (location, device, users ...) on the tab after sotring fields) I made a condition based
  // on pathname value from useLocation
  // this is from \UserProfileComponent\index.tsx : const { page } = useParams<{page: 'devices' | 'user-groups' | 'loginsessions' | 'users' | 'location';  }>();
  // if you change one of the page name, we need to change them in the conditions!
  const { search, pathname } = useLocation();
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);
  const [isLoadingDevicesGroup, setIsLoadingDevicesGroup] =
    useState<boolean>(false);
  const [isLoadingDevices, setIsLoadingDevices] = useState<boolean>(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [isLoadingSessions, setIsLoadingSessions] = useState<boolean>(false);
  const [isLoadingUserGroups, setIsLoadingUserGroups] =
    useState<boolean>(false);
  const [isLoadingUserLogs, setIsLoadingUserLogs] = useState<boolean>(false);

  const [user, setUser] = useState<User>({} as User);
  const [loginSessions, setLoginSessions] = useState<LogSessionInterface[]>([]);
  const [userLogs, setUserLogs] = useState<UserLogs[]>([]);
  const [totalUserLogs, setTotalUserLogs] = useState(0);

  const [userGroups, setUserGroups] = useState<UsersGroup[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [devicesGroup, setDevicesGroup] = useState<UsersGroup[]>([]);
  const [usersTotal, setUsersTotal] = useState<number>(0);
  const [deviceGroupTotal, setDeviceGroupTotal] = useState<number>(0);
  const [deviceTotal, setDeviceTotal] = useState<number>(0);
  const [userCompanies, setUserCompanies] = useState<Company[]>();
  const [userLocations, setUserLocations] = useState<Location[]>();
  const currentUserId = useParams<{ id: string }>().id;
  const [isAdmin, isFetchingRole] = useIsAdmin();
  const { id } = useParams<{ id: string }>();

  const getUserCompanies = async (
    companyIds: string[],
    selectedUserId: string,
  ) => {
    try {
      if (companyIds.length) {
        const items = isAdmin
          ? await api.companies.getAdminCompaniesByIds(
              companyIds,
              selectedUserId,
            )
          : await api.companies.getCompaniesByIds(companyIds, selectedUserId);
        setUserCompanies(items);
      } else {
        setUserCompanies([]);
      }
    } catch (error) {
      // be patient
    }
  };

  const getUserLocations = async (
    locationsIds: string[],
    selectedUserId: string,
  ) => {
    try {
      if (locationsIds.length) {
        const items = isAdmin
          ? await api.locations.getAdminLocationsByIds(
              locationsIds,
              selectedUserId,
            )
          : await api.locations.getLocationsByIds(locationsIds, selectedUserId);
        setUserLocations(items);
      } else {
        setUserLocations([]);
      }
    } catch (error) {
      // be patient
    }
  };

  const getUser = async () => {
    try {
      setIsLoadingUser(true);
      const data = await api.users.getUser(id);

      // const isAccountOwner = checkAccountOwner(data?.roles);
      // should be able to return companies and location no matter the role
      // if (isAccountOwner && !isFetchingRole) {
      await getUserCompanies(data.assignedCompanyIds, id);
      await getUserLocations(data.assignedLocationIds, id);
      // }
      setUser(data);
    } catch (e) {
      //  Do nothing for now
    }
    setIsLoadingUser(false);
  };

  const onEditUser = async (
    userId: string,
    data: Partial<CreateUserRequestData>,
  ) => {
    setIsLoadingUser(true);

    const formData = createFormData(
      user.isSocial ? omit(data, ['email']) : data,
      true,
    );
    try {
      if (isAdmin) {
        await api.users.updateAdminUser(userId, formData);
      } else {
        await api.users.updateUserData(userId, formData);
      }
      await getUser();
    } catch (e) {
      setIsLoadingUser(false);
    }
  };

  const getAssignedUserGroupsToUser = async (recentSearch: string) => {
    if (pathname.includes('user-groups')) {
      try {
        setIsLoadingUserGroups(true);
        const { items } = await api.users.getUserGroupsAssignedToUser(
          readAllFromQueryParams(recentSearch),
          id,
        );
        setUserGroups(items);
      } catch (error) {
        //  be patient for now!
      }
      setIsLoadingUserGroups(false);
    }
  };

  const getAssignedDevicesToUser = async (recentSearch: string) => {
    if (pathname.includes('devices')) {
      try {
        setIsLoadingDevices(true);
        const queryParams = readAllFromQueryParams(recentSearch);
        const res = isAdmin
          ? await api.devices.getAdminDevicesList({
              ...queryParams,
              currentUserId: /* queryParams?.companyId ? '' :  */ id,
            })
          : await api.users.getDevicesAssignedToUser(queryParams, id);

        setDeviceTotal(res.meta.totalItems);
        setDevices(res.items);
      } finally {
        setIsLoadingDevices(false);
      }
    }
  };

  const getAssignedUsersToUser = async (recentSearch: string) => {
    if (pathname.includes('users')) {
      try {
        setIsLoadingUsers(true);

        const { items, meta } = await api.users.getCustomerUsersListV2(
          id,
          readAllFromQueryParams(recentSearch),
        );

        setUsers(items);
        setUsersTotal(meta.totalItems);
      } finally {
        setIsLoadingUsers(false);
      }
    }
  };
  const getDevicesGroupe = async (recentSearch: string) => {
    if (pathname.includes('devicesGroup')) {
      try {
        setIsLoadingDevicesGroup(true);
        const queryParams = readAllFromQueryParams(recentSearch);
        const res = await api.devicesGroups.getAdminDevicesGroupList({
          ...queryParams,
          currentUserId: queryParams?.companyId ? '' : id,
        });
        setDeviceGroupTotal(res.meta.totalItems);
        setDevicesGroup(res.items);
      } finally {
        setIsLoadingDevicesGroup(false);
      }
    }
  };

  const getAssignedSessionsToUser = async () => {
    try {
      setIsLoadingSessions(true);
      const { items } = await api.users.getLoginSessionsAssignedToUser(id);
      setLoginSessions(items);
    } catch (error) {
      // be patient for now!
    }
    setIsLoadingSessions(false);
  };

  const getAssignedLogsToUser = async () => {
    try {
      setIsLoadingUserLogs(true);
      const res = await api.usersLogs.getUsersLog({
        limit: '5',
        userId: user?.id ?? id,
        ...readAllFromQueryParams(search),
      });
      setUserLogs(res?.items);
      setTotalUserLogs(res?.meta.totalItems);
    } catch (error) {
      console.log(error);
    }
    setIsLoadingUserLogs(false);
  };
  const onAddGroup = async (data: any) => {
    if (pathname.includes('customers')) {
      try {
        await api.devicesGroups.addDeviceGroupsAndAssignToOwner(
          data.name,
          currentUserId,
          data.userIds,
          data.deviceIds,
        );
        await getDevicesGroupe(search);
      } catch (e) {
        console.log(e);
        // be patient for now
      }
    }
  };
  useEffect(() => {
    setIsLoadingUser(true);
    getUser();
  }, [isFetchingRole, id]);

  useEffect(() => {
    setIsLoadingUserGroups(true);
    getAssignedUserGroupsToUser(search);
  }, [search]);

  useEffect(() => {
    getDevicesGroupe(search);
  }, [search]);

  useEffect(() => {
    if (isCustomer) {
      getAssignedUsersToUser(search);
    }
  }, [search, isCustomer]);

  useEffect(() => {
    setIsLoadingDevices(true);

    if (!isFetchingRole) {
      getAssignedDevicesToUser(search);
    }
  }, [search, isFetchingRole]);

  useEffect(() => {
    setIsLoadingSessions(true);
    getAssignedSessionsToUser();
    setIsLoadingUserLogs(true);
    getAssignedLogsToUser();
  }, []);

  //  ------------------------------------------------------
  //  user groups assigned to user
  //   edit + //  assign - //  unassign -
  //  ------------------------------------------------------

  const onEditUserGroupInUserProfile = async (
    data: UpdateUsersGroupAction['payload'],
  ) => {
    try {
      setIsLoadingUserGroups(true);
      await api.usersGroups.updateUsersGroup(data.data, data.id);
      getAssignedUserGroupsToUser(search);
    } catch (e) {
      //  be patient for now!
    }
    setIsLoadingUserGroups(false);
  };
  const assignUserGroupInUserProfile = async (data: SelectedValue[]) => {
    data.map(async ({ value }) => {
      try {
        setIsLoadingUserGroups(true);

        if (isAdmin) {
          await api.usersGroups.adminAssignUserGroupToUser(value, id);
        } else {
          await api.usersGroups.assignUserGroupToUser(value, id);
        }

        await getAssignedUserGroupsToUser(search);
      } catch (e) {
        // be patient for now
      }
      setIsLoadingUserGroups(false);
    });
  };
  // comment unassign usergroup admin
  // const unAssignUserGroupInUserProfile = async (
  //   _userGroupId: string,
  //   userId: string,
  // ) => {
  //   setIsLoadingUserGroups(true);
  //   try {
  //     await api.usersGroups.unAssignUserGroupFromUser(_userGroupId, userId);
  //     getAssignedUserGroupsToUser(search);
  //   } catch (e) {
  //     // be patient for now
  //   }
  //   setIsLoadingUserGroups(false);
  // };

  const handleRemoveUserGroup = async (userGroupId: string) => {
    setIsLoadingUserGroups(true);
    try {
      if (isAdmin) {
        await api.usersGroups.AdminDeleteUsersGroup(userGroupId);
      } else {
        await api.usersGroups.deleteUsersGroup(userGroupId);
      }
    } catch (e) {
      // Do nothing for now
    }
    await getAssignedUserGroupsToUser(search);
    setIsLoadingUserGroups(false);
  };

  //  ------------------------------------------------------
  //  devices assigned to user
  //   edit + //  assign - //  unassign -
  //  ------------------------------------------------------

  const onEditDevicesInUserProfile = async (data: {
    id: string;
    data: UpdateDeviceRequestData;
  }) => {
    try {
      setIsLoadingDevices(true);

      if (isAdmin) {
        await api.devices.adminUpdateDevice(data.data, data.id);
      } else {
        await api.devices.updateDevice(data.data, data.id);
      }

      await getAssignedDevicesToUser(search);
    } finally {
      setIsLoadingDevices(false);
    }
  };

  const unAssignDeviceFromUserProfile = async (
    deviceId: string,
    userId: string,
  ) => {
    setIsLoadingDevices(true);
    try {
      if (isAdmin) {
        await api.devices.unAssignDeviceFromUser(deviceId, userId);
      } else {
        await api.devices.unAssignDeviceFromUserUP(deviceId, userId);
      }
      getAssignedDevicesToUser(search);
    } catch (e) {
      // be patient for now
      setIsLoadingDevices(false);
    }
  };

  const assignDeviceToUserProfile = async (data: SelectedValue[]) => {
    const deviceIds: (string | undefined)[] = data.map((item) => item.value);
    try {
      setIsLoadingDevices(true);
      await api.devices.assignDevicesToOwner(deviceIds as string[], id);
      getAssignedDevicesToUser(search);
    } catch (e) {
      setIsLoadingDevices(false);
    }
  };

  const onBulkEditDevice = async (
    data: Partial<BulkUpdateDevicesRequestDataInterface>,
  ) => {
    setIsLoadingDevices(true);

    try {
      if (isAdmin) {
        await api.devices.bulkUpdateAdminDevices(
          data as BulkUpdateDevicesRequestDataInterface,
        );
      } else {
        await api.devices.bulkUpdateDevices(data);
      }

      await getAssignedDevicesToUser(search);
    } catch (e) {
      setIsLoadingDevices(false);
    }
  };

  const onCreateOwnedUser = async (data: CreateUserRequestData) => {
    setIsLoadingUsers(true);

    try {
      await api.users.postAdminUser(createFormData(data, true), user.id);

      await getAssignedUsersToUser(search);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const onEditOwnedUser = async (
    userId: string,
    data: Partial<CreateUserRequestData>,
  ) => {
    setIsLoadingUsers(true);

    try {
      await api.users.updateAdminUser(userId, createFormData(data, true));

      await getAssignedUsersToUser(search);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const onEditDeviceGroupInUserProfile = async (data: {
    id: string;
    data: UpdateDeviceGroupRequestData;
  }) => {
    try {
      setIsLoadingDevicesGroup(true);
      await api.devicesGroups.updateDeviceGroup(data.data, data.id);
      await getDevicesGroupe(search);
    } finally {
      setIsLoadingDevicesGroup(false);
    }
  };
  const removeDeviceGroup = async (deviceGroupId: string) => {
    setIsLoadingDevices(true);
    try {
      await api.devicesGroups.deleteDeviceGroup(deviceGroupId);
      await getDevicesGroupe(search);
      setIsLoadingDevices(false);
    } catch (e) {
      // be patient for now
      setIsLoadingDevices(false);
    }
  };
  const onSuspendOwnedUser = async (userId: string) => {
    setIsLoadingUsers(true);

    try {
      await api.customers.suspendCustomer(userId);

      await getAssignedUsersToUser(search);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const onDeleteOwnedUser = async (userId: string) => {
    setIsLoadingUsers(true);

    try {
      await api.users.deleteAdminUser(userId);

      await getAssignedUsersToUser(search);
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const onRestoreOwnedUser = async (userId: string) => {
    setIsLoadingUsers(true);

    try {
      await api.users.adminRestoreUser(userId);

      await getAssignedUsersToUser(search);
    } finally {
      setIsLoadingUsers(false);
    }
  };
  const addUserGroup = async (data: any) => {
    try {
      setIsLoadingUserGroups(true);
      await api.usersGroups.createUsersGroupAP(data);

      await getAssignedUserGroupsToUser(search);
      setIsLoadingUserGroups(false);
    } catch (error) {
      toast.error('error');
    }
  };
  return (
    <UserProfileComponent
      onUpdateLicense={() => getAssignedDevicesToUser(search)}
      id={id}
      deviceTotal={deviceTotal}
      devices={devices}
      user={user}
      getUser={getUser}
      users={users}
      removeDeviceGroup={removeDeviceGroup}
      isLoadingUsers={isLoadingUsers}
      userGroups={userGroups}
      loginsessions={loginSessions}
      isLoadingDevices={isLoadingDevices}
      isLoadingDeviceGroups={isLoadingDevicesGroup}
      deviceGroupTotal={deviceGroupTotal}
      devicesGroups={devicesGroup}
      onAddGroup={onAddGroup}
      isLoadingUserGroup={isLoadingUserGroups}
      isLoadingSessions={isLoadingSessions}
      isLoadingUser={isLoadingUser}
      onEditUser={onEditUser}
      onEditDevicesInUserProfile={onEditDevicesInUserProfile}
      unAssignDeviceFromUserProfile={unAssignDeviceFromUserProfile}
      assignDeviceToUserProfile={assignDeviceToUserProfile}
      onEditDeviceGroupInUserProfile={onEditDeviceGroupInUserProfile}
      onEditUserGroupInUserProfile={onEditUserGroupInUserProfile}
      // unAssignUserGroupInUserProfile={unAssignUserGroupInUserProfile}
      onDeleteUserGroup={handleRemoveUserGroup}
      assignUserGroupInUserProfile={assignUserGroupInUserProfile}
      onBulkEditDevice={onBulkEditDevice}
      isCustomer={isCustomer}
      isLoadingUserLogs={isLoadingUserLogs}
      userLogs={userLogs}
      usersTotal={usersTotal}
      userCompanies={userCompanies}
      userLocations={userLocations}
      userActions={{
        onEdit: onEditOwnedUser,
        onCreate: onCreateOwnedUser,
        onSuspend: onSuspendOwnedUser,
        onDelete: onDeleteOwnedUser,
        onRestore: onRestoreOwnedUser,
      }}
      addUserGroup={addUserGroup}
      totalUserLogs={totalUserLogs}
      onUpdateUserLogs={() => getAssignedLogsToUser()}
    />
  );
};
