enum WeatherColor {
  BlueMulticolor = 'blueMulticolor',
  Green = 'green',
  NavyBlue = 'navyBlue',
  Orange = 'orange',
  PinkMulticolor = 'pinkMulticolor',
  Purple = 'purple',
  PurpleMulticolor = 'purpleMulticolor',
  Red = 'red',
  SkyBlue = 'skyBlue',
  SkyblueGradient = 'skyblueGradient',
  Widget = 'widget',
}

export default WeatherColor;
