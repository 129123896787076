import axios from 'axios';
import { API_URL } from '../../../constants/api';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import GetDevicesListRequestData from '../devices/interfaces/getDevicesListRequestData.interface';
import UserWithRelationsIdsInterface from '../../../interfaces/user/userWithRelationsIds.interface';
import GetDevicesListResponseData from '../devices/interfaces/getDevicesListResponseData.interface';
import GetUsersGroupsRequestData from '../usersGroups/interfaces/getUsersGroupsRequestData.interface';
import GetUsersGroupsResponseData from '../usersGroups/interfaces/getUsersGroupsResponseData.interface';
import GetUserDevicesListResponseData from './interfaces/getUserDevicesListResponseData.interface';
import GetUserDevicesListRequestData from './interfaces/getUserDevicesRequestData.interface';
import BulkUpdateUsersRequestData from './interfaces/bulkUpdateUsersRequestData.interface';
import GetUsersListResponseData from './interfaces/getUsersListResponseData.interface';
import UpdateUserRequestData from './interfaces/updateUserRequestData.interface';
import GetUsersRequestData from './interfaces/getUsersRequestData.interface';
import UsersRoutes from './enums/usersRoutes.enum';
import UsersQueryParam from './enums/usersQueryParam.enum';

export default {
  /**
   * Retrieves the list of users
   * @param requestData
   */
  getUsersList: async (
    requestData: GetUsersRequestData,
  ): Promise<GetUsersListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of users
   * @param requestData
   */
  getUsersListForAssignV2: async (
    requestData: GetUsersRequestData,
  ): Promise<GetUsersListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}/assign-users/v2`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of users V2
   * @param requestData
   */
  getUsersListV2: async (
    requestData: GetUsersRequestData,
  ): Promise<GetUsersListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}/v2`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  getAdminUsersList: async (
    requestData: GetUsersRequestData,
  ): Promise<GetUsersListResponseData> => {
    const params = sanitizeQueryParams(requestData);

    if (!params.userType) {
      params.userType = 'isIndigoEmployee';
    }

    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.AdminUsers}`,
      params,
    });

    return data;
  },

  getAdminUsersForUserGroupInfo: async (
    managerId?: string,
  ): Promise<GetUsersListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.AdminUsers}/manager/${managerId}`,
    });
    return data;
  },
  /**
   * Retrieves the user data by id
   * @param userId
   */
  getUser: async (userId: string): Promise<UserWithRelationsIdsInterface> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}/${userId}`,
    });

    return data;
  },

  /**
   * Retrieves the user data by id for admin
   * @param userId
   */
  getUserForAdmin: async (
    userId: string,
  ): Promise<UserWithRelationsIdsInterface> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.AdminUsers}/${userId}`,
    });

    return data;
  },

  /**
   * Retrieves the playlist's devices
   * @param requestData
   * @param userId
   */
  getUserDevices: async (
    requestData: GetUserDevicesListRequestData,
    userId: string,
  ): Promise<GetUserDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}/${userId}/devices`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Creates new user
   * @param requestData
   */
  createUser: async (requestData: FormData): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${UsersRoutes.Users}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: requestData,
      params: { resetPassword: requestData.get('resetPassword') },
    });
  },

  postAdminUser: async (
    requestData: FormData,
    ownerId?: string,
  ): Promise<any> => {
    if (ownerId) {
      requestData.append('ownerId', ownerId);
    }

    const { data } = await axios({
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: `${API_URL}/${UsersRoutes.AdminUsers}`,
      data: requestData,
      params: { [UsersQueryParam.ManagerId]: ownerId },
    });

    return data;
  },

  /**
   * Updates the admin's user by id
   * @param userId
   * @param requestData
   */
  updateAdminUser: async (
    userId: string,
    requestData: FormData,
  ): Promise<any> => {
    const { data } = await axios({
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      url: `${API_URL}/${UsersRoutes.AdminUsers}/${userId}`,
      data: requestData,
    });

    return data;
  },

  deleteAdminUser: async (id: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${UsersRoutes.AdminUsers}/${id}`,
    });
  },

  updateUser: async (
    requestData: UpdateUserRequestData,
    userId: string,
  ): Promise<any> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${UsersRoutes.Users}/${userId}`,
      data: requestData,
    });

    return data;
  },

  /**
   * Updates the user data
   * @param userId
   * @param requestData
   */
  updateUserData: async (
    userId: string,
    requestData: FormData,
  ): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${UsersRoutes.Users}/${userId}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: requestData,
    });
  },

  /**
   * Removes the user by id
   * @param id
   */
  deleteUser: async (id: string): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${UsersRoutes.Users}/${id}/disable`,
    });
  },

  /**
   * Removes the user by id permanently
   * @param id
   */
  permanentlyDeleteUser: async (id: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${UsersRoutes.Users}/${id}`,
    });
  },

  /**
   * Suspends the user by id
   * @param id
   */
  suspendUser: async (id: string): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${UsersRoutes.Users}/${id}/ban`,
    });
  },

  /**
   * Restores the user by id
   * @param id
   */
  restoreUser: async (id: string): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${UsersRoutes.Users}/${id}/activate`,
    });
  },

  RemoveUsersFromDeviceGroup: async (
    deviceGroupeId: string,
    users: string[],
  ): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${UsersRoutes.DeviceGroupe}/${deviceGroupeId}/users`,
      params: { userIds: users },
    });
  },
  /**
   * Restores the user by id (for admin)
   * @param id
   */
  adminRestoreUser: async (id: string): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${UsersRoutes.AdminUsers}/${id}/activate`,
    });
  },

  /**
   * Resets the user password
   * @param id
   */
  resetUserPassword: async (id: string): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${UsersRoutes.Users}/${id}/reset-password`,
    });
  },

  /**
   * Upgrade trial user
   * @param id
   */
  upgradeUser: async (id: string): Promise<void> => {
    await axios({
      method: 'PUT',
      url: `${API_URL}/${UsersRoutes.AdminUsers}/trialUser/${id}`,
    });
  },

  /**
   * Bulk update users
   * @params requestData
   */
  bulkEditUsers: async (
    requestData: BulkUpdateUsersRequestData,
  ): Promise<void> => {
    await axios({
      method: 'put',
      url: `${API_URL}/${UsersRoutes.Users}`,
      data: requestData,
    });
  },

  /**
   * Bulk update admin users
   * @params requestData
   */
  bulkEditAdminUsers: async (
    requestData: BulkUpdateUsersRequestData,
  ): Promise<void> => {
    await axios({
      method: 'put',
      url: `${API_URL}/${UsersRoutes.AdminUsers}`,
      data: requestData,
    });
  },

  /**
   * Receives customer device groups
   * @param id
   * @param requestData
   */
  getCustomerUsersList: async (
    id: string,
    requestData: GetUsersRequestData,
  ): Promise<GetUsersListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.AdminUsers}/manager/${id}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Receives customer device groups
   * @param id
   * @param requestData
   */
  getCustomerUsersListV2: async (
    id: string,
    requestData: GetUsersRequestData,
  ): Promise<GetUsersListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.AdminUsers}/manager/${id}/v2`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  // ------------------- UserProfile Page

  // devices

  /**
   * Retrieves the list of devices relates to current user
   *  @param requestData
   *  @param id
   */
  getDevicesAssignedToUser: async (
    requestData: GetDevicesListRequestData,
    id: string,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}/${id}/devices`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of login sessions relates to current user
   *  @param id
   */
  getLoginSessionsAssignedToUser: async (id: string) => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}/${id}/loginsessions`,
      // params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  // user-group

  /**
   * Retrieves the list of user groups relates to current user
   * @param requestData
   * @param id
   */
  getUserGroupsAssignedToUser: async (
    requestData: GetUsersGroupsRequestData,
    id: string,
  ): Promise<GetUsersGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersRoutes.Users}/${id}/groups`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
};
