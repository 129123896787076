import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../../Modal';
import SelectPlaylists from '../../../containers/DeviceGroupInfo/SelectNotAssignedPlaylists';
import { SelectedValue } from '../../Select/SelectAsync';
import validation from '../../../utils/validation';
import { assignPlaylistToGroupValidation } from '../../../validation/playlists';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../FormErrorMessage';

interface FormInputs {
  playlists: SelectedValue[];
}

export default ({
  children,
  onSubmit,
}: {
  children: React.ReactNode;
  onSubmit?: (data: SelectedValue[]) => void;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    clearErrors,
  } = useForm<FormInputs>({
    resolver: yupResolver(
      validation.object(assignPlaylistToGroupValidation(t)).required(),
    ),
    defaultValues: {
      playlists: [],
    },
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [playlists, setPlaylists] = useStateWithHookForm<
    FormInputs,
    SelectedValue[]
  >({ setValue, trigger, name: 'playlists' }, []);

  const handleClose = () => setIsModalVisible(false);

  const handleSubmitData = () => {
    if (onSubmit) {
      onSubmit(playlists);
    }

    handleClose();
  };

  const open = () => {
    clearErrors();
    setIsModalVisible(true);
  };

  // empty the select value when the component unmounts(on modal close)
  React.useEffect(() => {
    if (isModalVisible) {
      return () => setPlaylists([]);
    }
    // sould return something or eslint would throw an issue
    return undefined;
  }, [isModalVisible]);

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('playlists.add_playlist')}
        widthDefaultSize={false}
        show={isModalVisible}
        onHide={handleClose}
        bodyClassName="py-5"
        footerContent={
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(handleSubmitData)}
          >
            {t('playlists.add_playlist')}
          </button>
        }
      >
        <span className="col-form-label text-lg-end">
          {t('playlists.playlists')}
        </span>
        <SelectPlaylists initialValue={playlists} onChange={setPlaylists} />
        <FormErrorMessage name="playlists" errors={errors} />
      </Modal>
    </>
  );
};
