import { UserRole } from '../enums/userRole.enum';

export const userRoles: { [key: string]: string } = {
  [UserRole.SuperAdmin]: 'Super admin',
  [UserRole.Admin]: 'Admin',
  [UserRole.Employee]: 'Employee',
  [UserRole.Manager]: 'Manager',
  [UserRole.Editor]: 'Editor',
  [UserRole.AccountOwner]: 'Account owner',
};
