import clsx from 'clsx';
import React from 'react';
import { KTSVG } from '../../lib/metronic/helpers';

const disabledControlClassNames = 'pe-none opacity-50';

interface CounterProps {
  title: string;
  value?: number;
  placeholder?: string;
  onChange: (value: number) => void;
  max?: number;
  min?: number;
  className?: string;
  classNameTitle?: string;
  name?: string;
  isNotRequired?: boolean;
  isLocked?: boolean;
}

interface DefaultProps extends CounterProps {
  value: Required<CounterProps>['value'];
  max: Required<CounterProps>['max'];
  min: Required<CounterProps>['min'];
}

const Counter = ({
  title,
  value,
  placeholder,
  onChange,
  max,
  min,
  className,
  classNameTitle,
  name,
  isNotRequired,
  isLocked,
}: DefaultProps) => {
  const isMin = value <= min;
  const isMax = value >= max;

  return (
    <>
      <label
        className="d-flex align-items-center fs-6 fw-bold mb-2"
        htmlFor="counter"
      >
        <span className={clsx(!isNotRequired && 'required', classNameTitle)}>
          {title}
        </span>
      </label>
      <div className={clsx('position-relative', className)} id="counter">
        <button
          type="button"
          className={clsx(
            'btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 start-0',
            (isLocked || isMin) && disabledControlClassNames,
          )}
          onClick={() => {
            if (!isLocked && !isMin) {
              onChange(value - 1);
            }
          }}
        >
          <KTSVG
            path="/media/icons/duotune/general/gen042.svg"
            className="svg-icon svg-icon-1"
          />
        </button>
        <input
          type="text"
          name={name}
          className="form-control form-control-solid border-0 text-center ps-12 pe-12"
          placeholder={placeholder}
          onChange={({ currentTarget: { value: inputValue } }) => {
            const numberValue = Number(inputValue);

            if (
              !isLocked &&
              !Number.isNaN(numberValue) &&
              numberValue >= min &&
              numberValue <= max
            )
              onChange(numberValue);
          }}
          value={value}
          disabled={isLocked}
        />
        <button
          type="button"
          className={clsx(
            'btn btn-icon btn-active-color-gray-700 position-absolute translate-middle-y top-50 end-0',
            (isLocked || isMax) && disabledControlClassNames,
          )}
          onClick={() => {
            if (!isLocked && !isMax) {
              onChange(value + 1);
            }
          }}
        >
          <KTSVG
            path="/media/icons/duotune/general/gen041.svg"
            className="svg-icon svg-icon-1"
          />
        </button>
      </div>
    </>
  );
};

Counter.defaultProps = {
  value: 0,
  max: Infinity,
  min: 0,
};

export default Counter;
