import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import styles from './instagram.module.scss';
import instagramLogo from '../../../../assets/images/apps/instagram.svg';
import instagramAuthLoginIcon from '../../../../assets/images/apps/InstagramLogoAuth.png';
import { Apis } from '../../../../api/endpoints/apps/Instagram';
import {
  APP_ID,
  APP_LOGGER_ID,
  APP_URI,
} from '../../../../constants/dotenvconst';
import UpdateInstagramRequestData from '../../../../api/endpoints/apps/interfaces/updateInstagramRequestData.interface';
import GlobalState from '../../../../store/reducers/globalState.interface';
import WithSpinner from '../../../WithSpinner';
import App from '../../../../interfaces/app.interface';

// interface getInstagramLoginData {
//   accessToken: string;
//   userID: string;
//   expiresIn: Number;
//   graphDomain: string;
//   signedRequest: string;
// }

export interface CreateFeedModalProps {
  loading?: boolean;
  app?: App;
  onSuccess?: (updateRequest: UpdateInstagramRequestData) => void;
  code?: string;
}

export default ({ loading, onSuccess, code, app }: CreateFeedModalProps) => {
  const { t } = useTranslation();

  const { id } = useSelector((state: GlobalState) => state.userData.user);

  const [isLoading, setLoading] = useState<boolean>(!!code ?? false);
  const history = useHistory();

  // const handleInstagramLogin = async (_accessToken: string) => {
  //   try {
  //     setLoading(true);
  //     const response = await Apis.getInstagramFeeds(`${_accessToken}`);

  //     setInstagramUser(response?.data?.data[0]?.username);
  //     setAccessToken(response?.data?.AccessToken);
  //     // setExpiresIn(response?.data?.expires_in);

  //     setLoading(false);
  //   } catch (e) {
  //     console.log(e);
  //     setLoading(false);
  //   }
  // };
  const instagramLoginApi = async (_code: string) => {
    try {
      setLoading(true);
      const response = await Apis.instagramLogin(_code);

      if (response) {
        // handleInstagramLogin(response?.data?.access_token);
        // toast.success(t('apps.instagram.loginSuccess'));

        console.log('response', response);
        const responseDataFeeds = await Apis.getInstagramFeeds(
          response?.data?.access_token,
        );

        const currentInstagramUserName = sessionStorage.getItem(
          'currentInstagramUserName',
        );

        if (
          onSuccess &&
          currentInstagramUserName ===
            responseDataFeeds?.data?.data[0]?.username
        ) {
          onSuccess({
            instagramUserName: responseDataFeeds?.data.data[0]?.username,
            token: response?.data?.access_token,
            indigoUserId: id,
          });
        } else {
          toast.error(
            `${t(
              'apps.instagram.pleaseReconnect',
            )}: ${currentInstagramUserName}`,
          );

          setLoading(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  React.useEffect(() => {
    if (loading) setLoading(loading);
    // else setLoading(false);
    if (code !== 'null' && code !== undefined) {
      instagramLoginApi(String(code));
      history.push('/apps/instagram');
    }
  }, [loading, code]);

  const handleInstagramAuth = async () => {
    try {
      sessionStorage.setItem('instagramRelogin', 'true');
      sessionStorage.setItem(
        'currentInstagramUserName',
        app?.dependency?.config?.instagramUser,
      );
      window.location.href = `https://www.instagram.com/oauth/authorize?app_id=${APP_ID}&redirect_uri=${APP_URI}&scope=user_profile&response_type=code&logger_id=${APP_LOGGER_ID}&scope=user_profile,user_media&update=true`;
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="container ps-0 mx-auto py-auto">
      <div className="d-flex flex-column justify-content-center">
        <img
          src={instagramLogo}
          alt="instagram"
          className={clsx('mb-4', styles.instagramImage)}
        />
        <h1 className="text-center text-dark mb-4">
          {t('apps.instagram.title')}
        </h1>
        <WithSpinner isLoading={isLoading} className="min-h-100px">
          <div
            role="presentation"
            className={clsx(
              styles.googleBtn,
              styles.instagramBtn,
              'd-flex justify-content-center align-items-center cursor-pointer',
            )}
            onClick={handleInstagramAuth}
          >
            <div className={clsx(styles.instagramIcImage)}>
              <img src={instagramAuthLoginIcon} alt="instagram" width="20" />
            </div>
            <span className={clsx(styles.textInstagramLogin)}>
              Sign in with Instagram
            </span>
          </div>
        </WithSpinner>
      </div>
    </div>
  );
};
