import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import FormErrorMessage from '../../components/FormErrorMessage';
import Modal from '../../components/Modal';

import { CreateFileRequest } from '../../store/types/fileManager';

import useStateWithHookForm from '../../utils/hooks/useStateWithHookForm';
import pointerEventsStyles from '../../styles/pointerEvents.module.scss';

type CreateFolderModalFormPayload = Omit<
  CreateFileRequest,
  'file' | 'parentDirectoryId' | 'height' | 'width'
>;

export interface CreateFolderModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (createFolderRequest: CreateFileRequest) => void;
  title: string;
  isLoading: boolean;
  directoryId: string | null;
  isPublicState: boolean;
  disableTypeSelection: boolean;
  resetFetch: () => void;
}

const CreateFolderModal = ({
  show,
  onHide,
  title,
  onSubmit,
  resetFetch,
  isLoading = false,
  directoryId,
  isPublicState,
  disableTypeSelection = false,
}: CreateFolderModalProps) => {
  const initialValues: CreateFolderModalFormPayload = {
    name: '',
    isDirectory: true,
    isPublic: isPublicState,
  };
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    isDirectory: Yup.boolean().defined(),
    isPublic: Yup.boolean().defined(
      'Please select a file status (private or public)',
    ),
    conversionJobId: Yup.number().nullable(true),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateFolderModalFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  // hook form input states
  const [newFolderName, setNewFolderName] = useStateWithHookForm<
    CreateFolderModalFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);
  const [folderType, setFolderType] = useStateWithHookForm<
    CreateFolderModalFormPayload,
    boolean
  >({ setValue, trigger, name: 'isPublic' }, initialValues.isPublic);

  // handlers
  const handleNewFolderNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setNewFolderName(value),
    [setNewFolderName],
  );

  const handleFolderTypeChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      const isPublic = value === 'true';
      setFolderType(isPublic);
    },
    [setFolderType],
  );

  const handleOnSubmit = React.useCallback(
    (data: CreateFolderModalFormPayload) => {
      const createFileRequest: CreateFileRequest = {
        ...data,
        file: null,
        parentDirectoryId: directoryId,
        width: null,
        height: null,
      };

      onSubmit(createFileRequest);

      resetFetch();
    },
    [directoryId, onSubmit, resetFetch],
  );

  return (
    <Modal
      title={`${title} (${folderType ? 'public' : 'private'})`}
      show={show}
      onHide={onHide}
      widthDefaultSize={false}
      withFooter
      footerContent={
        <button
          form="form"
          type="submit"
          className={clsx(
            'btn btn-primary',
            isLoading && pointerEventsStyles.noPointerEvents,
          )}
        >
          {isLoading ? (
            <span>
              Saving{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            <span>Save</span>
          )}
        </button>
      }
    >
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        id="form"
        className="d-flex flex-column"
      >
        <div className="d-flex flex-column my-2 w-100">
          <label htmlFor="name" className="text-gray-900 my-1">
            Folder Name
          </label>
          <input
            name="name"
            id="name"
            value={newFolderName}
            onChange={handleNewFolderNameChange}
            className="form-control form-control-solid"
            placeholder="Type Here"
            type="text"
          />
          <FormErrorMessage name="name" errors={errors} className="my-1 px-2" />
        </div>

        {!disableTypeSelection && (
          <div className="d-flex flex-column my-2 w-100">
            <label htmlFor="isPublic" className="text-gray-900 my-1">
              Type
            </label>

            <div className="d-flex align-items-center">
              <div className="form-check form-check-inline form-check-solid">
                <input
                  name="isPublic"
                  id="isPublic"
                  className="form-check-input"
                  value="true"
                  type="radio"
                  checked={folderType}
                  onChange={handleFolderTypeChange}
                  disabled={disableTypeSelection}
                />
                <span className="form-check-label">Public</span>
              </div>

              <div className="form-check form-check-inline form-check-solid">
                <input
                  name="isPublic"
                  id="isPublic"
                  className="form-check-input"
                  value="false"
                  type="radio"
                  checked={!folderType}
                  onChange={handleFolderTypeChange}
                  disabled={disableTypeSelection}
                />
                <span className="form-check-label">Private</span>
              </div>
            </div>
            <FormErrorMessage
              name="isPublic"
              errors={errors}
              className="my-1 px-2"
            />
          </div>
        )}
      </form>
    </Modal>
  );
};

export default CreateFolderModal;
