import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { readFromQueryString } from '../../../utils/queryParams';
import useSyncWithSearch from '../../../utils/hooks/useSyncWithSearch';
import useDidUpdate from '../../../utils/hooks/useDidUpdate';
import Pagination from '..';

export default ({
  total,
  pageSize,
  pageNumberQueryField,
  pageSizeQueryField,
  className,
  hideText,
}: {
  total: number;
  pageSize: number;
  pageSizeQueryField: string;
  pageNumberQueryField: string;
  className?: string;
  hideText?: boolean;
}) => {
  const { search } = useLocation();
  const [, setPageSizeSearch] = useSyncWithSearch(
    pageSizeQueryField,
    String(pageSize),
  );
  const [pageNumberSearch, setPageNumberSearch] = useSyncWithSearch(
    pageNumberQueryField,
    readFromQueryString<string>(search, pageNumberQueryField) ?? '1',
  );

  useDidUpdate(() => setPageSizeSearch(String(pageSize)), [pageSize]);

  const pageNumber = Number(pageNumberSearch);

  const setPageNumber = (number: number): void =>
    setPageNumberSearch(String(number));

  // useEffect(() => {
  //   if (total <= pageSize) {
  //     setPageNumber(1);
  //   }
  // }, [total, pageSize]);
  // If the user change the entries number, the table returns to page 1 because I found that if you
  // show 10 entries per page and when you click on page 18 for example after that when you select to show
  // 1000 entries it still at page 18
  useEffect(() => {
    setPageNumber(1);
  }, [pageSize]);

  return (
    <Pagination
      className={className}
      pageSize={pageSize}
      total={total}
      pageNumber={pageNumber}
      onPageNumberUpdate={setPageNumber}
      hideText={hideText}
    />
  );
};
