import React from 'react';
import styles from './ticker_animation.module.scss';

export default ({
  children,
  duration,
}: {
  children: React.ReactNode | string;
  duration: number;
}) => {
  return (
    <div className={styles.tickerContainer}>
      <div
        className={styles.tickerItem}
        style={{
          animationDuration: `${duration ? `${duration}s` : '8s'}`,
        }}
      >
        {children}
      </div>
    </div>
  );
};
