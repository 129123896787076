import axios from 'axios';
import { API_URL } from '../../../constants/api';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import GetTemplatesResponseData from './interfaces/getTemplatesResponseData.interface';
import GetTemplatesRequestData from './interfaces/getTemplatesRequestData.interface';
import TemplatesRoutes from './enums/templatesRoutes.enum';
import Template from '../../../interfaces/template.interface';

export default {
  /**
   * Retrieves the list of templates
   * @param requestData
   */
  getTemplates: async (
    requestData: GetTemplatesRequestData,
  ): Promise<GetTemplatesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${TemplatesRoutes.Templates}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of templates Admin Panel
   * @param requestData
   */
  getTemplatesAdmin: async (
    requestData: GetTemplatesRequestData,
  ): Promise<GetTemplatesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${TemplatesRoutes.TemplatesAdmin}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Get template by id
   * @param templateId
   */
  getTemplate: async (templateId: string): Promise<Template> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${TemplatesRoutes.Templates}/${templateId}`,
    });

    return data;
  },

  /**
   * Creates a new template
   * @param requestData
   */
  createTemplate: async (requestData: FormData): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${TemplatesRoutes.Templates}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: requestData,
    });
  },

  /**
   * Delete template
   * @param templateId
   */
  deleteTemplate: async (templateId: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${TemplatesRoutes.Templates}/${templateId}`,
    });
  },

  /**
   * Update template
   * @param templateId
   * @param data
   */
  updateTemplate: async (templateId: string, data: FormData): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${TemplatesRoutes.Templates}/${templateId}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data,
    });
  },
};
