import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FacebookModal from '../../../../components/Apps/Facebook/CreateFacebook';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import App from '../../../../interfaces/app.interface';
import Tooltip from '../../../../components/Tooltip';
import Modal from '../../../../components/Modal';
import api from '../../../../api';

const EditFacebook = ({
  children,
  onUpdate,
  facebook,
}: {
  children?: React.ReactNode;
  onUpdate: () => void;
  facebook: App;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const open = () => setModalShown(true);
  const close = () => setModalShown(false);
  const handleSubmit = (data: CreateAppRequestData) => {
    setLoading(true);

    api.apps
      .updateApp(facebook.id, data)
      .then(() => {
        close();
        if (onUpdate) {
          onUpdate();
        }
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.edit')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-edit" />
            </span>
          </Tooltip>
        )}
      </span>
      <Modal
        title={t('apps.facebook.editModalTitle')}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
      >
        <FacebookModal
          onClose={close}
          facebook={facebook}
          onSubmit={handleSubmit}
          loading={loading}
          update
        />
      </Modal>
    </>
  );
};

export default EditFacebook;
