import i18next from 'i18next';
import * as React from 'react';

export interface EmptyFeedsProps {
  descriptionText: string;
}
const EmptyFeeds = ({ descriptionText }: EmptyFeedsProps) => {
  return (
    <div className="d-flex flex-column flex-center h-100">
      <img
        alt="No items found"
        src="/media/illustrations/sketchy-1/5.png"
        className="mw-400px"
      />
      <div className="fs-1 fw-bolder text-dark mb-4">No items found.</div>
      <div className="fs-6">{i18next.t(`${descriptionText}`)}</div>
    </div>
  );
};

export default EmptyFeeds;
