import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateTopicRequestData from '../../../../api/endpoints/libraries/interfaces/createTopicRequestData.interface';
import api from '../../../../api';

import CreateTopic from '../../../../components/AdminPanel/Library/Topic/CreateTopic';
import Modal from '../../../../components/Modal';

const UpdateCategoryTopic = ({
  onUpdate,
  children,
  topic,
  update,
}: {
  children?: React.ReactNode;
  onUpdate?: (topicId: string) => void;
  topic?: any;
  update?: boolean;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(false);

  // const [customerId, setCustomerId] = useState('');
  const open = () => setModalShown(true);
  const close = () => setModalShown(false);
  const handleSubmit = (data: CreateTopicRequestData) => {
    api.libraries.updateTopic(data, topic?.id).then((res) => {
      close();
      if (onUpdate) {
        onUpdate(res.id);
      }
    });
  };
  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('library.indigo.updateTopic')}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
        fullscreen
      >
        <CreateTopic onSubmit={handleSubmit} topic={topic} update={update} />
      </Modal>
    </>
  );
};
export default UpdateCategoryTopic;
