/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { deletePlaylist } from '../../../store/actions/playlists';
import WithDeleteConfirm from '../../../components/WithDeleteConfirm/Controlled';
import WithConfirm from '../../../components/WithConfirm/Controlled';
import Playlist from '../../../interfaces/playlist.interface';
import Tooltip from '../../../components/Tooltip';
import api from '../../../api';
import GlobalState from '../../../store/reducers/globalState.interface';

export default ({
  playlist,
  children,
  unAssignPlaylistFromDeviceGroup,
  selectedItems,
  onSuccess,
}: {
  playlist?: Playlist;
  children?: React.ReactNode;
  unAssignPlaylistFromDeviceGroup?: (playlistId: string, id: string) => void;
  selectedItems?: string[];
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: deviceGroupId } = useParams<{ id: string }>();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  const handleConfirm = () => {
    if (unAssignPlaylistFromDeviceGroup && playlist?.id) {
      unAssignPlaylistFromDeviceGroup(playlist?.id, deviceGroupId);
      return;
    }

    if (selectedItems) {
      api.playlists.bulkDeletePlaylist(selectedItems).then(onSuccess);
      return;
    }

    if (playlist) {
      dispatch(deletePlaylist({ id: playlist.id }));
    }
  };

  const currentUserId = useSelector(
    (state: GlobalState) => state.userData.user?.id,
  );

  return (
    <>
      <span tabIndex={-1} role="button" onKeyPress={open} onClick={open}>
        {children ?? (
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            {unAssignPlaylistFromDeviceGroup ? (
              <Tooltip text={t('common.unssign')}>
                <i className="fa fa-trash" />
              </Tooltip>
            ) : (
              <Tooltip text={t('common.remove')}>
                <i className="fa fa-trash" />
              </Tooltip>
            )}
          </span>
        )}
      </span>

      {playlist?.owner?.id === currentUserId ? (
        <WithDeleteConfirm
          handleDecline={close}
          isVisible={isModalVisible}
          isLoading={false}
          confirmButtonText={
            !unAssignPlaylistFromDeviceGroup
              ? t('common.delete_confirmation.confirm')
              : 'Unassign'
          }
          body={`${
            !unAssignPlaylistFromDeviceGroup
              ? selectedItems && selectedItems.length > 1
                ? t('common.delete_confirmation.multiple_delete_title')
                : `${t('common.delete_confirmation.title')} ${t(
                    'common.delete_confirmation.playlist',
                  )} ${playlist?.name}`
              : ` ${t('devices.unassign_confirms')} ${playlist?.name}`
          }
       `}
          handleConfirm={handleConfirm}
        />
      ) : playlist?.locked ? (
        <WithConfirm
          isVisible={isModalVisible}
          isLoading={false}
          handleDecline={close}
          body={t('playlists.delete_playlist_locked_alert')}
          discardButtonText={t('common.ok')}
        />
      ) : (
        <WithDeleteConfirm
          handleDecline={close}
          isVisible={isModalVisible}
          isLoading={false}
          confirmButtonText={
            !unAssignPlaylistFromDeviceGroup
              ? t('common.delete_confirmation.confirm')
              : 'Unassign'
          }
          body={`${
            !unAssignPlaylistFromDeviceGroup
              ? selectedItems && selectedItems.length > 1
                ? t('common.delete_confirmation.multiple_delete_title')
                : `${t('common.delete_confirmation.title')} ${t(
                    'common.delete_confirmation.playlist',
                  )} ${playlist?.name}`
              : ` ${t('devices.unassign_confirms')} ${playlist?.name}`
          }
       `}
          handleConfirm={handleConfirm}
        />
      )}
    </>
  );
};
