import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BulkEditDevices from '../../../components/Devices/BulkEditDevices';
import BulkUpdateDevicesRequestDataInterface from '../../../api/endpoints/devices/interfaces/bulkUpdateDevicesRequestData.interface';
import UpdateMultipleDevicesAction from '../../../store/actions/devices/interfaces/updateMultipleDevicesAction.interface';
import {
  updateMultipleDevices,
  updateMultipleAdminDevices,
} from '../../../store/actions/devices';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';

export default ({
  children,
  selectedItems,
  onBulkEditDevice,
  isSuperAdmin,
  skipEditModalDeviceGroup,
}: {
  children: React.ReactNode;
  selectedItems: string[];
  isSuperAdmin?: boolean;
  onBulkEditDevice?: (
    data: Partial<BulkUpdateDevicesRequestDataInterface>,
  ) => void;
  skipEditModalDeviceGroup?: boolean;
}) => {
  const dispatch = useDispatch();
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);

  const handleSubmit = (
    data: Partial<BulkUpdateDevicesRequestDataInterface>,
  ) => {
    if (onBulkEditDevice) {
      onBulkEditDevice({ ...data, deviceIds: selectedItems });
      return;
    }

    if (roles.includes(UserRole.SuperAdmin)) {
      dispatch(
        updateMultipleAdminDevices({
          data: { ...data, deviceIds: selectedItems },
        } as UpdateMultipleDevicesAction['payload']),
      );
    } else {
      dispatch(
        updateMultipleDevices({
          data: { ...data, deviceIds: selectedItems },
        } as UpdateMultipleDevicesAction['payload']),
      );
    }
  };

  return selectedItems.length > 1 ? (
    <BulkEditDevices
      onSubmit={handleSubmit}
      isAdmin={isSuperAdmin}
      skipEditModalDeviceGroup={skipEditModalDeviceGroup}
    >
      {children}
    </BulkEditDevices>
  ) : (
    <></>
  );
};
