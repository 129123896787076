import { useSelector } from 'react-redux';
import GlobalState from '../../store/reducers/globalState.interface';
import { UserRole } from '../../enums/userRole.enum';

export default () => {
  const loginAsUserName = window.localStorage.getItem('loginAsUserName');
  const roles = useSelector(
    (state: GlobalState) => state?.userData?.user?.roles,
  );
  let loggedInAsAccountOwner = false;
  if (
    loginAsUserName !== 'Super Admin' &&
    roles?.includes(UserRole.AccountOwner)
  ) {
    loggedInAsAccountOwner = true;
  }
  return [loggedInAsAccountOwner, !roles?.length];
};
