enum UsersActions {
  GET_USERS_LIST = 'GET_USERS_LIST',
  GET_ADMIN_USERS_LIST = 'GET_ADMIN_USERS_LIST',
  GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS',
  GET_ADMIN_USERS_LIST_SUCCESS = 'GET_ADMIN_USERS_LIST_SUCCESS',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  SUSPEND_USER = 'SUSPEND_USER',
  RESTORE_USER = 'RESTORE_USER',
  SET_USERS_LOADING = 'SET_USERS_LOADING',
}

export default UsersActions;
