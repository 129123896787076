import React, { useState } from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import useTableMultiSelect from '../../../utils/hooks/useTableMultiSelect';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import CreateLocation from '../../../containers/AdminPanel/CompanyLocations/CreateLocation';
import GetLocationsRequestData from '../../../api/endpoints/locations/interfaces/getLocationsRequestData.interface';
import LocationsQueryParam from '../../../api/endpoints/locations/enums/locationsQueryParam.enum';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import Location from '../../../interfaces/location.interface';
import Company from '../../../interfaces/company.interface';
import SortOrder from '../../../enums/sortOrder.enum';
import { KTSVG } from '../../../lib/metronic/helpers';
import Pagination from '../../Pagination';
import TableView from './TableView';
import Search from '../../Search';
import Card from '../../Card';
import LocationsSortField from '../../../api/endpoints/locations/enums/locationsSortField.enum';
// import locationPaginationOptions from '../../../constants/locationPaginationOptions';

const tableRowHeight = 90;

const options = [
  { value: 10, label: '10' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 500, label: '500' },
];
export default ({
  locations,
  total,
  isLoading,
  resetOnSearch,
  onUpdate,
  setRequestData,
  requestData,
  company,
  isSelectable,
}: {
  locations: Location[];
  total: number;
  isLoading: boolean;
  resetOnSearch?: boolean;
  onUpdate?: () => void;
  setRequestData?: (value: Partial<GetLocationsRequestData>) => void;
  requestData?: Partial<GetLocationsRequestData>;
  company: Company;
  isSelectable?: boolean;
}) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(locations, 'id'),
    [],
    resetOnSearch ? requestData?.search : undefined,
  );
  const [sortFieldValue, setSortFieldValue] = useState<{
    field: string;
    isInverted: boolean;
  }>();

  // const pageSize = Number(requestData?.limit ?? 6);

  const [pageSize, setPageSize] = useState(10);

  const handleSearch = (searchValue: string) => {
    if (setRequestData) {
      setRequestData({
        ...requestData,
        [LocationsQueryParam.Search]: searchValue,
        [PaginationQueryParam.Page]: '1',
      });
    }
  };

  const handlePageNumberChange = (pageNumber: number) => {
    if (setRequestData) {
      setRequestData({
        ...requestData,
        [PaginationQueryParam.Page]: String(pageNumber),
      });
    }
  };

  const handleSort = (sortField: string, isInverted: boolean) => {
    setSortFieldValue({ field: sortField, isInverted });

    if (setRequestData) {
      setRequestData({
        ...requestData,
        [SortingQueryParam.SortBy]: sortField,
        [SortingQueryParam.SortOrder]: isInverted
          ? SortOrder.Ascending
          : SortOrder.Descending,
      });
    }
  };

  // const handleDevicesChange = ({
  //   currentTarget: { value },
  // }: React.ChangeEvent<HTMLSelectElement>) => {
  //   setPageSize(Number(value));
  // };

  React.useEffect(() => {
    if (setRequestData) {
      setRequestData({
        ...requestData,
        [SortingQueryParam.SortBy]: LocationsSortField.Name,
        [PaginationQueryParam.Limit]: String(pageSize),
      });
    }
  }, [pageSize]);
  // comment select rows
  //  <div className="min-w-200px w-100 w-sm-auto">
  // {t('locations.location_count')}
  // <select
  //   id="locationPagination"
  //   onChange={handleDevicesChange}
  //   name="locationPagination"
  //   className="form-control-sm"
  //   style={{ width: 'auto' }}
  // >
  //   {/* <option value="" selected disabled>
  //     {t('locations.location_count')}
  //   </option> */}
  //   {locationPaginationOptions.map((i) => (
  //     <option key={i} value={i}>
  //       {i}
  //     </option>
  //   ))}
  // </select>
  // {t('locations.locations')}
  // </div>

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          <div className="d-flex align-items-center flex-wrap">
            <Search
              searchValue={requestData?.search ?? ''}
              onChange={handleSearch}
              placeholder={t('companies.search')}
              className="me-2"
            />
          </div>
          <div className="d-flex align-items-center align-content-center">
            <CreateLocation onSuccess={onUpdate} company={company}>
              <button type="button" className="btn btn-primary me-2">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                {t('locations.add_location')}
              </button>
            </CreateLocation>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <TableView
          onUpdate={onUpdate}
          locations={locations}
          isLoading={isLoading}
          onSort={handleSort}
          sortFieldValue={sortFieldValue}
          preloaderHeight={pageSize * tableRowHeight}
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          isSelectable={isSelectable}
        />
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-80px mb-4">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(+e.target.value);
              }}
              className="form-select form-select-sm form-select-solid"
            >
              {options.map((option) => (
                <option key={`${option.label}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-fill ps-5">
            <Pagination
              total={total}
              pageSize={pageSize}
              pageNumber={Number(requestData?.page ?? 0)}
              onPageNumberUpdate={handlePageNumberChange}
              className="mt-3 mb-5"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
