/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableHandle } from 'react-sortable-hoc';
import moment from 'moment';
import clsx from 'clsx';
import ListIcon from '../../../../assets/images/icons/list.svg';
import EditContent from '../../../../containers/Contents/EditContent';
import CopyContent from '../../../../containers/Playlist/CopyContent';
import RemoveContent from '../../../../containers/Playlist/RemoveContent';
import PreviewContent from '../../../../containers/Contents/PreviewContent';
import IContent from '../../../../interfaces/content.interface';
import Playlist from '../../../../interfaces/playlist.interface';
import { formatDateString } from '../../../../utils/dateTime';
import daysOfWeek from '../../../../constants/daysOfWeek';
import ContentPreviewIcon from './ContentPreviewIcon';
import ShowMoreCell from '../../../ShowMoreCell';
import CheckBox from '../../../CheckBox';
import Table from '../../../Table';
import styles from './tableRow.module.scss';
import Device from '../../../../interfaces/devices/device.interface';
import AppType from '../../../../enums/appType.enum';
import Tooltip from '../../../Tooltip';
import UpdateFacebook from '../../../../containers/Apps/Facebook/UpdateFacebook';
import { useAccessByRole } from '../../../../utils/hooks/useAccessRole';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hourCycle: 'h12',
});

const formatTime = new Intl.DateTimeFormat(navigator.language, {
  minute: 'numeric',
  second: 'numeric',
});

interface TableRowProps {
  content: IContent;
  playlist: Playlist;
  config?: {
    skipActions?: boolean;
    disableLinks?: boolean;
    isSelectable?: boolean;
  };
  isChecked?: boolean;
  onCheck?: (id: string) => void;
  onEdit: () => void;
}

interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
  isChecked: Required<TableRowProps>['isChecked'];
  onCheck: Required<TableRowProps>['onCheck'];
  devicesWithoutLicence?: Device[];
}

const DragHandle = SortableHandle(() => (
  <img className={styles.dragHandle} src={ListIcon} alt="move" />
));

export default ({
  content,
  playlist,
  config,
  isChecked,
  onCheck,
  onEdit,
  devicesWithoutLicence,
}: DefaultProps) => {
  const { t } = useTranslation();
  let rowColor;
  const dateNow = moment().toISOString();
  const { duration, startDate, endDate, weekDays } = content;
  const [brokenApp, setBrokenApp] = useState<string>('');

  const [brokenAppInstagram, setBrokenAppInstagram] = useState<string>('');

  let userAccessByRole;
  if (content?.contentOwner?.roles) {
    userAccessByRole = useAccessByRole(content?.contentOwner);
  } else if (content.app?.ownerApp?.roles) {
    userAccessByRole = useAccessByRole(content.app?.ownerApp);
  } else {
    userAccessByRole = useAccessByRole(
      content?.mediaGroup?.medias?.[0]?.ownerMedia,
    );
  }

  if (startDate > dateNow) {
    rowColor = 'bg-light-warning';
  } else if (endDate < dateNow) {
    rowColor = 'bg-light-danger';
  }
  const IsFacebookApp = content.app?.type === AppType.Facebook;
  const IsInstagramApp = content.app?.type === AppType.Instagram;

  React.useMemo(() => {
    if (IsFacebookApp) {
      const expireDays = 6;
      const facebookFeeds = content?.app?.dependency?.config?.facebookFeeds;
      const foundFeedWithImage = facebookFeeds?.find(
        (item: { attachements: { media: { image: { src: string } } } }) =>
          item.attachements?.media?.image?.src,
      );
      if (
        !content?.app?.dependency?.facebookTokenCache ||
        content.app?.dependency?.facebookTokenCache.nbAttempts > 0
      ) {
        setBrokenApp('alert lost');
      } else if (foundFeedWithImage) {
        const img = new Image();
        img.src = foundFeedWithImage?.attachements?.media?.image?.src;

        img.onerror = () => {
          setBrokenApp('alert images');
        };
      } else if (expireDays <= 5) {
        setBrokenApp('warning');
      }
    }

    if (IsInstagramApp) {
      const expireDays = 6;

      const instagramFeeds = content?.app?.dependency?.config?.feeds;
      const foundFeedWithImage = instagramFeeds?.find(
        (item: { media_url: string }) => item.media_url,
      );
      if (
        !content?.app?.dependency?.instagramTokenCache ||
        content?.app?.dependency?.instagramTokenCache.nbAttempts > 0
      ) {
        setBrokenAppInstagram('alert lost');
      } else if (foundFeedWithImage) {
        const img = new Image();
        img.src = foundFeedWithImage?.media_url;

        img.onerror = () => {
          setBrokenAppInstagram('alert images');
        };
      } else if (expireDays <= 5) {
        setBrokenAppInstagram('warning');
      }
    }
  }, []);

  return (
    <Row className={clsx(styles.row, rowColor)} key={content.id}>
      {config.isSelectable && (
        <Cell className="w-30px pe-0">
          <CheckBox
            name="contentId"
            id={content.id}
            onChange={() => onCheck(content.id)}
            checked={isChecked}
          />
        </Cell>
      )}
      <Cell isEmpty={!content.title} isNoWrap>
        <PreviewContent content={content}>
          <ContentPreviewIcon
            devicesWithoutLicence={devicesWithoutLicence}
            content={content}
            playlist={playlist}
            disableLinks={config.disableLinks}
          >
            <DragHandle />
          </ContentPreviewIcon>
        </PreviewContent>
      </Cell>
      <Cell className="text-center" isNoWrap isEmpty={!duration}>
        <span className="text-dark fw-bold d-block mb-1 fs-6">
          {duration && formatTime.format(duration * 1000)}
        </span>
      </Cell>
      <Cell className="text-center" isNoWrap isEmpty={!startDate}>
        <span className="text-dark fw-bold d-block mb-1 fs-6">
          {startDate && formatDate(startDate)}
        </span>
      </Cell>
      <Cell className="text-center" isNoWrap isEmpty={!endDate}>
        <span className="text-dark fw-bold d-block mb-1 fs-6">
          {endDate && formatDate(endDate)}
        </span>
      </Cell>
      <Cell className="text-center" isEmpty={!weekDays?.length}>
        <div className="d-flex flex-wrap justify-content-evenly">
          {weekDays?.length &&
            daysOfWeek.map((day, index) => (
              <div className={weekDays.includes(index) ? styles.activeDay : ''}>
                <span className="text-dark fw-bold d-block mb-3 fs-6">
                  {day}
                </span>
              </div>
            ))}
        </div>
      </Cell>
      <Cell className="text-center" isNoWrap isEmpty={!content.tags?.length}>
        <div className=" d-flex justify-content-center align-items-center">
          <ShowMoreCell
            itemNames={content?.tags as string[]}
            title={t('playlist.tags')}
            withModal
          >
            {content?.tags?.map((itemName: string) => (
              <ShowMoreCell.Tags itemName={itemName} />
            ))}
          </ShowMoreCell>
        </div>
      </Cell>
      <Cell isNoWrap isTextEnd>
        <div className="d-flex justify-content-end">
          {brokenApp.includes('alert') && content && (
            <>
              <div className="mx-1">
                <UpdateFacebook facebook={content.app as any} onUpdate={onEdit}>
                  <Tooltip
                    text={
                      brokenApp.includes('images')
                        ? t('common.broken_images')
                        : t('common.broken_app')
                    }
                  >
                    <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                      <i className="fa fa-exclamation-triangle text-warning" />{' '}
                    </span>
                  </Tooltip>
                </UpdateFacebook>
              </div>
            </>
          )}

          {(!brokenApp.includes('alert') ||
            !brokenAppInstagram.includes('alert')) && (
            <>
              <div className="mx-1">
                <EditContent
                  content={content}
                  onSuccess={onEdit}
                  playlist={playlist}
                  userAccessByRole={userAccessByRole}
                />
              </div>

              <div className="mx-1">
                <CopyContent content={content} onSuccess={onEdit} />
              </div>
            </>
          )}
          <div className="mx-1">
            <RemoveContent
              content={content}
              playlist={playlist}
              onSuccess={onEdit}
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};
