import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
import User from '../../../interfaces/user/user.interface';
import api from '../../../api';
import SelectAsync, {
  SelectedValue,
} from '../../../components/Select/SelectAsync';

const loadOptions = async (
  isAdmin: boolean,
  currentUserId?: string,
  companyId?: string,
  locationId?: string,
  search?: string,
): Promise<SelectedValue[]> => {
  const { items } = isAdmin
    ? await api.usersGroups.adminGetUsersGroups({
        search,
        currentUserId,
        active: true,
        companyId,
        locationId,
      })
    : await api.usersGroups.getUsersGroups({
        search,
        active: true,
        companyId,
        locationId,
      });

  return items.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

interface SelectUserGroupProps {
  onChange?: (selectedValues: SelectedValue[]) => void;
  initialValue?: string[];
  id?: string;
  className?: string;
  currentUser?: User;
}

interface DefaultProps extends SelectUserGroupProps {
  onChange: Required<SelectUserGroupProps>['onChange'];
  initialValue: Required<SelectUserGroupProps>['initialValue'];
  companyId?: string;
  locationId?: string;
}

const SelectUserGroup = ({
  onChange,
  initialValue,
  id,
  className,
  currentUser,
  companyId,
  locationId,
}: DefaultProps) => {
  const { t } = useTranslation();
  const [isAdmin] = useIsAdmin();
  const [setSelectedValues, setSelectedValue] = useState<SelectedValue[]>([
    {
      value: 'loadingValue',
      label: `${t('common.loading')}...`,
    },
  ]);
  const [loadedGroups, setLoadedGroups] = useState<SelectedValue[] | undefined>(
    undefined,
  );
  useEffect(() => {
    if (loadedGroups) {
      setSelectedValue(
        loadedGroups?.filter((grp) =>
          initialValue?.includes(grp.value as string),
        ) as SelectedValue[],
      );
    }
  }, [loadedGroups]);
  const handleLoad = async (search?: string): Promise<SelectedValue[]> => {
    let usergroups: SelectedValue[] = [];

    if (companyId || locationId) {
      if (search)
        return loadOptions(
          isAdmin,
          currentUser?.id,
          companyId,
          locationId,
          search,
        );

      usergroups = await loadOptions(
        isAdmin,
        currentUser?.id,
        companyId,
        locationId,
      );
    }
    setLoadedGroups(usergroups);
    return usergroups;
  };

  return (
    <SelectAsync
      key={(companyId as string) + (locationId as string)}
      id={id}
      initialValue={setSelectedValues}
      onChange={(selectedValues: SelectedValue[]) => onChange(selectedValues)}
      loadOptions={handleLoad}
      placeholder={t('users_groups.select_user_group')}
      className={className}
      isClearable
      isMulti
      noAutocomplete
    />
  );
};

SelectUserGroup.defaultProps = {
  initialValue: [],
  onChange: () => null,
};

export default SelectUserGroup;
