import axios from 'axios';
import { API_URL } from '../../../constants/api';
import LibraryCategoriesRoutes from './enums/libraryCategoriesRoutes.enum';
import GetLibraryCategoriesRequestData from './interfaces/getLibraryCategoriesRequestData.interface';
import GetLibraryCategoriesResponseData from './interfaces/getLibraryCategoriesResponseData.interface';
import LibraryCategory from '../../../interfaces/libraryCategory.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import CreateLibraryCategoryRequestData from './interfaces/createLibraryCategoryRequestData';
import { createFormData } from '../../../utils/formData';

export default {
  /**
   * Retrieves the library categories list
   * @param requestData
   */
  getCategories: async (
    requestData: GetLibraryCategoriesRequestData,
  ): Promise<GetLibraryCategoriesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LibraryCategoriesRoutes.Categories}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Retrieves the library categories list
   * @param parentCategoryId
   * @param requestData
   */
  getSubCategories: async (
    parentCategoryId: string,
    requestData: GetLibraryCategoriesRequestData,
  ): Promise<GetLibraryCategoriesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LibraryCategoriesRoutes.Categories}/subCategories/${parentCategoryId}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Create Category
   */

  createCategories: async (
    requestData: CreateLibraryCategoryRequestData,
  ): Promise<GetLibraryCategoriesResponseData> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${LibraryCategoriesRoutes.Categories}`,
      data: createFormData(requestData, true),
    });

    return data;
  },

  /**
   * Edit Category by Id
   * @param id
   */

  editCategories: async (
    id: string,
    requestData: CreateLibraryCategoryRequestData,
  ): Promise<GetLibraryCategoriesResponseData> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${LibraryCategoriesRoutes.Categories}/${id}`,
      data: createFormData(requestData, true),
    });

    return data;
  },

  /**
   * Delete  the category  category by id
   * @param id
   */

  deleteCategory: async (id: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${LibraryCategoriesRoutes.Categories}/${id}`,
    });
  },

  /**
   * Retrieves the library category by id
   * @param id
   */
  getCategoryById: async (id: string): Promise<LibraryCategory> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LibraryCategoriesRoutes.Categories}/${id}`,
    });

    return data;
  },

  /**
   * Create Sub Category
   */

  createSubCategories: async (
    requestData: CreateLibraryCategoryRequestData,
    catgegoryId: string,
  ): Promise<GetLibraryCategoriesResponseData> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${LibraryCategoriesRoutes.Categories}/${catgegoryId}`,
      data: createFormData(requestData, true),
    });

    return data;
  },
};
