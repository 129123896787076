/* eslint-disable no-nested-ternary */
import { debounce } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ScheduleContentRequestData from '../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import PublishModal from '../../../components/PublishModal';
import Pagination from '../../../components/Pagination';
import Search from '../../../components/Search';
import {
  scheduleLiveStreamFeedActionCreator,
  editLiveStreamFeedActionCreator,
  getLiveStreamFeedsActionCreator,
  scheduleLiveStreamFeedResetActionCreator,
  editLiveStreamFeedResetActionCreator,
  deleteLiveStreamFeedResetActionCreator,
  createLiveStreamFeedActionCreator,
  createScheduleLiveStreamFeedResetActionCreator,
  createLiveStreamFeedResetActionCreator,
  deleteLiveStreamFeedActionCreator,
} from '../../../store/actions/apps';
import GlobalState from '../../../store/reducers/globalState.interface';
import { SortBy, SortOrder } from '../../../store/types/apps/globalTypes';
import {
  CreateLiveStreamFeedRequest,
  EditLiveStreamFeedRequest,
  GetLiveStreamFeedsRequest,
  LiveStreamFeedSchema,
  ScheduleLiveStreamFeedRequest,
} from '../../../store/types/apps/liveStreamFeed';
import CreateFeedModal from './CreateFeedModal';
import DeleteFeedModal from './DeleteFeedModal';
import EditFeedModal from './EditFeedModal';
import ErrorFeeds from './ErrorFeeds';
import FeedsTable from './FeedsTable';
import PreviewFeedModal from './PreviewFeedModal';
import Yup from '../../../utils/validation';

const HTMLAppContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    loading: liveStreamFeedsLoading,
    deleteLiveStreamFeedLoading,
    editLiveStreamFeedLoading,
    data: paginatedLiveStreamFeeds,
    error,
    isGetLiveStreamFeedsSuccess,
    isCreateLiveStreamFeedSuccess,
    isScheduleLiveStreamFeedSuccess,
    isDeleteLiveStreamFeedSuccess,
    isEditLiveStreamFeedSuccess,
    isCreateScheduleLiveStreamFeedSuccess,
  } = useSelector((state: GlobalState) => state.liveStreamFeeds);

  const [searchValue, setSearchValue] = React.useState<string>('');

  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [isScheduleandCreateSuccess, setIsScheduleandCreateSuccess] =
    React.useState<boolean>(false);

  const [getLiveStreamFeedsRequest, setGetliveStreamFeedsRequest] =
    React.useState<GetLiveStreamFeedsRequest>({
      params: {
        search: searchValue,
        limit: 10,
        page: paginatedLiveStreamFeeds.meta.currentPage,
        appType: 'live-stream',
        sortOrder: SortOrder.ASC,
        sortBy: SortBy.CREATED_AT,
      },
    });

  const { params } = getLiveStreamFeedsRequest;

  const [selectedLiveStreamFeedSchema, setSelectedLiveStreamFeedSchema] =
    React.useState<LiveStreamFeedSchema | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [showFilterModal, setShowFilterModal] = React.useState<boolean>(false);

  const [showCreateFeedModal, setShowCreateFeedModal] =
    React.useState<boolean>(false);

  const [showPreviewLiveStreamFeedModal, setShowPreviewLiveStreamFeedModal] =
    React.useState<boolean>(false);

  const [showEditLiveStreamFeedModal, setShowEditLiveStreamFeedModal] =
    React.useState<boolean>(false);

  const [showDeleteLiveStreamFeedModal, setShowDeleteLiveStreamFeedModal] =
    React.useState<boolean>(false);

  const [showScheduleLiveStreamFeedModal, setShowScheduleLiveStreamFeedModal] =
    React.useState<boolean>(false);

  // create feed modal handler
  const handleOpenCreateFeedModal = React.useCallback(
    () => setShowCreateFeedModal(true),
    [],
  );

  const handleCloseCreateFeedModal = React.useCallback(
    () => setShowCreateFeedModal(false),
    [],
  );

  // HTML feed preview modal handler
  const handleOpenPreviewLiveStreamFeedModal = React.useCallback(
    (feed: LiveStreamFeedSchema) => {
      setSelectedLiveStreamFeedSchema(feed);
      setShowPreviewLiveStreamFeedModal(true);
    },
    [],
  );

  const handleClosePreviewLiveStreamFeedModal = React.useCallback(() => {
    setSelectedLiveStreamFeedSchema(null);
    setShowPreviewLiveStreamFeedModal(false);
  }, []);

  // edit HTML feed modal handler
  const handleOpenEditLiveStreamFeedModal = React.useCallback(
    (feed: LiveStreamFeedSchema) => {
      setSelectedLiveStreamFeedSchema(feed);
      setShowEditLiveStreamFeedModal(true);
    },
    [],
  );

  const handleCloseEditLiveStreamFeedModal = React.useCallback(() => {
    setSelectedLiveStreamFeedSchema(null);
    setShowEditLiveStreamFeedModal(false);
  }, []);

  // delete HTML feed modal handler
  const handleOpenDeleteLiveStreamFeedModal = React.useCallback(
    (feed: LiveStreamFeedSchema) => {
      setSelectedLiveStreamFeedSchema(feed);
      setShowDeleteLiveStreamFeedModal(true);
    },
    [],
  );

  const handleCloseDeleteLiveStreamFeedModal = React.useCallback(() => {
    setSelectedLiveStreamFeedSchema(null);
    setShowDeleteLiveStreamFeedModal(false);
  }, []);

  // schedule HTML feed modal handler
  const handleOpenScheduleLiveStreamFeedModal = React.useCallback(
    (feed: LiveStreamFeedSchema) => {
      setSelectedLiveStreamFeedSchema(feed);
      setShowScheduleLiveStreamFeedModal(true);
    },
    [],
  );

  const handleCloseScheduleLiveStreamFeedModal = React.useCallback(() => {
    setSelectedLiveStreamFeedSchema(null);
    setShowScheduleLiveStreamFeedModal(false);
  }, []);

  const handleOpenActionsDropdown = React.useCallback(
    (feed: LiveStreamFeedSchema) => setSelectedLiveStreamFeedSchema(feed),
    [],
  );

  const handleSearchValueChange = React.useCallback((value: string) => {
    setSearchValue(value);
    setIsSearching(true);
  }, []);

  const debounceOnLastSearchValue = React.useMemo(
    () =>
      debounce((value) => {
        setGetliveStreamFeedsRequest({
          ...getLiveStreamFeedsRequest,
          params: { ...params, search: value },
        });
      }, 1000),
    [getLiveStreamFeedsRequest, params],
  );

  const handleOnPageNumberUpdate = React.useCallback(
    (pageNumber: number) =>
      setGetliveStreamFeedsRequest({
        ...getLiveStreamFeedsRequest,
        params: { ...params, page: pageNumber },
      }),
    [getLiveStreamFeedsRequest, params],
  );

  const handleCreateFeed = React.useCallback(
    (createLiveStreamFeedRequest: CreateLiveStreamFeedRequest) => {
      dispatch(createLiveStreamFeedActionCreator(createLiveStreamFeedRequest));
    },
    [dispatch],
  );

  const handleScheduleLiveStreamFeed = React.useCallback(
    (data: ScheduleContentRequestData) => {
      const {
        zoneId,
        duration,
        isOrdered,
        tags,
        startDate,
        endDate,
        weekDays,
        isLocked,
        playlistIds,
      } = data;

      const request: ScheduleLiveStreamFeedRequest = {
        appId: (selectedLiveStreamFeedSchema as LiveStreamFeedSchema).id,
        appType: params.appType,
        name: (selectedLiveStreamFeedSchema as LiveStreamFeedSchema).name,
        startDate: startDate && startDate.split('T')[0],
        endDate: endDate && endDate.split('T')[0],
        duration,
        zoneId,
      };

      if (isLocked) {
        request.isLocked = isLocked;
      }

      if (isOrdered) {
        request.isOrdered = isOrdered;
      }

      if (tags) {
        request.tags = tags;
      }

      if (playlistIds) {
        request.playlistIds = playlistIds;
      }

      if (weekDays) {
        request.weekDays = weekDays;
      }

      dispatch(scheduleLiveStreamFeedActionCreator(request));
    },
    [dispatch, params.appType, selectedLiveStreamFeedSchema],
  );

  const handleDeleteLiveStreamFeed = React.useCallback(
    ({ id }: LiveStreamFeedSchema) =>
      dispatch(deleteLiveStreamFeedActionCreator({ id })),
    [dispatch],
  );

  const handleEditLiveStreamFeed = React.useCallback(
    (editLiveStreamFeedRequest: EditLiveStreamFeedRequest) =>
      dispatch(editLiveStreamFeedActionCreator(editLiveStreamFeedRequest)),
    [dispatch],
  );

  const handleClickOnLiveStreamFeed = React.useCallback(
    (feed: LiveStreamFeedSchema) => handleOpenPreviewLiveStreamFeedModal(feed),
    [handleOpenPreviewLiveStreamFeedModal],
  );

  // effects
  React.useEffect(() => {
    if (isSearching) {
      debounceOnLastSearchValue(searchValue);
      setIsSearching(false);
    }
  }, [debounceOnLastSearchValue, isSearching, searchValue]);

  React.useEffect(() => {
    dispatch(getLiveStreamFeedsActionCreator(getLiveStreamFeedsRequest));
  }, [dispatch, getLiveStreamFeedsRequest, params]);

  React.useEffect(() => {
    if (isCreateLiveStreamFeedSuccess) {
      if (!isScheduleandCreateSuccess) {
        toast.success(t('apps.liveStream.feedCreated'));
        handleCloseCreateFeedModal();
      }
      setIsScheduleandCreateSuccess(false);
      dispatch(getLiveStreamFeedsActionCreator(getLiveStreamFeedsRequest));

      dispatch(
        createLiveStreamFeedResetActionCreator({
          loading: false,
          isCreateLiveStreamFeedSuccess: false,
          error: null,
        }),
      );
    }
    if (isScheduleLiveStreamFeedSuccess) {
      // toast.success(
      //   i18next.t<string>('apps.liveStream.feed_created_and_scheduled'),
      //   {
      //     position: 'bottom-center',
      //     autoClose: 3000,
      //     hideProgressBar: true,
      //   },
      // );

      dispatch(getLiveStreamFeedsActionCreator(getLiveStreamFeedsRequest));

      handleCloseCreateFeedModal();

      dispatch(
        createScheduleLiveStreamFeedResetActionCreator({
          loading: false,
          isCreateScheduleLiveStreamFeedSuccess: false,
          error: null,
        }),
      );
    }

    if (isScheduleLiveStreamFeedSuccess) {
      toast.success(t('apps.liveStream.feedScheduled'));
      handleCloseScheduleLiveStreamFeedModal();

      dispatch(
        scheduleLiveStreamFeedResetActionCreator({
          loading: false,
          isScheduleLiveStreamFeedSuccess: false,
          error: null,
        }),
      );
    }

    if (isEditLiveStreamFeedSuccess) {
      toast.success(t('apps.liveStream.feedEdited'));
      dispatch(getLiveStreamFeedsActionCreator(getLiveStreamFeedsRequest));

      handleCloseEditLiveStreamFeedModal();

      dispatch(
        editLiveStreamFeedResetActionCreator({
          loading: false,
          isEditLiveStreamFeedSuccess: false,
          error: null,
        }),
      );
    }

    if (isDeleteLiveStreamFeedSuccess) {
      toast.success(t('apps.liveStream.feedDeleted'));

      dispatch(getLiveStreamFeedsActionCreator(getLiveStreamFeedsRequest));

      handleCloseDeleteLiveStreamFeedModal();

      dispatch(
        deleteLiveStreamFeedResetActionCreator({
          loading: false,
          isDeleteLiveStreamFeedSuccess: false,
          error: null,
        }),
      );
    }
  }, [
    dispatch,
    getLiveStreamFeedsRequest,
    handleCloseCreateFeedModal,
    handleCloseDeleteLiveStreamFeedModal,
    handleCloseEditLiveStreamFeedModal,
    handleCloseScheduleLiveStreamFeedModal,
    isCreateLiveStreamFeedSuccess,
    isDeleteLiveStreamFeedSuccess,
    isEditLiveStreamFeedSuccess,
    isScheduleLiveStreamFeedSuccess,
    isCreateScheduleLiveStreamFeedSuccess,
  ]);

  return (
    <div>
      <div className="bg-white d-flex flex-column p-6">
        <div className="bg-white d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-4 w-100">
          <Search
            onChange={handleSearchValueChange}
            placeholder="Search"
            className="override-input-search-field-width-file-manager"
            searchValue={searchValue}
            wrapperClassName="mx-md-2"
          />

          <div className="d-flex flex-column flex-sm-row">
            {/* <button */}
            {/*  type="button" */}
            {/*  className="btn btn-light-primary d-flex flex-center" */}
            {/*  onClick={handleOpenFilterModal} */}
            {/* > */}
            {/*  <KTSVG */}
            {/*    path="/media/icons/duotune/general/gen031.svg" */}
            {/*    className="svg-icon-2" */}
            {/*  /> */}
            {/*  <span>Filter</span> */}
            {/* </button> */}
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleOpenCreateFeedModal}
            >
              <span>{t('apps.liveStream.createNewLiveStream')}</span>
            </button>
          </div>
        </div>

        <div className="bg-white d-flex flex-column w-100 flex-grow-1">
          {!isGetLiveStreamFeedsSuccess && error ? (
            <ErrorFeeds error={error} />
          ) : (
            <FeedsTable
              clickOnLiveStreamFeed={handleClickOnLiveStreamFeed}
              openEditLiveStreamFeedModal={handleOpenEditLiveStreamFeedModal}
              openDeleteLiveStreamFeedModal={
                handleOpenDeleteLiveStreamFeedModal
              }
              openActionsDropdown={handleOpenActionsDropdown}
              openScheduleLiveStreamFeedModal={
                handleOpenScheduleLiveStreamFeedModal
              }
              selectedLiveStreamFeed={selectedLiveStreamFeedSchema}
              isLoading={liveStreamFeedsLoading}
              paginatedLiveStreamFeeds={paginatedLiveStreamFeeds}
            />
          )}

          <Pagination
            total={paginatedLiveStreamFeeds.meta.totalItems}
            pageSize={paginatedLiveStreamFeeds.meta.itemsPerPage}
            pageNumber={paginatedLiveStreamFeeds.meta.currentPage}
            onPageNumberUpdate={handleOnPageNumberUpdate}
            className="mt-3 mb-5"
          />
        </div>
      </div>

      {/** Filter Modal */}
      {/* {showFilterModal ? <p>FilterModal</p> : null} */}

      {/** Create Feed Modal */}
      {showCreateFeedModal ? (
        <CreateFeedModal
          onSetIsScheduleandCreateSuccess={setIsScheduleandCreateSuccess}
          show={showCreateFeedModal}
          onSubmit={handleCreateFeed}
          onHide={handleCloseCreateFeedModal}
          title={t('apps.liveStream.createNewLiveStream')}
        />
      ) : null}

      {/** HTML Feed Preview Modal */}
      {showPreviewLiveStreamFeedModal ? (
        <PreviewFeedModal
          show={showPreviewLiveStreamFeedModal}
          onHide={handleClosePreviewLiveStreamFeedModal}
          selectedLiveStreamFeedSchema={
            selectedLiveStreamFeedSchema as LiveStreamFeedSchema
          }
          title={t('apps.liveStream.previewNewLiveStream')}
        />
      ) : null}

      {/** HTML Feed Edit Modal */}
      {showEditLiveStreamFeedModal ? (
        <EditFeedModal
          show={showEditLiveStreamFeedModal}
          onSubmit={handleEditLiveStreamFeed}
          onHide={handleCloseEditLiveStreamFeedModal}
          title={t('apps.liveStream.editFeed')}
          isLoading={editLiveStreamFeedLoading}
          selectedLiveStreamFeedSchema={
            selectedLiveStreamFeedSchema as LiveStreamFeedSchema
          }
        />
      ) : null}

      {/** HTML Feed Delete Modal */}
      {showDeleteLiveStreamFeedModal ? (
        <DeleteFeedModal
          show={showDeleteLiveStreamFeedModal}
          onHide={handleCloseDeleteLiveStreamFeedModal}
          title={t('apps.liveStream.deleteFeed')}
          onSubmit={handleDeleteLiveStreamFeed}
          selectedLiveStreamFeedSchema={
            selectedLiveStreamFeedSchema as LiveStreamFeedSchema
          }
          isLoading={deleteLiveStreamFeedLoading}
        />
      ) : null}

      {/** HTML Feed Schedule Modal */}
      {showScheduleLiveStreamFeedModal ? (
        <PublishModal
          onClose={handleCloseScheduleLiveStreamFeedModal}
          onSubmit={handleScheduleLiveStreamFeed}
          isVisible={showScheduleLiveStreamFeedModal}
          infoValidationSchema={{
            orientation: Yup.string().required(t('required')),
            duration: Yup.number().required(t('required')),
            startDate: Yup.date(),
            endDate: Yup.date().when('startDate', {
              is: (startDate: any) => !!startDate,
              then: Yup.date().min(
                Yup.ref('startDate'),
                t('common.validation.endDate'),
              ),
            }),
            isLocked: Yup.boolean(),
          }}
          skipOrder
          skipOrientationAuto
        />
      ) : null}
    </div>
  );
};

export default HTMLAppContainer;
