import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import account from '../../api/endpoints/account';
import { getUserData } from '../../store/actions/userData';
import GlobalState from '../../store/reducers/globalState.interface';
import Modal from '../Modal';

const WithPreview = ({
  children,
  modalContent,
  id,
  copy,
  handleShow,
  fullscreen,
  title,
  dialogClassName,
}: {
  children: React.ReactNode;
  copy?: () => void;
  id?: string;
  handleShow?: () => void;
  fullscreen?:
    | true
    | 'sm-down'
    | 'md-down'
    | 'lg-down'
    | 'xl-down'
    | 'xxl-down'
    | undefined;
  modalContent: React.ReactNode;
  title?: string;
  dialogClassName?: string;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const didUserUsePreviewBefore = useSelector(
    (state: any) => state.userData?.user?.onboarding?.devicePreview,
  );
  const isTrial = useSelector((state: GlobalState) => {
    return state.userData?.user?.isTrial;
  });
  const dispatch = useDispatch();

  const show = () => {
    setIsModalVisible(true);
    dispatch(getUserData());
    if (!didUserUsePreviewBefore) {
      (async () => {
        await account.updateOnboardingDeviceIsPreviewed();
      })();
    }
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={show} onClick={show}>
        {children}
      </span>
      <Modal
        show={isModalVisible}
        title={title}
        copy={copy}
        isTrial={isTrial}
        fullscreen={fullscreen}
        handleShow={handleShow}
        onHide={() => setIsModalVisible(false)}
        withFooter={false}
        widthDefaultSize={false}
        dialogClassName={dialogClassName}
        bodyClassName={id}
      >
        {modalContent}
      </Modal>
    </>
  );
};

WithPreview.defaultProps = {
  title: '',
};

export default WithPreview;
