import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import clsx from 'clsx';
import DeviceGroup from '../../../interfaces/deviceGroup.interface';
import UpdateDeviceGroupRequestData from '../../../api/endpoints/devicesGroups/interfaces/updateDeviceGroupRequestData.interface';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../FormErrorMessage';
import TextInput from '../../TextInput';
import Modal from '../../Modal';
import { SelectedValue } from '../../Select/SelectAsync';
import SelectDevices from '../../../containers/Devices/SelectDevices';
import Device from '../../../interfaces/devices/device.interface';
import User from '../../../interfaces/user/user.interface';
import Tooltip from '../../Tooltip';
import SelectUsers from '../../Users/SelectUsers';
import styles from '../devicesGroups.module.scss';

interface FormInputs {
  name: string;
  deviceIds: SelectedValue[];
  userIds: SelectedValue[];
}

interface EditGroupProps {
  group: DeviceGroup;
  children: React.ReactNode;
  isName?: boolean;
  onSubmit?: (updateData: {
    id: string;
    data: UpdateDeviceGroupRequestData;
  }) => void;
}

interface DefaultProps extends EditGroupProps {
  onSubmit: Required<EditGroupProps>['onSubmit'];
}

const EditGroup = ({ group, onSubmit, children, isName }: DefaultProps) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().required(t('contents.validation.please_enter_name')),
      }).required(),
    ),
    defaultValues: {
      name: group.name,
      deviceIds:
        group?.devices?.map((device: Device) => ({
          label: device.name,
          value: device.id,
        })) ?? [],
      userIds:
        group?.users?.map((user: User) => ({
          label: user.firstName,
          value: user.id,
        })) ?? [],
    },
  });

  const [name, setName] = useStateWithHookForm<FormInputs, string>(
    { setValue, trigger, name: 'name' },
    group.name,
  );

  const [devices, setDevices] = useStateWithHookForm<
    FormInputs,
    { label?: string; value?: string }[]
  >(
    {
      setValue,
      trigger,
      name: 'userIds',
    },
    group?.devices?.map((device: Device) => ({
      label: device.name,
      value: device.id,
    })) ?? [],
  );

  const [users, setUsers] = useStateWithHookForm<
    FormInputs,
    { label?: string; value?: string }[]
  >(
    {
      setValue,
      trigger,
      name: 'deviceIds',
    },
    group?.users?.map((user: User) => ({
      label: user.firstName,
      value: user.id,
    })) ?? [],
  );

  // group.devices?.map((group: DeviceGroup) => ({
  //   label: group.name,
  //   value: group.id,
  // })) ?? [],
  const close = () => setIsModalVisible(false);
  const open = () => setIsModalVisible(true);

  const handleSubmitData = () => {
    if (isName) {
      onSubmit({
        id: group.id,
        data: {
          name,
        },
      });
    } else {
      onSubmit({
        id: group.id,
        data: {
          name,
          deviceIds: (devices.map((item) => item.value) as string[]) ?? [],
          userIds: (users?.map((item) => item.value) as string[]) ?? [],
        },
      });
    }

    close();
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        onHide={close}
        show={isModalVisible}
        title={t('devices_groups.edit_device_group.edit_group')}
        widthDefaultSize={false}
        withFooter={false}
        bodyClassName="py-5"
      >
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <p className="mb-2">
            <label
              htmlFor="deviceGroupName"
              className="col-form-label text-lg-end"
            >
              {t('devices_groups.edit_device_group.group_name')}
            </label>
            <TextInput
              name="name"
              id="deviceGroupName"
              placeholder={t('devices_groups.edit_device_group.group_name')}
              value={name}
              onChange={setName}
            />
            <FormErrorMessage
              name="name"
              errors={errors}
              className="mt-1 min-h-20px"
            />
          </p>
          {!isName && (
            <>
              {' '}
              <div className="mb-6">
                <div className="row">
                  <label className="col mw-65px fs-6 fw-bolder mb-2 required">
                    {t('devices.devices')}
                  </label>
                  <div className="col mw-45px">
                    <Tooltip text={t('devices.multipleInfo')}>
                      <i
                        className={clsx(
                          'fas fa-info-circle',
                          styles.tooltipDeviceGroup,
                        )}
                        data-bs-toggle="tooltip"
                      />
                    </Tooltip>
                  </div>
                </div>

                <SelectDevices
                  initialValue={devices.map(({ value }) => value as string)}
                  onChange={(values) => setDevices(values as SelectedValue[])}
                  hideLicence
                />

                {errors.deviceIds && (
                  <FormErrorMessage
                    name="deviceIds"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                )}
              </div>
              <div className="mb-6">
                <label className="fs-6 fw-bolder mb-2">
                  {t('users.users')}
                </label>

                <SelectUsers
                  initialValue={users}
                  onChange={(values) => setUsers(values)}
                />
                <FormErrorMessage
                  name="users"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>
            </>
          )}

          <Modal.Separator />
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {t('common.save')}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

EditGroup.defaultProps = {
  onSubmit: () => null,
};

export default EditGroup;
