import { map } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import SortOrder from '../../../enums/sortOrder.enum';
import Template from '../../../interfaces/template.interface';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../utils/hooks/useTableMultiSelect';
import { readFromQueryString } from '../../../utils/queryParams';
import Table from '../../Table';
import Columns from '../../Table/Columns';
import WithSpinner from '../../WithSpinner';
import TableRow from './TableRow';

export default ({
  templates,
  isLoading,
  sortQueryField,
  orderQueryField,
  onUpdate,
  preloaderHeight = 300,
  isSelectable = false,
  multiSelectConfig: { selectedItems, setSelectedItem, selectAll },
}: {
  templates: Template[];
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight: number;
  onUpdate?: () => void;
  isSelectable?: boolean;
  multiSelectConfig: TableMultiSelectConfig;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  const columns = [
    {
      name: t('templates.table.title'),
    },
    {
      name: t('templates.table.layout'),
    },
    {
      name: t('templates.table.background'),
    },
    {
      name: t('templates.table.logo'),
    },
    {
      name: t('common.actions'),
    },
  ];
  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns
            columns={columns}
            checked={isAllSelected(selectedItems, map(templates, 'id'))}
            onChange={selectAll}
            config={{
              sortField,
              setSortField,
              sortQueryField,
              orderQueryField,
              isSelectable,
            }}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={!isLoading && !templates.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={t('templates.noFound')}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {templates.map((template) => (
            <TableRow
              isChecked={selectedItems.includes(template.id)}
              key={template.id}
              template={template}
              onCheck={setSelectedItem}
              config={{ isSelectable: false }}
              onUpdate={onUpdate}
            />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
