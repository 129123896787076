import { useEffect, useState } from 'react';
import api from '../../api';

enum AllowanceType {
  Device = 'device',
  DeviceGroup = 'deviceGroup',
}

const checkAllowance = async (
  allowanceType: AllowanceType,
  data: {
    deviceId?: string;
    deviceGroupId?: string;
    priority: number;
    endDate: Date;
    startDate: Date;
  },
) => {
  switch (allowanceType) {
    case AllowanceType.Device: {
      return !(await api.devices.getPlaylists(data)).length;
    }
    case AllowanceType.DeviceGroup: {
      return !(await api.devicesGroups.getPlaylists(data)).length;
    }

    default:
      return true;
  }
};

const useCreatePlaylistAllowance = ({
  devices,
  deviceGroupIds,
  priority,
  startDate,
  endDate,
  playlistId,
}: {
  devices: { label?: string; value?: string }[];
  deviceGroupIds: { label?: string; value?: string }[];
  priority?: number;
  startDate: Date;
  endDate: Date;
  playlistId?: string;
}) => {
  if (playlistId) return true;

  const [deviceAllowance, setDeviceAllowance] = useState(true);
  const [deviceGroupAllowance, setDeviceGroupAllowance] = useState(true);

  const [allowance, setAllowance] = useState(true);

  useEffect(() => {
    setAllowance(deviceAllowance && deviceGroupAllowance);
  }, [deviceAllowance, deviceGroupAllowance]);

  useEffect(() => {
    if (devices?.length && !!priority) {
      devices.forEach(async (item: { label?: string; value?: string }) => {
        if (!item.value) return;

        const checkedAllowance = await checkAllowance(AllowanceType.Device, {
          priority,
          startDate,
          endDate,
          deviceId: item.value,
        });

        setDeviceAllowance(checkedAllowance);
      });
    } else {
      setDeviceAllowance(true);
    }
  }, [devices]);

  useEffect(() => {
    if (deviceGroupIds?.length && !!priority) {
      deviceGroupIds.forEach(
        async (item: { label?: string; value?: string }) => {
          if (!item.value) return;

          const checkedAllowance = await checkAllowance(
            AllowanceType.DeviceGroup,
            {
              priority,
              startDate,
              endDate,
              deviceGroupId: item.value,
            },
          );

          setDeviceGroupAllowance(checkedAllowance);
        },
      );
    } else {
      setDeviceGroupAllowance(true);
    }
  }, [deviceGroupIds]);

  return allowance;
};

export default useCreatePlaylistAllowance;
