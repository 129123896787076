import { UserRole } from '../enums/userRole.enum';

export const userAllowedRoles: { [key: string]: UserRole[] } = {
  [UserRole.SuperAdmin]: Object.values(UserRole),
  [UserRole.Admin]: [UserRole.Admin, UserRole.Manager, UserRole.Employee],
  [UserRole.AccountOwner]: [
    UserRole.Admin,
    UserRole.Manager,
    UserRole.Employee,
  ],
  [UserRole.Manager]: [UserRole.Admin, UserRole.Manager, UserRole.Employee],
  [UserRole.Employee]: [UserRole.Admin, UserRole.Manager, UserRole.Employee],
};
