import React from 'react';
import clsx from 'clsx';
import ThumbnailIcon from './ThumbnailIcon';
import WeatherThumbnail from './WeatherThumbnail';
import YouTubeThumbnail from './YouTubeThumbnail';
import AppTypes from '../../../../../../enums/appType.enum';
import IContent from '../../../../../../interfaces/content.interface';
import noPreview from '../../../../../../assets/images/noPreview/noPreview.png';
import googleCalendar from '../../../../../../assets/images/apps/google_calendar.svg';
import googleSlides from '../../../../../../assets/images/apps/google_slides.svg';
import googleDocs from '../../../../../../assets/images/apps/google_docs.svg';
import sheets from '../../../../../../assets/images/apps/sheets.svg';
import powerBi from '../../../../../../assets/images/apps/powerBi.svg';
import styles from './thumbnails.module.scss';
import RssThumbnail from './RssThumbnail';
import CanvaThumbnail from '../../../../../Apps/Canva/CanvaThumbnail';
import TwitterThumbnail from './TwitterThumbnail';
import FacebookThumbnail from './FacebookThumbnail';
import InstagramThumbnail from './InstagramThumbnail';
import MsTeamsThumbnail from './MsteamsThumbnail';
import CountDownTimerThumbnail from './CountDownThumbnail';
import CountUpTimerThumbnail from './CountUpThumbnail';
import DaysSafeThumbnail from './DaysSafeThumbnail';
import Orientation from '../../../../../../enums/orientation.enum';
import playlistStyles from '../../../../../Playlist/Playlist.module.scss';
import LiveStreamThumbnail from './LiveStreamThumbnail';

export default ({ content }: { content: IContent }) => {
  switch (content.app?.type) {
    case AppTypes.Html:
      return <i className={clsx('fab fa-html5', styles.html5)} />;
    case AppTypes.Rss:
      return <RssThumbnail content={content} />;
    case AppTypes.GoogleSheet:
      return <ThumbnailIcon src={sheets} />;
    case AppTypes.GoogleDocs:
      return <ThumbnailIcon src={googleDocs} />;
    case AppTypes.GoogleSlides:
      return <ThumbnailIcon src={googleSlides} />;
    case AppTypes.GoogleCalendar:
      return <ThumbnailIcon src={googleCalendar} />;
    case AppTypes.PowerBi:
      return <ThumbnailIcon src={powerBi} />;
    case AppTypes.Canva:
      return <CanvaThumbnail app={content.app} />;
    case AppTypes.YouTube:
      return <YouTubeThumbnail content={content} />;
    case AppTypes.Weather:
      return <WeatherThumbnail content={content} />;
    case AppTypes.Twitter:
      return <TwitterThumbnail content={content} />;
    case AppTypes.Facebook:
      return <FacebookThumbnail content={content} />;
    case AppTypes.Instagram:
      return <InstagramThumbnail content={content} />;
    case AppTypes.MsTeams:
      return <MsTeamsThumbnail content={content} />;
    case AppTypes.QrCode:
      return (
        <img
          src={content?.app?.dependency?.config?.qrCodeImage}
          onError={({ currentTarget }) => {
            // eslint-disable-next-line no-param-reassign
            currentTarget.src = noPreview;
          }}
          alt="preview"
          className={clsx(
            content.zoneId !== Orientation.Landscape ? 'h-30px' : '',
            styles.thumbnail,
            playlistStyles.image_background,
            playlistStyles.imageThumbnail,
          )}
        />
      );
    case AppTypes.CountDownTimer:
      return <CountDownTimerThumbnail content={content} />;
    case AppTypes.CountUpTimer:
      return <CountUpTimerThumbnail content={content} />;
    case AppTypes.DaysSafe:
      return <DaysSafeThumbnail content={content} />;
    case AppTypes.LiveStream:
      return <LiveStreamThumbnail content={content} />;

    default:
      return (
        <img src={noPreview} className={styles.noPreview} alt="no preview" />
      );
  }
};
