import GetUsersGroupsSuccessAction from './interfaces/getUsersGroupsSuccessAction.interface';
import DeleteUsersGroupAction from './interfaces/deleteUsersGroupAction.interface';
import CreateUsersGroupAction from './interfaces/createUsersGroupAction.interface';
import UpdateUsersGroupAction from './interfaces/updateUsersGroupAction.interface';
import GetUsersGroupsAction from './interfaces/getUsersGroupsAction.interface';
import UsersGroupsActions from './usersGroupsActions.enum';
import LoadingAction from '../loadingAction.interface';

export const getUsersGroups = (
  payload: GetUsersGroupsAction['payload'],
): GetUsersGroupsAction => ({
  type: UsersGroupsActions.GET_USERS_GROUPS,
  payload,
});

export const getUsersGroupsSuccess = (
  payload: GetUsersGroupsSuccessAction['payload'],
): GetUsersGroupsSuccessAction => ({
  type: UsersGroupsActions.GET_USERS_GROUPS_SUCCESS,
  payload,
});

export const deleteUsersGroup = (
  payload: DeleteUsersGroupAction['payload'],
): DeleteUsersGroupAction => ({
  type: UsersGroupsActions.DELETE_USERS_GROUP,
  payload,
});

export const createUsersGroup = (
  payload: CreateUsersGroupAction['payload'],
): CreateUsersGroupAction => ({
  type: UsersGroupsActions.CREATE_USERS_GROUP,
  payload,
});

export const updateUsersGroup = (
  payload: UpdateUsersGroupAction['payload'],
): UpdateUsersGroupAction => ({
  type: UsersGroupsActions.UPDATE_USERS_GROUP,
  payload,
});

export const setUsersGroupsLoading = (
  payload: LoadingAction['payload'],
): LoadingAction => ({
  type: UsersGroupsActions.SET_USERS_GROUPS_LOADING,
  payload,
});

export const setTrialExpireDays = (payload: { trialExpireDays: number }) => ({
  type: UsersGroupsActions.SET_TRIAL_EXPIRE_DAYS,
  payload,
});
