import config from '../routing/config';

const configs = Object.values(config);

export const getRouteId = (route: string) => {
  const matched = route.match(/\/[^/]+$/g);

  return matched ? matched[0].replace('/', '') : undefined;
};

export const isMatchesRoute = (route: string, value: string): boolean => {
  if (route === '/' && route === value) {
    return true;
  }
  return new RegExp(`^${route.replaceAll(/\/:[^/]*/g, '/.+')}$`).test(
    value.replace(/\/$/, ''),
  );
};

export const getConfigByRoute = (value: string) =>
  configs.find(({ route }) => isMatchesRoute(route, value));
