import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../api';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import DeviceAssignmentTarget from '../../../interfaces/devices/deviceAssignmentTarget.interface';
import DeviceAssignmentTargetEnum from '../../../enums/deviceAssignmentTarget.enum';
import Device from '../../../interfaces/devices/device.interface';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
import License from '../../../interfaces/license.interface';
import GlobalState from '../../../store/reducers/globalState.interface';
import { setDevicesLoading } from '../../../store/actions/devices';
import WithSpinner from '../../../components/WithSpinner';
import { readAllFromQueryParams } from '../../../utils/queryParams';

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

type DeviceOptions = (Option & Device)[];
type OptionsWithLicense = (Option & { license: License })[];

const mapDevicesToSelects = (devices: Device[], reponsedLicence?: License) =>
  devices.map(({ id, name, license }) => {
    const licenseTypeName = license?.licenseType?.name;
    if (reponsedLicence) {
      return {
        value: id,
        label: `${name}${licenseTypeName ? ` (${licenseTypeName})` : ''}`,
        license,
        disabled: license.licenseType.id !== reponsedLicence?.licenseType.id,
      };
    }
    return {
      value: id,
      label: `${name}${licenseTypeName ? ` (${licenseTypeName})` : ''}`,
      license,
    };
  });

const mapOptionsWithLicensing = (
  options: DeviceOptions,
  selectedValue: OptionsWithLicense,
) =>
  options.map((option) =>
    selectedValue.find(
      ({ license, value }) =>
        value !== option.value &&
        license?.licenseType?.id !== option?.license?.licenseType?.id,
    )
      ? { ...option, disabled: true }
      : option,
  );

const findOptionWithSameLicense = (options: OptionsWithLicense) =>
  options.find(({ license }) =>
    options.find(
      (option) => option.license?.licenseType?.id === license?.licenseType?.id,
    ),
  );

const SelectDevices = ({
  onChange,
  initialValue,
  deviceAssignmentTarget,
  denyDifferentLicense,
  license,
}: {
  onChange: (selectedValue: SelectedValue[]) => void;
  initialValue?: any;
  deviceAssignmentTarget?: DeviceAssignmentTarget;
  denyDifferentLicense?: boolean;
  license?: License;
}) => {
  const { adminDevices, loading } = useSelector(
    (state: GlobalState) => state.devices,
  );
  const [options, setOptions] = useState<any>([]);
  const { id: deviceGroupId } = useParams<{ id: string }>();
  const [isAdmin] = useIsAdmin();
  const dispatch = useDispatch();

  const { search } = useLocation();

  const loadOptions = async () => {
    let items: Device[];
    dispatch(setDevicesLoading({ loading: true }));

    switch (deviceAssignmentTarget?.type) {
      case DeviceAssignmentTargetEnum.Playlist:
        ({ items } = await api.playlists.getPlaylistDevices(
          {},
          deviceAssignmentTarget.id,
        ));
        break;
      case DeviceAssignmentTargetEnum.User:
        ({ items } = isAdmin
          ? await api.devices.getCustomerDevicesList(
              deviceAssignmentTarget.id,
              { exclude: true },
            )
          : await api.users.getUserDevices(
              { exclude: true },
              deviceAssignmentTarget.id,
            ));
        break;
      case DeviceAssignmentTargetEnum.Device:
        ({ items } = isAdmin
          ? // ? await api.devices.getCustomerDevicesList(
            //     deviceAssignmentTarget.id,

            //     { exclude: true },
            //   )

            await api.devices.getAdminDevicesList({
              userGroupId: deviceAssignmentTarget.id,
              exclude: true,
              currentUserId: readAllFromQueryParams(search)
                .currentUserId as string,
            })
          : await api.usersGroups.getDevicesAssignedToUserGroup(
              { exclude: true },
              deviceGroupId,
            ));
        break;
      default:
        ({ items } = !isAdmin
          ? await api.devicesGroups.getDeviceGroupDevices(
              { exclude: true },
              deviceGroupId,
            )
          : await api.devices.getDeviceGroupeDevicesByCurrentUserId({
              exclude: true,
              currentUserId: readAllFromQueryParams(search)
                .currentUserId as string,
              deviceGroupId,
            }));
    }
    dispatch(setDevicesLoading({ loading: false }));
    setOptions(
      denyDifferentLicense
        ? mapDevicesToSelects(items, license)
        : items.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
    );
  };

  const handleFilterOptions = (filteredOptions: any, input: string) => {
    return filteredOptions.filter((option: { label: string }) =>
      option.label.toLowerCase().includes(input.toLowerCase()),
    );
  };

  useEffect(() => {
    loadOptions();
  }, [adminDevices]);

  return (
    <WithSpinner isLoading={loading}>
      <MultiSelect
        options={
          denyDifferentLicense
            ? mapOptionsWithLicensing(
                options as DeviceOptions,
                initialValue as OptionsWithLicense,
              )
            : options
        }
        value={initialValue}
        onChange={onChange}
        filterOptions={handleFilterOptions}
        labelledBy="Selects Devices"
        hasSelectAll={
          denyDifferentLicense && !findOptionWithSameLicense(options)
        }
      />
    </WithSpinner>
  );
};

SelectDevices.defaultProps = {
  initialValue: [],
};

export default SelectDevices;
