import clsx from 'clsx';
import React from 'react';
import { Dropdown } from 'react-bootstrap-v5';
import styles from './cardDropdown.module.scss';

const CustomToggle = React.forwardRef<
  HTMLDivElement,
  { onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void }
>(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div
    role="button"
    tabIndex={-1}
    ref={ref}
    onClick={(event) => {
      event.preventDefault();

      onClick(event);
    }}
  >
    {children}
  </div>
));

interface CardDropDownProps {
  children: React.ReactNode;
  toggle: React.ReactNode;
  show?: boolean;
  onToggle?: (isOpen: boolean) => void;
  className?: string;
  menuClassName?: string;
  containerClassName?: string;
  closeOnSelect?: boolean;
  renderClosed?: boolean;
}

const CardDropdown = ({
  children,
  toggle,
  show,
  onToggle,
  className,
  menuClassName,
  containerClassName,
  closeOnSelect = true,
  renderClosed = true,
}: CardDropDownProps) => {
  const handleMenuClick = () => {
    if (closeOnSelect && onToggle) onToggle(false);
  };

  return (
    <div className={clsx('card-toolbar', className)}>
      <Dropdown
        show={show}
        onToggle={onToggle}
        className={styles.resetBootstrapDropdown}
      >
        <Dropdown.Toggle as={CustomToggle}>{toggle}</Dropdown.Toggle>

        {(renderClosed || show) && (
          <Dropdown.Menu
            onClick={handleMenuClick}
            className={clsx(styles.resetBootstrapMenu, containerClassName)}
          >
            <div
              className={clsx(
                'menu menu-sub menu-sub-dropdown show',
                menuClassName,
              )}
            >
              {children}
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};

export default CardDropdown;
