import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssignDevices from '../../../../../containers/Devices/AssignDevices';
import AssignUsers from '../../../../../containers/Users/AssignUsers';
import SelectNavTab from '../../../../NavTabs/SelectNavTab';
import Card from '../../../../Card';
import CreateDeviceGroupRequestData from '../../../../../api/endpoints/devicesGroups/interfaces/createDeviceGroupRequestData.interface';
import useDidUpdate from '../../../../../utils/hooks/useDidUpdate';
import DeviceGroup from '../../../../../interfaces/deviceGroup.interface';

enum Tab {
  Devices = 'devices',
  Users = 'users',
}

export default ({
  data,
  setData,
  group,
}: {
  data: Partial<CreateDeviceGroupRequestData>;
  setData: (newData: Partial<CreateDeviceGroupRequestData>) => void;
  group?: DeviceGroup;
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.Devices);

  const devicesPreselectedItems = group?.devices?.map((item) => item.id);
  const usersPreselectedItems = group?.users?.map((item) => item.id);

  let currentTable: React.ReactNode;
  useDidUpdate(
    () =>
      setData({
        ...data,
        deviceIds: data.deviceIds,
        userIds: data.userIds,
      }),
    [currentTab],
  );
  switch (currentTab) {
    case Tab.Devices:
      currentTable = (
        <AssignDevices
          preselectedItems={data.deviceIds ?? devicesPreselectedItems ?? []}
          onSelectionChange={(selectedItems) =>
            setData({ ...data, deviceIds: selectedItems })
          }
        />
      );
      break;
    case Tab.Users:
      currentTable = (
        <AssignUsers
          preselectedItems={data.userIds ?? usersPreselectedItems ?? []}
          onSelectionChange={(selectedItems) =>
            setData({ ...data, userIds: selectedItems })
          }
          companyId={data?.companyId}
          locationId={data?.locationId}
        />
      );
      break;
    default:
      currentTable = '';
  }

  return (
    <Card className="mb-5" withoutMargins>
      <Card.Header>
        <Card.Header.Toolbar>
          <SelectNavTab<Tab>
            tabs={[
              {
                key: Tab.Devices,
                isActive: currentTab === Tab.Devices,
                text: t('devices.devices'),
              },
              {
                key: Tab.Users,
                isActive: currentTab === Tab.Users,
                text: t('devices_groups.create_group.assigned_users'),
              },
            ]}
            onSelect={setCurrentTab}
          />
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>{currentTable}</Card.Body>
    </Card>
  );
};
