import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';

export interface Option<T = string> {
  value: T;
  label: string;
  isDisabled?: boolean;
}

const Select = <T extends string = string>({
  options,
  defaultValue,
  onSelect,
  id,
  placeholder,
  name,
  isDisabled,
  observeDefaultValueChange,
  isMulti,
  className,
  isClearable,
  defaultStyles = true,
  components,
}: {
  options: Option[];
  defaultValue?: Option | Option[];
  onSelect: (value: T | T[]) => void;
  id?: string;
  placeholder?: string;
  name?: string;
  isDisabled?: boolean;
  observeDefaultValueChange?: boolean;
  isMulti?: boolean;
  className?: string;
  isClearable?: boolean;
  defaultStyles?: boolean;
  components?: any;
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  useEffect(() => {
    if (observeDefaultValueChange) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  const handleSelect = (event: any): void => {
    setSelectedValue(event);
    onSelect(
      event instanceof Array ? event.map(({ value }) => value) : event?.value,
    );
  };

  return (
    <ReactSelect
      className={className}
      isMulti={isMulti}
      options={options}
      isClearable={isClearable}
      styles={
        defaultStyles
          ? {
              control: (provided) => {
                return {
                  ...provided,
                  background: '#F5F8FA',
                  color: '#9899AC',
                  boxShadow: 'none',
                  border: 'none',
                };
              },
            }
          : {}
      }
      onChange={handleSelect}
      components={{
        IndicatorSeparator: () => null,
        ...components,
      }}
      id={id}
      placeholder={placeholder}
      name={name}
      value={selectedValue}
      isDisabled={isDisabled}
    />
  );
};

Select.defaultProps = {
  isDisabled: false,
};

export default Select;
