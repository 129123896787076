import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../../../api';
import { KTSVG } from '../../../../../lib/metronic/helpers';
import Modal from '../../../../Modal';
import CreateSku from '../../CreateSku';

export default ({
  children,
  library,
  onUpdate,
}: {
  children?: React.ReactNode;
  onUpdate?: (topicId: string) => void;
  library?: any;
}) => {
  const { t } = useTranslation();

  const [isModalVisible, setsIsModalVisible] = useState(false);

  const open = () => {
    setsIsModalVisible(true);
  };

  const close = () => {
    setsIsModalVisible(false);
  };
  const handleSubmit = (data: any) => {
    // let id = '';
    // [...library?.posters, ...library?.content].map((item: any) => {
    //   id = item.id;
    // });

    api.libraries
      .createPostersAndContents(data, library.id)
      .then((res) => {
        close();
        if (onUpdate) {
          onUpdate(res.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </span>
        )}
      </span>
      <Modal
        show={isModalVisible}
        title={t('library.indigo.createSku')}
        onHide={close}
        withFooter={false}
        widthDefaultSize={false}
        dialogClassName="mw-900px"
        // bodyClassName="d-flex flex-center flex-column my-7"
      >
        <CreateSku
          onSubmit={handleSubmit}
          incrementIndexForPosters={library?.posters?.length}
          incrementIndexForContent={library?.content?.length}
          incrementIndexForVideos={library?.videos?.length}
          incrementIndexForFullScreen={library?.fullScreen?.length}
          update
        />
      </Modal>
    </>
  );
};
