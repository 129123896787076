/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import Card from '../Card';
import Search from '../Search/QueryParamSearch';
import Pagination from '../Pagination/QueryParamPagination';
import SortingQueryParam from '../../enums/queryParams/sortingQueryParam.enum';
import PaginationQueryParam from '../../enums/queryParams/paginationQueryParam.enum';
import UsersGroupsQueryParam from '../../api/endpoints/usersGroups/enums/usersGroupsQueryParam.enum';
import UsersQueryParam from '../../api/endpoints/users/enums/usersQueryParam.enum';
import CreateUserGroup from '../../containers/UsersGroups/CreateGroup';
import UsersGroup from '../../interfaces/usersGroup.interface';
import { buildQueryString } from '../../utils/queryParams';
import { UserStatus } from '../../enums/userStatus.enum';
import FiltersDropdown from './FiltersDropdown';
import GroupsTable from './GroupsTable';
import NavTabs from '../NavTabs';
import routesConfig from '../../routing/config';
import useTableMultiSelect from '../../utils/hooks/useTableMultiSelect';
import RemoveUserGroup from '../../containers/UsersGroups/RemoveGroup';
import { SelectedValue } from '../Select/SelectAsync';
import AssignUserGroup from '../../containers/UsersGroups/AssignUserGroupToGroup';
import { KTSVG } from '../../lib/metronic/helpers';
import UpdateUsersGroupAction from '../../store/actions/usersGroups/interfaces/updateUsersGroupAction.interface';
import { UserRole } from '../../enums/userRole.enum';
import GlobalState from '../../store/reducers/globalState.interface';

// const pageSize = 7;
const tableRowHeight = 63;

const options = [
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 1000, label: '1000' },
];

export default ({
  groups,
  total,
  isLoading,
  resetOnSearch,
  children,
  isSelectable,
  onEditUserGroup,
  assignUserGroup,
  addUserGroup,
  unAssignUserGroup,
  onDeleteUserGroup,
  userProfileId,
}: {
  groups: UsersGroup[];
  total: number;
  isLoading: boolean;
  resetOnSearch?: boolean;
  isSelectable?: boolean;
  children?: React.ReactNode;
  onEditUserGroup?: (data: UpdateUsersGroupAction['payload']) => void;
  assignUserGroup?: (data: SelectedValue[]) => void;
  addUserGroup?: (data: any) => void;
  unAssignUserGroup?: (deviceGroupId: string, userGroupId: string) => void;
  onDeleteUserGroup?: (id: string) => void;
  userProfileId?: string;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [pageSize, setPageSize] = useState(10);
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(groups, 'id'),
    [],
    resetOnSearch ? search : undefined,
  );

  const navTabs = [
    {
      key: 'active-groups',
      tab: (
        <Link
          to={routesConfig.users.route}
          className="nav-link text-active-primary me-6"
        >
          {t('users.active_users')}
        </Link>
      ),
    },
    {
      key: 'groups-groups',
      tab: (
        <span className="nav-link text-active-primary me-6 active">
          {t('users.users_groups')}
        </span>
      ),
    },
    {
      key: 'disabled-groups',
      tab: (
        <Link
          to={`${routesConfig.users.route}/${buildQueryString({
            [UsersQueryParam.Status]: UserStatus.Disabled,
          })}`}
          className="nav-link text-active-primary me-6"
        >
          {t('users.archive')}
        </Link>
      ),
    },
  ];
  const actionButtonFroAssignOrUpdate = () => {
    if (roles.includes(UserRole.Employee) || roles.includes(UserRole.Manager)) {
      return;
    }
    if (assignUserGroup) {
      return (
        <AssignUserGroup assignUserGroup={assignUserGroup}>
          <button type="button" className="btn btn-primary">
            <KTSVG
              path="/media/icons/duotune/arrows/arr087.svg"
              className="svg-icon-3"
            />

            {t('users_groups.add_group')}
          </button>
        </AssignUserGroup>
      );
    }
    if (addUserGroup) {
      return (
        <CreateUserGroup
          className="me-2"
          ownerId={userProfileId}
          addUserGroup={addUserGroup}
        />
      );
    }
    return <CreateUserGroup className="me-2" />;
  };
  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          {children || <NavTabs tabs={navTabs} separatorClassName="mb-7" />}
          <Search
            queryField={UsersGroupsQueryParam.Search}
            pageNumberQueryField={PaginationQueryParam.Page}
            placeholder={t('users_groups.search_groups')}
            className="me-2"
          />
          <div className="d-flex flex-wrap flex-stack">
            <FiltersDropdown className="me-4" UserId={userProfileId} />
            {selectedItems.length > 1 && (
              <RemoveUserGroup selectedItems={selectedItems}>
                <button type="button" className="btn btn-danger m-2">
                  {t('common.delete_confirmation.delete_selected')}
                </button>
              </RemoveUserGroup>
            )}
            {actionButtonFroAssignOrUpdate()}
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <GroupsTable
          groups={groups}
          isLoading={isLoading}
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
          unAssignUserGroup={unAssignUserGroup}
          onEditUserGroup={onEditUserGroup}
          isSelectable={isSelectable}
          onDeleteUserGroup={onDeleteUserGroup}
          userProfileId={userProfileId}
        />
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-80px mb-4">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(+e.target.value);
              }}
              className="form-select form-select-sm form-select-solid"
            >
              {options.map((option) => (
                <option key={`${option.label}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-fill ps-5">
            <Pagination
              total={total}
              pageSize={pageSize}
              pageNumberQueryField={PaginationQueryParam.Page}
              pageSizeQueryField={PaginationQueryParam.Limit}
              className="mt-3 mb-5"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
