import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import api from '../../../api';
import Modal from '../../../components/Modal';
import CreateAppRequestData from '../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import CreateEditTickerModal from '../../../components/Ticker/CreateEditTickerModal';
import App from '../../../interfaces/app.interface';
import Tooltip from '../../../components/Tooltip';

export default ({
  children,
  ticker,
  onUpdate,
}: {
  ticker: App;
  onUpdate: () => void;
  children?: React.ReactNode;
}) => {
  const [modalShown, setModalShown] = useState<boolean>(false);
  const { t } = useTranslation();
  const open = () => setModalShown(true);
  const handleClose = () => setModalShown(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (data: CreateAppRequestData) => {
    setIsLoading(true);
    api.apps.updateApp(ticker.id, data).then(() => {
      handleClose();
      if (onUpdate) {
        onUpdate();
      }
      toast.success(i18next.t<string>('tickers.ticker_edited'));
    });
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.edit')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-edit" />
            </span>
          </Tooltip>
        )}
      </span>
      {modalShown && (
        <Modal
          title={t('tickers.addTicker.editTicker')}
          show={modalShown}
          onHide={handleClose}
          withFooter={false}
        >
          <CreateEditTickerModal
            isLoading={isLoading}
            handleClose={handleClose}
            app={ticker}
            onSubmit={handleSubmit}
          />
        </Modal>
      )}
    </>
  );
};
