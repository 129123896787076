import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useLocation } from 'react-router-dom';
import { isEqual, map, sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import License from '../../interfaces/license.interface';
import { SelectedValue } from '../../components/Select/SelectAsync';
import DeviceGroup from '../../interfaces/deviceGroup.interface';
import api from '../../api';
import useIsAdmin from '../../utils/hooks/useIsAdmin';
import {
  readAllFromQueryParams,
  readFromQueryString,
} from '../../utils/queryParams';
import dropdownsData from '../../constants/dropdownsData';

interface Option {
  value: any;
  label: string;
  key?: string;
  disabled?: boolean;
}

type DeviceGroupOptions = (Option & DeviceGroup)[];
type OptionsWithLicense = (Option & { licenses: License[] })[];

const mapOptionsWithLicensing = (
  options: DeviceGroupOptions,
  selectedValue: OptionsWithLicense,
) =>
  options.map((option) =>
    selectedValue.find(
      ({ licenses, value }) =>
        value !== option.value &&
        !isEqual(map(licenses, 'id').sort(), map(option.licenses, 'id').sort()),
    )
      ? { ...option, disabled: true }
      : option,
  );

const findOptionWithSameLicense = (options: OptionsWithLicense) =>
  options.find(({ licenses }) =>
    options.find((option) => {
      return map(licenses, 'id').find((id) =>
        map(option.licenses, 'id').includes(id),
      );
    }),
  );

const SelectDevicesGroups = ({
  onChange,
  initialValue,
  deviceIds,
  userGroupId,
}: {
  onChange: (selectedValue: SelectedValue[]) => void;
  initialValue?: any;
  deviceIds?: string[];
  userGroupId?: string;
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin] = useIsAdmin();
  const { search } = useLocation();

  const devicesIdsAP = readFromQueryString(search, 'devicesIds');

  const loadOptions = async () => {
    setIsLoading(true);
    try {
      let currentUserId;
      let items;

      if (isAdmin && !userGroupId) {
        if (deviceIds && deviceIds?.length > 0) {
          const device = await api.devices.getDeviceById(String(deviceIds));
          currentUserId = device?.owner?.id;
        } else if (devicesIdsAP) {
          const device = await api.devices.getDeviceById(String(devicesIdsAP));
          currentUserId = device?.owner?.id;
        }
      }

      if (isAdmin) {
        if (!userGroupId) {
          const { items: result } =
            await api.devicesGroups.getAdminDevicesGroups({
              currentUserId,
              filterByDeviceLicense: true,
              limit: dropdownsData.deviceGroupsLimit,
            });
          items = result;
        } else {
          const { items: result } =
            await api.devicesGroups.getAdminDevicesGroups({
              exclude: true,
              currentUserId: String(
                readAllFromQueryParams(search).currentUserId,
              ),
              userGroupId,
              limit: dropdownsData.deviceGroupsLimit,
            });
          items = result;
        }
      } else if (!isAdmin) {
        if (userGroupId) {
          const { items: result } =
            await api.devicesGroups.getDevicesGroupsInUserGroupInfo(
              {
                exclude: true,
                limit: dropdownsData.deviceGroupsLimit,
              },
              userGroupId,
            );
          items = result;
        } else {
          const { items: result } = await api.devicesGroups.getDevicesGroups({
            deviceIds,
            filterByDeviceLicense: true,
            limit: dropdownsData.deviceGroupsLimit,
          });
          items = result;
        }
      }

      // if (isAdmin && !userGroupId) {
      //   const { items: result } = await api.devicesGroups.getAdminDevicesGroups(
      //     {
      //       currentUserId,
      //       filterByDeviceLicense: true,
      //     },
      //   );
      //   items = result;
      // } else if (isAdmin && userGroupId) {
      //   const { items: result } = await api.devicesGroups.getAdminDevicesGroups(
      //     {
      //       exclude: true,
      //       currentUserId: String(readAllFromQueryParams(search).currentUserId),
      //       userGroupId,
      //     },
      //   );
      //   items = result;
      // } else if (userGroupId) {
      //   const { items: result } =
      //     await api.devicesGroups.getDevicesGroupsInUserGroupInfo(
      //       {
      //         exclude: true,
      //       },
      //       userGroupId,
      //     );
      //   items = result;
      // } else {
      //   const { items: result } = await api.devicesGroups.getDevicesGroups({
      //     deviceIds,
      //     filterByDeviceLicense: true,
      //   });
      //   items = result;
      // }

      const getOptions = sortBy(items, ['name']).map(
        ({ id, name, licenses }) => ({
          value: id,
          label: `${name}${
            licenses?.length
              ? ` (${map(licenses, 'name')
                  .map(
                    (licenseName) =>
                      licenseName || t('devices.licensing.no_license_short'),
                  )
                  .join(', ')})`
              : ''
          }`,
          licenses,
        }),
      );

      setOptions(getOptions);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterOptions = (filteredOptions: any, input: string) => {
    return filteredOptions.filter((option: { label: string }) =>
      option.label.toLowerCase().includes(input.toLowerCase()),
    );
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <>
      <MultiSelect
        options={mapOptionsWithLicensing(options, initialValue)}
        value={initialValue}
        onChange={onChange}
        filterOptions={handleFilterOptions}
        labelledBy="Selects Devices Groups"
        isLoading={isLoading}
        hasSelectAll={!findOptionWithSameLicense(options)}
      />
    </>
  );
};

SelectDevicesGroups.defaultProps = {
  initialValue: [],
};

export default SelectDevicesGroups;
