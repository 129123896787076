import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import useModalState from '../../../../utils/hooks/useModalState';
import EditSevereWeatherModal from '../../../../components/Apps/SevereWeather/EditSevereWeather';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import App from '../../../../interfaces/app.interface';
import Tooltip from '../../../../components/Tooltip';
import Modal from '../../../../components/Modal';
import api from '../../../../api';

export default ({
  children,
  severeWeather,
  onUpdate,
}: {
  children?: React.ReactNode;
  severeWeather: App;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [isVisible, handleOpen, handleClose] = useModalState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (data: CreateAppRequestData) => {
    setLoading(true);

    api.apps
      .updateApp(severeWeather.id, data)
      .then(() => {
        handleClose();
        onUpdate();
        toast.success(i18next.t<string>('apps.severeWeather.severe_edited'));
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={handleOpen}
        onClick={handleOpen}
      >
        {children ?? (
          <Tooltip text={t('common.edit')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-edit" />
            </span>
          </Tooltip>
        )}
      </span>
      <Modal
        title={t('apps.severeWeather.editSevereWeatherModal')}
        widthDefaultSize={false}
        withFooter={false}
        show={isVisible}
        onHide={handleClose}
      >
        <EditSevereWeatherModal
          severeWeather={severeWeather}
          onSubmit={handleSubmit}
          onDecline={handleClose}
          isLoading={loading}
        />
      </Modal>
    </>
  );
};
