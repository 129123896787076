import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import GlobalState from '../../store/reducers/globalState.interface';
import { UserRole } from '../../enums/userRole.enum';

export default ({
  children,
  fallback,
  isTrial,
  allowedRoles = [],
  isHide = false,
  lockedApp = false,
}: {
  allowedRoles?: UserRole[];
  children?: ReactNode | ReactNode[];
  fallback?: ReactNode;
  isHide?: boolean;
  isTrial?: boolean;
  lockedApp?: boolean;
}) => {
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const loginAsUserName = window.localStorage.getItem('loginAsUserName');
  if (lockedApp && process.env.REACT_APP_ENV === 'prod') {
    return <>{fallback}</>;
  }
  if (isHide || !roles?.length) {
    return null;
  }

  const matchingRoles = roles.filter((value) => allowedRoles.includes(value));

  if (!matchingRoles.length || loginAsUserName === 'Super Admin' || isTrial) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};
