import { isEqual, pick } from 'lodash';
import { UserRole } from '../enums/userRole.enum';

export const getComparatorBy =
  <T extends { [key: string]: any }>(props: string[]) =>
  (first: T, second: T): boolean =>
    isEqual(pick(first, props), pick(second, props));

export const containsUndefined = (arr: string[]) => {
  return arr?.some((element) => element === undefined);
};

export const checkAccountOwner = (roles: string[]) => {
  return roles?.some((name) => name === UserRole.AccountOwner);
};

export const disableRightClickIframe = (id: string) => {
  document
    ?.getElementById(id)
    ?.ownerDocument.addEventListener('contextmenu', (event) =>
      event.preventDefault(),
    );
};
