import React from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/Breadcrumbs';
import useRouteConfig from '../../../utils/hooks/useRouteConfig';

export default () => {
  const { t } = useTranslation();
  const [currentRoute] = useRouteConfig();

  return (
    <div className="toolbar position-relative start-0 top-0">
      <div className="d-flex flex-stack">
        <div className="page-title d-flex">
          <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">
            <span className="h-20px ms-3 mx-2" />
            <small className="text-muted fs-7 fw-bold my-1 ms-1">
              <h1>
                {currentRoute?.title
                  ? t(currentRoute.title)
                  : t('pageTitles.default')}
              </h1>
            </small>
          </h1>

          <Breadcrumbs />
        </div>
      </div>
    </div>
  );
};
