import React from 'react';
import { useDispatch } from 'react-redux';
import { createDevice } from '../../../store/actions/devices';
import CreateNewDeviceRequestData from '../../../api/endpoints/devices/interfaces/createNewDeviceRequestData.interface';
import DeviceCreationStepsData from '../../../interfaces/devices/deviceCreationStepsData.interface';
import CreateDevice from '../../../components/Devices/CreateDevice';
import useModalState from '../../../utils/hooks/useModalState';

export default ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const [isVisible, handleOpen, handleClose] = useModalState(false);

  const handleSubmit = (data: DeviceCreationStepsData) => {
    dispatch(createDevice(data as CreateNewDeviceRequestData));
  };

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={handleOpen}
        onClick={handleOpen}
      >
        {children}
      </span>
      <CreateDevice
        isVisible={isVisible}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </>
  );
};
