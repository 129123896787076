/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RestrictedAccess from '../../../containers/RestrictedAccess';
import { UserRole } from '../../../enums/userRole.enum';
import UserMenuDropDown from './UserMenuDropdown';
import TalkToSalesModal from '../../../components/Trial/TalkToSales';
import config from '../../../routing/config';

export default () => {
  const { t } = useTranslation();
  const isTrial = useSelector((state: any) => state.userData?.user?.isTrial);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const handleClose = () => {
    setIsModalVisible(false);
  };

  return (
    <UserMenuDropDown>
      <div className="menu-item px-5">
        <Link to={config.myAccount.route} className="menu-link px-5">
          {t('acc_drop_down.my_account')}
        </Link>
      </div>
      <RestrictedAccess
        allowedRoles={[UserRole.AccountOwner, UserRole.Manager, UserRole.Admin]}
      >
        <div className="menu-item px-5">
          <Link to={config.users.route} className="menu-link px-5">
            {t('acc_drop_down.manage_users')}
          </Link>
        </div>
      </RestrictedAccess>
      <div className="menu-item px-5">
        {isTrial ? (
          <TalkToSalesModal
            handleClose={handleClose}
            isModalVisible={isModalVisible}
          >
            <a
              href="#"
              tabIndex={0}
              type="button"
              className="menu-link px-5"
              onClick={() => {
                setIsModalVisible(true);
              }}
            >
              {t('acc_drop_down.manage_templates')}
              <i
                className="bi bi-lock-fill ms-3"
                style={{ fontSize: '1.4rem' }}
              />
            </a>
          </TalkToSalesModal>
        ) : (
          <Link to={config.templates.route} className="menu-link px-5">
            {t('acc_drop_down.manage_templates')}
          </Link>
        )}
      </div>
      <RestrictedAccess allowedRoles={[UserRole.AccountOwner]}>
        <div className="menu-item px-5">
          <Link to={config.company.route} className="menu-link px-5">
            {t('acc_drop_down.manage_companies')}
          </Link>
        </div>
      </RestrictedAccess>
      {/** Manage Banners */}
      <RestrictedAccess
        allowedRoles={[
          UserRole.IndigoAdmin,
          UserRole.IndigoEmployee,
          UserRole.SuperAdmin,
        ]}
      >
        <div className="menu-item px-5">
          <Link to={config.adminBanners.route} className="menu-link px-5">
            {t('acc_drop_down.manage_banners')}
          </Link>
        </div>
      </RestrictedAccess>
      <div className="menu-item px-5">
        <div className="menu-sub menu-sub-dropdown w-175px py-4">
          <div className="separator my-2" />
        </div>
      </div>

      <div className="separator my-2" />

      <div style={{ display: 'none' }} className="menu-item px-5 my-1">
        <Link to={config.integrations.route} className="menu-link px-5">
          {t('acc_drop_down.integrations')}
        </Link>
      </div>
    </UserMenuDropDown>
  );
};
