import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import CreateAppRequestData from '../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import RepublishTicker from '../../../components/Ticker/RepublishTicker';
import App from '../../../interfaces/app.interface';
import Tooltip from '../../../components/Tooltip';

export default ({
  children,
  ticker,
  onUpdate,
}: {
  ticker: App;
  onUpdate: () => void;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = (data: CreateAppRequestData) => {
    setIsLoading(true);
    api.apps.updateApp(ticker.id, data).then(() => {
      if (onUpdate) {
        onUpdate();
      }
    });
  };

  return (
    <>
      <RepublishTicker
        isLoading={isLoading}
        ticker={ticker}
        onSubmit={handleSubmit}
      >
        {children ?? (
          <Tooltip text={t('common.republish')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-retweet" />
            </span>
          </Tooltip>
        )}
      </RepublishTicker>
    </>
  );
};
