import { yupResolver } from '@hookform/resolvers/yup';
import i18next from 'i18next';
import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import FormErrorMessage from '../../../components/FormErrorMessage';
import routesConfig from '../../../routing/config';
import Select from '../../../components/Select/RegularSelect';
import TextInput from '../../../components/TextInput';
import { languagesTranslation } from '../../../constants/translation/languages';
import Language from '../../../enums/language.enum';
import { changeLanguage } from '../../../i18n';
import { updateUserData } from '../../../store/actions/userData';
import GlobalState from '../../../store/reducers/globalState.interface';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import { updateUserProfileValidation } from '../../../validation/users';
import ResetEmail from '../../../containers/Profile/ResetEmail';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
import validation from '../../../utils/validation';
import styles from '../myAccount.module.scss';
import api from '../../../api';
import { UserRole } from '../../../enums/userRole.enum';
import { userRoles } from '../../../constants/userRoles';
import { checkAccountOwner } from '../../../utils/common';
import ShowMoreCell from '../../../components/ShowMoreCell';
import dummyImage from '../../../assets/images/noPreview/dummy.jpg';
import WithSpinner from '../../../components/WithSpinner';
import devicesCount from '../../../constants/devicesCount';

interface FormInputs {
  phoneNumber: string;
  language: string;
  firstName: string;
  lastName: string;
  numberOfScreens: string;
}

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isAdmin] = useIsAdmin();

  const userData = useSelector((state: GlobalState) => state.userData);

  const {
    firstName,
    lastName,
    email,
    profilePicture,
    phoneNumber,
    language,
    isSocial,
    company,
    location,
    roles,
    companies,
    assignedLocations,
    numberOfScreens,
    isTrial,
  } = userData.user;

  const { loading } = userData;
  const languageValue: Language = language as Language;

  const isAccountOwner = checkAccountOwner(roles);

  const initialValues: FormInputs = {
    firstName,
    lastName,
    phoneNumber,
    language,
    numberOfScreens,
  };
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(
      validation.object(updateUserProfileValidation(t)).required(),
    ),
    defaultValues: initialValues,
  });
  const [picture, setPic] = React.useState<File>();
  const [picturePreview, setPicturePreview] = React.useState('');
  const [firstNameLoc, setFirstNameLoc] = useStateWithHookForm<
    FormInputs,
    FormInputs['firstName']
  >({ setValue, trigger, name: 'firstName' }, firstName);
  const [lastNameLoc, setLastNameLoc] = useStateWithHookForm<
    FormInputs,
    FormInputs['lastName']
  >({ setValue, trigger, name: 'lastName' }, lastName);
  const [phoneLoc, setPhoneLoc] = useStateWithHookForm<
    FormInputs,
    FormInputs['phoneNumber']
  >({ setValue, trigger, name: 'phoneNumber' }, phoneNumber);
  const [numberOfScreensLoc, setScreens] = useStateWithHookForm<
    FormInputs,
    FormInputs['numberOfScreens']
  >({ setValue, trigger, name: 'numberOfScreens' }, numberOfScreens);
  const [languageLoc, setLanguageLoc] = useStateWithHookForm<
    FormInputs,
    FormInputs['language']
  >(
    { setValue, trigger, name: 'language' },
    languageValue || (i18next.language?.toUpperCase() as Language),
  );

  const [isResettingPassword, setIsResettingPassword] = React.useState(false);

  const handleLanguage = (value: Language) => {
    changeLanguage(value);
    setLanguageLoc(value);
  };

  React.useEffect(() => setPhoneLoc(phoneNumber), [phoneNumber]);
  React.useEffect(() => setFirstNameLoc(firstName), [firstName]);
  React.useEffect(() => setLastNameLoc(lastName), [lastName]);
  React.useEffect(() => setLanguageLoc(languageValue), [language]);
  React.useEffect(() => setScreens(numberOfScreens), [numberOfScreens]);
  React.useEffect(
    () =>
      setPicturePreview(profilePicture !== '' ? profilePicture : dummyImage),
    [profilePicture],
  );
  React.useEffect(() => handleLanguage(languageValue), [languageValue]);

  const handleCancel = () => {
    setPicturePreview(profilePicture);
    setPic(undefined);
    setPhoneLoc(phoneNumber);
    setFirstNameLoc(firstName);
    setLastNameLoc(lastName);
    handleLanguage(languageValue);
  };

  const handleSave = () => {
    dispatch(
      updateUserData({
        picture,
        lastNameLoc,
        firstNameLoc,
        phoneLoc,
        numberOfScreens: numberOfScreensLoc,
        languageLoc: languageLoc?.toUpperCase(),
      }),
    );
  };

  const handleResetPassword = async () => {
    setIsResettingPassword(true);

    try {
      await api.account.resetPassword();

      toast.success(t('success_messages.reset_password'));
    } finally {
      setIsResettingPassword(false);
    }
  };

  const imageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setPicturePreview(URL.createObjectURL(e.target.files[0]));
      setPic(e.target.files[0]);
    }
  };

  const getRoles = (currentRoles: UserRole[]): string => {
    const userRole = currentRoles.map((role: string) => userRoles[role]);
    return userRole.join(', ');
  };

  const handleScreensChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    setScreens(value);
  };
  const verifEmptyInitialValues = () => {
    if (
      initialValues.firstName === undefined ||
      initialValues.language === undefined ||
      initialValues.lastName === undefined ||
      initialValues.numberOfScreens === undefined ||
      initialValues.phoneNumber === undefined
    ) {
      return true;
    }
    return false;
  };
  React.useEffect(() => {
    const loginAsUserName = window.localStorage.getItem('loginAsUserName');
    if (!verifEmptyInitialValues() && loginAsUserName) {
      window.localStorage.setItem(
        'loginAsUserName',
        `${initialValues.firstName} ${initialValues.lastName}`,
      );
    }
  }, [verifEmptyInitialValues]);
  return (
    <div className="flex-grow-1 mw-600px">
      <div
        className={
          !verifEmptyInitialValues()
            ? `${styles.tableWrapper} table-responsive bg-white overflow-visible`
            : `table-responsive bg-white overflow-visible min-w-600px ${styles.tableWidth}`
        }
      >
        <div>
          <h2 className="fw-normal text-300 m-0">
            {t('my_account.left_table.title')}
          </h2>
          <WithSpinner
            isLoading={verifEmptyInitialValues()}
            size="md"
            className={clsx('min-h-400px w-100 ', styles.spinner)}
          >
            <>
              <div className="card-toolbar d-flex flex-wrap flex-sm-nowrap">
                <div>
                  <div style={{ marginRight: '30px' }}>
                    <div
                      className="image-input image-input"
                      style={{
                        backgroundImage: `url(${picturePreview})`,
                        marginTop: '1rem',
                      }}
                    >
                      <div
                        onContextMenu={(ev) => ev.preventDefault()}
                        className="image-input-wrapper w-125px h-125px"
                        style={{
                          backgroundImage: `url(${picturePreview})`,
                        }}
                      />

                      <label
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Change avatar"
                      >
                        <i className="bi bi-pencil fs-7" />
                        <input
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                          onChange={imageUpload}
                        />
                        <input type="hidden" name="avatar_remove" />
                      </label>

                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Cancel avatar"
                      >
                        <i className="bi bi-x fs-2" />
                      </span>
                    </div>
                  </div>
                  <label
                    htmlFor="deviceName"
                    className="col-form-label text-muted fs-8 text-lg-start-sm w-125px"
                  >
                    {t('my_account.left_table.img')}
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="deviceName"
                    className="col-form-label text-muted text-lg-start"
                  >
                    {t('my_account.left_table.full_name')}
                  </label>
                  <h2 className="fw-bolder m-0">
                    {firstName} {lastName}
                  </h2>
                  {roles && roles.length !== 0 && (
                    <div className="my-3 mt-0 pt-5">
                      <span className="d-inline-block min-w-100px">
                        {t('my_account.left_table.role')}:
                      </span>
                      <span
                        className={`${styles.badgeStyle} badge fs-16 badge-light min-w-100px d-inline-block`}
                      >
                        {getRoles(roles)}
                      </span>
                    </div>
                  )}
                  {isAccountOwner ? (
                    <div className="my-3">
                      <span className="d-inline-block min-w-100px">
                        {t('common.companies')}:
                      </span>
                      <ShowMoreCell
                        itemNames={
                          companies?.map((companyObj) => companyObj?.name) ?? []
                        }
                        widthDefaultSize
                        linkTo={routesConfig.adminCustomerCompanies.route}
                        title={t('companies.companies')}
                        bodyClassName="justify-content-center"
                      >
                        <div className="row w-100">
                          {companies?.map((companyObj) => (
                            <div className="col-lg-3 col-sm-12 mb-5">
                              <span
                                className={clsx(
                                  'w-100 text-center bg-light text-primary rounded p-2 d-block',
                                )}
                              >
                                {companyObj && companyObj?.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      </ShowMoreCell>
                    </div>
                  ) : (
                    company &&
                    Object.keys(company).length !== 0 && (
                      <div className="my-3 ">
                        <span className="d-inline-block min-w-100px">
                          {t('common.company')}:
                        </span>
                        <span
                          className={`${styles.badgeStyle} badge fs-16 badge-light min-w-100px d-inline-block`}
                        >
                          {company?.name}
                        </span>
                      </div>
                    )
                  )}

                  {isAccountOwner ? (
                    <div className="my-3">
                      <span className="d-inline-block min-w-100px">
                        {t('common.locations')}:
                      </span>
                      <ShowMoreCell
                        itemNames={
                          assignedLocations?.map(
                            (locationObj) => locationObj?.name,
                          ) ?? []
                        }
                        widthDefaultSize
                        title={t('common.location')}
                        bodyClassName="justify-content-center"
                      >
                        <div className="row w-100">
                          {assignedLocations?.map((locationObj) => (
                            <div className="col-lg-3 col-sm-12 mb-5">
                              <span
                                className={clsx(
                                  'w-100 text-center bg-light text-primary rounded p-2 d-block',
                                )}
                              >
                                {locationObj && locationObj.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      </ShowMoreCell>
                    </div>
                  ) : (
                    location &&
                    Object.keys(location).length !== 0 && (
                      <div className="my-3 ">
                        <span className="d-inline-block min-w-100px">
                          {t('common.location')}:
                        </span>
                        <span
                          className={`${styles.badgeStyle} badge fs-16 badge-light min-w-100px d-inline-block`}
                        >
                          {location?.name}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-6">
                    <label
                      htmlFor="firstName"
                      className="col-form-label text-muted text-lg-start"
                    >
                      {t('my_account.left_table.first_name')}
                    </label>
                    <TextInput
                      id="firstName"
                      placeholder={t('my_account.left_table.first_name')}
                      value={firstNameLoc ?? ''}
                      onChange={setFirstNameLoc}
                    />
                    <FormErrorMessage
                      name="firstName"
                      errors={errors}
                      className="mt-1 min-h-20px"
                    />

                    <label
                      htmlFor="phone"
                      className="col-form-label text-muted text-lg-start"
                    >
                      {t('my_account.left_table.phone')}
                    </label>
                    <TextInput
                      id="phone"
                      placeholder={t('my_account.left_table.phone')}
                      value={phoneLoc ?? ''}
                      onChange={setPhoneLoc}
                      name="phoneNumber"
                    />
                    <FormErrorMessage
                      name="phoneNumber"
                      errors={errors}
                      className="mt-1 min-h-20px"
                    />

                    {isTrial ? (
                      <>
                        {' '}
                        <label
                          htmlFor="deviceCount"
                          className="col-form-label text-muted text-lg-start"
                        >
                          {t('complete_profile.labels.device_count')}
                        </label>
                        <select
                          id="devices"
                          value={numberOfScreensLoc ?? ''}
                          onChange={handleScreensChange}
                          name="devices"
                          className="form-control bg-transparent d-block"
                        >
                          <option value="" selected disabled>
                            -
                          </option>
                          {devicesCount.map((i) => (
                            <option key={i} value={i}>
                              {i}
                            </option>
                          ))}
                        </select>
                      </>
                    ) : (
                      <></>
                    )}
                    <label
                      htmlFor="language"
                      className="col-form-label text-muted text-lg-start"
                    >
                      {t('my_account.left_table.lang')}
                    </label>
                    <Select<Language>
                      id="language"
                      placeholder={t('my_account.left_table.lang')}
                      defaultValue={{
                        value: languageLoc,
                        label: _.capitalize(
                          t(languagesTranslation[languageLoc]),
                        ),
                      }}
                      onSelect={(selectedLanguage) =>
                        handleLanguage(selectedLanguage as Language)
                      }
                      options={Object.values(Language).map((languageVal) => ({
                        label: _.capitalize(
                          t(languagesTranslation[languageVal]),
                        ),
                        value: languageVal,
                      }))}
                      observeDefaultValueChange
                    />
                    <FormErrorMessage
                      name="language"
                      errors={errors}
                      className="mt-1 min-h-20px"
                    />
                  </div>

                  <div className="col-6">
                    <label
                      htmlFor="lastName"
                      className="col-form-label text-muted text-lg-start"
                    >
                      {t('my_account.left_table.last_name')}
                    </label>
                    <TextInput
                      id="lastName"
                      placeholder={t('my_account.left_table.last_name')}
                      value={lastNameLoc ?? ''}
                      onChange={setLastNameLoc}
                    />
                    <FormErrorMessage
                      name="lastName"
                      errors={errors}
                      className="mt-1 min-h-20px"
                    />

                    <label
                      htmlFor="deviceName"
                      className="col-form-label text-muted text-lg-start"
                    >
                      {t('my_account.left_table.email')}
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      disabled
                      placeholder={email}
                    />

                    {!isSocial && !isAdmin && (
                      <ResetEmail handleSave={handleSave} />
                    )}

                    <button
                      type="button"
                      className="btn btn-secondary btn-sm px-5 me-3 w-100"
                      onClick={handleResetPassword}
                    >
                      {isResettingPassword ? (
                        <span>
                          {t('my_account.left_table.resetting_password')}{' '}
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      ) : (
                        t('my_account.left_table.reset_password')
                      )}
                    </button>
                  </div>
                  <div
                    className="col-12"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div
                      className="col-12 d-md-flex "
                      style={{
                        justifyContent: 'space-between',
                        width: 'fit-content',
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-secondary px-5 me-3 min-w-100px"
                        onClick={handleCancel}
                      >
                        {t('my_account.left_table.cancel')}
                      </button>
                      <div style={{ minWidth: '100px' }}>
                        <WithSpinner isLoading={loading}>
                          <button
                            id="submit"
                            type="button"
                            className="btn btn-primary px-5 min-w-100px"
                            onClick={handleSubmit(handleSave)}
                          >
                            {t('my_account.left_table.apply')}
                          </button>
                        </WithSpinner>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </WithSpinner>
        </div>
      </div>
    </div>
  );
};
