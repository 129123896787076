import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Modal from '../../Modal';
import CheckBox from '../../CheckBox';
import DatePicker from '../../DatePicker';
import License from '../../../interfaces/license.interface';
import commonStyles from '../../../styles/common.module.scss';
import Timezone from '../../../interfaces/timezone.interface';

export default ({
  onHide,
  isModalVisible,
  license,
  timezone,
}: {
  isModalVisible: boolean;
  onHide?: () => void;
  license?: License;
  timezone?: Timezone;
}) => {
  const { t } = useTranslation();
  const isActive = !!license?.isActive;
  return (
    <Modal
      title={t('devices.edit_device.device_info')}
      widthDefaultSize={false}
      show={isModalVisible}
      onHide={onHide}
      bodyClassName="py-5"
      withFooter={false}
      dialogClassName="mw-1000px"
    >
      <div className="row d-flex w-100 justify-content-between">
        <div className="col rounded border p-5 m-5 pb-0 pt-7">
          <h4>{t('devices.licensing.BaseLicense')}</h4>

          {license?.id ? (
            <>
              <div className="">
                <div className="d-flex d-flex flex-wrap align-items-center me-5">
                  <label
                    htmlFor="licenseTypeId"
                    className="col-form-label text-lg-end me-3"
                  >
                    {t('devices.licensing.license_type')}:
                  </label>
                  <div className="badge badge-primary">
                    <strong id="licenseTypeId">
                      {license?.licenseType?.name ?? ''}
                    </strong>
                  </div>
                </div>
                <div className="d-flex d-flex flex-wrap align-items-center me-5">
                  <label
                    htmlFor="timezoneId1"
                    className="col-form-label text-lg-end me-3"
                  >
                    {t('timezone').charAt(0).toUpperCase() +
                      t('timezone').slice(1)}
                    {' :'}
                  </label>
                  <div className="badge badge-success">
                    <strong id="timezoneId1">
                      {timezone?.name ?? 'No timezone is picked !'}
                    </strong>
                  </div>
                </div>
                <div className="d-flex d-flex flex-wrap align-items-center me-5 pb-5">
                  <div className="d-flex align-items-center me-6">
                    <label
                      htmlFor="isActive"
                      className="col-form-label text-lg-end me-3"
                    >
                      {t('devices.licensing.active')}
                    </label>
                    <CheckBox
                      disabled
                      name="isActive"
                      checked={isActive}
                      className="mt-1"
                    />
                  </div>
                  <div
                    className={clsx(
                      'd-flex align-items-center me-6',
                      !isActive && commonStyles.disabled,
                    )}
                  >
                    <label
                      htmlFor="isTrial"
                      className="col-form-label text-lg-end me-3"
                    >
                      {t('devices.licensing.trial')}
                    </label>
                    <CheckBox
                      disabled
                      name="isTrial"
                      checked={!!license?.isTrial}
                      className="mt-1"
                    />
                  </div>
                </div>
              </div>

              {isActive && (
                <div>
                  <div>
                    <div className="fv-row mb-5">
                      <label
                        htmlFor="startDate"
                        className="d-flex align-items-center fs-6"
                      >
                        <span>{t('playlists.start_date')}</span>
                      </label>
                      <DatePicker
                        disabled
                        name="startDate"
                        value={license?.startDate}
                        placeholder="Select start date - time"
                        options={{
                          enableTime: true,
                          defaultHour: 0,
                        }}
                      />
                    </div>
                    <div className="fv-row">
                      <label
                        htmlFor="expireDate"
                        className="d-flex align-items-center fs-6"
                      >
                        <span>{t('playlists.end_date')}</span>
                      </label>
                      <DatePicker
                        disabled
                        name="expireDate"
                        value={license?.expireDate}
                        placeholder="Select end date - time"
                        options={{
                          enableTime: true,
                          defaultHour: 23,
                          defaultMinute: 59,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="h-200px d-flex justify-content-center align-items-center pb-5">
              {t('devices.licensing.no_license')}
            </div>
          )}
        </div>

        <div className="col rounded border p-5 m-5 pb-0 pt-7">
          <h4>{t('devices.licensing.library_license')}</h4>
          <div className="d-flex d-flex flex-wrap align-items-center me-5">
            <label
              htmlFor="licenseTypeId"
              className="col-form-label text-lg-end me-3"
            >
              {t('devices.licensing.license_type')}:
            </label>
            <div className="badge badge-primary">
              <strong id="licenseTypeId">
                {license?.licenseType?.name ?? ''}
              </strong>
            </div>
            <div className="d-flex d-flex flex-wrap align-items-center me-5">
              <label
                htmlFor="timezoneId2"
                className="col-form-label text-lg-end me-3"
              >
                {t('timezone').charAt(0).toUpperCase() + t('timezone').slice(1)}
                {' :'}
              </label>
              <div className="badge badge-success">
                <strong id="timezoneId2">
                  {timezone?.name ?? 'No timezone is picked !'}
                </strong>
              </div>
            </div>
          </div>
          {license?.isLibrary && (
            <div>
              <div className="d-flex d-flex flex-wrap align-items-center me-5 pb-5">
                <div className="d-flex align-items-center me-6">
                  <label
                    htmlFor="isActive"
                    className="col-form-label text-lg-end me-3"
                  >
                    {t('devices.licensing.libraryActive')}
                  </label>
                  <CheckBox
                    disabled
                    name="isActive"
                    checked={!!license?.isLibraryActive}
                    className="mt-1"
                  />
                </div>
                <div
                  className={clsx(
                    'd-flex align-items-center me-6',
                    !isActive && commonStyles.disabled,
                  )}
                >
                  <label
                    htmlFor="isTrial"
                    className="col-form-label text-lg-end me-3"
                  >
                    {t('devices.licensing.LibraryTrial')}
                  </label>
                  <CheckBox
                    disabled
                    name="isTrial"
                    checked={!!license?.isLibraryTrial}
                    className="mt-1"
                  />
                </div>
              </div>
              <div className="fv-row mb-5">
                <label
                  htmlFor="libraryAccessStartDate"
                  className="d-flex align-items-center fs-6"
                >
                  <span>{t('playlists.start_date')}</span>
                </label>
                <DatePicker
                  disabled
                  name="libraryAccessStartDate"
                  value={license?.libraryAccessStartDate || undefined}
                  placeholder="Select start date - time"
                  options={{
                    enableTime: true,
                    defaultHour: 0,
                  }}
                />
              </div>
              <div className="fv-row mb-10">
                <label
                  htmlFor="libraryAccessExpireDate"
                  className="d-flex align-items-center fs-6"
                >
                  <span>{t('playlists.end_date')}</span>
                </label>
                <DatePicker
                  disabled
                  name="libraryAccessExpireDate"
                  value={license?.libraryAccessExpireDate || undefined}
                  placeholder="Select end date - time"
                  options={{
                    enableTime: true,
                    defaultHour: 23,
                    defaultMinute: 59,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
