enum UsersGroupsActions {
  GET_USERS_GROUPS = 'GET_USERS_GROUPS',
  GET_USERS_GROUPS_SUCCESS = 'GET_USERS_GROUPS_SUCCESS',
  DELETE_USERS_GROUP = 'DELETE_USERS_GROUP',
  UPDATE_USERS_GROUP = 'UPDATE_USERS_GROUP',
  CREATE_USERS_GROUP = 'CREATE_USERS_GROUP',
  SET_USERS_GROUPS_LOADING = 'SET_USERS_GROUPS_LOADING',
  SET_TRIAL_EXPIRE_DAYS = 'SET_TRIAL_EXPIRE_DAYS',
}

export default UsersGroupsActions;
