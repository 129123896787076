import React from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Card from '../../Card';
import App from '../../../interfaces/app.interface';
import useTableMultiSelect from '../../../utils/hooks/useTableMultiSelect';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import QrCodeTable from './Table';
import Search from '../../Search/QueryParamSearch';
import { KTSVG } from '../../../lib/metronic/helpers';
import CreateQrCodeContainer from '../../../containers/Apps/QrCode/CreateQrCode';
import UploadQrCodeContainer from '../../../containers/Apps/QrCode/UploadQrCode';
import Pagination from '../../Pagination/QueryParamPagination';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';

const pageSize = 6;
const tableRowHeight = 63;

export default ({
  qrCodeList,
  total,
  isLoading,
  resetOnSearch,
  onUpdate,
}: {
  qrCodeList: App[];
  total: number;
  isLoading: boolean;
  resetOnSearch?: boolean;
  onUpdate: () => void;
}) => {
  console.log(total);
  const { t } = useTranslation();

  const { search } = useLocation();

  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(qrCodeList, 'id'),
    [],
    resetOnSearch ? search : undefined,
  );

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          <Search
            queryField="search"
            pageNumberQueryField=""
            placeholder={t('common.search')}
            className="me-2"
          />
          <div className="d-flex">
            <UploadQrCodeContainer onUpdate={onUpdate}>
              {/* <button type="button" className="mt-2 btn btn-primary">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
           
              </button> */}{' '}
              <button
                type="button"
                className=" ms-3 btn btn-light-primary mt-2 "
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                {t('apps.qrCode.uploadQrCode')}
              </button>
            </UploadQrCodeContainer>
            <CreateQrCodeContainer onUpdate={onUpdate}>
              <button
                type="button"
                className=" ms-3 btn btn-light-primary mt-2"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                {t('apps.qrCode.createQrCode')}
              </button>
            </CreateQrCodeContainer>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <QrCodeTable
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          onUpdate={onUpdate}
          qrCodes={qrCodeList}
          isLoading={isLoading}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
        />

        <Pagination
          total={total}
          pageSize={pageSize}
          pageNumberQueryField={PaginationQueryParam.Page}
          pageSizeQueryField={PaginationQueryParam.Limit}
          className="mt-3 mb-5"
        />
      </Card.Body>
    </Card>
  );
};
