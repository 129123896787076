import React from 'react';
import clsx from 'clsx';
import { KTSVG } from '../../lib/metronic/helpers';

const Icon = ({
  path,
  isActive,
  text,
  className,
}: {
  path: string;
  isActive?: boolean;
  text?: string;
  className?: string;
}) => (
  <span
    className={clsx(
      'btn btn-sm btn-icon',
      isActive && 'active',
      text && 'w-auto ps-3 pe-4',
      isActive && text
        ? 'btn-bg-primary btn-active-color-white'
        : 'btn-bg-light btn-active-color-primary',
      className,
    )}
  >
    <KTSVG
      path={path}
      className={clsx(
        'svg-icon-1',
        text && 'rounded',
        text && isActive && 'bg-white',
      )}
    />
    {text && <span className="ms-3">{text}</span>}
  </span>
);

Icon.defaultProps = {
  isActive: false,
};

export default Icon;
