import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import { createFormData } from '../../../utils/formData';
import Template from '../../../interfaces/template.interface';
import EditTemplateModal from '../../../components/Templates/EditTemplate';
import CreateTemplateRequestData from '../../../api/endpoints/templates/interfaces/createTemplateRequestData.interface';
import Tooltip from '../../../components/Tooltip';
import Modal from '../../../components/Modal';

const EditTemplate = ({
  template,
  children,
  onSuccess,
}: {
  template: Template;
  children?: React.ReactNode;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const handleSubmit = (data: Partial<CreateTemplateRequestData>) => {
    setIsLoading(true);

    api.templates
      .updateTemplate(template.id, createFormData(data))
      .then(() => {
        close();

        if (onSuccess) {
          onSuccess();
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.edit')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-edit" />
            </span>
          </Tooltip>
        )}
      </span>
      <Modal
        title={t('templates.edit_template')}
        show={modalShown}
        onHide={close}
        withFooter={false}
        bodyClassName="py-5"
      >
        <EditTemplateModal
          isLoading={isLoading}
          template={template}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default EditTemplate;
