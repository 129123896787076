import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UsersGroup from '../../../interfaces/usersGroup.interface';
import UpdateUsersGroupRequestData from '../../../api/endpoints/usersGroups/interfaces/updateUsersGroupRequestData.interface';
import EditGroupForm from './EditGroupForm';
import Modal from '../../Modal';

type FormInputs = Partial<UpdateUsersGroupRequestData>;

interface EditUsersGroupProps {
  group: UsersGroup;
  children: React.ReactNode;
  onSubmit?: (updateData: {
    id: string;
    data: UpdateUsersGroupRequestData;
  }) => void;
  onEditUserGroup?: (data: FormInputs, id: string) => void;
}

interface DefaultProps extends EditUsersGroupProps {
  onSubmit: Required<EditUsersGroupProps>['onSubmit'];
}

const EditUsersGroup = ({
  group,
  children,
  onSubmit,
  onEditUserGroup,
}: DefaultProps) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleSubmitData = (data: FormInputs) => {
    if (onEditUserGroup) {
      onEditUserGroup(data, group.id);
      return;
    }
    onSubmit({
      id: group.id,
      data,
    });

    handleClose();
  };

  const open = () => setIsModalVisible(true);

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('users_groups.edit_group.edit_users_group')}
        widthDefaultSize={false}
        show={isModalVisible}
        onHide={handleClose}
        bodyClassName="py-5"
        withFooter={false}
      >
        <EditGroupForm group={group} onSubmit={handleSubmitData} />
      </Modal>
    </>
  );
};

EditUsersGroup.defaultProps = {
  onSubmit: () => null,
};

export default EditUsersGroup;
