import React, { useEffect, useState } from 'react';
import { Switch, useHistory } from 'react-router';
import { Route } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { checkSignIn } from '../../utils/auth0';
import NotFound from '../../pages/Errors/NotFound';
import config from '../../routing/config';

export default ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  let timeoutID: number;

  useEffect(() => {
    timeoutID = setTimeout(async () => {
      const check = await checkSignIn();

      if (check) {
        setLoading(false);
      } else {
        history.push(config.signin.route);
      }
    });

    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  return loading ? (
    <Spinner backdropClassName="bg-white" />
  ) : (
    <Switch>
      {children}
      <Route component={NotFound} />
    </Switch>
  );
};
