import React, { useState, useEffect, useMemo } from 'react';

type SerializeFunction<T> = (value: T) => string;
type DeserializeFunction<T> = (serializedValue: string) => T;

interface Options<T> {
  serialize?: SerializeFunction<T>;
  deserialize?: DeserializeFunction<T>;
}

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
  options: Options<T> = {},
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] {
  const { serialize = JSON.stringify, deserialize = JSON.parse } = options;

  const [value, setValue] = useState<T>(() => {
    try {
      const storedValue = localStorage.getItem(key);
      if (storedValue !== null) {
        return deserialize(storedValue);
      }
      if (typeof initialValue === 'function') {
        const generatedValue = (initialValue as Function)();
        localStorage.setItem(key, serialize(generatedValue));
        return generatedValue;
      }
      localStorage.setItem(key, serialize(initialValue));
      return initialValue;
    } catch (error) {
      console.error(
        `Error retrieving/storing "${key}" from/in local storage:`,
        error,
      );
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      const serializedValue = serialize(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error(`Error storing "${key}" in local storage:`, error);
    }
  }, [key, value, serialize]);

  const removeValue = () => {
    try {
      localStorage.removeItem(key);
      setValue(initialValue);
    } catch (error) {
      console.error(`Error removing "${key}" from local storage:`, error);
    }
  };

  return useMemo(
    () => [value, setValue, removeValue],
    [value, setValue, removeValue],
  );
}
