import React, { useState } from 'react';
import useDidUpdate from './useDidUpdate';

export default (
  isLoading: boolean,
): [boolean, (value: React.SetStateAction<boolean>) => void] => {
  const [loading, setLoading] = useState(isLoading);

  // Observe loading status change
  useDidUpdate(() => setLoading(isLoading), [isLoading]);

  return [loading, setLoading];
};
