/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import WithSpinner from '../../../WithSpinner';

export default ({
  restoreSubscriptionIssue,
  restoringSubscriptionLoading,
}: {
  restoringSubscriptionLoading: boolean;
  restoreSubscriptionIssue: any;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {restoringSubscriptionLoading && (
        <div className="d-sm-flex flex-row justify-content-center mt-4">
          <h2 className="text-left text-dark mb-4 fs-3">
            {t('apps.msTeams.restoringSubscription')}
          </h2>
        </div>
      )}

      <WithSpinner
        isLoading={restoringSubscriptionLoading}
        style={{ minHeight: `80px` }}
        // isForTable
        size="md"
      >
        <div className="d-sm-flex flex-row justify-content-center mt-4">
          <h2
            className="text-left text-dark mb-4 fs-3"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                restoreSubscriptionIssue?.message ??
                t('apps.msTeams.restoredSubscription'),
            }}
          />
        </div>
      </WithSpinner>
    </>
  );
};
