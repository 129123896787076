import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AppDependencyConfig } from '../../../store/types/apps/globalTypes';
import { GoogleSlidesFeedSchema } from '../../../store/types/apps/googleSlidesFeed';
import Spinner from '../../Spinner';
import Modal from '../../Modal';
import { disableRightClickIframe } from '../../../utils/common';

export interface PreviewFeedModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  selectedGoogleSlidesFeedSchema: GoogleSlidesFeedSchema;
}

const PreviewFeedModal = ({
  show,
  onHide,
  title,
  selectedGoogleSlidesFeedSchema,
}: PreviewFeedModalProps) => {
  const { t } = useTranslation();
  const [previewDocumentIsFetching, setPreviewDocumentIsFetching] =
    React.useState<boolean>(true);

  const handleIframeLoad = () => setPreviewDocumentIsFetching(false);

  const { name: feedTitle, dependency } = React.useMemo(
    () => selectedGoogleSlidesFeedSchema,
    [selectedGoogleSlidesFeedSchema],
  );

  const handleHide = () => {
    onHide();
    setPreviewDocumentIsFetching(true);
  };

  React.useEffect(() => {
    disableRightClickIframe(feedTitle);
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleHide}
      widthDefaultSize
      title={title}
      withFooter
      footerContent={
        <button
          type="button"
          className="btn btn-light-primary me-2"
          onClick={handleHide}
        >
          {t('common.close')}
        </button>
      }
    >
      <div>
        <h3 className="text-center mt-3 mb-7">
          {t('common.title')}
          {': '}
          <small className="text-muted">{feedTitle}</small>
        </h3>
        <div className="w-100">
          {previewDocumentIsFetching ? (
            <Spinner size="md" backdropClassName="bg-white" />
          ) : null}

          <iframe
            title={feedTitle}
            src={(dependency.config as AppDependencyConfig).googleSlidesFeedUrl}
            width="821"
            height="465"
            onLoad={handleIframeLoad}
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            style={{ padding: '4px' }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PreviewFeedModal;
