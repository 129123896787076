import { call, put, takeEvery } from 'redux-saga/effects';
import DevicesActions from '../actions/devices/devicesActions.enum';
import GetDevicesListAction from '../actions/devices/interfaces/getDevicesListAction.interface';
import GetDevicesListResponseData from '../../api/endpoints/devices/interfaces/getDevicesListResponseData.interface';
import UpdateDeviceAction from '../actions/devices/interfaces/updateDeviceAction.interface';
import UpdateMultipleDevicesAction from '../actions/devices/interfaces/updateMultipleDevicesAction.interface';
import { readAllFromQueryParams } from '../../utils/queryParams';
import history from '../../constants/history';
import api from '../../api';
import {
  getDevicesList,
  setDevicesLoading,
  getDevicesListSuccess,
  getAdminDevicesListSuccess,
  // getAdminDevicesList,
  getAllAdminDevicesListPageSuccess,
  getAllAdminDevicesListPage,
} from '../actions/devices';
import CreateDeviceAction from '../actions/devices/interfaces/createDeviceAction.interface';
import { createFormData } from '../../utils/formData';
import LinkDeviceAction from '../actions/devices/interfaces/linkDeviceAction.interface';

/**
 * The devices retrieval worker
 * @param payload
 */
function* getDevicesRequestWorker({ payload }: GetDevicesListAction) {
  yield put(setDevicesLoading({ loading: true }));

  try {
    const responseData: GetDevicesListResponseData = yield call(
      api.devices.getDevicesList,
      payload,
    );

    yield put(getDevicesListSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setDevicesLoading({ loading: false }));
}

/**
 * The admin devices list in admin devices page retrieval worker
 * @param payload
 */
function* getAllAdminDevicesListPageRequestWorker({
  payload,
}: GetDevicesListAction) {
  yield put(setDevicesLoading({ loading: true }));

  try {
    const responseData: GetDevicesListResponseData = yield call(
      api.devices.getAllAdminDevicesListPage,
      payload,
    );
    yield put(getAllAdminDevicesListPageSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setDevicesLoading({ loading: false }));
}

/**
 * The admin devices retrieval worker
 * @param payload
 */
function* getAdminDevicesRequestWorker({ payload }: GetDevicesListAction) {
  yield put(setDevicesLoading({ loading: true }));

  try {
    const responseData: GetDevicesListResponseData = yield call(
      api.devices.getAdminDevicesList,
      payload,
    );

    yield put(getAdminDevicesListSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setDevicesLoading({ loading: false }));
}

/**
 * The device update worker
 * @param payload
 */
function* updateDeviceRequestWorker({ payload }: UpdateDeviceAction) {
  yield put(setDevicesLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.devices.updateDevice, payload.data, payload.id);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(
      payload.isAdmin
        ? getAllAdminDevicesListPage(
            readAllFromQueryParams(history.location.search),
          )
        : getDevicesList(readAllFromQueryParams(history.location.search)),
    );
  } else {
    yield put(setDevicesLoading({ loading: false }));
  }
}

/**
 * The device linking worker
 * @param payload
 */
function* linkDeviceRequestWorker({ payload }: LinkDeviceAction) {
  yield put(setDevicesLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.players.linkDevice, payload.deviceId, payload.pin);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(getDevicesList(readAllFromQueryParams(history.location.search)));
  } else {
    yield put(setDevicesLoading({ loading: false }));
  }
}

/**
 * The device creator worker
 * @param payload
 */
function* createDeviceRequestWorker({ payload }: CreateDeviceAction) {
  yield put(setDevicesLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.devices.createDevice, createFormData(payload, true));
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(
      getAllAdminDevicesListPage(
        readAllFromQueryParams(history.location.search),
      ),
    );
  } else {
    yield put(setDevicesLoading({ loading: false }));
  }
}

/**
 * Bulk update devices
 */
function* bulkUpdateDevicesRequestWorker({
  payload,
}: UpdateMultipleDevicesAction) {
  yield put(setDevicesLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.devices.bulkUpdateDevices, payload.data);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(getDevicesList(readAllFromQueryParams(history.location.search)));
  } else {
    yield put(setDevicesLoading({ loading: false }));
  }
}

/**
 * Bulk update admin devices
 */
function* bulkUpdateAdminDevicesRequestWorker({
  payload,
}: UpdateMultipleDevicesAction) {
  yield put(setDevicesLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.devices.bulkUpdateAdminDevices, payload.data);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(
      getAllAdminDevicesListPage(
        readAllFromQueryParams(history.location.search),
      ),
    );
  } else {
    yield put(setDevicesLoading({ loading: false }));
  }
}

export default function* devicesWatcher() {
  yield takeEvery<GetDevicesListAction>(
    DevicesActions.GET_DEVICES_LIST,
    getDevicesRequestWorker,
  );

  yield takeEvery<GetDevicesListAction>(
    DevicesActions.GET_ADMIN_DEVICES_LIST_PAGE,
    getAllAdminDevicesListPageRequestWorker,
  );
  yield takeEvery<GetDevicesListAction>(
    DevicesActions.GET_ADMIN_DEVICES_LIST,
    getAdminDevicesRequestWorker,
  );

  yield takeEvery<UpdateDeviceAction>(
    DevicesActions.UPDATE_DEVICE,
    updateDeviceRequestWorker,
  );

  yield takeEvery<LinkDeviceAction>(
    DevicesActions.LINK_DEVICE,
    linkDeviceRequestWorker,
  );

  yield takeEvery<UpdateMultipleDevicesAction>(
    DevicesActions.UPDATE_MULTIPLE_DEVICES,
    bulkUpdateDevicesRequestWorker,
  );

  yield takeEvery<UpdateMultipleDevicesAction>(
    DevicesActions.UPDATE_MULTIPLE_ADMIN_DEVICES,
    bulkUpdateAdminDevicesRequestWorker,
  );

  yield takeEvery<CreateDeviceAction>(
    DevicesActions.CREATE_DEVICE,
    createDeviceRequestWorker,
  );
}
