import { ACTIONS, FileManagerReduxAction } from '../../actions/fileManager';
import { ErrorResponse, PaginatedFiles } from '../../types/fileManager';

export interface FileManagerState {
  error: ErrorResponse | null;

  data: PaginatedFiles;
  loading: boolean;
  isGetFilesSuccess: boolean;

  onlyDirectories: PaginatedFiles;
  onlyDirectoriesLoading: boolean;
  isGetOnlyDirectoriesSuccess: boolean;

  deleteFilesLoading: boolean;
  isDeleteFilesSuccess: boolean;

  renameFileLoading: boolean;
  isRenameFileSuccess: boolean;

  moveFilesLoading: boolean;
  isMoveFileSuccess: boolean;

  createFileLoading: boolean;
  isCreateFileSuccess: boolean;

  scheduleFileLoading: boolean;
  isScheduleFileSuccess: boolean;
}

const initialState: FileManagerState = {
  error: null,

  data: {
    items: [],
    links: { first: '', previous: '', next: '', last: '' },
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 10,
      totalPages: 0,
      currentPage: 1,
    },
  },
  loading: false,
  isGetFilesSuccess: false,

  onlyDirectories: {
    items: [],
    links: { first: '', previous: '', next: '', last: '' },
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 10,
      totalPages: 0,
      currentPage: 1,
    },
  },
  onlyDirectoriesLoading: false,
  isGetOnlyDirectoriesSuccess: false,

  deleteFilesLoading: false,
  isDeleteFilesSuccess: false,

  renameFileLoading: false,
  isRenameFileSuccess: false,

  moveFilesLoading: false,
  isMoveFileSuccess: false,

  createFileLoading: false,
  isCreateFileSuccess: false,

  scheduleFileLoading: false,
  isScheduleFileSuccess: false,
};

export function fileManagerReducer(
  state = initialState,
  action: FileManagerReduxAction<ACTIONS>,
): FileManagerState {
  const { payload } = action;

  switch (action.type) {
    // GET
    case ACTIONS.GET_FILES_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };

    case ACTIONS.GET_FILES_SUCCESS:
      return {
        ...state,
        loading: payload.loading,
        data: payload.data,
        isGetFilesSuccess: payload.isGetFilesSuccess,
      };

    case ACTIONS.GET_FILES_ERROR:
      return {
        ...state,
        loading: payload.loading,
        error: payload.error,
        isGetFilesSuccess: payload.isGetFilesSuccess,
      };

    case ACTIONS.GET_FILES_RESET:
      return {
        ...state,
        data: payload.data,
        loading: payload.loading,
        error: payload.error,
        isGetFilesSuccess: payload.isGetFilesSuccess,
      };

    // GET ONLY DIRECTORIES
    case ACTIONS.GET_ONLY_DIRECTORIES_LOADING:
      return {
        ...state,
        onlyDirectoriesLoading: payload.loading,
      };

    case ACTIONS.GET_ONLY_DIRECTORIES_SUCCESS:
      return {
        ...state,
        onlyDirectoriesLoading: payload.loading,
        onlyDirectories: payload.data,
        isGetOnlyDirectoriesSuccess: payload.isGetOnlyDirectoriesSuccess,
      };

    case ACTIONS.GET_ONLY_DIRECTORIES_ERROR:
      return {
        ...state,
        onlyDirectoriesLoading: payload.loading,
        error: payload.error,
        isGetOnlyDirectoriesSuccess: payload.isGetOnlyDirectoriesSuccess,
      };

    case ACTIONS.GET_ONLY_DIRECTORIES_RESET:
      return {
        ...state,
        data: payload.data,
        onlyDirectoriesLoading: payload.loading,
        error: payload.error,
        isGetOnlyDirectoriesSuccess: payload.isGetOnlyDirectoriesSuccess,
      };

    // DELETE
    case ACTIONS.DELETE_FILES_LOADING:
      return {
        ...state,
        deleteFilesLoading: payload.loading,
      };

    case ACTIONS.DELETE_FILES_SUCCESS:
      return {
        ...state,
        deleteFilesLoading: payload.loading,
        isDeleteFilesSuccess: payload.isDeleteFilesSuccess,
      };

    case ACTIONS.DELETE_FILES_ERROR:
      return {
        ...state,
        deleteFilesLoading: payload.loading,
        error: payload.error,
        isDeleteFilesSuccess: payload.isDeleteFilesSuccess,
      };

    case ACTIONS.DELETE_FILES_RESET:
      return {
        ...state,
        deleteFilesLoading: payload.loading,
        error: payload.error,
        isDeleteFilesSuccess: payload.isDeleteFilesSuccess,
      };

    // RENAME
    case ACTIONS.RENAME_FILE_LOADING:
      return {
        ...state,
        renameFileLoading: payload.loading,
      };

    case ACTIONS.RENAME_FILE_SUCCESS:
      return {
        ...state,
        renameFileLoading: payload.loading,
        isRenameFileSuccess: payload.isRenameFileSuccess,
      };

    case ACTIONS.RENAME_FILE_ERROR:
      return {
        ...state,
        renameFileLoading: payload.loading,
        error: payload.error,
        isRenameFileSuccess: payload.isRenameFileSuccess,
      };

    case ACTIONS.RENAME_FILE_RESET:
      return {
        ...state,
        renameFileLoading: payload.loading,
        error: payload.error,
        isRenameFileSuccess: payload.isRenameFileSuccess,
      };

    // MOVE
    case ACTIONS.MOVE_FILE_LOADING:
      return {
        ...state,
        moveFilesLoading: payload.loading,
      };

    case ACTIONS.MOVE_FILE_SUCCESS:
      return {
        ...state,
        moveFilesLoading: payload.loading,
        isMoveFileSuccess: payload.isMoveFileSuccess,
      };

    case ACTIONS.MOVE_FILE_ERROR:
      return {
        ...state,
        moveFilesLoading: payload.loading,
        error: payload.error,
        isMoveFileSuccess: payload.isMoveFileSuccess,
      };

    case ACTIONS.MOVE_FILE_RESET:
      return {
        ...state,
        moveFilesLoading: payload.loading,
        error: payload.error,
        isMoveFileSuccess: payload.isMoveFileSuccess,
      };

    // CREATE FILE
    case ACTIONS.CREATE_FILE_LOADING:
      return {
        ...state,
        createFileLoading: payload.loading,
      };

    case ACTIONS.CREATE_FILE_SUCCESS:
      return {
        ...state,
        createFileLoading: payload.loading,
        isCreateFileSuccess: payload.isCreateFileSuccess,
      };

    case ACTIONS.CREATE_FILE_RESET:
      return {
        ...state,
        createFileLoading: payload.loading,
        error: payload.error,
        isCreateFileSuccess: payload.isCreateFileSuccess,
      };

    // SCHEDULE FILE
    case ACTIONS.SCHEDULE_FILE_LOADING:
      return {
        ...state,
        scheduleFileLoading: payload.loading,
      };

    case ACTIONS.SCHEDULE_FILE_SUCCESS:
      return {
        ...state,
        scheduleFileLoading: payload.loading,
        isScheduleFileSuccess: payload.isScheduleFileSuccess,
      };

    case ACTIONS.SCHEDULE_FILE_ERROR:
      return {
        ...state,
        scheduleFileLoading: payload.loading,
        error: payload.error,
        isScheduleFileSuccess: payload.isScheduleFileSuccess,
      };

    case ACTIONS.SCHEDULE_FILE_RESET:
      return {
        ...state,
        scheduleFileLoading: payload.loading,
        error: payload.error,
        isScheduleFileSuccess: payload.isScheduleFileSuccess,
      };

    default:
      return state;
  }
}
