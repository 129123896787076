import GetPlaylistsSuccessAction from '../../actions/playlists/interfaces/getPlaylistsSuccessAction.interface';
import PlaylistsActions from '../../actions/playlists/playlistsActions.enum';
import LoadingAction from '../../actions/loadingAction.interface';
import ReduxAction from '../../actions/reduxAction.interface';
import PlaylistsState from './playlistsState.interface';

const initialState: PlaylistsState = {
  loading: true,
  playlists: [],
  total: 0,
};

export default function playlistsReducer(
  state = initialState,
  action: ReduxAction<PlaylistsActions>,
): PlaylistsState {
  switch (action.type) {
    case PlaylistsActions.GET_PLAYLISTS_SUCCESS: {
      const {
        payload: { items, meta },
      } = action as GetPlaylistsSuccessAction;

      return {
        ...state,
        playlists: items,
        total: meta.totalItems,
      };
    }
    case PlaylistsActions.SET_PLAYLISTS_LOADING:
      return {
        ...state,
        ...(action as LoadingAction<PlaylistsActions.SET_PLAYLISTS_LOADING>)
          .payload,
      };
    default:
      return state;
  }
}
