import { TFunction } from 'i18next';
import validation from '../utils/validation';

export const playlistContentCreateValidation = (t: TFunction) => ({
  name: validation
    .string()
    .required(t('contents.validation.please_enter_name')),
  orientation: validation
    .string()
    .required(t('contents.validation.please_select_orientation')),
  duration: validation
    .number()
    .positive()
    .required(t('contents.validation.duration_is_required')),
  width: validation.number().positive(),
  height: validation.number().positive(),
});

export const bulkEditContentsValidation = (t: TFunction) => ({
  orientation: validation.string(),
  duration: validation.number().positive(),
  startDate: validation.date(),
  endDate: validation.date().test(
    'is-greater-than-start-date',
    t('common.endDateIsGreaterThanStartDate'),
    // eslint-disable-next-line func-names
    function (endDate) {
      const { startDate } = this.parent;
      if (startDate && endDate) {
        return startDate && endDate && startDate < endDate;
      }
      return true;
    },
  ),
});

export const playlistContentEditValidation = (t: TFunction) => ({
  ...bulkEditContentsValidation(t),
  name: validation
    .string()
    .required(t('contents.validation.please_enter_name')),
});

export const playlistContentUploadValidation = (
  t: TFunction,
  includeFileValidation: boolean,
) => ({
  ...playlistContentCreateValidation(t),
  ...(includeFileValidation
    ? {
        files: validation
          .array()
          .min(1, t('contents.validation.file_is_required'))
          .required(t('contents.validation.file_is_required')),
      }
    : {}),
});

export const playlistsContentUploadValidation = (
  t: TFunction,
  includeFileValidation: boolean,
) => ({
  ...playlistContentUploadValidation(t, includeFileValidation),
  isSeparate: validation
    .boolean()
    .required(t('contents.validation.please_select_upload_method')),
  isOrdered: validation
    .boolean()
    .required(t('contents.validation.please_select_ordering')),
});
