import * as React from 'react';
import { useTable } from 'react-table';
import EmptyFeeds from '../../../components/Apps/EmptyFeeds/EmptyFeeds';

import Table from '../../../components/Table';
import WithSpinner from '../../../components/WithSpinner';

import {
  HTMLFeedSchema,
  PaginatedHTMLFeeds,
} from '../../../store/types/apps/htmlFeed';

import useColumns from './useColumns';
import useData from './useData';
import styles from './FeedsTable.module.scss';

export interface FeedsTableProps {
  isLoading: boolean;
  paginatedHTMLFeeds: PaginatedHTMLFeeds;
  clickOnHTMLFeed: (feed: HTMLFeedSchema) => void;
  openEditHTMLFeedModal: (feed: HTMLFeedSchema) => void;
  openDeleteHTMLFeedModal: (feed: HTMLFeedSchema) => void;
  openActionsDropdown: (feed: HTMLFeedSchema) => void;
  openScheduleHTMLFeedModal: (feed: HTMLFeedSchema) => void;
  selectedHTMLFeed: HTMLFeedSchema | null;
}

const FeedsTable = ({
  isLoading = false,
  paginatedHTMLFeeds,
  clickOnHTMLFeed,
  openEditHTMLFeedModal,
  openDeleteHTMLFeedModal,
  openActionsDropdown,
  openScheduleHTMLFeedModal,
  selectedHTMLFeed,
}: FeedsTableProps) => {
  const data = useData(paginatedHTMLFeeds.items);
  const columns = useColumns({
    clickOnHTMLFeed,
    openEditHTMLFeedModal,
    openDeleteHTMLFeedModal,
    openActionsDropdown,
    openScheduleHTMLFeedModal,
    selectedHTMLFeed,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <Table className="h-100" {...getTableProps()}>
      <Table.Head>
        {headerGroups.map((headerGroup) => (
          <Table.Head.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <Table.Head.Row.Cell
                className={
                  index === headerGroup.headers.length - 1 ? styles.actions : ''
                }
                isTextEnd={index === headerGroup.headers.length - 1}
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </Table.Head.Row.Cell>
            ))}
          </Table.Head.Row>
        ))}
      </Table.Head>
      <Table.Body
        {...getTableBodyProps()}
        isEmpty={!isLoading && paginatedHTMLFeeds.items.length === 0}
        emptyStateHeight={500}
        emptyStateChildren={
          <EmptyFeeds descriptionText="apps.html5.noHtml5Found" />
        }
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: '500px' }}
          isForTable
        >
          {rows.map((row) => {
            prepareRow(row);

            return (
              <Table.Body.Row {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <Table.Body.Row.Cell
                      isTextEnd={index === row.cells.length - 1}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </Table.Body.Row.Cell>
                  );
                })}
              </Table.Body.Row>
            );
          })}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};

export default FeedsTable;
