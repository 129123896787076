import axios from 'axios';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import GetTagsRequestData from './interfaces/getTagsRequestData.interface';
import TagsRoutes from './enums/tagsRoutes.enum';
import { API_URL } from '../../../constants/api';

export default {
  getTagsList: async (requestData: GetTagsRequestData): Promise<string[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${TagsRoutes.Tags}`,
      params: sanitizeQueryParams({
        type: requestData.target,
        search: requestData.search,
      }),
    });

    return data;
  },
};
