import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import BulkEditUsers from '../../../components/Users/BulkEditUsers';
import BulkUpdateUsersRequestDataInterface from '../../../api/endpoints/users/interfaces/bulkUpdateUsersRequestData.interface';
import { getAdminUsersList, getUsersList } from '../../../store/actions/users';
import { UserRole } from '../../../enums/userRole.enum';
import GlobalState from '../../../store/reducers/globalState.interface';
import api from '../../../api';
import { readAllFromQueryParams } from '../../../utils/queryParams';

export default ({
  children,
  selectedItems,
  onBulkEditUser,
}: {
  children: React.ReactNode;
  selectedItems: string[];
  onBulkEditUser?: (data: BulkUpdateUsersRequestDataInterface) => void;
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);

  const handleSubmit = async (data: BulkUpdateUsersRequestDataInterface) => {
    try {
      if (onBulkEditUser) {
        onBulkEditUser({ ...data, userIds: selectedItems });
        return;
      }

      if (roles.includes(UserRole.SuperAdmin)) {
        await api.users.bulkEditAdminUsers({ ...data, userIds: selectedItems });
        dispatch(getAdminUsersList(readAllFromQueryParams(search)));
      } else {
        await api.users.bulkEditUsers({ ...data, userIds: selectedItems });
        dispatch(getUsersList(readAllFromQueryParams(search)));
      }
    } catch (e) {
      // Do nothing for now
    }
  };

  return selectedItems.length > 1 ? (
    <BulkEditUsers onSubmit={handleSubmit}>{children}</BulkEditUsers>
  ) : (
    <></>
  );
};
