import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import FileUploader from '../../components/FileUploader';
import FormErrorMessage from '../../components/FormErrorMessage';
import Modal from '../../components/Modal';

import { CreateFileRequest, FileMimeType } from '../../store/types/fileManager';

import useStateWithHookForm from '../../utils/hooks/useStateWithHookForm';
import WithSpinner from '../../components/WithSpinner';
import { videoFileTypes } from '../../constants/file';

type CreateFileModalFormPayload = Omit<CreateFileRequest, 'parentDirectoryId'>;

export interface CreateFileModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (createFolderRequest: CreateFileRequest) => void;
  title: string;
  isLoading: boolean;
  isUploadingFile?: boolean | undefined;
  directoryId: string | null;
  isPublicState: boolean;
  disableTypeSelection: boolean;
  onFileDataUpdate?: (data: {
    file?: File;
    isSeparate?: boolean;
    isOrdered?: boolean;
    isMyContent?: boolean;
  }) => Promise<boolean>;
}

interface DefaultProps extends CreateFileModalProps {
  onFileDataUpdate: Required<CreateFileModalProps>['onFileDataUpdate'];
}

const CreateFileModal = ({
  show,
  onHide,
  title,
  onSubmit,
  isLoading = false,
  directoryId,
  isPublicState,
  disableTypeSelection,
  isUploadingFile,
  onFileDataUpdate,
}: DefaultProps) => {
  const initialValues: CreateFileModalFormPayload = {
    name: '',
    isDirectory: false,
    file: null,
    height: null,
    width: null,
    isPublic: isPublicState,
  };
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    isDirectory: Yup.boolean().defined(),
    file: Yup.mixed().defined('Please select a file.'),
    height: Yup.mixed().defined(),
    width: Yup.mixed().defined(),
    isPublic: Yup.boolean().defined(
      'Please select a file status (private or public)',
    ),
    conversionJobId: Yup.number().nullable(true),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateFileModalFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [file, setFile] = useStateWithHookForm<
    CreateFileModalFormPayload,
    File | null
  >(
    {
      setValue,
      trigger,
      name: 'file',
    },
    initialValues.file,
  );

  const [fileName, setNewFileName] = useStateWithHookForm<
    CreateFileModalFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);
  const [duration, setDuration] = React.useState<number>(0);

  const [fileType, setFileType] = useStateWithHookForm<
    CreateFileModalFormPayload,
    boolean
  >({ setValue, trigger, name: 'isPublic' }, initialValues.isPublic);

  const [isFilesLoading, setIsFilesLoading] = React.useState(false);

  const handleNewFileNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setNewFileName(value),
    [setNewFileName],
  );

  const handleFileChange = async (files: File[]) => {
    if (files.length) {
      setIsFilesLoading(true);
      if (await onFileDataUpdate({ file: files[0], isMyContent: true })) {
        setFile(files[0] as File);
      }
    } else {
      setFile(null);
      setIsFilesLoading(false);

      onFileDataUpdate({ file: undefined });
      return;
    }

    const fileDur: File = files[0];
    const fileTypes = fileDur?.type as FileMimeType;
    if (videoFileTypes.includes(fileTypes)) {
      const video = document.createElement('video');

      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        setDuration(Number(video.duration.toFixed(2)));
        setIsFilesLoading(false);

        URL.revokeObjectURL(video.src);
      };

      video.onerror = () => setIsFilesLoading(false);

      video.src = URL.createObjectURL(fileDur);
    }
    setIsFilesLoading(false);
  };

  const handleFileTypeChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      const isPublic = value === 'true';
      setFileType(isPublic);
    },
    [setFileType],
  );

  const handleOnSubmit = async (data: CreateFileModalFormPayload) => {
    const reader = new FileReader();

    reader.readAsDataURL(file as File);

    const [width, height] = await new Promise<
      [number | undefined, number | undefined]
    >((resolve) => {
      reader.onload = (e) => {
        const image = new Image();

        image.src = (e.target as FileReader).result as string;

        image.onload = () => resolve([image.width, image.height]);
        image.onerror = () => resolve([undefined, undefined]);
      };

      reader.onerror = () => resolve([undefined, undefined]);
    });

    const createFileRequest: CreateFileRequest = {
      ...data,
      parentDirectoryId: directoryId,
      file,
      duration,
      width: width?.toString() ?? null,
      height: height?.toString() ?? null,
    };

    onSubmit(createFileRequest);
  };

  return (
    <Modal
      title={`${title} (${fileType ? 'public' : 'private'})`}
      show={show}
      onHide={onHide}
      widthDefaultSize={false}
      withFooter
      footerContent={
        <button
          form="form"
          type="submit"
          className="btn btn-primary"
          disabled={file === null}
        >
          {isLoading ? (
            <span>
              Saving{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            <span>Save</span>
          )}
        </button>
      }
    >
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        id="form"
        className="d-flex flex-column"
      >
        <div className="d-flex flex-column my-2 w-100">
          <WithSpinner
            isLoading={isUploadingFile || isFilesLoading}
            className="h-100px"
          >
            <FileUploader
              name="file"
              mutedText="Select File"
              accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .mp4, .gif"
              onChange={handleFileChange}
              defaultValue={file ? [file] : []}
            />
          </WithSpinner>
          <FormErrorMessage name="file" errors={errors} className="my-1 px-2" />
        </div>

        <div className="d-flex flex-column my-2 w-100">
          <label htmlFor="name" className="text-gray-900 my-1">
            Add Title
          </label>
          <input
            name="name"
            id="name"
            value={fileName}
            onChange={handleNewFileNameChange}
            className="form-control form-control-solid"
            placeholder="Type Here"
            type="text"
          />
          <FormErrorMessage name="name" errors={errors} className="my-1 px-2" />
        </div>

        {!disableTypeSelection && (
          <div className="d-flex flex-column my-2 w-100">
            <label htmlFor="isPublic" className="text-gray-900 my-1">
              Type
            </label>

            <div className="d-flex align-items-center">
              <div className="form-check form-check-inline form-check-solid">
                <input
                  name="isPublic"
                  id="isPublic"
                  className="form-check-input"
                  value="true"
                  type="radio"
                  checked={fileType}
                  onChange={handleFileTypeChange}
                  disabled={disableTypeSelection}
                />
                <span className="form-check-label">Public</span>
              </div>

              <div className="form-check form-check-inline form-check-solid">
                <input
                  name="isPublic"
                  id="isPublic"
                  className="form-check-input"
                  value="false"
                  type="radio"
                  checked={!fileType}
                  onChange={handleFileTypeChange}
                  disabled={disableTypeSelection}
                />
                <span className="form-check-label">Private</span>
              </div>
            </div>
            <FormErrorMessage
              name="isPublic"
              errors={errors}
              className="my-1 px-2"
            />
          </div>
        )}
      </form>
    </Modal>
  );
};

CreateFileModal.defaultProps = {
  onFileDataUpdate: () => null,
};

export default CreateFileModal;
