import React, { useEffect, useState } from 'react';
import PlaylistsTable from '../../../../../components/AssignmentTables/PlaylistsTable';
import Playlist from '../../../../../interfaces/playlist.interface';
import GetPlaylistsRequestData from '../../../../../api/endpoints/playlists/interfaces/getPlaylistsRequestData.interface';

export default ({
  onSelectionChange,
  preselectedItems,
  requestData,
  playlists,
  total = 10,
  setFiltersSearch,
  loadingModals = false,
}: {
  onSelectionChange?: (selectedItems: string[]) => void;
  preselectedItems: string[];
  requestData: GetPlaylistsRequestData;
  setFiltersSearch?: (filters: GetPlaylistsRequestData) => void;
  playlists: Playlist[];
  total: number;
  loadingModals?: boolean;
}) => {
  const [filters, setFilters] = useState<GetPlaylistsRequestData>(requestData);

  useEffect(() => {
    if (setFiltersSearch) setFiltersSearch(filters);
  }, [filters]);

  return (
    <>
      {onSelectionChange ? (
        <PlaylistsTable
          isLoading={loadingModals}
          playlists={playlists}
          total={total}
          onSearchDataChange={setFilters}
          onSelectionChange={onSelectionChange}
          preselectedItems={preselectedItems}
          lockingPlaylist
        />
      ) : (
        <></>
      )}
    </>
  );
};
