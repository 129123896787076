import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Info from './Info';
import Assign from './Assign';
import WithModesModal from '../../WithModesModal';
import { KTSVG } from '../../../lib/metronic/helpers';
import AddUsersGroupSteps from '../../../enums/steps/addUsersGroupSteps.enum';
import CreateUsersGroupRequestData from '../../../api/endpoints/usersGroups/interfaces/createUsersGroupRequestData.interface';
import useModalState from '../../../utils/hooks/useModalState';
import sizingStyles from '../../../styles/sizing.module.scss';

export default ({
  className,
  onSubmit,
}: {
  className?: string;
  onSubmit: (data: CreateUsersGroupRequestData) => void;
}) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] = useState<
    Partial<CreateUsersGroupRequestData>
  >({});
  const [isVisible, handleOpen, handleClose] = useModalState(false);

  return (
    <>
      <button
        type="button"
        onClick={handleOpen}
        className={clsx('btn btn-primary', className)}
      >
        <KTSVG
          path="/media/icons/duotune/arrows/arr075.svg"
          className="svg-icon-2"
        />{' '}
        {t('users_groups.add_group')}
      </button>
      <WithModesModal<any, AddUsersGroupSteps>
        isVisible={isVisible}
        onClose={handleClose}
        modes={{
          [AddUsersGroupSteps.Info]: {
            title: t('users_groups.add_group'),
            render: ({ setMode }) => (
              <Info setMode={setMode} data={stepsData} setData={setStepsData} />
            ),
          },
          [AddUsersGroupSteps.Assign]: {
            title: t('users_groups.add_group'),
            render: ({ setMode, close }) => (
              <Assign
                setMode={setMode}
                onSubmit={(data) => {
                  close();
                  setStepsData({});
                  onSubmit(data as CreateUsersGroupRequestData);
                }}
                setData={setStepsData}
                data={stepsData}
              />
            ),
          },
        }}
        defaultMode={AddUsersGroupSteps.Info}
        dialogClassName={clsx('mw-1000px', sizingStyles.fitContentOld)}
      />
    </>
  );
};
