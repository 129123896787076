/* eslint-disable no-nested-ternary */
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import FormErrorMessage from '../../../components/FormErrorMessage';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import { AppDependencyConfig } from '../../../store/types/apps/globalTypes';
import Orientation from '../../../enums/orientation.enum';
import {
  EditLiveStreamFeedRequest,
  LiveStreamFeedSchema,
} from '../../../store/types/apps/liveStreamFeed';
import { disableRightClickIframe } from '../../../utils/common';

type EditLiveStreamRequestFormMutated = Omit<
  EditLiveStreamFeedRequest,
  'id' | 'config' | 'placement'
>;

interface EditLiveStreamFeedRequestFormPayload
  extends EditLiveStreamRequestFormMutated {
  liveStreamFeedUrl: string;
}

export interface EditFeedModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (editLiveStreamFeedRequest: EditLiveStreamFeedRequest) => void;
  title: string;
  isLoading: boolean;
  selectedLiveStreamFeedSchema: LiveStreamFeedSchema;
  previewHeight?: number;
}

const EditFeedModal = ({
  show,
  onHide,
  title,
  onSubmit,
  isLoading = false,
  selectedLiveStreamFeedSchema,
  previewHeight = 400,
}: EditFeedModalProps) => {
  const { t } = useTranslation();
  const [previewDocumentIsFetching, setPreviewDocumentIsFetching] =
    React.useState<boolean>(false);

  const initialValues: EditLiveStreamFeedRequestFormPayload = {
    name: selectedLiveStreamFeedSchema?.name,
    liveStreamFeedUrl: (
      selectedLiveStreamFeedSchema?.dependency?.config as AppDependencyConfig
    )?.liveStreamFeedUrl as string,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    liveStreamFeedUrl: Yup.string()
      .required()
      .url('Please enter a valid Website URL')
      .defined(),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<EditLiveStreamFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [feedName, setFeedName] = useStateWithHookForm<
    EditLiveStreamFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const handleFeedNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFeedName(value),
    [setFeedName],
  );

  const [liveStreamFeedUrl, setLiveStreamFeedURL] = useStateWithHookForm<
    EditLiveStreamFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'liveStreamFeedUrl' },
    initialValues.liveStreamFeedUrl,
  );

  const handlePreviewDocumentIsFetching = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (previewDocumentIsFetching: boolean) =>
      setPreviewDocumentIsFetching(previewDocumentIsFetching),
    [],
  );

  const handleLiveStreamFeedURLChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (value === '') {
        handlePreviewDocumentIsFetching(false);
      } else {
        handlePreviewDocumentIsFetching(true);
      }

      setLiveStreamFeedURL(value);
    },
    [handlePreviewDocumentIsFetching, setLiveStreamFeedURL],
  );

  const handleOnIFrameLoaded = React.useCallback(
    () => handlePreviewDocumentIsFetching(false),
    [handlePreviewDocumentIsFetching],
  );

  const handleOnSubmit = React.useCallback(
    async (data: EditLiveStreamFeedRequestFormPayload) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, liveStreamFeedUrl } = data;

      const editHTMLFeedRequest: EditLiveStreamFeedRequest = {
        id: selectedLiveStreamFeedSchema.id,
        name,
        config: {
          liveStreamFeedUrl,
        },
        zoneId: Orientation.Landscape,
      };

      onSubmit(editHTMLFeedRequest);
    },
    [onSubmit, selectedLiveStreamFeedSchema?.id],
  );

  React.useEffect(() => {
    disableRightClickIframe(feedName);
  }, []);

  return (
    <Modal
      title={title}
      show={show}
      onHide={onHide}
      widthDefaultSize
      withFooter
      footerContent={
        <button
          form="form"
          type="submit"
          className="btn btn-primary"
          disabled={!!isLoading}
        >
          {isLoading ? (
            <span>
              Editing{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            <span>{t('common.edit')}</span>
          )}
        </button>
      }
    >
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        id="form"
        className="d-flex flex-column  justify-content-between"
      >
        <div className="d-flex flex-row p-4 w-100">
          <div className="d-flex flex-column mx-2 w-100">
            <label htmlFor="name" className="required text-dark fw-bolder my-1">
              {t('apps.liveStream.liveStreamTitle')}
            </label>
            <input
              name="name"
              id="name"
              value={feedName}
              onChange={handleFeedNameChange}
              className="form-control form-control-solid"
              placeholder="Type here"
              type="text"
            />
            <FormErrorMessage
              name="name"
              errors={errors}
              className="my-1 px-2"
            />
          </div>
          <div className="d-flex flex-column mx-2 w-100">
            <label
              htmlFor="liveStreamFeedUrl"
              className="required text-dark fw-bolder my-1"
            >
              {t('apps.liveStream.webPageUrl')}
            </label>
            <input
              name="liveStreamFeedUrl"
              id="liveStreamFeedUrl"
              value={liveStreamFeedUrl}
              onChange={handleLiveStreamFeedURLChange}
              className="form-control form-control-solid"
            />
            <FormErrorMessage
              name="liveStreamFeedUrl"
              errors={errors}
              className="my-1 px-2"
            />
          </div>
        </div>

        <div className="d-flex flex-column   flex-center  p-4 w-100">
          {!errors.liveStreamFeedUrl && liveStreamFeedUrl !== '' ? (
            <div className="d-flex flex-column flex-center w-100 h-100">
              {previewDocumentIsFetching ? (
                <Spinner role="status" animation="border" />
              ) : null}
              <iframe
                title={feedName}
                src={liveStreamFeedUrl}
                width="100%"
                height={`${previewHeight}px`}
                onLoad={handleOnIFrameLoaded}
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}
                style={{ padding: '4px' }}
              />
            </div>
          ) : (
            <div className="d-flex flex-column flex-center w-100">
              <img
                alt="No items found"
                src="/media/illustrations/sketchy-1/5.png"
                className="d-flex flex-column align-self-center w-25"
              />
              <div className="fs-1">{t('apps.liveStream.noPreview')}</div>
              <div className="fs-6">{t('apps.liveStream.webPageUrl')}</div>
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default EditFeedModal;
