import React, { useEffect } from 'react';
import IContent from '../../../../../../../interfaces/content.interface';
import RSSThumbnail from '../../../../../../Apps/Rss/Thumbnail';

export default ({ content }: { content: IContent }) => {
  const [url, setUrl] = React.useState('');

  const rssData = content.app;

  useEffect(() => {
    if (!rssData) return;

    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?appId=${rssData?.id}`,
    );
  }, [rssData]);

  return <RSSThumbnail src={url} />;
};
