/* eslint-disable no-nested-ternary */
import i18next from 'i18next';
import { debounce } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ScheduleContentRequestData from '../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import PublishModal from '../../../components/PublishModal';
import Pagination from '../../../components/Pagination';
import Search from '../../../components/Search';
import { KTSVG } from '../../../lib/metronic/helpers';
import {
  createScheduleYOUTUBEFeedResetActionCreator,
  createYoutubeFeedActionCreator,
  createYoutubeFeedResetActionCreator,
  deleteYoutubeFeedActionCreator,
  deleteYoutubeFeedResetActionCreator,
  editYoutubeFeedActionCreator,
  editYoutubeFeedResetActionCreator,
  getYoutubeFeedsActionCreator,
  scheduleYoutubeFeedActionCreator,
  scheduleYoutubeFeedResetActionCreator,
} from '../../../store/actions/apps';
import GlobalState from '../../../store/reducers/globalState.interface';
import { SortBy, SortOrder } from '../../../store/types/apps/globalTypes';
import {
  CreateYoutubeFeedRequest,
  EditYoutubeFeedRequest,
  GetYoutubeFeedsRequest,
  ScheduleYoutubeFeedRequest,
  YoutubeFeedSchema,
} from '../../../store/types/apps/youtubeFeed';
import CreateFeedModal from './CreateFeedModal';
import DeleteFeedModal from './DeleteFeedModal';
import EditFeedModal from './EditFeedModal';
import ErrorFeeds from './ErrorFeeds';
import FeedsTable from './FeedsTable';
import PreviewFeedModal from './PreviewFeedModal';

const YoutubeAppContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    loading: youtubeFeedsLoading,
    deleteYoutubeFeedLoading,
    editYoutubeFeedLoading,
    data: paginatedYoutubeFeeds,
    error,
    isGetYoutubeFeedsSuccess,
    isCreateYoutubeFeedSuccess,
    isScheduleYoutubeFeedSuccess,
    isDeleteYoutubeFeedSuccess,
    isEditYoutubeFeedSuccess,
    isCreateScheduleYOUTUBEFeedSuccess,
  } = useSelector((state: GlobalState) => state.youtubeFeeds);
  const [isScheduleandCreateSuccess, setSCS] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');

  const [isSearching, setIsSearching] = React.useState<boolean>(false);

  const [getYoutubeFeedsRequest, setGetYoutubeFeedsRequest] =
    React.useState<GetYoutubeFeedsRequest>({
      params: {
        search: searchValue,
        limit: 10,
        page: paginatedYoutubeFeeds?.meta?.currentPage || 1,
        appType: 'youtube',
        sortOrder: SortOrder.ASC,
        sortBy: SortBy.CREATED_AT,
      },
    });

  const { params } = getYoutubeFeedsRequest;

  const [selectedYoutubeFeedSchema, setSelectedYoutubeFeedSchema] =
    React.useState<YoutubeFeedSchema | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [showFilterModal, setShowFilterModal] = React.useState<boolean>(false);

  const [showCreateFeedModal, setShowCreateFeedModal] =
    React.useState<boolean>(false);

  const [showPreviewYoutubeFeedModal, setShowPreviewYoutubeFeedModal] =
    React.useState<boolean>(false);

  const [showEditYoutubeFeedModal, setShowEditYoutubeFeedModal] =
    React.useState<boolean>(false);

  const [showDeleteYoutubeFeedModal, setShowDeleteYoutubeFeedModal] =
    React.useState<boolean>(false);

  const [showScheduleYoutubeFeedModal, setShowScheduleYoutubeFeedModal] =
    React.useState<boolean>(false);
  // callbacks/handlers

  // const handleOpenFilterModal = React.useCallback(
  //   () => setShowFilterModal(true),
  //   [],
  // );

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleCloseFilterModal = React.useCallback(
  //   () => setShowFilterModal(false),
  //   [],
  // );

  // create feed modal handler
  const handleOpenCreateFeedModal = React.useCallback(
    () => setShowCreateFeedModal(true),
    [],
  );

  const handleCloseCreateFeedModal = React.useCallback(
    () => setShowCreateFeedModal(false),
    [],
  );

  // youtube feed preview modal handler
  const handleOpenPreviewYoutubeFeedModal = React.useCallback(
    (feed: YoutubeFeedSchema) => {
      setSelectedYoutubeFeedSchema(feed);
      setShowPreviewYoutubeFeedModal(true);
    },
    [],
  );

  const handleClosePreviewYoutubeFeedModal = React.useCallback(() => {
    setSelectedYoutubeFeedSchema(null);
    setShowPreviewYoutubeFeedModal(false);
  }, []);

  // edit youtube feed modal handler
  const handleOpenEditYoutubeFeedModal = React.useCallback(
    (feed: YoutubeFeedSchema) => {
      setSelectedYoutubeFeedSchema(feed);
      setShowEditYoutubeFeedModal(true);
    },
    [],
  );

  const handleCloseEditYoutubeFeedModal = React.useCallback(() => {
    setSelectedYoutubeFeedSchema(null);
    setShowEditYoutubeFeedModal(false);
  }, []);

  // delete youtube feed modal handler
  const handleOpenDeleteYoutubeFeedModal = React.useCallback(
    (feed: YoutubeFeedSchema) => {
      setSelectedYoutubeFeedSchema(feed);
      setShowDeleteYoutubeFeedModal(true);
    },
    [],
  );

  const handleCloseDeleteYoutubeFeedModal = React.useCallback(() => {
    setSelectedYoutubeFeedSchema(null);
    setShowDeleteYoutubeFeedModal(false);
  }, []);

  // schedule youtube feed modal handler
  const handleOpenScheduleYoutubeFeedModal = React.useCallback(
    (feed: YoutubeFeedSchema) => {
      setSelectedYoutubeFeedSchema(feed);
      setShowScheduleYoutubeFeedModal(true);
    },
    [],
  );

  const handleCloseScheduleYoutubeFeedModal = React.useCallback(() => {
    setSelectedYoutubeFeedSchema(null);
    setShowScheduleYoutubeFeedModal(false);
  }, []);

  const handleOpenActionsDropdown = React.useCallback(
    (feed: YoutubeFeedSchema) => setSelectedYoutubeFeedSchema(feed),
    [],
  );

  const handleSearchValueChange = React.useCallback((value: string) => {
    setSearchValue(value);
    setIsSearching(true);
  }, []);

  const debounceOnLastSearchValue = React.useMemo(
    () =>
      debounce((value) => {
        setGetYoutubeFeedsRequest({
          ...getYoutubeFeedsRequest,
          params: { ...params, search: value },
        });
      }, 1000),
    [getYoutubeFeedsRequest, params],
  );

  const handleOnPageNumberUpdate = React.useCallback(
    (pageNumber: number) =>
      setGetYoutubeFeedsRequest({
        ...getYoutubeFeedsRequest,
        params: { ...params, page: pageNumber },
      }),
    [getYoutubeFeedsRequest, params],
  );

  const handleCreateFeed = React.useCallback(
    (createFeedRequest: CreateYoutubeFeedRequest) =>
      dispatch(createYoutubeFeedActionCreator(createFeedRequest)),
    [dispatch],
  );

  const setIsScheduleandCreateSuccess = (bool: boolean) => {
    setSCS(bool);
  };
  const handleScheduleYoutubeFeed = React.useCallback(
    (data: ScheduleContentRequestData) => {
      const {
        zoneId,
        duration,
        isOrdered,
        tags,
        startDate,
        endDate,
        weekDays,
        isLocked,
        playlistIds,
      } = data;

      const request: ScheduleYoutubeFeedRequest = {
        appId: (selectedYoutubeFeedSchema as YoutubeFeedSchema).id,
        appType: 'youtube',
        name: (selectedYoutubeFeedSchema as YoutubeFeedSchema).name,
        startDate: startDate && startDate.split('T')[0],
        endDate: endDate && endDate.split('T')[0],
        duration,
        zoneId,
      };

      if (isLocked) {
        request.isLocked = isLocked;
      }

      if (isOrdered) {
        request.isOrdered = isOrdered;
      }

      if (tags) {
        request.tags = tags;
      }

      if (playlistIds) {
        request.playlistIds = playlistIds;
      }

      if (weekDays) {
        request.weekDays = weekDays;
      }

      dispatch(scheduleYoutubeFeedActionCreator(request));
    },
    [dispatch, selectedYoutubeFeedSchema],
  );

  const handleDeleteYoutubeFeed = React.useCallback(
    ({ id }: YoutubeFeedSchema) =>
      dispatch(deleteYoutubeFeedActionCreator({ id })),
    [dispatch],
  );

  const handleEditYoutubeFeed = React.useCallback(
    (editFeedRequest: EditYoutubeFeedRequest) =>
      dispatch(editYoutubeFeedActionCreator(editFeedRequest)),
    [dispatch],
  );

  const handleClickOnYoutubeFeed = React.useCallback(
    async (feed: YoutubeFeedSchema) => handleOpenPreviewYoutubeFeedModal(feed),
    [handleOpenPreviewYoutubeFeedModal],
  );

  // effects
  React.useEffect(() => {
    if (isSearching) {
      debounceOnLastSearchValue(searchValue);
      setIsSearching(false);
    }
  }, [debounceOnLastSearchValue, isSearching, searchValue]);

  React.useEffect(() => {
    dispatch(getYoutubeFeedsActionCreator(getYoutubeFeedsRequest));
  }, [dispatch, getYoutubeFeedsRequest, params]);

  React.useEffect(() => {
    if (isCreateYoutubeFeedSuccess) {
      if (!isScheduleandCreateSuccess) {
        toast.success(i18next.t<string>('apps.youtube.feed_created'));
        handleCloseCreateFeedModal();
      }
      setSCS(false);
      dispatch(getYoutubeFeedsActionCreator(getYoutubeFeedsRequest));
      dispatch(
        createYoutubeFeedResetActionCreator({
          loading: false,
          isCreateYoutubeFeedSuccess: false,
          error: null,
        }),
      );
    }

    if (isCreateScheduleYOUTUBEFeedSuccess) {
      toast.success(
        i18next.t<string>('apps.youtube.feed_created_and_scheduled'),
        {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: true,
        },
      );

      dispatch(getYoutubeFeedsActionCreator(getYoutubeFeedsRequest));

      handleCloseCreateFeedModal();

      dispatch(
        createScheduleYOUTUBEFeedResetActionCreator({
          loading: false,
          isCreateScheduleYOUTUBEFeedSuccess: false,
          error: null,
        }),
      );
      dispatch(
        createYoutubeFeedResetActionCreator({
          loading: false,
          isCreateYoutubeFeedSuccess: false,
          error: null,
        }),
      );

      dispatch(
        scheduleYoutubeFeedResetActionCreator({
          loading: false,
          isScheduleYoutubeFeedSuccess: false,
          error: null,
        }),
      );
    }

    if (isScheduleYoutubeFeedSuccess) {
      toast.success(i18next.t<string>('apps.youtube.feed_scheduled'));

      handleCloseScheduleYoutubeFeedModal();

      dispatch(
        scheduleYoutubeFeedResetActionCreator({
          loading: false,
          isScheduleYoutubeFeedSuccess: false,
          error: null,
        }),
      );
    }

    if (isEditYoutubeFeedSuccess) {
      toast.success(i18next.t<string>('apps.youtube.feed_edited'));

      dispatch(getYoutubeFeedsActionCreator(getYoutubeFeedsRequest));

      handleCloseEditYoutubeFeedModal();

      dispatch(
        editYoutubeFeedResetActionCreator({
          loading: false,
          isEditYoutubeFeedSuccess: false,
          error: null,
        }),
      );
    }

    if (isDeleteYoutubeFeedSuccess) {
      toast.success(i18next.t<string>('apps.youtube.feed_deleted'));

      dispatch(getYoutubeFeedsActionCreator(getYoutubeFeedsRequest));

      handleCloseDeleteYoutubeFeedModal();

      dispatch(
        deleteYoutubeFeedResetActionCreator({
          loading: false,
          isDeleteYoutubeFeedSuccess: false,
          error: null,
        }),
      );
    }
  }, [
    dispatch,
    getYoutubeFeedsRequest,
    handleCloseCreateFeedModal,
    handleCloseDeleteYoutubeFeedModal,
    handleCloseEditYoutubeFeedModal,
    handleCloseScheduleYoutubeFeedModal,
    isCreateYoutubeFeedSuccess,
    isDeleteYoutubeFeedSuccess,
    isEditYoutubeFeedSuccess,
    isScheduleYoutubeFeedSuccess,
    isCreateScheduleYOUTUBEFeedSuccess,
  ]);

  return (
    <div>
      <div className="bg-white d-flex flex-column p-6">
        <div className="bg-white d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-4 w-100">
          <Search
            onChange={handleSearchValueChange}
            placeholder="Search"
            className="override-input-search-field-width-file-manager"
            searchValue={searchValue}
            wrapperClassName="mx-md-2"
          />

          <div className="d-flex">
            {/* <FiltersDropdown className="me-3" /> */}
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleOpenCreateFeedModal}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr075.svg"
                className="svg-icon-2"
              />
              {t('apps.youtube.add_video')}
            </button>
          </div>
        </div>

        <div className="bg-white d-flex flex-column w-100 flex-grow-1">
          {!isGetYoutubeFeedsSuccess && error ? (
            <ErrorFeeds error={error} />
          ) : (
            <FeedsTable
              clickOnYoutubeFeed={handleClickOnYoutubeFeed}
              openEditYoutubeFeedModal={handleOpenEditYoutubeFeedModal}
              openDeleteYoutubeFeedModal={handleOpenDeleteYoutubeFeedModal}
              openActionsDropdown={handleOpenActionsDropdown}
              openScheduleYoutubeFeedModal={handleOpenScheduleYoutubeFeedModal}
              openPreviewYoutubeFeedModal={handleOpenPreviewYoutubeFeedModal}
              selectedYoutubeFeed={selectedYoutubeFeedSchema}
              isLoading={youtubeFeedsLoading}
              paginatedYoutubeFeeds={paginatedYoutubeFeeds}
            />
          )}

          <Pagination
            total={paginatedYoutubeFeeds.meta.totalItems}
            pageSize={paginatedYoutubeFeeds.meta.itemsPerPage}
            pageNumber={paginatedYoutubeFeeds.meta.currentPage}
            onPageNumberUpdate={handleOnPageNumberUpdate}
            className="mt-3 mb-5"
          />
        </div>
      </div>

      {/** Filter Modal */}
      {/* {showFilterModal ? <p>FilterModal</p> : null} */}

      {/** Create Feed Modal */}
      {showCreateFeedModal ? (
        <CreateFeedModal
          onSetIsScheduleandCreateSuccess={setIsScheduleandCreateSuccess}
          show={showCreateFeedModal}
          onSubmit={handleCreateFeed}
          onHide={handleCloseCreateFeedModal}
          title={t('apps.youtube.add_video')}
          widthDefaultSize
        />
      ) : null}

      {/** Youtube Feed Preview Modal */}
      {showPreviewYoutubeFeedModal ? (
        <PreviewFeedModal
          show={showPreviewYoutubeFeedModal}
          onHide={handleClosePreviewYoutubeFeedModal}
          widthDefaultSize
          selectedYoutubeFeedSchema={
            selectedYoutubeFeedSchema as YoutubeFeedSchema
          }
          title={t('apps.youtube.preview_video')}
        />
      ) : null}

      {/** Youtube Feed Edit Modal */}
      {showEditYoutubeFeedModal ? (
        <EditFeedModal
          show={showEditYoutubeFeedModal}
          onSubmit={handleEditYoutubeFeed}
          onHide={handleCloseEditYoutubeFeedModal}
          title={t('apps.youtube.edit_feed')}
          isLoading={editYoutubeFeedLoading}
          widthDefaultSize
          selectedYoutubeFeedSchema={
            selectedYoutubeFeedSchema as YoutubeFeedSchema
          }
        />
      ) : null}

      {/** Youtube Feed Delete Modal */}
      {showDeleteYoutubeFeedModal ? (
        <DeleteFeedModal
          show={showDeleteYoutubeFeedModal}
          onHide={handleCloseDeleteYoutubeFeedModal}
          title={t('apps.youtube.delete_feed')}
          onSubmit={handleDeleteYoutubeFeed}
          selectedYoutubeFeedSchema={
            selectedYoutubeFeedSchema as YoutubeFeedSchema
          }
          isLoading={deleteYoutubeFeedLoading}
        />
      ) : null}

      {/** Youtube Feed Schedule Modal */}
      {showScheduleYoutubeFeedModal ? (
        <PublishModal
          onClose={handleCloseScheduleYoutubeFeedModal}
          onSubmit={handleScheduleYoutubeFeed}
          isVisible={showScheduleYoutubeFeedModal}
          scheduleYoutubeApp
          defaultValues={{
            isOrdered: true,
            duration: (
              selectedYoutubeFeedSchema as YoutubeFeedSchema
            )?.dependency?.config?.youtubeFeedDurationInSeconds?.toString(),
          }}
          skipOrder
          skipOrientationAuto
        />
      ) : null}
    </div>
  );
};

export default YoutubeAppContainer;
