import { call, put, takeEvery } from 'redux-saga/effects';
import ContentsActions from '../actions/contents/contentsActions.enum';
import {
  setContentsLoading,
  getPlaylistContentsSuccess,
} from '../actions/contents';
import api from '../../api';
import GetPlaylistContentsAction from '../actions/contents/interfaces/getPlaylistContentsAction.interface';
import GetPlaylistContentsResponseData from '../../api/endpoints/playlists/interfaces/getPlaylistContentsResponseData.interface';

/**
 * The playlist content retrieval worker
 * @param playlistId
 */
function* getPlaylistContentsRequestWorker({
  payload,
}: GetPlaylistContentsAction) {
  yield put(setContentsLoading({ loading: true }));

  try {
    const responseData: GetPlaylistContentsResponseData = yield call(
      api.playlists.getContent,
      payload,
    );

    yield put(getPlaylistContentsSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setContentsLoading({ loading: false }));
}

export default function* devicesWatcher() {
  yield takeEvery<GetPlaylistContentsAction>(
    ContentsActions.GET_PLAYLIST_CONTENT,
    getPlaylistContentsRequestWorker,
  );
}
