import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoginAsConfirm from '..';
import User from '../../../../interfaces/user/user.interface';
import { loginAsUser } from '../../../../utils/auth0';
import useIsAdmin from '../../../../utils/hooks/useIsAdmin';

export default ({ user, className }: { user: User; className?: string }) => {
  const { t } = useTranslation();
  const [isAdmin] = useIsAdmin();
  return (
    <>
      {isAdmin && (
        <button type="button" className={clsx('btn', 'btn-primary', className)}>
          <LoginAsConfirm
            body={t('users.login_as_user', {
              firstName: user.firstName,
              lastName: user.lastName,
            })}
            text="Login As"
            handleConfirm={() => loginAsUser(user)}
            user={user}
          />
        </button>
      )}
    </>
  );
};
