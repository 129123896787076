export const APP_ID =
  process.env.REACT_APP_INSTAGRAM_APP_ID ?? '1145111962840652';

export const APP_URI =
  process.env.REACT_APP_INSTAGRAM_URI ??
  'https://dev.indigovizual.io/apps/instagram';

export const APP_LOGGER_ID =
  process.env.REACT_APP_INSTAGRAM_LOGGER_ID ??
  '02cc4885-a6ce-4c73-9492-7d49b3c09477';

export const FACEBOOK_APP_ID =
  process.env.REACT_APP_FACEBOOK_APP_ID ?? '3443566672541069';
