import React, { memo } from 'react';
import { isEqual } from 'lodash';
import api from '../../api';
import SelectAsync, {
  SelectedValue,
} from '../../components/Select/SelectAsync';

/**
 * Retrieves the select options asynchronously
 * @param search
 */
const loadOptions = async (search: string): Promise<SelectedValue[]> => {
  const timezones = await api.timezones.getTimezones({ search });

  return timezones.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

const SelectTimezone = ({
  id,
  onChange,
  initialValue,
  isDisabled,
}: {
  id?: string;
  onChange: (selectedValue: SelectedValue) => void;
  initialValue?: SelectedValue;
  isDisabled?: boolean;
}) => {
  return (
    <SelectAsync
      id={id}
      initialValue={initialValue}
      onChange={onChange}
      loadOptions={loadOptions}
      isDisabled={isDisabled}
    />
  );
};

SelectTimezone.defaultProps = {
  initialValue: {},
};

export default memo(
  SelectTimezone,
  (prev, next) =>
    (['id', 'isDisabled'] as const).every((key) => prev[key] === next[key]) &&
    isEqual(prev.initialValue, next.initialValue),
);
