import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { deleteUsersGroup } from '../../../store/actions/usersGroups';
import WithDeleteConfirm from '../../../components/WithDeleteConfirm';
import UsersGroup from '../../../interfaces/usersGroup.interface';
import Tooltip from '../../../components/Tooltip';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';

export default ({
  group,
  children,
  selectedItems,
  onDeleteUserGroup,
  unAssignUserGroup,
}: {
  group?: UsersGroup;
  children?: React.ReactNode;
  selectedItems?: string[];
  onDeleteUserGroup?: (id: string) => void;
  unAssignUserGroup?: (userGroupId: string, deviceGroupId: string) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: deviceGroupId } = useParams<{ id: string }>();
  const [isAdmin] = useIsAdmin();

  const handleConfirm = () => {
    if (selectedItems) {
      // Multiple delete user groups
    }
    if (group) {
      if (unAssignUserGroup) {
        unAssignUserGroup(deviceGroupId, group.id);
      } else if (onDeleteUserGroup) {
        onDeleteUserGroup(group.id);
      } else dispatch(deleteUsersGroup({ id: group.id, isAdmin }));
    }
  };
  return (
    <WithDeleteConfirm
      body={
        unAssignUserGroup
          ? `${t('common.delete_confirmation.unassign_title')} ${t(
              'common.delete_confirmation.users_group',
            )} "${group?.name}"?`
          : `${t('common.delete_confirmation.title')} ${t(
              'common.delete_confirmation.users_group',
            )} "${group?.name}"?`
      }
      handleConfirm={handleConfirm}
      confirmText={
        unAssignUserGroup
          ? t('common.delete_confirmation.confirm_unassign')
          : t('common.delete_confirmation.confirm')
      }
    >
      {children ?? (
        <Tooltip
          text={unAssignUserGroup ? t('common.unssign') : t('common.delete')}
        >
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-trash" />
          </span>
        </Tooltip>
      )}
    </WithDeleteConfirm>
  );
};
