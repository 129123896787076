import React, { useState } from 'react';

// import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card';
import Tabs from '../../../components/Tabs';

export default () => {
  // const { t } = useTranslation();
  const initialTab = 0;
  const tabs = [{ name: 'Alerts' }, { name: 'Templates' }];
  const [activeTab, changeActiveTab] = useState(initialTab);
  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          <Tabs
            initialTab={initialTab}
            changeActiveTab={changeActiveTab}
            tabs={tabs}
          />
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        {activeTab === 0 && <div>Alerts</div>}
        {activeTab === 1 && <div>Templates</div>}
      </Card.Body>
    </Card>
  );
};
