/* eslint-disable no-nested-ternary */
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';
import Counter from '../../../components/Counter';
import FormErrorMessage from '../../../components/FormErrorMessage';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import { AppDependencyConfig } from '../../../store/types/apps/globalTypes';
import Orientation from '../../../enums/orientation.enum';
import {
  EditHTMLFeedRequest,
  HTMLFeedSchema,
} from '../../../store/types/apps/htmlFeed';
import { disableRightClickIframe } from '../../../utils/common';

type EditHTMLFeedRequestFormMutated = Omit<
  EditHTMLFeedRequest,
  'id' | 'config' | 'placement'
>;

interface EditHTMLFeedRequestFormPayload
  extends EditHTMLFeedRequestFormMutated {
  htmlFeedUrl: string;
  refreshRate: number;
}

export interface EditFeedModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (editHTMLFeedRequest: EditHTMLFeedRequest) => void;
  title: string;
  isLoading: boolean;
  selectedHTMLFeedSchema: HTMLFeedSchema;
  previewHeight?: number;
}

const EditFeedModal = ({
  show,
  onHide,
  title,
  onSubmit,
  isLoading = false,
  selectedHTMLFeedSchema,
  previewHeight = 400,
}: EditFeedModalProps) => {
  const { t } = useTranslation();
  const [previewDocumentIsFetching, setPreviewDocumentIsFetching] =
    React.useState<boolean>(false);

  const initialValues: EditHTMLFeedRequestFormPayload = {
    name: selectedHTMLFeedSchema?.name,
    htmlFeedUrl: (
      selectedHTMLFeedSchema?.dependency?.config as AppDependencyConfig
    )?.htmlFeedUrl as string,
    refreshRate: (
      selectedHTMLFeedSchema?.dependency?.config as AppDependencyConfig
    )?.htmlRefreshRateInSeconds as number,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    htmlFeedUrl: Yup.string()
      .required()
      .url('Please enter a valid Website URL')
      .defined(),
    refreshRate: Yup.number()
      .min(0, t('common.validation.refreshRate'))
      .defined(),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<EditHTMLFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [feedName, setFeedName] = useStateWithHookForm<
    EditHTMLFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const handleFeedNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFeedName(value),
    [setFeedName],
  );

  const [htmlFeedUrl, setHTMLFeedURL] = useStateWithHookForm<
    EditHTMLFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'htmlFeedUrl' }, initialValues.htmlFeedUrl);

  const handlePreviewDocumentIsFetching = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (previewDocumentIsFetching: boolean) =>
      setPreviewDocumentIsFetching(previewDocumentIsFetching),
    [],
  );

  const handleHTMLFeedURLChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      if (value === '') {
        handlePreviewDocumentIsFetching(false);
      } else {
        handlePreviewDocumentIsFetching(true);
      }

      setHTMLFeedURL(value);
    },
    [handlePreviewDocumentIsFetching, setHTMLFeedURL],
  );

  const [refreshRate, setRefreshRate] = useStateWithHookForm<
    EditHTMLFeedRequestFormPayload,
    number
  >({ setValue, trigger, name: 'refreshRate' }, initialValues.refreshRate);

  const handleRefreshRateChange = React.useCallback(
    (value: number) => setRefreshRate(value),
    [setRefreshRate],
  );

  const handleOnIFrameLoaded = React.useCallback(
    () => handlePreviewDocumentIsFetching(false),
    [handlePreviewDocumentIsFetching],
  );

  const handleOnSubmit = React.useCallback(
    async (data: EditHTMLFeedRequestFormPayload) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, htmlFeedUrl, refreshRate } = data;

      const editHTMLFeedRequest: EditHTMLFeedRequest = {
        id: selectedHTMLFeedSchema.id,
        name,
        config: {
          htmlFeedUrl,
          htmlRefreshRateInSeconds: refreshRate as number,
        },
        zoneId: Orientation.Landscape,
      };

      onSubmit(editHTMLFeedRequest);
    },
    [onSubmit, selectedHTMLFeedSchema?.id],
  );

  React.useEffect(() => {
    disableRightClickIframe(feedName);
  }, []);

  return (
    <Modal
      title={title}
      show={show}
      onHide={onHide}
      widthDefaultSize
      withFooter
      footerContent={
        <button form="form" type="submit" className="btn btn-primary">
          {isLoading ? (
            <span>
              Editing{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            <span>{t('common.edit')}</span>
          )}
        </button>
      }
    >
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        id="form"
        className="d-flex flex-column  justify-content-between"
      >
        <div className="d-flex flex-row p-4 w-100">
          <div className="d-flex flex-column mx-2 w-100">
            <label htmlFor="name" className="required text-dark fw-bolder my-1">
              {t('apps.html5.Html5Title')}
            </label>
            <input
              name="name"
              id="name"
              value={feedName}
              onChange={handleFeedNameChange}
              className="form-control form-control-solid"
              placeholder="Type here"
              type="text"
            />
            <FormErrorMessage
              name="name"
              errors={errors}
              className="my-1 px-2"
            />
          </div>
          <div className="d-flex flex-column mx-2 w-100">
            <label
              htmlFor="htmlFeedUrl"
              className="required text-dark fw-bolder my-1"
            >
              {t('apps.html5.webPageUrl')}
            </label>
            <input
              name="htmlFeedUrl"
              id="htmlFeedUrl"
              value={htmlFeedUrl}
              onChange={handleHTMLFeedURLChange}
              className="form-control form-control-solid"
            />
            <FormErrorMessage
              name="htmlFeedUrl"
              errors={errors}
              className="my-1 px-2"
            />
          </div>
        </div>
        <div className="d-flex flex-row px-4 w-100">
          <div className="d-flex flex-column mx-2 w-50">
            <Counter
              name="refreshRate"
              title="Refresh Rate (sec)"
              value={refreshRate}
              onChange={handleRefreshRateChange}
              className="form-control form-control-solid"
              classNameTitle="text-dark fw-bolder my-1"
              isNotRequired
            />
            <FormErrorMessage
              name="refreshRate"
              errors={errors}
              className="my-1 px-2"
            />
          </div>
        </div>

        <div className="d-flex flex-column   flex-center  p-4 w-100">
          {!errors.htmlFeedUrl && htmlFeedUrl !== '' ? (
            <div className="d-flex flex-column flex-center w-100 h-100">
              {previewDocumentIsFetching ? (
                <Spinner role="status" animation="border" />
              ) : null}
              <iframe
                title={feedName}
                src={htmlFeedUrl}
                width="100%"
                height={`${previewHeight}px`}
                onLoad={handleOnIFrameLoaded}
                frameBorder="0"
                marginHeight={0}
                marginWidth={0}
                style={{ padding: '4px' }}
              />
            </div>
          ) : (
            <div className="d-flex flex-column flex-center w-100">
              <img
                alt="No items found"
                src="/media/illustrations/sketchy-1/5.png"
                className="d-flex flex-column align-self-center w-25"
              />
              <div className="fs-1">{t('apps.html5.noPreview')}</div>
              <div className="fs-6">{t('apps.html5.webPageUrl')}</div>
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default EditFeedModal;
