import SignedAsActions from '../../actions/signedAs/signedAsActions.enum';
import ReduxAction from '../../actions/reduxAction.interface';
import SignedAsInterface from './signedAs.interface';

const initialState: SignedAsInterface = {
  id: '',
  name: '',
  signed: false,
};

export default function userDataReducer(
  state = initialState,
  action: ReduxAction<SignedAsActions>,
) {
  switch (action.type) {
    case SignedAsActions.SIGNED_AS_USER:
      return {
        id: action.payload?.id,
        name: action.payload?.name,
        signed: true,
      };
    case SignedAsActions.LOGOUT_AS_USER:
      return initialState;

    default:
      return state;
  }
}
