import React from 'react';
import clsx from 'clsx';
import { KTSVG } from '../../lib/metronic/helpers';

interface SearchProps {
  searchValue: string;
  onChange?: (value: string) => void;
  className?: string;
  wrapperClassName?: string;
  placeholder?: string;
}

interface DefaultProps extends SearchProps {
  onChange: Required<SearchProps>['onChange'];
}

const Search = ({
  onChange,
  className,
  wrapperClassName,
  placeholder,
  searchValue,
}: DefaultProps) => {
  const handleChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(value);
  };

  return (
    <div
      className={clsx(
        'd-flex align-items-center position-relative my-1',
        wrapperClassName,
      )}
    >
      <KTSVG
        path="/media/icons/duotune/general/gen021.svg"
        className="svg-icon-1 position-absolute ms-6"
      />
      <input
        value={searchValue}
        onChange={handleChange}
        className={clsx(
          'form-control form-control-solid w-md-250px w-200px ps-14',
          className,
        )}
        placeholder={placeholder}
        type="text"
      />
    </div>
  );
};

Search.defaultProps = {
  onChange: () => {},
  placeholder: 'Search',
};

export default Search;
