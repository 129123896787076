import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import SelectAsync, {
  SelectedValue,
} from '../../components/Select/SelectAsync';
import { getComparatorBy } from '../../utils/common';
import useDidUpdate from '../../utils/hooks/useDidUpdate';

/**
 * Retrieves the select options asynchronously
 * @param search
 */
const loadOptions = async (search: string): Promise<SelectedValue[]> => {
  const timezones = await api.timezones.getTimezones({ search });

  return timezones.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

const SelectTimezoneId = ({
  id,
  onChange,
  initialValue,
  isDisabled,
  selectedTimeZoneId,
  observeInitialValue,
}: {
  id?: string;
  onChange: (selectedValue: SelectedValue) => void;
  initialValue?: string;
  isDisabled?: boolean;
  selectedTimeZoneId?: string;
  observeInitialValue?: boolean;
}) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<SelectedValue>();

  // useEffect(() => {

  // }, []);

  const handleInitialValueUpdate = () => {
    if (!initialValue) return;

    setSelectedValue({
      label: `${t('common.loading')}...`,
      value: initialValue,
    });

    setTimeout(async () => {
      const { name } = selectedTimeZoneId
        ? await api.timezones.getTimezone(selectedTimeZoneId)
        : await api.timezones.getTimezone(initialValue);

      setSelectedValue({ label: name, value: selectedTimeZoneId });
    });
  };
  useEffect(() => {
    handleInitialValueUpdate();
  }, []);

  useDidUpdate(() => {
    if (observeInitialValue) {
      handleInitialValueUpdate();
    }
  }, [initialValue]);

  const handleOptions = async (search: string) => {
    const timezones = await loadOptions(search);
    if (selectedTimeZoneId) {
      const timezone = timezones.filter(
        ({ value }) => value === selectedTimeZoneId,
      );
      if (timezone.length) {
        setSelectedValue({
          label: timezone[0].label,
          value: timezone[0].value,
        });
      }
    }
    return timezones;
  };

  useEffect(() => {}, [selectedTimeZoneId]);

  return (
    <SelectAsync
      id={id}
      initialValue={selectedValue}
      onChange={onChange}
      loadOptions={handleOptions}
      isDisabled={isDisabled}
    />
  );
};

SelectTimezoneId.defaultProps = {
  initialValue: null,
};

export const MemoizedSelectTimezoneId = memo(
  SelectTimezoneId,
  getComparatorBy(['id', 'initialValue', 'isDisabled']),
);

export default SelectTimezoneId;
