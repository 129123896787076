// import GetLocationsResponseData from '../../endpoints/locations/interfaces/getLocationsResponseData.interface';

export default {
  locations: [
    { id: '1', name: 'first' },
    { id: '2', name: 'second' },
    { id: '3', name: 'third' },
    { id: '4', name: 'foo' },
    { id: '5', name: 'bar' },
  ],
} as any;
