import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../../Modal';
import SelectUsers from '../../../containers/DeviceGroupInfo/SelectNotAssignedUsers';
import { SelectedValue } from '../../Select/SelectAsync';
import FiltersLocationDropdown from '../../Devices/FiltersLocationDropdown';
import validation from '../../../utils/validation';
import { assignUserValidation } from '../../../validation/users';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../FormErrorMessage';
import UserAssignmentTarget from '../../../interfaces/user/userAssignmentTarget.interface';

interface FormInputs {
  users: SelectedValue[];
}

export default ({
  children,
  onSubmit,
  userAssignmentTarget,
}: {
  children?: React.ReactNode;
  onSubmit?: (data: SelectedValue[]) => void;
  userAssignmentTarget?: UserAssignmentTarget;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    clearErrors,
  } = useForm<FormInputs>({
    resolver: yupResolver(
      validation.object(assignUserValidation(t)).required(),
    ),
    defaultValues: {
      users: [],
    },
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [users, setUsers] = useStateWithHookForm<FormInputs, SelectedValue[]>(
    { setValue, trigger, name: 'users' },
    [],
  );
  const handleClose = () => setIsModalVisible(false);

  const handleSubmitData = () => {
    if (onSubmit) {
      onSubmit(users);
    }

    handleClose();
  };

  const open = () => {
    clearErrors();
    setIsModalVisible(true);
  };

  // empty the select value when the component unmounts(on modal close)
  React.useEffect(() => {
    if (isModalVisible) {
      return () => setUsers([]);
    }
    // sould return something or eslint would throw an issue
    return undefined;
  }, [isModalVisible]);

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('users.add_new_user')}
        widthDefaultSize={false}
        show={isModalVisible}
        onHide={handleClose}
        bodyClassName="py-5"
        footerContent={
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(handleSubmitData)}
          >
            {t('users.add_user')}
          </button>
        }
      >
        <>
          <div className="d-flex justify-content-end mb-5">
            <FiltersLocationDropdown className="me-2" />
          </div>
          <div>
            <span className="col-form-label text-lg-end">
              {t('users.users')}
            </span>
            <SelectUsers
              userAssignmentTarget={userAssignmentTarget}
              initialValue={users}
              onChange={setUsers}
            />
            <FormErrorMessage name="users" errors={errors} />
          </div>
        </>
      </Modal>
    </>
  );
};
