/* eslint-disable no-extra-bind */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import account from '../../../api/endpoints/account';
import IV_VIDEO_THUMBNAIL from '../../../assets/images/iv_vid_thumbnail.png';
import { getUserData } from '../../../store/actions/userData';
import GlobalState from '../../../store/reducers/globalState.interface';

const WelcomeVideo = () => {
  const dispatch = useDispatch();
  const vidRef = React.useRef<HTMLVideoElement>(null);
  const isTrial = useSelector((state: GlobalState) => {
    return state.userData?.user?.isTrial;
  });
  const watchedWelcomeVideoBefore = useSelector((state: GlobalState) => {
    return state.userData?.user?.onboarding?.watchedWelcomeVideo;
  });

  const [haveWeSentARequestToServerYet, setHaveWeSentARequestToServerYet] =
    useState(false);

  const [
    doWeNeedToSendThatUserWatchedThis,
    setDoWeNeedToSendThatUserWatchedThis,
  ] = useState(false);

  useEffect(() => {
    if (
      isTrial &&
      !watchedWelcomeVideoBefore &&
      !haveWeSentARequestToServerYet
    ) {
      setDoWeNeedToSendThatUserWatchedThis(true);
    } else if (doWeNeedToSendThatUserWatchedThis) {
      setDoWeNeedToSendThatUserWatchedThis(false);
    }
  }, [isTrial, watchedWelcomeVideoBefore]);

  const sendThatWeDoneWatchedThisVideo = () => {
    (async () => {
      await account.updateOnboardingWatchedWelcomeVideo();
      dispatch(getUserData());
    })();
  };

  useEffect(() => {
    if (doWeNeedToSendThatUserWatchedThis) {
      if (vidRef.current) {
        vidRef.current.ontimeupdate = (() => {
          if (doWeNeedToSendThatUserWatchedThis) {
            if (
              vidRef.current?.currentTime &&
              vidRef.current?.currentTime > 60
            ) {
              sendThatWeDoneWatchedThisVideo();
              setHaveWeSentARequestToServerYet(true);
              vidRef.current.ontimeupdate = null;
            }
          }
        }).bind(this);
      }
    } else if (vidRef.current && vidRef.current.ontimeupdate) {
      vidRef.current.ontimeupdate = null;
    }
  }, [vidRef, doWeNeedToSendThatUserWatchedThis]);

  return (
    <video
      controls
      style={{ width: '100%', maxWidth: '500px', height: '100%' }}
      ref={vidRef}
      onContextMenu={(ev) => ev.preventDefault()}
    >
      <source
        src="https://ivcontents.s3-accelerate.amazonaws.com/welcomeVid.mp4"
        type="video/mp4"
      />
    </video>
  );
};

const RegularVideo = ({ vidUrl }: { vidUrl: string }) => {
  return (
    <video
      controls
      style={{ width: '100%', maxWidth: '500px', height: '100%' }}
      onContextMenu={(ev) => ev.preventDefault()}
    >
      <source src={vidUrl} type="video/mp4" />
    </video>
  );
};

const ResultComponent = ({
  tabId,
  isDefaultSelected,
  ContentComponent,
}: {
  tabId: string;
  isDefaultSelected?: boolean;
  ContentComponent: any;
}) => (
  <div
    className={`tab-pane fade ${isDefaultSelected ? ' show' : ''}`}
    id={tabId}
    role="tabpanel"
  >
    {ContentComponent}
  </div>
);

export default ({
  tabId,
  isDefaultSelected,
}: {
  tabId: string;
  isDefaultSelected?: boolean;
}) => {
  switch (tabId) {
    case 'tab1':
      return (
        <ResultComponent
          tabId={tabId}
          isDefaultSelected={isDefaultSelected}
          ContentComponent={<WelcomeVideo />}
        />
      );

    case 'tab2':
      return (
        <ResultComponent
          tabId={tabId}
          isDefaultSelected={isDefaultSelected}
          ContentComponent={
            <RegularVideo vidUrl="https://ivcontents.s3-accelerate.amazonaws.com/Trial Create Playlist - 3 October 2022.mp4" />
          }
        />
      );

    case 'tab3':
      return (
        <ResultComponent
          tabId={tabId}
          isDefaultSelected={isDefaultSelected}
          ContentComponent={
            <RegularVideo vidUrl="https://ivcontents.s3-accelerate.amazonaws.com/Trial - Upload Content - 3 October 2022.mp4" />
          }
        />
      );

    case 'tab4':
      return (
        <ResultComponent
          tabId={tabId}
          isDefaultSelected={isDefaultSelected}
          ContentComponent={
            <RegularVideo vidUrl="https://ivcontents.s3-accelerate.amazonaws.com/Visit Integration Page - 3 October 2022.mp4" />
          }
        />
      );

    case 'tab5':
      return (
        <ResultComponent
          tabId={tabId}
          isDefaultSelected={isDefaultSelected}
          ContentComponent={
            <RegularVideo vidUrl="https://ivcontents.s3-accelerate.amazonaws.com/Add Integration - 3 October 2022.mp4" />
          }
        />
      );

    case 'tab6':
      return (
        <ResultComponent
          tabId={tabId}
          isDefaultSelected={isDefaultSelected}
          ContentComponent={
            <RegularVideo vidUrl="https://ivcontents.s3-accelerate.amazonaws.com/Preview your Device - 3 October 2022.mp4" />
          }
        />
      );

    default:
      return (
        <div
          className={`tab-pane fade ${isDefaultSelected ? ' show' : ''}`}
          id={tabId}
          role="tabpanel"
        >
          <img
            src={IV_VIDEO_THUMBNAIL}
            alt="Welcome to the world of digital signage"
            style={{ width: '100%', maxWidth: '500px', maxHeight: '281.25px' }}
            onContextMenu={(ev) => ev.preventDefault()}
          />
        </div>
      );
  }

  // return (
  //   <div
  //     className={`tab-pane fade ${isDefaultSelected ? ' show' : ''}`}
  //     id={tabId}
  //     role="tabpanel"
  //   >
  //     <iframe
  //       width="490"
  //       height="300"
  //       src="https://www.youtube.com/embed/bQivsR1kUSU"
  //       title="YouTube video player"
  //       frameBorder="0"
  //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //       allowFullScreen
  //     />
  //   </div>
  // );
};
