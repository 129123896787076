import React, { useEffect } from 'react';
import { TagsTarget } from '../../api/endpoints/tags/enums/tagsTarget.enum';
import api from '../../api';
import CreatableAsyncSelect, {
  SelectedValue,
} from '../../components/Select/CreatableAsyncSelect';

const SelectTags = ({
  onChange,
  initialValue,
  id,
  target,
}: {
  onChange: (selectedValue: SelectedValue[]) => void;
  initialValue?: SelectedValue[];
  id?: string;
  target?: TagsTarget;
}) => {
  const loadOptions = async (search?: string) => {
    let items;
    items = await api.tags.getTagsList({ target });
    if (search) {
      items = items.filter((item) => item?.includes(search));
    }
    return items.map((value) => ({
      value,
      label: value,
    }));
  };

  useEffect(() => {
    loadOptions();
  }, []);

  // const promiseOptions = (inputValue: string) =>
  //   new Promise<SelectedValue[]>((resolve) => {
  //     setTimeout(() => {
  //       resolve(loadOptions(inputValue));
  //     }, 1000);
  //   });

  return (
    <CreatableAsyncSelect
      id={id}
      onChange={onChange}
      initialValue={initialValue}
      loadOptions={loadOptions}
      isClearable
      isMulti
    />
  );
};

SelectTags.defaultProps = {
  initialValue: [],
};

export default SelectTags;
