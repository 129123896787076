import React from 'react';
import { useTranslation } from 'react-i18next';
import AddDevicesGroupSteps from '../../../../enums/steps/addDevicesGroupSteps.enum';
import StepsTitle from '../../../WithModesModal/StepsTitle';

export default ({ currentStep }: { currentStep: AddDevicesGroupSteps }) => {
  const { t } = useTranslation();

  const steps = {
    [AddDevicesGroupSteps.Info]: t('devices_groups.create_group.group_info'),
    [AddDevicesGroupSteps.Assign]: t(
      'devices_groups.create_group.devices_and_users',
    ),
  } as const;

  return <StepsTitle currentStep={currentStep} steps={steps} />;
};
