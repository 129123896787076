import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import Search from '../../components/Search/QueryParamSearch';
import Pagination from '../../components/Pagination/QueryParamPagination';
import LibraryCategoryCard from '../../components/Libraries/LibraryCategory';
import { LibraryCategoryType } from '../../enums/library/libraryCategoryType.enum';
import PaginationQueryParam from '../../enums/queryParams/paginationQueryParam.enum';
import LibraryCategoriesQueryParam from '../../api/endpoints/libraryCategories/enums/libraryCategoriesQueryParam.enum';
import LibraryCategory from '../../interfaces/libraryCategory.interface';
import useSyncWithSearch from '../../utils/hooks/useSyncWithSearch';
import { readAllFromQueryParams } from '../../utils/queryParams';
import WithSpinner from '../../components/WithSpinner';
import Card from '../../components/Card';
import api from '../../api';
import GlobalState from '../../store/reducers/globalState.interface';
import { UserRole } from '../../enums/userRole.enum';

import CreateLibraryCategory from '../../containers/AdminPanel/Library';
import {
  clearPlaceHolder,
  clearQueryParams,
} from '../../store/actions/breadcrumbs';
import Tooltip from '../../components/Tooltip';
import TalkToSales from '../../components/Trial/TalkToSales';

const pageSize = 8;
const preloaderHeight = 489;
const inactiveBtnStyles = 'btn-white text-primary';

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isTrial = useSelector(
    (state: GlobalState) => state.userData.user.isTrial,
  );
  const [libraryCategories, setLibraryCategories] = useState<LibraryCategory[]>(
    [],
  );

  const roles = useSelector((state: GlobalState) => state.userData.user.roles);

  const [total, setTotal] = useState<number>(0);
  const [libraryCategoriesLoading, setLibraryCategoriesLoading] =
    useState<boolean>(true);

  const { search } = useLocation();

  const [categoriesType, setCategoriesType] =
    useSyncWithSearch<LibraryCategoryType>(
      LibraryCategoriesQueryParam.Type,
      LibraryCategoryType.Premium,
    );
  const [, setPageNumber] = useSyncWithSearch(PaginationQueryParam.Page);

  const onClickFree = () => {
    setCategoriesType(LibraryCategoryType.Free);
    setPageNumber('1');
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleClose = () => {
    setIsModalVisible(false);
  };

  const onClickPremium = () => {
    if (isTrial) {
      setIsModalVisible(true);
      return;
    }
    setCategoriesType(LibraryCategoryType.Premium);
    setPageNumber('1');
  };

  useEffect(() => {
    setLibraryCategoriesLoading(true);
    if (isTrial) {
      setCategoriesType(LibraryCategoryType.Free);
      setPageNumber('1');
      setLibraryCategoriesLoading(false);
    }
  }, [isTrial]);
  const getLibraryCategories = async (recentSearch: string) => {
    try {
      setLibraryCategoriesLoading(true);

      if (recentSearch !== '') {
        const { items, meta } = await api.libraryCategories.getCategories(
          readAllFromQueryParams(recentSearch),
        );

        setLibraryCategories(items);
        setTotal(meta.totalItems);
      }
    } finally {
      setLibraryCategoriesLoading(false);
    }
  };

  const onUpdate = () => {
    setLibraryCategoriesLoading(true);
    getLibraryCategories(search);
  };

  useEffect(() => {
    getLibraryCategories(search);
  }, [search, total]);
  useEffect(() => {
    dispatch(clearPlaceHolder());
    dispatch(clearQueryParams());
  }, []);
  return (
    <Card>
      <Card.Header>
        <Card.Header.Title>{t('library.indigo.categories')}</Card.Header.Title>
        <Card.Header.Toolbar>
          <Search
            queryField="search"
            pageNumberQueryField=""
            placeholder={t('common.search')}
            className="me-2"
          />

          <div className="d-flex align-items-center flex-wrap">
            {roles.includes(UserRole.SuperAdmin) && (
              <CreateLibraryCategory total={total} onUpdate={onUpdate}>
                <button type="button" className="btn btn-primary me-2">
                  {t('library.indigo.createCategory')}
                </button>
              </CreateLibraryCategory>
            )}
            <div className="btn-group me-4">
              <TalkToSales
                handleClose={handleClose}
                isModalVisible={isModalVisible}
              >
                <Tooltip text={t('common.premiumTooltip')} hide={!isTrial}>
                  <button
                    type="button"
                    onClick={onClickPremium}
                    className={clsx(
                      'btn border border-primary',
                      categoriesType === LibraryCategoryType.Premium
                        ? 'btn-primary'
                        : inactiveBtnStyles,
                    )}
                  >
                    {t('common.premium')}{' '}
                    {isTrial && <i className="bi bi-lock-fill" />}
                  </button>
                </Tooltip>
              </TalkToSales>

              <button
                type="button"
                onClick={onClickFree}
                className={clsx(
                  'btn border border-primary',
                  categoriesType === LibraryCategoryType.Free
                    ? 'btn-primary'
                    : inactiveBtnStyles,
                )}
              >
                {t('common.free')}
              </button>
            </div>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <WithSpinner
          isLoading={libraryCategoriesLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          size="md"
        >
          <div className="row no-margin">
            {libraryCategories.map((category) => (
              <LibraryCategoryCard
                category={category}
                total={total}
                onUpdate={onUpdate}
              />
            ))}
          </div>
        </WithSpinner>
        <Pagination
          total={total}
          pageSize={pageSize}
          pageNumberQueryField={PaginationQueryParam.Page}
          pageSizeQueryField={PaginationQueryParam.Limit}
          className="mt-3 mb-5"
        />
      </Card.Body>
    </Card>
  );
};
