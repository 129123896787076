import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../../../constants/api';
import ConversionJob from '../../../interfaces/conversionJob.interface';
import ConversionJobsRoutes from './enums/conversionJobsRoutes.enum';
import UpdateConversionJobRequestData from './interfaces/updateConversionJobRequestData.interface';
import CreateConversionJobRequestData from './interfaces/createConversionJobRequestData.interface';
import { createFormData } from '../../../utils/formData';

export default {
  /**
   * Retrieves the conversion job by id
   * @param id
   */
  getConversionJob: async (id: number): Promise<ConversionJob> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${ConversionJobsRoutes.ConversionJobs}/${id}`,
    });

    return data;
  },

  /**
   * Retrieves conversion jobs by playlist id
   * @param id
   * @param limit
   * @param excludeCompleted
   */
  getPlaylistConversionJobs: async (
    id: string,
    limit: number = 5,
    excludeCompleted: boolean = true,
    userId: string | undefined = undefined,
  ): Promise<ConversionJob[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${ConversionJobsRoutes.ConversionJobs}/playlists/${id}?excludeCompleted=${excludeCompleted}&limit=${limit}&userId=${userId}`,
      throwError: true,
    } as AxiosRequestConfig);

    return data;
  },

  /**
   * Retrieves conversion jobs by playlist id
   * @param limit
   * @param excludeCompleted
   */
  getFilesConversionJobs: async (
    limit: number = 5,
    excludeCompleted: boolean = true,
  ): Promise<ConversionJob[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${ConversionJobsRoutes.ConversionJobs}/file/jobs?excludeCompleted=${excludeCompleted}&limit=${limit}`,
    } as AxiosRequestConfig);

    return data;
  },

  /**
   * Creates a new conversion job
   * @param requestData
   */
  createConversionJob: async (
    requestData: CreateConversionJobRequestData,
  ): Promise<ConversionJob> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${ConversionJobsRoutes.ConversionJobs}/create`,
      data: createFormData(requestData, true),
    });

    return data;
  },

  /**
   * Updates the conversion job data
   * @param id
   * @param requestData
   */
  updateConversionJob: async (
    id: number,
    requestData: UpdateConversionJobRequestData,
  ): Promise<ConversionJob> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${ConversionJobsRoutes.ConversionJobs}/${id}`,
      data: createFormData(requestData, true),
    });

    return data;
  },

  /**
   * Cancel and cleanup the conversion job
   * @param id
   */
  cancelConversionJob: async (id: number): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${ConversionJobsRoutes.ConversionJobs}/${id}`,
    });
  },

  /**
   * Create conversion job free single content
   * @param id
   */
  createConversionJobSingleContent: async (
    requestData: CreateConversionJobRequestData,
    topicId: string,
  ): Promise<{ type: string; data: Buffer }[]> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${ConversionJobsRoutes.ConversionJobs}/library/conversion/${topicId}`,
      data: createFormData(requestData, true),
    });

    return data;
  },
};
