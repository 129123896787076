import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FieldErrors } from 'react-hook-form';
import SelectLicenseType from '../../../containers/SelectLicenseType';
import { billingRateTranslation } from '../../../constants/translation/billingRate';
import FormErrorMessage from '../../FormErrorMessage';
import DatePicker from '../../DatePicker';
import CheckBox from '../../CheckBox';
import Select from '../../Select/RegularSelect';
import BillingRate from '../../../enums/licensing/billingRate.enum';
import LicenseTerm from '../../../enums/licensing/licenseTerm.enum';
import Device from '../../../interfaces/devices/device.interface';
import commonStyles from '../../../styles/common.module.scss';

export interface LicensingFormInputs {
  licenseTypeId?: string;
  startDate?: Date;
  expireDate?: Date;
  libraryAccessStartDate?: Date;
  libraryAccessExpireDate?: Date;
  isActive?: boolean;
  isTrial?: boolean;
  billingRate?: string;
  licenseTerm?: string;
  isLibraryTrial?: boolean;
  isLibrary?: boolean;
}

export interface LicensingFormSetters {
  setIsActive: (value: boolean) => void;
  setIsTrial: (value: boolean) => void;
  setLicenseType: (value?: string) => void;
  setStartDate: (value: Date) => void;
  setEndDate: (value: Date) => void;
  setBillingRate: (value: string) => void;
  setIsLibrary: (value: boolean) => void;
  setLicenseTerm: (value: string) => void;
  setIsLibraryTrial: (value: boolean) => void;
  setLibraryStartDate: (value: Date) => void;
  setLibraryEndDate: (value: Date) => void;
}

export default ({
  errors,
  fieldsConfig: {
    isActive,
    setIsActive,
    isTrial,
    setIsTrial,
    licenseTypeId,
    setLicenseType,
    startDate,
    setStartDate,
    expireDate,
    setEndDate,
    billingRate,
    setBillingRate,
    isLibrary,
    setIsLibrary,
    licenseTerm,
    setLicenseTerm,
    isLibraryTrial,
    setIsLibraryTrial,
    libraryAccessStartDate,
    setLibraryStartDate,
    libraryAccessExpireDate,
    setLibraryEndDate,
  },
  device,
}: {
  errors: FieldErrors<LicensingFormInputs>;
  fieldsConfig: LicensingFormInputs & LicensingFormSetters;
  device?: Device;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {device && (
        <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 justify-content-center mt-3">
          <div className="d-flex align-items-center me-5 text-gray-700 fw-bold">
            {t('devices.device_name')}: {device?.name}
          </div>
          <div className="d-flex align-items-center me-5 text-gray-700 fw-bold">
            {t('common.location')}: {device?.location?.name}
          </div>
          <div className="d-flex align-items-center text-gray-700 fw-bold">
            {t('common.company')}: {device?.company?.name}
          </div>
        </div>
      )}

      <div className="d-flex justify-content-center">
        <div className="d-flex align-items-center me-6">
          <label htmlFor="isActive" className="col-form-label text-lg-end me-3">
            {t('devices.licensing.active')}
          </label>
          <CheckBox
            name="isActive"
            onChange={() => setIsActive(!isActive)}
            checked={isActive}
            className="mt-1"
          />
        </div>

        <div
          className={clsx(
            'd-flex align-items-center me-6',
            !isActive && commonStyles.disabled,
          )}
        >
          <label htmlFor="isTrial" className="col-form-label text-lg-end me-3">
            {t('devices.licensing.trial')}
          </label>
          <CheckBox
            name="isTrial"
            onChange={() => setIsTrial(!isTrial)}
            checked={isTrial}
            className="mt-1"
          />
        </div>
      </div>

      <div className={clsx(!isActive && commonStyles.disabled)}>
        <div className="mb-5">
          <label htmlFor="licenseTypeId" className="col-form-label text-lg-end">
            {t('devices.licensing.license_type')}
          </label>
          <SelectLicenseType
            id="licenseTypeId"
            initialValue={licenseTypeId}
            onChange={({ value }) => setLicenseType(value)}
          />
          <FormErrorMessage name="licenseTypeId" errors={errors} />
        </div>

        <div>
          <div className="fv-row mb-5">
            <label
              htmlFor="startDate"
              className="d-flex align-items-center fs-6"
            >
              <span>{t('playlists.start_date')}</span>
            </label>
            <DatePicker
              name="startDate"
              value={startDate}
              placeholder="Select start date - time"
              onChange={(newDate) => setStartDate(newDate[0])}
              options={{
                enableTime: true,
                defaultHour: 0,
              }}
            />
            <FormErrorMessage name="startDate" errors={errors} />
          </div>
          <div className="fv-row mb-6">
            <label
              htmlFor="expireDate"
              className="d-flex align-items-center fs-6"
            >
              <span>{t('playlists.end_date')}</span>
            </label>
            <DatePicker
              name="expireDate"
              value={expireDate}
              placeholder="Select end date - time"
              onChange={(newDate) => setEndDate(newDate[0])}
              options={{
                enableTime: true,
                defaultHour: 23,
                defaultMinute: 59,
              }}
            />
            <FormErrorMessage name="expireDate" errors={errors} />
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center mb-7">
          <div className="me-7 min-w-150px">
            <label
              htmlFor="billingRate"
              className="col-form-label text-muted text-lg-start"
            >
              {t('devices.licensing.billing_rate')}
            </label>
            <Select<BillingRate>
              id="billingRate"
              defaultValue={
                billingRate
                  ? {
                      value: billingRate,
                      label: t(billingRateTranslation[billingRate]),
                    }
                  : undefined
              }
              onSelect={(selectedValue) =>
                setBillingRate(selectedValue as BillingRate)
              }
              options={Object.values(BillingRate).map((value) => ({
                label: t(billingRateTranslation[value]),
                value,
              }))}
              observeDefaultValueChange
            />
          </div>

          <div className="min-w-150px">
            <label
              htmlFor="licenseTerm"
              className="col-form-label text-muted text-lg-start"
            >
              {t('devices.licensing.license_term')}
            </label>
            <Select<LicenseTerm>
              id="licenseTerm"
              defaultValue={
                licenseTerm
                  ? {
                      value: licenseTerm,
                      label: licenseTerm,
                    }
                  : undefined
              }
              onSelect={(selectedValue) =>
                setLicenseTerm(selectedValue as LicenseTerm)
              }
              options={Object.values(LicenseTerm).map((value) => ({
                label: value,
                value,
              }))}
              observeDefaultValueChange
            />
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center me-6 mb-1">
          <label
            htmlFor="isLibrary"
            className="col-form-label text-lg-end me-3"
          >
            {t('devices.licensing.library')}
          </label>
          <CheckBox
            name="isLibrary"
            onChange={() => setIsLibrary(!isLibrary)}
            checked={isLibrary}
            className="mt-1"
          />

          <div
            className={clsx(
              'd-flex align-items-center me-6',
              !isLibrary && commonStyles.disabled,
            )}
          >
            <label
              htmlFor="isLibraryTrial"
              className="col-form-label text-lg-end me-3"
            >
              {t('devices.licensing.trial')}
            </label>
            <CheckBox
              name="isLibraryTrial"
              onChange={() => setIsLibraryTrial(!isLibraryTrial)}
              checked={isLibraryTrial}
              className="mt-1"
            />
          </div>
        </div>

        <div className={clsx('mb-2 mt-2', !isLibrary && commonStyles.disabled)}>
          <div className="fv-row mb-5">
            <label
              htmlFor="libraryAccessStartDate"
              className="d-flex align-items-center fs-6"
            >
              <span>{t('playlists.start_date')}</span>
            </label>
            <DatePicker
              name="libraryAccessStartDate"
              value={libraryAccessStartDate}
              placeholder="Select start date - time"
              onChange={(newDate) => setLibraryStartDate(newDate[0])}
              options={{
                enableTime: true,
                defaultHour: 0,
              }}
            />
            <FormErrorMessage name="libraryAccessStartDate" errors={errors} />
          </div>
          <div className="fv-row mb-10">
            <label
              htmlFor="libraryAccessExpireDate"
              className="d-flex align-items-center fs-6"
            >
              <span>{t('playlists.end_date')}</span>
            </label>
            <DatePicker
              name="libraryAccessExpireDate"
              value={libraryAccessExpireDate}
              placeholder="Select end date - time"
              onChange={(newDate) => setLibraryEndDate(newDate[0])}
              options={{
                enableTime: true,
                defaultHour: 23,
                defaultMinute: 59,
              }}
            />
            <FormErrorMessage name="libraryAccessExpireDate" errors={errors} />
          </div>
        </div>
      </div>
    </>
  );
};
