import axios from 'axios';
import { API_URL } from '../../../constants/api';
import UsersLogsRoutes from './enmus/userLogsRoutes.enum';
import GetUsersLogsRequestData from './interfaces/getUsersLogsRequestData.interface';
// import { sanitizeQueryParams } from '../../../utils/queryParams';
import GetUsersLogsResponseData from './interfaces/getUsersLogsResponseData.interface';
import GetLogsMetaByLogIdResponseData from './interfaces/getUserLogMetaResponse.interface';
import GetUserLogMetaRequestData from './interfaces/GetUserLogMetaRequest.interface';

export default {
  /**
   * get users logs / activities
   */

  getUsersLog: async (
    requestData: GetUsersLogsRequestData,
  ): Promise<GetUsersLogsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersLogsRoutes.usersLogs}`,
      params: requestData,
    });

    return data;
  },
  getUsersLogsReports: async (
    requestData: GetUsersLogsRequestData,
  ): Promise<GetUsersLogsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersLogsRoutes.usersLogsSuperAdmin}`,
      params: requestData,
    });

    return data;
  },

  getLogsMetaByLogId: async (
    requestData: GetUserLogMetaRequestData,
  ): Promise<GetLogsMetaByLogIdResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersLogsRoutes.usersLogs}/get-log-by-id`,
      params: requestData,
    });

    return data;
  },
};
