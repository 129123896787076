import React, { memo } from 'react';
import { isEqual } from 'lodash';
import api from '../../../api';
import SelectAsync, { SelectedValue } from '../../Select/SelectAsync';

/**
 * Retrieves the select roles asynchronously
 */
const loadOptions = async () => {
  const customUserRole = ['ADMIN', 'MANAGER', 'EMPLOYEE'];
  const roles = await api.roles.getRoles();

  return roles
    .map(({ id, name }) => ({
      value: id,
      label: name,
    }))
    .filter(({ label }) => customUserRole.includes(label));
};

const SelectRoles = ({
  id,
  onChange,
  initialValue,
  isDisabled,
}: {
  id?: string;
  onChange: (selectedValue: SelectedValue) => void;
  initialValue?: SelectedValue;
  isDisabled?: boolean;
}) => {
  return (
    <SelectAsync
      id={id}
      initialValue={initialValue}
      onChange={onChange}
      loadOptions={loadOptions}
      isDisabled={isDisabled}
    />
  );
};

SelectRoles.defaultProps = {
  initialValue: {},
};

export default memo(
  SelectRoles,
  (prev, next) =>
    (['id', 'isDisabled'] as const).every((key) => prev[key] === next[key]) &&
    isEqual(prev.initialValue, next.initialValue),
);
