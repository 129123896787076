import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectNavTab from '../../../NavTabs/SelectNavTab';
import CopyContentSteps from '../../../../enums/steps/copyContentSteps.enum';

export default ({ currentStep }: { currentStep: CopyContentSteps }) => {
  const { t } = useTranslation();

  return (
    <SelectNavTab<CopyContentSteps>
      isSelectable={false}
      withUnderline={false}
      className="mb-5 justify-content-center"
      tabs={[
        {
          key: CopyContentSteps.DevicesAndGroups,
          isActive: currentStep === CopyContentSteps.DevicesAndGroups,
          text: t('contents.create_content.steps.devices'),
        },
        {
          key: CopyContentSteps.Playlists,
          isActive: currentStep === CopyContentSteps.Playlists,
          text: t('contents.create_content.steps.playlists'),
        },
      ]}
    />
  );
};
