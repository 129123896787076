enum UserDataActions {
  GET_USER_DATA = 'GET_USER_DATA',
  GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS',
  UPDATE_USER_DATA_REQUEST = 'UPDATE_USER_DATA_REQUEST',
  UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS',
  SET_USER_LOADING = 'SET_USER_LOADING',
  UPDATE_USER_INTEGRATION_REQUEST = 'UPDATE_USER_INTEGRATION_REQUEST',
  UPDATE_USER_INTEGRATION_SUCCESS = 'UPDATE_USER_INTEGRATION_SUCCESS',
  SET_TRIAL_EXPIRE_DAYS = 'SET_TRIAL_EXPIRE_DAYS',
  SET_HUBSPOT_LOADED = 'SET_HUBSPOT_LOADED',
}

export default UserDataActions;
