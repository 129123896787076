import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import validation from '../../../utils/validation';
import FormErrorMessage from '../../FormErrorMessage';
import { prepareHookFromState } from '../../../utils/hooks/useStateWithHookForm';
import Company from '../../../interfaces/company.interface';
import { createCompanyValidation } from '../../../validation/company';
import TextInput from '../../TextInput';
import WithSpinner from '../../WithSpinner';
import Modal from '../../Modal';
import SelectCountry from '../../../containers/SelectCountry';
import SelectState from '../../../containers/SelectState';
import SelectIndustry from '../../../containers/SelectIndustry';
import SelectCounty from '../../../containers/SelectCounty';
import style from './createCompany.module.scss';
import { SelectedValue } from '../../Select/SelectAsync';
import County from '../../../interfaces/county.interface';
import GlobalState from '../../../store/reducers/globalState.interface';
import CountryCode from '../../../enums/countryCode.enum';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

interface FormInputs {
  name: string;
  address: string;
  state: string;
  city: string;
  zip: string;
  userId: string;
  description: string;
  createUser: boolean;
  countryId: string;
  industryId: string;
  countyIds: SelectedValue[];
}

const CreateUserCompany = ({
  company,
  isLoading,
  onSubmit,
}: {
  company?: Company;
  isLoading: boolean;
  onSubmit?: (data: any) => void;
}) => {
  const { t } = useTranslation();

  const defaultValues: Partial<FormInputs> = {
    name: company?.name ?? '',
    userId: company?.owner.id ?? '',
    description: company?.description ?? '',
    address: company?.address ?? '',
    state: company?.state?.id ?? '',
    city: company?.city ?? '',
    zip: company?.zip ?? '',
    countryId: company?.country?.id ?? '',
    industryId: company?.industry?.id ?? '',
    countyIds:
      company?.counties?.map((county: County) => ({
        label: county.name,
        value: county.id,
      })) ?? [],
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      validation.object(createCompanyValidation(t)).required(),
    ),
    defaultValues,
  });

  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const { id: userId } = useSelector(
    (state: GlobalState) => state.userData.user,
  );

  const [name, setName] = useFs<FormInputs['name']>('name');
  const [address, setAddress] = useFs<FormInputs['address']>('address');
  const [state, setState] = useFs<FormInputs['state'] | undefined>('state');
  const [city, setCity] = useFs<FormInputs['city'] | undefined>('city');
  const [zip, setZip] = useFs<FormInputs['zip']>('zip');
  const [countryId, setCountryId] = useFs<FormInputs['countryId'] | undefined>(
    'countryId',
  );
  const [countyIds, setCountyIds] = useFs<FormInputs['countyIds']>('countyIds');
  const [industryId, setIndustryId] = useFs<
    FormInputs['industryId'] | undefined
  >('industryId');
  const [description, setDescription] =
    useFs<FormInputs['description']>('description');

  const handleSubmitData = async () => {
    try {
      if (onSubmit) {
        const counties = countyIds.length
          ? countyIds.map((county) => county.value)
          : '';
        onSubmit({
          name,
          address,
          stateId: state,
          city,
          countryId,
          industryId,
          zip,
          userId,
          description,
          counties,
        });
      }
    } catch (e) {
      // error handling
    }
  };

  return (
    <>
      <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <div className="row">
            <div className="col-lg-12 col-sm-12">
              <div>
                <label className="fs-6 fw-bolder mb-2">
                  {t('companies.company_name')}
                </label>
                <TextInput
                  name="name"
                  id="weatherName"
                  placeholder={t('contents.type_here')}
                  className="border-secondary"
                  value={name}
                  onChange={setName}
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              <div>
                <label className="fs-6 fw-bolder mb-2">
                  {t('companies.address')}
                </label>
                <TextInput
                  name="address"
                  id="address"
                  placeholder={t('contents.type_here')}
                  className="border-secondary"
                  value={address}
                  onChange={setAddress}
                />
                <FormErrorMessage
                  name="address"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              <div>
                <div className="d-flex">
                  <div className="w-100">
                    <label className="fs-6 fw-bolder mb-2">
                      {t('companies.zipcode')}
                    </label>
                    <TextInput
                      name="zip"
                      id="zip"
                      placeholder={t('contents.type_here')}
                      className={clsx('border-secondary', style.inputHeight)}
                      value={zip}
                      onChange={setZip}
                    />
                    <FormErrorMessage
                      name="zip"
                      errors={errors}
                      className={errorMessageClassNames}
                    />
                  </div>
                  <div className="ms-2 w-100">
                    <label className="fs-6 fw-bolder mb-2">
                      {t('common.country')}
                    </label>
                    <SelectCountry
                      id="countryId"
                      onChange={(selectedCountry) => {
                        setCountryId(selectedCountry.value);
                        setState(undefined);
                        setCountyIds([]);
                      }}
                      initialValue={countryId}
                      isClearable
                      preselect={!!company}
                    />
                    <FormErrorMessage
                      name="countryId"
                      errors={errors}
                      className={errorMessageClassNames}
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex">
                  <div className="w-100">
                    <label className="fs-6 fw-bolder mb-2">
                      {t('companies.city')}
                    </label>
                    <TextInput
                      name="city"
                      id="city"
                      placeholder={t('contents.type_here')}
                      className={clsx('border-secondary', style.inputHeight)}
                      value={city}
                      onChange={setCity}
                    />
                    <FormErrorMessage
                      name="city"
                      errors={errors}
                      className={errorMessageClassNames}
                    />
                  </div>
                  {countryId !== CountryCode.Ca ? (
                    <div className="ms-2 w-100">
                      <label className="fs-6 fw-bolder mb-2">
                        {t('companies.state')}
                      </label>
                      <SelectState
                        id="state"
                        onChange={(selectedState) => {
                          setState(selectedState.value);
                        }}
                        initialValue={state}
                        countryId={countryId}
                        isClearable
                        showStateCode
                      />
                      <FormErrorMessage
                        name="stateId"
                        errors={errors}
                        className={errorMessageClassNames}
                      />
                    </div>
                  ) : (
                    <div className="ms-2 w-100">
                      <label className="fs-6 fw-bolder mb-2">
                        {t('companies.county')}
                      </label>
                      <SelectCounty
                        isSevereWeather
                        id="counties"
                        name="counties"
                        initialOption={countyIds}
                        onChange={
                          setCountyIds as (value: SelectedValue) => void
                        }
                        countryCode={countryId}
                        stateCode={state}
                        isMulti
                        isClearable
                      />
                      <FormErrorMessage
                        name="counties"
                        errors={errors}
                        className={errorMessageClassNames}
                      />
                    </div>
                  )}
                </div>
              </div>

              {countryId === CountryCode.Us && (
                <div>
                  <div className="d-flex">
                    <div className="w-100">
                      <label className="fs-6 fw-bolder mb-2">
                        {t('companies.county')}
                      </label>
                      <SelectCounty
                        isSevereWeather
                        id="counties"
                        name="counties"
                        initialOption={countyIds}
                        onChange={
                          setCountyIds as (value: SelectedValue) => void
                        }
                        countryCode={countryId}
                        stateCode={state}
                        isMulti
                        isClearable
                      />
                      <FormErrorMessage
                        name="counties"
                        errors={errors}
                        className={errorMessageClassNames}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div>
                <div className="d-flex">
                  <div className="w-100">
                    <label className="fs-6 fw-bolder mb-2">
                      {t('common.industry')}
                    </label>
                    <SelectIndustry
                      initialValue={industryId}
                      onChange={(selectedIndustry) => {
                        setIndustryId(selectedIndustry.value);
                      }}
                    />
                  </div>
                  <div className="ms-2 w-100">
                    <label className="fs-6 fw-bolder mb-2">
                      {t('companies.description')}
                    </label>
                    <TextInput
                      name="description"
                      id="weatherName"
                      placeholder={t('contents.type_here')}
                      className={clsx('border-secondary', style.inputHeight)}
                      value={description}
                      onChange={setDescription}
                    />
                    <FormErrorMessage
                      name="description"
                      errors={errors}
                      className={errorMessageClassNames}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                {company ? t('common.edit') : t('common.create')}
              </button>
            </div>
          </div>
        </form>
      </WithSpinner>
    </>
  );
};
export default CreateUserCompany;
