import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { readFromQueryString } from '../../../../utils/queryParams';
import ContentsQueryParam from '../../../../api/endpoints/contents/enums/contentsQueryParam.enum';
import TimeLinesEnum from '../../../../enums/timeLines.enum';
import { updateSearch } from '../../../../utils/browserHistory';
import ReactSelect from '../../../Select/RegularSelect';

export default ({
  className,
  onValueChange,
}: {
  className: string;
  onValueChange: () => void;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const timeLineValue = readFromQueryString<string>(
    search,
    ContentsQueryParam.TimeLine,
  );

  useEffect(() => {
    if (timeLineValue) return;

    updateSearch({
      [ContentsQueryParam.TimeLine]: TimeLinesEnum.Current,
    });
  }, []);

  const defaultTimeline = timeLineValue ?? TimeLinesEnum.Current;

  return (
    <div className={className}>
      <ReactSelect<TimeLinesEnum>
        id="timeline"
        placeholder="Timeline"
        observeDefaultValueChange={!!timeLineValue}
        isClearable
        defaultValue={[
          {
            label: t(`contents.timelines.${defaultTimeline}`),
            value: defaultTimeline,
          },
        ]}
        onSelect={(selectedTimeLine) => {
          updateSearch({
            [ContentsQueryParam.TimeLine]: selectedTimeLine,
          });
          onValueChange();
        }}
        options={Object.values(TimeLinesEnum).map((placementValue) => ({
          label: t(`contents.timelines.${placementValue}`),
          value: placementValue,
        }))}
      />
    </div>
  );
};
