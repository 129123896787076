import React, { ChangeEventHandler, FC, memo } from 'react';
import clsx from 'clsx';

type SwitchCheckboxProps = {
  checked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  labelText?: string;
  inputClassName?: string;
  name?: string;
  className?: string;
  disabled?: boolean;
};

const SwitchCheckbox: FC<SwitchCheckboxProps> = ({
  checked,
  onChange,
  labelText,
  inputClassName,
  name,
  className,
  disabled,
}) => {
  return (
    <label
      className={clsx(
        'form-check form-check-custom form-check-solid form-switch',
        className,
      )}
    >
      <input
        name={name}
        className={clsx('form-check-input', inputClassName)}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      {labelText && <span className="form-check-label fs-5">{labelText}</span>}
    </label>
  );
};

export default memo(SwitchCheckbox);
