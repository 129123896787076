import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Location from '../../../../interfaces/location.interface';
import WithDeleteConfirm from '../../../../components/WithDeleteConfirm';
import { handleError } from '../../../../api/interceptors/errors.interceptor';
import api from '../../../../api';
import useIsAdmin from '../../../../utils/hooks/useIsAdmin';

export default ({
  children,
  onUpdate,
  location,
}: {
  children: React.ReactNode;
  location: Location;
  onUpdate?: () => void;
}) => {
  const { t } = useTranslation();
  const [isAdmin] = useIsAdmin();

  const handleConfirm = async () => {
    try {
      if (isAdmin) {
        await api.locations.deleteAdminLocation(location.id);
      } else {
        await api.locations.deleteLocation(location.id);
      }

      if (onUpdate) {
        onUpdate();
      }

      toast.success(i18next.t<string>('locations.removed_message'));
    } catch (error: any) {
      if (error.response.status === 404) {
        toast.error(i18next.t<string>('locations.removal_error'));
      } else {
        handleError(error);
      }
    }
  };

  return (
    <WithDeleteConfirm
      body={`${t('common.delete_confirmation.title')} ${t(
        'common.delete_confirmation.location',
      )} "${location.name}"?`}
      handleConfirm={handleConfirm}
    >
      {children}
    </WithDeleteConfirm>
  );
};
