import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import TableRow from '../../DevicesGroups/GroupsTable/TableRow';
import Columns, { TableHeaderColumn } from '../../Table/Columns';
import DevicesGroupsSortField from '../../../api/endpoints/devicesGroups/enums/devicesGroupsSortField.enum';
import DevicesGroupsQueryParam from '../../../api/endpoints/devicesGroups/enums/devicesGroupsQueryParam.enum';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import DeviceGroup from '../../../interfaces/deviceGroup.interface';
import SortOrder from '../../../enums/sortOrder.enum';
import { SortField } from '../../../utils/sorting';
import WithSpinner from '../../WithSpinner';
import Pagination from '../../Pagination';
import Search from '../../Search';
import Table from '../../Table';
import useTableMultiSelect, {
  isAllSelected,
} from '../../../utils/hooks/useTableMultiSelect';

const preloaderHeight = 251;
const pageSize = 5;

export default ({
  devicesGroups,
  isLoading,
  total,
  onSearchDataChange,
  onSelectionChange,
  preselectedItems,
  showIcon = false,
  isPowerBiPublished,
}: {
  devicesGroups: DeviceGroup[];
  isLoading: boolean;
  total: number;
  onSearchDataChange: (searchData: { [key: string]: string }) => void;
  onSelectionChange: (selectedItems: string[]) => void;
  preselectedItems: string[];
  showIcon?: boolean;
  isPowerBiPublished?: boolean;
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [sortField, setSortField] = useState<SortField>({
    field: null,
    isInverted: false,
  });
  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(devicesGroups, 'id'),
    preselectedItems,
  );

  useEffect(() => {
    onSearchDataChange({
      [DevicesGroupsQueryParam.Search]: searchValue,
      [PaginationQueryParam.Limit]: String(pageSize),
      [PaginationQueryParam.Page]: String(pageNumber),
      [SortingQueryParam.SortBy]: sortField.field ?? '',
      [SortingQueryParam.SortOrder]: sortField.isInverted
        ? SortOrder.Ascending
        : SortOrder.Descending,
    });
  }, [searchValue, pageNumber, sortField]);

  useEffect(() => onSelectionChange(selectedItems), [selectedItems]);

  const columns: TableHeaderColumn<DevicesGroupsSortField>[] = [
    {
      name: t('devices_groups.device_group_name'),
      sortBy: DevicesGroupsSortField.Name,
    },
    { name: t('devices_groups.devices_name') },
    { name: t('devices_groups.users_name') },
    { name: t('devices_groups.playlists') },
    { name: t('devices_groups.creation_date') },
  ];

  const hasNonEnterpriseLicense = devicesGroups.some((deviceGroup) =>
    deviceGroup.licenses?.some((license) => license?.name !== 'Enterprise'),
  );
  const disabled = !!(isPowerBiPublished && hasNonEnterpriseLicense);

  return (
    <>
      <Search
        searchValue={searchValue}
        onChange={setSearchValue}
        placeholder={t('devices_groups.search_device_group')}
        wrapperClassName="mb-3"
      />
      <Table className="min-w-600px">
        <Table.Head>
          <Table.Head.Row>
            <Columns<DevicesGroupsSortField>
              columns={columns}
              config={{ sortField, setSortField, isSelectable: true, disabled }}
              onChange={selectAll}
              checked={isAllSelected(selectedItems, map(devicesGroups, 'id'))}
            />
          </Table.Head.Row>
        </Table.Head>
        <Table.Body
          isEmpty={!isLoading && !devicesGroups.length}
          emptyStateHeight={preloaderHeight}
          emptyStateChildren={t('devices_groups.no_one_group_found')}
        >
          <WithSpinner
            isLoading={isLoading}
            style={{ minHeight: `${preloaderHeight}px` }}
            isForTable
            size="md"
          >
            {devicesGroups.map((group) => (
              <TableRow
                showIcon={showIcon}
                key={group.id}
                group={group}
                isChecked={selectedItems.includes(group.id)}
                onCheck={setSelectedItem}
                config={{ skipActions: true, isSelectable: true }}
                isPowerBiPublished={isPowerBiPublished}
              />
            ))}
          </WithSpinner>
        </Table.Body>
      </Table>
      <Pagination
        total={total}
        pageSize={pageSize}
        pageNumber={pageNumber}
        onPageNumberUpdate={setPageNumber}
        className="mt-3"
      />
    </>
  );
};
