import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppType from '../../../enums/appType.enum';
import GlobalState from '../../../store/reducers/globalState.interface';
import Connection from '../Connection';
import { updateIntegration } from '../../../store/actions/userData';
import postUserIntegrationRequestData from '../../../api/endpoints/userData/interfaces/postUserIntegrationRequestData.interface';

export default () => {
  const dispatch = useDispatch();

  const user = useSelector((state: GlobalState) => state.userData.user);
  const { integrations } = user;
  const app = integrations?.find(
    (integrationApp) => integrationApp.appName === AppType.Canva,
  );

  const disconnectClicked = () => {
    const payload: postUserIntegrationRequestData = {
      appId: app?.appId ?? '',
      userId: user?.id ?? '',
    };
    dispatch(updateIntegration(payload));
  };

  return (
    <>
      <Connection isConnected={!!app} disconnectClicked={disconnectClicked} />
    </>
  );
};
