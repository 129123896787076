import React from 'react';
// import { useTranslation } from 'react-i18next';
import Table from '../../../Table';
import ShowMoreCell from '../../../ShowMoreCell';
import AppDetailsType from '../../../../interfaces/appDetails.interface';
// import User from '../../../../interfaces/user/user.interface';
// import { Contents } from '../../../../interfaces/library.interface';
// import Device from '../../../../interfaces/devices/device.interface';

const { Row } = Table.Body;
const { Cell } = Row;

const TableRow = ({ row }: { row: AppDetailsType }) => {
  console.log('row', row);
  return (
    <>
      <Row>
        <Cell isEmpty={!row.name}>
          <div>{row?.name}</div>
        </Cell>
        <Cell isEmpty={!row.owner?.assignedCompanies.length}>
          <ShowMoreCell
            itemNames={
              row?.owner?.assignedCompanies.map(
                (elem: { name: string }) => elem?.name,
              ) || []
            }
            title="Companies"
            widthDefaultSize
            bodyClassName="justify-content-center "
          >
            {row?.owner?.assignedCompanies.map((company: { name: string }) => (
              <span className="badge fs-7 py-3 px-7 fw-bold badge-light-primary mb-2 ms-2 d-flex justify-content-center">
                {company?.name}
              </span>
            ))}
          </ShowMoreCell>
        </Cell>
        <Cell isEmpty={!row.owner?.assignedLocations.length}>
          <ShowMoreCell
            itemNames={
              row?.owner?.assignedLocations.map(
                (elem: { name: string }) => elem?.name,
              ) || []
            }
            title="Locations"
            widthDefaultSize
            bodyClassName="justify-content-center "
          >
            {row?.owner?.assignedLocations.map((location: { name: string }) => (
              <span className="badge fs-7 py-3 px-7 fw-bold badge-light-primary mb-2 ms-2 d-flex justify-content-center">
                {location?.name}
              </span>
            ))}
          </ShowMoreCell>
        </Cell>
        <Cell isEmpty={!row.owner}>
          <div>{`${row?.owner?.firstName} ${row?.owner?.lastName}`}</div>
        </Cell>
        <Cell isEmpty={!Array(row?.contents).length}>
          <ShowMoreCell
            itemNames={row?.contents
              .filter((elem) => elem?.playlists && elem?.playlists.length > 0)
              .flatMap((elem) => elem?.playlists)
              .map(
                (elem: {
                  playlist: {
                    name: string;
                  };
                }) => elem?.playlist?.name,
              )}
            title="Playlists"
            widthDefaultSize
            bodyClassName="justify-content-center "
          >
            {row?.contents
              .filter((elem) => elem?.playlists && elem?.playlists.length > 0)
              .flatMap((elem) => elem?.playlists[0])
              .map(
                (elem: {
                  playlist: {
                    name: string;
                  };
                }) => elem?.playlist?.name,
              )
              .map((content) => (
                <span className="badge fs-7 py-3 px-7 fw-bold badge-light-primary mb-2 ms-2 d-flex justify-content-center">
                  {content}
                </span>
              ))}
          </ShowMoreCell>
        </Cell>
        <Cell isEmpty={!Array(row?.devices).length}>
          <ShowMoreCell
            itemNames={row?.devices.map((elem: { name: string }) => elem?.name)}
            title="Devices"
            widthDefaultSize
            bodyClassName="justify-content-center "
          >
            {row?.devices.map((device: { name: string }) => (
              <span className="badge fs-7 py-3 px-7 fw-bold badge-light-primary mb-2 ms-2 d-flex justify-content-center">
                {device?.name}
              </span>
            ))}
          </ShowMoreCell>
        </Cell>
      </Row>
    </>
  );
};

export default TableRow;
