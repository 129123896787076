/* eslint-disable func-names */
import { TFunction } from 'i18next';
import validation from '../utils/validation';

export const editPlaylistValidation = (t: TFunction) => ({
  name: validation.string().required(t('playlists.validation.name')),
  devices: validation.array().when('deviceGroupIds', {
    is: (deviceGroupIds: string[]) => !deviceGroupIds?.length,
    then: validation.array().min(0, t('playlists.validation.devices')),
    otherwise: validation.array(),
  }),
  deviceGroupIds: validation.array().when('devices', {
    is: (devices: string[]) => !devices.length,
    then: validation.array().min(0, t('playlists.validation.devices_groups')),
    otherwise: validation.array(),
  }),
  startDate: validation.date(),
  endDate: validation
    .date()
    .test(
      'is-greater-than-start-date',
      t('common.endDateIsGreaterThanStartDate'),
      function (endDate) {
        const { startDate } = this.parent;
        if (startDate && endDate) {
          return startDate && endDate && startDate < endDate;
        }
        return true;
      },
    ),
});

export const playlistValidation = (t: TFunction) => ({
  ...editPlaylistValidation(t),
});

export const assignPlaylistToGroupValidation = (t: TFunction) => ({
  playlists: validation.array().min(1, t('playlists.validation.playlists')),
});

export const bulkUpdatePlaylist = () => ({});
