import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { suspendUser } from '../../../store/actions/users';
import WithConfirm from '../../../components/WithConfirm';
import User from '../../../interfaces/user/user.interface';
import Tooltip from '../../../components/Tooltip';

export default ({
  user: { id, firstName, lastName },
  children,
  onSuspendUser,
}: {
  user: User;
  children?: React.ReactNode;
  onSuspendUser?: (userId: string) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (onSuspendUser) {
      onSuspendUser(id);
      return;
    }

    dispatch(suspendUser({ id }));
    toast.success(i18next.t<string>('users.suspended_message'));
  };

  return (
    <WithConfirm
      body={`${t(
        'users.suspend_confirmation.title',
      )} "${firstName} ${lastName}"?`}
      handleConfirm={handleConfirm}
      confirmButtonText={t('users.suspend_confirmation.confirm')}
      discardButtonText={t('users.suspend_confirmation.decline')}
    >
      {children ?? (
        <Tooltip text={t('common.suspend')}>
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-ban" />
          </span>
        </Tooltip>
      )}
    </WithConfirm>
  );
};
