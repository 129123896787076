import { debouncedUpdateSearch } from './browserHistory';
import SortOrder from '../enums/sortOrder.enum';

export interface SortField {
  field: string | null;
  isInverted: boolean;
}

export type SetSortField = ({ field, isInverted }: SortField) => void;

/**
 * The table sorting handler
 * @param sortField
 * @param setSortField
 * @param sortQueryField
 * @param orderQueryField
 */
export const handleSort =
  <T extends string>(
    sortField: T | undefined,
    {
      setSortField,
      sortQueryField,
      orderQueryField,
    }: {
      setSortField: SetSortField;
      sortQueryField?: string;
      orderQueryField?: string;
    },
  ) =>
  (isInverted: boolean) => {
    if (sortField) {
      setSortField({ field: sortField, isInverted });

      if (sortQueryField || orderQueryField) {
        debouncedUpdateSearch({
          [sortQueryField as string]: sortField,
          [orderQueryField as string]: isInverted
            ? SortOrder.Ascending
            : SortOrder.Descending,
        });
      }
    }
  };
