import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import TimeLine from '../../../components/TimeLine';
import api from '../../../api';
import UserLogs from '../../../interfaces/userLogs.interface';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import GlobalState from '../../../store/reducers/globalState.interface';

export default () => {
  const { id } = useSelector((state: GlobalState) => state.userData.user);
  // const { t } = useTranslation();
  const { search } = useLocation();
  // const user = useSelector((state: GlobalState) => state.userData.user);
  const [usersLogs, setUserLogs] = useState<UserLogs[]>();
  const [totalItems, setTotalItems] = useState<number>();
  // const page = readFromQueryString<string>(search, 'page');

  const [loading, setIsLoading] = useState(false);

  const usersLogsApi = async () => {
    try {
      const getUserLogsData = await api.usersLogs.getUsersLog({
        userId: id,
        ...readAllFromQueryParams(search),
        myAccount: 'true',
        limit: '5',
      });
      setUserLogs(getUserLogsData.items);
      setTotalItems(getUserLogsData.meta.totalItems);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    usersLogsApi();
  }, [search, id]);

  return (
    <div className="flex-grow-1 mw-600px" style={{ marginTop: '10px' }}>
      <TimeLine
        usersLogs={usersLogs}
        loading={loading}
        totalItems={totalItems}
        hideHeader
        myAccountHeaderTitle
      />
    </div>
  );
};
