import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { QrCodeFeedSchema } from '../../../store/types/apps/qrCodeFeed';
import { AppDependencyConfig } from '../../../store/types/apps/globalTypes';

export interface PreviewFeedModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  selectedQrCodeFeedSchema: QrCodeFeedSchema;
  // previewHeight?: number;
}

const PreviewFeedModal = ({
  show,
  onHide,
  title,
  selectedQrCodeFeedSchema,
}: // previewHeight = 600,
PreviewFeedModalProps) => {
  const { t } = useTranslation();

  const { name: feedTitle, dependency } = React.useMemo(
    () => selectedQrCodeFeedSchema,
    [selectedQrCodeFeedSchema],
  );

  const handleHide = () => {
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={handleHide}
      widthDefaultSize
      title={title}
      withFooter
      footerContent={
        <button
          type="button"
          className="btn btn-light-primary me-2"
          onClick={handleHide}
        >
          {t('common.close')}
        </button>
      }
    >
      <div>
        <h3 className="text-center mt-3 mb-7">
          {t('common.title')}
          {': '}
          <small className="text-muted">{feedTitle}</small>
        </h3>
        <div
          className=" d-flex flex-column   flex-center  p-4 w-100"
          style={{
            backgroundColor: (dependency?.config as AppDependencyConfig)
              ?.qrCodeBgColor,
          }}
        >
          {(dependency?.config as AppDependencyConfig)?.icon && (
            <label htmlFor="name" className=" fw-bolder my-1">
              <i
                className={`${
                  (dependency?.config as AppDependencyConfig)?.icon
                }`}
                style={{
                  color: `${
                    (dependency?.config as AppDependencyConfig)?.iconColor
                  }`,
                  fontSize: `${
                    (dependency?.config as AppDependencyConfig)?.iconSize
                  }px`,
                }}
              />
            </label>
          )}

          <label
            htmlFor="name"
            className="  my-1"
            style={{
              color: (dependency?.config as AppDependencyConfig)
                ?.qrCodeTxtColor,
              fontSize: '150%',
              fontWeight: 'bold',
              paddingBottom: '12px',
            }}
          >
            {(dependency?.config as AppDependencyConfig)?.qrCodeTitle && (
              <> {(dependency?.config as AppDependencyConfig)?.qrCodeTitle}</>
            )}
          </label>
          <img
            alt={feedTitle}
            src={(dependency?.config as AppDependencyConfig)?.qrCodeImage}
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '50%',
            }}
          />

          <label
            htmlFor="name"
            className=" fw-bolder my-1"
            style={{
              color: (dependency?.config as AppDependencyConfig)
                ?.qrCodeTxtColor,
              opacity: '0.6',
            }}
          >
            {(dependency?.config as AppDependencyConfig)?.qrCodeSubTitle && (
              <>
                {' '}
                {(dependency?.config as AppDependencyConfig)?.qrCodeSubTitle}
              </>
            )}
          </label>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewFeedModal;
