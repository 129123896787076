import clsx from 'clsx';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import noPreview from '../../assets/images/noPreview/noPreview.png';
import PlaylistContent from '../../containers/Playlist/PlaylistContent';
import PlaylistDevices from '../../containers/Playlist/PlaylistDevices';
import PlaylistTimeline from '../../containers/Playlist/PlaylistTimeline';
import EditPlaylist from '../../containers/Playlists/EditPlaylist';
import PlaylistTabs from '../../enums/playlistTabs.enum';
import IPlaylist from '../../interfaces/playlist.interface';
import { KTSVG } from '../../lib/metronic/helpers';
import NotFound from '../../pages/Errors/NotFound';
import { formatDateString } from '../../utils/dateTime';
import { getPlaylistInfoRoute } from '../../utils/playlists';
import Card from '../Card';
import NavTabs from '../NavTabs';
import ShowMoreCell from '../ShowMoreCell';
import WithSpinner from '../WithSpinner';
import PlaylistPreviewModal from './PlaylistPreviewModal';
import styles from './Playlist.module.scss';
import ViewTemplatePreview from '../../containers/Playlists/viewPlaylist/ViewTemplatePreview';
import quickClick from '../../assets/images/apps/quickclick.svg';
import StatusBadge from '../Badge/StatusBadge';
import PlaylistStatuses from '../../enums/playlistStatuses.enum';
import RestrictedAccess from '../../containers/RestrictedAccess';
import { UserRole } from '../../enums/userRole.enum';
import ContentIcon from '../Contents/ContentTable/TableRow/ContentPreviewIcon/ContentIcon';
import GetPlaylistContentsResponseData from '../../api/endpoints/playlists/interfaces/getPlaylistContentsResponseData.interface';
import {
  readAllFromQueryParams,
  readFromQueryString,
} from '../../utils/queryParams';
import ContentsQueryParam from '../../api/endpoints/contents/enums/contentsQueryParam.enum';
import api from '../../api';
import useIsAdmin from '../../utils/hooks/useIsAdmin';
import { defaultDebounceValue } from '../../constants/api/debounceSettings';
import Device from '../../interfaces/devices/device.interface';
import TimeLinesEnum from '../../enums/timeLines.enum';

type PlaylistProps = {
  playlist: IPlaylist;
  isPlaylistLoading: boolean;
  onEdit: () => void;
  currentTab: PlaylistTabs;
};

const Playlist: FC<PlaylistProps> = ({
  playlist,
  isPlaylistLoading,
  onEdit,
  currentTab,
}) => {
  if (!playlist.id && !isPlaylistLoading) {
    return <NotFound />;
  }
  const { search } = useLocation();
  const [isAdmin] = useIsAdmin();
  const [appTypes, setAppTypes] = useState<string[]>([]);
  const [isContentsLoading, setIsContentsLoading] = useState(true);
  // const [noLicense, setNoLicense] = useState<boolean>(false);
  const [contents, setContents] = useState<any>({
    meta: { totalItems: 0 },
    items: [],
  });
  const timeLineValue =
    readFromQueryString<string>(search, ContentsQueryParam.TimeLine) ||
    TimeLinesEnum.Current;

  const [devicesWithoutLicence, setDevicesWithoutLicence] = useState<Device[]>(
    [],
  );
  const { name, startDate, endDate, priority, locked, tags, status } = playlist;

  const { t } = useTranslation();
  const formatDate = formatDateString({
    month: 'numeric',
    day: 'numeric',
    minute: '2-digit',
    hour: 'numeric',
    hourCycle: 'h12',
  });
  const setAllContents = (
    playlistContentResponse: GetPlaylistContentsResponseData,
  ) => {
    const { items } = playlistContentResponse;
    const filteredContent = items || [];
    /* why we should filter the data when we did received it correctly form the server  */
    // const dateNow = moment().toISOString();
    // switch (timeLineValueMemo) {
    //   case TimeLinesEnum.Current:
    //     filteredContent = items.filter(
    //       ({ content }: IPlaylistContent) =>
    //         moment().isBetween(content?.startDate, content?.endDate) ||
    //         (content?.startDate <= dateNow && !content?.endDate) ||
    //         (!content?.startDate && content?.endDate >= dateNow) ||
    //         (!content?.endDate && !content?.startDate),
    //     );
    //     break;

    //   case TimeLinesEnum.Past:
    //     filteredContent = items.filter(
    //       ({ content }: IPlaylistContent) => content?.endDate < dateNow,
    //     );
    //     break;

    //   case TimeLinesEnum.Future:
    //     filteredContent = items.filter(
    //       ({ content }: IPlaylistContent) => content?.startDate > dateNow,
    //     );
    //     break;
    //   default:
    //     filteredContent = items.filter(
    //       ({ content }: IPlaylistContent) =>
    //         moment().isBetween(content?.startDate, content?.endDate) ||
    //         (content?.startDate <= dateNow && !content?.endDate) ||
    //         (!content?.startDate && content?.endDate >= dateNow) ||
    //         (!content?.endDate && !content?.startDate),
    //     );
    //     break;
    // }

    if (filteredContent?.length) {
      const appTypesNew: string[] = [];
      filteredContent.forEach((singlePlaylist): void => {
        if (singlePlaylist.content?.app?.type) {
          appTypesNew.push(singlePlaylist.content.app.type);
        }
      });

      const uniqueAppTypes = appTypesNew?.filter(
        (type, i, self) => self.indexOf(type) === i,
      );
      setAppTypes(uniqueAppTypes);
    }
    const meta = {
      ...playlistContentResponse.meta,
    };

    setContents({
      items: filteredContent,
      meta,
    });
  };

  const fetchContents = useCallback(
    debounce(async (recentSearch: string) => {
      setIsContentsLoading(true);
      if (playlist.id) {
        api.playlists
          .getContent({
            playlistId: playlist.id,
            params: readAllFromQueryParams(recentSearch),
          })
          .then(setAllContents)
          .finally(() => setIsContentsLoading(false));
      }

      const { items } = isAdmin
        ? await api.devices.getAdminDevicesList({
            playlistId: playlist.id,
          })
        : await api.devices.getDevicesList({
            playlistId: playlist.id,
          });

      items.forEach((device) => {
        const experiedLibrabray = moment(
          device?.license?.libraryAccessExpireDate,
        ).isBefore(new Date());

        if (
          !device?.license?.isLibrary ||
          (device?.license?.isLibrary && experiedLibrabray)
        ) {
          setDevicesWithoutLicence((prevState) => [...prevState, device]);
        }
      });
    }, defaultDebounceValue),
    [timeLineValue, playlist.id],
  );

  const tablesTabs = [
    {
      key: PlaylistTabs.Contents,
      tab: (
        <Link
          role="tab"
          to={getPlaylistInfoRoute(
            playlist.id,
            PlaylistTabs.Contents,
            false,
            playlist,
          )}
          tabIndex={-1}
          className={clsx(
            'nav-link text-active-primary me-6',
            currentTab === PlaylistTabs.Contents ? 'active' : 'hoverable',
          )}
        >
          {t('playlist.content')}
        </Link>
      ),
    },
    {
      key: PlaylistTabs.Devices,
      tab: (
        <Link
          role="tab"
          to={getPlaylistInfoRoute(
            playlist.id,
            PlaylistTabs.Devices,
            false,
            playlist,
          )}
          tabIndex={-1}
          className={clsx(
            'nav-link text-active-primary me-6',
            currentTab === PlaylistTabs.Devices ? 'active' : 'hoverable',
          )}
        >
          {t('playlist.assigned_devices')}
        </Link>
      ),
    },

    {
      key: PlaylistTabs.TimeLine,
      tab: (
        <Link
          role="tab"
          to={getPlaylistInfoRoute(
            playlist.id,
            PlaylistTabs.TimeLine,
            false,
            playlist,
          )}
          tabIndex={-1}
          className={clsx(
            'nav-link text-active-primary me-6',
            currentTab === PlaylistTabs.TimeLine ? 'active' : 'hoverable',
          )}
        >
          {t('playlist.timeline')}
        </Link>
      ),
    },
  ];

  const renderCurrentTab = () => {
    if (currentTab === PlaylistTabs.Contents) {
      return (
        <PlaylistContent
          playlist={playlist}
          fetchContents={fetchContents}
          contents={contents.items}
          contentsMeta={contents.meta}
          isContentsLoading={isContentsLoading}
          devicesWithoutLicence={devicesWithoutLicence}
        />
      );
    }

    if (currentTab === PlaylistTabs.Devices) {
      return <PlaylistDevices playlist={playlist} />;
    }

    return <PlaylistTimeline playListId={playlist.id} />;
  };

  useEffect(() => {
    if (!playlist.id) return;
    fetchContents(search);
  }, [playlist.id]);
  return (
    <div className="w-100 h-100 position-relative">
      <Card>
        <Card.Header>
          <Card.Header.Toolbar>
            <div className="d-flex align-items-center">
              {!isPlaylistLoading && (
                <>
                  <div>
                    <Card.Header.Title>
                      <Card.Header.Title.Label>
                        {name}
                        {playlist?.isQuickClick && (
                          <span className="ms-6 svg-icon svg-icon-2x">
                            <img src={quickClick} alt="quick click icon" />
                          </span>
                        )}
                      </Card.Header.Title.Label>
                    </Card.Header.Title>
                  </div>
                  {locked && (
                    <KTSVG
                      path="/media/icons/locked.svg"
                      className="svg-icon-2x"
                    />
                  )}
                  {!locked && (
                    <KTSVG
                      path="/media/icons/unlocked.svg"
                      className="svg-icon-2x"
                    />
                  )}
                  <StatusBadge status={status || PlaylistStatuses.Draft} />
                </>
              )}
            </div>

            <div className="d-flex flex-wrap flex-stack ">
              <PlaylistPreviewModal
                url={`${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?id=${playlist.id}`}
              >
                <button className="btn btn-light-primary m-2" type="button">
                  {t('playlists.view_playlist_preview_btn')}
                </button>
              </PlaylistPreviewModal>

              <RestrictedAccess
                allowedRoles={[
                  UserRole.SuperAdmin,
                  UserRole.Admin,
                  UserRole.AccountOwner,
                  UserRole.Manager,
                  UserRole.IndigoAdmin,
                  UserRole.Editor,
                ]}
              >
                {!!playlist.id && (
                  <EditPlaylist playlist={playlist} onSuccess={onEdit}>
                    <button className="btn btn-light-primary" type="button">
                      {t('playlists.edit_playlist_btn')}
                    </button>
                  </EditPlaylist>
                )}
              </RestrictedAccess>
            </div>
          </Card.Header.Toolbar>
        </Card.Header>

        <Card.Body>
          <WithSpinner
            isLoading={isPlaylistLoading}
            style={{ minHeight: `100px` }}
            size="md"
          >
            <div className="d-flex flex-wrap ">
              <Card className="border mx-3">
                <div className="d-flex pb-3">
                  <div className="px-3 d-flex flex-column justify-content-center">
                    <span className="fs-4 text-muted pt-2">
                      {t('playlists.start_date')}
                    </span>
                    <Card.Header.Title className="fw-bold pt-2">
                      {startDate ? formatDate(startDate) : '-'}
                    </Card.Header.Title>
                  </div>
                  <div className="border" />
                  {/* vertical divider */}
                  <div className="px-3 d-flex flex-column justify-content-center">
                    <span className="fs-4 text-muted pt-2">
                      {t('playlists.end_date')}
                    </span>
                    <Card.Header.Title className="fw-bold pt-2">
                      {endDate ? formatDate(endDate) : '-'}
                    </Card.Header.Title>
                  </div>
                </div>
              </Card>
              <Card className="border me-2">
                <div className="pb-3 px-3 pt-2 d-flex justify-content-center fs-5 text-muted">
                  {t('playlists.priority_lvl')}
                </div>
                <div className="px-3 d-flex justify-content-center">
                  <Card.Header.Title className="fw-bold">
                    {priority}
                  </Card.Header.Title>
                </div>
              </Card>
              <Card className="border me-2">
                <div className="pb-3 px-3 pt-2 text-muted">
                  {t('common.tags')}
                </div>
                <div className="px-3 d-flex justify-content-center">
                  <Card.Header.Title>
                    <ShowMoreCell
                      itemNames={tags?.map((itemName: string) => itemName)}
                      title={t('playlist.tags')}
                    >
                      {tags?.map((itemName: string) => (
                        <ShowMoreCell.Tags itemName={itemName} />
                      ))}
                    </ShowMoreCell>
                  </Card.Header.Title>
                </div>
              </Card>
              <Card className="border me-2">
                <div className=" px-3 pt-2 text-muted">
                  {t('common.Apps_Playling')}
                </div>
                <>
                  {appTypes?.length && appTypes?.length > 0 ? (
                    <div className="d-flex flex-wrap p-1">
                      {appTypes?.map((type) => (
                        <span className="p-2 w-25">
                          <ContentIcon typeApp={type} />
                        </span>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </>
              </Card>
              <Card className="border">
                <div
                  className="pb-2 px-3 pt-2 fs-5 text-muted"
                  onContextMenu={(ev) => ev.preventDefault()}
                >
                  {t('playlists.template')}
                </div>
                <div
                  className="px-3 d-flex justify-content-center"
                  onContextMenu={(ev) => ev.preventDefault()}
                >
                  <Card.Header.Title>
                    <div className="symbol symbol-45px symbol-2by3">
                      <ViewTemplatePreview
                        image={playlist.template?.layout?.preview || noPreview}
                      >
                        <div
                          onContextMenu={(ev) => ev.preventDefault()}
                          className={`symbol-label ${styles.image_background}`}
                          style={{
                            backgroundImage: `url('${
                              playlist.template?.layout?.preview || noPreview
                            }')`,
                          }}
                        />
                      </ViewTemplatePreview>
                    </div>
                  </Card.Header.Title>
                </div>
              </Card>
            </div>
          </WithSpinner>
        </Card.Body>
        <NavTabs tabs={tablesTabs} withUnderline={false} className="ms-7" />
      </Card>

      <WithSpinner
        isLoading={!playlist.id && isPlaylistLoading}
        className="card bg-white min-h-600px"
        size="md"
      >
        {renderCurrentTab()}
      </WithSpinner>
    </div>
  );
};

export default Playlist;
