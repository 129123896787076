enum UsersSortField {
  FirstName = 'firstName',
  Status = 'status',
  Company = 'companies.name',
  Industry = 'industry.name',
  ExpireDate = 'trialExpireDate',
  LastOnline = 'lastOnline',
  StartDate = 'createdAt',
}

export default UsersSortField;
