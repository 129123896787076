import { useLocation } from 'react-router-dom';
import routesConfig from '../routing/config';
import PlaylistTabs from '../enums/playlistTabs.enum';
import PlaylistContentsQueryParam from '../enums/queryParams/playlistQueryParam.enum';
import Orientation from '../enums/orientation.enum';
import ContentsQueryParam from '../api/endpoints/contents/enums/contentsQueryParam.enum';
import TimeLinesEnum from '../enums/timeLines.enum';
import { readFromQueryString } from './queryParams';
import useRouteConfig from './hooks/useRouteConfig';
import IPlaylist from '../interfaces/playlist.interface';

export const getPlaylistInfoRoute = (
  id: string,
  tab: PlaylistTabs = PlaylistTabs.Contents,
  isPortrait: boolean = false,
  playlist: IPlaylist,
): string => {
  const { search } = useLocation();
  const [currentRoute] = useRouteConfig();
  let pageParams = '';
  let timelineParam = '';

  if (tab === PlaylistTabs.Contents) {
    const timeLineValue = readFromQueryString<string>(
      search,
      ContentsQueryParam.TimeLine,
    );

    pageParams = playlist?.template
      ? `?${
          isPortrait
            ? `${PlaylistContentsQueryParam.Orientation}=${Orientation.Portrait}`
            : `${PlaylistContentsQueryParam.Orientation}=${Orientation.Landscape}&${PlaylistContentsQueryParam.Orientation}=${Orientation.FullScreen}`
        }`
      : `?${PlaylistContentsQueryParam.Orientation}=${Orientation.Landscape}&${PlaylistContentsQueryParam.Orientation}=${Orientation.FullScreen}&${PlaylistContentsQueryParam.Orientation}=${Orientation.Portrait}`;

    timelineParam = `${
      currentRoute?.route === routesConfig.playlist.route
        ? `${
            timeLineValue
              ? `&${ContentsQueryParam.TimeLine}=${timeLineValue}`
              : ''
          }`
        : `&${ContentsQueryParam.TimeLine}=${
            timeLineValue ?? TimeLinesEnum.Current
          }`
    }`;
  }

  return routesConfig.playlist.route
    .replace(':id', id)
    .replace(':page', `${tab}${pageParams}${timelineParam}`);
};
