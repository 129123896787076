import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../../Modal';
import { SelectedValue } from '../../Select/SelectAsync';
import SelectRoles from '../SelectRoles';
import validation from '../../../utils/validation';
import { bulkEditUsers } from '../../../validation/users';
import FormErrorMessage from '../../FormErrorMessage';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';

interface FormInputs {
  role: SelectedValue;
}

export default ({
  children,
  onSubmit,
}: {
  children: React.ReactNode;
  onSubmit?: (data: any) => void;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    clearErrors,
  } = useForm<FormInputs>({
    resolver: yupResolver(validation.object(bulkEditUsers(t)).required()),
    defaultValues: {
      role: {},
    },
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [role, setRole] = useStateWithHookForm<FormInputs, SelectedValue>(
    { setValue, trigger, name: 'role' },
    {},
  );

  const handleClose = () => setIsModalVisible(false);

  const handleSubmitData = () => {
    if (onSubmit) {
      onSubmit({
        roles: [role.label],
      });
    }

    handleClose();
  };

  const open = () => {
    clearErrors();
    setIsModalVisible(true);
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('users.bulk_edit_users')}
        widthDefaultSize={false}
        show={isModalVisible}
        onHide={handleClose}
        bodyClassName="py-5"
        footerContent={
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(handleSubmitData)}
          >
            {t('common.save')}
          </button>
        }
      >
        <div>
          <span className="col-form-label text-lg-end">
            {t('common.roles')}
          </span>
          <SelectRoles initialValue={role} onChange={setRole} />
          <FormErrorMessage name="role" errors={errors} />
        </div>
      </Modal>
    </>
  );
};
