import React from 'react';
import { Months } from '../../../../../enums/library/months.enum';
import RegularSelect, { Option } from '../../../../Select/RegularSelect';

export const getConciegerMonths = (months: Months): Option => ({
  value: String(months),
  label: String(months),
});

const SelectConciegerMonths = ({
  onChange,
  initialValue,
}: {
  onChange: (selectedValue: Months | Months[]) => void;
  initialValue?: Option | Option[];
}) => {
  return (
    <>
      <RegularSelect
        isMulti
        defaultValue={initialValue}
        options={Object.values(Months).map((value) =>
          getConciegerMonths(value as Months),
        )}
        onSelect={(value: Months | Months[]) => onChange(value)}
      />
    </>
  );
};

SelectConciegerMonths.defaultProps = {
  initialValue: [],
};

export default SelectConciegerMonths;
