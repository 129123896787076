import { useSelector } from 'react-redux';
import GlobalState from '../../store/reducers/globalState.interface';
import { UserRole } from '../../enums/userRole.enum';

export default () => {
  const loginAsUserName = window.localStorage.getItem('loginAsUserName');
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  let isSuper = false;
  if (
    loginAsUserName !== 'Super Admin' &&
    roles?.includes(UserRole.SuperAdmin)
  ) {
    isSuper = true;
  }
  return [isSuper, !roles?.length];
};
