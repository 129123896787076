import React from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '..';
import BadgeTypes from '../../../enums/badgeTypes.enum';
import PlaylistStatuses from '../../../enums/playlistStatuses.enum';

export default ({ status }: { status: PlaylistStatuses }) => {
  const { t } = useTranslation();
  let badgeType;

  switch (status) {
    case PlaylistStatuses.Active: {
      badgeType = BadgeTypes.Success;
      break;
    }
    case PlaylistStatuses.Draft: {
      badgeType = BadgeTypes.Danger;
      break;
    }
    case PlaylistStatuses.Scheduled: {
      badgeType = BadgeTypes.Info;
      break;
    }
    default:
      badgeType = BadgeTypes.Default;
  }

  return (
    <Badge type={badgeType}>
      <span>{t(`playlists.statuses.${status.toLocaleLowerCase()}`)}</span>
    </Badge>
  );
};
