import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import SortOrder from '../../../enums/sortOrder.enum';
import DevicesGroupsSortField from '../../../api/endpoints/devicesGroups/enums/devicesGroupsSortField.enum';
import { readFromQueryString } from '../../../utils/queryParams';
import Columns, { TableHeaderColumn } from '../../Table/Columns';
import WithSpinner from '../../WithSpinner';
import TableRow from './TableRow';
import Table from '../../Table';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../utils/hooks/useTableMultiSelect';
import DeviceGroup from '../../../interfaces/deviceGroup.interface';
import UpdateDeviceGroupAction from '../../../store/actions/devicesGroups/interfaces/updateDeviceGroupAction.interface';

interface TableViewProps {
  groups: DeviceGroup[];
  isLoading: boolean;
  sortQueryField: string;
  drownDown: boolean;
  orderQueryField: string;
  preloaderHeight?: number;
  removeDeviceGroup?: (idDeviceGroup: string) => void;
  onEditDeviceGroup?: (data: UpdateDeviceGroupAction['payload']) => void;
  unAssignDeviceGroup?: (deviceGroupId: string, userGroupId: string) => void;
  isSelectable?: boolean;
  multiSelectConfig?: TableMultiSelectConfig;
  userProfileId?: string;
}

interface DefaultProps extends TableViewProps {
  multiSelectConfig: Required<TableViewProps>['multiSelectConfig'];
}

export default ({
  groups,
  isLoading,
  sortQueryField,
  userProfileId,
  orderQueryField,
  removeDeviceGroup,
  preloaderHeight = 300,
  isSelectable = false,
  onEditDeviceGroup,
  unAssignDeviceGroup,
  drownDown,
  multiSelectConfig: { selectedItems, setSelectedItem, selectAll },
}: DefaultProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  const columns: TableHeaderColumn<DevicesGroupsSortField>[] = [
    {
      name: t('devices_groups.device_group_name'),
      sortBy: DevicesGroupsSortField.Name,
    },
    { name: t('devices_groups.devices_name') },
    { name: t('devices_groups.users_name') },
    { name: t('devices_groups.playlists') },
    {
      name: t('devices_groups.creation_date'),
      sortBy: DevicesGroupsSortField.CreationDate,
    },
    { name: t('common.actions') },
  ];

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns<DevicesGroupsSortField>
            columns={columns}
            checked={isAllSelected(selectedItems, map(groups, 'id'))}
            config={{
              isSelectable,
              sortField,
              setSortField,
              sortQueryField,
              orderQueryField,
            }}
            onChange={selectAll}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={!isLoading && !groups.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={t('devices_groups.no_one_group_found')}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {groups.map((group) => (
            <TableRow
              key={group.id}
              group={group}
              config={{ isSelectable, skipActions: false, drownDown }}
              isChecked={selectedItems.includes(group.id)}
              onCheck={setSelectedItem}
              onEditDeviceGroup={onEditDeviceGroup}
              unAssignDeviceGroup={unAssignDeviceGroup}
              removeDeviceGroup={removeDeviceGroup}
              drownDown={drownDown}
              userProfileId={userProfileId}
            />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
