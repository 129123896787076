import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { debounce } from 'lodash';
import LibraryCard from '../../components/Libraries/LibraryCard';
import useSyncWithSearch from '../../utils/hooks/useSyncWithSearch';
import FiltersDropdown from '../../components/Libraries/FiltersDropdown';
import PaginationQueryParam from '../../enums/queryParams/paginationQueryParam.enum';
import LibrariesSortField from '../../api/endpoints/libraries/enums/librariesSortField.enum';
import LibrariesQueryParam from '../../api/endpoints/libraries/enums/librariesQueryParam.enum';
import SortingQueryParam from '../../enums/queryParams/sortingQueryParam.enum';
import { defaultDebounceValue } from '../../constants/api/debounceSettings';
import Pagination from '../../components/Pagination/QueryParamPagination';
import LibraryCategory from '../../interfaces/libraryCategory.interface';
import Search from '../../components/Search/QueryParamSearch';
import Library from '../../interfaces/library.interface';
import WithSpinner from '../../components/WithSpinner';
import SortOrder from '../../enums/sortOrder.enum';
import Card from '../../components/Card';
import api from '../../api';
import {
  readFromQueryString,
  readAllFromQueryParams,
} from '../../utils/queryParams';
import GlobalState from '../../store/reducers/globalState.interface';
import { UserRole } from '../../enums/userRole.enum';
import CreateCategoryTopic from '../../containers/AdminPanel/Topic';
import CreateSkuContainer from '../../containers/AdminPanel/Sku';
import CreateLibraryCategory from '../../containers/AdminPanel/Library';
import FiltersDropdownAdmin from '../../components/Libraries/FiltersDropdownAdmin';
import LibraryCategoryCard from '../../components/Libraries/LibraryCategory';
import { LibraryCategoryType } from '../../enums/library/libraryCategoryType.enum';
import CreateSkuContainerSingle from '../../containers/AdminPanel/Sku/SingleContent';
import {
  clearPlaceHolder,
  clearQueryParams,
  setPlaceHolder,
  setQueryParams,
} from '../../store/actions/breadcrumbs';
import routesConfig from '../../routing/config';
import Language from '../../enums/language.enum';

const pageSize = 20;
const preloaderHeight = 500;

export default () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { roles } = useSelector((state: GlobalState) => state.userData.user);
  const [libraries, setLibraries] = useState<Library[]>([]);
  const [subCategories, setSubCategories] = useState<LibraryCategory[]>([]);
  const [parentCategoryType, setParentCategoryType] =
    useState<LibraryCategoryType>();
  const [category, setCategory] = useState<LibraryCategory>();
  const [subCategoriesTotal, setSubCategoriesTotal] = useState<number>(0);

  const [total, setTotal] = useState<number>(0);
  const [librariesLoading, setLibrariesLoading] = useState<boolean>(true);
  const [sortBy, setSortBy] = useSyncWithSearch<string>(
    SortingQueryParam.SortBy,
    LibrariesSortField.Title,
  );
  const [sortOrder, setSortOrder] = useSyncWithSearch<string>(
    SortingQueryParam.SortOrder,
    SortOrder.Ascending,
  );

  const [, setLang] = useSyncWithSearch<string>(LibrariesQueryParam.Languages);

  const isAscendingOrder = sortOrder === SortOrder.Ascending;
  const isSortedByDate = sortBy === LibrariesSortField.Date;
  const isSortedByTitle = sortBy === LibrariesSortField.Title;
  const [topicId, setTopicId] = useState<string>();

  const [showUploadModal, setShowUploadModal] = useState<boolean>();
  const [singleContent, setSingleContent] = useState<boolean>(false);
  const dispatch = useDispatch();
  const getLibrariesAndCategory = useCallback(
    debounce(async (recentSearch: string) => {
      try {
        const categoryId = readFromQueryString(
          recentSearch,
          LibrariesQueryParam.CategoryId,
        );
        setLibrariesLoading(true);
        const { items, meta } = await api.libraries.getLibraries(
          readAllFromQueryParams(recentSearch),
        );
        if (categoryId) {
          const { items: subCategoriesItems, meta: subCategoriesMeta } =
            await api.libraryCategories.getSubCategories(
              categoryId,
              readAllFromQueryParams(recentSearch),
            );
          setSubCategories(subCategoriesItems);
          setSubCategoriesTotal(subCategoriesMeta.totalItems);

          const { type } = await api.libraryCategories.getCategoryById(
            categoryId,
          );
          setParentCategoryType(type);

          const foundCategory = await api.libraryCategories.getCategoryById(
            categoryId,
          );
          if (foundCategory?.parentCategory) {
            dispatch(
              setQueryParams({
                route: routesConfig.indigoLibraries.route,
                qp: LibrariesQueryParam.CategoryId,
                values: [foundCategory?.parentCategory?.id],
              }),
            );
            dispatch(
              setQueryParams({
                route: routesConfig.indigoSubCategoriesLibraries.route,
                qp: LibrariesQueryParam.CategoryId,
                values: [foundCategory?.id],
              }),
            );
            dispatch(
              setPlaceHolder({
                route: routesConfig.indigoLibraries.route,
                value: foundCategory?.parentCategory?.name,
              }),
            );
            dispatch(
              setPlaceHolder({
                route: routesConfig.indigoSubCategoriesLibraries.route,
                value: foundCategory?.name,
              }),
            );
          } else {
            dispatch(
              setQueryParams({
                route: routesConfig.indigoLibraries.route,
                qp: LibrariesQueryParam.CategoryId,
                values: [foundCategory?.id],
              }),
            );

            dispatch(
              setPlaceHolder({
                route: routesConfig.indigoLibraries.route,
                value: foundCategory?.name,
              }),
            );
          }
          setCategory(foundCategory);
        }
        setLibraries(items);
        setTotal(meta.totalItems);
      } finally {
        setLibrariesLoading(false);
      }
    }, defaultDebounceValue),
    [],
  );

  const switchSortOrder = () =>
    setSortOrder(isAscendingOrder ? SortOrder.Descending : SortOrder.Ascending);

  useEffect(() => {
    setLang(Language.English);
    return () => {
      dispatch(clearPlaceHolder());
      dispatch(clearQueryParams());
    };
  }, []);
  useEffect(() => {
    getLibrariesAndCategory(search);
  }, [search]);

  const refreshDataAfterTopicUpdate = () => {
    getLibrariesAndCategory(search);
  };
  const onUpdate = (tpId: string) => {
    getLibrariesAndCategory(search);
    setShowUploadModal(true);
    setTopicId(tpId);
    // setShowUploadModal(false);
    if (category) setSingleContent(category?.type === LibraryCategoryType.Free);
  };

  const onUpdateContentPosters = () => {
    setShowUploadModal(false);

    getLibrariesAndCategory(search);
  };

  const onHideModal = () => {
    setShowUploadModal(false);
  };

  const getTopicNameForSingContent = () => {
    const topicName = libraries.find((item) => item.id === topicId);
    return topicName?.text;
  };

  return (
    <Card>
      <Card.Header>
        <Card.Header.Title>{category?.name}</Card.Header.Title>
        <Card.Header.Toolbar>
          <Search
            queryField="search"
            pageNumberQueryField=""
            placeholder={t('common.search')}
            className="me-2"
          />
          <div className="card-toolbar">
            {roles.includes(UserRole.SuperAdmin) && (
              <>
                {!category?.parentCategory && (
                  <CreateLibraryCategory
                    createSubCategory
                    onUpdate={refreshDataAfterTopicUpdate}
                    total={subCategoriesTotal}
                    parentCategoryType={parentCategoryType}
                  >
                    <button
                      type="button"
                      className="btn btn-light-primary me-4 bi bi-plus"
                    >
                      {t('library.indigo.createSubCategory')}
                    </button>
                  </CreateLibraryCategory>
                )}

                <CreateCategoryTopic
                  categoryType={category?.type}
                  onUpdate={onUpdate}
                >
                  <button
                    type="button"
                    className="btn btn-light-primary me-4 bi bi-plus"
                  >
                    {t('library.indigo.createTopic')}
                  </button>
                </CreateCategoryTopic>

                {showUploadModal && !singleContent && (
                  <CreateSkuContainer
                    onUpdateContentPosters={onUpdateContentPosters}
                    topicId={topicId as string}
                    dynamicOpen
                    onHideModal={onHideModal}
                  />
                )}

                {showUploadModal && singleContent && (
                  <CreateSkuContainerSingle
                    onUpdateContentPosters={onUpdateContentPosters}
                    topicId={topicId as string}
                    dynamicOpen
                    onHideModal={onHideModal}
                    topicName={getTopicNameForSingContent()}
                  />
                )}
              </>
            )}

            {!roles.includes(UserRole.SuperAdmin) && (
              <>
                <button
                  type="button"
                  className={clsx(
                    'btn btn-light-primary me-4 bi bi-arrow-down-up',
                    isSortedByTitle && 'active',
                  )}
                  onClick={() => {
                    if (isSortedByTitle) {
                      switchSortOrder();
                    } else {
                      setSortBy(LibrariesSortField.Title);
                    }
                  }}
                >
                  {t(
                    isSortedByTitle && isAscendingOrder
                      ? 'library.indigo.sort_order.a_z'
                      : 'library.indigo.sort_order.z_a',
                  )}
                </button>
                <button
                  type="button"
                  className={clsx(
                    'btn btn-light-primary me-4 bi bi-arrow-down-up',
                    isSortedByDate && 'active',
                  )}
                  onClick={() => {
                    if (isSortedByDate) {
                      switchSortOrder();
                    } else {
                      setSortBy(LibrariesSortField.Date);
                    }
                  }}
                >
                  {' '}
                  {t(
                    isSortedByDate && isAscendingOrder
                      ? 'library.indigo.sort_order.oldest_to_newest'
                      : 'library.indigo.sort_order.newest_to_oldest',
                  )}
                </button>
              </>
            )}

            {roles.includes(UserRole.SuperAdmin) ? (
              <FiltersDropdownAdmin className="me-4" />
            ) : (
              <FiltersDropdown className="me-4" />
            )}
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <WithSpinner
          isLoading={librariesLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          size="md"
        >
          <div className="row no-margin">
            {subCategories.map((subCategory) => (
              <LibraryCategoryCard
                category={subCategory}
                total={subCategoriesTotal}
                onUpdate={refreshDataAfterTopicUpdate}
                parentCategoryType={parentCategoryType}
                createSubCategory
              />
            ))}
          </div>
          <div
            className="d-flex row"
            onContextMenu={(ev) => ev.preventDefault()}
          >
            {libraries.map((library) => (
              <LibraryCard
                library={library}
                onUpdate={refreshDataAfterTopicUpdate}
              />
            ))}
          </div>
        </WithSpinner>

        <Pagination
          total={total ?? subCategoriesTotal}
          pageSize={pageSize}
          pageNumberQueryField={PaginationQueryParam.Page}
          pageSizeQueryField={PaginationQueryParam.Limit}
          className="mt-3 mb-5"
        />
      </Card.Body>
    </Card>
  );
};
