import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';
import { KTSVG } from '../../lib/metronic/helpers';

interface WrapperProps {
  children: React.ReactNode;
}

const Table = ({
  children,
  className,
  style,
}: { className?: string; style?: React.CSSProperties } & WrapperProps) => (
  <div className={clsx('table-responsive', className)} style={style}>
    <table className="table align-middle table-row-dashed fs-6 gy-5">
      {children}
    </table>
  </div>
);

const Head = ({ children }: WrapperProps) => <thead>{children}</thead>;

const HeadRow = ({ children }: WrapperProps) => (
  <tr className="fw-bolder text-muted">{children}</tr>
);

const HeadRowCell = ({
  children,
  isTextEnd,
  isNoWrap,
  isSortable,
  onToggle,
  isInverted,
  className,
}: {
  isInverted?: boolean;
  isSortable?: boolean;
  isTextEnd?: boolean;
  isNoWrap?: boolean;
  onToggle?: (isInverted: boolean) => void;
  className?: string;
} & WrapperProps) => (
  <th
    onClick={() => {
      if (onToggle) onToggle(!isInverted);
    }}
    className={clsx(
      'fw-bold',
      {
        'text-end': isTextEnd,
        'text-nowrap': isNoWrap,
        hoverable: isSortable,
      },
      className,
    )}
  >
    {children}
    {isSortable && (
      <>
        {' '}
        <KTSVG
          path={
            isInverted
              ? '/media/icons/duotune/arrows/arr073.svg'
              : '/media/icons/duotune/arrows/arr072.svg'
          }
        />
      </>
    )}
  </th>
);

const Body = ({
  children,
  emptyStateChildren,
  isEmpty = false,
  emptyStateHeight,
}: WrapperProps & {
  emptyStateChildren?: React.ReactNode;
  isEmpty?: boolean;
  emptyStateHeight?: number;
}) => (
  <tbody>
    {isEmpty && emptyStateChildren ? (
      <tr className="position-relative">
        <td
          style={
            emptyStateHeight ? { height: `${emptyStateHeight}px` } : undefined
          }
          className="d-block"
        >
          <div className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center start-0 top-0 fw-bold text-muted">
            {emptyStateChildren}
          </div>
        </td>
      </tr>
    ) : (
      children
    )}
  </tbody>
);

const BodyRow = ({
  children,
  onClick,
  className,
}: WrapperProps & {
  onClick?: MouseEventHandler<HTMLTableRowElement>;
  className?: string;
}) => (
  <tr className={className} onClick={onClick}>
    {children}
  </tr>
);

const BodyRowCell = ({
  children,
  isEmpty,
  isTextEnd,
  isNoWrap,
  emptyStateChildren,
  className,
  onClick,
}: {
  emptyStateChildren?: React.ReactNode;
  isEmpty?: boolean;
  isTextEnd?: boolean;
  isNoWrap?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLTableDataCellElement>;
} & { children?: React.ReactNode }) => (
  <td
    role="presentation"
    onClick={onClick}
    className={clsx(className, {
      'text-end': isTextEnd,
      'text-nowrap': isNoWrap,
    })}
  >
    {isEmpty
      ? emptyStateChildren ?? (
          <span className="d-flex justify-content-center">-</span>
        )
      : children}
  </td>
);

Head.Row = HeadRow;
HeadRow.Cell = HeadRowCell;
Table.Head = Head;
BodyRow.Cell = BodyRowCell;
Body.Row = BodyRow;
Table.Body = Body;

export default Table;
