import { call, put, takeEvery } from 'redux-saga/effects';
import GetPlaylistsAction from '../actions/playlists/interfaces/getPlaylistsAction.interface';
import GetPlaylistsResponseData from '../../api/endpoints/playlists/interfaces/getPlaylistsResponseData.interface';
import DeletePlaylistAction from '../actions/playlists/interfaces/deletePlaylistAction.interface';
import PlaylistsActions from '../actions/playlists/playlistsActions.enum';
import { readAllFromQueryParams } from '../../utils/queryParams';
import history from '../../constants/history';
import api from '../../api';
import {
  getPlaylists,
  getPlaylistsSuccess,
  setPlaylistsLoading,
} from '../actions/playlists';

/**
 * The playlists retrieval worker
 * @param payload
 */
function* getPlaylistsRequestWorker({ payload }: GetPlaylistsAction) {
  yield put(setPlaylistsLoading({ loading: true }));

  try {
    const responseData: GetPlaylistsResponseData = yield call(
      payload.isAdmin
        ? api.playlists.adminGetPlaylists
        : api.playlists.getPlaylists,
      payload.params,
    );

    yield put(getPlaylistsSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setPlaylistsLoading({ loading: false }));
}

/**
 * The playlist removal worker
 * @param payload
 */
function* removePlaylistRequestWorker({ payload }: DeletePlaylistAction) {
  yield put(setPlaylistsLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.playlists.deletePlaylist, payload.id);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(
      getPlaylists({ params: readAllFromQueryParams(history.location.search) }),
    );
  } else {
    yield put(setPlaylistsLoading({ loading: false }));
  }
}

export default function* playlistsWatcher() {
  yield takeEvery<GetPlaylistsAction>(
    PlaylistsActions.GET_PLAYLISTS,
    getPlaylistsRequestWorker,
  );

  yield takeEvery<DeletePlaylistAction>(
    PlaylistsActions.DELETE_PLAYLIST,
    removePlaylistRequestWorker,
  );
}
