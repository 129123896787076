import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import api from '../../../api';
import PowerBi from '../../../components/Apps/PowerBi';
import App from '../../../interfaces/app.interface';

export default () => {
  const { search } = useLocation();
  const [apps, setApps] = useState<App[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getAppList = async (recentSearch: string) => {
    try {
      setLoading(true);

      const { items, meta } = await api.apps.getApps(
        'power-bi',
        readAllFromQueryParams(recentSearch),
      );

      setApps(items);
      setTotal(meta.totalItems);
    } catch (e) {
      toast.error(`${e}`);
    }

    setLoading(false);
  };
  const onUpdate = () => {
    getAppList(search);
  };
  useEffect(() => {
    getAppList(search);
  }, [search]);

  return (
    <PowerBi
      powerBis={apps}
      total={total}
      isLoading={isLoading}
      onUpdate={onUpdate}
    />
  );
};
