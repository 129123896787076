import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import UpdatePlaylistRequestData from '../../../../api/endpoints/playlists/interfaces/updatePlaylistRequestData.interface';
import noPreview from '../../../../assets/images/noPreview/noPreview.png';
import EditPlaylist from '../../../../containers/Playlists/EditPlaylist';
import RemovePlaylist from '../../../../containers/Playlists/RemovePlaylist';
import ViewPlaylist from '../../../../containers/Playlists/viewPlaylist/ViewPlaylist';
import BadgeTypes from '../../../../enums/badgeTypes.enum';
import PlaylistStatuses from '../../../../enums/playlistStatuses.enum';
import PlaylistCells from '../../../../enums/tableCells/playlistCells.enum';
import Playlist from '../../../../interfaces/playlist.interface';
import { KTSVG } from '../../../../lib/metronic/helpers';
import routesConfig from '../../../../routing/config';
import { formatDateString } from '../../../../utils/dateTime';
import { getPlaylistInfoRoute } from '../../../../utils/playlists';
import Badge from '../../../Badge';
import CheckBox from '../../../CheckBox';
import ShowMoreCell from '../../../ShowMoreCell';
import Table from '../../../Table';
import styles from '../tableRow.module.scss';
import quickClick from '../../../../assets/images/apps/quickclick.svg';
import Tooltip from '../../../Tooltip';
import StatusBadge from '../../../Badge/StatusBadge';
import RestrictedAccess from '../../../../containers/RestrictedAccess';
import { UserRole } from '../../../../enums/userRole.enum';
import CopyPlaylist from '../../../../containers/Playlists/CopyPlaylist';
// import { buildQueryString } from '../../../../utils/queryParams';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: '2-digit',
  month: 'short',
  day: 'numeric',
});

// const formatUpdatedAtDate = formatDateString({
//   year: 'numeric',
//   month: 'numeric',
//   day: 'numeric',
// });

interface TableRowProps {
  playlist: Playlist;
  config?: {
    skipActions?: boolean;
    disableLinks?: boolean;
    isSelectable?: boolean;
    skipColumns?: string[];
  };
  isChecked?: boolean;
  onCheck?: (id: string) => void;
  unAssignPlaylistFromDeviceGroup?: (playlistId: string, id: string) => void;
  onEditPlaylist?: (id: string, data: UpdatePlaylistRequestData) => void;
  onCopyPlaylist?: () => void;
  lockingPlaylist?: boolean;
  isPowerBiPublished?: boolean;
}

interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
  isChecked: Required<TableRowProps>['isChecked'];
  onCheck: Required<TableRowProps>['onCheck'];
}

export default ({
  playlist,
  config,
  isChecked,
  onCheck,
  onEditPlaylist,
  unAssignPlaylistFromDeviceGroup,
  onCopyPlaylist,
  lockingPlaylist,
  isPowerBiPublished,
}: DefaultProps) => {
  const { t } = useTranslation();
  const playlistLink = getPlaylistInfoRoute(
    playlist.id,
    undefined,
    false,
    playlist,
  );
  const disabled = !!(
    isPowerBiPublished &&
    playlist.devices?.some((device) => device.license.name !== 'Enterprise')
  );
  return (
    <Row>
      {config.isSelectable && (
        <Cell>
          <Tooltip
            text={t('playlists.lockedContent')}
            hide={!!(!playlist?.locked || !playlist?.isLockedContent)}
          >
            <CheckBox
              name="playlistId"
              id={playlist.id}
              onChange={() => onCheck(playlist.id)}
              checked={isChecked}
              className="mt-1"
              disabled={
                (lockingPlaylist &&
                  (playlist?.locked || playlist?.isLockedContent)) ||
                disabled
              }
            />
          </Tooltip>
        </Cell>
      )}
      {!config.skipColumns?.includes(PlaylistCells.Name) && (
        <Cell isNoWrap>
          <div
            className="d-flex align-items-center"
            onContextMenu={(ev) => ev.preventDefault()}
          >
            <ViewPlaylist playlist={playlist}>
              <iframe
                src={
                  `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/thumbnail.html?id=${playlist.id}` ||
                  noPreview
                }
                title={playlist.name}
                className={clsx('h-30px me-2 pe-none', styles.preview)}
                width="55px"
              />
            </ViewPlaylist>
            <span className="text-dark fw-bold text-hover-primary d-block fs-6">
              <Link to={playlistLink} className="text-reset">
                {playlist.name}
              </Link>
            </span>
            {playlist?.locked && (
              <KTSVG path="/media/icons/locked.svg" className="svg-icon-2x" />
            )}
            {playlist?.isQuickClick && (
              <span className="ms-2 svg-icon svg-icon-2x">
                <img src={quickClick} alt="quick click icon" />
              </span>
            )}
          </div>
        </Cell>
      )}
      {!config.skipColumns?.includes(PlaylistCells.DevicesCount) && (
        <Cell
          isEmpty={!playlist?.devicesCount}
          className="text-center"
          isNoWrap
        >
          <Link
            className="text-dark fw-bold d-block mb-1 fs-6"
            to={`${routesConfig.devices.route}?playlistId=${playlist.id}`}
          >
            {playlist?.devicesCount}
          </Link>
        </Cell>
      )}
      {!config.skipColumns?.includes(PlaylistCells.Priority) && (
        <Cell isEmpty={!playlist.priority} className="text-center" isNoWrap>
          <Badge type={BadgeTypes.Success}>
            <span className="fw-bold d-block mb-1 fs-6">
              {playlist.priority}
            </span>
          </Badge>
        </Cell>
      )}
      {!config.skipColumns?.includes(PlaylistCells.StartDate) && (
        <Cell isEmpty={!playlist.startDate} className="text-center" isNoWrap>
          <span className="text-dark fw-bold d-block mb-1 fs-6">
            {playlist.startDate && formatDate(playlist.startDate)}
          </span>
        </Cell>
      )}
      {!config.skipColumns?.includes(PlaylistCells.EndDate) && (
        <Cell isEmpty={!playlist.endDate} className="text-center" isNoWrap>
          <span className="text-dark fw-bold d-block mb-1 fs-6">
            {playlist.endDate && formatDate(playlist.endDate)}
          </span>
        </Cell>
      )}
      {!config.skipColumns?.includes(PlaylistCells.Status) && (
        <Cell className="text-center" isEmpty={!playlist.status} isNoWrap>
          <StatusBadge status={playlist.status || PlaylistStatuses.Draft} />
        </Cell>
      )}
      {!config.skipColumns?.includes(PlaylistCells.LastUpdate) && (
        <Cell isEmpty={!playlist.updatedAt} className="text-center" isNoWrap>
          <span className="text-dark fw-bold d-block mb-1 fs-6">
            {playlist.updatedAt && formatDate(playlist.updatedAt)}
          </span>
        </Cell>
      )}
      {!config.skipColumns?.includes(PlaylistCells.Owner) && (
        <Cell className="text-center" isEmpty={!playlist?.owner} isNoWrap>
          <span className="text-dark fw-bold d-block mb-1 fs-6">
            {`${playlist?.owner?.firstName} ${playlist?.owner?.lastName}`}
          </span>
        </Cell>
      )}
      {!config.skipColumns?.includes(PlaylistCells.Tags) && (
        <Cell className="text-center" isNoWrap isEmpty={!playlist.tags?.length}>
          <ShowMoreCell
            itemNames={playlist?.tags}
            title={t('playlist.tags')}
            withModal
          >
            {playlist?.tags?.map((itemName: string) => (
              <ShowMoreCell.Tags itemName={itemName} />
            ))}
          </ShowMoreCell>
        </Cell>
      )}

      {!config.skipActions && (
        <Cell isNoWrap isTextEnd>
          <div className="d-flex justify-content-end">
            <div className="mx-1">
              <Link to={playlistLink} className="text-reset">
                <Tooltip text={t('playlists.view_playlist')}>
                  <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                    <i className="fa fa-eye" />
                  </span>
                </Tooltip>
              </Link>
            </div>
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.Manager,
                UserRole.IndigoAdmin,
                UserRole.Editor,
              ]}
              fallback={
                <div className="mx-1">
                  <EditPlaylist
                    onSuccess={onEditPlaylist}
                    playlist={playlist}
                  />
                </div>
              }
            >
              {!unAssignPlaylistFromDeviceGroup && (
                <div className="mx-1">
                  <EditPlaylist
                    onSuccess={onEditPlaylist}
                    playlist={playlist}
                  />
                </div>
              )}
            </RestrictedAccess>
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.Manager,
                UserRole.IndigoAdmin,
                UserRole.Editor,
              ]}
              fallback={
                <div className="mx-1">
                  <CopyPlaylist
                    playlist={playlist}
                    onSuccess={onCopyPlaylist}
                  />
                </div>
              }
            >
              {!unAssignPlaylistFromDeviceGroup && (
                <div className="mx-1">
                  <CopyPlaylist
                    playlist={playlist}
                    onSuccess={onCopyPlaylist}
                  />
                </div>
              )}
            </RestrictedAccess>
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.Manager,
                UserRole.IndigoAdmin,
                UserRole.Editor,
              ]}
              fallback={
                <div className="mx-1">
                  <RemovePlaylist
                    playlist={playlist}
                    unAssignPlaylistFromDeviceGroup={
                      unAssignPlaylistFromDeviceGroup
                    }
                  />
                </div>
              }
            >
              <div className="mx-1">
                <RemovePlaylist
                  playlist={playlist}
                  unAssignPlaylistFromDeviceGroup={
                    unAssignPlaylistFromDeviceGroup
                  }
                />
              </div>
            </RestrictedAccess>
          </div>
        </Cell>
      )}
    </Row>
  );
};
