/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils';
import { KTSVG } from "../../../helpers";

type Props = {
  title?: string;
  subTitle?: string;
  className: string;
};

const ChartsWidget9: React.FC<Props> = ({ className, title, subTitle }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, 'height'));

    const chart = new ApexCharts(chartRef.current, getChartOptions(height));
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  return (
    <div style={{ border: '1px solid #E4E6EF' }} className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-1 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
        </h3>

        {/* begin::Toolbar */}
        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen024.svg"
              className="svg-icon-1"
            />
          </button>
          {/* end::Menu */}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          id="kt_charts_widget_8_chart"
          style={{ minHeight: '350px' }}
          className="card-rounded-bottom border-1"
        />

        <div style={{ textAlign: 'center' }}>
          <span style={{ color: '#A1A5B7', fontSize: 12 }}>{subTitle}</span>
        </div>
        <div style={{ color: '#000', marginTop: 20 }} className="d-flex justify-content-around border-top-1">
          <div className="d-flex me-lg-1">
            <div style={{ width: 10, height: 10, background: '#009EF7', marginTop: 5, marginRight: 12 }} />
            <div className="d-flex flex-column">
            <span style={{ fontSize: 16, fontWeight: 700 }}>5,621</span>
            <span style={{ fontSize: 14, fontWeight: 400, color: '#9899AC' }}>Players Online</span>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ width: 10, height: 10, background: '#E8EAF1', marginTop: 5, marginRight: 12 }} />
            <div className="d-flex flex-column">
              <span style={{ fontSize: 16, fontWeight: 700 }}>1,456</span>
              <span style={{ fontSize: 14, fontWeight: 400, color: '#9899AC' }}>Players Offline</span>
            </div>
          </div>
        </div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ChartsWidget9 };

function getChartOptions(height: number): ApexOptions {
  const borderColor = getCSSVariableValue('--bs-gray-200');

  return {
    series: [76],
    chart: {
      fontFamily: 'inherit',
      type: 'radialBar',
      height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: borderColor,
            strokeWidth: '60%',
            margin: 10, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: borderColor,
              opacity: 1,
              blur: 2
            }
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '24px',
              fontFamily: 'inherit',
              fontWeight: 700,
              color: '#000',
              offsetY: -10
            },
            value: {
              show: true,
              fontSize: '20px',
              fontFamily: undefined,
              fontWeight: 700,
              color: undefined,
              offsetY: 16,
            },
            total: {
              formatter: function (val) {
                return `val`
              }
            }
          }
        }
      },
      grid: {
        padding: {
          top: -10
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['8,346'],
      fill:{
        colors:['#1e22aa'],
        type: 'solid'
      }
    };
}
