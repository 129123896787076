import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import WithDeleteConfirm from '../../../components/WithDeleteConfirm';
import App from '../../../interfaces/app.interface';
import Tooltip from '../../../components/Tooltip';
import api from '../../../api';

export default ({
  app: { id, name },
  children,
  onUpdate,
  title,
  toastMessage,
}: {
  app: App;
  children?: React.ReactNode;
  onUpdate: () => void;
  title?: string;
  toastMessage: string;
}) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    api.apps.deleteApp(id).then(() => {
      if (onUpdate) {
        onUpdate();
      }
      toast.success(i18next.t<string>(`${toastMessage}`));
    });
  };

  return (
    <WithDeleteConfirm
      body={`${t('common.delete_confirmation.title')} ${
        title ?? t('common.delete_confirmation.weather')
      } "${name}"?`}
      handleConfirm={handleConfirm}
    >
      {children ?? (
        <Tooltip text={t('common.delete')}>
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-trash" />
          </span>
        </Tooltip>
      )}
    </WithDeleteConfirm>
  );
};
