import axios from 'axios';
import UsersRoutes from './enums/userDataRoutes.enum';
import { API_URL } from '../../../constants/api';
import getUserDataResponseData from './interfaces/getUserDataResponseData.interface';
import putUserDataResponseData from './interfaces/putUserDataRequestData.interface';

export default {
  getUserData: async (): Promise<getUserDataResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}${UsersRoutes.Users}`,
    });

    return data;
  },
  updateUserData: async (
    requestData: object,
  ): Promise<putUserDataResponseData> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}${UsersRoutes.Users}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: requestData,
    });
    return data;
  },
};
