import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap-v5';
import { useForm } from 'react-hook-form';
import api from '../../../../../api';
import ReorderMediaTopic from '../../../../../api/endpoints/libraries/interfaces/reorderMediaTopic.interface';
import Library from '../../../../../interfaces/library.interface';
import Modal from '../../../../Modal';

const ReOrderSkus = ({
  topic,
  children,
  onUpdate,
}: {
  children?: React.ReactNode;
  topic: Library;
  onUpdate?: () => void;
}) => {
  const { handleSubmit } = useForm<any>();
  const [modalShown, setModalShown] = useState<boolean>(false);

  // const [customerId, setCustomerId] = useState('');

  const [loading, isLoading] = useState(false);
  const open = () => setModalShown(true);
  const close = () => {
    setModalShown(false);
  };

  const [SkuReOrder, setSkuReOrderPosters] = useState<ReorderMediaTopic[]>([]);

  useEffect(() => {
    const reorderFirst: ReorderMediaTopic[] = [];
    if (topic) {
      if (topic?.posters?.length) {
        topic.posters.forEach((post) => {
          reorderFirst.push({
            id: post.id,
            text: post.text,
            order: post.order !== undefined ? post.order : 0,
          });
        });
      }

      if (topic?.content?.length) {
        topic.content.forEach((content) => {
          reorderFirst.push({
            id: content.id,
            text: content.text,
            order: content.order !== undefined ? content.order : 0,
          });
        });
      }

      if (topic?.videos?.length) {
        topic.videos.forEach((video) => {
          reorderFirst.push({
            id: video.id,
            text: video.text,
            order: video.order !== undefined ? video.order : 0,
          });
        });
      }

      setSkuReOrderPosters(reorderFirst);
    }
  }, [topic]);
  const onchangeInputText = (event: any, id: string) => {
    setSkuReOrderPosters((prevState) => {
      // Loop over your list
      return prevState?.map((item) => {
        // Check for the item with the specified id and update it
        return item.id === id ? { ...item, text: event?.target.value } : item;
      });
    });
  };

  const onchangeInputOrder = (event: any, id: string) => {
    setSkuReOrderPosters((prevState) => {
      // Loop over your list
      return prevState?.map((item) => {
        // Check for the item with the specified id and update it
        return item.id === id
          ? { ...item, order: event?.target.value - 1 }
          : item;
      });
    });
  };

  const handleSubmitData = async () => {
    isLoading(true);
    await api.libraries.reorderMediaTopic(SkuReOrder).then(() => {
      close();
      if (onUpdate) onUpdate();
    });
  };

  const modalSize = () => {
    let size = 0;

    if (topic?.posters.length > 0) size += 1;

    if (topic?.videos.length > 0) size += 1;

    if (topic?.content.length > 0) size += 1;

    if (size === 1) return 'mw-700px';
    if (size === 2) return 'mw-1100px';
    if (size === 3) return 'mw-1450px';
    return 'mw-700px';
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title="Reorder Contents"
        show={modalShown}
        onHide={() => {
          setModalShown(false);
          // onUpdateContentPosters();
        }}
        withFooter={false}
        widthDefaultSize={false}
        dialogClassName={modalSize()}
      >
        <form onSubmit={handleSubmit(handleSubmitData)}>
          <Row>
            {topic?.posters?.length > 0 && (
              <Col>
                <h4 className="fw-boldest mb-2">Reorder Posters</h4>
                {topic?.posters?.map((item) => {
                  return (
                    <>
                      <div className=" d-flex card border mt-2">
                        <div className="card-body ">
                          <div className="row justify-content-md-center ">
                            <div className="col col-lg-10">
                              <img
                                src={item.media?.thumbnailUrl}
                                width="60"
                                alt={item.text}
                                className="mx-1"
                              />

                              {item.text}
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col col-lg-9">
                              Title:
                              <input
                                name={`Text${item.text}}`}
                                className="form-control form-control-solid sm"
                                onChange={(event) =>
                                  onchangeInputText(event, item.id)
                                }
                                type="text"
                                // defaultValue={String(index + 1 + (incrementIndex ?? 0))}
                                min="0"
                                defaultValue={item.text}
                              />
                            </div>

                            <div className="col col-lg-3">
                              {' '}
                              Order:
                              <input
                                name={`Text${item.text}}`}
                                className="form-control form-control-solid sm"
                                onChange={(event) =>
                                  onchangeInputOrder(event, item.id)
                                }
                                type="number"
                                defaultValue={String(
                                  item.order !== undefined ? item.order + 1 : 0,
                                )}
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Col>
            )}

            {topic?.content?.length > 0 && (
              <>
                <div className="d-flex" style={{ width: '40px' }}>
                  <div className="vr" />
                </div>
                <Col>
                  <h4 className="fw-boldest mb-2">Reorder Landscapes</h4>

                  {topic?.content.map((item) => {
                    return (
                      <div className=" d-flex card border mt-2">
                        <div className="card-body ">
                          <div className="row justify-content-md-center ">
                            <div className="col col-lg-10">
                              <img
                                src={item.media.thumbnailUrl}
                                width="60"
                                alt={topic?.posters?.[0]?.text}
                                className="mx-1"
                              />

                              {item.text}
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col col-lg-9">
                              Tile:
                              <input
                                name={`Text${item.text}}`}
                                className="form-control form-control-solid sm"
                                // onChange={(event) =>
                                //   onchangeInputText(event, file.name)
                                // }

                                onChange={(event) =>
                                  onchangeInputText(event, item.id)
                                }
                                type="text"
                                // defaultValue={String(index + 1 + (incrementIndex ?? 0))}
                                min="0"
                                defaultValue={item.text}
                              />
                            </div>

                            <div className="col col-lg-3">
                              {' '}
                              Order:
                              <input
                                name={`Text${item.text}}`}
                                className="form-control form-control-solid sm"
                                // onChange={(event) => onchangeInput(event, file.name)}
                                onChange={(event) =>
                                  onchangeInputOrder(event, item.id)
                                }
                                type="number"
                                defaultValue={String(
                                  item.order !== undefined ? item.order + 1 : 0,
                                )}
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Col>
              </>
            )}
            {topic?.videos?.length > 0 && (
              <>
                <div className="d-flex" style={{ width: '40px' }}>
                  <div className="vr" />
                </div>
                <Col>
                  <h4 className="fw-boldest mb-2">ReorderVideo</h4>

                  {topic?.videos.map((item) => {
                    return (
                      <div className=" d-flex card border mt-2">
                        <div className="card-body ">
                          <div className="row justify-content-md-center ">
                            <div className="col col-lg-10">
                              <video width="80" autoPlay muted>
                                <source
                                  className="mx-1"
                                  src={item.media?.url}
                                  type="video/mp4"
                                />
                              </video>

                              {item?.text}
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col col-lg-9">
                              Title:
                              <input
                                name={`Text${item?.text}}`}
                                className="form-control form-control-solid sm"
                                onChange={(event) =>
                                  onchangeInputText(event, item.id)
                                }
                                type="text"
                                // defaultValue={String(index + 1 + (incrementIndex ?? 0))}
                                min="0"
                                defaultValue={item?.text}
                              />
                            </div>

                            <div className="col col-lg-3">
                              {' '}
                              Order:
                              <input
                                name={`Text${item?.text}}`}
                                className="form-control form-control-solid sm"
                                // onChange={(event) => onchangeInput(event, file.name)}

                                onChange={(event) =>
                                  onchangeInputOrder(event, item.id)
                                }
                                type="number"
                                defaultValue={String(
                                  item.order !== undefined ? item.order + 1 : 0,
                                )}
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Col>
              </>
            )}
          </Row>
          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7 mb-2" />
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              //   onClick={onSubmitData}
              disabled={loading}
            >
              {loading ? (
                <>
                  {' '}
                  <span>
                    Updating
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                </>
              ) : (
                <> Update </>
              )}
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default ReOrderSkus;
