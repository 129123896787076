import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React from 'react';
import { RGBColor } from 'react-color';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SelectDevices from '../../../containers/Devices/SelectDevices';
import SelectDevicesGroups from '../../../containers/SelectDevicesGroups';
// import SelectLocation from '../../../containers/SelectLocation';
import SelectTickerSpeed from '../../../containers/Ticker/SelectTickerSpeed';
import { TickerSpeedTypes } from '../../../containers/Ticker/SelectTickerSpeed/tickerSpeedTypes.interface';
import App from '../../../interfaces/app.interface';
import DeviceGroup from '../../../interfaces/deviceGroup.interface';
import Device from '../../../interfaces/devices/device.interface';
import { prepareHookFromState } from '../../../utils/hooks/useStateWithHookForm';
import validation from '../../../utils/validation';
import { tickerCreateValidation } from '../../../validation/apps/ticker';
import ColorPicker from '../../ColorPicker';
import Counter from '../../Counter';
import DatePicker from '../../DatePicker';
import FormErrorMessage from '../../FormErrorMessage';
import Modal from '../../Modal';
import { SelectedValue } from '../../Select/SelectAsync';
import SwitchCheckbox from '../../SwitchCheckbox';
import TextInput from '../../TextInput';
import WithSpinner from '../../WithSpinner';

interface FormInputs {
  name: string;
  message: string;
  location: string;
  daysSafe: number;
  showDaysSafe: boolean;
  backgroundColor: RGBColor;
  textColor: RGBColor;
  startDate: Date;
  endDate: Date;
  tickerSpeed: SelectedValue;
  deviceIds: SelectedValue[];
  deviceGroupIds: SelectedValue[];
}

export default ({
  onSubmit,
  app,
  handleClose,
  isLoading,
}: {
  onSubmit: any;
  app: App;
  handleClose: () => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  const defaultValues: Partial<FormInputs> = {
    name: app?.name ?? '',
    message: app?.dependency?.config?.message ?? '',
    location: app?.dependency?.config?.location ?? '',
    showDaysSafe: app?.dependency?.config?.showDaysSafe ?? false,
    tickerSpeed:
      TickerSpeedTypes.find(
        ({ value }) => value === app?.dependency?.config?.tickerSpeed,
      ) ??
      ({
        value: 'NORMAL',
        label: 'Normal',
      } as SelectedValue),
    daysSafe: app?.dependency?.config?.daysSafe ?? 0,
    backgroundColor: app?.dependency?.config?.backgroundColor ?? {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    },
    textColor: app?.dependency?.config?.textColor ?? { r: 0, g: 0, b: 0, a: 1 },
    startDate: app?.startDate ? new Date(app.startDate) : undefined,
    endDate: app?.endDate ? new Date(app?.endDate) : undefined,
    deviceIds:
      app.devices?.map((device: Device) => ({
        label: device.name,
        value: device.id,
      })) ?? [],
    deviceGroupIds:
      app.deviceGroups?.map((item: DeviceGroup) => ({
        label: item.name,
        value: item.id,
      })) ?? [],
  };

  const {
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      validation
        .object()
        .shape(tickerCreateValidation(t), [['deviceGroupIds', 'deviceIds']])
        .required(),
    ),
    defaultValues,
  });

  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );
  const [name, setName] = useFs<FormInputs['name']>('name');

  const [message, setMessage] = useFs<FormInputs['message']>('message');

  const [backgroundColor, setBackgroundColor] =
    useFs<FormInputs['backgroundColor']>('backgroundColor');

  const [textColor, setTextColor] = useFs<FormInputs['textColor']>('textColor');

  const [startDate, setStartDate] = useFs<FormInputs['startDate']>('startDate');

  const [endDate, setEndDate] = useFs<FormInputs['endDate']>('endDate');
  const [location, setLocation] = useFs<FormInputs['location']>('location');
  const [daysSafe, setDaysSafe] = useFs<FormInputs['daysSafe']>('daysSafe');
  const [showDaysSafe, setShowDaysSafe] =
    useFs<FormInputs['showDaysSafe']>('showDaysSafe');

  const [tickerSpeed, setTickerSpeed] =
    useFs<FormInputs['tickerSpeed']>('tickerSpeed');
  const [devices, setDevices] = useFs<FormInputs['deviceIds']>('deviceIds');

  const [deviceGroups, setDeviceGroups] =
    useFs<FormInputs['deviceGroupIds']>('deviceGroupIds');

  const handleSubmitData = () => {
    if (onSubmit) {
      onSubmit({
        name,
        endDate,
        startDate,
        config: {
          message,
          textColor,
          tickerSpeed: tickerSpeed.value,
          backgroundColor,
          location,
          daysSafe,
          showDaysSafe,
        },
        deviceIds: (devices.map((item) => item.value) as string[]) ?? [],
        deviceGroupIds:
          (deviceGroups?.map((item) => item.value) as string[]) ?? [],
      });
    }
  };

  return (
    <WithSpinner isLoading={isLoading} size="md" className="min-h-400px">
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className="col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('tickers.addTicker.name')}
                </label>
                <TextInput
                  name="name"
                  id="name"
                  placeholder={t('contents.type_here')}
                  value={name}
                  onChange={setName}
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </p>

              <p className="mt-2 mb-6">
                <label
                  htmlFor="message"
                  className="col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('tickers.addTicker.message')}
                </label>
                <TextInput
                  name="message"
                  id="message"
                  placeholder={t('contents.type_here')}
                  value={message}
                  onChange={setMessage}
                />
                <FormErrorMessage
                  name="message"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </p>
              <div className="my-1 w-100">
                <div className="w-100 d-flex justify-content-between align-items-center">
                  <p className="fs-6 fw-bolder mb-2">
                    {t('tickers.addTicker.daysSafe')}
                  </p>
                  <SwitchCheckbox
                    name="showDaysSafe"
                    onChange={() => setShowDaysSafe(!showDaysSafe)}
                    checked={showDaysSafe}
                  />
                </div>
              </div>
              <div className="mb-6">
                <Counter
                  isNotRequired
                  title={t('tickers.addTicker.enterDaysSafe')}
                  onChange={(value) => setDaysSafe(value)}
                  value={daysSafe}
                />
              </div>
              <p className="mb-6">
                <p className="fs-6 fw-bolder mb-2">
                  {t('tickers.addTicker.defaultMessage')}
                </p>
                <p className="text-primary fs-6">
                  Our Location has been safe for XXX days.
                </p>
              </p>
              <p className="mb-6">
                <label
                  htmlFor="location"
                  className="form-label fw-bolder fs-6 mb-2"
                >
                  {t('tickers.addTicker.locationSafedays')}
                </label>
                {/* <SelectLocation
                  id="location"
                  initialValue={location}
                  onChange={({ value }) => {
                    if (value !== 'loadingValue') setLocation(value ?? '');
                  }}
                  isClearable
                />
                <FormErrorMessage
                  name="location"
                  errors={errors}
                  className="mt-1 min-h-20px"
                /> */}

                <TextInput
                  name="location"
                  id="location"
                  placeholder={t('contents.type_here')}
                  value={location}
                  onChange={setLocation}
                />
                <FormErrorMessage
                  name="location"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </p>

              <div className="mb-6">
                <label className="fs-6 fw-bolder mb-2">
                  {t('tickers.addTicker.selectTickerSpeed')}
                </label>
                <SelectTickerSpeed
                  initialValue={tickerSpeed}
                  onChange={(value) => setTickerSpeed(value)}
                />
                <FormErrorMessage
                  name="tickerSpeed"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mt-2 mb-0">
                <label className="required fs-6 fw-bolder mb-2">
                  {t('tickers.addTicker.chooseBackgroundColor')}
                </label>
                <ColorPicker
                  id="backgroundColor"
                  name="backgroundColor"
                  onSelect={(color) => setBackgroundColor(color)}
                  value={backgroundColor}
                />
                <FormErrorMessage
                  name="backgroundColor"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>
              <div className="mt-2 mb-0">
                <label className="required fs-6 fw-bolder mb-2">
                  {t('tickers.addTicker.chooseTextColor')}
                </label>
                <ColorPicker
                  value={textColor}
                  id="textColor"
                  name="textColor"
                  onSelect={(color) => setTextColor(color)}
                />
                <FormErrorMessage
                  name="textColor"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>

              <div className="mb-6">
                <label
                  className={clsx(
                    ' fs-6 fw-bolder mb-2',
                    !deviceGroups.length && 'required',
                  )}
                >
                  {t('playlists.device')}
                </label>
                <SelectDevices
                  initialValue={devices.map(({ value }) => value as string)}
                  onChange={(values) => {
                    setDevices(values);
                  }}
                  hideLicence
                />
                <FormErrorMessage
                  name="deviceIds"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>
              <div className="mb-6">
                <label
                  className={clsx(
                    ' fs-6 fw-bolder mb-2',
                    !devices.length && 'required',
                  )}
                >
                  {t('devices.device_group')}
                </label>
                <SelectDevicesGroups
                  initialValue={deviceGroups}
                  onChange={(values) => {
                    setDeviceGroups(values);
                  }}
                />
                <FormErrorMessage
                  name="deviceGroupIds"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>
              <label className="fs-6 fw-bolder mb-2">
                {t('playlists.schedule')}
              </label>
              <div className="mb-0">
                <DatePicker
                  value={startDate}
                  onChange={(newDate) => setStartDate(newDate[0])}
                  labelText={t('tickers.addTicker.startDate')}
                  options={{
                    enableTime: true,
                    defaultHour: 0,
                  }}
                />
                <FormErrorMessage
                  name="startDate"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>

              <div className="mb-0">
                <DatePicker
                  value={endDate}
                  options={{
                    enableTime: true,
                    defaultHour: 23,
                    defaultMinute: 59,
                  }}
                  onChange={(newDate) => setEndDate(newDate[0])}
                  labelText={t('tickers.addTicker.endDate')}
                />
                <FormErrorMessage
                  name="endDate"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>
            </div>
          </div>
          <Modal.Separator />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-light"
              onClick={handleClose}
            >
              {t('common.cancel')}
            </button>

            <button type="submit" className="btn btn-primary">
              {t('common.submit')}
            </button>
          </div>
        </div>
      </form>
    </WithSpinner>
  );
};
