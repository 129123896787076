import React from 'react';
import { useParams } from 'react-router-dom';
import AssignDevice from '../../../components/Devices/AssignDevice';
import api from '../../../api';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import DeviceAssignmentTarget from '../../../interfaces/devices/deviceAssignmentTarget.interface';
import { UserDataSetInterface } from '../../../store/reducers/userData/userDataState.interface';
import License from '../../../interfaces/license.interface';

export default ({
  children,
  isFilter,
  assignDeviceToDeviceGroup,
  assignDeviceToUserGroup,
  deviceAssignmentTarget,
  denyDifferentLicense,
  user,
  license,
}: {
  children: React.ReactNode;
  isFilter?: boolean;
  assignDeviceToDeviceGroup?: any;
  assignDeviceToUserGroup?: (data: SelectedValue[]) => void;
  deviceAssignmentTarget?: DeviceAssignmentTarget;
  denyDifferentLicense?: boolean;
  user?: UserDataSetInterface;
  license?: License;
}) => {
  const { id } = useParams<{ id: string }>();

  const handleSubmit = async (data: SelectedValue[]) => {
    if (assignDeviceToUserGroup) {
      assignDeviceToUserGroup(data);
      return;
    }

    await Promise.all(
      data.map((device) =>
        api.devices.assignDeviceToDeviceGroup(device.value as string, id),
      ),
    );

    assignDeviceToDeviceGroup(true);
  };

  return (
    <AssignDevice
      onSubmit={handleSubmit}
      isFilter={isFilter}
      deviceAssignmentTarget={deviceAssignmentTarget}
      denyDifferentLicense={denyDifferentLicense}
      user={user}
      license={license}
    >
      {children}
    </AssignDevice>
  );
};
