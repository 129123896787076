import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { readFromQueryString } from '../queryParams';

/**
 * Creates the state partially synchronized with query param filter
 * @param queryParamName
 * @param defaultValue
 * @param isArray
 */
export default <T extends string | string[] = string>(
  queryParamName: string,
  defaultValue?: T,
  isArray = false,
): [T | undefined, (value: T | undefined) => void] => {
  const { search } = useLocation();

  const [value, setValue] = useState<T | undefined>(defaultValue);

  // Observe search update and sync state with query params
  useEffect(() => {
    const searchValue = readFromQueryString<T>(search, queryParamName, isArray);

    if (searchValue !== value) {
      setValue(searchValue ?? ((isArray ? [] : '') as T));
    }
  }, [search]);

  return [value, setValue];
};
