import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import App from '../../../../../interfaces/app.interface';
import Table from '../../../../Table';
import EditRss from '../../../../../containers/Apps/Rss/EditRss';
import DeleteRss from '../../../../../containers/Apps/DeleteApp';
import ScheduleRss from '../../../../../containers/Apps/ScheduleApp';
import { formatDateString } from '../../../../../utils/dateTime';
import PlacementsEnum from '../../../../../enums/placements.enum';
import Yup from '../../../../../utils/validation';
import PreviewFeedModal from '../../Preview/PreviewFeedModal';
import { AppDependency } from '../../../../../store/types/apps/globalTypes';
import PreviewThumbnail from '../../Thumbnail';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({ rss, onUpdate }: { rss: App; onUpdate: () => void }) => {
  const { t } = useTranslation();

  const [showModalFeed, setShowModalFeed] = useState(false);
  const [iframeRef, setIframeRef] = React.useState<HTMLIFrameElement>();
  const [url, setUrl] = React.useState('');

  const displayPreview = () => setShowModalFeed(true);

  useEffect(() => {
    if (iframeRef?.contentWindow) {
      setUrl(
        `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?appId=${rss.id}`,
      );
    }
  }, [iframeRef, rss]);

  return (
    <Row>
      <Cell isEmpty={!rss.name}>
        <PreviewFeedModal
          show={showModalFeed}
          onHide={() => {
            setShowModalFeed(false);
          }}
          title={t('common.preview')}
          selectedRssFeedSchema={{
            type: 'rss',
            createdAt: rss!.createdAt,
            updatedAt: rss!.updatedAt,
            dependency: rss!.dependency as AppDependency,
            id: rss!.id,
            name: rss!.name,
            placement: rss!.placement as PlacementsEnum,
          }}
        >
          <PreviewThumbnail
            onOpen={displayPreview}
            src={url}
            title={rss.name}
            setRef={(e) => setIframeRef(e || undefined)}
          />
        </PreviewFeedModal>
      </Cell>
      {/* <Cell isEmpty={!rss.name}>
        <div onClick={() => setShowModalFeed(true)} aria-hidden>
          <span className="text-dark cursor-pointer fw-bold text-hover-primary d-block mb-1 fs-6 w-100">
            {rss.name}
          </span>
        </div>
      </Cell> */}

      <Cell isEmpty={!rss.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {rss.ownerApp?.firstName} {rss.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!rss.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {rss.createdAt && formatDate(rss.createdAt)}
        </span>
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          <div className="mx-1">
            <EditRss rss={rss} onUpdate={onUpdate} />
          </div>

          {rss.placement === PlacementsEnum.FULL_SCREEN && (
            <div className="mx-1">
              <ScheduleRss
                app={rss}
                onUpdate={onUpdate}
                infoValidationSchema={{
                  orientation: Yup.string().required(t('required')),
                  duration: Yup.number().required(t('required')).min(1),
                  startDate: Yup.date(),
                  endDate: Yup.date().when('startDate', {
                    is: (startDate: any) => !!startDate,
                    then: Yup.date().min(
                      Yup.ref('startDate'),
                      t('common.validation.endDate'),
                    ),
                  }),
                  isLocked: Yup.boolean(),
                }}
                skipOrientationAuto
                skipOrder
              />
            </div>
          )}

          <div className="mx-1">
            <DeleteRss
              title={t('common.delete_confirmation.rss')}
              app={rss}
              onUpdate={onUpdate}
              toastMessage="apps.rss.feed_deleted"
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default TableRow;
