import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import Banner from '../../../interfaces/banner.interface';
import useLocalStorage from '../../../utils/hooks/useLocalStorage';
import GlobalState from '../../../store/reducers/globalState.interface';

export default ({ item }: { item: Banner }) => {
  const { user } = useSelector((state: GlobalState) => state.userData);
  const [isBanner, setIsBanner] = useLocalStorage(
    `isBanner-${item?.id}-${user.id}`,
    true,
  );

  return (
    <>
      {isBanner && (
        <div
          style={{
            backgroundColor: '#ffe7a6',
            margin: '20px',
            padding: '20px',
          }}
          className="flex justify-between items-center flex-direction-column"
        >
          <div style={{ float: 'right' }}>
            <CloseOutlined onClick={() => setIsBanner(false)} />
          </div>
          <div>
            <h1>{item.title}</h1>
            <p>{item.body}</p>
          </div>
        </div>
      )}
    </>
  );
};
