import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Language from '../../enums/language.enum';
import { languagesTranslation } from '../../constants/translation/languages';
import Select from '../../components/Select/RegularSelect';
import { getComparatorBy } from '../../utils/common';

const SelectLanguage = ({
  language,
  setLanguage,
  id,
  placeholder,
  isDisabled,
  className,
}: {
  setLanguage: (language: Language) => void;
  language?: Language;
  id?: string;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Select<Language>
      className={className}
      isDisabled={isDisabled}
      id={id}
      defaultValue={
        language && {
          label: t(languagesTranslation[language]),
          value: language,
        }
      }
      placeholder={placeholder}
      onSelect={(selectedLanguage) => setLanguage(selectedLanguage as Language)}
      options={Object.values(Language).map((languageValue) => ({
        label: t(languagesTranslation[languageValue]),
        value: languageValue,
      }))}
    />
  );
};

export const MemoizedSelectLanguage = memo(
  SelectLanguage,
  getComparatorBy(['id', 'placeholder', 'language', 'className']),
);

export default SelectLanguage;
