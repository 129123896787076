import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getConfigByRoute } from '../../utils/routes';
import useRouteConfig from '../../utils/hooks/useRouteConfig';
import { buildQueryString } from '../../utils/queryParams';
import GlobalState from '../../store/reducers/globalState.interface';

export default () => {
  const { t } = useTranslation();
  const [currentRoute, pathname] = useRouteConfig();
  const { queryParams, placeholder } = useSelector(
    (state: GlobalState) => state.breadcrumbs,
  );

  if (!currentRoute) {
    return null;
  }
  const { title } = currentRoute;
  // we need to exclude scheduleLibrary from path
  const subRoutes = pathname.split('/').filter(Boolean);
  const links: { path: string; name?: string }[] = [];
  subRoutes.forEach((subRoute, index) => {
    if (!placeholder[currentRoute.route] && !index) return; // Skip current
    let path = `/${subRoutes.slice(0, subRoutes.length - index).join('/')}`;
    const routeConfig = getConfigByRoute(path);
    if (path && routeConfig) {
      const qp = queryParams[routeConfig.route];
      const placeholderValue = placeholder[routeConfig.route];
      // if routes/config.indigoLibraryUnderSubCategory route changes this should be updated
      // if routes/config.indigoLibrary route changes this should be updated

      if (!path?.endsWith('scheduleLibrary')) {
        if (qp) {
          path = `${path}${buildQueryString(qp)}`;
        }

        links.push({
          path,
          name: placeholderValue || routeConfig.title,
        });
      }
    }
  });

  // Reverse for proper display
  links.reverse();
  return (
    <>
      <span className="h-20px border-gray-200 border-start mx-4 my-3" />
      <ul className="breadcrumb fw-bold fs-7 my-1">
        <li className={clsx('breadcrumb-item')}>
          <Link className="text-muted text-hover-primary" to="/">
            Dashboard
          </Link>
        </li>

        {links.map(({ name, path }, index) => {
          if (
            index === links.length - 1 &&
            (title === 'pageTitles.libraries' || title === 'pageTitles.library')
          ) {
            return (
              name && <li className="breadcrumb-item text-dark">{t(name)}</li>
            );
          }
          return (
            name && (
              <li className="breadcrumb-item" key={path}>
                <Link to={path} className="text-capitalize">
                  {t(name)}
                </Link>
              </li>
            )
          );
        })}

        {title &&
          title !== 'pageTitles.libraries' &&
          title !== 'pageTitles.library' && (
            <li className="breadcrumb-item text-dark">{t(title)}</li>
          )}
      </ul>
    </>
  );
};
