/* eslint-disable react/jsx-props-no-spreading */

import React, { memo, ReactElement } from 'react';
import ReactFlatpickr, { DateTimePickerProps } from 'react-flatpickr';
import flatpickr from 'flatpickr';
import clsx from 'clsx';
import 'flatpickr/dist/flatpickr.min.css';
import { KTSVG } from '../../lib/metronic/helpers';

const CustomInput = ({
  defaultValue,
  inputRef,
  inputName,
  placeholderValue,
  extraProps,
}: any & {
  inputRef: (node: HTMLInputElement) => void;
  defaultValue: DateTimePickerProps['defaultValue'];
  inputName?: string;
  placeholderValue?: string;
  extraProps?: any;
}): ReactElement => {
  return (
    <input
      {...extraProps}
      className="form-control form-control-solid ps-12 flatpickr-input active"
      placeholder={placeholderValue}
      name={inputName}
      defaultValue={defaultValue}
      ref={inputRef}
      readOnly
    />
  );
};

const DatePicker = ({
  labelText,
  onChange,
  value,
  name,
  className,
  placeholder,
  options,
  id,
  disabled,
  labelNotRequired = false,
}: {
  labelText?: string;
  labelNotRequired?: boolean;
  value?: Date[] | Date;
  placeholder?: string;
  name?: string;
  className?: string;
  onChange?: (newDate: Date[]) => void;
  options?: flatpickr.Options.Options;
  id?: string;
  disabled?: boolean;
}) => (
  <>
    {labelText && (
      <label className={`${!labelNotRequired && 'required'} fs-6 fw-bold mb-2`}>
        {labelText}
      </label>
    )}
    <div
      className={clsx('position-relative d-flex align-items-center', className)}
      id={id}
    >
      <KTSVG
        path="/media/icons/duotune/general/gen014.svg"
        className="svg-icon svg-icon-2 position-absolute mx-4"
      />
      <ReactFlatpickr
        disabled={disabled}
        value={value}
        onChange={onChange || (() => {})}
        options={{
          ...options,
          formatDate: (date) => {
            const dateFormat = new Intl.DateTimeFormat(navigator.language, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              minute: '2-digit',
              hour: 'numeric',
            });

            return dateFormat.format(date);
          },
        }}
        render={({ defaultValue, value: renderValue, ...props }, ref) => (
          <CustomInput
            extraProps={props}
            defaultValue={defaultValue}
            value={renderValue}
            inputRef={ref}
            placeholderValue={placeholder}
            inputName={name}
          />
        )}
      />
    </div>
  </>
);

export const MemoizedDatePicker = memo(DatePicker, () => true);

export default DatePicker;
