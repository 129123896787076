import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import CopyContentSteps from '../../../../../enums/steps/copyContentSteps.enum';
import AssignDevicesGroups from '../../../../../containers/DevicesGroups/AssignDevicesGroups';
import AssignDevices from '../../../../../containers/Devices/AssignDevices';
import CopyContentStepsData from '../../copyContentStepsData.interface';
import StepsTitle from '../../StepsTitle';
import Modal from '../../../../Modal';
import GlobalState from '../../../../../store/reducers/globalState.interface';
import Tooltip from '../../../../Tooltip';

enum Tab {
  Devices = 'devices',
  DevicesGroups = 'devices-groups',
}

const tabsTranslation = {
  [Tab.Devices]: 'contents.create_content.devices_and_groups_tabs.devices',
  [Tab.DevicesGroups]:
    'contents.create_content.devices_and_groups_tabs.devices_groups',
} as const;

export default ({
  data,
  setData,
  setMode,
  copyContent,
  skipLicence,
}: {
  data: any;
  setData: (newData: Partial<CopyContentStepsData>) => void;
  setMode: (mode: CopyContentSteps) => void;
  copyContent?: boolean;
  skipLicence?: boolean;
}) => {
  const { isTrial } = useSelector((state: GlobalState) => state.userData.user);
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(Tab.Devices);

  let currentTable: React.ReactNode;

  switch (currentTab) {
    case Tab.Devices:
      currentTable = (
        <AssignDevices
          skipLicence={skipLicence}
          copyContent={copyContent}
          preselectedItems={data.deviceIds ?? []}
          onSelectionChange={(selectedItems) =>
            setData({ ...data, deviceIds: selectedItems })
          }
        />
      );
      break;
    case Tab.DevicesGroups:
      currentTable = (
        <AssignDevicesGroups
          preselectedItems={data.deviceGroupsIds ?? []}
          onSelectionChange={(selectedItems) =>
            setData({ ...data, deviceGroupsIds: selectedItems })
          }
        />
      );
      break;
    default:
      currentTable = '';
  }

  return (
    <>
      <StepsTitle currentStep={CopyContentSteps.DevicesAndGroups} />

      <div className="d-flex justify-content-center mb-5">
        {Object.values(Tab).map((tab, index) =>
          isTrial && tab === Tab.DevicesGroups ? (
            <Tooltip
              text={t('devices_groups.trial_plan')}
              className={clsx(
                'btn w-100 py-4',
                tab === currentTab
                  ? 'btn-bg-light btn-color-primary'
                  : 'btn-bg-secondary btn-color-muted',
                !index && 'me-7',
              )}
            >
              <span key={tab} role="button" tabIndex={-1}>
                {t(tabsTranslation[tab])}
                <i
                  className="bi bi-lock-fill ms-3"
                  style={{ fontSize: '1.4rem' }}
                />
              </span>
            </Tooltip>
          ) : (
            <span
              key={tab}
              role="button"
              tabIndex={-1}
              className={clsx(
                'btn w-100 py-4',
                tab === currentTab
                  ? 'btn-bg-light btn-color-primary'
                  : 'btn-bg-secondary btn-color-muted',
                !index && 'me-7',
              )}
              onClick={() => setCurrentTab(tab)}
              onKeyPress={() => setCurrentTab(tab)}
            >
              {t(tabsTranslation[tab])}
            </span>
          ),
        )}
      </div>

      {currentTable}

      <Modal.Separator withoutDefaultMargins className="mt-6 mb-7" />
      <div className="d-flex justify-content-end">
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => setMode(CopyContentSteps.Playlists)}
        >
          {t('common.next_step')}
        </button>
      </div>
    </>
  );
};
