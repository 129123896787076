import axios from 'axios';
import { API_URL } from '../../../constants/api';
import LibrariesRoutes from './enums/librariesRoutes.enum';
import GetLibrariesRequestData from './interfaces/getLibrariesRequestData.interface';
import ScheduleLibraryRequestData from './interfaces/scheduleLibraryRequestData.interface';
import GetLibrariesResponseData from './interfaces/getLibrariesResponseData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import Library from '../../../interfaces/library.interface';
import CreateTopicRequestData from './interfaces/createTopicRequestData.interface';
import SkuInfo from '../../../interfaces/skuInfo.interface';
import SkuReOrder from './interfaces/reorderMediaTopic.interface';

export default {
  /**
   * Retrieves the libraries list
   * @param requestData
   */
  getLibraries: async (
    requestData: GetLibrariesRequestData,
  ): Promise<GetLibrariesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LibrariesRoutes.Libraries}`,
      params: sanitizeQueryParams(requestData),
    });
    console.log(data);
    return data;
  },

  /**
   * Retrieves the library by id
   * @param id
   */
  getLibraryById: async (id: string): Promise<Library> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/${id}`,
    });

    return data;
  },

  /**
   * Create Topic without Mdeia
   */

  createTopic: async (
    requestData: CreateTopicRequestData,
  ): Promise<Library> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/createTopic/noMedia`,
      data: requestData,
    });

    return data;
  },
  updateTopic: async (
    requestData: CreateTopicRequestData,
    id: string,
  ): Promise<Library> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/${id}`,
      data: requestData,
    });

    return data;
  },
  deleteTopic: async (id: string): Promise<Library> => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/deleteTopic/${id}`,
    });
    return data;
  },
  deleteImage: async (id: string): Promise<Library> => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/deleteImage/${id}`,
    });
    return data;
  },
  updateImage: async (id: string, images: any): Promise<Library> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/updateImage/${id}`,
      data: { images },
    });
    return data;
  },
  /**
   * Create posters and Contents
   */

  createPostersAndContents: async (
    requestData: SkuInfo[],
    TopicId: string,
    isDownloadable?: boolean,
  ): Promise<Library> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/${TopicId}/createMedia`,
      data: requestData,
      params: { isDownloadable },
    });

    return data;
  },

  /**
   * replace Signle and Contents
   */

  ReplaceSignleContents: async (
    requestData: SkuInfo[],
    TopicId: string,
    isDownloadable?: boolean,
  ): Promise<Library> => {
    const { data } = await axios({
      method: 'put',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/${TopicId}/replaceMediaTopic`,
      data: requestData,
      params: { isDownloadable },
    });

    return data;
  },

  /**
   * Update topicOrder
   */

  reorderMediaTopic: async (requestData: SkuReOrder[]): Promise<Library> => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/library/reorderMediaTopic`,
      data: requestData,
    });

    return data;
  },

  /**
   * Schedule the library
   * @param id
   * @param requestData
   */
  scheduleLibrary: async (
    id: string,
    requestData: ScheduleLibraryRequestData,
  ): Promise<Library> => {
    const { data } = await axios({
      method: 'post',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/${id}`,
      data: requestData,
    });

    return data;
  },

  /**
   * Quick Click
   * @param id
   */
  quickClick: async (id: string): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${LibrariesRoutes.Libraries}/${id}/quick-click`,
    });
  },
};
