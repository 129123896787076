import { Spinner } from 'react-bootstrap-v5';
import React from 'react';
import clsx from 'clsx';
import styles from './spinner.module.scss';

export default ({
  size,
  className,
  backdropClassName,
  overlap,
}: {
  size?: string;
  className?: string;
  backdropClassName?: string;
  overlap?: boolean;
}) => (
  <div
    className={clsx(
      'w-100 h-100 d-flex align-items-center justify-content-center position-absolute top-0 start-0',
      className,
    )}
  >
    <div
      className={clsx(
        'position-absolute w-100 h-100 start-0 top-0',
        styles.backdrop,
        overlap && styles.backdropOverlap,
        backdropClassName,
      )}
    />
    <Spinner
      as="span"
      role="status"
      animation="border"
      size={size as any}
      className={clsx(styles.backdrop, overlap && styles.spinnerOverlap)}
      aria-hidden="true"
    />
  </div>
);
