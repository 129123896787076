import { useEffect, useState } from 'react';
import { intersection } from 'lodash';

export const isAllSelected = (
  selectedItems: string[],
  currentItems: string[],
) => {
  return !!(
    selectedItems.length &&
    intersection(selectedItems, currentItems).length === currentItems.length
  );
};

export interface TableMultiSelectConfig {
  selectedItems: string[];
  setSelectedItem: (item: string) => void;
  selectAll: () => void;
}

export default (
  currentItems: TableMultiSelectConfig['selectedItems'] = [],
  initialState: TableMultiSelectConfig['selectedItems'] = [],
  search?: string,
): [
  TableMultiSelectConfig['selectedItems'],
  TableMultiSelectConfig['setSelectedItem'],
  TableMultiSelectConfig['selectAll'],
  () => void,
] => {
  const [selectedItems, setSelectedItems] = useState<string[]>(initialState);

  const setSelectedItem = (item: string) => {
    setSelectedItems(
      selectedItems.includes(item)
        ? selectedItems.filter((currentItem) => currentItem !== item)
        : [...selectedItems, item],
    );
  };

  const selectAll = () =>
    setSelectedItems(
      isAllSelected(selectedItems, currentItems)
        ? []
        : [...currentItems, ...selectedItems],
    );

  const reset = () => setSelectedItems([]);

  useEffect(() => {
    if (search) reset();
  }, [search]);

  return [selectedItems, setSelectedItem, selectAll, reset];
};
