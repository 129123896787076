import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CreateUser from '../../../components/Users/CreateUser';
import { createUser } from '../../../store/actions/users';
import AssignUsersToUserGroup from '../../../components/Users/AssignUser';
import { KTSVG } from '../../../lib/metronic/helpers';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import UserAssignmentTarget from '../../../interfaces/user/userAssignmentTarget.interface';

export default ({
  className,
  assignUser,
  userAssignmentTarget,
}: {
  className?: string;
  assignUser?: (data: SelectedValue[]) => void;
  userAssignmentTarget?: UserAssignmentTarget;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return assignUser ? (
    <AssignUsersToUserGroup
      userAssignmentTarget={userAssignmentTarget}
      onSubmit={assignUser}
    >
      <button type="button" className={clsx('btn btn-primary', className)}>
        <KTSVG
          path="/media/icons/duotune/arrows/arr075.svg"
          className="svg-icon-2"
        />{' '}
        {t('users.add_user')}
      </button>
    </AssignUsersToUserGroup>
  ) : (
    <CreateUser
      className={className}
      onSubmit={(data) => {
        dispatch(createUser(data));
      }}
    />
  );
};
