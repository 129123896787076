import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debouncedUpdateSearch } from '../../../utils/browserHistory';
import FiltersDropdown from '../../Dropdown/FiltersDropdown';
import { readFromQueryString } from '../../../utils/queryParams';
import SelectLocation from '../../../containers/SelectLocation';

export default ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const currentUserId = readFromQueryString(search, 'currentUserId') ?? null;
  const [location, setLocation] = useState<string | undefined>(
    readFromQueryString<string>(search, 'locationId') ?? undefined,
  );

  const handleApply = () =>
    debouncedUpdateSearch({
      locationId: location ?? '',
    });

  const handleReset = () =>
    debouncedUpdateSearch({
      locationId: '',
    });

  return (
    <FiltersDropdown
      className={className}
      onApplyFilters={handleApply}
      onReset={handleReset}
    >
      <div className="mb-10">
        <label htmlFor="location" className="form-label fw-bold">
          {t('common.location')}
        </label>
        <SelectLocation
          id="location"
          currentUserId={currentUserId ?? undefined}
          initialValue={location}
          onChange={({ value }) => {
            if (value !== 'loadingValue') setLocation(value);
          }}
          isClearable
        />
      </div>
    </FiltersDropdown>
  );
};
