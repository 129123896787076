import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import App from '../../../../../interfaces/app.interface';
import EditGoogleSlides from '../../../../../containers/Apps/GoogleSlides/EditGoogleSlides';
import ScheduleGoogleSlides from '../../../../../containers/Apps/ScheduleApp';
import { AppDependency } from '../../../../../store/types/apps/globalTypes';
import DeleteGoogleSlides from '../../../../../containers/Apps/DeleteApp';
import { formatDateString } from '../../../../../utils/dateTime';
import PlacementsEnum from '../../../../../enums/placements.enum';
import PreviewFeedModal from '../../PreviewFeedModal';
import Yup from '../../../../../utils/validation';
import Table from '../../../../Table';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({
  googleSlides,
  onUpdate,
}: {
  googleSlides: App;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [showModalFeed, setShowModalFeed] = useState(false);

  return (
    <Row>
      <Cell isEmpty={!googleSlides.name}>
        <div onClick={() => setShowModalFeed(true)} aria-hidden>
          <span className="text-dark cursor-pointer fw-bold text-hover-primary d-block mb-1 fs-6 w-100">
            {googleSlides.name}
          </span>
        </div>
        <PreviewFeedModal
          show={showModalFeed}
          onHide={() => {
            setShowModalFeed(false);
          }}
          title={t('common.preview')}
          selectedGoogleSlidesFeedSchema={{
            type: 'googleSlides',
            createdAt: googleSlides!.createdAt,
            updatedAt: googleSlides!.updatedAt,
            dependency: googleSlides!.dependency as AppDependency,
            id: googleSlides!.id,
            name: googleSlides!.name,
            placement: googleSlides!.placement as PlacementsEnum,
          }}
        />
      </Cell>
      <Cell isEmpty={!googleSlides.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {googleSlides.ownerApp?.firstName} {googleSlides.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!googleSlides.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {googleSlides.createdAt && formatDate(googleSlides.createdAt)}
        </span>
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          <div className="mx-1">
            <EditGoogleSlides googleSlides={googleSlides} onUpdate={onUpdate} />
          </div>

          {googleSlides.placement === PlacementsEnum.FULL_SCREEN && (
            <div className="mx-1">
              <ScheduleGoogleSlides
                app={googleSlides}
                onUpdate={onUpdate}
                infoValidationSchema={{
                  orientation: Yup.string().required(t('required')),
                  duration: Yup.number().required(t('required')).min(1),
                  startDate: Yup.date(),
                  endDate: Yup.date().when('startDate', {
                    is: (startDate: any) => !!startDate,
                    then: Yup.date().min(
                      Yup.ref('startDate'),
                      t('common.validation.endDate'),
                    ),
                  }),
                  isLocked: Yup.boolean(),
                }}
                skipOrientationAuto
                skipOrder
              />
            </div>
          )}

          <div className="mx-1">
            <DeleteGoogleSlides
              title={t('common.delete_confirmation.google_slides')}
              app={googleSlides}
              onUpdate={onUpdate}
              toastMessage="apps.googleSlides.slide_feed_deleted"
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default TableRow;
