import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { map } from 'lodash';
import SortOrder from '../../../../enums/sortOrder.enum';
import LocationsSortField from '../../../../api/endpoints/locations/enums/locationsSortField.enum';
import { readFromQueryString } from '../../../../utils/queryParams';
import Columns, { TableHeaderColumn } from '../../../Table/Columns';
import WithSpinner from '../../../WithSpinner';
import TableRow from './TableRow';
import Table from '../../../Table';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../../utils/hooks/useTableMultiSelect';
import Location from '../../../../interfaces/location.interface';

interface TableViewProps {
  locations: Location[];
  isLoading: boolean;
  sortQueryField?: string;
  orderQueryField?: string;
  preloaderHeight?: number;
  isSelectable?: boolean;
  multiSelectConfig?: TableMultiSelectConfig;
  onUpdate?: () => void;
  onSort?: (sortField: string, isInverted: boolean) => void;
  sortFieldValue?: { field: string; isInverted: boolean };
}

interface DefaultProps extends TableViewProps {
  multiSelectConfig: Required<TableViewProps>['multiSelectConfig'];
}

export default ({
  locations,
  isLoading,
  sortQueryField,
  orderQueryField,
  preloaderHeight = 300,
  isSelectable = false,
  onUpdate,
  onSort,
  sortFieldValue,
  multiSelectConfig: { selectedItems, setSelectedItem, selectAll },
}: DefaultProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [sortField, setSortField] = useState(
    sortFieldValue ?? {
      field: sortQueryField ? readFromQueryString(search, sortQueryField) : '',
      isInverted:
        (orderQueryField && readFromQueryString(search, orderQueryField)) ===
        SortOrder.Ascending,
    },
  );

  useEffect(() => {
    if (sortFieldValue) {
      setSortField(sortFieldValue);
    }
  }, [sortFieldValue]);

  const columns: TableHeaderColumn<LocationsSortField>[] = [
    {
      name: t('locations.name'),
      sortBy: LocationsSortField.Name,
    },
    { name: t('locations.address1'), sortBy: LocationsSortField.Address1 },
    { name: t('locations.address2') },
    { name: t('locations.city'), sortBy: LocationsSortField.City },
    { name: t('locations.state'), sortBy: LocationsSortField.State },
    { name: t('locations.timezone') },
    { name: t('common.actions') },
  ];

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns<LocationsSortField>
            columns={columns}
            checked={isAllSelected(selectedItems, map(locations, 'id'))}
            onChange={selectAll}
            onSort={onSort}
            config={{
              isSelectable,
              sortField,
              setSortField,
              sortQueryField,
              orderQueryField,
            }}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={!isLoading && !locations.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={t('locations.no_location_found')}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {locations.map((location: Location) => (
            <TableRow
              onUpdate={onUpdate}
              key={location.id}
              location={location}
              config={{ isSelectable }}
              isChecked={selectedItems.includes(location.id)}
              onCheck={setSelectedItem}
            />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
