import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import clsx from 'clsx';
import QRCodeStyling, {
  CornerSquareType,
  CornerDotType,
  DotType,
} from 'qr-code-styling';
import Select, { components, OptionProps } from 'react-select';

import FormErrorMessage from '../../../FormErrorMessage';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import Orientation from '../../../../enums/orientation.enum';
import styles from './QrCode.module.scss';
import { CreateQrCodeFeedRequest } from '../../../../store/types/apps/qrCodeFeed';
import TextInput from '../../../TextInput';
import ColorPicker from '../../../ColorPicker';
import AdvancedDropdown from '../../../Dropdown/AdvancedDropdown';
import {
  getRgbaValuesFromString,
  rgbaToHex,
} from '../../../../utils/colorFunctions';
import { IconsForQrCode } from '../../../../constants/IconsQr';
import Tooltip from '../../../Tooltip';

const DotsStyle = [
  { label: 'Square', value: 'square' },
  { label: 'Dots', value: 'dots' },
  { label: 'Rounded', value: ' rounded' },
  { label: 'Extra Rounded', value: 'extra-rounded' },
  { label: 'Classy', value: 'classy' },
  { label: 'Classy Rounded', value: 'classy-rounded ' },
];

const CornerSquareStyle = [
  { label: 'Square', value: 'square' },
  { label: 'Dot', value: 'dot' },
  { label: 'Extra Rounded', value: 'extra-rounded' },
];

const CornerDotStyle = [
  { label: 'Square', value: 'square' },
  { label: 'Dot', value: 'dot' },
];

type CreateQrCodeRequestFormMutated = Omit<
  CreateQrCodeFeedRequest,
  'config' | 'placement'
>;

const qrCodePreview = new QRCodeStyling({
  width: 300,
  height: 300,
  type: 'canvas',
  margin: 0,

  dotsOptions: {
    color: 'black',
    type: DotsStyle[1].value as DotType,
  },
  cornersSquareOptions: {
    color: 'black',
    type: CornerSquareStyle[0].value as CornerSquareType,
  },
  cornersDotOptions: {
    color: 'black',
    type: CornerDotStyle[0].value as CornerDotType,
  },

  backgroundOptions: {
    color: 'white',
  },

  qrOptions: {
    errorCorrectionLevel: 'L',
  },
});
interface CreateQrCodeFeedRequestFormPayload
  extends CreateQrCodeRequestFormMutated {
  qrCodeTitle: string;
  qrCodeMessage: string;
  qrCodeBgColor: string;
  qrCodeTxtColor: string;
  icon?: string;
  iconColor?: string;
  iconSize?: string;
  qrCodeImage?: string;
  qrCodeIconCenter?: string;
  qrCodeSubTitle?: string;
  base64FileType?: string;
  dotsStyle?: string;
  dotsColor?: string;
  cornersSquareStyle?: string;
  cornersSquareColor?: string;
  cornersDotStyle?: string;
  cornersDotColor?: string;
  qrCodeIconImage?: string;
}

export interface CreateFeedModalProps {
  onSubmit: (data: CreateQrCodeFeedRequest) => void;
  qrCode?: App;
  onClose?: () => void;
  isLoading: boolean;
}

export default ({
  onSubmit,
  onClose,
  isLoading,
  qrCode = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();

  const initialValues: CreateQrCodeFeedRequestFormPayload = {
    name: qrCode?.name ?? '',
    qrCodeTitle: qrCode?.dependency?.config?.qrCodeTitle ?? '',
    qrCodeMessage: qrCode?.dependency?.config?.qrCodeMessage ?? '',
    qrCodeBgColor:
      qrCode?.dependency?.config?.qrCodeBgColor ?? 'rgb(255, 255, 255)',
    qrCodeTxtColor:
      qrCode?.dependency?.config?.qrCodeTxtColor ?? 'rgba(0,0,0,1)',
    qrCodeImage: qrCode?.dependency?.config?.qrCodeImage ?? '',
    qrCodeSubTitle: qrCode?.dependency?.config?.qrCodeSubTitle ?? '',
    icon: qrCode?.dependency?.config?.icon ?? '',
    iconColor: qrCode?.dependency?.config?.iconColor ?? '',
    iconSize: qrCode?.dependency?.config?.iconSize ?? 50,
    dotsStyle: qrCode?.dependency?.config?.dotsStyle ?? DotsStyle[1].value,
    dotsColor: qrCode?.dependency?.config?.dotsColor ?? 'rgba(0,0,0,1)',
    cornersSquareStyle:
      qrCode?.dependency?.config?.cornersSquareStyle ??
      CornerSquareStyle[0].value,
    cornersSquareColor:
      qrCode?.dependency?.config?.cornersSquareColor ?? 'rgba(0,0,0,1)',
    cornersDotStyle:
      qrCode?.dependency?.config?.cornersDotStyle ?? CornerDotStyle[0].value,
    cornersDotColor:
      qrCode?.dependency?.config?.cornersDotColor ?? 'rgba(0,0,0,1)',
    qrCodeIconImage: qrCode?.dependency?.config?.qrCodeIconImage ?? '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t('apps.qrCode.validationName')),
    qrCodeMessage: Yup.string().required(t('apps.qrCode.validationMessage')),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateQrCodeFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const [qrCodeTitle, setQrCodeTitle] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'qrCodeTitle' }, initialValues.qrCodeTitle);

  const [subTitle, setSubtitle] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'qrCodeSubTitle' },
    initialValues.qrCodeSubTitle,
  );

  const [qrCodeMessage, setQrCodeMessage] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'qrCodeMessage' }, initialValues.qrCodeMessage);

  const [qrCodeBgColor, setQrCodeBgColor] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'qrCodeBgColor' }, initialValues.qrCodeBgColor);

  const [qrCodeTxtColor, setQrCodeTxtColor] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'qrCodeTxtColor' },
    initialValues.qrCodeTxtColor,
  );

  const [icon, setIcon] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'icon' }, initialValues.icon);

  const [iconColor, setIconColor] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'iconColor' }, initialValues.iconColor);

  const [iconSize, setIconSize] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'iconSize' }, initialValues.iconSize);

  const [dotsStyle, setDotsStyle] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'dotsStyle' }, initialValues.dotsStyle);

  const [dotsColor, setDotsColor] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'dotsColor' }, initialValues.dotsColor);

  const [cornersSquareStyle, setCornersSquareStyle] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'cornersSquareStyle' },
    initialValues.cornersSquareStyle,
  );

  const [cornersSquareColor, setCornersSquareColor] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'cornersSquareColor' },
    initialValues.cornersSquareColor,
  );

  const [cornersDotStyle, setCornersDotStyle] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'cornersDotStyle' },
    initialValues.cornersDotStyle,
  );

  const [cornersDotColor, setCornersDotColor] = useStateWithHookForm<
    CreateQrCodeFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'cornersDotColor' },
    initialValues.cornersDotColor,
  );

  const [isScheduled, setIsScheduled] = useState<boolean>(false);

  const [iconQrcode, setIconQrCode] = useState<any>(false);

  // const [iconQrcode, setIconQrCode] = useStateWithHookForm<
  //   CreateQrCodeFeedRequestFormPayload,
  //   string
  // >(
  //   { setValue, trigger, name: 'qrCodeIconImage' },
  //   initialValues.qrCodeIconImage,
  // );

  const handleUpload = async (selectedFiles: File) => {
    if (selectedFiles) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFiles);
      reader.onloadend = async () => {
        const base64data = reader.result;
        setIconQrCode(base64data);
      };
    }
  };

  const handleOnSubmit = async (data: CreateQrCodeFeedRequestFormPayload) => {
    const qrCodeCanvas = await qrCodePreview.getRawData('png');
    const testPromise = (_qrCodeCanvas: Blob | null): Promise<string> =>
      new Promise((resolve, reject) => {
        if (_qrCodeCanvas) {
          const reader = new FileReader();
          reader.readAsDataURL(_qrCodeCanvas);
          reader.onloadend = async () => {
            if (typeof reader.result === 'string') resolve(reader.result);
            else reject(new Error('no result in file'));
          };
          reader.onerror = async () => {
            console.log('err in qr_code file');
            reject(new Error('err in qr_code file'));
          };
        }
      });
    const qrCodeUri = await testPromise(qrCodeCanvas);
    const {
      name,
      qrCodeMessage: qrCodeFeedUrlData,
      qrCodeBgColor: bg,
      qrCodeTxtColor: fg,
    } = data;

    if (qrCodeUri) {
      const createQrCodeFeedRequest: CreateQrCodeFeedRequest = {
        name,
        isScheduled,
        config: {
          qrCodeTitle,
          qrCodeMessage: qrCodeFeedUrlData,
          qrCodeSubTitle: subTitle,
          qrCodeBgColor: bg,
          qrCodeTxtColor: fg,
          dotsStyle,
          dotsColor,
          cornersSquareStyle,
          cornersSquareColor,
          cornersDotStyle,
          cornersDotColor,
          qrCodeImage: qrCodeUri,
          base64FileType: 'image/png',
          icon,
          iconColor,
          iconSize,
          uploadedQrCode: false,
        },
        zoneId: Orientation.Landscape,
      };
      //    qrCodeIconImage: iconQrcode,
      onSubmit(createQrCodeFeedRequest);
    }
  };

  const createQrCode = async () => {
    const NewportalDiv = document.getElementById('canvas')!;
    qrCodePreview.append(NewportalDiv);

    return qrCodePreview.update({
      data: qrCodeMessage,
      dotsOptions: {
        color: `${rgbaToHex(dotsColor)}`,
        type: dotsStyle as DotType,
      },
      cornersSquareOptions: {
        color: `${rgbaToHex(cornersSquareColor)}`,
        type: cornersSquareStyle as CornerSquareType,
      },
      cornersDotOptions: {
        color: `${rgbaToHex(cornersDotColor)}`,
        type: cornersDotStyle as CornerDotType,
      },
      image: iconQrcode,
      imageOptions: {
        imageSize: 1,
        crossOrigin: 'anonymous',
        hideBackgroundDots: true,
        margin: 0,
      },
    });
  };

  const handleReset = () => {
    setDotsStyle(DotsStyle[1].value);
    setDotsColor('rgba(0,0,0,1)');
    setCornersDotColor('rgba(0,0,0,1)');
    setCornersDotStyle(CornerDotStyle[0].value);
    setCornersSquareColor('rgba(0,0,0,1)');
    setCornersSquareStyle(CornerSquareStyle[0].value);
    setIconQrCode(null);
  };

  React.useEffect(() => {
    createQrCode();
  }, [
    qrCodeMessage,
    dotsStyle,
    cornersDotStyle,
    cornersSquareStyle,
    iconQrcode,
    dotsColor,
    cornersSquareColor,
    cornersDotColor,
    iconQrcode,
  ]);

  const CustomOption = (innerProps: OptionProps<any, false, any>) => {
    const { data } = innerProps;
    return (
      <components.Option {...innerProps}>
        <i className={`${data.value}`} /> {data.label}
      </components.Option>
    );
  };

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id="form"
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex flex-row p-4 w-100">
            <div className="d-flex flex-column mx-2 w-100">
              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" required col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('apps.qrCode.name')}
                </label>
                <TextInput
                  name="name"
                  id="name"
                  value={feedName}
                  onChange={setFeedName}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className="my-1 px-2"
                />
              </p>
              <p className="mt-2 mb-0">
                <label
                  htmlFor="qrCodeMessageLabel"
                  className=" d-inline-flex align-items-center required col-form-label text-lg-end fw-bolder required fs-6 "
                >
                  {t('apps.qrCode.qrCodeMessage')}
                  <Tooltip text={t('apps.qrCode.qrCodeMessageInformation')}>
                    <i className="fas fa-info-circle fs-6 " />
                  </Tooltip>
                </label>

                <div className="input-group">
                  <TextInput
                    name="qrCodeMessage"
                    id="qrCodeMessage"
                    value={qrCodeMessage}
                    onChange={setQrCodeMessage}
                    className="form-control form-control-solid"
                    placeholder="Type here"
                  />
                  <FormErrorMessage
                    name="qrCodeMessage"
                    errors={errors}
                    className="my-1 px-2"
                  />
                </div>
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className="   d-inline-flex align-items-center col-form-label text-lg-end fw-bolder  fs-6"
                >
                  {t('apps.qrCode.qrCodeTitle')}
                  <Tooltip text={t('apps.qrCode.qrCodeTitleInformation')}>
                    <i className="fas fa-info-circle fs-6 " />
                  </Tooltip>
                </label>
                <TextInput
                  name="name"
                  id="name"
                  value={qrCodeTitle}
                  onChange={setQrCodeTitle}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
              </p>
              <p className="mt-2 mb-0">
                <label
                  htmlFor="QrCodeSubtitle"
                  className="  d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.qrCode.qrCodeSubTitle')}
                  <Tooltip text={t('apps.qrCode.qrCodeSubTitleInformation')}>
                    <i className="fas fa-info-circle fs-6 " />
                  </Tooltip>
                </label>
                <div className="input-group">
                  <TextInput
                    name="QrCodeSubtitle"
                    id="QrCodeSubtitle"
                    value={subTitle}
                    onChange={setSubtitle}
                    className="form-control form-control-solid"
                    placeholder="Type here"
                  />
                </div>
              </p>
            </div>

            <div className="d-flex flex-column mx-2 w-100">
              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.qrCode.qrCodeBgColor')}
                </label>
                <ColorPicker
                  id="BgColor"
                  name="BgColor"
                  onSelect={(color) => {
                    setQrCodeBgColor(
                      `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                        color?.b ?? 0
                      }, ${color?.a ?? 1})`,
                    );
                  }}
                  value={getRgbaValuesFromString(qrCodeBgColor)}
                />
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.qrCode.qrCodeTxtColor')}
                </label>
                <ColorPicker
                  id="BgColor"
                  name="BgColor"
                  onSelect={(color) => {
                    setQrCodeTxtColor(
                      `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                        color?.b ?? 0
                      }, ${color?.a ?? 1})`,
                    );
                  }}
                  value={getRgbaValuesFromString(qrCodeTxtColor)}
                />
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.qrCode.qrCodeIcon')}
                  <Tooltip text={t('apps.qrCode.qrCodeIconInformation')}>
                    <i className="fas fa-info-circle fs-6 " />
                  </Tooltip>
                </label>
                <Select
                  options={IconsForQrCode}
                  components={{
                    Option: CustomOption,
                  }}
                  onChange={(val) => {
                    setIcon(val?.value);
                  }}
                  defaultInputValue={icon}
                  isClearable
                />
              </p>

              {icon && (
                <p className="mt-2 mb-0">
                  <div
                    className="row mt-2"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div className="col col-lg-9">
                      <label
                        htmlFor="name"
                        className=" col-form-label text-lg-end fw-bolder fs-6"
                      >
                        {t('apps.qrCode.qrCodeIconSize')}{' '}
                      </label>
                      <TextInput
                        name="QrCodeIconSize"
                        id="QrCodeIconSize"
                        value={iconSize}
                        onChange={setIconSize}
                        className="form-control form-control-solid"
                        placeholder="Type here"
                        type="number"
                      />
                    </div>

                    <div className="col col-lg-3">
                      <label
                        htmlFor="name"
                        className=" col-form-label text-lg-end fw-bolder fs-6"
                      >
                        {t('apps.qrCode.qrCodeIconColor')}
                      </label>
                      <ColorPicker
                        id="BgColor"
                        name="BgColor"
                        onSelect={(color) => {
                          setIconColor(
                            `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                              color?.b ?? 0
                            }, ${color?.a ?? 1})`,
                          );
                        }}
                        value={getRgbaValuesFromString(iconColor)}
                      />
                    </div>
                  </div>
                </p>
              )}
            </div>
          </div>
          {qrCodeMessage && (
            <AdvancedDropdown>
              <div className="d-flex flex-row p-4 w-100">
                <div className="d-flex flex-column mx-2 w-100">
                  <p className="mt-2 mb-0">
                    <label
                      htmlFor="name"
                      className=" d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                    >
                      {t('apps.qrCode.dotsOptions')}

                      <Tooltip
                        text={t('apps.qrCode.qrCodeDotsOptionsInformation')}
                      >
                        <i className="fas fa-info-circle fs-6 " />
                      </Tooltip>
                    </label>

                    <div
                      className="row mt-2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div className="col col-lg-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={false}
                          name="uploadType"
                          options={DotsStyle}
                          onChange={(val) => {
                            if (val?.value) setDotsStyle(val?.value);
                          }}
                          defaultValue={DotsStyle[1]}
                          value={DotsStyle.find(
                            (dot) => dot.value === dotsStyle,
                          )}
                        />
                      </div>

                      <div className="col col-lg-3">
                        {' '}
                        <ColorPicker
                          id="BgColor"
                          name="BgColor"
                          onSelect={(color) => {
                            setDotsColor(
                              `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                                color?.b ?? 0
                              }, ${color?.a ?? 1})`,
                            );
                          }}
                          value={getRgbaValuesFromString(dotsColor)}
                        />
                      </div>
                    </div>
                  </p>
                  <p className="mt-2 mb-0">
                    <label
                      htmlFor="name"
                      className=" d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                    >
                      {t('apps.qrCode.cornersDotOptions')}

                      <Tooltip
                        text={t(
                          'apps.qrCode.qrCodeCornersDotOptionsInformation',
                        )}
                      >
                        <i className="fas fa-info-circle fs-6 " />
                      </Tooltip>
                    </label>

                    <div
                      className="row mt-2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div className="col col-lg-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={false}
                          name="uploadType"
                          options={CornerDotStyle}
                          onChange={(val) => {
                            if (val?.value) setCornersDotStyle(val?.value);
                          }}
                          defaultValue={CornerDotStyle[0]}
                          value={CornerDotStyle.find(
                            (corner) => corner.value === cornersDotStyle,
                          )}
                        />
                      </div>

                      <div className="col col-lg-3">
                        <ColorPicker
                          id="BgColor"
                          name="BgColor"
                          onSelect={(color) => {
                            setCornersDotColor(
                              `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                                color?.b ?? 0
                              }, ${color?.a ?? 1})`,
                            );
                          }}
                          value={getRgbaValuesFromString(cornersDotColor)}
                        />
                      </div>
                    </div>
                  </p>
                </div>

                <div className="d-flex flex-column mx-2 w-100">
                  <p className="mt-2 mb-0">
                    <label
                      htmlFor="name"
                      className=" d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                    >
                      {t('apps.qrCode.cornersSquareOptions')}

                      <Tooltip
                        text={t(
                          'apps.qrCode.qrCodeCornersSquareOptionsInformation',
                        )}
                      >
                        <i className="fas fa-info-circle fs-6 " />
                      </Tooltip>
                    </label>

                    <div
                      className="row mt-2"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <div className="col col-lg-9">
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isSearchable={false}
                          name="uploadType"
                          options={CornerSquareStyle}
                          onChange={(val) => {
                            if (val?.value) setCornersSquareStyle(val?.value);
                          }}
                          defaultValue={DotsStyle[0]}
                          value={CornerSquareStyle.find(
                            (square) => square.value === cornersSquareStyle,
                          )}
                        />
                      </div>

                      <div className="col col-lg-3">
                        {' '}
                        <ColorPicker
                          id="BgColor"
                          name="BgColor"
                          onSelect={(color) => {
                            setCornersSquareColor(
                              `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                                color?.b ?? 0
                              }, ${color?.a ?? 1})`,
                            );
                          }}
                          value={getRgbaValuesFromString(cornersSquareColor)}
                        />
                      </div>
                    </div>
                  </p>

                  <p className="mt-2 mb-0">
                    <label
                      htmlFor="name"
                      className="  col-form-label text-lg-end fw-bolder  fs-6"
                    >
                      {t('apps.qrCode.uploadIcon')}
                    </label>

                    <div className="mb-3">
                      <input
                        className="form-control form-control-sm"
                        id="formFileSm"
                        type="file"
                        onChange={(images) => {
                          if (images?.target?.files)
                            handleUpload(images.target.files[0]);
                        }}
                        accept=".png, .jpg, .jpeg"
                        multiple={false}
                        style={{ marginTop: '0.75rem ' }}
                      />
                    </div>
                  </p>
                </div>
              </div>

              <div className="container">
                <button
                  onClick={handleReset}
                  className="btn btn-sm btn-white btn-active-light-primary me-2 float-end"
                  type="button"
                  style={{ marginBottom: '1rem' }}
                >
                  {t('common.reset')}
                </button>
              </div>
            </AdvancedDropdown>
          )}

          {qrCodeMessage && (
            <div
              className=" d-flex flex-column   flex-center  p-4 w-100"
              style={{ backgroundColor: qrCodeBgColor }}
            >
              {icon && (
                <label htmlFor="name" className=" fw-bolder my-1">
                  <i
                    className={`${icon}`}
                    style={{ color: `${iconColor}`, fontSize: `${iconSize}px` }}
                  />
                </label>
              )}

              <label
                htmlFor="name"
                className="  my-1"
                style={{
                  color: qrCodeTxtColor,
                  fontSize: '150%',
                  fontWeight: 'bold',
                  paddingBottom: '12px',
                }}
              >
                {qrCodeTitle && <> {qrCodeTitle}</>}
              </label>
              {qrCodeMessage && <div id="canvas" />}

              <label
                htmlFor="name"
                className=" fw-bolder my-1"
                style={{ color: qrCodeTxtColor, opacity: '0.6' }}
              >
                {subTitle && <> {subTitle}</>}
              </label>
            </div>
          )}
          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {t('common.save')}
              </button>
              {!Object.keys(qrCode).length && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                >
                  {t('common.save_schedule')}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
