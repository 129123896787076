import { call, put, takeEvery } from 'redux-saga/effects';
import CustomersActions from '../actions/customers/customersActions.enum';
import GetCustomersListAction from '../actions/customers/interfaces/getCustomersListAction.interface';
import GetCustomersListResponseData from '../../api/endpoints/customers/interfaces/getCustomersListResponseData.interface';
import api from '../../api';
import {
  getCustomersListSuccess,
  setCustomersLoading,
} from '../actions/customers';

/**
 * The users retrieval worker
 * @param payload
 */
function* getCustomersRequestWorker({ payload }: GetCustomersListAction) {
  yield put(setCustomersLoading({ loading: true }));

  try {
    const responseData: GetCustomersListResponseData = yield call(
      api.customers.getCustomersListV2,
      payload,
    );

    yield put(getCustomersListSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setCustomersLoading({ loading: false }));
}

export default function* customersWatcher() {
  yield takeEvery<GetCustomersListAction>(
    CustomersActions.GET_CUSTOMERS_LIST,
    getCustomersRequestWorker,
  );
}
