import React from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap-v5';
import Playlist from '../../interfaces/playlist.interface';
import PaginationQueryParam from '../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../enums/queryParams/sortingQueryParam.enum';
import { SelectedValue } from '../Select/SelectAsync';
import PlaylistsTable from './PlaylistsTable';
import Pagination from '../Pagination/QueryParamPagination';
import AssignPlaylist from '../../containers/Playlists/AssignPlaylistToGroup';
import Card from '../Card';
import { KTSVG } from '../../lib/metronic/helpers';
import useTableMultiSelect from '../../utils/hooks/useTableMultiSelect';
import Search from '../Search/QueryParamSearch';
import UsersGroupsQueryParam from '../../api/endpoints/usersGroups/enums/usersGroupsQueryParam.enum';
import UpdatePlaylistRequestData from '../../api/endpoints/playlists/interfaces/updatePlaylistRequestData.interface';
import BulkEditPlaylist from '../../containers/Playlists/BulkEditPlaylists';
import CreatePlaylist from '../../containers/Playlists/CreatePlaylist';
import FiltersDropdown from './FiltersDropdown';
import BulkRemovePlaylists from '../../containers/Playlists/RemovePlaylist';
import useRouteConfig from '../../utils/hooks/useRouteConfig';
import GlobalState from '../../store/reducers/globalState.interface';
import RestrictedAccess from '../../containers/RestrictedAccess';
import { UserRole } from '../../enums/userRole.enum';
import BulkEditDropdown from '../Playlist/PlaylistContent/BulkEditDropdown';
import ContentIcon from '../Contents/ContentTable/TableRow/ContentPreviewIcon/ContentIcon';

const tableRowHeight = 61;
const options = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 1000, label: '1000' },
];
export default ({
  playlists,
  total,
  appTypes,
  isLoading,
  toolbarElement,
  assignPlaylistToDeviceGroup,
  unAssignPlaylistFromDeviceGroup,
  handleEditPlaylistInDeviceGroup,
  disableTitle,
  onUpdate,
  deviceName,
}: {
  playlists: Playlist[];
  total: number;
  appTypes?: string[];
  isLoading: boolean;
  assignPlaylistToDeviceGroup?: (data: SelectedValue[]) => void;
  unAssignPlaylistFromDeviceGroup?: (playlistId: string, id: string) => void;
  handleEditPlaylistInDeviceGroup?: (
    id: string,
    data: UpdatePlaylistRequestData,
  ) => void;
  toolbarElement?: React.ReactNode;
  disableTitle?: boolean;
  onUpdate: () => void;
  deviceName?: string | undefined;
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(10);
  const [currentRoute] = useRouteConfig();
  const { placeholder } = useSelector(
    (state: GlobalState) => state.breadcrumbs,
  );
  const [selectedItems, setSelectedItem, selectAll, reset] =
    useTableMultiSelect(map(playlists, 'id'), []);

  const onEditPlaylist = (id: string, data: UpdatePlaylistRequestData) => {
    if (handleEditPlaylistInDeviceGroup) {
      handleEditPlaylistInDeviceGroup(id, data);
    }
    reset();
    onUpdate();
  };

  const onCopyPlaylist = () => {
    onUpdate();
  };
  const { roles } = useSelector((state: GlobalState) => state.userData.user);
  const isSuperAdmin = roles?.includes(UserRole.SuperAdmin);
  return (
    <Card>
      <Card.Header>
        <div className="d-flex">
          <div>
            {!disableTitle && (
              <Card.Header.Title>
                <Card.Header.Title.Label>
                  {currentRoute && placeholder[currentRoute?.route] && (
                    <span className="text-capitalize">
                      {`${placeholder[currentRoute.route]} - `}
                    </span>
                  )}
                  {isSuperAdmin
                    ? `${deviceName} - Playlists`
                    : t('playlists.playlists')}
                </Card.Header.Title.Label>
              </Card.Header.Title>
            )}
          </div>
          <>
            {currentRoute && placeholder[currentRoute?.route] && (
              <>
                {appTypes?.length && appTypes?.length > 0 ? (
                  <Card
                    className="border ms-4 d-flex align-items-center justify-content-center"
                    withoutMargins
                  >
                    <div className="d-flex">
                      {appTypes?.map((type) => (
                        <span className="m-2">
                          <ContentIcon typeApp={type} />
                        </span>
                      ))}
                    </div>
                  </Card>
                ) : (
                  ''
                )}
              </>
            )}
          </>
        </div>
        <Card.Header.Toolbar>
          {toolbarElement}
          <Search
            queryField={UsersGroupsQueryParam.Search}
            pageNumberQueryField={PaginationQueryParam.Page}
            placeholder={t('playlists.search_playlists')}
            className="me-2"
          />
          <div className="d-flex flex-wrap flex-stack">
            {selectedItems.length > 1 && !isSuperAdmin && (
              <div className="me-3">
                <BulkEditDropdown>
                  <Dropdown.Menu>
                    <BulkEditPlaylist selectedItems={selectedItems}>
                      <Dropdown.Item>{t('common.edit')}</Dropdown.Item>
                    </BulkEditPlaylist>
                    <BulkRemovePlaylists
                      selectedItems={selectedItems}
                      onSuccess={() => {
                        reset();
                        onUpdate();
                      }}
                    >
                      <Dropdown.Item>{t('common.delete')}</Dropdown.Item>
                    </BulkRemovePlaylists>
                  </Dropdown.Menu>
                </BulkEditDropdown>
              </div>
            )}
            <FiltersDropdown className="me-3" />
            {assignPlaylistToDeviceGroup ? (
              <AssignPlaylist
                assignPlaylistToDeviceGroup={assignPlaylistToDeviceGroup}
              >
                <button type="button" className="btn btn-primary">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr087.svg"
                    className="svg-icon-3"
                  />
                  {t('playlists.new_playlist')}
                </button>
              </AssignPlaylist>
            ) : (
              <RestrictedAccess
                allowedRoles={[
                  UserRole.SuperAdmin,
                  UserRole.Admin,
                  UserRole.AccountOwner,
                  UserRole.Manager,
                  UserRole.IndigoAdmin,
                  UserRole.Editor,
                ]}
                fallback={<CreatePlaylist onSuccess={onUpdate} />}
              >
                <CreatePlaylist onSuccess={onUpdate} />
              </RestrictedAccess>
            )}
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <>
          <PlaylistsTable
            isSelectable
            playlists={playlists}
            isLoading={isLoading}
            sortQueryField={SortingQueryParam.SortBy}
            orderQueryField={SortingQueryParam.SortOrder}
            preloaderHeight={pageSize * tableRowHeight}
            unAssignPlaylistFromDeviceGroup={unAssignPlaylistFromDeviceGroup}
            onEditPlaylist={onEditPlaylist}
            onCopyPlaylist={onCopyPlaylist}
            multiSelectConfig={{
              selectedItems,
              setSelectedItem,
              selectAll,
            }}
          />
          <div className="d-flex justify-content-between align-items-center">
            <div className="w-80px mb-4">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(+e.target.value);
                }}
                className="form-select form-select-sm form-select-solid"
              >
                {options.map((option) => (
                  <option key={`${option.label}`} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex-fill ps-5">
              <Pagination
                total={total}
                pageSize={pageSize}
                pageNumberQueryField={PaginationQueryParam.Page}
                pageSizeQueryField={PaginationQueryParam.Limit}
                className="mt-3 mb-5"
              />
            </div>
          </div>
        </>
      </Card.Body>
    </Card>
  );
};
