import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Templates from '../../components/Templates';
import Template from '../../interfaces/template.interface';
import api from '../../api';
import { readAllFromQueryParams } from '../../utils/queryParams';

export default () => {
  const { search } = useLocation();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);

  const getTemplates = async (recentSearch: string) => {
    try {
      setIsLoading(true);
      const { items, meta } = await api.templates.getTemplates(
        readAllFromQueryParams(recentSearch),
      );

      setTotal(meta.totalItems);
      setTemplates(items);
    } catch (e) {
      // Do nothing for now
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getTemplates(search);
  }, [search]);

  return (
    <Templates
      templates={templates}
      total={total}
      isLoading={isLoading}
      onUpdate={() => getTemplates(search)}
      resetOnSearch
    />
  );
};
