import React, { useEffect } from 'react';

import IContent from '../../../../../../../interfaces/content.interface';
import WeatherThumbnail from '../../../../../../Weather/WeatherThumbnail';

export default ({ content }: { content: IContent }) => {
  const [iframeRef, setIframeRef] = React.useState<HTMLIFrameElement>();
  const [url, setUrl] = React.useState('');

  const weatherData = content.app?.dependency?.weather;

  useEffect(() => {
    if (iframeRef?.contentWindow) {
      setUrl(
        `${
          process.env.REACT_APP_PLAYER_PREVIEW_URL
        }/apps/weather/weather.html?weather=${JSON.stringify(weatherData)}
        &config=${JSON.stringify(content.app?.dependency?.config ?? '')
          .replace('#', '__ht__')
          .toLowerCase()
          .replace('color', 'Color')}&ratio=${50 / 1920}`,
      );

      iframeRef.contentWindow.document.body.style.transformOrigin = 'top left';
      iframeRef.style.display = 'block';
    }
  }, [iframeRef]);

  return (
    <WeatherThumbnail src={url} setRef={(e) => setIframeRef(e || undefined)} />
  );
};
