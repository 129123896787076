import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import PlaylistComponent from '../../components/Playlist';
import Playlist from '../../interfaces/playlist.interface';
import api from '../../api';
import PlaylistTabs from '../../enums/playlistTabs.enum';
import { getRouteId } from '../../utils/routes';

export default () => {
  const { id } = useParams<{ id: string }>();
  const [playlist, setPlaylist] = useState<Playlist>({} as Playlist);
  const [isPlaylistLoading, setIsPlaylistLoading] = useState(true);
  const { pathname } = useLocation();

  const fetchPlaylist = () => {
    setIsPlaylistLoading(true);

    api.playlists
      .getPlaylist({
        playlistId: id,
      })
      .then((res) => {
        setPlaylist(res);
      })
      .finally(() => setIsPlaylistLoading(false));
  };

  useEffect(() => {
    fetchPlaylist();
  }, []);

  return (
    <PlaylistComponent
      currentTab={
        (getRouteId(pathname) as PlaylistTabs) ?? PlaylistTabs.Contents
      }
      playlist={playlist}
      isPlaylistLoading={isPlaylistLoading}
      onEdit={fetchPlaylist}
    />
  );
};
