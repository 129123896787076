import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import License from '../../../interfaces/license.interface';
import { prepareHookFromState } from '../../../utils/hooks/useStateWithHookForm';
import LicensingForm, { LicensingFormInputs } from '../LicensingForm';
import Device from '../../../interfaces/devices/device.interface';
import validation from '../../../utils/validation';
import WithSpinner from '../../WithSpinner';
import Modal from '../../Modal';

export default ({
  onSubmit,
  onHide,
  isModalVisible,
  isLoading,
  license,
  device,
}: {
  isModalVisible: boolean;
  onSubmit: (data: Partial<LicensingFormInputs>) => void;
  onHide?: () => void;
  isLoading: boolean;
  license?: License;
  device?: Device;
}) => {
  const { t } = useTranslation();
  const defaultValues: Partial<LicensingFormInputs> = {
    licenseTypeId: license?.licenseType?.id ?? '',
    isLibrary: !!license?.isLibrary,
    isActive: !!license?.isActive,
    isTrial: !!license?.isTrial,
    billingRate: license?.billingRate,
    licenseTerm: license?.licenseTerm,
    isLibraryTrial: !!license?.isLibraryTrial,
    startDate: license?.startDate,
    expireDate: license?.expireDate,
    libraryAccessStartDate: license?.libraryAccessStartDate || undefined,
    libraryAccessExpireDate: license?.libraryAccessExpireDate || undefined,
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<LicensingFormInputs>>({
    resolver: yupResolver(
      validation
        .object()
        .shape(
          {
            licenseTypeId: validation.string().required(),
            startDate: validation.date().required(),
            expireDate: validation.date().required(),
            libraryAccessStartDate: validation
              .date()
              .when(['isLibrary', 'isActive'], {
                is: (isLibrary?: boolean, isActive?: boolean) =>
                  isLibrary && isActive,
                then: validation.date().required(),
                otherwise: validation.date(),
              }),
            libraryAccessExpireDate: validation
              .date()
              .when(['isLibrary', 'isActive'], {
                is: (isLibrary?: boolean, isActive?: boolean) =>
                  isLibrary && isActive,
                then: validation.date().required(),
                otherwise: validation.date(),
              }),
          },
          [
            ['libraryAccessStartDate', 'isLibrary'],
            ['libraryAccessExpireDate', 'isLibrary'],
          ],
        )
        .required(),
    ),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<LicensingFormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [licenseTypeId, setLicenseType] =
    useFs<LicensingFormInputs['licenseTypeId']>('licenseTypeId');
  const [startDate, setStartDate] =
    useFs<LicensingFormInputs['startDate']>('startDate');
  const [expireDate, setEndDate] =
    useFs<LicensingFormInputs['expireDate']>('expireDate');
  const [libraryAccessStartDate, setLibraryStartDate] = useFs<
    LicensingFormInputs['libraryAccessStartDate']
  >('libraryAccessStartDate');
  const [libraryAccessExpireDate, setLibraryEndDate] = useFs<
    LicensingFormInputs['libraryAccessExpireDate']
  >('libraryAccessExpireDate');
  const [isActive, setIsActive] =
    useFs<LicensingFormInputs['isActive']>('isActive');
  const [isTrial, setIsTrial] =
    useFs<LicensingFormInputs['isTrial']>('isTrial');
  const [billingRate, setBillingRate] =
    useFs<LicensingFormInputs['billingRate']>('billingRate');
  const [licenseTerm, setLicenseTerm] =
    useFs<LicensingFormInputs['licenseTerm']>('licenseTerm');
  const [isLibraryTrial, setIsLibraryTrial] =
    useFs<LicensingFormInputs['isLibraryTrial']>('isLibraryTrial');
  const [isLibrary, setIsLibrary] =
    useFs<LicensingFormInputs['isLibrary']>('isLibrary');

  return (
    <Modal
      title={t('devices.licensing.licensing')}
      widthDefaultSize={false}
      show={isModalVisible}
      onHide={onHide}
      bodyClassName="py-5"
      withFooter={false}
    >
      <WithSpinner isLoading={isLoading} className="min-h-600px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <LicensingForm
            device={device}
            errors={errors}
            fieldsConfig={{
              isActive,
              setIsActive,
              isTrial,
              setIsTrial,
              licenseTypeId,
              setLicenseType,
              startDate,
              setStartDate,
              expireDate,
              setEndDate,
              billingRate,
              setBillingRate,
              isLibrary,
              setIsLibrary,
              licenseTerm,
              setLicenseTerm,
              isLibraryTrial,
              setIsLibraryTrial,
              libraryAccessStartDate,
              setLibraryStartDate,
              libraryAccessExpireDate,
              setLibraryEndDate,
            }}
          />

          <Modal.Separator withoutDefaultMargins className="mt-6 mb-7" />
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {t('common.submit')}
            </button>
          </div>
        </form>
      </WithSpinner>
    </Modal>
  );
};
