import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PlaylistModal from '../../../components/PlaylistModal';
import PlaylistFormInputs from '../../../components/PlaylistModal/playlistFormInputs.interface';
import UpdatePlaylistRequestData from '../../../api/endpoints/playlists/interfaces/updatePlaylistRequestData.interface';
import IPlaylist from '../../../interfaces/playlist.interface';
import Tooltip from '../../../components/Tooltip';
import api from '../../../api';

const EditPlaylist = ({
  playlist,
  children,
  onSuccess,
}: {
  playlist: IPlaylist;
  children?: React.ReactNode;
  onSuccess?: (id: string, data: UpdatePlaylistRequestData) => void;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const open = () => {
    setModalShown(true);
  };
  const close = () => {
    setModalShown(false);
  };

  const handleSubmit = (data: PlaylistFormInputs) => {
    setIsLoading(true);

    const updatePlaylistData = {
      startDate: data.startDate && data.startDate.toISOString(),
      endDate: data.endDate && data.endDate.toISOString(),
      name: data.name,
      priority: data.priority,
      locked: data.locked,
      isActive: data.isActive,
      tags: data.tags,
      isTrial: data?.isTrial,
      industryId: data?.industryId?.length ? data?.industryId : undefined,
      templateId: data.templateId,
      deviceIds: data.devices?.length
        ? (data.devices?.map((item) => item.value) as string[])
        : [],
      deviceGroupIds: data.deviceGroupIds?.length
        ? (data.deviceGroupIds?.map((item) => item.value) as string[])
        : [],
    };

    api.playlists
      .updatePlaylist(playlist.id, updatePlaylistData)
      .then(() => {
        close();

        if (onSuccess) {
          onSuccess(playlist.id, updatePlaylistData);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.edit')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-edit" />
            </span>
          </Tooltip>
        )}
      </span>

      <PlaylistModal
        isLoading={isLoading}
        playlist={playlist}
        onSubmit={handleSubmit}
        shown={modalShown}
        modalTitle={t('playlists.edit_playlist')}
        hide={close}
        isEditMode
      />
    </>
  );
};

export default EditPlaylist;
