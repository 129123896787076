import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { useParams } from 'react-router-dom';
import TableRow from '../../Devices/TableView/TableRow';
import Columns, { TableHeaderColumn } from '../../Table/Columns';
import DevicesSortField from '../../../api/endpoints/devices/enums/devicesSortField.enum';
import DevicesQueryParam from '../../../api/endpoints/devices/enums/devicesQueryParam.enum';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import Device from '../../../interfaces/devices/device.interface';
import SortOrder from '../../../enums/sortOrder.enum';
import { SortField } from '../../../utils/sorting';
import WithSpinner from '../../WithSpinner';
import Pagination from '../../Pagination';
import Search from '../../Search';
import Table from '../../Table';
import useTableMultiSelect, {
  isAllSelected,
} from '../../../utils/hooks/useTableMultiSelect';
import DeviceCells from '../../../enums/tableCells/deviceCells.enum';
import SelectCompany from '../../../containers/Companies/SelectCompany';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';

const preloaderHeight = 227;
const pageSize = 10;

export default ({
  devices,
  isLoading,
  total,
  onSearchDataChange,
  onSelectionChange,
  preselectedItems,
  createDeviceGroupTarget,
  libraryPublish,
  copyContent,
  skipLicence,
  isPowerBiPublished,
}: {
  devices: Device[];
  isLoading: boolean;
  skipLicence?: boolean;
  total: number;
  copyContent?: boolean;
  onSearchDataChange: (searchData: { [key: string]: string }) => void;
  onSelectionChange: (selectedItems: string[]) => void;
  preselectedItems: string[];
  createDeviceGroupTarget?: any;
  libraryPublish?: boolean;
  isPowerBiPublished?: boolean;
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const [company, setCompany] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [sortField, setSortField] = useState<SortField>({
    field: null,
    isInverted: false,
  });
  const { isTrial } = useSelector((state: GlobalState) => state.userData.user);

  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(devices, 'id'),
    preselectedItems,
  );
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const isSuperAdmin = roles.includes(UserRole.SuperAdmin);
  const currentUserId = useParams<{ id: string }>();
  useEffect(() => {
    onSearchDataChange({
      [DevicesQueryParam.Search]: searchValue,

      [DevicesQueryParam.Company]: company,
      [PaginationQueryParam.Limit]: String(pageSize),
      [PaginationQueryParam.Page]: String(pageNumber),
      [SortingQueryParam.SortBy]: sortField.field ?? '',
      [SortingQueryParam.SortOrder]: sortField.isInverted
        ? SortOrder.Ascending
        : SortOrder.Descending,
    });
  }, [searchValue, pageNumber, sortField, company]);

  useEffect(() => onSelectionChange(selectedItems), [selectedItems]);

  const columns: TableHeaderColumn<DevicesSortField>[] = isTrial
    ? [
        {
          name: t('devices.device_name'),
          sortBy: DevicesSortField.Name,
        },
        { name: t('common.location') },
        { name: t('common.status') },
        { name: t('common.playlist') },
        { name: t('devices.last_active') },
      ]
    : [
        {
          name: t('devices.device_name'),
          sortBy: DevicesSortField.Name,
        },
        { name: t('common.location') },
        { name: t('common.status') },
        { name: t('common.playlist') },
        { name: t('devices.device_group') },
        { name: t('devices.last_active') },
      ];
  return (
    <>
      <div className="d-flex flex-row">
        <Search
          searchValue={searchValue}
          onChange={setSearchValue}
          placeholder={t('devices.search_devices')}
          className="me-2"
        />
        {/* 
        <Search
          searchValue={searchValue}
          onChange={setSearchValue}
          placeholder={t('devices.search_devices')}
          wrapperClassName="mb-3"
        /> */}
        {isSuperAdmin && (
          <div className="min-w-200px ms-4 me-2 mt-2">
            <SelectCompany
              id="company"
              currentUserId={
                libraryPublish || copyContent ? undefined : currentUserId?.id
              }
              onChange={({ value }) => {
                if (value !== 'loadingValue') setCompany(value ?? '');
              }}
              isClearable
            />
          </div>
        )}
      </div>

      <Table>
        <Table.Head>
          <Table.Head.Row>
            <Columns<DevicesSortField>
              columns={columns}
              config={{
                sortField,
                setSortField,
                isSelectable: true,
                disabled:
                  (createDeviceGroupTarget &&
                    !devices?.every(
                      (device) =>
                        device?.license?.licenseType?.id ===
                        devices?.[0]?.license?.licenseType?.id,
                    )) ||
                  (isPowerBiPublished &&
                    devices.filter(
                      (device) =>
                        device?.license?.licenseType?.id !== 'Enterprise',
                    ).length > 0),
              }}
              onChange={selectAll}
              checked={isAllSelected(selectedItems, map(devices, 'id'))}
            />
          </Table.Head.Row>
        </Table.Head>
        <Table.Body
          isEmpty={!isLoading && devices.length === 0}
          emptyStateHeight={preloaderHeight}
          emptyStateChildren={t('devices.table.no_one_device_found')}
        >
          <WithSpinner
            isLoading={isLoading}
            style={{ minHeight: `${preloaderHeight}px` }}
            isForTable
            size="md"
          >
            {devices.map((device) => (
              <TableRow
                key={device?.id}
                device={device}
                isPowerBiPublished={isPowerBiPublished}
                selectedItemsLicenseType={devices
                  ?.filter(({ id }) => selectedItems.includes(id))
                  ?.map(({ id, license }) => {
                    return {
                      id,
                      licenseTypeId: license?.licenseType?.id,
                    };
                  })}
                isChecked={selectedItems.includes(device.id)}
                onCheck={setSelectedItem}
                skipLicence={skipLicence}
                config={{
                  skipActions: true,
                  disableLinks: true,
                  isSelectable: true,
                  skipColumns: [
                    DeviceCells.AspectRatio,
                    DeviceCells.Type,
                    DeviceCells.PlanName,
                    DeviceCells.Company,
                    DeviceCells.LastOnline,
                    DeviceCells.Playlist,
                    DeviceCells.TimeZone,
                    DeviceCells.License,
                  ],
                }}
              />
            ))}
          </WithSpinner>
        </Table.Body>
      </Table>
      <Pagination
        total={total}
        pageSize={pageSize}
        pageNumber={pageNumber}
        onPageNumberUpdate={setPageNumber}
        className="mt-3"
      />
    </>
  );
};
