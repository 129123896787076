import * as React from 'react';

import Modal from '../../components/Modal';
import ButtonDropdown from '../../components/Dropdown/ButtonDropdown';

import { FileMimeType } from '../../store/types/fileManager';

export interface FilterModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (fileTypeFilter: FileMimeType | null) => void;
  title: string;
  withHeader: boolean;
  widthDefaultSize: boolean;
  isLoading: boolean;
}

interface FileTypeFilter {
  fileTypeFilter: FileMimeType | null;
  fileTypeFilerText: string;
}

const FilterModal = ({
  show,
  onHide,
  title,
  withHeader,
  widthDefaultSize,
  onSubmit,
  isLoading = false,
}: FilterModalProps) => {
  const [{ fileTypeFilter, fileTypeFilerText }, setFileTypeFilter] =
    React.useState<FileTypeFilter>({
      fileTypeFilter: null,
      fileTypeFilerText: 'All',
    });

  const resetFilter = React.useCallback(
    () => setFileTypeFilter({ fileTypeFilter: null, fileTypeFilerText: 'All' }),
    [],
  );

  return (
    <Modal
      withHeader={withHeader}
      title={title}
      show={show}
      onHide={onHide}
      widthDefaultSize={widthDefaultSize}
      withFooter
      footerContent={
        <div>
          <button
            type="button"
            className="btn btn-light-primary me-2"
            onClick={resetFilter}
          >
            Reset
          </button>
          <button
            id="submit"
            type="button"
            className="btn btn-primary"
            onClick={() => onSubmit(fileTypeFilter)}
          >
            {isLoading ? (
              <span>
                Filtering{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            ) : (
              <span>Apply</span>
            )}
          </button>
        </div>
      }
    >
      <div className="d-flex flex-column align-items-start">
        <div className="flex flex-column my-2 w-100">
          <span className="text-gray-900 my-1">Tag</span>
          <ButtonDropdown
            text="Tag"
            actionButtonStyles={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            items={[
              {
                key: 'tag',
                content: <span>Tags</span>,
                disabled: true,
              },
            ]}
          />
        </div>

        <div className="flex flex-column my-2 w-100">
          <span className="text-gray-900 my-1">Type</span>

          <ButtonDropdown
            text={fileTypeFilerText}
            actionButtonStyles={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            items={[
              {
                key: 'all',
                content: (
                  <span
                    className="w-100"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() =>
                      setFileTypeFilter({
                        fileTypeFilter: null,
                        fileTypeFilerText: 'All',
                      })
                    }
                  >
                    All
                  </span>
                ),
              },
              ...Object.entries(FileMimeType).map(([key, value]) => ({
                key,
                content: (
                  <span
                    className="w-100"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() =>
                      setFileTypeFilter({
                        fileTypeFilter: value,
                        fileTypeFilerText: key,
                      })
                    }
                  >
                    {key}
                  </span>
                ),
              })),
            ]}
          />
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
