import clsx from 'clsx';
import React from 'react';
import styles from '../../../tableRow.module.scss';
import playlistStyles from '../../../../../../Playlist/Playlist.module.scss';
import IContent from '../../../../../../../interfaces/content.interface';

export default ({ content }: { content: IContent }) => (
  <video
    src={content.mediaGroup?.medias[0]?.url}
    autoPlay={false}
    className={clsx(
      'h-30px',
      styles.thumbnail,
      playlistStyles.image_background,
    )}
  >
    <track kind="captions" />
  </video>
);
