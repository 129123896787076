import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import html5Logo from '../../assets/images/apps/html5.png';
import tickerLogo from '../../assets/images/apps/Bullhorn.png';
import powerBiLogo from '../../assets/images/apps/powerBi.svg';
import youtubeLogo from '../../assets/images/apps/youtube.svg';
import weatherLogo from '../../assets/images/apps/PartlyCloud.png';
// import twitterLogo from '../../assets/images/apps/twitter.svg';
import instagramLogo from '../../assets/images/apps/instagram.svg';
import facebookLogo from '../../assets/images/apps/facebook.svg';
import severeWeatherLogo from '../../assets/images/apps/Storm.png';
import qrCodeLogo from '../../assets/images/apps/qrCode.svg';
import msTeams from '../../assets/images/apps/microsoft-teams.svg';
import rss from '../../assets/images/apps/rss.svg';
import countUpTimer from '../../assets/images/apps/countUpTimer.svg';
import countDownTimer from '../../assets/images/apps/countDownTimer.svg';
import daysSafe from '../../assets/images/apps/daysSafe.svg';
import liveStream from '../../assets/images/apps/liveStream.svg';
import canva from '../../assets/images/apps/canva.svg';
import googleDocs from '../../assets/images/apps/google_docs.svg';
import googleCalendar from '../../assets/images/apps/google_calendar.svg';
import googleSlides from '../../assets/images/apps/google_slides.svg';
import sheets from '../../assets/images/apps/sheets.svg';
import AppIcon from '../../components/AppIcon';
import Card from '../../components/Card';
import config from '../../routing/config';

import account from '../../api/endpoints/account';
import GlobalState from '../../store/reducers/globalState.interface';
import { getUserData } from '../../store/actions/userData';
import Tooltip, { Placement } from '../../components/Tooltip';

export default () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const didUseVisitHereBefore = useSelector(
    (state: any) => state.userData?.user?.onboarding?.viewAppsPage,
  );
  const isTrial = useSelector((state: GlobalState) => {
    return state.userData?.user?.isTrial;
  });
  const userLoggedIn = useSelector((state: GlobalState) => {
    return state.userData?.user;
  });

  useEffect(() => {
    dispatch(getUserData());
    if (!didUseVisitHereBefore) {
      (async () => {
        await account.updateOnboardingAppsAccessedFirstTime();
      })();
    }
  }, [didUseVisitHereBefore]);

  const apps =
    // process.env.REACT_APP_ENV === 'prod'
    //   ? [
    //       {
    //         id: '1',
    //         label: 'Youtube',
    //         iconPath: youtubeLogo,
    //         to: config.youtube.route,
    //       },
    //       {
    //         id: '2',
    //         label: 'Canva',
    //         iconPath: canva,
    //         to: config.canva.route,
    //       },
    //       {
    //         id: '3',
    //         label: 'HTML5',
    //         iconPath: html5Logo,
    //         to: config.html5.route,
    //       },
    //       {
    //         id: '4',
    //         label: 'RSS',
    //         iconPath: rss,
    //         to: config.rss.route,
    //       },
    //       {
    //         id: '5',
    //         label: 'Twitter',
    //         iconPath: twitterLogo,
    //         to: config.twitter.route,
    //       },
    //       {
    //         id: '6',
    //         label: 'Instagram',
    //         iconPath: instagramLogo,
    //         to: config.instagram.route,
    //       },
    //       {
    //         id: '7',
    //         label: 'Facebook',
    //         iconPath: facebookLogo,
    //         to: config.facebook.route,
    //       },

    //       {
    //         id: '8',
    //         label: 'PowerBi',
    //         iconPath: powerBiLogo,
    //         to: config.powerBi.route,
    //       },

    //       {
    //         id: '9',
    //         label: 'Google Sheets',
    //         iconPath: sheets,
    //         to: config.googleSheets.route,
    //       },
    //       {
    //         id: '10',
    //         label: 'Google Docs',
    //         iconPath: googleDocs,
    //         to: config.googleDocs.route,
    //       },
    //       {
    //         id: '11',
    //         label: 'Google Calendar',
    //         iconPath: googleCalendar,
    //         to: config.googleCalendar.route,
    //       },
    //       {
    //         id: '12',
    //         label: 'Google Slides',
    //         iconPath: googleSlides,
    //         to: config.googleSlides.route,
    //       },

    //       {
    //         id: '13',
    //         label: 'Weather',
    //         iconPath: weatherLogo,
    //         to: config.weather.route,
    //       },
    //       {
    //         id: '14',
    //         label: 'Severe Weather',
    //         iconPath: severeWeatherLogo,
    //         to: config.severeWeather.route,
    //       },
    //       {
    //         id: '15',
    //         label: 'Ticker',
    //         iconPath: tickerLogo,
    //         to: `${config.tickers.route}?isCompleted=false`,
    //       },

    //       {
    //         id: '16',
    //         label: 'QR Code',
    //         iconPath: qrCodeLogo,
    //         to: config.qrCode.route,
    //       },
    //       {
    //         id: '16',
    //         label: 'MS Teams',
    //         iconPath: msTeams,
    //         to: config.MsTeams.route,
    //       },
    //     ]
    //   :
    [
      // {
      //   id: '1',
      //   label: 'Emergency Alerts',
      //   iconPath:
      //     'https://media.istockphoto.com/vectors/silane-alarm-signal-alert-icon-for-danger-from-an-accident-vector-id1171161711?k=20&m=1171161711&s=612x612&w=0&h=VVz14JrH56wV0F5ADOSwbfyWNWs_8qfkmQdzPHrG1-Q=',
      //   to: config.emergencyAlerts.route,
      // },
      {
        id: '1',
        label: 'Youtube',
        iconPath: youtubeLogo,
        to: config.youtube.route,
      },
      {
        id: '2',
        label: 'Canva',
        iconPath: canva,
        to: config.canva.route,
      },
      {
        id: '3',
        label: 'HTML5',
        iconPath: html5Logo,
        to: config.html5.route,
      },
      {
        id: '4',
        label: 'RSS',
        iconPath: rss,
        to: config.rss.route,
      },
      // {
      //   id: '5',
      //   label: 'Twitter',
      //   iconPath: twitterLogo,
      //   to: config.twitter.route,
      // },
      {
        id: '6',
        label: 'Instagram',
        iconPath: instagramLogo,
        to: config.instagram.route,
      },
      {
        id: '7',
        label: 'Facebook',
        iconPath: facebookLogo,
        to: config.facebook.route,
      },

      {
        id: '8',
        label: 'PowerBi',
        iconPath: powerBiLogo,
        to: config.powerBi.route,
      },

      {
        id: '9',
        label: 'Google Sheets',
        iconPath: sheets,
        to: config.googleSheets.route,
      },
      {
        id: '10',
        label: 'Google Docs',
        iconPath: googleDocs,
        to: config.googleDocs.route,
      },
      {
        id: '11',
        label: 'Google Calendar',
        iconPath: googleCalendar,
        to: config.googleCalendar.route,
      },
      {
        id: '12',
        label: 'Google Slides',
        iconPath: googleSlides,
        to: config.googleSlides.route,
      },

      {
        id: '13',
        label: 'Weather',
        iconPath: weatherLogo,
        to: config.weather.route,
      },
      {
        id: '14',
        label: 'Severe Weather',
        iconPath: severeWeatherLogo,
        to: config.severeWeather.route,
      },
      {
        id: '15',
        label: 'Ticker',
        iconPath: tickerLogo,
        to: `${config.tickers.route}?isCompleted=false`,
      },

      {
        id: '16',
        label: 'QR Code',
        iconPath: qrCodeLogo,
        to: config.qrCode.route,
      },
      {
        id: '17',
        label: 'MS Teams',
        iconPath: msTeams,
        to: config.MsTeams.route,
      },
      {
        id: '18',
        label: 'Count UP Timer',
        iconPath: countUpTimer,
        to: config.CountUpTimer.route,
      },
      {
        id: '19',
        label: 'Count Down Timer',
        iconPath: countDownTimer,
        to: config.CountDownTimer.route,
      },
      {
        id: '20',
        label: 'Injury Free Counter',
        iconPath: daysSafe,
        to: config.DaysSafe.route,
      },

      {
        id: '21',
        label: 'Live Stream',
        iconPath: liveStream,
        to: config.LiveStream.route,
      },
    ];
  // const safeEmailsListForInsta = [
  //   'instagramagent@gmail.com',
  //   'seif@locusdigital.com',
  // ];
  // const safeEmailsListForFB = [
  //   'facebookagent@gmail.com',
  //   'seif@locusdigital.com',
  // ];
  // const safeEmailsListCanva = [
  //   'mcheikh@locusdigital.com',
  //   'seif@locusdigital.com',
  //   'msnow@indigoworkplace.com ',
  // ];

  const safeEmailsListForMsTeams = ['msnow@indigoworkplace.com'];

  const isAppDisabled = (app: any) => {
    // IF YOU UPDATE THIS FUNCTION PLEASE UPDATE THE showTooltipForApps FUNCTION FOR SHOWING TOOLTIP COMING SOON APPS

    if (process.env.REACT_APP_ENV === 'prod' && app.label === 'Live Stream')
      return true;
    if (
      process.env.REACT_APP_ENV === 'prod' &&
      app.label === 'MS Teams' &&
      safeEmailsListForMsTeams.find((email) => email === userLoggedIn.email)
    )
      return false;
    if (process.env.REACT_APP_ENV === 'prod' && app.label === 'MS Teams')
      return true;

    if (
      isTrial &&
      (app.label === 'PowerBi' ||
        app.label === 'Ticker' ||
        app.label === 'Severe Weather')
      //     ||
      // (process.env.REACT_APP_ENV === 'prod' &&
      //   (app.label === 'PowerBi' ||
      //     (app.label === 'Canva' &&
      //       !safeEmailsListCanva.find(
      //         (email) => email === userLoggedIn?.email,
      //       ))))
    ) {
      return true;
    }

    return false;
  };

  const showTooltipForApps = (app: any) => {
    if (process.env.REACT_APP_ENV === 'prod' && app.label === 'Live Stream')
      return true;
    if (
      process.env.REACT_APP_ENV === 'prod' &&
      app.label === 'MS Teams' &&
      safeEmailsListForMsTeams.find((email) => email === userLoggedIn.email)
    )
      return false;
    if (process.env.REACT_APP_ENV === 'prod' && app.label === 'MS Teams')
      return true;

    if (
      isTrial &&
      (app.label === 'PowerBi' ||
        app.label === 'Ticker' ||
        app.label === 'Severe Weather')
    )
      return true;

    return false;
  };

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          <>
            <div className="d-flex align-items-center">
              <Card.Header.Title>
                <Card.Header.Title.Label>
                  {t('pageTitles.apps')}
                </Card.Header.Title.Label>
              </Card.Header.Title>
            </div>
          </>
        </Card.Header.Toolbar>
      </Card.Header>
      <div className="separator" />
      <Card.Body>
        <Row gutter={[24, 16]} className="text-center">
          {apps.map((app) => (
            // <Link to={routesConfig.emergencyAlerts.route} className="col">
            <Col
              className="gutter-row"
              xs={24}
              sm={16}
              md={12}
              lg={8}
              xl={6}
              span={6}
            >
              {showTooltipForApps(app) ? (
                <>
                  <Tooltip placement={Placement.Top} text="Coming soon!">
                    <AppIcon
                      to={app.to}
                      label={app.label}
                      iconPath={app.iconPath}
                      disabled={isAppDisabled(app)}
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  {' '}
                  <AppIcon
                    to={app.to}
                    label={app.label}
                    iconPath={app.iconPath}
                    disabled={isAppDisabled(app)}
                  />
                </>
              )}
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};
