import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import Card from '../Card';
import SelectCompany from '../../containers/Companies/SelectCompany';
import { readFromQueryString } from '../../utils/queryParams';
import UsersGroupsQueryParam from '../../api/endpoints/usersGroups/enums/usersGroupsQueryParam.enum';
import NavTabs from '../NavTabs';
import PlayersStatistic from './PlayersStatistic';
import DevicesStatistic from './DevicesStatistic';
import RestrictedAccess from '../../containers/RestrictedAccess';
import { UserRole } from '../../enums/userRole.enum';

const playersData = {
  series: [
    {
      name: 'Online',
      data: [44, 55, 57, 56, 61, 58],
    },
    {
      name: 'Offline',
      data: [76, 85, 101, 98, 87, 105],
    },
  ],
  categories: ['00:00', '5:00', '10:00', '15:00', '20:00', '23:00'],
};

const devicesData = {
  series: [
    {
      name: 'Active',
      data: [24, 35, 37, 45, 50, 28],
    },
    {
      name: 'Inactive',
      data: [14, 6, 24, 31, 37, 40],
    },
  ],
  categories: ['00:00', '5:00', '10:00', '15:00', '20:00', '23:00'],
};

export default () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { page } = useParams<{ page: string }>();

  const [company, setCompany] = useState<string | undefined>(
    readFromQueryString<string>(search, UsersGroupsQueryParam.CompanyId) ??
      undefined,
  );

  const navTabs = [
    {
      key: 'statistic',
      tab: (
        <Link
          to="/statistic/players"
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'players' && 'active',
          )}
        >
          {t('statistic.statistic')}
        </Link>
      ),
    },
    {
      key: 'devices',
      tab: (
        <Link
          to="/statistic/devices"
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'devices' && 'active',
          )}
        >
          {t('devices.devices')}
        </Link>
      ),
    },
  ];

  return (
    <Card className="pb-5">
      <Card.Body>
        <RestrictedAccess allowedRoles={[UserRole.SuperAdmin]}>
          <>
            {t('common.company')}
            <div className="w-25 mb-7">
              <SelectCompany
                id="company"
                initialValue={company}
                onChange={({ value }) => {
                  if (value !== 'loadingValue') setCompany(value);
                }}
                isClearable
              />
            </div>
          </>
        </RestrictedAccess>
        <NavTabs tabs={navTabs} separatorClassName="mb-4" />
        {page === 'players' ? (
          <PlayersStatistic data={playersData} />
        ) : (
          <DevicesStatistic data={devicesData} />
        )}
      </Card.Body>
    </Card>
  );
};
