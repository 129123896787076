import React from 'react';
import { useTranslation } from 'react-i18next';

import WithConfirm from '../WithConfirm';

export default ({
  body,
  children,
  handleConfirm,
  confirmText,
}: {
  body: string;
  children: React.ReactNode;
  handleConfirm: () => void;
  confirmText?: string;
}) => {
  const { t } = useTranslation();

  return (
    <WithConfirm
      handleConfirm={handleConfirm}
      body={body}
      confirmButtonText={confirmText ?? t('common.delete_confirmation.confirm')}
      discardButtonText={t('common.delete_confirmation.decline')}
    >
      {children}
    </WithConfirm>
  );
};
