import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import App from '../../../../../interfaces/app.interface';
import Table from '../../../../Table';
import EditGoogleSheet from '../../../../../containers/Apps/GoogleSheets/EditGoogleSheet';
import DeleteGoogleSheet from '../../../../../containers/Apps/DeleteApp';
import ScheduleGoogleSheet from '../../../../../containers/Apps/ScheduleApp';
import { formatDateString } from '../../../../../utils/dateTime';
import PlacementsEnum from '../../../../../enums/placements.enum';
import Yup from '../../../../../utils/validation';
import PreviewFeedModal from '../../PreviewFeedModal';
import {
  AppDependency,
  Placement,
} from '../../../../../store/types/apps/globalTypes';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({
  googleSheet,
  onUpdate,
}: {
  googleSheet: App;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [showModalFeed, setShowModalFeed] = useState(false);

  return (
    <Row>
      <Cell isEmpty={!googleSheet.name}>
        <div onClick={() => setShowModalFeed(true)} aria-hidden>
          <span className="text-dark cursor-pointer fw-bold text-hover-primary d-block mb-1 fs-6 w-100">
            {googleSheet.name}
          </span>
        </div>
        <PreviewFeedModal
          show={showModalFeed}
          onHide={() => {
            setShowModalFeed(false);
          }}
          title={t('common.preview')}
          selectedGoogleSheetFeedSchema={{
            type: 'googleSheet',
            createdAt: googleSheet!.createdAt,
            updatedAt: googleSheet!.updatedAt,
            dependency: googleSheet!.dependency as unknown as AppDependency,
            id: googleSheet!.id,
            name: googleSheet!.name,
            placement: googleSheet!.placement as unknown as Placement,
          }}
        />
      </Cell>
      <Cell isEmpty={!googleSheet.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {googleSheet.ownerApp?.firstName} {googleSheet.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!googleSheet.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {googleSheet.createdAt && formatDate(googleSheet.createdAt)}
        </span>
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          <div className="mx-1">
            <EditGoogleSheet googleSheet={googleSheet} onUpdate={onUpdate} />
          </div>

          {googleSheet.placement === PlacementsEnum.FULL_SCREEN && (
            <div className="mx-1">
              <ScheduleGoogleSheet
                app={googleSheet}
                onUpdate={onUpdate}
                infoValidationSchema={{
                  orientation: Yup.string().required(t('required')),
                  duration: Yup.number().required(t('required')).min(1),
                  startDate: Yup.date(),
                  endDate: Yup.date().when('startDate', {
                    is: (startDate: any) => !!startDate,
                    then: Yup.date().min(
                      Yup.ref('startDate'),
                      t('common.validation.endDate'),
                    ),
                  }),
                  isLocked: Yup.boolean(),
                }}
                skipOrientationAuto
                skipOrder
              />
            </div>
          )}

          <div className="mx-1">
            <DeleteGoogleSheet
              title={t('common.delete_confirmation.google_sheet')}
              app={googleSheet}
              onUpdate={onUpdate}
              toastMessage="apps.googleSheets.sheet_feed_deleted"
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default TableRow;
