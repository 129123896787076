import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import FormErrorMessage from '../../components/FormErrorMessage';
import Modal from '../../components/Modal';

import { FileSchema, RenameFileRequest } from '../../store/types/fileManager';

import useStateWithHookForm from '../../utils/hooks/useStateWithHookForm';

export interface RenameFileModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (renameFileRequest: RenameFileRequest) => void;
  title: string;
  selectedFileSchema: FileSchema;
  isLoading: boolean;
}

const RenameFileModal = ({
  show,
  onHide,
  onSubmit,
  title,
  selectedFileSchema,
  isLoading = false,
}: RenameFileModalProps) => {
  const initialValues: RenameFileRequest = {
    fileId: selectedFileSchema?.id,
    name: '',
  };
  const { t } = useTranslation();

  const validationSchema: Yup.SchemaOf<RenameFileRequest> = Yup.object({
    fileId: Yup.string().defined(),
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<RenameFileRequest>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [newFileName, setNewFileName] = useStateWithHookForm<
    RenameFileRequest,
    string
  >({ setValue, trigger, name: 'name' });

  const handleNewFileNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      console.log('value', value);

      setNewFileName(value);
    },
    [setNewFileName],
  );

  const handleOnSubmit = React.useCallback(
    (data: RenameFileRequest) => onSubmit(data),
    [onSubmit],
  );

  const currentFileTitle = selectedFileSchema?.title;

  return (
    <Modal
      show={show}
      onHide={onHide}
      widthDefaultSize={false}
      title={title}
      withFooter
      footerContent={
        <button
          form="form"
          type="submit"
          className="btn btn-primary"
          disabled={newFileName === currentFileTitle}
        >
          {isLoading ? (
            <span>
              Saving{' '}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          ) : (
            <span>Save</span>
          )}
        </button>
      }
    >
      <form
        onSubmit={handleSubmit(handleOnSubmit)}
        id="form"
        className="flex flex-column"
      >
        <div className="flex flex-column my-2 w-100">
          <label htmlFor="name" className="text-gray-900 my-1">
            File Name
          </label>
          <input
            name="name"
            id="name"
            value={newFileName}
            onChange={handleNewFileNameChange}
            className="form-control form-control-solid"
            placeholder="Type Here"
            type="text"
          />
          <span className="text-muted my-1">
            Current file title: {currentFileTitle}
          </span>
          <FormErrorMessage name="name" errors={errors} className="my-1 px-2" />
        </div>
      </form>
    </Modal>
  );
};

export default RenameFileModal;
