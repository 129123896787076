export default {
  /* Regular Routes */
  home: {
    route: '/',
    title: 'pageTitles.home',
  },
  login: {
    route: '/login',
    title: 'pageTitles.login',
  },
  CanvaSignin: {
    route: '/canva-signin',
    title: 'pageTitles.login',
  },
  signin: {
    route: '/signin',
    title: 'pageTitles.login',
  },

  signupPassword: {
    route: '/signup-password',
    title: 'pageTitles.signup',
  },
  signupEmail: {
    route: '/signup',
    title: 'pageTitles.signup',
  },
  forgotPassword: {
    route: '/forgot-password',
    title: 'pageTitles.forgotPassword',
  },
  completeProfile: {
    route: '/completeProfile',
    title: 'pageTitles.completeProfile',
  },
  logout: {
    route: '/logout',
    title: 'pageTitles.logout',
  },
  users: {
    route: '/users',
    title: 'pageTitles.users',
  },
  user: {
    route: '/users/:id/:page',
    title: 'pageTitles.user',
  },
  customer: {
    route: '/customers/:id/:page',
    title: 'pageTitles.user',
  },
  usersGroups: {
    route: '/users-groups',
    title: 'pageTitles.usersGroups',
  },
  usersGroup: {
    route: '/users-groups/:id/:page',
    title: 'pageTitles.userGroup',
  },
  devices: {
    route: '/devices',
    title: 'pageTitles.devices',
  },
  devicesGroups: {
    route: '/devices-groups',
    title: 'pageTitles.devicesGroups',
  },
  devicesGroup: {
    route: '/devices-groups/:id/:page',
    title: 'pageTitles.deviceGroup',
  },
  playlists: {
    route: '/playlists',
    title: 'pageTitles.playlists',
  },
  playlist: {
    route: '/playlists/:id/:page',
    title: 'pageTitles.playlist',
  },
  myAccount: {
    route: '/my-account',
    title: 'pageTitles.myAccount',
  },
  library: {
    route: '/library',
    title: 'pageTitles.library',
  },
  manageLibrary: {
    route: '/manage-library',
    title: 'pageTitles.manageLibrary',
  },
  reports: {
    route: '/reports',
    title: 'pageTitles.reports',
  },
  support: {
    route: '/support',
    title: 'pageTitles.support',
  },
  templates: {
    route: '/templates',
    title: 'pageTitles.templates',
  },
  integrations: {
    route: '/integrations',
    title: 'pageTitles.integrations',
  },
  contents: {
    route: '/contents',
    title: 'pageTitles.contents',
  },
  sharedContents: {
    route: '/sharedContents',
    title: 'pageTitles.contents',
  },
  content: {
    route: '/contents/:id',
    title: 'pageTitles.content',
  },
  apps: {
    route: '/apps',
    title: 'pageTitles.apps',
  },
  emergencyAlerts: {
    route: '/apps/emergencyAlerts',
    title: 'pageTitles.emergencyAlerts',
  },
  youtube: {
    route: '/apps/youtube',
    title: 'pageTitles.youtube',
  },
  app: {
    route: '/app/:id',
    title: 'pageTitles.app',
  },
  powerBi: {
    route: '/apps/powerBi',
    title: 'pageTitles.powerBi',
  },
  audience: {
    route: '/audience',
    title: 'pageTitles.audience',
  },
  report: {
    route: '/report',
    title: 'pageTitles.report',
  },
  tickers: {
    route: '/apps/tickers',
    title: 'pageTitles.tickers',
  },
  html5: {
    route: '/apps/html5',
    title: 'pageTitles.html5',
  },
  weather: {
    route: '/apps/weathers',
    title: 'pageTitles.weathers',
  },
  severeWeather: {
    route: '/apps/severe-weathers',
    title: 'pageTitles.severeWeathers',
  },
  googleSheets: {
    route: '/apps/google-sheets',
    title: 'pageTitles.googleSheets',
  },
  googleDocs: {
    route: '/apps/google-docs',
    title: 'pageTitles.googleDocs',
  },
  googleCalendar: {
    route: '/apps/google-calendar',
    title: 'pageTitles.googleCalendar',
  },
  googleSlides: {
    route: '/apps/google-slides',
    title: 'pageTitles.googleSlides',
  },
  rss: {
    route: '/apps/rss',
    title: 'pageTitles.rss',
  },
  twitter: {
    route: '/apps/twitter',
    title: 'pageTitles.twitter',
  },
  facebook: {
    route: '/apps/facebook',
    title: 'pageTitles.facebook',
  },
  instagram: {
    route: '/apps/instagram',
    title: 'pageTitles.instagram',
  },
  canva: {
    route: '/apps/canva',
    title: 'pageTitles.canva',
  },
  canvaSchedule: {
    route: '/apps/canva/:id/schedule',
    title: 'pageTitles.canva',
  },

  MsTeams: {
    route: '/apps/microsoft-teams',
    title: 'pageTitles.msTeams',
  },
  qrCode: {
    route: '/apps/qrcode',
    title: 'pageTitles.qrCode',
  },

  CountUpTimer: {
    route: '/apps/count-up',
    title: 'pageTitles.countUpTimer',
  },

  CountDownTimer: {
    route: '/apps/count-down',
    title: 'pageTitles.countDownTimer',
  },
  DaysSafe: {
    route: '/apps/injury-free-counter',
    title: 'pageTitles.daysSafe',
  },
  LiveStream: {
    route: '/apps/live-stream',
    title: 'pageTitles.liveStream',
  },
  statistic: {
    route: '/statistic/:page',
    title: 'pageTitles.statistic',
  },
  company: {
    route: '/companies',
    title: 'pageTitles.companies',
  },
  // library
  indigoLibraryCategories: {
    route: '/indigo-library-categories',
    title: 'pageTitles.indigo_library_categories',
  },
  // if this path should be changed for some reason, breadcrumb should also be updated
  // please verify the breadcrumb's generated paths( scheduleLibrary is excluded from the path)
  indigoLibraryUnderSubCategory: {
    route:
      '/indigo-library-categories/:categoryName/:subCategoryName/scheduleLibrary/:id',
    title: 'pageTitles.library',
  },
  // if this path should be changed for some reason, breadcrumb should also be updated
  // please verify the breadcrumb's generated paths( scheduleLibrary is excluded from the path)
  indigoLibrary: {
    route: '/indigo-library-categories/:categoryName/scheduleLibrary/:id',
    title: 'pageTitles.library',
  },
  indigoSubCategoriesLibraries: {
    route: '/indigo-library-categories/:categoryName/:subCategoryName',
    title: 'pageTitles.libraries',
  },

  indigoLibraries: {
    route: '/indigo-library-categories/:categoryName',
    title: 'pageTitles.libraries',
  },
  indigoLibraryWorkplace: {
    route: '/indigo-library-workplace',
    title: 'pageTitles.indigo_library_categories',
  },

  /* Admin Routes */
  adminHome: {
    route: '/admin',
    title: '',
  },
  dashboard: {
    route: '/admin/dashboard',
    title: 'pageTitles.dashboard',
  },
  logs: {
    route: '/admin/logs',
    title: 'pageTitles.logs',
  },
  logsReports: {
    route: '/admin/logs-reports',
    title: 'pageTitles.LogsReports',
  },
  adminCompanies: {
    route: '/admin/companies',
    title: 'pageTitles.companies',
  },
  adminCustomerCompanies: {
    route: '/admin/customers/companies',
    title: 'pageTitles.companies',
  },
  adminCustomerTrialCompanies: {
    route: '/admin/customers/companies-trial',
    title: 'pageTitles.trial_companies',
  },
  adminCustomerTrialDevices: {
    route: '/admin/trial-devices',
    title: 'pageTitles.is_trial',
  },
  adminUsers: {
    route: '/admin/users',
    title: 'pageTitles.indigo_user',
  },
  adminCustomers: {
    route: '/admin/customers',
    title: 'pageTitles.users',
  },
  adminTrialCustomers: {
    route: '/admin/trial-customers',
    title: 'pageTitles.trial_user',
  },
  adminCustomerDetails: {
    route: '/admin/customer/:id/:page',
    title: 'pageTitles.customerDetails',
  },
  adminSales: {
    route: '/admin/sales',
    title: 'pageTitles.sales',
  },
  adminDevices: {
    route: '/admin/devices',
    title: 'pageTitles.adminDevices',
  },
  devicesCharts: {
    route: '/admin/devices-charts',
    title: 'pageTitles.devicesCharts',
  },
  adminBilling: {
    route: '/admin/billing',
    title: 'pageTitles.billing',
  },
  adminPackages: {
    route: '/admin/packages',
    title: 'pageTitles.packages',
  },
  adminAlerts: {
    route: '/admin/alerts',
    title: 'pageTitles.alerts',
  },
  adminStatistic: {
    route: '/statistic/:page',
    title: 'pageTitles.statistic',
  },
  adminReports: {
    route: '/admin/reports',
    title: 'pageTitles.report',
  },
  adminBanners: {
    route: '/admin/banners',
    title: 'pageTitles.banners',
  },
  adminAppAnalytics: {
    route: '/admin/app-analytics',
    title: 'Apps Analytics',
  },
} as const;
