import React from 'react';
import { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Card from '../../Card';
import App from '../../../interfaces/app.interface';
import useTableMultiSelect from '../../../utils/hooks/useTableMultiSelect';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import SheetsTable from './Table';
import Search from '../../Search/QueryParamSearch';
import { KTSVG } from '../../../lib/metronic/helpers';
import CreateGoogleSheet from '../../../containers/Apps/GoogleSheets/CreateGoogleSheet';

const pageSize = 6;
const tableRowHeight = 63;

export default ({
  googleSheets,
  total,
  isLoading,
  resetOnSearch,
  onUpdate,
}: {
  googleSheets: App[];
  total: number;
  isLoading: boolean;
  resetOnSearch?: boolean;
  onUpdate: () => void;
}) => {
  console.log(total);
  const { t } = useTranslation();

  const { search } = useLocation();

  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(googleSheets, 'id'),
    [],
    resetOnSearch ? search : undefined,
  );

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          <Search
            queryField="search"
            pageNumberQueryField=""
            placeholder={t('common.search')}
            className="me-2"
          />
          <div className="d-flex">
            {/* <FiltersDropdown className="me-3" /> */}
            <CreateGoogleSheet onUpdate={onUpdate}>
              <button type="button" className="mt-2 btn btn-primary">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                {t('apps.googleSheets.createSheet')}
              </button>
            </CreateGoogleSheet>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <SheetsTable
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          onUpdate={onUpdate}
          googleSheets={googleSheets}
          isLoading={isLoading}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
        />
      </Card.Body>
    </Card>
  );
};
