import axios from 'axios';
import { API_URL } from '../../../constants/api';
import { videoFileTypes } from '../../../constants/file';
import {
  CreateFileRequest,
  FileMimeType,
  FileSchema,
} from '../../../store/types/fileManager';

export default {
  createFileService: async (
    request: CreateFileRequest,
  ): Promise<Partial<FileSchema>> => {
    const {
      name,
      isDirectory,
      parentDirectoryId,
      file,
      duration,
      width,
      height,
      isPublic,
      conversionJobId,
    } = request;

    const fd = new FormData();

    fd.append('name', name);
    fd.append('isDirectory', isDirectory.toString());
    fd.append('isPublic', isPublic.toString());
    fd.append(
      'conversionJobId',
      conversionJobId ? conversionJobId.toString() : '',
    );

    const fileType = file?.type as FileMimeType;
    if (duration && videoFileTypes.includes(fileType)) {
      fd.append('duration', duration.toString());
    }

    if (parentDirectoryId) {
      fd.append('parentDirectoryId', parentDirectoryId);
    }

    if (file) {
      fd.append('file', file);
    }

    if (width && height) {
      fd.append('width', width);
      fd.append('height', height);
    }

    const response = await axios({
      method: 'POST',
      url: `${API_URL}/files`,
      data: fd,
    });

    return response.data;
  },
};
