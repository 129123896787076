import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import CreateSku from '../../../components/AdminPanel/Library/CreateSku';
import Modal from '../../../components/Modal';

const CreateSkuContainer = ({
  children,
  onUpdateContentPosters,
  topicId,
  dynamicOpen,
  onHideModal,
}: {
  children?: React.ReactNode;
  onUpdateContentPosters: () => void;
  topicId: string;
  dynamicOpen: boolean;
  onHideModal?: () => void;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(dynamicOpen);
  // const [loading, setLoading] = useState<boolean>(false);
  // const [customerId, setCustomerId] = useState('');
  const open = () => setModalShown(true);
  const close = () => {
    setModalShown(false);
  };

  const handleSubmit = (data: any) => {
    // console.log(data);

    api.libraries.createPostersAndContents(data, topicId).then(() => {
      close();
      onUpdateContentPosters();
    });
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('library.indigo.createSku')}
        show={modalShown}
        onHide={() => {
          setModalShown(false);
          if (onHideModal) onHideModal();
        }}
        withFooter={false}
        widthDefaultSize={false}
        dialogClassName="mw-1450px"
      >
        <CreateSku onSubmit={handleSubmit} />
      </Modal>
    </>
  );
};
export default CreateSkuContainer;
