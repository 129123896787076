import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { map } from 'lodash';
import SortOrder from '../../../enums/sortOrder.enum';
import UsersSortField from '../../../api/endpoints/users/enums/usersSortField.enum';
import { readFromQueryString } from '../../../utils/queryParams';
import Columns, { TableHeaderColumn } from '../../Table/Columns';
import User from '../../../interfaces/user/user.interface';
import WithSpinner from '../../WithSpinner';
import TableRow from './TableRow';
import Table from '../../Table';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../utils/hooks/useTableMultiSelect';
import CreateUserRequestData from '../../../api/endpoints/users/interfaces/createUserRequestData.interface';

interface TableViewProps {
  users: User[];
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight?: number;
  isSelectable?: boolean;
  skipActions?: boolean;
  skipEditSuspendReset?: boolean;
  multiSelectConfig?: TableMultiSelectConfig;
  onEditUser?: (userId: string, data: Partial<CreateUserRequestData>) => void;
  unAssignUser?: (userId: string, userGroupId: string) => void;
  onRestoreUser?: (userId: string) => void;
  onSuspendUser?: (userId: string) => void;
  onUnssingUserFromDeviceGroup?: (userId: string, userGroupId: string) => void;
  userGroupInfo?: boolean;
}

interface DefaultProps extends TableViewProps {
  multiSelectConfig: Required<TableViewProps>['multiSelectConfig'];
}

export default ({
  users,
  isLoading,
  sortQueryField,
  orderQueryField,
  preloaderHeight = 300,
  isSelectable = false,
  userGroupInfo,
  onEditUser,
  unAssignUser,
  onRestoreUser,
  onSuspendUser,
  skipActions,
  skipEditSuspendReset,
  onUnssingUserFromDeviceGroup,
  multiSelectConfig: { selectedItems, setSelectedItem, selectAll },
}: DefaultProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  const columns: TableHeaderColumn<UsersSortField>[] = [
    {
      name: t('users.table.username'),
      sortBy: UsersSortField.FirstName,
    },
    { name: t('users.common.company') },
    { name: t('users.common.role') },
    {
      name: t('users.table.status'),
      sortBy: UsersSortField.Status,
    },
    { name: t('users.table.devices_number') },
    { name: t('users.table.group_access') },
    { name: t('users.table.location') },
  ];

  if (!skipActions) {
    columns.push({ name: t('common.actions') });
  }

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns<UsersSortField>
            columns={columns}
            checked={isAllSelected(selectedItems, map(users, 'id'))}
            config={{
              isSelectable,
              sortField,
              setSortField,
              sortQueryField,
              orderQueryField,
            }}
            onChange={selectAll}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={!isLoading && !users.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={t('users.table.no_one_user_found')}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {users.map((user) => (
            <TableRow
              key={user.id}
              user={user}
              config={{
                isSelectable,
                skipActions,
                skipEditSuspendReset,
              }}
              isChecked={selectedItems.includes(user.id)}
              onCheck={setSelectedItem}
              onEditUser={onEditUser}
              unAssignUser={unAssignUser}
              onRestoreUser={onRestoreUser}
              onSuspendUser={onSuspendUser}
              onUnssingUserFromDeviceGroup={onUnssingUserFromDeviceGroup}
              userGroupInfo={userGroupInfo}
            />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
