import React, { useState } from 'react';
import { Card } from 'antd';
// import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import Tooltip, { Placement } from '../Tooltip';
import TalkToSales from '../Trial/TalkToSales';
// import GlobalState from '../../store/reducers/globalState.interface';
// import TrialAccountPopup from '../TrialAccountPopup';
// import { UserRole } from '../../enums/userRole.enum';

const AppIcon = ({
  to,
  label,
  iconPath,
  disabled = false,
}: {
  label: string;
  iconPath?: string;
  to: string;
  disabled?: boolean;
}) => {
  // const user = useSelector((state: GlobalState) => state.userData.user);
  // const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const handleClick = () => {
    // const { isTrial } = user;
    // const isAccountOwner = user.roles.includes(UserRole.AccountOwner); // I do not know what the purpose behind this logic, just why?
    // if (isAccountOwner && isTrial) {
    //   setOpen(true);
    // } else {
    //   history.push(to);
    // }
    history.push(to);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleClose = () => {
    setIsModalVisible(false);
  };

  if (disabled) {
    return (
      <>
        {/* <Tooltip placement={Placement.Top} text="Coming soon!"> */}
        <TalkToSales handleClose={handleClose} isModalVisible={isModalVisible}>
          <Card
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              // alignItems: 'center',
            }}
            onContextMenu={(ev) => ev.preventDefault()}
            hoverable
            onClick={() => {
              setIsModalVisible(true);
            }}
            cover={
              <img
                // className={clsx(styles.appIcon, 'mb-1')}
                style={{
                  // maxWidth: '64px',
                  maxHeight: '64px',
                  margin: '10% auto 0 auto',
                  width: 'auto',
                }}
                src={iconPath}
                alt={label}
              />
            }
          >
            <Card.Meta
              title={
                <>
                  <span>{label}</span>
                  <i
                    className="bi bi-lock-fill ms-3"
                    style={{ fontSize: '1.4rem' }}
                  />
                </>
              }
              style={{ justifyContent: 'center' }}
            />

            {/* <button
          type="button"
          onClick={handleClick}
          onKeyDown={handleClick}
          className={clsx(
            // styles.wrapper,
            'py-2 cursor-pointer w-100 bg-transparent cursor-pointer',
          )}
        >
          <img
            className={clsx(styles.appIcon, 'mb-1')}
            src={iconPath}
            alt="Pic"
          />
          <div className={clsx(styles.label, 'mt-1')}></div>
        </button> */}
            {/* <TrialAccountPopup isModalVisible={open} setIsModalVisible={setOpen} /> */}
          </Card>
        </TalkToSales>
        {/* </Tooltip> */}
      </>
    );
  }

  return (
    <>
      <Card
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        onContextMenu={(ev) => ev.preventDefault()}
        hoverable
        onClick={handleClick}
        cover={
          <img
            // className={clsx(styles.appIcon, 'mb-1')}
            style={{
              // maxWidth: '64px',
              maxHeight: '64px',
              margin: '10% auto 0 auto',
              width: 'auto',
            }}
            src={iconPath}
            alt={label}
          />
        }
      >
        <Card.Meta
          title={label}
          style={{
            justifyContent: 'center',
          }}
        />
        {/* <button
          type="button"
          onClick={handleClick}
          onKeyDown={handleClick}
          className={clsx(
            // styles.wrapper,
            'py-2 cursor-pointer w-100 bg-transparent cursor-pointer',
          )}
        >
          <img
            className={clsx(styles.appIcon, 'mb-1')}
            src={iconPath}
            alt="Pic"
          />
          <div className={clsx(styles.label, 'mt-1')}></div>
        </button> */}
        {/* <TrialAccountPopup isModalVisible={open} setIsModalVisible={setOpen} /> */}
      </Card>
    </>
  );
};

export default AppIcon;
