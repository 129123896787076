import React from 'react';
import clsx from 'clsx';
import pointerEventsStyles from '../../styles/pointerEvents.module.scss';
import NavTab from '../../interfaces/navTab.interface';

interface NavTabsProps<T extends string> {
  tabs: NavTab<T>[];
  onSelect?: (key: T) => void;
  separatorClassName?: string;
  className?: string;
  isSelectable?: boolean;
  withUnderline?: boolean;
}

interface DefaultProps<T extends string> extends NavTabsProps<T> {
  onSelect: Required<NavTabsProps<T>>['onSelect'];
  isSelectable: Required<NavTabsProps<T>>['isSelectable'];
  withUnderline: Required<NavTabsProps<T>>['withUnderline'];
}

const NavTabs = <T extends string>({
  tabs,
  isSelectable,
  withUnderline,
  onSelect,
  separatorClassName,
  className,
}: DefaultProps<T>) => (
  <>
    <div className={clsx('d-flex overflow-auto h-55px w-100', className)}>
      <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold flex-nowrap mw-0 text-nowrap">
        {tabs
          .filter(({ isHidden }) => !isHidden)
          .map(({ key, tab }) => (
            <li
              role="tab"
              tabIndex={-1}
              key={key}
              className={clsx(
                'nav-item',
                !isSelectable && pointerEventsStyles.noPointerEvents,
              )}
              onClick={() => onSelect(key)}
              onKeyPress={() => onSelect(key)}
            >
              {tab}
            </li>
          ))}
      </ul>
    </div>
    {withUnderline && (
      <span className={clsx('separator w-100', separatorClassName)} />
    )}
  </>
);

NavTabs.defaultProps = {
  onSelect: () => null,
  isSelectable: true,
  withUnderline: true,
};

export default NavTabs;
