import React, { ReactNode, SyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap-v5';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { CheckTreePicker, CheckPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { ItemDataType } from 'rsuite/esm/@types/common';
import FormErrorMessage from '../../../FormErrorMessage';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import Orientation from '../../../../enums/orientation.enum';
import styles from './custom.module.scss';
import categories from '../../../../constants/feeds/rssCategories';
import feeds from '../../../../constants/feeds/rssFeeds';
import TextInput from '../../../TextInput';
import { prepareHookFromState } from '../../../../utils/hooks/useStateWithHookForm';
import api from '../../../../api';
import removeFeed from '../../../../assets/images/apps/removeFeed.svg';
import { buildQueryString } from '../../../../utils/queryParams';
import RssQueryParam from '../../../../enums/queryParams/rssQueryParams.enum';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import { disableRightClickIframe } from '../../../../utils/common';

interface FormInputs {
  name: string;
  rssFeedUrl: string;
  customFeedsData: (string | number)[];
  defaultFeedsData: (string | number)[];
}

export default ({
  onSubmit,
  rss = {} as App,
  onClose,
  isLoading,
}: {
  onSubmit: any;
  rss?: App;
  onClose?: () => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const defaultValues: Partial<FormInputs> = {
    name: rss?.name ?? '',
    rssFeedUrl: rss?.dependency?.config?.rssFeedUrl ?? '',
    customFeedsData: rss?.dependency?.config?.customFeedsData ?? [],
    defaultFeedsData: rss?.dependency?.config?.defaultFeedsData ?? [],
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('apps.rss.invalid_name'))
      .defined(),
    rssFeedUrl: Yup.string().url(t('apps.rss.invalid_url')).defined(),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [name, setName] = useFs<FormInputs['name']>('name');

  const [rssFeedUrl, setRssFeedURL] =
    useFs<FormInputs['rssFeedUrl']>('rssFeedUrl');

  const [selectedDefaultFeeds, setSelectedDefaultFeeds] =
    useFs<FormInputs['defaultFeedsData']>('defaultFeedsData');

  const [selectedCustomFeeds, setSelectedCustomFeeds] =
    useFs<FormInputs['customFeedsData']>('customFeedsData');

  const [previewDocumentIsFetching, setPreviewDocumentIsFetching] =
    React.useState<boolean>(false);

  const [staticFeeds, setStaticFeeds] = React.useState<
    ItemDataType<string | number>[]
  >([]);

  const [customFeeds, setCustomFeeds] = React.useState<
    ItemDataType<string | number>[]
  >([]);

  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  const [creatingFeed, setCreatingFeed] = React.useState<boolean>(false);
  const [isScheduled, setIsScheduled] = React.useState<boolean>(false);
  const [url, setUrl] = React.useState('');
  const [windowWidth] = useWindowSize();

  const handlePreviewDocumentIsFetching = (
    previewDocumentIsFetchingData: boolean,
  ) => {
    setPreviewDocumentIsFetching(previewDocumentIsFetchingData);
  };

  const handleOnIFrameLoaded = () => {
    handlePreviewDocumentIsFetching(false);
  };

  const handleSubmitData = () => {
    if (onSubmit) {
      const uniqueRssFeeds = Array.from(
        new Set([...selectedDefaultFeeds, ...selectedCustomFeeds]),
      );

      if (!uniqueRssFeeds.length) {
        toast.warning(t('apps.rss.no_feed_selected'));
        return;
      }

      onSubmit({
        name,
        isScheduled,
        zoneId: Orientation.Landscape,
        config: {
          rssFeedUrl,
          feedsData: uniqueRssFeeds,
          customFeedsData: selectedCustomFeeds,
          defaultFeedsData: selectedDefaultFeeds,
        },
      });
    }
  };

  const handleTreeChange = (value: (string | number)[]) => {
    if (value && value.length > 0) {
      const linksArray = value.filter(
        (element: string | number) => typeof element === 'string',
      );
      const parent = value.filter(
        (element: string | number) => typeof element === 'number',
      );
      if (parent.length > 0) {
        parent.forEach((parentEl) => {
          staticFeeds.forEach((item: ItemDataType<string | number>) => {
            if (item.value === parentEl) {
              const index = value.indexOf(parentEl);
              if (index !== -1) {
                const data: string[] = item.children?.map(
                  (child: ItemDataType<string | number>) => child.value,
                ) as string[];
                linksArray.push(...data);
              }
            }
          });
        });
      }
      setSelectedDefaultFeeds([...linksArray]);
    } else {
      setSelectedDefaultFeeds([]);
    }
  };

  const handleCustomTreeChange = async (value: (string | number)[]) => {
    await trigger('rssFeedUrl');
    setSelectedCustomFeeds(value);
  };

  const getCustomFeeds = async () => {
    try {
      const customFeedsData: ItemDataType[] = [];
      setIsFetching(true);
      const response = await api.customFeeds.getCustomFeeds();
      if (response && response.length > 0) {
        response.forEach((item) => {
          customFeedsData.push({
            id: item.id,
            label: item.url,
            value: item.url,
          });
        });
      }
      setCustomFeeds(customFeedsData);
      setIsFetching(false);
    } catch {
      setIsFetching(false);
    }
  };

  const handleRemoveFeed = async (e: SyntheticEvent, id: string) => {
    e.preventDefault();
    try {
      setIsFetching(true);
      await api.customFeeds.deleteFeed(id);
      toast.success(t('apps.rss.custom_feed_deleted'));
      getCustomFeeds();
    } catch {
      setIsFetching(false);
    }
  };

  const renderMenuItem = (menu: ReactNode, val: ItemDataType) => {
    if (customFeeds && customFeeds.length > 0) {
      return (
        <>
          <div
            className={clsx(
              'd-inline-block position-relative',
              styles.customFeedMenu,
            )}
          >
            <div className="overflow-hidden text-truncate">{menu}</div>
          </div>
          <button
            className="bg-transparent position-absolute end-0"
            type="button"
            onClick={(e) => handleRemoveFeed(e, val.id)}
          >
            <img src={removeFeed} alt="remove" />
          </button>
        </>
      );
    }
    return menu;
  };

  const renderMenu = (menu: ReactNode) => {
    if (isFetching) {
      return (
        <div className="d-flex justify-content-center my-2">
          <Spinner role="status" animation="border" />
        </div>
      );
    }
    return menu;
  };

  // Click Event
  const handleCustomFeed = async () => {
    try {
      if (rssFeedUrl) {
        setCreatingFeed(true);
        await api.customFeeds.createCustomFeed({
          url: rssFeedUrl,
        });
        toast.success(t('apps.rss.custom_feed_added'));
        getCustomFeeds();
      }
    } catch {
      toast.error(t('apps.rss.custom_feed_error'));
    }
    setRssFeedURL('');
    setCreatingFeed(false);
  };

  // Effects
  React.useEffect(() => {
    getCustomFeeds();
  }, []);

  React.useEffect(() => {
    if (categories.length > 0 && feeds.length > 0) {
      const fetchedFeedsByCategory: ItemDataType<string | number>[] = [];
      categories.forEach((parent) => {
        const data = feeds.filter(
          (child) =>
            child.rssCategoryID === parent.value &&
            child.label !== 'NULL' &&
            child.value !== 'NULL' &&
            child.value,
        );
        if (data.length > 0) {
          fetchedFeedsByCategory.push({
            label: parent.label,
            value: parent.value,
            children: data,
          });
        }
      });
      setStaticFeeds((prev: ItemDataType<string | number>[]) => [
        ...prev,
        ...fetchedFeedsByCategory,
      ]);
    }
  }, [categories, feeds]);

  React.useEffect(() => {
    if (!selectedDefaultFeeds.length && !selectedCustomFeeds.length) {
      setUrl('');
      return;
    }

    const uniqueFeeds = Array.from(
      new Set([...selectedDefaultFeeds, ...selectedCustomFeeds]),
    );

    const urlPreview = `${
      process.env.REACT_APP_PLAYER_PREVIEW_URL
    }/preview.html${buildQueryString({
      [RssQueryParam.RssList]: uniqueFeeds as string[],
    })}`;

    setUrl(urlPreview);
  }, [selectedCustomFeeds, selectedDefaultFeeds]);

  React.useEffect(() => {
    disableRightClickIframe(name);
  }, []);

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form
          onSubmit={handleSubmit(handleSubmitData)}
          id="form"
          className="d-flex flex-column justify-content-between"
        >
          <div className="row">
            <div className="col-6">
              <div className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className="required text-dark fw-bolder my-1"
                >
                  {t('apps.rss.rss_title')}
                </label>
                <TextInput
                  name="name"
                  id="name"
                  value={name}
                  onChange={setName}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className="my-1 px-2"
                />
              </div>
              <div className="mt-2 mb-0">
                <label
                  htmlFor="rssStaticFeed"
                  className="text-dark fw-bolder my-1"
                >
                  {t('apps.rss.select_default_feed')}
                </label>
                <CheckTreePicker
                  defaultValue={selectedDefaultFeeds}
                  name="defaultFeedsData"
                  defaultExpandAll
                  className="w-100"
                  menuClassName={styles.feedsMenu}
                  searchable={false}
                  countable={false}
                  showIndentLine
                  data={staticFeeds}
                  onChange={handleTreeChange}
                />
              </div>
              <div className="mt-2 mb-0">
                <label
                  htmlFor="rssStaticFeed"
                  className="text-dark fw-bolder my-1"
                >
                  {t('apps.rss.select_custom_feed')}
                </label>
                <CheckPicker
                  defaultValue={selectedCustomFeeds}
                  name="customFeedsData"
                  className="w-100"
                  menuClassName={styles.feedsMenu}
                  searchable={false}
                  countable={false}
                  data={customFeeds}
                  onChange={handleCustomTreeChange}
                  renderMenuItem={renderMenuItem}
                  renderMenu={renderMenu}
                />
              </div>
              <div className="mt-2 mb-0">
                <label
                  htmlFor="rssFeedUrl"
                  className="text-dark fw-bolder my-1"
                >
                  {t('apps.rss.web_page_url')}
                </label>
                <TextInput
                  name="rssFeedUrl"
                  id="rssFeedUrl"
                  value={rssFeedUrl}
                  onChange={setRssFeedURL}
                  className="form-control form-control-solid"
                />
                <FormErrorMessage
                  name="rssFeedUrl"
                  errors={errors}
                  className="my-1 px-2"
                />
                {rssFeedUrl && (
                  <div className="mt-2 mb-0 text-end">
                    <button
                      id="submit"
                      type="button"
                      className="btn btn-primary btn-sm m-1"
                      onClick={handleCustomFeed}
                      disabled={!!errors.rssFeedUrl || creatingFeed}
                    >
                      {t('apps.rss.inset_custom_feed')}
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex flex-column   flex-center  p-4 w-100">
                {url ? (
                  <div className="d-flex flex-column flex-center w-100 h-100">
                    {previewDocumentIsFetching ? (
                      <Spinner role="status" animation="border" />
                    ) : null}
                    <iframe
                      title={name}
                      src={`${url}&dimension=${windowWidth}`}
                      width="100%"
                      height={215}
                      onLoad={handleOnIFrameLoaded}
                      frameBorder="0"
                      marginHeight={0}
                      marginWidth={0}
                      style={{ padding: '4px' }}
                    />
                  </div>
                ) : (
                  <div className="d-flex flex-column flex-center w-100">
                    <img
                      className="d-flex flex-column align-self-center w-25"
                      alt="No items found"
                      src="/media/illustrations/sketchy-1/5.png"
                    />
                    <div className="fs-1">{t('apps.rss.no_preview')}</div>
                    <div className="fs-6">{t('apps.rss.feed_url')}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {Object.keys(rss).length
                  ? t('common.save')
                  : t('common.create')}
              </button>
              {!Object.keys(rss).length && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                >
                  {t('common.save_schedule')}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
