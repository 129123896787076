import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import api from '../../../api';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import { UserRole } from '../../../enums/userRole.enum';
import GlobalState from '../../../store/reducers/globalState.interface';
import { readFromQueryString } from '../../../utils/queryParams';

const SelectPlaylists = ({
  onChange,
  initialValue,
}: {
  onChange: (selectedValue: SelectedValue[]) => void;
  initialValue?: any;
}) => {
  const [options, setOptions] = useState<any>([]);
  const { search } = useLocation();
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const isAdmin = roles.includes(UserRole.SuperAdmin);
  const { id: deviceGroupId } = useParams<{ id: string }>();
  const currentUserId = readFromQueryString(search, 'currentUserId');
  const loadOptions = async () => {
    const { items } = isAdmin
      ? await api.devicesGroups.getDeviceGroupPlaylistsByCurrentUser(
          {
            exclude: true,
            currentUserId: String(currentUserId) || '',
          },
          deviceGroupId,
        )
      : await api.devicesGroups.getDeviceGroupPlaylists(
          { exclude: true, currentUserId: String(currentUserId) || '' },
          deviceGroupId,
        );

    const getOptions = items.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    setOptions(getOptions);
  };

  const handleFilterOptions = (filteredOptions: any, input: string) => {
    return filteredOptions.filter((option: { label: string }) =>
      option.label.toLowerCase().includes(input.toLowerCase()),
    );
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <MultiSelect
      options={options}
      value={initialValue}
      onChange={onChange}
      filterOptions={handleFilterOptions}
      labelledBy="Selects Devices"
    />
  );
};

SelectPlaylists.defaultProps = {
  initialValue: [],
};

export default SelectPlaylists;
