/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { KTSVG } from '../../lib/metronic/helpers';

import GlobalState from '../../store/reducers/globalState.interface';
import { getOnlyDirectoriesActionCreator } from '../../store/actions/fileManager';
import {
  MoveFileRequest,
  FileSchema,
  GetFilesRequest,
  SortBy,
} from '../../store/types/fileManager';

import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';

import EmptyFiles from './EmptyFiles';
import ErrorFiles from './ErrorFiles';
import FileManagerBreadcrumb, { ClickedFolder } from './FileManagerBreadcrumb';

import Spinner from '../../components/Spinner';

export interface MoveFileModalProps {
  show: boolean;
  params: boolean;
  onHide: () => void;
  onSubmit: (moveFileRequest: MoveFileRequest) => void;
  title: string;
  selectedFileSchema: FileSchema;
  isLoading: boolean;
}

const MoveFileModal = ({
  show,
  onHide,
  onSubmit,
  title,
  params,
  selectedFileSchema,
  isLoading = false,
}: MoveFileModalProps) => {
  const {
    onlyDirectoriesLoading,
    onlyDirectories: paginatedOnlyDirectories,
    isGetOnlyDirectoriesSuccess,
    error,
  } = useSelector((state: GlobalState) => state.fileManager);

  const dispatch = useDispatch();

  const [directoryId, setDirectoryId] = React.useState<string | null>(null);

  const [directoryChain, setDirectoryChain] = React.useState<
    Array<ClickedFolder>
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [getOnlyDirectoriesRequest, setOnlyDirectoriesRequest] =
    React.useState<GetFilesRequest>({
      params: {
        limit: paginatedOnlyDirectories.meta.itemsPerPage,
        page: paginatedOnlyDirectories.meta.currentPage,
        search: '',
        type: null,
        directoryId,
        onlyDir: true,
        sortBy: SortBy.CREATED_AT,
        isPublic: params,
      },
    });

  const handleOnPageNumberUpdate = React.useCallback(
    (pageNumber: number) =>
      dispatch(
        getOnlyDirectoriesActionCreator({
          ...getOnlyDirectoriesRequest,
          params: { ...getOnlyDirectoriesRequest.params, page: pageNumber },
        }),
      ),
    [dispatch, getOnlyDirectoriesRequest],
  );

  const handleOnCheckboxChange = React.useCallback(
    (id: string) => {
      return directoryId ? setDirectoryId(null) : setDirectoryId(id);
    },
    [directoryId],
  );

  const handleClickOnFolder = React.useCallback(
    // eslint-disable-next-line consistent-return
    ({ id, isDirectory, title: name }: FileSchema) => {
      if (isDirectory) {
        setDirectoryChain([...directoryChain, { id, name }]);
        dispatch(
          getOnlyDirectoriesActionCreator({
            ...getOnlyDirectoriesRequest,
            params: { ...getOnlyDirectoriesRequest.params, directoryId: id },
          }),
        );
      }
    },
    [directoryChain, dispatch, getOnlyDirectoriesRequest],
  );

  const handleClickOnBreadcrumbItem = React.useCallback(
    ({ id }: ClickedFolder) => {
      const newDirectory = directoryChain;
      const itemIndex = directoryChain.findIndex(
        (directory) => directory?.id === id,
      );

      newDirectory.length = itemIndex + 1;

      setDirectoryChain(newDirectory);

      return dispatch(
        getOnlyDirectoriesActionCreator({
          ...getOnlyDirectoriesRequest,
          params: { ...getOnlyDirectoriesRequest.params, directoryId: id },
        }),
      );
    },
    [directoryChain, dispatch, getOnlyDirectoriesRequest],
  );

  const handleResetBreadcrumbItems = React.useCallback(() => {
    setDirectoryChain([]);

    return dispatch(
      getOnlyDirectoriesActionCreator({
        ...getOnlyDirectoriesRequest,
        params: { ...getOnlyDirectoriesRequest.params, directoryId: null },
      }),
    );
  }, [dispatch, getOnlyDirectoriesRequest]);

  React.useEffect(() => {
    dispatch(getOnlyDirectoriesActionCreator(getOnlyDirectoriesRequest));
  }, [dispatch, getOnlyDirectoriesRequest]);

  const memoizedItems: FileSchema[] = React.useMemo(
    () =>
      paginatedOnlyDirectories.items.filter(
        (item: FileSchema) => item?.id !== selectedFileSchema?.id,
      ),
    [paginatedOnlyDirectories.items, selectedFileSchema?.id],
  );

  return (
    <Modal
      show={show}
      onHide={onHide}
      widthDefaultSize={false}
      title={title}
      withFooter
      footerContent={
        <div className="d-flex flex-center">
          <Pagination
            total={paginatedOnlyDirectories.meta.totalItems - 1}
            pageSize={paginatedOnlyDirectories.meta.itemsPerPage}
            pageNumber={paginatedOnlyDirectories.meta.currentPage}
            onPageNumberUpdate={handleOnPageNumberUpdate}
          />

          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm btn-white btn-active-light-primary me-2"
              onClick={onHide}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="btn btn-sm btn-primary"
              disabled={!directoryId}
              onClick={() =>
                onSubmit({
                  fileIds: [selectedFileSchema?.id],
                  targetDirectoryId: directoryId as string,
                })
              }
            >
              {isLoading ? (
                <span>
                  Moving{' '}
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              ) : (
                <span>Confirm</span>
              )}
            </button>
          </div>
        </div>
      }
    >
      <div className="d-flex flex-column">
        <FileManagerBreadcrumb
          directories={directoryChain}
          clickOnBreadcrumbItem={handleClickOnBreadcrumbItem}
          resetBreadcrumbItems={handleResetBreadcrumbItems}
        />
        <div className="separator my-3" />
        <p className="p-1 text-muted">
          You will be moving folder <strong>{selectedFileSchema?.title}</strong>
        </p>
        <div
          className="d-flex flex-column"
          style={{ height: '400px', overflowY: 'auto' }}
        >
          {onlyDirectoriesLoading ? (
            <Spinner size="md" />
          ) : !isGetOnlyDirectoriesSuccess && error ? (
            <ErrorFiles error={error} />
          ) : paginatedOnlyDirectories.items.length === 0 ? (
            <EmptyFiles />
          ) : (
            <div className="d-flex flex-column align-items-start px-2">
              {memoizedItems.map((item) => (
                <div
                  key={`${item?.title}-${item?.id}`}
                  className="d-flex flex-center my-2 p-4"
                >
                  <input
                    type="checkbox"
                    id={`${item?.title}-${item?.id}`}
                    value={item?.id}
                    name={`${item?.title}-${item?.id}`}
                    checked={Boolean(directoryId) && directoryId === item?.id}
                    onChange={() => handleOnCheckboxChange(item?.id)}
                    className="form-check-input me-2"
                  />

                  <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => handleClickOnFolder(item)}
                    className="d-flex flex-center"
                  >
                    <KTSVG
                      path="/media/icons/duotune/files/fil012.svg"
                      className="svg-icon svg-icon-2x svg-icon-primary me-1"
                    />
                    <span className="text-gray-800 text-hover-primary">
                      {item?.title}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default MoveFileModal;
