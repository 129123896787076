const LIGHT_GOKU_100 = '#F5F5F5';

export const SelectAsyncMultiple = {
  option: (provided: any, state: any) => ({
    ...provided,
    background: state.isFocused && !state.isSelected ? LIGHT_GOKU_100 : '',
    borderRadius: '8px',
  }),

  input: (provided: any) => ({
    ...provided,
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: 'none',
    border: state.isFocused ? '2px solid #4E46B4' : '',
    '&:hover': {
      boxShadow: 'none',
    },
  }),
};
