import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import UsersTable from '../../../components/AssignmentTables/UsersTable';
import { defaultDebounceValue } from '../../../constants/api/debounceSettings';
import User from '../../../interfaces/user/user.interface';
import api from '../../../api';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';

export default ({
  onSelectionChange,
  preselectedItems,
  companyId,
  locationId,
}: {
  onSelectionChange: (selectedItems: string[]) => void;
  preselectedItems: string[];
  companyId?: string;
  locationId?: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [users, setUsers] = useState<User[]>([]);
  const [total, setTotal] = useState<number>(0);

  const { roles } = useSelector((state: GlobalState) => state.userData.user);

  const { id } = useParams<{ id: string }>();

  const fetchUsersList = useCallback(
    debounce(async (searchData: { [key: string]: string }) => {
      try {
        const { items, meta } =
          roles.includes(UserRole.SuperAdmin) && id
            ? await api.users.getCustomerUsersList(id, searchData)
            : await api.users.getUsersListForAssignV2({
                ...searchData,
                companyIds: companyId ? [companyId] : [],
                locationIds: locationId ? [locationId] : [],
              });

        setUsers(items);
        setTotal(meta.totalItems);
      } catch (e) {
        // Do nothing
      }

      setLoading(false);
    }, defaultDebounceValue),
    [],
  );

  useEffect(() => {
    setLoading(true);
    fetchUsersList(filters);
  }, [filters]);

  return (
    <UsersTable
      isLoading={loading}
      users={users}
      total={total}
      onSearchDataChange={setFilters}
      onSelectionChange={onSelectionChange}
      preselectedItems={preselectedItems}
    />
  );
};
