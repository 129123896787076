import browserHistory from '../constants/history';
import { buildQueryString } from './queryParams';
import { defaultDebounceValue } from '../constants/api/debounceSettings';

interface SearchData {
  [key: string]: string | number | boolean | string[];
}

/**
 * Updates the browser history search value
 * @param search
 */
export const updateSearch = (search: SearchData) => {
  browserHistory.replace({
    search: buildQueryString(search, browserHistory.location.search),
  });
};

let pending = false;
let resultSearch: SearchData = {};

/**
 * Updates the browser history search value with merged data
 * retrieved from the calls within the debounce interval
 * @param search
 */
export const debouncedUpdateSearch = (search: SearchData) => {
  resultSearch = { ...resultSearch, ...search };

  if (pending) return;

  pending = true;

  setTimeout(() => {
    updateSearch(resultSearch);

    pending = false;
    resultSearch = {};
  }, Math.floor(defaultDebounceValue / 1.5));
};
