import React, { useState } from 'react';
import {
  FieldValues,
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form';
import useDidUpdate from './useDidUpdate';

/**
 * Enhances the setState with react-hook-form validation
 * @param name
 * @param setValue
 * @param trigger
 * @param initialState
 */
const useStateWithHookForm = <
  F extends FieldValues,
  T extends UnpackNestedValue<PathValue<F, Path<F>>> | undefined,
>(
  {
    name,
    setValue,
    trigger,
  }: {
    name: Path<F>;
    setValue: UseFormSetValue<F>;
    trigger: UseFormTrigger<F>;
  },
  initialState?: T,
): [T, (value: React.SetStateAction<T>) => void] => {
  const [state, setState] = useState<T>(initialState as T);

  useDidUpdate(() => {
    setValue(name, state as T & UnpackNestedValue<PathValue<F, Path<F>>>);
    trigger(name);
  }, [state]);

  return [state, setState];
};

/**
 * Returns the simplified useStateWithHookForm version
 * with already set some arguments
 * @param setValue
 * @param trigger
 * @param initialState
 */
export const prepareHookFromState =
  <
    F extends {
      [key: string]: UnpackNestedValue<PathValue<F, Path<F>>> | undefined;
    },
  >(
    setValue: UseFormSetValue<F>,
    trigger: UseFormTrigger<F>,
    initialState: F,
  ) =>
  <T extends UnpackNestedValue<PathValue<F, Path<F>>> | undefined>(
    name: Path<F>,
    defaultValue?: T,
  ) => {
    return useStateWithHookForm<F, T>(
      { name, trigger, setValue },
      (initialState[name] as T) ?? defaultValue,
    );
  };

export default useStateWithHookForm;
