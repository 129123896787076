import { floor } from 'lodash';
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import IPlaylist from '../../../interfaces/playlist.interface';
import GlobalState from '../../../store/reducers/globalState.interface';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import ViewPlaylistContainer from './ViewPlaylistContainer';

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    msFullscreenElement?: Element;
    mozFullScreenElement?: Element;
    webkitIsFullScreen?: Element;
    mozFullScreen?: Element;
    webkitFullscreenElement?: Element;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

export default ({
  children,
  playlist,
}: {
  children: React.ReactNode;
  playlist: IPlaylist;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isLoading = useSelector((state: GlobalState) => state.contents.loading);
  const [fullscreen, setFullscreen] = useState<
    | true
    | 'sm-down'
    | 'md-down'
    | 'lg-down'
    | 'xl-down'
    | 'xxl-down'
    | undefined
  >();
  // const { t } = useTranslation();
  const heightBaseValue = 4;
  const [windowWidth] = useWindowSize();
  const [url, setUrl] = React.useState('');
  const iframeHeight = floor(windowWidth / heightBaseValue);

  React.useEffect(() => {
    if (!playlist.id) return;
    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?id=${playlist.id}&ratio=${windowWidth}&fullsceen=${fullscreen}`,
    );
  }, [windowWidth, playlist.id, fullscreen]);

  const open = () => setIsModalVisible(true);
  const close = () => {
    setIsModalVisible(false);
  };

  // const copy = async () => {
  //   await navigator.clipboard.writeText(
  //     `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/thumbnail.html?id=${playlist.id}`,
  //   );
  //   toast.success(t('common.copied'));
  // };
  const handleShow = () => {
    setFullscreen(true);
    const targetEl = document.getElementsByClassName(playlist.id.toString());
    const setFull = targetEl.length && targetEl[0];
    if (setFull && setFull.requestFullscreen) {
      setFull.requestFullscreen();
    }
  };

  useEffect(() => {
    const exitHandler = () => {
      if (
        document &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setFullscreen(undefined);
      }
    };

    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }
  }, []);

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={`${playlist.name ?? ''}`}
        withFooter={false}
        fullscreen={fullscreen}
        handleShow={handleShow}
        // copy={copy}
        show={isModalVisible}
        onHide={close}
        bodyClassName={playlist.id}
      >
        <ViewPlaylistContainer
          isLoading={isLoading}
          src={url}
          height={iframeHeight}
          playlist={playlist}
          fullscreen={fullscreen}
        />
      </Modal>
    </>
  );
};
