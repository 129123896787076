import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Location from '../../../../interfaces/location.interface';
import WithClickBackdrop from '../../../../components/WithClickBackdrop';
import LocationForm from '../../../../components/AdminPanel/CompanyLocations/LocationForm';
import EditAdminLocationRequestData from '../../../../api/endpoints/locations/interfaces/editAdminLocationRequestData.interface';
import Modal from '../../../../components/Modal';
import api from '../../../../api';
import useIsAdmin from '../../../../utils/hooks/useIsAdmin';

export default ({
  children,
  location,
  onUpdate,
}: {
  children: React.ReactNode;
  location: Location;
  onUpdate?: () => void;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAdmin] = useIsAdmin();

  const handleSubmit = async (data: EditAdminLocationRequestData) => {
    setIsLoading(true);

    try {
      if (isAdmin) {
        await api.locations.editAdminLocation(location.id, data);
      } else {
        await api.locations.editLocation(location.id, data);
      }
      if (onUpdate) {
        onUpdate();
      }
      toast.success(i18next.t<string>('companies.updated_message'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={() => setOpen(true)}
        onClick={() => setOpen(true)}
      >
        <WithClickBackdrop>{children}</WithClickBackdrop>
      </span>
      <Modal
        title={t('locations.edit')}
        show={open}
        onHide={() => setOpen(false)}
        withFooter={false}
        widthDefaultSize={false}
        dialogClassName="mw-900px"
      >
        <LocationForm
          isLoading={isLoading}
          onSubmit={handleSubmit}
          initialState={{
            city: location.city,
            countyId: location?.county?.id,
            address1: location.address1,
            address2: location.address2,
            email: location.email,
            phone: location.phone,
            name: location.name,
            timezone: location.timezone?.id,
            countryId: location.country,
            zipcode: location.zipcode,
            contact: location.contact,
            stateId: location?.state?.id,
          }}
          isEdit
        />
      </Modal>
    </>
  );
};
