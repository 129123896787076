import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../../../constants/api';
import CountriesRoutes from './enums/countriesRoutes.enum';
import GetCountriesRequestData from './interfaces/getCountriesRequestData.interface';
import GetCountriesResponseData from './interfaces/getCountriesResponseData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import Country from '../../../interfaces/country.interface';

export default {
  /**
   * Retrieves the list of countries
   * @param requestData
   */
  getCountries: async (
    requestData: GetCountriesRequestData,
  ): Promise<GetCountriesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CountriesRoutes.Countries}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the country by id
   * @param id
   */
  getCountry: async (id: string): Promise<Country> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CountriesRoutes.Countries}/${id}`,
      disableToast: true,
    } as AxiosRequestConfig);

    return data;
  },
};
