import SetQueryParamsAction from '../../actions/breadcrumbs/interfaces/setQueryParamsAction.interface';
import BreadcrumbsActions from '../../actions/breadcrumbs/breadcrumbsActions.enum';
import ReduxAction from '../../actions/reduxAction.interface';
import BreadcrumbsState from './breadcrumbsState.interface';
import SetPlaceHolderAction from '../../actions/breadcrumbs/interfaces/setPlaceHolderAction.interface';

const initialState: BreadcrumbsState = {
  queryParams: {},
  placeholder: {},
};

export default function usersReducer(
  state = initialState,
  action: ReduxAction<BreadcrumbsActions>,
): BreadcrumbsState {
  switch (action.type) {
    case BreadcrumbsActions.SET_BREADCRUMBS_QP: {
      const {
        payload: { qp, route, values },
      } = action as SetQueryParamsAction;
      const queryParams = { ...state.queryParams };

      if (!queryParams[route]) {
        queryParams[route] = {};
      }

      queryParams[route][qp] = values;

      return {
        ...state,
        queryParams,
      };
    }
    case BreadcrumbsActions.SET_BREADCRUMBS_PLACEHOLDER: {
      const {
        payload: { route, value },
      } = action as SetPlaceHolderAction;

      const placeholder = { ...state.placeholder };

      placeholder[route] = value;

      return {
        ...state,
        placeholder,
      };
    }
    case BreadcrumbsActions.CLEAR_BREADCRUMBS_PLACEHOLDER: {
      return {
        ...state,
        placeholder: {},
      };
    }
    case BreadcrumbsActions.CLEAR_BREADCRUMBS_QP: {
      return {
        ...state,
        queryParams: {},
      };
    }
    default:
      return state;
  }
}
