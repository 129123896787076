import { TFunction } from 'i18next';
import validation from '../utils/validation';

export const createCompanyValidation = (t: TFunction) => ({
  name: validation
    .string()
    .required(t('contents.validation.please_enter_name')),
  address: validation
    .string()
    .required(t('contents.validation.please_enter_address')),
  description: validation.string(),
  zip: validation
    .string()
    .matches(
      /^(^\d{5}$)|(^\d{5}-\d{4}$)|[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
      t('contents.validation.please_enter_zip'),
    )
    .defined(),
  createUser: validation.boolean(),
  userId: validation.string().when('createUser', {
    is: (value: boolean) => value === false,
    then: validation
      .string()
      .required(t('contents.validation.please_select_owner')),
  }),
  firstName: validation.string().when('createUser', {
    is: (value: boolean) => value === true,
    then: validation
      .string()
      .required(t('users.create_user.validation.please_enter_first_name')),
  }),
  email: validation.string().when('createUser', {
    is: (value: boolean) => value === true,
    then: validation
      .string()
      .required(t('users.create_user.validation.please_enter_email'))
      .isEmail(t('users.create_user.validation.please_enter_valid_email')),
  }),
});
