import React from 'react';
import { Link } from 'react-router-dom';
import routesConfig from '../../routing/config';
import Layout from '../Layout';

export default () => (
  <Layout>
    <div className="d-flex flex-column flex-center flex-column-fluid p-10">
      <img
        src="/media/illustrations/sketchy-1/18.png"
        alt=""
        className="mw-100 mb-10 h-lg-450px"
      />
      <h1 className="fw-bold mb-10" style={{ color: '#A3A3C7' }}>
        Seems there is nothing here
      </h1>
      <Link to={routesConfig.home.route} className="btn btn-primary">
        Return Home
      </Link>
    </div>
  </Layout>
);
