import * as React from 'react';

import { ErrorResponse } from '../../store/types/fileManager';

export interface ErrorFilesProps {
  error: ErrorResponse;
}

const ErrorFiles = ({ error }: ErrorFilesProps) => {
  return (
    <div className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column h-100 py-10 px-10 px-lg-20 mb-10">
      <h1 className="fw-bolder mb-5 text-danger">Something happened.</h1>

      <div className="mb-9 text-dark text-center">
        <p>{error.message}</p>
      </div>
    </div>
  );
};

export default ErrorFiles;
