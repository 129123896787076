import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../components/Modal';

import { FileSchema } from '../../store/types/fileManager';

export interface DeleteFileModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (file: FileSchema) => void;
  title: string;
  selectedFileSchema: FileSchema;
  isLoading: boolean;
}

const DeleteFileModal = ({
  show,
  onHide,
  onSubmit,
  title,
  selectedFileSchema,
  isLoading = false,
}: DeleteFileModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      onHide={onHide}
      widthDefaultSize={false}
      title={title}
      withFooter
      footerContent={
        <div className="d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-danger me-2"
            onClick={() => onSubmit(selectedFileSchema)}
          >
            {isLoading ? (
              <span>
                {t('common.deleting')}{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            ) : (
              <span>{t('common.confirm')}</span>
            )}
          </button>
          <button
            type="button"
            className="btn btn-outline btn-outline-danger btn-active-danger"
            onClick={onHide}
          >
            {t('common.cancel')}
          </button>
        </div>
      }
    >
      <p>
        {selectedFileSchema?.isDirectory
          ? t('common.directory')
          : t('common.file')}{' '}
        <strong>{selectedFileSchema?.title}</strong>{' '}
        {t('file_uploader.removal_confirmation_message')}
      </p>
    </Modal>
  );
};

export default DeleteFileModal;
