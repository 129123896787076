import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import GlobalState from '../../../../store/reducers/globalState.interface';
import { logout } from '../../../../utils/auth0';
import styles from './userMenu.module.scss';
import config from '../../../../routing/config';
import dummyImage from '../../../../assets/images/noPreview/dummy.jpg';

export default ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { firstName, lastName, email, profilePicture } = useSelector(
    (state: GlobalState) => state.userData.user,
  );
  const picture = profilePicture === '' ? dummyImage : profilePicture;

  return (
    <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px show">
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <div
              className="image-input image-input"
              style={{
                backgroundImage: `url(${picture})`,
                marginTop: '1rem',
              }}
            >
              <div
                onContextMenu={(ev) => ev.preventDefault()}
                className="image-input-wrapper w-50px h-50px"
                style={{
                  backgroundImage: `url(${picture})`,
                }}
              />
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {firstName} {lastName}
            </div>
            <Link
              to={config.myAccount.route}
              className={clsx(
                'fw-bold text-muted text-hover-primary fs-7 hoverable',
                styles.email,
              )}
            >
              {email}
            </Link>
          </div>
        </div>
      </div>

      <div className="separator my-2" />

      {children}

      <div className="menu-item px-5">
        <span
          role="button"
          onClick={logout}
          onKeyPress={logout}
          className="menu-link text-hover-primary hoverable px-5"
          tabIndex={-1}
        >
          {t('acc_drop_down.log_out')}
        </span>
      </div>
    </div>
  );
};
