import axios from 'axios';
import { POWER_API_URL } from '../../../../constants/api';
import PowerBiUtility from '../../../../interfaces/apps/powerBiUtility.interface';
import PowerBiScheduleUtility from '../../../../interfaces/apps/powerBiUtilitySchedule.interface';
import IContent from '../../../../interfaces/content.interface';

export default {
  /**
   * Check if user has connected microsoft or not
   */
  checkUserMicrosoftConnected: async (id: string) => {
    const { data } = await axios({
      method: 'get',
      url: `${POWER_API_URL}/tokenForUser/${id}`,
    });
    return data;
  },

  /**
   * Update the current report
   */
  updateReport: async (id: string) => {
    const { data } = await axios({
      method: 'get',
      url: `${POWER_API_URL}/recording/${id}`,
    });
    return data;
  },

  /**
   * Create the same app in utility server
   */
  createApp: async (payload: PowerBiUtility): Promise<PowerBiUtility> => {
    const { data } = await axios({
      method: 'post',
      url: `${POWER_API_URL}/embedURL`,
      data: payload,
    });
    return data;
  },

  /**
   * Update the corresponding app in utility server
   */
  updateApp: async (payload: PowerBiUtility): Promise<void> => {
    const { data } = await axios.put(`${POWER_API_URL}/embedURL`, payload);
    return data;
  },

  /**
   * Delete the same app in utility server
   */
  deleteApp: async (id: string): Promise<void> => {
    await axios.delete(`${POWER_API_URL}/embedURL?id=${id}`);
  },

  /**
   * Add to playlist in utility server
   */
  addToPlaylist: async (payload: PowerBiScheduleUtility): Promise<IContent> => {
    const { data } = await axios({
      method: 'post',
      url: `${POWER_API_URL}/embedURL/schedule`,
      data: payload,
    });
    return data;
  },
};
