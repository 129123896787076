/* eslint-disable camelcase */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../../Table';
import { formatDateString } from '../../../../../utils/dateTime';
import App from '../../../../../interfaces/app.interface';
import PlacementsEnum from '../../../../../enums/placements.enum';
import Yup from '../../../../../utils/validation';
import ScheduleInstagram from '../../../../../containers/Apps/ScheduleApp';
import DeleteInstagram from '../../../../../containers/Apps/DeleteApp';
import PreviewFeedModal from '../../PreviewFeedModal';
import { AppDependency } from '../../../../../store/types/apps/globalTypes';
import EditInstagram from '../../../../../containers/Apps/Instagram/EditInstagram';
import Preview from '../../../../../containers/Apps/Preview';
import UpdateInstagram from '../../../../../containers/Apps/Instagram/UpdateInstagram';
import Tooltip from '../../../../Tooltip';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({
  instagram,
  onUpdate,
}: {
  instagram: App;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [showModalFeed, setShowModalFeed] = useState(false);

  const [brokenApp, setBrokenApp] = useState<string>('');

  const expireDays = 6;

  React.useMemo(() => {
    const instagramFeeds = instagram?.dependency?.config?.feeds;
    const foundFeedWithImage = instagramFeeds?.find(
      (item: { media_url: string }) => item.media_url,
    );
    if (
      !instagram?.dependency?.instagramTokenCache ||
      instagram?.dependency?.instagramTokenCache.nbAttempts > 0
    ) {
      setBrokenApp('alert lost');
    } else if (foundFeedWithImage) {
      const img = new Image();
      img.src = foundFeedWithImage?.media_url;

      img.onerror = () => {
        setBrokenApp('alert images');
      };
    } else if (expireDays <= 5) {
      setBrokenApp('alert lost');
    }
  }, [instagram]);

  return (
    <Row>
      {console.log(instagram?.dependency?.config?.instagramPreview)}
      <Cell isEmpty={!instagram?.name}>
        <div onClick={() => setShowModalFeed(true)} aria-hidden>
          <span className="text-dark cursor-pointer fw-bold text-hover-primary d-block mb-1 fs-6 w-100">
            {instagram?.name}
          </span>
        </div>
        <PreviewFeedModal
          show={showModalFeed}
          onHide={() => {
            setShowModalFeed(false);
          }}
          title={t('common.preview')}
          selectedInstagramFeedSchema={{
            type: 'Instagram',
            createdAt: instagram!.createdAt,
            updatedAt: instagram!.updatedAt,
            dependency: instagram!.dependency as AppDependency,
            id: instagram!.id,
            name: instagram!.name,
            placement: instagram!.placement as PlacementsEnum,
          }}
        />
      </Cell>
      <Cell isEmpty={!instagram?.dependency?.config?.instagramPreview}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {instagram?.dependency?.config?.instagramPreview === 'single_post'
            ? 'Single'
            : 'Wall'}{' '}
          View
        </span>
      </Cell>
      <Cell isEmpty={!instagram?.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {instagram?.ownerApp?.firstName} {instagram?.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!instagram?.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {instagram?.createdAt && formatDate(instagram?.createdAt)}
        </span>
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          {brokenApp.includes('alert') && (
            <>
              <div className="mx-1">
                <UpdateInstagram app={instagram} onUpdate={onUpdate}>
                  <Tooltip
                    text={
                      brokenApp.includes('images')
                        ? t('common.broken_images')
                        : t('common.broken_app')
                    }
                  >
                    <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                      <i className="fa fa-exclamation-triangle text-warning" />{' '}
                    </span>
                  </Tooltip>
                </UpdateInstagram>
              </div>
            </>
          )}

          {!brokenApp.includes('alert') && (
            <>
              <div className="mx-1">
                <Preview instagram={instagram} />
              </div>
              <div className="mx-1">
                <EditInstagram instagram={instagram} onUpdate={onUpdate} />
              </div>
              {PlacementsEnum.FULL_SCREEN && (
                <div className="mx-1">
                  <ScheduleInstagram
                    app={instagram}
                    onUpdate={onUpdate}
                    infoValidationSchema={{
                      orientation: Yup.string().required(t('required')),
                      duration: Yup.number().required(t('required')).min(1),
                      startDate: Yup.date(),
                      endDate: Yup.date().when('startDate', {
                        is: (startDate: any) => !!startDate,
                        then: Yup.date().min(
                          Yup.ref('startDate'),
                          t('common.validation.endDate'),
                        ),
                      }),
                      isLocked: Yup.boolean(),
                    }}
                    skipOrientationAuto
                    skipOrder
                  />
                </div>
              )}
            </>
          )}
          <div className="mx-1">
            <DeleteInstagram
              title={t('common.delete_confirmation.instagram')}
              app={instagram}
              onUpdate={onUpdate}
              toastMessage={t('apps.instagram.InstagramDelete')}
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default TableRow;
