import React, { useEffect } from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import WithPreview from '../../WithPreview';
import styles from './withDevicePreview.module.scss';
import Tooltip from '../../Tooltip';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import { disableRightClickIframe } from '../../../utils/common';

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    msFullscreenElement?: Element;
    mozFullScreenElement?: Element;
    webkitIsFullScreen?: Element;
    mozFullScreen?: Element;
    webkitFullscreenElement?: Element;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

const turnPreviewURLIntoFSURL = (url: string) => {
  return url.replace('preview', 'fs');
};

export default ({
  previewUrl,
  children,
  id,
}: {
  previewUrl: string;
  id?: string;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const [fullscreen, setFullscreen] = React.useState<
    | true
    | 'sm-down'
    | 'md-down'
    | 'lg-down'
    | 'xl-down'
    | 'xxl-down'
    | undefined
  >();
  const [windowWidth] = useWindowSize();
  const [url, setUrl] = React.useState('');

  React.useEffect(() => {
    if (!id) return;
    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?deviceId=${id}&ratio=${windowWidth}&fullsceen=${fullscreen}`,
    );
  }, [windowWidth, id, fullscreen]);

  const copy = async () => {
    if (previewUrl) {
      await navigator.clipboard.writeText(turnPreviewURLIntoFSURL(previewUrl));
    }
    toast.success(t('common.copied'));
  };
  const handleShow = () => {
    setFullscreen(true);
    if (id) {
      const targetEl = document.getElementsByClassName(id.toString());
      const setFull = targetEl.length && targetEl[0];
      if (setFull && setFull.requestFullscreen) {
        setFull.requestFullscreen();
      }
    }
  };

  useEffect(() => {
    const exitHandler = () => {
      if (
        document &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setFullscreen(undefined);
      }
    };

    if (document.addEventListener) {
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }
  }, []);

  React.useEffect(() => {
    if (id) {
      disableRightClickIframe(id);
    }
  }, []);

  return (
    <WithPreview
      title={t('devices.preview_device')}
      copy={copy}
      id={id}
      handleShow={handleShow}
      fullscreen={fullscreen}
      dialogClassName={styles.modal}
      modalContent={
        <div id={id}>
          <iframe
            src={url}
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            className={clsx('w-100  rounded', styles.preview)}
            style={{ height: `${fullscreen ? '100vh' : '500px'}` }}
            title={t('playlists.view_playlist_preview_btn')}
          />
        </div>
      }
    >
      {children ?? (
        <Tooltip text={t('common.preview')}>
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-eye" />
          </span>
        </Tooltip>
      )}
    </WithPreview>
  );
};
