import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { isEqual, map } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap-v5';
import ScheduleContentRequestData from '../../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import SelectDaysOfWeek, {
  getDayOfWeekOption,
} from '../../../../containers/SelectDaysOfWeek';
import SelectTags from '../../../../containers/SelectTags';
import { TagsTarget } from '../../../../api/endpoints/tags/enums/tagsTarget.enum';
import DayOfWeek from '../../../../enums/dayOfWeek.enum';
import Orientation from '../../../../enums/orientation.enum';
import ScheduleContentSteps from '../../../../enums/steps/scheduleContentSteps.enum';
import orientations from '../../../../partials/orientations';
import { prepareHookFromState } from '../../../../utils/hooks/useStateWithHookForm';
import Yup from '../../../../utils/validation';
import Counter from '../../../Counter';
import DatePicker from '../../../DatePicker';
import FormErrorMessage from '../../../FormErrorMessage';
import Modal from '../../../Modal';
import RadioButtons from '../../../RadioButtons';
import { SelectedValue } from '../../../Select/CreatableAsyncSelect';
import SelectMode from '../../../Select/SelectMode';
import SwitchCheckbox from '../../../SwitchCheckbox';
import contentUploadStyles from '../../contentUpload.module.scss';
import StepsTitle from '../../StepsTitle';
import RestrictedAccess from '../../../../containers/RestrictedAccess';
import { UserRole } from '../../../../enums/userRole.enum';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

type FieldsData = Partial<ScheduleContentRequestData>;

interface FormInputs {
  orientation: Orientation;
  duration: number;
  startDate: Date;
  endDate: Date;
  isLocked: boolean;
  isOrdered: boolean;
  tags: string[];
  weekDays: DayOfWeek[];
}

export default ({
  data,
  setData,
  onDecline,
  setMode,
  skipOrder,
  skipOrientationAuto,
  validationSchema,
  scheduleYoutubeApp = false,
  hidePortrait,
  eventDate,
  isCountDown,
  isPowerBiPublished,
}: {
  data: FieldsData;
  setData: (newData: FieldsData) => void;
  onDecline: () => void;
  setMode: (mode: ScheduleContentSteps) => void;
  scheduleYoutubeApp?: boolean;
  skipOrder?: boolean;
  skipOrientationAuto?: boolean;
  validationSchema?: { [key: string]: any };
  hidePortrait?: boolean;
  eventDate?: Date;
  isCountDown?: boolean;
  isPowerBiPublished?: boolean;
}) => {
  const { t } = useTranslation();
  const defaultValues: Partial<FormInputs> = {
    orientation: data.zoneId || Orientation.FullScreen,
    duration: Number(data.duration ?? 15),
    startDate: data.startDate ? new Date(data.startDate) : undefined,
    endDate: data.endDate ? new Date(data.endDate) : undefined,
    isLocked: data.isLocked,
    isOrdered: data.isOrdered,
    tags: data.tags ?? [],
    weekDays: data.weekDays ?? [],
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      Yup.object(
        validationSchema ?? {
          orientation: Yup.string().required(t('required')),
          duration: Yup.number().required(t('required')),
          startDate: Yup.date(),
          endDate: Yup.date().when('startDate', {
            is: (startDate: any) => !!startDate,
            then: Yup.date().min(
              Yup.ref('startDate'),
              t('common.validation.endDate'),
            ),
          }),
          isLocked: Yup.boolean(),
          isOrdered: skipOrder
            ? Yup.boolean()
            : Yup.boolean().required('required'),
        },
      ).required(),
    ),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [tags, setTags] = useFs<FormInputs['tags']>('tags');
  const [days, setDays] = useFs<FormInputs['weekDays']>('weekDays');
  const [duration, setDuration] = useFs<FormInputs['duration']>('duration');
  const [startDate, setStartDate] = useFs<FormInputs['startDate']>('startDate');
  const [endDate, setEndDate] = useFs<FormInputs['endDate']>('endDate');
  const [isOrdered, setIsOrdered] = useFs<FormInputs['isOrdered']>('isOrdered');
  const [orientation, setOrientation] =
    useFs<FormInputs['orientation']>('orientation');
  const [isContentLocked, setIsContentLocked] =
    useFs<FormInputs['isLocked']>('isLocked');

  const handleSubmitData = (inputsData: Partial<FormInputs>) => {
    setData({
      ...data,
      zoneId: inputsData.orientation,
      duration: String(inputsData.duration),
      startDate: inputsData.startDate
        ? inputsData.startDate.toISOString()
        : undefined,
      endDate: inputsData.endDate
        ? inputsData.endDate.toISOString()
        : undefined,
      isLocked: inputsData.isLocked,
      isOrdered: inputsData.isOrdered,
      tags: inputsData.tags,
      weekDays: inputsData.weekDays,
    });

    setMode(ScheduleContentSteps.Devices);
  };

  useEffect(() => {
    if (eventDate) {
      const specificDate = new Date(eventDate);

      // Set the hours to 23 and minutes to 59
      specificDate.setHours(23, 59, 0);

      setEndDate(specificDate);
    }
  }, []);

  return (
    <>
      <StepsTitle currentStep={ScheduleContentSteps.Info} />

      {isPowerBiPublished && (
        <Alert
          key="warning"
          variant="warning"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <i
            className="bi bi-exclamation-triangle-fill"
            style={{ fontWeight: 'bolder', fontSize: '20px' }}
          />
          <span
            style={{
              fontWeight: 'bolder',
              fontSize: '1em',

              marginLeft: '10px',
            }}
          >
            Power BI is only available to enterprise devices and device groups.
          </span>
        </Alert>
      )}

      <form onSubmit={handleSubmit(handleSubmitData)}>
        <div className="d-flex flex-row flex-column-fluid mb-2">
          <div className="d-flex w-50 flex-row-fluid flex-column me-10 rounded border p-10 pb-5 mb-5">
            <h2>{t('library.indigo.publishModal.addInfo')}*</h2>
            <label
              htmlFor="contentOrientation"
              className="required fw-bold mb-2"
            >
              {t('common.orientation')}
            </label>
            <SelectMode<Orientation>
              asInput
              className="flex-wrap"
              inputName="orientation"
              inputId="contentOrientation"
              modes={
                hidePortrait
                  ? orientations({
                      landscapeText: t('common.landscape'),
                      autoText: !skipOrientationAuto
                        ? t('common.auto_select')
                        : undefined,
                      fullScreenText: t('common.full_screen'),
                    })
                  : orientations({
                      landscapeText: t('common.landscape'),
                      portraitText: t('common.portrait'),
                      autoText: !skipOrientationAuto
                        ? t('common.auto_select')
                        : undefined,
                      fullScreenText: t('common.full_screen'),
                    })
              }
              modeValue={orientation ?? 'fullScreen'}
              onSelect={setOrientation}
            />
            <FormErrorMessage
              name="orientation"
              errors={errors}
              className={errorMessageClassNames}
            />

            {scheduleYoutubeApp ? null : (
              <div>
                <Counter
                  name="duration"
                  title={t('common.duration')}
                  placeholder={t('contents.type_here')}
                  value={duration}
                  onChange={setDuration}
                  className={contentUploadStyles.duration}
                />
                <FormErrorMessage
                  name="duration"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
            )}

            {skipOrder || scheduleYoutubeApp ? null : (
              <div>
                <span className="form-label d-flex fw-bold mb-4">
                  {t('contents.is_ordered')}
                </span>
                <RadioButtons
                  name="isOrdered"
                  onChange={(value) => setIsOrdered(value === 'true')}
                  options={[
                    {
                      label: t('contents.order.ordered'),
                      value: 'true',
                      key: 'ordered',
                      checked: !!isOrdered,
                    },
                    {
                      label: t('contents.order.random'),
                      value: 'false',
                      key: 'random',
                      checked: isOrdered === false,
                    },
                  ]}
                />
                <FormErrorMessage
                  name="isOrdered"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>
            )}

            <label htmlFor="contentTag" className="fw-bold mb-2">
              {t('contents.tag')}
            </label>
            <SelectTags
              id="contentTag"
              target={TagsTarget.Contents}
              initialValue={tags.map((value) => ({ value, label: value }))}
              onChange={(values) => {
                const newValues = map<SelectedValue>(values, 'value');

                if (!isEqual(newValues, tags)) {
                  setTags(newValues);
                }
              }}
            />
            <FormErrorMessage
              name="tag"
              errors={errors}
              className={errorMessageClassNames}
            />
          </div>

          <div className="d-flex w-50 flex-row-fluid flex-column position-relative">
            <div className="rounded border p-10 pb-5 mb-5">
              <div>
                {isCountDown ? (
                  <h3 className="text-dark fw-bolder text-hover-primary">
                    {t('contents.schedule_title_count_down')}
                  </h3>
                ) : (
                  <h2 className="text-dark fw-bolder text-hover-primary">
                    {t('contents.schedule_title')}
                  </h2>
                )}
                <p className="py-3">
                  {isCountDown
                    ? t('contents.schedule_text_count_down')
                    : t('contents.schedule_text')}
                </p>
              </div>

              <div className="fv-row mb-5">
                <label
                  htmlFor="startDate"
                  className="d-flex align-items-center fs-6"
                >
                  <span>Start Date</span>
                </label>
                <DatePicker
                  name="startDate"
                  value={startDate}
                  placeholder="Select start date - time"
                  onChange={(newDate) => setStartDate(newDate[0])}
                  options={{
                    enableTime: true,
                    defaultHour: 0,
                  }}
                />
                <FormErrorMessage name="startDate" errors={errors} />
              </div>

              <div className="fv-row mb-10">
                <label
                  htmlFor="endDate"
                  className="d-flex align-items-center fs-6"
                >
                  <span>End Date</span>
                </label>
                <DatePicker
                  name="endDate"
                  value={endDate}
                  placeholder="Select end date - time"
                  onChange={(newDate) => setEndDate(newDate[0])}
                  options={{
                    enableTime: true,
                    defaultHour: 23,
                    defaultMinute: 59,
                  }}
                />
                <FormErrorMessage name="endDate" errors={errors} />
              </div>

              <div className="separator separator-content text-muted mb-7 mt-2">
                {t('contents.and_or')}
              </div>

              <span className="fw-bold mb-4">{t('contents.mtwtfss')}</span>
              <SelectDaysOfWeek
                initialValue={days.map((day) => getDayOfWeekOption(day, t))}
                onChange={(selectedDays) =>
                  setDays(selectedDays as DayOfWeek[])
                }
              />
            </div>

            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.Manager,
                UserRole.IndigoAdmin,
                UserRole.Editor,
              ]}
            >
              <div className="d-inline-flex justify-content-between w-100">
                <label
                  htmlFor="lockContent"
                  className="form-label fw-bold pt-1"
                >
                  {t('contents.lock_content')}
                </label>
                <SwitchCheckbox
                  name="lockContent"
                  inputClassName="h-20px w-30px"
                  checked={!!isContentLocked}
                  onChange={({ currentTarget: { checked } }) =>
                    setIsContentLocked(checked)
                  }
                />
              </div>
            </RestrictedAccess>
          </div>
        </div>

        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-white text-primary"
            onClick={onDecline}
          >
            {t('common.close')}
          </button>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {t('common.next_step')}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
