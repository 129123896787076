/* eslint-disable prefer-spread */
/* eslint-disable no-useless-concat */
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';

import { getComparatorBy } from '../../../utils/common';
import User from '../../../interfaces/user/user.interface';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
import api from '../../../api';
import SelectAsync, {
  SelectedValue,
} from '../../../components/Select/SelectAsync';
import dropdownsData from '../../../constants/dropdownsData';
import ISelectCompany from '../../../interfaces/selectCompany.interface';

/**
 * Retrieves the select options asynchronously
 * @param search
 * @param isAdmin
 * @param currentUserId
 */
const loadOptions = async (
  search?: string,
  isAdmin?: boolean,
  currentUserId?: string,
  limit?: string,
  trialCompany?: boolean,
  testData?: boolean,
): Promise<ISelectCompany[]> => {
  const { items } = isAdmin
    ? await api.companies.getAdminCompaniesListForSelect({
        search,
        currentUserId,
        limit,
        trialCompany,
        isTest: !!testData,
      })
    : await api.companies.getCompaniesListForSelect({ search, limit });
  return sortBy(items, ['name']).map(({ id, name, owner, locations }) => ({
    value: id,
    label: name,
    ownerId: owner.id,
    locations,
  }));
};

const SelectCompany = ({
  onChange,
  initialValue,
  id,
  currentUserId,
  className,
  isClearable,
  placeholder,
  noAutocomplete = true,
  currentUser,
  trialCompany,
  testData,
  userId,
}: {
  onChange: (selectedValue: SelectedValue | ISelectCompany) => void;
  currentUser?: User;
  initialValue?: string;
  id?: string;
  className?: string;
  placeholder?: string;
  isClearable?: boolean;
  noAutocomplete?: boolean;
  currentUserId?: string | null;
  trialCompany?: boolean;
  testData?: boolean;
  userId?: string;
}) => {
  const { limit } = dropdownsData;
  const { t } = useTranslation();
  const isFirstRequestRef = useRef(false);
  const [selectedValuesop, setSelectedValue] = useState<SelectedValue>({
    value: 'loadingValue',
    label: `${t('common.loading')}...`,
  });
  const [loadedCompanies, setLoadedCompanies] = useState<
    SelectedValue[] | undefined
  >(undefined);

  const [isAdmin] = useIsAdmin();

  useEffect(() => {
    if (loadedCompanies) {
      setSelectedValue(
        loadedCompanies?.find(
          (company) => initialValue === company.value,
        ) as SelectedValue,
      );
    }
  }, [loadedCompanies]);

  const handleLoad = async (search: string) => {
    const response = await loadOptions(
      search,
      isAdmin,
      currentUser?.id ?? userId ?? (currentUserId || ''),
      limit,
      trialCompany,
      testData,
    );
    if (search) return response;

    if (response.length === 1 && !isFirstRequestRef.current) {
      isFirstRequestRef.current = true;
      const defaultSelect = response[0].value;
      if (!defaultSelect) return response;

      const name = response.find(({ value }) => value === defaultSelect)?.label;
      setSelectedValue({ label: name, value: defaultSelect });
    }

    setLoadedCompanies(response ?? []);

    return response;
  };

  return (
    <SelectAsync
      id={id}
      initialValue={selectedValuesop}
      onChange={onChange}
      loadOptions={handleLoad}
      className={className}
      placeholder={placeholder ?? t('common.filter_by_company')}
      isClearable={isClearable}
      noAutocomplete={noAutocomplete}
      key={`${trialCompany}` + `${testData}`}
    />
  );
};

SelectCompany.defaultProps = {
  initialValue: null,
};

export const MemoizedSelectCompany = memo(
  SelectCompany,
  getComparatorBy(['id', 'className', 'isClearable', 'trialCompany']),
);

export default SelectCompany;
