import React from 'react';
import RadioButtonActive from '../../assets/images/icons/RadioButtonActive.svg';
import RadioButtonInactive from '../../assets/images/icons/RadioButtonInactive.svg';

interface RadiobuttonProps {
  active: boolean;
  title: string;
  subtitle?: string;
}

const Radiobutton = ({ active, title, subtitle }: RadiobuttonProps) => {
  return (
    <div className="d-flex cursor-pointer">
      <div className="me-2">
        {active ? (
          <img src={RadioButtonActive} alt="active" />
        ) : (
          <img src={RadioButtonInactive} alt="inactive" />
        )}
      </div>
      <div>
        <div className="fs-3">{title}</div>
        {subtitle && <div>{subtitle}</div>}
      </div>
    </div>
  );
};

Radiobutton.RadiobuttonProps = {
  active: false,
  title: 'Radio Button',
};

export default Radiobutton;
