import React from 'react';
import LocationsTable from './CompanyLocation';

import Location from '../../../interfaces/location.interface';
import User from '../../../interfaces/user/user.interface';
import NavTabs from '../../NavTabs';
import LoginAsButton from '../../AdminPanel/LoginAsConfirm/LoginAsButton';

export default ({
  isLoading,
  tabs,
  user,
  className,
  locations,
  setRequestData,
  requestData,
  isSelectable,
  total,
  onUpdate,
  showModal = false,
  isAccountOwner = false,
  loggedInAsAccountOwner = false,
}: {
  isLoading: boolean;
  onUpdate: () => void;
  total: number;
  setRequestData: any;
  requestData: any;
  locations?: Location[];
  isSelectable?: boolean;
  tabs: Array<{
    key: string;
    tab: React.ReactNode;
    isHidden?: boolean;
  }>;
  user: User;
  className?: string;
  showModal?: boolean;
  isAccountOwner?: boolean;
  loggedInAsAccountOwner?: boolean;
}) => {
  return (
    <LocationsTable
      loggedInAsAccountOwner={loggedInAsAccountOwner}
      isAccountOwner={isAccountOwner}
      showModal={showModal}
      isLoading={isLoading}
      locations={locations}
      onUpdate={onUpdate}
      total={total}
      setRequestData={setRequestData}
      requestData={requestData}
      isSelectable={isSelectable}
    >
      <NavTabs tabs={tabs} separatorClassName="mb-7" />
      <LoginAsButton user={user} className={className} />
    </LocationsTable>
  );
};
