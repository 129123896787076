import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import UsersGroup from '../../../interfaces/usersGroup.interface';
import UpdateUsersGroupRequestData from '../../../api/endpoints/usersGroups/interfaces/updateUsersGroupRequestData.interface';
import WithModesModal from '../../WithModesModal';
import AddUsersGroupSteps from '../../../enums/steps/addUsersGroupSteps.enum';
import useModalState from '../../../utils/hooks/useModalState';
import CreateUsersGroupRequestData from '../../../api/endpoints/usersGroups/interfaces/createUsersGroupRequestData.interface';
import Assign from '../CreateGroup/Assign';
import Info from '../CreateGroup/Info';
import sizingStyles from '../../../styles/sizing.module.scss';

type FormInputs = Partial<UpdateUsersGroupRequestData>;

interface EditUsersGroupProps {
  group: UsersGroup;
  children: React.ReactNode;
  onSubmit?: (updateData: {
    id: string;
    data: UpdateUsersGroupRequestData;
  }) => void;
  onEditUserGroup?: (data: FormInputs, id: string) => void;
}

interface DefaultProps extends EditUsersGroupProps {
  onSubmit: Required<EditUsersGroupProps>['onSubmit'];
}

const EditUsersGroup = ({
  group,
  children,
  onSubmit,
  onEditUserGroup,
}: DefaultProps) => {
  const { t } = useTranslation();

  const [stepsData, setStepsData] = useState<
    Partial<CreateUsersGroupRequestData>
  >({});
  const [isVisible, handleOpen, handleClose] = useModalState(false);

  const handleSubmitData = (data: FormInputs) => {
    if (onEditUserGroup) {
      onEditUserGroup(data, group.id);
      return;
    }
    onSubmit({
      id: group.id,
      data,
    });

    handleClose();
  };

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={handleOpen}
        onClick={handleOpen}
      >
        {children}
      </span>
      {/* <Modal
        title={t('users_groups.edit_group.edit_users_group')}
        widthDefaultSize={false}
        show={isModalVisible}
        onHide={handleClose}
        bodyClassName="py-5"
        withFooter={false}
      >
        <EditGroupForm group={group} onSubmit={handleSubmitData} />
      </Modal> */}

      <WithModesModal<any, AddUsersGroupSteps>
        isVisible={isVisible}
        onClose={handleClose}
        modes={{
          [AddUsersGroupSteps.Info]: {
            title: t('users_groups.edit_group.edit_users_group'),
            render: ({ setMode }) => (
              <Info
                group={group}
                setMode={setMode}
                data={stepsData}
                setData={setStepsData}
              />
            ),
          },
          [AddUsersGroupSteps.Assign]: {
            title: t('users_groups.edit_group.edit_users_group'),
            render: ({ setMode, close }) => (
              <Assign
                group={group}
                setMode={setMode}
                onSubmit={(data) => {
                  close();
                  setStepsData({});
                  handleSubmitData(data);
                }}
                setData={setStepsData}
                data={stepsData}
              />
            ),
          },
        }}
        defaultMode={AddUsersGroupSteps.Info}
        dialogClassName={clsx('mw-1000px', sizingStyles.fitContentOld)}
      />
    </>
  );
};

EditUsersGroup.defaultProps = {
  onSubmit: () => null,
};

export default EditUsersGroup;
