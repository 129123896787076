import { TFunction } from 'i18next';
import validation from '../utils/validation';

export const editDeviceValidation = (t: TFunction) => ({
  name: validation
    .string()
    .min(1, t('devices.validation.please_enter_device_name')),
  zipCode: validation
    .string()
    .length(5, t('devices.validation.zipcode'))
    .matches(/^[0-9]{5}/),
  aspectRatio: validation
    .string()
    .matches(/^(\d+):(\d+)$/, t('devices.validation.aspectRatio')),
  deviceGroupIds: validation
    .array()
    .min(1, t('devices.validation.device_groups')),
});

export const bulkEditDeviceValidation = (t: TFunction) => ({
  name: validation
    .string()
    .min(1, t('devices.validation.please_enter_device_name')),
  zipCode: validation
    .string()
    .test(
      'len',
      t('devices.validation.zipcode'),
      (val) => !val || val.length === 5,
    )
    .matches(/^[0-9]{5}/, { excludeEmptyString: true }),
  aspectRatio: validation.string().matches(/^(\d+):(\d+)$/, {
    excludeEmptyString: true,
    message: t('devices.validation.aspectRatio'),
  }),
  deviceGroupIds: validation
    .array()
    .min(1, t('devices.validation.device_groups')),
});

export const assignDeviceValidation = (t: TFunction) => ({
  devices: validation.array().min(1, t('playlists.validation.devices')),
});
