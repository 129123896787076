import { useState } from 'react';

export default (
  initialState: boolean = false,
): [boolean, () => void, () => void] => {
  const [isModalVisible, setIsModalVisible] = useState(initialState);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  return [isModalVisible, open, close];
};
