import { TFunction } from 'i18next';
import validation from '../utils/validation';

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];

export const createCategoryValidation = (t: TFunction) => ({
  name: validation
    .string()
    .required(t('contents.validation.please_enter_name')),

  icon: validation.string(),
  // // file: validation.mixed().when('icon', {
  // //   is: (value: any) => !!value,
  // //   then: validation.mixed().required('This is a required field.'),
  // // }),

  // // .required(t('contents.validation.file_is_required')),
  file: validation.mixed().when('icon', {
    is: (icon: string) => icon === '',
    then: validation
      .mixed()
      .required('Icon is required')
      .test(
        'fileFormat',
        'Unsupported Format',
        (value) => value && SUPPORTED_FORMATS.includes(value.type),
      ),
  }),
});
