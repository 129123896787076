import { ErrorResponse } from '../../types/apps/globalTypes';
import {
  CreateScheduleYoutubeFeedRequest,
  CreateYoutubeFeedRequest,
  DeleteYoutubeFeedRequest,
  EditYoutubeFeedRequest,
  GetYoutubeFeedsRequest,
  PaginatedYoutubeFeeds,
  ScheduleYoutubeFeedRequest,
  YoutubeFeedSchema,
} from '../../types/apps/youtubeFeed';

export interface YoutubeFeedsReduxAction<TYPE = ACTIONS, PAYLOAD = any> {
  type: TYPE;
  payload?: PAYLOAD;
}

export enum ACTIONS {
  GET_YOUTUBE_FEEDS = 'GET_YOUTUBE_FEEDS',
  GET_YOUTUBE_FEEDS_LOADING = 'GET_YOUTUBE_FEEDS_LOADING',
  GET_YOUTUBE_FEEDS_SUCCESS = 'GET_YOUTUBE_FEEDS_SUCCESS',
  GET_YOUTUBE_FEEDS_ERROR = 'GET_YOUTUBE_FEEDS_ERROR',
  GET_YOUTUBE_FEEDS_RESET = 'GET_YOUTUBE_FEEDS_RESET',

  CREATE_YOUTUBE_FEED = 'CREATE_YOUTUBE_FEED',
  CREATE_YOUTUBE_FEED_LOADING = 'CREATE_YOUTUBE_FEED_LOADING',
  CREATE_YOUTUBE_FEED_SUCCESS = 'CREATE_YOUTUBE_FEED_SUCCESS',
  CREATE_YOUTUBE_FEED_ERROR = 'CREATE_YOUTUBE_FEED_ERROR',
  CREATE_YOUTUBE_FEED_RESET = 'CREATE_YOUTUBE_FEED_RESET',

  SCHEDULE_YOUTUBE_FEED = 'SCHEDULE_YOUTUBE_FEED',
  SCHEDULE_YOUTUBE_FEED_LOADING = 'SCHEDULE_YOUTUBE_FEED_LOADING',
  SCHEDULE_YOUTUBE_FEED_SUCCESS = 'SCHEDULE_YOUTUBE_FEED_SUCCESS',
  SCHEDULE_YOUTUBE_FEED_ERROR = 'SCHEDULE_YOUTUBE_FEED_ERROR',
  SCHEDULE_YOUTUBE_FEED_RESET = 'SCHEDULE_YOUTUBE_FEED_RESET',

  DELETE_YOUTUBE_FEED = 'DELETE_YOUTUBE_FEED',
  DELETE_YOUTUBE_FEED_LOADING = 'DELETE_YOUTUBE_FEED_LOADING',
  DELETE_YOUTUBE_FEED_SUCCESS = 'DELETE_YOUTUBE_FEED_SUCCESS',
  DELETE_YOUTUBE_FEED_ERROR = 'DELETE_YOUTUBE_FEED_ERROR',
  DELETE_YOUTUBE_FEED_RESET = 'DELETE_YOUTUBE_FEED_RESET',

  EDIT_YOUTUBE_FEED = 'EDIT_YOUTUBE_FEED',
  EDIT_YOUTUBE_FEED_LOADING = 'EDIT_YOUTUBE_FEED_LOADING',
  EDIT_YOUTUBE_FEED_SUCCESS = 'EDIT_YOUTUBE_FEED_SUCCESS',
  EDIT_YOUTUBE_FEED_ERROR = 'EDIT_YOUTUBE_FEED_ERROR',
  EDIT_YOUTUBE_FEED_RESET = 'EDIT_YOUTUBE_FEED_RESET',

  CREATE_SCHEDULE_YOUTUBE_FEED = 'CREATE_SCHEDULE_YOUTUBE_FEED',
  CREATE_SCHEDULE_YOUTUBE_FEED_LOADING = 'CREATE_SCHEDULE_YOUTUBE_FEED_LOADING',
  CREATE_SCHEDULE_YOUTUBE_FEED_SUCCESS = 'CREATE_SCHEDULE_YOUTUBE_FEED_SUCCESS',
  CREATE_SCHEDULE_YOUTUBE_FEED_ERROR = 'CREATE_SCHEDULE_YOUTUBE_FEED_ERROR',
  CREATE_SCHEDULE_YOUTUBE_FEED_RESET = 'CREATE_SCHEDULE_YOUTUBE_FEED_RESET',
}

export interface GET_YOUTUBE_FEEDS_ACTIONS
  extends YoutubeFeedsReduxAction<
    ACTIONS.GET_YOUTUBE_FEEDS,
    GetYoutubeFeedsRequest
  > {}

export const getYoutubeFeedsActionCreator = (
  payload: GET_YOUTUBE_FEEDS_ACTIONS['payload'],
): GET_YOUTUBE_FEEDS_ACTIONS => ({
  type: ACTIONS.GET_YOUTUBE_FEEDS,
  payload,
});

export interface CREATE_YOUTUBE_FEED_ACTIONS
  extends YoutubeFeedsReduxAction<
    ACTIONS.CREATE_YOUTUBE_FEED,
    CreateYoutubeFeedRequest
  > {}

export const createYoutubeFeedActionCreator = (
  payload: CREATE_YOUTUBE_FEED_ACTIONS['payload'],
): CREATE_YOUTUBE_FEED_ACTIONS => ({
  type: ACTIONS.CREATE_YOUTUBE_FEED,
  payload,
});

export interface CREATE_SCHEDULE_YOUTUBE_FEED_ACTIONS
  extends YoutubeFeedsReduxAction<
    ACTIONS.CREATE_SCHEDULE_YOUTUBE_FEED,
    CreateScheduleYoutubeFeedRequest
  > {}

export const createScheduleYoutubeFeedActionCreator = (
  payload: CREATE_SCHEDULE_YOUTUBE_FEED_ACTIONS['payload'],
): CREATE_SCHEDULE_YOUTUBE_FEED_ACTIONS => ({
  type: ACTIONS.CREATE_SCHEDULE_YOUTUBE_FEED,
  payload,
});

export interface CREATE_SCHEDULE_YOUTUBE_FEED_RESET_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.CREATE_SCHEDULE_YOUTUBE_FEED_RESET,
    {
      error: null;
      loading: boolean;
      isCreateScheduleYOUTUBEFeedSuccess: boolean;
    }
  > {}

export const createScheduleYOUTUBEFeedResetActionCreator = (
  payload: CREATE_SCHEDULE_YOUTUBE_FEED_RESET_ACTION['payload'],
): CREATE_SCHEDULE_YOUTUBE_FEED_RESET_ACTION => ({
  type: ACTIONS.CREATE_SCHEDULE_YOUTUBE_FEED_RESET,
  payload,
});

export interface EDIT_YOUTUBE_FEED_ACTIONS
  extends YoutubeFeedsReduxAction<
    ACTIONS.EDIT_YOUTUBE_FEED,
    EditYoutubeFeedRequest
  > {}

export const editYoutubeFeedActionCreator = (
  payload: EDIT_YOUTUBE_FEED_ACTIONS['payload'],
): EDIT_YOUTUBE_FEED_ACTIONS => ({
  type: ACTIONS.EDIT_YOUTUBE_FEED,
  payload,
});

export interface SCHEDULE_YOUTUBE_FEED_ACTIONS
  extends YoutubeFeedsReduxAction<
    ACTIONS.SCHEDULE_YOUTUBE_FEED,
    ScheduleYoutubeFeedRequest
  > {}

export const scheduleYoutubeFeedActionCreator = (
  payload: SCHEDULE_YOUTUBE_FEED_ACTIONS['payload'],
): SCHEDULE_YOUTUBE_FEED_ACTIONS => ({
  type: ACTIONS.SCHEDULE_YOUTUBE_FEED,
  payload,
});

export interface DELETE_YOUTUBE_FEED_ACTIONS
  extends YoutubeFeedsReduxAction<
    ACTIONS.DELETE_YOUTUBE_FEED,
    DeleteYoutubeFeedRequest
  > {}

export const deleteYoutubeFeedActionCreator = (
  payload: DELETE_YOUTUBE_FEED_ACTIONS['payload'],
): DELETE_YOUTUBE_FEED_ACTIONS => ({
  type: ACTIONS.DELETE_YOUTUBE_FEED,
  payload,
});

// Success action and success action creator
export interface CREATE_YOUTUBE_FEED_SUCCESS_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.CREATE_YOUTUBE_FEED_SUCCESS,
    {
      data: Partial<YoutubeFeedSchema>;
      loading: boolean;
      isCreateYoutubeFeedSuccess: boolean;
    }
  > {}

export const createYoutubeFeedSuccessActionCreator = (
  payload: CREATE_YOUTUBE_FEED_SUCCESS_ACTION['payload'],
): CREATE_YOUTUBE_FEED_SUCCESS_ACTION => ({
  type: ACTIONS.CREATE_YOUTUBE_FEED_SUCCESS,
  payload,
});

export interface EDIT_YOUTUBE_FEED_SUCCESS_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.EDIT_YOUTUBE_FEED_SUCCESS,
    {
      data: Partial<YoutubeFeedSchema>;
      loading: boolean;
      isEditYoutubeFeedSuccess: boolean;
    }
  > {}

export const editYoutubeFeedSuccessActionCreator = (
  payload: EDIT_YOUTUBE_FEED_SUCCESS_ACTION['payload'],
): EDIT_YOUTUBE_FEED_SUCCESS_ACTION => ({
  type: ACTIONS.EDIT_YOUTUBE_FEED_SUCCESS,
  payload,
});

export interface GET_YOUTUBE_FEEDS_SUCCESS_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.GET_YOUTUBE_FEEDS_SUCCESS,
    {
      data: PaginatedYoutubeFeeds;
      loading: boolean;
      isGetYoutubeFeedsSuccess: boolean;
    }
  > {}

export const getYoutubeFeedsSuccessActionCreator = (
  payload: GET_YOUTUBE_FEEDS_SUCCESS_ACTION['payload'],
): GET_YOUTUBE_FEEDS_SUCCESS_ACTION => ({
  type: ACTIONS.GET_YOUTUBE_FEEDS_SUCCESS,
  payload,
});

export interface SCHEDULE_YOUTUBE_FEED_SUCCESS_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.SCHEDULE_YOUTUBE_FEED_SUCCESS,
    {
      data: Partial<YoutubeFeedSchema>;
      loading: boolean;
      isScheduleYoutubeFeedSuccess: boolean;
    }
  > {}

export const scheduleYoutubeFeedSuccessActionCreator = (
  payload: SCHEDULE_YOUTUBE_FEED_SUCCESS_ACTION['payload'],
): SCHEDULE_YOUTUBE_FEED_SUCCESS_ACTION => ({
  type: ACTIONS.SCHEDULE_YOUTUBE_FEED_SUCCESS,
  payload,
});
export interface CREATE_SCHEDULE_YOUTUBE_FEED_SUCCESS_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.CREATE_SCHEDULE_YOUTUBE_FEED_SUCCESS,
    {
      data: Partial<YoutubeFeedSchema>;
      loading: boolean;
      isCreateScheduleYoutubeFeedSuccess: boolean;
    }
  > {}

export const createScheduleYoutubeFeedSuccessActionCreator = (
  payload: CREATE_SCHEDULE_YOUTUBE_FEED_SUCCESS_ACTION['payload'],
): CREATE_SCHEDULE_YOUTUBE_FEED_SUCCESS_ACTION => ({
  type: ACTIONS.CREATE_SCHEDULE_YOUTUBE_FEED_SUCCESS,
  payload,
});

export interface DELETE_YOUTUBE_FEED_SUCCESS_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.DELETE_YOUTUBE_FEED_SUCCESS,
    { data: void; loading: boolean; isDeleteYoutubeFeedSuccess: boolean }
  > {}

export const deleteYoutubeFeedSuccessActionCreator = (
  payload: DELETE_YOUTUBE_FEED_SUCCESS_ACTION['payload'],
): DELETE_YOUTUBE_FEED_SUCCESS_ACTION => ({
  type: ACTIONS.DELETE_YOUTUBE_FEED_SUCCESS,
  payload,
});

// Loading action and loading action creator
export interface GET_YOUTUBE_FEEDS_LOADING_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.GET_YOUTUBE_FEEDS_LOADING,
    { loading: boolean }
  > {}

export const getYoutubeFeedsLoadingActionCreator = (
  payload: GET_YOUTUBE_FEEDS_LOADING_ACTION['payload'],
): GET_YOUTUBE_FEEDS_LOADING_ACTION => ({
  type: ACTIONS.GET_YOUTUBE_FEEDS_LOADING,
  payload,
});

export interface CREATE_YOUTUBE_FEED_LOADING_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.CREATE_YOUTUBE_FEED_LOADING,
    { loading: boolean }
  > {}

export const createYoutubeFeedLoadingActionCreator = (
  payload: CREATE_YOUTUBE_FEED_LOADING_ACTION['payload'],
): CREATE_YOUTUBE_FEED_LOADING_ACTION => ({
  type: ACTIONS.CREATE_YOUTUBE_FEED_LOADING,
  payload,
});

export interface EDIT_YOUTUBE_FEED_LOADING_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.EDIT_YOUTUBE_FEED_LOADING,
    { loading: boolean }
  > {}

export const editYoutubeFeedLoadingActionCreator = (
  payload: EDIT_YOUTUBE_FEED_LOADING_ACTION['payload'],
): EDIT_YOUTUBE_FEED_LOADING_ACTION => ({
  type: ACTIONS.EDIT_YOUTUBE_FEED_LOADING,
  payload,
});

export interface CREATE_SHEDULE_YOUTUBE_FEED_ERROR_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.CREATE_SCHEDULE_YOUTUBE_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isCreateYOUTUBEFeedSuccess: boolean;
    }
  > {}

export const createScheduleYOUTUBEFeedErrorActionCreator = (
  payload: CREATE_SHEDULE_YOUTUBE_FEED_ERROR_ACTION['payload'],
): CREATE_SHEDULE_YOUTUBE_FEED_ERROR_ACTION => ({
  type: ACTIONS.CREATE_SCHEDULE_YOUTUBE_FEED_ERROR,
  payload,
});

export interface SCHEDULE_YOUTUBE_FEED_LOADING_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.SCHEDULE_YOUTUBE_FEED_LOADING,
    { loading: boolean }
  > {}

export const scheduleYoutubeFeedLoadingActionCreator = (
  payload: SCHEDULE_YOUTUBE_FEED_LOADING_ACTION['payload'],
): SCHEDULE_YOUTUBE_FEED_LOADING_ACTION => ({
  type: ACTIONS.SCHEDULE_YOUTUBE_FEED_LOADING,
  payload,
});

export interface DELETE_YOUTUBE_FEED_LOADING_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.DELETE_YOUTUBE_FEED_LOADING,
    { loading: boolean }
  > {}

export const deleteYoutubeFeedLoadingActionCreator = (
  payload: DELETE_YOUTUBE_FEED_LOADING_ACTION['payload'],
): DELETE_YOUTUBE_FEED_LOADING_ACTION => ({
  type: ACTIONS.DELETE_YOUTUBE_FEED_LOADING,
  payload,
});

// Error action and error action creator
export interface GET_YOUTUBE_FEEDS_ERROR_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.GET_YOUTUBE_FEEDS_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isGetYoutubeFeedsSuccess: boolean;
    }
  > {}

export const getYoutubeFeedsErrorActionCreator = (
  payload: GET_YOUTUBE_FEEDS_ERROR_ACTION['payload'],
): GET_YOUTUBE_FEEDS_ERROR_ACTION => ({
  type: ACTIONS.GET_YOUTUBE_FEEDS_ERROR,
  payload,
});

export interface CREATE_YOUTUBE_FEED_ERROR_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.CREATE_YOUTUBE_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isCreateYoutubeFeedSuccess: boolean;
    }
  > {}

export const createYoutubeFeedErrorActionCreator = (
  payload: CREATE_YOUTUBE_FEED_ERROR_ACTION['payload'],
): CREATE_YOUTUBE_FEED_ERROR_ACTION => ({
  type: ACTIONS.CREATE_YOUTUBE_FEED_ERROR,
  payload,
});

export interface EDIT_YOUTUBE_FEED_ERROR_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.EDIT_YOUTUBE_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isEditYoutubeFeedSuccess: boolean;
    }
  > {}

export const editYoutubeFeedErrorActionCreator = (
  payload: EDIT_YOUTUBE_FEED_ERROR_ACTION['payload'],
): EDIT_YOUTUBE_FEED_ERROR_ACTION => ({
  type: ACTIONS.EDIT_YOUTUBE_FEED_ERROR,
  payload,
});

export interface SCHEDULE_YOUTUBE_FEED_ERROR_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.SCHEDULE_YOUTUBE_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isScheduleYoutubeFeedSuccess: boolean;
    }
  > {}

export const scheduleYoutubeFeedErrorActionCreator = (
  payload: SCHEDULE_YOUTUBE_FEED_ERROR_ACTION['payload'],
): SCHEDULE_YOUTUBE_FEED_ERROR_ACTION => ({
  type: ACTIONS.SCHEDULE_YOUTUBE_FEED_ERROR,
  payload,
});

export interface DELETE_YOUTUBE_FEED_ERROR_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.DELETE_YOUTUBE_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isDeleteYoutubeFeedSuccess: boolean;
    }
  > {}

export const deleteYoutubeFeedErrorActionCreator = (
  payload: DELETE_YOUTUBE_FEED_ERROR_ACTION['payload'],
): DELETE_YOUTUBE_FEED_ERROR_ACTION => ({
  type: ACTIONS.DELETE_YOUTUBE_FEED_ERROR,
  payload,
});

// Reset action and reset action creator
export interface GET_YOUTUBE_FEEDS_RESET_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.GET_YOUTUBE_FEEDS_RESET,
    {
      data: PaginatedYoutubeFeeds;
      loading: boolean;
      isGetYoutubeFeedsSuccess: boolean;
      error: null;
    }
  > {}

export const getYoutubeFeedsResetActionCreator = (
  payload: GET_YOUTUBE_FEEDS_RESET_ACTION['payload'],
): GET_YOUTUBE_FEEDS_RESET_ACTION => ({
  type: ACTIONS.GET_YOUTUBE_FEEDS_RESET,
  payload,
});

export interface CREATE_YOUTUBE_FEED_RESET_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.CREATE_YOUTUBE_FEED_RESET,
    { error: null; loading: boolean; isCreateYoutubeFeedSuccess: boolean }
  > {}

export const createYoutubeFeedResetActionCreator = (
  payload: CREATE_YOUTUBE_FEED_RESET_ACTION['payload'],
): CREATE_YOUTUBE_FEED_RESET_ACTION => ({
  type: ACTIONS.CREATE_YOUTUBE_FEED_RESET,
  payload,
});

export interface EDIT_YOUTUBE_FEED_RESET_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.EDIT_YOUTUBE_FEED_RESET,
    { error: null; loading: boolean; isEditYoutubeFeedSuccess: boolean }
  > {}

export const editYoutubeFeedResetActionCreator = (
  payload: EDIT_YOUTUBE_FEED_RESET_ACTION['payload'],
): EDIT_YOUTUBE_FEED_RESET_ACTION => ({
  type: ACTIONS.EDIT_YOUTUBE_FEED_RESET,
  payload,
});

export interface SCHEDULE_YOUTUBE_FEED_RESET_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.SCHEDULE_YOUTUBE_FEED_RESET,
    { error: null; loading: boolean; isScheduleYoutubeFeedSuccess: boolean }
  > {}

export const scheduleYoutubeFeedResetActionCreator = (
  payload: SCHEDULE_YOUTUBE_FEED_RESET_ACTION['payload'],
): SCHEDULE_YOUTUBE_FEED_RESET_ACTION => ({
  type: ACTIONS.SCHEDULE_YOUTUBE_FEED_RESET,
  payload,
});

export interface DELETE_YOUTUBE_FEED_RESET_ACTION
  extends YoutubeFeedsReduxAction<
    ACTIONS.DELETE_YOUTUBE_FEED_RESET,
    { error: null; loading: boolean; isDeleteYoutubeFeedSuccess: boolean }
  > {}

export const deleteYoutubeFeedResetActionCreator = (
  payload: DELETE_YOUTUBE_FEED_RESET_ACTION['payload'],
): DELETE_YOUTUBE_FEED_RESET_ACTION => ({
  type: ACTIONS.DELETE_YOUTUBE_FEED_RESET,
  payload,
});
