/* eslint-disable jsx-a11y/label-has-associated-control */
import { Radio, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SkuOrientation from '../../../../../enums/skuOrientation';
import SkuInfo from '../../../../../interfaces/skuInfo.interface';
import FileUploader from '../../../../FileUploader';
import Tooltip from '../../../../Tooltip';
import WithSpinner from '../../../../WithSpinner';

export default ({
  onSubmit,
  orientation,
  incrementIndex,
}: {
  orientation: SkuOrientation;
  onSubmit: (data: any) => void;
  incrementIndex?: number | undefined;
}) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState<any>();
  const [skuInfos, setSkuInfos] = useState<SkuInfo[]>();
  const [reload, setReload] = useState<boolean>();

  const [filesPreview, setFilesPreview] = useState<any[]>([]);

  const handleUpload = async (selectedFiles: File[]) => {
    if (!selectedFiles.length) {
      setFilesPreview([]);
      setSkuInfos([]);
      onSubmit([]);
      return;
    }

    setFilesPreview([]);

    const filesPre: File[] = selectedFiles;
    function fnTest(index: number) {
      if (index === filesPre.length) {
        setReload(true);
        return;
      }
      const img = new Image();
      const reader = new FileReader();
      const objectUrl = URL.createObjectURL(filesPre[index]);
      img.onload = function handleLoadImage() {
        URL.revokeObjectURL(objectUrl);
      };

      img.src = objectUrl;
      reader.readAsDataURL(filesPre[index]);
      reader.onloadend = async () => {
        const base64data = reader.result;
        setFilesPreview((filePreview) => [
          ...filePreview,
          {
            src: base64data,
            name: filesPre[index].name,
            width: img.width,
            height: img.height,
            size: filesPre[index].size,
            type: filesPre[index].type,
          },
        ]);
        fnTest(index + 1);
      };
    }

    fnTest(0);
  };

  const [value, setValuee] = useState();

  const onChange = (e: RadioChangeEvent) => {
    setValuee(e.target.value);
  };

  const onchangeInput = (event: any, filename: string) => {
    setSkuInfos((prevState) => {
      // Loop over your list
      return prevState?.map((item) => {
        // Check for the item with the specified id and update it
        return item.imageName === filename
          ? { ...item, order: event?.target.value }
          : item;
      });
    });
    setReload(true);
  };

  const onchangeInputText = (event: any, filename: string) => {
    setSkuInfos((prevState) => {
      // Loop over your list
      return prevState?.map((item) => {
        // Check for the item with the specified id and update it
        return item.imageName === filename
          ? { ...item, text: event?.target.value }
          : item;
      });
    });
    setReload(true);
  };
  const wrapFileName = (str: string) => {
    if (str.length > 10)
      return `${str?.split('.')?.reverse()?.[1]?.slice(0, 10)}....${
        str?.split('.')?.reverse()?.[0]
      }`;
    return str;
  };
  useEffect(() => {
    if (reload && filesPreview) {
      setReload(false);
      const skuInfoss: SkuInfo[] = [];
      filesPreview.map((file, index) => {
        return skuInfoss.push({
          base64File: file?.src,
          orientation,
          imageName: file.name,
          isCover: index === value,
          text: skuInfos ? skuInfos[index]?.text : '',
          order: skuInfos ? skuInfos[index]?.order : index,
          metaData: {
            originalName: file.name,
            base64Type: file.type,
            width: file?.width,
            height: file?.height,
            size: file.size,
          },
        });
      });
      setSkuInfos(skuInfoss);
    }

    onSubmit(skuInfos);
  }, [reload, skuInfos]);

  return (
    <>
      <FileUploader
        name={`files ${orientation} `}
        mutedText={t('contents.upload_image')}
        accept={
          orientation === SkuOrientation.Video
            ? 'video/mp4,video/x-m4v,video/*'
            : '.png, .jpg, .jpeg'
        }
        // defaultValue={file}
        className="w-100 mt-2"
        onChange={(image) => {
          setFiles(image);
          // setReload(true);
          handleUpload(image);
        }}
        containerClassName="justify-content-center"
        maxFiles={10}
        key={orientation}
      />

      {files &&
        files.map((file: File, index: number) => (
          <WithSpinner isLoading={!filesPreview[index]?.src}>
            <div className=" d-flex card border mt-2">
              <label
                className="btn d-flex position-absolute top-0 start-100 translate-middle btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow  mx-1"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
              >
                <Tooltip text="Select Cover">
                  <Radio.Group
                    onChange={(val) => {
                      onChange(val);
                      setReload(true);
                    }}
                    value={value}
                    // defaultValue={0}
                  >
                    <Radio className="mx-1" value={index} />
                  </Radio.Group>
                </Tooltip>
              </label>

              <div className="card-body ">
                <div className="row justify-content-md-center ">
                  <div className="col col-lg-10">
                    {filesPreview &&
                      (orientation === SkuOrientation.Video ? (
                        <video width="80" autoPlay muted>
                          <source
                            className="mx-1"
                            src={filesPreview[index]?.src}
                            type="video/mp4"
                          />
                        </video>
                      ) : (
                        <img
                          src={filesPreview[index]?.src}
                          width="60"
                          alt={file.name}
                          className="mx-1"
                        />
                      ))}
                    {wrapFileName(file?.name)}
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col col-lg-9">
                    Title :
                    <input
                      name={`Text${file.name}`}
                      className="form-control form-control-solid sm"
                      onChange={(event) => onchangeInputText(event, file.name)}
                      type="text"
                      // defaultValue={String(index + 1 + (incrementIndex ?? 0))}
                      min="0"
                    />
                  </div>

                  <div className="col col-lg-3">
                    {' '}
                    Order:
                    <input
                      name={`files ${orientation} `}
                      className="form-control form-control-solid sm"
                      onChange={(event) => onchangeInput(event, file.name)}
                      type="number"
                      defaultValue={String(index + 1 + (incrementIndex ?? 0))}
                      min="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </WithSpinner>
        ))}
    </>
  );
};
