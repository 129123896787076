import { TFunction } from 'i18next';
import { useSelector } from 'react-redux';
import { UserRole } from '../../enums/userRole.enum';
import { rolesTranslation } from '../../constants/translation/users';
import GlobalState from '../../store/reducers/globalState.interface';
import { userAllowedRoles } from '../../constants/userAllowedRoles';
import User from '../../interfaces/user/user.interface';

export const useUserRoleOptions = (
  t: TFunction,
  user?: User,
  onEdit?: boolean,
) => {
  const roles = useSelector(
    (state: GlobalState) => user?.roles ?? state.userData.user.roles,
  );
  const userConnect = useSelector(
    (state: GlobalState) => state.userData.user.roles,
  );
  const getRoleList = (): UserRole[] => {
    const userRoles = Object.entries(userAllowedRoles).find(([role]) =>
      roles.includes(role as UserRole),
    );
    return userRoles ? userRoles[1] : [];
  };
  const roleList = getRoleList().map((userRoleValue) => ({
    label: t(rolesTranslation[userRoleValue]),
    value: userRoleValue,
  }));

  if (userConnect.includes(UserRole.Manager)) {
    return roleList.filter((role) => role.value !== UserRole.Admin);
  }
  if (
    userConnect.includes(UserRole.SuperAdmin) &&
    roles.includes(UserRole.Manager) &&
    !onEdit
  ) {
    return roleList.filter((role) => role.value !== UserRole.Admin);
  }
  return roleList;
};
