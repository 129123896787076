import React from 'react';
import { useTranslation } from 'react-i18next';
import StepsTitle from '../../../WithModesModal/StepsTitle';
import AddDeviceSteps from '../../../../enums/steps/addDeviceSteps.enum';

export default ({
  currentStep,
  className,
}: {
  currentStep: AddDeviceSteps;
  className?: string;
}) => {
  const { t } = useTranslation();

  const steps = {
    [AddDeviceSteps.GeneralInfo]: t(
      'devices.add_device_modal.steps.licensing_info',
    ),
    [AddDeviceSteps.Indigo]: t('devices.add_device_modal.steps.device_info'),
  } as const;

  return (
    <StepsTitle currentStep={currentStep} steps={steps} className={className} />
  );
};
