import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../../components/Modal';
import Yup from '../../../../utils/validation';
import api from '../../../../api';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import AppType from '../../../../enums/appType.enum';
import GoogleSlidesModal from '../../../../components/Apps/GoogleSlides/CreateGoogleSlides';
import useModalState from '../../../../utils/hooks/useModalState';
import PublishModal from '../../../../components/PublishModal';
import ScheduleContentRequestData from '../../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import { getUserData } from '../../../../store/actions/userData';

const CreateGoogleSlides = ({
  children,
  onUpdate,
}: {
  children: React.ReactNode;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isVisible, handleOpen, handleClose] = useModalState(false);
  const [app, setApp] = useState<App>({} as App);
  const dispatch = useDispatch();
  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const handleScheduleSubmit = (data: ScheduleContentRequestData) => {
    api.apps.scheduleApp(app.type, app.id, data).then(() => {
      toast.success(i18next.t<string>('common.app_scheduled'));
      handleClose();
      onUpdate();
    });
  };

  const handleSubmit = (data: CreateAppRequestData) => {
    setLoading(true);

    api.apps
      .createApp(AppType.GoogleSlides, data)
      .then((result: App) => {
        setApp(result);
        close();
        dispatch(getUserData());
        if (onUpdate) {
          onUpdate();
        }

        toast.success(i18next.t<string>('apps.googleSlides.slide_feed_added'));
      })
      .finally(() => {
        setLoading(false);
        if (data.isScheduled) {
          handleOpen();
        }
      });
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('apps.googleSlides.createModalTitle')}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
      >
        <GoogleSlidesModal
          onClose={close}
          onSubmit={handleSubmit}
          isLoading={loading}
        />
      </Modal>
      <PublishModal
        onClose={handleClose}
        onSubmit={handleScheduleSubmit}
        isVisible={isVisible}
        skipOrder
        skipOrientationAuto
        infoValidationSchema={{
          orientation: Yup.string().required(t('required')),
          duration: Yup.number().required(t('required')).min(1),
          startDate: Yup.date(),
          endDate: Yup.date().when('startDate', {
            is: (startDate: any) => !!startDate,
            then: Yup.date().min(
              Yup.ref('startDate'),
              t('common.validation.endDate'),
            ),
          }),
          isLocked: Yup.boolean(),
        }}
      />
    </>
  );
};

export default CreateGoogleSlides;
