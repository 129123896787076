import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import IContent from '../../../interfaces/content.interface';
import GlobalState from '../../../store/reducers/globalState.interface';
import Previews from '../../../components/Contents/ContentTable/TableRow/ContentPreview';

export default ({
  children,
  content,
}: {
  children: React.ReactNode;
  content: IContent;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isLoading = useSelector((state: GlobalState) => state.contents.loading);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  return (
    <>
      <div role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </div>

      <Previews
        content={content}
        show={isModalVisible}
        onClose={close}
        isLoading={isLoading}
      />
    </>
  );
};
