import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import Device from '../../../interfaces/devices/device.interface';
import DevicesSortField from '../../../api/endpoints/devices/enums/devicesSortField.enum';
import UpdateDeviceRequestData from '../../../api/endpoints/devices/interfaces/updateDeviceRequestData.interface';
import { readFromQueryString } from '../../../utils/queryParams';
import Columns, { TableHeaderColumn } from '../../Table/Columns';
import SortOrder from '../../../enums/sortOrder.enum';
import WithSpinner from '../../WithSpinner';
import TableRow from './TableRow';
import Table from '../../Table';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../utils/hooks/useTableMultiSelect';
import DeviceCells from '../../../enums/tableCells/deviceCells.enum';
import GlobalState from '../../../store/reducers/globalState.interface';

interface TableViewProps {
  devices: Device[];
  isSuperAdmin?: boolean;
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight?: number;
  isSelectable?: boolean;
  multiSelectConfig?: TableMultiSelectConfig;
  onEditDevice?: (data: { id: string; data: UpdateDeviceRequestData }) => void;
  unAssignDevice?: (deviceId: string, targetId: string) => void;
  onAssignUser?: () => void;
  onUnAssignUser?: () => void;
  onUpdateLicense?: () => void;
  withDeleteAction?: boolean;
  isDeleteRemoval?: boolean;
  skipLicence?: boolean;
  config?: { skipEditModalDeviceGroup?: boolean; skipColumns?: string[] };
  isTrial?: boolean;
}

interface DefaultProps extends TableViewProps {
  multiSelectConfig: Required<TableViewProps>['multiSelectConfig'];
}

export const TableView = ({
  devices,
  isLoading,
  isSuperAdmin = false,
  sortQueryField,
  skipLicence,
  orderQueryField,
  preloaderHeight = 300,
  isSelectable = false,
  withDeleteAction = false,
  isDeleteRemoval = false,
  multiSelectConfig: { selectedItems, setSelectedItem, selectAll },
  onEditDevice,
  unAssignDevice,
  onAssignUser,
  onUnAssignUser,
  onUpdateLicense,
  config,
  isTrial,
}: DefaultProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const isTrialUser = useSelector((state: GlobalState) => {
    return state.userData?.user?.isTrial;
  });
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  let columns: TableHeaderColumn<DevicesSortField>[] = [
    {
      name: t('devices.device_name'),
      sortBy: DevicesSortField.Name,
    },
    { name: t('common.location'), sortBy: DevicesSortField.Location },
    { name: t('common.status') },
    { name: t('devices.current_playlist') },
    { name: t('devices.device_group'), sortBy: DevicesSortField.deviceGroups },
    { name: t('devices.table.last_active') },
    { name: t('common.actions') },
  ];
  if (isTrialUser) {
    columns = [
      {
        name: t('devices.device_name'),
        sortBy: DevicesSortField.Name,
      },
      { name: t('common.location'), sortBy: DevicesSortField.Location },
      { name: t('common.status') },
      { name: t('devices.current_playlist') },
      { name: t('devices.table.last_active') },
      { name: t('common.actions') },
    ];
  }
  if (isSuperAdmin) {
    columns = [
      {
        name: t('devices.device_name'),
        sortBy: DevicesSortField.Name,
      },
      { name: t('common.location'), sortBy: DevicesSortField.Location },
      { name: t('common.status') },
      { name: t('devices.current_playlist') },
      {
        name: t('devices.device_group'),
      },
      { name: t('devices.table.last_active') },
      { name: t('devices.table.is_linked') },
      { name: t('devices.table.license') },
      { name: t('devices.table.owner') },
      { name: t('common.actions') },
    ];
  }
  if (isSuperAdmin && !isTrial) {
    columns = [
      {
        name: t('devices.device_name'),
        sortBy: DevicesSortField.Name,
      },
      { name: t('common.location'), sortBy: DevicesSortField.Location },
      { name: t('common.status') },
      { name: t('devices.table.license') },
      {
        name: t('devices.table.licenseDates'),
      },
      { name: t('devices.table.licenseLibraryDates') },
      { name: t('devices.table.is_linked') },
      { name: t('devices.table.owner') },
      { name: t('common.actions') },
    ];
  }

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns<DevicesSortField>
            columns={columns}
            checked={isAllSelected(selectedItems, map(devices, 'id'))}
            onChange={selectAll}
            config={{
              isSelectable,
              sortField,
              setSortField,
              sortQueryField,
              orderQueryField,
            }}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={!isLoading && !devices.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={t('devices.table.no_one_device_found')}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {devices.map((device) => (
            <TableRow
              isSuperAdmin={isSuperAdmin}
              skipLicence={skipLicence}
              config={{
                isSelectable,
                withDeleteAction,
                isDeleteRemoval,
                skipEditModalDeviceGroup: config?.skipEditModalDeviceGroup,
                skipColumns: config?.skipColumns
                  ? [...config?.skipColumns, DeviceCells.Billing]
                  : [DeviceCells.Billing], // TODO: remove it
              }}
              isTrial={isTrial}
              isChecked={selectedItems.includes(device.id)}
              onCheck={setSelectedItem}
              key={device.id}
              device={device}
              onEditDevice={onEditDevice}
              unAssignDevice={unAssignDevice}
              onAssignUser={onAssignUser}
              onUnAssignUser={onUnAssignUser}
              onUpdateLicense={onUpdateLicense}
            />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};

TableView.defaultProps = {
  isTrial: false,
  multiSelectConfig: {
    selectedItems: [],
    setSelectedItem: () => null,
    selectAll: () => null,
  },
};

export default TableView;
