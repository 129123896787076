import React from 'react';
import clsx from 'clsx';
import YouTubeReactPlayer from 'react-player/youtube';
import styles from '../../../tableRow.module.scss';
import { AppDependencyConfig } from '../../../../../../../store/types/apps/globalTypes';
import IContent from '../../../../../../../interfaces/content.interface';

export default ({ content }: { content: IContent }) => (
  <div className={clsx('position-relative', styles.thumbnail)}>
    <div className={styles.iframePreview}>
      <YouTubeReactPlayer
        muted
        url={
          (content.app?.dependency?.config as AppDependencyConfig)
            ?.youtubeFeedUrl
        }
        height="100%"
        width="100%"
      />
    </div>
  </div>
);
