import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { defaultDebounceValue } from '../../constants/api/debounceSettings';
import GlobalState from '../../store/reducers/globalState.interface';
import { readAllFromQueryParams } from '../../utils/queryParams';
import CompaniesTable from '../../components/Companies/UserPanel';
import { getCompaniesList } from '../../store/actions/companies';
import useLoading from '../../utils/hooks/useLoading';

export default () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { companies, total, loading } = useSelector(
    (state: GlobalState) => state.companies,
  );
  const [isLoading, setIsLoading] = useLoading(loading);

  const dispatchGetCompaniesList = useCallback(
    debounce(
      (recentSearch: string) =>
        dispatch(getCompaniesList(readAllFromQueryParams(recentSearch))),
      defaultDebounceValue,
    ),
    [],
  );

  useEffect(() => {
    setIsLoading(true);
    dispatchGetCompaniesList(search);
  }, [search]);

  return (
    <CompaniesTable
      companies={companies}
      total={total}
      isLoading={isLoading}
      onUpdate={() => dispatchGetCompaniesList(search)}
    />
  );
};
