import React from 'react';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import DayOfWeek from '../../enums/dayOfWeek.enum';
import { daysOfWeekTranslation } from '../../constants/translation/daysOfWeek';
import RegularSelect, { Option } from '../../components/Select/RegularSelect';

export const getDayOfWeekOption = (
  dayOfWeek: DayOfWeek,
  t: TFunction,
): Option => ({
  value: String(dayOfWeek),
  label: t(daysOfWeekTranslation[dayOfWeek]),
});

const SelectDaysOfWeek = ({
  onChange,
  initialValue,
}: {
  onChange: (selectedValue: DayOfWeek | DayOfWeek[]) => void;
  initialValue?: Option | Option[];
}) => {
  const { t } = useTranslation();

  return (
    <RegularSelect
      isMulti
      defaultValue={initialValue}
      options={Object.values(DayOfWeek)
        .filter(isNumber)
        .map((value) => getDayOfWeekOption(value as DayOfWeek, t))}
      onSelect={(value: string | string[]) =>
        onChange(
          value instanceof Array
            ? value.map((val) => Number.parseInt(val, 10))
            : Number.parseInt(value, 10),
        )
      }
    />
  );
};

SelectDaysOfWeek.defaultProps = {
  initialValue: [],
};

export default SelectDaysOfWeek;
