import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import WithModesModal from '../../WithModesModal';
import AssignPlaylists from './Steps/AssignPlaylists';
import AssignDevicesAndGroups from './Steps/AssignDevicesAndGroups';
import sizingStyles from '../../../styles/sizing.module.scss';
import CopyContentSteps from '../../../enums/steps/copyContentSteps.enum';
import CopyContentStepsData from './copyContentStepsData.interface';

export default ({
  onSubmit,
  isVisible,
  isLoading,
  onClose,
}: {
  onSubmit: (data: Partial<CopyContentStepsData>) => void;
  onClose?: () => void;
  isLoading?: boolean;
  isVisible: boolean;
}) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] = useState<Partial<CopyContentStepsData>>({});

  return (
    <WithModesModal<CopyContentStepsData, CopyContentSteps>
      isVisible={isVisible}
      onClose={onClose}
      modes={{
        [CopyContentSteps.Playlists]: {
          title: t('playlists.copy_content'),
          render: ({ setMode }) => (
            <AssignPlaylists
              isLoading={isLoading}
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onSubmit={() => {
                onSubmit(stepsData);
                setStepsData({});
              }}
            />
          ),
        },
        [CopyContentSteps.DevicesAndGroups]: {
          title: t('playlists.copy_content'),
          render: ({ setMode }) => (
            <AssignDevicesAndGroups
              skipLicence
              copyContent
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
            />
          ),
        },
      }}
      defaultMode={CopyContentSteps.DevicesAndGroups}
      dialogClassName={clsx('mw-1000px', sizingStyles.fitContent)}
    />
  );
};
