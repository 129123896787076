import * as React from 'react';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { AppDependencyConfig } from '../../../store/types/apps/globalTypes';
import { YoutubeFeedSchema } from '../../../store/types/apps/youtubeFeed';
import { formatDateString } from '../../../utils/dateTime';
import Tooltip from '../../../components/Tooltip';

interface UseColumnsProps {
  clickOnYoutubeFeed: (feed: YoutubeFeedSchema) => void;
  openEditYoutubeFeedModal: (feed: YoutubeFeedSchema) => void;
  openDeleteYoutubeFeedModal: (feed: YoutubeFeedSchema) => void;
  openActionsDropdown: (feed: YoutubeFeedSchema) => void;
  openScheduleYoutubeFeedModal: (feed: YoutubeFeedSchema) => void;
  openPreviewYoutubeFeedModal: (feed: YoutubeFeedSchema) => void;
  selectedYoutubeFeed: YoutubeFeedSchema | null;
}

export default function useColumns({
  clickOnYoutubeFeed,
  openEditYoutubeFeedModal,
  openDeleteYoutubeFeedModal,
  openActionsDropdown,
  openScheduleYoutubeFeedModal,
  openPreviewYoutubeFeedModal,
  selectedYoutubeFeed,
}: UseColumnsProps): Column<YoutubeFeedSchema>[] {
  const { t } = useTranslation();

  const [isActionDropdownOpen, setIsActionDropdownOpen] =
    React.useState<boolean>(false);

  const handleToggleIsActionDropdownOpen = React.useCallback(
    () => setIsActionDropdownOpen(!isActionDropdownOpen),
    [isActionDropdownOpen],
  );

  const formatDate = React.useCallback(
    () =>
      formatDateString({
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        minute: '2-digit',
        hour: 'numeric',
      }),
    [],
  );

  // Duration foromatted based on Figma's prototypes.
  const formatDuration = React.useCallback((duration: number) => {
    if (!duration) {
      return '0s';
    }
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - hours * 3600) / 60);
    const seconds = duration - hours * 3600 - minutes * 60;

    let formattedTime = `${seconds}s`;
    if (hours) {
      formattedTime = `${hours}h ${seconds}s`;

      if (minutes) {
        formattedTime = `${hours}h ${minutes}m ${seconds}s`;
      }
    } else if (minutes) {
      formattedTime = `${minutes}m ${seconds}s`;
    }

    return formattedTime;
  }, []);

  return React.useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
        Cell: ({ value, row }: Cell<YoutubeFeedSchema>) => {
          const { original: feedData } = row;

          return (
            <div
              className="d-flex align-items-center"
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => clickOnYoutubeFeed(feedData)}
            >
              <span className="text-gray-800 text-hover-primary">{value}</span>
            </div>
          );
        },
      },
      {
        Header: 'DATE CREATED',
        accessor: 'createdAt',
        Cell: ({ value }: Cell<YoutubeFeedSchema>) => {
          const formatDateRef = formatDate();
          return <time dateTime={value}>{formatDateRef(value)}</time>;
        },
      },
      {
        Header: 'DURATION',
        Cell: ({ row }: Cell<YoutubeFeedSchema>) => {
          const feed = row.original;
          const durationInSec = (feed.dependency.config as AppDependencyConfig)
            .youtubeFeedDurationInSeconds as number;
          return <span>{formatDuration(durationInSec)}</span>;
        },
      },
      {
        Header: 'ACTIONS',
        Cell: ({ row }: Cell<YoutubeFeedSchema>) => {
          const feed = row.original;

          return (
            <div className="d-flex justify-content-end">
              <div className="mx-1">
                <Tooltip text={t('common.schedule')}>
                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => openScheduleYoutubeFeedModal(feed)}
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <i className="fa fa-calendar-day" />
                  </span>
                </Tooltip>
              </div>

              <div className="mx-1">
                <Tooltip text={t('common.preview')}>
                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => openPreviewYoutubeFeedModal(feed)}
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <i className="fa fa-eye" />
                  </span>
                </Tooltip>
              </div>

              <div className="mx-1">
                <Tooltip text={t('common.edit')}>
                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => openEditYoutubeFeedModal(feed)}
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <i className="fa fa-edit" />
                  </span>
                </Tooltip>
              </div>

              <div className="mx-1">
                <Tooltip text={t('common.delete')}>
                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => openDeleteYoutubeFeedModal(feed)}
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <i className="fa fa-trash" />
                  </span>
                </Tooltip>
              </div>
            </div>
          );
        },
      },
    ],
    [
      clickOnYoutubeFeed,
      formatDate,
      formatDuration,
      handleToggleIsActionDropdownOpen,
      isActionDropdownOpen,
      openActionsDropdown,
      selectedYoutubeFeed,
    ],
  );
}
