import React, { useState } from 'react';
import { KTSVG } from '../../lib/metronic/helpers';
import Modal from '../Modal';

export default ({
  body,
  children,
  handleConfirm,
  transitionDuration = 300,
  confirmButtonText,
  discardButtonText,
}: {
  body: string;
  children: React.ReactNode;
  handleConfirm: () => void;
  transitionDuration?: number;
  confirmButtonText: string;
  discardButtonText: string;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const open = () => {
    setIsModalVisible(true);
  };

  const close = () => {
    setIsModalVisible(false);
  };

  const onOk = () => {
    close();

    // Wait for the animation end
    setTimeout(handleConfirm, transitionDuration);
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        show={isModalVisible}
        onHide={close}
        withFooter={false}
        withHeader={false}
        widthDefaultSize={false}
        bodyClassName="d-flex flex-center flex-column my-7"
      >
        <span className="svg-icon svg-icon-5tx svg-icon-danger mb-10">
          <KTSVG
            path="/media/icons/duotune/general/gen044.svg"
            className="svg-icon-1"
          />
        </span>
        <div className="text-center">
          <h5 className="fw-bolder fs-1 mb-10">{body}</h5>
          <div className="d-flex flex-center flex-wrap">
            <button onClick={onOk} type="button" className="btn btn-danger m-2">
              {confirmButtonText}
            </button>
            <button
              onClick={close}
              type="button"
              className="btn btn-outline btn-outline-danger btn-active-danger m-2"
            >
              {discardButtonText}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
