import * as React from 'react';
import clsx from 'clsx';
import Modal from '../../Modal';
import useWindowSize from '../../../utils/hooks/useWindowSize';
import styles from './msTeams.module.scss';
import { disableRightClickIframe } from '../../../utils/common';
import { MsTeamsFeedSchema } from '../../../store/types/apps/msTeamsFeed';

export interface PreviewFeedModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  selectedMsteamsFeedSchema: MsTeamsFeedSchema;
}
const PreviewFeedModal = ({
  show,
  onHide,
  title,
  selectedMsteamsFeedSchema,
}: PreviewFeedModalProps) => {
  const [fullscreen, setFullscreen] = React.useState<
    | true
    | 'sm-down'
    | 'md-down'
    | 'lg-down'
    | 'xl-down'
    | 'xxl-down'
    | undefined
  >();
  const { name: feedTitle, id } = React.useMemo(
    () => selectedMsteamsFeedSchema,
    [selectedMsteamsFeedSchema],
  );
  const [windowWidth] = useWindowSize();

  const [url, setUrl] = React.useState(
    `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?appId=${id}&ratio=${windowWidth}&fullsceen=${fullscreen}`,
  );

  const handleHide = () => {
    onHide();
    setFullscreen(undefined);
  };

  const handleShow = () => {
    setFullscreen(true);
    if (id) {
      const targetEl = document.getElementsByClassName(id.toString());
      const setFull = targetEl.length && targetEl[0];
      if (setFull && setFull.requestFullscreen) {
        setFull.requestFullscreen();
      }
    }
  };
  React.useEffect(() => {
    if (!id) return;
    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?appId=${id}&ratio=${windowWidth}&fullsceen=${fullscreen}`,
    );
  }, [windowWidth, id, fullscreen]);
  React.useEffect(() => {
    const exitHandler = () => {
      if (
        document &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setFullscreen(undefined);
      }
    };

    if (document.addEventListener) {
      console.log('add event listener');
      document.addEventListener('fullscreenchange', exitHandler, false);
      document.addEventListener('mozfullscreenchange', exitHandler, false);
      document.addEventListener('MSFullscreenChange', exitHandler, false);
      document.addEventListener('webkitfullscreenchange', exitHandler, false);
    }

    return () => {
      if (document.removeEventListener) {
        console.log('remove event listener');
        document.removeEventListener('fullscreenchange', exitHandler, false);
        document.removeEventListener('mozfullscreenchange', exitHandler, false);
        document.removeEventListener('MSFullscreenChange', exitHandler, false);
        document.removeEventListener(
          'webkitfullscreenchange',
          exitHandler,
          false,
        );
      }
    };
  }, []);

  React.useEffect(() => {
    if (id) {
      disableRightClickIframe(id);
    }
  }, []);
  return (
    <>
      <Modal
        show={show}
        onHide={handleHide}
        title={title}
        handleShow={handleShow}
        withFooter={false}
        dialogClassName={styles.dialog}
        fullscreen={fullscreen}
        fullScreenPreview
        bodyClassName={id}
        widthDefaultSize={false}
      >
        <div id={id}>
          <iframe
            title={feedTitle}
            src={url}
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            style={{ height: `${fullscreen ? '100vh' : '500px'}` }}
            className={clsx('w-100  rounded', styles.preview)}
          />
        </div>
      </Modal>
    </>
  );
};
export default PreviewFeedModal;
