import React, { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { webAuth0 } from '../../../utils/auth0';

import './signin.css';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import validation from '../../../utils/validation';

import FormErrorMessage from '../../../components/FormErrorMessage';
import config from '../../../routing/config';

interface FormInputs {
  email: string;
}

export default () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validationSchema = validation
    .object({
      email: validation
        .string()
        .required(t('signup.email'))
        .email(t('signup.emailValid'))
        .defined(),
    })
    .defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const [email, setEmail] = useStateWithHookForm<Partial<FormInputs>, string>(
    { setValue, trigger, name: 'email' },
    '',
  );

  const handleEmailChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(value);
  };

  const handleSubmitData = async (inputsData: Partial<FormInputs>) => {
    setIsLoading(true);

    const payload: { email: string } = {
      email: inputsData?.email ?? '',
    };

    webAuth0.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email: payload.email,
      },
      (err, res) => {
        if (err) {
          Bugsnag.notify(err.description!);
          toast.error(err.description);
        } else {
          console.log({ res });
          toast.success('Check your email for a password reset link.');
        }
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ call submit function here
        handleSubmit(handleSubmitData)();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <form className="form w-100 position-relative" id="kt_sign_up_form">
      <div className="text-center mb-11">
        <h1 className="text-dark capital fw-bolder mb-3">
          {t('auth_pages.forgot_password')}
        </h1>
      </div>
      <div className="fv-row mb-8">
        <input
          type="text"
          name="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email"
          autoComplete="none"
          className="form-control bg-transparent d-block"
        />
        <FormErrorMessage name="email" errors={errors} className="my-1 px-2" />
      </div>
      <div className="d-grid mb-10">
        <button
          disabled={isLoading}
          onClick={handleSubmit(handleSubmitData)}
          type="button"
          id="kt_sign_up_submit"
          className="btn btn-purple"
        >
          {!isLoading ? (
            <span className="indicator-label">{t('auth_pages.confirm')}</span>
          ) : (
            <span className="d-block indicator-progress">
              {t('auth_pages.wait')}
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
        </button>
      </div>

      <div className="text-gray-500 text-center capital fw-semibold fs-6">
        {t('auth_pages.back_to')}{' '}
        <Link
          to={config.signin.route}
          className="link-primary capital fw-semibold"
        >
          {t('auth_pages.signin')}
        </Link>
      </div>
    </form>
  );
};
