import React from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  isConnected = false,
  disconnectClicked,
}: {
  isConnected?: boolean;
  disconnectClicked: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isConnected ? (
        <div>
          <span className="px-2">
            <i className="fas fa-check-circle text-success px-2" />
            {t('common.connected')}
          </span>
          <button
            type="button"
            onClick={disconnectClicked}
            className="btn btn-primary"
          >
            {t('common.disconnect')}
          </button>
        </div>
      ) : (
        <p className="mt-3">
          <i className="fas fa-exclamation-circle text-warning px-2" />
          {t('common.not_connected')}
        </p>
      )}
    </>
  );
};
