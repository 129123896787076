import React from 'react';
import clsx from 'clsx';
import NavTabs from '../index';

interface Tab<T extends string> {
  key: T;
  text: string;
  isActive: boolean;
}

export default <T extends string>({
  tabs,
  onSelect,
  separatorClassName,
  className,
  isSelectable,
  withUnderline,
}: {
  tabs: Tab<T>[];
  onSelect?: (key: T) => void;
  separatorClassName?: string;
  className?: string;
  isSelectable?: boolean;
  withUnderline?: boolean;
}) => (
  <NavTabs<T>
    isSelectable={isSelectable}
    withUnderline={withUnderline}
    className={className}
    separatorClassName={separatorClassName}
    tabs={tabs.map(({ key, text, isActive }) => ({
      key,
      tab: (
        <span
          className={clsx(
            'nav-link text-active-primary me-6',
            isActive ? 'active' : 'hoverable',
          )}
        >
          {text}
        </span>
      ),
    }))}
    onSelect={onSelect}
  />
);
