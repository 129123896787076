/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { Spin } from 'antd';
import './signup.css';

import logo from '../../../assets/images/logo/indigo_vizual_logo.png';

import config from '../../../routing/config';
import useQuery from '../../../utils/hooks/useQuery';
import { store } from '../../../store';
import GlobalState from '../../../store/reducers/globalState.interface';
// import SocialLogin from '../../../components/SocialLogin';

const scriptTagForm: string = `
<script>
    if (hbspt) {
      hbspt.forms.create({
        region: 'na1',
        portalId: '21304428',
        formId: 'e97a2b54-992f-47f6-b14c-df8b38e27053',
        version: 'V2_PRERELEASE',
        onFormSubmit: ($form) => {
          setTimeout(() => {
            const formData = Array.from(
              new FormData($form),
              function(e) { return e.map(encodeURIComponent).join('='); }
            ).join('&');
//             window.location = 'https://app.indigovizual.com/signup-password?' + formData;
              window.location = '${process.env.REACT_APP_URL}/signup-password?' + formData;
          }, 50); // Redirects to url with query string data from form fields after 50 milliseconds.
        },
        css: '',
        cssClass: 'hubspot-popup form w-100',
      });
    }
  </script>
`;

const HubspotJS = () => {
  //  HubSpot Embed Code
  const promise = new Promise((resolve) => {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.src = '//js.hsforms.net/forms/v2.js';
    scriptTag.defer = true;
    scriptTag.async = true;
    scriptTag.id = 'hs-script-loader';
    scriptTag.onload = () => {
      (window as any).leScriptWasLoaded = true;
      resolve(true);
      store.dispatch({ type: 'SET_HUBSPOT_LOADED', payload: true } as any);
      // if ((window as any).hbspt) {
      //   // eslint-disable-next-line no-eval
      //   eval(scriptTagForm);
      // }
    };
    document.body.appendChild(scriptTag);
  });
  return promise;
};

const scriptEl = document.createRange().createContextualFragment(scriptTagForm);

export default () => {
  const { t } = useTranslation();
  const query = useQuery();
  const queryEmail = query.get('email')?.trim();
  const divRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  const isHubSpotLoaded = useSelector(
    (state: GlobalState) => state.userData?.isHubSpotLoaded,
  );

  useEffect(() => {
    if (queryEmail) {
      // function to validate email
      const isValidEmail = (email: string) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
      };

      if (isValidEmail(queryEmail)) {
        history.push(
          `${config.signupPassword.route}?email=${encodeURIComponent(
            queryEmail,
          )}`,
        );
        return;
      }
    }

    if (process.env.REACT_APP_ENV === 'dev') {
      history.push(config.signupPassword.route);
    } else {
      HubspotJS().then(() => {
        if (divRef?.current) {
          divRef.current!.innerHTML = '';
          divRef.current!.appendChild(scriptEl);
          // console.log(
          //   divRef.current!.innerHTML,
          //   'divRef.current!.innerHTML',
          //   divRef.current!.innerHTML.length,
          //   divRef.current!.innerHTML === '',
          // );
        }
      });
    }

    // setTimeout(() => {
    //   // eslint-disable-next-line no-eval
    //   if (divRef.current !== null) {
    //     divRef.current.innerHTML = '';
    //   }
    // }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      store.dispatch({ type: 'SET_HUBSPOT_LOADED', payload: false } as any);
    };
  }, []);

  useEffect(() => {
    // console.log('isHubSportLoaded', isHubSpotLoaded);
    // console.log(divRef.current!.innerHTML);

    if (isHubSpotLoaded && divRef.current?.innerHTML === '') {
      // reload page
      // console.log('reload page');
      // console.log(divRef.current!.innerHTML);
      window.location.reload();
    }
  }, [isHubSpotLoaded, divRef]);

  return (
    <form action="#" className="form w-100">
      <div className="formLogo mb-11 text-center">
        <img alt="Logo" src={logo} width="170" />
      </div>
      <div className="text-center mb-8">
        <h1 className="text-dark fw-bolder mb-3">{t('auth_pages.signup')}</h1>
      </div>
      <div ref={divRef} className="d-grid mb-10 text-center">
        <Spin />
      </div>
      {/* <div className="d-grid mb-10">
        <button
          // disabled={isLoading}
          // onClick={handleSubmit(handleSubmitData)}
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-purple"
        >
          <span className="indicator-label">{t('auth_pages.signup')}</span>
        </button>
      </div> */}
      {/* <SocialLogin /> */}

      <div className="text-gray-500 text-center fw-semibold fs-6">
        {t('auth_pages.already_account')} &nbsp;
        <Link to={config.signin.route} className="link-primary fw-semibold">
          {t('auth_pages.signin')}
        </Link>
      </div>
    </form>
  );
};
