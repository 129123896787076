import React, { useEffect } from 'react';
import IContent from '../../../../../../../interfaces/content.interface';
import TwitterThumbnail from '../../../../../../Apps/TwitterApp/Thumbnail';

export default ({ content }: { content: IContent }) => {
  const [url, setUrl] = React.useState('');

  const TwitterData = content.app;

  useEffect(() => {
    if (!TwitterData) return;

    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?appId=${TwitterData?.id}`,
    );
  }, [TwitterData]);

  return <TwitterThumbnail src={url} />;
};
