import React from 'react';
import clsx from 'clsx';
import Template from '../../../../interfaces/template.interface';
import CheckBox from '../../../CheckBox';
import Table from '../../../Table';
import noPreview from '../../../../assets/images/noPreview/noPreview.png';
import RemoveTemplate from '../../../../containers/Templates/RemoveTemplate';
import EditTemplate from '../../../../containers/Templates/EditTemplate';
import styles from './tableRow.module.scss';
import commonStyles from '../../../../styles/common.module.scss';
import PreviewTemplatesModal from '../../PreviewTemplate';

const { Row } = Table.Body;
const { Cell } = Row;

interface TableRowProps {
  template: Template;
  config?: {
    skipStatus?: boolean;
    skipGroupAccess?: boolean;
    skipDevicesNumber?: boolean;
    skipActions?: boolean;
    disableLinks?: boolean;
    isSelectable?: boolean;
  };
  isChecked?: boolean;
  onCheck?: (id: string) => void;
  onUpdate?: () => void;
}

interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
  isChecked: Required<TableRowProps>['isChecked'];
  onCheck: Required<TableRowProps>['onCheck'];
}

const TableRow = ({
  template,
  config,
  isChecked,
  onCheck,
  onUpdate,
}: DefaultProps) => (
  <Row>
    {config.isSelectable && (
      <Cell>
        <CheckBox
          name="templates"
          id={template.id}
          onChange={() => onCheck(template.id)}
          checked={isChecked}
          className="mt-1"
        />
      </Cell>
    )}
    <Cell isEmpty={!template.name} isNoWrap>
      <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 hoverable">
        {template.name}
      </span>
    </Cell>
    <Cell isNoWrap>
      <div
        className="symbol symbol-50px me-5 d-flex justify-content-center"
        onContextMenu={(ev) => ev.preventDefault()}
      >
        <PreviewTemplatesModal url={template.layout.preview || noPreview}>
          <img
            onContextMenu={(ev) => ev.preventDefault()}
            src={template.layout.preview || noPreview}
            alt="preview"
            className={clsx('h-30px', styles.preview, commonStyles.gridBg)}
          />
        </PreviewTemplatesModal>
      </div>
    </Cell>
    <Cell isNoWrap>
      <div className="symbol symbol-50px me-5 d-flex justify-content-center">
        <PreviewTemplatesModal url={template.background || noPreview}>
          <img
            src={template.background || noPreview}
            alt="preview"
            className={clsx(
              'h-30px min-w-50px',
              styles.preview,
              commonStyles.gridBg,
            )}
          />
        </PreviewTemplatesModal>
      </div>
    </Cell>
    <Cell isNoWrap>
      <div className="symbol symbol-50px me-5 d-flex justify-content-center">
        <PreviewTemplatesModal url={template.logo || noPreview}>
          <img
            src={template.logo || noPreview}
            alt="preview"
            className={clsx('h-30px', styles.preview, commonStyles.gridBg)}
          />
        </PreviewTemplatesModal>
      </div>
    </Cell>
    <Cell isNoWrap>
      <div className="d-flex justify-content-end">
        <div className="mx-1">
          <EditTemplate template={template} onSuccess={onUpdate} />
        </div>

        <div className="mx-1">
          <RemoveTemplate template={template} onSuccess={onUpdate} />
        </div>
      </div>
    </Cell>
  </Row>
);

export default TableRow;
