import React from 'react';
import { useTranslation } from 'react-i18next';
import Library from '../../../interfaces/library.interface';
import quickClick from '../../../assets/images/apps/quickclick.svg';
import WithSpinner from '../../WithSpinner';
import Modal from '../../Modal';

const preloaderHeight = 232;

export default ({
  onHide,
  handleConfirm,
  library: { text },
  isLoading,
  isModalVisible,
}: {
  onHide: () => void;
  handleConfirm: () => void;
  library: Library;
  isLoading: boolean;
  isModalVisible: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={isModalVisible}
        onHide={onHide}
        withFooter={false}
        withHeader={false}
        widthDefaultSize={false}
        bodyClassName="d-flex flex-center flex-column my-7"
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          size="md"
        >
          <span className="svg-icon svg-icon-5tx svg-icon-danger mb-10">
            <img src={quickClick} alt="quick click icon" className="w-40px" />
          </span>
          <div className="text-center">
            <h5 className="fw-bolder fs-1 mb-10">
              &quot;{text}&quot; {t('library.quick_click.will_be_sent')}
            </h5>
            <div className="d-flex flex-center flex-wrap">
              <button
                onClick={handleConfirm}
                type="button"
                className="btn btn-primary m-2"
              >
                {t('common.continue')}
              </button>
              <button
                onClick={onHide}
                type="button"
                className="btn btn-outline btn-outline-danger btn-danger m-2"
              >
                {t('common.cancel')}
              </button>
            </div>
          </div>
        </WithSpinner>
      </Modal>
    </>
  );
};
