import React from 'react';
import AssignPlaylist from '../../../components/Playlists/AssignPlaylist';
import { SelectedValue } from '../../../components/Select/SelectAsync';

export default ({
  children,
  assignPlaylistToDeviceGroup,
}: {
  children: React.ReactNode;
  assignPlaylistToDeviceGroup?: (data: SelectedValue[]) => void;
}) => {
  return (
    <AssignPlaylist onSubmit={assignPlaylistToDeviceGroup}>
      {children}
    </AssignPlaylist>
  );
};
