import DayOfWeek from '../../enums/dayOfWeek.enum';

export const daysOfWeekTranslation: { [key: string]: string } = {
  [DayOfWeek.Monday]: 'days_of_week.monday',
  [DayOfWeek.Tuesday]: 'days_of_week.tuesday',
  [DayOfWeek.Wednesday]: 'days_of_week.wednesday',
  [DayOfWeek.Thursday]: 'days_of_week.thursday',
  [DayOfWeek.Friday]: 'days_of_week.friday',
  [DayOfWeek.Saturday]: 'days_of_week.saturday',
  [DayOfWeek.Sunday]: 'days_of_week.sunday',
};
