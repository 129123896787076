import React from 'react';
import { useTranslation } from 'react-i18next';
import SwitchCheckbox from '../../SwitchCheckbox';

export default ({
  locked,
  setLocked,
}: {
  locked: boolean;
  setLocked: (locked: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex mt-9 justify-content-between align-items-center">
      <label htmlFor="locked" className="d-flex align-items-center fs-4 fw-bol">
        <strong>{t('playlists.lock')}</strong>
      </label>
      <SwitchCheckbox
        name="locked"
        checked={locked}
        onChange={(event) => {
          setLocked(event.target.checked);
        }}
      />
    </div>
  );
};
