enum DevicesGroupsActions {
  GET_DEVICES_GROUPS = 'GET_DEVICES_GROUPS',
  GET_DEVICES_GROUPS_SUCCESS = 'GET_DEVICES_GROUPS_SUCCESS',
  CREATE_DEVICES_GROUP = 'CREATE_DEVICES_GROUP',
  SET_DEVICES_GROUPS_LOADING = 'SET_DEVICES_GROUPS_LOADING',
  GET_DEVICE_GROUP = 'GET_DEVICE_GROUP',
  GET_DEVICE_GROUP_SUCCESS = 'GET_DEVICE_GROUP_SUCCESS',
  GET_DEVICE_GROUP_USERS = 'GET_DEVICE_GROUP_USERS',
  GET_DEVICE_GROUP_USERS_SUCCESS = 'GET_DEVICE_GROUP_USERS_SUCCESS',
  DELETE_DEVICE_GROUP = 'DELETE_DEVICE_GROUP',
  UPDATE_DEVICE_GROUP = 'UPDATE_DEVICE_GROUP',
  ASSIGN_USERS_TO_DEVICE_GROUP = 'ASSIGN_USERS_TO_DEVICE_GROUP',
  REMOVE_USERS_FROM_DEVICE_GROUP = 'REMOVE_USERS_FROM_DEVICE_GROUP',
}

export default DevicesGroupsActions;
