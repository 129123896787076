import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../../components/Modal';
import ReLoginComponent from '../../../../components/Apps/MSTeams/ReLoginComponent';
import { readFromQueryString } from '../../../../utils/queryParams';
import { MsTeamsApis } from '../../../../api/endpoints/apps/Microsoft';

const ReLoginMsTeams = ({
  children,
  msTeam,
}: {
  children?: React.ReactNode;
  //   onUpdate: () => void;
  msTeam: App;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [code, setCode] = useState<string>();
  const history = useHistory();

  const [modalShown, setModalShown] = useState<boolean>(false);
  //   const [loading, setLoading] = useState<boolean>(false);

  const open = () => setModalShown(true);
  //   const close = () => setModalShown(false);
  const [authenticatedUser, setAuthenticatedUser] = useState<any>();
  const [isloadingLogin, setIsloadingLogin] = useState<boolean>(false);
  const [wrongAccountAlert, setWrongAccountAlert] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [updatingAppsLoading, setUpdatingAppsLoading] = useState(true);
  const [startUpdatingApps, setStartUpdatingApps] = useState(false);
  const getTokenAndProfileData = async () => {
    try {
      const res = await MsTeamsApis.MsTeamsGetToken(code);
      const userProfile = await MsTeamsApis.MsTeamsGetProfile(
        res?.data?.access_token,
      );

      setAuthenticatedUser({
        id: userProfile?.data?.id,
        name: userProfile?.data?.displayName,
        mail: userProfile?.data?.mail,
        accessToken: res?.data?.access_token,
        refreshToken: res?.data?.refresh_token,
        expiresIn: res?.data.expires_in,
      });
      // setAccountname(userProfile?.data?.displayName);
      if (
        userProfile?.data?.id &&
        userProfile?.data?.id !== msTeam?.dependency?.config?.msUserId
      ) {
        setWrongAccountAlert(true);
        toast.error(`${t('apps.msTeams.wrongAccountAlertTitle')}`);
      } else {
        setStartUpdatingApps(true);
      }
      setIsloadingLogin(false);
    } catch (e) {
      setIsloadingLogin(false);
      setModalShown(false);
      console.log(e);
      toast.error(`Error During login please try it later`);
    }
  };

  const msTeamsReEstablishConnection = async () => {
    try {
      await MsTeamsApis.MsTeamsReEstablishConnection(msTeam?.id, {
        expiresIn: authenticatedUser?.expiresIn,
        AccessToken: authenticatedUser?.accessToken,
        RefreshToken: authenticatedUser?.refreshToken,
        msUserId: authenticatedUser?.id,
        mail: authenticatedUser?.mail,
        Accountname: authenticatedUser?.name,
      });
      setUpdatingAppsLoading(false);
    } catch (e) {
      //   setUpdatingAppsLoading(false);
      setModalShown(false);
      console.log(e);
      toast.error(`Error while trying to re-establish connection`);
    }
  };
  React.useEffect(() => {
    if (startUpdatingApps && authenticatedUser) {
      msTeamsReEstablishConnection();
    }
  }, [startUpdatingApps, authenticatedUser]);
  React.useEffect(() => {
    const standardAuth = sessionStorage.getItem('relogin');
    const codeToken = readFromQueryString<string>(search, 'code');

    if (standardAuth === 'true') {
      sessionStorage.removeItem('relogin');

      setModalShown(true);
      if (codeToken) {
        setCode(codeToken);
      }
    }
  }, []);
  React.useEffect(() => {
    if (code && modalShown) {
      setIsloadingLogin(true);
      getTokenAndProfileData();
    }
    if (code && !modalShown) {
      history.replace('/apps/microsoft-teams');
      setCode(undefined);
    }
  }, [modalShown]);

  React.useEffect(() => {
    if (code) {
      setModalTitle(`${t('apps.msTeams.reEstablishConnectionTitle')}`);
      if (wrongAccountAlert) {
        setModalTitle(`${t('apps.msTeams.wrongAccountAlertTitle')}`);
      }
    } else {
      setModalTitle(
        `${t('apps.msTeams.reloginTo')} ${
          msTeam?.dependency?.config?.Accountname
        }`,
      );
    }
  }, [modalShown, wrongAccountAlert, code]);

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={modalTitle}
        // withHeader={!code}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
      >
        <ReLoginComponent
          updatingAppsLoading={updatingAppsLoading}
          wrongAccountAlert={wrongAccountAlert}
          code={code}
          msTeam={msTeam}
          authenticatedUser={authenticatedUser}
          isloadingLogin={isloadingLogin}
        />
      </Modal>
    </>
  );
};

export default ReLoginMsTeams;
