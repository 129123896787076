import React, { useEffect } from 'react';
import IContent from '../../../../../../../interfaces/content.interface';
import MsTeamsThumbnail from '../../../../../../Apps/MSTeams/Thumbnail';

export default ({ content }: { content: IContent }) => {
  const [url, setUrl] = React.useState('');

  const AppData = content.app;

  useEffect(() => {
    if (!AppData) return;

    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/thumbnail.html?appId=${AppData?.id}`,
    );
  }, [AppData]);

  return <MsTeamsThumbnail src={url} />;
};
