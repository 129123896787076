/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import CopyContent from '../../../components/Playlist/CopyContent';
import CopyContentStepsData from '../../../components/Playlist/CopyContent/copyContentStepsData.interface';
import useModalState from '../../../utils/hooks/useModalState';
import IContent from '../../../interfaces/content.interface';
import Tooltip from '../../../components/Tooltip';
import api from '../../../api';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';

export default ({
  children,
  content,
  onSuccess,
}: {
  children?: React.ReactNode;
  content: IContent;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, handleOpen, handleClose] = useModalState(false);
  const [isAdmin] = useIsAdmin();

  const handleSubmit = async ({
    playlistIds,
  }: Partial<CopyContentStepsData>) => {
    setIsLoading(true);

    isAdmin
      ? await api.contents.bulkCopyContentAdmin({
          contentIds: [content.id],
          playlistIds,
        })
      : await api.contents.copyContent(content.id, { playlistIds });

    handleClose();
    onSuccess();
    toast.success(i18next.t<string>('playlists.content_copied_message'));
    setIsLoading(false);
  };
  const { app } = content;
  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={app?.type === 'power-bi' ? handleClose : handleOpen}
        onClick={app?.type === 'power-bi' ? handleClose : handleOpen}
      >
        {children ?? (
          <Tooltip
            text={
              app?.type === 'power-bi' ? t('common.noCopy') : t('common.copy')
            }
          >
            <button
              type="button"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              disabled={app?.type === 'power-bi'}
            >
              <i className="fa fa-copy" />
            </button>
          </Tooltip>
        )}
      </span>
      <CopyContent
        isLoading={isLoading}
        isVisible={isVisible}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </>
  );
};
