import React from 'react';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import Card from '../../Card';
import styles from '../usersGroups.module.scss';
import EditUserGroupInfo from '../EditUserGroupInfo';
import RemoveGroup from '../../../containers/UsersGroups/RemoveGroup';
import { KTSVG } from '../../../lib/metronic/helpers';
import { UserStatus } from '../../../enums/userStatus.enum';
import { statusesTranslation } from '../../../constants/translation/users';
import UsersTable from '../../Users';
import DevicesTable from '../../Devices';
import DeviceGroups from '../../DevicesGroups';
import User from '../../../interfaces/user/user.interface';
import UpdateUsersGroupRequestData from '../../../api/endpoints/usersGroups/interfaces/updateUsersGroupRequestData.interface';
import Device from '../../../interfaces/devices/device.interface';
import NavTabs from '../../NavTabs';
import CreateUserRequestData from '../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import DeviceGroup from '../../../interfaces/deviceGroup.interface';
import UpdateDeviceGroupAction from '../../../store/actions/devicesGroups/interfaces/updateDeviceGroupAction.interface';
import UpdateDeviceRequestData from '../../../api/endpoints/devices/interfaces/updateDeviceRequestData.interface';
import BulkUpdateUsersRequestData from '../../../api/endpoints/users/interfaces/bulkUpdateUsersRequestData.interface';
import { SelectedValue } from '../../Select/SelectAsync';
import CreateDeviceGroupRequestData from '../../../api/endpoints/devicesGroups/interfaces/createDeviceGroupRequestData.interface';
import DeviceAssignmentTarget from '../../../enums/deviceAssignmentTarget.enum';
import UserAssignmentTarget from '../../../enums/userAssignmentTarget.enum';

type FormInputs = Partial<UpdateUsersGroupRequestData>;

export default ({
  id,
  devices,
  currentUserId,
  users,
  deviceGroups,
  isLoadingUserGroup,
  isLoadingUsers,
  isLoadingDevices,
  isLoadingDeviceGroups,
  group,
  onEditUserGroup,
  onDeleteUserGroup,
  onEditDevicesInUserGroup,
  onEditUsersInUserGroup,
  onEditDeviceGroupInUserGroup,
  unAssignDeviceGroupFromUserGroup,
  unAssignDeviceFromUserGroup,
  unAssignUserFromUserGroup,
  assignUserToUserGroup,
  assignDeviceToUserGroup,
  onUpdateLicense,
  onRestoreUser,
  onSuspendUser,
  onBulkEditUser,
  onUnAssignUser,
  onAddDeviceGroup,
  assignDevicesGroupsToUserGroup,
}: {
  id: string;
  users: User[];
  devices: Device[];
  deviceGroups: DeviceGroup[];
  isLoadingUsers: boolean;
  isLoadingUserGroup: boolean;
  isLoadingDevices: boolean;
  isLoadingDeviceGroups: boolean;
  group: any;
  currentUserId?: string;
  onEditUserGroup: (data: FormInputs, id: string) => void;
  onDeleteUserGroup: (id: string) => void;
  onEditDevicesInUserGroup: (data: {
    id: string;
    data: UpdateDeviceRequestData;
  }) => void;
  onEditUsersInUserGroup: (
    userId: string,
    data: Partial<CreateUserRequestData>,
  ) => void;
  onEditDeviceGroupInUserGroup: (
    data: UpdateDeviceGroupAction['payload'],
  ) => void;
  unAssignDeviceGroupFromUserGroup: (
    deviceGroupId: string,
    userGroupId: string,
  ) => void;
  unAssignDeviceFromUserGroup: (deviceId: string, userGroupId: string) => void;
  unAssignUserFromUserGroup: (userId: string, userGroupId: string) => void;
  assignUserToUserGroup: (data: SelectedValue[]) => void;
  assignDeviceToUserGroup: (data: SelectedValue[]) => void;
  onRestoreUser?: (userId: string) => void;
  onSuspendUser?: (userId: string) => void;
  onUpdateLicense?: () => void;
  onBulkEditUser?: (data: BulkUpdateUsersRequestData) => void;
  onUnAssignUser?: (userId: string, userGroupId: string) => void;
  onAddDeviceGroup?: (values: CreateDeviceGroupRequestData) => void;
  assignDevicesGroupsToUserGroup?: (data: SelectedValue[]) => void;
}) => {
  const { t } = useTranslation();
  const { page } = useParams<{ page: string }>();

  const navTabs = [
    {
      key: 'assigned-users',
      tab: (
        <Link
          to={`/users-groups/${id}/users?currentUserId=${currentUserId}`}
          className={clsx(
            'nav-link text-active-primary me-6',
            !(page === 'devices' || page === 'device-groups') && 'active',
          )}
        >
          Assigned users
        </Link>
      ),
    },
    {
      key: 'devices',
      tab: (
        <Link
          to={`/users-groups/${id}/devices?currentUserId=${currentUserId}`}
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'devices' && 'active',
          )}
        >
          {t('pageTitles.devices')}
        </Link>
      ),
    },
    {
      key: 'device-groups',
      tab: (
        <Link
          to={`/users-groups/${id}/device-groups?currentUserId=${currentUserId}`}
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'device-groups' && 'active',
          )}
        >
          {t('devices_groups.device_groups')}
        </Link>
      ),
    },
  ];

  const table = () => {
    if (page === 'devices') {
      return (
        <DevicesTable
          isLoading={isLoadingDevices}
          devices={devices}
          total={devices.length}
          onEditDevice={onEditDevicesInUserGroup}
          unAssignDevice={unAssignDeviceFromUserGroup}
          assignDevice={assignDeviceToUserGroup}
          onUpdateLicense={onUpdateLicense}
          currentUserId={currentUserId}
          withDeleteAction
          deviceAssignmentTarget={{ id, type: DeviceAssignmentTarget.Device }}
        >
          <NavTabs tabs={navTabs} separatorClassName="mb-7" />
        </DevicesTable>
      );
    }
    if (page === 'device-groups') {
      return (
        <DeviceGroups
          assignDevicesGroupsToUserGroup={assignDevicesGroupsToUserGroup}
          groups={deviceGroups}
          total={deviceGroups.length}
          isLoading={isLoadingDeviceGroups}
          onEditDeviceGroup={onEditDeviceGroupInUserGroup}
          unAssignDeviceGroup={unAssignDeviceGroupFromUserGroup}
          onAddGroup={onAddDeviceGroup}
          userGroupId={id}
          currentUserId={currentUserId}
          drownDown
        >
          <NavTabs tabs={navTabs} separatorClassName="mb-7" />
        </DeviceGroups>
      );
    }

    return (
      <UsersTable
        users={users}
        isLoading={isLoadingUsers}
        total={users.length}
        onEditUser={onEditUsersInUserGroup}
        unAssignUser={unAssignUserFromUserGroup}
        assignUser={assignUserToUserGroup}
        onRestoreUser={onRestoreUser}
        onSuspendUser={onSuspendUser}
        onBulkEditUser={onBulkEditUser}
        onUnAssignUser={onUnAssignUser}
        userAssignmentTarget={{ id, type: UserAssignmentTarget.UserGroup }}
        skipEditSuspendReset
        isSelectable={false}
      >
        <NavTabs tabs={navTabs} separatorClassName="mb-7" />
      </UsersTable>
    );
  };

  return (
    <div className="row">
      {!isLoadingUserGroup ? (
        <Card className={clsx('ml-5 col mw-300px', styles.userGroupBlockInfo)}>
          <Card.Header>
            <div className="d-flex flex-column">
              <span className={styles.userGroupName}>{group.name}</span>
              <span
                className={clsx(
                  'badge fs-7 py-3 px-7 mb-3 fw-bold',
                  group.status === UserStatus.Active
                    ? 'badge-light-success'
                    : 'badge-light',
                )}
              >
                {t(statusesTranslation[group.status] ?? group.status)}
              </span>
            </div>
          </Card.Header>
          <Card.Body>
            <div className={clsx('d-flex flex-column', styles.location)}>
              {t('users.table.location')}
              <span>{group.location?.name}</span>
            </div>
            <div className={clsx('d-flex flex-column', styles.location)}>
              {t('users.table.company')}
              <span>{group.company?.name}</span>
            </div>
            <div
              className={clsx(
                'd-flex justify-content-between flex-wrap',
                styles.footerButtons,
              )}
            >
              <EditUserGroupInfo
                group={group}
                onEditUserGroup={onEditUserGroup}
              >
                <span
                  className={clsx(
                    'btn btn-bg-light btn-active-color-primary',
                    styles.editButton,
                  )}
                >
                  Edit User Group
                </span>
              </EditUserGroupInfo>
              <RemoveGroup group={group} onDeleteUserGroup={onDeleteUserGroup}>
                <span
                  className={clsx(
                    'btn btn-icon btn-bg-light btn-color-danger btn-sm me-2',
                    styles.removeButton,
                  )}
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen027.svg"
                    className="svg-icon-3"
                  />
                </span>
              </RemoveGroup>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="w-300px d-flex justify-content-center align-items-center card mb-8 min-h-300px">
          <Spinner as="span" animation="border" size="sm" role="status" />
        </div>
      )}

      <div className="flex-fill col-1 min-w-500px">{table()}</div>
    </div>
  );
};
