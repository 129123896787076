import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { store } from './store';
import App from './pages';
import 'antd/dist/antd.css';
import './lib/metronic/assets/sass/style.scss';
import './lib/metronic/assets/sass/style.react.scss';
import './lib/metronic/assets/sass/plugins.scss';
import 'react-toastify/dist/ReactToastify.css';
import webWorkers from './webWorkers';
import './index.scss';
import './i18n';

webWorkers();
const queryClient = new QueryClient();

Bugsnag.start({
  apiKey: '6da8170cb3f8bc492cef2cd1943aa6b7',
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION,
  releaseStage: process.env.REACT_APP_BUGSNAG_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(
  React,
) as any;

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
          <ToastContainer />
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
