/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from 'clsx';
import React, { useState, useMemo, useEffect } from 'react';
import { Spinner } from 'react-bootstrap-v5';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreateUserRequestData from '../../api/endpoints/users/interfaces/createUserRequestData.interface';
import EditUser from '../../containers/Users/EditUser';
import Device from '../../interfaces/devices/device.interface';
import User from '../../interfaces/user/user.interface';
import routesConfig from '../../routing/config';
import UsersGroup from '../../interfaces/usersGroup.interface';
// import LogTable from '../../pages/MyAccount/LogTable';
import UpdateUsersGroupAction from '../../store/actions/usersGroups/interfaces/updateUsersGroupAction.interface';
import BulkUpdateDevicesRequestDataInterface from '../../api/endpoints/devices/interfaces/bulkUpdateDevicesRequestData.interface';
import UpdateDeviceRequestData from '../../api/endpoints/devices/interfaces/updateDeviceRequestData.interface';
import { LogSessionInterface } from '../../store/reducers/userData/userDataState.interface';
import DeviceAssignmentTarget from '../../enums/deviceAssignmentTarget.enum';
import Card from '../Card';
import DevicesTable from '../Devices';
import NavTabs from '../NavTabs';
import { SelectedValue } from '../Select/SelectAsync';
// import SwitchCheckbox from '../SwitchCheckbox';
import UserGroupsTable from '../UsersGroups';
import LocationsTable from './Location';
import styles from './userProfile.module.scss';
import LoginAsButton from '../AdminPanel/LoginAsConfirm/LoginAsButton';
import { rolesTranslation } from '../../constants/translation/users';
import UsersTable from './UsersTab';
import Company from '../../interfaces/company.interface';
import ShowMoreCell from '../ShowMoreCell';
import Location from '../../interfaces/location.interface';
// import { checkAccountOwner } from '../../utils/common';
import CreateLocation from '../../containers/AdminPanel/CompanyLocations/CreateLocation';
import { KTSVG } from '../../lib/metronic/helpers';
import GetLocationsRequestData from '../../api/endpoints/locations/interfaces/getLocationsRequestData.interface';
import api from '../../api';
import PaginationQueryParam from '../../enums/queryParams/paginationQueryParam.enum';
import useIsAdmin from '../../utils/hooks/useIsAdmin';
import { formatDateString } from '../../utils/dateTime';
import Tooltip from '../Tooltip';
import { checkAccountOwner } from '../../utils/common';
import useIsAccountOwner from '../../utils/hooks/useIsAccountOwner';
import { UserRole } from '../../enums/userRole.enum';
import DevicesGroups from '../DevicesGroups';
import UpdateDeviceGroupRequestData from '../../api/endpoints/devicesGroups/interfaces/updateDeviceGroupRequestData.interface';
import TimeLine from '../TimeLine';
import UserLogs from '../../interfaces/userLogs.interface';

type FormInput = Partial<CreateUserRequestData>;

const formatDate = formatDateString({
  year: '2-digit',
  month: 'short',
  day: 'numeric',
});

interface UserProfileComponentProps {
  id: string;
  deviceTotal: number;
  user: User;
  users: User[];
  usersTotal?: number;
  devices: Device[];
  userGroups: UsersGroup[];
  loginsessions: LogSessionInterface[];
  onEditUser: (id: string, data: FormInput) => void;
  isLoadingDevices: boolean;
  isLoadingUsers: boolean;
  isLoadingUserGroup: boolean;
  isLoadingSessions: boolean;
  isLoadingUser: boolean;
  isLoadingDeviceGroups: boolean;
  isLoadingUserLogs: boolean;
  deviceGroupTotal: number;
  devicesGroups: any[];
  addUserGroup: (data: any) => void;
  getUser: () => void;
  onAddGroup: (data: any) => void;
  onEditDevicesInUserProfile: (data: {
    id: string;
    data: UpdateDeviceRequestData;
  }) => void;
  onEditDeviceGroupInUserProfile: (data: {
    id: string;
    data: UpdateDeviceGroupRequestData;
  }) => void;
  removeDeviceGroup: (deviceGroupeId: string) => void;
  unAssignDeviceFromUserProfile: (deviceId: string, userId: string) => void;
  assignDeviceToUserProfile: (data: SelectedValue[]) => void;
  onEditUserGroupInUserProfile: (
    data: UpdateUsersGroupAction['payload'],
  ) => void;
  userActions?: {
    onCreate: (data: CreateUserRequestData) => void;
    onEdit: (userId: string, data: Partial<CreateUserRequestData>) => void;
    onSuspend: (userId: string) => void;
    onDelete: (userId: string) => void;
    onRestore: (userId: string) => void;
  };
  unAssignUserGroupInUserProfile?: (
    userGroupId: string,
    userId: string,
  ) => void;
  onDeleteUserGroup?: (id: string) => void;
  assignUserGroupInUserProfile: (data: SelectedValue[]) => void;
  onBulkEditDevice: (
    data: Partial<BulkUpdateDevicesRequestDataInterface>,
  ) => void;
  isCustomer?: boolean;
  onUpdateLicense?: () => void;
  userCompanies?: Company[];
  userLocations?: Location[];
  userLogs?: UserLogs[];
  totalUserLogs?: number;
  onUpdateUserLogs?: () => void;
}

interface DefaultProps extends UserProfileComponentProps {
  userActions: Required<UserProfileComponentProps>['userActions'];
}

const UserProfileComponent = ({
  id,
  devices,
  user,
  getUser,
  userGroups,
  users,
  deviceTotal,
  usersTotal,
  onEditUser,
  loginsessions,
  userLogs,
  deviceGroupTotal,
  devicesGroups,
  isLoadingDevices,
  isLoadingDeviceGroups,
  isLoadingUsers,
  onAddGroup,
  isLoadingUserGroup,
  totalUserLogs,
  onUpdateUserLogs,
  isLoadingSessions,
  isLoadingUserLogs,
  isLoadingUser,
  onEditDeviceGroupInUserProfile,
  onEditDevicesInUserProfile,
  onUpdateLicense,
  unAssignDeviceFromUserProfile,
  assignDeviceToUserProfile,
  onEditUserGroupInUserProfile,
  unAssignUserGroupInUserProfile,
  removeDeviceGroup,
  addUserGroup,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  assignUserGroupInUserProfile,
  onBulkEditDevice,
  onDeleteUserGroup,
  isCustomer,
  userCompanies,
  userLocations,
  userActions,
}: DefaultProps) => {
  const { t } = useTranslation();
  const { page } = useParams<{
    page:
      | 'devices'
      | 'user-groups'
      | 'loginsessions'
      | 'users'
      | 'location'
      | 'devicesGroup'
      | 'activity';
  }>();
  // const isAccountOwner = checkAccountOwner(user?.roles);
  // const [isResettingPassword, setIsResettingPassword] = React.useState(true);
  const userRole = user?.roles && user?.roles[0];
  const { search } = useLocation();
  const entityRouteSegment = isCustomer ? 'customers' : 'users';
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const [locations, setLocations] = useState<Location[] | undefined>([]);
  const [total, setTotal] = useState<number>(0);
  const [requestData, setRequestData] = useState<
    Partial<GetLocationsRequestData>
  >({
    [PaginationQueryParam.Page]: '1',
    [PaginationQueryParam.Limit]: '6',
  });

  const [isAdmin] = useIsAdmin();
  const isAccountOwner = checkAccountOwner(user?.roles) as boolean;
  const [loggedInAsAccountOwner] = useIsAccountOwner();
  const resetPassword = async () => {
    setDisable(true);

    try {
      await api.account.adminResetPassword(user.id);
      toast.success(t('success_messages.reset_password'));
      getUser();
    } finally {
      setDisable(false);
    }
  };

  const sendWelcomeEmail = async () => {
    setDisable(true);
    try {
      await api.account.adminSendWelcomeEmail(user.id);
      toast.success(t('success_messages.welcome_email'));
      getUser();
    } finally {
      setDisable(false);
    }
  };

  const getCompanyLocations = async (
    locationsRequestData: Partial<GetLocationsRequestData>,
  ) => {
    try {
      let objectlocationsRequestData = { ...locationsRequestData };
      if (isAccountOwner)
        objectlocationsRequestData = {
          ...locationsRequestData,
          companyIds: user?.assignedCompanyIds?.length
            ? user?.assignedCompanyIds
            : [''],
        };
      else
        objectlocationsRequestData = {
          ...locationsRequestData,
          currentUserId: user.id || id,
        };
      setLoading(true);
      const { items, meta } = isAdmin
        ? await api.locations.getAdminLocations(objectlocationsRequestData)
        : await api.locations.getLocations(objectlocationsRequestData);

      setLocations(items);
      setTotal(meta.totalItems);
    } catch (e) {
      // Do nothing for now
    }
    setLoading(false);
  };
  // this should be more optimized --> [user.id] (edit issue),[userlocation](change before mount)
  // issue in parent so for now it will be [user] but with more calls
  useMemo(() => {
    setLoading(true);
    if (user?.id) getCompanyLocations(requestData);
  }, [requestData, user, page]);

  useMemo(() => {
    setLoading(true);
  }, [onEditUser]);
  const getKey = () => {
    if (id) {
      return `${id}`;
    }
    return id;
  };
  const navTabs: Array<{
    key: string;
    tab: React.ReactNode;
    isHidden?: boolean;
  }> = [
    {
      key: 'devices',
      tab: (
        <Link
          to={`/${entityRouteSegment}/${id}/devices`}
          className={clsx(
            'nav-link text-active-primary me-6',
            (!page || page === 'devices') && 'active',
          )}
          style={{ pointerEvents: page === 'devices' ? 'none' : 'auto' }}
        >
          {t('user_profile.tabs.devices')}
        </Link>
      ),
    },
    {
      key: 'devicesGroup',
      tab: (
        <Link
          to={`/${entityRouteSegment}/${id}/devicesGroup`}
          className={clsx(
            'nav-link text-active-primary me-6',
            (!page || page === 'devicesGroup') && 'active',
          )}
          style={{ pointerEvents: page === 'devicesGroup' ? 'none' : 'auto' }}
        >
          {t('user_profile.tabs.deviceGroup')}
        </Link>
      ),
      isHidden:
        !isCustomer ||
        user?.roles?.includes(UserRole.IndigoEmployee) ||
        user?.roles?.includes(UserRole.IndigoAdmin) ||
        user?.roles?.includes(UserRole.SuperAdmin),
    },
    {
      key: 'users',
      tab: (
        <Link
          to={`/${entityRouteSegment}/${id}/users`}
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'users' && 'active',
          )}
          style={{ pointerEvents: page === 'users' ? 'none' : 'auto' }}
        >
          {t('users.users')}
        </Link>
      ),
      isHidden: !isCustomer || user?.roles?.includes(UserRole.Employee),
    },
    {
      key: 'user-groups',
      tab: (
        <Link
          to={`/${entityRouteSegment}/${id}/user-groups`}
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'user-groups' && 'active',
          )}
          style={{ pointerEvents: page === 'user-groups' ? 'none' : 'auto' }}
        >
          {t('user_profile.tabs.user_groups')}
        </Link>
      ),
      isHidden:
        !isCustomer ||
        user?.roles?.includes(UserRole.Employee) ||
        user?.roles?.includes(UserRole.Manager),
    },

    {
      key: 'location',
      tab: (
        <Link
          to={`/${entityRouteSegment}/${id}/location`}
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'location' && 'active',
          )}
          style={{ pointerEvents: page === 'location' ? 'none' : 'auto' }}
        >
          {t('locations.locations')}
        </Link>
      ),
    },
    {
      key: 'activity',
      tab: (
        <Link
          to={`/${entityRouteSegment}/${id}/activity`}
          className={clsx(
            'nav-link text-active-primary me-6',
            page === 'activity' && 'active',
          )}
          style={{ pointerEvents: page === 'activity' ? 'none' : 'auto' }}
        >
          {t('user_profile.tabs.activity')}
        </Link>
      ),
    },
  ];

  const table = (): React.ReactNode => {
    if (page === 'devices') {
      return (
        <DevicesTable
          isLoading={isLoadingDevices}
          devices={devices}
          total={deviceTotal}
          onEditDevice={onEditDevicesInUserProfile}
          unAssignDevice={unAssignDeviceFromUserProfile}
          assignDevice={assignDeviceToUserProfile}
          onBulkEditDevice={onBulkEditDevice}
          withDeleteAction={!!user?.roles?.includes(UserRole.AccountOwner)}
          deviceAssignmentTarget={{ id, type: DeviceAssignmentTarget.User }}
          onUpdateLicense={onUpdateLicense}
          roles={user?.roles}
          currentUserId={id}
          hideDeviceButton={!user?.roles?.includes(UserRole.AccountOwner)}
        >
          <NavTabs tabs={navTabs} separatorClassName="mb-7" />
          {isLoadingUser ? (
            <div className={styles.loginAs}>
              <Spinner
                hidden={!isLoadingUser}
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </div>
          ) : (
            <LoginAsButton user={user} className={styles.loginAs} />
          )}
        </DevicesTable>
      );
    }
    if (page === 'user-groups') {
      return (
        <UserGroupsTable
          isLoading={isLoadingUserGroup}
          groups={userGroups}
          total={userGroups.length}
          onEditUserGroup={onEditUserGroupInUserProfile}
          unAssignUserGroup={unAssignUserGroupInUserProfile}
          onDeleteUserGroup={onDeleteUserGroup}
          userProfileId={id}
          isSelectable={false}
          addUserGroup={addUserGroup}
        >
          <NavTabs tabs={navTabs} separatorClassName="mb-7" />
          {!isLoadingUser && (
            <LoginAsButton user={user} className={styles.loginAs} />
          )}
        </UserGroupsTable>
      );
    }
    if (page === 'devicesGroup') {
      return (
        <DevicesGroups
          key={getKey()}
          groups={devicesGroups}
          total={deviceGroupTotal ?? 0}
          isLoading={isLoadingDeviceGroups}
          onEditDeviceGroup={onEditDeviceGroupInUserProfile}
          isSelectable={false}
          removeDeviceGroup={removeDeviceGroup}
          onAddGroup={onAddGroup}
          userProfileId={id}
          currentUserId={user?.id}
          hideButton={!!user?.roles?.includes(UserRole.Employee)}
        >
          <NavTabs tabs={navTabs} separatorClassName="mb-7" />
          {!isLoadingUser && (
            <LoginAsButton user={user} className={styles.loginAs} />
          )}
        </DevicesGroups>
      );
    }
    if (page === 'users') {
      return (
        <UsersTable
          isLoading={isLoadingUsers}
          users={users}
          currentUser={user}
          onCreateUser={userActions.onCreate}
          onEditUser={userActions.onEdit}
          onSuspendUser={userActions.onSuspend}
          onDeleteUser={userActions.onDelete}
          onRestoreUser={userActions.onRestore}
          total={usersTotal ?? 0}
        >
          <NavTabs tabs={navTabs} separatorClassName="mb-7" />
          {!isLoadingUser && (
            <LoginAsButton user={user} className={styles.loginAs} />
          )}
        </UsersTable>
      );
    }

    if (page === 'location') {
      return (
        <LocationsTable
          loggedInAsAccountOwner={loggedInAsAccountOwner}
          isAccountOwner={isAccountOwner}
          showModal={userCompanies && userCompanies?.length > 0}
          isLoading={loading}
          locations={locations}
          setRequestData={setRequestData}
          isSelectable={false}
          requestData={requestData}
          onUpdate={() => getUser()}
          total={total}
          tabs={navTabs}
          user={user}
          className={styles.loginAs}
        />
      );
    }

    return (
      <Card>
        <Card.Body className="mt-4">
          <TimeLine
            hideHeader
            loading={isLoadingUserLogs}
            usersLogs={userLogs}
            // hideUsername
            totalItems={totalUserLogs}
          >
            <NavTabs tabs={navTabs} separatorClassName="mb-7" />
            <LoginAsButton
              user={user}
              className={clsx(styles.loginAs, styles.loginSessions)}
            />
          </TimeLine>
        </Card.Body>
      </Card>
    );
  };
  useEffect(() => {
    if (onUpdateUserLogs && search) onUpdateUserLogs();
  }, [search]);
  return (
    <div className={clsx('row', styles.authenticate)}>
      <Card
        className={clsx(
          'col-md-12 col-lg-4 col-xxl-3 col-12 ml-5',
          styles.userGroupBlockInfo,
        )}
      >
        {!isLoadingUser ? (
          <>
            <Card.Header>
              <div className="d-flex flex-column align-items-center w-100 ">
                <div className="d-flex flex-row-fluid flex-column mw-150px">
                  <img
                    src={user.profilePicture}
                    alt={`${user.firstName} ${user.lastName}`}
                    className="rounded"
                  />
                  <span className="text-center fw-bolder fs-4 my-4">{`${user.firstName} ${user.lastName}`}</span>
                  <span className="w-100 text-center py-2 bg-light text-primary rounded">
                    {userRole && t(rolesTranslation[userRole])}
                  </span>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="px-xxl-4 px-0">
              {/* <div className="d-inline-flex justify-content-between w-100 mb-4">
                <label
                  htmlFor="twoFactorEnabled"
                  className="form-label fw-bold pt-1"
                >
                  {t('common.two_form')}
                </label>
                <SwitchCheckbox
                  name="twoFactorEnabled"
                  inputClassName="h-20px w-30px"
                  checked={user.twoFactorEnabled}
                  onChange={() => {}}
                />
              </div> */}
              {user?.isTrial ? (
                <p className="mt-3 mb-0 fs-5 fw-bold">
                  <b>Trial Account</b>
                </p>
              ) : null}
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-5">
                <span className="fs-4 fw-bolder">Info</span>
                <EditUser user={user} onEditUser={onEditUser}>
                  <span className="btn btn-light text-primary">Edit</span>
                </EditUser>
              </div>

              <div className="mt-4">
                <p className="mt-3 mb-0 fs-5 fw-bold">Email</p>
                <p>{user.email ?? '-'}</p>
                <p className="mt-3 mb-0 fs-5 fw-bold">Phone</p>
                <p>{user.phoneNumber ?? '-'}</p>
                {isAdmin && (
                  <div>
                    <span className="fs-4 fw-bolder">Emails</span>
                    <div className="d-flex justify-content-between mb-5">
                      <p className="mt-3 mb-0 fs-5 fw-bold">Welcome Email</p>

                      <button
                        type="button"
                        disabled={!!user.isWelcomeEmail}
                        className="btn btn-secondary btn-sm px-2 me-2 "
                        onClick={sendWelcomeEmail}
                      >
                        send
                      </button>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="mt-3 mb-0 fs-5 fw-bold">
                        Reset Password Email
                      </p>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm px-2 me-2 "
                        onClick={resetPassword}
                      >
                        {user.isForgotPassword ? 'Resend' : 'Send'}
                      </button>
                    </div>
                  </div>
                )}
                <p className="mt-3 mb-0 fs-5 fw-bold">Company</p>

                <div className={clsx(styles.userProfileCompanyList)}>
                  <ShowMoreCell
                    itemNames={
                      userCompanies?.map((company) => company?.name) ?? []
                    }
                    itemId={userCompanies?.map((company) => company?.id) ?? []}
                    widthDefaultSize
                    count={4}
                    linkTo={routesConfig.adminCustomerCompanies.route}
                    title={t('companies.companies')}
                    bodyClassName="justify-content-center"
                  >
                    <div className="row w-100">
                      {userCompanies?.map((company) => (
                        <div className="col-lg-3 col-sm-12 mb-5">
                          <span className="w-100 text-center bg-light text-primary rounded p-2 d-block">
                            {company && company.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </ShowMoreCell>
                </div>

                <p className="mt-3 mb-0 fs-5 fw-bold">Status</p>
                <p>{user.status ?? '-'}</p>
                <p className="mt-3 mb-0 fs-5 fw-bold">Location</p>
                <div className={clsx(styles.userProfileCompanyList)}>
                  {userLocations?.length === 0 ? (
                    <div className="d-flex align-items-center align-content-center justify-content-center">
                      <CreateLocation
                        disabled={
                          userCompanies?.length === 0 ||
                          !loggedInAsAccountOwner ||
                          !isAccountOwner
                        }
                        hasCompany
                        onSuccess={() => {
                          getCompanyLocations(requestData);
                          getUser();
                        }}
                      >
                        {userCompanies &&
                        userCompanies?.length > 0 &&
                        loggedInAsAccountOwner &&
                        isAccountOwner ? (
                          <p className="text-center">
                            <button
                              type="button"
                              className="btn btn-secondary me-2"
                            >
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr075.svg"
                                className="svg-icon-2"
                              />
                              {t('locations.add_location')}
                            </button>
                          </p>
                        ) : (
                          <Tooltip
                            text={
                              !loggedInAsAccountOwner || !isAccountOwner
                                ? t(
                                    'locations.add_location_lock_not_accountowner',
                                  )
                                : t(
                                    'locations.add_location_lock_empty_companies',
                                  )
                            }
                          >
                            <p className="text-center">
                              <button
                                type="button"
                                className="btn btn-secondary me-2"
                              >
                                {t('locations.add_location')}
                                <i
                                  className="bi bi-lock-fill ms-3"
                                  style={{ fontSize: '1.4rem' }}
                                />
                              </button>
                            </p>
                          </Tooltip>
                        )}
                      </CreateLocation>
                    </div>
                  ) : (
                    <ShowMoreCell
                      itemNames={
                        userLocations?.map((location) => location?.name) ?? []
                      }
                      widthDefaultSize
                      count={4}
                      title={t('locations.locations')}
                      bodyClassName="justify-content-center"
                    >
                      <div className="row w-100">
                        {userLocations?.map((location) => (
                          <div className="col-lg-3 col-sm-12 mb-5">
                            <span className="w-100 text-center bg-light text-primary rounded p-2 d-block">
                              {location && location.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    </ShowMoreCell>
                  )}
                </div>
              </div>
            </Card.Body>
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-center m-auto">
            <Spinner
              hidden={!isLoadingUser}
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </div>
        )}
      </Card>

      <div
        className={clsx(
          'col-lg-8 col-xxl-9 col-md-12 col-12',
          styles.customerProfile,
        )}
      >
        {table()}
      </div>
    </div>
  );
};

UserProfileComponent.defaultProps = {
  userActions: {},
};

export default UserProfileComponent;
