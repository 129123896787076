import React from 'react';
import ConversionJob from '../../../interfaces/conversionJob.interface';
import { conversionJobBadgeTypes } from '../../../constants/conversionJob';
import commonStyles from '../../../styles/common.module.scss';
import styles from '../../Contents/ContentTable/TableRow/tableRow.module.scss';
import CheckBox from '../../CheckBox';
import Table from '../../Table';
import Badge from '../../Badge';

const { Row } = Table.Body;
const { Cell } = Row;

interface TableRowProps {
  conversionJob: ConversionJob;
  config?: {
    skipActions?: boolean;
    disableLinks?: boolean;
    isSelectable?: boolean;
  };
}

interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
}

export default ({ conversionJob, config }: DefaultProps) => {
  const { media } = conversionJob;

  return (
    <Row className={styles.row} key={media?.id}>
      {config.isSelectable && (
        <Cell className="w-30px pe-0">
          <CheckBox
            name="contentId"
            id={media?.id}
            checked={false}
            className={commonStyles.disabled}
          />
        </Cell>
      )}
      <Cell isNoWrap>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px w-55px me-5 d-flex justify-content-center">
            <i className="fas fa-sync fa-spin fa-lg" />
          </div>
          {conversionJob.originalName}
        </div>
      </Cell>
      <Cell className="text-center" isNoWrap isEmpty={!media?.title}>
        <span className="text-dark fw-bold d-block mb-1 fs-6">
          {media?.title}
        </span>
      </Cell>
      <Cell className="text-center" isNoWrap>
        <span className="text-dark fw-bold d-block mb-1 fs-6">-</span>
      </Cell>
      <Cell isNoWrap isTextEnd>
        <Badge type={conversionJobBadgeTypes[conversionJob.status]}>
          {conversionJob.status}
        </Badge>
      </Cell>
    </Row>
  );
};
