import React from 'react';
import { useTranslation } from 'react-i18next';
import useFilter from '../../../utils/hooks/useFilter';
import { UserStatus } from '../../../enums/userStatus.enum';
import { debouncedUpdateSearch } from '../../../utils/browserHistory';
import UsersQueryParam from '../../../api/endpoints/users/enums/usersQueryParam.enum';
import FiltersDropdown from '../../Dropdown/FiltersDropdown';
import { UserRole } from '../../../enums/userRole.enum';
import Select from '../../Select/RegularSelect';
import {
  rolesTranslation,
  statusesTranslation,
} from '../../../constants/translation/users';

export default ({ className }: { className?: string }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useFilter<UserStatus>(UsersQueryParam.Status);
  const [role, setRole] = useFilter<UserRole>(UsersQueryParam.Role);
  const customUserRole = ['ADMIN', 'MANAGER', 'EMPLOYEE'];

  const handleApply = () =>
    debouncedUpdateSearch({
      [UsersQueryParam.Status]: status ?? '',
      [UsersQueryParam.Role]: role ?? '',
    });

  // Reset filters in the query params
  const handleReset = () =>
    debouncedUpdateSearch({
      [UsersQueryParam.Status]: '',
      [UsersQueryParam.Role]: '',
    });

  return (
    <FiltersDropdown
      className={className}
      onApplyFilters={handleApply}
      onReset={handleReset}
    >
      <p className="mb-5">
        <label htmlFor="status" className="form-label fw-bold">
          {t('common.status')}:
        </label>
        <Select<UserStatus>
          id="status"
          defaultValue={
            status && { value: status, label: t(statusesTranslation[status]) }
          }
          placeholder={t('users_groups.select_status')}
          onSelect={(selectedStatus) => setStatus(selectedStatus as UserStatus)}
          options={Object.values(UserStatus).map((userStatusValue) => ({
            label: t(statusesTranslation[userStatusValue]),
            value: userStatusValue,
          }))}
        />
      </p>
      <p className="mb-10">
        <label htmlFor="role" className="form-label fw-bold">
          {t('common.role')}:
        </label>
        <Select<UserRole>
          id="role"
          defaultValue={
            role && { value: role, label: t(rolesTranslation[role]) }
          }
          placeholder={t('users_groups.select_role')}
          onSelect={(selectedRole) => setRole(selectedRole as UserRole)}
          options={Object.values(UserRole)
            .map((userRoleValue) => ({
              label: t(rolesTranslation[userRoleValue]),
              value: userRoleValue,
            }))
            .filter((userRoleValue) =>
              customUserRole.includes(userRoleValue.value),
            )}
        />
      </p>
    </FiltersDropdown>
  );
};
