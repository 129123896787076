import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
// import DaysSafeModal from '../../../../components/Apps/DaysSafe/CreateDaysSafe';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import App from '../../../../interfaces/app.interface';

import Modal from '../../../../components/Modal';
import api from '../../../../api';
import ResetDaysSafeModal from '../../../../components/Apps/DaysSafe/ResetDaysSafeModal';

const ResetDaysSafe = ({
  children,
  onUpdate,
  daysSafeApp,
}: {
  children?: React.ReactNode;
  onUpdate: () => void;
  daysSafeApp: App;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const handleSubmit = (data: CreateAppRequestData) => {
    setLoading(true);

    api.apps
      .updateApp(daysSafeApp.id, data)
      .then(() => {
        close();
        if (onUpdate) {
          onUpdate();
        }
        toast.success(`${i18next.t<string>('apps.daysSafe.daysSafeEdited')}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {children ?? (
        <button
          type="button"
          className="btn  btn-sm btn-indigo-pink fw-bold"
          onClick={() => {
            open();
          }}
        >
          {t('apps.daysSafe.reset')}
        </button>
      )}

      <Modal
        title={`${t('apps.daysSafe.resetDays')}`}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
        fullscreen="sm-down"
      >
        <ResetDaysSafeModal
          onClose={close}
          daysSafeApp={daysSafeApp}
          onSubmit={handleSubmit}
          isLoading={loading}
        />
      </Modal>
    </>
  );
};

export default ResetDaysSafe;
