import GetUsersListSuccessAction from './interfaces/getUsersListSuccessAction.interface';
import GetUsersListAction from './interfaces/getUsersListAction.interface';
import SuspendUserAction from './interfaces/suspendUserAction.interface';
import RestoreUserAction from './interfaces/restoreUserAction.interface';
import UpdateUserAction from './interfaces/updateUserAction.interface';
import CreateUserAction from './interfaces/createUserAction.interface';
import LoadingAction from '../loadingAction.interface';
import UsersActions from './usersActions.enum';

export const getUsersList = (
  payload: GetUsersListAction['payload'],
): GetUsersListAction => ({
  type: UsersActions.GET_USERS_LIST,
  payload,
});

export const getAdminUsersList = (
  payload: GetUsersListAction['payload'],
): any => ({
  type: UsersActions.GET_ADMIN_USERS_LIST,
  payload,
});

export const getUsersListSuccess = (
  payload: GetUsersListSuccessAction['payload'],
): GetUsersListSuccessAction => ({
  type: UsersActions.GET_USERS_LIST_SUCCESS,
  payload,
});

export const getAdminUsersListSuccess = (
  payload: GetUsersListSuccessAction['payload'],
): any => ({
  type: UsersActions.GET_ADMIN_USERS_LIST_SUCCESS,
  payload,
});

export const createUser = (
  payload: CreateUserAction['payload'],
): CreateUserAction => ({
  type: UsersActions.CREATE_USER,
  payload,
});

export const updateUser = (
  payload: UpdateUserAction['payload'],
): UpdateUserAction => ({
  type: UsersActions.UPDATE_USER,
  payload,
});

export const suspendUser = (
  payload: SuspendUserAction['payload'],
): SuspendUserAction => ({
  type: UsersActions.SUSPEND_USER,
  payload,
});

export const restoreUser = (
  payload: RestoreUserAction['payload'],
): RestoreUserAction => ({
  type: UsersActions.RESTORE_USER,
  payload,
});

export const setUsersLoading = (
  payload: LoadingAction['payload'],
): LoadingAction => ({
  type: UsersActions.SET_USERS_LOADING,
  payload,
});
