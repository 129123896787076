import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getAdminDevicesList,
  linkDevice,
} from '../../../store/actions/devices';
import Device from '../../../interfaces/devices/device.interface';
import LinkDevice from '../../../components/Devices/LinkDevice';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import WithUnlinkConfirm from './WithUnlinkConfirm';

export default ({
  device,
  children,
  linkedDevice,
}: {
  device: Device;
  children?: React.ReactNode;
  linkedDevice?: boolean;
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const handleSubmit = (pin: string) => {
    dispatch(linkDevice({ deviceId: device.id, pin }));
    dispatch(getAdminDevicesList(readAllFromQueryParams(search)));
  };

  return (
    <>
      {!linkedDevice ? (
        <LinkDevice onSubmit={handleSubmit} device={device}>
          {children ?? (
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-link" />
            </span>
          )}
        </LinkDevice>
      ) : (
        <WithUnlinkConfirm device={device} onSubmit={handleSubmit}>
          {children ?? (
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-link" />
            </span>
          )}
        </WithUnlinkConfirm>
      )}
    </>
  );
};
