import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChartsWidget2,
  StatisticData,
} from '../../../lib/metronic/partials/widgets';
import { ChartsWidget9 } from '../../../lib/metronic/partials/widgets/charts/ChartWidget9';

export default ({ data }: { data: StatisticData }) => {
  const { t } = useTranslation();

  return (
    <div className="row g-5 g-xl-8">
      <div className="col-xl-6">
        <ChartsWidget2
          title={t('statistic.players.title')}
          subTitle={t('statistic.players.subTitle')}
          data={data}
        />
      </div>
      <div className="col-xl-6">
        <ChartsWidget9
          className="w-50"
          title={t('statistic.players.title')}
          subTitle={t('statistic.players.totalPlayers')}
        />
      </div>
    </div>
  );
};
