import { TFunction } from 'i18next';
import validation from '../../utils/validation';

export const tickerCreateValidation = (t: TFunction) => ({
  name: validation.string().required(t('tickers.validation.enterTickerName')),
  message: validation
    .string()
    .required(t('tickers.validation.enterTickerMessage')),
  location: validation.string(),
  backgroundColor: validation.object().shape({
    r: validation.number(),
    g: validation.number(),
    b: validation.number(),
    a: validation.number(),
  }),
  textColor: validation.object().shape({
    r: validation.number(),
    g: validation.number(),
    b: validation.number(),
    a: validation.number(),
  }),
  tickerSpeed: validation
    .object()
    .shape({
      label: validation.string(),
      value: validation.string(),
    })
    .required(),
  startDate: validation.date(),
  endDate: validation.date().when('startDate', {
    is: (startDate: any) => !!startDate,
    then: validation.date().min(validation.ref('startDate')),
  }),
  deviceIds: validation.array().when('deviceGroupIds', {
    is: (deviceGroupIds: string[]) =>
      !deviceGroupIds || deviceGroupIds.length === 0,
    then: validation
      .array()
      .min(1, t('tickers.validation.selectDevices'))
      .required(t('tickers.validation.selectDevices')),
    otherwise: validation.array(),
  }),
  deviceGroupIds: validation.array().when('deviceIds', {
    is: (deviceIds: string[]) => !deviceIds || deviceIds.length === 0,
    then: validation
      .array()
      .min(1, t('tickers.validation.selectDeviceGroups'))
      .required(t('tickers.validation.selectDeviceGroups')),
    otherwise: validation.array(),
  }),
});

export const tickerRepublishValidationStepCreate = () => ({
  startDate: validation.date(),
  endDate: validation.date().when('startDate', {
    is: (startDate: any) => !!startDate,
    then: validation.date().min(validation.ref('startDate')),
  }),
});

export const tickerBulkEditValidation = (t: TFunction) => ({
  backgroundColor: validation.object().shape({
    r: validation.number(),
    g: validation.number(),
    b: validation.number(),
    a: validation.number(),
  }),
  textColor: validation.object().shape({
    r: validation.number(),
    g: validation.number(),
    b: validation.number(),
    a: validation.number(),
  }),
  startDate: validation.date(),
  endDate: validation.date().when('startDate', {
    is: (startDate: any) => !!startDate,
    then: validation.date().min(validation.ref('startDate')),
  }),
  deviceIds: validation
    .array()
    .min(1)
    .required(t('tickers.validation.selectDevices')),
  deviceGroupIds: validation
    .array()
    .min(1)
    .required(t('tickers.validation.selectDeviceGroups')),
});
