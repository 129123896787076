import { map } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import clsx from 'clsx';
import ConversionJobRow from './ConversionJobRow';
import PlaylistContentsSortField from '../../../api/endpoints/playlists/enums/playlistContentsSortField.enum';
import ConversionJob from '../../../interfaces/conversionJob.interface';
import IContent from '../../../interfaces/content.interface';
import SortOrder from '../../../enums/sortOrder.enum';
import { readFromQueryString } from '../../../utils/queryParams';
import Columns, { TableHeaderColumn } from '../../Table/Columns';
import Playlist from '../../../interfaces/playlist.interface';
import styles from './contentTable.module.scss';
import WithSpinner from '../../WithSpinner';
import TableRow from './TableRow';
import Table from '../../Table';
import api from '../../../api';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../utils/hooks/useTableMultiSelect';
import Device from '../../../interfaces/devices/device.interface';

interface TableViewProps {
  contents: IContent[];
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight?: number;
  isSelectable?: boolean;
  multiSelectConfig?: TableMultiSelectConfig;
  playlist: Playlist;
  onEdit: () => void;
  conversionJobs?: ConversionJob[];
  devicesWithoutLicence?: Device[];
}

interface DefaultProps extends TableViewProps {
  multiSelectConfig: Required<TableViewProps>['multiSelectConfig'];
}

export default ({
  contents,
  playlist,
  isLoading,
  sortQueryField,
  orderQueryField,
  onEdit,
  conversionJobs,
  preloaderHeight = 300,
  isSelectable = false,
  multiSelectConfig: { selectedItems, setSelectedItem, selectAll },
  devicesWithoutLicence,
}: DefaultProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [orderedContents, setContents] = useState(contents);
  const playlistId = window.location.pathname.split('/')[2];
  let updatedContent: any[] = [];
  let changedContentName = '';
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  // TODO: optimize and reduce the number of re-renders
  React.useEffect(() => {
    if (!contents) return;
    setContents(contents);
  }, [contents]);

  const columns: TableHeaderColumn<PlaylistContentsSortField>[] = [
    {
      name: t('contents.title'),
    },
    {
      name: t('contents.duration'),
    },
    {
      name: t('contents.start_date'),
    },
    {
      name: t('contents.end_date'),
    },
    {
      name: 'MTWTFSS',
    },
    {
      name: t('common.tag'),
    },
    { name: t('common.actions') },
  ];

  const isSelectableValue = contents.length > 1 ? isSelectable : false;

  const SortableItem = SortableElement(({ children }: any) => {
    return <>{children}</>;
  });
  const SortableCont = SortableContainer(({ children }: any) => {
    return <>{children}</>;
  });

  const arrayMoveMutate = (array: any, from: number, to: number) => {
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  };

  const arrayMove = (array: any, from: number, to: number) => {
    const newArray = array.slice();
    changedContentName = newArray[from].title;
    arrayMoveMutate(newArray, from, to);
    return newArray;
  };

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setContents((oldItems) => {
      const newContent = arrayMove(oldItems, oldIndex, newIndex);
      updatedContent = newContent;

      if (updatedContent && updatedContent.length) {
        const contentOrders: any[] = [];
        // eslint-disable-next-line array-callback-return
        updatedContent.map((content, i) => {
          contentOrders.push({
            order: i,
            contentId: content.itemId,
            name: content.name,
          });
        });
        api.playlists.updateContentOrders({
          playlistId,
          contentOrders,
          contentName: changedContentName,
          playlistName: playlist.name,
        });
      }

      return newContent;
    });
  }, []);

  const handleSortStart = ({ node }: any) => {
    const tds: any =
      document.getElementsByClassName('SortableHelper')[0].childNodes;
    node.childNodes.forEach(
      // eslint-disable-next-line @typescript-eslint/no-shadow,no-return-assign
      (node: any, idx: any) => (tds[idx].style.width = `${node.offsetWidth}px`),
    );
  };

  return (
    <div>
      <Table>
        <Table.Head>
          <Table.Head.Row>
            <Columns<PlaylistContentsSortField>
              columns={columns}
              checked={isAllSelected(selectedItems, map(contents, 'id'))}
              onChange={selectAll}
              checkBoxClassName={clsx('pe-0', styles.checkbox)}
              config={{
                isSelectable: isSelectableValue,
                sortField,
                setSortField,
                sortQueryField,
                orderQueryField,
              }}
            />
          </Table.Head.Row>
        </Table.Head>
        <SortableCont
          onSortEnd={onSortEnd}
          axis="y"
          lockAxis="y"
          lockToContainerEdges
          lockOffset={['30%', '50%']}
          helperClass="SortableHelper"
          onSortStart={handleSortStart}
          useDragHandle
        >
          <Table.Body
            isEmpty={!isLoading && !contents.length && !conversionJobs?.length}
            emptyStateChildren={t('playlists.no_contents_found')}
          >
            <WithSpinner
              isLoading={isLoading}
              style={{ minHeight: `${preloaderHeight}px` }}
              size="md"
              isForTable
            >
              {orderedContents.map((content, index) => {
                if (!content) return null;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <SortableItem key={`item-${index}`} index={index}>
                    <TableRow
                      playlist={playlist}
                      content={content}
                      key={content.id}
                      config={{ isSelectable: isSelectableValue }}
                      isChecked={selectedItems.includes(content.id)}
                      onCheck={setSelectedItem}
                      onEdit={onEdit}
                      devicesWithoutLicence={devicesWithoutLicence}
                    />
                  </SortableItem>
                );
              })}

              {conversionJobs?.length
                ? conversionJobs.map((conversionJob) => (
                    <ConversionJobRow
                      key={conversionJob?.id}
                      conversionJob={conversionJob}
                      config={{ isSelectable: isSelectableValue }}
                    />
                  ))
                : null}
            </WithSpinner>
          </Table.Body>
        </SortableCont>
      </Table>
    </div>
  );
};
