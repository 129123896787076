import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Details from './Steps/Details';
import Layout from './Steps/Layout';
import WithModesModal from '../../WithModesModal';
import CreateTemplateRequestData from '../../../api/endpoints/templates/interfaces/createTemplateRequestData.interface';
import sizingStyles from '../../../styles/sizing.module.scss';
import AddTemplateSteps from '../../../enums/steps/addTemplateSteps.enum';
import validation from '../../../utils/validation';

export default ({
  onSubmit,
  isVisible,
  onClose,
  isLoading,
  defaultStepsValues = {},
}: {
  onSubmit: (data: CreateTemplateRequestData) => void;
  onClose?: () => void;
  isVisible: boolean;
  isLoading?: boolean;
  defaultStepsValues?: Partial<CreateTemplateRequestData>;
}) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] =
    useState<Partial<CreateTemplateRequestData>>(defaultStepsValues);

  return (
    <WithModesModal<Partial<CreateTemplateRequestData>, AddTemplateSteps>
      isVisible={isVisible}
      isLoading={isLoading}
      onClose={onClose}
      modes={{
        [AddTemplateSteps.Layout]: {
          title: t('templates.create_template.title'),
          render: ({ setMode }) => (
            <Layout
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              validationSchema={{
                name: validation
                  .string()
                  .required(t('templates.create_template.validation.name')),
                layout: validation.object().shape({
                  id: validation
                    .string()
                    .required(t('templates.create_template.validation.layout')),
                  preview: validation.string(),
                }),
              }}
            />
          ),
        },
        [AddTemplateSteps.Details]: {
          title: t('templates.create_template.title'),
          render: ({ setMode }) => (
            <Details
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              validationSchema={{
                background: validation
                  .array()
                  .min(
                    1,
                    t('templates.create_template.validation.backgroundImage'),
                  )
                  .required(
                    t('templates.create_template.validation.backgroundImage'),
                  ),
                logo: validation
                  .array()
                  .min(1, t('templates.create_template.validation.logoImage'))
                  .required(
                    t('templates.create_template.validation.logoImage'),
                  ),
              }}
              onSubmit={(data) => {
                onSubmit(data as CreateTemplateRequestData);

                setStepsData({});
              }}
            />
          ),
        },
      }}
      defaultMode={AddTemplateSteps.Layout}
      dialogClassName={clsx('w-100', sizingStyles.fitContentOld)}
    />
  );
};
