import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AssignDevices from '../../../../../containers/Devices/AssignDevices';
import CreateUsersGroupRequestData from '../../../../../api/endpoints/usersGroups/interfaces/createUsersGroupRequestData.interface';
import AssignDevicesGroups from '../../../../../containers/DevicesGroups/AssignDevicesGroups';
import AssignUsers from '../../../../../containers/Users/AssignUsers';
import SelectNavTab from '../../../../NavTabs/SelectNavTab';
import Card from '../../../../Card';
import UsersGroup from '../../../../../interfaces/usersGroup.interface';
import User from '../../../../../interfaces/user/user.interface';
import api from '../../../../../api';
import GlobalState from '../../../../../store/reducers/globalState.interface';
import { UserRole } from '../../../../../enums/userRole.enum';

enum Tab {
  Users = 'users',
  Devices = 'devices',
  DevicesGroups = 'devices-groups',
}

export default ({
  data,
  setData,
  group,
}: {
  data: Partial<CreateUsersGroupRequestData>;
  setData: (newData: Partial<CreateUsersGroupRequestData>) => void;
  group: UsersGroup;
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.Users);

  const { id } = useParams<{ id: string }>();

  const [currentUser, setCurrentUser] = useState<User>();
  const { roles } = useSelector((state: GlobalState) => state.userData.user);

  const getCurrentUser = async () => {
    try {
      const user = await api.users.getUserForAdmin(id);
      setCurrentUser(user);
    } catch (e: any) {
      toast.error(e?.message ?? '');
    }
  };

  useEffect(() => {
    if (roles.includes(UserRole.SuperAdmin)) {
      getCurrentUser();
    }
  }, []);

  const usersPreselectedItems = group?.users?.map((item) => item.id);
  const devicesPreselectedItems = group?.devices?.map((item) => item.id);
  const devicesGroupPreselectedItems = group?.assignedDeviceGroups?.map(
    (item) => item.id,
  );
  let currentTable: React.ReactNode;

  switch (currentTab) {
    case Tab.Users:
      currentTable = (
        <AssignUsers
          preselectedItems={data.userIds ?? usersPreselectedItems ?? []}
          onSelectionChange={(selectedItems) =>
            setData({ ...data, userIds: selectedItems })
          }
          companyId={data?.companyId}
          locationId={data?.locationId}
        />
      );
      break;
    case Tab.Devices:
      currentTable = (
        <AssignDevices
          preselectedItems={data.deviceIds ?? devicesPreselectedItems ?? []}
          onSelectionChange={(selectedItems) =>
            setData({ ...data, deviceIds: selectedItems })
          }
          currentUser={currentUser}
        />
      );
      break;
    case Tab.DevicesGroups:
      currentTable = (
        <AssignDevicesGroups
          preselectedItems={
            data.deviceGroupIds ?? devicesGroupPreselectedItems ?? []
          }
          onSelectionChange={(selectedItems) =>
            setData({ ...data, deviceGroupIds: selectedItems })
          }
          currentUser={currentUser}
        />
      );
      break;
    default:
      currentTable = '';
  }

  return (
    <Card className="mb-5" withoutMargins>
      <Card.Header>
        <Card.Header.Toolbar>
          <SelectNavTab<Tab>
            tabs={[
              {
                key: Tab.Users,
                isActive: currentTab === Tab.Users,
                text: t('users_groups.create_group.assigned_users'),
              },
              {
                key: Tab.Devices,
                isActive: currentTab === Tab.Devices,
                text: t('devices.devices'),
              },
              {
                key: Tab.DevicesGroups,
                isActive: currentTab === Tab.DevicesGroups,
                text: t('devices_groups.devices_groups'),
              },
            ]}
            onSelect={setCurrentTab}
          />
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>{currentTable}</Card.Body>
    </Card>
  );
};
