import axios from 'axios';
import { API_URL } from '../../../constants/api';
import PlaylistsRoutes from './enums/playlistsRoutes.enum';
import GetPlaylistsRequestData from './interfaces/getPlaylistsRequestData.interface';
import GetPlaylistsResponseData from './interfaces/getPlaylistsResponseData.interface';
import CreatePlaylistsRequestData from './interfaces/createPlaylistRequestData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import GetPlaylistRequestData from './interfaces/getPlaylistRequestData.interface';
import GetPlaylistResponseData from './interfaces/getPlaylistResponseData.interface';
import GetPlaylistContentsRequestData from './interfaces/getPlaylistContentsRequestData.interface';
import GetPlaylistContentsResponseData from './interfaces/getPlaylistContentsResponseData.interface';
import UpdatePlaylistRequestData from './interfaces/updatePlaylistRequestData.interface';
import DeleteContentFromPlaylistRequestData from './interfaces/deleteContentFromPlaylistRequestData.interface';
import BulkUpdatePlaylistRequestData from './interfaces/bulkUpdatePlaylistRequestData.interface';
import GetPlaylistDevicesListRequestData from './interfaces/getPlaylistDevicesRequestData.interface';
import GetPlaylistDevicesListResponseData from './interfaces/getPlaylistDevicesListResponseData.interface';
import User from '../../../interfaces/user/user.interface';

export default {
  /**
   * Retrieves the single playlist by id
   * @param requestData
   */
  getPlaylist: async ({
    playlistId,
  }: GetPlaylistRequestData): Promise<GetPlaylistResponseData> => {
    const { data } = await axios.get(
      `${API_URL}/${PlaylistsRoutes.Playlists}/${playlistId}`,
    );

    return data;
  },

  /**
   * Retrieves the playlist's devices
   * @param requestData
   * @param playlistId
   */
  getPlaylistDevices: async (
    requestData: GetPlaylistDevicesListRequestData,
    playlistId: string,
  ): Promise<GetPlaylistDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${PlaylistsRoutes.Playlists}/${playlistId}/devices`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Assign devices to playlist
   * @param devicesIds
   * @param playlistId
   */
  assignDevicesToPlaylist: async (
    playlistId: string,
    devicesIds: string[],
  ): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${PlaylistsRoutes.Playlists}/${playlistId}/devices`,
      data: { devicesIds },
    });
  },

  /**
   * Assign devices to playlist
   * @param devicesIds
   * @param playlistId
   */
  clonePlaylist: async (playlistId: string): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${PlaylistsRoutes.Playlists}/clone/${playlistId}`,
    });
  },

  cloneAdminPlaylist: async (playlistId: string): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/management/${PlaylistsRoutes.Playlists}/clone/${playlistId}`,
    });
  },
  /**
   * Retrieves the list of playlists
   * @param requestData
   */
  getPlaylists: async (
    requestData: GetPlaylistsRequestData,
  ): Promise<GetPlaylistsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${PlaylistsRoutes.Playlists}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of playlists Base on content ID
   * @param requestData
   */
  getPlaylistsByContentId: async (
    requestData: GetPlaylistsRequestData,
    mediaId?: string,
    appId?: string,
    uuid?: string,
  ): Promise<GetPlaylistsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${PlaylistsRoutes.Playlists}/playlists/multiple`,
      params: sanitizeQueryParams({ ...requestData, appId, mediaId, uuid }),
    });

    return data;
  },

  /**
   * Retrieves the list of playlists
   * @param requestData
   */
  adminGetPlaylists: async (
    requestData: GetPlaylistsRequestData,
  ): Promise<GetPlaylistsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${PlaylistsRoutes.AdminPlaylists}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Removes the playlist by id
   * @param id
   */
  deletePlaylist: async (id: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${PlaylistsRoutes.Playlists}/${id}`,
    });
  },

  /**
   * Bulk delete playlists
   * @param playlistIds
   */
  bulkDeletePlaylist: async (playlistIds: string[]): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${PlaylistsRoutes.Playlists}`,
      data: playlistIds,
    });
  },

  /**
   * Updates the playlist by id
   * @param id
   * @param data
   */
  updatePlaylist: async (
    id: string,
    data: UpdatePlaylistRequestData,
  ): Promise<void> => {
    await axios.patch(`${API_URL}/${PlaylistsRoutes.Playlists}/${id}`, data);
  },

  /**
   * Bulk update playlist
   * @param requestData
   */
  bulkUpdatePlaylist: async (
    requestData: BulkUpdatePlaylistRequestData,
  ): Promise<void> => {
    await axios({
      method: 'put',
      url: `${API_URL}/${PlaylistsRoutes.Playlists}/bulk`,
      data: requestData,
    });
  },

  /**
   * Retrieves the list of contents of playlist by playlistId
   * @param playlistId
   * @param params
   */
  getContent: async ({
    playlistId,
    params,
  }: GetPlaylistContentsRequestData): Promise<GetPlaylistContentsResponseData> => {
    const { data } = await axios.get(
      `${API_URL}/${PlaylistsRoutes.Playlists}/${playlistId}/content?sortOrder=asc`,
      {
        params: sanitizeQueryParams(params ?? {}),
      },
    );

    return data;
  },

  /**
   * Retrieves Owner of the content
   * @param contentId
   * @param app
   */
  getContentOwner: async (contentId: string, app: Boolean): Promise<User> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${PlaylistsRoutes.Playlists}/content/getContentOwner`,
      params: { contentId, app: app === true ? 'true' : 'false' },
    });

    return data;
  },

  updateContentOrders: async (data: any): Promise<void> => {
    await axios.patch(
      `${API_URL}/${PlaylistsRoutes.Playlists}/content/bulk`,
      data,
    );
  },

  createPlaylist: async (
    requestPayload: CreatePlaylistsRequestData,
  ): Promise<void> => {
    await axios.post(`${API_URL}/${PlaylistsRoutes.Playlists}`, requestPayload);
  },

  admincreatePlaylist: async (
    requestPayload: CreatePlaylistsRequestData,
  ): Promise<void> => {
    await axios.post(
      `${API_URL}/${PlaylistsRoutes.AdminPlaylists}`,
      requestPayload,
    );
  },

  deleteContentFromPlaylist: async ({
    playlistId,
    contentId,
  }: DeleteContentFromPlaylistRequestData): Promise<void> => {
    await axios.delete(
      `${API_URL}/${PlaylistsRoutes.Playlists}/${playlistId}/content/${contentId}`,
    );
  },

  deleteContentFromMultiplePlaylists: async (
    playlistIds: string[],
    contentId?: string,
    appId?: string,
  ): Promise<void> => {
    await axios.delete(
      `${API_URL}/${PlaylistsRoutes.Playlists}/content/multiplePlaylists/delete`,
      { params: { playlistIds, mediaId: contentId, appId } },
    );
  },

  deleteMultipleContentsFromPlaylist: async (
    playlistId: string,
    contentIds: string[],
    playlistIds: string[],
  ): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${PlaylistsRoutes.Playlists}/${playlistId}/contents`,
      params: sanitizeQueryParams({ contentIds, playlistIds }),
    });
  },
};
