import React, { useState } from 'react';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useModalState from '../../../utils/hooks/useModalState';
import PublishModal from '../../../components/PublishModal';
import ScheduleContentRequestData from '../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import App from '../../../interfaces/app.interface';
import Tooltip from '../../../components/Tooltip';
import api from '../../../api';

export default ({
  app,
  children,
  onUpdate,
  skipOrder,
  skipOrientationAuto,
  infoValidationSchema,
  isCountDown,
}: {
  app: App;
  children?: React.ReactNode;
  onUpdate: () => void;
  skipOrder?: boolean;
  skipOrientationAuto?: boolean;
  infoValidationSchema?: { [key: string]: any };
  isCountDown?: boolean;
}) => {
  const { t } = useTranslation();
  const [isVisible, handleOpen, handleClose] = useModalState(false);
  const [hidePortrait, setHidePortrait] = useState(false);

  const handleSubmit = (data: ScheduleContentRequestData) => {
    api.apps.scheduleApp(app.type, app.id, data).then(() => {
      toast.success(i18next.t<string>('common.app_scheduled'));
      handleClose();
      onUpdate();
    });
  };

  React.useEffect(() => {
    if (app.type === 'rss' || app.type === 'weather') {
      setHidePortrait(true);
    }
  }, [app]);

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={handleOpen}
        onClick={handleOpen}
      >
        {children ?? (
          <Tooltip text={t('common.schedule')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-calendar-day" />
            </span>
          </Tooltip>
        )}
      </span>
      <PublishModal
        onClose={handleClose}
        onSubmit={handleSubmit}
        isVisible={isVisible}
        skipOrder={skipOrder}
        skipOrientationAuto={skipOrientationAuto}
        infoValidationSchema={infoValidationSchema}
        hidePortrait={hidePortrait}
        eventDate={app?.dependency?.config?.countTime}
        isCountDown={isCountDown}
      />
    </>
  );
};
