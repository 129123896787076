/* eslint-disable react/jsx-props-no-spreading */

import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { toast } from 'react-toastify';

import { KTSVG } from '../../lib/metronic/helpers';
import useDidUpdate from '../../utils/hooks/useDidUpdate';
import styles from './fileUploader.module.scss';
import { videoFileTypes } from '../../constants/file';
import { FileMimeType } from '../../store/types/fileManager';

const containerClassNames =
  'notice bg-light-primary rounded border-primary border p-6';
const FILE_LIMIT =
  parseInt(process.env.REACT_APP_MAX_FILE_SIZE as string, 10) || 52428800; // 50MB

const FileUploader = ({
  name,
  text,
  mutedText,
  accept,
  onChange,
  className,
  maxFiles = 1,
  containerClassName,
  id,
  defaultValue,
}: {
  name?: string;
  text?: string;
  mutedText?: string;
  accept?: string;
  onChange?: (files: File[]) => void;
  defaultValue?: File[];
  className?: string;
  maxFiles?: number;
  containerClassName?: string;
  id?: string;
}) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<File[]>(
    defaultValue ?? [],
  );
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles);
  }, []);
  const { getRootProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    accept,
  });
  const wrapFileName = (str: string) => {
    if (str.length > 10)
      return `${str?.split('.')?.reverse()?.[1]?.slice(0, 10)}....${
        str?.split('.')?.reverse()?.[0]
      }`;
    return str;
  };
  const handleInputChange = ({
    currentTarget: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const buffers = [...(files as any)] as File[];

    if (
      buffers?.some(
        ({ type, size }) =>
          !videoFileTypes.includes(type as FileMimeType) && size > FILE_LIMIT,
      )
    ) {
      toast.error(
        <div>
          <strong>
            {`Oops! ${t('file_uploader.file_size_limit')} (${
              Math.round(FILE_LIMIT / 1024 / 1024) || 50
            } MB)`}
          </strong>
        </div>,
      );
      return;
    }

    setSelectedFiles(buffers);
  };

  const handleDeleteFile = (nameAndDate: string) => {
    setSelectedFiles(
      selectedFiles.filter(
        ({ name: targetName, lastModified }) =>
          `${targetName}${lastModified}` !== nameAndDate,
      ),
    );
  };

  useDidUpdate(() => {
    if (onChange) onChange(selectedFiles);
  }, [selectedFiles]);

  // The state with selected files
  if (selectedFiles.length)
    return (
      <div
        className={clsx(
          containerClassNames,
          'd-inline-flex flex-column min-h-90px border-dashed',
          className,
        )}
        id={id}
      >
        {selectedFiles.map(({ size, name: fileName, lastModified }) => {
          const nameAndDate = `${fileName}${lastModified}`;

          return (
            <div key={nameAndDate} className="d-flex align-items-center m-auto">
              <div>
                <span>{wrapFileName(fileName)}</span>{' '}
                <strong>
                  (<span>{Math.ceil(size / 1024)}kb</span>)
                </strong>
              </div>
              <div>
                <span
                  role="button"
                  tabIndex={-1}
                  onKeyPress={() => handleDeleteFile(nameAndDate)}
                  onClick={() => handleDeleteFile(nameAndDate)}
                >
                  <span className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                    <KTSVG
                      className="svg-icon-1"
                      path="/media/icons/duotune/arrows/arr061.svg"
                    />
                  </span>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  // Empty state
  return (
    <>
      <label
        htmlFor={`fileUploader${name}${id}`}
        className={className}
        {...getRootProps()}
      >
        <div
          id={id}
          className={clsx(
            containerClassNames,
            'd-flex hoverable',
            isDragActive ? styles.containerDnd : 'border-dashed',
            containerClassName,
          )}
        >
          <KTSVG
            path="/media/icons/duotune/files/fil010.svg"
            className="svg-icon svg-icon-3hx svg-icon-primary"
          />
          <div
            className={clsx('ms-4', !mutedText && 'd-flex align-items-center')}
          >
            <h3 className="fw-bolder text-gray-900 mb-1">
              {text ?? t('file_uploader.drop_files')}
            </h3>
            {accept && (
              <span className="fw-bold fs-9 text-muted">{`${t(
                'file_uploader.accepted_formats',
              )} : ${accept}`}</span>
            )}
          </div>
        </div>
      </label>
      <input
        className="visually-hidden"
        id={`fileUploader${name}${id}`}
        type="file"
        name={name}
        accept={accept}
        onChange={handleInputChange}
        multiple={maxFiles !== 1}
      />
    </>
  );
};
export const MemoizedFileUploader = memo(FileUploader, () => true);

export default FileUploader;
