import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import api from '../../../../../api';
import SkuOrientation from '../../../../../enums/skuOrientation';
import SkuInfo from '../../../../../interfaces/skuInfo.interface';
// import CheckBox from '../../../../CheckBox';
import FileUploader from '../../../../FileUploader';
import Modal from '../../../../Modal';

import WithSpinner from '../../../../WithSpinner';

const typeOptions = [
  { value: String(SkuOrientation.Poster), label: 'POSTER' },
  { value: String(SkuOrientation.Content), label: 'LANDSCAPE' },
  { value: String(SkuOrientation.Video), label: 'VIDEO' },
  { value: 'ppt', label: 'PPT' },
  { value: 'editablePpt', label: 'PPT (EDITABLE)' },
];
// const PPTSkuOrientationOptions = [
//   { value: String(SkuOrientation.Poster), label: 'Poster' },
//   { value: String(SkuOrientation.Content), label: 'Landscape' },
// ];

const SkuSingleContent = ({
  onSubmit,
  topicId,
  topicName,
}: {
  onSubmit?: (SKU: SkuInfo) => void;
  topicName?: string;
  topicId: string;
}) => {
  const { handleSubmit } = useForm<any>();
  const { t } = useTranslation();
  const [file, setFiles] = useState<File>();
  const [skuInfos, setSkuInfos] = useState<SkuInfo>();
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState<any>();

  const [converSionLoading, setConversionLoading] = useState(false);

  const [pptFile, setPptFiles] = useState<File>();

  // const staticSelect = ['poster', 'content', 'video'];

  const handleUpload = async (selectedFile: File) => {
    if (selectedFile) {
      const filesPre: File = selectedFile;

      const img = new Image();
      const reader = new FileReader();
      const objectUrl = URL.createObjectURL(filesPre);
      img.onload = function handleLoadImage() {
        URL.revokeObjectURL(objectUrl);
      };

      img.src = objectUrl;
      reader.readAsDataURL(filesPre);
      reader.onloadend = async () => {
        const base64data = reader.result;

        setSkuInfos({
          base64File: String(base64data),
          orientation: Number(selectedType),
          imageName: filesPre.name,
          isCover: true,
          text: topicName ?? '',
          order: 0,
          metaData: {
            originalName: filesPre.name,
            base64Type: filesPre.type,
            width: img.width,
            height: img.height,
            size: String(filesPre.size),
          },
        });
      };
    }
  };

  const [pptPreview, setPptPreview] = useState<string>('');

  const startConversionJobSingleContent = async (selectedFile: File) => {
    if (selectedFile) {
      const dataImagePrefix = `data:image/jpg;base64,`;

      setConversionLoading(true);

      await api.conversionJobs
        .createConversionJobSingleContent(
          {
            file: selectedFile,
          },
          topicId,
        )
        .then((res) => {
          const filesPre = Buffer.from(res[0].data).toString('base64');
          setPptPreview(`${dataImagePrefix}${filesPre}`);

          setConversionLoading(false);

          return setSkuInfos({
            base64File: `${dataImagePrefix}${filesPre}`,
            orientation: 1,
            imageName: selectedFile.name,
            isCover: true,
            text: topicName ?? '',
            order: 0,
            isDownloadable: selectedType === 'editablePpt',
            metaData: {
              originalName: selectedFile.name,
              base64Type: dataImagePrefix,
              // width: filesPre.width,
              // height: filesPre.height,
              size: String(selectedFile.size),
            },
          });
        });
    }
  };

  const handleSubmitData = () => {
    if (onSubmit && skuInfos) {
      setLoading(true);
      onSubmit(skuInfos);
    }
  };

  const testOnSelectedValue = () => {
    if (
      selectedType === String(SkuOrientation.Poster) ||
      selectedType === String(SkuOrientation.Content) ||
      selectedType === String(SkuOrientation.Video) ||
      selectedType === String(SkuOrientation.FullScreen)
    )
      return true;
    return false;
  };

  return (
    <>
      <div className="mb-3">
        <div className="mb-2">
          <label className="fs-6 fw-bolder me-2">
            {t('library.indigo.selectType')}
          </label>
        </div>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isSearchable={false}
          name="uploadType"
          options={typeOptions}
          onChange={(val) => {
            setSelectedType(val?.value);

            if (file && skuInfos) {
              setFiles(undefined);
              setSkuInfos(undefined);
            }
          }}
          defaultValue={typeOptions[3]}
        />
      </div>
      {testOnSelectedValue() ? (
        <form className="mb-3" onSubmit={handleSubmit(handleSubmitData)}>
          <FileUploader
            name={`files ${selectedType} `}
            mutedText={t('contents.upload_image')}
            accept={
              selectedType === String(SkuOrientation.Video)
                ? 'video/mp4,video/x-m4v,video/*'
                : '.png, .jpg, .jpeg'
            }
            className="w-100 mt-2"
            onChange={(image) => {
              setFiles(image[0]);

              handleUpload(image[0]);
            }}
            containerClassName="justify-content-center"
            maxFiles={1}
            key={selectedType}
          />

          {file && (
            <WithSpinner isLoading={!skuInfos?.base64File}>
              <div className=" d-flex card border mt-2">
                <div className="card-body ">
                  <div className="row justify-content-md-center ">
                    <div className="col col-lg-10">
                      {skuInfos &&
                        (selectedType === String(SkuOrientation.Video) ? (
                          <video className="w-100 mh-320px" autoPlay muted>
                            <source
                              className="mx-1"
                              src={skuInfos?.base64File}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img
                            src={skuInfos?.base64File}
                            alt={file?.name}
                            className="mx-1 w-100 mh-320px"
                          />
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </WithSpinner>
          )}
          <Modal.Separator withoutDefaultMargins className="mb-7" />
          <div className="d-sm-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {loading ? (
                <>
                  {' '}
                  <span>
                    Uploading
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                </>
              ) : (
                <> Add Single Content </>
              )}
            </button>
          </div>
        </form>
      ) : (
        <form className="mb-3" onSubmit={handleSubmit(handleSubmitData)}>
          <div className="mb-7">
            <FileUploader
              name="filesPPT"
              mutedText="Upload PPT or PPTX"
              accept=".ppt, .pptx"
              // defaultValue={file}
              className="w-100 mt-2"
              onChange={(ppt) => {
                if (ppt) {
                  setPptFiles(ppt[0]);
                  startConversionJobSingleContent(ppt[0]);
                }
                if (!ppt) {
                  setPptFiles(undefined);
                  setPptPreview('');
                }
              }}
              containerClassName="justify-content-center"
              maxFiles={1}
              key={selectedType}
            />
          </div>

          {pptPreview?.length > 0 && (
            <div className="mb-3">
              <img
                src={pptPreview}
                className="mx-1 w-100 mh-320px"
                alt="fdcfdw"
              />
            </div>
          )}
          {pptFile && (
            <div className="mb-7">
              <WithSpinner
                isLoading={!!(converSionLoading && !pptPreview)}
                className="min-h-200px"
                size="md"
              >
                <div className="justify-content-md-center  row mt-2">
                  {/* <div className=" align-items-center col col-lg-3">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isSearchable={false}
                      name="uploadType"
                      options={PPTSkuOrientationOptions}
                      onChange={(val) => {
                        if (skuInfos)
                          setSkuInfos({
                            ...skuInfos,
                            orientation:
                              val?.value === String(SkuOrientation.Poster)
                                ? SkuOrientation.Poster
                                : SkuOrientation.Content,
                          });
                      }}
                      defaultValue={typeOptions[0]}
                      placeholder="Select Orientation"
                    />
                  </div> */}
                </div>
              </WithSpinner>
            </div>
          )}

          <Modal.Separator withoutDefaultMargins className="mb-7" />
          <div className="d-sm-flex justify-content-end">
            <button
              disabled={!pptFile || loading || converSionLoading}
              type="submit"
              className="btn btn-primary"
            >
              {loading ? (
                <>
                  {' '}
                  <span>
                    Uploading
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                </>
              ) : (
                <> Add Single Content </>
              )}
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default SkuSingleContent;
