import React from 'react';
import { useTranslation } from 'react-i18next';
import ConversionJob from '../../../../interfaces/conversionJob.interface';
import { conversionJobBadgeTypes } from '../../../../constants/conversionJob';
import commonStyles from '../../../../styles/common.module.scss';
import { formatDateString } from '../../../../utils/dateTime';
import daysOfWeek from '../../../../constants/daysOfWeek';
import styles from '../TableRow/tableRow.module.scss';
import ShowMoreCell from '../../../ShowMoreCell';
import CheckBox from '../../../CheckBox';
import Table from '../../../Table';
import Badge from '../../../Badge';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hourCycle: 'h12',
});

const formatTime = new Intl.DateTimeFormat(navigator.language, {
  minute: 'numeric',
  second: 'numeric',
});

interface TableRowProps {
  conversionJob: ConversionJob;
  config?: {
    skipActions?: boolean;
    disableLinks?: boolean;
    isSelectable?: boolean;
  };
}

interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
}

export default ({ conversionJob, config }: DefaultProps) => {
  const { t } = useTranslation();
  if (
    !conversionJob ||
    !conversionJob.content ||
    !conversionJob.content?.duration
  )
    return null;

  const { content } = conversionJob;
  const { duration, startDate, endDate, weekDays } = content;

  return (
    <Row className={styles.row} key={content.id}>
      {config.isSelectable && (
        <Cell className="w-30px pe-0">
          <CheckBox
            name="contentId"
            id={content.id}
            checked={false}
            className={commonStyles.disabled}
            readOnly
          />
        </Cell>
      )}
      <Cell isNoWrap>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50px w-55px me-5 d-flex justify-content-center">
            <i className="fas fa-sync fa-spin fa-lg" />
          </div>
          {conversionJob.originalName}
        </div>
      </Cell>
      <Cell className="text-center" isNoWrap isEmpty={!duration}>
        <span className="text-dark fw-bold d-block mb-1 fs-6">
          {duration && formatTime.format(duration * 1000)}
        </span>
      </Cell>
      <Cell className="text-center" isNoWrap isEmpty={!startDate}>
        <span className="text-dark fw-bold d-block mb-1 fs-6">
          {startDate && formatDate(startDate)}
        </span>
      </Cell>
      <Cell className="text-center" isNoWrap isEmpty={!endDate}>
        <span className="text-dark fw-bold d-block mb-1 fs-6">
          {endDate && formatDate(endDate)}
        </span>
      </Cell>
      <Cell className="text-center">
        <div className="d-flex flex-wrap justify-content-evenly">
          {weekDays?.length &&
            daysOfWeek.map((day, index) => (
              <div className={weekDays.includes(index) ? styles.activeDay : ''}>
                <span className="text-dark fw-bold d-block mb-3 fs-6">
                  {day}
                </span>
              </div>
            ))}
        </div>
      </Cell>
      <Cell className="text-center" isNoWrap isEmpty={!content.tags?.length}>
        <div className=" d-flex justify-content-center align-items-center">
          <ShowMoreCell
            itemNames={content?.tags as string[]}
            title={t('playlist.tags')}
            withModal
          >
            {content?.tags?.map((itemName: string) => (
              <ShowMoreCell.Tags itemName={itemName} />
            ))}
          </ShowMoreCell>
        </div>
      </Cell>
      <Cell isNoWrap isTextEnd>
        <Badge type={conversionJobBadgeTypes[conversionJob.status]}>
          <span style={{ textTransform: 'uppercase' }}>
            {conversionJob.status}...
          </span>
        </Badge>
      </Cell>
    </Row>
  );
};
