import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DevicesTable from '../../../components/AssignmentTables/DevicesTable';
import { defaultDebounceValue } from '../../../constants/api/debounceSettings';
import Device from '../../../interfaces/devices/device.interface';
import User from '../../../interfaces/user/user.interface';
import api from '../../../api';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';

export default ({
  onSelectionChange,
  preselectedItems,
  currentUser,
  selectedCompaniesIds,
  isForPublish,
  libraryPublish,
  skipLicence,
  copyContent,
  isPowerBiPublished,
}: {
  onSelectionChange: (
    selectedItems: string[],
    selectedPlaylists?: string[],
  ) => void;
  preselectedItems: string[];
  currentUser?: User;
  selectedCompaniesIds?: string[];
  isForPublish?: boolean;
  libraryPublish?: boolean;
  skipLicence?: boolean;
  copyContent?: boolean;
  isPowerBiPublished?: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [devices, setDevices] = useState<Device[]>([]);
  const [total, setTotal] = useState<number>(0);
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const isSuperAdmin = roles.includes(UserRole.SuperAdmin);

  const page = useParams<{ page: string }>();
  const currentUserIds =
    isForPublish || (page.page !== 'devicesGroup' && isSuperAdmin)
      ? null
      : useParams<{ id: string }>();

  const fetchDevicesList = useCallback(
    debounce(async (searchData: { [key: string]: string }) => {
      try {
        const { items, meta } = isSuperAdmin
          ? await api.devices.getAdminDevicesList({
              ...searchData,
              currentUserId: currentUser?.id ?? currentUserIds?.id,
              companyIds: selectedCompaniesIds ?? [],
            })
          : await api.devices.getDevicesList({
              ...searchData,
              companyIds: selectedCompaniesIds ?? [],
            });

        setDevices(items);
        setTotal(meta.totalItems);
      } catch (e) {
        // Do nothing
      }

      setLoading(false);
    }, defaultDebounceValue),
    [],
  );

  useEffect(() => {
    setLoading(true);
    fetchDevicesList(filters);
  }, [filters]);

  return (
    <DevicesTable
      skipLicence={skipLicence}
      copyContent={copyContent}
      createDeviceGroupTarget
      isLoading={loading}
      devices={devices}
      total={total}
      onSearchDataChange={setFilters}
      onSelectionChange={onSelectionChange}
      preselectedItems={preselectedItems}
      libraryPublish={libraryPublish}
      isPowerBiPublished={isPowerBiPublished}
    />
  );
};
