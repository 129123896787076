/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../../Modal';
// import { readFromQueryString } from '../../../utils/queryParams';
import { prepareHookFromState } from '../../../utils/hooks/useStateWithHookForm';

// import SelectLocation from '../../../containers/SelectLocation';
import validation from '../../../utils/validation';
import License from '../../../interfaces/license.interface';
import Device from '../../../interfaces/devices/device.interface';
import LicensingForm, { LicensingFormInputs } from '../LicensingForm';
import WithSpinner from '../../WithSpinner';
import Tooltip from '../../Tooltip';

export default ({
  onSubmit,
  isLoading,
  license,
  device,
  isAdmin,
  children,
  isCloseModal,
  skipEditModalDeviceGroup,
}: {
  children: React.ReactNode;
  isAdmin?: boolean;
  onSubmit: (data: Partial<LicensingFormInputs>) => void;
  isLoading?: boolean;
  license?: License;
  isCloseModal?: boolean;
  device?: Device;
  skipEditModalDeviceGroup?: boolean;
}) => {
  const { t } = useTranslation();
  const defaultValues: Partial<LicensingFormInputs> = {
    licenseTypeId: license?.licenseType?.id ?? '',
    isLibrary: !!license?.isLibrary,
    isActive: !!license?.isActive,
    isTrial: !!license?.isTrial,
    billingRate: license?.billingRate,
    licenseTerm: license?.licenseTerm,
    isLibraryTrial: !!license?.isLibraryTrial,
    startDate: license?.startDate,
    expireDate: license?.expireDate,
    libraryAccessStartDate: license?.libraryAccessStartDate || undefined,
    libraryAccessExpireDate: license?.libraryAccessExpireDate || undefined,
  };
  const {
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<Partial<LicensingFormInputs>>({
    resolver: yupResolver(
      validation.object().shape(
        {
          licenseTypeId: validation.string(),
          startDate: validation.date(),
          expireDate: validation
            .date()

            .test(
              'isAfter',
              t('common.endDateIsGreaterThanStartDate'),
              // eslint-disable-next-line func-names
              function (value) {
                // eslint-disable-next-line react/no-this-in-sfc
                const { startDate } = this.parent;
                return !value || !startDate || value > startDate;
              },
            ),
          libraryAccessStartDate: validation
            .date()
            .when(['isLibrary', 'isActive'], {
              is: (isLibrary?: boolean, isActive?: boolean) =>
                isLibrary && isActive,
              then: validation.date(),
              otherwise: validation.date(),
            }),
          libraryAccessExpireDate: validation
            .date()
            .when(['isLibrary', 'isActive'], {
              is: (isLibrary?: boolean, isActive?: boolean) =>
                isLibrary && isActive,
              then: validation.date(),
              otherwise: validation.date(),
            })
            .test(
              'isAfter',
              t('common.endDateIsGreaterThanStartDate'),
              // eslint-disable-next-line func-names
              function (value) {
                // eslint-disable-next-line react/no-this-in-sfc
                const { libraryAccessStartDate } = this.parent;
                return (
                  !value ||
                  !libraryAccessStartDate ||
                  value > libraryAccessStartDate
                );
              },
            ),
        },
        [
          ['libraryAccessStartDate', 'isLibrary'],
          ['libraryAccessExpireDate', 'isLibrary'],
        ],
      ),
    ),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<LicensingFormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [licenseTypeId, setLicenseType] =
    useFs<LicensingFormInputs['licenseTypeId']>('licenseTypeId');
  const [startDate, setStartDate] =
    useFs<LicensingFormInputs['startDate']>('startDate');
  const [expireDate, setEndDate] =
    useFs<LicensingFormInputs['expireDate']>('expireDate');
  const [libraryAccessStartDate, setLibraryStartDate] = useFs<
    LicensingFormInputs['libraryAccessStartDate']
  >('libraryAccessStartDate');
  const [libraryAccessExpireDate, setLibraryEndDate] = useFs<
    LicensingFormInputs['libraryAccessExpireDate']
  >('libraryAccessExpireDate');
  const [isActive, setIsActive] =
    useFs<LicensingFormInputs['isActive']>('isActive');
  const [isTrial, setIsTrial] =
    useFs<LicensingFormInputs['isTrial']>('isTrial');
  const [billingRate, setBillingRate] =
    useFs<LicensingFormInputs['billingRate']>('billingRate');
  const [licenseTerm, setLicenseTerm] =
    useFs<LicensingFormInputs['licenseTerm']>('licenseTerm');
  const [isLibraryTrial, setIsLibraryTrial] =
    useFs<LicensingFormInputs['isLibraryTrial']>('isLibraryTrial');
  const [isLibrary, setIsLibrary] =
    useFs<LicensingFormInputs['isLibrary']>('isLibrary');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const resetForm = () => {
    setLicenseType('');
    setStartDate(undefined);
    setEndDate(undefined);
    setLibraryStartDate(undefined);
    setLibraryEndDate(undefined);
    setIsActive(false);
    setIsTrial(false);
    setBillingRate(undefined);
    setLicenseTerm(undefined);
    setIsLibraryTrial(false);
    setIsLibrary(false);
    reset(defaultValues);
  };
  const open = () => {
    setIsModalVisible(true);
  };
  const handleClose = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    if (isCloseModal) {
      handleClose();
    }
  }, [isCloseModal]);
  useEffect(() => {
    if (handleSubmit(onSubmit)) {
      resetForm();
      handleClose();
    }
  }, [onSubmit]);
  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>

      <Modal
        title={t('devices.licensing.BulkEditlicensing')}
        widthDefaultSize={false}
        show={isModalVisible}
        onHide={handleClose}
        bodyClassName="py-5"
        withFooter={false}
      >
        <WithSpinner isLoading={isLoading as boolean} className="min-h-600px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <LicensingForm
              device={device}
              errors={errors}
              fieldsConfig={{
                isActive,
                setIsActive,
                isTrial,
                setIsTrial,
                licenseTypeId,
                setLicenseType,
                startDate,
                setStartDate,
                expireDate,
                setEndDate,
                billingRate,
                setBillingRate,
                isLibrary,
                setIsLibrary,
                licenseTerm,
                setLicenseTerm,
                isLibraryTrial,
                setIsLibraryTrial,
                libraryAccessStartDate,
                setLibraryStartDate,
                libraryAccessExpireDate,
                setLibraryEndDate,
              }}
            />

            <Modal.Separator withoutDefaultMargins className="mt-6 mb-7" />
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {t('common.submit')}
              </button>
            </div>
          </form>
        </WithSpinner>
      </Modal>
    </>
  );
};
