import React, { useCallback, useEffect } from 'react';

import api from '../../api';
import Banner from '../../interfaces/banner.interface';
import BannerItem from './BannerItem';

export default () => {
  const [banners, setBanners] = React.useState<Banner[] | null>([]);
  const getBanner = useCallback(async () => {
    try {
      const data = await api.banner.getCurrentlyActiveBanner();
      setBanners(data);
    } catch (e) {
      // Do nothing for now
    }
  }, []);

  useEffect(() => {
    getBanner();
  }, []);
  return (
    <>
      {banners &&
        banners?.map((banner) => <BannerItem item={banner} key={banner.id} />)}
    </>
  );
};
