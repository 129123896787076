import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce, map } from 'lodash';
import { useTranslation } from 'react-i18next';
import IPlaylist from '../../../interfaces/playlist.interface';
import Devices from '../../../components/Devices';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import { defaultDebounceValue } from '../../../constants/api/debounceSettings';
import DeviceAssignmentTarget from '../../../enums/deviceAssignmentTarget.enum';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import Device from '../../../interfaces/devices/device.interface';
import UpdateDeviceRequestData from '../../../api/endpoints/devices/interfaces/updateDeviceRequestData.interface';
import BulkUpdateDevicesRequestDataInterface from '../../../api/endpoints/devices/interfaces/bulkUpdateDevicesRequestData.interface';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
import api from '../../../api';
import GlobalState from '../../../store/reducers/globalState.interface';

export default ({ playlist }: { playlist: IPlaylist }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const user = useSelector((state: GlobalState) => state.userData.user);
  const [isLoading, setLoading] = useState(true);
  const [devices, setDevices] = useState<Device[]>([]);
  const [total, setTotal] = useState(0);

  const [isAdmin, isFethingRole] = useIsAdmin();

  const fetchDevicesList = debounce(async (recentSearch: string) => {
    setLoading(true);
    try {
      const {
        items,
        meta: { totalItems },
      } = isAdmin
        ? await api.devices.getAdminDevicesList({
            ...readAllFromQueryParams(recentSearch),
            playlistId: playlist.id,
          })
        : await api.devices.getDevicesList({
            ...readAllFromQueryParams(recentSearch),
            playlistId: playlist.id,
          });
      setDevices(items);
      setTotal(totalItems);
    } finally {
      setLoading(false);
    }
  }, defaultDebounceValue);

  const handleAssignDevices = (data: SelectedValue[]) => {
    const devicesIds = map(data, 'value').filter(Boolean) as string[];

    if (!devicesIds.length) return;

    setLoading(true);

    api.playlists
      .assignDevicesToPlaylist(playlist.id, devicesIds)
      .then(() => fetchDevicesList(search))
      .catch(() => setLoading(false));
  };

  const handleUnAssignDevice = (deviceId: string) => {
    setLoading(true);

    api.devices
      .unAssignDeviceFromPlaylist(deviceId, playlist.id)
      .then(() => fetchDevicesList(search))
      .catch(() => setLoading(false));
  };

  const handleEditDevice = ({
    id,
    data,
  }: {
    id: string;
    data: UpdateDeviceRequestData;
  }) => {
    setLoading(true);

    api.devices
      .updateDevice(data, id)
      .then(() => fetchDevicesList(search))
      .catch(() => setLoading(false));
  };

  const handleBulkEditDevice = async (
    data: Partial<BulkUpdateDevicesRequestDataInterface>,
  ) => {
    setLoading(true);

    try {
      if (isAdmin) {
        await api.devices.bulkUpdateAdminDevices(
          data as BulkUpdateDevicesRequestDataInterface,
        );
      } else {
        await api.devices.bulkUpdateDevices(data);
      }

      fetchDevicesList(search);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isFethingRole) {
      fetchDevicesList(search);
    }
  }, [search, isFethingRole]);
  return (
    <Devices
      assignDevice={handleAssignDevices}
      unAssignDevice={handleUnAssignDevice}
      deviceAssignmentTarget={{
        type: DeviceAssignmentTarget.Playlist,
        id: playlist.id,
      }}
      onEditDevice={handleEditDevice}
      onBulkEditDevice={handleBulkEditDevice}
      onUpdateLicense={() => fetchDevicesList(search)}
      title={t('devices.devices')}
      devices={devices}
      total={total}
      isLoading={isLoading}
      withDeleteAction
      resetOnSearch
      user={user}
    />
  );
};
