import {
  LiveStreamFeedsReduxAction,
  LIVE_STREAM_FEED_ACTIONS,
} from '../../actions/apps/liveStreamFeed';

import { ErrorResponse } from '../../types/apps/globalTypes';
import { PaginatedLiveStreamFeeds } from '../../types/apps/liveStreamFeed';

export interface LiveStreamFeedsState {
  error: ErrorResponse | null;
  data: PaginatedLiveStreamFeeds;
  loading: boolean;
  isGetLiveStreamFeedsSuccess: boolean;
  createLiveStreamFeedLoading: boolean;
  isCreateLiveStreamFeedSuccess: boolean;
  scheduleLiveStreamFeedLoading: boolean;
  isScheduleLiveStreamFeedSuccess: boolean;
  deleteLiveStreamFeedLoading: boolean;
  isDeleteLiveStreamFeedSuccess: boolean;
  editLiveStreamFeedLoading: boolean;
  isEditLiveStreamFeedSuccess: boolean;
  isCreateScheduleLiveStreamFeedSuccess: boolean;
}

const initialState: LiveStreamFeedsState = {
  error: null,

  data: {
    items: [],
    // links: { first: '', previous: '', next: '', last: '' },
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 10,
      // totalPages: 0,
      currentPage: 1,
    },
  },
  loading: false,
  isGetLiveStreamFeedsSuccess: false,
  createLiveStreamFeedLoading: false,
  isCreateLiveStreamFeedSuccess: false,
  scheduleLiveStreamFeedLoading: false,
  isScheduleLiveStreamFeedSuccess: false,
  deleteLiveStreamFeedLoading: false,
  isDeleteLiveStreamFeedSuccess: false,
  editLiveStreamFeedLoading: false,
  isEditLiveStreamFeedSuccess: false,
  isCreateScheduleLiveStreamFeedSuccess: false,
};

export default function LiveStreamFeedsReducer(
  state = initialState,
  action: LiveStreamFeedsReduxAction<LIVE_STREAM_FEED_ACTIONS>,
): LiveStreamFeedsState {
  const { payload } = action;

  switch (action.type) {
    // GET
    case LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_LOADING:
      return {
        ...state,
        loading: payload.loading,
      };

    case LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_SUCCESS:
      return {
        ...state,
        loading: payload.loading,
        data: payload.data,
        isGetLiveStreamFeedsSuccess: payload.isGetLiveStreamFeedsSuccess,
      };

    case LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_ERROR:
      return {
        ...state,
        loading: payload.loading,
        error: payload.error,
        isGetLiveStreamFeedsSuccess: payload.isGetLiveStreamFeedsSuccess,
      };

    case LIVE_STREAM_FEED_ACTIONS.GET_LIVE_STREAM_FEEDS_RESET:
      return {
        ...state,
        data: payload.data,
        loading: payload.loading,
        error: payload.error,
        isGetLiveStreamFeedsSuccess: payload.isGetLiveStreamFeedsSuccess,
      };

    // CREATE HTML FEED
    case LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_LOADING:
      return {
        ...state,
        createLiveStreamFeedLoading: payload.loading,
      };

    case LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_SUCCESS:
      return {
        ...state,
        createLiveStreamFeedLoading: payload.loading,
        isCreateLiveStreamFeedSuccess: payload.isCreateLiveStreamFeedSuccess,
      };

    case LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_ERROR:
      return {
        ...state,
        createLiveStreamFeedLoading: payload.loading,
        error: payload.error,
        isCreateLiveStreamFeedSuccess: payload.isCreateLiveStreamFeedSuccess,
      };

    case LIVE_STREAM_FEED_ACTIONS.CREATE_LIVE_STREAM_FEED_RESET:
      return {
        ...state,
        createLiveStreamFeedLoading: payload.loading,
        error: payload.error,
        isCreateLiveStreamFeedSuccess: payload.isCreateLiveStreamFeedSuccess,
      };

    // SCHEDULE HTML FEED
    case LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_LOADING:
      return {
        ...state,
        scheduleLiveStreamFeedLoading: payload.loading,
      };

    case LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_SUCCESS:
      return {
        ...state,
        scheduleLiveStreamFeedLoading: payload.loading,
        isScheduleLiveStreamFeedSuccess:
          payload.isScheduleLiveStreamFeedSuccess,
      };

    case LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_ERROR:
      return {
        ...state,
        scheduleLiveStreamFeedLoading: payload.loading,
        error: payload.error,
        isScheduleLiveStreamFeedSuccess:
          payload.isScheduleLiveStreamFeedSuccess,
      };

    case LIVE_STREAM_FEED_ACTIONS.SCHEDULE_LIVE_STREAM_FEED_RESET:
      return {
        ...state,
        scheduleLiveStreamFeedLoading: payload.loading,
        error: payload.error,
        isScheduleLiveStreamFeedSuccess:
          payload.isScheduleLiveStreamFeedSuccess,
      };

    // DELETE HTML FEED
    case LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_LOADING:
      return {
        ...state,
        deleteLiveStreamFeedLoading: payload.loading,
      };

    case LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_SUCCESS:
      return {
        ...state,
        deleteLiveStreamFeedLoading: payload.loading,
        isDeleteLiveStreamFeedSuccess: payload.isDeleteLiveStreamFeedSuccess,
      };

    case LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_ERROR:
      return {
        ...state,
        deleteLiveStreamFeedLoading: payload.loading,
        error: payload.error,
        isDeleteLiveStreamFeedSuccess: payload.isDeleteLiveStreamFeedSuccess,
      };

    case LIVE_STREAM_FEED_ACTIONS.DELETE_LIVE_STREAM_FEED_RESET:
      return {
        ...state,
        deleteLiveStreamFeedLoading: payload.loading,
        error: payload.error,
        isDeleteLiveStreamFeedSuccess: payload.isDeleteLiveStreamFeedSuccess,
      };

    // EDIT HTML FEED
    case LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_LOADING:
      return {
        ...state,
        editLiveStreamFeedLoading: payload.loading,
      };

    case LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_SUCCESS:
      return {
        ...state,
        editLiveStreamFeedLoading: payload.loading,
        isEditLiveStreamFeedSuccess: payload.isEditLiveStreamFeedSuccess,
      };

    case LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_ERROR:
      return {
        ...state,
        editLiveStreamFeedLoading: payload.loading,
        error: payload.error,
        isEditLiveStreamFeedSuccess: payload.isEditLiveStreamFeedSuccess,
      };

    case LIVE_STREAM_FEED_ACTIONS.EDIT_LIVE_STREAM_FEED_RESET:
      return {
        ...state,
        editLiveStreamFeedLoading: payload.loading,
        error: payload.error,
        isEditLiveStreamFeedSuccess: payload.isEditLiveStreamFeedSuccess,
      };

    default:
      return state;
  }
}
