import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import UsersActions from '../actions/users/usersActions.enum';
import GetUsersListAction from '../actions/users/interfaces/getUsersListAction.interface';
import GetUsersListResponseData from '../../api/endpoints/users/interfaces/getUsersListResponseData.interface';
import RestoreUserAction from '../actions/users/interfaces/restoreUserAction.interface';
import SuspendUserAction from '../actions/users/interfaces/suspendUserAction.interface';
import UpdateUserAction from '../actions/users/interfaces/updateUserAction.interface';
import CreateUserAction from '../actions/users/interfaces/createUserAction.interface';
import { readAllFromQueryParams } from '../../utils/queryParams';
import { createFormData } from '../../utils/formData';
import history from '../../constants/history';
import api from '../../api';
import {
  getUsersList,
  setUsersLoading,
  getUsersListSuccess,
  getAdminUsersListSuccess,
} from '../actions/users';

/**
 * The users retrieval worker
 * @param payload
 */
function* getUsersRequestWorker({ payload }: GetUsersListAction) {
  yield put(setUsersLoading({ loading: true }));

  try {
    const responseData: GetUsersListResponseData = yield call(
      api.users.getUsersListV2,
      payload,
    );

    yield put(getUsersListSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setUsersLoading({ loading: false }));
}

function* getAdminUsersRequestWorker({ payload }: GetUsersListAction) {
  yield put(setUsersLoading({ loading: true }));

  try {
    const responseData: GetUsersListResponseData = yield call(
      api.users.getAdminUsersList,
      payload,
    );

    yield put(getAdminUsersListSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setUsersLoading({ loading: false }));
}

/**
 * The user update worker
 * @param payload
 */
function* updateUserRequestWorker({ payload }: UpdateUserAction) {
  yield put(setUsersLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.users.updateUser, payload.data, payload.id);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(getUsersList(readAllFromQueryParams(history.location.search)));
  } else {
    yield put(setUsersLoading({ loading: false }));
  }
}

/**
 * The user suspend worker
 * @param payload
 */
function* suspendUserRequestWorker({ payload }: SuspendUserAction) {
  yield put(setUsersLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.users.suspendUser, payload.id);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(getUsersList(readAllFromQueryParams(history.location.search)));
  } else {
    yield put(setUsersLoading({ loading: false }));
  }
}

/**
 * Worker for user restore
 * @param payload
 */
function* restoreUserRequestWorker({ payload }: RestoreUserAction) {
  yield put(setUsersLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.users.restoreUser, payload.id);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(getUsersList(readAllFromQueryParams(history.location.search)));
  } else {
    yield put(setUsersLoading({ loading: false }));
  }
}

/**
 * The user creation worker
 * @param payload
 */
function* createUserRequestWorker({ payload }: CreateUserAction) {
  yield put(setUsersLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.users.createUser, createFormData(payload, true));
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(getUsersList(readAllFromQueryParams(history.location.search)));
    toast.success(i18next.t<string>('users.created_message'));
  } else {
    yield put(setUsersLoading({ loading: false }));
  }
}

export default function* usersWatcher() {
  yield takeEvery<GetUsersListAction>(
    UsersActions.GET_USERS_LIST,
    getUsersRequestWorker,
  );

  yield takeEvery<GetUsersListAction>(
    UsersActions.GET_ADMIN_USERS_LIST,
    getAdminUsersRequestWorker,
  );

  yield takeEvery<UpdateUserAction>(
    UsersActions.UPDATE_USER,
    updateUserRequestWorker,
  );

  yield takeEvery<SuspendUserAction>(
    UsersActions.SUSPEND_USER,
    suspendUserRequestWorker,
  );

  yield takeEvery<RestoreUserAction>(
    UsersActions.RESTORE_USER,
    restoreUserRequestWorker,
  );

  yield takeEvery<CreateUserAction>(
    UsersActions.CREATE_USER,
    createUserRequestWorker,
  );
}
