import errorsInterceptor from './interceptors/errors.interceptor';
import authorizationInterceptor from './interceptors/authorization.interceptor';
import mockDataInterceptor from './interceptors/mockData.interceptor';
import devicesGroups from './endpoints/devicesGroups';
import usersGroups from './endpoints/usersGroups';
import locations from './endpoints/locations';
import timezones from './endpoints/timezones';
import playlists from './endpoints/playlists';
import players from './endpoints/players';
import userData from './endpoints/userData';
import contents from './endpoints/contents';
import devices from './endpoints/devices';
import companies from './endpoints/companies';
import customers from './endpoints/customers';
import counties from './endpoints/counties';
import countries from './endpoints/countries';
import users from './endpoints/users';
import roles from './endpoints/roles';
import tags from './endpoints/tags';
import apps from './endpoints/apps';
import powerBi from './endpoints/apps/powerBi';
import canva from './endpoints/apps/canva';
import account from './endpoints/account';
import layouts from './endpoints/layouts';
import templates from './endpoints/templates';
import states from './endpoints/states';
import libraries from './endpoints/libraries';
import industries from './endpoints/industries';
import libraryCategories from './endpoints/libraryCategories';
import customFeeds from './endpoints/customFeeds';
import conversionJobs from './endpoints/conversionJobs';
import licenses from './endpoints/licenses';
import fileManager from './endpoints/fileManager';
import usersLogs from './endpoints/usersLogs';
import dashboard from './endpoints/dashboard';
import banner from './endpoints/banner/banner';

mockDataInterceptor();
errorsInterceptor();
authorizationInterceptor();

export default {
  account,
  users,
  customers,
  devices,
  companies,
  counties,
  countries,
  devicesGroups,
  usersGroups,
  locations,
  timezones,
  playlists,
  userData,
  contents,
  players,
  roles,
  tags,
  apps,
  powerBi,
  canva,
  layouts,
  templates,
  states,
  customFeeds,
  libraries,
  libraryCategories,
  industries,
  conversionJobs,
  licenses,
  fileManager,
  usersLogs,
  dashboard,
  banner,
};
