import * as React from 'react';
import { Cell, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import { LiveStreamFeedSchema } from '../../../store/types/apps/liveStreamFeed';
import { formatDateString } from '../../../utils/dateTime';
import Tooltip from '../../../components/Tooltip';

interface UseColumnsProps {
  clickOnLiveStreamFeed: (feed: LiveStreamFeedSchema) => void;
  openEditLiveStreamFeedModal: (feed: LiveStreamFeedSchema) => void;
  openDeleteLiveStreamFeedModal: (feed: LiveStreamFeedSchema) => void;
  openActionsDropdown: (feed: LiveStreamFeedSchema) => void;
  openScheduleLiveStreamFeedModal: (feed: LiveStreamFeedSchema) => void;
  selectedLiveStreamFeed: LiveStreamFeedSchema | null;
}

export default function useColumns({
  clickOnLiveStreamFeed,
  openEditLiveStreamFeedModal,
  openDeleteLiveStreamFeedModal,
  openActionsDropdown,
  openScheduleLiveStreamFeedModal,
  selectedLiveStreamFeed,
}: UseColumnsProps): Column<LiveStreamFeedSchema>[] {
  const { t } = useTranslation();

  const [isActionDropdownOpen, setIsActionDropdownOpen] =
    React.useState<boolean>(false);

  const handleToggleIsActionDropdownOpen = React.useCallback(
    () => setIsActionDropdownOpen(!isActionDropdownOpen),
    [isActionDropdownOpen],
  );

  const formatDate = React.useCallback(
    () =>
      formatDateString({
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        minute: '2-digit',
        hour: 'numeric',
      }),
    [],
  );

  return React.useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name',
        Cell: ({ value, row }: Cell<LiveStreamFeedSchema>) => {
          const { original: feedData } = row;

          return (
            <div
              className="d-flex align-items-center"
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => clickOnLiveStreamFeed(feedData)}
            >
              <span className="text-gray-800 text-hover-primary">{value}</span>
            </div>
          );
        },
      },
      {
        Header: 'DATE CREATED',
        accessor: 'createdAt',
        Cell: ({ value }: Cell<LiveStreamFeedSchema>) => {
          const formatDateRef = formatDate();
          return <time dateTime={value}>{formatDateRef(value)}</time>;
        },
      },
      {
        Header: 'ACTIONS',
        className: 'text-right pr-7 bg-danger',
        Cell: ({ row }: Cell<LiveStreamFeedSchema>) => {
          const feed = row.original;

          return (
            <div className="d-flex justify-content-end">
              <div className="mx-1">
                <Tooltip text={t('common.schedule')}>
                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => openScheduleLiveStreamFeedModal(feed)}
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <i className="fa fa-calendar-day" />
                  </span>
                </Tooltip>
              </div>

              <div className="mx-1">
                <Tooltip text={t('common.edit')}>
                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => openEditLiveStreamFeedModal(feed)}
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <i className="fa fa-edit" />
                  </span>
                </Tooltip>
              </div>

              <div className="mx-1">
                <Tooltip text={t('common.delete')}>
                  <span
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => openDeleteLiveStreamFeedModal(feed)}
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                  >
                    <i className="fa fa-trash" />
                  </span>
                </Tooltip>
              </div>
            </div>
          );
        },
      },
    ],
    [
      clickOnLiveStreamFeed,
      formatDate,
      handleToggleIsActionDropdownOpen,
      isActionDropdownOpen,
      openActionsDropdown,
      selectedLiveStreamFeed,
    ],
  );
}
