import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import { TickerSpeedTypes } from './tickerSpeedTypes.interface';

const SelectTickerSpeed = ({
  onChange,
  initialValue,
  id,
  className,
  isClearable,
}: {
  onChange: (selectedValue: SelectedValue) => void;
  initialValue?: SelectedValue;
  id?: string;
  className?: string;
  isClearable?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <ReactSelect
      id={id}
      initialValue={initialValue}
      defaultValue={initialValue}
      onChange={(value) => {
        if (value) {
          onChange(value);
        }
      }}
      options={TickerSpeedTypes}
      className={className}
      placeholder={t('tickers.addTicker.selectTickerSpeed')}
      isClearable={isClearable}
      isMulti={false}
    />
  );
};

export default SelectTickerSpeed;
