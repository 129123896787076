import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { toast } from 'react-toastify';
import styles from './facebook.module.scss';
import facebookLogo from '../../../../assets/images/apps/Facebook.png';
import { FACEBOOK_APP_ID } from '../../../../constants/dotenvconst';
import WithSpinner from '../../../WithSpinner';

interface getFacebookLoginData {
  accessToken: string;
  userID: string;
  expiresIn: Number;
  graphDomain: string;
  signedRequest: string;
}

export interface CreateFeedModalProps {
  loading?: boolean;
  onSuccess?: (accessToken: string, userID: string) => void;
}
export default ({ loading, onSuccess }: CreateFeedModalProps) => {
  const { t } = useTranslation();
  const [facebookLoginData, setFacebookLoginData] =
    useState<getFacebookLoginData>();
  /* const [feeds, setFeeds] = React.useState<any>([]); */

  const [isLoading, setLoading] = useState<boolean>(false);

  const responseFacebookOnLoginSuccess = (response: any) => {
    setFacebookLoginData(response);
  };
  const responseFacebookOnLoginFailure = (response: any) => {
    console.log(response);
    toast.error('Something went wrong please try again later');
  };

  React.useEffect(() => {
    if (loading) setLoading(loading);
    else setLoading(false);
  }, [loading]);

  React.useEffect(() => {
    if (facebookLoginData && onSuccess) {
      onSuccess(facebookLoginData.accessToken, facebookLoginData.userID);
    }
  }, [facebookLoginData]);

  return (
    <div className="container ps-0 mx-auto py-auto">
      <div className="d-sm-flex flex-column justify-content-between">
        <img
          src={facebookLogo}
          alt="facebook"
          className={clsx('mb-4', styles.facebookImage)}
        />
        <h1 className="text-center text-dark mb-4">
          {t('apps.facebook.title')}
        </h1>
        <WithSpinner isLoading={isLoading} size="md">
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            autoLoad={false}
            scope="public_profile,pages_show_list,pages_read_engagement"
            fields="name"
            onSuccess={responseFacebookOnLoginSuccess}
            onFail={responseFacebookOnLoginFailure}
            className={clsx(
              styles.facebookLogin,
              'd-sm-flex justify-content-center w-50 mx-auto',
            )}
          />
        </WithSpinner>
      </div>
    </div>
  );
};
