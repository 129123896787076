import React from 'react';
import { useTranslation } from 'react-i18next';
import Orientation from '../../enums/orientation.enum';
import { orientationsTranslation } from '../../constants/translation/orientations';
import Select from '../../components/Select/RegularSelect';
import Playlist from '../../interfaces/playlist.interface';

const SelectOrientation = ({
  orientation,
  setOrientation,
  id,
  placeholder,
  isDisabled,
  className,
  playlist,
}: {
  setOrientation: (orientation: Orientation) => void;
  orientation?: Orientation;
  id?: string;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
  playlist: Playlist;
}) => {
  const { t } = useTranslation();

  const getOrientations = (filterOrientation: Orientation) => {
    return !playlist?.template
      ? filterOrientation === Orientation.FullScreen
      : filterOrientation;
  };

  const isPortrait = [
    t('playlists.layouts.default_layout_2'),
    t('playlists.layouts.default_layout_3'),
  ].includes(playlist?.template?.layout.name ?? '');

  return (
    <Select<Orientation>
      className={className}
      isDisabled={isDisabled}
      id={id}
      defaultValue={
        orientation && {
          label: t(orientationsTranslation[orientation]),
          value: orientation,
        }
      }
      placeholder={placeholder}
      onSelect={(selectedOrientation) =>
        setOrientation(selectedOrientation as Orientation)
      }
      options={Object.values(Orientation)
        .filter((filterOrientation) => getOrientations(filterOrientation))
        .map((orientationValue) => ({
          label: t(orientationsTranslation[orientationValue]),
          value: orientationValue,
          isDisabled: orientationValue === Orientation.Portrait && isPortrait,
        }))}
    />
  );
};

export default SelectOrientation;
