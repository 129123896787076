import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { getPlaylists } from '../../store/actions/playlists';
import {
  readAllFromQueryParams,
  readFromQueryString,
} from '../../utils/queryParams';
import GlobalState from '../../store/reducers/globalState.interface';
import { defaultDebounceValue } from '../../constants/api/debounceSettings';
import useLoading from '../../utils/hooks/useLoading';
import Playlists from '../../components/Playlists';
import api from '../../api';
import PlaylistsQueryParam from '../../api/endpoints/playlists/enums/playlistsQueryParam.enum';
import {
  setPlaceHolder,
  setQueryParams,
} from '../../store/actions/breadcrumbs';
import config from '../../routing/config';
import useIsAdmin from '../../utils/hooks/useIsAdmin';

export default () => {
  const { search } = useLocation();
  const [deviceName, setDeviceName] = useState<string | undefined>('');
  const dispatch = useDispatch();
  const [appTypes, setAppTypes] = useState<string[]>([]);
  const deviceIds = readFromQueryString<string[]>(
    search,
    PlaylistsQueryParam.DeviceIds,
    true,
  );
  const isLoading = useSelector(
    (state: GlobalState) => state.playlists.loading,
  );
  const playlists = useSelector(
    (state: GlobalState) => state.playlists.playlists,
  );

  const total = useSelector((state: GlobalState) => state.playlists.total);
  const [loading, setLoading] = useLoading(isLoading);
  const [isAdmin] = useIsAdmin();

  const dispatchGetPlaylists = useCallback(
    debounce((recentSearch: string) => {
      dispatch(
        getPlaylists({ params: readAllFromQueryParams(recentSearch), isAdmin }),
      );
    }, defaultDebounceValue),
    [isAdmin],
  );

  const getDevices = async () => {
    if (deviceIds?.length && deviceIds.length === 1) {
      const { items } = await api.devices.getDevicesList({
        ids: deviceIds,
      });
      if (items?.length && items?.length > 0) {
        const types = items[0].apps?.map((app) => app.type);
        const uniqueAppTypes = types?.filter(
          (type, i, self) => self.indexOf(type) === i,
        );
        setAppTypes(uniqueAppTypes);
      }
      dispatch(
        setQueryParams({
          route: config.playlists.route,
          qp: PlaylistsQueryParam.DeviceIds,
          values: deviceIds,
        }),
      );

      dispatch(
        setPlaceHolder({
          route: config.playlists.route,
          value: items[0]?.name,
        }),
      );
    }
  };

  const removePlaceholder = () => {
    dispatch(
      setQueryParams({
        route: config.playlists.route,
        qp: PlaylistsQueryParam.DeviceIds,
        values: [],
      }),
    );

    dispatch(
      setPlaceHolder({
        route: config.playlists.route,
        value: '',
      }),
    );
  };

  useEffect(() => {
    setLoading(true); // show preloader before dispatch
    dispatchGetPlaylists(search);
    getDevices();
    const queryParams = new URLSearchParams(search);
    setDeviceName(queryParams.get('deviceName') || '');
    return () => removePlaceholder();
  }, [search, isAdmin]);

  return (
    <Playlists
      deviceName={deviceName}
      appTypes={appTypes}
      playlists={playlists}
      total={total}
      isLoading={loading}
      onUpdate={() => dispatchGetPlaylists(search)}
    />
  );
};
