import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CreateSevereWeatherModal from '../../../../components/Apps/SevereWeather/CreateSevere';
import useModalState from '../../../../utils/hooks/useModalState';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import api from '../../../../api';
import AppType from '../../../../enums/appType.enum';
import { getUserData } from '../../../../store/actions/userData';

export default ({
  children,
  onUpdate,
}: {
  children: React.ReactNode;
  onUpdate: () => void;
}) => {
  const [isVisible, handleOpen, handleClose] = useModalState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (data: CreateAppRequestData) => {
    setLoading(true);
    api.apps
      .createApp(AppType.SevereWeather, data)
      .then(() => {
        handleClose();
        onUpdate();
        dispatch(getUserData());
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={handleOpen}
        onClick={handleOpen}
      >
        {children}
      </span>
      <CreateSevereWeatherModal
        isVisible={isVisible}
        onSubmit={handleSubmit}
        onClose={handleClose}
        isLoading={loading}
      />
    </>
  );
};
