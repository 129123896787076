import { API_URL } from '../constants/api';
import { getAccessTokenOrSignIn, checkSignIn } from '../utils/auth0';
import eventEmitter from '../utils/eventEmitter';
import ConversionJobsRoutes from '../api/endpoints/conversionJobs/enums/conversionJobsRoutes.enum';
import ConversionJobEvents from '../enums/events/conversionJobEvents.enum';
import ConversionJob from '../interfaces/conversionJob.interface';
import { completedStatuses } from '../constants/conversionJob';

// The "lastCompleteInterval" needed to exclude old
// conversion jobs which are completed or failed
const lastCompleteInterval = 180; // 180 sec === 3 min
const refreshInterval = 2000; // 2000 ms === 2 sec
const toastsMaxNumber = 5;

let conversionJobStatusWorker: Worker;

/**
 * Starts the conversion jobs polling
 */
export const start = async () => {
  const isSignIn = await checkSignIn();
  if (isSignIn) {
    const token = await getAccessTokenOrSignIn();

    conversionJobStatusWorker.postMessage({
      msg: 'init',
      token: `Bearer ${token}`,
      url: `${API_URL}/${ConversionJobsRoutes.ConversionJobs}`,
      qp: `?lastCompleteInterval=${lastCompleteInterval}&limit=${toastsMaxNumber}`,
      interval: refreshInterval,
      completedStatuses,
      assume: window.localStorage.getItem('loginAsUserId') ?? undefined,
    });
  }
};

/**
 * Stops the conversion jobs polling
 */
export const stop = () => {
  if (conversionJobStatusWorker) {
    conversionJobStatusWorker.postMessage({ msg: 'stop' });
  }
};

/**
 * Initializes the conversion job worker
 */
export const init = async () => {
  if (conversionJobStatusWorker) return;

  conversionJobStatusWorker = new Worker('/workers/conversionJobStatus.js');

  conversionJobStatusWorker.onmessage = (event: MessageEvent) => {
    const jobs: ConversionJob[] = event?.data;

    if (!jobs) return;

    jobs.forEach((job: ConversionJob) => {
      const { status } = job;

      if (status) {
        eventEmitter.emit(
          `${ConversionJobEvents.ConversionJobPrefix}.${status}`,
          job,
        );
      }
    });
  };

  await start();

  document.addEventListener(
    'visibilitychange',
    () => {
      if (document.hidden) {
        stop();
      } else {
        start();
      }
    },
    false,
  );
};
