import React from 'react';
import { useTranslation } from 'react-i18next';
import TemplateSteps from '../../../../enums/steps/addTemplateSteps.enum';
import SelectNavTab from '../../../NavTabs/SelectNavTab';

export default ({ currentStep }: { currentStep: TemplateSteps }) => {
  const { t } = useTranslation();

  return (
    <SelectNavTab<TemplateSteps>
      isSelectable={false}
      withUnderline={false}
      className="mb-5 justify-content-center"
      tabs={[
        {
          key: TemplateSteps.Layout,
          isActive: currentStep === TemplateSteps.Layout,
          text: t('templates.create_template.steps.layout'),
        },
        {
          key: TemplateSteps.Details,
          isActive: currentStep === TemplateSteps.Details,
          text: t('templates.create_template.steps.details'),
        },
      ]}
    />
  );
};
