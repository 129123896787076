import React, { memo } from 'react';
import clsx from 'clsx';

export default memo(
  <T extends string>({
    currentStep,
    steps,
    className,
  }: {
    currentStep: T;
    steps: { [key: string]: string };
    className?: string;
  }) => (
    <div className={clsx('d-flex justify-content-center', className)}>
      {Object.entries(steps).map(([step, text]) => (
        <span
          key={step}
          className={clsx(
            'badge badge-lg fw-bold fs-6 px-7 py-3 me-3',
            step === currentStep ? 'badge-light text-primary' : 'text-muted',
          )}
        >
          {text}
        </span>
      ))}
    </div>
  ),
);
