import React from 'react';
import BadgeTypes from '../../enums/badgeTypes.enum';

export default ({
  type,
  children,
}: {
  type?: BadgeTypes;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={`badge fs-7 py-3 px-7 fw-bold badge-light${
        type ? `-${type}` : ''
      } `}
      style={{ textTransform: 'capitalize' }}
    >
      {children}
    </div>
  );
};
