import axios from 'axios';
import { API_URL } from '../../../constants/api';
import RolesRoutes from './enums/rolesRoutes.enum';
import Role from '../../../interfaces/role.interface';

export default {
  /**
   * Retrieves the list of roles
   */
  getRoles: async (): Promise<Role[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${RolesRoutes.Roles}`,
    });

    return data;
  },
};
