import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import WithDeleteConfirm from '../../../../components/WithDeleteConfirm';
import App from '../../../../interfaces/app.interface';
import Tooltip from '../../../../components/Tooltip';
import api from '../../../../api';

export default ({
  app,
  children,
  onUpdate,
}: {
  app: App;
  children?: React.ReactNode;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();

  const handleConfirm = async () => {
    await api.apps.deleteApp(app.id).then(() => {
      toast.success(i18next.t<string>('apps.powerBi.powerBi_deleted'));
    });
    onUpdate();
    const utilityId = app?.dependency.config?.powerBiUtilityAppId;
    if (utilityId) {
      await api.powerBi.deleteApp(utilityId);
    }
  };

  return (
    <WithDeleteConfirm
      body={`${t('common.delete_confirmation.title')} ${t(
        'common.delete_confirmation.powerBi',
      )} "${app.name}"?`}
      handleConfirm={handleConfirm}
    >
      {children ?? (
        <Tooltip text={t('common.delete')}>
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-trash" />
          </span>
        </Tooltip>
      )}
    </WithDeleteConfirm>
  );
};
