import React from 'react';
import TitleComponent from '../../components/TitleComponent';
import Layout from '../../pages/Layout';
import AuthLayout from '../../pages/Auth/Layout';

export default ({
    ChildComponent,
    title,
    isAuthLayout = false,
  }: {
    ChildComponent: React.ComponentType;
    title: string;
    isAuthLayout?: boolean;
  }) =>
  (props: { children?: React.ReactNode }) =>
    (
      <>
        {isAuthLayout ? (
          <AuthLayout>
            <TitleComponent title={title} />
            <ChildComponent {...props} />
          </AuthLayout>
        ) : (
          <Layout>
            <TitleComponent title={title} />
            <ChildComponent {...props} />
          </Layout>
        )}
      </>
    );
