import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../api';
import SelectAsync, {
  SelectedValue,
} from '../../../components/Select/SelectAsync';
import User from '../../../interfaces/user/user.interface';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
// import Company from '../../../interfaces/company.interface';
import dropdownsData from '../../../constants/dropdownsData';

interface SelectCompaniesProps {
  onChange?: (selectedValues: SelectedValue[]) => void;
  initialValue?: string[];
  id?: string;
  className?: string;
  preselect?: boolean;
  currentUser?: User;
  isLoadingCompany?: boolean;
}

interface DefaultProps extends SelectCompaniesProps {
  onChange: Required<SelectCompaniesProps>['onChange'];
  initialValue: Required<SelectCompaniesProps>['initialValue'];
}

const SelectCompanies = ({
  onChange,
  initialValue,
  id,
  className,
  preselect,
  currentUser,
  isLoadingCompany,
}: DefaultProps) => {
  const { limit } = dropdownsData;
  const { t } = useTranslation();
  const [selectedValuesop, setSelectedValue] = useState<SelectedValue[]>([
    {
      value: 'loadingValue',
      label: `${t('common.loading')}...`,
    },
  ]);
  const [loadedCompanies, setLoadedCompanies] = useState<
    SelectedValue[] | undefined
  >(undefined);
  const [isAdmin] = useIsAdmin();

  // useEffect(() => {
  //   if (!selectedValuesop)
  //     setSelectedValue([
  //       {
  //         label: `${t('common.loading')}...`,
  //       },
  //     ]);

  //   // if (!initialValue.length) return;

  //   // setTimeout(async () => {
  //   //   let companies: Company[] = [];

  //   //   if (initialValue?.length) {
  //   //     companies = isAdmin
  //   //       ? await api.companies.getAdminCompaniesByIds(
  //   //           initialValue,
  //   //           currentUser?.id,
  //   //         )
  //   //       : await api.companies.getCompaniesByIds(initialValue);
  //   //   }

  //   //   setSelectedValue(
  //   //     companies.map(({ name, id: value }) => ({ label: name, value })),
  //   //   );
  //   // });
  // }, [isLoadingCompany, initialValue]);

  useEffect(() => {
    if (loadedCompanies)
      setSelectedValue(
        loadedCompanies?.filter((company) =>
          initialValue?.includes(company.value as string),
        ) as SelectedValue[],
      );
  }, [isLoadingCompany, loadedCompanies]);

  return (
    <SelectAsync
      id={id}
      initialValue={selectedValuesop}
      // defaultInitValue={initialValue}
      onChange={(selectedValues: SelectedValue[]) => onChange(selectedValues)}
      loadOptions={async (search: string): Promise<SelectedValue[]> => {
        const {
          items,
          meta: { totalItems },
        } = isAdmin
          ? await api.companies.getAdminCompaniesList({
              search,
              currentUserId: currentUser?.id,
              limit,
            })
          : await api.companies.getCompaniesList({ search, limit });

        const companiesOP = items.map(({ id: value, name }) => ({
          value,
          label: name,
        }));
        if (search) return companiesOP;
        if (preselect && totalItems === 1 && !search) {
          const [item] = items;

          setSelectedValue([{ label: item.name, value: item.id }]);
        }

        setLoadedCompanies(companiesOP);

        return companiesOP;
      }}
      className={className}
      placeholder={t('companies.select_companies')}
      isClearable
      isMulti
    />
  );
};

SelectCompanies.defaultProps = {
  initialValue: [],
  onChange: () => null,
};

export default SelectCompanies;
