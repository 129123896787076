import axios from 'axios';
import { API_URL } from '../../../constants/api';
import PlayersRoutes from './enums/playersRoutes.enum';

export default {
  /**
   * Links the device to the platform
   * @param deviceId
   * @param pin
   */
  linkDevice: async (deviceId: string, pin: string): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${PlayersRoutes.PlayerDevices}/${deviceId}`,
      params: { pin },
    });
  },
};
