import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../../Modal';
import SelectDevices from '../../../containers/DeviceGroupInfo/SelectNotAssignedDevices';
import { SelectedValue } from '../../Select/SelectAsync';
import FiltersLocationDropdown from '../FiltersLocationDropdown';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import validation from '../../../utils/validation';
import { assignDeviceValidation } from '../../../validation/devices';
import FormErrorMessage from '../../FormErrorMessage';
import DeviceAssignmentTarget from '../../../interfaces/devices/deviceAssignmentTarget.interface';
import { UserDataSetInterface } from '../../../store/reducers/userData/userDataState.interface';
import TalkToSales from '../../Trial/TalkToSales';
import License from '../../../interfaces/license.interface';

interface FormInputs {
  devices: SelectedValue[];
}

export default ({
  children,
  isFilter,
  onSubmit,
  deviceAssignmentTarget,
  denyDifferentLicense,
  user,
  license,
}: {
  children: React.ReactNode;
  isFilter?: boolean;
  onSubmit?: (data: SelectedValue[]) => void;
  deviceAssignmentTarget?: DeviceAssignmentTarget;
  denyDifferentLicense?: boolean;
  user?: UserDataSetInterface;
  license?: License;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    clearErrors,
  } = useForm<FormInputs>({
    resolver: yupResolver(
      validation.object(assignDeviceValidation(t)).required(),
    ),
    defaultValues: {
      devices: [],
    },
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [devices, setDevices] = useStateWithHookForm<
    FormInputs,
    SelectedValue[]
  >({ setValue, trigger, name: 'devices' }, []);

  const handleClose = () => setIsModalVisible(false);
  const [talkToSales, setTalkToSales] = useState(false);
  const openTalkToSales = () => {
    setTalkToSales(true);
  };
  const closeTalkToSales = () => {
    setTalkToSales(false);
  };
  const handleSubmitData = () => {
    if (onSubmit) {
      onSubmit(devices);
    }

    handleClose();
    setDevices([]);
  };

  const open = () => {
    clearErrors();
    setIsModalVisible(true);
  };

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={user?.isTrial ? openTalkToSales : open}
        onClick={user?.isTrial ? openTalkToSales : open}
      >
        {children}
      </span>
      <Modal
        title={t('devices.choose_device')}
        widthDefaultSize={false}
        show={isModalVisible}
        onHide={handleClose}
        bodyClassName="py-5"
        footerContent={
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit(handleSubmitData)}
          >
            {t('devices.add_device')}
          </button>
        }
      >
        <>
          {isFilter && (
            <div className="d-flex justify-content-end mb-5">
              <FiltersLocationDropdown className="me-2" />
            </div>
          )}
          <div>
            <span className="col-form-label text-lg-end">
              {t('playlists.device')}
            </span>
            <div className="row">
              <div className="col-12">
                <SelectDevices
                  initialValue={devices}
                  onChange={setDevices}
                  deviceAssignmentTarget={deviceAssignmentTarget}
                  denyDifferentLicense={denyDifferentLicense}
                  license={license}
                />
              </div>
              {/* {isAdmin && (
                <div className="col-2">
                  <CreateDevice>
                    <button type="button" className="btn btn-light me-2">
                      <i className="fas fa-plus ps-1" />
                    </button>
                  </CreateDevice>
                </div>
              )} */}
            </div>

            <FormErrorMessage name="devices" errors={errors} />
          </div>
        </>
      </Modal>
      <TalkToSales
        handleClose={closeTalkToSales}
        isModalVisible={talkToSales}
      />
    </>
  );
};
