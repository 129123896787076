import { map } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import CreateTemplate from '../../containers/Templates/CreateTemplate';
import PaginationQueryParam from '../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../enums/queryParams/sortingQueryParam.enum';
import useTableMultiSelect from '../../utils/hooks/useTableMultiSelect';
import Card from '../Card';
import Pagination from '../Pagination/QueryParamPagination';
import Search from '../Search/QueryParamSearch';
// import FiltersDropdown from '../Devices/FiltersDropdown';
import TableView from './TableView';
import Template from '../../interfaces/template.interface';
import TemplatesQueryParam from '../../api/endpoints/templates/enums/templatesQyueryParam.enum';

const pageSize = 7;
const tableRowHeight = 63;

export default ({
  templates,
  total,
  isLoading,
  children,
  resetOnSearch,
  onUpdate,
}: {
  templates: Template[];
  total: number;
  isLoading: boolean;
  children?: React.ReactNode;
  resetOnSearch?: boolean;
  onUpdate?: () => void;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(templates, 'id'),
    [],
    resetOnSearch ? search : undefined,
  );

  return (
    <Card>
      <Card.Header>
        {!children && (
          <Card.Header.Title>
            <Card.Header.Title.Label>
              {t('templates.templates')}
            </Card.Header.Title.Label>
          </Card.Header.Title>
        )}

        <Card.Header.Toolbar>
          <Search
            queryField={TemplatesQueryParam.Search}
            pageNumberQueryField={PaginationQueryParam.Page}
            placeholder={t('common.search')}
            className="me-2"
          />

          <div className="d-flex flex-wrap flex-stack">
            {/* <FiltersDropdown className="me-2" /> */}
            <CreateTemplate onSuccess={onUpdate}>
              <button type="button" className="btn btn-primary me-2">
                {t('templates.create_template.title')}
              </button>
            </CreateTemplate>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <TableView
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          templates={templates}
          isLoading={isLoading}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
          onUpdate={onUpdate}
        />
        <Pagination
          total={total}
          pageSize={pageSize}
          pageNumberQueryField={PaginationQueryParam.Page}
          pageSizeQueryField={PaginationQueryParam.Limit}
          className="mt-3 mb-5"
        />
      </Card.Body>
    </Card>
  );
};
