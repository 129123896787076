import React from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import AddUsersGroupSteps from '../../../../enums/steps/addUsersGroupSteps.enum';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import CreateUsersGroupRequestData from '../../../../api/endpoints/usersGroups/interfaces/createUsersGroupRequestData.interface';
import { statusesTranslation } from '../../../../constants/translation/users';
import SelectCompany from '../../../../containers/Companies/SelectCompany';
import SelectLocation from '../../../../containers/SelectLocation';
import { UserStatus } from '../../../../enums/userStatus.enum';
import FormErrorMessage from '../../../FormErrorMessage';
import Select from '../../../Select/RegularSelect';
import TextInput from '../../../TextInput';
import StepsTitle from '../StepsTitle';
import Modal from '../../../Modal';
import UsersGroup from '../../../../interfaces/usersGroup.interface';

type FieldsData = Partial<CreateUsersGroupRequestData>;

export default ({
  setMode,
  data,
  group,
  setData,
}: {
  setMode: (mode: AddUsersGroupSteps) => void;
  data: FieldsData;
  group?: UsersGroup;
  setData: (newData: FieldsData) => void;
}) => {
  const { t } = useTranslation();

  const defaultValues: Partial<FieldsData> = {
    name: data.name ?? group?.name ?? '',
    companyId: data.companyId ?? group?.company?.id,
    locationId: data.locationId ?? group?.location?.id,
    status: data.status ?? group?.status,
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FieldsData>({
    resolver: yupResolver(
      Yup.object({
        name: Yup.string().required(
          t('users_groups.create_group.validation.please_enter_name'),
        ),
        status: Yup.string().required(
          t('users_groups.create_group.validation.please_select_status'),
        ),
      }).required(),
    ),
    defaultValues,
  });

  const { id } = useParams<{ id: string }>();

  const [name, setName] = useStateWithHookForm<FieldsData, FieldsData['name']>(
    { setValue, trigger, name: 'name' },
    data.name ?? group?.name ?? '',
  );
  const [status, setStatus] = useStateWithHookForm<
    FieldsData,
    FieldsData['status']
  >({ setValue, trigger, name: 'status' }, data.status ?? group?.status);
  const [companyId, setCompanyId] = useStateWithHookForm<
    FieldsData,
    FieldsData['companyId'] | undefined
  >(
    { setValue, trigger, name: 'companyId' },
    data.companyId ?? group?.company?.id ?? '',
  );
  const [locationId, setLocationId] = useStateWithHookForm<
    FieldsData,
    FieldsData['locationId'] | undefined
  >(
    { setValue, trigger, name: 'locationId' },
    data.locationId ?? group?.location?.id ?? '',
  );

  /**
   * Submits the step data and switches to the next step
   * @param inputsData
   */
  const handleSubmitData = (inputsData: FieldsData) => {
    setData({ ...data, ...inputsData });
    setMode(AddUsersGroupSteps.Assign);
  };

  return (
    <div className="w-450px">
      <StepsTitle currentStep={AddUsersGroupSteps.Info} />
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <p className="mt-2 mb-0">
          <label htmlFor="userGroupName" className="col-form-label text-lg-end">
            {t('users_groups.common.group_name')}
          </label>
          <TextInput
            name="name"
            id="userGroupName"
            placeholder={t('users_groups.common.group_name')}
            value={name}
            onChange={setName}
          />
          <FormErrorMessage
            name="name"
            errors={errors}
            className="mt-1 min-h-20px"
          />
        </p>
        <p className="mb-0">
          <label htmlFor="company" className="col-form-label text-lg-end pt-0">
            {t('users_groups.common.company')}
          </label>
          <SelectCompany
            id="company"
            onChange={({ value }) => {
              if (value !== 'loadingValue') setCompanyId(value);
            }}
            initialValue={companyId}
            isClearable
            userId={id}
          />
          <FormErrorMessage
            name="companyId"
            errors={errors}
            className="mt-1 min-h-20px"
          />
        </p>
        <p className="mb-0">
          <label
            htmlFor="userGroupLocation"
            className="col-form-label text-lg-end pt-0"
          >
            {t('users_groups.common.group_location')}
          </label>
          <SelectLocation
            id="userGroupLocation"
            onChange={({ value }) => {
              if (value !== 'loadingValue') setLocationId(value);
            }}
            initialValue={locationId}
            companyId={companyId}
            showDefaultOptions={false}
            isClearable
          />
          <FormErrorMessage
            name="locationId"
            errors={errors}
            className="mt-1 min-h-20px"
          />
        </p>
        <p className="mb-0">
          <label htmlFor="status" className="form-label fw-bold pt-0">
            {t('common.status')}:
          </label>
          <Select<UserStatus>
            id="status"
            placeholder={t('users_groups.common.select_status')}
            defaultValue={
              status && {
                label: t(statusesTranslation[status]),
                value: status,
              }
            }
            onSelect={(selectedStatus) =>
              setStatus(selectedStatus as UserStatus)
            }
            options={Object.values(UserStatus).map((userStatusValue) => ({
              label: t(statusesTranslation[userStatusValue]),
              value: userStatusValue,
            }))}
          />
          <FormErrorMessage
            name="status"
            errors={errors}
            className="mt-1 min-h-20px"
          />
        </p>
        <Modal.Separator />
        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            {t('common.next_step')}
          </button>
        </div>
      </form>
    </div>
  );
};
