import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { map } from 'lodash';
import Card from '../Card';
import Search from '../Search/QueryParamSearch';
import UsersTable from './UsersTable';
import Pagination from '../Pagination/QueryParamPagination';
import CreateUser from '../../containers/Users/CreateUser';
import SortingQueryParam from '../../enums/queryParams/sortingQueryParam.enum';
import PaginationQueryParam from '../../enums/queryParams/paginationQueryParam.enum';
import UsersQueryParam from '../../api/endpoints/users/enums/usersQueryParam.enum';
import useSyncWithSearch from '../../utils/hooks/useSyncWithSearch';
import { debouncedUpdateSearch } from '../../utils/browserHistory';
import { UserStatus } from '../../enums/userStatus.enum';
import User from '../../interfaces/user/user.interface';
import routesConfig from '../../routing/config';
import FiltersDropdown from './FiltersDropdown';
import NavTabs from '../NavTabs';
import useTableMultiSelect from '../../utils/hooks/useTableMultiSelect';
import RemoveUser from '../../containers/Users/RemoveUser';
import CreateUserRequestData from '../../api/endpoints/users/interfaces/createUserRequestData.interface';
import { SelectedValue } from '../Select/SelectAsync';
import { KTSVG } from '../../lib/metronic/helpers';
import BulkEditUsers from '../../containers/Users/BulkEditUsers';
import BulkUpdateUsersRequestData from '../../api/endpoints/users/interfaces/bulkUpdateUsersRequestData.interface';
import RestrictedAccess from '../../containers/RestrictedAccess';
import { UserRole } from '../../enums/userRole.enum';
import UserAssignmentTarget from '../../interfaces/user/userAssignmentTarget.interface';

const tableRowHeight = 73.7;
const options = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 1000, label: '1000' },
];
export default ({
  users,
  total,
  isLoading,
  resetOnSearch,
  children,
  onEditUser,
  onBulkEditUser,
  unAssignUser,
  assignUser,
  onRestoreUser,
  onSuspendUser,
  onUnAssignUser,
  onUnAssignUsers,
  userGroupInfo,
  onArchiveUsers,
  skipActions,
  skipEditSuspendReset,
  isSelectable = true,
  userAssignmentTarget,
  onUnssingUserFromDeviceGroup,
}: {
  users: User[];
  total: number;
  isLoading: boolean;
  skipActions?: boolean;
  isSelectable?: boolean;
  resetOnSearch?: boolean;
  userGroupInfo?: boolean;
  skipEditSuspendReset?: boolean;
  children?: React.ReactNode;
  onEditUser?: (userId: string, data: Partial<CreateUserRequestData>) => void;
  onBulkEditUser?: (data: BulkUpdateUsersRequestData) => void;
  unAssignUser?: (userId: string, userGroupId: string) => void;
  assignUser?: (data: SelectedValue[]) => void;
  onRestoreUser?: (userId: string) => void;
  onSuspendUser?: (userId: string) => void;
  onUnAssignUser?: (userId: string, userGroupId: string) => void;
  onUnAssignUsers?: (userIds: string[]) => void;
  onArchiveUsers?: (userIds: string[]) => void;
  onUnssingUserFromDeviceGroup?: (
    userId: string,
    deviceGroupId: string,
  ) => void;
  userAssignmentTarget?: UserAssignmentTarget;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [status, setStatus] = useSyncWithSearch(UsersQueryParam.Status);
  const isActiveUsersTab = !status || status === UserStatus.Active;
  const [pageSize, setPageSize] = React.useState(5);

  const [selectedItems, setSelectedItem, selectAll, resetSelection] =
    useTableMultiSelect(
      map(users, 'id'),
      [],
      resetOnSearch ? search : undefined,
    );

  const handleChangeStatus = (newStatus: UserStatus | '') => {
    debouncedUpdateSearch({
      [PaginationQueryParam.Page]: 1,
    });

    setStatus(newStatus);
  };

  const navTabs = [
    {
      key: 'active-users',
      tab: (
        <span
          role="tab"
          tabIndex={-1}
          className={clsx(
            'nav-link text-active-primary me-6',
            isActiveUsersTab ? 'active' : 'hoverable',
          )}
          onClick={() => handleChangeStatus('')}
          onKeyPress={() => handleChangeStatus('')}
        >
          {t('users.active_users')}
        </span>
      ),
    },
    {
      key: 'users-groups',
      tab: (
        <RestrictedAccess
          allowedRoles={[
            UserRole.SuperAdmin,
            UserRole.Admin,
            UserRole.AccountOwner,
            UserRole.Employee,
            UserRole.IndigoAdmin,
            UserRole.Editor,
          ]}
        >
          <Link
            to={routesConfig.usersGroups.route}
            className="nav-link text-active-primary me-6"
          >
            {t('users.users_groups')}
          </Link>
        </RestrictedAccess>
      ),
    },
    {
      key: 'disabled-users',
      tab: (
        <span
          role="tab"
          tabIndex={-1}
          className={clsx(
            'nav-link text-active-primary me-6',
            status === UserStatus.Disabled ? 'active' : 'hoverable',
          )}
          onClick={() => handleChangeStatus(UserStatus.Disabled)}
          onKeyPress={() => handleChangeStatus(UserStatus.Disabled)}
        >
          {t('users.archive')}
        </span>
      ),
    },
  ];

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          {children || <NavTabs tabs={navTabs} separatorClassName="mb-7" />}
          <Search
            queryField={UsersQueryParam.Search}
            pageNumberQueryField={PaginationQueryParam.Page}
            placeholder={t('users.search_users')}
            className="me-2"
          />
          <div className="d-flex flex-wrap flex-stack">
            <BulkEditUsers
              onBulkEditUser={onBulkEditUser}
              selectedItems={selectedItems}
            >
              <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2">
                <KTSVG
                  path="/media/icons/duotune/art/art005.svg"
                  className="svg-icon-3"
                />
              </span>
            </BulkEditUsers>
            <FiltersDropdown className="me-2" />
            {(onUnAssignUser || onUnAssignUsers) && selectedItems.length > 1 && (
              <RemoveUser
                confirmText={
                  isActiveUsersTab
                    ? t('common.delete_confirmation.confirm_archive')
                    : undefined
                }
                title={
                  isActiveUsersTab
                    ? t('common.delete_confirmation.multiple_archive_title')
                    : undefined
                }
                selectedItems={selectedItems}
                unAssignUser={onUnAssignUser}
                unAssignUsers={
                  isActiveUsersTab ? onArchiveUsers : onUnAssignUsers
                }
                resetSelection={resetSelection}
              >
                <button type="button" className="btn btn-danger m-2">
                  {isActiveUsersTab
                    ? t('common.delete_confirmation.archive_selected')
                    : t('common.delete_confirmation.delete_selected')}
                </button>
              </RemoveUser>
            )}
            <div className="d-flex align-items-center align-content-center">
              <CreateUser
                userAssignmentTarget={userAssignmentTarget}
                className="me-2"
                assignUser={assignUser}
              />
            </div>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <UsersTable
          users={users}
          isLoading={isLoading}
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
          isSelectable={isSelectable}
          onEditUser={onEditUser}
          unAssignUser={unAssignUser}
          onRestoreUser={onRestoreUser}
          onSuspendUser={onSuspendUser}
          skipActions={skipActions}
          skipEditSuspendReset={skipEditSuspendReset}
          userGroupInfo={userGroupInfo}
          onUnssingUserFromDeviceGroup={onUnssingUserFromDeviceGroup}
        />
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-80px mb-4">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(+e.target.value);
              }}
              className="form-select form-select-sm form-select-solid"
            >
              {options.map((option) => (
                <option key={`${option.label}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div className="flex-fill ps-5">
            <Pagination
              total={total}
              pageSize={pageSize}
              pageNumberQueryField={PaginationQueryParam.Page}
              pageSizeQueryField={PaginationQueryParam.Limit}
              className="mt-3 mb-5"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
