import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GetUsersRequestData from '../../../api/endpoints/users/interfaces/getUsersRequestData.interface';
import api from '../../../api';
import SelectAsync, {
  SelectedValue,
} from '../../../components/Select/SelectAsync';
import dropdownsData from '../../../constants/dropdownsData';
import { UserRole } from '../../../enums/userRole.enum';
import { UserType } from '../../../enums/userType.enum';

interface SelectUserProps {
  onChange?: (selectedValue: string) => void;
  initialValue?: string;
  id?: string;
  className?: string;
  filters?: GetUsersRequestData;
  isAdmin?: boolean;
  companyId?: string;
  currentUserId?: string;
  userProfileId?: string;
  isTrial?: boolean;
}

interface DefaultProps extends SelectUserProps {
  onChange: Required<SelectUserProps>['onChange'];
  initialValue: Required<SelectUserProps>['initialValue'];
}

const SelectUser = ({
  onChange,
  initialValue,
  id,
  userProfileId,
  className,
  filters,
  isAdmin,
  companyId,
  currentUserId,
  isTrial,
}: DefaultProps) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<SelectedValue>();
  const getKey = () => {
    if (companyId) {
      return `${companyId}_${id}`;
    }
    if (currentUserId) {
      return `${currentUserId}_${id}`;
    }
    if (userProfileId) {
      return `${userProfileId}_${id}`;
    }
    return id;
  };

  /**
   * Retrieves the select options asynchronously
   * @param search
   */
  const loadOptions = async (search: string): Promise<SelectedValue[]> => {
    const queryParams = { search, limit: dropdownsData.limit, ...filters };

    let items;

    if (currentUserId) {
      items =
        isAdmin && currentUserId
          ? (await api.users.getAdminUsersForUserGroupInfo(currentUserId)).items
          : (await api.users.getUsersList(queryParams)).items;

      return items.map(({ id: value, firstName, lastName }) => ({
        value,
        label: `${firstName} ${lastName}`,
      }));
    }

    const role = UserRole.AccountOwner;
    items = isAdmin
      ? (
          await api.users.getAdminUsersList({
            search,
            isTrial: String(isTrial),
            userType: UserType.Customer,
            role,
            companyId,
            limit: dropdownsData.limit,
          })
        ).items
      : (await api.users.getUsersList(queryParams)).items;
    return items.map(({ id: value, firstName, lastName }) => ({
      value,
      label: `${firstName} ${lastName}`,
    }));
  };

  useEffect(() => {
    if (!initialValue) return;
    setSelectedValue({
      label: `${t('common.loading')}...`,
      value: initialValue,
    });

    setTimeout(async () => {
      const { firstName, lastName } = await (isAdmin
        ? api.users.getUserForAdmin(initialValue)
        : api.users.getUser(initialValue));

      setSelectedValue({
        label: `${firstName} ${lastName}`,
        value: initialValue,
      });
    });
  }, []);

  return (
    <SelectAsync
      id={id}
      key={getKey()}
      initialValue={selectedValue}
      onChange={({ value }: SelectedValue) => {
        onChange(value as string);
      }}
      loadOptions={loadOptions}
      className={className}
      placeholder={t('users.select_user')}
      isClearable
    />
  );
};

SelectUser.defaultProps = {
  initialValue: null,
  onChange: () => null,
};

export default SelectUser;
