import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { CreateInstagramFeedRequest } from '../../../../store/types/apps/instagramFeed';
import Theme from '../../../../enums/theme.enum';
import InstagramPreview from '../../../../enums/instagramFeedType.enum';
import App from '../../../../interfaces/app.interface';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import Orientation from '../../../../enums/orientation.enum';
import FormErrorMessage from '../../../FormErrorMessage';
import Select from '../../../../containers/Apps/Instagram/SelectFeedType';
import { feedTypesTranslation } from '../../../../constants/translation/instagramFeedType';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import styles from './instagram.module.scss';
import instagramLogo from '../../../../assets/images/apps/instagram.svg';
import instagramAuthLoginIcon from '../../../../assets/images/apps/InstagramLogoAuth.png';
import { Apis } from '../../../../api/endpoints/apps/Instagram';
import {
  APP_ID,
  APP_LOGGER_ID,
  APP_URI,
} from '../../../../constants/dotenvconst';

type CreateInstagramRequestFormMutated = Omit<
  CreateInstagramFeedRequest,
  'config' | 'placement'
>;

interface CreateInstagramFeedRequestFormPayload
  extends CreateInstagramRequestFormMutated {
  instagramPreview: InstagramPreview;
  theme: Theme;
  InstagramUser: string[];
}

export interface CreateFeedModalProps {
  onSubmit: (data: CreateInstagramFeedRequest) => void;
  instagram?: App;
  onClose?: () => void;
  loading?: boolean;
  update?: boolean;
  code?: string;
}

export default ({
  onSubmit,
  onClose,
  loading,
  update,
  code,
  instagram = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();
  const initialValues: CreateInstagramFeedRequestFormPayload = {
    name: instagram?.name ?? '',
    instagramPreview: instagram?.dependency?.config?.instagramPreview ?? '',
    theme: instagram?.dependency?.config?.Theme ?? '',
    InstagramUser: instagram?.dependency?.config?.instagramUser ?? [],
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t('apps.instagram.required'))
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    instagramPreview: Yup.string()
      .required(t('apps.instagram.required'))
      .defined(),
    theme: Yup.string().required(t('apps.instagram.required')).defined(),
  }).defined();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateInstagramFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const instagramPreviewValue: InstagramPreview =
    initialValues.instagramPreview as InstagramPreview;
  const [InstagramType, setInstagramType] = useStateWithHookForm<
    CreateInstagramFeedRequestFormPayload,
    InstagramPreview
  >({ setValue, trigger, name: 'instagramPreview' }, instagramPreviewValue);

  const feedThemeValue: Theme = initialValues.theme as Theme;
  const [feeds, setFeeds] = useState([]);
  const [feedName, setFeedName] = useStateWithHookForm<
    CreateInstagramFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const [instagramUser, setInstagramUser] = useStateWithHookForm<
    CreateInstagramFeedRequestFormPayload,
    string[]
  >({ setValue, trigger, name: 'InstagramUser' }, initialValues.InstagramUser);
  const [accessToken, setAccessToken] = useState();
  const [expiresIn, setExpiresIn] = useState();
  const handleFeedNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFeedName(value),
    [setFeedName],
  );

  const [isInstagramLoggedIn, setIsInstagramLoggedIn] = useState<boolean>(
    (update && code !== null) ?? false,
  );

  const handleInstagramPreview = (value: InstagramPreview) => {
    setInstagramType(value);
  };

  const [InstagramTheme, setInstagramTheme] = useStateWithHookForm<
    CreateInstagramFeedRequestFormPayload,
    Theme
  >({ setValue, trigger, name: 'theme' }, feedThemeValue);

  const handleInstagramTheme = (value: Theme) => {
    setInstagramTheme(value);
  };

  const [isScheduled, setIsScheduled] = useState<boolean>(false);

  const handleOnSubmit = async (
    data: CreateInstagramFeedRequestFormPayload,
  ) => {
    try {
      setLoading(true);

      const { name } = data;
      const createInstagramFeedRequest: CreateInstagramFeedRequest = {
        name,
        isScheduled,
        config: {
          AccessToken: accessToken,
          expiresIn,
          instagramPreview: InstagramType,
          Theme: InstagramTheme,
          instagramUser,
          feeds,
        },
        zoneId: Orientation.Landscape,
      };
      onSubmit(createInstagramFeedRequest);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const handleInstagramLogin = async (_accessToken: string) => {
    try {
      setLoading(true);
      const response = await Apis.getInstagramFeeds(`${_accessToken}`);
      setFeeds(response?.data?.data);
      setInstagramUser(response?.data?.data[0]?.username);
      setAccessToken(response?.data?.AccessToken);
      setExpiresIn(response?.data?.expires_in);

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const instagramLoginApi = async (_code: string) => {
    try {
      setLoading(true);
      const response = await Apis.instagramLogin(_code);

      if (response) {
        setIsInstagramLoggedIn(true);
        handleInstagramLogin(response?.data?.access_token);
        toast.success(t('apps.instagram.loginSuccess'));
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (loading) setLoading(loading);
    else setLoading(false);
    if (code !== 'null' && code !== undefined) {
      instagramLoginApi(String(code));
      history.push('/apps/instagram');
    }
  }, [loading, code]);

  const handleInstagramAuth = async () => {
    try {
      sessionStorage.setItem('instagramRelogin', 'false');
      window.location.href = `https://www.instagram.com/oauth/authorize?app_id=${APP_ID}&redirect_uri=${APP_URI}&scope=user_profile&response_type=code&logger_id=${APP_LOGGER_ID}&scope=user_profile,user_media`;
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="container ps-0 mx-auto py-auto">
      {isInstagramLoggedIn ? (
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id="form"
          className="d-flex flex-column justify-content-between"
        >
          <img
            src={instagramLogo}
            alt="instagram"
            className={clsx('mb-4', styles.instagramImage)}
          />
          <h1 className="text-center text-dark mb-4">
            {t('apps.instagram.title')}
          </h1>
          <div
            className={clsx(
              styles.googleBtn,
              styles.instagramBtn,
              'd-flex justify-content-center align-items-center',
            )}
          >
            <div className={clsx(styles.instagramIcImage)}>
              <img src={instagramAuthLoginIcon} alt="instagram" width="20" />
            </div>
            <WithSpinner
              isLoading={!instagramUser}
              size="md"
              className="btn text-white"
            >
              <span className={clsx(styles.textInstagramLogin)}>
                authorized with instagram as <b>{instagramUser}</b>
              </span>
            </WithSpinner>
          </div>
          <div className="d-flex flex-row p-4 w-100">
            <div className="d-flex flex-column mx-2 w-100">
              <label
                htmlFor="name"
                className="required text-dark fw-bolder my-1"
              >
                {`${t('apps.instagram.appName')}`}
              </label>
              <input
                name="name"
                id="name"
                value={feedName}
                onChange={handleFeedNameChange}
                className="form-control form-control-solid"
                placeholder={t('apps.instagram.type_Here')}
                type="text"
              />
              <FormErrorMessage
                name="name"
                errors={errors}
                className="my-1 px-2"
              />
            </div>

            <div className="d-flex flex-column mx-2 w-100">
              <label
                htmlFor="instagramPreview"
                className="required text-dark fw-bolder my-1"
              >
                {t('apps.instagram.instagramAccountType')}
              </label>
              <Select<InstagramPreview>
                id="instagramPreview"
                placeholder={t('my_account.left_table.lang')}
                defaultValue={{
                  value: InstagramType,
                  label: _.capitalize(t(feedTypesTranslation[InstagramType])),
                }}
                onSelect={(selectedInstagramAccountType) =>
                  handleInstagramPreview(
                    selectedInstagramAccountType as InstagramPreview,
                  )
                }
                options={Object.values(InstagramPreview).map((languageVal) => ({
                  label: _.capitalize(t(feedTypesTranslation[languageVal])),
                  value: languageVal,
                }))}
                observeDefaultValueChange
              />
              <FormErrorMessage
                name="instagramPreview"
                errors={errors}
                className="my-1 px-2"
              />
            </div>
            <div className="d-flex flex-column px-4 w-100">
              <label
                htmlFor="theme"
                className="required text-dark fw-bolder my-1"
              >
                {t('apps.instagram.theme')}
              </label>
              <Select<Theme>
                id="theme"
                placeholder={t('my_account.left_table.lang')}
                defaultValue={{
                  value: InstagramTheme,
                  label: _.capitalize(t(feedTypesTranslation[InstagramTheme])),
                }}
                onSelect={(selectedInstagramTheme) =>
                  handleInstagramTheme(selectedInstagramTheme as Theme)
                }
                options={Object.values(Theme).map((languageVal) => ({
                  label: _.capitalize(t(feedTypesTranslation[languageVal])),
                  value: languageVal,
                }))}
                observeDefaultValueChange
              />
              <FormErrorMessage
                name="theme"
                errors={errors}
                className="my-1 px-2"
              />
            </div>
          </div>
          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                <WithSpinner
                  isLoading={isLoading && !isScheduled}
                  className="min-w-100px"
                  size="md"
                >
                  {t('common.save')}
                </WithSpinner>
              </button>
              {!Object.keys(instagram).length && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                >
                  <WithSpinner
                    isLoading={isLoading && isScheduled}
                    className="min-w-100px"
                    size="md"
                  >
                    {t('common.save_schedule')}
                  </WithSpinner>
                </button>
              )}
            </div>
          </div>
        </form>
      ) : (
        <div className="d-flex flex-column justify-content-center">
          <img
            src={instagramLogo}
            alt="instagram"
            className={clsx('mb-4', styles.instagramImage)}
          />
          <h1 className="text-center text-dark mb-4">
            {t('apps.instagram.title')}
          </h1>

          <div
            role="presentation"
            className={clsx(
              styles.googleBtn,
              styles.instagramBtn,
              'd-flex justify-content-center align-items-center cursor-pointer',
            )}
            onClick={handleInstagramAuth}
          >
            <div className={clsx(styles.instagramIcImage)}>
              <img src={instagramAuthLoginIcon} alt="instagram" width="20" />
            </div>
            <span className={clsx(styles.textInstagramLogin)}>
              Sign in with Instagram
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
