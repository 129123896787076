import { call, put, takeEvery } from 'redux-saga/effects';
import DevicesGroupsActions from '../actions/devicesGroups/devicesGroupsActions.enum';
import GetDevicesGroupsResponseData from '../../api/endpoints/devicesGroups/interfaces/getDevicesGroupsResponseData.interface';
import UpdateDeviceGroupAction from '../actions/devicesGroups/interfaces/updateDeviceGroupAction.interface';
import DeleteDeviceGroupAction from '../actions/devicesGroups/interfaces/deleteDeviceGroupAction.interface';
import GetDevicesGroupsAction from '../actions/devicesGroups/interfaces/getDevicesGroupsAction.interface';
import { readAllFromQueryParams } from '../../utils/queryParams';
import history from '../../constants/history';
import api from '../../api';
import CreateDeviceGroup from '../actions/devicesGroups/interfaces/createDeviceGroup.interface';
import {
  getDeviceGroupSuccess,
  getDeviceGroupUsersSuccess,
  getDevicesGroups,
  getDevicesGroupsSuccess,
  setDevicesGroupsLoading,
} from '../actions/devicesGroups';
import GetDeviceGroupAction from '../actions/devicesGroups/interfaces/getDeviceGroupAction.interface';
import GetDeviceGroupResponseData from '../../api/endpoints/devicesGroups/interfaces/getDeviceGroupResponseData.interface';
import AssignUsersToDeviceGroupAction from '../actions/devicesGroups/interfaces/assignUsersToDeviceGroupAction.interface';
import GetDeviceGroupUsersAction from '../actions/devicesGroups/interfaces/getDeviceGroupUsersAction.interface';
import GetDevicesGroupUsersResponseData from '../../api/endpoints/devicesGroups/interfaces/getDevicesGroupUsersResponseData.interface';
import RemoveUsersFromDeviceGroupAction from '../actions/devicesGroups/interfaces/removeUsersFromDeviceGroupAction.interface';

/**
 * The devices groups retrieval worker
 * @param payload
 */
function* getDevicesGroupsRequestWorker({ payload }: GetDevicesGroupsAction) {
  yield put(setDevicesGroupsLoading({ loading: true }));

  try {
    const responseData: GetDevicesGroupsResponseData = yield call(
      api.devicesGroups.getDevicesGroups,
      payload,
    );

    yield put(getDevicesGroupsSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setDevicesGroupsLoading({ loading: false }));
}

/**
 * The device group retrieval worker
 * @param payload
 */
function* getDeviceGroupRequestWorker({ payload }: GetDeviceGroupAction) {
  // yield put(setDevicesGroupsLoading({ loading: true }));

  try {
    const responseData: GetDeviceGroupResponseData = yield call(
      api.devicesGroups.getDeviceGroup,
      payload,
    );

    yield put(getDeviceGroupSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  // yield put(setDevicesGroupsLoading({ loading: false }));
}

/**
 * The device group users list retrieval worker
 * @param payload
 */
function* getDeviceGroupUsersRequestWorker({
  payload,
}: GetDeviceGroupUsersAction) {
  try {
    const responseData: GetDevicesGroupUsersResponseData = yield call(
      api.devicesGroups.getDeviceGroupUsersList,
      readAllFromQueryParams(history.location.search),
      payload,
    );

    yield put(getDeviceGroupUsersSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }
}

/**
 * The device group update worker
 * @param payload
 */
function* updateDeviceGroupRequestWorker({ payload }: UpdateDeviceGroupAction) {
  yield put(setDevicesGroupsLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.devicesGroups.updateDeviceGroup, payload.data, payload.id);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(
      getDevicesGroups(readAllFromQueryParams(history.location.search)),
    );
  } else {
    yield put(setDevicesGroupsLoading({ loading: false }));
  }
}

/**
 * The assign users to device group worker
 * @param payload
 */
function* assignUserToDeviceGroupRequestWorker({
  payload,
}: AssignUsersToDeviceGroupAction) {
  yield put(setDevicesGroupsLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.devicesGroups.assignUsersToGroup, payload.data, payload.id);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(
      getDevicesGroups(readAllFromQueryParams(history.location.search)),
    );
  } else {
    yield put(setDevicesGroupsLoading({ loading: false }));
  }
}

/**
 * The removal users from device group worker
 * @param payload
 */
function* removeUserFromDeviceGroupRequestWorker({
  payload,
}: RemoveUsersFromDeviceGroupAction) {
  yield put(setDevicesGroupsLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(
      api.devicesGroups.removeUsersFromGroup,
      payload.data,
      payload.id,
    );
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(
      getDevicesGroups(readAllFromQueryParams(history.location.search)),
    );
  } else {
    yield put(setDevicesGroupsLoading({ loading: false }));
  }
}

/**
 * The devices group removal worker
 * @param payload
 */
function* removeDeviceGroupRequestWorker({ payload }: DeleteDeviceGroupAction) {
  yield put(setDevicesGroupsLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.devicesGroups.deleteDeviceGroup, payload.id);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(
      getDevicesGroups(readAllFromQueryParams(history.location.search)),
    );
  } else {
    yield put(setDevicesGroupsLoading({ loading: false }));
  }
}

function* createDeviceGroupRequestWorker({ payload }: CreateDeviceGroup) {
  yield put(setDevicesGroupsLoading({ loading: true }));

  let isSuccess = true;

  try {
    yield call(api.devicesGroups.createDeviceGroup, payload);
  } catch (error) {
    isSuccess = false;
  }

  if (isSuccess) {
    yield put(
      getDevicesGroups(readAllFromQueryParams(history.location.search)),
    );
  } else {
    yield put(setDevicesGroupsLoading({ loading: false }));
  }
}

export default function* devicesGroupsWatcher() {
  yield takeEvery<CreateDeviceGroup>(
    DevicesGroupsActions.CREATE_DEVICES_GROUP,
    createDeviceGroupRequestWorker,
  );

  yield takeEvery<GetDeviceGroupAction>(
    DevicesGroupsActions.GET_DEVICE_GROUP,
    getDeviceGroupRequestWorker,
  );

  yield takeEvery<AssignUsersToDeviceGroupAction>(
    DevicesGroupsActions.ASSIGN_USERS_TO_DEVICE_GROUP,
    assignUserToDeviceGroupRequestWorker,
  );

  yield takeEvery<RemoveUsersFromDeviceGroupAction>(
    DevicesGroupsActions.REMOVE_USERS_FROM_DEVICE_GROUP,
    removeUserFromDeviceGroupRequestWorker,
  );

  yield takeEvery<GetDevicesGroupsAction>(
    DevicesGroupsActions.GET_DEVICES_GROUPS,
    getDevicesGroupsRequestWorker,
  );

  yield takeEvery<GetDeviceGroupUsersAction>(
    DevicesGroupsActions.GET_DEVICE_GROUP_USERS,
    getDeviceGroupUsersRequestWorker,
  );

  yield takeEvery<UpdateDeviceGroupAction>(
    DevicesGroupsActions.UPDATE_DEVICE_GROUP,
    updateDeviceGroupRequestWorker,
  );

  yield takeEvery<DeleteDeviceGroupAction>(
    DevicesGroupsActions.DELETE_DEVICE_GROUP,
    removeDeviceGroupRequestWorker,
  );
}
