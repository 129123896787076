import React from 'react';
import clsx from 'clsx';

export default ({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode;
}) => (
  <div
    className={clsx(
      'fv-plugins-message-container invalid-feedback formik-error-message',
      className,
    )}
  >
    {children}
  </div>
);
