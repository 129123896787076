import { call, put, takeEvery } from 'redux-saga/effects';
import GetCompaniesListAction from '../actions/companies/interfaces/getCompaniesListAction.interface';
import api from '../../api';
import {
  getCompaniesListSuccess,
  setCompaniesLoading,
} from '../actions/companies';
import CompaniesActions from '../actions/companies/companies';
import GetCompaniesResponseData from '../../api/endpoints/companies/interfaces/getCompaniesResponseData.interface';

/**
 * The users retrieval worker
 * @param payload
 */
function* getCompaniesRequestWorker({ payload }: GetCompaniesListAction) {
  yield put(setCompaniesLoading({ loading: true }));

  try {
    const responseData: GetCompaniesResponseData = yield call(
      api.companies.getCompaniesList,
      payload,
    );

    yield put(getCompaniesListSuccess(responseData));
  } catch (error) {
    // Do nothing for now
  }

  yield put(setCompaniesLoading({ loading: false }));
}

export default function* companiesWatcher() {
  yield takeEvery<GetCompaniesListAction>(
    CompaniesActions.GET_COMPANIES_LIST,
    getCompaniesRequestWorker,
  );
}
