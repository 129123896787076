import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import api from '../../../api';
import CreateLibraryCategoryRequestData from '../../../api/endpoints/libraryCategories/interfaces/createLibraryCategoryRequestData';
import CreateCategory from '../../../components/AdminPanel/Library/CreateCategory';
import Modal from '../../../components/Modal';
import { LibraryCategoryType } from '../../../enums/library/libraryCategoryType.enum';
import LibraryCategory from '../../../interfaces/libraryCategory.interface';
import { readFromQueryString } from '../../../utils/queryParams';

const CreateLibraryCategory = ({
  libraryCategory,
  children,
  onUpdate,
  isEdit,
  total,
  createSubCategory,
  parentCategoryType,
}: {
  libraryCategory?: LibraryCategory;
  children?: React.ReactNode;
  onUpdate?: () => void;
  isEdit?: boolean;
  total?: number;
  createSubCategory?: boolean;
  parentCategoryType?: LibraryCategoryType;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(false);

  // const [customerId, setCustomerId] = useState('');
  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const [categoryId, setCategoryId] = useState<string>();

  const { search } = useLocation();

  React.useEffect(() => {
    const categoryIdParam = readFromQueryString(search, 'categoryId');

    if (categoryIdParam) {
      setCategoryId(categoryIdParam);
    }
  }, []);

  const handleSubmit = async (data: CreateLibraryCategoryRequestData) => {
    if (createSubCategory && categoryId) {
      if (isEdit && libraryCategory) {
        await api.libraryCategories
          .editCategories(libraryCategory.id, data)
          .then(() => {
            close();

            if (onUpdate) {
              onUpdate();
            }
          });
      } else {
        await api.libraryCategories
          .createSubCategories(data, categoryId)
          .then(() => {
            close();
            if (onUpdate) {
              onUpdate();
            }
          });
      }
    } else if (isEdit) {
      if (!libraryCategory) {
        return;
      }

      await api.libraryCategories
        .editCategories(libraryCategory.id, data)
        .then(() => {
          close();

          if (onUpdate) {
            onUpdate();
          }
        });
    } else {
      await api.libraryCategories.createCategories(data).then(() => {
        close();
        if (onUpdate) {
          onUpdate();
        }
      });
    }
  };

  const modalTitle = () => {
    if (createSubCategory) {
      if (isEdit) {
        return t('library.indigo.editSubCategory');
      }
      return t('library.indigo.createSubCategory');
    }
    if (isEdit) {
      return t('library.indigo.editCategory');
    }
    return t('library.indigo.createCategory');
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={modalTitle()}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
        fullscreen
      >
        <CreateCategory
          isLoading={false}
          libraryCategory={libraryCategory}
          total={total}
          onSubmit={handleSubmit}
          createSubCategory={createSubCategory}
          parentCategoryType={parentCategoryType}
        />
      </Modal>
    </>
  );
};
export default CreateLibraryCategory;
