import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { map } from 'lodash';
import SortOrder from '../../../../enums/sortOrder.enum';
import CompaniesSortField from '../../../../api/endpoints/companies/enums/companiesSortField.enum';
import { readFromQueryString } from '../../../../utils/queryParams';
import Columns, { TableHeaderColumn } from '../../../Table/Columns';
import Company from '../../../../interfaces/company.interface';
import WithSpinner from '../../../WithSpinner';
import TableRow from './TableRow';
import Table from '../../../Table';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../../utils/hooks/useTableMultiSelect';

interface TableViewProps {
  companies: Company[];
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight?: number;
  isSelectable?: boolean;
  multiSelectConfig?: TableMultiSelectConfig;
  onUpdate?: () => void;
}

interface DefaultProps extends TableViewProps {
  multiSelectConfig: Required<TableViewProps>['multiSelectConfig'];
}

export default ({
  companies,
  isLoading,
  sortQueryField,
  orderQueryField,
  onUpdate,
  preloaderHeight = 300,
  isSelectable = false,
  multiSelectConfig: { selectedItems, setSelectedItem, selectAll },
}: DefaultProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  const columns: TableHeaderColumn<CompaniesSortField>[] = [
    {
      name: t('companies.name'),
      sortBy: CompaniesSortField.Name,
    },
    { name: t('companies.numberLocations') },
    { name: t('companies.users') },
    { name: t('companies.industry') },
    { name: t('common.actions') },
  ];

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns<CompaniesSortField>
            columns={columns}
            checked={isAllSelected(selectedItems, map(companies, 'id'))}
            onChange={selectAll}
            config={{
              isSelectable,
              sortField,
              setSortField,
              sortQueryField,
              orderQueryField,
            }}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={!isLoading && !companies.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={t('companies.no_one_company_found')}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {companies.map((company: Company) => (
            <TableRow
              key={company.id}
              company={company}
              config={{ isSelectable }}
              isChecked={selectedItems.includes(company.id)}
              onCheck={setSelectedItem}
              onUpdate={onUpdate}
            />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
