import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AddDeviceSteps from '../../../../../enums/steps/addDeviceSteps.enum';
import DeviceCreationStepsData from '../../../../../interfaces/devices/deviceCreationStepsData.interface';
import { prepareHookFromState } from '../../../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../../../FormErrorMessage';
import validation from '../../../../../utils/validation';
import { MemoizedFileUploader } from '../../../../FileUploader';
import TextInput from '../../../../TextInput';
import StepsTitle from '../../StepsTitle';
import Device from '../../../../../interfaces/devices/device.interface';
import { MemoizedSelectLocation } from '../../../../../containers/SelectLocation';
import { MemoizedDatePicker } from '../../../../DatePicker';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

interface FormInputs {
  teamViewerId: string;
  teamViewerLocationId?: string;
  teamViewerInfoFile: File;
  teamViewerCommentFile: File;
  dateProgrammed: Date;
  programmedBy: string;
  pinNumber: string;
  signId: string;
  macAddress: string;
  wifiMacAddress: string;
  currentSoftwareVersion: string;
  playerBrandAndModel: string;
  playerSerialNumber: string;
  playerSerialFile: File;
}

export default ({
  data,
  device,
  setData,
  setMode,
  validationSchema,
  onSubmit,
}: {
  data: Partial<DeviceCreationStepsData>;
  device?: Device;
  setData: (newData: Partial<DeviceCreationStepsData>) => void;
  setMode: (mode: AddDeviceSteps) => void;
  validationSchema: { [key: string]: any };
  onSubmit: (data: Partial<DeviceCreationStepsData>) => void;
}) => {
  const { t } = useTranslation();
  const deviceInfo = device?.deviceInfo;
  const defaultValues: Partial<FormInputs> = {
    teamViewerId: deviceInfo?.teamViewerId ?? data?.teamViewerId,
    teamViewerLocationId:
      deviceInfo?.teamViewerLocationId ?? data?.teamViewerLocationId,
    teamViewerInfoFile:
      deviceInfo?.teamViewerInfoFile ?? data?.teamViewerInfoFile,
    teamViewerCommentFile:
      deviceInfo?.teamViewerCommentFile ?? data?.teamViewerCommentFile,
    dateProgrammed: deviceInfo?.dateProgrammed ?? data.dateProgrammed,
    programmedBy: deviceInfo?.programmedBy ?? data.programmedBy,
    pinNumber: device?.pinNumber ?? data.pinNumber,
    signId: device?.signId ?? data.signId,
    macAddress: deviceInfo?.macAddress ?? data.macAddress,
    wifiMacAddress: deviceInfo?.wifiMacAddress ?? data.wifiMacAddress,
    currentSoftwareVersion:
      deviceInfo?.currentSoftwareVersion ?? data.currentSoftwareVersion,
    playerBrandAndModel:
      deviceInfo?.playerBrandAndModel ?? data?.playerBrandAndModel,
    playerSerialNumber:
      deviceInfo?.playerSerialNumber ?? data?.playerSerialNumber,
    playerSerialFile: deviceInfo?.playerSerialFile ?? data?.playerSerialFile,
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validation.object(validationSchema).required()),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [teamViewerId, setTeamViewerId] =
    useFs<FormInputs['teamViewerId']>('teamViewerId');
  const [teamViewerLocationId, setTeamViewerLocationId] = useFs<
    FormInputs['teamViewerLocationId'] | undefined
  >('teamViewerLocationId');
  const [, setTeamViewerInfoFile] =
    useFs<FormInputs['teamViewerInfoFile']>('teamViewerInfoFile');
  const [, setTeamViewerCommentFile] = useFs<
    FormInputs['teamViewerCommentFile']
  >('teamViewerCommentFile');

  const [playerBrandAndModel, setPlayerBrandAndModel] = useFs<
    FormInputs['playerBrandAndModel']
  >('playerBrandAndModel');
  const [playerSerialNumber, setPlayerSerialNumber] =
    useFs<FormInputs['playerSerialNumber']>('playerSerialNumber');
  const [, setPlayerSerialFile] =
    useFs<FormInputs['playerSerialFile']>('playerSerialFile');

  const [dateProgrammed, setDateProgrammed] =
    useFs<FormInputs['dateProgrammed']>('dateProgrammed');
  const [programmedBy, setProgrammedBy] =
    useFs<FormInputs['programmedBy']>('programmedBy');
  const [pinNumber, setPinNumber] = useFs<FormInputs['pinNumber']>('pinNumber');
  const [signId, setSignId] = useFs<FormInputs['signId']>('signId');
  const [macAddress, setMacAddress] =
    useFs<FormInputs['macAddress']>('macAddress');
  const [wifiMacAddress, setWifiMacAddress] =
    useFs<FormInputs['wifiMacAddress']>('wifiMacAddress');
  const [currentSoftwareVersion, setCurrentSoftwareVersion] = useFs<
    FormInputs['currentSoftwareVersion']
  >('currentSoftwareVersion');

  /**
   * Submits the step data and switches to the next step
   * @param inputsData
   */
  const handleSubmitData = (inputsData: Partial<FormInputs>) => {
    const submitData: Partial<DeviceCreationStepsData> = {
      ...data,
      ...inputsData,
    };

    setData(submitData);
    onSubmit(submitData);
  };

  return (
    <>
      <StepsTitle currentStep={AddDeviceSteps.Indigo} className="mb-10" />

      <form onSubmit={handleSubmit(handleSubmitData)}>
        <div className="row g-5 g-xl-8 mw-100">
          <div className="col-xl-7 rounded border p-5 m-5 pb-0 pt-10 row">
            <div className="badge badge-light-warning fs-7 w-100 py-6 mb-7">
              {t('devices.add_device_modal.device_info')}
            </div>

            <div className="col-xl">
              <div>
                <label
                  htmlFor="playerBrandAndModel"
                  className="required fw-bold mb-2"
                >
                  {t('devices.add_device_modal.player_brand_and_model')}
                </label>
                <TextInput
                  name="playerBrandAndModel"
                  id="playerBrandAndModel"
                  placeholder={t('common.type_here')}
                  value={playerBrandAndModel}
                  onChange={setPlayerBrandAndModel}
                />
                <FormErrorMessage
                  name="playerBrandAndModel"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              <div>
                <label
                  htmlFor="playerSerialNumber"
                  className="required fw-bold mb-2"
                >
                  {t('devices.add_device_modal.player_serial_number')}
                </label>
                <TextInput
                  name="playerSerialNumber"
                  id="playerSerialNumber"
                  placeholder={t('common.type_here')}
                  value={playerSerialNumber}
                  onChange={setPlayerSerialNumber}
                />
                <FormErrorMessage
                  name="playerSerialNumber"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              <label htmlFor="playerSerialFile" className="col-form-label pt-0">
                {t('devices.add_device_modal.player_serial_number')}
              </label>
              <MemoizedFileUploader
                id="playerSerialFile"
                name="playerSerialFile"
                mutedText={t('devices.add_device_modal.upload_image')}
                accept=".png, .jpg, .jpeg"
                onChange={(files) => setPlayerSerialFile(files[0])}
                className="w-100 mt-2"
                containerClassName="justify-content-center"
              />
              <FormErrorMessage
                name="playerSerialFile"
                errors={errors}
                className={errorMessageClassNames}
              />

              <div className="mb-0">
                <label htmlFor="pinNumber" className="required fw-bold mb-2">
                  {t('devices.add_device_modal.pin_number')}
                </label>
                <TextInput
                  name="pinNumber"
                  id="pinNumber"
                  placeholder={t('common.type_here')}
                  value={pinNumber}
                  onChange={setPinNumber}
                />
                <FormErrorMessage
                  name="pinNumber"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              <div className="mb-0">
                <label htmlFor="signId" className="required fw-bold mb-2">
                  {t('devices.add_device_modal.sign_id')}
                </label>
                <TextInput
                  name="signId"
                  id="signId"
                  placeholder={t('common.type_here')}
                  value={signId}
                  onChange={setSignId}
                />
                <FormErrorMessage
                  name="signId"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
            </div>

            <div className="col-xl">
              <div className="mt-7 mb-0">
                <label htmlFor="macAddress" className="required fw-bold mb-2">
                  {t('devices.add_device_modal.mac_address')}
                </label>
                <TextInput
                  name="macAddress"
                  id="macAddress"
                  placeholder={t('common.type_here')}
                  value={macAddress}
                  onChange={setMacAddress}
                />
                <FormErrorMessage
                  name="macAddress"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              <div className="mt-7 mb-0">
                <label
                  htmlFor="wifiMacAddress"
                  className="required fw-bold mb-2"
                >
                  {t('devices.add_device_modal.wifi_mac_address')}
                </label>
                <TextInput
                  name="wifiMacAddress"
                  id="wifiMacAddress"
                  placeholder={t('common.type_here')}
                  value={wifiMacAddress}
                  onChange={setWifiMacAddress}
                />
                <FormErrorMessage
                  name="wifiMacAddress"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              <div className="mt-7 mb-0">
                <label
                  htmlFor="currentSoftwareVersion"
                  className="required fw-bold mb-2"
                >
                  {t('devices.add_device_modal.current_software_version')}
                </label>
                <TextInput
                  name="currentSoftwareVersion"
                  id="currentSoftwareVersion"
                  placeholder={t('common.type_here')}
                  value={currentSoftwareVersion}
                  onChange={setCurrentSoftwareVersion}
                />
                <FormErrorMessage
                  name="currentSoftwareVersion"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>

              <div className="mb-8">
                <label
                  htmlFor="dateProgrammed"
                  className="d-flex align-items-center fs-6"
                >
                  <span>{t('devices.add_device_modal.date_programmed')}</span>
                </label>
                <MemoizedDatePicker
                  name="dateProgrammed"
                  id="dateProgrammed"
                  value={new Date(dateProgrammed)}
                  placeholder={t('common.select_date')}
                  onChange={(selectedDate) =>
                    setDateProgrammed(selectedDate[0])
                  }
                  options={{ dateFormat: 'Y-m-d' }}
                />
                <FormErrorMessage name="dateProgrammed" errors={errors} />
              </div>

              <div className="mb-0 mt-5">
                <label htmlFor="programmedBy" className="required fw-bold mb-2">
                  {t('devices.add_device_modal.programmed_by')}
                </label>
                <TextInput
                  name="programmedBy"
                  id="programmedBy"
                  placeholder={t('common.type_here')}
                  value={programmedBy}
                  onChange={setProgrammedBy}
                />
                <FormErrorMessage
                  name="programmedBy"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
            </div>
          </div>

          <div className="col-xl rounded border p-5 m-5 pb-0 pt-10">
            <div className="badge badge-light-info fs-7 w-100 py-6 mb-7">
              {t('devices.add_device_modal.remote_access')}
            </div>

            <div>
              <label htmlFor="teamViewerId" className="required fw-bold mb-2">
                {t('devices.add_device_modal.team_viewer_id')}
              </label>
              <TextInput
                name="teamViewerId"
                id="teamViewerId"
                placeholder={t('common.type_here')}
                value={teamViewerId}
                onChange={setTeamViewerId}
              />
              <FormErrorMessage
                name="teamViewerId"
                errors={errors}
                className={errorMessageClassNames}
              />
            </div>

            <div className="mb-0">
              <label
                htmlFor="teamViewerLocationId"
                className="col-form-label pt-1"
              >
                {t('common.location')}
              </label>
              <MemoizedSelectLocation
                id="teamViewerLocationId"
                onChange={({ value }) => setTeamViewerLocationId(value)}
                initialValue={teamViewerLocationId}
                isClearable
              />
              <FormErrorMessage
                name="teamViewerLocationId"
                errors={errors}
                className="mt-1 min-h-20px"
              />
            </div>

            <label
              htmlFor="teamViewerCommentFile"
              className="col-form-label pt-1"
            >
              {t('devices.add_device_modal.team_viewer_info_label')}
            </label>
            <MemoizedFileUploader
              id="teamViewerInfoFile"
              name="teamViewerInfoFile"
              mutedText={t('devices.add_device_modal.upload_image')}
              accept=".png, .jpg, .jpeg"
              onChange={(files) => setTeamViewerInfoFile(files[0])}
              className="w-100 mt-2"
              containerClassName="justify-content-center"
            />
            <FormErrorMessage
              name="teamViewerInfoFile"
              errors={errors}
              className={errorMessageClassNames}
            />

            <div className="separator mb-7 mt-2" />

            <label
              htmlFor="teamViewerCommentFile"
              className="col-form-label pt-1"
            >
              {t('devices.add_device_modal.team_viewer_comment_label')}
            </label>
            <MemoizedFileUploader
              id="teamViewerCommentFile"
              name="teamViewerCommentFile"
              mutedText={t('devices.add_device_modal.upload_image')}
              accept=".png, .jpg, .jpeg"
              onChange={(files) => setTeamViewerCommentFile(files[0])}
              className="w-100 mt-2"
              containerClassName="justify-content-center"
            />
            <FormErrorMessage
              name="teamViewerCommentFile"
              errors={errors}
              className={errorMessageClassNames}
            />
          </div>

          <div className="position-relative mt-6 mb-4 w-100">
            <div className="separator position-absolute w-100 start-0" />
          </div>

          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={() => setMode(AddDeviceSteps.GeneralInfo)}
            >
              {t('common.prev_step')}
            </button>
            <button type="submit" className="btn btn-primary">
              {t('common.submit')}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
