import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateUsersGroupRequestData from '../../../../api/endpoints/usersGroups/interfaces/createUsersGroupRequestData.interface';
import AddUsersGroupSteps from '../../../../enums/steps/addUsersGroupSteps.enum';
import UsersGroup from '../../../../interfaces/usersGroup.interface';
import Modal from '../../../Modal';
import StepsTitle from '../StepsTitle';
import AssignmentTables from './AssigmentTables';

interface AssignProps {
  onSubmit?: (data: Partial<CreateUsersGroupRequestData>) => void;
  setMode: (mode: AddUsersGroupSteps) => void;
  data: Partial<CreateUsersGroupRequestData>;
  setData: (newData: Partial<CreateUsersGroupRequestData>) => void;
  group?: UsersGroup;
}

interface DefaultProps extends AssignProps {
  onSubmit: Required<AssignProps>['onSubmit'];
}

const Info = ({ onSubmit, setMode, data, setData, group }: DefaultProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StepsTitle currentStep={AddUsersGroupSteps.Assign} />
      <AssignmentTables
        group={group as UsersGroup}
        data={data}
        setData={setData}
      />
      <Modal.Separator withoutDefaultMargins className="mb-7" />
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-white text-primary"
          onClick={() => setMode(AddUsersGroupSteps.Info)}
        >
          {t('common.prev_step')}
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => onSubmit(data)}
        >
          {t('users_groups.create_group.save')}
        </button>
      </div>
    </>
  );
};

Info.defaultProps = {
  onSubmit: () => null,
};

export default Info;
