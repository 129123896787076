import React from 'react';
import Marquee from 'react-fast-marquee';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './loginAsHeader.module.scss';
import QuitConfirm from '../AdminPanel/LoginAsConfirm/QuitConfirm';
import config from '../../routing/config';
import GlobalState from '../../store/reducers/globalState.interface';
import WithSpinner from '../WithSpinner';

const LoginAsHeader = () => {
  const tmpArr = [];
  const history = useHistory();
  const user = useSelector((state: GlobalState) => state.userData.user);
  const [loginAsUserName, setLoginAsUserName] = React.useState(
    `${user.firstName} ${user.lastName}`,
  );

  const quit = () => {
    window.localStorage.removeItem('loginAsUserId');
    window.localStorage.removeItem('loginAsUserName');
    history.push(config.adminCustomers.route);
    window.location.reload();
  };

  for (let i = 0; i < 10; i += 1) {
    tmpArr.push(i);
  }
  tmpArr[10] = true;
  React.useEffect(() => {
    setLoginAsUserName(`${user.firstName} ${user.lastName}`);
  }, [user.lastName]);

  return (
    <>
      <div className={styles.wrapper}>
        <WithSpinner
          isLoading={
            !loginAsUserName ||
            loginAsUserName === 'undefined undefined' ||
            loginAsUserName === ''
          }
          size="md"
          className="min-w-100 text-white"
        >
          <Marquee gradient={false} className={styles.text}>
            {tmpArr.map((i) => (
              <span key={`loggedas-${i}`} className="me-5">
                Logged In as {loginAsUserName}
              </span>
            ))}
          </Marquee>
        </WithSpinner>
        <div className={styles.buttonWrapper}>
          <QuitConfirm handleConfirm={quit}>
            <div role="presentation" className={styles.button}>
              Quit X
            </div>
          </QuitConfirm>
        </div>
      </div>
    </>
  );
};

export default LoginAsHeader;
