import React from 'react';
import clsx from 'clsx';
import CardDropdown from '../../../../components/Dropdown/CardDropdown';

type Props = {
  title: string;
  children: React.ReactNode;
  onToggle: (isOpened: boolean) => void;
  isOpened: boolean;
  className?: string;
};

export default ({
  children,
  title,
  onToggle,
  isOpened = false,
  className = '',
}: Props) => {
  const handleOpen = () => onToggle(true);

  return (
    <div className="menu-item me-lg-1 ms-2">
      <CardDropdown
        className={className}
        show={isOpened}
        onToggle={onToggle}
        closeOnSelect
        renderClosed={false}
        toggle={
          <div
            role="button"
            tabIndex={-1}
            onKeyPress={handleOpen}
            onClick={handleOpen}
            className={clsx(
              'menu-item menu-accordion',
              className,
              'mb-0',
              isOpened && 'show',
            )}
          >
            <span className="menu-link">
              <span className="menu-title">{title}</span>
              <span className="menu-arrow" />
            </span>
          </div>
        }
      >
        {children}
      </CardDropdown>
    </div>
  );
};
