import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Facebook from '../../../components/Apps/Facebook';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import App from '../../../interfaces/app.interface';
import AppType from '../../../enums/appType.enum';
import api from '../../../api';

export default () => {
  const { search } = useLocation();
  const [apps, setApps] = useState<App[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const getAppList = async (recentSearch: string) => {
    try {
      setLoading(true);

      const { items, meta } = await api.apps.getApps(
        AppType.Facebook,
        readAllFromQueryParams(recentSearch),
      );

      setApps(items);
      setTotal(meta.totalItems);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAppList(search);
  }, [search]);
  return (
    <Facebook
      facebook={apps}
      total={total}
      isLoading={isLoading}
      onUpdate={() => getAppList(search)}
    />
  );
};
