import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import CreateSevereWeatherLocation from './Steps/CreateSevereWeatherLocation';
import WithModesModal from '../../../WithModesModal';
import AssignSevereWeatherLocation from './Steps/AssignSevereWeatherLocation';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import AddSevereWeatherSteps from '../../../../enums/steps/addSevereWeatherSteps.enum';
import sizingStyles from '../../../../styles/sizing.module.scss';
import validation from '../../../../utils/validation';
import CountryCode from '../../../../enums/countryCode.enum';

export default ({
  onSubmit,
  isVisible,
  onClose,
  isLoading,
}: {
  onSubmit: (data: CreateAppRequestData) => void;
  onClose?: () => void;
  isVisible: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] = useState<Partial<CreateAppRequestData>>({});

  return (
    <WithModesModal<Partial<CreateAppRequestData>, AddSevereWeatherSteps>
      isVisible={isVisible}
      isLoading={isLoading}
      onClose={onClose}
      modes={{
        [AddSevereWeatherSteps.CreateSevereWeatherLocation]: {
          title: t('apps.severeWeather.createModalTitle'),
          render: ({ setMode, close }) => (
            <CreateSevereWeatherLocation
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onDecline={close}
              validationSchema={{
                name: validation
                  .string()
                  .min(1, t('devices.validation.please_enter_device_name'))
                  .required(),
                countryId: validation
                  .string()
                  .min(1)
                  .required(t('apps.severeWeather.validation.country')),
                stateId: validation
                  .string()
                  .when('countryId', (countryId, schema) =>
                    countryId !== CountryCode.Ca
                      ? schema.required(
                          t('apps.severeWeather.validation.state'),
                        )
                      : schema,
                  ),
                countyIds: validation
                  .array()
                  .when('countryId', (countryId, schema) =>
                    countryId === CountryCode.Us || countryId === CountryCode.Ca
                      ? schema
                          .min(1, t('apps.severeWeather.validation.common'))
                          .required(t('apps.severeWeather.validation.common'))
                      : schema,
                  ),
              }}
            />
          ),
        },
        [AddSevereWeatherSteps.AssignSevereWeatherLocation]: {
          title: t('apps.severeWeather.createModalTitle'),
          render: ({ setMode }) => (
            <AssignSevereWeatherLocation
              setMode={setMode}
              data={stepsData}
              onSubmit={(data) => {
                onSubmit(data as CreateAppRequestData);

                setStepsData({});
              }}
            />
          ),
        },
      }}
      defaultMode={AddSevereWeatherSteps.CreateSevereWeatherLocation}
      dialogClassName={clsx('mw-1000px', sizingStyles.fitContent)}
    />
  );
};
