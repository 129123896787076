import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import Table from '../../../Table';
import { UserStatus } from '../../../../enums/userStatus.enum';
import UsersGroup from '../../../../interfaces/usersGroup.interface';
import RemoveGroup from '../../../../containers/UsersGroups/RemoveGroup';
import { statusesTranslation } from '../../../../constants/translation/users';
import UpdateUsersGroupAction from '../../../../store/actions/usersGroups/interfaces/updateUsersGroupAction.interface';
import EditGroup from '../../../../containers/UsersGroups/EditGroup';
import routesConfig from '../../../../routing/config';
import CheckBox from '../../../CheckBox';
import styles from '../../usersGroups.module.scss';
import GlobalState from '../../../../store/reducers/globalState.interface';
import { UserRole } from '../../../../enums/userRole.enum';
import { readFromQueryString } from '../../../../utils/queryParams';

const { Row } = Table.Body;
const { Cell } = Row;

interface TableRowProps {
  group: UsersGroup;
  config?: {
    skipActions?: boolean;
    isSelectable?: boolean;
  };
  isChecked?: boolean;
  onCheck?: (id: string) => void;
  onClick?: () => void;
  unAssignUserGroup?: (deviceGroupId: string, userGroupId: string) => void;
  onEditUserGroup?: (data: UpdateUsersGroupAction['payload']) => void;
  onDeleteUserGroup?: (id: string) => void;
  userProfileId?: string;
}

interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
  isChecked: Required<TableRowProps>['isChecked'];
  onCheck: Required<TableRowProps>['onCheck'];
}

export default ({
  group,
  config,
  isChecked,
  onCheck,
  onClick,
  unAssignUserGroup,
  onEditUserGroup,
  onDeleteUserGroup,
  userProfileId,
}: DefaultProps) => {
  const { t } = useTranslation();
  const { id, roles } = useSelector(
    (state: GlobalState) => state?.userData?.user,
  );
  const isGroupOwner = (
    groupOwnerId: string,
    connectedUserId: string,
  ): boolean => groupOwnerId === connectedUserId;
  const { search } = useLocation();
  const currentUserId = readFromQueryString(search, 'currentUserId') as string;
  return (
    <Row>
      {config.isSelectable && (
        <Cell>
          <CheckBox
            name="groupId"
            id={group.id}
            onChange={() => onCheck(group.id)}
            checked={isChecked}
            className="mt-1"
          />
        </Cell>
      )}
      <Cell isNoWrap>
        <div
          role="presentation"
          className={clsx(styles.div, 'text-nowrap d-flex align-items-center')}
          onClick={onClick}
        >
          {roles.includes(UserRole.SuperAdmin) ? (
            <Link
              to={routesConfig.usersGroup.route
                .replace(':id', group.id)
                .replace(
                  ':page',
                  `users?currentUserId=${userProfileId ?? currentUserId}`,
                )}
              className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
            >
              {group.name}
            </Link>
          ) : (
            <Link
              to={routesConfig.usersGroup.route
                .replace(':id', group.id)
                .replace(':page', 'users')}
              className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6"
            >
              {group.name}
            </Link>
          )}
        </div>
      </Cell>
      <Cell isEmpty={!group.company?.name}>
        <div
          role="presentation"
          className={clsx(
            styles.div,
            'text-dark fw-bold d-block mb-1 fs-6 text-center',
          )}
          onClick={onClick}
        >
          {group.company?.name}
        </div>
      </Cell>
      <Cell isNoWrap className="text-center" isEmpty={!group.status}>
        <div
          role="presentation"
          className={clsx(
            styles.div,
            'badge fs-7 py-3 px-7 fw-bold',
            group.status === UserStatus.Active
              ? 'badge-light-success'
              : 'badge-light',
          )}
          onClick={onClick}
        >
          {t(statusesTranslation[group.status] ?? group.status)}
        </div>
      </Cell>
      <Cell>
        <div
          role="presentation"
          className={clsx(
            styles.div,
            'text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center',
          )}
          onClick={onClick}
        >
          {group.usersCount}
        </div>
      </Cell>
      <Cell isEmpty={!group.location?.name}>
        <div
          role="presentation"
          className={clsx(
            styles.div,
            'text-dark fw-bold d-block mb-1 fs-6 text-center',
          )}
          onClick={onClick}
        >
          {group.location?.name}
        </div>
      </Cell>
      <Cell isNoWrap isTextEnd>
        <div className="d-flex justify-content-end">
          {!unAssignUserGroup && (
            <div className="mx-1">
              <EditGroup group={group} onEditUserGroup={onEditUserGroup} />
            </div>
          )}

          <div className={clsx('mx-1', group.usersCount)}>
            {unAssignUserGroup && (
              <RemoveGroup
                group={group}
                unAssignUserGroup={unAssignUserGroup}
              />
            )}

            {!unAssignUserGroup &&
              (onDeleteUserGroup || isGroupOwner(group?.owner?.id, id)) && (
                <RemoveGroup
                  group={group}
                  onDeleteUserGroup={onDeleteUserGroup}
                />
              )}
          </div>
        </div>
      </Cell>
    </Row>
  );
};
