import React from 'react';

import { formatDateString } from '../../../../utils/dateTime';
import Table from '../../../Table';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({ content, meta }: { content: any; meta: any }) => {
  return (
    <Row>
      <Cell>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {content}
        </span>
      </Cell>

      <Cell isEmpty={!meta?.zone}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {meta?.zone}
        </span>
      </Cell>
      <Cell isEmpty={!meta?.startDate}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {meta?.startDate && formatDate(meta?.startDate)}
        </span>
      </Cell>
      <Cell isEmpty={!meta?.endDate}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {meta?.endDate && formatDate(meta?.endDate)}
        </span>
      </Cell>
      <Cell isEmpty={!meta?.duration}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {meta?.duration}
        </span>
      </Cell>
      <Cell isEmpty={!meta?.tags}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {meta?.tags}
        </span>
      </Cell>
    </Row>
  );
};
export default TableRow;
