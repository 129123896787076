import axios from 'axios';
import { API_URL } from '../../../constants/api';
import BannerRoutes from './enums/bannersRoutes.enum';
import Banner from '../../../interfaces/banner.interface';
import UpdateBannerRequestData from './interfaces/updateBannerRequestData.interface';
import DeleteBannerRequestData from './interfaces/deleteBannerRequestData.interface';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import GetBannersResponseData from './interfaces/getBannersResponseData.interface';

export default {
  /**
   * Get Banner
   * @param bannerId
   */
  getBanners: async (requestData: {
    [PaginationQueryParam.Page]: number;
    [PaginationQueryParam.Limit]: number;
    search: string;
  }): Promise<GetBannersResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${BannerRoutes.Banner}/`,
      headers: { 'Content-Type': 'multipart/form-data' },
      params: requestData,
    });
    return data;
  },
  /**
   * Get Banner
   * @param bannerId
   */
  getBannerById: async (bannerId: string): Promise<Banner> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${BannerRoutes.Banner}/${bannerId}`,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  },
  /**
   * Get Latest active Banner (if any)
   * @param bannerId
   */
  getCurrentlyActiveBanner: async (): Promise<Banner[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${BannerRoutes.Banner}/currently-active`,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  },

  /**
   * Creates a new Banner
   * @param requestData
   */
  createBanner: async (requestData: Banner): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${BannerRoutes.Banner}`,
      headers: { 'Content-Type': 'application/json' },
      data: requestData,
    });
  },

  /**
   * Updates the Banner
   * @param requestData
   * @param id
   */
  updateBanner: async (
    requestData: UpdateBannerRequestData,
    id: string,
  ): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${BannerRoutes.Banner}/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: requestData,
    });
  },

  /**
   * Deletes Banner by id
   * @param requestData
   */
  deleteBanner: async (requestData: DeleteBannerRequestData): Promise<void> => {
    await axios.delete(`${API_URL}/${BannerRoutes.Banner}/${requestData.id}`);
  },
};
