import CreateDeviceGroup from './interfaces/createDeviceGroup.interface';
import GetDevicesGroupsSuccessAction from './interfaces/getDevicesGroupsSuccessAction.interface';
import DeleteDeviceGroupAction from './interfaces/deleteDeviceGroupAction.interface';
import UpdateDeviceGroupAction from './interfaces/updateDeviceGroupAction.interface';
import GetDevicesGroupsAction from './interfaces/getDevicesGroupsAction.interface';
import DevicesGroupsActions from './devicesGroupsActions.enum';
import LoadingAction from '../loadingAction.interface';
import GetDeviceGroupAction from './interfaces/getDeviceGroupAction.interface';
import GetDeviceGroupSuccessAction from './interfaces/getDeviceGroupSuccessAction.interface';
import AssignUsersToDeviceGroupAction from './interfaces/assignUsersToDeviceGroupAction.interface';
import RemoveUsersFromDeviceGroupAction from './interfaces/removeUsersFromDeviceGroupAction.interface';
import GetDeviceGroupUsersAction from './interfaces/getDeviceGroupUsersAction.interface';
import GetDeviceGroupUsersSuccessAction from './interfaces/getDeviceGroupUsersSuccessAction.interface';

export const createDeviceGroup = (
  payload: CreateDeviceGroup['payload'],
): CreateDeviceGroup => ({
  type: DevicesGroupsActions.CREATE_DEVICES_GROUP,
  payload,
});

export const getDevicesGroups = (
  payload: GetDevicesGroupsAction['payload'],
): GetDevicesGroupsAction => ({
  type: DevicesGroupsActions.GET_DEVICES_GROUPS,
  payload,
});

export const getDevicesGroupsSuccess = (
  payload: GetDevicesGroupsSuccessAction['payload'],
): GetDevicesGroupsSuccessAction => ({
  type: DevicesGroupsActions.GET_DEVICES_GROUPS_SUCCESS,
  payload,
});

export const getDeviceGroup = (
  payload: GetDeviceGroupAction['payload'],
): GetDeviceGroupAction => ({
  type: DevicesGroupsActions.GET_DEVICE_GROUP,
  payload,
});

export const getDeviceGroupSuccess = (
  payload: GetDeviceGroupSuccessAction['payload'],
): GetDeviceGroupSuccessAction => ({
  type: DevicesGroupsActions.GET_DEVICE_GROUP_SUCCESS,
  payload,
});

export const updateDeviceGroup = (
  payload: UpdateDeviceGroupAction['payload'],
): UpdateDeviceGroupAction => ({
  type: DevicesGroupsActions.UPDATE_DEVICE_GROUP,
  payload,
});

export const assignUsersToDeviceGroup = (
  payload: AssignUsersToDeviceGroupAction['payload'],
): AssignUsersToDeviceGroupAction => ({
  type: DevicesGroupsActions.ASSIGN_USERS_TO_DEVICE_GROUP,
  payload,
});

export const removeUsersFromDeviceGroup = (
  payload: RemoveUsersFromDeviceGroupAction['payload'],
): RemoveUsersFromDeviceGroupAction => ({
  type: DevicesGroupsActions.REMOVE_USERS_FROM_DEVICE_GROUP,
  payload,
});

export const deleteDeviceGroup = (
  payload: DeleteDeviceGroupAction['payload'],
): DeleteDeviceGroupAction => ({
  type: DevicesGroupsActions.DELETE_DEVICE_GROUP,
  payload,
});

export const setDevicesGroupsLoading = (
  payload: LoadingAction['payload'],
): LoadingAction => ({
  type: DevicesGroupsActions.SET_DEVICES_GROUPS_LOADING,
  payload,
});

export const getDeviceGroupUsers = (
  payload: GetDeviceGroupUsersAction['payload'],
): GetDeviceGroupUsersAction => ({
  type: DevicesGroupsActions.GET_DEVICE_GROUP_USERS,
  payload,
});

export const getDeviceGroupUsersSuccess = (
  payload: GetDeviceGroupUsersSuccessAction['payload'],
): GetDeviceGroupUsersSuccessAction => ({
  type: DevicesGroupsActions.GET_DEVICE_GROUP_USERS_SUCCESS,
  payload,
});
