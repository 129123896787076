import { FileMimeType } from '../store/types/fileManager';

export const imageFileTypes = [
  FileMimeType.JPG,
  FileMimeType.JPEG,
  FileMimeType.PNG,
  FileMimeType.GIF,
];
export const videoFileTypes = [
  FileMimeType.MP4,
  FileMimeType.VIDEO,
  FileMimeType.MPEG,
  FileMimeType.MOV,
  FileMimeType.HVEC,
  FileMimeType.WEBM,
  FileMimeType.AVI,
  FileMimeType.FLV,
];

export const mediaFileTypes = [...imageFileTypes, ...videoFileTypes];
