/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { isEqual, map, pick } from 'lodash';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import DayOfWeek from '../../../../../enums/dayOfWeek.enum';
import { SelectedValue } from '../../../../Select/CreatableAsyncSelect';
import { prepareHookFromState } from '../../../../../utils/hooks/useStateWithHookForm';
import CreateContentRequestData from '../../../../../api/endpoints/contents/interfaces/createContentRequestData.interface';
import { TagsTarget } from '../../../../../api/endpoints/tags/enums/tagsTarget.enum';
import AddContentSteps from '../../../../../enums/steps/addContentSteps.enum';
import orientations from '../../../../../partials/orientations';
import Orientation from '../../../../../enums/orientation.enum';
import SelectTags from '../../../../../containers/SelectTags';
import FormErrorMessage from '../../../../FormErrorMessage';
import SwitchCheckbox from '../../../../SwitchCheckbox';
import SelectMode from '../../../../Select/SelectMode';
import FileUploader from '../../../../FileUploader';
import RadioButtons from '../../../../RadioButtons';
import DatePicker from '../../../../DatePicker';
import TextInput from '../../../../TextInput';
import StepsTitle from '../../StepsTitle';
import Counter from '../../../../Counter';
import Modal from '../../../../Modal';
import SelectDaysOfWeek, {
  getDayOfWeekOption,
} from '../../../../../containers/SelectDaysOfWeek';
import WithSpinner from '../../../../WithSpinner';
import { UserRole } from '../../../../../enums/userRole.enum';
import RestrictedAccess from '../../../../../containers/RestrictedAccess';
import { imageFileTypes, videoFileTypes } from '../../../../../constants/file';
import { FileMimeType } from '../../../../../store/types/fileManager';
import commonStyles from '../../../../../styles/common.module.scss';
import contentUploadStyles from '../../contentUpload.module.scss';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

type FieldsData = Partial<CreateContentRequestData>;

interface FormInputs {
  name: string;
  orientation: Orientation;
  files?: File[];
  duration: number;
  startDate: Date;
  endDate: Date;
  lockContent: boolean;
  isOrdered: boolean;
  isSeparate: boolean;
  tags: string[];
  weekDays: DayOfWeek[];
}

interface InfoStepProps {
  data: FieldsData;
  setData: (newData: FieldsData) => void;
  onDecline: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  validationSchema: { [key: string]: any };
  setMode: (mode: AddContentSteps) => void;
  onSubmit: (submitData?: Partial<CreateContentRequestData>) => void;
  includeFileUpload: boolean;
  isUploadingFile?: boolean;
  skipSteps?: AddContentSteps[];
  minDuration?: number;
  onFileDataUpdate?: (data: {
    file?: File;
    isSeparate?: boolean;
    isOrdered?: boolean;
    orientation?: Orientation;
  }) => Promise<boolean>;
}

interface DefaultProps extends InfoStepProps {
  skipSteps: Required<InfoStepProps>['skipSteps'];
  onFileDataUpdate: Required<InfoStepProps>['onFileDataUpdate'];
}

const Info = ({
  data,
  setData,
  onDecline,
  setMode,
  includeFileUpload,
  skipSteps,
  onSubmit,
  minDuration,
  onFileDataUpdate,
  isUploadingFile,
}: DefaultProps) => {
  const { t } = useTranslation();
  const getDuration = Number(data.duration);
  const defaultValues: Partial<FormInputs> = {
    name: data.name ?? '',
    orientation: data.zoneId ?? Orientation.Auto,
    files: (() => {
      if (includeFileUpload) {
        if (data.file) {
          return [data.file];
        }
        return [];
      }

      return undefined;
    })(),
    duration: getDuration && !Number.isNaN(getDuration) ? getDuration : 15,
    startDate: data.startDate ? new Date(data.startDate as string) : undefined,
    endDate: data.endDate ? new Date(data.endDate as string) : undefined,
    lockContent: data.isLocked,
    isOrdered: data.isOrdered ?? false,
    isSeparate: data.isSeparate ?? true,
    tags: data.tags ?? [],
    weekDays: data.weekDays ?? [],
  };
  const validationSchemas = Yup.object({
    name: Yup.string().required(t('common.requiredField')),
    orientation: Yup.string().required(t('common.requiredField')),
    files: Yup.array().when('isSeparate', {
      is: true,
      then: Yup.array().min(1, t('common.requiredField')),
    }),
    duration: Yup.number()
      .required(t('common.requiredField'))
      .min(minDuration ?? 1, t('validation.min', { min: minDuration ?? 1 })),
    startDate: Yup.date(),
    endDate: Yup.date().test(
      'is-greater-than-start-date',
      t('common.endDateIsGreaterThanStartDate'),
      function (endDate) {
        const { startDate } = this.parent;
        if (startDate && endDate) {
          return startDate && endDate && startDate < endDate;
        }
        return true;
      },
    ),

    lockContent: Yup.boolean(),
  }).defined();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validationSchemas),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [name, setName] = useFs<FormInputs['name']>('name');
  const [tags, setTags] = useFs<FormInputs['tags']>('tags');
  const [weekDays, setDays] = useFs<FormInputs['weekDays']>('weekDays');
  const [duration, setDuration] = useFs<FormInputs['duration']>('duration');
  const [startDate, setStartDate] = useFs<FormInputs['startDate']>('startDate');
  const [endDate, setEndDate] = useFs<FormInputs['endDate']>('endDate');
  const [isOrdered, setIsOrdered] = useFs<FormInputs['isOrdered']>('isOrdered');
  const [isSeparate, setIsSeparate] =
    useFs<FormInputs['isSeparate']>('isSeparate');
  const [files, setFiles] = useFs<FormInputs['files']>('files');
  const [orientation, setOrientation] =
    useFs<FormInputs['orientation']>('orientation');
  const [isContentLocked, setIsContentLocked] =
    useFs<FormInputs['lockContent']>('lockContent');

  const [isFilesLoading, setIsFilesLoading] = useState(false);
  const [dimensions, setDimensions] = useState<
    { width: number; height: number } | undefined
  >();

  let nextStep: AddContentSteps | undefined;

  if (
    [AddContentSteps.DevicesAndGroups, AddContentSteps.Playlists].every(
      (step) => skipSteps.includes(step),
    )
  ) {
    nextStep = undefined;
  } else if (skipSteps.includes(AddContentSteps.DevicesAndGroups)) {
    nextStep = AddContentSteps.Playlists;
  } else {
    nextStep = AddContentSteps.DevicesAndGroups;
  }

  const updateOrientation = (orientationValue: Orientation) => {
    if (orientation === orientationValue) return;

    setOrientation(orientationValue);
    onFileDataUpdate({ orientation: orientationValue });
  };

  const updateIsSeparate = (isSeparateValue: boolean) => {
    if (isSeparate === isSeparateValue) return;

    setIsSeparate(isSeparateValue);
    onFileDataUpdate({ isSeparate: isSeparateValue });
  };

  const updateIsOrdered = (isOrderedValue: boolean) => {
    if (isOrdered === isOrderedValue) return;

    setIsOrdered(isOrderedValue);
    onFileDataUpdate({ isOrdered: isOrderedValue });
  };

  /**
   * Submits the step data and switches to the next step
   * @param inputsData
   */
  const handleSubmitData = (inputsData: Partial<FormInputs>) => {
    const resultData = {
      ...data,
      name: inputsData.name,
      zoneId: inputsData.orientation,
      file: (() => {
        if (includeFileUpload) {
          return (inputsData.files as File[])[0];
        }

        return undefined;
      })(),
      duration: String(inputsData.duration),
      startDate: inputsData.startDate?.toISOString(),
      endDate: inputsData.endDate?.toISOString(),
      isLocked: inputsData.lockContent,
      isOrdered: inputsData.isOrdered,
      isSeparate: inputsData.isSeparate,
      tags: inputsData.tags,
      weekDays: inputsData.weekDays,
      width: dimensions?.width ?? data.width,
      height: dimensions?.height ?? data.height,
    };
    if (startDate && endDate && startDate > endDate) {
      toast.error(t('common.validation.endDate'));
      return;
    }
    if (nextStep) {
      setData(resultData);
      setMode(nextStep);
    } else {
      onSubmit(resultData);
      setData(resultData);
    }
  };

  const handleUpload = async (selectedFiles: File[]) => {
    if (selectedFiles.length) {
      if (await onFileDataUpdate({ file: selectedFiles[0] })) {
        setFiles(selectedFiles);
      }
    } else {
      setFiles([]);

      onFileDataUpdate({ file: undefined });

      return;
    }

    setIsFilesLoading(true);

    const file: File = selectedFiles[0];
    const fileType = file?.type as FileMimeType;

    if (videoFileTypes.includes(fileType)) {
      const video = document.createElement('video');

      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        setDuration(Number(video.duration.toFixed(2)));
        setIsFilesLoading(false);

        URL.revokeObjectURL(video.src);
      };

      video.onerror = () => setIsFilesLoading(false);

      video.src = URL.createObjectURL(file);
    }

    if (imageFileTypes.includes(fileType)) {
      const img = new Image();

      const objectUrl = URL.createObjectURL(file);

      img.onload = function handleLoadImage() {
        const { width, height } = this as unknown as {
          width: number;
          height: number;
        };

        setDimensions({ width, height });
        setIsFilesLoading(false);
        URL.revokeObjectURL(objectUrl);
      };

      img.onerror = () => setIsFilesLoading(false);

      img.src = objectUrl;
    } else {
      setIsFilesLoading(false);
    }
  };

  const [visible, setVisible] = useState<boolean>(true);

  const open = () => setVisible(true);

  useEffect(() => {
    if (window.innerWidth < 426) {
      setVisible(false);
    }
    onFileDataUpdate(pick(defaultValues, ['isOrdered', 'isSeparate']));
  }, []);

  return (
    <>
      <StepsTitle currentStep={AddContentSteps.Info} skipSteps={skipSteps} />

      <form onSubmit={handleSubmit(handleSubmitData)}>
        {includeFileUpload ? (
          <div>
            <WithSpinner
              isLoading={isUploadingFile || isFilesLoading}
              className="h-100px"
            >
              <FileUploader
                name="files"
                mutedText={t('contents.upload_image')}
                accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .mp4, .gif"
                onChange={handleUpload}
                defaultValue={files}
                className="w-100 mt-2"
                containerClassName="justify-content-center"
              />
            </WithSpinner>
            <FormErrorMessage
              name="files"
              errors={errors}
              className={errorMessageClassNames}
            />
          </div>
        ) : null}

        <div className="row mb-2">
          <div className="col-12 col-md-6 col-lg-6  mw-100 p-10 rounded border">
            <label htmlFor="contentName" className="required fw-bold mb-2">
              {t('contents.add_title')}
            </label>
            <TextInput
              name="name"
              id="contentName"
              placeholder={t('contents.type_here')}
              value={name}
              onChange={setName}
              useDisabledStyles
            />
            <FormErrorMessage
              name="name"
              errors={errors}
              className={errorMessageClassNames}
            />

            <label
              htmlFor="contentOrientation"
              className="required fw-bold mb-2"
            >
              {t('common.orientation')}
            </label>
            <SelectMode<Orientation>
              asInput
              className="flex-wrap"
              inputName="orientation"
              inputId="contentOrientation"
              modes={orientations({
                landscapeText: t('common.landscape'),
                autoText: t('common.auto_select'),
                fullScreenText: t('common.full_screen'),
                portraitText: t('common.portrait'),
              })}
              modeValue={orientation}
              onSelect={(_orientation) => {
                setOrientation(_orientation);
                updateOrientation(_orientation);
              }}
            />
            <FormErrorMessage
              name="orientation"
              errors={errors}
              className={errorMessageClassNames}
            />
            {!visible && (
              <p className="text-center">
                <button
                  type="button"
                  className="text-primary border-0 bg-white"
                  onClick={open}
                  onKeyPress={open}
                >
                  <i className="fas fa-angle-down" /> Show more
                </button>
              </p>
            )}
            {visible && (
              <>
                <Counter
                  name="duration"
                  title={t('common.duration')}
                  placeholder={t('contents.type_here')}
                  value={duration}
                  onChange={setDuration}
                  className={contentUploadStyles.duration}
                  min={minDuration}
                  isLocked={
                    files?.some(({ type }) =>
                      videoFileTypes.includes(type as FileMimeType),
                    ) || getDuration > 0
                  }
                />
                <FormErrorMessage
                  name="duration"
                  errors={errors}
                  className={errorMessageClassNames}
                />

                <div className={clsx(isUploadingFile && commonStyles.disabled)}>
                  <span className="form-label d-flex fw-bold me-5">
                    {t('contents.select_upload_as')}:
                  </span>
                  <RadioButtons
                    name="isSeparate"
                    onChange={(value) => updateIsSeparate(value === 'true')}
                    options={[
                      {
                        label: t('contents.is_separate.one_file'),
                        value: 'false',
                        key: 'one',
                        checked: !isSeparate,
                      },
                      {
                        label: t('contents.is_separate.separate'),
                        value: 'true',
                        key: 'separate',
                        checked: !!isSeparate,
                      },
                    ]}
                  />
                  <FormErrorMessage
                    name="isSeparate"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>

                <div className={clsx(isUploadingFile && commonStyles.disabled)}>
                  <span className="form-label d-flex fw-bold mb-4">
                    {t('contents.is_ordered')}
                  </span>
                  <RadioButtons
                    name="isOrdered"
                    onChange={(value) => updateIsOrdered(value === 'true')}
                    options={[
                      {
                        label: t('contents.order.ordered'),
                        value: 'true',
                        key: 'ordered',
                        checked: !!isOrdered,
                      },
                      {
                        label: t('contents.order.random'),
                        value: 'false',
                        key: 'random',
                        checked: isOrdered === false,
                      },
                    ]}
                  />
                  <FormErrorMessage
                    name="isOrdered"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </div>
              </>
            )}
          </div>

          {visible && (
            <div className="col-12 col-md-6 col-lg-6 position-relative">
              <div className="rounded border p-10 pb-5 mb-5">
                <div className="mb-3">
                  <span className="text-dark fw-bolder text-hover-primary fs-4">
                    {t('contents.schedule_title')}
                  </span>
                </div>

                <div className="fv-row mb-5">
                  <label
                    htmlFor="startDate"
                    className="d-flex align-items-center fs-6"
                  >
                    <span>{t('playlists.start_date')}</span>
                  </label>
                  <DatePicker
                    name="startDate"
                    value={startDate}
                    placeholder="Select start date - time"
                    onChange={(newDate) => setStartDate(newDate[0])}
                    options={{
                      enableTime: true,
                      defaultHour: 0,
                    }}
                  />
                  <FormErrorMessage name="startDate" errors={errors} />
                </div>
                <div className="fv-row mb-10">
                  <label
                    htmlFor="endDate"
                    className="d-flex align-items-center fs-6"
                  >
                    <span>{t('playlists.end_date')}</span>
                  </label>
                  <DatePicker
                    name="endDate"
                    value={endDate}
                    placeholder="Select end date - time"
                    onChange={(newDate) => setEndDate(newDate[0])}
                    options={{
                      enableTime: true,
                      defaultHour: 23,
                      defaultMinute: 59,
                    }}
                  />
                  <FormErrorMessage name="endDate" errors={errors} />
                </div>

                <div className="separator separator-content text-muted mb-7 mt-2">
                  {t('contents.and_or')}
                </div>

                <span className="fw-bold mb-4">{t('contents.mtwtfss')}</span>
                <SelectDaysOfWeek
                  initialValue={weekDays.map((day) =>
                    getDayOfWeekOption(day, t),
                  )}
                  onChange={(selectedDays) =>
                    setDays(selectedDays as DayOfWeek[])
                  }
                />
              </div>
              <RestrictedAccess
                allowedRoles={[
                  UserRole.SuperAdmin,
                  UserRole.Admin,
                  UserRole.AccountOwner,
                  UserRole.Manager,
                  UserRole.IndigoAdmin,
                  UserRole.Editor,
                ]}
              >
                <div className="d-inline-flex justify-content-between w-100">
                  <label
                    htmlFor="lockContent"
                    className="form-label fw-bold pt-1"
                  >
                    {t('contents.lock_content')}
                  </label>
                  <SwitchCheckbox
                    name="lockContent"
                    inputClassName="h-20px w-30px"
                    checked={!!isContentLocked}
                    onChange={({ currentTarget: { checked } }) =>
                      setIsContentLocked(checked)
                    }
                  />
                </div>
              </RestrictedAccess>
              <label htmlFor="contentTag" className="fw-bold mb-2">
                {t('contents.tag')}
              </label>
              <SelectTags
                id="contentTag"
                target={TagsTarget.Contents}
                initialValue={tags.map((value) => ({ value, label: value }))}
                onChange={(values) => {
                  const newValues = map<SelectedValue>(values, 'value');

                  if (!isEqual(newValues, tags)) {
                    setTags(newValues);
                  }
                }}
              />
              <FormErrorMessage
                name="tag"
                errors={errors}
                className={errorMessageClassNames}
              />
            </div>
          )}
        </div>

        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-white text-primary"
            onClick={onDecline}
          >
            {t('common.close')}
          </button>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className={clsx(
                'btn btn-primary ',
                isUploadingFile && commonStyles.disabled,
              )}
            >
              {nextStep ? t('common.next_step') : t('common.submit')}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

Info.defaultProps = {
  skipSteps: [],
  onFileDataUpdate: () => null,
};

export default Info;
