import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateDeviceGroupRequestData from '../../../../api/endpoints/devicesGroups/interfaces/createDeviceGroupRequestData.interface';
import AddDevicesGroupSteps from '../../../../enums/steps/addDevicesGroupSteps.enum';
import DeviceGroup from '../../../../interfaces/deviceGroup.interface';
import Modal from '../../../Modal';
import StepsTitle from '../StepsTitle';
import AssignmentTables from './AssignmentTables';

interface AssignProps {
  onSubmit?: (data: Partial<CreateDeviceGroupRequestData>) => void;
  setMode: (mode: AddDevicesGroupSteps) => void;
  data: Partial<CreateDeviceGroupRequestData>;
  setData: (newData: Partial<CreateDeviceGroupRequestData>) => void;
  isEdit?: boolean;
  group?: DeviceGroup;
}

interface DefaultProps extends AssignProps {
  onSubmit: Required<AssignProps>['onSubmit'];
}

const Info = ({
  onSubmit,
  setMode,
  data,
  setData,
  isEdit,
  group,
}: DefaultProps) => {
  const { t } = useTranslation();

  return (
    <>
      <StepsTitle currentStep={AddDevicesGroupSteps.Assign} />
      <AssignmentTables group={group} data={data} setData={setData} />
      <Modal.Separator withoutDefaultMargins className="mb-7" />
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-white text-primary"
          onClick={() => setMode(AddDevicesGroupSteps.Info)}
        >
          {t('common.prev_step')}
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={!data?.deviceIds?.length}
          onClick={() => onSubmit(data)}
        >
          {isEdit
            ? t('devices_groups.edit_device_group.edit_group')
            : t('devices_groups.create_group.save')}
        </button>
      </div>
    </>
  );
};

Info.defaultProps = {
  onSubmit: () => null,
};

export default Info;
