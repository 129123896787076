import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { disableRightClickIframe } from '../../../utils/common';
import Modal from '../../Modal';
import styles from './playlistPreviewModal.module.scss';

interface IPlaylistPreviewModal {
  url: string;
  children: React.ReactNode;
}
const PlaylistPreviewModal: FC<IPlaylistPreviewModal> = ({ url, children }) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  React.useEffect(() => {
    disableRightClickIframe(url);
  }, []);

  return (
    <div onContextMenu={(ev) => ev.preventDefault()}>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        hideButton
        title={t('common.preview')}
        show={isModalVisible}
        onHide={close}
        withFooter={false}
        widthDefaultSize
        bodyClassName={clsx(styles.dialog_body)}
      >
        <iframe
          src={url}
          title={t('common.preview')}
          className={clsx(styles.iframe_content)}
        />
      </Modal>
    </div>
  );
};

export default PlaylistPreviewModal;
