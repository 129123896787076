import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import SelectAsync, {
  SelectedValue,
} from '../../components/Select/SelectAsync';
import CountryCode from '../../enums/countryCode.enum';
import useDidUpdate from '../../utils/hooks/useDidUpdate';

/**
 * Retrieves the select options asynchronously
 * @param search
 */
const loadOptions = async (search: string): Promise<SelectedValue[]> => {
  const { items } = await api.countries.getCountries({ search });

  return sortBy(items, ['name']).map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

const SelectLocation = ({
  onChange,
  initialValue,
  id,
  className,
  isClearable,
  preselect,
  observeInitialValue,
  disabled,
}: {
  onChange: (selectedValue: SelectedValue) => void;
  initialValue?: string;
  id?: string;
  className?: string;
  isClearable?: boolean;
  preselect?: boolean;
  observeInitialValue?: boolean;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState<SelectedValue>();

  const handleInitialValueUpdate = () => {
    if (!initialValue) return;

    setSelectedValue({
      label: `${t('common.loading')}...`,
      value: initialValue,
    });

    setTimeout(async () => {
      try {
        const { name } = await api.countries.getCountry(initialValue);

        setSelectedValue({ label: name, value: initialValue });
      } catch (e) {
        setSelectedValue({});
      }
    });
  };

  useEffect(() => {
    handleInitialValueUpdate();
  }, []);

  useDidUpdate(() => {
    if (observeInitialValue) {
      handleInitialValueUpdate();
    }
  }, [initialValue]);

  const handleOptions = async (search: string) => {
    const countries = await loadOptions(search);
    if (!preselect && !search) {
      const country = countries.filter(({ value }) => value === CountryCode.Us);
      if (country.length) {
        setSelectedValue({
          label: country[0].label,
          value: country[0].value,
        });
      }
    }
    return countries;
  };

  return (
    <SelectAsync
      id={id}
      key={`${id}-hezllo`}
      initialValue={selectedValue}
      onChange={onChange}
      loadOptions={(search) => handleOptions(search)}
      className={className}
      placeholder={t('common.search')}
      isClearable={isClearable}
      isDisabled={disabled}
    />
  );
};

SelectLocation.defaultProps = {
  initialValue: null,
};

export default SelectLocation;
