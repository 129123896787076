import React from 'react';
import { useTranslation } from 'react-i18next';
import AddUsersGroupSteps from '../../../../enums/steps/addUsersGroupSteps.enum';
import StepsTitle from '../../../WithModesModal/StepsTitle';

export default ({ currentStep }: { currentStep: AddUsersGroupSteps }) => {
  const { t } = useTranslation();

  const steps = {
    [AddUsersGroupSteps.Info]: t('users_groups.create_group.group_info'),
    [AddUsersGroupSteps.Assign]: t('users_groups.create_group.assign'),
  } as const;

  return <StepsTitle currentStep={currentStep} steps={steps} />;
};
