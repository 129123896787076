import React, { useState } from 'react';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import CopyContent from '../../../components/Playlist/CopyContent';
import CopyContentStepsData from '../../../components/Playlist/CopyContent/copyContentStepsData.interface';
import useModalState from '../../../utils/hooks/useModalState';
import api from '../../../api';
import BulkCopyContentRequestData from '../../../api/endpoints/contents/interfaces/bulkCopyContentRequestData.interface';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
import Tooltip from '../../../components/Tooltip';

export default ({
  children,
  selectedItems,
  onSuccess,
  disabled,
}: {
  children?: React.ReactNode;
  selectedItems?: string[];
  onSuccess: () => void;
  disabled?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, handleOpen, handleClose] = useModalState(false);
  const [isAdmin] = useIsAdmin();

  const handleSubmit = async ({
    playlistIds,
  }: Partial<CopyContentStepsData>) => {
    /*eslint-disable */
    setIsLoading(true);
    const payload: BulkCopyContentRequestData = {
      contentIds: selectedItems,
      playlistIds,
    };

    isAdmin
      ? await api.contents.bulkCopyContentAdmin(payload)
      : await api.contents.bulkCopyContent(payload);
    /*eslint-disable */
    handleClose();
    onSuccess();
    toast.success(i18next.t<string>('playlists.content_copied_message'));
    setIsLoading(false);
  };
  return (
    <>
      {disabled ? (
        <Tooltip text={i18next.t<string>('common.noCopy')}>
          <span
            role="button"
            tabIndex={-1}
            color="grey"
          >
            {children}
          </span>
        </Tooltip>
      ) : (
        <span
          role="button"
          tabIndex={-1}
          onKeyPress={handleOpen}
          onClick={handleOpen}
        >
          {children}
        </span>
      )}
      <CopyContent
        isLoading={isLoading}
        isVisible={isVisible}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </>
  );
};
