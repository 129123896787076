import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import './signup.css';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import validation from '../../../utils/validation';
import { logout } from '../../../utils/auth0';
import FormErrorMessage from '../../../components/FormErrorMessage';
import Industry from '../../../interfaces/industry.interface';
import api from '../../../api';
import config from '../../../routing/config';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';
import logo from '../../../assets/images/logo/indigo_vizual_logo.png';
import LeadSourceEnum from '../../../enums/LeadSource.enum';

interface FormInputs {
  fullName: string;
  firstName: string;
  lastName: string;
  industry: string;
  industryName?: string;
  company: string;
  phoneNumber?: string;
  devices?: number;
  leadSource?: string;
  otherLeadSource?: string;
}

export default () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showIndustryName, setShowIndustryName] = useState<boolean>(false);

  const user = useSelector((state: GlobalState) => {
    return state.userData.user;
  });
  const { roles, onboarding, isTrial, email } = user;

  useEffect(() => {
    if (roles && onboarding) {
      if (
        !(
          roles.includes(UserRole.AccountOwner) &&
          isTrial &&
          !onboarding?.isProfileCompleted
        )
      ) {
        window.location.href = config.home.route;
      }
    }
  }, [onboarding, roles]);

  // app-blank bgi-size-cover bgi-position-center bgi-no-repeat

  // useEffect(() => {
  //   // 👇 add class to body element
  //   document.body.classList.add('bg-salmon');

  //   // 👇️ set style on body element
  //   document.body.style.backgroundColor = 'salmon';

  //   return () => {
  //     // 👇️ optionally remove styles when component unmounts
  //     document.body.style.backgroundColor = null;

  //     document.body.classList.remove('bg-salmon');
  //   };
  // }, []);

  const validationSchema = validation
    .object({
      fullName: validation
        .string()
        .required(t('complete_profile.name'))
        .matches(
          /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/g,
          'Name can only contain Latin letters.',
        )
        .defined(),
      industry: validation
        .string()
        .required(t('complete_profile.industry'))
        .defined(),
      industryName: validation.string().when('industry', {
        is: (industry: string) => industry === '0',
        then: validation.string().required(t('complete_profile.industryName')),
      }),
      company: validation
        .string()
        .required(t('complete_profile.company'))
        .defined(),
      leadSource: validation
        .string()
        .required(t('complete_profile.leadSource'))
        .defined(),
      otherLeadSource: validation.string().when('leadSource', {
        is: (leadSource: string) => leadSource === '0',
        then: validation
          .string()
          .required(t('complete_profile.otherLeadSource')),
      }),

      phoneNumber: validation
        .string()
        .isMobilePhone(
          t('users.create_user.validation.please_enter_valid_phone'),
        )
        .required(t('complete_profile.phoneNumberRequired')),
    })
    .defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });
  const [industryOptions, setIndustryOptions] = useState<Industry[]>([]);

  const leadSourceOptions = [
    {
      value: LeadSourceEnum.facebook,
      label: 'Facebook',
    },
    {
      value: LeadSourceEnum.instagram,
      label: 'Instagram',
    },
    {
      value: LeadSourceEnum.google,
      label: 'Google',
    },
    {
      value: LeadSourceEnum.linkedin,
      label: 'LinkedIn',
    },
    {
      value: LeadSourceEnum.youTube,
      label: 'YouTube',
    },
    {
      value: LeadSourceEnum.manufacturingMarvels,
      label: 'Manufacturing Marvels',
    },
    {
      value: LeadSourceEnum.email,
      label: 'Email or Newsletter',
    },
    {
      value: LeadSourceEnum.Radio,
      label: 'Radio',
    },
    {
      value: LeadSourceEnum.blog,
      label: 'Blog or Publication',
    },
    {
      value: LeadSourceEnum.other,
      label: 'Other',
    },
  ];
  const [optionOtherLeadSource, setOptionOtherLeadSource] =
    useState<boolean>(false);

  const [fullName, setFullName] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'fullName' }, '');

  const [leadSource, setLeadSource] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'leadSource' }, '');

  const setIndustries = async () => {
    const { items } = await api.industries.getIndustries({});
    setIndustryOptions(items);
  };
  useEffect(() => {
    setIndustries();
  }, []);

  const handleLeadSourceChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    setOptionOtherLeadSource(value === '0');
    setLeadSource(value);
  };
  const handleFullNameChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(value);
  };

  const [industry, setIndustry] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'industry' }, '');

  const handleIndustryChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    setShowIndustryName(value === '0');
    setIndustry(value);
  };

  const [otherLeadSource, setOtherLeadSource] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'otherLeadSource' }, '');

  const [industryName, setIndustryName] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'industryName' }, '');

  const handleIndustryNameChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setIndustryName(value);
  };

  const [phoneNumber, setPhoneNumber] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'phoneNumber' }, '');

  const handlePhoneNumberChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(value);
  };

  const [company, setCompany] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'company' }, '');

  const handleCompanyChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(value);
  };

  const handleSubmitData = async (inputsData: Partial<FormInputs>) => {
    const canvaId = sessionStorage?.getItem('canvaId');
    const state = sessionStorage?.getItem('state');
    const nonce = sessionStorage?.getItem('nonce');
    const {
      industry: industryId,
      industryName: industryNameSelect,
      phoneNumber: phone,
    } = inputsData;
    // const finalIndustryName =
    //   industryId === '0' ? industryNameSelect : industryId;
    setIsLoading(true);
    const firstName = fullName.trim().split(' ').shift();
    const lastName = firstName
      ? fullName.trim().substring(firstName.length).trim().split(' ')
      : '';

    let newLastName = '';
    for (let i = 0; i < lastName.length; i += 1) {
      if (lastName[i] !== '') {
        newLastName += `${lastName[i]} `;
      } else {
        newLastName += `${lastName[i]}`;
      }
    }

    const data = {
      firstName,
      lastName: newLastName,
      heardAboutUs: leadSource === '0' ? otherLeadSource : leadSource,
      companyName: company,
      industryId: industryId === '0' ? undefined : industryId,
      industryName: industryId === '0' ? industryNameSelect : undefined,
      phoneNumber: phone,
    };
    await api.account.updateOnboarding(data);
    setIsLoading(false);

    if (canvaId && state && nonce) {
      window.location.href = `${
        process.env.REACT_APP_API_URL
      }/canva/redirect?state=${encodeURIComponent(
        state,
      )}&canvaId=${encodeURIComponent(canvaId)}&email=${encodeURIComponent(
        email,
      )}&nonce=${encodeURIComponent(nonce)}`;
    } else window.location.href = config.home.route;
  };

  return (
    <form className="form w-100" id="kt_sign_up_form">
      <div className="formLogo mb-11 text-center">
        <img alt="Logo" src={logo} width="170" />
      </div>
      <div className="text-center mb-11 mt-5">
        <h1 className="text-dark fw-bolder mb-3">Complete Your Profile</h1>
      </div>
      <div className="fv-row mb-8">
        <label htmlFor="fullName" className="required text-dark fw-bolder my-1">
          Name
        </label>
        <input
          type="text"
          name="fullName"
          id="fullName"
          value={fullName}
          onChange={handleFullNameChange}
          placeholder="Name"
          autoComplete="none"
          className="form-control bg-transparent d-block"
        />
        <FormErrorMessage
          name="fullName"
          errors={errors}
          className="my-1 px-2"
        />
      </div>

      <div className="fv-row mb-8">
        <label htmlFor="lastName" className="required text-dark fw-bolder my-1">
          Phone number
        </label>
        <input
          type="text"
          name="phoneNumber"
          id="phoneNumber"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          placeholder="Phone number"
          autoComplete="none"
          className="form-control bg-transparent d-block"
        />
        <FormErrorMessage
          name="phoneNumber"
          errors={errors}
          className="my-1 px-2"
        />
      </div>

      <div className="fv-row mb-8">
        <label htmlFor="company" className="required text-dark fw-bolder my-1">
          Company name
        </label>
        <input
          type="text"
          name="company"
          id="company"
          value={company}
          onChange={handleCompanyChange}
          placeholder="Company name"
          autoComplete="none"
          className="form-control bg-transparent d-block"
        />
        <FormErrorMessage
          name="company"
          errors={errors}
          className="my-1 px-2"
        />
      </div>

      <div className="fv-row mb-8">
        <label htmlFor="industry" className="required text-dark fw-bolder my-1">
          Industry
        </label>
        <select
          id="industry"
          onChange={handleIndustryChange}
          defaultValue={industry}
          name="industry"
          className="form-control bg-transparent d-block"
        >
          <option value="" selected disabled>
            Select Industry
          </option>
          {industryOptions.map((i) => (
            <option key={i.id} value={i.id}>
              {i.name}
            </option>
          ))}
          <option value="0">Other</option>
        </select>
        <FormErrorMessage
          name="industry"
          errors={errors}
          className="my-1 px-2"
        />
      </div>
      {showIndustryName && (
        <div className="fv-row mb-8">
          <label
            htmlFor="lastName"
            className="required text-dark fw-bolder my-1"
          >
            Industry name
          </label>
          <input
            type="text"
            name="industryName"
            id="industryName"
            value={industryName}
            onChange={handleIndustryNameChange}
            placeholder="Industry name"
            autoComplete="none"
            className="form-control bg-transparent d-block"
          />
          <FormErrorMessage
            name="industryName"
            errors={errors}
            className="my-1 px-2"
          />
        </div>
      )}
      <div className="fv-row mb-8">
        <label
          htmlFor="leadSource"
          className="required text-dark fw-bolder my-1"
        >
          How did you hear about us ?
        </label>
        <select
          id="leadSource"
          onChange={handleLeadSourceChange}
          defaultValue={leadSource}
          name="leadSource "
          className="form-control bg-transparent d-block cursor-pointer"
        >
          <option value="" selected disabled>
            How did you hear about us ?
          </option>

          {leadSourceOptions.map((op) => (
            <option value={op.value}>{op.label}</option>
          ))}
        </select>
        <FormErrorMessage
          name="leadSource"
          errors={errors}
          className="my-1 px-2"
        />
      </div>
      {optionOtherLeadSource && (
        <div className="fv-row mb-8">
          <label
            htmlFor="otherLeadSource"
            className=" required text-dark fw-bolder my-1"
          >
            Please specify how you heard about us ?
          </label>
          <input
            type="text"
            name="otherLeadSource "
            id="otherLeadSource "
            onChange={(e) => setOtherLeadSource(e.target.value)}
            placeholder="Put your text here"
            autoComplete="none"
            className="form-control bg-transparent d-block"
          />
          <FormErrorMessage
            name="otherLeadSource"
            errors={errors}
            className="my-1 px-2"
          />
        </div>
      )}

      <div className="d-grid mb-10">
        <button
          disabled={isLoading}
          onClick={handleSubmit(handleSubmitData)}
          type="button"
          id="kt_sign_up_submit"
          className="btn btn-purple"
        >
          {!isLoading ? (
            <span className="indicator-label">Start Now!</span>
          ) : (
            <span className="d-block indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            </span>
          )}
        </button>
      </div>
      <div className="text-gray-500 text-center fw-semibold fs-6">
        <button
          onClick={logout}
          type="button"
          className="bg-transparent link-primary fw-semibold"
        >
          Logout
        </button>
      </div>
    </form>
  );
};
