import * as React from 'react';

import { KTSVG } from '../../lib/metronic/helpers';

export interface ClickedFolder {
  id: string;
  name: string;
}

export interface FileManagerBreadcrumbProps {
  directories: Array<ClickedFolder>;
  rootText?: string;
  clickOnBreadcrumbItem: (clickedFolder: ClickedFolder) => void;
  resetBreadcrumbItems: () => void;
}

const FileManagerBreadcrumb = ({
  directories,
  clickOnBreadcrumbItem,
  resetBreadcrumbItems,
  rootText = 'Initial',
}: FileManagerBreadcrumbProps) => {
  return (
    <div className="badge badge-lg badge-light-primary text-primary mb-2 my-lg-0 mx-md-2">
      <div className="d-flex align-items-center flex-wrap">
        <span
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={resetBreadcrumbItems}
          className="p-3"
        >
          {rootText}
        </span>

        {directories.length !== 0
          ? directories.map((directory) => (
              <div key={directory.id} className="p-3">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr023.svg"
                  className="svg-icon svg-icon-2 svg-icon-primary mx-1"
                />
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  onClick={() => clickOnBreadcrumbItem(directory)}
                >
                  {directory.name}
                </span>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default FileManagerBreadcrumb;
