import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { restoreUser } from '../../../store/actions/users';
import User from '../../../interfaces/user/user.interface';
import Tooltip from '../../../components/Tooltip';

export default ({
  user: { id },
  children,
  onRestoreUser,
}: {
  user: User;
  children?: React.ReactNode;
  onRestoreUser?: (userId: string) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const restore = () => {
    if (onRestoreUser) {
      onRestoreUser(id);
      return;
    }

    dispatch(restoreUser({ id }));
    toast.success(i18next.t<string>('users.restore_message'));
  };

  return (
    <span role="button" tabIndex={-1} onKeyPress={restore} onClick={restore}>
      {children ?? (
        <Tooltip text={t('common.restore')}>
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-trash-restore" />
          </span>
        </Tooltip>
      )}
    </span>
  );
};
