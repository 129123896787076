import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { useLocation } from 'react-router-dom';
import SortOrder from '../../../enums/sortOrder.enum';
import UsersGroupsSortField from '../../../api/endpoints/usersGroups/enums/usersGroupsSortField.enum';
import UsersGroup from '../../../interfaces/usersGroup.interface';
import { readFromQueryString } from '../../../utils/queryParams';
import Columns, { TableHeaderColumn } from '../../Table/Columns';
import WithSpinner from '../../WithSpinner';
import TableRow from './TableRow';
import Table from '../../Table';
import {
  isAllSelected,
  TableMultiSelectConfig,
} from '../../../utils/hooks/useTableMultiSelect';
import UpdateUsersGroupAction from '../../../store/actions/usersGroups/interfaces/updateUsersGroupAction.interface';

interface TableViewProps {
  groups: UsersGroup[];
  isLoading: boolean;
  sortQueryField: string;
  orderQueryField: string;
  preloaderHeight?: number;
  isSelectable?: boolean;
  multiSelectConfig?: TableMultiSelectConfig;
  unAssignUserGroup?: (deviceGroupId: string, userGroupId: string) => void;
  onEditUserGroup?: (data: UpdateUsersGroupAction['payload']) => void;
  onDeleteUserGroup?: (id: string) => void;
}

interface DefaultProps extends TableViewProps {
  multiSelectConfig: Required<TableViewProps>['multiSelectConfig'];
  userProfileId?: string;
}

export default ({
  groups,
  isLoading,
  sortQueryField,
  orderQueryField,
  preloaderHeight = 300,
  isSelectable = false,
  unAssignUserGroup,
  onEditUserGroup,
  onDeleteUserGroup,
  userProfileId,
  multiSelectConfig: { selectedItems, setSelectedItem, selectAll },
}: DefaultProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  // const history = useHistory();
  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, sortQueryField),
    isInverted:
      readFromQueryString(search, orderQueryField) === SortOrder.Ascending,
  });

  const columns: TableHeaderColumn<UsersGroupsSortField>[] = [
    {
      name: t('users_groups.table.name'),
      sortBy: UsersGroupsSortField.Name,
    },
    { name: t('users_groups.common.company') },
    // { name: t('users_groups.table.role') },
    { name: t('users_groups.table.status') },
    {
      name: t('users_groups.table.users_number'),
      sortBy: UsersGroupsSortField.UsersNumber,
    },
    { name: t('users_groups.table.location') },
    { name: t('common.actions') },
  ];

  // const handleClickUserGroup = (userGroupId: string) =>
  //   history.push(`/users-groups/${userGroupId}/users`);

  return (
    <Table>
      <Table.Head>
        <Table.Head.Row>
          <Columns<UsersGroupsSortField>
            columns={columns}
            checked={isAllSelected(selectedItems, map(groups, 'id'))}
            config={{
              isSelectable,
              sortField,
              setSortField,
              sortQueryField,
              orderQueryField,
            }}
            onChange={selectAll}
          />
        </Table.Head.Row>
      </Table.Head>
      <Table.Body
        isEmpty={!isLoading && !groups.length}
        emptyStateHeight={preloaderHeight}
        emptyStateChildren={t('users_groups.table.no_one_group_found')}
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: `${preloaderHeight}px` }}
          isForTable
          size="md"
        >
          {groups.map((group) => (
            <TableRow
              key={group.id}
              group={group}
              config={{ isSelectable }}
              isChecked={selectedItems.includes(group.id)}
              onCheck={setSelectedItem}
              // onClick={() => handleClickUserGroup(group.id)}
              unAssignUserGroup={unAssignUserGroup}
              onEditUserGroup={onEditUserGroup}
              onDeleteUserGroup={onDeleteUserGroup}
              userProfileId={userProfileId}
            />
          ))}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};
