enum ConversionJobStatus {
  Initialising = 'initialising',
  Converting = 'converting',
  Downloading = 'downloading',
  Successful = 'successful',
  Done = 'done',
  Failed = 'failed',
}

export default ConversionJobStatus;
