import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Table from '../../../../Table';
import WithClickBackdrop from '../../../../WithClickBackdrop';
import ButtonDropdown from '../../../../Dropdown/ButtonDropdown';
import DeleteLocation from '../../../../../containers/AdminPanel/CompanyLocations/DeleteLocation';
import EditLocation from '../../../../../containers/AdminPanel/CompanyLocations/EditLocation';
import Location from '../../../../../interfaces/location.interface';
import CheckBox from '../../../../CheckBox';

const { Row } = Table.Body;
const { Cell } = Row;

interface TableRowProps {
  location: Location;
  config?: {
    skipActions?: boolean;
    disableLinks?: boolean;
    isSelectable?: boolean;
  };
  isChecked?: boolean;
  onCheck?: (id: string) => void;
  onUpdate?: () => void;
}

interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
  isChecked: Required<TableRowProps>['isChecked'];
  onCheck: Required<TableRowProps>['onCheck'];
}

export default ({
  location,
  config,
  isChecked,
  onCheck,
  onUpdate,
}: DefaultProps) => {
  const { t } = useTranslation();

  return (
    <Row>
      {config.isSelectable && (
        <Cell>
          <CheckBox
            name="locationId"
            id={location.id}
            onChange={() => onCheck(location.id)}
            checked={isChecked}
            className="mt-1"
          />
        </Cell>
      )}

      <Cell isEmpty={!location.name}>
        <span className={clsx('text-dark fw-bold d-block mb-1 fs-6')}>
          {location.name}
        </span>
      </Cell>
      <Cell isEmpty={!location.address1}>
        <span className={clsx('text-dark fw-bold d-block mb-1 fs-6')}>
          {location.address1}
        </span>
      </Cell>
      <Cell isEmpty={!location.address2}>
        <span className={clsx('text-dark fw-bold d-block mb-1 fs-6')}>
          {location.address2}
        </span>
      </Cell>

      <Cell isEmpty={!location.city}>
        <span className={clsx('text-dark fw-bold d-block mb-1 fs-6')}>
          {location.city}
        </span>
      </Cell>
      <Cell isEmpty={!location.state?.id}>
        <span className={clsx('text-dark fw-bold d-block mb-1 fs-6')}>
          {location.state?.id}
        </span>
      </Cell>
      <Cell isEmpty={!location?.timezone?.name}>
        <span className={clsx('text-dark fw-bold d-block mb-1 fs-6')}>
          {location?.timezone?.name}
        </span>
      </Cell>

      <Cell isNoWrap isTextEnd>
        <div className="d-flex justify-content-end">
          <ButtonDropdown
            text={t('common.actions')}
            items={[
              {
                key: 'edit',
                content: (
                  <EditLocation location={location} onUpdate={onUpdate}>
                    <WithClickBackdrop>{t('common.edit')}</WithClickBackdrop>
                  </EditLocation>
                ),
              },
              {
                key: 'delete',
                content: (
                  <DeleteLocation location={location} onUpdate={onUpdate}>
                    <WithClickBackdrop>{t('common.delete')}</WithClickBackdrop>
                  </DeleteLocation>
                ),
              },
            ]}
          />
        </div>
      </Cell>
    </Row>
  );
};
