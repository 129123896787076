import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import CountDownTimerModal from '../../../../components/Apps/CountDownTimer/CreateCountDownTimer';
import CreateAppRequestData from '../../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import App from '../../../../interfaces/app.interface';
import Tooltip from '../../../../components/Tooltip';
import Modal from '../../../../components/Modal';
import api from '../../../../api';

const CreateCounterUpTimer = ({
  children,
  onUpdate,
  countDownTimerApp,
}: {
  children?: React.ReactNode;
  onUpdate: () => void;
  countDownTimerApp: App;
}) => {
  const { t } = useTranslation();

  const [modalShown, setModalShown] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const handleSubmit = (data: CreateAppRequestData) => {
    setLoading(true);

    api.apps
      .updateApp(countDownTimerApp.id, data)
      .then(() => {
        close();
        if (onUpdate) {
          onUpdate();
        }
        toast.success(
          `${i18next.t<string>(
            'apps.countTimer.countDownTimer',
          )} ${i18next.t<string>('apps.countTimer.CountTimerEdited')}`,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <Tooltip text={t('common.edit')}>
            <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <i className="fa fa-edit" />
            </span>
          </Tooltip>
        )}
      </span>
      <Modal
        title={`${t('apps.countTimer.editCountTimer')} ${t(
          'apps.countTimer.countDownTimer',
        )} `}
        show={modalShown}
        onHide={() => setModalShown(false)}
        withFooter={false}
      >
        <CountDownTimerModal
          onClose={close}
          countDownTimerApp={countDownTimerApp}
          onSubmit={handleSubmit}
          isLoading={loading}
        />
      </Modal>
    </>
  );
};

export default CreateCounterUpTimer;
