import React from 'react';
import { useDispatch } from 'react-redux';
import CreateGroup from '../../../components/UsersGroups/CreateGroup';
import { createUsersGroup } from '../../../store/actions/usersGroups';

export default ({
  className,
  ownerId,
  addUserGroup,
}: {
  className?: string;
  ownerId?: string;
  addUserGroup?: (data: any) => void;
}) => {
  const dispatch = useDispatch();
  const onSubmit = (data: any) => {
    const datas = { ...data, userId: ownerId };
    if (addUserGroup) addUserGroup(datas);
  };
  if (ownerId) {
    return <CreateGroup className={className} onSubmit={onSubmit} />;
  }
  return (
    <CreateGroup
      className={className}
      onSubmit={(data) => dispatch(createUsersGroup(data))}
    />
  );
};
