import React from 'react';
import IContent from '../../../../../../interfaces/content.interface';
import PreviewWeatherModal from '../../../../../Apps/Weather/Preview';

export default ({
  show,
  onClose,
  content,
}: {
  show: boolean;
  onClose: () => void;
  content: IContent;
}) => {
  const weatherData = content.app?.dependency?.weather;

  return (
    <PreviewWeatherModal
      selectedWeatherContent={JSON.stringify(weatherData)}
      selectedWeatherConfig={content.app?.dependency?.config}
      key="preview"
      show={show}
      onHide={onClose}
      title={weatherData.name}
      placement={weatherData.placement}
    />
  );
};
