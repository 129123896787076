/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { OptionTypeBase, ValueType } from 'react-select/src/types';
import AsyncSelect from 'react-select/async';
import * as reactSelect from 'react-select';
import { SelectAsyncMultiple } from './styles';

export interface SelectedValue extends OptionTypeBase {
  value?: string;
  label?: string;
}

type ArrayHandler = (selectedValue: SelectedValue[]) => void;
type SingleSelectHandler = (selectedValue: SelectedValue) => void;

interface SelectAsyncProps {
  onChange: SingleSelectHandler | ArrayHandler;
  loadOptions: (search: string) => Promise<SelectedValue[]>;
  initialValue?: SelectedValue | SelectedValue[] | undefined;
  id?: string;
  className?: string;
  isMulti?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  noAutocomplete?: boolean;
  name?: string;
  key?: string;
  defaultOptions?: SelectedValue[];
  isLoading?: boolean;
  currentUserId?: string;
}

interface DefaultProps extends SelectAsyncProps {
  initialValue: Required<SelectAsyncProps>['initialValue'];
  isMulti: Required<SelectAsyncProps>['isMulti'];
  isDisabled: Required<SelectAsyncProps>['isDisabled'];
  isClearable: Required<SelectAsyncProps>['isClearable'];
  components?: any;
}

const SelectAsync = ({
  isMulti,
  onChange,
  initialValue,
  loadOptions,
  id,
  currentUserId,
  components,
  className,
  placeholder,
  isDisabled,
  isClearable,
  name,
  key,
  noAutocomplete,
  defaultOptions,
  isLoading = false,
}: DefaultProps) => {
  const [selectedValue, setSelectedGroup] = useState<
    ValueType<SelectedValue, false> | undefined
  >(initialValue);

  const handleChange = (currentValue: ValueType<SelectedValue, false>) => {
    if (isClearable || currentValue) {
      setSelectedGroup(currentValue);
    }
  };

  useEffect(() => {
    if (!selectedValue) return;
    if (isMulti) {
      (onChange as ArrayHandler)((selectedValue as SelectedValue[]) ?? []);
    } else {
      (onChange as SingleSelectHandler)((selectedValue as SelectedValue) ?? {});
    }
  }, [selectedValue]);

  useEffect(() => {
    setSelectedGroup(initialValue ?? null);
  }, [initialValue]);

  return (
    <AsyncSelect
      key={key}
      isMulti={isMulti}
      currentUserId={currentUserId}
      name={name}
      id={id}
      isLoading={isLoading}
      styles={SelectAsyncMultiple}
      cacheOptions
      defaultOptions={defaultOptions ?? true}
      value={selectedValue}
      onChange={handleChange}
      loadOptions={loadOptions}
      className={className}
      components={{
        ...components,
        IndicatorSeparator: () => null,
        Input: (props) => {
          return (
            <reactSelect.components.Input
              {...props}
              autoComplete={noAutocomplete ? 'none' : undefined}
            />
          );
        },
      }}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isClearable={isClearable}
      autoComplete="none"
    />
  );
};

SelectAsync.defaultProps = {
  initialValue: null,
  isMulti: false,
  isDisabled: false,
  isClearable: false,
};

export default SelectAsync;
