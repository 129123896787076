/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Table from '../../../../Table';
import CheckBox from '../../../../CheckBox';
import Company from '../../../../../interfaces/company.interface';
import Modal from '../../../../Modal';
import LocationsTable from '../../../../../containers/AdminPanel/CompanyLocations/AssignLocation';
import DeleteCompany from '../../../DeleteCompany';
import CreateUserCompany from '../../../../../containers/Companies/CreateUserCompany';
import GlobalState from '../../../../../store/reducers/globalState.interface';
import Tooltip from '../../../../Tooltip';
import ShowMoreCell from '../../../../ShowMoreCell';

const { Row } = Table.Body;
const { Cell } = Row;

interface TableRowProps {
  company: Company;
  config?: {
    skipActions?: boolean;
    disableLinks?: boolean;
    isSelectable?: boolean;
  };
  isChecked?: boolean;
  onCheck?: (id: string) => void;
  onUpdate?: () => void;
}
interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
  isChecked: Required<TableRowProps>['isChecked'];
  onCheck: Required<TableRowProps>['onCheck'];
}

export default ({
  company,
  config,
  isChecked,
  onCheck,
  onUpdate,
}: DefaultProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const userId = useSelector((state: GlobalState) => state?.userData?.user?.id);
  return (
    <Row>
      {config.isSelectable && (
        <Cell>
          <CheckBox
            name="deviceId"
            id={company?.id}
            onChange={() => onCheck(company?.id)}
            checked={isChecked}
            className="mt-1"
          />
        </Cell>
      )}

      <Cell isEmpty={!company?.name}>
        <span className={clsx('text-dark fw-bold d-block mb-1 fs-6 ')}>
          {company?.name}
        </span>
      </Cell>
      <Cell isEmpty={!company?.locationsCount} className="text-center ">
        <span className={clsx('text-dark fw-bold d-block mb-1 fs-6')}>
          {company?.locationsCount}
        </span>
      </Cell>
      <Cell
        isNoWrap
        isEmpty={company?.users?.length === 0}
        className="text-center "
      >
        <ShowMoreCell
          itemNames={company?.users?.map(
            (user: any) => `${user?.firstName} ${user?.lastName}`,
          )}
          title={t('companies.users')}
          widthDefaultSize
          bodyClassName="justify-content-center "
        >
          {company?.users?.map((us: any) => (
            <span className="badge fs-7 py-3 px-7 fw-bold badge-light-primary mb-2 ms-2 d-flex justify-content-center">
              {us?.firstName} {us?.lastName}
            </span>
          ))}
        </ShowMoreCell>
      </Cell>

      <Cell isNoWrap isEmpty={!company?.industry?.name} className="text-center">
        {`${company?.industry?.name}`}
      </Cell>

      <Cell isNoWrap isTextEnd>
        <div className="d-flex flex-row justify-content-center bd-highlight">
          {/* <ButtonDropdown
            disable={company?.owner?.id !== userId}
            text={t('common.actions')}
            items={[
              {
                key: 'manage-locations',
                content: (
                  <>
                    <span
                      role="button"
                      tabIndex={-1}
                      onKeyPress={() => setOpen(true)}
                      onClick={() => setOpen(true)}
                    >
                      <WithClickBackdrop>
                        {t('companies.manage_company_locations')}
                      </WithClickBackdrop>
                    </span>
                    <Modal 
                      title={`${company?.name} Locations`}
                      show={open}
                      onHide={() => setOpen(false)}
                      withFooter={false}
                      bodyClassName="p-0"
                    >
                      <LocationsTable company={company} />
                    </Modal>
                  </>
                ),
                disabled: !company?.owner.id,
              },
              {
                key: 'edit',
                content: (
                  <CreateUserCompany
                    company={company}
                    onUpdate={onUpdate}
                    isEdit
                  >
                    <WithClickBackdrop>{t('common.edit')}</WithClickBackdrop>
                  </CreateUserCompany>
                ),
              },
              {
                key: 'delete',
                content: (
                  <DeleteCompany company={company} onUpdate={onUpdate}>
                    <WithClickBackdrop>{t('common.delete')}</WithClickBackdrop>
                  </DeleteCompany>
                ),
              },
            ]}
          /> */}

          {company?.owner?.id === userId ? (
            <>
              <div className="mx-1">
                <Tooltip text={t('common.location')}>
                  <span
                    aria-hidden="true"
                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm "
                    onKeyPress={() => setOpen(true)}
                    onClick={() => setOpen(true)}
                  >
                    <i className="fa fa-list " />
                  </span>

                  <Modal
                    title={`${company?.name} Locations`}
                    show={open}
                    onHide={() => setOpen(false)}
                    withFooter={false}
                    bodyClassName="p-0"
                  >
                    <LocationsTable company={company} />
                  </Modal>
                </Tooltip>
              </div>
              <div className="mx-1">
                <CreateUserCompany
                  companyId={company.id}
                  onUpdate={onUpdate}
                  isEdit
                >
                  <Tooltip text={t('common.edit')}>
                    <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                      <i className="fa fa-edit" />
                    </span>
                  </Tooltip>
                </CreateUserCompany>
              </div>
              <div className="mx-1">
                <DeleteCompany company={company} onUpdate={onUpdate}>
                  <Tooltip text={t('common.remove')}>
                    <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                      <i className="fa fa-trash" />
                    </span>
                  </Tooltip>
                </DeleteCompany>
              </div>
            </>
          ) : (
            <>
              <div className="mx-1">
                <span aria-hidden="true" className="btn btn-icon  btn-sm ">
                  <i className="fa fa-list " />
                </span>
              </div>

              <div className="mx-1">
                <span className="btn btn-icon  btn-sm">
                  <i className="fa fa-edit" />
                </span>
              </div>

              <div className="mx-1">
                <span className="btn btn-icon  btn-sm">
                  <i className="fa fa-trash" />
                </span>
              </div>
            </>
          )}
        </div>
      </Cell>
    </Row>
  );
};
