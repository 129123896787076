import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  readAllFromQueryParams,
  readFromQueryString,
} from '../../../utils/queryParams';
import api from '../../../api';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import UsersGroup from '../../../interfaces/usersGroup.interface';
import UserGroups from '../../../components/UsersGroups';
import UpdateUsersGroupAction from '../../../store/actions/usersGroups/interfaces/updateUsersGroupAction.interface';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';

export default ({ children }: { children: React.ReactNode }) => {
  const [userGroups, setUserGroups] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [userGroupsLoading, setUserGroupsLoading] = useState<boolean>(true);

  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const isSuperAdmin = roles.includes(UserRole.SuperAdmin);
  const currentUserId = readFromQueryString(search, 'currentUserId') || '';
  const getDeviceGroupUserGroups = async (recentSearch: string) => {
    try {
      setUserGroupsLoading(true);
      const { items, meta } =
        !isSuperAdmin && !currentUserId
          ? await api.devicesGroups.getDeviceGroupUserGroupsList(
              readAllFromQueryParams(recentSearch),
              id,
            )
          : await api.usersGroups.adminGetUsersGroups({
              currentUserId,
              deviceGroupId: id,
              ...readAllFromQueryParams(recentSearch),
            });

      setUserGroups(items);
      setTotal(meta.totalItems);
    } catch (e) {
      // Do nothing for now
    }
    setUserGroupsLoading(false);
  };

  useEffect(() => {
    setUserGroupsLoading(true);
    getDeviceGroupUserGroups(search);
  }, [search]);

  const handleEditUserGroupInDeviceGroup = async (
    data: UpdateUsersGroupAction['payload'],
  ) => {
    try {
      setUserGroupsLoading(true);
      await api.usersGroups.updateUsersGroup(data.data, data.id);

      getDeviceGroupUserGroups(search);
    } catch (e) {
      // Do nothing for now
    }
    setUserGroupsLoading(false);
  };

  const handleUnAssignUserGroupsFromUserGroup = async (
    deviceGroupId: string,
    userGroupId: string,
  ) => {
    try {
      setUserGroupsLoading(true);

      await api.devicesGroups.unAssignUserGroupsFromGroup(
        deviceGroupId,
        userGroupId,
      );
      setUserGroups(
        userGroups.filter(
          (userGroup: UsersGroup) => userGroup.id !== userGroupId,
        ),
      );
    } catch (e) {
      // Do nothing for now
    }
    setUserGroupsLoading(false);
  };

  const handleAssignUserGroupsToDeviceGroup = async (data: SelectedValue[]) => {
    setUserGroupsLoading(true);

    await Promise.all(
      data.map(async ({ value }) => {
        try {
          await api.devicesGroups.assignUserGroupToGroup(id, value as string);

          getDeviceGroupUserGroups(search);
        } catch (e) {
          // Do nothing for now
        }
      }),
    );

    setUserGroupsLoading(false);
  };

  return (
    <UserGroups
      groups={userGroups}
      total={total}
      isLoading={userGroupsLoading}
      onEditUserGroup={handleEditUserGroupInDeviceGroup}
      assignUserGroup={handleAssignUserGroupsToDeviceGroup}
      unAssignUserGroup={handleUnAssignUserGroupsFromUserGroup}
    >
      {children}
    </UserGroups>
  );
};
