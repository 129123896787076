import React, { useState } from 'react';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import Library from '../../../interfaces/library.interface';
import QuickClick from '../../../components/Libraries/QuickClick';
import api from '../../../api';

export default ({
  children,
  library,
}: {
  children: React.ReactNode;
  library: Library;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const open = () => setIsModalVisible(true);
  const close = () => {
    setIsModalVisible(false);
    setIsLoading(false);
  };

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      await api.libraries.quickClick(library.id);

      toast.success(i18next.t<string>('library.quick_click.successfully_sent'));

      close();
    } catch (e) {
      setIsModalVisible(false);
    }
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <QuickClick
        isModalVisible={isModalVisible}
        handleConfirm={handleConfirm}
        library={library}
        isLoading={isLoading}
        onHide={close}
      />
    </>
  );
};
