import axios, { AxiosError, AxiosResponse } from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';

import { API_URL } from '../../../constants/api';

import {
  getHTMLFeedsLoadingActionCreator,
  getHTMLFeedsSuccessActionCreator,
  getHTMLFeedsErrorActionCreator,
  createHTMLFeedLoadingActionCreator,
  createHTMLFeedSuccessActionCreator,
  createHTMLFeedErrorActionCreator,
  editHTMLFeedLoadingActionCreator,
  editHTMLFeedSuccessActionCreator,
  editHTMLFeedErrorActionCreator,
  scheduleHTMLFeedLoadingActionCreator,
  scheduleHTMLFeedSuccessActionCreator,
  scheduleHTMLFeedErrorActionCreator,
  deleteHTMLFeedLoadingActionCreator,
  deleteHTMLFeedSuccessActionCreator,
  deleteHTMLFeedErrorActionCreator,
  HTML_FEED_ACTIONS,
  CREATE_HTML_FEED_ACTIONS,
  DELETE_HTML_FEED_ACTIONS,
  EDIT_HTML_FEED_ACTIONS,
  GET_HTML_FEEDS_ACTIONS,
  SCHEDULE_HTML_FEED_ACTIONS,
  CREATE_SCHEDULE_HTML_FEED_ACTIONS,
  createScheduleHTMLFeedErrorActionCreator,
} from '../../actions/apps';
import { getUserData } from '../../actions/userData';

import { ErrorResponse } from '../../types/apps/globalTypes';
import {
  GetHTMLFeedsRequest,
  PaginatedHTMLFeeds,
  HTMLFeedSchema,
  CreateHTMLFeedRequest,
  DeleteHTMLFeedRequest,
  EditHTMLFeedRequest,
  ScheduleHTMLFeedRequest,
} from '../../types/apps/htmlFeed';

const HTML_FEED_APP_URL = `${API_URL}/apps/html`;

// services

export const getHTMLFeedsService = async ({
  params,
}: GetHTMLFeedsRequest): Promise<PaginatedHTMLFeeds> => {
  const response = await axios({
    method: 'GET',
    url: HTML_FEED_APP_URL,
    params,
  });

  return response.data;
};

function* getHTMLFeedsWorker({ payload }: GET_HTML_FEEDS_ACTIONS) {
  try {
    yield put(getHTMLFeedsLoadingActionCreator({ loading: true }));

    const response: PaginatedHTMLFeeds = yield call(
      getHTMLFeedsService,
      payload as GetHTMLFeedsRequest,
    );

    yield put(
      getHTMLFeedsSuccessActionCreator({
        data: response,
        loading: false,
        isGetHTMLFeedsSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      getHTMLFeedsErrorActionCreator({
        error: response.data,
        loading: false,
        isGetHTMLFeedsSuccess: false,
      }),
    );
  }
}

export const createHTMLFeedService = async (
  request: CreateHTMLFeedRequest,
): Promise<Partial<HTMLFeedSchema>> => {
  const response = await axios({
    method: 'POST',
    url: HTML_FEED_APP_URL,
    data: request,
  });

  return response.data;
};

function* createHTMLFeedWorker({ payload }: CREATE_HTML_FEED_ACTIONS) {
  try {
    yield put(createHTMLFeedLoadingActionCreator({ loading: true }));

    const response: Partial<HTMLFeedSchema> = yield call(
      createHTMLFeedService,
      payload as CreateHTMLFeedRequest,
    );

    yield put(
      createHTMLFeedSuccessActionCreator({
        data: response,
        loading: false,
        isCreateHTMLFeedSuccess: true,
      }),
    );

    yield put(getUserData());
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      createHTMLFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isCreateHTMLFeedSuccess: false,
      }),
    );
  }
}

export const editHTMLFeedService = async (
  request: EditHTMLFeedRequest,
): Promise<Partial<HTMLFeedSchema>> => {
  const response = await axios({
    method: 'PATCH',
    url: `${API_URL}/apps/${request.id}`,
    data: request,
  });

  return response.data;
};

function* editHTMLFeedWorker({ payload }: EDIT_HTML_FEED_ACTIONS) {
  try {
    yield put(editHTMLFeedLoadingActionCreator({ loading: true }));

    const response: Partial<HTMLFeedSchema> = yield call(
      editHTMLFeedService,
      payload as EditHTMLFeedRequest,
    );

    yield put(
      editHTMLFeedSuccessActionCreator({
        data: response,
        loading: false,
        isEditHTMLFeedSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      editHTMLFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isEditHTMLFeedSuccess: false,
      }),
    );
  }
}

export const scheduleHTMLFeedService = async (
  request: ScheduleHTMLFeedRequest,
): Promise<Partial<HTMLFeedSchema>> => {
  const response = await axios({
    method: 'POST',
    url: `${HTML_FEED_APP_URL}/${request.appId}`,
    data: request,
  });

  return response.data;
};

function* scheduleHTMLFeedWorker({ payload }: SCHEDULE_HTML_FEED_ACTIONS) {
  try {
    yield put(scheduleHTMLFeedLoadingActionCreator({ loading: true }));

    const response: Partial<HTMLFeedSchema> = yield call(
      scheduleHTMLFeedService,
      payload as ScheduleHTMLFeedRequest,
    );

    yield put(
      scheduleHTMLFeedSuccessActionCreator({
        data: response,
        loading: false,
        isScheduleHTMLFeedSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      scheduleHTMLFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isScheduleHTMLFeedSuccess: false,
      }),
    );
  }
}

export const deleteHTMLFeedService = async (
  request: DeleteHTMLFeedRequest,
): Promise<void> => {
  const response = await axios({
    method: 'DELETE',
    url: `${API_URL}/apps/${request.id}`,
  });

  return response.data;
};

function* deleteHTMLFeedWorker({ payload }: DELETE_HTML_FEED_ACTIONS) {
  try {
    yield put(deleteHTMLFeedLoadingActionCreator({ loading: true }));

    const response: Partial<void> = yield call(
      deleteHTMLFeedService,
      payload as DeleteHTMLFeedRequest,
    );

    yield put(
      deleteHTMLFeedSuccessActionCreator({
        data: response,
        loading: false,
        isDeleteHTMLFeedSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      deleteHTMLFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isDeleteHTMLFeedSuccess: false,
      }),
    );
  }
}

function* createScheduleHTMLFeedWorker({
  payload,
}: CREATE_SCHEDULE_HTML_FEED_ACTIONS) {
  try {
    yield put(createHTMLFeedLoadingActionCreator({ loading: true }));

    const response: Partial<HTMLFeedSchema> = yield call(
      createHTMLFeedService,
      payload?.htmlFeed as CreateHTMLFeedRequest,
    );

    const scheduleFeed = payload!.scheduleFeed!;
    scheduleFeed.appId = response!.id!;

    yield put(
      createHTMLFeedSuccessActionCreator({
        data: { name: payload?.htmlFeed?.name, ...response },
        loading: false,
        isCreateHTMLFeedSuccess: true,
      }),
    );

    yield put(scheduleHTMLFeedLoadingActionCreator({ loading: true }));

    const response1: Partial<HTMLFeedSchema> = yield call(
      scheduleHTMLFeedService,
      scheduleFeed as ScheduleHTMLFeedRequest,
    );

    yield put(
      scheduleHTMLFeedSuccessActionCreator({
        data: response1,
        loading: false,
        isScheduleHTMLFeedSuccess: true,
      }),
    );
  } catch (error) {
    const response = (error as AxiosError)
      .response as AxiosResponse<ErrorResponse>;

    yield put(
      createHTMLFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isCreateHTMLFeedSuccess: false,
      }),
    );
    yield put(
      createScheduleHTMLFeedErrorActionCreator({
        error: response.data,
        loading: false,
        isCreateHTMLFeedSuccess: false,
      }),
    );
  }
}

// watchers
export default function* HTMLFeedsWatcher() {
  yield takeEvery<GET_HTML_FEEDS_ACTIONS>(
    HTML_FEED_ACTIONS.GET_HTML_FEEDS,
    getHTMLFeedsWorker,
  );

  yield takeEvery<CREATE_HTML_FEED_ACTIONS>(
    HTML_FEED_ACTIONS.CREATE_HTML_FEED,
    createHTMLFeedWorker,
  );
  yield takeEvery<CREATE_SCHEDULE_HTML_FEED_ACTIONS>(
    HTML_FEED_ACTIONS.CREATE_SCHEDULE_HTML_FEED,
    createScheduleHTMLFeedWorker,
  );

  yield takeEvery<SCHEDULE_HTML_FEED_ACTIONS>(
    HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED,
    scheduleHTMLFeedWorker,
  );

  yield takeEvery<DELETE_HTML_FEED_ACTIONS>(
    HTML_FEED_ACTIONS.DELETE_HTML_FEED,
    deleteHTMLFeedWorker,
  );

  yield takeEvery<EDIT_HTML_FEED_ACTIONS>(
    HTML_FEED_ACTIONS.EDIT_HTML_FEED,
    editHTMLFeedWorker,
  );
}
