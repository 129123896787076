/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import ShowMoreDeviceGroupsTable from './ModalContents/ShowMoreDeviceGroupsTable';
import styles from './showMoreCell.module.scss';
import useIsAdmin from '../../utils/hooks/useIsAdmin';

const Tags = ({ itemName }: { itemName: string }) => (
  <span className={clsx('fs-5 fw-bold mb-1 fs-6', styles.item)}>
    {itemName}
  </span>
);

const ShowMoreCell = ({
  itemNames = [],
  itemId = [],
  withModal,
  title,
  count = 1,
  linkTo,
  children,
  widthDefaultSize = false,
  bodyClassName,
  onlyCount = false,
}: {
  itemNames: string[];
  itemId?: string[];
  count?: number;
  withModal?: boolean;
  widthDefaultSize?: boolean;
  title: string;
  linkTo?: string;
  bodyClassName?: string;
  children: React.ReactNode;
  onlyCount?: boolean;
}) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);
  const [isAdmin] = useIsAdmin();

  if (!itemNames.length) {
    return <div className="w-100 text-center">-</div>;
  }

  if (itemNames.length === 1 && !onlyCount) {
    return (
      <>
        {isAdmin && linkTo ? (
          <Link to={linkTo.replace(':id', itemId[0])}>
            <div className="w-100 min-w-150px h-100 d-flex justify-content-center align-items-center">
              <span
                className={clsx('fs-7 fw-bold mb-1 fs-7', styles.singleItem)}
                style={{ padding: '1%', textAlign: 'center' }}
              >
                {itemNames[0]}
              </span>
            </div>
          </Link>
        ) : (
          <div className="w-100 min-w-150px h-100 d-flex justify-content-center align-items-center">
            <span
              className={clsx('fs-7 fw-bold mb-1 fs-7', styles.singleItem)}
              style={{ padding: '1%', textAlign: 'center' }}
            >
              {itemNames[0]}
            </span>
          </div>
        )}
      </>
    );
  }

  const itemsToDisplay = itemNames.slice(0, count);
  const skippedLength = !onlyCount
    ? itemNames.length - itemsToDisplay.length
    : itemNames.length;

  return (
    <>
      <div className="d-flex min-w-200px flex-wrap  align-items-center justify-content-center flex-column">
        {!onlyCount &&
          itemsToDisplay.map((itemName: string, i) => (
            <>
              {itemId.length === 0 ? (
                <>
                  {isAdmin && linkTo ? (
                    <Link
                      to={linkTo.replace(':id', itemId[0])}
                      key={itemName + i}
                    >
                      <span
                        className={clsx('fs-7 fw-bold mb-1 fs-6', styles.item)}
                      >
                        {itemName}
                      </span>
                    </Link>
                  ) : (
                    <span
                      className={clsx('fs-7 fw-bold mb-1 fs-6', styles.item)}
                      key={itemName + i}
                    >
                      {itemName}
                    </span>
                  )}
                </>
              ) : (
                <>
                  {isAdmin && linkTo ? (
                    <Link
                      to={linkTo.replace(':id', itemId[0])}
                      key={itemName + i}
                    >
                      <span
                        className={clsx('fs-7 fw-bold mb-1 fs-6', styles.item)}
                      >
                        {itemName}
                      </span>
                    </Link>
                  ) : (
                    <span
                      className={clsx('fs-7 fw-bold mb-1 fs-6', styles.item)}
                      key={itemName + i}
                    >
                      {itemName}
                    </span>
                  )}
                </>
              )}
            </>
          ))}

        {!!skippedLength && !onlyCount && (
          <div
            role="button"
            tabIndex={-1}
            onClick={open}
            onKeyPress={open}
            className={clsx(
              'fs-7 fw-bold',
              styles.showMoreBtn,
              withModal && ['hoverable', styles.clickableItem],
            )}
          >
            +{skippedLength}
          </div>
        )}

        {onlyCount && (
          <div
            role="button"
            tabIndex={-1}
            onClick={open}
            onKeyPress={open}
            className={clsx(
              'fs-7 fw-bold',
              styles.showMoreBtn,
              withModal && ['hoverable', styles.clickableItem],
            )}
          >
            {t('common.show_more')}
          </div>
        )}
      </div>
      <Modal
        onHide={close}
        show={isModalVisible}
        title={title}
        widthDefaultSize={widthDefaultSize}
        withFooter={false}
        bodyClassName={clsx('d-flex flex-wrap ', bodyClassName)}
      >
        {children}
      </Modal>
    </>
  );
};

ShowMoreCell.Tags = Tags;
ShowMoreCell.DeviceGroups = ShowMoreDeviceGroupsTable;
export default ShowMoreCell;
