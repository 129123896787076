import React from 'react';
import { useTranslation } from 'react-i18next';
import { KTSVG } from '../../lib/metronic/helpers';
import Modal from '../Modal';

export default ({
  isModalVisible,
  setIsModalVisible,
}: {
  isModalVisible: boolean;
  setIsModalVisible: (mode: boolean) => void;
}) => {
  const close = () => {
    setIsModalVisible(false);
  };
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={isModalVisible}
        onHide={close}
        withFooter={false}
        withHeader={false}
        widthDefaultSize={false}
        bodyClassName="d-flex flex-center flex-column my-7"
      >
        <span className="svg-icon svg-icon-5tx svg-icon-danger mb-10">
          <KTSVG
            path="/media/icons/duotune/general/gen044.svg"
            className="svg-icon-1"
          />
        </span>
        <div className="text-center">
          <h5 className="fw-bolder fs-1 mb-10">
            {t('users.trial_user_warning')}
          </h5>
          <div className="d-flex flex-center flex-wrap">
            <button
              onClick={close}
              type="button"
              className="btn btn-outline btn-outline-danger btn-active-danger m-2"
            >
              {t('common.close')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
