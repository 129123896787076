import React, { useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { OptionTypeBase, ValueType } from 'react-select/src/types';

export interface SelectedValue extends OptionTypeBase {
  value?: string;
  label?: string;
}

type ArrayHandler = (selectedValue: SelectedValue[]) => void;
type SingleSelectHandler = (selectedValue: SelectedValue) => void;

interface SelectAsyncProps {
  onChange: SingleSelectHandler | ArrayHandler;
  loadOptions: (search: string) => Promise<SelectedValue[]>;
  initialValue?: SelectedValue | SelectedValue[];
  id?: string;
  className?: string;
  isMulti?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  name?: string;
}

interface DefaultProps extends SelectAsyncProps {
  initialValue: Required<SelectAsyncProps>['initialValue'];
  isMulti: Required<SelectAsyncProps>['isMulti'];
  isDisabled: Required<SelectAsyncProps>['isDisabled'];
  isClearable: Required<SelectAsyncProps>['isClearable'];
}

const SelectAsync = ({
  isMulti,
  onChange,
  initialValue,
  loadOptions,
  id,
  className,
  placeholder,
  isDisabled,
  isClearable,
  name,
}: DefaultProps) => {
  const [selectedValue, setSelectedGroup] =
    useState<ValueType<SelectedValue, false>>(initialValue);

  const handleChange = (currentValue: ValueType<SelectedValue, false>) => {
    if (isClearable || currentValue) {
      setSelectedGroup(currentValue);
    }
  };

  useEffect(() => {
    if (isMulti) {
      (onChange as ArrayHandler)((selectedValue as SelectedValue[]) ?? []);
    } else {
      (onChange as SingleSelectHandler)((selectedValue as SelectedValue) ?? {});
    }
  }, [selectedValue]);

  useEffect(() => {
    setSelectedGroup(initialValue ?? null);
  }, [initialValue]);

  return (
    <AsyncCreatableSelect
      cacheOptions
      isMulti={isMulti}
      name={name}
      id={id}
      defaultOptions
      value={selectedValue}
      onChange={handleChange}
      loadOptions={loadOptions}
      className={className}
      components={{
        IndicatorSeparator: () => null,
      }}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isClearable={isClearable}
    />
  );
};

SelectAsync.defaultProps = {
  initialValue: null,
  isMulti: false,
  isDisabled: false,
  isClearable: false,
};

export default SelectAsync;
