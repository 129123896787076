import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { UserStatus } from '../../../enums/userStatus.enum';
import { debouncedUpdateSearch } from '../../../utils/browserHistory';
import UsersGroupsQueryParam from '../../../api/endpoints/usersGroups/enums/usersGroupsQueryParam.enum';
import { statusesTranslation } from '../../../constants/translation/users';
import SelectCompany from '../../../containers/Companies/SelectCompany';
import SelectLocation from '../../../containers/SelectLocation';
import FiltersDropdown from '../../Dropdown/FiltersDropdown';
import useFilter from '../../../utils/hooks/useFilter';
import Select from '../../Select/RegularSelect';
import { readFromQueryString } from '../../../utils/queryParams';

export default ({
  className,
  UserId,
}: {
  className?: string;
  UserId?: string;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const currentUserId =
    UserId || (readFromQueryString(search, 'currentUserId') as string);
  const [status, setStatus] = useFilter<UserStatus>(
    UsersGroupsQueryParam.Status,
  );
  const [location, setLocation] = useFilter<string>(
    UsersGroupsQueryParam.LocationId,
  );
  const [company, setCompany] = useFilter<string>(
    UsersGroupsQueryParam.CompanyId,
  );

  const handleApply = () => {
    debouncedUpdateSearch({
      [UsersGroupsQueryParam.Status]: status ?? '',
      [UsersGroupsQueryParam.LocationId]: location ?? '',
      [UsersGroupsQueryParam.CompanyId]: company ?? '',
    });
  };

  // Reset filters in the query params
  const handleReset = () =>
    debouncedUpdateSearch({
      [UsersGroupsQueryParam.Status]: '',
      [UsersGroupsQueryParam.LocationId]: '',
      [UsersGroupsQueryParam.CompanyId]: '',
    });

  return (
    <FiltersDropdown
      className={className}
      onApplyFilters={handleApply}
      onReset={handleReset}
    >
      <p className="mb-5">
        <label htmlFor="status" className="form-label fw-bold">
          {t('common.status')}:
        </label>
        <Select<UserStatus>
          id="status"
          defaultValue={
            status && { value: status, label: t(statusesTranslation[status]) }
          }
          placeholder={t('users_groups.select_status')}
          onSelect={(selectedStatus) => setStatus(selectedStatus as UserStatus)}
          options={Object.values(UserStatus).map((userStatusValue) => ({
            label: t(statusesTranslation[userStatusValue]),
            value: userStatusValue,
          }))}
        />
      </p>
      <p className="mb-10">
        <label htmlFor="company" className="form-label fw-bold">
          {t('common.company')}:
        </label>
        <SelectCompany
          id="company"
          initialValue={company}
          currentUserId={currentUserId}
          onChange={({ value }) => {
            if (value !== 'loadingValue') setCompany(value);
          }}
          isClearable
        />
      </p>
      {company && (
        <p className="mb-10">
          <label htmlFor="location" className="form-label fw-bold">
            {t('common.location')}: {console.log('company  ', company)}
          </label>
          <SelectLocation
            id="location"
            initialValue={location}
            onChange={({ value }) => {
              if (value !== 'loadingValue') setLocation(value);
            }}
            companyId={company}
            showDefaultOptions={false}
            isClearable
            currentUserId={currentUserId}
          />
        </p>
      )}
    </FiltersDropdown>
  );
};
