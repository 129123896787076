/**
 * Formats the date string
 * @param format
 */
export const formatDateString = (
  format: Intl.DateTimeFormatOptions,
): ((dateString: string) => string) => {
  const dateFormat = new Intl.DateTimeFormat(navigator.language, format);

  return (dateString: string) => dateFormat.format(new Date(dateString));
};
