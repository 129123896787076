import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KTSVG } from '../../../../lib/metronic/helpers';
import Modal from '../../../../components/Modal';
import Device from '../../../../interfaces/devices/device.interface';
import LinkDevice from '../../../../components/Devices/LinkDevice';

export default ({
  children,
  onSubmit,
  device,
  isDisabled,
}: {
  children?: React.ReactNode;
  onSubmit: (pin: string) => void;
  isDisabled?: boolean;
  device: Device;
}) => {
  const { t } = useTranslation();

  // const handleConfirm = async () => {
  //   api.libraryCategories.deleteCategory(categoryId).then(() => {
  //     if (onUpdate) {
  //       onUpdate();
  //     }
  //   });
  // };

  // return (
  //   <WithDeleteConfirm
  //     body={`${t('common.delete_confirmation.title')} ${t(
  //       'common.delete_confirmation.library',
  //     )} `}
  //     handleConfirm={handleConfirm}
  //   >
  //     {children ?? (
  //       <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
  //         <KTSVG
  //           path="/media/icons/duotune/general/gen027.svg"
  //           className="svg-icon-3"
  //         />
  //       </span>
  //     )}
  //   </WithDeleteConfirm>
  // );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const open = () => {
    setIsModalVisible(true);
  };

  const close = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </span>
        )}
      </span>
      <Modal
        show={isModalVisible}
        onHide={close}
        withFooter={false}
        withHeader={false}
        widthDefaultSize={false}
        bodyClassName="d-flex flex-center flex-column my-7"
      >
        <span className="svg-icon svg-icon-5tx svg-icon-danger mb-10">
          <KTSVG
            path="/media/icons/duotune/general/gen044.svg"
            className="svg-icon-1"
          />{' '}
        </span>
        <h1 className="fw-bolder fs-1 mb-5">
          {t('devices.confirm_unlink_and_reassign.title')}
        </h1>
        <div className="text-center">
          <h5 className="fw-bolder fs-3 mb-10 ">
            {t('devices.confirm_unlink_and_reassign.content')}
          </h5>
          <div className="d-flex flex-center flex-wrap">
            <LinkDevice onSubmit={onSubmit} device={device}>
              <button
                type="button"
                className="btn btn-danger m-2"
                disabled={isDisabled}
              >
                {isDisabled ? (
                  <>
                    {' '}
                    <span>
                      {t('devices.confirm_unlink_and_reassign.Confirm')}
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </>
                ) : (
                  t('devices.confirm_unlink_and_reassign.confirm')
                )}
              </button>
            </LinkDevice>
            <button
              onClick={close}
              type="button"
              className="btn btn-outline btn-outline-danger btn-active-danger m-2"
              disabled={isDisabled}
            >
              {t('devices.confirm_unlink_and_reassign.cancel')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
