import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import clsx from 'clsx';
import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import Card from '../../components/Card';
import { Option } from '../../components/Select/RegularSelect';
import Carousel from '../../components/Libraries/LibraryInfo/Carousel';
import ScheduleLibraryRequestData from '../../api/endpoints/libraries/interfaces/scheduleLibraryRequestData.interface';
import LibrariesQueryParam from '../../api/endpoints/libraries/enums/librariesQueryParam.enum';
import PublishModal from '../../components/Libraries/LibraryInfo/PublishModal';
import {
  clearPlaceHolder,
  clearQueryParams,
  setPlaceHolder,
  setQueryParams,
} from '../../store/actions/breadcrumbs';
import Library, { Contents } from '../../interfaces/library.interface';
import { getUrlsFromContent } from '../../utils/library';
import ErrorMessage from '../../components/ErrorMessage';
import WithSpinner from '../../components/WithSpinner';
import config from '../../routing/config';
import api from '../../api';

const preloaderHeight = 500;

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [publishModal, setPublishModal] = useState<boolean>(false);
  const [libraryLoading, setLibraryLoading] = useState<boolean>(false);
  const [library, setLibrary] = useState<Library>();

  const { id } = useParams<{
    id: string;
  }>();

  const [content, setContent] = useState<Option[]>([]);
  const videoSelected = () => {
    let initialArray: Contents = [];
    if (library?.posters?.length) {
      initialArray = [...initialArray, ...library.posters];
    }
    if (library?.content?.length) {
      initialArray = [...initialArray, ...library.content];
    }
    if (library?.videos?.length) {
      initialArray = [...initialArray, ...library.videos];
    }
    return initialArray?.filter((item) =>
      content.find((selectedContent) => selectedContent.value === item.id),
    );
  };
  const isContentSelected = !!content?.length;

  const handleCloseSchedule = () => setPublishModal(false);
  const handleOpenSchedule = () => setPublishModal(true);

  const scheduleLibraryOptions = () => {
    let initialArray: Contents = [];
    if (library?.posters?.length) {
      initialArray = [...initialArray, ...library.posters];
    }
    if (library?.content?.length) {
      initialArray = [...initialArray, ...library.content];
    }
    if (library?.videos?.length) {
      initialArray = [...initialArray, ...library.videos];
    }
    if (library?.fullscreen?.length) {
      initialArray = [...initialArray, ...library.fullscreen];
    }

    const returnedArray = initialArray.map((item) => ({
      label: item.text,
      value: item.id,
    }));
    return returnedArray;
  };

  const getLibrary = async () => {
    try {
      setLibraryLoading(true);

      const foundLibrary = await api.libraries.getLibraryById(id);
      const parentCategory = foundLibrary?.category?.parentCategory;
      const category = foundLibrary?.category;
      if (parentCategory) {
        dispatch(
          setQueryParams({
            route: config.indigoLibraries.route,
            qp: LibrariesQueryParam.CategoryId,
            values: [parentCategory?.id],
          }),
        );
        dispatch(
          setQueryParams({
            route: config.indigoSubCategoriesLibraries.route,
            qp: LibrariesQueryParam.CategoryId,
            values: [category?.id],
          }),
        );
        dispatch(
          setPlaceHolder({
            route: config.indigoLibraries.route,
            value: parentCategory?.name,
          }),
        );
        dispatch(
          setPlaceHolder({
            route: config.indigoSubCategoriesLibraries.route,
            value: category?.name,
          }),
        );
        dispatch(
          setPlaceHolder({
            route: config.indigoLibraryUnderSubCategory.route,
            value: foundLibrary?.text,
          }),
        );
      } else {
        dispatch(
          setQueryParams({
            route: config.indigoLibraries.route,
            qp: LibrariesQueryParam.CategoryId,
            values: [category?.id],
          }),
        );

        dispatch(
          setPlaceHolder({
            route: config.indigoLibraries.route,
            value: category?.name,
          }),
        );
        dispatch(
          setPlaceHolder({
            route: config.indigoLibrary.route,
            value: foundLibrary?.text,
          }),
        );
      }

      setLibrary(foundLibrary);
    } finally {
      setLibraryLoading(false);
    }
  };

  useEffect(() => {
    getLibrary();
    return () => {
      dispatch(clearPlaceHolder());
      dispatch(clearQueryParams());
    };
  }, []);

  const handleScheduleLibrary = async (data: ScheduleLibraryRequestData) => {
    setLibraryLoading(true);
    try {
      await api.libraries.scheduleLibrary(id, {
        ...data,
        skuIds: map(content, 'value'),
      });

      toast.success(i18next.t<string>('library.successfully_scheduled'));

      setContent([]);
    } finally {
      setLibraryLoading(false);
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <WithSpinner
            isLoading={libraryLoading}
            style={{ minHeight: `${preloaderHeight}px` }}
            size="md"
          >
            <div className="container py-10">
              <div className="row">
                <div className="col-md-7 col-sm-12 col-lg-8 col-xl-6">
                  <Carousel
                    library={library}
                    images={[
                      ...getUrlsFromContent(library?.posters),
                      ...getUrlsFromContent(library?.content),
                      ...getUrlsFromContent(library?.fullscreen),
                    ]}
                  />
                </div>
                <div className="col-md-5 col-sm-12 col-lg-4 col-xl-6 py-5 px-10">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <h1 className="h1">{library?.text}</h1>
                  </div>
                  <p className="my-5">{library?.description}</p>

                  <div className="mw-600px d-flex flex-row">
                    <div className="w-75">
                      <span className="col-form-label text-lg-end fw-bolder">
                        {t('library.indigo.selectContent')}
                      </span>
                      {/* Added videos: We should Implement Schedule for videos */}
                      <MultiSelect
                        options={scheduleLibraryOptions()}
                        value={content}
                        onChange={setContent}
                        labelledBy={t('library.indigo.selectContent')}
                        hasSelectAll
                      />
                      <ErrorMessage>
                        {!isContentSelected &&
                          'Please select at least one item'}
                      </ErrorMessage>
                    </div>

                    <div className="w-25 mx-4 mt-6">
                      <button
                        type="button"
                        disabled={!isContentSelected}
                        className={clsx(
                          'btn border px-15 py-2 fw-bolder',
                          isContentSelected
                            ? 'border-primary text-primary'
                            : 'border-dark text-dark',
                        )}
                        onClick={handleOpenSchedule}
                      >
                        {t('common.schedule')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </WithSpinner>
        </Card.Body>
      </Card>

      <PublishModal
        fullInfoSelectedItems={videoSelected()}
        isVisible={publishModal}
        onSubmit={handleScheduleLibrary}
        onClose={handleCloseSchedule}
        defaultValues={{ duration: '15' }}
      />
    </>
  );
};
