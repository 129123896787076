import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { map } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Card from '../Card';
import Search from '../Search/QueryParamSearch';
import Pagination from '../Pagination/QueryParamPagination';
import GroupsTable from './GroupsTable';
import SelectUser from '../../containers/Users/SelectUser';
import CreateDeviceGroup from '../../containers/DevicesGroups/CreateGroup';
import DevicesGroupsQueryParam from '../../api/endpoints/devicesGroups/enums/devicesGroupsQueryParam.enum';
import PaginationQueryParam from '../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../enums/queryParams/sortingQueryParam.enum';
import DeviceGroup from '../../interfaces/deviceGroup.interface';
import styles from './devicesGroups.module.scss';
import useTableMultiSelect from '../../utils/hooks/useTableMultiSelect';
import RemoveDeviceGroup from '../../containers/DevicesGroups/RemoveGroup';
import UpdateDeviceGroupAction from '../../store/actions/devicesGroups/interfaces/updateDeviceGroupAction.interface';
import CreateDeviceGroupRequestData from '../../api/endpoints/devicesGroups/interfaces/createDeviceGroupRequestData.interface';
import useSyncWithSearch from '../../utils/hooks/useSyncWithSearch';
import AssignDeviceGroup from './AssignDeviceGroup';
import { KTSVG } from '../../lib/metronic/helpers';
import { SelectedValue } from '../Select/SelectAsync';
import GlobalState from '../../store/reducers/globalState.interface';
import { UserRole } from '../../enums/userRole.enum';

const pageSize = 7;
const tableRowHeight = 63;

export default ({
  groups,
  total,
  isLoading,
  resetOnSearch,
  children,
  onEditDeviceGroup,
  unAssignDeviceGroup,
  removeDeviceGroup,
  onAddGroup,
  assignDevicesGroupsToUserGroup,
  userGroupId,
  userProfileId,
  isSelectable,
  drownDown,
  currentUserId,
  hideButton,
}: {
  groups: DeviceGroup[];
  total: number;
  isLoading: boolean;
  resetOnSearch?: boolean;
  children?: React.ReactNode;
  drownDown?: boolean;
  isSelectable?: boolean;
  userProfileId?: string;
  removeDeviceGroup?: (idDeviceGroup: string) => void;
  onEditDeviceGroup?: (data: UpdateDeviceGroupAction['payload']) => void;
  unAssignDeviceGroup?: (deviceGroupId: string, userGroupId: string) => void;
  onAddGroup?: (values: CreateDeviceGroupRequestData) => void;
  assignDevicesGroupsToUserGroup?: (data: SelectedValue[]) => void;
  userGroupId?: string;
  currentUserId?: string;
  hideButton?: boolean;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const customersId = useLocation().pathname.split('/')[2];
  const [userSelectValue, setUserSelectValue] = useSyncWithSearch(
    DevicesGroupsQueryParam.UserId,
  );
  const isSuperAdmin = useSelector((state: GlobalState) =>
    state.userData.user.roles.includes(UserRole.SuperAdmin),
  );
  const [selectedItems, setSelectedItem, selectAll, resetSelectedItems] =
    useTableMultiSelect(
      map(groups, 'id'),
      [],
      resetOnSearch ? search : undefined,
    );
  return (
    <Card>
      <Card.Header>
        {!children && (
          <Card.Header.Title>
            <Card.Header.Title.Label>
              {t('devices_groups.manage_groups')}{' '}
            </Card.Header.Title.Label>
          </Card.Header.Title>
        )}
        <Card.Header.Toolbar>
          {children}
          <div className="d-sm-flex flex-wrap flex-stack mb-sm-5">
            <Search
              queryField={DevicesGroupsQueryParam.Search}
              pageNumberQueryField={PaginationQueryParam.Page}
              placeholder={t('devices_groups.search_device_group')}
              className="me-2"
            />
            <SelectUser
              initialValue={userSelectValue}
              onChange={setUserSelectValue}
              className={clsx(styles.userSelect)}
              id={groups[0]?.id}
              currentUserId={currentUserId || userProfileId || customersId}
              userProfileId={userProfileId}
              isAdmin={isSuperAdmin}
            />
          </div>
          <div>
            {selectedItems.length > 1 && (
              <RemoveDeviceGroup
                selectedItems={selectedItems}
                onDelete={resetSelectedItems}
              >
                <button type="button" className="btn btn-danger m-2">
                  {t('common.delete_confirmation.delete_selected')}
                </button>
              </RemoveDeviceGroup>
            )}
            {!assignDevicesGroupsToUserGroup && !hideButton && (
              <CreateDeviceGroup className="me-2" onSubmit={onAddGroup} />
            )}
            {assignDevicesGroupsToUserGroup && (
              <AssignDeviceGroup
                userGroupId={userGroupId}
                assignDevicesGroupsToUserGroup={assignDevicesGroupsToUserGroup}
              >
                <button type="button" className="btn btn-primary me-2">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />{' '}
                  {t('devices_groups.add_group')}
                </button>
              </AssignDeviceGroup>
            )}
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <GroupsTable
          groups={groups}
          isLoading={isLoading}
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
          isSelectable={isSelectable}
          onEditDeviceGroup={onEditDeviceGroup}
          removeDeviceGroup={removeDeviceGroup}
          unAssignDeviceGroup={unAssignDeviceGroup}
          drownDown={drownDown || false}
          userProfileId={currentUserId}
        />
        <Pagination
          total={total}
          pageSize={pageSize}
          pageNumberQueryField={PaginationQueryParam.Page}
          pageSizeQueryField={PaginationQueryParam.Limit}
          className="mt-3 mb-5"
        />
      </Card.Body>
    </Card>
  );
};
