import React from 'react';
import { useTranslation } from 'react-i18next';
import WithConfirm from '../../../../components/WithConfirm/Controlled';
import Modal from '../../../../components/Modal';
import RemoveContentTable from './RemoveContentTable';
import GetPlaylistsRequestData from '../../../../api/endpoints/playlists/interfaces/getPlaylistsRequestData.interface';

export default ({
  body,
  isVisible,
  isLoading,
  handleConfirm,
  handleDecline,
  confirmButtonText,
  playlists,
  contentTitle,
  onSelectionChange,
  handleConfirmMultipleDelete,
  multipleContents,
  total,
  preselectedItems,
  setFiltersSearch,
  multiplePlaylists,
  loadingModals,
}: {
  body: string;
  isVisible: boolean;
  isLoading: boolean;
  handleConfirm: () => void;
  handleDecline: () => void;
  handleConfirmMultipleDelete?: () => void;
  confirmButtonText?: string;
  playlists?: any;
  onSelectionChange?: (selectedItems: string[]) => void;
  multipleContents?: boolean;
  total: number;
  preselectedItems: string;
  setFiltersSearch?: (filters: GetPlaylistsRequestData) => void;
  multiplePlaylists?: boolean;
  loadingModals?: boolean;
  contentTitle?: string;
}) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  return (
    <>
      {multiplePlaylists && !multipleContents ? (
        <>
          {' '}
          <Modal
            title={`${t(
              'common.delete_confirmation.delete_multiple_playlists',
            )} (${contentTitle})`}
            // widthDefaultSize={false}
            show={isVisible}
            onHide={handleDecline}
            bodyClassName="py-5"
            footerContent={
              <button
                type="button"
                className="btn btn-danger m-2"
                onClick={() => {
                  if (handleConfirmMultipleDelete) {
                    handleConfirmMultipleDelete();
                    setDisabled(true);
                  }
                }}
                disabled={disabled}
              >
                {t('common.delete_confirmation.delete')}
              </button>
            }
          >
            <RemoveContentTable
              onSelectionChange={onSelectionChange}
              preselectedItems={[preselectedItems]}
              total={total}
              requestData={{}}
              playlists={playlists}
              setFiltersSearch={setFiltersSearch}
              loadingModals={loadingModals}
            />
          </Modal>
        </>
      ) : (
        <WithConfirm
          isVisible={isVisible}
          isLoading={isLoading}
          handleConfirm={handleConfirm}
          handleDecline={handleDecline}
          body={body}
          confirmButtonText={
            confirmButtonText ?? t('common.delete_confirmation.confirm')
          }
          discardButtonText={t('common.delete_confirmation.decline')}
        />
      )}
    </>
  );
};
