import clsx from 'clsx';
import { map } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import CreateTicker from '../../containers/Ticker/CreateTicker';
import PaginationQueryParam from '../../enums/queryParams/paginationQueryParam.enum';
import SortingQueryParam from '../../enums/queryParams/sortingQueryParam.enum';
import App from '../../interfaces/app.interface';
import { KTSVG } from '../../lib/metronic/helpers';
import useTableMultiSelect from '../../utils/hooks/useTableMultiSelect';
import { readAllFromQueryParams } from '../../utils/queryParams';
import Card from '../Card';
import NavTabs from '../NavTabs';
import Pagination from '../Pagination/QueryParamPagination';
import Search from '../Search/QueryParamSearch';
import TickerTable from './Tables/TickersTable';

const pageSize = 6;
const tableRowHeight = 63;

export default ({
  tickers,
  total,
  isLoading,
  resetOnSearch,
  onUpdate,
}: {
  tickers: App[];
  total: number;
  isLoading: boolean;
  resetOnSearch?: boolean;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(tickers, 'id'),
    [],
    resetOnSearch ? search : undefined,
  );

  const navTabs = [
    {
      key: 'tickers',
      tab: (
        <Link
          to="/apps/tickers/?isCompleted=false"
          className={clsx(
            'nav-link text-active-primary me-6',
            readAllFromQueryParams(search).isCompleted === 'false' && 'active',
          )}
        >
          {t('tickers.tickers')}
        </Link>
      ),
    },
    {
      key: 'completed-tickers',
      tab: (
        <Link
          to="/apps/tickers/?isCompleted=true"
          className={clsx(
            'nav-link text-active-primary me-6',
            readAllFromQueryParams(search).isCompleted === 'true' && 'active',
          )}
        >
          {t('tickers.completedTickers')}
        </Link>
      ),
    },
  ];

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          <NavTabs tabs={navTabs} separatorClassName="mb-7" />
          <Search
            queryField="search"
            pageNumberQueryField=""
            placeholder={t('tickers.searchTickers')}
            className="me-2"
          />
          <div className="d-flex">
            <CreateTicker onSuccess={onUpdate}>
              <button type="button" className="btn btn-primary">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />{' '}
                {t('tickers.addTicker.createTicker')}
              </button>
            </CreateTicker>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <TickerTable
          onUpdate={onUpdate}
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          tickers={tickers}
          isLoading={isLoading}
          sortQueryField={SortingQueryParam.SortBy}
          orderQueryField={SortingQueryParam.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
        />

        <Pagination
          total={total}
          pageSize={pageSize}
          pageNumberQueryField={PaginationQueryParam.Page}
          pageSizeQueryField={PaginationQueryParam.Limit}
          className="mt-3 mb-5"
        />
      </Card.Body>
    </Card>
  );
};
