import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddDeviceSteps from '../../../enums/steps/addDeviceSteps.enum';
import DeviceCreationStepsData from '../../../interfaces/devices/deviceCreationStepsData.interface';
import sizingStyles from '../../../styles/sizing.module.scss';
import WithModesModal from '../../WithModesModal';
import styles from './createDevice.module.scss';
import GeneralInfo from './Steps/LicensingInfo';
import Indigo from './Steps/DeviceInfo';
import validation from '../../../utils/validation';

export default ({
  onSubmit,
  isVisible,
  onClose,
}: {
  onSubmit: (data: DeviceCreationStepsData) => void;
  onClose?: () => void;
  isVisible: boolean;
}) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] = useState<Partial<DeviceCreationStepsData>>(
    {},
  );

  return (
    <WithModesModal<DeviceCreationStepsData, AddDeviceSteps>
      isVisible={isVisible}
      onClose={onClose}
      modes={{
        [AddDeviceSteps.GeneralInfo]: {
          title: t('devices.add_device'),
          render: ({ setMode, close }) => (
            <GeneralInfo
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              onDecline={close}
              validationSchema={validation
                .object()
                .shape(
                  {
                    // TODO: move this config to the appropriate place
                    name: validation
                      .string()
                      .min(1, t('devices.validation.please_enter_device_name'))
                      .required(),
                    language1: validation.string().required(),
                    // Licensing
                    licenseTypeId: validation.string().when('isActive', {
                      is: (isActive?: boolean) => !!isActive,
                      then: validation.string().required(),
                      otherwise: validation.string(),
                    }),
                    startDate: validation.date().when('isActive', {
                      is: (isActive?: boolean) => !!isActive,
                      then: validation.date().required(),
                      otherwise: validation.date(),
                    }),
                    expireDate: validation.date().when('isActive', {
                      is: (isActive?: boolean) => !!isActive,
                      then: validation.date().required(),
                      otherwise: validation.date(),
                    }),
                    libraryAccessStartDate: validation
                      .date()
                      .when(['isLibrary', 'isActive'], {
                        is: (isLibrary?: boolean, isActive?: boolean) =>
                          isLibrary && isActive,
                        then: validation.date().required(),
                        otherwise: validation.date(),
                      }),
                    libraryAccessExpireDate: validation
                      .date()
                      .when(['isLibrary', 'isActive'], {
                        is: (isLibrary?: boolean, isActive?: boolean) =>
                          isLibrary && isActive,
                        then: validation.date().required(),
                        otherwise: validation.date(),
                      }),
                  },
                  [
                    ['licenseTypeId', 'isActive'],
                    ['startDate', 'isActive'],
                    ['expireDate', 'isActive'],
                    ['libraryAccessStartDate', 'isLibrary'],
                    ['libraryAccessExpireDate', 'isLibrary'],
                  ],
                )
                .required()}
            />
          ),
        },
        [AddDeviceSteps.Indigo]: {
          title: t('devices.add_device'),
          render: ({ setMode, close }) => (
            <Indigo
              setMode={setMode}
              data={stepsData}
              setData={setStepsData}
              validationSchema={{}}
              onSubmit={(data) => {
                onSubmit(data as DeviceCreationStepsData);

                close();
                setStepsData({});
              }}
            />
          ),
        },
      }}
      defaultMode={AddDeviceSteps.GeneralInfo}
      dialogClassName={clsx('mw-1000px', sizingStyles.fitContent)}
      bodyClassName={styles.modalBody}
    />
  );
};
