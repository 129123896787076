import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * Performs the useEffect functionality but skips the "did mount" call
 * @param effect
 * @param deps
 */
export default (effect: EffectCallback, deps?: DependencyList) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) return effect();

    didMountRef.current = true;

    return undefined;
  }, deps);
};
