import React from 'react';
import { useTranslation } from 'react-i18next';
import WithConfirm from '../../WithConfirm/Controlled';

export default ({
  body,
  isVisible,
  isLoading,
  handleConfirm,
  handleDecline,
  confirmButtonText,
}: {
  body: string;
  isVisible: boolean;
  isLoading: boolean;
  handleConfirm: () => void;
  handleDecline: () => void;
  confirmButtonText?: string;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <WithConfirm
        isVisible={isVisible}
        isLoading={isLoading}
        handleConfirm={handleConfirm}
        handleDecline={handleDecline}
        body={body}
        confirmButtonText={
          confirmButtonText ?? t('common.delete_confirmation.confirm')
        }
        discardButtonText={t('common.delete_confirmation.decline')}
      />
    </>
  );
};
