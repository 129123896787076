import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap-v5';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import clsx from 'clsx';
import FormErrorMessage from '../../../FormErrorMessage';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import { CreateGoogleCalendarFeedRequest } from '../../../../store/types/apps/googleCalendarFeed';
import Orientation from '../../../../enums/orientation.enum';
import styles from './GoogleCalendar.module.scss';
import { disableRightClickIframe } from '../../../../utils/common';

type CreateGoogleCalendarRequestFormMutated = Omit<
  CreateGoogleCalendarFeedRequest,
  'config' | 'placement'
>;

interface CreateGoogleCalendarFeedRequestFormPayload
  extends CreateGoogleCalendarRequestFormMutated {
  googleCalendarFeedUrl: string;
}

export interface CreateFeedModalProps {
  onSubmit: (data: CreateGoogleCalendarFeedRequest) => void;
  googleCalendar?: App;
  onClose?: () => void;
  isLoading: boolean;
}

export default ({
  onSubmit,
  onClose,
  isLoading,
  googleCalendar = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();
  const [previewDocumentIsFetching, setPreviewDocumentIsFetching] =
    React.useState<boolean>(false);

  const initialValues: CreateGoogleCalendarFeedRequestFormPayload = {
    name: googleCalendar?.name ?? '',
    googleCalendarFeedUrl:
      googleCalendar?.dependency?.config?.googleCalendarFeedUrl ?? '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    googleCalendarFeedUrl: Yup.string()
      .matches(
        /(calendar.google.com\/calendar)/,
        t('apps.googleCalendar.invalidLink'),
      )
      .url(t('common.validation.validUrl'))
      .defined(),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateGoogleCalendarFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const modalHeight = 400;

  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [embedUrl, setEmbedUrl] = useState<string>('');

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateGoogleCalendarFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const handleFeedNameChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFeedName(value);
  };

  const [googleCalendarFeedUrl, setgoogleCalendarFeedUrl] =
    useStateWithHookForm<CreateGoogleCalendarFeedRequestFormPayload, string>(
      { setValue, trigger, name: 'googleCalendarFeedUrl' },
      initialValues.googleCalendarFeedUrl,
    );

  const handlePreviewDocumentIsFetching = (
    previewDocumentIsFetchingData: boolean,
  ) => setPreviewDocumentIsFetching(previewDocumentIsFetchingData);

  const handlegoogleCalendarFeedUrlChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    handlePreviewDocumentIsFetching(value != null);

    try {
      const urlObj = new URL(value);
      const urlParmsObj = new URLSearchParams(urlObj.searchParams);

      if (value) {
        urlParmsObj.set('showTitle', '1');
        urlParmsObj.set('showNav', '0');
        urlParmsObj.set('showTabs', '0');
        urlParmsObj.set('showPrint', '0');
        urlParmsObj.set('showDate', '1');
        urlParmsObj.set('showCalendars', '1');
        urlParmsObj.set('showTz', '0');

        const newUrl = `${urlObj}${urlObj.hash}?${urlParmsObj.toString()}`;

        setgoogleCalendarFeedUrl(newUrl);
      }
    } catch {
      setgoogleCalendarFeedUrl(value);
    }
  };

  const handlegoogleCalendarEmbedUrlChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const parser = new DOMParser();
    const iframeHtml = parser.parseFromString(value, 'text/html');
    const url = iframeHtml.querySelector('iframe')?.getAttribute('src');

    if (url) {
      const urlObj = new URL(`${url}`);
      const urlParmsObj = new URLSearchParams(urlObj.searchParams);

      urlParmsObj.set('showTitle', '1');
      urlParmsObj.set('showNav', '0');
      urlParmsObj.set('showTabs', '0');
      urlParmsObj.set('showPrint', '0');
      urlParmsObj.set('showDate', '1');
      urlParmsObj.set('showCalendars', '1');
      urlParmsObj.set('showTz', '0');

      const newUrl = `${urlObj}${urlObj.hash}?${urlParmsObj.toString()}`;

      setgoogleCalendarFeedUrl(newUrl ?? '');
    }

    setEmbedUrl(value);
  };

  const handleOnIFrameLoaded = () => handlePreviewDocumentIsFetching(false);

  const handleOnSubmit = async (
    data: CreateGoogleCalendarFeedRequestFormPayload,
  ) => {
    const { name, googleCalendarFeedUrl: googleCalendarFeedUrlData } = data;
    const createGoogleCalendarFeedRequest: CreateGoogleCalendarFeedRequest = {
      name,
      isScheduled,
      config: {
        googleCalendarFeedUrl: googleCalendarFeedUrlData,
      },
      zoneId: Orientation.Landscape,
    };

    onSubmit(createGoogleCalendarFeedRequest);
  };

  React.useEffect(() => {
    disableRightClickIframe(feedName);
  }, []);

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id="form"
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex flex-row p-4 w-100">
            <div className="d-flex flex-column mx-2 w-100">
              <label
                htmlFor="name"
                className="required text-dark fw-bolder my-1"
              >
                {t('apps.googleCalendar.googleCalendarTitle')}
              </label>
              <input
                name="name"
                id="name"
                value={feedName}
                onChange={handleFeedNameChange}
                className="form-control form-control-solid"
                placeholder="Type here"
                type="text"
              />
              <FormErrorMessage
                name="name"
                errors={errors}
                className="my-1 px-2"
              />
            </div>

            <div className="d-flex flex-column mx-2 w-100">
              <label
                htmlFor="googleCalendarFeedUrl"
                className="required text-dark fw-bolder my-1"
              >
                {t('apps.googleCalendar.calendarUrl')}
              </label>
              <div className="input-group">
                <input
                  name="googleCalendarFeedUrl"
                  id="googleCalendarFeedUrl"
                  value={googleCalendarFeedUrl}
                  onChange={handlegoogleCalendarFeedUrlChange}
                  className="form-control form-control-solid"
                />
                <span
                  className="input-group-text border-0"
                  title={t('apps.googleCalendar.howItWorks')}
                >
                  <a
                    href="https://support.google.com/a/users/answer/9308870?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fas fa-info-circle fs-4 text-dark" />
                  </a>
                </span>
                <FormErrorMessage
                  name="googleCalendarFeedUrl"
                  errors={errors}
                  className="my-1 px-2"
                />
              </div>
            </div>
          </div>
          <div className="px-4 w-100">
            <label
              htmlFor="googleCalendarFeedUrl"
              className="text-dark fw-bolder my-1"
            >
              {t('apps.googleCalendar.calenderEmbedUrl')}
            </label>

            <div className="input-group">
              <input
                name="googleCalendarEmbedUrl"
                id="googleCalendarEmbedUrl"
                value={embedUrl}
                onChange={handlegoogleCalendarEmbedUrlChange}
                className="form-control form-control-solid"
              />
              <span
                className="input-group-text border-0"
                title={t('apps.googleCalendar.howItWorks')}
              >
                <a
                  href="https://support.google.com/calendar/answer/41207?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fas fa-info-circle fs-4 text-dark" />
                </a>
              </span>
            </div>

            <FormErrorMessage
              name="googleCalendarFeedUrl"
              errors={errors}
              className="my-1 px-2"
            />
          </div>

          <div className="d-flex flex-column   flex-center  p-4 w-100">
            {!errors.googleCalendarFeedUrl && googleCalendarFeedUrl !== '' ? (
              <div className="d-flex flex-column flex-center w-100 h-100">
                {previewDocumentIsFetching ? (
                  <Spinner role="status" animation="border" />
                ) : null}
                <iframe
                  title={feedName}
                  src={googleCalendarFeedUrl}
                  width="100%"
                  height={`${modalHeight}px`}
                  onLoad={handleOnIFrameLoaded}
                  frameBorder="0"
                  marginHeight={0}
                  marginWidth={0}
                  className={styles.htmlIframe}
                />
              </div>
            ) : (
              <div className="d-flex flex-column flex-center w-100">
                <img
                  className="d-flex flex-column align-self-center w-25"
                  alt="No items found"
                  src="/media/illustrations/sketchy-1/5.png"
                />
                <div className="fs-1">{t('apps.googleCalendar.noPreview')}</div>
                <div className="fs-6">
                  {t('apps.googleCalendar.calendarUrl')}
                </div>
              </div>
            )}
          </div>

          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {t('common.save')}
              </button>
              {!Object.keys(googleCalendar).length && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                >
                  {t('common.save_schedule')}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
