import React from 'react';
import { useDispatch } from 'react-redux';
import WithConfirm from './WithConfirm';
import WithClickBackdrop from '../../WithClickBackdrop';
import { saveUserData } from '../../../store/actions/userData';
import User from '../../../interfaces/user/user.interface';
import Customer from '../../../interfaces/customer.interface';

export default ({
  body,
  text,
  handleConfirm,
  className,
  user,
}: {
  body: string;
  text: string;
  handleConfirm: () => void;
  className?: string;
  user: User | Customer;
}) => {
  const dispatch = useDispatch();

  const confirmClicked = () => {
    dispatch(saveUserData(user));
    handleConfirm();
  };
  return (
    <WithConfirm
      handleConfirm={confirmClicked}
      body={body}
      confirmButtonText="Yes, login!"
      discardButtonText="No, cancel"
      buttonClassName={className}
    >
      <WithClickBackdrop>{text}</WithClickBackdrop>
    </WithConfirm>
  );
};
