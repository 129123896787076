/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Skeleton } from 'antd';
import { debounce } from 'lodash';
import { defaultDebounceValue } from '../../constants/api/debounceSettings';
import GlobalState from '../../store/reducers/globalState.interface';
import { readAllFromQueryParams } from '../../utils/queryParams';
import { getDevicesList } from '../../store/actions/devices';
import useLoading from '../../utils/hooks/useLoading';
import Devices from '../../components/Devices';
import OnboardingSteps from '../../containers/OnboardingSteps/OnboardingSteps';
import { UserRole } from '../../enums/userRole.enum';
import Device from '../../interfaces/devices/device.interface';
import TalkToSales from '../../components/Trial/TalkToSales';
import tvScreen from '../../assets/images/tv/TV_screen.png';

function calculateAspectRatioFit(
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number,
) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

const generatePreviewDeviceURL = (device: Device) => {
  if (!device) return undefined;
  return `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?deviceId=${device.id}`;
};

export default () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: GlobalState) => state.devices.loading);
  const devices = useSelector((state: GlobalState) => state.devices.devices);
  const total = useSelector((state: GlobalState) => state.devices.total);
  const [displayOnBoarding, setDisplayOnBoarding] = useState(false);
  const [loading, setLoading] = useLoading(isLoading);
  const [maxWidth, setMaxWidth] = useState(400);
  const [maxHeight, setMaxHeight] = useState(300);
  const divRef = React.useRef<HTMLDivElement>(null);
  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  const [previewDevice, setPreviewDevice] = useState<Device | undefined>(
    undefined,
  );

  const [previewDeviceURL, setPreviewDeviceURL] = useState<string | undefined>(
    undefined,
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleClose = () => {
    setIsModalVisible(false);
  };

  const user = useSelector((state: GlobalState) => {
    return state.userData.user;
  });

  const { roles, isTrial, onboarding } = user;

  const trialExpireDays = useSelector(
    (state: GlobalState) => state.userData.trialExpireDays,
  );

  const dispatchGetDevicesList = useCallback(
    debounce(
      (recentSearch: string) =>
        dispatch(getDevicesList(readAllFromQueryParams(recentSearch))),
      defaultDebounceValue,
    ),
    [],
  );

  useEffect(() => {
    // debugger;
    if (devices && devices.length) {
      setPreviewDevice(devices[0]);
    }
  }, [devices]);

  useEffect(() => {
    setLoading(true); // show preloader before dispatch
    dispatchGetDevicesList(search);
  }, [search]);

  useEffect(() => {
    if (
      roles.includes(UserRole.AccountOwner) &&
      isTrial &&
      onboarding &&
      !onboarding?.userSkipped
    )
      setDisplayOnBoarding(true);
    else setDisplayOnBoarding(false);
  }, [roles, isTrial, onboarding]);

  useEffect(() => {
    if (previewDevice) {
      const url = generatePreviewDeviceURL(previewDevice);
      console.log({ url });
      setPreviewDeviceURL(url);
    }
  }, [previewDevice]);

  useEffect(() => {
    const updateDimensions = () => {
      if (divRef.current) {
        const { width, height } = divRef.current.getBoundingClientRect();
        const { width: newWidth, height: newHeight } = calculateAspectRatioFit(
          1920,
          1080,
          width,
          height,
        );
        setMaxWidth(newWidth);
        setMaxHeight(newHeight);
        iframeRef.current?.contentWindow?.location?.reload();
      }
    };
    if (isTrial && divRef.current) {
      updateDimensions();
      divRef.current.addEventListener('resize', updateDimensions);
    }
    return () =>
      divRef.current?.removeEventListener('resize', updateDimensions);
  }, [isTrial, divRef.current]);

  const TrialExpiresIn = () => (
    <div className="card card-flush h-md-100">
      <div className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0">
        <div className="mb-10">
          <div className="fs-2x fw-bold text-gray-800 text-center mb-11">
            <span className="me-2">
              Your free trial expires in
              <br />
              <b style={{ color: '#ff5e19' }}>
                {trialExpireDays !== undefined && trialExpireDays >= 0 ? (
                  trialExpireDays
                ) : (
                  <Skeleton.Button shape="round" active />
                )}
              </b>{' '}
              Days{' '}
            </span>
          </div>

          <div className="text-center">
            <TalkToSales
              handleClose={handleClose}
              isModalVisible={isModalVisible}
            >
              <button
                type="button"
                className="btn  btn-sm btn-indigo-pink fw-bold"
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                Upgrade Now
              </button>
            </TalkToSales>
          </div>
          <div>&nbsp;</div>
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
              maxHeight,
              maxWidth,
              margin: 'auto',
            }}
            ref={divRef}
          >
            <div
              style={{
                backgroundImage: `url(${tvScreen})`,
                width: '100%',
                height: '100%',
                zIndex: 1,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                position: 'relative',
                // margin: '-2.4% -2.1% -2.2% -3%',
                padding: '2.4% 2.1% 2.2% 3%',
              }}
            >
              {/* <Skeleton.Input
                active
                size="large"
                block
                style={{ width: '100%', height: '100%', zIndex: 2 }}
              /> */}
              {previewDeviceURL && (
                <iframe
                  src={previewDeviceURL}
                  frameBorder="0"
                  marginHeight={0}
                  marginWidth={0}
                  style={{
                    height: `100%`,
                    width: `100%`,
                    position: 'relative',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                  title="Your device preview"
                  ref={iframeRef}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {/* {displayOnBoarding ? (
        <>
          <div className="row g-5 g-xl-10 mb-5 mb-xl-10 capital">
            <div className="col-xxl-8">
              <OnboardingSteps />
            </div>
            <div className="col-xxl-4">
              <div className="card card-flush h-md-100">
                <div className="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0">
                  <div className="mb-10">
                    <div className="fs-2x fw-bold text-gray-800 text-center mb-13">
                      <span className="me-2">
                        Your free trial expires in
                        <br />
                        <b style={{ color: '#ff5e19' }}>18</b> Days{' '}
                      </span>
                    </div>

                    <div className="text-center">
                      <a
                        href="#"
                        className="btn btn-sm btn-indigo-pink fw-bold"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_upgrade_plan"
                        // style={{ backgroundColor: '#ff702e' }}
                      >
                        Upgrade Now
                      </a>
                    </div>
                    <p>&nbsp;</p>

                    <div className="text-center">
                      <img
                        className="mx-auto h-150px h-lg-200px theme-light-show"
                        src={dozzyImg}
                        alt="Upgrade Now!"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null} */}

      {isTrial && (
        <div className="row g-5 g-xl-10 mb-5 mb-xl-10 capital">
          {displayOnBoarding ? (
            <>
              <div className="col-xxl-8">
                <OnboardingSteps />
              </div>
              <div className="col-xxl-4">
                <TrialExpiresIn />
              </div>
            </>
          ) : (
            <div className="col-xxl-12">
              <TrialExpiresIn />
            </div>
          )}
        </div>
      )}
      <Devices
        user={user}
        devices={devices}
        total={total}
        isLoading={loading}
        onUpdateLicense={() => dispatchGetDevicesList(search)}
        resetOnSearch
      />
    </>
  );
};
