enum AppType {
  SevereWeather = 'severe-weather',
  EmergencyAlert = 'emergency-alert',
  Ticker = 'ticker',
  YouTube = 'youtube',
  Html = 'html',
  Weather = 'weather',
  PowerBi = 'power-bi',
  MsTeams = 'ms-teams',
  Rss = 'rss',
  GoogleSheet = 'google-sheets',
  GoogleDocs = 'google-docs',
  GoogleCalendar = 'google-calendar',
  GoogleSlides = 'google-slides',
  Canva = 'canva',
  Twitter = 'twitter',
  Instagram = 'instagram',
  Facebook = 'facebook',
  QrCode = 'qr-code',
  CountUpTimer = 'count-up',
  CountDownTimer = 'count-down',
  DaysSafe = 'days-safe',
  LiveStream = 'live-stream',
}

export default AppType;
