import LoadingAction from '../loadingAction.interface';
import ContentsActions from './contentsActions.enum';
import GetPlaylistContentsAction from './interfaces/getPlaylistContentsAction.interface';
import GetPlaylistContentsSuccessAction from './interfaces/getPlaylistContentsSuccessAction.interface';

export const setContentsLoading = (
  payload: LoadingAction['payload'],
): LoadingAction => ({
  type: ContentsActions.SET_CONTENTS_LOADING,
  payload,
});

export const getPlaylistContents = (
  payload: GetPlaylistContentsAction['payload'],
): GetPlaylistContentsAction => ({
  type: ContentsActions.GET_PLAYLIST_CONTENT,
  payload,
});

export const getPlaylistContentsSuccess = (
  payload: GetPlaylistContentsSuccessAction['payload'],
): GetPlaylistContentsSuccessAction => ({
  type: ContentsActions.GET_PLAYLIST_CONTENT_SUCCESS,
  payload,
});
