enum DeviceCells {
  Name = 'name',
  Status = 'status',
  Location = 'location',
  Company = 'company',
  CurrentPlaylist = 'current_playlist',
  DevicesGroups = 'devices_groups',
  AspectRatio = 'aspect_ratio',
  Type = 'type',
  TimeZone = 'time_zone',
  LastOnline = 'last_online',
  PlanName = 'plane_name',
  Playlist = 'playlist',
  Billing = 'billing',
  Owner = 'owner',
  License = 'license',
}

export default DeviceCells;
