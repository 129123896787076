import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import EditUser from '../../../../../containers/Users/EditUser';
import RemoveUser from '../../../../../containers/Users/RemoveUser';
import CreateUserRequestData from '../../../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import RestoreUser from '../../../../../containers/Users/RestoreUser';
import SuspendUser from '../../../../../containers/Users/SuspendUser';
import { UserStatus } from '../../../../../enums/userStatus.enum';
import User from '../../../../../interfaces/user/user.interface';
import capitalizationStyles from '../../../../../styles/capitalize.module.scss';
// import pointerEventsStyles from '../../../../../styles/pointerEvents.module.scss';
import LoginAsConfirm from '../../../../AdminPanel/LoginAsConfirm';
import ButtonDropdown from '../../../../Dropdown/ButtonDropdown';
import WithClickBackdrop from '../../../../WithClickBackdrop';
import routesConfig from '../../../../../routing/config';
import { loginAsUser } from '../../../../../utils/auth0';
import Table from '../../../../Table';
import {
  rolesTranslation,
  statusesTranslation,
} from '../../../../../constants/translation/users';

const { Row } = Table.Body;
const { Cell } = Row;

interface TableRowProps {
  user: User;
  currentUser?: User;
  onEditUser?: (userId: string, data: Partial<CreateUserRequestData>) => void;
  onRestoreUser?: (userId: string) => void;
  onSuspendUser?: (userId: string) => void;
  onDeleteUser?: (userId: string) => void;
}

export default ({
  user,
  currentUser,
  onEditUser,
  onRestoreUser,
  onSuspendUser,
  onDeleteUser,
}: TableRowProps) => {
  const { t } = useTranslation();
  const userRole = user.roles?.[0];

  return (
    <Row>
      <Cell isNoWrap className="d-flex align-items-center">
        <div className="symbol symbol-45px overflow-hidden me-3">
          <div className="symbol-label">
            <img src={user.profilePicture} alt="user" className="w-100" />
          </div>
        </div>
        <div className="d-flex flex-column">
          <Link
            to={routesConfig.customer.route
              .replace(':id', user.id)
              .replace(':page', 'devices')}
            className={clsx(
              'text-gray-800 text-hover-primary mb-1',
              // pointerEventsStyles.noPointerEvents,
            )}
          >
            {user.firstName} {user.lastName}
          </Link>
          <span className="text-muted">{user.email}</span>
        </div>
      </Cell>

      <Cell isEmpty={!user?.assignedCompanies?.[0]?.name}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center hoverable">
          {user?.assignedCompanies?.[0]?.name}
        </span>
      </Cell>

      <Cell isEmpty={!user.roles?.length}>
        <span
          className={clsx(
            'text-dark fw-bold d-block mb-1 fs-6 text-center',
            capitalizationStyles.lowerCaseAndCapitalize,
          )}
        >
          {userRole && t(rolesTranslation[userRole])}
        </span>
      </Cell>

      <Cell isNoWrap className="text-center">
        <span
          className={clsx(
            'badge fs-7 py-3 px-7 fw-bold',
            user.status === UserStatus.Active
              ? 'badge-light-success'
              : 'badge-light',
          )}
        >
          {t(statusesTranslation[user.status] ?? user.status)}
        </span>
      </Cell>
      <Cell>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center hoverable">
          {user.devicesCount}
        </span>
      </Cell>

      <Cell isEmpty={!user.groups?.length} className="text-center">
        {user.groups.map((group, index) => (
          <span
            key={group}
            className={clsx(
              'badge fs-7 py-3 px-7 fw-bold badge-light-primary mb-2',
              index && 'ms-2',
            )}
          >
            {group}
          </span>
        ))}
      </Cell>

      <Cell isEmpty={!user.assignedLocations?.[0]?.name}>
        <span className="text-dark fw-bold d-block mb-1 fs-6 text-center">
          {user.assignedLocations?.[0]?.name}
        </span>
      </Cell>

      <Cell isNoWrap isTextEnd>
        <div className="d-flex justify-content-end">
          <ButtonDropdown
            text={t('common.actions')}
            items={[
              {
                key: 'edit',
                content: (
                  <EditUser
                    user={user}
                    onEditUser={onEditUser}
                    currentUser={currentUser}
                  >
                    <WithClickBackdrop>{t('common.edit')}</WithClickBackdrop>
                  </EditUser>
                ),
              },
              {
                key: 'loginAs',
                content: (
                  <LoginAsConfirm
                    body={`Are you sure you want to login as ${user.firstName} ${user.lastName}?`}
                    text="Login As"
                    user={user}
                    handleConfirm={() => loginAsUser(user)}
                  />
                ),
                hidden: user.status !== UserStatus.Active,
              },
              {
                key: 'restore',
                content: (
                  <RestoreUser user={user} onRestoreUser={onRestoreUser}>
                    <WithClickBackdrop>{t('common.restore')}</WithClickBackdrop>
                  </RestoreUser>
                ),
                hidden: user.status === UserStatus.Active,
              },
              {
                key: 'suspend',
                content: (
                  <SuspendUser user={user} onSuspendUser={onSuspendUser}>
                    <WithClickBackdrop>{t('common.suspend')}</WithClickBackdrop>
                  </SuspendUser>
                ),
                hidden: user.status === UserStatus.Banned,
              },
              {
                key: 'delete',
                content: (
                  <RemoveUser admin user={user} unAssignUser={onDeleteUser}>
                    <WithClickBackdrop>{t('common.delete')}</WithClickBackdrop>
                  </RemoveUser>
                ),
              },
            ]}
          />
        </div>
      </Cell>
    </Row>
  );
};
