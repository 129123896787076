import ContentsActions from '../../actions/contents/contentsActions.enum';
import LoadingAction from '../../actions/loadingAction.interface';
import ReduxAction from '../../actions/reduxAction.interface';
import ContentsState from './contentsState.interface';
import IContent from '../../../interfaces/content.interface';

const initialState: ContentsState = {
  loading: false,
  contents: [] as IContent[],
  total: 0,
};

export default function contentsReducer(
  state = initialState,
  action: ReduxAction<ContentsActions>,
): ContentsState {
  switch (action.type) {
    case ContentsActions.SET_CONTENTS_LOADING:
      return {
        ...state,
        ...(action as LoadingAction<ContentsActions.SET_CONTENTS_LOADING>)
          .payload,
      };
    case ContentsActions.GET_PLAYLIST_CONTENT_SUCCESS: {
      const { payload } = action as any;

      return {
        ...state,
        total: payload.meta.totalItems,
        contents: payload.items.map((item: any) => ({
          ...item.content,
          itemId: item.id,
          zoneId: item.zoneId,
          order: item.order,
        })),
      };
    }
    default:
      return state;
  }
}
