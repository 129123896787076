import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './tabs.module.scss';

const Tabs = ({
  tabs,
  initialTab,
  changeActiveTab,
}: {
  initialTab: number;
  tabs: { name: string }[];
  changeActiveTab: any;
}) => {
  const [activeTab, setActiveTab] = useState(initialTab);
  return (
    <div className={styles.wrapper}>
      {tabs.map((tab, i) => (
        <div
          role="presentation"
          className={clsx(styles.label, activeTab === i ? styles.active : '')}
          onClick={() => {
            setActiveTab(i);
            changeActiveTab(i);
          }}
        >
          {tab.name}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
