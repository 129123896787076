import {
  GetFilesRequest,
  ScheduleFileRequest,
  DeleteFilesRequest,
  RenameFileRequest,
  CreateFileRequest,
  MoveFileRequest,
  PaginatedFiles,
  FileSchema,
  ErrorResponse,
} from '../../types/fileManager';

export interface FileManagerReduxAction<TYPE = ACTIONS, PAYLOAD = any> {
  type: TYPE;
  payload?: PAYLOAD;
}

export enum ACTIONS {
  GET_FILES = 'GET_FILES',
  GET_FILES_LOADING = 'GET_FILES_LOADING',
  GET_FILES_SUCCESS = 'GET_FILES_SUCCESS',
  GET_FILES_ERROR = 'GET_FILES_ERROR',
  GET_FILES_RESET = 'GET_FILES_RESET',

  GET_ONLY_DIRECTORIES = 'GET_ONLY_DIRECTORIES',
  GET_ONLY_DIRECTORIES_LOADING = 'GET_ONLY_DIRECTORIES_LOADING',
  GET_ONLY_DIRECTORIES_SUCCESS = 'GET_ONLY_DIRECTORIES_SUCCESS',
  GET_ONLY_DIRECTORIES_ERROR = 'GET_ONLY_DIRECTORIES_ERROR',
  GET_ONLY_DIRECTORIES_RESET = 'GET_ONLY_DIRECTORIES_RESET',

  DELETE_FILES = 'DELETE_FILES',
  DELETE_FILES_LOADING = 'DELETE_FILES_LOADING',
  DELETE_FILES_SUCCESS = 'DELETE_FILES_SUCCESS',
  DELETE_FILES_ERROR = 'DELETE_FILES_ERROR',
  DELETE_FILES_RESET = 'DELETE_FILES_RESET',

  RENAME_FILE = 'RENAME_FILE',
  RENAME_FILE_LOADING = 'RENAME_FILE_LOADING',
  RENAME_FILE_SUCCESS = 'RENAME_FILE_SUCCESS',
  RENAME_FILE_ERROR = 'RENAME_FILE_ERROR',
  RENAME_FILE_RESET = 'RENAME_FILE_RESET',

  MOVE_FILE = 'MOVE_FILE',
  MOVE_FILE_LOADING = 'MOVE_FILE_LOADING',
  MOVE_FILE_SUCCESS = 'MOVE_FILE_SUCCESS',
  MOVE_FILE_ERROR = 'MOVE_FILE_ERROR',
  MOVE_FILE_RESET = 'MOVE_FILE_RESET',

  CREATE_FILE = 'CREATE_FILE',
  CREATE_FILE_LOADING = 'CREATE_FILE_LOADING',
  CREATE_FILE_SUCCESS = 'CREATE_FILE_SUCCESS',
  CREATE_FILE_RESET = 'CREATE_FILE_RESET',

  SCHEDULE_FILE = 'SCHEDULE_FILE',
  SCHEDULE_FILE_LOADING = 'SCHEDULE_FILE_LOADING',
  SCHEDULE_FILE_SUCCESS = 'SCHEDULE_FILE_SUCCESS',
  SCHEDULE_FILE_ERROR = 'SCHEDULE_FILE_ERROR',
  SCHEDULE_FILE_RESET = 'SCHEDULE_FILE_RESET',
}

export interface GET_FILES_ACTIONS
  extends FileManagerReduxAction<ACTIONS.GET_FILES, GetFilesRequest> {}

export const getFilesActionCreator = (
  payload: GET_FILES_ACTIONS['payload'],
): GET_FILES_ACTIONS => ({
  type: ACTIONS.GET_FILES,
  payload,
});

export interface SCHEDULE_FILE_ACTIONS
  extends FileManagerReduxAction<ACTIONS.SCHEDULE_FILE, ScheduleFileRequest> {}

export const scheduleFileActionCreator = (
  payload: SCHEDULE_FILE_ACTIONS['payload'],
): SCHEDULE_FILE_ACTIONS => ({
  type: ACTIONS.SCHEDULE_FILE,
  payload,
});

export interface GET_ONLY_DIRECTORIES_ACTIONS
  extends FileManagerReduxAction<
    ACTIONS.GET_ONLY_DIRECTORIES,
    GetFilesRequest
  > {}

export const getOnlyDirectoriesActionCreator = (
  payload: GET_ONLY_DIRECTORIES_ACTIONS['payload'],
): GET_ONLY_DIRECTORIES_ACTIONS => ({
  type: ACTIONS.GET_ONLY_DIRECTORIES,
  payload,
});

export interface DELETE_FILES_ACTIONS
  extends FileManagerReduxAction<ACTIONS.DELETE_FILES, DeleteFilesRequest> {}

export const deleteFilesActionCreator = (
  payload: DELETE_FILES_ACTIONS['payload'],
): DELETE_FILES_ACTIONS => ({
  type: ACTIONS.DELETE_FILES,
  payload,
});

export interface RENAME_FILE_ACTIONS
  extends FileManagerReduxAction<ACTIONS.RENAME_FILE, RenameFileRequest> {}

export const renameFileActionCreator = (
  payload: RENAME_FILE_ACTIONS['payload'],
): RENAME_FILE_ACTIONS => ({
  type: ACTIONS.RENAME_FILE,
  payload,
});

export interface CREATE_FILE_ACTIONS
  extends FileManagerReduxAction<ACTIONS.CREATE_FILE, CreateFileRequest> {}

export const createFileActionCreator = (
  payload: CREATE_FILE_ACTIONS['payload'],
): CREATE_FILE_ACTIONS => ({
  type: ACTIONS.CREATE_FILE,
  payload,
});

export interface MOVE_FILE_ACTIONS
  extends FileManagerReduxAction<ACTIONS.MOVE_FILE, MoveFileRequest> {}

export const moveFileActionCreator = (
  payload: MOVE_FILE_ACTIONS['payload'],
): MOVE_FILE_ACTIONS => ({
  type: ACTIONS.MOVE_FILE,
  payload,
});

// Success action and success action creator
export interface GET_FILES_SUCCESS_ACTION
  extends FileManagerReduxAction<
    ACTIONS.GET_FILES_SUCCESS,
    { data: PaginatedFiles; loading: boolean; isGetFilesSuccess: boolean }
  > {}

export const getFilesSuccessActionCreator = (
  payload: GET_FILES_SUCCESS_ACTION['payload'],
): GET_FILES_SUCCESS_ACTION => ({
  type: ACTIONS.GET_FILES_SUCCESS,
  payload,
});

export interface GET_ONLY_DIRECTORIES_SUCCESS_ACTION
  extends FileManagerReduxAction<
    ACTIONS.GET_ONLY_DIRECTORIES_SUCCESS,
    {
      data: PaginatedFiles;
      loading: boolean;
      isGetOnlyDirectoriesSuccess: boolean;
    }
  > {}

export const getOnlyDirectoriesSuccessActionCreator = (
  payload: GET_ONLY_DIRECTORIES_SUCCESS_ACTION['payload'],
): GET_ONLY_DIRECTORIES_SUCCESS_ACTION => ({
  type: ACTIONS.GET_ONLY_DIRECTORIES_SUCCESS,
  payload,
});

export interface RENAME_FILE_SUCCESS_ACTION
  extends FileManagerReduxAction<
    ACTIONS.RENAME_FILE_SUCCESS,
    {
      data: Partial<FileSchema>;
      loading: boolean;
      isRenameFileSuccess: boolean;
    }
  > {}

export const renameFileSuccessActionCreator = (
  payload: RENAME_FILE_SUCCESS_ACTION['payload'],
): RENAME_FILE_SUCCESS_ACTION => ({
  type: ACTIONS.RENAME_FILE_SUCCESS,
  payload,
});

export interface CREATE_FILE_SUCCESS_ACTION
  extends FileManagerReduxAction<
    ACTIONS.CREATE_FILE_SUCCESS,
    {
      data: Partial<FileSchema>;
      loading: boolean;
      isCreateFileSuccess: boolean;
    }
  > {}

export const createFileSuccessActionCreator = (
  payload: CREATE_FILE_SUCCESS_ACTION['payload'],
): CREATE_FILE_SUCCESS_ACTION => ({
  type: ACTIONS.CREATE_FILE_SUCCESS,
  payload,
});

export interface DELETE_FILES_SUCCESS_ACTION
  extends FileManagerReduxAction<
    ACTIONS.DELETE_FILES_SUCCESS,
    { data: void; loading: boolean; isDeleteFilesSuccess: boolean }
  > {}

export const deleteFilesSuccessActionCreator = (
  payload: DELETE_FILES_SUCCESS_ACTION['payload'],
): DELETE_FILES_SUCCESS_ACTION => ({
  type: ACTIONS.DELETE_FILES_SUCCESS,
  payload,
});

export interface MOVE_FILE_SUCCESS_ACTION
  extends FileManagerReduxAction<
    ACTIONS.MOVE_FILE_SUCCESS,
    { data: void; loading: boolean; isMoveFileSuccess: boolean }
  > {}

export const moveFileSuccessActionCreator = (
  payload: MOVE_FILE_SUCCESS_ACTION['payload'],
): MOVE_FILE_SUCCESS_ACTION => ({
  type: ACTIONS.MOVE_FILE_SUCCESS,
  payload,
});

export interface SCHEDULE_FILE_SUCCESS_ACTION
  extends FileManagerReduxAction<
    ACTIONS.SCHEDULE_FILE_SUCCESS,
    { data: void; loading: boolean; isScheduleFileSuccess: boolean }
  > {}

export const scheduleFileSuccessActionCreator = (
  payload: SCHEDULE_FILE_SUCCESS_ACTION['payload'],
): SCHEDULE_FILE_SUCCESS_ACTION => ({
  type: ACTIONS.SCHEDULE_FILE_SUCCESS,
  payload,
});

// Loading action and loading action creator
export interface GET_FILES_LOADING_ACTION
  extends FileManagerReduxAction<
    ACTIONS.GET_FILES_LOADING,
    { loading: boolean }
  > {}

export const getFilesLoadingActionCreator = (
  payload: GET_FILES_LOADING_ACTION['payload'],
): GET_FILES_LOADING_ACTION => ({
  type: ACTIONS.GET_FILES_LOADING,
  payload,
});

export interface GET_ONLY_DIRECTORIES_LOADING_ACTION
  extends FileManagerReduxAction<
    ACTIONS.GET_ONLY_DIRECTORIES_LOADING,
    { loading: boolean }
  > {}

export const getOnlyDirectoriesLoadingActionCreator = (
  payload: GET_ONLY_DIRECTORIES_LOADING_ACTION['payload'],
): GET_ONLY_DIRECTORIES_LOADING_ACTION => ({
  type: ACTIONS.GET_ONLY_DIRECTORIES_LOADING,
  payload,
});

export interface DELETE_FILES_LOADING_ACTION
  extends FileManagerReduxAction<
    ACTIONS.DELETE_FILES_LOADING,
    { loading: boolean }
  > {}

export const deleteFilesLoadingActionCreator = (
  payload: DELETE_FILES_LOADING_ACTION['payload'],
): DELETE_FILES_LOADING_ACTION => ({
  type: ACTIONS.DELETE_FILES_LOADING,
  payload,
});

export interface RENAME_FILE_LOADING_ACTION
  extends FileManagerReduxAction<
    ACTIONS.RENAME_FILE_LOADING,
    { loading: boolean }
  > {}

export const renameFileLoadingActionCreator = (
  payload: RENAME_FILE_LOADING_ACTION['payload'],
): RENAME_FILE_LOADING_ACTION => ({
  type: ACTIONS.RENAME_FILE_LOADING,
  payload,
});

export interface CREATE_FILE_LOADING_ACTION
  extends FileManagerReduxAction<
    ACTIONS.CREATE_FILE_LOADING,
    { loading: boolean }
  > {}

export const createFileLoadingActionCreator = (
  payload: CREATE_FILE_LOADING_ACTION['payload'],
): CREATE_FILE_LOADING_ACTION => ({
  type: ACTIONS.CREATE_FILE_LOADING,
  payload,
});

export interface MOVE_FILE_LOADING_ACTION
  extends FileManagerReduxAction<
    ACTIONS.MOVE_FILE_LOADING,
    { loading: boolean }
  > {}

export const moveFileLoadingActionCreator = (
  payload: MOVE_FILE_LOADING_ACTION['payload'],
): MOVE_FILE_LOADING_ACTION => ({
  type: ACTIONS.MOVE_FILE_LOADING,
  payload,
});

export interface SCHEDULE_FILE_LOADING_ACTION
  extends FileManagerReduxAction<
    ACTIONS.SCHEDULE_FILE_LOADING,
    { loading: boolean }
  > {}

export const scheduleFileLoadingActionCreator = (
  payload: SCHEDULE_FILE_LOADING_ACTION['payload'],
): SCHEDULE_FILE_LOADING_ACTION => ({
  type: ACTIONS.SCHEDULE_FILE_LOADING,
  payload,
});

// Error action and error action creator
export interface GET_FILES_ERROR_ACTION
  extends FileManagerReduxAction<
    ACTIONS.GET_FILES_ERROR,
    { error: ErrorResponse; loading: boolean; isGetFilesSuccess: boolean }
  > {}

export const getFilesErrorActionCreator = (
  payload: GET_FILES_ERROR_ACTION['payload'],
): GET_FILES_ERROR_ACTION => ({
  type: ACTIONS.GET_FILES_ERROR,
  payload,
});

export interface GET_ONLY_DIRECTORIES_ERROR_ACTION
  extends FileManagerReduxAction<
    ACTIONS.GET_ONLY_DIRECTORIES_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isGetOnlyDirectoriesSuccess: boolean;
    }
  > {}

export const getOnlyDirectoriesErrorActionCreator = (
  payload: GET_ONLY_DIRECTORIES_ERROR_ACTION['payload'],
): GET_ONLY_DIRECTORIES_ERROR_ACTION => ({
  type: ACTIONS.GET_ONLY_DIRECTORIES_ERROR,
  payload,
});

export interface DELETE_FILES_ERROR_ACTION
  extends FileManagerReduxAction<
    ACTIONS.DELETE_FILES_ERROR,
    { error: ErrorResponse; loading: boolean; isDeleteFilesSuccess: boolean }
  > {}

export const deleteFilesErrorActionCreator = (
  payload: DELETE_FILES_ERROR_ACTION['payload'],
): DELETE_FILES_ERROR_ACTION => ({
  type: ACTIONS.DELETE_FILES_ERROR,
  payload,
});

export interface RENAME_FILE_ERROR_ACTION
  extends FileManagerReduxAction<
    ACTIONS.RENAME_FILE_ERROR,
    { error: ErrorResponse; loading: boolean; isRenameFileSuccess: boolean }
  > {}

export const renameFileErrorActionCreator = (
  payload: RENAME_FILE_ERROR_ACTION['payload'],
): RENAME_FILE_ERROR_ACTION => ({
  type: ACTIONS.RENAME_FILE_ERROR,
  payload,
});

export interface MOVE_FILE_ERROR_ACTION
  extends FileManagerReduxAction<
    ACTIONS.MOVE_FILE_ERROR,
    { error: ErrorResponse; loading: boolean; isMoveFileSuccess: boolean }
  > {}

export const moveFileErrorActionCreator = (
  payload: MOVE_FILE_ERROR_ACTION['payload'],
): MOVE_FILE_ERROR_ACTION => ({
  type: ACTIONS.MOVE_FILE_ERROR,
  payload,
});

export interface SCHEDULE_FILE_ERROR_ACTION
  extends FileManagerReduxAction<
    ACTIONS.SCHEDULE_FILE_ERROR,
    { error: ErrorResponse; loading: boolean; isScheduleFileSuccess: boolean }
  > {}

export const scheduleFileErrorActionCreator = (
  payload: SCHEDULE_FILE_ERROR_ACTION['payload'],
): SCHEDULE_FILE_ERROR_ACTION => ({
  type: ACTIONS.SCHEDULE_FILE_ERROR,
  payload,
});

// Reset action and reset action creator
export interface GET_FILES_RESET_ACTION
  extends FileManagerReduxAction<
    ACTIONS.GET_FILES_RESET,
    {
      data: PaginatedFiles;
      loading: boolean;
      isGetFilesSuccess: boolean;
      error: null;
    }
  > {}

export const getFilesResetActionCreator = (
  payload: GET_FILES_RESET_ACTION['payload'],
): GET_FILES_RESET_ACTION => ({
  type: ACTIONS.GET_FILES_RESET,
  payload,
});

export interface GET_ONLY_DIRECTORIES_RESET_ACTION
  extends FileManagerReduxAction<
    ACTIONS.GET_ONLY_DIRECTORIES_RESET,
    {
      data: PaginatedFiles;
      error: null;
      loading: boolean;
      isGetOnlyDirectoriesSuccess: boolean;
    }
  > {}

export const getOnlyDirectoriesResetActionCreator = (
  payload: GET_ONLY_DIRECTORIES_RESET_ACTION['payload'],
): GET_ONLY_DIRECTORIES_RESET_ACTION => ({
  type: ACTIONS.GET_ONLY_DIRECTORIES_RESET,
  payload,
});

export interface DELETE_FILES_RESET_ACTION
  extends FileManagerReduxAction<
    ACTIONS.DELETE_FILES_RESET,
    { error: null; loading: boolean; isDeleteFilesSuccess: boolean }
  > {}

export const deleteFilesResetActionCreator = (
  payload: DELETE_FILES_RESET_ACTION['payload'],
): DELETE_FILES_RESET_ACTION => ({
  type: ACTIONS.DELETE_FILES_RESET,
  payload,
});

export interface RENAME_FILE_RESET_ACTION
  extends FileManagerReduxAction<
    ACTIONS.RENAME_FILE_RESET,
    { error: null; loading: boolean; isRenameFileSuccess: boolean }
  > {}

export const renameFileResetActionCreator = (
  payload: RENAME_FILE_RESET_ACTION['payload'],
): RENAME_FILE_RESET_ACTION => ({
  type: ACTIONS.RENAME_FILE_RESET,
  payload,
});

export interface CREATE_FILE_RESET_ACTION
  extends FileManagerReduxAction<
    ACTIONS.CREATE_FILE_RESET,
    { error: null; loading: boolean; isCreateFileSuccess: boolean }
  > {}

export const createFileResetActionCreator = (
  payload: CREATE_FILE_RESET_ACTION['payload'],
): CREATE_FILE_RESET_ACTION => ({
  type: ACTIONS.CREATE_FILE_RESET,
  payload,
});

export interface MOVE_FILE_RESET_ACTION
  extends FileManagerReduxAction<
    ACTIONS.MOVE_FILE_RESET,
    { error: null; loading: boolean; isMoveFileSuccess: boolean }
  > {}

export const moveFileResetActionCreator = (
  payload: MOVE_FILE_RESET_ACTION['payload'],
): MOVE_FILE_RESET_ACTION => ({
  type: ACTIONS.MOVE_FILE_RESET,
  payload,
});

export interface SCHEDULE_FILE_RESET_ACTION
  extends FileManagerReduxAction<
    ACTIONS.SCHEDULE_FILE_RESET,
    { error: null; loading: boolean; isScheduleFileSuccess: boolean }
  > {}

export const scheduleFileResetActionCreator = (
  payload: SCHEDULE_FILE_RESET_ACTION['payload'],
): SCHEDULE_FILE_RESET_ACTION => ({
  type: ACTIONS.SCHEDULE_FILE_RESET,
  payload,
});
