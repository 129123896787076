import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap-v5';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import clsx from 'clsx';

import FormErrorMessage from '../../../FormErrorMessage';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import { CreateGoogleSheetFeedRequest } from '../../../../store/types/apps/googleSheetFeed';
import Orientation from '../../../../enums/orientation.enum';
import styles from './GoogleSheet.module.scss';
import { disableRightClickIframe } from '../../../../utils/common';

type CreateGoogleSheetRequestFormMutated = Omit<
  CreateGoogleSheetFeedRequest,
  'config' | 'placement'
>;

interface CreateGoogleSheetFeedRequestFormPayload
  extends CreateGoogleSheetRequestFormMutated {
  googleSheetFeedUrl: string;
}

export interface CreateFeedModalProps {
  onSubmit: (data: CreateGoogleSheetFeedRequest) => void;
  googleSheet?: App;
  onClose?: () => void;
  isLoading: boolean;
}

export default ({
  onSubmit,
  onClose,
  isLoading,
  googleSheet = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();
  const [previewDocumentIsFetching, setPreviewDocumentIsFetching] =
    React.useState<boolean>(false);

  const initialValues: CreateGoogleSheetFeedRequestFormPayload = {
    name: googleSheet?.name ?? '',
    googleSheetFeedUrl:
      googleSheet?.dependency?.config?.googleSheetFeedUrl ?? '',
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),
    googleSheetFeedUrl: Yup.string()
      .matches(
        /(docs.google.com\/spreadsheets)/,
        t('apps.googleSheets.invalidLink'),
      )
      .url(t('common.validation.validUrl'))
      .defined(),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateGoogleSheetFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const modalHeight = 400;

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateGoogleSheetFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const handleFeedNameChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setFeedName(value),
    [setFeedName],
  );

  const [googleSheetFeedUrl, setgoogleSheetFeedUrl] = useStateWithHookForm<
    CreateGoogleSheetFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'googleSheetFeedUrl' },
    initialValues.googleSheetFeedUrl,
  );

  const [isScheduled, setIsScheduled] = useState<boolean>(false);

  const handlePreviewDocumentIsFetching = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (previewDocumentIsFetching: boolean) =>
      setPreviewDocumentIsFetching(previewDocumentIsFetching),
    [],
  );

  const handlegoogleSheetFeedUrlChange = React.useCallback(
    ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      handlePreviewDocumentIsFetching(value !== '');
      try {
        const urlObj = new URL(value);
        const urlParmsObj = new URLSearchParams(urlObj.searchParams);
        urlParmsObj.set('widget', 'false');
        urlParmsObj.set('chrome', 'false');
        const pathname = urlObj.pathname.endsWith('/pub')
          ? urlObj.pathname.replace('/pub', '/embed')
          : urlObj.pathname;
        const newUrl = `${urlObj.origin}${pathname}${
          urlObj.hash
        }?${urlParmsObj.toString()}`;
        setgoogleSheetFeedUrl(newUrl);
      } catch (e) {
        setgoogleSheetFeedUrl(value);
      }
    },
    [handlePreviewDocumentIsFetching, setgoogleSheetFeedUrl],
  );

  const handleOnIFrameLoaded = React.useCallback(
    () => handlePreviewDocumentIsFetching(false),
    [handlePreviewDocumentIsFetching],
  );

  const handleOnSubmit = async (
    data: CreateGoogleSheetFeedRequestFormPayload,
  ) => {
    const { name, googleSheetFeedUrl: googleSheetFeedUrlData } = data;
    const createGoogleSheetFeedRequest: CreateGoogleSheetFeedRequest = {
      name,
      isScheduled,
      config: {
        googleSheetFeedUrl: googleSheetFeedUrlData,
      },
      zoneId: Orientation.Landscape,
    };

    onSubmit(createGoogleSheetFeedRequest);
  };

  React.useEffect(() => {
    disableRightClickIframe(feedName);
  }, []);

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id="form"
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex flex-row p-4 w-100">
            <div className="d-flex flex-column mx-2 w-100">
              <label
                htmlFor="name"
                className="required text-dark fw-bolder my-1"
              >
                {t('apps.googleSheets.googleSheetTitle')}
              </label>
              <input
                name="name"
                id="name"
                value={feedName}
                onChange={handleFeedNameChange}
                className="form-control form-control-solid"
                placeholder="Type here"
                type="text"
              />
              <FormErrorMessage
                name="name"
                errors={errors}
                className="my-1 px-2"
              />
            </div>

            <div className="d-flex flex-column mx-2 w-100">
              <label
                htmlFor="googleSheetFeedUrl"
                className="required text-dark fw-bolder my-1"
              >
                {t('apps.googleSheets.sheetUrl')}
              </label>
              <div className="input-group">
                <input
                  name="googleSheetFeedUrl"
                  id="googleSheetFeedUrl"
                  value={googleSheetFeedUrl}
                  onChange={handlegoogleSheetFeedUrlChange}
                  className="form-control form-control-solid"
                />
                <span
                  className="input-group-text border-0"
                  title={t('apps.googleCalendar.howItWorks')}
                >
                  <a
                    href="https://support.google.com/a/users/answer/9308870?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fas fa-info-circle fs-4 text-dark" />
                  </a>
                </span>
                <FormErrorMessage
                  name="googleSheetFeedUrl"
                  errors={errors}
                  className="my-1 px-2"
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column   flex-center  p-4 w-100">
            {!errors.googleSheetFeedUrl && googleSheetFeedUrl !== '' ? (
              <div className="d-flex flex-column flex-center w-100 h-100">
                {previewDocumentIsFetching ? (
                  <Spinner role="status" animation="border" />
                ) : null}
                <iframe
                  title={feedName}
                  src={googleSheetFeedUrl}
                  width="100%"
                  height={`${modalHeight}px`}
                  onLoad={handleOnIFrameLoaded}
                  frameBorder="0"
                  marginHeight={0}
                  marginWidth={0}
                  className={styles.htmlIframe}
                />
              </div>
            ) : (
              <div className="d-flex flex-column flex-center w-100">
                <img
                  className="d-flex flex-column align-self-center w-25"
                  alt="No items found"
                  src="/media/illustrations/sketchy-1/5.png"
                />
                <div className="fs-1">{t('apps.googleSheets.noPreview')}</div>
                <div className="fs-6">{t('apps.googleSheets.sheetUrl')}</div>
              </div>
            )}
          </div>

          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {t('common.save')}
              </button>
              {!Object.keys(googleSheet).length && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                >
                  {t('common.save_schedule')}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
