import React from 'react';
import clsx from 'clsx';

export interface Mode<T = string> {
  value: T;
  renderView: (isActive: boolean) => React.ReactChild;
}

export default <T extends string = string>({
  modes,
  modeValue,
  onSelect,
  className,
  asInput,
  inputId,
  inputName,
}: {
  modes: Mode<T>[];
  modeValue: T;
  onSelect: (value: T) => void;
  className?: string;
  asInput?: boolean;
  inputId?: string;
  inputName?: string;
}) => (
  <div className={clsx('d-flex', className)}>
    {asInput && (
      <input type="hidden" name={inputName} value={modeValue} id={inputId} />
    )}
    {modes.map(({ value, renderView }) => (
      <div
        key={value}
        role="button"
        tabIndex={-1}
        onClick={() => onSelect(value)}
        onKeyPress={() => onSelect(value)}
        className="me-2"
      >
        {renderView(modeValue === value)}
      </div>
    ))}
  </div>
);
