import { TFunction } from 'i18next';
import validation from '../../utils/validation';

export const severeWeatherEditValidation = (t: TFunction) => ({
  name: validation
    .string()
    .required(t('contents.validation.please_enter_name')),
  countryId: validation
    .string()
    .min(1)
    .required(t('apps.severeWeather.validation.country')),
  devices: validation.array().when('devicesGroups', {
    is: (devicesGroups: string[]) =>
      !devicesGroups || devicesGroups.length === 0,
    then: validation.array().min(1, t('playlists.validation.devices')),
  }),
  devicesGroups: validation.array().when('devices', {
    is: (devices: string[]) => !devices || devices.length === 0,
    then: validation.array().min(1, t('devices.validation.device_groups')),
  }),
});

export const severeWeatherCreationValidation = (t: TFunction) => ({
  devices: validation.array().when('deviceGroupIds', {
    is: (deviceGroupIds: string[]) =>
      !deviceGroupIds || deviceGroupIds.length === 0,
    then: validation.array().min(1, t('playlists.validation.devices')),
  }),
  deviceGroupIds: validation.array().when('devices', {
    is: (devices: string[]) => !devices || devices.length === 0,
    then: validation.array().min(1, t('devices.validation.device_groups')),
  }),
});
