/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/html-has-lang */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap-v5';
import { useSelector } from 'react-redux';
// import Spinner from '../../Spinner';

import background from '../../../assets/images/TalkToSalesBackground.png';

import dotIcon from '../../../assets/images/DotIcon.png';
import GlobalState from '../../../store/reducers/globalState.interface';
import Modal from '../ExpiringModal/modal';

export default ({
  isModalVisible,
  handleClose,
  children,
}: {
  isModalVisible: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
}) => {
  const user = useSelector((state: GlobalState) => state.userData.user);
  // user company name
  const [userCompany, setUserCompany] = useState('');

  React.useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';

    script.async = true;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);

    try {
      if (
        Array.isArray(user?.assignedCompanies) &&
        user?.assignedCompanies.length > 0
      ) {
        setUserCompany(user?.assignedCompanies[0].name);
      }
    } catch (error) {
      console.log({ error });
    }

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {children}

      <Modal
        closeModal
        onHide={handleClose}
        show={isModalVisible}
        background={background}
      >
        <Row>
          <Col
            sm={5}
            style={{
              margin: 'auto',
              paddingLeft: '4%',
            }}
          >
            {' '}
            <h1 className="display-5 pb-3">
              Ready to Take Your Digital Signage to the Next Level?
            </h1>
            <div
              className="Schedule"
              style={{
                fontWeight: '900',
                fontSize: '1.35rem',
              }}
            >
              <p>
                Schedule a call with our Sales Team to upgrade your account and
                unlock these powerful features:
              </p>
            </div>
            <ul
              style={{
                listStyleImage: `url(${dotIcon})`,
                listStylePosition: 'outside',
                fontWeight: '600',
                fontSize: '1.32rem ',
              }}
              className="text-justify"
            >
              <li>Organize playlists and device groups</li>
              <li>Content library of ready-to-use templates</li>
              <li>
                Integrate with Social Media Apps, PowerBi, Google Calendar and
                more
              </li>
              <li>Scheduling features and preview capabilities</li>
              <li>Enterprise app integration capabilities</li>
              <li>And more!</li>
            </ul>
          </Col>
          <Col
            sm={7}
            style={{
              margin: 'auto',
              paddingLeft: '4%',
            }}
          >
            <iframe
              id="expiringIframe"
              src={`https://meetings.hubspot.com/michael-trojnar?embed=true&parentHubspotUtk=d3ef7d34152c6a9df2c4c8b5dc744bde&firstname=${user?.firstName}&lastname=${user?.lastName}&email=${user?.email}&company=${userCompany}`}
              width="90%"
              data-hs-ignore="true"
              style={{
                minWidth: '312px',
                minHeight: '516px',
                height: '670px',
                border: 'none',

                // borderRadius: '40px',
              }}
            />

            {/* <div
              className="fs-2x fw-bolder text-black-800 text-center mb-11"
              style={{ paddingTop: '30px' }}
            >
              <div
                className={clsx(styles.btnTalk)}
                style={{
                  backgroundColor: '#d9178a',
                  borderRadius: '40px',
                  boxShadow: '1px 0px 17px 2px rgba(217,0,92,0.5) ',
                  margin: 'auto',
                  fontWeight: '600',
                }}
              >
                {' '}
                Or Call{' '}
                <i
                  className="bi bi-telephone-fill fs-7"
                  style={{ color: '#fff', fontSize: '60px' }}
                />{' '}
                214 - 796 - 7762
              </div>
            </div> */}
          </Col>
        </Row>
      </Modal>
    </>
  );
};
