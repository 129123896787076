import axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../../../constants/api';
import CompaniesRoutes from './enums/companiesRoutes.enum';
import GetCompaniesRequestData from './interfaces/getCompaniesRequestData.interface';
import GetCompaniesResponseData from './interfaces/getCompaniesResponseData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import Company from '../../../interfaces/company.interface';
import CreateUserCompanyRequestData from './interfaces/createUserCompanyRequestData.interfaces';
import CreateAdminCompanyRequestData from './interfaces/createAdminCompanyRequestData.interfaces';
import EditAdminCompanyRequestData from './interfaces/editAdminCompanyRequestData.interfaces';
import CompaniesByIdsQueryParam from './enums/companiesByIdsQueryParam.enum';

export default {
  /**
   * Retrieves the companies list
   * @param requestData
   */
  getCompaniesList: async (
    requestData: GetCompaniesRequestData,
  ): Promise<GetCompaniesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CompaniesRoutes.Companies}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the companies list For Select
   * @param requestData
   */
  getCompaniesListForSelect: async (
    requestData: GetCompaniesRequestData,
  ): Promise<GetCompaniesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CompaniesRoutes.Companies}/select-dropdown`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of companies by ids
   * @param ids
   * @param currentUserId
   */
  getCompaniesByIds: async (
    ids: string[],
    currentUserId?: string,
  ): Promise<Company[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CompaniesRoutes.Companies}`,
      params: { [CompaniesByIdsQueryParam.CompanyIds]: ids, currentUserId },
    });

    return data.items;
  },

  /**
   * Retrieves the list of companies by ids (admin)
   * @param ids
   * @param currentUserId
   */
  getAdminCompaniesByIds: async (
    ids: string[],
    currentUserId?: string,
  ): Promise<Company[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CompaniesRoutes.AdminCompanies}`,
      params: { [CompaniesByIdsQueryParam.CompanyIds]: ids, currentUserId },
    });

    return data.items;
  },

  /**
   * Retrieves the company by id
   * @param id
   */
  getCompany: async (id: string): Promise<Company> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CompaniesRoutes.Companies}/${id}`,
    });

    return data;
  },

  /**
   * Retrieves the company by id (for admin)
   * @param id
   * @param currentUserId
   */
  getAdminCompany: async (
    id: string,
    currentUserId?: string,
  ): Promise<Company> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CompaniesRoutes.AdminCompanies}/${id}`,
      params: sanitizeQueryParams({ currentUserId }),
    });

    return data;
  },

  /**
   * Removes the company by id
   * @param id
   */
  deleteCompany: async (id: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${CompaniesRoutes.AdminCompanies}/${id}`,
      throwError: true,
    } as AxiosRequestConfig);
  },

  /**
   * Removes the user company by id
   * @param id
   */
  deleteUserCompany: async (id: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${CompaniesRoutes.Companies}/${id}`,
      throwError: true,
    } as AxiosRequestConfig);
  },

  addCompany: async (company: CreateUserCompanyRequestData) => {
    return axios({
      method: 'post',
      url: `${API_URL}/${CompaniesRoutes.Companies}`,
      data: company,
    });
  },

  /**
   * Retrieves the companies list
   * @param requestData
   */
  getAdminCompaniesList: async (
    requestData: GetCompaniesRequestData,
  ): Promise<GetCompaniesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CompaniesRoutes.AdminCompanies}`,
      params: requestData,
    });

    return data;
  },

  getAdminCompaniesListForSelect: async (
    requestData: GetCompaniesRequestData,
  ): Promise<GetCompaniesResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${CompaniesRoutes.AdminCompanies}/select-dropdown`,
      params: requestData,
    });

    return data;
  },
  createAdminCompany: async (requestData: CreateAdminCompanyRequestData) => {
    return axios({
      method: 'post',
      url: `${API_URL}/${CompaniesRoutes.AdminCompanies}`,
      data: requestData,
    });
  },

  editAdminCompany: async (
    companyId: string,
    requestData: EditAdminCompanyRequestData,
  ) => {
    return axios({
      method: 'put',
      url: `${API_URL}/${CompaniesRoutes.AdminCompanies}/${companyId}`,
      data: requestData,
    });
  },

  editCompany: async (
    companyId: string,
    requestData: EditAdminCompanyRequestData,
  ) => {
    return axios({
      method: 'put',
      url: `${API_URL}/${CompaniesRoutes.Companies}/${companyId}`,
      data: requestData,
    });
  },
};
