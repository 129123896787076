import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import FormErrorMessage from '../../../FormErrorMessage';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import countDownFlipTimerPic from '../../../../assets/images/countTimer/countTimeFlip.png';
import countDownMinimalTimerPic from '../../../../assets/images/countTimer/countTimeMinimal.png';
import Orientation from '../../../../enums/orientation.enum';
import styles from './CountDownTimer.module.scss';
import { disableRightClickIframe } from '../../../../utils/common';
import { CreateCountDownTimerFeedRequest } from '../../../../store/types/apps/countDownTimer';
import TextInput from '../../../TextInput';
import ColorPicker from '../../../ColorPicker';
import { getRgbaValuesFromString } from '../../../../utils/colorFunctions';
import DatePicker from '../../../DatePicker';
import Yup from '../../../../utils/validation';
import SwitchCheckbox from '../../../SwitchCheckbox';

type CreateCountDownTimerRequestFormMutated = Omit<
  CreateCountDownTimerFeedRequest,
  'config' | 'placement'
>;

const styleOptions = [
  { label: 'Flip', value: 'flip' },
  { label: 'Minimal', value: 'minimal' },
];

const completionIcons = [
  { label: 'Thumbs up', value: 'fa fa-thumbs-up' },
  // { label: 'Balloons', value: 'Select a Start Date' },
  { label: 'Smile', value: 'fa fa-smile' },
  { label: 'Tick', value: 'fa fa-check' },
  { label: 'Nothing', value: 'nothing' },
];

interface CreateCountDownTimerFeedRequestFormPayload
  extends CreateCountDownTimerRequestFormMutated {
  countTime?: Date;
  style?: string;
  backgroundColor?: string;
  text?: string;
  textColor?: string;
  completionMessage?: string;
  completionIcon?: string;
  showDays?: boolean;
}

export interface CreateFeedModalProps {
  onSubmit: (data: CreateCountDownTimerFeedRequest) => void;
  countDownTimerApp?: App;
  onClose?: () => void;
  isLoading: boolean;
}

export default ({
  onSubmit,
  onClose,
  isLoading,
  countDownTimerApp = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();

  const initialValues: CreateCountDownTimerFeedRequestFormPayload = {
    name: countDownTimerApp?.name ?? '',

    backgroundColor:
      countDownTimerApp?.dependency?.config?.backgroundColor ??
      'rgba(248, 231, 28,100)',
    textColor:
      countDownTimerApp?.dependency?.config?.textColor ??
      'rgba(31, 30, 30,100)',
    text: countDownTimerApp?.dependency?.config?.text ?? '',
    completionMessage:
      countDownTimerApp?.dependency?.config?.completionMessage ?? '',
    completionIcon:
      countDownTimerApp?.dependency?.config?.completionIcon ??
      completionIcons[0].value,
    countTime: countDownTimerApp?.dependency?.config?.countTime ?? new Date(),
    style:
      countDownTimerApp?.dependency?.config?.style ?? styleOptions[0].value,

    showDays: countDownTimerApp?.dependency?.config?.showDays ?? false,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),

    text: Yup.string().required(t('apps.countTimer.required')),
    completionMessage: Yup.string().required(t('apps.countTimer.required')),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateCountDownTimerFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  //   const modalHeight = 400;

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateCountDownTimerFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const [backgroundColor, setBackgroundColor] = useStateWithHookForm<
    CreateCountDownTimerFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'backgroundColor' },
    initialValues.backgroundColor,
  );

  const [textColor, setTextColor] = useStateWithHookForm<
    CreateCountDownTimerFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'textColor' }, initialValues.textColor);

  const [text, setText] = useStateWithHookForm<
    CreateCountDownTimerFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'text' }, initialValues.text);

  const [style, setStyle] = useStateWithHookForm<
    CreateCountDownTimerFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'style' }, initialValues.style);

  const [countTime, setCountTime] = useStateWithHookForm<
    CreateCountDownTimerFeedRequestFormPayload,
    Date
  >({ setValue, trigger, name: 'countTime' }, initialValues.countTime);

  const [completionMessage, setcompletionMessage] = useStateWithHookForm<
    CreateCountDownTimerFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'completionMessage' },
    initialValues.completionMessage,
  );

  const [completionIcon, setCompletionIcon] = useStateWithHookForm<
    CreateCountDownTimerFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'completionIcon' },
    initialValues.completionIcon,
  );

  const [showDays, setShowDays] = useStateWithHookForm<
    CreateCountDownTimerFeedRequestFormPayload,
    boolean
  >({ setValue, trigger, name: 'showDays' }, initialValues.showDays);

  const [isScheduled, setIsScheduled] = useState<boolean>(false);

  const handleOnSubmit = async (
    data: CreateCountDownTimerFeedRequestFormPayload,
  ) => {
    const { name } = data;
    const createCountDownTimerFeedRequest: CreateCountDownTimerFeedRequest = {
      name,
      isScheduled,
      config: {
        backgroundColor,
        countTime,
        style,
        textColor,
        text,
        completionIcon,
        completionMessage,
        showDays,
      },
      zoneId: Orientation.Landscape,
    };

    onSubmit(createCountDownTimerFeedRequest);
  };

  React.useEffect(() => {
    disableRightClickIframe(feedName);
  }, []);

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id="form"
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex flex-row p-4 w-100">
            <div className="d-flex flex-column mx-2 w-100">
              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" required col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('apps.countTimer.name')}
                </label>
                <TextInput
                  name="name"
                  id="name"
                  value={feedName}
                  onChange={setFeedName}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className="my-1 px-2"
                />
              </p>
              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" required col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('apps.countTimer.text')}
                </label>
                <TextInput
                  name="text"
                  id="text"
                  value={text}
                  onChange={setText}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
                <FormErrorMessage
                  name="text"
                  errors={errors}
                  className="my-1 px-2"
                />
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="startYear"
                  className="  d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.countTimer.endDate')}
                </label>
                <DatePicker
                  name="startDate"
                  value={countTime}
                  placeholder="Select Date"
                  onChange={(newDate) => setCountTime(newDate[0])}
                  options={{
                    enableTime: true,
                    defaultHour: 0,
                  }}
                />
                <FormErrorMessage name="startDate" errors={errors} />
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" required col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('apps.countTimer.textMessageAfterCompletion')}
                </label>
                <TextInput
                  name="completionMessage"
                  id="completionMessage"
                  value={completionMessage}
                  onChange={setcompletionMessage}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
                <FormErrorMessage
                  name="completionMessage"
                  errors={errors}
                  className="my-1 px-2"
                />
              </p>
              <p className="mt-2 mb-0">
                <label
                  htmlFor="startTime"
                  className=" d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6 "
                >
                  {t('apps.countTimer.completionIcon')}
                </label>

                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={false}
                  name="uploadType"
                  options={completionIcons}
                  onChange={(val) => {
                    if (val?.value) setCompletionIcon(val?.value);
                  }}
                  // defaultValue={completionIcons.find((opt) => opt.value ===completionIcon )}
                  value={completionIcons.find(
                    (opt) => opt.value === completionIcon,
                  )}
                />
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.countTimer.style')}
                </label>
                <Select
                  options={styleOptions}
                  onChange={(val) => {
                    if (val?.value) setStyle(val?.value);
                  }}
                  value={styleOptions.find((opt) => opt.value === style)}
                  isSearchable={false}
                  isClearable
                />
              </p>

              {style === 'minimal' && (
                <p className="mt-8 mb-0 d-inline-flex justify-content-between w-100">
                  <label
                    htmlFor="displayDate"
                    className=" text-dark fw-bolder my-1"
                  >
                    {t('apps.countTimer.showDays')} (
                    {t('apps.countTimer.daysLeftDown')})
                  </label>
                  <SwitchCheckbox
                    name="displayDate"
                    inputClassName="h-20px w-30px"
                    checked={!!showDays}
                    onChange={({ currentTarget: { checked } }) =>
                      setShowDays(checked)
                    }
                  />
                </p>
              )}
            </div>

            <div className="d-flex flex-column mx-2 w-100">
              <label
                htmlFor="name"
                className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
              >
                {t('apps.countTimer.previewExapmle')}
              </label>
              <img
                src={
                  style === 'flip'
                    ? countDownFlipTimerPic
                    : countDownMinimalTimerPic
                }
                alt="count down timer preview"
                className="mb-5"
                style={{ width: '100%' }}
              />

              <p className="mt-2 mb-0 d-inline-flex justify-content-between w-100">
                <label
                  htmlFor="bgColor"
                  className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.countTimer.backgroundColor')}
                </label>
                <div className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6">
                  <ColorPicker
                    id="BgColor"
                    name="BgColor"
                    onSelect={(color) => {
                      setBackgroundColor(
                        `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                          color?.b ?? 0
                        }, ${color?.a ?? 1})`,
                      );
                    }}
                    value={getRgbaValuesFromString(backgroundColor)}
                  />
                </div>
              </p>

              <p className="mt-2 mb-0 d-inline-flex justify-content-between w-100">
                <label
                  htmlFor="name"
                  className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.countTimer.textColor')}
                </label>
                <div className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6">
                  <ColorPicker
                    id="textColor"
                    name="textColor"
                    onSelect={(color) => {
                      setTextColor(
                        `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                          color?.b ?? 0
                        }, ${color?.a ?? 1})`,
                      );
                    }}
                    value={getRgbaValuesFromString(textColor)}
                  />
                </div>
              </p>
            </div>
          </div>
          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                {t('common.save')}
              </button>
              {!Object.keys(countDownTimerApp).length && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                >
                  {t('common.save_schedule')}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
