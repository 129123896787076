import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Tabs, Timeline, Pagination, Button } from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  CalendarOutlined,
  WarningOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Card from '../Card';

import TimelineTabs from '../../enums/timeline/timeLineTabs.enum';
import UserLogs from '../../interfaces/userLogs.interface';
import WithSpinner from '../WithSpinner';
import {
  debouncedUpdateSearch,
  updateSearch,
} from '../../utils/browserHistory';
import EventType from '../../enums/timeline/eventType.enum';
import GlobalState from '../../store/reducers/globalState.interface';
import Modal from '../Modal';
import LogChangesTable from './LogChanges';
import { UserRole } from '../../enums/userRole.enum';

export default ({
  children,
  totalItems,
  loading = true,
  hideHeader,
  hideUsername,
  myAccountHeaderTitle,
  hidePlaylistName,
  isAdminDashboard,
  usersLogs,
}: {
  usersLogs?: UserLogs[];
  totalItems?: number;
  loading: boolean;
  hideHeader?: boolean;
  hideUsername?: boolean;
  myAccountHeaderTitle?: boolean;
  hidePlaylistName?: boolean;
  children?: React.ReactNode;

  isAdminDashboard?: boolean;
}) => {
  const { t } = useTranslation();

  const { roles } = useSelector((state: GlobalState) => state.userData.user);

  const [timeLinePage, setTimeLinePage] = useState<number>(1);

  const [currentTab, setCurrentTab] = useState<TimelineTabs>(TimelineTabs.All);
  const [modalShown, setModalShown] = React.useState(false);

  const [currentUserLog, setCurrentUserLog] = React.useState<UserLogs>();
  const { id } = useSelector((state: GlobalState) => state.userData.user);

  const showIconTimeLine = (item: UserLogs) => {
    if (item?.isSuccess === false) return <WarningOutlined />;
    if (item?.eventType === EventType.Create) return <PlusOutlined />;
    if (item?.eventType === EventType.Update) return <EditOutlined />;
    if (item?.eventType === EventType.Delete) return <DeleteOutlined />;
    if (item?.eventType === EventType.Copy) return <CopyOutlined />;
    if (item?.eventType === EventType.Schedule) return <CalendarOutlined />;
    if (item?.eventType === EventType.Lock) return <LockOutlined />;
    if (item?.eventType === EventType.UnLock) return <UnlockOutlined />;
    return <EditOutlined />;
  };

  const timelineColor = (item: UserLogs) => {
    if (item?.isSuccess === false) return 'red';
    if (item?.eventType === EventType.Create) return 'green';
    if (item?.eventType === EventType.Update) return 'blue';
    if (item?.eventType === EventType.Delete) return 'red';
    if (item?.eventType === EventType.Copy) return 'gray';
    if (item?.eventType === EventType.Schedule) return 'blue';
    if (item?.eventType === EventType.Lock) return 'red';
    if (item?.eventType === EventType.UnLock) return 'green';
    return 'gray';
  };

  useEffect(() => {
    updateSearch({
      date: currentTab,
      page: 1,
    });
  }, [currentTab]);

  return (
    <div>
      {children}
      <Card>
        <Card.Header>
          <Card.Header.Toolbar>
            <div className="d-flex align-items-center flex-wrap">
              {!hideHeader && (
                <Card.Header.Title.Label>
                  {' '}
                  {t('playlist.timeline')}{' '}
                </Card.Header.Title.Label>
              )}

              {myAccountHeaderTitle && (
                <h2
                  className="fw-normal text-300 m-0"
                  style={{ padding: '20px' }}
                >
                  {t('acc_drop_down.my_logs')}
                </h2>
              )}
              {isAdminDashboard && (
                <h3 className="card-title fw-bolder text-dark">
                  {t('logs.activityLogs')}
                </h3>
              )}
            </div>

            <div className="d-flex align-items-center align-content-center">
              <Tabs
                defaultActiveKey="1"
                onChange={(tab) => {
                  setCurrentTab(tab as TimelineTabs);
                }}
              >
                <Tabs.TabPane tab="All" key={TimelineTabs.All} />
                <Tabs.TabPane tab="Today" key={TimelineTabs.Today} />
                <Tabs.TabPane tab="Week" key={TimelineTabs.Week} />
                <Tabs.TabPane tab="Month" key={TimelineTabs.Month} />
                <Tabs.TabPane
                  tab={new Date().getFullYear()}
                  key={TimelineTabs.Year}
                />
              </Tabs>
            </div>
          </Card.Header.Toolbar>
        </Card.Header>
        <div
          // className={myAccountHeaderTitle ? 'flex-grow-1 mw-600px' : ''}
          style={{ paddingLeft: `${myAccountHeaderTitle ? '30px' : '0px'}` }}
        >
          <Card.Body>
            <WithSpinner isLoading={loading} className="min-h-400px" size="md">
              <Timeline>
                {usersLogs?.map((item) => (
                  <Timeline.Item
                    color={timelineColor(item)}
                    dot={showIconTimeLine(item)}
                    style={{ fontSize: '16px' }}
                  >
                    <div>
                      {!hideUsername && (
                        <>
                          {id === item?.user?.id ? (
                            <div
                              style={{ display: 'inline' }}
                              className="text-primary fw-bolder me-1"
                            >
                              You
                            </div>
                          ) : (
                            <a
                              href={`/customers/${item?.user?.id}/devices`}
                              className="text-primary fw-bolder me-1"
                            >
                              {roles[0] === UserRole.SuperAdmin &&
                              item?.isLoggedInAs ? (
                                <>
                                  Super Admin (Logged in as{' '}
                                  {`${item?.user?.firstName} ${item?.user?.lastName}`}{' '}
                                  )
                                </>
                              ) : (
                                <>
                                  {' '}
                                  {` ${item?.user?.firstName} ${item?.user?.lastName}`}
                                </>
                              )}
                            </a>
                          )}
                        </>
                      )}
                      {item.description}
                      {!hidePlaylistName &&
                        item?.playlistname &&
                        ` (Playlist: ${item?.playlistname})`}
                      {item?.eventType === EventType.BulkEdit ? (
                        <Button
                          type="link"
                          onClick={() => {
                            setCurrentUserLog(item);
                            setModalShown(true);
                          }}
                        >
                          Show list
                        </Button>
                      ) : null}
                    </div>
                    <div className="overflow-auto pb-5">
                      <div className="d-flex align-items-center mt-1 fs-6">
                        <div className="text-muted me-2 fs-7">
                          {' '}
                          {moment(item?.createdAt).fromNow()}{' '}
                          {` (${moment(item?.createdAt).format('MM/DD/YYYY')})`}
                        </div>
                      </div>
                    </div>
                  </Timeline.Item>
                ))}
              </Timeline>
            </WithSpinner>

            <div className="d-flex justify-content-center align-items-center mb-7">
              <Pagination
                onChange={(page) => {
                  debouncedUpdateSearch({
                    page,
                  });
                  setTimeLinePage(page);
                }}
                total={totalItems}
                showSizeChanger={false}
                current={timeLinePage}
              />
            </div>
          </Card.Body>
        </div>
      </Card>

      <Modal
        title="Edited Contents"
        show={modalShown}
        onHide={() => {
          setModalShown(false);
        }}
        withFooter={false}
        widthDefaultSize={false}
        dialogClassName="mw-900px"
      >
        <LogChangesTable item={currentUserLog} />
      </Modal>
    </div>
  );
};
