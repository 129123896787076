import GetPlaylistsSuccessAction from './interfaces/getPlaylistsSuccessAction.interface';
import DeletePlaylistAction from './interfaces/deletePlaylistAction.interface';
import GetPlaylistsAction from './interfaces/getPlaylistsAction.interface';
import LoadingAction from '../loadingAction.interface';
import PlaylistsActions from './playlistsActions.enum';

export const getPlaylists = (
  payload: GetPlaylistsAction['payload'],
): GetPlaylistsAction => ({
  type: PlaylistsActions.GET_PLAYLISTS,
  payload,
});

export const getPlaylistsSuccess = (
  payload: GetPlaylistsSuccessAction['payload'],
): GetPlaylistsSuccessAction => ({
  type: PlaylistsActions.GET_PLAYLISTS_SUCCESS,
  payload,
});

export const deletePlaylist = (
  payload: DeletePlaylistAction['payload'],
): DeletePlaylistAction => ({
  type: PlaylistsActions.DELETE_PLAYLIST,
  payload,
});

export const setPlaylistsLoading = (
  payload: LoadingAction['payload'],
): LoadingAction => ({
  type: PlaylistsActions.SET_PLAYLISTS_LOADING,
  payload,
});
