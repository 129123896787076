// The enum is sorted by access level, from highest to lowest (SuperAdmin > Admin > Employee > Manager > Editor > AccountOwner)
// The enum is used to determine the access level of the user, and to determine which options to display in the user's role dropdown menu
// Please make sure when you update the enum, take into account the access level of the new role
export enum UserRole {
  SuperAdmin = 'SUPER_ADMIN',
  IndigoAdmin = 'INDIGO_ADMIN',
  IndigoEmployee = 'INDIGO_EMPLOYEE',
  AccountOwner = 'ACCOUNT_OWNER',
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Employee = 'EMPLOYEE',
  Editor = 'EDITOR',
}
