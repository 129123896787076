import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../../Modal';
// import { readFromQueryString } from '../../../utils/queryParams';
import SelectDeviceGroup from '../../../containers/DevicesGroups/SelectGroup';
import BulkUpdateDevicesRequestDataInterface from '../../../api/endpoints/devices/interfaces/bulkUpdateDevicesRequestData.interface';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import { bulkEditDeviceValidation } from '../../../validation/devices';
// import SelectLocation from '../../../containers/SelectLocation';
import SelectTimezone from '../../../containers/SelectTimezone';
import { SelectedValue } from '../../Select/SelectAsync';
import FormErrorMessage from '../../FormErrorMessage';
import validation from '../../../utils/validation';

interface FormInputs {
  zipCode: string;
  aspectRatio: string;
}

export default ({
  children,
  onSubmit,
  isAdmin,
  skipEditModalDeviceGroup,
}: {
  children: React.ReactNode;
  isAdmin?: boolean;
  onSubmit?: (
    updateData: Partial<BulkUpdateDevicesRequestDataInterface>,
  ) => void;
  skipEditModalDeviceGroup?: boolean;
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
    clearErrors,
  } = useForm<FormInputs>({
    resolver: yupResolver(
      validation.object(bulkEditDeviceValidation(t)).required(),
    ),
    defaultValues: {
      zipCode: '',
      aspectRatio: '',
    },
  });
  // const { search } = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [timezone, setTimezone] = useState<SelectedValue | undefined>();
  const [deviceGroups, setDeviceGroups] = useState<
    SelectedValue | SelectedValue[]
  >([]);
  const [zipCode, setZipCode] = useStateWithHookForm<
    FormInputs,
    FormInputs['zipCode']
  >({ setValue, trigger, name: 'zipCode' }, '');
  // const [aspectRatio, setAspectRatio] = useStateWithHookForm<
  //   FormInputs,
  //   FormInputs['aspectRatio']
  // >({ setValue, trigger, name: 'aspectRatio' }, '');
  // const [location, setLocation] = useState<string | undefined>(
  //   readFromQueryString<string>(search, 'locationId') ?? undefined,
  // );

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleSubmitData = () => {
    const deviceGroupIds = deviceGroups.map(
      (device: { value: string }) => device.value,
    );

    if (onSubmit) {
      onSubmit({
        deviceGroupIds,
        // locationId: location,
        timezoneId: timezone?.value,
        zipCode,
        // aspectRatio,
      });
    }
    handleClose();
  };

  const open = () => {
    clearErrors();
    setIsModalVisible(true);
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('devices.edit_device.edit_device')}
        widthDefaultSize={false}
        show={isModalVisible}
        onHide={handleClose}
        bodyClassName="py-5"
        footerContent={
          <>
            <button
              type="button"
              className="btn btn-light me-3"
              onClick={handleClose}
            >
              {t('common.cancel')}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit(handleSubmitData)}
            >
              {t('common.save')}
            </button>
          </>
        }
      >
        <>
          {!isAdmin && !skipEditModalDeviceGroup && (
            <div>
              <span className="col-form-label text-lg-end">
                {t('devices.device_group')}
              </span>
              <SelectDeviceGroup
                id="deviceGroup"
                onChange={setDeviceGroups}
                isMulti
              />
            </div>
          )}
          <p>
            <label htmlFor="timezone" className="col-form-label text-lg-end">
              {t('common.timezone')}
            </label>
            <SelectTimezone
              id="timezone"
              initialValue={timezone}
              onChange={setTimezone}
            />
          </p>
          {/* <p> */}
          {/*  <label htmlFor="location" className="form-label fw-bold"> */}
          {/*    {t('common.location')} */}
          {/*  </label> */}
          {/*  <SelectLocation */}
          {/*    id="location" */}
          {/*    initialValue={location} */}
          {/*    onChange={({ value }) => setLocation(value)} */}
          {/*    isClearable */}
          {/*  /> */}
          {/* </p> */}
          <p>
            <label htmlFor="zipCode" className="col-form-label text-lg-end">
              {t('common.zip_code')}
            </label>
            <input
              type="text"
              id="zipCode"
              className="form-control form-control-lg form-control-solid"
              value={zipCode}
              onChange={({ currentTarget: { value } }) => setZipCode(value)}
              placeholder={t('common.zip_code')}
            />
            <FormErrorMessage
              name="zipCode"
              errors={errors}
              className="mt-1 min-h-20px"
            />
          </p>
          {/* <p> */}
          {/*  <label htmlFor="aspectRatio" className="col-form-label text-lg-end"> */}
          {/*    {t('common.aspect_ratio')} */}
          {/*  </label> */}
          {/*  <input */}
          {/*    type="text" */}
          {/*    id="aspectRatio" */}
          {/*    className="form-control form-control-lg form-control-solid" */}
          {/*    placeholder="1:2" */}
          {/*    value={aspectRatio} */}
          {/*    onChange={({ currentTarget: { value } }) => setAspectRatio(value)} */}
          {/*  /> */}
          {/*  <FormErrorMessage */}
          {/*    name="aspectRatio" */}
          {/*    errors={errors} */}
          {/*    className="mt-1 min-h-20px" */}
          {/*  /> */}
          {/* </p> */}
        </>
      </Modal>
    </>
  );
};
