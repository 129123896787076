import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import GlobalState from '../../store/reducers/globalState.interface';
import { defaultDebounceValue } from '../../constants/api/debounceSettings';
import { getUsersGroups } from '../../store/actions/usersGroups';
import { readAllFromQueryParams } from '../../utils/queryParams';
import UsersGroups from '../../components/UsersGroups';
import useLoading from '../../utils/hooks/useLoading';

export default () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: GlobalState) => state.usersGroups.loading,
  );
  const groups = useSelector((state: GlobalState) => state.usersGroups.groups);
  const total = useSelector((state: GlobalState) => state.usersGroups.total);
  const [loading, setLoading] = useLoading(isLoading);

  const dispatchGetGroupsList = useCallback(
    debounce(
      (recentSearch: string) =>
        dispatch(getUsersGroups(readAllFromQueryParams(recentSearch))),
      defaultDebounceValue,
    ),
    [],
  );

  useEffect(() => {
    setLoading(true); // show preloader before dispatch
    dispatchGetGroupsList(search);
  }, [search]);

  return (
    <UsersGroups
      groups={groups}
      total={total}
      isLoading={loading}
      resetOnSearch
    />
  );
};
