import { useCallback, useEffect } from 'react';
import EventEmitter from 'eventemitter3';
import { offEvents, onEvents } from '../eventEmitter';

/**
 * Adds the listener to the EventEmitter event and removes it on unmount
 * @param event
 * @param cb
 * @param context
 * @param deps
 * @param once
 */
export default <T extends EventEmitter.EventNames<string | symbol>>(
  event: T | T[],
  cb: EventEmitter.EventListener<string | symbol, T>,
  {
    context,
    deps = [],
    once,
  }: { context?: any; deps?: any[]; once?: boolean } = {},
) => {
  const handler = useCallback(cb, deps);
  const isArrayOfEvents = event instanceof Array;

  useEffect(() => {
    onEvents(isArrayOfEvents ? event : [event], handler, context, once);

    return () => offEvents(isArrayOfEvents ? event : [event], handler);
  }, [deps]);
};
