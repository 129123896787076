import React from 'react';
import clsx from 'clsx';
import styles from '../../../tableRow.module.scss';
import { AppDependencyConfig } from '../../../../../../../store/types/apps/globalTypes';
import IContent from '../../../../../../../interfaces/content.interface';

export default ({ content }: { content: IContent }) => (
  <div className={clsx('position-relative', styles.thumbnail)}>
    <div className={styles.iframePreview}>
      <iframe
        title={content?.app?.name}
        src={
          (content.app?.dependency?.config as AppDependencyConfig)
            ?.liveStreamFeedUrl
        }
        height="100%"
        width="100%"
      />
    </div>
  </div>
);
