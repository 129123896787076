import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import User from '../../../interfaces/user/user.interface';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import CreateUserRequestData from '../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import BulkUpdateUsersRequestData from '../../../api/endpoints/users/interfaces/bulkUpdateUsersRequestData.interface';
import { createFormData } from '../../../utils/formData';
import UsersTable from '../../../components/Users';
import api from '../../../api';

export default ({ children }: { children: React.ReactNode }) => {
  const [users, setUsers] = useState<any>([]);
  const [total, setTotal] = useState<number>(0);
  const [usersLoading, setUsersLoading] = useState<boolean>(true);

  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();

  const getDeviceGroupUsers = async (recentSearch: string) => {
    try {
      setUsersLoading(true);
      const { items, meta } = await api.devicesGroups.getDeviceGroupUsersList(
        readAllFromQueryParams(recentSearch),
        id,
      );

      setUsers(items);
      setTotal(meta.totalItems);
    } catch (e) {
      // Do nothing for now
    }
    setUsersLoading(false);
  };

  useEffect(() => {
    setUsersLoading(true);
    getDeviceGroupUsers(search);
  }, [search]);

  const handleUnAssignUserFromUserGroup = async (
    userId: string,
    deviceGroupId: string,
  ) => {
    try {
      setUsersLoading(true);

      await api.devicesGroups.unAssignUsersFromGroup(userId, deviceGroupId);
      setUsers(users.filter((user: User) => userId !== user.id));
    } catch (e) {
      // Do nothing for now
    }
    setUsersLoading(false);
  };

  const handleAssignUsersToDeviceGroup = async (data: SelectedValue[]) => {
    try {
      setUsersLoading(true);

      const userIds = data.map(({ value }) => value as string);
      await api.devicesGroups.assignUsersToGroup({ userIds }, id);

      getDeviceGroupUsers(search);
    } catch (e) {
      // Do nothing for now
    }
    setUsersLoading(false);
  };

  const handleEditUserInDeviceGroup = async (
    userId: string,
    data: Partial<CreateUserRequestData>,
  ) => {
    try {
      setUsersLoading(true);

      await api.users.updateUserData(userId, createFormData(data, true));
      getDeviceGroupUsers(search);
    } catch (e) {
      // Do nothing for now
    }
    setUsersLoading(false);
  };

  const handleBulkEditUsersInDeviceGroup = async (
    data: BulkUpdateUsersRequestData,
  ) => {
    try {
      setUsersLoading(true);

      await api.users.bulkEditUsers(data);
      getDeviceGroupUsers(search);
    } catch (e) {
      // Do nothing for now
    }
    setUsersLoading(false);
  };

  const handleRestoreUserInDeviceGroup = async (userId: string) => {
    try {
      setUsersLoading(true);

      await api.users.restoreUser(userId);

      setUsers(
        users.map((user: User) =>
          user.id === userId ? { ...user, status: 'ACTIVE' } : user,
        ),
      );
    } catch (e) {
      // Do nothing for now
    }
    setUsersLoading(false);
  };
  const handleUnAssignUserFromUserGroups = async (
    userId: string,
    deviceGroup: string,
  ) => {
    try {
      setUsersLoading(true);
      const ids: string[] = [];
      ids.push(userId);
      await api.users.RemoveUsersFromDeviceGroup(deviceGroup, ids);
      setUsers(users.filter((user: User) => userId !== user.id));
      toast.success('User unsigned from device group successfully');
      setUsersLoading(false);
    } catch (e) {
      console.log(e);
      toast.error('Error unsigned user from device group');
      setUsersLoading(false);
    }
    console.log(
      `handleUnAssignUserFromUserGroup${userId} device group ${deviceGroup}`,
    );
  };

  const handleSuspendUserInDeviceGroup = async (userId: string) => {
    try {
      setUsersLoading(true);

      await api.users.suspendUser(userId);

      setUsers(
        users.map((user: User) =>
          user.id === userId ? { ...user, status: 'BANNED' } : user,
        ),
      );
    } catch (e) {
      // Do nothing for now
    }
    setUsersLoading(false);
  };

  return (
    <UsersTable
      users={users}
      total={total}
      isLoading={usersLoading}
      onEditUser={handleEditUserInDeviceGroup}
      onRestoreUser={handleRestoreUserInDeviceGroup}
      onBulkEditUser={handleBulkEditUsersInDeviceGroup}
      onSuspendUser={handleSuspendUserInDeviceGroup}
      unAssignUser={handleUnAssignUserFromUserGroup}
      assignUser={handleAssignUsersToDeviceGroup}
      onUnssingUserFromDeviceGroup={handleUnAssignUserFromUserGroups}
      skipEditSuspendReset
      isSelectable={false}
    >
      {children}
    </UsersTable>
  );
};
