import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import api from '../../../api';
import CreateAppRequestData from '../../../api/endpoints/apps/interfaces/createAppRequestData.interface';
import Modal from '../../../components/Modal';
import CreateEditTickerModal from '../../../components/Ticker/CreateEditTickerModal';
import App from '../../../interfaces/app.interface';
import { getUserData } from '../../../store/actions/userData';

export default ({
  onSuccess,
  children,
}: {
  onSuccess: () => void;
  children: React.ReactNode;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalShown, setModalShown] = useState<boolean>(false);
  const { t } = useTranslation();
  const open = () => setModalShown(true);
  const handleClose = () => setModalShown(false);
  const dispatch = useDispatch();

  const handleSubmit = (data: CreateAppRequestData) => {
    setIsLoading(true);
    api.apps.createApp('ticker', data).then(() => {
      if (onSuccess) {
        onSuccess();
        toast.success(i18next.t<string>('tickers.ticker_added'));
      }
      dispatch(getUserData());
      setIsLoading(false);
      handleClose();
    });
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      {modalShown && (
        <Modal
          title={t('tickers.addTicker.createTicker')}
          show={modalShown}
          onHide={handleClose}
          withFooter={false}
        >
          <CreateEditTickerModal
            handleClose={handleClose}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            app={{} as App}
          />
        </Modal>
      )}
    </>
  );
};
