import * as React from 'react';
import { useTable } from 'react-table';
import EmptyFeeds from '../../../components/Apps/EmptyFeeds/EmptyFeeds';

import Table from '../../../components/Table';
import WithSpinner from '../../../components/WithSpinner';

import {
  LiveStreamFeedSchema,
  PaginatedLiveStreamFeeds,
} from '../../../store/types/apps/liveStreamFeed';

import useColumns from './useColumns';
import useData from './useData';
import styles from './FeedsTable.module.scss';

export interface FeedsTableProps {
  isLoading: boolean;
  paginatedLiveStreamFeeds: PaginatedLiveStreamFeeds;
  clickOnLiveStreamFeed: (feed: LiveStreamFeedSchema) => void;
  openEditLiveStreamFeedModal: (feed: LiveStreamFeedSchema) => void;
  openDeleteLiveStreamFeedModal: (feed: LiveStreamFeedSchema) => void;
  openActionsDropdown: (feed: LiveStreamFeedSchema) => void;
  openScheduleLiveStreamFeedModal: (feed: LiveStreamFeedSchema) => void;
  selectedLiveStreamFeed: LiveStreamFeedSchema | null;
}

const FeedsTable = ({
  isLoading = false,
  paginatedLiveStreamFeeds,
  clickOnLiveStreamFeed,
  openEditLiveStreamFeedModal,
  openDeleteLiveStreamFeedModal,
  openActionsDropdown,
  openScheduleLiveStreamFeedModal,
  selectedLiveStreamFeed,
}: FeedsTableProps) => {
  const data = useData(paginatedLiveStreamFeeds.items);
  const columns = useColumns({
    clickOnLiveStreamFeed,
    openEditLiveStreamFeedModal,
    openDeleteLiveStreamFeedModal,
    openActionsDropdown,
    openScheduleLiveStreamFeedModal,
    selectedLiveStreamFeed,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <Table className="h-100" {...getTableProps()}>
      <Table.Head>
        {headerGroups.map((headerGroup) => (
          <Table.Head.Row {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <Table.Head.Row.Cell
                className={
                  index === headerGroup.headers.length - 1 ? styles.actions : ''
                }
                isTextEnd={index === headerGroup.headers.length - 1}
                {...column.getHeaderProps()}
              >
                {column.render('Header')}
              </Table.Head.Row.Cell>
            ))}
          </Table.Head.Row>
        ))}
      </Table.Head>
      <Table.Body
        {...getTableBodyProps()}
        isEmpty={!isLoading && paginatedLiveStreamFeeds.items.length === 0}
        emptyStateHeight={500}
        emptyStateChildren={
          <EmptyFeeds descriptionText="apps.liveStream.noLiveStreamFound" />
        }
      >
        <WithSpinner
          isLoading={isLoading}
          style={{ minHeight: '500px' }}
          isForTable
        >
          {rows.map((row) => {
            prepareRow(row);

            return (
              <Table.Body.Row {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <Table.Body.Row.Cell
                      isTextEnd={index === row.cells.length - 1}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </Table.Body.Row.Cell>
                  );
                })}
              </Table.Body.Row>
            );
          })}
        </WithSpinner>
      </Table.Body>
    </Table>
  );
};

export default FeedsTable;
