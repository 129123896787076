import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import App from '../../../../../interfaces/app.interface';
import Table from '../../../../Table';
import DeleteCountDownTimer from '../../../../../containers/Apps/DeleteApp';
import ScheduleCountUpTimer from '../../../../../containers/Apps/ScheduleApp';
import { AppDependency } from '../../../../../store/types/apps/globalTypes';
import { formatDateString } from '../../../../../utils/dateTime';
import PlacementsEnum from '../../../../../enums/placements.enum';
import Yup from '../../../../../utils/validation';
import EditCountDownTimer from '../../../../../containers/Apps/CountDownTimer/EditCountDownTimer';
import PreviewFeedModal from '../../PreviewFeedModal';
import Preview from '../../../../../containers/Apps/Preview';

const { Row } = Table.Body;
const { Cell } = Row;

const formatDate = formatDateString({
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  minute: '2-digit',
  hour: 'numeric',
  hour12: true,
});

const TableRow = ({
  countTimer,
  onUpdate,
}: {
  countTimer: App;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [showModalFeed, setShowModalFeed] = useState(false);

  const countTime = countTimer?.dependency?.config?.countTime;
  const currentDate = new Date(Date.now()).toString();

  const getCountFromDate = () => {
    const time = Date.parse(countTime) - Date.parse(currentDate);

    if (time < 0) {
      return { Name: `${t('apps.countTimer.days')}`, Value: 0 };
    }

    let days = Math.floor(time / (1000 * 60 * 60 * 24));
    let hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);

    if (days) {
      if (hours || minutes || seconds) {
        console.log('before', days);
        days += 1;
      }

      if (days === 1)
        return {
          Name: `${t('apps.countTimer.day')}`,
          Value: `${days}`,
        };

      return {
        Name: `${t('apps.countTimer.days')}`,
        Value: days,
      };
    }

    if (hours) {
      if (minutes || seconds) {
        hours += 1;
      }
      if (hours === 1)
        return { Name: `${t('apps.countTimer.hour')}`, Value: `${hours}` };

      return { Name: `${t('apps.countTimer.hours')}`, Value: hours };
    }

    if (minutes) {
      if (minutes === 1)
        return { Name: `${t('apps.countTimer.minute')}`, Value: `${minutes}` };

      return { Name: `${t('apps.countTimer.minutes')}`, Value: minutes };
    }

    return {
      Name: 'Seconds',
      Value: seconds,
    };
  };

  return (
    <Row>
      <Cell isEmpty={!countTimer.name}>
        <div onClick={() => setShowModalFeed(true)} aria-hidden>
          <span className="text-dark cursor-pointer fw-bold text-hover-primary d-block mb-1 fs-6 w-100">
            {countTimer.name}
          </span>
        </div>
        <PreviewFeedModal
          show={showModalFeed}
          onHide={() => {
            setShowModalFeed(false);
          }}
          title={t('common.preview')}
          selectedCountTimerSchema={{
            type: 'count-down',
            createdAt: countTimer!.createdAt,
            updatedAt: countTimer!.updatedAt,
            dependency: countTimer!.dependency as AppDependency,
            id: countTimer!.id,
            name: countTimer!.name,
            placement: countTimer!.placement as PlacementsEnum,
          }}
        />
      </Cell>
      <Cell isEmpty={!countTimer?.dependency?.config?.countTime}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {getCountFromDate().Value} {getCountFromDate().Name}
        </span>
      </Cell>
      <Cell isEmpty={!countTimer.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {_.capitalize(countTimer?.dependency?.config?.style)}
        </span>
      </Cell>

      <Cell isEmpty={!countTimer.ownerApp}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {countTimer?.ownerApp?.firstName} {countTimer?.ownerApp?.lastName}
        </span>
      </Cell>
      <Cell isEmpty={!countTimer.createdAt}>
        <span className="text-dark fw-bold text-hover-primary d-block mb-1 fs-6 text-center ">
          {countTimer.createdAt && formatDate(countTimer.createdAt)}
        </span>
      </Cell>
      <Cell>
        <div className="d-flex justify-content-end">
          <div className="mx-1">
            <Preview countDownApp={countTimer} />
          </div>

          <div className="mx-1">
            <EditCountDownTimer
              countDownTimerApp={countTimer}
              onUpdate={onUpdate}
            />
          </div>

          {countTimer.placement === PlacementsEnum.FULL_SCREEN && (
            <div className="mx-1">
              <ScheduleCountUpTimer
                app={countTimer}
                onUpdate={onUpdate}
                infoValidationSchema={{
                  orientation: Yup.string().required(t('required')),
                  duration: Yup.number().required(t('required')).min(1),
                  startDate: Yup.date(),
                  endDate: Yup.date().when('startDate', {
                    is: (startDate: any) => !!startDate,
                    then: Yup.date().min(
                      Yup.ref('startDate'),
                      t('common.validation.endDate'),
                    ),
                  }),
                  isLocked: Yup.boolean(),
                }}
                skipOrientationAuto
                skipOrder
                isCountDown
              />
            </div>
          )}

          <div className="mx-1">
            <DeleteCountDownTimer
              title={t('common.delete_confirmation.countDownTimer')}
              app={countTimer}
              onUpdate={onUpdate}
              toastMessage="apps.countTimer.countTimerDeleted"
            />
          </div>
        </div>
      </Cell>
    </Row>
  );
};

export default TableRow;
