/**
 * Creates the form data from the object
 * @param payload
 * @param skipUndefined
 */
export const createFormData = (
  payload: { [key: string]: any },
  skipUndefined = false,
): FormData => {
  const formData = new FormData();

  Object.entries(payload).forEach(([key, value]) => {
    if (skipUndefined && value === undefined) return;

    formData.append(key, value);
  });

  return formData;
};
