/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import TimeLine from '../../../components/TimeLine';
import api from '../../../api';
import UserLogs from '../../../interfaces/userLogs.interface';
import {
  readAllFromQueryParams,
  readFromQueryString,
} from '../../../utils/queryParams';

export default ({ playListId }: { playListId: string }) => {
  // const { t } = useTranslation();
  const { search } = useLocation();
  // const user = useSelector((state: GlobalState) => state.userData.user);
  const [usersLogs, setUserLogs] = useState<UserLogs[]>();
  const [totalItems, setTotalItems] = useState<number>();
  const page = readFromQueryString<string>(search, 'page');

  const [loading, setIsLoading] = useState(false);

  const usersLogsApi = async () => {
    try {
      const getUserLogsData = await api.usersLogs.getUsersLog({
        playlistId: playListId,
        ...readAllFromQueryParams(search),
        limit: '10',
      });
      setUserLogs(getUserLogsData.items);
      setTotalItems(getUserLogsData.meta.totalItems);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    usersLogsApi();
  }, [search]);

  return (
    <>
      <TimeLine
        usersLogs={usersLogs}
        loading={loading}
        totalItems={totalItems}
        hidePlaylistName
      />
    </>
  );
};
