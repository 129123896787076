import postUserIntegrationRequestData from '../../../api/endpoints/userData/interfaces/postUserIntegrationRequestData.interface';
import Integration from '../../../interfaces/integration.interface';
import LoadingAction from '../loadingAction.interface';
import UserDataActions from './userDataAction.enum';

export const getUserData = () => ({
  type: UserDataActions.GET_USER_DATA,
});

export const saveUserData = (payload: any) => ({
  type: UserDataActions.GET_USER_DATA_SUCCESS,
  payload,
});

export const saveUserIntegrationData = (payload: Integration[]) => ({
  type: UserDataActions.UPDATE_USER_INTEGRATION_SUCCESS,
  payload,
});

export const updateUserData = (payload: {
  phoneLoc: string;
  languageLoc: string;
  picture: any;
  lastNameLoc: string;
  numberOfScreens: string;
  firstNameLoc: string;
}) => ({
  type: UserDataActions.UPDATE_USER_DATA_REQUEST,
  payload,
});

export const updateIntegration = (payload: postUserIntegrationRequestData) => ({
  type: UserDataActions.UPDATE_USER_INTEGRATION_REQUEST,
  payload,
});

export const setUserLoading = (
  payload: LoadingAction['payload'],
): LoadingAction => ({
  type: UserDataActions.SET_USER_LOADING,
  payload,
});
