import React from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { UserRole } from '../../../../enums/userRole.enum';
import { prepareHookFromState } from '../../../../utils/hooks/useStateWithHookForm';
import SelectUserGroups from '../../../../containers/UsersGroups/SelectUserGroups';
import { useUserRoleOptions } from '../../../../utils/hooks/useUserRoleOptions';
import { rolesTranslation } from '../../../../constants/translation/users';
import AddUserSteps from '../../../../enums/steps/addUserSteps.enum';
import SelectLocation from '../../../../containers/SelectLocation';
import User from '../../../../interfaces/user/user.interface';
import UploadProfilePhoto from '../../../UploadProfilePhoto';
import FormErrorMessage from '../../../FormErrorMessage';
import validation from '../../../../utils/validation';
import Select from '../../../Select/RegularSelect';
import TextInput from '../../../TextInput';
import StepsTitle from '../StepsTitle';
import Modal from '../../../Modal';
import CreateUserRequestData from '../../../../api/endpoints/users/interfaces/createUserRequestData.interface';
import SelectCompanies from '../../../../containers/Companies/SelectCompanies';
import SelectLocations from '../../../../containers/SelectLocations';
import SelectCompany from '../../../../containers/Companies/SelectCompany';
import CheckBox from '../../../CheckBox';
import devicesCount from '../../../../constants/devicesCount';
import GlobalState from '../../../../store/reducers/globalState.interface';

type FieldsData = Partial<CreateUserRequestData>;

interface AssignProps {
  onSubmit?: (data: Partial<CreateUserRequestData>) => void;
  setMode: (mode: AddUserSteps) => void;
  data: Partial<CreateUserRequestData>;
  setData: (newData: Partial<CreateUserRequestData>) => void;
  editData?: { profilePicture: string; roles: UserRole[] };
  validationSchema: { [key: string]: any };
  currentUser?: User;
}

interface DefaultProps extends AssignProps {
  onSubmit: Required<AssignProps>['onSubmit'];
}

const Info = ({
  onSubmit,
  setMode,
  data,
  setData,
  editData,
  validationSchema,
  currentUser,
}: DefaultProps) => {
  const { t } = useTranslation();
  const { roles, email: connectedEmail } = useSelector(
    (state: GlobalState) => state.userData.user,
  );
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FieldsData>({
    resolver: yupResolver(validation.object(validationSchema).required()),
    defaultValues: data,
  });

  const userRole = editData?.roles && editData?.roles[0];
  const isAccountOwner = userRole === UserRole.AccountOwner;

  const useFs = prepareHookFromState<FieldsData>(setValue, trigger, data);
  const [firstName, setFirstName] = useFs<FieldsData['firstName']>(
    'firstName',
    '',
  );

  const [lastName, setLastName] = useFs<FieldsData['lastName']>('lastName', '');
  const [email, setEmail] = useFs<FieldsData['email']>('email', '');
  const [phoneNumber, setPhoneNumber] = useFs<FieldsData['phoneNumber']>(
    'phoneNumber',
    '',
  );
  const [role, setRole] = useFs<FieldsData['role']>('role');
  // const [isTrial, setTrial] = useFs<FieldsData['isTrial']>('isTrial', false);

  const [numberOfScreensLoc, setScreens] = useFs<FieldsData['numberOfScreens']>(
    'numberOfScreens',
    '',
  );

  const [isTest, setIsTest] = useFs<FieldsData['isTest']>(
    'isTest',
    currentUser?.isTest,
  );

  // hardcode for now
  const isADSEmail = () => {
    // if this is dev, return false
    if (process.env.REACT_APP_ENV === 'dev') return false;

    const emailDomain = connectedEmail.split('@')[1];
    const emailForm = email?.split('@')[1];

    if (emailDomain === 'adspipe.com' || emailForm === 'adspipe.com')
      return false;
    return true;
  };

  const [resetPassword, setResetPassword] = useFs<FieldsData['resetPassword']>(
    'resetPassword',
    isADSEmail(),
  );

  // const [locationId, setLocationId] = useFs<
  //   FieldsData['locationId'] | undefined
  // >('locationId', '');
  const [groupIds, setUserGroupIds] = useFs<
    FieldsData['userGroupIds'] | undefined
  >('userGroupIds');
  const [profilePicture, setProfilePicture] = useFs<
    FieldsData['profilePicture'] | undefined
  >('profilePicture');
  const [assignedCompanyIds, setAssignedCompanyIds] =
    useFs<FieldsData['assignedCompanyIds']>('assignedCompanyIds');
  const [assignedLocationIds, setAssignedLocationIds] = useFs<
    FieldsData['assignedLocationIds']
  >('assignedLocationIds');
  /**
   * Submits the step data and switches to the next step
   * @param inputsData
   */
  const handleSubmitData = (inputsData: FieldsData) => {
    if (isAccountOwner) {
      onSubmit({ ...data, ...inputsData });
      return;
    }
    setData({ ...data, ...inputsData });
    setMode(AddUserSteps.Assign);
  };
  React.useEffect(
    () => setScreens(currentUser?.numberOfScreens),
    [currentUser?.numberOfScreens],
  );

  const handleScreensChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    setScreens(value);
  };

  const roless = useUserRoleOptions(t, currentUser, !!editData);

  React.useEffect(() => {
    if (isADSEmail() === false) setResetPassword(false);
    else {
      setResetPassword(true);
    }
  }, [email]);
  return (
    <div className="w-100">
      {!isAccountOwner && <StepsTitle currentStep={AddUserSteps.Info} />}
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <div className="row mt-10">
          <div className="col-6 col-sm-6 ">
            <UploadProfilePhoto
              name="profilePicture"
              title={t('users.create_user.upload_photo')}
              setPic={setProfilePicture}
              oldPhoto={
                profilePicture
                  ? URL.createObjectURL(profilePicture)
                  : editData?.profilePicture
              }
              className="mb-6"
            />
            {!isAccountOwner && (
              <>
                <p className="mb-0">
                  <label htmlFor="role" className="form-label fw-bold pt-1">
                    {t('common.role')}:
                  </label>
                  <Select<UserRole>
                    id="role"
                    defaultValue={
                      role && {
                        label: t(rolesTranslation[role]),
                        value: role,
                      }
                    }
                    placeholder={t('users.common.select_role')}
                    onSelect={(selectedRole) =>
                      setRole(selectedRole as UserRole)
                    }
                    options={roless}
                  />
                  <FormErrorMessage
                    name="role"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </p>
                <p className="mb-0">
                  <label
                    htmlFor="userGroupIds"
                    className="form-label fw-bold pt-1"
                  >
                    {t('users_groups.user_group')}:
                  </label>
                  <SelectUserGroups
                    initialValue={groupIds}
                    onChange={(values) => {
                      if (!values.find(({ value }) => value === 'loadingValue'))
                        setUserGroupIds(
                          values.map(({ value }) => value as string),
                        );
                    }}
                    companyId={assignedCompanyIds?.[0]}
                    locationId={assignedLocationIds?.[0]}
                    id="userGroupIds"
                    currentUser={currentUser}
                  />
                  <FormErrorMessage
                    name="userGroupIds"
                    errors={errors}
                    className="mt-1 min-h-20px"
                  />
                </p>
                {!currentUser && (
                  <div className="row">
                    <div className="col-md-9">
                      <label htmlFor="resetPassword" className="text-dark my-1">
                        Send Reset Password:
                      </label>
                    </div>
                    <div className="col-md-3 ">
                      <CheckBox
                        name="resetPassword"
                        onChange={() => setResetPassword(!resetPassword)}
                        checked={resetPassword}
                        className="mt-1 float-end"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="col-6 col-sm-6">
            <div className="mb-0">
              <label
                htmlFor="firstName"
                className="col-form-label text-lg-end pt-1"
              >
                {t('users.common.first_name')}
              </label>
              <TextInput
                name="firstName"
                id="firstName"
                placeholder={t('users.common.first_name')}
                value={firstName}
                onChange={setFirstName}
              />
              <FormErrorMessage
                name="firstName"
                errors={errors}
                className="mt-1 min-h-20px"
              />
            </div>
            <div className="mb-0">
              <label
                htmlFor="lastName"
                className="col-form-label text-lg-end pt-1"
              >
                {t('users.common.last_name')}
              </label>
              <TextInput
                name="lastName"
                id="lastName"
                placeholder={t('users.common.last_name')}
                value={lastName}
                onChange={setLastName}
              />
              <FormErrorMessage
                name="lastName"
                errors={errors}
                className="mt-1 min-h-20px"
              />
            </div>
            <div className="mb-0">
              <label
                htmlFor="email"
                className="col-form-label text-lg-end pt-1"
              >
                {t('users.common.email')}
              </label>
              <TextInput
                name="email"
                type="email"
                id="email"
                placeholder={t('users.common.email')}
                value={email}
                onChange={setEmail}
              />
              <FormErrorMessage
                name="email"
                errors={errors}
                className="mt-1 min-h-20px"
              />
            </div>
            <div className="mb-0">
              <label
                htmlFor="phone"
                className="col-form-label text-lg-end pt-1"
              >
                {t('users.common.phone')}
              </label>
              <TextInput
                name="phoneNumber"
                id="phone"
                placeholder={t('users.common.phone')}
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
              <FormErrorMessage
                name="phoneNumber"
                errors={errors}
                className="mt-1 min-h-20px"
              />
            </div>
            {data.isTrial ? (
              <div className="mb-0">
                <label
                  htmlFor="count"
                  className="col-form-label text text-lg-start"
                >
                  {t('complete_profile.labels.device_count')}
                </label>
                <select
                  id="devices"
                  value={numberOfScreensLoc ?? ''}
                  onChange={handleScreensChange}
                  name="devices"
                  className="form-control bg-transparent d-block"
                >
                  <option value="" selected disabled>
                    -
                  </option>
                  {devicesCount.map((i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <></>
            )}
            <div className="mb-1">
              <label
                htmlFor="company"
                className="col-form-label text-lg-end pt-1"
              >
                {t('users.common.company')}
              </label>
              {isAccountOwner ? (
                <SelectCompanies
                  onChange={(values) => {
                    if (!values.find(({ value }) => value === 'loadingValue'))
                      setAssignedCompanyIds(map(values, 'value') as string[]);
                  }}
                  initialValue={assignedCompanyIds}
                  currentUser={currentUser}
                  preselect
                />
              ) : (
                <SelectCompany
                  id="company"
                  placeholder={t('users.common.select_company')}
                  onChange={({ value }) => {
                    if (value !== 'loadingValue')
                      setAssignedCompanyIds(value ? [value] : []);
                  }}
                  initialValue={assignedCompanyIds?.[0]}
                  currentUser={currentUser}
                  isClearable
                />
              )}
            </div>
            <div className="mb-2">
              <label
                htmlFor="locationId"
                className="col-form-label text-lg-end pt-1"
              >
                {t('users.common.location')}
              </label>
              {isAccountOwner ? (
                <SelectLocations
                  onChange={(values) => {
                    if (!values.find(({ value }) => value === 'loadingValue'))
                      setAssignedLocationIds(map(values, 'value') as string[]);
                  }}
                  initialValue={assignedLocationIds}
                  companyIds={assignedCompanyIds}
                  showDefaultOptions={false}
                />
              ) : (
                <SelectLocation
                  id="locationId"
                  onChange={({ value }) => {
                    if (value !== 'loadingValue')
                      setAssignedLocationIds(value ? [value] : []);
                  }}
                  initialValue={assignedLocationIds?.[0]}
                  isClearable
                  companyId={assignedCompanyIds?.[0]}
                  showDefaultOptions={false}
                />
              )}
            </div>
            {/* <div className="mb-2">
              <div className="d-sm-flex align-items-center me-6 ms-1">
                <CheckBox
                  name="isActive"
                  onChange={() => setTrial(!isTrial)}
                  checked={isTrial}
                  className="mt-1"
                />
                <label
                  htmlFor="isActive"
                  className="col-form-label text-lg-end me-3"
                >
                  {t('users.common.trial_user')}
                </label>
              </div>
              <FormErrorMessage
                name="isTrial"
                errors={errors}
                className="mt-1 min-h-20px"
              />
            </div> */}

            {roles[0] === UserRole.SuperAdmin && (
              <div>
                <div className="d-sm-flex align-items-center me-6 ms-1">
                  <CheckBox
                    name="isTest"
                    onChange={() => setIsTest(!isTest)}
                    checked={isTest}
                    className="mt-1"
                  />
                  <label
                    htmlFor="isTest"
                    className="col-form-label text-lg-end me-3"
                  >
                    {t('users.common.test_data')}
                  </label>
                </div>
                <FormErrorMessage
                  name="isTesr"
                  errors={errors}
                  className="mt-1 min-h-20px"
                />
              </div>
            )}
          </div>
        </div>
        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-sm-flex justify-content-end">
          <button type="submit" className="btn btn-primary">
            {isAccountOwner ? t('users.update') : t('common.next_step')}
          </button>
        </div>
      </form>
    </div>
  );
};

Info.defaultProps = {
  onSubmit: () => null,
};

export default Info;
