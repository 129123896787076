import axios from 'axios';
import { API_URL } from '../../constants/api';
import LocationsRoutes from '../endpoints/locations/enums/locationsRoutes.enum';
import getLocationsResponseData from './mocks/getLocationsResponseData';

const mockData: { [key: string]: { [key: string]: any } } = {
  [LocationsRoutes.Locations]: {
    get: {
      data: getLocationsResponseData,
      status: 200,
      statusText: 'OK',
      mockResponseDelay: 1000,
    },
  },
};

/**
 * Intercepts the error responses which are match the config
 * and replaces it with mock successful responses
 */
export default () => {
  axios.interceptors.response.use(undefined, (error) => {
    const { config }: { config: { url: string; method: string } } = error;

    if (config) {
      const mockedResponseConfig =
        mockData[
          config.url.replace(`${API_URL}/`, '').replace(/\/\d+/g, '/:id')
        ];
      const mockedResponseData =
        mockedResponseConfig && mockedResponseConfig[config.method];

      if (mockedResponseData) {
        const mockResponseDelay = mockedResponseData.mockResponseDelay ?? 0;

        delete mockedResponseData.mockResponseDelay;

        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              isMock: true,
              headers: {},
              config,
              ...mockedResponseData,
            });
          }, mockResponseDelay);
        });
      }
    }

    return Promise.reject(error);
  });
};
