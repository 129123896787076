import React from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { KTSVG } from '../../../../lib/metronic/helpers';

const linkClassNames = 'menu-link without-sub';

type Props = {
  title: string;
  to?: string;
  icon?: string;
  image?: string;
  isHide?: boolean;
  hasBullet?: boolean;
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  onKeypress?: (event: React.KeyboardEvent) => void;
  lowerMargin?: boolean;
  isLocked?: boolean;
};

export default ({
  children,
  to,
  title,
  icon,
  image,
  isHide = false,
  hasBullet = false,
  className,
  onClick,
  onKeypress,
  lowerMargin = true,
  isLocked = false,
}: Props) => {
  const location = useLocation();

  function getActiveClass(route?: string): string {
    const currentRoute = location.pathname;
    if (route && currentRoute.indexOf(route) !== -1) {
      return 'active';
    }
    return '';
  }

  const content = (
    <>
      {hasBullet && (
        <span className="menu-bullet">
          <span className="bullet bullet-dot" />
        </span>
      )}

      {icon && (
        <span className="menu-icon">
          <KTSVG path={icon} className="svg-icon-1 w-90px h-43px" />
        </span>
      )}

      {image && (
        <span className="menu-icon">
          <img className="m-1 w-15px h-18px" src={image} alt="metronic" />
        </span>
      )}
      <span className="menu-title">{title}</span>
    </>
  );
  if (isHide) return null;

  return (
    <div
      role="link"
      tabIndex={-1}
      className={clsx(
        'menu-item',
        lowerMargin ? 'mb-4' : '',
        className,
        getActiveClass(to),
      )}
      onClick={onClick}
      onKeyPress={onKeypress}
    >
      {to ? (
        <Link className={linkClassNames} to={to}>
          {content}
          {isLocked ? <i className="bi bi-lock-fill" /> : null}
        </Link>
      ) : (
        <span className={linkClassNames}>{content}</span>
      )}

      {children}
    </div>
  );
};
