import React from 'react';
import { useSelector } from 'react-redux';
import GlobalState from '../../store/reducers/globalState.interface';
import LogTable from './LogTable/index';
import ProfileTable from './ProfileTable/index';
import MyTimeline from './MyTimeline';

export default () => {
  const { loginSessions } = useSelector(
    (state: GlobalState) => state.userData.user,
  );

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          {' '}
          <ProfileTable />{' '}
        </div>
        <div className="col">
          {' '}
          <MyTimeline />{' '}
        </div>
      </div>

      <div className="row">
        <LogTable loginsessions={loginSessions} />
      </div>
      {/* <div className="d-flex flex-wrap"></div> */}
    </div>
  );
};
