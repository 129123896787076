import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GlobalState from '../../../../store/reducers/globalState.interface';
import WithConfirm from '../../../WithConfirm';

export default ({
  connected = false,
  powerBiEmail = '',
}: {
  connected?: boolean;
  powerBiEmail?: string;
}) => {
  const user = useSelector((state: GlobalState) => state.userData.user);
  const { t } = useTranslation();

  const connectURL = `${process.env.REACT_APP_POWER_API_URL}/signup/${user.id}`;
  const disconnectURL = `${process.env.REACT_APP_POWER_API_URL}/signoutUser/${user.id}`;

  const disconnect = () => {
    // handle disconnect
    // redirect to disconnectURL
    window.location.href = disconnectURL;
  };

  const styles = {
    marginLeft: '15px',
  };

  if (connected) {
    return (
      <>
        <span>
          <i className="fas fa-check-circle text-success me-2" />
          {powerBiEmail}
        </span>
        <WithConfirm
          body={t('apps.powerBi.powerBi_disconnect_confirmation.body')}
          handleConfirm={disconnect}
          confirmButtonText={t(
            'apps.powerBi.powerBi_disconnect_confirmation.disconnect',
          )}
          discardButtonText={t(
            'apps.powerBi.powerBi_disconnect_confirmation.decline',
          )}
        >
          <button type="button" style={styles} className="btn btn-primary">
            {t('apps.powerBi.powerBi_disconnect_confirmation.disconnect')}
          </button>
        </WithConfirm>
      </>
    );
  }
  return (
    <a
      href={connectURL}
      target="_blank"
      rel="noreferrer"
      style={styles}
      className="btn btn-primary"
    >
      {t('common.connect')}
    </a>
  );
};
