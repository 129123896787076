import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  readAllFromQueryParams,
  readFromQueryString,
} from '../../../utils/queryParams';
import api from '../../../api';
import IPlaylist from '../../../interfaces/playlist.interface';
import PlaylistsTable from '../../../components/Playlists';
import { SelectedValue } from '../../../components/Select/SelectAsync';
import UpdatePlaylistRequestData from '../../../api/endpoints/playlists/interfaces/updatePlaylistRequestData.interface';
import GlobalState from '../../../store/reducers/globalState.interface';
import { UserRole } from '../../../enums/userRole.enum';

export default ({
  toolbarElement,
  disableTitle,
}: {
  toolbarElement?: React.ReactNode;
  disableTitle?: boolean;
}) => {
  const [playlists, setPlaylists] = useState<IPlaylist[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [playlistLoading, setPlaylistLoading] = useState<boolean>(true);

  const { search } = useLocation();
  const { id } = useParams<{ id: string }>();
  const roles = useSelector((state: GlobalState) => state.userData.user.roles);
  const isSuperAdmin = roles.includes(UserRole.SuperAdmin);
  const currentUserId = readFromQueryString(search, 'currentUserId') || '';
  const getDeviceGroupPlaylists = async (recentSearch: string) => {
    try {
      setPlaylistLoading(true);
      const { items, meta } =
        !isSuperAdmin && !currentUserId
          ? await api.devicesGroups.getDeviceGroupPlaylists(
              readAllFromQueryParams(recentSearch),
              id,
            )
          : await api.devicesGroups.getDeviceGroupPlaylistsByCurrentUser(
              readAllFromQueryParams(recentSearch),
              id,
            );
      setPlaylists(items);
      setTotal(meta.totalItems);
    } catch (e) {
      // Do nothing for now
    }
    setPlaylistLoading(false);
  };

  useEffect(() => {
    setPlaylistLoading(true);
    getDeviceGroupPlaylists(search);
  }, [search]);

  const handleAssignPlaylistsToDeviceGroup = async (data: SelectedValue[]) => {
    try {
      setPlaylistLoading(true);

      const playlistIds = data.map(({ value }) => value as string);
      await api.devicesGroups.assignPlaylistsToGroup(playlistIds, id);

      getDeviceGroupPlaylists(search);
    } catch (e) {
      // Do nothing for now
    }
    setPlaylistLoading(false);
  };

  const handleUnAssignPlaylistsFromUserGroup = async (
    playlistIds: string,
    deviceGroupId: string,
  ) => {
    try {
      setPlaylistLoading(true);

      await api.devicesGroups.unAssignPlaylistsFromToGroup(
        playlistIds,
        deviceGroupId,
      );
      setPlaylists(playlists.filter((playlist) => playlistIds !== playlist.id));
    } catch (e) {
      // Do nothing for now
    }
    setPlaylistLoading(false);
  };

  const handleEditPlaylistInDeviceGroup = async (
    playlistId: string,
    data: UpdatePlaylistRequestData,
  ) =>
    setPlaylists(
      playlists.map((playlist) =>
        playlist.id === playlistId ? { ...playlist, ...data } : playlist,
      ),
    );

  return (
    <PlaylistsTable
      onUpdate={() => getDeviceGroupPlaylists(search)}
      playlists={playlists}
      total={total}
      isLoading={playlistLoading}
      assignPlaylistToDeviceGroup={handleAssignPlaylistsToDeviceGroup}
      unAssignPlaylistFromDeviceGroup={handleUnAssignPlaylistsFromUserGroup}
      handleEditPlaylistInDeviceGroup={handleEditPlaylistInDeviceGroup}
      toolbarElement={toolbarElement}
      disableTitle={disableTitle}
    />
  );
};
