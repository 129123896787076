/* eslint-disable no-nested-ternary */
import { debounce } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import ScheduleContentRequestData from '../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import PublishModal from '../../../components/PublishModal';
import Pagination from '../../../components/Pagination';
import Search from '../../../components/Search';
import { KTSVG } from '../../../lib/metronic/helpers';
import {
  scheduleHTMLFeedActionCreator,
  deleteHTMLFeedActionCreator,
  editHTMLFeedActionCreator,
  getHTMLFeedsActionCreator,
  scheduleHTMLFeedResetActionCreator,
  editHTMLFeedResetActionCreator,
  deleteHTMLFeedResetActionCreator,
  createHTMLFeedActionCreator,
  createScheduleHTMLFeedResetActionCreator,
  createHTMLFeedResetActionCreator,
} from '../../../store/actions/apps';
import GlobalState from '../../../store/reducers/globalState.interface';
import { SortBy, SortOrder } from '../../../store/types/apps/globalTypes';
import {
  CreateHTMLFeedRequest,
  EditHTMLFeedRequest,
  GetHTMLFeedsRequest,
  HTMLFeedSchema,
  ScheduleHTMLFeedRequest,
} from '../../../store/types/apps/htmlFeed';
import CreateFeedModal from './CreateFeedModal';
import DeleteFeedModal from './DeleteFeedModal';
import EditFeedModal from './EditFeedModal';
import ErrorFeeds from './ErrorFeeds';
import FeedsTable from './FeedsTable';
import PreviewFeedModal from './PreviewFeedModal';
import Yup from '../../../utils/validation';

const HTMLAppContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    loading: htmlFeedsLoading,
    deleteHTMLFeedLoading,
    editHTMLFeedLoading,
    data: paginatedHTMLFeeds,
    error,
    isGetHTMLFeedsSuccess,
    isCreateHTMLFeedSuccess,
    isScheduleHTMLFeedSuccess,
    isDeleteHTMLFeedSuccess,
    isEditHTMLFeedSuccess,
    isCreateScheduleHTMLFeedSuccess,
  } = useSelector((state: GlobalState) => state.htmlFeeds);

  const [searchValue, setSearchValue] = React.useState<string>('');

  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [isScheduleandCreateSuccess, setIsScheduleandCreateSuccess] =
    React.useState<boolean>(false);

  const [getHTMLFeedsRequest, setGetHTMLFeedsRequest] =
    React.useState<GetHTMLFeedsRequest>({
      params: {
        search: searchValue,
        limit: 10,
        page: paginatedHTMLFeeds.meta.currentPage,
        appType: 'html',
        sortOrder: SortOrder.ASC,
        sortBy: SortBy.CREATED_AT,
      },
    });

  const { params } = getHTMLFeedsRequest;

  const [selectedHTMLFeedSchema, setSelectedHTMLFeedSchema] =
    React.useState<HTMLFeedSchema | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [showFilterModal, setShowFilterModal] = React.useState<boolean>(false);

  const [showCreateFeedModal, setShowCreateFeedModal] =
    React.useState<boolean>(false);

  const [showPreviewHTMLFeedModal, setShowPreviewHTMLFeedModal] =
    React.useState<boolean>(false);

  const [showEditHTMLFeedModal, setShowEditHTMLFeedModal] =
    React.useState<boolean>(false);

  const [showDeleteHTMLFeedModal, setShowDeleteHTMLFeedModal] =
    React.useState<boolean>(false);

  const [showScheduleHTMLFeedModal, setShowScheduleHTMLFeedModal] =
    React.useState<boolean>(false);
  // callbacks/handlers

  // // filter feeds modal handler
  // const handleOpenFilterModal = React.useCallback(
  //   () => setShowFilterModal(true),
  //   [],
  // );

  // // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const handleCloseFilterModal = React.useCallback(
  //   () => setShowFilterModal(false),
  //   [],
  // );

  // create feed modal handler
  const handleOpenCreateFeedModal = React.useCallback(
    () => setShowCreateFeedModal(true),
    [],
  );

  const handleCloseCreateFeedModal = React.useCallback(
    () => setShowCreateFeedModal(false),
    [],
  );

  // HTML feed preview modal handler
  const handleOpenPreviewHTMLFeedModal = React.useCallback(
    (feed: HTMLFeedSchema) => {
      setSelectedHTMLFeedSchema(feed);
      setShowPreviewHTMLFeedModal(true);
    },
    [],
  );

  const handleClosePreviewHTMLFeedModal = React.useCallback(() => {
    setSelectedHTMLFeedSchema(null);
    setShowPreviewHTMLFeedModal(false);
  }, []);

  // edit HTML feed modal handler
  const handleOpenEditHTMLFeedModal = React.useCallback(
    (feed: HTMLFeedSchema) => {
      setSelectedHTMLFeedSchema(feed);
      setShowEditHTMLFeedModal(true);
    },
    [],
  );

  const handleCloseEditHTMLFeedModal = React.useCallback(() => {
    setSelectedHTMLFeedSchema(null);
    setShowEditHTMLFeedModal(false);
  }, []);

  // delete HTML feed modal handler
  const handleOpenDeleteHTMLFeedModal = React.useCallback(
    (feed: HTMLFeedSchema) => {
      setSelectedHTMLFeedSchema(feed);
      setShowDeleteHTMLFeedModal(true);
    },
    [],
  );

  const handleCloseDeleteHTMLFeedModal = React.useCallback(() => {
    setSelectedHTMLFeedSchema(null);
    setShowDeleteHTMLFeedModal(false);
  }, []);

  // schedule HTML feed modal handler
  const handleOpenScheduleHTMLFeedModal = React.useCallback(
    (feed: HTMLFeedSchema) => {
      setSelectedHTMLFeedSchema(feed);
      setShowScheduleHTMLFeedModal(true);
    },
    [],
  );

  const handleCloseScheduleHTMLFeedModal = React.useCallback(() => {
    setSelectedHTMLFeedSchema(null);
    setShowScheduleHTMLFeedModal(false);
  }, []);

  const handleOpenActionsDropdown = React.useCallback(
    (feed: HTMLFeedSchema) => setSelectedHTMLFeedSchema(feed),
    [],
  );

  const handleSearchValueChange = React.useCallback((value: string) => {
    setSearchValue(value);
    setIsSearching(true);
  }, []);

  const debounceOnLastSearchValue = React.useMemo(
    () =>
      debounce((value) => {
        setGetHTMLFeedsRequest({
          ...getHTMLFeedsRequest,
          params: { ...params, search: value },
        });
      }, 1000),
    [getHTMLFeedsRequest, params],
  );

  const handleOnPageNumberUpdate = React.useCallback(
    (pageNumber: number) =>
      setGetHTMLFeedsRequest({
        ...getHTMLFeedsRequest,
        params: { ...params, page: pageNumber },
      }),
    [getHTMLFeedsRequest, params],
  );

  const handleCreateFeed = React.useCallback(
    (createHTMLFeedRequest: CreateHTMLFeedRequest) => {
      dispatch(createHTMLFeedActionCreator(createHTMLFeedRequest));
    },
    [dispatch],
  );

  const handleScheduleHTMLFeed = React.useCallback(
    (data: ScheduleContentRequestData) => {
      const {
        zoneId,
        duration,
        isOrdered,
        tags,
        startDate,
        endDate,
        weekDays,
        isLocked,
        playlistIds,
      } = data;

      const request: ScheduleHTMLFeedRequest = {
        appId: (selectedHTMLFeedSchema as HTMLFeedSchema).id,
        appType: params.appType,
        name: (selectedHTMLFeedSchema as HTMLFeedSchema).name,
        startDate: startDate && startDate.split('T')[0],
        endDate: endDate && endDate.split('T')[0],
        duration,
        zoneId,
      };

      if (isLocked) {
        request.isLocked = isLocked;
      }

      if (isOrdered) {
        request.isOrdered = isOrdered;
      }

      if (tags) {
        request.tags = tags;
      }

      if (playlistIds) {
        request.playlistIds = playlistIds;
      }

      if (weekDays) {
        request.weekDays = weekDays;
      }

      dispatch(scheduleHTMLFeedActionCreator(request));
    },
    [dispatch, params.appType, selectedHTMLFeedSchema],
  );

  const handleDeleteHTMLFeed = React.useCallback(
    ({ id }: HTMLFeedSchema) => dispatch(deleteHTMLFeedActionCreator({ id })),
    [dispatch],
  );

  const handleEditHTMLFeed = React.useCallback(
    (editHTMLFeedRequest: EditHTMLFeedRequest) =>
      dispatch(editHTMLFeedActionCreator(editHTMLFeedRequest)),
    [dispatch],
  );

  const handleClickOnHTMLFeed = React.useCallback(
    (feed: HTMLFeedSchema) => handleOpenPreviewHTMLFeedModal(feed),
    [handleOpenPreviewHTMLFeedModal],
  );

  // effects
  React.useEffect(() => {
    if (isSearching) {
      debounceOnLastSearchValue(searchValue);
      setIsSearching(false);
    }
  }, [debounceOnLastSearchValue, isSearching, searchValue]);

  React.useEffect(() => {
    dispatch(getHTMLFeedsActionCreator(getHTMLFeedsRequest));
  }, [dispatch, getHTMLFeedsRequest, params]);

  React.useEffect(() => {
    if (isCreateHTMLFeedSuccess) {
      if (!isScheduleandCreateSuccess) {
        toast.success(t('apps.html5.feedCreated'));
        handleCloseCreateFeedModal();
      }
      setIsScheduleandCreateSuccess(false);
      dispatch(getHTMLFeedsActionCreator(getHTMLFeedsRequest));

      dispatch(
        createHTMLFeedResetActionCreator({
          loading: false,
          isCreateHTMLFeedSuccess: false,
          error: null,
        }),
      );
    }
    if (isCreateScheduleHTMLFeedSuccess) {
      toast.success(
        i18next.t<string>('apps.html5.feed_created_and_scheduled'),
        {
          position: 'bottom-center',
          autoClose: 3000,
          hideProgressBar: true,
        },
      );

      dispatch(getHTMLFeedsActionCreator(getHTMLFeedsRequest));

      handleCloseCreateFeedModal();

      dispatch(
        createScheduleHTMLFeedResetActionCreator({
          loading: false,
          isCreateScheduleHTMLFeedSuccess: false,
          error: null,
        }),
      );
    }

    if (isScheduleHTMLFeedSuccess) {
      toast.success(t('apps.html5.feedScheduled'));
      handleCloseScheduleHTMLFeedModal();

      dispatch(
        scheduleHTMLFeedResetActionCreator({
          loading: false,
          isScheduleHTMLFeedSuccess: false,
          error: null,
        }),
      );
    }

    if (isEditHTMLFeedSuccess) {
      toast.success(t('apps.html5.feedEdited'));
      dispatch(getHTMLFeedsActionCreator(getHTMLFeedsRequest));

      handleCloseEditHTMLFeedModal();

      dispatch(
        editHTMLFeedResetActionCreator({
          loading: false,
          isEditHTMLFeedSuccess: false,
          error: null,
        }),
      );
    }

    if (isDeleteHTMLFeedSuccess) {
      toast.success(t('apps.html5.feedDeleted'));

      dispatch(getHTMLFeedsActionCreator(getHTMLFeedsRequest));

      handleCloseDeleteHTMLFeedModal();

      dispatch(
        deleteHTMLFeedResetActionCreator({
          loading: false,
          isDeleteHTMLFeedSuccess: false,
          error: null,
        }),
      );
    }
  }, [
    dispatch,
    getHTMLFeedsRequest,
    handleCloseCreateFeedModal,
    handleCloseDeleteHTMLFeedModal,
    handleCloseEditHTMLFeedModal,
    handleCloseScheduleHTMLFeedModal,
    isCreateHTMLFeedSuccess,
    isDeleteHTMLFeedSuccess,
    isEditHTMLFeedSuccess,
    isScheduleHTMLFeedSuccess,
    isCreateScheduleHTMLFeedSuccess,
  ]);

  return (
    <div>
      <div className="bg-white d-flex flex-column p-6">
        <div className="bg-white d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mb-4 w-100">
          <Search
            onChange={handleSearchValueChange}
            placeholder="Search"
            className="override-input-search-field-width-file-manager"
            searchValue={searchValue}
            wrapperClassName="mx-md-2"
          />

          <div className="d-flex flex-column flex-sm-row">
            {/* <button */}
            {/*  type="button" */}
            {/*  className="btn btn-light-primary d-flex flex-center" */}
            {/*  onClick={handleOpenFilterModal} */}
            {/* > */}
            {/*  <KTSVG */}
            {/*    path="/media/icons/duotune/general/gen031.svg" */}
            {/*    className="svg-icon-2" */}
            {/*  /> */}
            {/*  <span>Filter</span> */}
            {/* </button> */}
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleOpenCreateFeedModal}
            >
              <KTSVG
                path="/media/icons/duotune/files/fil013.svg"
                className="svg-icon-2"
              />
              <span>{t('apps.html5.createNewhtml5')}</span>
            </button>
          </div>
        </div>

        <div className="bg-white d-flex flex-column w-100 flex-grow-1">
          {!isGetHTMLFeedsSuccess && error ? (
            <ErrorFeeds error={error} />
          ) : (
            <FeedsTable
              clickOnHTMLFeed={handleClickOnHTMLFeed}
              openEditHTMLFeedModal={handleOpenEditHTMLFeedModal}
              openDeleteHTMLFeedModal={handleOpenDeleteHTMLFeedModal}
              openActionsDropdown={handleOpenActionsDropdown}
              openScheduleHTMLFeedModal={handleOpenScheduleHTMLFeedModal}
              selectedHTMLFeed={selectedHTMLFeedSchema}
              isLoading={htmlFeedsLoading}
              paginatedHTMLFeeds={paginatedHTMLFeeds}
            />
          )}

          <Pagination
            total={paginatedHTMLFeeds.meta.totalItems}
            pageSize={paginatedHTMLFeeds.meta.itemsPerPage}
            pageNumber={paginatedHTMLFeeds.meta.currentPage}
            onPageNumberUpdate={handleOnPageNumberUpdate}
            className="mt-3 mb-5"
          />
        </div>
      </div>

      {/** Filter Modal */}
      {/* {showFilterModal ? <p>FilterModal</p> : null} */}

      {/** Create Feed Modal */}
      {showCreateFeedModal ? (
        <CreateFeedModal
          onSetIsScheduleandCreateSuccess={setIsScheduleandCreateSuccess}
          show={showCreateFeedModal}
          onSubmit={handleCreateFeed}
          onHide={handleCloseCreateFeedModal}
          title={t('apps.html5.createNewhtml5')}
        />
      ) : null}

      {/** HTML Feed Preview Modal */}
      {showPreviewHTMLFeedModal ? (
        <PreviewFeedModal
          show={showPreviewHTMLFeedModal}
          onHide={handleClosePreviewHTMLFeedModal}
          selectedHTMLFeedSchema={selectedHTMLFeedSchema as HTMLFeedSchema}
          title={t('apps.html5.previewNewhtml5')}
        />
      ) : null}

      {/** HTML Feed Edit Modal */}
      {showEditHTMLFeedModal ? (
        <EditFeedModal
          show={showEditHTMLFeedModal}
          onSubmit={handleEditHTMLFeed}
          onHide={handleCloseEditHTMLFeedModal}
          title={t('apps.html5.editFeed')}
          isLoading={editHTMLFeedLoading}
          selectedHTMLFeedSchema={selectedHTMLFeedSchema as HTMLFeedSchema}
        />
      ) : null}

      {/** HTML Feed Delete Modal */}
      {showDeleteHTMLFeedModal ? (
        <DeleteFeedModal
          show={showDeleteHTMLFeedModal}
          onHide={handleCloseDeleteHTMLFeedModal}
          title={t('apps.html5.deleteFeed')}
          onSubmit={handleDeleteHTMLFeed}
          selectedHTMLFeedSchema={selectedHTMLFeedSchema as HTMLFeedSchema}
          isLoading={deleteHTMLFeedLoading}
        />
      ) : null}

      {/** HTML Feed Schedule Modal */}
      {showScheduleHTMLFeedModal ? (
        <PublishModal
          onClose={handleCloseScheduleHTMLFeedModal}
          onSubmit={handleScheduleHTMLFeed}
          isVisible={showScheduleHTMLFeedModal}
          infoValidationSchema={{
            orientation: Yup.string().required(t('required')),
            duration: Yup.number().required(t('required')),
            startDate: Yup.date(),
            endDate: Yup.date().when('startDate', {
              is: (startDate: any) => !!startDate,
              then: Yup.date().min(
                Yup.ref('startDate'),
                t('common.validation.endDate'),
              ),
            }),
            isLocked: Yup.boolean(),
          }}
          skipOrder
          skipOrientationAuto
        />
      ) : null}
    </div>
  );
};

export default HTMLAppContainer;
