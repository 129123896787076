import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { Alert } from 'antd';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import logo from '../../../assets/images/logo/indigo_vizual_logo.png';

import '../SignIn';
import useStateWithHookForm from '../../../utils/hooks/useStateWithHookForm';
import validation from '../../../utils/validation';

import FormErrorMessage from '../../../components/FormErrorMessage';
import config from '../../../routing/config';
import SocialLogin from '../../../components/SocialLogin';
import CreateAccountPayload from '../../../api/endpoints/auth/interfaces/CreateAccountPayload.interface';
import {
  readFromQueryString,
  useReadFromQueryString,
} from '../../../utils/queryParams';
import { checkSignIn, webAuth0 } from '../../../utils/auth0';
import GlobalState from '../../../store/reducers/globalState.interface';
import WithSpinner from '../../../components/WithSpinner';
import api from '../../../api';

interface FormInputs {
  email: string;
  password: string;
  repeatPassword: string;
}

export default () => {
  const [viewPassword, setViewPassword] = useState<boolean>(false);

  const { search } = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGPCLocked, setIsGPCLocked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isNonceValid, setIsNonceValid] = useState<boolean | null>(null);
  const nonce = useReadFromQueryString(search, 'nonce');
  const canvaId = useReadFromQueryString(search, 'canvaId');

  const { data: validationResponse, error: validationError } = useQuery(
    ['validate', nonce],
    () => api.canva.validateNonce(nonce),
    {
      enabled: typeof nonce === 'string' && nonce.trim().length > 0,
    },
  );

  const user = useSelector((state: GlobalState) => {
    return state.userData.user;
  });

  // hardcode for now
  const isItAGPCEmail = (email: string) => {
    try {
      const emailDomain = email.split('@')[1];
      return emailDomain === 'genpt.com' || emailDomain === 'napastore.com';
    } catch (error) {
      return false;
    }
  };

  const validationSchema = validation
    .object({
      email: validation
        .string()
        .required(t('signup.email'))
        .email(t('signup.emailValid'))
        .defined(),
      password: validation.string().required(t('signup.password')).defined(),
    })
    .defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validationSchema),
    defaultValues: {},
  });

  const [email, setEmail] = useStateWithHookForm<Partial<FormInputs>, string>(
    { setValue, trigger, name: 'email' },
    '',
  );

  const handleEmailChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(value);

    if (isItAGPCEmail(value) && !isGPCLocked) {
      setIsGPCLocked(true);
    } else if (isGPCLocked) {
      setIsGPCLocked(false);
    }
  };

  const [password, setPassword] = useStateWithHookForm<
    Partial<FormInputs>,
    string
  >({ setValue, trigger, name: 'password' }, '');

  const handlePasswordChange = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(value);
  };

  const handleSubmitData = async (inputsData: Partial<FormInputs>) => {
    if (isGPCLocked) return;
    setIsLoading(true);

    const payload: CreateAccountPayload = {
      email: inputsData?.email ?? '',
      password: inputsData?.password ?? '',
    };

    const query = window.location.search;
    const state = readFromQueryString(query, 'state');

    const shouldParseResult =
      query.includes('canvaId=') && query.includes('state=');

    if (shouldParseResult) {
      try {
        webAuth0.login(
          {
            realm: 'Username-Password-Authentication',
            username: payload.email,
            password: payload.password,
            responseType: 'token id_token',
            scope: 'roles openid profile email',
            redirectUri: `${process.env.REACT_APP_URL}${config.login.route}?canvaId=${canvaId}&stateCanva=${state}&email=${email}&nonce=${nonce}`,
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          (err: any, authResult: any) => {
            setIsLoading(false);

            if (err) {
              console.log(err);
              toast.error(err.description);
            }
          },
        );
      } catch (error: any) {
        console.log(error);
        toast.error(error.description);
        setIsLoading(false);
      }
    }

    if (!shouldParseResult) {
      setIsLoading(false);
      toast.error('Login Failed!');
    }
  };

  const checkUserSignedIn = async () => {
    const isSignedIn = await checkSignIn();

    if (user?.email === '' && !isSignedIn) {
      setLoading(false);
    }
  };

  // as soon as we get in, we have to validate the nonce
  useEffect(() => {
    if (nonce) {
      if (validationError) {
        setIsNonceValid(false);
        // redirect to canva
      } else if (validationResponse) {
        if (validationResponse.valid === true) {
          setIsNonceValid(true);
        } else {
          setIsNonceValid(false);
          // redirect to canva
        }
      }
    }
  }, [nonce, validationResponse, validationError]);

  // redirect to Canva if nonce is invalid
  useEffect(() => {
    console.log('changed', { isNonceValid });
    if (isNonceValid === false) {
      const state = readFromQueryString(search, 'state') || '';
      const params = new URLSearchParams({
        success: 'false',
        state,
        errors: 'invalid_nonce',
      });

      window.location.href = `https://www.canva.com/apps/configured?${params.toString()}`;
    }
  }, [isNonceValid]);

  useEffect(() => {
    const state = readFromQueryString(search, 'state');
    // clean old values
    sessionStorage.removeItem('canvaId');
    sessionStorage.removeItem('state');
    sessionStorage.removeItem('nonce');

    if (canvaId && state && nonce) {
      sessionStorage.setItem('canvaId', canvaId as string);
      sessionStorage.setItem('state', state as string);
      sessionStorage.setItem('nonce', nonce as string);
    }

    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ call submit function here
        handleSubmit(handleSubmitData)();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    if (email && email !== '') sessionStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    checkUserSignedIn();
  }, [user]);

  if (isNonceValid === false) {
    return <div>Invalid Nonce</div>;
  }

  if (isNonceValid === null) {
    return <div>Loading...</div>;
  }

  return (
    <form className="form w-100 position-relative" id="kt_sign_up_form">
      <div className="formLogo mb-11 text-center">
        <img alt="Logo" src={logo} width="170" />
      </div>
      <WithSpinner
        isLoading={loading}
        style={{ minHeight: `300px`, minWidth: '300px' }}
      >
        <div className="text-center mb-11">
          <h1 className="text-dark fw-bolder mb-3">{t('auth_pages.signin')}</h1>
        </div>
        <div className="fv-row mb-8">
          <input
            type="text"
            name="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
            autoComplete="none"
            className="form-control bg-transparent d-block"
          />
          <FormErrorMessage
            name="email"
            errors={errors}
            className="my-1 px-2"
          />
          {isGPCLocked ? (
            <Alert
              message="GPC users can only sign in using the Microsoft button below."
              type="info"
              showIcon
            />
          ) : null}
        </div>
        {isGPCLocked ? null : (
          <>
            <div className="fv-row mb-8" data-kt-password-meter="true">
              <div className="mb-1">
                <div className="position-relative mb-3">
                  <input
                    className="form-control bg-transparent"
                    type={viewPassword ? 'text' : 'password'}
                    placeholder="Password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="none"
                    disabled={isGPCLocked}
                  />
                  <span
                    tabIndex={-1}
                    role="button"
                    onKeyDown={() => setViewPassword(!viewPassword)}
                    onClick={() => setViewPassword(!viewPassword)}
                    className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                    data-kt-password-meter-control="visibility"
                  >
                    <i
                      className={clsx(
                        'bi bi-eye-slash fs-2',
                        !viewPassword ? 'd-none' : '',
                      )}
                    />
                    <i
                      className={clsx(
                        'bi bi-eye fs-2',
                        viewPassword ? 'd-none' : '',
                      )}
                    />
                  </span>
                </div>
                <FormErrorMessage
                  name="password"
                  errors={errors}
                  className="my-1 px-2"
                />
                <div className="my-1 px-2">
                  <Link
                    to={config.forgotPassword.route}
                    className="btn btn-link btn-color-muted btn-active-color-primary me-5 mb-2"
                  >
                    {t('auth_pages.forgot_password?')}
                  </Link>
                </div>
              </div>
            </div>
            <div className="d-grid mb-10">
              <button
                disabled={isLoading || isGPCLocked}
                onClick={handleSubmit(handleSubmitData)}
                type="button"
                id="kt_sign_up_submit"
                className="btn btn-purple"
              >
                {!isLoading ? (
                  <span className="indicator-label">
                    {t('auth_pages.signin')}
                  </span>
                ) : (
                  <span className="d-block indicator-progress">
                    {t('auth_pages.wait')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                )}
              </button>
            </div>
          </>
        )}
        <SocialLogin isGPCLocked={isGPCLocked} />

        {isGPCLocked ? null : (
          <div className="text-gray-500 text-center fw-semibold fs-6">
            {t('auth_pages.no_account')}{' '}
            <Link
              to={`${config.signupPassword.route}`}
              className="link-primary fw-semibold"
            >
              {t('auth_pages.signup')}
            </Link>
          </div>
        )}
      </WithSpinner>
    </form>
  );
};
