import ConversionJobEvents from '../enums/events/conversionJobEvents.enum';
import ConversionJobStatus from '../enums/conversionJobStatus.enum';
import BadgeTypes from '../enums/badgeTypes.enum';

export const conversionJobToastsConfig = {
  [ConversionJobStatus.Initialising]: 'info',
  [ConversionJobStatus.Converting]: 'info',
  [ConversionJobStatus.Downloading]: 'info',
  [ConversionJobStatus.Successful]: 'info',
  [ConversionJobStatus.Done]: 'success',
  [ConversionJobStatus.Failed]: 'error',
} as const;

export const completedStatuses: ConversionJobStatus[] = [
  ConversionJobStatus.Done,
  ConversionJobStatus.Failed,
];

export const conversionJobEvents = Object.values(ConversionJobStatus).map(
  (status) => `${ConversionJobEvents.ConversionJobPrefix}.${status}`,
);

export const conversionJobBadgeTypes = {
  [ConversionJobStatus.Initialising]: BadgeTypes.Default,
  [ConversionJobStatus.Converting]: BadgeTypes.Info,
  [ConversionJobStatus.Downloading]: BadgeTypes.Info,
  [ConversionJobStatus.Successful]: BadgeTypes.Success,
  [ConversionJobStatus.Done]: BadgeTypes.Default,
  [ConversionJobStatus.Failed]: BadgeTypes.Default,
} as const;
