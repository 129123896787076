import axios from 'axios';
import { API_URL } from '../../../constants/api';
import UsersGroup from '../../../interfaces/usersGroup.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import GetDevicesListRequestData from '../devices/interfaces/getDevicesListRequestData.interface';
import GetDevicesListResponseData from '../devices/interfaces/getDevicesListResponseData.interface';
import GetDevicesGroupsRequestData from '../devicesGroups/interfaces/getDevicesGroupsRequestData.interface';
import GetDevicesGroupsResponseData from '../devicesGroups/interfaces/getDevicesGroupsResponseData.interface';
import GetUsersListResponseData from '../users/interfaces/getUsersListResponseData.interface';
import GetUsersRequestData from '../users/interfaces/getUsersRequestData.interface';
import UsersGroupsByIdsQueryParam from './enums/usersGroupsByIdsQueryParam.enum';
import UsersGroupsRoutes from './enums/usersGroupsRoutes.enum';
import CreateUsersGroupRequestData from './interfaces/createUsersGroupRequestData.interface';
import GetUsersGroupsRequestData from './interfaces/getUsersGroupsRequestData.interface';
import GetUsersGroupsResponseData from './interfaces/getUsersGroupsResponseData.interface';
import UpdateUsersGroupRequestData from './interfaces/updateUsersGroupRequestData.interface';

export default {
  /**
   * Retrieves the list of users groups
   * @param requestData
   */
  getUsersGroups: async (
    requestData: GetUsersGroupsRequestData,
  ): Promise<GetUsersGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of users groups (admin)
   * @param requestData
   */
  adminGetUsersGroups: async (
    requestData: GetUsersGroupsRequestData,
  ): Promise<GetUsersGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.AdminGroups}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Retrieves a list of user groups of device group
   * @param requestData
   *
   */
  getDeviceGroupUserGroupsListAdmin: async (
    requestData: GetUsersGroupsRequestData,
  ): Promise<GetUsersGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.AdminGroups}`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },
  /**
   * Retrieves the users' group by id
   * @param id
   */
  getUsersGroup: async (id: string): Promise<UsersGroup> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.AssignedGroups}/${id}`,
    });

    return data;
  },

  /**
   * Retrieves the list of users relates to current user group for AdminPanel
   * @param requestData
   * @param id
   */
  getAdminUsersAssignedToUserGroup: async (
    requestData: GetUsersRequestData,
    id: string,
  ): Promise<GetUsersListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.AdminGroups}/${id}/users`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Removes the users group by id
   * @param id
   */
  deleteUsersGroup: async (id: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}/${id}`,
    });
  },

  /**
   * Removes the users group by id for admin Role
   * @param id
   */
  AdminDeleteUsersGroup: async (id: string): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${UsersGroupsRoutes.AdminGroups}/${id}`,
    });
  },

  /**
   * Creates a new group of users
   * @param requestData
   */
  createUsersGroup: async (
    requestData: CreateUsersGroupRequestData,
  ): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}`,
      data: requestData,
    });
  },
  /**
   * Creates a new group of users
   * @param requestData
   */
  createUsersGroupAP: async (
    requestData: CreateUsersGroupRequestData,
  ): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${UsersGroupsRoutes.AdminGroups}`,
      data: requestData,
    });
  },
  /**
   * Updates the users group by id
   * @param requestData
   * @param usersGroupId
   */
  updateUsersGroup: async (
    requestData: UpdateUsersGroupRequestData,
    usersGroupId: string,
    groupInfo?: boolean,
  ): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}/${usersGroupId}`,
      data: requestData,
      params: { groupInfo },
    });
  },

  /**
   * Retrieves the list of users groups by ids
   * @param ids
   */
  getUsersGroupsByIds: async (
    ids: string[],
    companyId: string | undefined,
    locationId: string | undefined,
  ): Promise<UsersGroup[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.AssignedGroups}`,
      params: {
        [UsersGroupsByIdsQueryParam.GroupIds]: ids,
        locationId,
        companyId,
      },
    });

    return data;
  },

  /**
   * Retrieves the list of users groups by ids
   * @param ids
   * @param currentUserId
   */
  adminGetUsersGroupsByIds: async (
    ids: string[],
    companyId: string | undefined,
    locationId: string | undefined,
    currentUserId?: string,
  ): Promise<UsersGroup[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.AdminAssignedGroups}`,
      params: {
        [UsersGroupsByIdsQueryParam.GroupIds]: ids,
        companyId,
        locationId,
        currentUserId,
      },
    });

    return data;
  },

  /**
   * Retrieves the list of users relates to current user group
   * @param requestData
   * @param id
   */
  getUsersAssignedToUserGroup: async (
    requestData: GetUsersRequestData,
    id: string,
  ): Promise<GetUsersListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}/${id}/users`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of devices relates to current user group
   * @param requestData
   * @param id
   */
  getDevicesAssignedToUserGroup: async (
    requestData: GetDevicesListRequestData,
    id: string,
  ): Promise<GetDevicesListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}/${id}/devices`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Retrieves the list of device groups relates to current user group
   * @param requestData
   * @param id
   */
  getDeviceGroupsAssignedToUserGroup: async (
    requestData: GetDevicesGroupsRequestData,
    id: string,
  ): Promise<GetDevicesGroupsResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}/${id}/device-groups`,
      params: sanitizeQueryParams(requestData),
    });

    return data;
  },

  /**
   * Unassign user from user group
   * @param groupId
   * @param userId
   */
  unAssignUserFromUserGroup: async (
    groupId: string,
    userId: string,
  ): Promise<void> => {
    await axios({
      method: 'delete',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}/${groupId}/users/${userId}`,
    });
  },

  /**
   * Assign user from user group
   * @param groupId
   * @param userId
   */
  assignUserFromUserGroup: async (
    groupId: string,
    userId: string | undefined,
  ): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}/${groupId}/users/${userId}`,
    });
  },
  /**
   * Assign userGroup to user
   * @param userGroupId
   * @param userId
   */
  assignUserGroupToUser: async (
    userGroupId: string | undefined,
    userId: string,
  ) => {
    const { data } = await axios({
      method: 'patch',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}/${userGroupId}/users/${userId}`,
    });
    return data;
  },

  /**
   * Assign userGroup to user (for admin)
   * @param userGroupId
   * @param userId
   */
  adminAssignUserGroupToUser: async (
    userGroupId: string | undefined,
    userId: string,
  ) => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${UsersGroupsRoutes.AdminGroups}/${userGroupId}/users/${userId}`,
    });
  },

  /**
   * unassign userGroup from user
   * @param userGroupId
   * @param userId
   */
  unAssignUserGroupFromUser: async (userGroupId: string, userId: string) => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${UsersGroupsRoutes.Groups}/${userId}/users/${userGroupId}`,
    });
    return data;
  },

  /**
   * unassign userGroup from user forAdmin
   * @param userGroupId
   * @param userId
   */
  AdminUnAssignUserGroupFromUser: async (
    userGroupId: string,
    userId: string,
  ) => {
    const { data } = await axios({
      method: 'delete',
      url: `${API_URL}/${UsersGroupsRoutes.AdminGroups}/${userGroupId}/users/${userId}`,
    });
    return data;
  },
};
