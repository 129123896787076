import CreateContentRequestData from '../../api/endpoints/contents/interfaces/createContentRequestData.interface';
import IMediaGroup from '../../interfaces/mediaGroup.interface';
import User from '../../interfaces/user/user.interface';

export enum FileMimeType {
  PNG = 'image/png',
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  MP4 = 'video/mp4',
  VIDEO = 'video',
  MPEG = 'mpeg',
  MOV = 'mov',
  HVEC = 'hvec ',
  WEBM = 'webm',
  AVI = 'avi',
  FLV = 'flv',
}

export enum FileType {
  PNG = 'png',
  JPG = 'jpg',
  JPEG = 'jpeg',
  GIF = 'gif',
  PDF = 'pdf',
  PPT = 'ppt',
  PPTX = 'pptx',
  DOC = 'doc',
  DOCX = 'docx',
  XLSX = 'xlsx',
  XLS = 'xls',
  MP4 = 'mp4',
  DOCUMENT = 'document',
  SHEET = 'sheet',
  PRESENTATION = 'presentation',
}

export enum SortBy {
  ID = 'id',
  NAME = 'name',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  DIRECTORY = 'isDirectory',
}

export interface FileSchema {
  id: string;
  title: string;
  size: number;
  type: FileMimeType | FileType;
  isEmpty: boolean;
  url: string;
  filesCount: number;
  updatedAt: string;
  createdAt: string;
  isDirectory: boolean;
  users: string[];
  userGroups: string[];
  mediaGroup: IMediaGroup;
  duration?: number | undefined;
  ownerMedia: User;
}

export interface PaginatedFileLink {
  first: string;
  previous: string;
  next: string;
  last: string;
}

export interface PaginatedFileMeta {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface PaginatedFiles {
  items: FileSchema[];
  links: PaginatedFileLink;
  meta: PaginatedFileMeta;
}

export interface ErrorResponse {
  message: string;
  error: string;
  statusCode: number;
}
export interface GetFilesRequest {
  params: GetFilesRequestQueryParams;
}

export interface GetFilesRequestQueryParams {
  search: string;
  limit: number;
  page: number;
  type: FileMimeType | null;
  directoryId: string | null;
  onlyDir: boolean;
  sortBy: SortBy;
  isPublic: boolean;
}

export interface DeleteFilesRequest {
  params: DeleteFilesRequestQueryParams;
  isFolder: boolean;
}

export interface DeleteFilesRequestQueryParams {
  fileIds: string[];
}

export interface RenameFileRequest {
  fileId: string;
  name: string;
}

export interface MoveFileRequest {
  fileIds: string[];
  targetDirectoryId: string;
}

export interface CreateFileRequest {
  name: string;
  isDirectory: boolean;
  parentDirectoryId: string | null;
  file?: File | null;
  width: string | null;
  height: string | null;
  isPublic: boolean;
  duration?: number | null | undefined;
  conversionJobId?: number | null | undefined;
}

export type ScheduleFileRequestType = Omit<CreateContentRequestData, 'file'>;
export interface ScheduleFileRequest extends ScheduleFileRequestType {
  fileId: string;
}
