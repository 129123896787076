import React, { useEffect } from 'react';
import IContent from '../../../../../../../interfaces/content.interface';
import CountThumbnail from '../../../../../../Apps/DaysSafe/Thumbnail';

export default ({ content }: { content: IContent }) => {
  const [url, setUrl] = React.useState('');

  const CountData = content.app;

  useEffect(() => {
    if (!CountData) return;

    setUrl(
      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?appId=${CountData?.id}`,
    );
  }, [CountData]);

  return <CountThumbnail src={url} />;
};
