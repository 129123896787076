import clsx from 'clsx';
import React from 'react';
import cardImage from '../../assets/images/workplace/card.png';
import ribbon from '../../assets/images/workplace/ribbon.png';
import styles from './workplace.module.scss';
import TalkToSalesPremiumLibrary from '../../components/Trial/TalkToSalesPremiumLibrary/index';

export default () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const handleClose = () => {
    setIsModalVisible(false);
  };
  return (
    <div
      className={clsx('container', styles.workplaceLibraryWrap)}
      onContextMenu={(ev) => ev.preventDefault()}
    >
      <div className={clsx(styles.workplaceLibraryContent)}>
        <div className={clsx(styles.cardLeft, styles.card)}>
          <div>
            <h1 className={clsx(styles.mainHeading)}>
              Premium <br />
              Workplace Library
            </h1>
            <p
              className={clsx(styles.secContent)}
              style={{ textAlign: 'justify' }}
            >
              Enhance your corporate messaging with our premium library content.
              With our extensive knowledge of safety compliance, health,
              wellness and employee engagement needs, Indigo&apos;s content and
              design team creates professional content that supports our
              communication initiatives.
            </p>

            <TalkToSalesPremiumLibrary
              handleClose={handleClose}
              isModalVisible={isModalVisible}
            >
              <button
                type="button"
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                Talk to Sales
              </button>
            </TalkToSalesPremiumLibrary>
          </div>
        </div>
        <div className={clsx(styles.cardRight, styles.card)}>
          <div className={clsx(styles.libraryImage)}>
            <img src={cardImage} alt="" className="img-fluid" />
            <div className={clsx(styles.overlayImage)}>
              <img src={ribbon} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
