import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CreateContentRequestData from '../../../api/endpoints/contents/interfaces/createContentRequestData.interface';
import CreateTemplate from '../../../components/Templates/CreateTemplate';
import useModalState from '../../../utils/hooks/useModalState';
import { createFormData } from '../../../utils/formData';
import api from '../../../api';
import CreateTemplateRequestData from '../../../api/endpoints/templates/interfaces/createTemplateRequestData.interface';
import { getUserData } from '../../../store/actions/userData';

export default ({
  onSuccess,
  children,
}: {
  onSuccess?: (data: Partial<CreateContentRequestData>) => void;
  children: React.ReactNode;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isVisible, handleOpen, handleClose] = useModalState(false);

  const dispatch = useDispatch();
  const handleSubmit = (templateData: Partial<CreateTemplateRequestData>) => {
    setLoading(true);

    api.templates
      .createTemplate(createFormData(templateData, true))
      .then(() => {
        handleClose();

        if (onSuccess) {
          onSuccess(templateData);
          dispatch(getUserData());
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <span
        role="button"
        tabIndex={-1}
        onKeyPress={handleOpen}
        onClick={handleOpen}
      >
        {children}
      </span>
      <CreateTemplate
        isVisible={isVisible}
        isLoading={isLoading}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </>
  );
};
