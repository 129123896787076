import { Auth0Client } from '@auth0/auth0-spa-js';
import auth0JS from 'auth0-js';
import User from '../interfaces/user/user.interface';
import routesConfig from '../routing/config';
import history from '../constants/history';
import Customer from '../interfaces/customer.interface';

export const auth0 = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  redirect_uri: `${window.location.origin}${routesConfig.login.route}`,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  useRefreshTokens: true,
  cacheLocation: 'localstorage',
  scope: 'roles',
});

export const webAuth0 = new auth0JS.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
  redirectUri: `${window.location.origin}${routesConfig.login.route}`,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  scope: 'roles openid profile email',
  responseType: 'token id_token',
});

/**
 * Performs the login and redirects backp
 */
export const loginRedirectLocation = async () => {
  // window.location.href = `${window.location.origin}${routesConfig.signin.route}`;
  const { location } = window;
  const redirectUrl = location.href.replace(location.origin, '');

  if (redirectUrl.match(routesConfig.login.route)) {
    return;
  }
  await auth0.loginWithRedirect({
    appState: {
      redirectUrl:
        redirectUrl === routesConfig.logout.route
          ? routesConfig.home.route
          : redirectUrl,
    },
  });
};

/**
 * Performs logout
 */
export const logout = () => {
  auth0.logout({
    returnTo: `${window.location.origin}${routesConfig.signin.route}`,
  });

  window.localStorage.removeItem('loginAsUserId');
  window.localStorage.removeItem('loginAsUserName');
};

/**
 * Retrieves the access token if authorized or redirects to the auth0 login page otherwise
 */
export const getAccessTokenOrSignIn = async (): Promise<string | never> => {
  if (!(await auth0.isAuthenticated())) {
    await loginRedirectLocation();
  }

  return auth0.getTokenSilently({ timeoutInSeconds: 3 });
};

/**
 * Checks is user authenticated
 */
export const checkSignIn = async () => auth0.isAuthenticated();

/**
 * Login as other customer
 */
export const loginAsUser = (user: User | Customer) => {
  window.localStorage.setItem('loginAsUserId', user.id);
  window.localStorage.setItem(
    'loginAsUserName',
    `${user.firstName} ${user.lastName}`,
  );
  history.push('/');
  window.location.reload();
};

/**
 * retrieve user info from auth0
 */
export const getUserInfo = async (token: string) => {
  if (token) {
    const userInfo = await auth0.getUser();
    return userInfo;
  }
  return null;
};
