import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Device from '../../../interfaces/devices/device.interface';
import WithDeleteConfirm from '../../../components/WithDeleteConfirm/Controlled';
import api from '../../../api';

export default ({
  device,
  children,
  onUnAssign,
}: {
  children?: React.ReactNode;
  device: Device;
  onUnAssign?: () => void;
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  const onSubmit = () => {
    setIsLoading(true);

    api.devices
      .unAssignDeviceFromUser(device.id, device.owner?.id)
      .then(() => {
        close();

        if (onUnAssign) {
          onUnAssign();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-user-minus" />
          </span>
        )}
      </span>

      <WithDeleteConfirm
        isVisible={isModalVisible}
        isLoading={isLoading}
        handleDecline={close}
        body={t('devices.unassign_confirm')}
        confirmButtonText={t('common.delete_confirmation.confirm_unassign')}
        handleConfirm={onSubmit}
      />
    </>
  );
};
