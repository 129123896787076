/* eslint-disable @typescript-eslint/no-unused-vars */
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import React, { useState } from 'react';
import { map } from 'lodash';
import { useLocation } from 'react-router';
import App from '../../../interfaces/app.interface';
import Card from '../../Card';
import Search from '../../Search/QueryParamSearch';
import { KTSVG } from '../../../lib/metronic/helpers';
import styles from './instagram.module.scss';
import WithSpinner from '../../WithSpinner';
import InstagramTable from './Table';
import SortingQueryParam from '../../../enums/queryParams/sortingQueryParam.enum';
import useTableMultiSelect from '../../../utils/hooks/useTableMultiSelect';
import CreateInstagram from '../../../containers/Apps/Instagram/CreateInstagram/Instagram';
import Pagination from '../../Pagination/QueryParamPagination';
import PaginationQueryParam from '../../../enums/queryParams/paginationQueryParam.enum';
import UpdateInstagram from '../../../containers/Apps/Instagram/UpdateInstagram';

const pageSize = 6;
const tableRowHeight = 63;

export default ({
  total,
  instagram,
  isLoading,
  resetOnSearch,
  onUpdate,
}: {
  instagram: App[];
  total: number;
  isLoading: boolean;
  resetOnSearch?: boolean;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  const [apiLoading, setApiLoading] = useState(false);
  const { search } = useLocation();
  const searchInUrl = window.location.search;
  const params = new URLSearchParams(searchInUrl);
  const code = params.get('code');
  const [openWindow, setOpenWindow] = useState<boolean>(false);

  const [openWindowRelogin, setOpenWindowRelogin] = useState<boolean>(false);
  const [selectedItems, setSelectedItem, selectAll] = useTableMultiSelect(
    map(instagram, 'id'),
    [],
    resetOnSearch ? search : undefined,
  );

  const instagramRelogin = sessionStorage.getItem('instagramRelogin');
  React.useEffect(() => {
    if (code && (instagramRelogin === 'false' || !instagramRelogin)) {
      setOpenWindow(true);
      sessionStorage.removeItem('instagramRelogin');
    }

    if (code && instagramRelogin === 'true') {
      setOpenWindowRelogin(true);
      sessionStorage.removeItem('instagramRelogin');
    }
  }, []);

  return (
    <Card>
      <Card.Header>
        <Card.Header.Toolbar>
          <Search
            queryField="search"
            pageNumberQueryField=""
            placeholder={t('common.search')}
            className="me-2"
          />
          <div className="d-flex">
            <div className={clsx('me-3', styles.buttonStyle)}>
              <WithSpinner isLoading={apiLoading}>
                {/* <IntegrationButton connected={connected} /> */}
              </WithSpinner>
            </div>
            <CreateInstagram
              onUpdate={onUpdate}
              openWindow={openWindow}
              code={code}
              key={`updateInstagram${openWindow}`}
            >
              <button type="button" className="mt-2 btn btn-primary">
                <KTSVG
                  path="/media/icons/duotune/arrows/arr075.svg"
                  className="svg-icon-2"
                />
                {t('apps.instagram.createInstagram')}
              </button>
            </CreateInstagram>
          </div>
        </Card.Header.Toolbar>
      </Card.Header>
      <Card.Body>
        <InstagramTable
          multiSelectConfig={{
            selectedItems,
            setSelectedItem,
            selectAll,
          }}
          onUpdate={onUpdate}
          instagram={instagram}
          isLoading={isLoading}
          sortQueryField={SortingQueryParam?.SortBy}
          orderQueryField={SortingQueryParam?.SortOrder}
          preloaderHeight={pageSize * tableRowHeight}
        />
        <Pagination
          total={total}
          pageSize={pageSize}
          pageNumberQueryField={PaginationQueryParam.Page}
          pageSizeQueryField={PaginationQueryParam.Limit}
          className="mt-3 mb-5"
        />

        <UpdateInstagram
          onUpdate={onUpdate}
          openWindow={openWindowRelogin}
          code={code ?? undefined}
          key={`updateInstagram${openWindowRelogin}`}
        />
      </Card.Body>
    </Card>
  );
};
