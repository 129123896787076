import axios from 'axios';
import {
  checkSignIn,
  getAccessTokenOrSignIn,
  getUserInfo,
} from '../../utils/auth0';

/**
 * Adds the Authorization header to the intercepted requests
 */
export default () => {
  axios.interceptors.request.use(async (config) => {
    if (await checkSignIn()) {
      const token = await getAccessTokenOrSignIn();
      const user = await getUserInfo(token);

      const loginAsUserId = window.localStorage.getItem('loginAsUserId');

      const headers = {
        ...config?.headers,
        Authorization: `Bearer ${token}`,
      };
      if (user) {
        Object.assign(headers, {
          sub: user?.sub,
        });
      }
      if (loginAsUserId) {
        headers.assume = loginAsUserId;
      }
      return {
        ...config,
        headers,
      };
    }
    return config;
  });
};
