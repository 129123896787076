import React, { FC } from 'react';
import clsx from 'clsx';

interface CheckBoxProps {
  id?: any;
  label?: string;
  className?: string;
  value?: string;
  name?: string;
  onChange?: () => void;
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

const CheckBox: FC<CheckBoxProps> = ({
  id,
  value,
  label,
  className,
  name,
  onChange,
  checked,
  disabled,
  readOnly = false,
}) => {
  return (
    <div className={clsx('form-check', className)}>
      <input
        type="checkbox"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        className="form-check-input"
        checked={checked}
        disabled={disabled}
        readOnly={readOnly}
      />
      {label && (
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
