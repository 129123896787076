import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Modal from '../../../components/Modal';

import { LiveStreamFeedSchema } from '../../../store/types/apps/liveStreamFeed';
import { KTSVG } from '../../../lib/metronic/helpers';

export interface DeleteFeedModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (feed: LiveStreamFeedSchema) => void;
  title: string;
  selectedLiveStreamFeedSchema: LiveStreamFeedSchema;
  isLoading: boolean;
}

const DeleteFeedModal = ({
  show,
  onHide,
  onSubmit,
  title,
  selectedLiveStreamFeedSchema,
  isLoading = false,
}: DeleteFeedModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={show}
      onHide={onHide}
      widthDefaultSize={false}
      title={title}
      withFooter={false}
      withHeader={false}
      bodyClassName="d-flex flex-center flex-column my-7"
    >
      <span className="svg-icon svg-icon-5tx svg-icon-danger mb-10">
        <KTSVG
          path="/media/icons/duotune/general/gen044.svg"
          className="svg-icon-1"
        />
      </span>
      <div className="text-center">
        <h5 className="fw-bolder fs-1 mb-10">
          {`${t('common.delete_confirmation.title')} Live Stream  ${
            selectedLiveStreamFeedSchema?.name
          }`}
        </h5>
        <div className="d-flex flex-center flex-wrap">
          <button
            type="submit"
            className="btn btn-danger me-2"
            onClick={() => onSubmit(selectedLiveStreamFeedSchema)}
          >
            {isLoading ? (
              <span>
                Deleting{' '}
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            ) : (
              <span>{t('common.delete_confirmation.confirm')}</span>
            )}
          </button>
          <button
            type="button"
            className="btn btn-outline btn-outline-danger btn-active-danger"
            onClick={onHide}
          >
            {t('common.cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteFeedModal;
