import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import CreateDeviceGroupRequestData from '../../../api/endpoints/devicesGroups/interfaces/createDeviceGroupRequestData.interface';
import CreateDeviceGroup from '../../../components/DevicesGroups/CreateDeviceGroup';
import { createDeviceGroup } from '../../../store/actions/devicesGroups';

export default ({
  className = undefined,
  onSubmit,
}: {
  className?: string;
  onSubmit?: (values: CreateDeviceGroupRequestData) => void;
}) => {
  const dispatch = useDispatch();

  const handleSubmit = async (values: CreateDeviceGroupRequestData) => {
    if (onSubmit) {
      onSubmit(values);

      return;
    }

    dispatch(createDeviceGroup(values));
  };

  return (
    <CreateDeviceGroup
      className={clsx('btn btn-primary', className)}
      onSubmit={handleSubmit}
    />
  );
};
