import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { defaultDebounceValue } from '../../constants/api/debounceSettings';
import GlobalState from '../../store/reducers/globalState.interface';
import { readAllFromQueryParams } from '../../utils/queryParams';
import { getUsersList } from '../../store/actions/users';
import useLoading from '../../utils/hooks/useLoading';
import Users from '../../components/Users';
import api from '../../api';

export default () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: GlobalState) => state.users.loading);
  const users = useSelector((state: GlobalState) => state.users.users);
  const total = useSelector((state: GlobalState) => state.users.total);
  const [loading, setLoading] = useLoading(isLoading);

  const dispatchGetUsersList = useCallback(
    debounce(
      (recentSearch: string) =>
        dispatch(getUsersList(readAllFromQueryParams(recentSearch))),
      defaultDebounceValue,
    ),
    [],
  );

  const onUnRemoveUsers = async (userIds: string[]) => {
    setLoading(true);

    try {
      await Promise.all(
        userIds.map((id: string) => api.users.permanentlyDeleteUser(id)),
      );

      toast.success(i18next.t<string>('users.bulk_remove_message'));
      dispatchGetUsersList(search);
    } catch (e) {
      setLoading(false);
    }
  };

  const onArchiveUsers = async (userIds: string[]) => {
    setLoading(true);

    try {
      await Promise.all(userIds.map((id: string) => api.users.deleteUser(id)));

      toast.success(i18next.t<string>('users.bulk_archive_message'));
      dispatchGetUsersList(search);
    } catch (e) {
      setLoading(false);
    }
  };

  const onDeleteUser = async (userId: string) => {
    setLoading(true);

    try {
      await api.users.permanentlyDeleteUser(userId);

      toast.success(i18next.t<string>('users.remove_message'));
      dispatchGetUsersList(search);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true); // show preloader before dispatch
    dispatchGetUsersList(search);
  }, [search]);

  return (
    <Users
      users={users}
      total={total}
      isLoading={loading}
      resetOnSearch
      onUnAssignUsers={onUnRemoveUsers}
      unAssignUser={onDeleteUser}
      onArchiveUsers={onArchiveUsers}
    />
  );
};
