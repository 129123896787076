import React, { useEffect, useState } from 'react';
import LocationsTable from '../../../../components/AdminPanel/CompanyLocations';
import GetLocationsRequestData from '../../../../api/endpoints/locations/interfaces/getLocationsRequestData.interface';
import PaginationQueryParam from '../../../../enums/queryParams/paginationQueryParam.enum';
import Location from '../../../../interfaces/location.interface';
import Company from '../../../../interfaces/company.interface';
import api from '../../../../api';
import useIsAdmin from '../../../../utils/hooks/useIsAdmin';
import SortingQueryParam from '../../../../enums/queryParams/sortingQueryParam.enum';
import LocationsSortField from '../../../../api/endpoints/locations/enums/locationsSortField.enum';
import SortOrder from '../../../../enums/sortOrder.enum';

export default ({
  company,
  totalLocations,
  isSelectable,
}: {
  company: Company;
  totalLocations?: (total: number) => void;
  isSelectable?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [requestData, setRequestData] = useState<
    Partial<GetLocationsRequestData>
  >({
    companyId: company.id,
    [PaginationQueryParam.Page]: '1',
    [PaginationQueryParam.Limit]: '6',
    [SortingQueryParam.SortBy]: LocationsSortField.Name,
    [SortingQueryParam.SortOrder]: SortOrder.Ascending,
  });
  const [isAdmin] = useIsAdmin();

  const getCompanyLocations = async (
    locationsRequestData: Partial<GetLocationsRequestData>,
  ) => {
    try {
      setLoading(true);
      let locationsData;
      if (isAdmin) {
        locationsData = await api.locations.getAdminLocations(
          locationsRequestData,
        );
      } else {
        locationsData = await api.locations.getLocations(locationsRequestData);
      }
      const { items, meta } = locationsData;
      setLocations(items);
      setTotal(meta.totalItems);
      if (totalLocations) totalLocations(meta.totalItems);
    } catch (e) {
      // Do nothing for now
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getCompanyLocations(requestData);
  }, [requestData]);

  return (
    <LocationsTable
      isLoading={loading}
      locations={locations}
      onUpdate={() => getCompanyLocations(requestData)}
      total={total}
      company={company}
      setRequestData={setRequestData}
      requestData={requestData}
      isSelectable={isSelectable}
    />
  );
};
