import GetDevicesGroupsSuccessAction from '../../actions/devicesGroups/interfaces/getDevicesGroupsSuccessAction.interface';
import DevicesGroupsActions from '../../actions/devicesGroups/devicesGroupsActions.enum';
import LoadingAction from '../../actions/loadingAction.interface';
import DevicesGroupsState from './devicesGroupsState.interface';
import ReduxAction from '../../actions/reduxAction.interface';
import GetDeviceGroupSuccessAction from '../../actions/devicesGroups/interfaces/getDeviceGroupSuccessAction.interface';
import GetDeviceGroupUsersSuccessAction from '../../actions/devicesGroups/interfaces/getDeviceGroupUsersSuccessAction.interface';

const initialState: DevicesGroupsState = {
  loading: true,
  groups: [],
  group: {
    id: '',
    name: '',
    description: '',
    users: [],
    usersCount: 0,
    devicesCount: 0,
    playlistsCount: 0,
    devices: [],
    licenses: [],
    playlists: [],
  },
  total: 0,
};

export default function devicesGroupsReducer(
  state = initialState,
  action: ReduxAction<DevicesGroupsActions>,
): DevicesGroupsState {
  switch (action.type) {
    case DevicesGroupsActions.GET_DEVICE_GROUP_SUCCESS: {
      const { payload } = action as GetDeviceGroupSuccessAction;

      return {
        ...state,
        group: { ...state.group, ...payload },
      };
    }
    case DevicesGroupsActions.GET_DEVICE_GROUP_USERS_SUCCESS: {
      const { payload } = action as GetDeviceGroupUsersSuccessAction;

      return {
        ...state,
        group: { ...state.group, users: payload.items },
      };
    }
    case DevicesGroupsActions.GET_DEVICES_GROUPS_SUCCESS: {
      const {
        payload: { items, meta },
      } = action as GetDevicesGroupsSuccessAction;

      return {
        ...state,
        groups: items,
        total: meta.totalItems,
      };
    }
    case DevicesGroupsActions.SET_DEVICES_GROUPS_LOADING:
      return {
        ...state,
        ...(
          action as LoadingAction<DevicesGroupsActions.SET_DEVICES_GROUPS_LOADING>
        ).payload,
      };
    default:
      return state;
  }
}
