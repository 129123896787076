import React from 'react';

/**
 * Adds the click backdrop which fills the background space to make able to click on background
 * Important! Cannot be used if the DevicePreview has no relative descending element
 * @param children
 */
export default ({ children }: { children: React.ReactNode }) => (
  <>
    <span className="position-absolute w-100 h-100 top-0 start-0" />
    {children}
  </>
);
