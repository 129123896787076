enum BadgeTypes {
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Pink = 'pink',
  Default = '',
}

export default BadgeTypes;
