import { ErrorResponse } from '../../types/apps/globalTypes';
import {
  CreateHTMLFeedRequest,
  CreateScheduleHTMLFeedRequest,
  DeleteHTMLFeedRequest,
  EditHTMLFeedRequest,
  GetHTMLFeedsRequest,
  HTMLFeedSchema,
  PaginatedHTMLFeeds,
  ScheduleHTMLFeedRequest,
} from '../../types/apps/htmlFeed';

export interface HTMLFeedsReduxAction<TYPE = HTML_FEED_ACTIONS, PAYLOAD = any> {
  type: TYPE;
  payload?: PAYLOAD;
}

export enum HTML_FEED_ACTIONS {
  GET_HTML_FEEDS = 'GET_HTML_FEEDS',
  GET_HTML_FEEDS_LOADING = 'GET_HTML_FEEDS_LOADING',
  GET_HTML_FEEDS_SUCCESS = 'GET_HTML_FEEDS_SUCCESS',
  GET_HTML_FEEDS_ERROR = 'GET_HTML_FEEDS_ERROR',
  GET_HTML_FEEDS_RESET = 'GET_HTML_FEEDS_RESET',

  CREATE_HTML_FEED = 'CREATE_HTML_FEED',
  CREATE_HTML_FEED_LOADING = 'CREATE_HTML_FEED_LOADING',
  CREATE_HTML_FEED_SUCCESS = 'CREATE_HTML_FEED_SUCCESS',
  CREATE_HTML_FEED_ERROR = 'CREATE_HTML_FEED_ERROR',
  CREATE_HTML_FEED_RESET = 'CREATE_HTML_FEED_RESET',

  SCHEDULE_HTML_FEED = 'SCHEDULE_HTML_FEED',
  SCHEDULE_HTML_FEED_LOADING = 'SCHEDULE_HTML_FEED_LOADING',
  SCHEDULE_HTML_FEED_SUCCESS = 'SCHEDULE_HTML_FEED_SUCCESS',
  SCHEDULE_HTML_FEED_ERROR = 'SCHEDULE_HTML_FEED_ERROR',
  SCHEDULE_HTML_FEED_RESET = 'SCHEDULE_HTML_FEED_RESET',

  DELETE_HTML_FEED = 'DELETE_HTML_FEED',
  DELETE_HTML_FEED_LOADING = 'DELETE_HTML_FEED_LOADING',
  DELETE_HTML_FEED_SUCCESS = 'DELETE_HTML_FEED_SUCCESS',
  DELETE_HTML_FEED_ERROR = 'DELETE_HTML_FEED_ERROR',
  DELETE_HTML_FEED_RESET = 'DELETE_HTML_FEED_RESET',

  EDIT_HTML_FEED = 'EDIT_HTML_FEED',
  EDIT_HTML_FEED_LOADING = 'EDIT_HTML_FEED_LOADING',
  EDIT_HTML_FEED_SUCCESS = 'EDIT_HTML_FEED_SUCCESS',
  EDIT_HTML_FEED_ERROR = 'EDIT_HTML_FEED_ERROR',
  EDIT_HTML_FEED_RESET = 'EDIT_HTML_FEED_RESET',

  CREATE_SCHEDULE_HTML_FEED = 'CREATE_SCHEDULE_HTML_FEED',
  CREATE_SCHEDULE_HTML_FEED_LOADING = 'CREATE_SCHEDULE_HTML_FEED_LOADING',
  CREATE_SCHEDULE_HTML_FEED_SUCCESS = 'CREATE_SCHEDULE_HTML_FEED_SUCCESS',
  CREATE_SCHEDULE_HTML_FEED_ERROR = 'CREATE_SCHEDULE_HTML_FEED_ERROR',
  CREATE_SCHEDULE_HTML_FEED_RESET = 'CREATE_SCHEDULE_HTML_FEED_RESET',
}

export interface GET_HTML_FEEDS_ACTIONS
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.GET_HTML_FEEDS,
    GetHTMLFeedsRequest
  > {}

export const getHTMLFeedsActionCreator = (
  payload: GET_HTML_FEEDS_ACTIONS['payload'],
): GET_HTML_FEEDS_ACTIONS => ({
  type: HTML_FEED_ACTIONS.GET_HTML_FEEDS,
  payload,
});

export interface CREATE_HTML_FEED_ACTIONS
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.CREATE_HTML_FEED,
    CreateHTMLFeedRequest
  > {}

export const createHTMLFeedActionCreator = (
  payload: CREATE_HTML_FEED_ACTIONS['payload'],
): CREATE_HTML_FEED_ACTIONS => ({
  type: HTML_FEED_ACTIONS.CREATE_HTML_FEED,
  payload,
});

export interface CREATE_SCHEDULE_HTML_FEED_ACTIONS
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.CREATE_SCHEDULE_HTML_FEED,
    CreateScheduleHTMLFeedRequest
  > {}

export const createScheduleHTMLFeedActionCreator = (
  payload: CREATE_SCHEDULE_HTML_FEED_ACTIONS['payload'],
): CREATE_SCHEDULE_HTML_FEED_ACTIONS => ({
  type: HTML_FEED_ACTIONS.CREATE_SCHEDULE_HTML_FEED,
  payload,
});

export interface EDIT_HTML_FEED_ACTIONS
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.EDIT_HTML_FEED,
    EditHTMLFeedRequest
  > {}

export const editHTMLFeedActionCreator = (
  payload: EDIT_HTML_FEED_ACTIONS['payload'],
): EDIT_HTML_FEED_ACTIONS => ({
  type: HTML_FEED_ACTIONS.EDIT_HTML_FEED,
  payload,
});

export interface SCHEDULE_HTML_FEED_ACTIONS
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED,
    ScheduleHTMLFeedRequest
  > {}

export const scheduleHTMLFeedActionCreator = (
  payload: SCHEDULE_HTML_FEED_ACTIONS['payload'],
): SCHEDULE_HTML_FEED_ACTIONS => ({
  type: HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED,
  payload,
});

export interface DELETE_HTML_FEED_ACTIONS
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.DELETE_HTML_FEED,
    DeleteHTMLFeedRequest
  > {}

export const deleteHTMLFeedActionCreator = (
  payload: DELETE_HTML_FEED_ACTIONS['payload'],
): DELETE_HTML_FEED_ACTIONS => ({
  type: HTML_FEED_ACTIONS.DELETE_HTML_FEED,
  payload,
});

// Success action and success action creator
export interface CREATE_HTML_FEED_SUCCESS_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.CREATE_HTML_FEED_SUCCESS,
    {
      data: Partial<HTMLFeedSchema>;
      loading: boolean;
      isCreateHTMLFeedSuccess: boolean;
    }
  > {}

export const createHTMLFeedSuccessActionCreator = (
  payload: CREATE_HTML_FEED_SUCCESS_ACTION['payload'],
): CREATE_HTML_FEED_SUCCESS_ACTION => ({
  type: HTML_FEED_ACTIONS.CREATE_HTML_FEED_SUCCESS,
  payload,
});

export interface EDIT_HTML_FEED_SUCCESS_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.EDIT_HTML_FEED_SUCCESS,
    {
      data: Partial<HTMLFeedSchema>;
      loading: boolean;
      isEditHTMLFeedSuccess: boolean;
    }
  > {}

export const editHTMLFeedSuccessActionCreator = (
  payload: EDIT_HTML_FEED_SUCCESS_ACTION['payload'],
): EDIT_HTML_FEED_SUCCESS_ACTION => ({
  type: HTML_FEED_ACTIONS.EDIT_HTML_FEED_SUCCESS,
  payload,
});

export interface GET_HTML_FEEDS_SUCCESS_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.GET_HTML_FEEDS_SUCCESS,
    {
      data: PaginatedHTMLFeeds;
      loading: boolean;
      isGetHTMLFeedsSuccess: boolean;
    }
  > {}

export const getHTMLFeedsSuccessActionCreator = (
  payload: GET_HTML_FEEDS_SUCCESS_ACTION['payload'],
): GET_HTML_FEEDS_SUCCESS_ACTION => ({
  type: HTML_FEED_ACTIONS.GET_HTML_FEEDS_SUCCESS,
  payload,
});

export interface SCHEDULE_HTML_FEED_SUCCESS_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_SUCCESS,
    {
      data: Partial<HTMLFeedSchema>;
      loading: boolean;
      isScheduleHTMLFeedSuccess: boolean;
    }
  > {}

export const scheduleHTMLFeedSuccessActionCreator = (
  payload: SCHEDULE_HTML_FEED_SUCCESS_ACTION['payload'],
): SCHEDULE_HTML_FEED_SUCCESS_ACTION => ({
  type: HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_SUCCESS,
  payload,
});

export interface DELETE_HTML_FEED_SUCCESS_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.DELETE_HTML_FEED_SUCCESS,
    { data: void; loading: boolean; isDeleteHTMLFeedSuccess: boolean }
  > {}

export const deleteHTMLFeedSuccessActionCreator = (
  payload: DELETE_HTML_FEED_SUCCESS_ACTION['payload'],
): DELETE_HTML_FEED_SUCCESS_ACTION => ({
  type: HTML_FEED_ACTIONS.DELETE_HTML_FEED_SUCCESS,
  payload,
});

// Loading action and loading action creator
export interface GET_HTML_FEEDS_LOADING_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.GET_HTML_FEEDS_LOADING,
    { loading: boolean }
  > {}

export const getHTMLFeedsLoadingActionCreator = (
  payload: GET_HTML_FEEDS_LOADING_ACTION['payload'],
): GET_HTML_FEEDS_LOADING_ACTION => ({
  type: HTML_FEED_ACTIONS.GET_HTML_FEEDS_LOADING,
  payload,
});

export interface CREATE_HTML_FEED_LOADING_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.CREATE_HTML_FEED_LOADING,
    { loading: boolean }
  > {}

export const createHTMLFeedLoadingActionCreator = (
  payload: CREATE_HTML_FEED_LOADING_ACTION['payload'],
): CREATE_HTML_FEED_LOADING_ACTION => ({
  type: HTML_FEED_ACTIONS.CREATE_HTML_FEED_LOADING,
  payload,
});

export interface EDIT_HTML_FEED_LOADING_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.EDIT_HTML_FEED_LOADING,
    { loading: boolean }
  > {}

export const editHTMLFeedLoadingActionCreator = (
  payload: EDIT_HTML_FEED_LOADING_ACTION['payload'],
): EDIT_HTML_FEED_LOADING_ACTION => ({
  type: HTML_FEED_ACTIONS.EDIT_HTML_FEED_LOADING,
  payload,
});

export interface SCHEDULE_HTML_FEED_LOADING_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_LOADING,
    { loading: boolean }
  > {}

export const scheduleHTMLFeedLoadingActionCreator = (
  payload: SCHEDULE_HTML_FEED_LOADING_ACTION['payload'],
): SCHEDULE_HTML_FEED_LOADING_ACTION => ({
  type: HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_LOADING,
  payload,
});

export interface DELETE_HTML_FEED_LOADING_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.DELETE_HTML_FEED_LOADING,
    { loading: boolean }
  > {}

export const deleteHTMLFeedLoadingActionCreator = (
  payload: DELETE_HTML_FEED_LOADING_ACTION['payload'],
): DELETE_HTML_FEED_LOADING_ACTION => ({
  type: HTML_FEED_ACTIONS.DELETE_HTML_FEED_LOADING,
  payload,
});

// Error action and error action creator
export interface GET_HTML_FEEDS_ERROR_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.GET_HTML_FEEDS_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isGetHTMLFeedsSuccess: boolean;
    }
  > {}

export const getHTMLFeedsErrorActionCreator = (
  payload: GET_HTML_FEEDS_ERROR_ACTION['payload'],
): GET_HTML_FEEDS_ERROR_ACTION => ({
  type: HTML_FEED_ACTIONS.GET_HTML_FEEDS_ERROR,
  payload,
});

export interface CREATE_HTML_FEED_ERROR_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.CREATE_HTML_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isCreateHTMLFeedSuccess: boolean;
    }
  > {}

export const createHTMLFeedErrorActionCreator = (
  payload: CREATE_HTML_FEED_ERROR_ACTION['payload'],
): CREATE_HTML_FEED_ERROR_ACTION => ({
  type: HTML_FEED_ACTIONS.CREATE_HTML_FEED_ERROR,
  payload,
});

export interface CREATE_SHEDULE_HTML_FEED_ERROR_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.CREATE_SCHEDULE_HTML_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isCreateHTMLFeedSuccess: boolean;
    }
  > {}

export const createScheduleHTMLFeedErrorActionCreator = (
  payload: CREATE_SHEDULE_HTML_FEED_ERROR_ACTION['payload'],
): CREATE_SHEDULE_HTML_FEED_ERROR_ACTION => ({
  type: HTML_FEED_ACTIONS.CREATE_SCHEDULE_HTML_FEED_ERROR,
  payload,
});

export interface EDIT_HTML_FEED_ERROR_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.EDIT_HTML_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isEditHTMLFeedSuccess: boolean;
    }
  > {}

export const editHTMLFeedErrorActionCreator = (
  payload: EDIT_HTML_FEED_ERROR_ACTION['payload'],
): EDIT_HTML_FEED_ERROR_ACTION => ({
  type: HTML_FEED_ACTIONS.EDIT_HTML_FEED_ERROR,
  payload,
});

export interface SCHEDULE_HTML_FEED_ERROR_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isScheduleHTMLFeedSuccess: boolean;
    }
  > {}

export const scheduleHTMLFeedErrorActionCreator = (
  payload: SCHEDULE_HTML_FEED_ERROR_ACTION['payload'],
): SCHEDULE_HTML_FEED_ERROR_ACTION => ({
  type: HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_ERROR,
  payload,
});

export interface DELETE_HTML_FEED_ERROR_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.DELETE_HTML_FEED_ERROR,
    {
      error: ErrorResponse;
      loading: boolean;
      isDeleteHTMLFeedSuccess: boolean;
    }
  > {}

export const deleteHTMLFeedErrorActionCreator = (
  payload: DELETE_HTML_FEED_ERROR_ACTION['payload'],
): DELETE_HTML_FEED_ERROR_ACTION => ({
  type: HTML_FEED_ACTIONS.DELETE_HTML_FEED_ERROR,
  payload,
});

// Reset action and reset action creator
export interface GET_HTML_FEEDS_RESET_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.GET_HTML_FEEDS_RESET,
    {
      data: PaginatedHTMLFeeds;
      loading: boolean;
      isGetHTMLFeedsSuccess: boolean;
      error: null;
    }
  > {}

export const getHTMLFeedsResetActionCreator = (
  payload: GET_HTML_FEEDS_RESET_ACTION['payload'],
): GET_HTML_FEEDS_RESET_ACTION => ({
  type: HTML_FEED_ACTIONS.GET_HTML_FEEDS_RESET,
  payload,
});

export interface CREATE_HTML_FEED_RESET_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.CREATE_HTML_FEED_RESET,
    { error: null; loading: boolean; isCreateHTMLFeedSuccess: boolean }
  > {}

export const createHTMLFeedResetActionCreator = (
  payload: CREATE_HTML_FEED_RESET_ACTION['payload'],
): CREATE_HTML_FEED_RESET_ACTION => ({
  type: HTML_FEED_ACTIONS.CREATE_HTML_FEED_RESET,
  payload,
});

export interface CREATE_SCHEDULE_HTML_FEED_RESET_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.CREATE_SCHEDULE_HTML_FEED_RESET,
    { error: null; loading: boolean; isCreateScheduleHTMLFeedSuccess: boolean }
  > {}

export const createScheduleHTMLFeedResetActionCreator = (
  payload: CREATE_SCHEDULE_HTML_FEED_RESET_ACTION['payload'],
): CREATE_SCHEDULE_HTML_FEED_RESET_ACTION => ({
  type: HTML_FEED_ACTIONS.CREATE_SCHEDULE_HTML_FEED_RESET,
  payload,
});

export interface EDIT_HTML_FEED_RESET_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.EDIT_HTML_FEED_RESET,
    { error: null; loading: boolean; isEditHTMLFeedSuccess: boolean }
  > {}

export const editHTMLFeedResetActionCreator = (
  payload: EDIT_HTML_FEED_RESET_ACTION['payload'],
): EDIT_HTML_FEED_RESET_ACTION => ({
  type: HTML_FEED_ACTIONS.EDIT_HTML_FEED_RESET,
  payload,
});

export interface SCHEDULE_HTML_FEED_RESET_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_RESET,
    { error: null; loading: boolean; isScheduleHTMLFeedSuccess: boolean }
  > {}

export const scheduleHTMLFeedResetActionCreator = (
  payload: SCHEDULE_HTML_FEED_RESET_ACTION['payload'],
): SCHEDULE_HTML_FEED_RESET_ACTION => ({
  type: HTML_FEED_ACTIONS.SCHEDULE_HTML_FEED_RESET,
  payload,
});

export interface DELETE_HTML_FEED_RESET_ACTION
  extends HTMLFeedsReduxAction<
    HTML_FEED_ACTIONS.DELETE_HTML_FEED_RESET,
    { error: null; loading: boolean; isDeleteHTMLFeedSuccess: boolean }
  > {}

export const deleteHTMLFeedResetActionCreator = (
  payload: DELETE_HTML_FEED_RESET_ACTION['payload'],
): DELETE_HTML_FEED_RESET_ACTION => ({
  type: HTML_FEED_ACTIONS.DELETE_HTML_FEED_RESET,
  payload,
});
