import React from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { prepareHookFromState } from '../../../../../utils/hooks/useStateWithHookForm';
import FormErrorMessage from '../../../../FormErrorMessage';
import validation from '../../../../../utils/validation';
import StepsTitle from '../../StepsTitle';
import Modal from '../../../../Modal';
import CreateTemplateRequestData from '../../../../../api/endpoints/templates/interfaces/createTemplateRequestData.interface';
import AddTemplateSteps from '../../../../../enums/steps/addTemplateSteps.enum';
import FileUploader from '../../../../FileUploader';
import styles from '../../../../../containers/Templates/SelectLayout/layout.module.scss';
import TemplateOrientation from '../../../../../enums/templateOrientation.enum';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

type FieldsData = Partial<CreateTemplateRequestData>;

interface FormInputs {
  layoutId: string;
  orientation: any;
  background: File[];
  logo: File[];
}

export default ({
  data,
  validationSchema,
  setMode,
  setData,
  onSubmit,
}: {
  data: any;
  validationSchema: { [key: string]: any };
  setMode: (mode: AddTemplateSteps) => void;
  setData: (newData: FieldsData) => void;
  onSubmit: (data: Partial<CreateTemplateRequestData>) => void;
}) => {
  const { t } = useTranslation();
  const defaultValues: Partial<FormInputs> = {
    orientation: data.orientation || TemplateOrientation.HORIZONTAL,
    background: data.background || [],
    logo: data.logo || [],
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(validation.object(validationSchema).required()),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [background, setBackground] =
    useFs<FormInputs['background']>('background');
  const [logo, setLogo] = useFs<FormInputs['logo']>('logo');

  const handleSubmitData = (inputsData: Partial<FormInputs>) => {
    onSubmit({
      name: data.name,
      layoutId: data.layout.id,
      background: inputsData.background && inputsData.background[0],
      logo: inputsData.logo && inputsData.logo[0],
      orientation: TemplateOrientation.HORIZONTAL,
    });
  };

  const handleUpload = (
    selectedFiles: File[],
    setFile: any,
    fileName: string,
  ) => {
    if (!selectedFiles.length) {
      setFile(selectedFiles);
      return;
    }

    setData({ ...data, [fileName]: selectedFiles });
    setFile(selectedFiles);

    const file: File = selectedFiles[0];
    const img = new Image();

    const objectUrl = URL.createObjectURL(file);

    img.onload = function handleLoadImage() {
      URL.revokeObjectURL(objectUrl);
    };

    img.src = objectUrl;
  };

  return (
    <>
      <StepsTitle currentStep={AddTemplateSteps.Details} />
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <div className="d-flex justify-content-center flex-wrap">
          <div className="d-flex flex-column me-5">
            <img
              src={data.layout?.preview}
              alt="preview"
              className={clsx('w-400px h-184px', styles)}
            />
          </div>
          <div className="w-400px d-flex flex-column">
            <FormErrorMessage
              name="orientation"
              errors={errors}
              className={errorMessageClassNames}
            />
            <div className="">
              <h4 className="fw-boldest mb-2">
                {t('templates.create_template.fields.logo')}
              </h4>

              <FileUploader
                name="logo"
                mutedText={t('contents.upload_image')}
                accept=".png, .jpg, .jpeg"
                onChange={(files) => handleUpload(files, setLogo, 'logo')}
                className="w-100 mt-2"
                defaultValue={logo}
                containerClassName="justify-content-center"
              />

              <div className="w-400px d-flex flex-column">
                <FormErrorMessage
                  name="logo"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
              <div className="mt-auto">
                <h4 className="fw-boldest mb-2">
                  {t('templates.create_template.fields.background')}
                </h4>

                <FileUploader
                  name="background"
                  mutedText={t('contents.upload_image')}
                  accept=".png, .jpg, .jpeg"
                  onChange={(files) =>
                    handleUpload(files, setBackground, 'background')
                  }
                  className="w-100 mt-2"
                  defaultValue={background}
                  containerClassName="justify-content-center"
                />

                <FormErrorMessage
                  name="background"
                  errors={errors}
                  className={errorMessageClassNames}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-flex justify-content-between mt-5">
          <button
            type="button"
            className="btn btn-white text-primary"
            onClick={() => setMode(AddTemplateSteps.Layout)}
          >
            {t('common.prev_step')}
          </button>
          <button type="submit" className="btn btn-primary">
            {t('common.create')}
          </button>
        </div>
      </form>
    </>
  );
};
