import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import FormErrorMessage from '../../../FormErrorMessage';
import useStateWithHookForm from '../../../../utils/hooks/useStateWithHookForm';
import App from '../../../../interfaces/app.interface';
import Modal from '../../../Modal';
import WithSpinner from '../../../WithSpinner';
import Orientation from '../../../../enums/orientation.enum';
import styles from './CountUpTimer.module.scss';
import countUpFlipTimerPic from '../../../../assets/images/countTimer/CountUpTimerFlip.png';
import countUpMinimalTimerPic from '../../../../assets/images/countTimer/CountUpTimerMinimal.png';
import { disableRightClickIframe } from '../../../../utils/common';
import { CreateCountUpTimerFeedRequest } from '../../../../store/types/apps/countUpTimer';
import TextInput from '../../../TextInput';
import ColorPicker from '../../../ColorPicker';
import { getRgbaValuesFromString } from '../../../../utils/colorFunctions';
import DatePicker from '../../../DatePicker';
import Yup from '../../../../utils/validation';
import SwitchCheckbox from '../../../SwitchCheckbox';

type CreateCountUpTimerRequestFormMutated = Omit<
  CreateCountUpTimerFeedRequest,
  'config' | 'placement'
>;

const startTimeOptions = [
  { label: 'Start the Timer Now', value: 'Start the Timer Now' },
  { label: 'Select a Start Date', value: 'Select a Start Date' },
];

const styleOptions = [
  { label: 'Flip', value: 'flip' },
  { label: 'Minimal', value: 'minimal' },
];

interface CreateCountUpTimerFeedRequestFormPayload
  extends CreateCountUpTimerRequestFormMutated {
  startTime: string;
  startTimer?: Date;
  style?: string;
  backgroundColor?: string;
  text?: string;
  textColor?: string;
  showDays?: boolean;
}

export interface CreateFeedModalProps {
  onSubmit: (data: CreateCountUpTimerFeedRequest) => void;
  countUpTimerApp?: App;
  onClose?: () => void;
  isLoading: boolean;
}

export default ({
  onSubmit,
  onClose,
  isLoading,
  countUpTimerApp = {} as App,
}: CreateFeedModalProps) => {
  const { t } = useTranslation();

  const initialValues: CreateCountUpTimerFeedRequestFormPayload = {
    name: countUpTimerApp?.name ?? '',
    startTime:
      countUpTimerApp?.dependency?.config?.startTime ??
      startTimeOptions[0].value,
    backgroundColor:
      countUpTimerApp?.dependency?.config?.backgroundColor ??
      'rgba(248, 231, 28,100)',
    textColor:
      countUpTimerApp?.dependency?.config?.textColor ?? 'rgba(31, 30, 30,100)',
    text: countUpTimerApp?.dependency?.config?.text ?? '',
    startTimer: countUpTimerApp?.dependency?.config?.startTimer ?? new Date(),
    style: countUpTimerApp?.dependency?.config?.style ?? styleOptions[0].value,
    showDays: countUpTimerApp?.dependency?.config?.showDays ?? false,
  };
  const currentDate = new Date();

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9_-\s]+$/, t('common.validation.alphacharacter'))
      .defined(),

    text: Yup.string().required(t('apps.countTimer.required')),
  }).defined();

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<CreateCountUpTimerFeedRequestFormPayload>({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  //   const modalHeight = 400;

  const [feedName, setFeedName] = useStateWithHookForm<
    CreateCountUpTimerFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'name' }, initialValues.name);

  const [backgroundColor, setBackgroundColor] = useStateWithHookForm<
    CreateCountUpTimerFeedRequestFormPayload,
    string
  >(
    { setValue, trigger, name: 'backgroundColor' },
    initialValues.backgroundColor,
  );

  const [textColor, setTextColor] = useStateWithHookForm<
    CreateCountUpTimerFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'textColor' }, initialValues.textColor);

  const [text, setText] = useStateWithHookForm<
    CreateCountUpTimerFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'text' }, initialValues.text);

  const [style, setStyle] = useStateWithHookForm<
    CreateCountUpTimerFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'style' }, initialValues.style);

  const [startTime, setStartTime] = useStateWithHookForm<
    CreateCountUpTimerFeedRequestFormPayload,
    string
  >({ setValue, trigger, name: 'startTime' }, initialValues.startTime);

  const [startTimer, setStartTimer] = useStateWithHookForm<
    CreateCountUpTimerFeedRequestFormPayload,
    Date
  >({ setValue, trigger, name: 'startTime' }, initialValues.startTimer);

  const [showDays, setShowDays] = useStateWithHookForm<
    CreateCountUpTimerFeedRequestFormPayload,
    boolean
  >({ setValue, trigger, name: 'showDays' }, initialValues.showDays);

  const [isScheduled, setIsScheduled] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const handleOnSubmit = async (
    data: CreateCountUpTimerFeedRequestFormPayload,
  ) => {
    const { name } = data;
    const createCountUpTimerFeedRequest: CreateCountUpTimerFeedRequest = {
      name,
      isScheduled,
      config: {
        backgroundColor,
        startTime,
        startTimer,
        style,
        textColor,
        text,
        showDays,
      },
      zoneId: Orientation.Landscape,
    };

    onSubmit(createCountUpTimerFeedRequest);
  };

  React.useEffect(() => {
    disableRightClickIframe(feedName);
  }, []);

  React.useEffect(() => {
    if (startTimer > currentDate) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [startTimer]);

  return (
    <WithSpinner isLoading={isLoading} className="min-h-400px" size="md">
      <div className="container ps-0">
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          id="form"
          className="d-flex flex-column justify-content-between"
        >
          <div className="d-flex flex-row p-4 w-100">
            <div className="d-flex flex-column mx-2 w-100">
              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" required col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('apps.countTimer.name')}
                </label>
                <TextInput
                  name="name"
                  id="name"
                  value={feedName}
                  onChange={setFeedName}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
                <FormErrorMessage
                  name="name"
                  errors={errors}
                  className="my-1 px-2"
                />
              </p>

              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" required col-form-label text-lg-end fw-bolder required fs-6"
                >
                  {t('apps.countTimer.text')}
                </label>
                <TextInput
                  name="text"
                  id="text"
                  value={text}
                  onChange={setText}
                  className="form-control form-control-solid"
                  placeholder="Type here"
                  type="text"
                />
                <FormErrorMessage
                  name="text"
                  errors={errors}
                  className="my-1 px-2"
                />
              </p>
              <p className="mt-2 mb-0">
                <label
                  htmlFor="startTime"
                  className=" d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6 "
                >
                  {t('apps.countTimer.startTime')}
                </label>

                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={false}
                  name="uploadType"
                  options={startTimeOptions}
                  onChange={(val) => {
                    if (val?.value) setStartTime(val?.value);
                  }}
                  defaultValue={
                    countUpTimerApp?.dependency?.config?.startTimer
                      ? startTimeOptions[1]
                      : startTimeOptions[0]
                  }
                />
              </p>

              {startTime === startTimeOptions[1].value && (
                <>
                  <p className="mt-2 mb-0">
                    <label
                      htmlFor="startYear"
                      className="  d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                    >
                      {t('apps.countTimer.startDate')}
                    </label>
                    <DatePicker
                      name="startTimer"
                      value={startTimer}
                      placeholder="Select Date"
                      onChange={(newDate) =>
                        setStartTimer(new Date(newDate[0]))
                      }
                      options={{
                        enableTime: true,
                        defaultHour: 0,
                      }}
                    />
                    {/* <FormErrorMessage name="startTimer" errors={errors} /> */}
                    {disableButton && (
                      <div className="fv-plugins-message-container invalid-feedback formik-error-message">
                        {t('apps.countTimer.dateRestriction')}
                      </div>
                    )}
                  </p>
                </>
              )}

              <p className="mt-2 mb-0">
                <label
                  htmlFor="name"
                  className=" d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.countTimer.style')}
                </label>
                <Select
                  options={styleOptions}
                  onChange={(val) => {
                    if (val?.value) setStyle(val?.value);
                  }}
                  value={styleOptions.find((opt) => opt.value === style)}
                  isClearable
                  isSearchable={false}
                />
              </p>

              {style === 'minimal' && (
                <p className="mt-8 mb-0 d-inline-flex justify-content-between w-100">
                  <label
                    htmlFor="displayDate"
                    className=" text-dark fw-bolder my-1"
                  >
                    {t('apps.countTimer.showDays')} (
                    {t('apps.countTimer.daysLeftUp')})
                  </label>
                  <SwitchCheckbox
                    name="displayDate"
                    inputClassName="h-20px w-30px"
                    checked={!!showDays}
                    onChange={({ currentTarget: { checked } }) =>
                      setShowDays(checked)
                    }
                  />
                </p>
              )}
            </div>

            <div className="d-flex flex-column mx-2 w-100">
              <label
                htmlFor="name"
                className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
              >
                {t('apps.countTimer.previewExapmle')}
              </label>
              <img
                src={
                  style === 'flip'
                    ? countUpFlipTimerPic
                    : countUpMinimalTimerPic
                }
                alt="count down timer preview"
                className="mb-5"
                style={{ width: '100%' }}
              />
              <p className="mt-2 mb-0 d-inline-flex justify-content-between w-100">
                <label
                  htmlFor="name"
                  className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.countTimer.backgroundColor')}
                </label>
                <div className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6">
                  <ColorPicker
                    id="BgColor"
                    name="BgColor"
                    onSelect={(color) => {
                      setBackgroundColor(
                        `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                          color?.b ?? 0
                        }, ${color?.a ?? 1})`,
                      );
                    }}
                    value={getRgbaValuesFromString(backgroundColor)}
                  />
                </div>
              </p>

              <p className="mt-2 mb-0 d-inline-flex justify-content-between w-100">
                <label
                  htmlFor="name"
                  className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6"
                >
                  {t('apps.countTimer.textColor')}
                </label>
                <div className="d-inline-flex align-items-center col-form-label text-lg-end fw-bolder fs-6">
                  <ColorPicker
                    id="textColor"
                    name="textColor"
                    onSelect={(color) => {
                      setTextColor(
                        `rgba(${color?.r ?? 0}, ${color?.g ?? 0}, ${
                          color?.b ?? 0
                        }, ${color?.a ?? 1})`,
                      );
                    }}
                    value={getRgbaValuesFromString(textColor)}
                  />
                </div>
              </p>
            </div>
          </div>
          <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-white text-primary"
              onClick={onClose}
            >
              {t('common.cancel')}
            </button>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={disableButton}
              >
                {t('common.save')}
              </button>
              {!Object.keys(countUpTimerApp).length && (
                <button
                  onClick={() => setIsScheduled(true)}
                  type="submit"
                  className={clsx('btn btn-primary', styles.scheduleBtn)}
                  disabled={disableButton}
                >
                  {t('common.save_schedule')}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </WithSpinner>
  );
};
