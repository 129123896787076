import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import useLoading from '../../utils/hooks/useLoading';
import GlobalState from '../../store/reducers/globalState.interface';
import { defaultDebounceValue } from '../../constants/api/debounceSettings';
import UpdateDeviceGroupAction from '../../store/actions/devicesGroups/interfaces/updateDeviceGroupAction.interface';
import { readAllFromQueryParams } from '../../utils/queryParams';
import DevicesGroups from '../../components/DevicesGroups';
import {
  getDevicesGroups,
  updateDeviceGroup,
} from '../../store/actions/devicesGroups';

export default () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: GlobalState) => state.devicesGroups.loading,
  );
  const groups = useSelector(
    (state: GlobalState) => state.devicesGroups.groups,
  );
  const total = useSelector((state: GlobalState) => state.devicesGroups.total);
  const [loading, setLoading] = useLoading(isLoading);

  const dispatchGetDevicesGroups = useCallback(
    debounce(
      (recentSearch: string) =>
        dispatch(getDevicesGroups(readAllFromQueryParams(recentSearch))),
      defaultDebounceValue,
    ),
    [],
  );

  const dispatchUpdateDevicesGroup = (
    data: UpdateDeviceGroupAction['payload'],
  ) => {
    dispatch(updateDeviceGroup(data));
  };

  useEffect(() => {
    setLoading(true); // show preloader before dispatch
    dispatchGetDevicesGroups(search);
  }, [search]);

  return (
    <DevicesGroups
      groups={groups}
      total={total}
      isLoading={loading}
      onEditDeviceGroup={dispatchUpdateDevicesGroup}
      resetOnSearch
    />
  );
};
