/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import Aside from './Aside';
import Header from './Header';
import UserMenu from './UserMenu';
import PageHeader from './PageHeader';
import { KTSVG } from '../../lib/metronic/helpers';
import routesConfig from '../../routing/config';
import styles from './layout.module.scss';
import './layout.scss';
import LoginAsHeader from '../../components/LoginAsHeader';
import ExpiringModal from '../../components/Trial/ExpiringModal';
import GlobalState from '../../store/reducers/globalState.interface';
import Banner from '../../components/Banner';

export default ({ children }: { children: React.ReactNode }) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const toggleDrawer = () => setIsDrawerOpened(!isDrawerOpened);
  const closeDrawer = () => setIsDrawerOpened(false);
  const loginAsUserName = window.localStorage.getItem('loginAsUserName');

  const { isTrial } = useSelector((state: GlobalState) => state.userData.user);
  const handleClose = () => {};

  const trialExpireDays = useSelector(
    (state: GlobalState) => state.userData.trialExpireDays,
  );

  useEffect(() => {
    if (trialExpireDays === 0 && isTrial && !loginAsUserName) {
      const targetNode = document.getElementById('blockClickId');

      if (targetNode) {
        const immortalDivParent = targetNode.parentNode;
        // console.log('------- immortaldivParent', immortalDivParent);

        // Options for the observer (which mutations to observe)
        const config = {
          attributes: true,
          childList: true,
          subtree: true,
          attributeOldValue: true,
        };
        // Create an observer instance linked to the callback function
        const observer = new MutationObserver((mutationList: any, obs: any) => {
          for (const mutation of mutationList) {
            if (mutation.type === 'childList') {
              immortalDivParent?.appendChild(targetNode);
              obs.disconnect();
              obs.observe(immortalDivParent, config);
            } else if (mutation.type === 'attributes') {
              targetNode?.setAttribute(
                mutation.attributeName,
                mutation.oldValue,
              );
              obs.disconnect();
              obs.observe(targetNode, config);
            }
          }
        });

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
        // const interval = setInterval(() => {
        //   console.log('------------- console log', trialExpireDays);
        //   const el = document.getElementById('blockClickId');

        //   if (!el) {
        //     document.appendChild(targetNode);
        //   }
        // }, 1000);
        // return () => clearInterval(interval);
      }
    }
  }, [isTrial]);

  return (
    <>
      {trialExpireDays === 0 && isTrial && !loginAsUserName && (
        <>
          <div>
            <div
              id="blockClickId"
              className="blockClickClass"
              style={{
                position: 'fixed',
                top: '0',
                left: '0',
                zIndex: '1050',
                width: '100vw',
                height: '100vh',
                backgroundColor: '#000000',
                opacity: '0.3',
              }}
            />
          </div>
          <ExpiringModal isModalVisible handleClose={handleClose} />
        </>
      )}
      {loginAsUserName && <LoginAsHeader />}
      <div
        className={clsx(
          'page d-flex flex-row flex-column-fluid h-100',
          styles.page,
          loginAsUserName && 'mt-40px',
        )}
      >
        <Aside
          marginTop={!!loginAsUserName}
          isDrawerOpened={isDrawerOpened}
          onLinkClick={closeDrawer}
        />
        <div className="wrapper d-flex flex-column flex-row-fluid">
          <div className="header align-items-stretch position-relative start-0">
            <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1 w-100">
              <div className="d-flex align-items-center d-lg-none me-1">
                <div
                  className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                  role="toolbar"
                  onKeyPress={toggleDrawer}
                  onClick={toggleDrawer}
                >
                  <KTSVG
                    path="/media/icons/duotune/abstract/abs015.svg"
                    className="svg-icon-2x mt-1"
                  />
                </div>
                <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                  <Link to={routesConfig.home.route} className="d-lg-none">
                    <img
                      alt="Logo"
                      src="/media/svg/indigo.svg"
                      className="h-30px"
                    />
                  </Link>
                </div>
              </div>
              <Header />
              <UserMenu />
            </div>
          </div>
          <div>
            <PageHeader />
            <Banner />
            <div>
              <div className="container mw-100 pt-10">{children}</div>
            </div>
          </div>
        </div>
      </div>

      {isDrawerOpened && (
        <div
          aria-label="close drawer"
          id="drawer-backdrop"
          role="button"
          tabIndex={-1}
          onKeyPress={closeDrawer}
          onClick={closeDrawer}
          className="drawer-overlay"
        />
      )}
    </>
  );
};
