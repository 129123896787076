import AppType from '../enums/appType.enum';
import canva from '../assets/images/apps/canva.svg';
import googleDocs from '../assets/images/apps/google_docs.svg';
import sheets from '../assets/images/apps/sheets.svg';
import googleSlides from '../assets/images/apps/google_slides.svg';
import googleCalendar from '../assets/images/apps/google_calendar.svg';
import html5 from '../assets/images/apps/html5.svg';
import liveStream from '../assets/images/apps/liveStream.svg';
import msTeams from '../assets/images/apps/microsoft-teams.svg';
import countUpTimer from '../assets/images/apps/countUpTimer.svg';
import countDownTimer from '../assets/images/apps/countDownTimer.svg';
import daysSafe from '../assets/images/apps/daysSafe.svg';

export const appIconType: {
  [key: string]: { type: string; icon: string; colour: string; width: string };
} = {
  [AppType.SevereWeather]: {
    type: 'icon',
    icon: 'fas fa-bolt',
    colour: '#0000ff',
    width: '15px',
  },
  [AppType.EmergencyAlert]: {
    type: 'icon',
    icon: 'fas fa-bell',
    colour: '#000',
    width: '15px',
  },
  [AppType.Ticker]: {
    type: 'icon',
    icon: 'fas fa-bullhorn',
    colour: '#000',
    width: '15px',
  },
  [AppType.YouTube]: {
    type: 'icon',
    icon: 'fab fa-youtube',
    colour: '#ef2000',
    width: '15px',
  },
  [AppType.Weather]: {
    type: 'icon',
    icon: 'fas fa-cloud-sun',
    colour: '#ffa500',
    width: '15px',
  },
  [AppType.PowerBi]: {
    type: 'icon',
    icon: 'fas fa-signal',
    colour: '#bbbb24',
    width: '15px',
  },
  [AppType.MsTeams]: {
    type: 'svg',
    icon: msTeams,
    colour: '#1DA1F2',
    width: '15px',
  },
  [AppType.Rss]: {
    type: 'icon',
    icon: 'fas fa-rss-square',
    colour: '#ffa500',
    width: '15px',
  },
  [AppType.Canva]: {
    type: 'svg',
    icon: canva,
    colour: '#ffa500',
    width: '15px',
  },
  [AppType.GoogleDocs]: {
    type: 'svg',
    icon: googleDocs,
    colour: '#ffa500',
    width: '15px',
  },
  [AppType.GoogleSheet]: {
    type: 'svg',
    icon: sheets,
    colour: '#ffa500',
    width: '15px',
  },
  [AppType.GoogleSlides]: {
    type: 'svg',
    icon: googleSlides,
    colour: '#ffa500',
    width: '15px',
  },
  [AppType.GoogleCalendar]: {
    type: 'svg',
    icon: googleCalendar,
    colour: '#ffa500',
    width: '15px',
  },
  [AppType.Html]: {
    type: 'svg',
    icon: html5,
    colour: '#ffa500',
    width: '15px',
  },
  [AppType.Twitter]: {
    type: 'icon',
    icon: 'fab fa-twitter',
    colour: '#1DA1F2',
    width: '15px',
  },
  [AppType.Facebook]: {
    type: 'icon',
    icon: 'fab fa-facebook',
    colour: '#1DA1F2',
    width: '15px',
  },
  [AppType.Instagram]: {
    type: 'icon',
    icon: 'fab fa-instagram',
    colour: '#8a3ab9',
    width: '15px',
  },
  [AppType.QrCode]: {
    type: 'icon',
    icon: 'fa fa-qrcode',
    colour: '#1DA1F2',
    width: '15px',
  },

  [AppType.CountDownTimer]: {
    type: 'svg',
    icon: countDownTimer,
    colour: '#1DA1F2',
    width: '15px',
  },
  [AppType.CountUpTimer]: {
    type: 'svg',
    icon: countUpTimer,
    colour: '#1DA1F2',
    width: '15px',
  },
  [AppType.DaysSafe]: {
    type: 'svg',
    icon: daysSafe,
    colour: '#1DA1F2',
    width: '15px',
  },
  [AppType.LiveStream]: {
    type: 'svg',
    icon: liveStream,
    colour: '#1DA1F2',
    width: '15px',
  },
};
