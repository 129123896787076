/* eslint-disable no-nested-ternary */
import { compact, debounce, omit, uniq } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import clsx from 'clsx';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
// import FileTreeView from './FileTreeView';
import { KTSVG } from '../../lib/metronic/helpers';
import Pagination from '../../components/Pagination';
import Search from '../../components/Search';
// import ButtonDropdown from '../../components/Dropdown/ButtonDropdown';

import UploadContent from '../../components/Aside/UploadContent';
import GlobalState from '../../store/reducers/globalState.interface';
import {
  getFilesActionCreator,
  deleteFilesActionCreator,
  renameFileActionCreator,
  moveFileActionCreator,
  deleteFilesResetActionCreator,
  renameFileResetActionCreator,
  moveFileResetActionCreator,
  createFileResetActionCreator,
  scheduleFileResetActionCreator,
  scheduleFileActionCreator,
} from '../../store/actions/fileManager';

import {
  GetFilesRequest,
  SortBy,
  FileSchema,
  FileMimeType,
  RenameFileRequest,
  CreateFileRequest,
  ScheduleFileRequest,
  MoveFileRequest,
} from '../../store/types/fileManager';

import CreateContentRequestData from '../../api/endpoints/contents/interfaces/createContentRequestData.interface';
import FilterModal from './FilterModal';
import MoveToFolderModal from './MoveToFolderModal';
import DeleteFileModal from './DeleteFileModal';
import RenameFileModal from './RenameFileModal';
import FileManagerBreadcrumb, { ClickedFolder } from './FileManagerBreadcrumb';
import ErrorFiles from './ErrorFiles';
import CreateFolderModal from './CreateFolderModal';
import CreateFileModal from './CreateFileModal';
import TableView from './TableView';
import PreviewFileModal from './PreviewFileModal';
import EmptyFiles from './EmptyFiles';
import api from '../../api';
import useOnEventEmitterEvent from '../../utils/hooks/useOnEventEmitterEvent';
import ConversionJob from '../../interfaces/conversionJob.interface';
import {
  completedStatuses,
  conversionJobEvents,
} from '../../constants/conversionJob';
import ConversionJobStatus from '../../enums/conversionJobStatus.enum';
import { mediaFileTypes } from '../../constants/file';
import { start } from '../../webWorkers/conversionJobStatus';
import ConversionJobEvents from '../../enums/events/conversionJobEvents.enum';
import eventEmitter from '../../utils/eventEmitter';
import { PlaylistEvents } from '../../enums/events/playlistEvents.enum';

export type ViewMode = 'table' | 'grid';

export interface FileManagerProps {
  isPublicState?: boolean;
}

interface ConversionJobData {
  file?: File;
  isSeparate?: boolean;
  isOrdered?: boolean;
  isMyContent?: boolean;
}

const FileManager = ({ isPublicState = false }: FileManagerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    loading: filesLoading,
    deleteFilesLoading,
    renameFileLoading,
    moveFilesLoading,
    data: paginatedFiles,
    error,
    isGetFilesSuccess,
    isDeleteFilesSuccess,
    isRenameFileSuccess,
    isMoveFileSuccess,
    isCreateFileSuccess,
    isScheduleFileSuccess,
  } = useSelector((state: GlobalState) => state.fileManager);

  const [searchValue, setSearchValue] = React.useState<string>('');

  const [isSearching, setIsSearching] = React.useState<boolean>(false);

  const [getFilesRequest, setGetFilesRequest] = React.useState<GetFilesRequest>(
    {
      params: {
        limit: 10,
        page: paginatedFiles.meta.currentPage,
        search: searchValue,
        type: null,
        directoryId: null,
        onlyDir: false,
        sortBy: SortBy.DIRECTORY,
        isPublic: isPublicState,
      },
    },
  );

  const { params } = getFilesRequest;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewMode, setViewMode] = React.useState<ViewMode>('table');
  const [isUploadingFile, setIsUploadingFile] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [uploadInProgress, setUploadInProgress] =
    React.useState<boolean>(false);
  const conversionJobDataRef = React.useRef<
    ConversionJobData & { id?: number }
  >({});
  const [conversionJobs, setConversionJobs] = React.useState<ConversionJob[]>(
    [],
  );

  const [selectedFileSchema, setSelectedFileSchema] =
    React.useState<FileSchema | null>(null);

  const [showFilterModal, setShowFilterModal] = React.useState<boolean>(false);

  const [showRenameFileModal, setShowRenameFileModal] =
    React.useState<boolean>(false);

  const [showCreateFolderModal, setShowCreateFolderModal] =
    React.useState<boolean>(false);
  const [showCreateFileModal, setShowCreateFileModal] =
    React.useState<boolean>(false);

  const [showDeleteFileModal, setShowDeleteFileModal] =
    React.useState<boolean>(false);

  const [showMoveToFolderModal, setShowMoveToFolderModal] =
    React.useState<boolean>(false);

  const [showFilePreviewModal, setShowFilePreviewModal] =
    React.useState<boolean>(false);

  const [showScheduleFileModal, setShowScheduleFileModal] =
    React.useState<boolean>(false);

  const [directoryChain, setDirectoryChain] = React.useState<
    Array<ClickedFolder>
  >([]);

  // callbacks/handlers
  const handleSearchValueChange = React.useCallback((value: string) => {
    setSearchValue(value);
    setIsSearching(true);
  }, []);

  // rename file modal handler
  const handleOpenRenameFileModal = React.useCallback((file: FileSchema) => {
    setSelectedFileSchema(file);
    setShowRenameFileModal(true);
  }, []);

  const handleCloseRenameFileModal = React.useCallback(() => {
    setSelectedFileSchema(null);
    setShowRenameFileModal(false);
  }, []);

  // upload content modal handler (schedule modal)
  const handleOpenScheduleFileModal = React.useCallback((file: FileSchema) => {
    setSelectedFileSchema(file);
    setShowScheduleFileModal(true);
  }, []);

  const handleCloseScheduleFileModal = React.useCallback(() => {
    setSelectedFileSchema(null);
    setShowScheduleFileModal(false);
  }, []);

  // file preview modal handler
  const handleOpenFilePreviewModal = React.useCallback((file: FileSchema) => {
    setSelectedFileSchema(file);
    setShowFilePreviewModal(true);
  }, []);

  const handleCloseFilePreviewModal = React.useCallback(() => {
    setSelectedFileSchema(null);
    setShowFilePreviewModal(false);
  }, []);

  // folder modal handler
  const handleOpenCreateFolderModal = React.useCallback(
    () => setShowCreateFolderModal(true),
    [],
  );

  const handleCloseCreateFolderModal = React.useCallback(
    () => setShowCreateFolderModal(false),
    [],
  );

  // delete file modal handler
  const handleOpenDeleteFileModal = React.useCallback((file: FileSchema) => {
    setSelectedFileSchema(file);
    setShowDeleteFileModal(true);
  }, []);

  const handleCloseDeleteFileModal = React.useCallback(() => {
    setSelectedFileSchema(null);
    setShowDeleteFileModal(false);
  }, []);

  // move to folder modal handler
  const handleOpenMoveToFolderModal = React.useCallback((file: FileSchema) => {
    setSelectedFileSchema(file);
    setShowMoveToFolderModal(true);
  }, []);

  const handleCloseMoveToFolderModal = React.useCallback(() => {
    setSelectedFileSchema(null);
    setShowMoveToFolderModal(false);
  }, []);

  // create file modal handler
  const handleOpenCreateFileModal = React.useCallback(
    () => setShowCreateFileModal(true),
    [],
  );

  const handleCloseCreateFileModal = React.useCallback(
    () => setShowCreateFileModal(false),
    [],
  );

  // filter files modal handler
  // const handleOpenFilterModal = React.useCallback(
  //   () => setShowFilterModal(true),
  //   [],
  // );

  const handleCloseFilterModal = React.useCallback(
    () => setShowFilterModal(false),
    [],
  );

  const handleOpenActionsDropdown = React.useCallback(
    (file: FileSchema) => setSelectedFileSchema(file),
    [],
  );

  // const handleSetTableViewMode = React.useCallback(
  //   () => setViewMode('table'),
  //   [],
  // );

  // const handleSetGridViewMode = React.useCallback(
  //   () => setViewMode('grid'),
  //   [],
  // );

  const handleClose = (withoutCancelling?: boolean) => {
    setShowCreateFileModal(false);

    if (withoutCancelling) {
      conversionJobDataRef.current = {};

      return;
    }

    const { id } = conversionJobDataRef.current;

    if (id) {
      api.conversionJobs.cancelConversionJob(id);

      conversionJobDataRef.current = {};
    }
  };

  const handleOnPageNumberUpdate = React.useCallback(
    (pageNumber: number) =>
      dispatch(
        getFilesActionCreator({
          ...getFilesRequest,
          params: { ...params, page: pageNumber },
        }),
      ),
    [dispatch, getFilesRequest, params],
  );

  const handleOnFilterModalSubmit = React.useCallback(
    (fileTypeFilter: FileMimeType | null) => {
      const directoryId =
        directoryChain.length === 0
          ? null
          : directoryChain[directoryChain.length - 1].id;

      dispatch(
        getFilesActionCreator({
          ...getFilesRequest,
          params: {
            ...params,
            directoryId,
            type: fileTypeFilter,
          },
        }),
      );
    },
    [directoryChain, dispatch, getFilesRequest, params],
  );

  const handleDeleteFile = React.useCallback(
    ({ id: fileId, isDirectory }: FileSchema) =>
      dispatch(
        deleteFilesActionCreator({
          params: { fileIds: [fileId] },
          isFolder: isDirectory,
        }),
      ),
    [dispatch],
  );

  const handleFetchFilesOnFileClick = React.useCallback(
    (
      id: string,
      name: string,
      currentDirectoryChain: Array<ClickedFolder>,
      fromTreeView?: boolean,
    ) => {
      let chain = [...currentDirectoryChain, { id, name }];

      if (fromTreeView) {
        chain = currentDirectoryChain;
      }

      setDirectoryChain(chain);
      setGetFilesRequest({ params: { ...params, directoryId: id } });
      dispatch(
        getFilesActionCreator({
          ...getFilesRequest,
          params: { ...params, directoryId: id },
        }),
      );
    },
    [dispatch, getFilesRequest, params],
  );

  const handleClickOnFile = React.useCallback(
    async (file: FileSchema) => {
      if (file.isDirectory) {
        const { id, title: name } = file;

        handleFetchFilesOnFileClick(id, name, directoryChain, false);
      } else {
        // preview file

        handleOpenFilePreviewModal(file);
      }
    },
    [directoryChain, handleFetchFilesOnFileClick, handleOpenFilePreviewModal],
  );

  const handleClickOnBreadcrumbItem = React.useCallback(
    ({ id }: ClickedFolder) => {
      const newDirectory = directoryChain;
      const itemIndex = directoryChain.findIndex(
        (directory) => directory.id === id,
      );

      newDirectory.length = itemIndex + 1;

      setDirectoryChain(newDirectory);

      dispatch(
        getFilesActionCreator({
          ...getFilesRequest,
          params: { ...params, directoryId: id },
        }),
      );
    },
    [directoryChain, dispatch, getFilesRequest, params],
  );

  const handleResetBreadcrumbItems = React.useCallback(() => {
    const directoryId =
      directoryChain.length === 0
        ? null
        : directoryChain[directoryChain.length - 1].id;
    dispatch(
      getFilesActionCreator({
        ...getFilesRequest,
        params: {
          ...params,
          directoryId,
        },
      }),
    );
  }, [dispatch, getFilesRequest, params]);

  const handleRenameFile = React.useCallback(
    ({ fileId, name }: RenameFileRequest) =>
      dispatch(renameFileActionCreator({ name, fileId })),
    [dispatch],
  );

  const handleCreateFile = async (createFolderRequest: CreateFileRequest) => {
    const requestData = createFolderRequest;
    const conversionJobId = conversionJobDataRef.current.id;

    if (conversionJobId) {
      requestData.conversionJobId = conversionJobId;
    }

    const directoryId =
      directoryChain.length === 0
        ? null
        : directoryChain[directoryChain.length - 1].id;

    setIsLoading(true);

    try {
      await api.fileManager.createFileService(requestData);
      if (requestData.isDirectory) {
        dispatch(
          getFilesActionCreator({
            ...getFilesRequest,
            params: {
              ...params,
              directoryId,
            },
          }),
        );
      }

      const currentJob = conversionJobId
        ? await api.conversionJobs.getConversionJob(conversionJobId)
        : undefined;

      if (
        mediaFileTypes.includes(
          createFolderRequest.file?.type as FileMimeType,
        ) ||
        currentJob?.status === ConversionJobStatus.Done
      ) {
        dispatch(
          getFilesActionCreator({
            ...getFilesRequest,
            params: {
              ...params,
              directoryId,
            },
          }),
        );

        toast.success(i18next.t<string>('contents.upload_success'));
      } else {
        if (!conversionJobDataRef.current.id) {
          await start();
        }

        setUploadInProgress(true);

        if (currentJob) {
          eventEmitter.emit(
            `${ConversionJobEvents.ConversionJobPrefix}.${currentJob.status}`,
            currentJob,
          );
        }
      }

      handleClose(true);
      handleCloseCreateFolderModal();
    } finally {
      setIsLoading(false);
    }

    dispatch(
      createFileResetActionCreator({
        loading: false,
        isCreateFileSuccess: false,
        error: null,
      }),
    );
  };

  const handleScheduleFile = React.useCallback(
    async (request: Partial<CreateContentRequestData>, reset: () => void) => {
      let scheduleFileRequest: Partial<ScheduleFileRequest> = {
        ...request,
        fileId: (selectedFileSchema as FileSchema).id,
      };
      setIsLoading(true);
      if (scheduleFileRequest.onlyCurrentSelected) {
        try {
          let selectedPlaylists: string[] = [];
          if (scheduleFileRequest.deviceGroupsIds) {
            // add deviceGroupIds criteria
            const { items } = await api.devices.getDevicesList({
              limit: '400',
              deviceGroupIds: scheduleFileRequest.deviceGroupsIds,
            });

            selectedPlaylists = [
              ...selectedPlaylists,
              ...compact(items.map((device) => device?.currentPlaylist?.id)),
            ];
          }
          if (scheduleFileRequest.deviceIds) {
            const { items } = await api.devices.getDevicesList({
              limit: '400',
            });

            selectedPlaylists = [
              ...selectedPlaylists,
              ...compact(
                items.map((device) =>
                  scheduleFileRequest.deviceIds?.includes(device.id)
                    ? device?.currentPlaylist?.id
                    : null,
                ),
              ),
            ];
          } else if (
            !scheduleFileRequest.deviceIds &&
            !scheduleFileRequest.deviceGroupsIds &&
            scheduleFileRequest
          ) {
            selectedPlaylists = compact(scheduleFileRequest?.playlistIds);
          }
          if (!selectedPlaylists || selectedPlaylists.length === 0) {
            toast.error(i18next.t('contents.validation.no_current_playlist'));
            return;
          }

          scheduleFileRequest.playlistIds = [...uniq(selectedPlaylists)];

          scheduleFileRequest = omit(scheduleFileRequest, [
            'onlyCurrentSelected',
          ]);
        } catch (e: any) {
          toast.error(i18next.t(e?.message ?? ''));
          return;
        }
      }

      dispatch(
        scheduleFileActionCreator(scheduleFileRequest as ScheduleFileRequest),
      );

      reset();
      setIsLoading(false);
    },
    [dispatch, selectedFileSchema],
  );

  const handleMoveFileToFolder = React.useCallback(
    ({ fileIds, targetDirectoryId }: MoveFileRequest) =>
      dispatch(moveFileActionCreator({ fileIds, targetDirectoryId })),
    [dispatch],
  );

  const debounceOnLastSearchValue = React.useMemo(
    () =>
      debounce((value) => {
        setGetFilesRequest({
          ...getFilesRequest,
          params: { ...params, search: value },
        });
      }, 1000),
    [getFilesRequest, params],
  );

  // effects
  React.useEffect(() => {
    if (isSearching) {
      debounceOnLastSearchValue(searchValue);
      setIsSearching(false);
    }
  }, [debounceOnLastSearchValue, isSearching, searchValue]);

  React.useEffect(() => {
    const directoryId =
      directoryChain.length === 0
        ? null
        : directoryChain[directoryChain.length - 1].id;

    dispatch(
      getFilesActionCreator({
        ...getFilesRequest,
        params: { ...params, directoryId },
      }),
    );
  }, [directoryChain, dispatch, getFilesRequest, params]);

  // effects after operations
  React.useEffect(() => {
    const directoryId =
      directoryChain.length === 0
        ? null
        : directoryChain[directoryChain.length - 1].id;

    if (isDeleteFilesSuccess) {
      dispatch(
        getFilesActionCreator({
          ...getFilesRequest,
          params: { ...params, directoryId },
        }),
      );
      handleCloseDeleteFileModal();
      dispatch(
        deleteFilesResetActionCreator({
          loading: false,
          isDeleteFilesSuccess: false,
          error: null,
        }),
      );
    }

    if (isRenameFileSuccess) {
      toast.success(i18next.t<string>('file_uploader.file_renamed'));

      dispatch(
        getFilesActionCreator({
          ...getFilesRequest,
          params: { ...params, directoryId },
        }),
      );

      handleCloseRenameFileModal();
      dispatch(
        renameFileResetActionCreator({
          loading: false,
          isRenameFileSuccess: false,
          error: null,
        }),
      );
    }

    if (isMoveFileSuccess) {
      toast.success(i18next.t<string>('file_uploader.file_moved'));
      dispatch(
        getFilesActionCreator({
          ...getFilesRequest,
          params: { ...params, directoryId },
        }),
      );
      handleCloseMoveToFolderModal();
      dispatch(
        moveFileResetActionCreator({
          loading: false,
          isMoveFileSuccess: false,
          error: null,
        }),
      );
    }

    if (isCreateFileSuccess) {
      toast.success(i18next.t<string>('file_uploader.file_created'));
      dispatch(
        getFilesActionCreator({
          ...getFilesRequest,
          params: { ...params, directoryId },
        }),
      );

      // TODO: differentiate between closing file or folder modal by payload type creation
      handleCloseCreateFolderModal();
      handleCloseCreateFileModal();

      dispatch(
        createFileResetActionCreator({
          loading: false,
          isCreateFileSuccess: false,
          error: null,
        }),
      );
    }

    if (isScheduleFileSuccess) {
      toast.success(i18next.t<string>('file_uploader.file_scheduled'));
      dispatch(
        getFilesActionCreator({
          ...getFilesRequest,
          params: { ...params, directoryId },
        }),
      );
      handleCloseScheduleFileModal();
      dispatch(
        scheduleFileResetActionCreator({
          loading: false,
          isScheduleFileSuccess: false,
          error: null,
        }),
      );
    }
  }, [
    directoryChain,
    dispatch,
    getFilesRequest,
    handleCloseCreateFileModal,
    handleCloseCreateFolderModal,
    handleCloseDeleteFileModal,
    handleCloseMoveToFolderModal,
    handleCloseRenameFileModal,
    handleCloseScheduleFileModal,
    isCreateFileSuccess,
    isDeleteFilesSuccess,
    isMoveFileSuccess,
    isRenameFileSuccess,
    isScheduleFileSuccess,
    params,
  ]);

  const fileManagerBreadcrumbRootText = React.useMemo(
    () => `My ${params.isPublic ? 'Public' : 'Private'} Content`,
    [params.isPublic],
  );

  /**
   * File uploader data update handler
   * @param data
   */
  const onFileDataUpdate = async (
    data: ConversionJobData,
  ): Promise<boolean> => {
    conversionJobDataRef.current = { ...conversionJobDataRef.current, ...data };
    const { id, file } = conversionJobDataRef.current;

    try {
      if (
        Object.prototype.hasOwnProperty.call(data, 'file') &&
        !data.file &&
        id
      ) {
        setIsUploadingFile(true);

        await api.conversionJobs.cancelConversionJob(id);

        setIsUploadingFile(false);

        delete conversionJobDataRef.current.id;
      } else if (!mediaFileTypes.includes(file?.type as FileMimeType)) {
        if (id) {
          const updatedJob = await api.conversionJobs.updateConversionJob(
            id,
            data,
          );

          setIsUploadingFile(false);

          if (data.file) {
            conversionJobDataRef.current.id = updatedJob.id;
          }
        } else if (file) {
          setIsUploadingFile(true);

          const jobData = await api.conversionJobs.createConversionJob(
            conversionJobDataRef.current,
          );

          setIsUploadingFile(false);

          conversionJobDataRef.current.id = jobData.id;

          await start();
        }
      }
    } catch (e) {
      console.log(e);
      setIsUploadingFile(false);

      return false;
    }

    return true;
  };

  useOnEventEmitterEvent(
    conversionJobEvents,
    ({ status }: ConversionJob) => {
      if (status === ConversionJobStatus.Failed) {
        toast.error(i18next.t<string>('contents.upload_error'));
      }

      if (!uploadInProgress) return;

      if (status === ConversionJobStatus.Done) {
        dispatch(
          getFilesActionCreator({
            ...getFilesRequest,

            params: {
              ...params,
              directoryId:
                directoryChain.length === 0
                  ? null
                  : directoryChain[directoryChain.length - 1].id,
            },
          }),
        );
        toast.success(i18next.t<string>('file_uploader.file_created'));
      }
    },
    { deps: [uploadInProgress] },
  );

  const refreshConversionJobs = async () => {
    try {
      const jobs = await api.conversionJobs.getFilesConversionJobs();

      setConversionJobs(jobs);
    } catch (e) {
      console.log(e);
    }
  };

  useOnEventEmitterEvent(PlaylistEvents.Refresh, () => {
    refreshConversionJobs();
  });

  useOnEventEmitterEvent(
    conversionJobEvents,
    (job: ConversionJob) => {
      const { status } = job;
      if (completedStatuses.includes(status)) {
        setConversionJobs(conversionJobs.filter(({ id }) => id !== job.id));
      } else if (
        conversionJobs.findIndex(
          (conversionJob) => conversionJob.id === job.id,
        ) === -1
      ) {
        setConversionJobs([...conversionJobs, job]);
      } else {
        setConversionJobs(
          conversionJobs.map((conversionJob) =>
            conversionJob.id === job.id ? job : conversionJob,
          ),
        );
      }
    },
    { deps: [conversionJobs] },
  );

  // const resetTree = React.useMemo(
  //   () =>
  //     isMoveFileSuccess ||
  //     isDeleteFilesSuccess ||
  //     isRenameFileSuccess ||
  //     isScheduleFileSuccess ||
  //     isCreateFileSuccess,
  //   [
  //     isCreateFileSuccess,
  //     isDeleteFilesSuccess,
  //     isMoveFileSuccess,
  //     isRenameFileSuccess,
  //     isScheduleFileSuccess,
  //   ],
  // );

  React.useEffect(() => {
    refreshConversionJobs();
  }, []);

  return (
    <div className="d-flex bg-white row">
      {/* <div className="bg-white p-6 col-sm-12 col-md-3"> */}
      {/* <FileTreeView
          fileTreeViewLabel={fileManagerBreadcrumbRootText}
          isPublicState={params.isPublic}
          fetchAllFilesOnFileTreeItemClick={handleFetchFilesOnFileClick}
          resetFetch={handleResetBreadcrumbItems}
          key={`reset-${resetTree}`}
        /> */}
      {/* </div> */}
      <div className="bg-white p-6 d-flex flex-column flex-grow-1 col-sm-12 col-md-8">
        <div className="bg-white d-flex flex-column w-100">
          <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-4">
            <Search
              onChange={handleSearchValueChange}
              placeholder="Search"
              className="override-input-search-field-width-file-manager"
              searchValue={searchValue}
              wrapperClassName="mx-md-2"
            />

            <div className="d-flex flex-column flex-sm-row">
              {/* <button */}
              {/*  type="button" */}
              {/*  className="btn btn-light-primary d-flex flex-center" */}
              {/*  onClick={handleOpenFilterModal} */}
              {/* > */}
              {/*  <KTSVG */}
              {/*    path="/media/icons/duotune/general/gen031.svg" */}
              {/*    className="svg-icon-2" */}
              {/*  /> */}
              {/*  <span>Filter</span> */}
              {/* </button> */}

              <button
                type="button"
                className="btn btn-light-primary my-2 my-sm-0 mx-sm-2 d-flex flex-center"
                onClick={handleOpenCreateFolderModal}
              >
                <KTSVG
                  path="/media/icons/duotune/files/fil013.svg"
                  className="svg-icon-2"
                />
                <span>New Folder</span>
              </button>
              <button
                type="button"
                className="btn btn-primary d-flex flex-center"
                onClick={handleOpenCreateFileModal}
              >
                <KTSVG
                  path="/media/icons/duotune/files/fil018.svg"
                  className="svg-icon-2"
                />
                <span>Upload Files</span>
              </button>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-start">
            <FileManagerBreadcrumb
              directories={directoryChain}
              clickOnBreadcrumbItem={handleClickOnBreadcrumbItem}
              resetBreadcrumbItems={() => {
                setGetFilesRequest({
                  params: { ...params, directoryId: null },
                });
                setDirectoryChain([]);
              }}
              rootText={fileManagerBreadcrumbRootText}
            />
            {/* <div className="d-flex"> */}
            {/*  <button */}
            {/*    type="button" */}
            {/*    className={clsx( */}
            {/*      'btn btn-icon btn-bg-light btn-active-light-primary', */}
            {/*      { 'btn-color-primary': viewMode === 'table' }, */}
            {/*    )} */}
            {/*    onClick={handleSetTableViewMode} */}
            {/*  > */}
            {/*    <KTSVG */}
            {/*      path="/media/icons/duotune/abstract/abs015.svg" */}
            {/*      className="svg-icon-2" */}
            {/*    /> */}
            {/*  </button> */}
            {/*  <button */}
            {/*    type="button" */}
            {/*    className={clsx( */}
            {/*      'btn btn-icon btn-bg-light btn-active-light-primary mx-2', */}
            {/*      { 'btn-color-primary': viewMode === 'grid' }, */}
            {/*    )} */}
            {/*    onClick={handleSetGridViewMode} */}
            {/*  > */}
            {/*    <KTSVG */}
            {/*      path="/media/icons/duotune/general/gen025.svg" */}
            {/*      className="svg-icon-2" */}
            {/*    /> */}
            {/*  </button> */}
            {/*  <ButtonDropdown */}
            {/*    text="All Connected" */}
            {/*    items={[]} */}
            {/*    cardDropdownClassName="m-0 align-items-start" */}
            {/*  /> */}
            {/* </div> */}
          </div>
        </div>

        <div className="bg-white d-flex flex-column w-100">
          {!isGetFilesSuccess && error ? (
            <ErrorFiles error={error} />
          ) : viewMode === 'table' ? (
            <TableView
              clickOnFile={handleClickOnFile}
              openRenameFileModal={handleOpenRenameFileModal}
              openDeleteFileModal={handleOpenDeleteFileModal}
              openMoveToFolderModal={handleOpenMoveToFolderModal}
              openActionsDropdown={handleOpenActionsDropdown}
              openScheduleFileModal={handleOpenScheduleFileModal}
              selectedFile={selectedFileSchema}
              isLoading={filesLoading}
              paginatedFiles={paginatedFiles}
              conversionJobs={conversionJobs}
            />
          ) : (
            <EmptyFiles descriptionText="Grid view mode is soon to be implemented." />
          )}

          <Pagination
            total={paginatedFiles.meta.totalItems}
            pageSize={paginatedFiles.meta.itemsPerPage}
            pageNumber={paginatedFiles.meta.currentPage}
            onPageNumberUpdate={handleOnPageNumberUpdate}
            className="mt-3 mb-5"
          />
        </div>
      </div>

      {/** Filter Modal */}
      {showFilterModal ? (
        <FilterModal
          withHeader
          title="Filter Options"
          show={showFilterModal}
          onHide={handleCloseFilterModal}
          widthDefaultSize={false}
          onSubmit={handleOnFilterModalSubmit}
          isLoading={filesLoading}
        />
      ) : null}

      {/** Rename File Modal */}
      {showRenameFileModal ? (
        <RenameFileModal
          show={showRenameFileModal}
          onHide={handleCloseRenameFileModal}
          title="Rename File"
          onSubmit={handleRenameFile}
          selectedFileSchema={selectedFileSchema as FileSchema}
          isLoading={renameFileLoading}
        />
      ) : null}

      {/** Create Folder Modal */}
      {showCreateFolderModal ? (
        <CreateFolderModal
          show={showCreateFolderModal}
          resetFetch={handleResetBreadcrumbItems}
          onHide={handleCloseCreateFolderModal}
          title="New Folder"
          onSubmit={handleCreateFile}
          isLoading={isLoading}
          directoryId={
            directoryChain.length === 0
              ? null
              : directoryChain[directoryChain.length - 1].id
          }
          isPublicState={params.isPublic}
          disableTypeSelection
        />
      ) : null}

      {/** Create File Modal */}
      {showCreateFileModal ? (
        <CreateFileModal
          show={showCreateFileModal}
          onHide={handleCloseCreateFileModal}
          isUploadingFile={isUploadingFile}
          title="Upload File"
          onSubmit={handleCreateFile}
          isLoading={isLoading}
          directoryId={
            directoryChain.length === 0
              ? null
              : directoryChain[directoryChain.length - 1].id
          }
          isPublicState={params.isPublic}
          disableTypeSelection
          onFileDataUpdate={onFileDataUpdate}
        />
      ) : null}

      {/** Delete File Modal */}
      {showDeleteFileModal ? (
        <DeleteFileModal
          show={showDeleteFileModal}
          onHide={handleCloseDeleteFileModal}
          title={`${t('common.delete')} ${
            selectedFileSchema?.isDirectory
              ? t('common.directory')
              : t('common.file')
          }`}
          onSubmit={handleDeleteFile}
          selectedFileSchema={selectedFileSchema as FileSchema}
          isLoading={deleteFilesLoading}
        />
      ) : null}

      {/** Move to Folder Modal */}
      {showMoveToFolderModal ? (
        <MoveToFolderModal
          show={showMoveToFolderModal}
          onHide={handleCloseMoveToFolderModal}
          title="Move File"
          params={params.isPublic}
          onSubmit={handleMoveFileToFolder}
          selectedFileSchema={selectedFileSchema as FileSchema}
          isLoading={moveFilesLoading}
        />
      ) : null}

      {/** File Preview Modal */}
      {showFilePreviewModal ? (
        <PreviewFileModal
          show={showFilePreviewModal}
          onHide={handleCloseFilePreviewModal}
          title="Preview File"
          selectedFileSchema={selectedFileSchema as FileSchema}
        />
      ) : null}

      {/** Schedule File Modal */}
      {showScheduleFileModal ? (
        <UploadContent
          onClose={handleCloseScheduleFileModal}
          onSubmit={handleScheduleFile}
          includeFileUpload={false}
          isLoading={isLoading}
          defaultStepsValues={{
            name: (selectedFileSchema as FileSchema)?.title,
            duration: (selectedFileSchema as FileSchema)?.duration?.toString(),
          }}
          isVisible={showScheduleFileModal}
        />
      ) : null}
    </div>
  );
};

export default FileManager;
