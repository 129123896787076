import axios from 'axios';
import { TWITTER_MICROSERVICE } from '../../../../constants/api';

export const api = axios.create({
  baseURL: TWITTER_MICROSERVICE,
  responseType: 'json',
});

export const Apis = {
  getInstagramFeeds: (AccessToken?: string) =>
    api.post(`insta/getFeeds`, { AccessToken }),
  instagramLogin: (code?: string) => api.post(`insta/login`, { code }),
};
