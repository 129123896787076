import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useQuery } from '@tanstack/react-query';
import Table from '../../Table';
import Columns from '../../Table/Columns';
import WithSpinner from '../../WithSpinner';
import TableRow from './TableRow';
import { readFromQueryString } from '../../../utils/queryParams';
import SortOrder from '../../../enums/sortOrder.enum';
import api from '../../../api';
import UserLogs from '../../../interfaces/userLogs.interface';
import Pagination from '../../Pagination';

type DataType = {
  data?: {
    tags: string[];
    zoneId: string;
    endDate: string;
    duration: string;
    weekDays: number[];
    startDate: string;
    playlistId: string;
  };
  contents?: string[];
  totalItems?: number;
};

export default ({ item }: { item?: UserLogs }) => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);

  const { isLoading, error, data } = useQuery({
    queryKey: ['logsMeta'],
    queryFn: async () => {
      const d = (await api.usersLogs.getLogsMetaByLogId({
        logId: item?.id,
        userId: item?.user?.id,
        page,
      })) as DataType;

      setTotal(d?.totalItems || 0);

      return d;
    },
  });

  console.log({ isLoading, error, data });

  const [sortField, setSortField] = useState({
    field: readFromQueryString(search, ''),
    isInverted: readFromQueryString(search, '') === SortOrder.Ascending,
  });

  const columns = [
    {
      name: t('logs.table.contentName'),
    },
    {
      name: t('logs.table.zone'),
    },
    {
      name: t('logs.table.startDate'),
    },
    {
      name: t('logs.table.endDate'),
    },
    {
      name: t('logs.table.duration'),
    },
    {
      name: t('logs.table.tags'),
    },
  ];
  return (
    <>
      <Table style={{ minHeight: '400px' }}>
        <Table.Head.Row>
          <Columns
            columns={columns}
            config={{
              sortField,
              setSortField,
            }}
          />
        </Table.Head.Row>

        <Table.Body>
          <WithSpinner isLoading={isLoading} isForTable>
            {data &&
              data?.contents?.map((item1) => (
                <TableRow key={item1} content={item1} meta={data?.data} />
              ))}
          </WithSpinner>
        </Table.Body>
      </Table>

      <Pagination
        pageSize={5}
        pageNumber={page}
        onPageNumberUpdate={setPage}
        total={total}
      />
    </>
  );
};
