import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import DeviceGroup from '../../../interfaces/deviceGroup.interface';
import WithDeleteConfirm from '../../../components/WithDeleteConfirm/Controlled';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import { KTSVG } from '../../../lib/metronic/helpers';
import history from '../../../constants/history';
import api from '../../../api';
import {
  getDevicesGroups,
  deleteDeviceGroup,
} from '../../../store/actions/devicesGroups';

export default ({
  group,
  children,
  onRemoveDeviceGroup,
  selectedItems,
  unAssignDeviceGroup,
  removeDeviceGroup,
  onDelete,
}: {
  group?: DeviceGroup;
  children?: React.ReactNode;
  onRemoveDeviceGroup?: (idDeviceGroup: string) => void;
  selectedItems?: string[];
  removeDeviceGroup?: (deviceGroupId: string) => void;
  unAssignDeviceGroup?: (deviceGroupId: string, userGroupId: string) => void;
  onDelete?: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: userGroupId } = useParams<{ id: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  const handleConfirm = () => {
    setIsLoading(true);

    if (selectedItems && selectedItems?.length > 1) {
      api.devicesGroups
        .deleteDeviceGroups(selectedItems)
        .then(() => {
          dispatch(
            getDevicesGroups(readAllFromQueryParams(history.location.search)),
          );

          if (onDelete) onDelete();
        })
        .finally(() => setIsLoading(true));

      return;
    }

    if (group && unAssignDeviceGroup) {
      unAssignDeviceGroup(group.id, userGroupId);
      return;
    }

    if (group && onRemoveDeviceGroup) {
      onRemoveDeviceGroup(group?.id);
      return;
    }

    if (removeDeviceGroup) {
      /*  removeDeviceGroup(group?.id); */
      return;
    }
    if (group) {
      dispatch(deleteDeviceGroup({ id: group.id }));
    }
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children ?? (
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </span>
        )}
      </span>
      {!unAssignDeviceGroup ? (
        <WithDeleteConfirm
          handleConfirm={handleConfirm}
          handleDecline={close}
          isVisible={isModalVisible}
          isLoading={isLoading}
          body={`${
            selectedItems && selectedItems.length > 1
              ? t('common.delete_confirmation.multiple_delete_title')
              : `${t('common.delete_confirmation.title')} ${t(
                  'common.delete_confirmation.devices_group',
                )} ${group?.name}`
          }
      ?`}
        />
      ) : (
        <WithDeleteConfirm
          handleConfirm={handleConfirm}
          handleDecline={close}
          isVisible={isModalVisible}
          isLoading={isLoading}
          confirmButtonText={t('common.delete_confirmation.confirm_unassign')}
          body={`${
            selectedItems && selectedItems.length > 1
              ? t('common.delete_confirmation.unassign_title')
              : `${t('common.delete_confirmation.unassign_title')} 
                 ${group?.name}`
          }
      ?`}
        />
      )}
    </>
  );
};
