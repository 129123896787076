import React from 'react';
import { appIconType } from '../../../../../../constants/icons';

export default ({ typeApp }: { typeApp?: string }) => {
  if (typeApp && typeApp in appIconType) {
    if (appIconType[typeApp].type === 'icon') {
      return (
        <i
          style={{ color: appIconType[typeApp].colour }}
          className={appIconType[typeApp].icon}
        />
      );
    }

    return (
      <img
        alt="icon"
        src={appIconType[typeApp].icon}
        style={{
          width: appIconType[typeApp].width,
          margin: ' 0px 10px',
        }}
      />
    );
  }

  return null;
};
