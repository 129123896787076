import { TFunction } from 'i18next';
import validation from '../utils/validation';

export const createCompanyLocationValidation = (t: TFunction) => ({
  name: validation.string().required(t('locations.validation.locationName')),
  address1: validation.string().required(t('locations.validation.address1')),
  email: validation
    .string()
    .required(t('locations.validation.email'))
    .isEmail(t('users.create_user.validation.please_enter_valid_email')),
  phone: validation
    .string()
    .required(t('locations.validation.phone'))
    .isMobilePhone(t('users.create_user.validation.please_enter_valid_phone')),
  // countyId: validation.string().required(t('locations.validation.county')),
  city: validation.string().required(t('locations.validation.city')),
  countryId: validation
    .string()
    .min(1)
    .required(t('locations.validation.country')),
  timezone: validation.string().required(t('locations.validation.timezone')),
  contact: validation.string().required(t('locations.validation.contact')),
  manageCompany: validation.boolean(),
  company: validation.string().when('manageCompany', {
    is: (value: boolean) => value === true,
    then: validation.string().required(t('locations.validation.company')),
  }),
  countyId: validation.string().when('countryId', {
    is: (value: string) => value === 'US' || value === 'CA',
    then: validation.string().required(t('locations.validation.county')),
  }),
});
