import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BulkUpdatePlaylistRequestData from '../../../api/endpoints/playlists/interfaces/bulkUpdatePlaylistRequestData.interface';
import PlaylistComponent from '../../../components/PlaylistModal/playlistComponent';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import { getPlaylists } from '../../../store/actions/playlists';
import Playlist from '../../../interfaces/playlist.interface';
import api from '../../../api';

const EditPlaylist = ({
  selectedItems,
  children,
}: {
  selectedItems: string[];
  children: React.ReactNode;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const open = () => setModalShown(true);
  const close = () => setModalShown(false);

  const handleSubmit = async (data: BulkUpdatePlaylistRequestData) => {
    try {
      setIsLoading(true);

      await api.playlists.bulkUpdatePlaylist(data);
      dispatch(getPlaylists({ params: readAllFromQueryParams(search) }));

      setModalShown(false);
    } catch (e) {
      // Do nothing for now
    }
    setIsLoading(false);
  };

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <PlaylistComponent
        isLoading={isLoading}
        playlist={{} as Playlist}
        onSubmit={({ endDate, startDate, isActive, locked }) =>
          handleSubmit({
            endDate,
            startDate,
            isActive,
            locked,
            playlistIds: selectedItems,
          })
        }
        shown={modalShown}
        modalTitle={t('playlists.bulk_edit_playlist')}
        hide={close}
        isEditMode
        isBulk
      />
    </>
  );
};

export default EditPlaylist;
