import axios from 'axios';
import { API_URL } from '../../../constants/api';
import ContentsRoutes from './enums/contentsRoutes.enum';
import DeleteContentRequestData from './interfaces/deleteContentRequestData.interface';
import BulkUpdateContentsRequestDataInterface from './interfaces/bulkUpdateContentRequestData.interface';
import CopyContentRequestData from './interfaces/copyContentRequestData.interface';
import { sanitizeQueryParams } from '../../../utils/queryParams';
import UpdateContentRequestData from './interfaces/updateContentRequestData.interface';
import BulkCopyContentRequestData from './interfaces/bulkCopyContentRequestData.interface';
import IContent from '../../../interfaces/content.interface';

export default {
  /**
   * Get content
   * @param contentId
   */
  getContent: async (contentId: string): Promise<IContent> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${ContentsRoutes.Contents}/${contentId}`,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data;
  },

  /**
   * Creates a new content
   * @param requestData
   */
  createContent: async (requestData: FormData): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${ContentsRoutes.Contents}`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: requestData,
    });
  },

  /**
   * Copies the content
   * @param requestData
   * @param id
   */
  copyContent: async (
    id: string,
    requestData: CopyContentRequestData,
  ): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${ContentsRoutes.Contents}/clone/${id}`,
      params: sanitizeQueryParams(requestData),
    });
  },

  /**
   * Bulk copies the content
   * @param requestData
   */
  bulkCopyContent: async (
    requestData: BulkCopyContentRequestData,
  ): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/${ContentsRoutes.Contents}/clone`,
      params: sanitizeQueryParams(requestData),
    });
  },

  /**
   * Bulk copies the content from admin
   * @param requestData
   */
  bulkCopyContentAdmin: async (
    requestData: BulkCopyContentRequestData,
  ): Promise<void> => {
    await axios({
      method: 'post',
      url: `${API_URL}/management/${ContentsRoutes.Contents}/clone`,
      params: sanitizeQueryParams(requestData),
    });
  },

  /**
   * Updates the content
   * @param requestData
   * @param id
   */
  updateContent: async (
    requestData: UpdateContentRequestData,
    id: string,
  ): Promise<void> => {
    await axios({
      method: 'put',
      url: `${API_URL}/${ContentsRoutes.Contents}/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: requestData,
    });
  },

  /**
   * Bulk update the contents
   * @param requestData
   */
  bulkUpdateContent: async (
    requestData: BulkUpdateContentsRequestDataInterface,
  ): Promise<void> => {
    await axios({
      method: 'patch',
      url: `${API_URL}/${ContentsRoutes.Contents}/bulk`,
      data: requestData,
    });
  },

  /**
   * Deletes content by id
   * @param requestData
   */
  deleteContent: async (
    requestData: DeleteContentRequestData,
  ): Promise<void> => {
    await axios.delete(
      `${API_URL}/${ContentsRoutes.Contents}/${requestData.id}`,
    );
  },

  /**
   * Update content from multiple playlists
   * @param requestData
   */

  UpdateContentFromMultiplePlaylists: async (
    requestData: UpdateContentRequestData,
    contentId?: string,
    appId?: string,
  ): Promise<void> => {
    await axios({
      method: 'put',
      url: `${API_URL}/${ContentsRoutes.Contents}/updateContent/multiplePlaylists`,
      data: requestData,
      params: { mediaId: contentId, appId },
    });

    // await axios.put(
    //   `${API_URL}/${ContentsRoutes.Contents}/updateContent/multiplePlaylists`,
    //   { params: { mediaId: contentId, appId }, data: requestData },
    // );
  },
};
