import React from 'react';
import { useTranslation } from 'react-i18next';
import AssignPlaylists from '../../../../containers/Playlists/AssignPlaylists';
import ScheduleContentSteps from '../../../../enums/steps/scheduleContentSteps.enum';
import ScheduleContentRequestData from '../../../../api/endpoints/contents/interfaces/scheduleContentRequestData.interface';
import StepsTitle from '../../StepsTitle';
import Modal from '../../../Modal';

export default ({
  data,
  setData,
  setMode,
  onSubmit,
  isOptional,
}: {
  data: Partial<ScheduleContentRequestData>;
  setData: (newData: Partial<ScheduleContentRequestData>) => void;
  setMode: (mode: ScheduleContentSteps) => void;
  onSubmit: () => void;
  isOptional?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <StepsTitle currentStep={ScheduleContentSteps.Playlists} />

      <AssignPlaylists
        requestData={{
          devicesIds: data.deviceIds,
          deviceGroupsIds: data?.deviceGroupIds,
        }}
        preselectedItems={data.playlistIds ?? []}
        onSelectionChange={(selectedItems) =>
          setData({ ...data, playlistIds: selectedItems })
        }
      />

      <Modal.Separator withoutDefaultMargins className="mt-6 mb-7" />
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-white text-primary"
          onClick={() => setMode(ScheduleContentSteps.Devices)}
        >
          {t('common.prev_step')}
        </button>
        <button
          disabled={!isOptional && !data.playlistIds?.length}
          type="submit"
          className="btn btn-primary"
          onClick={onSubmit}
        >
          {t('common.publish')}
        </button>
      </div>
    </>
  );
};
