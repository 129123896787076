import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { pick } from 'lodash';
import clsx from 'clsx';
import Device from '../../../interfaces/devices/device.interface';
import UpdateDeviceRequestData from '../../../api/endpoints/devices/interfaces/updateDeviceRequestData.interface';
import validation from '../../../utils/validation';
import { editDeviceValidation } from '../../../validation/devices';
import WithModesModal from '../../WithModesModal';
import DeviceCreationStepsData from '../../../interfaces/devices/deviceCreationStepsData.interface';
import AddDeviceSteps from '../../../enums/steps/addDeviceSteps.enum';
import GeneralInfo from '../CreateDevice/Steps/LicensingInfo';
import sizingStyles from '../../../styles/sizing.module.scss';
import styles from '../CreateDevice/createDevice.module.scss';
import Indigo from '../CreateDevice/Steps/DeviceInfo';

type FormInputs = Partial<UpdateDeviceRequestData>;

export default ({
  device,
  children,
  isReadOnlyInitialState = false,
  onSubmit,
}: {
  device: Device;
  children: React.ReactNode;
  isReadOnlyInitialState?: boolean;
  onSubmit?: (updateData: {
    id: string;
    data: UpdateDeviceRequestData;
  }) => void;
}) => {
  const { t } = useTranslation();
  const [stepsData, setStepsData] = useState<Partial<DeviceCreationStepsData>>(
    {},
  );
  const { clearErrors } = useForm<FormInputs>({
    resolver: yupResolver(
      validation.object(editDeviceValidation(t)).required(),
    ),
    defaultValues: pick(device, [
      'name',
      'zipCode',
      'aspectRatio',
      'deviceGroups',
    ]),
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(isReadOnlyInitialState);

  const handleClose = () => {
    setIsModalVisible(false);
    setIsReadOnly(isReadOnlyInitialState);
  };

  function validateObject(
    obj: Partial<DeviceCreationStepsData>,
  ): UpdateDeviceRequestData | undefined {
    return Object.assign(obj);
  }

  const handleSubmitData = () => {
    if (onSubmit) {
      onSubmit({
        id: device.id,
        data: validateObject(stepsData) as UpdateDeviceRequestData,
      });
    }

    handleClose();
  };

  const open = () => {
    clearErrors();
    setIsModalVisible(true);
  };
  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <WithModesModal<DeviceCreationStepsData, AddDeviceSteps>
        isVisible={isModalVisible}
        onClose={handleClose}
        modes={{
          [AddDeviceSteps.GeneralInfo]: {
            title: isReadOnly
              ? t('devices.edit_device.device_info')
              : t('devices.edit_device.edit_device'),
            render: ({ setMode, close }) => (
              <GeneralInfo
                setData={setStepsData}
                setMode={setMode}
                device={device}
                onDecline={close}
                validationSchema={validation
                  .object()
                  .shape(
                    {
                      // TODO: move this config to the appropriate place
                      name: validation
                        .string()
                        .min(
                          1,
                          t('devices.validation.please_enter_device_name'),
                        )
                        .required(),
                      language1: validation.string().required(),
                      // Licensing
                      licenseTypeId: validation.string().when('isActive', {
                        is: (isActive?: boolean) => !!isActive,
                        then: validation.string().required(),
                        otherwise: validation.string(),
                      }),
                      startDate: validation.date().when('isActive', {
                        is: (isActive?: boolean) => !!isActive,
                        then: validation.date().required(),
                        otherwise: validation.date(),
                      }),
                      expireDate: validation.date().when('isActive', {
                        is: (isActive?: boolean) => !!isActive,
                        then: validation.date().required(),
                        otherwise: validation.date(),
                      }),
                      libraryAccessStartDate: validation
                        .date()
                        .when(['isLibrary', 'isActive'], {
                          is: (isLibrary?: boolean, isActive?: boolean) =>
                            isLibrary && isActive,
                          then: validation.date().required(),
                          otherwise: validation.date(),
                        }),
                      libraryAccessExpireDate: validation
                        .date()
                        .when(['isLibrary', 'isActive'], {
                          is: (isLibrary?: boolean, isActive?: boolean) =>
                            isLibrary && isActive,
                          then: validation.date().required(),
                          otherwise: validation.date(),
                        }),
                    },
                    [
                      ['licenseTypeId', 'isActive'],
                      ['startDate', 'isActive'],
                      ['expireDate', 'isActive'],
                      ['libraryAccessStartDate', 'isLibrary'],
                      ['libraryAccessExpireDate', 'isLibrary'],
                    ],
                  )
                  .required()}
              />
            ),
          },
          [AddDeviceSteps.Indigo]: {
            title: t('devices.add_device'),
            render: ({ setMode, close }) => (
              <Indigo
                device={device}
                setMode={setMode}
                data={stepsData}
                setData={setStepsData}
                validationSchema={{}}
                onSubmit={() => {
                  handleSubmitData();
                  close();
                  setStepsData({});
                }}
              />
            ),
          },
        }}
        defaultMode={AddDeviceSteps.GeneralInfo}
        dialogClassName={clsx('mw-1000px', sizingStyles.fitContent)}
        bodyClassName={styles.modalBody}
      />
    </>
  );
};
