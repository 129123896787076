/* eslint-disable func-names */
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import noPreview from '../../../../assets/images/noPreview/noPreview.png';
import AssignUser from '../../../../containers/Devices/AssignUser';
import EditDevice from '../../../../containers/Devices/EditDevice';
import LinkDevice from '../../../../containers/Devices/LinkDevice';
import RemoveDevice from '../../../../containers/Devices/RemoveDevice';
import DeviceCells from '../../../../enums/tableCells/deviceCells.enum';
import Device from '../../../../interfaces/devices/device.interface';
import routesConfig from '../../../../routing/config';
import UpdateDeviceAction from '../../../../store/actions/devices/interfaces/updateDeviceAction.interface';
import UpdateDeviceRequestData from '../../../../api/endpoints/devices/interfaces/updateDeviceRequestData.interface';
import ShowMoreDeviceGroupsTable from '../../../ShowMoreCell/ModalContents/ShowMoreDeviceGroupsTable';
import pointerEventsStyles from '../../../../styles/pointerEvents.module.scss';
import ButtonDropdown from '../../../Dropdown/ButtonDropdown';
import DevicePlaylistsLink from '../../DevicePlaylistsLink';
import WithClickBackdrop from '../../../WithClickBackdrop';
import WithDevicePreview from '../../WithDevicePreview';
import ShowMoreCell from '../../../ShowMoreCell';
import styles from './tableRow.module.scss';
import CheckBox from '../../../CheckBox';
import Table from '../../../Table';
import { KTSVG } from '../../../../lib/metronic/helpers';
import UnAssignUser from '../../../../containers/Devices/UnAssignUser';
import LicensingModal from '../../../../containers/Devices/LicensingModal';
import DeviceInfoModal from '../../../../containers/Devices/DeviceInfoModal';
import Tooltip from '../../../Tooltip';
import { getPlaylistInfoRoute } from '../../../../utils/playlists';
import GlobalState from '../../../../store/reducers/globalState.interface';
import { UserRole } from '../../../../enums/userRole.enum';
import { readFromQueryString } from '../../../../utils/queryParams';
import { formatDateString } from '../../../../utils/dateTime';

const { Row } = Table.Body;
const { Cell } = Row;

interface TableRowProps {
  device: Device;
  config?: {
    skipActions?: boolean;
    disableLinks?: boolean;
    isSelectable?: boolean;
    skipColumns?: string[];
    withDeleteAction?: boolean;
    isDeleteRemoval?: boolean;
    skipEditModalDeviceGroup?: boolean;
  };
  isChecked?: boolean;
  onCheck?: (id: string) => void;
  onUpdateLicense?: () => void;
  onEditDevice?: (data: UpdateDeviceAction['payload']) => void;
  unAssignDevice?: (deviceId: string, targetId: string) => void;
}

interface DefaultProps extends TableRowProps {
  config: Required<TableRowProps>['config'];
  isChecked: Required<TableRowProps>['isChecked'];
  skipLicence?: boolean;
  onCheck: Required<TableRowProps>['onCheck'];
  isSuperAdmin?: boolean;
  onEditDevice?: (data: { id: string; data: UpdateDeviceRequestData }) => void;
  onAssignUser?: () => void;
  onUnAssignUser?: () => void;
  selectedItemsLicenseType?: Array<{ id: string; licenseTypeId: string }>;
  isPowerBiPublished?: boolean;
  isTrial?: boolean;
}

const formatDate = formatDateString({
  year: '2-digit',
  month: 'short',
  day: 'numeric',
});
const DevicePreviewImage = ({ device }: { device?: Device }) => {
  const imgRef = React.useRef<HTMLImageElement>(null);
  const [preview, setPreview] = React.useState(noPreview);
  const verifyUrl = (url: string) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = function () {
        resolve(true);
      };
      img.onerror = function () {
        resolve(false);
      };
      img.src = url;
    });
  };

  const reloadImg = (url: string) => {
    verifyUrl(url).then((response) => {
      if (response) {
        if (imgRef.current) imgRef.current.src = url;
      }
    });
  };
  useEffect(() => {
    let reloadImageInterval: any;

    if (device?.previewURL) {
      const PreviewURLCheck = device.previewURL.replace(/https:\/\//g, '');
      verifyUrl(`https://${PreviewURLCheck}`).then((response) => {
        if (response) {
          setPreview(`https://${PreviewURLCheck}`);
        }
      });
      reloadImageInterval = setInterval(() => {
        reloadImg(`https://${PreviewURLCheck}`.replace('-accelerate', ''));
      }, 10000);
    } else {
      return () => {};
    }
    return () => clearInterval(reloadImageInterval);
  }, [device?.previewURL]);

  return (
    <>
      <img
        ref={imgRef}
        src={preview}
        onError={({ currentTarget }) => {
          // eslint-disable-next-line no-param-reassign
          currentTarget.src = noPreview;
        }}
        alt={`${device?.name} preview`}
        className={clsx('h-30px', styles.preview)}
      />
    </>
  );
};

const DevicesTableRow = ({
  device,
  config,
  isChecked,
  onCheck,
  isSuperAdmin = false,
  onEditDevice,
  unAssignDevice,
  onAssignUser,
  onUnAssignUser,
  onUpdateLicense,
  skipLicence = false,
  selectedItemsLicenseType,
  isTrial = false,
  isPowerBiPublished,
}: DefaultProps) => {
  const indigoUsersRoles = [
    UserRole.IndigoAdmin,
    UserRole.SuperAdmin,
    UserRole.IndigoEmployee,
  ];
  const { roles } = useSelector((state: GlobalState) => state.userData.user);
  const { t } = useTranslation();
  const inActiveTime = moment
    .duration(moment().diff(device?.lastOnline))
    .asMinutes();
  const checkDeviceActivity = inActiveTime <= 10;
  const isTrialUser = useSelector((state: GlobalState) => {
    return state.userData?.user?.isTrial;
  });
  const ownerId = device?.owner?.id;
  const { search } = useLocation();
  const currentUserId = readFromQueryString(search, 'currentUserId');
  const playlistLink = getPlaylistInfoRoute(
    device?.currentPlaylist?.id,
    undefined,
    false,
    device?.currentPlaylist,
  );

  const disabled = !!(
    isPowerBiPublished && device?.license?.licenseType.name !== 'Enterprise'
  );

  const [playlistNotFound, setPlaylistNotFound] = React.useState(false);
  const actionItems = [
    {
      key: 'preview',
      content: (
        <WithDevicePreview
          id={device?.id}
          previewUrl={
            `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?deviceId=${device?.id}` ||
            noPreview
          }
        >
          <WithClickBackdrop>{t('common.preview')}</WithClickBackdrop>
        </WithDevicePreview>
      ),
    },
    {
      key: 'playlists',
      content: (
        <DevicePlaylistsLink
          device={device}
          isAdmin={roles.includes(UserRole.SuperAdmin)}
          currentUserId={currentUserId as string}
        >
          <WithClickBackdrop>{t('devices.table.playlists')}</WithClickBackdrop>
        </DevicePlaylistsLink>
      ),
    },
    {
      key: 'edit',
      content: (
        <EditDevice
          device={device}
          onEditDevice={onEditDevice}
          isSuperAdmin={isSuperAdmin}
        >
          <WithClickBackdrop>{t('common.edit')}</WithClickBackdrop>
        </EditDevice>
      ),
    },
    {
      key: 'licensing',
      content: (
        <LicensingModal device={device} onSuccess={onUpdateLicense}>
          <WithClickBackdrop>{t('devices.table.licensing')}</WithClickBackdrop>
        </LicensingModal>
      ),
    },
    ownerId
      ? {
          key: 'unassign',
          content: (
            <UnAssignUser device={device} onUnAssign={onUnAssignUser}>
              <WithClickBackdrop>
                {t('devices.table.unassign_from_owner')}
              </WithClickBackdrop>
            </UnAssignUser>
          ),
        }
      : {
          key: 'assign',
          content: (
            <AssignUser device={device} onAssign={onAssignUser}>
              <WithClickBackdrop>
                {t('devices.table.assign_to_owner')}
              </WithClickBackdrop>
            </AssignUser>
          ),
        },
    {
      key: 'link',
      content: (
        <LinkDevice device={device} linkedDevice={device?.isLinked}>
          <WithClickBackdrop>{t('common.link')}</WithClickBackdrop>
        </LinkDevice>
      ),
    },
    ownerId
      ? {
          key: 'view_owner',
          content: (
            <Link to={routesConfig.user.route.replace(':id', ownerId)}>
              <WithClickBackdrop>
                {t('devices.table.view_user_profile')}
              </WithClickBackdrop>
            </Link>
          ),
        }
      : null,
  ];

  if (config.withDeleteAction) {
    actionItems.push({
      key: 'delete',
      content: (
        <RemoveDevice
          device={device}
          unAssignDevice={unAssignDevice}
          isDelete={config.isDeleteRemoval}
        >
          <WithClickBackdrop>{t('common.delete')}</WithClickBackdrop>
        </RemoveDevice>
      ),
    });
  }
  React.useEffect(() => {
    if (!device?.currentPlaylist) {
      setPlaylistNotFound(true);
    }
  }, [device?.currentPlaylist]);

  return (
    <Row>
      {config.isSelectable && (
        <Cell>
          <CheckBox
            disabled={
              !device?.currentPlaylist ||
              (!!selectedItemsLicenseType?.length &&
                selectedItemsLicenseType?.[0]?.licenseTypeId !==
                  device?.license?.licenseType?.id) ||
              disabled
            }
            name="deviceId"
            id={device?.id}
            onChange={() => onCheck(device?.id)}
            checked={isChecked}
            className="mt-1"
          />
        </Cell>
      )}
      <Cell isNoWrap>
        <div className="d-flex align-items-center">
          {!isTrialUser && (
            <div
              className="symbol symbol-50px me-5 d-flex"
              onContextMenu={(ev) => ev.preventDefault()}
            >
              <WithDevicePreview
                id={device?.id}
                previewUrl={
                  `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?deviceId=${device.id}` ||
                  noPreview
                }
              >
                <DevicePreviewImage device={device} />
              </WithDevicePreview>
            </div>
          )}
          <span
            className={clsx(
              'text-dark fw-bold text-hover-primary d-block mb-1 fs-6',
              config.disableLinks && pointerEventsStyles.noPointerEvents,
            )}
          >
            {/*
          This for testing purpose only 
          Please leave only the name when done with testing  
          adding license type to test selecting devices whith different license when create a device group
          */}
            <DevicePlaylistsLink
              device={device}
              isAdmin={isSuperAdmin}
              currentUserId={currentUserId as string}
            >
              {device.name}{' '}
              {(!isSuperAdmin ||
                config.skipColumns?.includes(DeviceCells.License)) &&
                !skipLicence &&
                `(${device?.license?.licenseType?.name ?? 'No license'})`}
            </DevicePlaylistsLink>
          </span>
          {playlistNotFound && (
            <>
              <Tooltip text="Device without a playlist">
                <i className="bi bi-exclamation-triangle text-warning cursor-pointer" />
              </Tooltip>
            </>
          )}
        </div>
      </Cell>
      {!config.skipColumns?.includes(DeviceCells.Location) && (
        <Cell isNoWrap isEmpty={!device?.location} className="text-center">
          {device?.location?.name ?? '-'}
        </Cell>
      )}
      {!config.skipColumns?.includes(DeviceCells.Status) && (
        <Cell isNoWrap className="text-center">
          <div
            className={clsx(
              'badge fs-7 py-2 fw-normal',
              device?.online &&
                (device?.checkDeviceActivity || checkDeviceActivity)
                ? 'badge-light-success'
                : 'badge-light-danger',
            )}
          >
            {device?.online &&
            (device?.checkDeviceActivity || checkDeviceActivity)
              ? t('devices.table.online_status')
              : t('devices.table.inactive_status')}
          </div>
        </Cell>
      )}
      {!config.skipColumns?.includes(DeviceCells.CurrentPlaylist) && (
        <Cell
          isNoWrap
          isEmpty={!device?.currentPlaylist}
          className="text-center"
        >
          <Link className="text-reset" to={playlistLink}>
            {device?.currentPlaylist?.name ?? '-'}
          </Link>
        </Cell>
      )}
      {!isTrialUser &&
        !config.skipColumns?.includes(DeviceCells.DevicesGroups) && (
          <Cell
            isNoWrap
            isEmpty={!device.deviceGroups?.length}
            className="text-center mw-200px "
          >
            <ShowMoreCell
              itemNames={device.deviceGroups?.map(
                (deviceGroup) => deviceGroup?.name,
              )}
              widthDefaultSize
              title={t('devices_groups.devices_groups')}
              bodyClassName="justify-content-center"
            >
              <ShowMoreDeviceGroupsTable device={device} />
            </ShowMoreCell>
          </Cell>
        )}{' '}
      {isSuperAdmin &&
        !isTrial &&
        !config.skipColumns?.includes(DeviceCells.License) && (
          <Cell isNoWrap className="text-center">
            {device?.license?.licenseType?.name ?? 'No license'}
          </Cell>
        )}
      {isSuperAdmin &&
        !isTrial &&
        !config.skipColumns?.includes(DeviceCells.License) && (
          <Cell isNoWrap className="text-center">
            {device?.license?.startDate
              ? formatDate(device?.license?.startDate?.toString())
              : 'No license'}{' '}
            -
            {device?.license?.expireDate
              ? formatDate(device?.license?.expireDate?.toString())
              : ' No license'}
          </Cell>
        )}
      {isSuperAdmin &&
        !isTrial &&
        !config.skipColumns?.includes(DeviceCells.License) && (
          <Cell isNoWrap className="text-center">
            {device?.license?.libraryAccessStartDate
              ? formatDate(device?.license?.libraryAccessStartDate?.toString())
              : 'No license'}{' '}
            -
            {device?.license?.libraryAccessExpireDate
              ? formatDate(device?.license?.libraryAccessExpireDate?.toString())
              : ' No license'}
          </Cell>
        )}
      {!config.skipColumns?.includes(DeviceCells.LastOnline) && (
        <Cell isNoWrap isEmpty={!device?.lastOnline} className="text-center">
          {moment(device?.lastOnline).fromNow()}
        </Cell>
      )}
      {!config.skipColumns?.includes(DeviceCells.Billing) && (
        <Cell isNoWrap isEmpty={!device?.billing} className="text-center">
          {device?.billing}
        </Cell>
      )}
      {isSuperAdmin && (
        <Cell isNoWrap className="text-center">
          {device.isLinked ? (
            <KTSVG
              path="/media/icons/duotune/arrows/arr016.svg"
              className="svg-icon-2 svg-icon-success"
            />
          ) : (
            <KTSVG
              path="/media/icons/duotune/arrows/arr014.svg"
              className="svg-icon-2 svg-icon-danger"
            />
          )}
        </Cell>
      )}
      {isSuperAdmin &&
        isTrial &&
        !config.skipColumns?.includes(DeviceCells.License) && (
          <Cell isNoWrap className="text-center">
            {device?.license?.licenseType?.name ?? 'No license'}
          </Cell>
        )}
      {isSuperAdmin && !config.skipColumns?.includes(DeviceCells.Owner) && (
        <Cell
          isNoWrap
          isEmpty={!device?.owner?.firstName && !device?.owner?.lastName}
          className="text-center"
        >
          <Link
            to={
              indigoUsersRoles.some((el) => device?.owner?.roles?.includes(el))
                ? `/users/${ownerId}${routesConfig.devices.route}`
                : `/customers/${ownerId}${routesConfig.devices.route}`
            }
          >
            {`${device?.owner?.firstName} ${device?.owner?.lastName}`}
          </Link>
        </Cell>
      )}
      {!config.skipActions && (
        <Cell isNoWrap isTextEnd>
          <div className="d-flex justify-content-end">
            {isSuperAdmin ? (
              <ButtonDropdown text={t('common.actions')} items={actionItems} />
            ) : (
              <>
                <div className="mx-1">
                  <WithDevicePreview
                    id={device.id}
                    previewUrl={
                      `${process.env.REACT_APP_PLAYER_PREVIEW_URL}/preview.html?deviceId=${device.id}` ||
                      noPreview
                    }
                  />
                </div>
                <div className="mx-1">
                  <DeviceInfoModal device={device} />
                </div>
                <div className="mx-1">
                  {config.withDeleteAction && (
                    <Tooltip text={t('common.unssign')}>
                      <RemoveDevice
                        device={device}
                        unAssignDevice={unAssignDevice}
                      />
                    </Tooltip>
                  )}
                </div>
              </>
            )}
          </div>
        </Cell>
      )}
    </Row>
  );
};

DevicesTableRow.defaultProps = {
  config: {
    skipActions: false,
    disableLinks: false,
    isSelectable: false,
    withDeleteAction: false,
  },
  isChecked: false,
  onCheck: () => null,
};

export default DevicesTableRow;
