/* eslint-disable camelcase */
import axios from 'axios';
import { API_URL } from '../../../constants/api';
import GetDailyTrialSignupsRequestData from './interfaces/getDailyTrialSignupsRequestData';
import GetCustomersDailyListResponseData from './interfaces/getCustomersListResponseData';
import DashboardRoutes from './enum/dashboardRoutes.enum';
import GetMostUsedApps from './interfaces/getMostUsedApps';
import GetMostUsedTopics from './interfaces/MostUsedTopicsResponseData';

export default {
  /**
   * Retrieves the list of Recent Costumers Signups
   * @param requestData
   */
  getDailyTrialSignups: async (
    requestData: GetDailyTrialSignupsRequestData,
  ): Promise<GetCustomersDailyListResponseData> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DashboardRoutes.dashboard}`,
      params: requestData,
    });

    return data;
  },

  /**
   * Retrieves the list of Most Used Apps
   * @param requestData
   */

  getMostUsedApps: async (): Promise<GetMostUsedApps[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DashboardRoutes.dashboard}/app-counting`,
    });

    return data;
  },

  /**
   * Retrieves the list of Most Used Topics
   * @param requestData
   */

  getMostUsedTopics: async (): Promise<GetMostUsedTopics[]> => {
    const { data } = await axios({
      method: 'get',
      url: `${API_URL}/${DashboardRoutes.dashboard}/most-used-topics`,
    });

    return data;
  },
};
