import * as React from 'react';
import { Cell, Column } from 'react-table';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CardDropdown from '../../components/Dropdown/CardDropdown';
import { KTSVG } from '../../lib/metronic/helpers';
import { FileSchema } from '../../store/types/fileManager';
import { formatDateString } from '../../utils/dateTime';
import pointerEventsStyles from '../../styles/pointerEvents.module.scss';
import Tooltip from '../../components/Tooltip';
import GlobalState from '../../store/reducers/globalState.interface';

interface Action {
  label: string;
  id: string;
  handler: (file: FileSchema) => void;
  disabled: (ownerId?: string) => boolean;
}

interface UseColumnsProps {
  clickOnFile: (file: FileSchema) => void;
  openRenameFileModal: (file: FileSchema) => void;
  openMoveToFolderModal: (file: FileSchema) => void;
  openDeleteFileModal: (file: FileSchema) => void;
  openActionsDropdown: (file: FileSchema) => void;
  openScheduleFileModal: (file: FileSchema) => void;
  selectedFile: FileSchema | null;
}

export default function useColumns({
  clickOnFile,
  openRenameFileModal,
  openMoveToFolderModal,
  openDeleteFileModal,
  openActionsDropdown,
  openScheduleFileModal,
  selectedFile,
}: UseColumnsProps): Column<FileSchema>[] {
  const { t } = useTranslation();

  const [isActionDropdownOpen, setIsActionDropdownOpen] =
    React.useState<boolean>(false);

  const handleToggleIsActionDropdownOpen = React.useCallback(
    () => setIsActionDropdownOpen(!isActionDropdownOpen),
    [isActionDropdownOpen],
  );

  const connectedUser = useSelector(
    (state: GlobalState) => state.userData.user,
  );

  const formatDate = React.useCallback(
    () =>
      formatDateString({
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        minute: '2-digit',
        hour: 'numeric',
      }),
    [],
  );

  const hideTooltip = (action: string, disabled: boolean) => {
    if (
      action === 'download-action' ||
      action === 'schedule-action' ||
      action === 'rename-action'
    )
      return true;

    if (disabled === false) return false;

    return true;
  };
  const textToShow = (action: string) => {
    if (action === 'rename-action') return t('file_uploader.rename_disabled');
    if (action === 'move-to-folder-action')
      return t('file_uploader.move_disabled');
    return '';
  };

  const prepareActions = React.useCallback(
    () => [
      {
        label: 'Schedule',
        id: 'schedule-action',
        handler: (file: FileSchema) => openScheduleFileModal(file),
        disabled: () => true,
      },
      {
        label: 'Download',
        id: 'download-action',
        handler: () => {},
        disabled: () => true,
      },
      {
        label: 'Rename',
        id: 'rename-action',
        handler: (file: FileSchema) => openRenameFileModal(file),
        disabled: (ownerId?: string) => connectedUser?.id === ownerId,
      },
      {
        label: 'Move to Folder',
        id: 'move-to-folder-action',
        handler: (file: FileSchema) => openMoveToFolderModal(file),

        disabled: (ownerId?: string) => connectedUser?.id === ownerId,
      },
      {
        label: 'Delete',
        id: 'delete-action',
        handler: (file: FileSchema) => openDeleteFileModal(file),
        disabled: (ownerId?: string) => connectedUser?.id === ownerId,
      },
    ],
    [
      openDeleteFileModal,
      openMoveToFolderModal,
      openRenameFileModal,
      openScheduleFileModal,
    ],
  );

  return React.useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'title',
        Cell: ({ value, row }: Cell<FileSchema>) => {
          const { original: fileData } = row;

          let importPath = '/media/icons/duotune/files/fil012.svg';

          if (!fileData.isDirectory) {
            importPath = '/media/icons/duotune/files/fil003.svg';
          }

          return (
            <div
              className="d-flex align-items-center"
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => clickOnFile(fileData)}
            >
              <KTSVG
                path={importPath}
                className="svg-icon svg-icon-2x svg-icon-primary me-4"
              />
              <span className="text-gray-800 text-hover-primary">{value}</span>
            </div>
          );
        },
      },
      {
        Header: 'TYPE',
        accessor: 'type',
        Cell: ({ row }: Cell<FileSchema>) => {
          const { original: fileData } = row;

          let fileType = 'UNKNOWN';

          if (fileData.mediaGroup) {
            const fileName = fileData.mediaGroup.name ?? '';
            const subStringsName = fileName.split('.');
            const extensionName = subStringsName[subStringsName.length - 1];
            fileType = extensionName.toUpperCase();
          } else if (!fileData.isDirectory) {
            const filePath = fileData.url ?? '';
            const subStrings = filePath.split('.');
            const extension = subStrings[subStrings.length - 1];
            fileType = extension.toUpperCase();
          } else {
            fileType = 'FOLDER';
          }

          return (
            <span className="badge badge-light-success py-3 px-7">
              {fileType}
            </span>
          );
        },
      },
      {
        Header: 'DATE ADDED',
        accessor: 'createdAt',
        Cell: ({ value }: Cell<FileSchema>) => {
          const formatDateRef = formatDate();
          return <time dateTime={value}>{formatDateRef(value)}</time>;
        },
      },
      {
        Header: 'CREATED BY',
        accessor: 'ownerMedia',
        Cell: ({ row }: Cell<FileSchema>) => {
          return (
            <>
              {' '}
              {row.original.ownerMedia.firstName}{' '}
              {row.original.ownerMedia.lastName}{' '}
            </>
          );
        },
      },
      {
        Header: 'ACTIONS',
        Cell: ({ row }: Cell<FileSchema>) => {
          const file = row.original;
          const actions: Action[] = prepareActions();

          return (
            <>
              <div className="d-flex align-items-center justify-content-end">
                {file.isDirectory ? (
                  <>
                    <div className="mx-1">
                      <Tooltip
                        hide={connectedUser?.id === file.ownerMedia.id}
                        text={t('file_uploader.rename_disabled')}
                      >
                        <span
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                          onClick={() => openRenameFileModal(file)}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                          style={{
                            pointerEvents:
                              connectedUser?.id === file?.ownerMedia?.id
                                ? 'auto'
                                : 'none',
                          }}
                        >
                          <i className="fa fa-edit" />
                        </span>
                      </Tooltip>
                    </div>

                    <div className="mx-1">
                      <Tooltip
                        hide={connectedUser?.id === file.ownerMedia.id}
                        text={t('file_uploader.move_disabled')}
                      >
                        <span
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                          onClick={() => openMoveToFolderModal(file)}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                          style={{
                            pointerEvents:
                              connectedUser?.id === file?.ownerMedia?.id
                                ? 'auto'
                                : 'none',
                          }}
                        >
                          <i className="fa fa-file-export" />
                        </span>
                      </Tooltip>
                    </div>

                    <div className="mx-1">
                      <Tooltip
                        hide={connectedUser?.id === file.ownerMedia.id}
                        text={t('file_uploader.delete_disabled')}
                      >
                        <span
                          role="button"
                          tabIndex={0}
                          onKeyDown={() => {}}
                          onClick={() => openDeleteFileModal(file)}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                          style={{
                            pointerEvents:
                              connectedUser?.id === file?.ownerMedia?.id
                                ? 'auto'
                                : 'none',
                          }}
                        >
                          <i className="fa fa-trash" />
                        </span>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <CardDropdown
                    show={
                      isActionDropdownOpen &&
                      file.id === (selectedFile as FileSchema).id
                    }
                    onToggle={handleToggleIsActionDropdownOpen}
                    menuClassName="menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                    toggle={
                      <span
                        role="button"
                        className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2"
                        tabIndex={0}
                        onKeyDown={() => {}}
                        onClick={() => openActionsDropdown(file)}
                      >
                        <KTSVG
                          path="/media/icons/duotune/general/gen023.svg"
                          className="svg-icon svg-icon-5 m-0"
                        />
                      </span>
                    }
                  >
                    {actions.map(({ label, handler, id, disabled }) => {
                      switch (id) {
                        case 'delete-action':
                          return (
                            <Tooltip
                              hide={connectedUser?.id === file.ownerMedia.id}
                              text={t('file_uploader.delete_disabled')}
                            >
                              <div key={id} className="menu-item px-3">
                                <span
                                  role="button"
                                  tabIndex={0}
                                  onKeyDown={() => {}}
                                  onClick={() => handler(file)}
                                  className={clsx(
                                    'w-100 text-danger menu-link px-3 position-relative',
                                    file.isDirectory &&
                                      !file.isEmpty &&
                                      clsx(
                                        pointerEventsStyles.noPointerEvents,
                                        'opacity-50',
                                      ),
                                  )}
                                  style={{
                                    pointerEvents:
                                      disabled(file?.ownerMedia?.id) === true
                                        ? 'auto'
                                        : 'none',
                                  }}
                                >
                                  {label}
                                </span>
                              </div>
                            </Tooltip>
                          );

                        case 'download-action':
                          return (
                            <div key={id} className="menu-item px-3">
                              <span className="menu-link px-3 position-relative">
                                <a
                                  style={{
                                    color: 'inherit',
                                    pointerEvents:
                                      disabled(file?.ownerMedia?.id) === true
                                        ? 'auto'
                                        : 'none',
                                  }}
                                  href={file.url}
                                  download
                                  className="w-100"
                                >
                                  {label}
                                </a>
                              </span>
                            </div>
                          );
                        default:
                          return (
                            <Tooltip
                              hide={hideTooltip(
                                id,
                                disabled(file?.ownerMedia?.id),
                              )}
                              text={textToShow(id)}
                            >
                              <div key={id} className="menu-item px-3">
                                <span
                                  role="button"
                                  tabIndex={0}
                                  onKeyDown={() => {}}
                                  onClick={() => handler(file)}
                                  className="w-100 menu-link px-3 position-relative"
                                  style={{
                                    pointerEvents:
                                      disabled(file?.ownerMedia?.id) === true
                                        ? 'auto'
                                        : 'none',
                                  }}
                                >
                                  {label}
                                </span>
                              </div>
                            </Tooltip>
                          );
                      }
                    })}
                  </CardDropdown>
                )}
              </div>
            </>
          );
        },
      },
    ],
    [
      clickOnFile,
      formatDate,
      handleToggleIsActionDropdownOpen,
      isActionDropdownOpen,
      openActionsDropdown,
      prepareActions,
      selectedFile,
      connectedUser,
    ],
  );
}
