import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import StepsTitle from '../../../../../PublishModal/StepsTitle';
import ScheduleContentSteps from '../../../../../../enums/steps/scheduleContentSteps.enum';
import ScheduleLibraryRequestData from '../../../../../../api/endpoints/libraries/interfaces/scheduleLibraryRequestData.interface';
import { prepareHookFromState } from '../../../../../../utils/hooks/useStateWithHookForm';
import Orientation from '../../../../../../enums/orientation.enum';
import DayOfWeek from '../../../../../../enums/dayOfWeek.enum';
import FormErrorMessage from '../../../../../FormErrorMessage';
import Yup from '../../../../../../utils/validation';
import DatePicker from '../../../../../DatePicker';
import Counter from '../../../../../Counter';
import Modal from '../../../../../Modal';
import { Contents } from '../../../../../../interfaces/library.interface';
import orientations from '../../../../../../partials/orientations';
import SelectMode from '../../../../../Select/SelectMode';

const errorMessageClassNames = 'mt-1 min-h-20px mb-2';

type FieldsData = Partial<ScheduleLibraryRequestData>;

interface FormInputs {
  orientation: Orientation;
  duration: number;
  startDate: Date;
  endDate: Date;
  isLocked: boolean;
  isOrdered: boolean;
  tags: string[];
  weekDays: DayOfWeek[];
}

export default ({
  data,
  setData,
  onDecline,
  setMode,
  validationSchema,
  fullInfoSelectedItems,
  withOrientation,
}: {
  data: FieldsData;
  setData: (newData: FieldsData) => void;
  onDecline: () => void;
  setMode: (mode: ScheduleContentSteps) => void;
  validationSchema?: { [key: string]: any };
  fullInfoSelectedItems?: Contents;
  withOrientation?: boolean;
}) => {
  const { t } = useTranslation();
  const getVideoDuration = fullInfoSelectedItems?.filter((item) =>
    item?.media?.type?.includes('video'),
  )?.[0]?.media?.duration;

  const disableDurationField = fullInfoSelectedItems?.every((item) =>
    item?.media?.type?.includes('video'),
  );
  const defaultDuration = () => {
    if (
      fullInfoSelectedItems?.length &&
      fullInfoSelectedItems.length === 1 &&
      Number(disableDurationField)
    )
      return getVideoDuration;
    return Number(data.duration ?? 15);
  };
  const defaultValues: Partial<FormInputs> = {
    duration: defaultDuration(),
    startDate: data.startDate ? new Date(data.startDate) : undefined,
    endDate: data.endDate ? new Date(data.endDate) : undefined,
    orientation: data.orientation ?? Orientation.FullScreen,
  };

  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<Partial<FormInputs>>({
    resolver: yupResolver(
      Yup.object(
        validationSchema ?? {
          duration: Yup.number().required(t('required')),
          startDate: Yup.date(),
          endDate: Yup.date().when('startDate', {
            is: (startDate: any) => !!startDate,
            then: Yup.date().min(
              Yup.ref('startDate'),
              t('common.validation.endDate'),
            ),
          }),
        },
      ).required(),
    ),
    defaultValues,
  });
  const useFs = prepareHookFromState<Partial<FormInputs>>(
    setValue,
    trigger,
    defaultValues,
  );

  const [duration, setDuration] = useFs<FormInputs['duration']>('duration');
  const [startDate, setStartDate] = useFs<FormInputs['startDate']>('startDate');
  const [endDate, setEndDate] = useFs<FormInputs['endDate']>('endDate');
  const [orientation, setOrientation] =
    useFs<FormInputs['orientation']>('orientation');

  const handleSubmitData = (inputsData: Partial<FormInputs>) => {
    const requestData = {
      ...data,
      duration: String(inputsData.duration),
      startDate: inputsData.startDate
        ? inputsData.startDate.toISOString()
        : undefined,
      endDate: inputsData.endDate
        ? inputsData.endDate.toISOString()
        : undefined,
    };
    if (withOrientation) {
      setData({ ...requestData, orientation });
    } else setData(requestData);

    setMode(ScheduleContentSteps.Devices);
  };

  return (
    <>
      <StepsTitle currentStep={ScheduleContentSteps.Info} />
      <form onSubmit={handleSubmit(handleSubmitData)} className="mw-600px">
        {withOrientation && (
          <>
            <label
              htmlFor="contentOrientation"
              className="required fw-bold mb-2"
            >
              {t('common.orientation')}
            </label>
            <SelectMode<Orientation>
              asInput
              className="flex-wrap"
              inputName="orientation"
              inputId="contentOrientation"
              modes={orientations({
                landscapeText: t('common.landscape'),
                fullScreenText: t('common.full_screen'),
                portraitText: t('common.portrait'),
              })}
              modeValue={orientation}
              onSelect={setOrientation}
            />
          </>
        )}
        {(!fullInfoSelectedItems?.length ||
          fullInfoSelectedItems.length <= 1 ||
          !disableDurationField) && (
          <div>
            <Counter
              name="duration"
              title={t('common.duration')}
              placeholder={t('contents.type_here')}
              value={duration}
              onChange={setDuration}
              isLocked={disableDurationField}
            />
            <FormErrorMessage
              name="duration"
              errors={errors}
              className={errorMessageClassNames}
            />
          </div>
        )}
        <div>
          <h2 className="text-dark fw-bolder text-hover-primary">
            {t('contents.schedule_title')}
          </h2>
          <p className="py-3">{t('contents.schedule_text')}</p>
        </div>

        <div className="fv-row mb-5">
          <label htmlFor="startDate" className="d-flex align-items-center fs-6">
            <span>Start Date</span>
          </label>
          <DatePicker
            name="startDate"
            value={startDate}
            placeholder="Select start date - time"
            onChange={(newDate) => setStartDate(newDate[0])}
            options={{
              enableTime: true,
              defaultHour: 0,
            }}
          />
          <FormErrorMessage name="startDate" errors={errors} />
        </div>

        <div className="fv-row mb-10">
          <label htmlFor="endDate" className="d-flex align-items-center fs-6">
            <span>End Date</span>
          </label>
          <DatePicker
            name="endDate"
            value={endDate}
            placeholder="Select end date - time"
            onChange={(newDate) => setEndDate(newDate[0])}
            options={{
              enableTime: true,
              defaultHour: 23,
              defaultMinute: 59,
            }}
          />
          <FormErrorMessage name="endDate" errors={errors} />
        </div>

        <Modal.Separator withoutDefaultMargins className="mt-1 mb-7" />
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-white text-primary"
            onClick={onDecline}
          >
            {t('common.close')}
          </button>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary">
              {t('common.next_step')}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
