import GetCustomersListSuccessAction from '../../actions/customers/interfaces/getCustomersListSuccessAction.interface';
import LoadingAction from '../../actions/loadingAction.interface';
import CustomersActions from '../../actions/customers/customersActions.enum';
import ReduxAction from '../../actions/reduxAction.interface';
import CustomersState from './customersState.interface';

const initialState: CustomersState = {
  loading: true,
  customers: [],
  total: 0,
};

export default function usersReducer(
  state = initialState,
  action: ReduxAction<CustomersActions>,
): CustomersState {
  switch (action.type) {
    case CustomersActions.GET_CUSTOMERS_LIST_SUCCESS: {
      const {
        payload: { items, meta },
      } = action as GetCustomersListSuccessAction;

      return {
        ...state,
        customers: items,
        total: meta.totalItems,
      };
    }
    case CustomersActions.SET_CUSTOMERS_LOADING:
      return {
        ...state,
        ...(action as LoadingAction<CustomersActions.SET_CUSTOMERS_LOADING>)
          .payload,
      };
    default:
      return state;
  }
}
