import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import UserFrame from '../../assets/images/icons/userFrame.svg';

const UploadProfilePhoto = ({
  title,
  setPic,
  oldPhoto,
  className,
  name,
  icon,
}: {
  title: string;
  setPic: any;
  oldPhoto?: string;
  className?: string;
  name?: string;
  icon?: string;
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [picturePreview, setPicturePreview] = React.useState('');
  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setPicturePreview(URL.createObjectURL(e.target.files[0]));
      setPic(e.target.files[0]);
    }
  };
  return (
    <div className={className}>
      <div>
        <img
          className={icon ?? 'w-100'}
          src={picturePreview || oldPhoto || UserFrame}
          alt="user"
        />
      </div>

      <div className="mt-5"> {t('my_account.left_table.img')}</div>
      <input
        name={name}
        ref={hiddenFileInput}
        onChange={handleChange}
        type="file"
        style={{ display: 'none' }}
        accept=".png, .jpg, .jpeg"
        multiple={false}
      />
      <button
        onClick={handleClick}
        type="button"
        className="btn btn-light btn-active-light-primary btn-sm w-100 mt-5"
      >
        {title}
      </button>
    </div>
  );
};

UploadProfilePhoto.defaultProps = {};

export default memo(UploadProfilePhoto);
