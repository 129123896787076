import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { readAllFromQueryParams } from '../../../utils/queryParams';
import WithDeleteConfirm from '../../../components/WithDeleteConfirm';
import User from '../../../interfaces/user/user.interface';
import Tooltip from '../../../components/Tooltip';
import history from '../../../constants/history';
import api from '../../../api';
import {
  getAdminUsersList,
  getUsersList,
  setUsersLoading,
} from '../../../store/actions/users';

export default ({
  user,
  children,
  selectedItems,
  admin = false,
  deleteUser = false,
  unAssignUser,
  unAssignUsers,
  title,
  confirmText,
  onUnssingUserFromDeviceGroup,
  resetSelection,
}: {
  user?: User;
  children?: React.ReactNode;
  admin?: boolean;
  selectedItems?: string[];
  unAssignUser?: (userId: string, userGroupId: string) => void;
  unAssignUsers?: (userIds: string[]) => void;
  title?: string;
  confirmText?: string;
  resetSelection?: () => void;
  deleteUser?: boolean;
  onUnssingUserFromDeviceGroup?: (
    userId: string,
    deviceGroupId: string,
  ) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: userGroupId } = useParams<{ id: string }>();

  const handleConfirm = async () => {
    if (selectedItems && unAssignUsers) {
      unAssignUsers(selectedItems);
    }

    if (resetSelection) {
      resetSelection();
    }

    if (user) {
      if (unAssignUser) {
        unAssignUser(user.id, userGroupId);
        return;
      }

      if (onUnssingUserFromDeviceGroup) {
        onUnssingUserFromDeviceGroup(user.id, userGroupId);
        return;
      }

      dispatch(setUsersLoading({ loading: true }));

      try {
        if (admin) {
          await api.users.deleteAdminUser(user.id);

          dispatch(
            getAdminUsersList(readAllFromQueryParams(history.location.search)),
          );
        } else {
          await api.users.deleteUser(user.id);

          dispatch(
            getUsersList(readAllFromQueryParams(history.location.search)),
          );
        }

        toast.success(
          i18next.t<string>(
            deleteUser ? 'users.delete_message' : 'users.archive_message',
          ),
        );
      } catch (e) {
        dispatch(setUsersLoading({ loading: false }));
      }
    }
  };

  return (
    <WithDeleteConfirm
      confirmText={confirmText}
      body={`${
        selectedItems && selectedItems.length > 1
          ? title ?? t('common.delete_confirmation.multiple_delete_title')
          : `${title ?? t('common.delete_confirmation.title')} ${t(
              'common.delete_confirmation.user',
            )} ${user?.firstName} ${user?.lastName}`
      }
      ?`}
      handleConfirm={handleConfirm}
    >
      {children ?? (
        <Tooltip text={t('common.remove')}>
          <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
            <i className="fa fa-trash" />
          </span>
        </Tooltip>
      )}
    </WithDeleteConfirm>
  );
};
