import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import WithDeleteConfirm from '../../../components/WithDeleteConfirm';
import Device from '../../../interfaces/devices/device.interface';

export default ({
  device: { id, name },
  children,
  unAssignDevice,
  isDelete,
}: {
  device: Device;
  children?: React.ReactNode;
  unAssignDevice?: (deviceId: string, targetId: string) => void;
  isDelete?: boolean;
}) => {
  const { t } = useTranslation();
  const { id: targetId } = useParams<{ id: string }>();

  const handleConfirm = () => {
    try {
      if (id && unAssignDevice) {
        unAssignDevice(id, targetId);
        toast.success(t('devices.delete_device.success_messages'));
      }
    } catch (e) {
      toast.error(t('devices.delete_device.error_messages'));
    }
  };

  return (
    <WithDeleteConfirm
      confirmText={
        isDelete
          ? t('common.delete_confirmation.confirm')
          : t('common.delete_confirmation.confirm_unassign')
      }
      body={`${
        isDelete
          ? t('common.delete_confirmation.delete_title')
          : t('common.delete_confirmation.unassign_title')
      } ${t('common.delete_confirmation.device')} "${name}"?`}
      handleConfirm={handleConfirm}
    >
      {children ?? (
        <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
          <i className="fa fa-trash" />
        </span>
      )}
    </WithDeleteConfirm>
  );
};
