import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Modal';
import Spinner from '../../../Spinner';
import useWindowSize from '../../../../utils/hooks/useWindowSize';
import styles from './weatherPreview.module.scss';
import PlacementsEnum from '../../../../enums/placements.enum';
import { disableRightClickIframe } from '../../../../utils/common';

export interface PreviewWeatherModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  selectedWeatherConfig: any;
  selectedWeatherContent: string;
  children?: React.ReactNode;
  placement: any;
}

const PreviewWeatherModal = ({
  show,
  onHide,
  title,
  selectedWeatherConfig,
  selectedWeatherContent,
  children,
  placement,
}: PreviewWeatherModalProps) => {
  const { t } = useTranslation();
  const [iframeRef, setIframeRef] = React.useState<HTMLIFrameElement>();
  const [iframeRatio, setIframeRatio] = useState(1);
  const [url, setUrl] = React.useState('');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [windowWidth] = useWindowSize();

  const handleIframeLoad = () => setIsLoading(false);

  const handleHide = () => {
    setIsLoading(true);
    onHide();
  };

  const updateRatio = (ratio: number) => setIframeRatio(ratio);

  useEffect(() => {
    if (iframeRef?.contentWindow) {
      updateRatio(iframeRef.clientWidth / 1920);
    }
  }, [iframeRef, windowWidth]);

  useEffect(() => {
    if (!iframeRef?.contentWindow) {
      return;
    }

    if (placement === PlacementsEnum.FULL_SCREEN) {
      setUrl(
        `${
          process.env.REACT_APP_PLAYER_PREVIEW_URL
        }/apps/weather/weather.html?weather=${selectedWeatherContent}
        &config=${JSON.stringify(selectedWeatherConfig)
          .replace('#', '__ht__')
          .toLowerCase()
          .replace('color', 'Color')}`,
      );
    } else if (placement === PlacementsEnum.WIDGET) {
      setUrl(
        `${
          process.env.REACT_APP_PLAYER_PREVIEW_URL
        }/apps/weather/weather-widget-thumbnail.html?weather=${selectedWeatherContent}
          &config=${JSON.stringify(selectedWeatherConfig)
            .replace('#', '__ht__')
            .toLowerCase()
            .replace('color', 'Color')}`,
      );
    } else {
      setUrl(
        `${
          process.env.REACT_APP_PLAYER_PREVIEW_URL
        }/apps/weather/weather.html?weather=${selectedWeatherContent}
        &config=${JSON.stringify(selectedWeatherConfig)
          .replace('#', '__ht__')
          .toLowerCase()
          .replace('color', 'Color')}`,
      );
    }

    iframeRef.contentWindow.document.body.style.transformOrigin = 'top left';
    iframeRef.style.display = 'block';
  }, [iframeRef]);

  React.useEffect(() => {
    disableRightClickIframe(title);
  }, []);

  return (
    <>
      {children}
      <Modal
        show={show}
        onHide={handleHide}
        title={title}
        withFooter
        widthDefaultSize={false}
        dialogClassName={styles.dialog}
        footerContent={
          <button
            type="button"
            className="btn btn-light-primary me-2"
            onClick={handleHide}
          >
            {t('common.close')}
          </button>
        }
        bodyClassName={styles.preview}
      >
        <>
          {isLoading ? (
            <Spinner size="md" backdropClassName="bg-white" />
          ) : null}

          <div
            className="d-flex flex-column-reverse flex-center w-100 h-100"
            onContextMenu={(ev) => ev.preventDefault()}
          >
            <iframe
              ref={(e) => setIframeRef(e || undefined)}
              title={title}
              src={url && `${url}&ratio=${iframeRatio}`}
              width="100%"
              height="100%"
              marginHeight={0}
              marginWidth={0}
              onLoad={handleIframeLoad}
              className="mh-425px"
            />
          </div>
        </>
      </Modal>
    </>
  );
};

export default PreviewWeatherModal;
