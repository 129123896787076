import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal';

export default ({
  children,
  image,
}: {
  children: React.ReactNode;
  image: string | undefined;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const open = () => setIsModalVisible(true);
  const close = () => setIsModalVisible(false);

  return (
    <>
      <span role="button" tabIndex={-1} onKeyPress={open} onClick={open}>
        {children}
      </span>
      <Modal
        title={t('contents.view_content')}
        widthDefaultSize={false}
        withFooter={false}
        show={isModalVisible}
        onHide={close}
      >
        <img style={{ width: '100%' }} src={image} alt="Template preview" />
      </Modal>
    </>
  );
};
